
import React, { useCallback, useState, useEffect } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import { LeftArrow, star, iconCase_Study, iconNearly_there, iconPhoto, iconPatch_test } from '../../config/images';
import RequestBookingCard from "../../components/UIComponent/RequestBookingCard";
import TransparentButton from "../../components/UIComponent/TransparentButton";
import LoadingSpinner from "../../components/UIComponent/LoadingSpinner"
import CloseRequestAcceptCancelCard from "../../components/UIComponent/CloseRequestAcceptCancelCard";
import DeclineBookingCard from "../../components/UIComponent/DeclineBookingCard";
import ModelRequirementCloseRequest from '../../components/ModelRequirementCloseRequest'
import { getLocalStorageData,clearLocalStorage, setLocalStorageData } from '../../utils/storage'
import {get24HrsTimeFormat}from '../../utils/utility'
import {
    acceptbookingAPI_Action, practitionerrequestDetailsAPI_Action, resetcreaterequestAPI_Action,
    practionerdeclinebookingAPI_Action, pendingbookingDetailsAPI_Action,resetLoginAPI_Action, upcomingbookingAPI_Action, resetupcomingbookingAPI_Action,
    resetgoginipigprofileAPI_Action, resetpractitionerrequestdetailsAPI_Action, resetacceptbookingAPI_Action,
    resetpractionerdeclinebookingAPI_Action, removerequestforpractitionerprofileAPI_Action,
    resetremoverequestforpractitionerprofileAPI_Action
} from '../../stores/actions'
import Modal from 'react-modal';
import moment from 'moment';

const PendingBookRequest = (props) => {

    let history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const [requestArray, setrequestArray] = useState([]);
    const [subcategory, setsubcategory] = useState("");
    const [bookingDate, setbookingDate] = useState();
    const [bookingId, setBookingId] = useState();
    const [bookingCount, setBookingCount] = useState();
    const [modalIsOpen, setIsOpen] = React.useState(false);

    const pendingbookingDetails = useSelector(state => state.pendingbookingdetails);
    const practitionerrequestDetailsdata = useSelector(state => state.practitionerrequestdetailsdata);
    const removerequestforpractitionerprofiledata = useSelector(state => state.removerequestforpractitionerprofile);
    const loginUserData = useSelector(state => state.login);
    const practitionersignupaddinfoData = useSelector(state => state.practitionersignupaddinfo);
    const cancelreasonslistData = useSelector(state => state.cancelreasonslistdata);
    const declinereasonslistData = useSelector(state => state.declinereasonslistdata);
    const acceptbooking = useSelector(state => state.acceptbooking);
    const practitionerdeclinebookingdata = useSelector(state => state.practitionerdeclinebookingdata)
    const removerequestforpractitionerprofile = useSelector(state => state.removerequestforpractitionerprofile);

    const practitionercancelbookingdata = useSelector(state => state.practitionercancelbookingdata);
    const [formData, updateFormData] = useState(Object.freeze({
        decline_reason_id: [],
        is_togglechangereason1: false,
        is_togglechangereason2: false,
        is_togglechangereason3: false,
        clickCancel: false,
        selectReasonId: '',
        selectReasonIdAll: ''

    }));

    const customStyles = {
        overlay: {
            background: "#dee2e663", zIndex: 999999
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            border: "none",
            background: "none"
        }
    };
    // Use effects


    useEffect(() => {

        try {
            let checkToken = "";
            let LocalDatatoken = getLocalStorageData("loginData")
            let registerData = getLocalStorageData("practitionersignupData")
            //  console.log(LocalDatatoken)

            if (registerData != "" && registerData !== undefined && registerData !== null) {
                checkToken = registerData.token;
            }
            else if (LocalDatatoken !== "" && LocalDatatoken !== undefined && LocalDatatoken !== null) {
                checkToken = LocalDatatoken.token;
            }

            let closerequestdata = getLocalStorageData("closerequestdata")
            api_callNew(closerequestdata.requestId, checkToken);

            let bookingCount = getLocalStorageData("BookigCount")
            setBookingCount(bookingCount)


            if (acceptbooking.acceptbookingdata && acceptbooking.acceptbookingdata.message !== "") {
                closeModal()
            }

        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    }, [])
    useEffect(() => {

        try {
            if (acceptbooking.acceptbookingdata && acceptbooking.acceptbookingdata.message !== "") {
                closeModal()
            }

        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    }, [acceptbooking.acceptbookingdata])



    useEffect(() => {

        try {
            let requestArray = practitionerrequestDetailsdata.practitionerrequestdetailsdata && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.request
            if (requestArray !== null) {
                let subCatArray = []
                requestArray.treatment.treatment_sub_category_new.forEach(element => {
                    subCatArray.push(element.name)

                })

                var str;
                if (subCatArray.length === 1) {
                    str = subCatArray.join()
                } else if (subCatArray.length === 2) {

                    str = subCatArray.join(" & ")
                } else if (subCatArray.length === 3) {
                    str = subCatArray[0] + "," + subCatArray[1] + " & " + subCatArray[2]
                }
                let newDate = ""
                let displayDate = requestArray.request_timeslots[0].date_time

                if (displayDate !== null && displayDate !== undefined) {
                    let day = displayDate.substring(8, 10);
                    let displayDay = ""
                    if (day < 9) {
                        displayDay = day.substring(1, 2)
                    } else {
                        displayDay = day
                    }

                    let n = displayDate.substring(5, 7)
                    // console.log(n)
                    let newn = ""
                    if (n < 10) {
                        newn = n.substring(1, 2)
                    } else {
                        newn = n
                    }

                    var month = new Array();
                    month[1] = "January";
                    month[2] = "February";
                    month[3] = "March";
                    month[4] = "April";
                    month[5] = "May";
                    month[6] = "June";
                    month[7] = "July";
                    month[8] = "August";
                    month[9] = "September";
                    month[10] = "October";
                    month[11] = "November";
                    month[12] = "December";

                    var displaymonth = month[newn];

                    if (displayDay == 1) {
                        newDate = displayDay + "st" + " " + displaymonth + " "
                    } else if (displayDay == 2) {
                        newDate = displayDay + "nd" + " " + displaymonth + " "
                    } else if (displayDay == 3) {
                        newDate = displayDay + "rd" + " " + displaymonth + " "
                    } else {
                        newDate = displayDay + "th" + " " + displaymonth + " "
                    }
                }

                let period = ""
                let hours = ''
                let [sHours, minutes] = ""
                if (requestArray.request_timeslots[0].start_time !== null) {
                    [sHours, minutes] = requestArray.request_timeslots[0].start_time && requestArray.request_timeslots[0].start_time.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
                    period = +sHours < 12 ? 'AM' : 'PM';
                    hours = +sHours % 12 || 12;
                }
               let timein24hrs= get24HrsTimeFormat(requestArray.request_timeslots[0].start_time)

                let displaydate = newDate + new Date().getFullYear() + " @ " + timein24hrs

                setbookingDate(displaydate)
                setsubcategory(requestArray.treatment.title)
            }

            setrequestArray(requestArray)


        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    }, [practitionerrequestDetailsdata])

    const api_callNew = async (requestId, checkToken) => {
        dispatch(resetacceptbookingAPI_Action())
        await dispatch(practitionerrequestDetailsAPI_Action(requestId, checkToken));
      
    }

    if ( practitionerrequestDetailsdata.practitionerrequestdetailsdata && practitionerrequestDetailsdata.practitionerrequestdetailsdata.status_code === 401) {
        dispatch(resetacceptbookingAPI_Action())
        dispatch(resetpractitionerrequestdetailsAPI_Action())
        dispatch(resetLoginAPI_Action())
        clearLocalStorage("loginData")
        clearLocalStorage("practitionersignupData")
        clearLocalStorage("RedirectPathName")
        clearLocalStorage("requestDataLocal")
        clearLocalStorage("confirmData")
        clearLocalStorage("openrequestdata")
        clearLocalStorage("closerequestdata")
        clearLocalStorage("noresponseData")
        window.scrollTo(0, 0);
        history.push('/login');
    }


    useEffect(() => {
        try {
            if (practitionerdeclinebookingdata.practitionerdeclinebookingdata && practitionerdeclinebookingdata.practitionerdeclinebookingdata.message !== "") {
                closeModal()
            }
        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
            closeModal()
        }
    }, [practitionerdeclinebookingdata.practitionerdeclinebookingdata])


    const acceptButtonClick = (e, item) => {
        e.preventDefault();
        setLocalStorageData("acceptBooking", item)
        openModal(e);
        __apiCall(item)
    }
    const openModal = (e) => {
        e.preventDefault();
        setIsOpen(true);
    }

    const closeModal = (item) => {
        setIsOpen(false);

    }
    //console.log(acceptbooking)

    if (acceptbooking.isSuccess === true) {
        dispatch(resetacceptbookingAPI_Action());
        closeModal()

        window.scrollTo(0, 0);
        history.push({
            pathname: '/booking-accepted',
            fromacceptbooking:true
        });
    }

    if (acceptbooking.acceptbookingdata && acceptbooking.acceptbookingdata.status_code === 401) {
        dispatch(resetacceptbookingAPI_Action());
        dispatch(resetLoginAPI_Action())
        dispatch(resetpractitionerrequestdetailsAPI_Action())
        clearLocalStorage("loginData")
        clearLocalStorage("practitionersignupData")
        closeModal()

        window.scrollTo(0, 0);
        history.push({
            pathname: '/login',
        });
    }

  
    const _redirecttoGinipicPhoto = (item,status) => {

        if (item.ginipig_images.length !== 0) {

            let photocloseobj={
                bookingId: item.uuid,
                details: item,
                ginipigreview: false,
                photoGinipigHideButtonClose: true,
                requestId: requestArray.uuid
            }

            setLocalStorageData("photoObj", item)
            if(status==="pending"){
                setLocalStorageData("photocloseobj",photocloseobj)
            }else{
                setLocalStorageData("photocloseobj","")
            }
            
            setLocalStorageData("bookingId", item.uuid)

            window.scrollTo(0, 0);
            history.push({
                pathname: '/photo-ginipig',
                bookingId: item.uuid,
                details: item,
                ginipigreview: false,
                photoGinipigHideButtonClose: true,
                requestId: requestArray.uuid
            });
        }


    }

    const __apiCall = useCallback(async (item) => {

        try {

            let checkToken = "";

            const RegisterToken = practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.token : "" : "";
            let LocalDatatoken = getLocalStorageData("loginData")

            if (RegisterToken != "") {
                checkToken = RegisterToken;
            }
            else if (LocalDatatoken !== "") {
                checkToken = LocalDatatoken.token;
            }
            await dispatch(resetcreaterequestAPI_Action());
            await dispatch(acceptbookingAPI_Action(item.uuid, checkToken));
            await dispatch(practitionerrequestDetailsAPI_Action(requestArray.uuid, checkToken));
            // window.scrollTo(0, 0);
            // history.push({
            //     pathname: '/booking-accepted',
            //   });
        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    });


    const __apiCallDecline = useCallback(async (data) => {

        try {

            let checkToken = "";
            const RegisterToken = practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.token : "" : "";
            let LocalDatatoken = getLocalStorageData("loginData")
            if (RegisterToken != "") {
                checkToken = RegisterToken;
            }
            else if (LocalDatatoken !== "") {
                checkToken = LocalDatatoken.token;
            }
            dispatch(resetpractionerdeclinebookingAPI_Action());
            await dispatch(practionerdeclinebookingAPI_Action(data, checkToken));
            // window.scrollTo(0, 0);

        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    });


    if (practitionerdeclinebookingdata.isSuccess === true) {
        dispatch(resetpractionerdeclinebookingAPI_Action());
        closeModal()

        window.scrollTo(0, 0);
        history.push({
            pathname: '/request-decline',
            bookingDetails: true,

        });
    }
    if (practitionerdeclinebookingdata.practitionerdeclinebookingdata && practitionerdeclinebookingdata.practitionerdeclinebookingdata.status_code === 401) {
        dispatch(resetpractionerdeclinebookingAPI_Action());
        dispatch(resetLoginAPI_Action())
        dispatch(resetpractitionerrequestdetailsAPI_Action())
        clearLocalStorage("loginData")
        clearLocalStorage("practitionersignupData")
        closeModal()

        window.scrollTo(0, 0);
        history.push({
            pathname: '/login',

        });
    }
    

    // if (practitionerdeclinebookingdata.isError === true) {
    //     dispatch(resetpractionerdeclinebookingAPI_Action());
    //     closeModal()
    // }
    const declineButtonClick = (e, id) => {
        e.preventDefault();
        setBookingId(id)


        updateFormData({
            ...formData,
            selectReasonId: '',
            selectReasonIdAll: ''
        });
    }
    const sendDeclineButtonClick = (e, item, status) => {
        e.preventDefault();

        setLocalStorageData("delineBooking", item)
        setLocalStorageData("formDatadecline", formData.decline_reason_id)

        if (status === "cancel") {
            cancelButtonClick(e, item);
        }
        else {
            let formDatanew = {
                id: item.uuid,
                booking_reason_id: formData.decline_reason_id,
            }
            // console.log(formDatanew)
            openModal(e);
            __apiCallDecline(formDatanew)

        }
    }

    const _onCancelClick = () => {

        updateFormData({
            ...formData,
            clickCancel: true,
            selectReasonId: "",
            selectReasonIdAll: ''
        });

    }


    const _onRemoveListingClick = async () => {
        let requestId = requestArray && requestArray.uuid

        let checkToken = "";
        let LocalDatatoken = getLocalStorageData("loginData")
        let registerData = getLocalStorageData("practitionersignupData")

        if (registerData !== "" && registerData !== undefined && registerData !== null) {
            checkToken = registerData.token;
        }
        else if (LocalDatatoken !== "" && LocalDatatoken !== undefined && LocalDatatoken !== null) {
            checkToken = LocalDatatoken.token;
        }
        await dispatch(removerequestforpractitionerprofileAPI_Action(requestId, checkToken));
    }

    if (removerequestforpractitionerprofile.removerequestforpractitionerprofiledata && removerequestforpractitionerprofile.isSuccess == true) {
        dispatch(resetremoverequestforpractitionerprofileAPI_Action());
        dispatch(resetupcomingbookingAPI_Action());
        window.scrollTo(0, 0);
        history.push('/upcoming-pasting-book')
    }

    if (removerequestforpractitionerprofile.removerequestforpractitionerprofiledata && removerequestforpractitionerprofile.removerequestforpractitionerprofiledata.status_code === 401) {
        dispatch(resetremoverequestforpractitionerprofileAPI_Action());
        dispatch(resetLoginAPI_Action())
        dispatch(resetpractitionerrequestdetailsAPI_Action())
        clearLocalStorage("loginData")
        clearLocalStorage("practitionersignupData")
        dispatch(resetupcomingbookingAPI_Action());
        window.scrollTo(0, 0);
        history.push('/login')
    }


    const cancelButtonDirectClick = (e, item) => {
        // let bookingId = practitionerrequestDetailsdata.practitionerrequestdetailsdata && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.booking.id
        //console.log('item',item)
        let formDatanew = {
            id: item,
            //booking_reason_id: formData.decline_reason_id,
        }
        window.scrollTo(0, 0);
        history.push({
            pathname: '/cancel-booking',
            formDatanew: formDatanew
        });
    }


    const cancelButtonClick = (e, item) => {
        // let bookingId = practitionerrequestDetailsdata.practitionerrequestdetailsdata && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.booking.id
        let formDatanew = {
            id: item.uuid,
            booking_reason_id: formData.decline_reason_id,
        }
        window.scrollTo(0, 0);
        history.push({
            pathname: '/confirm-cancel-booking',
            formDatanew: formDatanew
        });
    }

    const cancelAllButtonClick1 = (item) => {
        let requestId = requestArray && requestArray.uuid

        let formDatanew = {
            id: requestId,
            // booking_reason_id: formData.decline_reason_id,
        }
        window.scrollTo(0, 0);
        history.push({
            pathname: '/cancel-booking',
            formDatanew: formDatanew,
            cancel: "all"
        });

    }


    const cancelAllButtonClick = () => {

        let requestId = requestArray && requestArray.uuid
        let formDatanew = {

            cancel_reason_id: formData.decline_reason_id,
            //cancel_reason_id: '',
            id: requestId,
        }

        window.scrollTo(0, 0);
        history.push({
            pathname: '/confirm-cancel-booking',
            formDatanewall: formDatanew,
            cancel: "all"
        });

    }


    const toggleChangeReasonAll = (id, itemData) => {

        let selectedBoxes = formData.decline_reason_id;
        let index = selectedBoxes.indexOf(parseInt(id)) // check to see if the name is already stored in the array
        if (index === -1) {
            selectedBoxes.push(parseInt(id))
        } else {
            selectedBoxes.splice(index, 1)
        }
        updateFormData({
            ...formData,
            decline_reason_id: [id],
            selectReasonIdAll: id
        });


    }



    const toggleChangeReason1 = (id, itemData) => {
        let selectedBoxes = formData.decline_reason_id;

        let index = selectedBoxes.indexOf(parseInt(id)) // check to see if the name is already stored in the array
        if (index === -1) {
            selectedBoxes.push(parseInt(id))
        } else {
            selectedBoxes.splice(index, 1)
        }
        updateFormData({
            ...formData,
            decline_reason_id: [id],
            selectReasonId: id
        });
    }


    const editquatityButtonClick = (requestId) => {
        let ginipicArray = []
        let ginpiccount = practitionerrequestDetailsdata.practitionerrequestdetailsdata && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.request.ginipig_count
        for (let i = ginpiccount; i < 20; i++) {
            ginipicArray.push(i)
        }
        let editginiobj={
            requestId: practitionerrequestDetailsdata.practitionerrequestdetailsdata && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.request.uuid,
            ginipic_count: ginpiccount,
            bookings_count: location.bookings_count,
            ginipicArray: ginipicArray,  
            requesttype:2
        }
        setLocalStorageData("editginiobj",editginiobj)
        //  let bookingId = practitionerrequestDetailsdata.practitionerrequestdetailsdata && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.booking.id
        window.scrollTo(0, 0);
        history.push({
            pathname: '/edit-ginipig-quantity',
            requestId: practitionerrequestDetailsdata.practitionerrequestdetailsdata && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.request.uuid,
            ginipic_count: ginpiccount,
            bookings_count: location.bookings_count,
            ginipicArray: ginipicArray,
            requesttype: 2,

        })
    }


    return (
        <>
            <section className="bgcolor-gray common-section upcoming_past_booking_pg pb80">
                {
                     practitionerrequestDetailsdata.practitionerrequestdetailsdata === null? 
                     <div style={{ width: "100%", marginTop: 250, textAlign: 'center' }}>
                             <LoadingSpinner />
                             <p style={{ fontSize: 20, marginTop: 10 }}>Loading ...</p>
                     </div> 
                     : 
                    <Container>
                    <Row>
                        <Col className="col-lg-11 m-auto">
                            <div className="navigationTop padright-0">
                                <div className="heading-history">
                                    <div className="back-history">
                                        <button type="button" onClick={() => history.goBack()} className="back-btn" >
                                            {" "}
                                            <img src={LeftArrow} alt="arrow" />{" "}
                                        </button>
                                    </div>
                                </div>
                                {/* {removerequestforpractitionerprofile.isFetching ?
                                    <LoadingSpinner /> : null
                                } */}

                                {acceptbooking.isError && acceptbooking.acceptbookingdata &&
                                    acceptbooking.acceptbookingdata.hasOwnProperty(
                                        "message"
                                    ) ? (
                                    <p style={{ color: "red" }}>
                                        {
                                            acceptbooking.acceptbookingdata.message
                                        }{" "}
                                    </p>
                                ) : null}

                                {practitionerdeclinebookingdata.isError && practitionerdeclinebookingdata.practitionerdeclinebookingdata &&
                                    practitionerdeclinebookingdata.practitionerdeclinebookingdata
                                        .hasOwnProperty(
                                            "message"
                                        ) ? (
                                    <p style={{ color: "red" }}>
                                        {
                                            practitionerdeclinebookingdata.practitionerdeclinebookingdata.message
                                        }{" "}
                                    </p>
                                ) : null}

                                <div className="spce_btns_box">
                                    <button className="spc_fill_btns">{bookingCount}/{requestArray && requestArray.ginipig_count} spaces filled</button>
                                </div>
                            </div>

                            <div className="requirement_wrp padright-0">
                                <div className="request_card mb-16">
                                    <RequestBookingCard
                                        treatmentTitle={subcategory}
                                        tagsName={requestArray && requestArray.treatment && requestArray.treatment.categories && requestArray.treatment.categories.name && requestArray.treatment.categories.name}
                                        hrs={requestArray && requestArray.hours}
                                        minutes={requestArray && requestArray.minutes}
                                        // duration={requestArray && requestArray.hours + "h" + " " + requestArray.minutes + "m"}
                                        originalPriceText={requestArray && requestArray.rrp_price && requestArray.rrp_price.toFixed(2)}
                                        discountPrice={requestArray && requestArray.your_price && requestArray.your_price.toFixed(2)}
                                        addressText={requestArray && requestArray.postcodes && requestArray.postcodes.city && requestArray.postcodes.city}
                                        slotTime={bookingDate}
                                        categoryIcon1={iconNearly_there}
                                        categorName1={requestArray && requestArray.expertise_level}
                                        categoryIcon2={iconCase_Study}
                                        categorName2={requestArray && requestArray.reason_require_ginipig}
                                    />
                                </div>

                                {requestArray && requestArray.photograph_required === 1 || requestArray && requestArray.attributes_required === 1 || requestArray && requestArray.is_patchtest_required === 1 ?

                                    <div className="model_req_box mb-8">
                                        <ModelRequirementCloseRequest
                                            content={requestArray}
                                            modelTitle="GiniPig Requirements"
                                            disciption={requestArray.model_requirements}
                                            requiredImg={iconPhoto}
                                            requiredPatchImg={iconPatch_test}
                                            patchText="A patch test is required for this treatment "
                                            requiredText="The Treatment/Service Provider requires you to add a photo to ensure perfect compatibility for this appointment"
                                        />
                                    </div>
                                    : null}

                                <div className="requst-booking mt-24">
                                    <div className="two-col">
                                        <div className="first-div">
                                            {
                                                practitionerrequestDetailsdata.practitionerrequestdetailsdata && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.pending_bookings.length == 0 &&
                                                    practitionerrequestDetailsdata.practitionerrequestdetailsdata && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data &&  practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.accepted_bookings.length == 0 &&
                                                    (
                                                        practitionerrequestDetailsdata.practitionerrequestdetailsdata && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.cancel_bookings.length >= 1 ||
                                                        practitionerrequestDetailsdata.practitionerrequestdetailsdata &&  practitionerrequestDetailsdata.practitionerrequestdetailsdata.data && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.rejected_bookings.length >= 1
                                                    )
                                                    ?
                                                    <TransparentButton
                                                        ButtonType="button"
                                                        // disabled={openrequestbookingdetailsdata.openrequestbookingdetailsdata && openrequestbookingdetailsdata.openrequestbookingdetailsdata.data.accepted_bookings.length>0 || openrequestbookingdetailsdata.openrequestbookingdetailsdata.data !== "" && openrequestbookingdetailsdata.openrequestbookingdetailsdata.data.pending_bookings.length > 0?false:true}
                                                        ButtonClick={_onRemoveListingClick}
                                                        ButtonText="Remove Listing"
                                                    /> :

                                                    <TransparentButton
                                                        ButtonType="button"
                                                        // disabled={  
                                                        //     practitionerrequestDetailsdata.practitionerrequestdetailsdata && 
                                                        //     practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.accepted_bookings.length > 0 
                                                        //     || practitionerrequestDetailsdata.practitionerrequestdetailsdata && 
                                                        //     practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.pending_bookings.length < 0
                                                        //     ? false : true
                                                        // }
                                                        //disabled={practitionerrequestDetailsdata.practitionerrequestdetailsdata && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.accepted_bookings.length > 0 || practitionerrequestDetailsdata.practitionerrequestdetailsdata && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.pending_bookings.length > 0 ? false : true}
                                                        //ButtonClick={_onCancelClick}
                                                        disabled={
                                                            practitionerrequestDetailsdata.practitionerrequestdetailsdata && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data && 
                                                                practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.pending_bookings.length > 0
                                                                ? true : false
                                                        }

                                                        ButtonClick={() => cancelAllButtonClick1()}
                                                        ButtonText="Cancel" />
                                            }
                                        </div>
                                        <div className="sec-div">
                                            <TransparentButton
                                                ButtonType="button"
                                                ButtonClick={editquatityButtonClick}
                                                ButtonText="Edit quantity" />
                                        </div>
                                    </div>
                                </div>

                                {
                                    formData.clickCancel === true &&
                                    <div className="decline_boxs">

                                        <DeclineBookingCard
                                            toggleChangeReason={toggleChangeReasonAll}
                                            flag="cancelAll"
                                            //reasonArrayId={formData.selectReasonId}
                                            selectReasonIdAll={formData.selectReasonIdAll}
                                            status="cancel"
                                            sendDeclineButtonClick={(e) => cancelAllButtonClick(e)}
                                            description={cancelreasonslistData.cancelreasonslistdata && cancelreasonslistData.cancelreasonslistdata.data.reasons}
                                            headingtitle="Select a reason to explain why you are cancelling"
                                        />


                                    </div>
                                }

                                <div className="all_request_box mt-40">
                                    {practitionerrequestDetailsdata.practitionerrequestdetailsdata !== null && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data !==undefined && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.pending_bookings.length > 0 &&
                                        <div className="request_boxs pending mb-40">

                                            <h3 className="request_heading">Pending <span>({practitionerrequestDetailsdata.practitionerrequestdetailsdata !== null && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data !== "" && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.pending_bookings.length})</span> </h3>

                                            {
                                                practitionerrequestDetailsdata.practitionerrequestdetailsdata !== null && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.pending_bookings.map((item, index) => {

                                                    return (
                                                        <div className="mt-16">
                                                            <CloseRequestAcceptCancelCard
                                                                userImg={item.user.profile_pic}
                                                                userName={item.user.name}
                                                                arrowButtonClick={() => _redirecttoGinipicPhoto(item,"pending")}
                                                                showImagearrow={item.ginipig_images.length !== 0 ? true : false}
                                                                //showArrow={item.ginipig_images.length!==0}

                                                                imgArrow={LeftArrow}
                                                                declineButtonClick={(e) => declineButtonClick(e, item.uuid)}
                                                                acceptButtonClick={(e) => acceptButtonClick(e, item)}
                                                                ratingImg={star}
                                                                reliabilityCount={item.user.reliabilityAverage === 0 ? 0 : Math.ceil(item.user.reliabilityAverage)}
                                                                etiquetteCount={item.user.etiquetteAverage === 0 ? 0 : Math.ceil(item.user.etiquetteAverage)}
                                                                //suitabilityCount={item.user.suitabilityAverage === 0 ? 0 : Math.ceil(item.user.suitabilityAverage)}
                                                                ratingPoint={item.user.review_rating_ginipig}
                                                                AcceptButtonText="Accept"
                                                                DeclineButtonText="Decline"
                                                            />

                                                            {
                                                                item.uuid === bookingId &&
                                                                <div className="decline_boxs">

                                                                    <DeclineBookingCard
                                                                        toggleChangeReason={toggleChangeReason1}
                                                                        flag="cancelPending"
                                                                        reasonArrayId={formData.selectReasonId}
                                                                        sendDeclineButtonClick={(e) => sendDeclineButtonClick(e, item, "decline")}
                                                                        description={declinereasonslistData.declinereasonslistdata && declinereasonslistData.declinereasonslistdata.data.reasons}
                                                                        headingtitle="Select a reason to explain why you are declining"

                                                                    />


                                                                </div>
                                                            }
                                                        </div>


                                                    )
                                                })
                                            }





                                        </div>


                                    }

                                    {practitionerrequestDetailsdata.practitionerrequestdetailsdata && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data &&  practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.accepted_bookings.length > 0 &&

                                        <div className="request_boxs confirm_box mb-40">
                                            <h3 className="request_heading"> Confirmed <span>({practitionerrequestDetailsdata.practitionerrequestdetailsdata &&  practitionerrequestDetailsdata.practitionerrequestdetailsdata.data && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.accepted_bookings.length})</span> </h3>
                                            {
                                                practitionerrequestDetailsdata.practitionerrequestdetailsdata && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data &&  practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.accepted_bookings.map((item, index) => {
                                                   

                                                    return (
                                                        <div>
                                                            <CloseRequestAcceptCancelCard
                                                                userImg={item.user.profile_pic}
                                                                userName={item.user.name}
                                                                showImagearrow={true}
                                                                //showImagearrow={true}
                                                                arrowButtonClick={() => _redirecttoGinipicPhoto(item,"confirmed")}
                                                                imgArrow={item.ginipig_images.length > 0 ?  LeftArrow : null}
                                                                ratingImg={star}
                                                                reliabilityCount={item.user.reliabilityAverage === 0 ? 0 : Math.ceil(item.user.reliabilityAverage)}
                                                                etiquetteCount={item.user.etiquetteAverage === 0 ? 0 : Math.ceil(item.user.etiquetteAverage)}
                                                                //suitabilityCount={item.user.suitabilityAverage === 0 ? 0 : Math.ceil(item.user.suitabilityAverage)}

                                                                ratingPoint={item.user.review_rating_ginipig}
                                                                DeclineButtonText="Cancel"
                                                                // declineButtonClick={(e) => declineButtonClick(e, item.uuid)}
                                                                declineButtonClick={(e) => cancelButtonDirectClick(e, item.uuid)}
                                                            />

                                                            {
                                                                item.uuid === bookingId &&
                                                                <div className="decline_boxs">

                                                                    <DeclineBookingCard
                                                                        toggleChangeReason={toggleChangeReason1}
                                                                        flag="cancelConfirm"
                                                                        //itemData = {item}
                                                                        reasonArrayId={formData.selectReasonId}
                                                                        status="cancel"
                                                                        sendDeclineButtonClick={(e) => sendDeclineButtonClick(e, item, "cancel")}
                                                                        description={cancelreasonslistData.cancelreasonslistdata && cancelreasonslistData.cancelreasonslistdata.data.reasons}
                                                                        //description={item.user.cancelreasonslistData}
                                                                        headingtitle="Select a reason to explain why you are Canceling"

                                                                    />


                                                                </div>
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }


                                        </div>


                                    }

                                    {practitionerrequestDetailsdata.practitionerrequestdetailsdata && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data &&  practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.cancel_bookings.length > 0 &&
                                        <div className="request_boxs pending mb-40">
                                            <h3 className="request_heading">Cancellations <span>({practitionerrequestDetailsdata.practitionerrequestdetailsdata && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.cancel_bookings.length})</span> </h3>
                                            {
                                                practitionerrequestDetailsdata.practitionerrequestdetailsdata && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.cancel_bookings.map((item, index) => {
                                                    
                                                    let period = ""
                                                    let hours = ''
                                                    let [sHours, minutes] = ""

                                                    if (item.time !== null) {
                                                        [sHours, minutes] = item.time && item.time.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
                                                        period = +sHours < 12 ? 'am' : 'pm';
                                                        hours = +sHours % 12 || 12;
                                                    }
                                                    let timein24hrsuser = get24HrsTimeFormat(item.time && item.time)

                                                    let dateFormat = moment(item.date).format("Do MMMM YYYY")
                                                    var set_date = dateFormat + " @ " + timein24hrsuser


                                                    return (
                                                        <CloseRequestAcceptCancelCard
                                                            userImg={item.user.profile_pic}
                                                            userName={item.user.name}
                                                            status="cancel"
                                                             set_date={set_date}

                                                            showImagearrow={false}
                                                            ratingImg={star}
                                                            reliabilityCount={item.user.reliabilityAverage === 0 ? 0 : Math.ceil(item.user.reliabilityAverage)}
                                                            etiquetteCount={item.user.reliabilityAverage === 0 ? 0 : Math.ceil(item.user.etiquetteAverage)}
                                                           // suitabilityCount={item.user.reliabilityAverage === 0 ? 0 : Math.ceil(item.user.suitabilityAverage)}

                                                            ratingPoint={item.user.review_rating_ginipig}
                                                        />
                                                    )
                                                })
                                            }
                                        </div>


                                    }
                                </div>

                                <Modal
                                    isOpen={modalIsOpen}
                                    onRequestClose={closeModal}
                                    to
                                    style={customStyles}
                                    shouldCloseOnOverlayClick={false}
                                    contentLabel="Example Modal"
                                >


                                    <div>
                                        <LoadingSpinner />
                                    </div>

                                </Modal>


                            </div>

                        </Col>
                    </Row>
                </Container>
          
                }
                  </section>
        </>
    );
}

export default PendingBookRequest;