import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory,Link } from 'react-router-dom';
import { Container, Row, Col, Tab, Nav, Accordion } from 'react-bootstrap';
import { LeftArrow, reviewAvatar, star, iconPhoto, iconCase_Study, iconNearly_there, Aesthetic, Hair, Beauty, wellness, AestheticTwo, BeautyTwo, HairTwo, wellnessTwo } from '../../config/images';
import {Link as Slink} from 'react-scroll';
import TransparentButton from '../../components/UIComponent/TransparentButton';
import CloseRequestDetail from '../../components/CloseRequestDetail'

import SlickSlider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import UserProfile from '../../components/UserProfile';
import { bookingDetailsAPI_Action ,resetbookingdetailsAPI_Action,userdetailsAPI_Action,goginipigprofileAPI_Action,resetpractitionerProfileAPI_Action,resetgoginipigprofileAPI_Action,checkemailExistsAPI_Action,checkemailExistsEditAPI_Action,GinipigUpdateData_Action, resetGinipigUpdateAPI_Action, resetLoginAPI_Action} from '../../stores/actions'
import { getLocalStorageData ,clearLocalStorage} from '../../utils/storage'
import moment from 'moment'

const UpcomingPendingAppointment = (props) => {

    const SampleNextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div className={className} style={{ ...style }} onClick={onClick}>
                {" "}
                <img src={LeftArrow} className="arrow_icons" alt="icon" />{" "}
            </div>
        );
    };

    const SamplePrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div className={className} style={{ ...style }} onClick={onClick}>
                {" "}
                <img src={LeftArrow} className="arrow_icons" alt="icon" />{" "}
            </div>
        );
    };
    const settings = {
        dots: true,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };


    let history = useHistory();
    const dispatch = useDispatch();
    var param_data = props.match.params.id;
    let loginTokenData = getLocalStorageData("loginData");
    const bookingData = useSelector(state => state.bookingdetails);
    const [profileImages, setProfileImages] = useState([]);


    useEffect(() => {
        __apiCall()
    }, []);

    const __apiCall = useCallback(async () => {
        try {
            await dispatch(bookingDetailsAPI_Action(param_data, loginTokenData.token));
        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    });

    if (bookingData.bookingdetailsdata && bookingData.bookingdetailsdata !== null) {
       // console.log("booking",bookingData.bookingdetailsdata)
        if(bookingData &&  bookingData.bookingdetailsdata &&  bookingData.bookingdetailsdata.status_code===401){
            dispatch(resetbookingdetailsAPI_Action());
            clearLocalStorage("loginData")
            clearLocalStorage("practitionersignupData")
            dispatch(resetLoginAPI_Action());
            dispatch(resetpractitionerProfileAPI_Action());
           // dispatch(resetGinipigUpdateAPI_Action());
            dispatch(resetgoginipigprofileAPI_Action());
            window.scrollTo(0, 0);
           history.push("/login");
       }else{
       
        var time = ''; var time_hour = ''; var time_min = '';
        if (bookingData && bookingData.bookingdetailsdata &&  bookingData.bookingdetailsdata.data && bookingData.bookingdetailsdata.data.booking.time !== null) {
            time = bookingData.bookingdetailsdata.data.booking.time.split(':')
            time_hour = time[0];
            time_min = time[1]
            time = time[0] + ':' + time[1]

            var response_date = [new Date(bookingData.bookingdetailsdata.data.booking.date).getFullYear(), ("0" + (new Date(bookingData.bookingdetailsdata.data.booking.date).getMonth() + 1)).slice(-2), ("0" + new Date(bookingData.bookingdetailsdata.data.booking.date).getDate()).slice(-2)].join("-")

            var number_suf = ['1st', '2nd', '3rd', '4th', '5th', '6th', '7th', '8th', '9th', '10th',
                '11th', '12th', '13th', '14th', '15th', '16th', '17th', '18th', '19th',
                '20th', '21st', '22nd', '23rd', '24th', '25th', '26th', '27th', '28th',
                '29th', '30th', '31st']

            var only_date = new Date(bookingData.bookingdetailsdata.data.booking.date).getDate();
            if (new Date(bookingData.bookingdetailsdata.data.booking.date).getDate() != undefined) {
                for (var i = 0; i < number_suf.length; i++) {
                    if (i + 1 === only_date) {
                        only_date = number_suf[i]
                    }
                }
            }
        }
        // var set_date = only_date + ' ' + new Date([new Date(bookingData.bookingdetailsdata.data.booking.date).getFullYear(), ("0" + (new Date(bookingData.bookingdetailsdata.data.booking.date).getMonth() + 1)).slice(-2), ("0" + new Date(bookingData.bookingdetailsdata.data.booking.date).getDate()).slice(-2)].join("-")).toLocaleString('default', { month: 'long', year:'numeric' }) + ", " + time;
        // console.log('date==',set_date)

        let dateFormat = moment(bookingData.bookingdetailsdata &&  bookingData.bookingdetailsdata.data &&  bookingData.bookingdetailsdata.data.booking.date).format("Do MMMM YYYY")
        var set_date =  dateFormat + " @ " + time



        //console.log("today_date ", today_date , ' response_date ' , response_date ,' today_time ')
      //  console.log(bookingData.bookingdetailsdata && bookingData.bookingdetailsdata)
        var time = '';
        if (bookingData.bookingdetailsdata && bookingData.bookingdetailsdata.data && bookingData.bookingdetailsdata.data.booking.request !== null) {
            if (profileImages.length == 0) {
                var tempImg = [];
                // console.log("requestdetails.requestdetailsdata.data.request.request_images.data.length",requestdetails.requestdetailsdata.data.request.request_images.data.length);
                if (bookingData.bookingdetailsdata && bookingData.bookingdetailsdata.data && bookingData.bookingdetailsdata.data.booking && bookingData.bookingdetailsdata.data.booking.request.data.request_images.data.length < 2) {
                    var islength = true;
                    if (bookingData.bookingdetailsdata && bookingData.bookingdetailsdata.data && bookingData.bookingdetailsdata.data.booking && bookingData.bookingdetailsdata.data.booking.request.data.request_images.data.length == 1) {
                        islength = false;
                        tempImg.push(bookingData.bookingdetailsdata && bookingData.bookingdetailsdata.data && bookingData.bookingdetailsdata.data.booking && bookingData.bookingdetailsdata.data.booking.request.data.request_images.data[0].image_link)
                    }
                    if (bookingData.bookingdetailsdata && bookingData.bookingdetailsdata.data && bookingData.bookingdetailsdata.data.booking && bookingData.bookingdetailsdata.data.booking.request.data.treatment.data.categories.data.name == "Aesthetics") {
                        tempImg.push(Aesthetic);
                        if (islength) {
                            tempImg.push(AestheticTwo);
                        }
                    } else if (bookingData.bookingdetailsdata && bookingData.bookingdetailsdata.data && bookingData.bookingdetailsdata.data.booking && bookingData.bookingdetailsdata.data.booking.request.data.treatment.data.categories.data.name == "Hair") {
                        tempImg.push(Hair);
                        if (islength) {
                            tempImg.push(HairTwo);
                        }
                    } else if (bookingData.bookingdetailsdata && bookingData.bookingdetailsdata.data && bookingData.bookingdetailsdata.data.booking && bookingData.bookingdetailsdata.data.booking.request.data.treatment.data.categories.data.name == "Beauty") {
                        tempImg.push(Beauty);
                        if (islength) {
                            tempImg.push(BeautyTwo);
                        }
                    } else {
                        tempImg.push(wellness);
                        if (islength) {
                            tempImg.push(wellnessTwo);
                        }
                    }
                } else {
                    bookingData.bookingdetailsdata && bookingData.bookingdetailsdata.data && bookingData.bookingdetailsdata.data.booking && bookingData.bookingdetailsdata.data.booking.request.data.request_images.data.map(i => {
                        tempImg.push(i.image_link);
                    });
                }
              //  console.log(tempImg)
                setProfileImages(tempImg);
            }

        }
    }


    }

  

  //  console.log(bookingData.bookingdetailsdata && bookingData.bookingdetailsdata)

    const nextScreenCall = (id) => {
        window.scrollTo(0, 0)
        history.push({ 
            pathname: '/booking-cancellenation-confirmation/' + id,
            paramData : id
           }); 
      //  history.push('/booking-cancellenation-confirmation/' + id);
    }

    const cancellation = () => {
        window.scrollTo(0, 0)
        history.push("/tc#cancellation_policy");
    }

    // const __redirectportfolio = (id) =>{
    //     window.scrollTo(0, 0);
    //     history.push('/ginipig-portfolio/' + id)
    // }


    return (
        <>
            <section className="bgcolor-gray common-section practitioner_profile_forginipig upcoming_pending_pg pb80 upcom_appoint">
                <Container>
                    <Row>


                        <Col className="col-lg-11 m-auto">
                            <div className="navigationTop">
                                <div className="heading-history">
                                    <div className="back-history">
                                        <button type="button" onClick={() => history.goBack()} className="back-btn" >
                                            {" "}
                                            <img src={LeftArrow} alt="arrow" />{" "}
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {
                                bookingData.bookingdetailsdata && bookingData.bookingdetailsdata !== null &&   bookingData.bookingdetailsdata.data && !bookingData.isFetching ?
                                    <div>

                                        <div className="practitioner_profile-wrp from-wrp profile_border">
                                            <div className="practitioner-profile-img ">
                                                <div className="img_wrp_slider">
                                                    <SlickSlider {...settings} >
                                                        {
                                                            profileImages.map((i,index) => {
                                                                return (
                                                                    <div className="img_size profile_img1 ">
                                                                        
                                                                            <img src={i} />
                                                                        
                                                                    </div>
                                                                )
                                                            })
                                                        }

                                                    </SlickSlider>
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            bookingData.bookingdetailsdata && bookingData.bookingdetailsdata.data &&  bookingData.bookingdetailsdata.data.booking.status === 'rejected' ?
                                                <div className="details-wrps ">
                                                    <div className="close_request_box" style={{ marginTop: 15, marginBottom: 15 }}>
                                                        <div className="detail_box white_box">
                                                            <h3 style={{ textAlign: 'center' }}>
                                                                Sadly your enquiry has been declined, we promise it's not personal. Check your inbox for more details, don't delay get looking again for more amazing appointments.
                                                            </h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                : null
                                        }
                                        {/* {console.log('bookingData--',bookingData.bookingdetailsdata)} */}
                                        {
                                            bookingData.bookingdetailsdata && bookingData.bookingdetailsdata.data &&  bookingData.bookingdetailsdata.data.booking.status === 'cancelled_by_practitioner' || bookingData.bookingdetailsdata && bookingData.bookingdetailsdata.data &&  bookingData.bookingdetailsdata.data.booking.status === 'cancelled_by_ginipig'  ?
                                            <div className="details-wrps ">
                                                <div className="close_request_box" style={{ marginTop: 15, marginBottom: 15 }}>
                                                    <div className="detail_box white_box">
                                                        <h3 style={{ textAlign: 'center' }}>
                                                                Your booking enquiry has been cancelled, you have been sent an email with more details
                                                            {/* Your booking enquiry has been cancelled, you have been sent an email with more details */}
                                                            </h3>
                                                       
                                                    </div>
                                                </div>
                                            </div>
                                            : null                                            

                                        }
                                        <div className="details-wrps ">
                                            {/* upcoming_pendding_ap */}
                                            <div className="close_request_box mt-40 mb-70">
                                                <CloseRequestDetail

                                                    treatmentTitle={bookingData.bookingdetailsdata.data.booking.request.data.treatment.data.title}
                                                    currencySign="£"
                                                    originalPriceText={bookingData.bookingdetailsdata.data.booking.request.data.rrp_price.toFixed(2)}
                                                    discountPriceText={bookingData.bookingdetailsdata.data.booking.request.data.your_price.toFixed(2)}
                                                    tagsName={bookingData.bookingdetailsdata.data.booking.request.data.treatment.data.categories.data.name}
                                                  //  duration={bookingData.bookingdetailsdata.data.booking.request.data.hours>0 ?bookingData.bookingdetailsdata.data.booking.request.data.hours+"h ":"" + bookingData.bookingdetailsdata.data.booking.request.data.minutes >0 ?bookingData.bookingdetailsdata.data.booking.request.data.minutes+"m":""}

                                                     hrs={bookingData.bookingdetailsdata.data.booking.request.data.hours}
                                                    minutes={bookingData.bookingdetailsdata.data.booking.request.data.minutes}
                                                    description={bookingData.bookingdetailsdata.data.booking.request.data.treatment.data.description}
                                                    categoryIcon1={iconCase_Study}
                                                    categorName1={bookingData.bookingdetailsdata.data.booking.request.data.reason_require_ginipig}
                                                    categoryIcon2={iconNearly_there}
                                                    categorName2={bookingData.bookingdetailsdata.data.booking.request.data.expertise_level}
                                                    // categoryIcon3={iconGiniPigs_Required}
                                                    // categorName3={bookingData.bookingdetailsdata.data.booking.request.data.expertise_level+" places left "}
                                                    //buttonRequiestTime={bookingData.bookingdetailsdata.data.booking.request.data.type === 1 ? "Flexible availability ":set_date}
                                                    buttonRequiestTime={set_date}
                                                    buttonReqAddress={bookingData.bookingdetailsdata.data.booking.request.data.postcodes!==null && bookingData.bookingdetailsdata.data.booking.request.data.postcodes!==undefined? bookingData.bookingdetailsdata.data.booking.request.data.postcodes.data.postcode:null}
                                                    buttonText="Cancel booking"
                                                    onNextPress={() => { nextScreenCall(bookingData.bookingdetailsdata.data.booking.uuid) }}
                                                    request_type={bookingData.bookingdetailsdata.data.booking.request.data.type}
                                                    bookingStatus={bookingData.bookingdetailsdata.data.booking.status}
                                                    cancelStatus={'1'}
                                                    response_date={response_date}
                                                    time_hour={time_hour}
                                                    time_min={time_min}
                                                />
                                                {
                                                    bookingData.bookingdetailsdata.data.booking.status === 'accepted' || bookingData.bookingdetailsdata.data.booking.status === 'pending' ?
                                                        <div className="btn-align padding24">
                                                            {/* <Slink className="plainbtn btns-tops"  to="/tc#cancellation_policy" style={{cursor:'pointer'}} spy={true} smooth={true} offset={-65} >See cancellation policy</Slink> */}
                                                            <button className="plainbtn btns-tops" onClick={() => cancellation()}>See cancellation policy</button>
                                                        </div> : null
                                                }

                                            </div>
                                        </div>

                                        <div className="details-wrps">
                                            <UserProfile
                                                userImg={bookingData.bookingdetailsdata.data.booking.request.data.user.data.profile_pic}
                                                userName={bookingData.bookingdetailsdata.data.booking.request.data.user.data.first_name + ' ' + bookingData.bookingdetailsdata.data.booking.request.data.user.data.last_name}
                                                ratingImg={star}
                                                userId={bookingData.bookingdetailsdata.data.booking.request.data.user.data.uuid}
                                                ratingPoint="5.0"
                                                address={bookingData.bookingdetailsdata.data.booking.request.data.user.data.user_additional_info.data[0].postcode_data.data.city}
                                                tagsTitle="Other available appointments"
                                                // tagName="Hair"
                                                // count="2"
                                                categoryData={bookingData.bookingdetailsdata.data.booking.request.data.user.data.category_data}

                                            />
                                        </div>

                                    </div> :
                                    <div style={{ width: "100%", fontSize: 20, marginTop: 100, textAlign: 'center' }}>
                                        <p>Loading ...</p>
                                    </div>
                            }

                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default UpcomingPendingAppointment;