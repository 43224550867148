
import React, { useCallback, useState, useEffect } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from 'react-bootstrap';
import { LeftArrow, star, iconCase_Study, iconNearly_there, iconPatch_test, iconPhoto } from '../../config/images';
import RequestBookingCard from "../../components/UIComponent/RequestBookingCard";
import TransparentButton from "../../components/UIComponent/TransparentButton";
import OpenRequestCard from "../../components/UIComponent/OpenRequestCard";
import ModelRequirementCloseRequest from '../../components/ModelRequirementCloseRequest'
import LoadingSpinner from "../../components/UIComponent/LoadingSpinner"
import Modal from 'react-modal';

import { clearLocalStorage, getLocalStorageData, setLocalStorageData } from '../../utils/storage'
import { acceptbookingAPI_Action, openrequestenquiryDetailsAPI_Action, resetupcomingbookingAPI_Action, openrequestbookingDetailsAPI_Action, practitionerrequestDetailsAPI_Action, practionercancelbookingAPI_Action, practionerdeclinebookingAPI_Action, pendingbookingDetailsAPI_Action, upcomingbookingAPI_Action, removerequestforpractitionerprofileAPI_Action, resetremoverequestforpractitionerprofileAPI_Action, resetopenrequestbookingdetailsAPI_Action } from '../../stores/actions'
import CloseRequestAcceptCancelCard from "../../components/UIComponent/CloseRequestAcceptCancelCard";
import DeclineBookingCard from "../../components/UIComponent/DeclineBookingCard";
import moment from 'moment';
import { get24HrsTimeFormat } from "../../utils/utility";


const customStyles = {
    overlay: {
        background: "#dee2e663", zIndex: 999999
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        border: "none",
        background: "none"
    }
};






const OpenRequest = (props) => {

    let history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const [requestArray, setrequestArray] = useState([]);
    const [timeSlotsArray, setTimeSlotsArray] = useState([]);
    const [subcategory, setsubcategory] = useState("");
    const [bookingDate, setbookingDate] = useState();
    const [bookingId, setBookingId] = useState();
    const [bookingCount, setBookingCount] = useState();


    const cancelreasonslistData = useSelector(state => state.cancelreasonslistdata);
    const declinereasonslistData = useSelector(state => state.declinereasonslistdata);
    const openrequestbookingdetailsdata = useSelector(state => state.openrequestbookingdetailsdata);
    const removerequestforpractitionerprofile = useSelector(state => state.removerequestforpractitionerprofile);

    const [formData, updateFormData] = useState(Object.freeze({
        decline_reason_id: [],
        is_togglechangereason1: false,
        is_togglechangereason2: false,
        is_togglechangereason3: false,
        clickCancel: false,
        selectReasonId: ' '
    }));


    // Use effects
    useEffect(() => {

        try {
            let checkToken = "";
            let LocalDatatoken = getLocalStorageData("loginData")
            let registerData = getLocalStorageData("practitionersignupData")

            if (registerData !== "" && registerData !== undefined && registerData !== null) {
                checkToken = registerData.token;
            }
            else if (LocalDatatoken !== "" && LocalDatatoken !== undefined && LocalDatatoken !== null) {
                checkToken = LocalDatatoken.token;
            }
            let openrequestdata = getLocalStorageData("openrequestdata")
            api_callNew(openrequestdata.requestId, checkToken);

            clearLocalStorage("bookingDetailsopen")

            let bookingCount = getLocalStorageData("BookigCount")
            setBookingCount(bookingCount)


        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    }, [])

    const api_callNew = async (requestId, checkToken) => {
        await dispatch(openrequestbookingDetailsAPI_Action(requestId, checkToken));
    }
    useEffect(() => {
        // console.log(cancelreasonslistData.cancelreasonslistdata.data.reasons)

        try {

            let requestArray = openrequestbookingdetailsdata.openrequestbookingdetailsdata && openrequestbookingdetailsdata.openrequestbookingdetailsdata.data.request
            if (requestArray !== null) {
                let subCatArray = []
                requestArray.treatment.treatment_sub_category_new.forEach(element => {
                    subCatArray.push(element.name)

                })

                var str;
                if (subCatArray.length == 1) {
                    str = subCatArray.join()
                } else if (subCatArray.length == 2) {

                    str = subCatArray.join(" & ")
                } else if (subCatArray.length == 3) {
                    str = subCatArray[0] + "," + subCatArray[1] + " & " + subCatArray[2]
                }
                let newDate = ""
                let displayDate = requestArray.request_timeslots[0].date_time


                if (displayDate !== null && displayDate !== undefined) {
                    let day = displayDate.substring(8, 10);
                    let displayDay = ""
                    if (day < 9) {
                        displayDay = day.substring(1, 2)
                    } else {
                        displayDay = day
                    }

                    let n = displayDate.substring(5, 7)
                    // console.log(n)
                    let newn = ""
                    if (n < 10) {
                        newn = n.substring(1, 2)
                    } else {
                        newn = n
                    }

                    var month = new Array();
                    month[1] = "January";
                    month[2] = "February";
                    month[3] = "March";
                    month[4] = "April";
                    month[5] = "May";
                    month[6] = "June";
                    month[7] = "July";
                    month[8] = "August";
                    month[9] = "September";
                    month[10] = "October";
                    month[11] = "November";
                    month[12] = "December";

                    var displaymonth = month[newn];

                    if (displayDay == 1) {
                        newDate = displayDay + "st" + " " + displaymonth
                    } else if (displayDay == 2) {
                        newDate = displayDay + "nd" + " " + displaymonth
                    } else if (displayDay == 3) {
                        newDate = displayDay + "rd" + " " + displaymonth
                    } else {
                        newDate = displayDay + "th" + " " + displaymonth
                    }
                }
                let period = ""
                let hours = ''
                let [sHours, minutes] = ""
                if (requestArray.request_timeslots[0].start_time !== null) {
                    [sHours, minutes] = requestArray.request_timeslots[0].start_time && requestArray.request_timeslots[0].start_time.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
                    period = +sHours < 12 ? 'AM' : 'PM';
                    hours = +sHours % 12 || 12;
                }

                let displaydate = newDate
                setbookingDate(displaydate)
                setsubcategory(requestArray.treatment.title)


                let timeWithDateArray = [];
                let time = {};

                if (requestArray !== undefined) {
                    requestArray.request_timeslots && requestArray.request_timeslots.map((sub_item, index) => {
                        var dateobj = JSON.parse(sub_item.timeslots);
                        let date = sub_item.date_time;
                        let date1 = moment(date).format("DD-MM-yy")
                        time = dateobj[Object.keys(dateobj)[0]];


                        let combinedobj = {
                            date: date1,
                            formmatedDate: new Date(date),
                            request_tyep: 1,
                            selected_slots_morning: dateobj.morning,
                            selected_slots_afternoon: dateobj.afternoon,
                            selected_slots_evening: dateobj.evening
                        }

                        timeWithDateArray.push(combinedobj)

                    })
                }
                setTimeSlotsArray(timeWithDateArray)


            }

            setrequestArray(requestArray)

        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    }, [openrequestbookingdetailsdata])


    // console.log(removerequestforpractitionerprofile)
    if (removerequestforpractitionerprofile.removerequestforpractitionerprofiledata && removerequestforpractitionerprofile.isSuccess == true) {
        dispatch(resetremoverequestforpractitionerprofileAPI_Action());
        dispatch(resetupcomingbookingAPI_Action());
        window.scrollTo(0, 0);
        history.push('/upcoming-pasting-book')
    }




    const __apiCallDecline = useCallback(async (data) => {

        try {

            let checkToken = "";
            let LocalDatatoken = getLocalStorageData("loginData")
            let registerData = getLocalStorageData("practitionersignupData")

            if (registerData !== "" && registerData !== undefined && registerData !== null) {
                checkToken = registerData.token;
            }
            else if (LocalDatatoken !== "" && LocalDatatoken !== undefined && LocalDatatoken !== null) {
                checkToken = LocalDatatoken.token;
            }
            await dispatch(practionerdeclinebookingAPI_Action(data, checkToken));
            window.scrollTo(0, 0);

        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    });


    const _onRemoveListingClick = async () => {
        let requestId = openrequestbookingdetailsdata.openrequestbookingdetailsdata && openrequestbookingdetailsdata.openrequestbookingdetailsdata.data.request.uuid

        let checkToken = "";
        let LocalDatatoken = getLocalStorageData("loginData")
        let registerData = getLocalStorageData("practitionersignupData")

        if (registerData !== "" && registerData !== undefined && registerData !== null) {
            checkToken = registerData.token;
        }
        else if (LocalDatatoken !== "" && LocalDatatoken !== undefined && LocalDatatoken !== null) {
            checkToken = LocalDatatoken.token;
        }
        await dispatch(removerequestforpractitionerprofileAPI_Action(requestId, checkToken));
    }


    const sendDeclineButtonClick = (item, status) => {
        if (status === "cancel") {
            cancelButtonClick(item);
        }
        else {
            //  console.log(formData.decline_reason_id)
            let formDatanew = {
                id: item.uuid,
                booking_reason_id: formData.decline_reason_id,
            }
            // console.log(formDatanew)

            __apiCallDecline(formDatanew)
            window.scrollTo(0, 0);
            history.push({
                pathname: '/request-decline',
                bookingDetails: item,
                formData: formData.decline_reason_id
            });
        }
    }

    const _onCancelClick = () => {

        updateFormData({
            ...formData,
            clickCancel: true,
        });
    }


    const cancelAllButtonClick1 = (item) => {
        // let bookingId = openrequestbookingdetailsdata.openrequestbookingdetailsdata && openrequestbookingdetailsdata.openrequestbookingdetailsdata.data.booking.id
        let requestId = requestArray && requestArray.uuid

        let formDatanew = {
            id: requestId,
            // booking_reason_id: formData.decline_reason_id,
        }
        window.scrollTo(0, 0);
        history.push({
            pathname: '/cancel-booking',
            formDatanew: formDatanew,
            cancel: "all"
        });

    }

    const cancelButtonClick = (item) => {
        // let bookingId = openrequestbookingdetailsdata.openrequestbookingdetailsdata && openrequestbookingdetailsdata.openrequestbookingdetailsdata.data.booking.id
        let formDatanew = {
            id: item.uuid,
            booking_reason_id: formData.decline_reason_id,
        }
        window.scrollTo(0, 0);
        history.push({
            pathname: '/confirm-cancel-booking',
            formDatanew: formDatanew
        });

    }

    const cancelAllButtonClick = () => {
        let requestId = requestArray && requestArray.uuid
        let formDatanew = {

            cancel_reason_id: formData.decline_reason_id,
            id: requestId
        }
        window.scrollTo(0, 0);
        history.push({
            pathname: '/confirm-cancel-booking',
            formDatanewall: formDatanew,
            cancel: "all"
        });

    }



    const toggleChangeReason = (id) => {

        let selectedBoxes = formData.decline_reason_id;
        let index = selectedBoxes.indexOf(parseInt(id)) // check to see if the name is already stored in the array
        if (index === -1) {
            selectedBoxes.push(parseInt(id))
        } else {
            selectedBoxes.splice(index, 1)
        }
        updateFormData({
            ...formData,
            decline_reason_id: [id],
            selectReasonId: id
        });

    }



    const editquatityButtonClick = (requestId) => {
        let ginipicArray = []
        let ginpiccount = openrequestbookingdetailsdata.openrequestbookingdetailsdata && openrequestbookingdetailsdata.openrequestbookingdetailsdata.data.request.ginipig_count
        for (let i = ginpiccount; i < 21; i++) {
            ginipicArray.push(i)
        }
        let editginiobj = {
            requestId: openrequestbookingdetailsdata.openrequestbookingdetailsdata && openrequestbookingdetailsdata.openrequestbookingdetailsdata.data.request.uuid,
            ginipic_count: ginpiccount,
            bookings_count: location.bookings_count,
            ginipicArray: ginipicArray,
            requesttype: 1,
        }
        setLocalStorageData("editginiobj", editginiobj)
        //  let bookingId = openrequestbookingdetailsdata.openrequestbookingdetailsdata && openrequestbookingdetailsdata.openrequestbookingdetailsdata.data.booking.id
        window.scrollTo(0, 0);
        history.push({
            pathname: '/edit-ginipig-quantity',
            requestId: openrequestbookingdetailsdata.openrequestbookingdetailsdata && openrequestbookingdetailsdata.openrequestbookingdetailsdata.data.request.uuid,
            ginipic_count: ginpiccount,
            bookings_count: location.bookings_count,
            ginipicArray: ginipicArray,
            requesttype: 1,

        })
    }


    const handleBookingClick = useCallback(async (requestId, spaces_fiiled, item) => {
        try {
            let checkToken = "";
            let LocalDatatoken = getLocalStorageData("loginData")
            let registerData = getLocalStorageData("practitionersignupData")

            if (registerData != "" && registerData !== undefined && registerData !== null) {
                checkToken = registerData.token;
            }
            else if (LocalDatatoken !== "" && LocalDatatoken !== undefined && LocalDatatoken !== null) {
                checkToken = LocalDatatoken.token;
            }
            let bookingDetailsopendata = {
                requestId: requestId,
                bookings_count: spaces_fiiled,
                uuid: item.uuid

            }
            setLocalStorageData("bookingDetailsopen", item)
            setLocalStorageData("bookingDetailsopendata", bookingDetailsopendata)

            await dispatch(practitionerrequestDetailsAPI_Action(requestId, checkToken));
            await dispatch(openrequestenquiryDetailsAPI_Action(item.uuid, checkToken));


            window.scrollTo(0, 0);
            history.push({

                pathname: '/accept-open-request',
                bookings_count: spaces_fiiled,
                bookingDetails: item
            });


        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    });


    const goBack = () => {
        dispatch(resetopenrequestbookingdetailsAPI_Action())
        history.goBack()
    }

    const handleBookingClickCancel = useCallback(async (requestId, spaces_fiiled, item) => {
        try {
            let checkToken = "";
            let LocalDatatoken = getLocalStorageData("loginData")
            let registerData = getLocalStorageData("practitionersignupData")

            if (registerData != "" && registerData !== undefined && registerData !== null) {
                checkToken = registerData.token;
            }
            else if (LocalDatatoken !== "" && LocalDatatoken !== undefined && LocalDatatoken !== null) {
                checkToken = LocalDatatoken.token;
            }
            // console.log("openre",item)
            let bookingDetailsopendata = {
                requestId: requestId,
                bookings_count: spaces_fiiled,
                itemuuid: item.uuid

            }
            setLocalStorageData("bookingDetailsopen", item)
            setLocalStorageData("bookingDetailsopendata", bookingDetailsopendata)

            await dispatch(practitionerrequestDetailsAPI_Action(requestId, checkToken));
            await dispatch(openrequestenquiryDetailsAPI_Action(item.uuid, checkToken));


            window.scrollTo(0, 0);
            history.push({

                pathname: '/reject-open-request',
                bookings_count: spaces_fiiled,
                bookingDetails: item
            });


        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    });

    let durationTimeZero = '';
    if (requestArray) {
        if (requestArray.hours === 0) {
            durationTimeZero = requestArray.minutes + 'm';
        } else {
            durationTimeZero = requestArray.hours + 'h' + '  ' + requestArray.minutes + 'm';
        }
    }

    return (
        <>
            <section className="bgcolor-gray common-section upcoming_past_booking_pg pb80">
                {openrequestbookingdetailsdata.openrequestbookingdetailsdata === null ?
                    <div style={{ width: "100%", marginTop: 250, textAlign: 'center' }}>
                        <LoadingSpinner />
                        <p style={{ fontSize: 20, marginTop: 10 }}>Loading ...</p>
                    </div>
                    :
                    <Container>
                        <Row>
                            <Col className="col-lg-11 m-auto">
                                <div className="navigationTop padright-0">
                                    <div className="heading-history">
                                        <div className="back-history">
                                            <button type="button"
                                                onClick={goBack} className="back-btn" >
                                                {" "}
                                                <img src={LeftArrow} alt="arrow" />{" "}
                                            </button>
                                        </div>
                                    </div>
                                    {removerequestforpractitionerprofile.isFetching ?
                                        <LoadingSpinner /> : null
                                    }

                                    <div className="spce_btns_box">
                                        <button className="spc_fill_btns">{bookingCount}/{requestArray && requestArray.ginipig_count} spaces filled</button>
                                    </div>
                                </div>

                                <div className="requirement_wrp padright-0">
                                    <div className="request_card mb-16">
                                        <RequestBookingCard
                                            treatmentTitle={subcategory}
                                            tagsName={requestArray && requestArray.treatment && requestArray.treatment.categories && requestArray.treatment.categories.name && requestArray.treatment.categories.name}
                                            hrs={requestArray && requestArray.hours}
                                            minutes={requestArray && requestArray.minutes}

                                            //duration={requestArray && requestArray.hours + "h" + " " + requestArray.minutes + "m"}
                                            //duration={durationTimeZero}
                                            originalPriceText={requestArray && requestArray.rrp_price && requestArray.rrp_price.toFixed(2)}
                                            discountPrice={requestArray && requestArray.your_price && requestArray.your_price.toFixed(2)}
                                            addressText={requestArray && requestArray.postcodes && requestArray.postcodes.city && requestArray.postcodes.city}
                                            slotTime="Flexible availability"
                                            categoryIcon1={iconNearly_there}
                                            categorName1={requestArray && requestArray.expertise_level}
                                            categoryIcon2={iconCase_Study}
                                            categorName2={requestArray && requestArray.reason_require_ginipig}
                                        />
                                    </div>

                                    {(requestArray && requestArray.photograph_required === 1) || (requestArray && requestArray.attributes_required === 1) || (requestArray && requestArray.is_patchtest_required === 1) ?

                                        <div className="model_req_box mb-8">
                                            <ModelRequirementCloseRequest
                                                content={requestArray}
                                                modelTitle="GiniPig Requirements "
                                                disciption={requestArray.model_requirements}
                                                requiredImg={iconPhoto}
                                                requiredPatchImg={iconPatch_test}
                                                patchText="A patch test is required for this treatment"
                                                requiredText="The Treatment/Service Provider requires you to add a photo to ensure perfect compatibility for this appointment "
                                            />
                                        </div>
                                        : null}


                                    <div className="requst-booking mt-24">
                                        <div className="two-col">
                                            <div className="first-div">
                                                {
                                                    openrequestbookingdetailsdata.openrequestbookingdetailsdata && openrequestbookingdetailsdata.openrequestbookingdetailsdata.data.accepted_bookings.length == 0 && openrequestbookingdetailsdata.openrequestbookingdetailsdata.data !== "" && openrequestbookingdetailsdata.openrequestbookingdetailsdata.data.pending_bookings.length == 0 &&
                                                        (
                                                            openrequestbookingdetailsdata.openrequestbookingdetailsdata.data !== "" && openrequestbookingdetailsdata.openrequestbookingdetailsdata.data.cancel_bookings.length >= 1 ||
                                                            openrequestbookingdetailsdata.openrequestbookingdetailsdata.data !== "" && openrequestbookingdetailsdata.openrequestbookingdetailsdata.data.rejected_bookings.length >= 1
                                                        )
                                                        ?
                                                        <TransparentButton
                                                            ButtonType="button"
                                                            ButtonClick={_onRemoveListingClick}
                                                            ButtonText="Remove Listing"
                                                        /> :

                                                        <TransparentButton
                                                            ButtonType="button"
                                                            disabled={
                                                                openrequestbookingdetailsdata.openrequestbookingdetailsdata &&
                                                                    openrequestbookingdetailsdata.openrequestbookingdetailsdata.data.pending_bookings.length > 0
                                                                    ? true : false
                                                            }
                                                            ButtonClick={() => cancelAllButtonClick1()}
                                                            // ButtonClick={_onCancelClick}
                                                            ButtonText="Cancel"
                                                        />



                                                }
                                                {/* {console.log('d',openrequestbookingdetailsdata.openrequestbookingdetailsdata)} */}

                                            </div>
                                            <div className="sec-div">
                                                <TransparentButton
                                                    ButtonType="button"
                                                    ButtonClick={editquatityButtonClick}
                                                    ButtonText="Edit quantity" />
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        formData.clickCancel === true &&
                                        <div className="decline_boxs">

                                            <DeclineBookingCard
                                                toggleChangeReason={toggleChangeReason}
                                                flag="cancelOpen"
                                                reasonArrayId={formData.selectReasonId}
                                                status="cancel"
                                                sendDeclineButtonClick={() => cancelAllButtonClick()}
                                                description={cancelreasonslistData.cancelreasonslistdata && cancelreasonslistData.cancelreasonslistdata.data.reasons}
                                                headingtitle="Select a reason to explain why you are cancelling"

                                            />


                                        </div>
                                    }

                                    <div>
                                        <div className="title_set pb24 titile_box mt-32 border_bottom_1">
                                            <h3 className="heading">Listed Availability </h3>

                                        </div>
                                        {
                                            timeSlotsArray.length !== 0 && timeSlotsArray.map((item, index) => {
                                                return (
                                                    <div className="form-wrp mt-32">
                                                        <div className="inside_box_1">
                                                            <div className="time-box">
                                                                <div className="appointment_date">
                                                                    <p className="dates">{item.date}</p>
                                                                </div>
                                                                <div className="appointment_slot">
                                                                    <div className={item.selected_slots_morning && item.selected_slots_morning.length > 0 ? "day_slot mb-10" : "day_slot mb-10 disable_opacity"}>
                                                                        <div className="appoint_slots">
                                                                            <span className="morning_icon icon_slots slot_heading">Morning</span>
                                                                        </div>
                                                                        <div className="appointment_times">
                                                                            <div className="timeslot">
                                                                                {
                                                                                    (item.selected_slots_morning && item.selected_slots_morning.length > 0) && item.selected_slots_morning.map((sub_item, sub_index) => {
                                                                                        return (
                                                                                            <span>{sub_item}</span>
                                                                                        )

                                                                                    })
                                                                                }
                                                                                <div className={item.selected_slots_morning && item.selected_slots_morning.length > 0 ? "noAvailable_box slot-show" : "noAvailable_box"}>
                                                                                    <span className="noavailaibel_text">No availability</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className={item.selected_slots_afternoon && item.selected_slots_afternoon.length > 0 ? "day_slot mb-10" : "day_slot mb-10 disable_opacity"}>
                                                                        <div className="appoint_slots">
                                                                            <span className="afternoon_icon icon_slots slot_heading">Afternoon</span>
                                                                        </div>

                                                                        <div className="appointment_times">
                                                                            <div className="timeslot">
                                                                                {
                                                                                    (item.selected_slots_afternoon && item.selected_slots_afternoon.length > 0) && item.selected_slots_afternoon.map((sub_item, sub_index) => {
                                                                                        return (
                                                                                            <span>{sub_item}</span>
                                                                                        )

                                                                                    })
                                                                                }
                                                                                <div className={item.selected_slots_afternoon && item.selected_slots_afternoon.length > 0 ? "noAvailable_box slot-show" : "noAvailable_box"}>
                                                                                    <span className="noavailaibel_text">No availability</span>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className={item.selected_slots_evening && item.selected_slots_evening.length > 0 ? "day_slot mb-10" : "day_slot mb-10 disable_opacity"}>
                                                                        <div className="appoint_slots">
                                                                            <span className="evening_icon icon_slots slot_heading">Evening</span>
                                                                        </div>
                                                                        <div className="appointment_times">
                                                                            <div className="timeslot">
                                                                                {
                                                                                    (item.selected_slots_evening && item.selected_slots_evening.length > 0) && item.selected_slots_evening.map((sub_item, sub_index) => {
                                                                                        return (
                                                                                            <span>{sub_item}</span>
                                                                                        )

                                                                                    })
                                                                                }
                                                                                <div className={item.selected_slots_evening && item.selected_slots_evening.length > 0 ? "noAvailable_box slot-show" : "noAvailable_box"}>
                                                                                    <span className="noavailaibel_text">No availability</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                )
                                            })
                                        }
                                    </div>

                                    <div className="all_request_box mt-40">
                                        {openrequestbookingdetailsdata.openrequestbookingdetailsdata !== null && openrequestbookingdetailsdata.openrequestbookingdetailsdata.data !== "" && openrequestbookingdetailsdata.openrequestbookingdetailsdata.data.pending_bookings.length > 0 &&
                                            <div className="request_boxs pending mb-40">

                                                <h3 className="request_heading">Pending <span>({openrequestbookingdetailsdata.openrequestbookingdetailsdata !== null && openrequestbookingdetailsdata.openrequestbookingdetailsdata.data !== "" && openrequestbookingdetailsdata.openrequestbookingdetailsdata.data.pending_bookings.length})</span> </h3>
                                                {
                                                    openrequestbookingdetailsdata.openrequestbookingdetailsdata !== null && openrequestbookingdetailsdata.openrequestbookingdetailsdata.data && openrequestbookingdetailsdata.openrequestbookingdetailsdata.data.pending_bookings.map((item, index) => {
                                                        let set_date = moment(item.booking.date).format("Do MMMM YYYY");
                                                        //  let set_time = moment(item.booking.time, "HH:mm:ss").format("hh:mm");
                                                        //  let set_dateTime = `${set_date} @ ${set_time}`
                                                        //console.log(item.user.profile_pic)
                                                        //console.log("all data",set_date)
                                                        // console.log(" data",set_time)
                                                        return (
                                                            <div className="mt-16">
                                                                <OpenRequestCard
                                                                    Title={subcategory}
                                                                    imgArrow={LeftArrow}
                                                                    addressText={requestArray && requestArray.postcodes && requestArray.postcodes.city && requestArray.postcodes.city}
                                                                    //slotTime={moment(item.date).format("DD-MM-yy")}
                                                                    slotTime={set_date}
                                                                    reviewAvatar={item.user.profile_pic}
                                                                    userName={item.user.name}
                                                                    ratingImg={star}
                                                                    ratingPoint={item.user.review_rating_ginipig}
                                                                    handleBookingClick={() => handleBookingClick(requestArray.uuid, location.bookings_count, item)}


                                                                //arrowButtonClick={() =>_redirecttoGinipicPhoto(item)}
                                                                />
                                                                {/* <CloseRequestAcceptCancelCard
                                                               userImg={item.user.profile_pic}
                                                               userName={item.user.name}
                                                               arrowButtonClick={() => _redirecttoGinipicPhoto(item)}
   
                                                               imgArrow={LeftArrow}
                                                               declineButtonClick={() => declineButtonClick(item.id)}
                                                               acceptButtonClick={() => acceptButtonClick(item)}
                                                               ratingImg={star}
                                                               ratingPoint="5.0"
                                                               AcceptButtonText="Select & Accept"
                                                               DeclineButtonText="Decline"
                                                           /> */}

                                                                {
                                                                    item.uuid === bookingId &&
                                                                    <div className="decline_boxs">

                                                                        <DeclineBookingCard
                                                                            toggleChangeReason={toggleChangeReason}
                                                                            flag="cancelOpenPending"
                                                                            reasonArrayId={formData.selectReasonId}
                                                                            sendDeclineButtonClick={() => sendDeclineButtonClick(item, "decline")}
                                                                            description={declinereasonslistData.declinereasonslistdata && declinereasonslistData.declinereasonslistdata.data.reasons}
                                                                            headingtitle="Select a reason to explain why you are declining"

                                                                        />
                                                                    </div>
                                                                }
                                                            </div>


                                                        )
                                                    })
                                                }





                                            </div>


                                        }
                                        {openrequestbookingdetailsdata.openrequestbookingdetailsdata && openrequestbookingdetailsdata.openrequestbookingdetailsdata.data.accepted_bookings.length > 0 &&
                                            <div className="request_boxs confirm_box mb-40">
                                                <h3 className="request_heading"> Confirmed <span>({openrequestbookingdetailsdata.openrequestbookingdetailsdata && openrequestbookingdetailsdata.openrequestbookingdetailsdata.data.accepted_bookings.length})</span> </h3>
                                                {
                                                    openrequestbookingdetailsdata.openrequestbookingdetailsdata && openrequestbookingdetailsdata.openrequestbookingdetailsdata.data.accepted_bookings.map((item, index) => {
                                                        // console.log("current time...",item)
                                                        let set_date1 = moment(item.date).format("Do MMMM YYYY");
                                                        let set_time1 = moment(item.time, "HH:mm").format("HH:mm");
                                                        let set_dateTime = `${set_date1} @ ${set_time1}`


                                                        return (
                                                            <div>

                                                                <OpenRequestCard
                                                                    Title={subcategory}
                                                                    imgArrow={LeftArrow}
                                                                    addressText={requestArray && requestArray.postcodes && requestArray.postcodes.city && requestArray.postcodes.city}
                                                                    //slotTime={moment(item.date).format("DD-MM-yy")}
                                                                    slotTime={set_dateTime}
                                                                    reviewAvatar={item.user.profile_pic}
                                                                    userName={item.user.name}
                                                                    ratingImg={star}
                                                                    ratingPoint={item.user.review_rating_ginipig}
                                                                    handleBookingClick={() => handleBookingClickCancel(requestArray.uuid, location.bookings_count, item)}

                                                                //arrowButtonClick={() =>_redirecttoGinipicPhoto(item)}
                                                                />
                                                                {/* <CloseRequestAcceptCancelCard
                                                              userImg={item.user.profile_pic}
                                                              userName={item.user.name}
                                                              arrowButtonClick={() => _redirecttoGinipicPhoto(item)}
                                                              imgArrow={LeftArrow}
                                                              ratingImg={star}
                                                              ratingPoint="5.0"
                                                              DeclineButtonText="Cancel"
                                                              declineButtonClick={() => declineButtonClick(item.id)}
                                                          /> */}

                                                                {
                                                                    item.uuid === bookingId &&
                                                                    <div className="decline_boxs">

                                                                        <DeclineBookingCard
                                                                            toggleChangeReason={toggleChangeReason}
                                                                            reasonArrayId={formData.selectReasonId}
                                                                            flag="cancelOpenConfirm"
                                                                            status="cancel"
                                                                            sendDeclineButtonClick={() => sendDeclineButtonClick(item, "cancel")}
                                                                            description={cancelreasonslistData.cancelreasonslistdata && cancelreasonslistData.cancelreasonslistdata.data.reasons}
                                                                            headingtitle="Select a reason to explain why you are Canceling"
                                                                        />


                                                                    </div>
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                }


                                            </div>


                                        }

                                        {openrequestbookingdetailsdata.openrequestbookingdetailsdata && openrequestbookingdetailsdata.openrequestbookingdetailsdata.data.cancel_bookings.length > 0 &&
                                            <div className="request_boxs pending mb-40">
                                                <h3 className="request_heading">Cancellations <span>({openrequestbookingdetailsdata.openrequestbookingdetailsdata && openrequestbookingdetailsdata.openrequestbookingdetailsdata.data.cancel_bookings.length})</span> </h3>
                                                {
                                                    openrequestbookingdetailsdata.openrequestbookingdetailsdata && openrequestbookingdetailsdata.openrequestbookingdetailsdata.data.cancel_bookings.map((item, index) => {
                                                        var time = '';
                                                        let period = ""
                                                        let hours = ''
                                                        let [sHours, minutes] = ""

                                                        if (item.time !== null) {
                                                            [sHours, minutes] = item.time && item.time.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
                                                            period = +sHours < 12 ? 'am' : 'pm';
                                                            hours = +sHours % 12 || 12;
                                                        }
                                                        let timein24hrsuser = get24HrsTimeFormat(item.time && item.time)

                                                        let dateFormat = moment(item.date).format("Do MMMM YYYY")
                                                        var set_date = dateFormat + " @ " + timein24hrsuser

                                                        return (
                                                            <CloseRequestAcceptCancelCard
                                                                userImg={item.user.profile_pic}
                                                                userName={item.user.name}
                                                                status="cancel"
                                                                showImagearrow={false}
                                                                ratingImg={star}
                                                                set_date={set_date}
                                                                reliabilityCount={item.user.reliabilityAverage === 0 ? 0 : Math.ceil(item.user.reliabilityAverage)}
                                                                etiquetteCount={item.user.reliabilityAverage === 0 ? 0 : Math.ceil(item.user.etiquetteAverage)}
                                                                //suitabilityCount={item.user.reliabilityAverage === 0 ? 0 : Math.ceil(item.user.suitabilityAverage)}

                                                                ratingPoint={item.user.review_rating_ginipig}
                                                            />
                                                        )
                                                    })
                                                }
                                            </div>


                                        }
                                    </div>


                                </div>










                            </Col>
                        </Row>
                    </Container>

                }
            </section>
        </>
    );
}


export default OpenRequest;