
import React, { useCallback, useState, useEffect } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from 'react-bootstrap';
import { LeftArrow, star, iconPhoto,iconDelete, reviewAvatar } from '../../config/images';
import  NavigationBreadcrumbs from '../../components/NavigationBreadcrumbs';

//redux 
import { GinipigPortfolioAPI_Action, resetGinipigPortfolioAPI_Action } from '../../stores/actions'


const GinipigPortfolio = (props) => {

    let history = useHistory();
    let dispatch = useDispatch();
    
    const id=props.match.params.id;
    const portfolioInfo = useSelector(state => state.ginipigPortfolio);

        // Use effects
        useEffect(() => {
            dispatch(GinipigPortfolioAPI_Action(id));
        }, [])
    
        //console.log(portfolioInfo);
        const backBtnPressed = () => {
            //console.log("back button presses");
            history.goBack();
        }

    return (
        <>
            <section className="bgcolor-gray common-section Ginipigportfolio pb80">
                <Container>
                    <Row>
                        <Col className="col-lg-11 m-auto">
                            <NavigationBreadcrumbs onBackClick={() => { backBtnPressed() }}
                            />
                                
                                <div className="portfolio_wrp">
                                    <div className="port pading16 white">
                                        <div className="gridbox gridcol-3">
                                        {
                                   
                                   portfolioInfo.portfolioImgList && portfolioInfo.portfolioImgList.data.portfolio_images.map((item, key) => {
                                            return (
                                                <div className="img-item">
                                                        <div className="img-box">
                                                            <img src={item.image_link} className="imgblock" />
                                                        </div>
                                                        <div className={item.image_title===null || item.image_title==="" ?"display-none": "img-label"} ><span>{item.image_title}</span></div>
                                                    </div>
                                            
                                                ) 
                                            })
                                        }

                                        </div>
                                    </div>                   
                                </div>
                            

                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default GinipigPortfolio;