import React, { useCallback, useState, useEffect } from "react";
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";

import { clearLocalStorage, getLocalStorageData } from '../../utils/storage'
import { LeftArrow, star, iconCase_Study, iconNearly_there, iconPhoto, reviewAvatar } from '../../config/images';
import TransparentButton from "../../components/UIComponent/TransparentButton";
import CloseRequestAcceptCancelCard from "../../components/UIComponent/CloseRequestAcceptCancelCard";
import { resetpractionerdeclinebookingAPI_Action,resetupcomingbookingAPI_Action } from '../../stores/actions';

const RequestDecline = (props) => {
    const dispatch = useDispatch();
    let history = useHistory();
    const location=useLocation()
    const [bookingdata,SetbookingData]=useState()
    const [formDatadecline,SetformDatadecline] = useState()

    useEffect(() => {
        window.addEventListener('popstate', function(event) {        
            __redirectProfile()
             window.location.reload(false);
        }, false);
        if(!location.bookingDetails){
            __redirectProfile()
        }

        let bookingdata=getLocalStorageData("delineBooking")
        SetbookingData(bookingdata)
        clearLocalStorage('bookingDetailsopen')
        clearLocalStorage('photoObj')

        let formDatadecline=getLocalStorageData("formDatadecline")
        SetformDatadecline(formDatadecline)

        
        dispatch(resetpractionerdeclinebookingAPI_Action());
      
    }, [])


    const __redirectProfile = () => {
        window.scrollTo(0, 0);
        window.removeEventListener('popstate', function(event) {        
            // history.go(1);
         }, false);
        dispatch(resetupcomingbookingAPI_Action());
        history.push({
            pathname: '/practitioner-account',
            iswindowreload:true
        });
    }

    

    return (
        <>
            <section className="bgcolor-gray common-section request_decline_pg pb80">
                <Container>
                    <Row>
                        <Col className="col-lg-11 m-auto">

                            <div className="decline_boxs mt-70">
                                <h3 className="decline-heading mb-8" > You have declined this GiniPig’s booking</h3>
                                <div className="decline_review mt-16 mb-16">
                                    <CloseRequestAcceptCancelCard
                                        userImg={bookingdata && bookingdata.user.profile_pic}
                                        userName={bookingdata && bookingdata.user.name}
                                        ratingImg={star}
                                        showImagearrow={false}
                                        reliabilityCount={bookingdata && bookingdata.user && bookingdata.user.reliabilityAverage === 0 ? 0 : Math.ceil(bookingdata && bookingdata.user && bookingdata.user.reliabilityAverage)}
                                        etiquetteCount={bookingdata && bookingdata.user && bookingdata.user.reliabilityAverage === 0 ? 0 : Math.ceil(bookingdata && bookingdata.user &&bookingdata.user.etiquetteAverage)}
                                        //suitabilityCount={bookingdata && bookingdata.user && bookingdata.user.reliabilityAverage === 0 ? 0 : Math.ceil(bookingdata && bookingdata.user && bookingdata.user.suitabilityAverage)}

                                        ratingPoint={bookingdata && bookingdata.user.review_rating_ginipig}
                                    />
                                </div>
                            </div>

                            <div className="reason_box mt-40">
                                <h3 className="reason_title mb-16">The reason you declined the booking</h3>

                                <div className="form-wrp reason-txt">
                                    {
                                        formDatadecline && formDatadecline.map((id, index) => {
                                            if (id === 1) {
                                                return <p>Appointment has now been filled</p>
                                            }
                                            else if (id === 2) {
                                                return <p>Appointment now taken, others appointments available</p>
                                            } else if (id === 3) {
                                                return <p>GiniPig didn’t fulfil the requirements</p>
                                            } else if (id === 4) {
                                                return <p>This date and time is no longer available</p>
                                                // return <p>This date/ and time is no longer available</p>
                                            } else if (id === 5) {
                                                return <p>Other</p>
                                            }
                                        })
                                    }
                                    {/* {
                                           location.formData.is_togglechangereason1===true &&
                                           <p>This GiniPig is not suitable for this particular treatment</p>
                                       }
                                        {
                                           location.formData.is_togglechangereason2===true &&
                                           <p>This appointment has been filled </p>
                                       }
                                        {
                                           location.formData.is_togglechangereason3===true &&
                                           <p>I am no longer able to do this time/date </p>
                                       } */}
                                </ div>

                            </div>
                            <div className="treatment btn-another text-center pt-110 pb-32 ">
                                <button className="addtreatmentbtns" onClick={__redirectProfile}> My Account </button>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default RequestDecline;