import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";

import { Container, Row, Col } from "react-bootstrap";

import { LeftArrow, EyeView, iconDelete_peach } from "../../config/images";

import FromSideBarSlider from '../SideBarSlider/FromSideBarSlider';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// redux
import { registerAPI_Action, resetRegisterAPI_Action, checkemailExistsAPI_Action, ginipigSignupSaveData_Action, resetcheckemailExistsAPI_Action } from '../../stores/actions'
import { setLocalStorageData, clearLocalStorage, getLocalStorageData } from '../../utils/storage'
import { COMPANYCATEGORYLIST_FAILURE } from "../../stores/types";

const initialFormData = Object.freeze({
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  password_confirmation: "",
  profile_pic: "",
  file: "",
  telephone_no: "",
  is_checked_terms_condition: false,
  is_checked_email_message: false,
  is_accept_notify_ginipig: false,
  is_checked_marketing: "",
});

const initialErrorData = Object.freeze({
  email_error: "",
  email_correct: false,
  firstname_error: "",
  lastname_error: "",
  password_error: "",
  phonenumber_error: "",
  is_form_valid: false,
});
let validate_errors = {};
const Register = (props) => {
  //state define
  const [passwordType, setPasswordType] = useState("password");
  const [eyeIcon, setEyeIcon] = useState(true);

  const __onChangePasswordToText = (e) => {
    setEyeIcon(!eyeIcon);
    setPasswordType(passwordType === "password" ? "text" : "password");
  };

  const [formData, updateFormData] = useState(initialFormData);
  const [errorData, updateErrorData] = useState(initialErrorData);
  let errors = {};
  const dispatch = useDispatch();
  let history = useHistory();


  // states
  const registerData = useSelector(state => state.register);
  const ginipigRegisterData = useSelector(state => state.ginipigregister);

  const Data = JSON.stringify(registerData);
  const loginUserData = useSelector(state => state.login);
  const checkemailaexistsData = useSelector(state => state.checkemailaexists);

  // Use effects
  useEffect(() => {
    const loginToken = loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token : "" : "";
    if (loginToken !== '') {
      history.goBack();
    }

    if (ginipigRegisterData.ginipigregisterdata !== null) {

      updateFormData({
        first_name: ginipigRegisterData.ginipigregisterdata.first_name,
        last_name: ginipigRegisterData.ginipigregisterdata.last_name,
        email: ginipigRegisterData.ginipigregisterdata.email,
        password: ginipigRegisterData.ginipigregisterdata.password,
        password_confirmation: ginipigRegisterData.ginipigregisterdata.password_confirmation,
        profile_pic: ginipigRegisterData.ginipigregisterdata.profile_pic,
        file: ginipigRegisterData.ginipigregisterdata.file,
        telephone_no: ginipigRegisterData.ginipigregisterdata.telephone_no,
        is_checked_terms_condition: ginipigRegisterData.ginipigregisterdata.is_checked_terms_condition,
        is_checked_email_message: ginipigRegisterData.ginipigregisterdata.is_checked_email_message,
        is_accept_notify_ginipig: ginipigRegisterData.ginipigregisterdata.is_accept_notify_ginipig,
        is_checked_marketing: ginipigRegisterData.ginipigregisterdata.is_checked_marketing,

      }
      )
      updateErrorData({
        ...errorData,
        is_form_valid: true,
      });

      if (ginipigRegisterData.ginipigregisterdata.email) {
        __checkIsEmailExist(ginipigRegisterData.ginipigregisterdata)
      }
    }
    dispatch(resetRegisterAPI_Action());
  }, [ginipigRegisterData.ginipigregisterdata])


  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
    if (e.target.name === "first_name" && e.target.value !== "") {

      //if (e.target.value.match(/^[a-zA-Z]+$/)) {
      if (e.target.value.match(/^[a-zA-Z\-\s]+$/)) {
        updateErrorData({
          ...errorData,
          is_form_valid: true,
          firstname_error: ""
        })
      }
    }

    if (e.target.name === "last_name" && e.target.value !== "") {

      //if (e.target.value.match(/^[a-zA-Z]+$/)) {
      if (e.target.value.match(/^[a-zA-Z\-\s]+$/)) {
        updateErrorData({
          ...errorData,
          is_form_valid: true,
          lastname_error: ""
        })
      }
    }

    if (e.target.name === "password" && e.target.value !== "") {
      var decimal = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;

      if (e.target.value.match(decimal)) {
        updateErrorData({
          ...errorData,
          is_form_valid: false,
          password_error: ""
        });
      }
    }

    if (e.target.name === "telephone_no" && e.target.value !== "") {
      //console.log("inside tel")
      var decimal = /^[0][1-9]\d{9}$|^[1-9]\d{9}$/;
      if (e.target.value.match(decimal) && e.target.value.length === 11)
        updateErrorData({
          ...errorData,
          is_form_valid: true,
          phonenumber_error: "",
        });
    }
  };
  const checkMobileValidation = (e) => {
    const { id, value } = e.target;
    var decimal = /^[0][1-9]\d{9}$|^[1-9]\d{9}$/;

    if (!value) {
      updateErrorData({
        ...errorData,
        is_form_valid: false,
        phonenumber_error: "Phone Number Is Required.",
      });
    } else if (!value.match(decimal)) {
      updateErrorData({
        ...errorData,
        is_form_valid: false,
        phonenumber_error:
          "Phone number must start with 0 and must be 11 digits.",
      });
    } else if (value.length !== 11) {
      updateErrorData({
        ...errorData,
        is_form_valid: false,
        phonenumber_error:
          "Phone number must start with 0 and must be 11 digits.",
      });
    } else {
      updateErrorData({
        ...errorData,
        is_form_valid: true,
        phonenumber_error: "",
      });
    }
  };

  const checkPasswordValidation = (e) => {
    const { id, value } = e.target;
    // var decimal=  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/;
    var decimal = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;

    if (!value) {
      updateErrorData({
        ...errorData,
        is_form_valid: false,
        password_error: "Password Is Required."
      });
    } else if (!value.match(decimal)) {
      updateErrorData({
        ...errorData,
        is_form_valid: false,
        password_error: "Password must contain [8 to 20 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit."
      });
    } else {
      updateErrorData({
        ...errorData,
        is_form_valid: true,
        password_error: ""
      });
    }
  }

  const __checkIsEmailExist = useCallback(async (formDatanew) => {
    try {
      await dispatch(checkemailExistsAPI_Action(formDatanew));
    } catch (error) {
      console.error(" profile ::  profile() :: Exception ==", error);
    }
  });


  const checkEmailValidationkeyup = (e) => {
    const { value } = e.target;
    if (typeof value !== "undefined") {
      let lastAtPos = value.lastIndexOf('@');
      let lastDotPos = value.lastIndexOf('.');
      // if (value) {
      //   updateErrorData({
      //     ...errorData,
      //     is_form_valid: true,
      //     email_correct: false,
      //     email_error: "",
      //   });
      // }

      if ((lastAtPos < lastDotPos && lastAtPos > 0 && value.indexOf('@@') == -1 && lastDotPos > 2 && (value.length - lastDotPos) > 2)) {
        updateErrorData({
          ...errorData,
          is_form_valid: true,
          email_correct: true,
          email_error: ""
        });
        __checkIsEmailExist(formData);
      }

    }

  }
  const checkEmailValidation = async (e) => {
    if (registerData.isError === true) {
      dispatch(resetRegisterAPI_Action());
    }
    if (checkemailaexistsData.isError === true) {
      dispatch(resetcheckemailExistsAPI_Action());
    }
    const { value } = e.target;

    if (!value) {
      updateErrorData({
        ...errorData,
        is_form_valid: false,
        email_correct: false,
        email_error: "Email is Required.",
      });
    } else {
      updateErrorData({
        ...errorData,
        is_form_valid: true,
        email_correct: false,
        email_error: "",
      });
    }

    if (typeof value !== "undefined") {
      let lastAtPos = value.lastIndexOf("@");
      let lastDotPos = value.lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          value.indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          value.length - lastDotPos > 2
        )
      ) {
        if (!value) {
          updateErrorData({
            ...errorData,
            is_form_valid: false,
            email_correct: false,
            email_error: "Email is Required.",
          });
        } else {
          updateErrorData({
            ...errorData,
            is_form_valid: false,
            email_correct: false,
            email_error: "Email is not valid.",
          });
        }
      } else {
        let data = {
          email: formData.email
        };
        updateErrorData({
          ...errorData,
          is_form_valid: true,
          email_correct: true,
          email_error: ""
        });
        // let res = await checkemailExistsAPI_Action(formData);
        __checkIsEmailExist(formData);
        // updateErrorData({
        //   ...errorData,
        //   is_form_valid: true,
        //   email_error: "",
        // });
      }
    } else if (value.length > 50) {
      updateErrorData({
        ...errorData,
        is_form_valid: false,
        email_correct: false,
        email_error: "Only 50 Characters are Allowed.",
      });
    } else if (value.length < 6) {
      updateErrorData({
        ...errorData,
        is_form_valid: false,
        email_correct: false,
        email_error: "Minimum 6 Characters are Allowed.",
      });
    } else {
      updateErrorData({
        ...errorData,
        is_form_valid: true,
        email_correct: false,
        email_error: "",
      });
    }

    // if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(value)) {
    //     console.log("true");
    //     updateErrorData({
    //         ...errorData,
    //         email_error: ""
    //       });
    // }
    // else{

    //   updateErrorData({
    //     ...errorData,
    //     email_error: "Please Enter Correct Email:"
    //   });
    // }
    // console.log("Email Error: ",this.state.email_error);
  };

  const checkFirstNameValidation = (e) => {
    const { value } = e.target;
    if (!value) {
      updateErrorData({
        ...errorData,
        is_form_valid: false,
        firstname_error: "First Name Is Required.",
      });
    } else if (value.length > 50) {
      updateErrorData({
        ...errorData,
        is_form_valid: false,
        firstname_error: "Only 50 Characters are Allowed.",
      });
      //} else if (!value.match(/^[a-zA-Z]+$/)) {
    } else if (!value.match(/^[a-zA-Z\-\s]+$/)) {
      updateErrorData({
        ...errorData,
        is_form_valid: false,
        firstname_error: "Oops, you've added an incorrect character! Letters only ",
      });
    } else {
      updateErrorData({
        ...errorData,
        is_form_valid: true,
        firstname_error: "",
      });
    }
  };

  const checkLastNameValidation = (e) => {
    const { id, value } = e.target;
    if (!value) {
      updateErrorData({
        ...errorData,
        is_form_valid: false,
        lastname_error: "Last Name Is Required.",
      });
    } else if (value.length > 50) {
      updateErrorData({
        ...errorData,
        is_form_valid: false,
        lastname_error: "Only 50 Characters are Allowed.",
      });
      //} else if (!value.match(/^[a-zA-Z]+$/)) {
    } else if (!value.match(/^[a-zA-Z\-\s]+$/)) {
      updateErrorData({
        ...errorData,
        is_form_valid: false,
        lastname_error: "Oops, you've added an incorrect character! Letters only ",
      });
    } else {
      updateErrorData({
        ...errorData,
        is_form_valid: true,
        lastname_error: "",
      });
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files[0] != undefined) {
      updateFormData({
        ...formData,
        [e.target.name]: e.target.files[0],
        file: URL.createObjectURL(e.target.files[0]),
      });
    }
    dispatch(resetRegisterAPI_Action())

  };


  const __apiCall = useCallback(async () => {
    try {
      await dispatch(registerAPI_Action(formData));
    } catch (error) {
      console.error(" profile ::  profile() :: Exception ==", error);
    }
  });

  if (registerData.isSuccess === true) {
    // history.push("/login");
    // console.log('redirect issuccess is true');
    setLocalStorageData("loginData", registerData.registerdata.data)
    setLocalStorageData("loginDataUser", registerData.registerdata.data.user)
    clearLocalStorage("stripeCode");

    let bookCloseRequest = getLocalStorageData("book_close_request");
    // console.log("bookCloseRequest ", bookCloseRequest)
    let bookOpenRequest = getLocalStorageData("book_open_request");
    if (bookCloseRequest) {
      // console.log("bookCloseRequest1 ", bookCloseRequest)
      if (bookCloseRequest != null || bookCloseRequest != undefined) {

        if (registerData.registerdata.data.user.type !== 'practitioner') {
          var request = JSON.parse(bookCloseRequest)
          // console.log('request ', request)
          if (request.book_close_request === true) {
            window.scrollTo(0, 0);
            if (registerData.registerdata.data.user.profile_pic === '' || registerData.registerdata.data.user.profile_pic === undefined || registerData.registerdata.data.user.profile_pic === null) {
             var dataforphoto=JSON.parse(getLocalStorageData("dataforphoto"))
            //  console.log("dataforphoto",dataforphoto)
              window.scrollTo(0, 0);
              history.push('/update-profile-confirmation', { data: dataforphoto });
            } else
              if (request.photo_required === 1) {
                window.scrollTo(0, 0);
                history.push('photo-requirement/' + request.param_data)
              } else {
                window.scrollTo(0, 0);
                var today = new Date();
                var difference = today.getTime() - request.savedtime;
                var minutesDifference = Math.floor(difference / 1000 / 60);
                difference = minutesDifference
                if (difference < 10) {
                  history.push('view-booking/' + request.param_data)
                }
                else {
                  clearLocalStorage('book_close_request')
                  history.push("/ginipig-profile");
                }
              }
          }
          clearLocalStorage('book_close_request')
        } else {
          history.push("/ginipig-profile");
        }
      }
    } else if (bookOpenRequest) {
      if (bookOpenRequest != null || bookOpenRequest != undefined) {
        if (registerData.registerdata.data.user.type !== 'practitioner') {
          var request = JSON.parse(bookOpenRequest)

          if (request.book_open_request === true) {

            if (registerData.registerdata.data.user.profile_pic === '' || registerData.registerdata.data.user.profile_pic === undefined || registerData.registerdata.data.user.profile_pic === null) {
              var dataforphoto=JSON.parse(getLocalStorageData("dataforphoto"))
              window.scrollTo(0, 0);
              history.push('/update-profile-confirmation', { data: dataforphoto });
            } else {
              window.scrollTo(0, 0);
              var today = new Date();
              var difference = today.getTime() - request.savedtime;
              var minutesDifference = Math.floor(difference / 1000 / 60);
              difference = minutesDifference
              if (difference < 10) {
                history.push({
                  pathname: '/treatment-availability-open-request',
                  state: { 'paramData': request.param_data, 'selectedData': request.selectedData, 'treatmentData': request.treatmentData, fromlogin: true },
                });
              } else {
                clearLocalStorage('book_open_request')
                history.push("/ginipig-profile");
              }
            }


          }
         // clearLocalStorage('book_open_request')
        } else {
          window.scrollTo(0, 0);
          history.push("/ginipig-profile");
        }
      }
    } else {
      window.scrollTo(0, 0);
      history.push("/ginipig-profile");
    }

  }

  const __selectMarketingValue = (e, type) => {

    updateFormData({
      ...formData,
      is_checked_marketing: parseInt(e.target.value.trim()),

    });
  }



  const handleSubmit = (e) => {
    e.preventDefault();
    //   if(handleValidation()){
    //     alert("Form submitted");
    //  }else{
    //     alert("Form has errors.")
    //  }
    __apiCall();
  };


  const toggleChangeTerms_condition = (e) => {
    updateFormData({
      ...formData,
      is_checked_terms_condition: !formData.is_checked_terms_condition,
    });
  };

  const toggleChangeEmailMessage = (e) => {
    updateFormData({
      ...formData,
      is_checked_email_message: !formData.is_checked_email_message,
    });
    // dispatch(resetcheckemailExistsAPI_Action())
  };

  const toggleChangeNotifyMessage = (e) => {
    updateFormData({
      ...formData,
      is_accept_notify_ginipig: !formData.is_accept_notify_ginipig,
    });
  };


  const toggleChangeMarketing = (e) => {
    updateFormData({
      ...formData,
      is_checked_marketing: !formData.is_checked_marketing,
    });
  };




  const handleValidation = (e) => {
    let fields = formData;
    let formIsValid = true;
    //First Name
    if (!fields["first_name"]) {
      formIsValid = false;
      errors["first_name"] = "Cannot be empty";
    }

    if (typeof fields["first_name"] !== "undefined") {
      if (!fields["first_name"].match(/^[a-zA-Z]+$/)) {
        formIsValid = false;
        errors["first_name"] = "Only letters";
      }
    }

    //Last Name
    if (!fields["last_name"]) {
      formIsValid = false;
      errors["last_name"] = "Cannot be empty";
    }

    if (typeof fields["last_name"] !== "undefined") {
      if (!fields["last_name"].match(/^[a-zA-Z]+$/)) {
        formIsValid = false;
        errors["last_name"] = "Only letters";
      }
    }

    //Email
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "Cannot be empty";
    }

    if (typeof fields["email"] !== "undefined") {
      let lastAtPos = fields["email"].lastIndexOf("@");
      let lastDotPos = fields["email"].lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["email"].indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          fields["email"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] = "Email is not valid";
      }
    }
    validate_errors = errors;
    return formIsValid;
  };

  const __redirectUrl = (e) => {
    dispatch(resetRegisterAPI_Action());
    dispatch(resetcheckemailExistsAPI_Action());
    history.goBack()
  }

  const deleteImg = () => {
    updateFormData({
      ...formData,
      profile_pic: "",
      file: "",

    });

    dispatch(resetRegisterAPI_Action())

  }


  const __reDirectPage = async () => {
    window.scrollTo(0, 0);
    await dispatch(ginipigSignupSaveData_Action(formData));
  }



  return (
    <>
      <section className="bgcolor-gray registration">
        <Container>
          <Row>
            <Col className="col-lg-11 m-auto">
              <div className="navigationTop">
                <div className="heading-history">
                  <div className="back-history">
                    <button
                      type="button"
                      className="back-btn"
                      // onClick={() => history.goBack()}
                      onClick={() => {
                        __redirectUrl()
                      }}
                    >
                      {" "}
                      <img src={LeftArrow} alt="arrow" />{" "}
                    </button>
                  </div>
                  <div className="history-title">
                    <h2>GiniPig Registration </h2>
                  </div>
                </div>
                <div className="headingbox alignright mobile-hidden">
                  <div className="next-title">
                    <Link to="/signup-practitioner" className="navTopRegister">
                    Join Us</Link>
                    <div className="navTopRightArrow">
                      <img src={LeftArrow} alt="icon" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="registration-wrp">
                <form onSubmit={handleSubmit}>
                  <div className="ginipig-register register-wrp">
                    <div className="one box">
                      <div className="fromProfile profile-wrp">
                        <div className="profilefield">
                          <div className="showWithLabal">
                            <label className="profile-label">First name</label>
                            {errorData.firstname_error &&
                              errorData.firstname_error != "" ? (
                              <p className="error">
                                <p style={{ color: "red" }}>
                                  {errorData.firstname_error}{" "}
                                </p>
                              </p>
                            ) : null}

                            {registerData.registerdata &&
                              registerData.registerdata.hasOwnProperty(
                                "errors"
                              ) ? (
                              <p className="error">
                                {registerData.registerdata.errors.hasOwnProperty(
                                  "first_name"
                                ) ? (
                                  <p style={{ color: "red" }}>
                                    {
                                      registerData.registerdata.errors
                                        .first_name[0]
                                    }{" "}
                                  </p>
                                ) : null}
                              </p>
                            ) : null}
                          </div>

                          <input
                            type="text"
                            id="first_name"
                            name="first_name"
                            onChange={handleChange}
                            value={formData.first_name}
                            // onKeyUp={checkFirstNameValidation}
                            onBlur={checkFirstNameValidation}
                            autoComplete="off"
                            maxLength="50"
                            required
                          />
                        </div>
                        <div className="profilefield">
                          <div className="showWithLabal">
                            <label className="profile-label"> Last name </label>
                            {errorData.lastname_error &&
                              errorData.lastname_error != "" ? (
                              <p className="error">
                                <p style={{ color: "red" }}>
                                  {errorData.lastname_error}{" "}
                                </p>
                              </p>
                            ) : null}

                            {registerData.registerdata &&
                              registerData.registerdata.hasOwnProperty(
                                "errors"
                              ) ? (
                              <p className="error">
                                {registerData.registerdata.errors.hasOwnProperty(
                                  "last_name"
                                ) ? (
                                  <p style={{ color: "red" }}>
                                    {
                                      registerData.registerdata.errors
                                        .last_name[0]
                                    }{" "}
                                  </p>
                                ) : null}
                              </p>
                            ) : null}
                          </div>
                          <input
                            type="text"
                            id="last_name"
                            name="last_name"
                            value={formData.last_name}
                            onChange={handleChange}
                            // onKeyUp={checkLastNameValidation}
                            onBlur={checkLastNameValidation}
                            autoComplete="off"
                            maxLength="50"
                            required
                          />
                        </div>
                        <div className="profilefield">
                          <div className="showWithLabal">
                            <label className="profile-label">
                              Contact number
                            </label>
                            {errorData.phonenumber_error &&
                              errorData.phonenumber_error != "" ? (
                              <p className="error">
                                <p style={{ color: "red" }}>
                                  {errorData.phonenumber_error}{" "}
                                </p>
                              </p>
                            ) : null}

                            {registerData.registerdata &&
                              registerData.registerdata.hasOwnProperty(
                                "errors"
                              ) ? (
                              <p className="error">
                                {registerData.registerdata.errors.hasOwnProperty(
                                  "telephone_no"
                                ) ? (
                                  <p style={{ color: "red" }}>
                                    {
                                      registerData.registerdata.errors
                                        .telephone_no[0]
                                    }{" "}
                                  </p>
                                ) : null}
                              </p>
                            ) : null}
                          </div>

                          <input
                            type="number"
                            id="telephone_no"
                            name="telephone_no"
                            value={formData.telephone_no}
                            onChange={handleChange}
                            // onKeyUp={checkMobileValidation}
                            onBlur={checkMobileValidation}
                            required
                            minLength="10"
                            maxLength="11"
                          />
                        </div>
                        <div className="profilefield">
                          <div className="showWithLabal">
                            <label className="profile-label">
                              {" "}
                              Email address{" "}
                            </label>
                            {errorData.email_error &&
                              errorData.email_error != "" ? (
                              <p className="error">
                                <p style={{ color: "red" }}>
                                  {errorData.email_error}{" "}
                                </p>
                              </p>
                            ) : null}

                            {registerData.registerdata &&
                              registerData.registerdata.hasOwnProperty(
                                "errors"
                              ) ? (
                              <p className="error">
                                {registerData.registerdata.errors.hasOwnProperty(
                                  "email"
                                ) ? (
                                  <p style={{ color: "red" }}>
                                    {registerData.registerdata.errors.email[0]}{" "}
                                  </p>
                                ) : null}
                              </p>
                            ) : null}

                            {
                              checkemailaexistsData.isError == true && checkemailaexistsData.checkemailexistsdata.status_code != 200 && checkemailaexistsData.checkemailexistsdata.message != "" ? (
                                <p className="error">

                                  <p style={{ color: "red" }}>{checkemailaexistsData.checkemailexistsdata.message} </p>

                                </p>

                              ) : null
                            }
                          </div>
                          <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            onKeyUp={checkEmailValidationkeyup}
                            onBlur={checkEmailValidation}
                            autoComplete="off"
                            required
                            minLength="6"
                            maxLength="50"
                          />
                          {/* <span className="checkerroricon">
                          {errorData.email_error &&
                            errorData.email_error != "" ? (
                              <img src={iconClose} alt="icon" className="erroricon warning" style={{"display":"none"}}/>
                            ) : formData.email && formData.email!="" ? checkemailaexistsData.isError==false && errorData.email_correct === true ? (
                              <img src={iconCorrect} alt="icon" className="erroricon succusess" style={{"display":"block"}} />
                            ) : (
                              <img src={iconClose} alt="icon" className="erroricon warning" style={{"display":"none"}} />
                            ): (
                              <img src={iconClose} alt="icon" className="erroricon warning" style={{"display":"none"}} />
                            )}
                            
                            
                          </span> */}
                        </div>
                        <div className="profilefield">
                          <div className="showWithLabal">
                            <label className="profile-label"> Password </label>
                            {errorData.password_error &&
                              errorData.password_error != "" ? (
                              <p className="error">
                                <p style={{ color: "red" }}>
                                  {errorData.password_error}{" "}
                                </p>
                              </p>
                            ) : null}

                            {registerData.registerdata &&
                              registerData.registerdata.hasOwnProperty(
                                "errors"
                              ) ? (
                              <p className="error">
                                {registerData.registerdata.errors.hasOwnProperty(
                                  "password"
                                ) ? (
                                  <span style={{ color: "red" }}>
                                    {
                                      registerData.registerdata.errors
                                        .password[0]
                                    }{" "}
                                  </span>
                                ) : null}
                              </p>
                            ) : null}
                          </div>
                          <div className="addicon">
                            <input
                              type={passwordType}
                              id="password"
                              name="password"
                              value={formData.password}
                              onChange={handleChange}
                              // onKeyUp={checkPasswordValidation}
                              onBlur={checkPasswordValidation}
                              required
                              minLength="8"
                              maxLength="20"
                            />

                            <span
                              className="eyeicon"
                              onClick={__onChangePasswordToText}
                            >
                              <img
                                src={eyeIcon ? EyeView : EyeView}
                                alt="icon"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                    {/* end one box*/}
                    <div className="two box">
                      <div className="profile-wrp media-profile">
                        <div className="inside-media">
                          <div className="media-img">
                            {
                              formData.file != "" ? (
                                <>
                                  <img src={formData.file} className="form-control1" />
                                  <img src={iconDelete_peach} className="remove-icon" onClick={deleteImg} />
                                </>
                              ) : null
                            }


                            <div className="upload-area">

                              <div className="media-btns uploadbtn-postion">
                                <input
                                  type="file"
                                  className="form-control"
                                  id="profile_pic"
                                  name="profile_pic"
                                  onChange={handleFileChange}
                                />
                                <span className="upload-profile">{formData.file !== "" ? "Edit" : "Upload"}</span>
                              </div>
                            </div>
                          </div>
                          <div className="media-content">
                            <div className="media-decs">
                              <h3> Profile Picture </h3>
                              <p>Some Providers maybe needing a particular look or specific criteria! Therefore we would encourage you to put the most up to date picture of yourself to ensure perfect matching.</p>
                              {/* <p>
                                {" "}
                                You can skip this for now but will need a
                                profile photo before making a booking.{" "}
                              </p> */}
                            </div>
                          </div>
                        </div>

                        {registerData.registerdata &&
                          registerData.registerdata.hasOwnProperty("errors") ? (
                          <p className="error">
                            {registerData.registerdata.errors.hasOwnProperty(
                              "profile_pic"
                            ) ? (
                              <span style={{ color: "red" }}>
                                {
                                  registerData.registerdata.errors
                                    .profile_pic[0]
                                }{" "}
                              </span>
                            ) : null}
                          </p>
                        ) : null}
                      </div>
                    </div>{" "}
                    {/*end two*/}
                    <div className="three box">
                      <div className="profile-wrp checkmark-wrp">
                        <div className="check-wrp">
                          <div className="inside-checkmark">
                            <div className="checkbox-group">

                              <label htmlFor="check1">
                                <div className="checkinput">
                                  <input
                                    className="tnc"
                                    type="checkbox"
                                    id="check1"
                                    defaultChecked={formData.is_checked_terms_condition === true ? true : false}
                                    onChange={(e) => {
                                      toggleChangeTerms_condition(e)
                                    }}
                                    required
                                  />
                                </div>
                                <span className="checkbox-decs"> I agree to the <Link to="/tc" style={{ fontWeight: 700 }} onClick={__reDirectPage} className="plainbtn ">Terms & Conditions</Link>  and  <Link to="/privacy-policy" onClick={__reDirectPage} style={{ fontWeight: 700 }} className="plainbtn"> Privacy
                                  Policy </Link>  of GoGiniPig and confirm that I am 18 years or older  </span>
                              </label>
                            </div>

                            <div className="checkbox-group">

                              <label htmlFor="check2">
                                <div className="checkinput">
                                  <input
                                    className="tnc"
                                    type="checkbox"
                                    id="check2"
                                    defaultChecked={formData.is_checked_email_message === true ? true : false}
                                    onChange={(e) => {
                                      toggleChangeEmailMessage(e)
                                    }}
                                    required
                                  />
                                </div>
                                <span className="checkbox-decs"> I agree to being contacted and updated via email for appointment purposes. </span>
                              </label>
                            </div>
                            <div className="checkbox-group">
                              <label htmlFor="check3">
                                <div className="checkinput">
                                  <input
                                    className="tnc"
                                    type="checkbox"
                                    id="check3"
                                    defaultChecked={formData.is_accept_notify_ginipig === true ? true : false}
                                    onChange={(e) => {
                                      toggleChangeNotifyMessage(e)
                                    }}
                                  />
                                </div>
                                <span className="checkbox-decs"> I agree to being contacted via text by GoGiniPig for appointment purposes only.</span>
                              </label>
                            </div>

                            <div className="profilefield-checkbox mt-3">
                              <label className="profile-label-marketing">We’d love to send you exclusive offers and keep you up to date with the savviest treatments & news. We’ll always look after your details with respect & never sell them to other companies for marketing purposes. </label>
                              <div style={{ display: 'flex' }}>
                                <div className="check-tick">
                                  <input
                                    className="tnc"
                                    type="checkbox"
                                    id="practicing_saloon"
                                    name="practicing_saloon"
                                    onClick={(e) => __selectMarketingValue(e, "individual")}
                                    value={1}
                                    defaultChecked={formData.is_checked_marketing === 1 ? true : false}

                                  />
                                  <label htmlFor="practicing_saloon" className="checkbox-text-marketing">Yes, I'd like to hear from you</label>
                                </div>
                                <div className="check-tick">
                                  <input
                                    className="tnc"
                                    type="checkbox"
                                    id="practicing_saloon1"
                                    name="practicing_saloon"
                                    onClick={(e) => __selectMarketingValue(e, "individual")}
                                    value={0}
                                    defaultChecked={formData.is_checked_marketing === 0 ? true : false}

                                  />
                                  <label htmlFor="practicing_saloon1" className="checkbox-text-marketing">No, Not for me</label>
                                </div>
                              </div>
                              <div className="error-show">

                              </div>
                            </div>

                          </div>
                          <div className="alignbtn-signup">
                            {/* <button type="submit" className="signUpbtn" disabled={formData.is_checked_terms_condition===true && formData.is_checked_terms_condition ===true && errorData.is_form_valid === true ? false : true}> Sign up </button>  */}

                            {
                              registerData.isFetching ? <button className="signUpbtn" disabled={true}> Loading </button> : <button type="submit" className="signUpbtn" disabled={formData.is_checked_terms_condition === true && formData.is_checked_email_message === true && formData.is_accept_notify_ginipig === true && errorData.is_form_valid === true && registerData.isError === false && checkemailaexistsData.isError == false && checkemailaexistsData.checkemailexistsdata != null ? checkemailaexistsData.checkemailexistsdata.status_code == 200 && checkemailaexistsData.checkemailexistsdata.message !== "" && formData.is_checked_marketing !== "" ? false : true : true}> Sign up </button>
                            }
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                    {/*end three*/}
                    <div className="four box">
                      <div className="register-signup">
                        <FromSideBarSlider />
                      </div>
                    </div>{" "}
                    {/*end four*/}
                  </div>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Register;
