import React, { useCallback, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { LeftArrow } from '../../config/images';

import TreatmentRadioButton from '../../components/TreatmentRadioButton';
import LargeButton from '../../components/LargeButton';
import { getLocalStorageData,clearLocalStorage } from '../../utils/storage'

import { createtreatmentAPI_Action, createAppointmentData_Action, resetcreateAppointmentData_Action, resettreatmentaddreviewAPI_Action } from '../../stores/actions'

const initialErrorData = Object.freeze({
    treatment_title_error: "",
    treatment_description_error: "",
    treatment_sub_cat_error: "",

    treatment_description_error_active: "Description should be minimum 25 characters long.",

    is_form_valid_title: false,
    is_form_valid_sub_lenght: false,
    is_form_valid_desc: false,
    is_form_valid_sub_cat: false,
});

const TreatmentInformation = () => {
    let history = useHistory();
    const dispatch = useDispatch();
    const [character_count_title, setCharactercountTitle] = useState(65);


    // States Management
    const [formData, updateFormData] = useState(Object.freeze({

        title: "",
        description: "",
        page: 1,
        token: "",
        category_id: 0,
        sub_category_id: [],



    }));
    const [errorData, updateErrorData] = useState(initialErrorData);
 const [lenghtCatArray, setlenghtCatArray] = useState(1);
    const [catId, setCatId] = useState(0);
    const [SubCatlenghtError, setSubCatlenghtError] = useState(false);
    const [character_count, setCharactercount] = useState(500);
 const practitionercatData = useSelector(state => state.practitionercategory);
  const AppointmentCreateData = useSelector(state => state.appointmentcreatedata);

    useEffect(() => {
       

        if (AppointmentCreateData.appointmentcreatedata !== null) {
            let formDataNew = AppointmentCreateData.appointmentcreatedata
            dispatch(createAppointmentData_Action(formDataNew));
        }


        if (practitionercatData.practitionercategorydata !== null) {

            let arraylenght = practitionercatData.practitionercategorydata.data.user_category_data.length

            if (practitionercatData.practitionercategorydata.data.user_category_data[0].id !== null) {
                updateFormData({
                    ...formData,
                    category_id: practitionercatData.practitionercategorydata.data.user_category_data[0].id
                }, []);

            }


            setlenghtCatArray(arraylenght)
            setCatId(practitionercatData.practitionercategorydata.data.user_category_data[0].id)
        }
        let LocalDatatoken = getLocalStorageData("loginData")
        let registerData = getLocalStorageData("practitionersignupData")
        
        if (LocalDatatoken !== null && LocalDatatoken !== undefined) {

            updateFormData({
                ...formData,
                token: LocalDatatoken.token
            }, []);


        } else if (registerData && registerData && registerData.user !== "") {

            let token = registerData.token
            updateFormData({
                ...formData,
                token: token
            }, []);

        }
        else {
            window.scrollTo(0, 0);
            history.push('/login');
        }


    }, [])



    const __onTextChange = (e) => {

        updateFormData({
            ...formData,
            [e.target.name]: e.target.value,
            category_id: catId
        });


        if (e.target.name === "title" && e.target.value !== "") {
            setCharactercountTitle(65 - e.target.value.length)
        } else {
            setCharactercountTitle(65)
        }

        if (e.target.name === "description" && e.target.value !== "") {
            setCharactercount(500 - e.target.value.length)
        }
        if (e.target.name === "description" && e.target.value.length > 25) {
            updateErrorData({
                ...errorData,

                is_form_valid_desc: false,
                treatment_description_error: ""

            });
        }

        if (formData.page == lenghtCatArray) {


            updateFormData({
                ...formData,
                [e.target.name]: e.target.value,

            });


        }

    };


    const checkTreatmentTitleValidation = (e) => {
        const { value } = e.target;
        const title = value.trim();
        if (!title) {

            updateErrorData({
                ...errorData,
                is_form_valid_title: false,

                treatment_title_error: "Treatment title is required."
            });



        } else {
            updateErrorData({
                ...errorData,
                is_form_valid_title: true,
                is_form_valid_desc: true,
                treatment_title_error: ""
            });
        }
    }

    const __selectSubCategory = (e, category_id, id) => {
        // const {id,value} = e.target;
        let selectedBoxes = formData.sub_category_id;

        let index = selectedBoxes.indexOf(parseInt(id)) // check to see if the name is already stored in the array
        if (index === -1) {
            selectedBoxes.push(parseInt(id))
        } else {
            selectedBoxes.splice(index, 1)
        }

        if (selectedBoxes !== null) {
            if (selectedBoxes.length <= 3) {
                //setSubCatlenghtError(true)
                updateErrorData({
                    ...errorData,
                    is_form_valid_sub_cat: true,
                    treatment_sub_cat_error: ""
                });
            }
            else {
                //setSubCatlenghtError(false)
                updateErrorData({
                    ...errorData,
                    treatment_sub_cat_error: "(select up to 3)"
                });
            }

        }
        selectedBoxes = selectedBoxes.slice(0, 3);

        updateFormData({
            ...formData,
            sub_category_id: selectedBoxes,
            category_id: catId,
        });
        // updateErrorData({
        //     ...errorData,
        //     is_form_valid_sub_cat: true,
        //     treatment_sub_cat_error: ""
        // });
    }

    const checkDescValidation = (e) => {
        const { id, value } = e.target;
        const description = value.trim();
        if (!description) {

            updateErrorData({
                ...errorData,
                is_form_valid_desc: true,
                treatment_description_error: "Treatment description is required.",
            });


        } else if (description.length < 25) {
            updateErrorData({
                ...errorData,
                is_form_valid_desc: true,
                treatment_description_error: "Description should be minimum 25 characters long.",
            });
        } else if (description.length > 500) {
            updateErrorData({
                ...errorData,
                is_form_valid_desc: true,
                treatment_description_error: "Only 500 Characters are Allowed.",
            });
        } else {
            updateErrorData({
                ...errorData,
                is_form_valid_desc: false,
                treatment_description_error: ""
            });
        }


    };

    const saveAndAddAnotherClick = (e) => {
        e.preventDefault();
        if (errorData.is_form_valid_sub_cat === true && formData.description !== "" && formData.description.length > 25) {

            __apiCall();
            updateFormData({
                ...formData,
                sub_category_id: [],
                category_id: formData.category_id,
                title: "",
                description: "",

            });
            updateErrorData({
                ...errorData,
                is_form_valid_desc: false,
                is_form_valid_title: false,
                is_form_valid_sub_cat: false,
                treatment_sub_cat_error: "",
                treatment_title_error: "",
                treatment_description_error: ""
            });


        }
        else if (errorData.is_form_valid_sub_cat === false) {

            updateErrorData({
                ...errorData,
                is_form_valid_sub_cat: true,
                is_form_valid_desc: false,
                treatment_sub_cat_error: "Please Select Atleast 1 sub-category.",
            });
        }
        else if (formData.description == "") {
            updateErrorData({
                ...errorData,

                is_form_valid_desc: false,
                treatment_description_error: "Please add Treatment Description.",
            });
        }



    };

    const nextButtonClick = async (e) => {
        e.preventDefault();
        dispatch(resetcreateAppointmentData_Action())

        let lenghArray = practitionercatData.practitionercategorydata.data.user_category_data.length

        // console.log("out..",formData.description.length)
        // console.log("out..lenghArray",lenghArray)
        if (formData.page < lenghArray) {
            // console.log("in..")
            if (errorData.is_form_valid_sub_cat === true && formData.description !== "" && formData.description.length >= 25) {

                await __apiCall();
                let nextCatId = practitionercatData.practitionercategorydata.data.user_category_data[formData.page].id
                setCatId(nextCatId)

                if (formData.page < lenghArray) {
                    updateFormData({
                        ...formData,
                        sub_category_id: [],
                        // category_id: formData.category_id+1,
                        page: formData.page + 1,
                        title: "",
                        description: "",

                    });
                    updateErrorData({
                        ...errorData,
                        is_form_valid_desc: false,
                        is_form_valid_title: false,
                        is_form_valid_sub_cat: false,
                        treatment_sub_cat_error: "",
                        treatment_title_error: "",
                        treatment_description_error: ""
                    });
                    setSubCatlenghtError(false)
                    window.scrollTo(0, 0);
                }
                else
                    if (formData.page == lenghArray) {
                        updateErrorData({
                            ...errorData,
                            is_form_valid_desc: true,
                            is_form_valid_title: true,
                            is_form_valid_sub_cat: true,

                        });
                    }
            }
            else if (formData.title === "") {

                updateErrorData({
                    ...errorData,
                    is_form_valid_sub_cat: false,
                    is_form_valid_desc: false,
                    treatment_title_error: "Treatment title is required.",
                });
            }
            else if (errorData.is_form_valid_sub_cat === false) {

                updateErrorData({
                    ...errorData,
                    is_form_valid_sub_cat: false,
                    is_form_valid_desc: false,
                    treatment_sub_cat_error: "Please Select Atleast 1 sub-category.",
                });
            }
            else if (formData.description == "") {
                updateErrorData({
                    ...errorData,

                    is_form_valid_desc: false,
                    treatment_description_error: "Please add Treatment Description.",
                });
            }
        }
        else
            if (formData.page == lenghArray) {

                if (errorData.is_form_valid_title === false && errorData.is_form_valid_sub_cat === false) {
                    dispatch(resettreatmentaddreviewAPI_Action());
                    window.scrollTo(0, 0);
                    history.push('/review-treatment');

                } else if (errorData.is_form_valid_sub_cat === true && formData.description !== "" && formData.description.length >= 25) {
                    await __apiCall();
                    dispatch(resettreatmentaddreviewAPI_Action());
                    window.scrollTo(0, 0);
                    history.push('/review-treatment');

                } else if (errorData.is_form_valid_sub_cat === false) {

                    updateErrorData({
                        ...errorData,
                        is_form_valid_sub_cat: false,
                        is_form_valid_desc: false,
                        treatment_sub_cat_error: "Please Select Atleast 1 sub-category.",
                    });
                }
                else if (formData.description == "") {
                    updateErrorData({
                        ...errorData,

                        is_form_valid_desc: false,
                        treatment_description_error: "Please add Treatment Description.",
                    });
                }
            }
    }

    const skipCategoryClick = async (e) => {
        e.preventDefault();
        let lenghArray = practitionercatData.practitionercategorydata.data.user_category_data.length

        if (formData.page < lenghArray) {
            let nextCatId = practitionercatData.practitionercategorydata.data.user_category_data[formData.page].id
            setCatId(nextCatId)
            updateFormData({
                ...formData,
                sub_category_id: [],
                page: formData.page + 1,
                title: "",
                description: "",


            });
            updateErrorData({
                ...errorData,
                is_form_valid_sub_cat: false,
                is_form_valid_title: false,
                is_form_valid_desc: false,

            });
            window.scrollTo(0, 0);

        }
        if (formData.page === lenghArray) {
            if (errorData.is_form_valid_title === false && errorData.is_form_valid_sub_cat === false) {
                dispatch(resettreatmentaddreviewAPI_Action());
                window.scrollTo(0, 0);
                history.push('/review-treatment');

            } else if (errorData.is_form_valid_sub_cat === true && formData.description !== "" && formData.description.length > 25) {
                await __apiCall();
                dispatch(resettreatmentaddreviewAPI_Action());
                window.scrollTo(0, 0);
                history.push('/review-treatment');

            } else if (errorData.is_form_valid_sub_cat === false) {

                updateErrorData({
                    ...errorData,
                    is_form_valid_sub_cat: false,
                    is_form_valid_desc: false,
                    treatment_sub_cat_error: "Please Select Atleast 1 sub-category.",
                });
            }
            else if (formData.description == "") {
                updateErrorData({
                    ...errorData,

                    is_form_valid_desc: false,
                    treatment_description_error: "Please add Treatment Description.",
                });
            }
        }


    }



    const __apiCall = useCallback(async () => {
        try {
            // const loginToken = loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token : "" : "";

            let checkToken = "";

            let LocalDatatoken = getLocalStorageData("loginData")
            let registerData = getLocalStorageData("practitionersignupData")

            if (registerData != "" && registerData !== undefined && registerData !== null) {
                checkToken = registerData.token;
            }
            else if (LocalDatatoken !== "" && LocalDatatoken !== undefined && LocalDatatoken !== null) {
                checkToken = LocalDatatoken.token;
            }

            if (checkToken !== '') {
                await dispatch(createtreatmentAPI_Action(formData, checkToken));
            } else {
                window.scrollTo(0, 0);
                history.push('/login');
            }


            // await dispatch(createtreatmentAPI_Action(formData, loginToken));



        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    });



    
    //when ac suspend
    //useEffect(() => {
        if(practitionercatData && practitionercatData.practitionercategorydata &&  practitionercatData.practitionercategorydata.status_code===401){
           clearLocalStorage("loginData")
           clearLocalStorage("practitionersignupData")   
           dispatch(resetcreateAppointmentData_Action());
           dispatch(resettreatmentaddreviewAPI_Action());
           window.scrollTo(0, 0);
           
          history.push("/login");
        }
    //});

    //end



    return (
        <>
            <section className="bgcolor-gray common-section treatmentInfopg pb80">
                <Container>
                    <Row>
                        <Col className="col-lg-11 m-auto">
                            <div className="navigationTop">
                                <div className="heading-history">
                                    <div className="back-history">
                                        <button type="button" onClick={() => history.goBack()} className="back-btn" >
                                            {" "}
                                            <img src={LeftArrow} alt="arrow" />{" "}
                                        </button>
                                    </div>
                                    <div className="history-title">
                                        <h2> Add to treatment skills    </h2>
                                    </div>
                                </div>
                            </div>
                            <div className="from-wrp mt-40">
                                <div className="treatment_info_step">
                                    <p className="steps-label-new mb-16">Enter all your talent under this category, don't worry these won't be listed as live appointments yet, we just want you to show off your progress and skills on your profile</p>
                                    <div className="align-skip">
                                    <div className="treatment-catogory ">
                                    <div className="category-list">
                                        {
                                            practitionercatData.practitionercategorydata &&practitionercatData.practitionercategorydata.data && practitionercatData.practitionercategorydata.data.user_category_data.map((item, index) => {
                                                //categoryData.categorylistdata&&categoryData.categorylistdata.data.categories.map((item, index) => {
                                                return (
                                                  
                                                        <TreatmentRadioButton
                                                            CategoryTitle={item.name}
                                                            id={item.id}
                                                            activeid={formData}
                                                            activeclassformdata={catId}

                                                        />

                                                  
                                                )

                                            })
                                        }
                                        </div>

                                    </div>
                                        
                                        <div className="skip-div">
                                            {practitionercatData.practitionercategorydata && practitionercatData.practitionercategorydata.data.user_category_data.length !== formData.page ?
                                                <button
                                                    className="skip_btn"

                                                    onClick={skipCategoryClick}>Skip category</button> :
                                                null
                                            }
                                        </div>
                                    
                                    </div>

                                        
                                </div>
                                <form>
                                    <div className="treatmentform form-wrp fromProfile mb-24 mt-24">

                                        <div className="profilefield">
                                            <div className="showwithlabel">
                                                <label className="profile-label">Treatment Title</label>
                                                {errorData.treatment_title_error &&
                                                    errorData.treatment_title_error !== "" ? (
                                                        <p className="error">
                                                            <p style={{ color: "red" }}>
                                                                {errorData.treatment_title_error}{" "}
                                                            </p>
                                                        </p>
                                                    ) : null}
                                            </div>
                                            <input
                                                type="text"
                                                value={formData.title}
                                                id="title"
                                                name="title"
                                                maxLength="65"
                                                onChange={__onTextChange}

                                                onBlur={checkTreatmentTitleValidation}
                                            />
                                             {
                                                character_count_title == 65 ?
                                                    <span className="char-span-title">{character_count_title} Character limit</span> :
                                                    <span className="char-span-title">{character_count_title} Remaining</span>
                                            }
                                        </div>
                                        <div className="category-box mb-24">
                                            <div className="insidebox">
                                                <div className="optional-errormsg">
                                                    <label className="profile-label">Choose the categories that apply to this treatment</label>
                                                    {errorData.treatment_sub_cat_error &&
                                                        errorData.treatment_sub_cat_error !== "" ? (
                                                            <p className="error">
                                                                <span style={{ color: "red" }}>
                                                                    {/* {errorData.treatment_sub_cat_error}{" "} */}
                                                                    {"(select up to 3)"}
                                                                </span>
                                                            </p>
                                                        ) : <p className="optiontext"> (select up to 3) </p>}
                                                        {/*) : null} */}
                                                    {/* {SubCatlenghtError ? (
                                                        <p className="error">
                                                            <p style={{ color: "red" }}>
                                                                {"You can select only three subcategories."}
                                                            </p>
                                                        </p>
                                                    ) : null}
                                                    <p className="optiontext">(select up to 3)</p> */}
                                                </div>
                                            </div>
                                            <div className="category-checkbox-grp">

                                                {
                                                    practitionercatData.practitionercategorydata && practitionercatData.practitionercategorydata.data.user_category_data[formData.page - 1].sub_categories.map((sub_item, sub_index) => {
                                                        return (
                                                           
                                                            <div className="checkbox-item">
                                                                <div className={formData.sub_category_id.indexOf(sub_item.id) !== -1 ? "checkbox-button sub-background" : "checkbox-button"} >
                                                                    <label className="checkox-label yes-checked">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="checkbox-btn"
                                                                            id={sub_item.id}
                                                                            name={sub_item.id}
                                                                            onClick={(e) => __selectSubCategory(e, catId, sub_item.id)}
                                                                            value={sub_item.id}
                                                                            checked={formData.sub_category_id.indexOf(sub_item.id) !== -1 ? true : false} />
                                                                        <span className="button-text">{sub_item.name}</span>
                                                                    </label>

                                                                </div>
                                                            </div>



                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>


                                        <div className="profilefield mb-0">
                                            <div className="showwithlabel">
                                                <label className="profile-label"> Treatment Description </label>
                                                {errorData.treatment_description_error &&
                                                    errorData.treatment_description_error !== "" ? (
                                                        <p className="error">
                                                            <span className="errortext">
                                                                {errorData.treatment_description_error}{" "}
                                                            </span>
                                                        </p>
                                                    ) : 
                                                    <p className="optiontext error">
                                                        {errorData.treatment_description_error_active}{" "}
                                                    </p>
                                                }
                                            </div>
                                            <div className="input-addicon addicontextarea" >
                                                <textarea
                                                    // placeholder="Character limit"
                                                    value={formData.description}
                                                    id="description"
                                                    name="description"
                                                    maxLength="500"
                                                    onChange={__onTextChange}
                                                    onBlur={checkDescValidation}
                                                    placeholder="Keep your wording relevant to this treatment, you might like to add more details about your level of expertise to make this appealing. If access is difficult for people with disabilities please remember to include this. Parking is a bonus if you have it, so don't forget to mention it!"
                                                    onFocus={(e) => e.target.placeholder = ""}

                                                />
                                                 {
                                                    character_count===500?
                                                    <span  className="char-span">{character_count} Character limit</span>:
                                                    <span  className="char-span">{character_count} Remaining</span>
                                                }
                                            </div>

                                        </div>
                                    </div>
                                    <div className="treatment btn-another pt24 mt24 border_top d-none">
                                        {/* client want to hide this button 28012022 */}
                                        {/* <button className="addtreatmentbtns"
                                            disabled={errorData.is_form_valid_title === false
                                                && formData.description === ""}

                                            onClick={saveAndAddAnotherClick}>Add to My Menu</button> */}
                                    </div>

                                    <div className="btn-align pd32 pt-110">
                                        
                                        <LargeButton
                                            ButtonType="button"
                                            ButtonText={practitionercatData.practitionercategorydata && practitionercatData.practitionercategorydata.data.user_category_data.length == formData.page ? "Save & Review" : "Next"}
                                            nextButtonClick={nextButtonClick}
                                        />

                                    </div>
                                </form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default TreatmentInformation;