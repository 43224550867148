import React , {useCallback,useEffect,useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {useHistory,useLocation} from 'react-router-dom';
import DoubleConfirmationPopup from '../../components/UIComponent/DoubleConfirmationPopup';
import { cancelbookingAPIGinipig_Action } from '../../stores/actions';
import { getLocalStorageData,clearLocalStorage } from '../../utils/storage'
import LoadingSpinner from "../../components/UIComponent/LoadingSpinner"
import Modal from 'react-modal';


const BookingCancellenationConfirmation = (props) =>{   

    let history = useHistory();
    const location=useLocation()
    const dispatch = useDispatch(); 
    var param_data = props.match.params.id;
    let loginTokenData=getLocalStorageData("loginData");
    const [isData, setIsData] = useState(false);
    const [modalIsOpen,setIsOpen] = React.useState(false);

    useEffect(() => {
        if(!location.paramData){
            setTimeout(() =>{
                setIsData(false)
                window.scrollTo(0, 0);
                history.push("/ginipig-profile");
            },2)
        }
        
      }, []);

    const customStyles = {
        overlay: {
            background: "#dee2e663" ,zIndex: 999999  },
        content : {
          top                   : '50%',
          left                  : '50%',
          right                 : 'auto',
          bottom                : 'auto',
          marginRight           : '-50%',
          transform             : 'translate(-50%, -50%)',
          border                 :"none",
          background              :"none"
        }
      };

    const handleClickYes = (e) => {       
        __apiCallCancel(e)
    }

    const __apiCallCancel = useCallback(async (e) => {
        e.preventDefault();
        openModal(e);
        try {            
            await dispatch(cancelbookingAPIGinipig_Action(param_data,loginTokenData.token));
            setIsData(true)
        } catch (error) {
            console.error("Exception ==", error);
        }
    });

    if(isData){       
        setTimeout(() =>{
            setIsData(false)
            window.scrollTo(0, 0);
            history.push("/ginipig-profile");
        },2)
    }

    const openModal = (e) => {
        e.preventDefault();
        setIsOpen(true);
     }

    const closeModal = (item) => {
        setIsOpen(false);
       
    }


    return (
        <>
            <div className="cancelconf_booking_confirmation">
                <DoubleConfirmationPopup 
                    subHeadingtext=" cancel your booking? "
                    buttonYesText="Yes, cancel the booking"
                    buttonNoText="No, do not cancel the booking"                    
                    handleClick={(e)=>handleClickYes(e)}
                />

                                <Modal
                                    isOpen={modalIsOpen}
                                    onRequestClose={closeModal}
                                    to
                                    style={customStyles}
                                    shouldCloseOnOverlayClick={false}
                                    contentLabel="Example Modal"
                                >                              
                                    <div>
                                        <LoadingSpinner/>
                                    </div>
                                  
                                </Modal>

            </div>  
        </>
    );
}

export default BookingCancellenationConfirmation ;