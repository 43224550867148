
import React, { useCallback, useState, useEffect } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import { LeftArrow, star, iconCase_Study, iconNearly_there, iconPhoto, reviewAvatar } from '../../config/images';
import  NavigationBreadcrumbs from '../../components/NavigationBreadcrumbs';
import {Link } from 'react-scroll';
import {  practitionerSignupSaveData_Action ,ginipigSignupSaveData_Action} from '../../stores/actions'

const PrivacyPolicy = (props) => {
    const practitionerRegisterData = useSelector(state => state.practitionerregister);
    const ginipigRegisterData = useSelector(state => state.ginipigregister);

    const dispatch = useDispatch();
    let history = useHistory();

    const __reDirectPage = () =>{
        window.scrollTo(0, 0);
    }
    
    const backBtnPressed= async ()=>{
        if (practitionerRegisterData.practitionerregisterdata !== null) {

            let formDataNew = {
              first_name: practitionerRegisterData.practitionerregisterdata.first_name,
              last_name: practitionerRegisterData.practitionerregisterdata.last_name,
              practitioner_category_apply: practitionerRegisterData.practitionerregisterdata.practitioner_category_apply,
              email: practitionerRegisterData.practitionerregisterdata.email,
              password: practitionerRegisterData.practitionerregisterdata.password,
              password_confirmation: practitionerRegisterData.practitionerregisterdata.password_confirmation,
              profile_pic: practitionerRegisterData.practitionerregisterdata.profile_pic,
              company_name: practitionerRegisterData.practitionerregisterdata.company_name,
              file: practitionerRegisterData.practitionerregisterdata.file
            }
            await dispatch(practitionerSignupSaveData_Action(formDataNew));
        }
        if (ginipigRegisterData.ginipigregisterdata !== null) {

            let formDataNew = {
                first_name: ginipigRegisterData.ginipigregisterdata.first_name,
                last_name: ginipigRegisterData.ginipigregisterdata.last_name,
                email: ginipigRegisterData.ginipigregisterdata.email,
                password:  ginipigRegisterData.ginipigregisterdata.password,
                password_confirmation:  ginipigRegisterData.ginipigregisterdata.password_confirmation,
                profile_pic:  ginipigRegisterData.ginipigregisterdata.profile_pic,
                file:  ginipigRegisterData.ginipigregisterdata.file,
                telephone_no:  ginipigRegisterData.ginipigregisterdata.telephone_no,
                is_checked_terms_condition:  ginipigRegisterData.ginipigregisterdata.is_checked_terms_condition,
                is_checked_email_message:  ginipigRegisterData.ginipigregisterdata.is_checked_email_message,
                is_accept_notify_ginipig:  ginipigRegisterData.ginipigregisterdata.is_accept_notify_ginipig,

            }
            await dispatch(ginipigSignupSaveData_Action(formDataNew));
        }
        history.goBack();
            
       
    }


    return (
        <>
            <section className="bgcolor-gray common-section open_req_booking_pg pb80">
                <Container>
                    <Row>
                        <Col className="col-lg-12 col-xl-11 m-auto">
                            
                        <div className="navigationTop padright-0">
                            <div className="heading-history">
                                <div className="back-history">
                                    <button type="button" onClick={()=>{backBtnPressed()}} className="back-btn" >
                                        {" "}
                                        <img src={LeftArrow} alt="arrow" />{" "}
                                    </button>
                                </div>
                                    <div className="history-title">
                                        <h2> Privacy Policy  </h2>
                                    </div>
                            </div>
                        </div>



                            <div className="requirement_wrp padright-0 mt-24">
                                    <div className="white_wrp">
                                        <div className="content-box">
                                            <p> Hello, welcome to the GoGiniPig Privacy Policy. At GoGiniPig (‘GGP’), we are committed to keeping your personal information safe and secure, whether you are a GiniPig or a Treatment Provider, and handling it in accordance with our legal obligations. This Privacy Policy sets out in detail the purposes for which we process your personal information, what rights you have in relation to that information, who we share it with and everything else we think is important for you to be aware of.</p>
                                            <p>Please make sure you check it carefully and if you do not agree with it, then (although we hate to turn you away) you should not use our digital products or services. This is because by accessing our digital products or services, you confirm that you accept the way in which we process your personal information. This Privacy Policy forms part of our Website <a href="/tc"  onClick={__reDirectPage}> Terms of Use </a>, and capitalised words and phrases in it have the same meaning as those in our Terms.</p>   
                                            <p> If you have any concerns, please feel free to contact us at hello@goginipig.com.  </p>                                


                                            <h3 className="heading"> About GGP </h3>
                                            <p>Go Ginipig Limited, trading as GoGinipig (referred to as <span className="bold"> Go Ginipig, GGP, we, our </span> or <span className="bold"> us </span> ) is a limited company incorporated in England and Wales, with registered company number 12201976 and registered at 1a Albany Road, Stratford-Upon-Avon, Warwickshire, CV37 6PG. We are the data controller for the purposes of the personal information processed in accordance with this Privacy Policy.  
                                            </p>
                                            <p> Our Data Protection Officer is responsible for our approach to data protection and protecting your privacy. You can contact our Data Protection Officer via email at: hello@goginipig.com. </p>
                                            <p><span className="bold">Contents of this Privacy Policy: </span></p>
                                             <ol className="olderlist">
                                                <li> <Link to="oneDiv" spy={true} smooth={true} offset={-65}> About this Privacy Policy </Link> </li>
                                                <li> <Link to="twoDiv" spy={true} smooth={true}  offset={-65}> The personal information we collect, how we collect it, and why </Link> </li>
                                                <li> <Link to="threeDiv" spy={true} smooth={true} offset={-65} > Our legal basis for processing personal information </Link> </li>
                                                <li> <Link to="fourDiv" spy={true} smooth={true} offset={-65}> When do we share your personal information? </Link> </li>
                                                <li> <Link to="fiveDiv" spy={true} smooth={true} offset={-65}> Communications </Link> </li>
                                                <li> <Link to="sixDiv" spy={true} smooth={true} offset={-65}> How long do we store your personal information? </Link> </li>
                                                <li> <Link to="sevenDiv" spy={true} smooth={true} offset={-65}> Security of your personal information </Link> </li>
                                                <li> <Link to="eightDiv" spy={true} smooth={true} offset={-65}> Links </Link> </li>
                                                <li> <Link to="nineDiv" spy={true} smooth={true} offset={-65}> Age Restrictions</Link> </li>
                                                <li> <Link to="tenDiv" spy={true} smooth={true} offset={-65}> Your rights and choices  </Link> </li>
                                                <li> <Link to="elevenDiv" spy={true} smooth={true} offset={-65}> Contacting us</Link> </li>
                                                <li> <Link to="tewelDiv" spy={true} smooth={true} offset={-65}> Browser and device local storage</Link> </li>
                                                <li> <Link to="ThirteenDiv" spy={true} smooth={true} offset={-65}> Cookies</Link> </li>
                                             </ol>
                                        
                                            <div id="oneDiv">
                                             <h3 className="heading"> 1.	About this Privacy Policy </h3>
                                                <p>This Privacy Policy applies to the personal information we collect about you through our digital products, by telephone, by post, through our social media platforms, from third parties and when you otherwise communicate with us. </p>
                                                <p>This Privacy Policy may change from time to time and, if it does, the up-to-date version will always be available on our  <Link to=""> website </Link>. </p>
                                            </div>
                                            <div id="twoDiv"> 
                                             <h3 className="heading"> 2.	The personal information we may collect, how we would collect it, and why </h3>

                                             <p>This section informs you of what information we may collect about you and why. </p>
                                             <p> Personal information means any information about an individual from which that individual can be identified. The following shows information we may process about you, and the purpose for which we would process that information. There may be more than one reason for which we would collect such information and we have only listed the main reasons. If you would like further information, please contact us via the email address above.   </p>
                                             <p>We have also included the legal basis on which we rely on to legitimately process your personal information. </p>
                                                <div className="tableDiv">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th> Information </th>
                                                        <th> The main reasons we would collect it </th>
                                                        <th> Legal basis for processing </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td colSpan="2"> Information you provide to us  </td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td> Name </td>
                                                        <td> Your name enables us to personally communicate with you, both through the platform, and externally in emails, SMS messages and other forms of communication.  </td>
                                                        <td> Performance of a contract with you. </td>
                                                    </tr>
                                                    <tr>
                                                        <td> <p> Email address </p> <p> Phone number</p> </td>
                                                        <td>  These are unique identifiers and allow us to personally communicate with you (e.g. sending marketing communications, service updates and send customer satisfaction surveys). Each one is always stored privately against your profile, so that only you and us can see it. </td>
                                                        <td> Performance of a contract with you. </td>
                                                    </tr>
                                                    <tr>
                                                        <td> City and country of residence Address or postal code  </td>
                                                        <td> To customise your experience and to ensure your browser and App settings are appropriately localised. Your address or postal code is always kept private. </td>
                                                        <td> Performance of a contract with you. </td>
                                                    </tr>
                                                    <tr>
                                                        <td> Date of birth  </td>
                                                        <td> To confirm that you are old enough to access our digital products and consent to the processing of your personal information (you must be 18 years of age to be a GiniPig or a Treatment Provider). We keep this information private. </td>
                                                        <td> Performance of a contract with you. </td>
                                                    </tr>
                                                    <tr>
                                                        <td> Gender </td>
                                                        <td> To populate your personal profile. This is optional and you do not need to tell us if you do not wish to.  </td>
                                                        <td>Necessary for our legitimate interests (to study how users use our services, to develop them and grow our business).</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Your preferences for receiving communications and notifications </td>
                                                        <td>We may store your preferences so we know exactly how to communicate with you (e.g. for marketing or sending service communications), and in some cases, how not to communicate with you.</td>
                                                        <td> Consent; and Necessary for our legitimate interests (to ensure that we are not at risk of breaching data protection laws by communicating with you where you have asked us not to). </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="2"> Information we collect automatically  </td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td> <p> Unique identifier </p> <p> Customer number </p>  </td>
                                                        <td> When a you sign up as either a GiniPig or a Treatment Provider, we generate this as a mechanism to identify you across our technical systems, and to link you with any applicable product preferences, service interaction analytics and customer service history.  </td>
                                                        <td> Necessary for our legitimate interests (to study how users use our services, to develop them and grow our business). </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Your interactions with our service</td>
                                                        <td> When you interact with our website or app, so that we can analyse how you are using our service and use that information to create improvements. </td>
                                                        <td> Necessary for our legitimate interests (to define types of users for our services, to keep the digital products updated and relevant, to develop our business and inform our marketing strategy). </td> 
                                                    </tr>

                                                </tbody>
                                            </table>
                                                </div>
                                            <p> We may also use all of the above information to establish, exercise and defend our legal rights. The lawful bases on which we rely upon to do such are where it is necessary for compliance with a legal obligation and where it is in our legitimate interest to establish, exercise or defend our legal rights.  </p>
                                            <p> In respect of all the above information, our overarching purpose is to enable us to generate a trusted, secure and engaged userbase. We want your information to be secure, but also visible to us so that we can provide a personalised customer service. For any questions or queries, please feel free to get in touch via the email address above.  </p>
                                           </div> 
                                            <div id="threeDiv">
                                            <h3 className="heading"> 3.	Our legal basis for processing personal information </h3>
                                            <p> We only ever use your information in line with applicable data protection laws - in particular, the EU General Data Protection Regulation <span className="bold"> (GDPR) </span>. In short, this means we only use it where we have a legal basis to do so. Under GDPR, the following are the general legal bases for which we process your personal information: </p>

                                            <ul>
                                                <li> <span className="bold">  Consent </span> - you have given us consent to process your personal information for a specific purpose that we have told you about. 
                                                </li>
                                                <li> <span className="bold"> Performance of our contract </span> - processing your personal information is necessary for a contract you have with us, or because we have asked you to take specific steps before entering into that contract. 
                                                </li>
                                                <li> <span className="bold">  Legitimate interests </span> - processing your personal information is necessary for our legitimate interests or those of a third party, provided those interests are not outweighed by your rights and interests (including where processing is required to comply with or enforce a legal obligation). 
                                                </li>
                                            </ul>
                                        </div>
                                            <div id="fourDiv">
                                            <h3 className="heading"> 4.	When do we share your personal information? </h3>
                                                <p>We may disclose your information for certain purposes and to third parties, as described below:</p>

                                                <ul>
                                                    <li> <span className="bold"> The GGP group of companies: </span>  we share your information within the GGP group of companies as required for: providing you with access to our services according to our agreement, data storage and processing, providing customer support, making internal choices around business improvements, content development, and for the other purposes set out in this Privacy Policy.</li>
                                                    <li> <span className="bold">  Third Party Providers: </span> We use certain companies, agents or contractors (Third Party Providers) to perform services on our behalf or to help deliver our services to you. We contract with Third Party Providers, for example to provide marketing, communications, infrastructure and IT services, to personalise and enhance our services, to provide customer service, to collect debts, to analyse and action data (including data about our users’ interactions with our digital products), and to process and administer consumer surveys. In the course of providing such services, these Third Party Providers may have access to your personal information. We do not authorise them to use or disclose your personal information except in connection with providing their services to us.</li>

                                                    <li>  <span className="bold"> Promotions with Our Providers: </span>  We may offer joint promotions, schemes or incentives with our selected partners that, in order for you to participate, will require us to share your information with the relevant partner. In fulfilling these types of promotions, we may share your name and other information in connection with fulfilling the relevant incentive. Please note that Our Providers are responsible for their own privacy and data protection methods and if applicable you should refer to their relevant privacy policy. </li>

                                                    <li>  <span className="bold"> To protect legitimate interests: </span>  There are certain circumstances where GGP and any of our third party providers may disclose and/or make use of your information where a disclosure would be necessary to: (a) satisfy any applicable law, regulation, legal process, or other legal or governmental request or requirement, (b) enforce applicable terms of use, including investigation of any actual or alleged breaches, (c) detect, prevent, or otherwise address illegal or suspected illegal activities (including fraud), security or technical issues, or (d) protect against harm to the rights, property or safety of GGP, members or the public, as required or permitted by law. </li>

                                                    <li> <span className="bold"> Transfers of our business: </span>  In connection with any corporate reorganisation, restructuring, merger or sale, or other transfer of assets, we will transfer information, including personal information, provided that the receiving party agrees to comply with our requirements as set out in this Privacy Policy relating to your personal information. </li>

                                                </ul>
                                            </div>

                                            <div id="fiveDiv">
                                                    <h3 className="heading"> 5.	Communications </h3>
                                                    <p>This section is to explain how we will ensure that you only receive communications that you wish to receive. </p>
                                                    <h3 className="heading"> Marketing communications: </h3>
                                                    <p> We want to ensure that you are informed and aware of the best services and promotions that we can offer you. By consenting to receive additional communications (by mail, telephone, text/picture/video message or email) from us and any named third parties that feature at the point of obtaining consent in respect of such information, we will process your personal information in accordance with this Privacy Policy. </p>

                                                    <p> You can change your marketing preferences and unsubscribe at any time by accessing the settings within our digital products. If you choose not to receive this information we will be unable to keep you informed of new services and promotions of ours, or the GGP group of companies, that may interest you.  </p>

                                                    <p>Whatever you choose, you will still receive other important information, for example service updates, as described below. </p>

                                                    <h3 className="heading"> Service communications: </h3>
                                                    <p> As detailed in the table at section 2 above, we may send you communications such as those which relate to any service updates (e.g. service availability) or provide customer satisfaction surveys. We consider that we can lawfully send these communications to you as we have a legitimate interest to do so, namely to effectively provide you with the best service we can and to grow our business. </p>
                                            </div>

                                            <div id="sixDiv">
                                                <h3 className="heading"> 6.	How long do we store your personal information? </h3>
                                                <p> This section explains the length of time that we will retain your personal information.  </p>
                                                <p>We keep your personal information for only as long as is necessary to provide you with our service and for our legitimate and necessary business purposes. Such purposes might include maintaining the high standards of service which we strive to uphold, making decisions on how to progress our offering, complying with applicable legal obligations, and resolving any disputes which arise in the course of our business. </p>
                                                <ul>
                                                    <p>In accordance with this Privacy Policy, you have the right to request that we delete your personal information, except where we are legally permitted or required to maintain certain personal information. For example:</p>
                                                    <li>If there is an unresolved issue relating to your account, for example relating to outstanding an unresolved dispute, then we will retain your personal information until the issue is resolved.</li>
                                                    <li>There may be other situations where we have legitimate business interests to retain personal information, such as to prevent fraud or protect security of our other users. </li>
                                                </ul>
                                                <p>Any third party providers that we engage will keep your personal information stored on their systems for as long as is necessary to provide the relevant services to you or us. If we end our relationship with any third party providers, we will make sure that they securely delete or return your personal information to us. </p>
                                                <p>We may retain personal information about you for statistical purposes. Where information is retained for statistical purposes it will always be anonymised, meaning that you will not be identifiable from that information.</p>
                                            </div>

                                            <div id="sevenDiv">
                                                    <h3 className="heading"> 7.	Security of your personal information </h3>
                                                    <p>This section explains how we keep your personal information safe and where it will be held. </p>
                                                    <p> We are committed to securing and protecting your personal information, and we make sure to implement appropriate technical and organisational measures to help protect the security of your personal information. We have implemented various policies including anonymisation, pseudonymisation, encryption, access, and retention policies to guard against unauthorised access and unnecessary retention of personal information in our systems.  </p>
                                                    <p>The information that we collect from you may be transferred to, and stored at, a destination outside of the European Economic Area (EEA). When we transfer and store your personal information outside of the EEA we will take steps to ensure that the information is transferred in accordance with this Privacy Policy and applicable data protection laws. In particular, we will ensure that appropriate contractual, technical, and organisational measures are in place with any parties outside the EEA such as the Standard Contractual Clauses approved by the EU Commission.</p>

                                                    <p>Unfortunately, the transmission of your personal information via the internet is not completely secure and although we do our best to protect your personal information, we cannot guarantee the security of your information transmitted to us over the internet and you acknowledge that any transmission is at your own risk. </p>
                                            </div>
                                            <div id="eightDiv">
                                                    <h3 className="heading"> 8.	Links</h3>
                                                    <p>This section explains the risks of clicking on any link within our digital products. </p>
                                                   
                                                    <p> Our digital products may, from time to time, contain links to websites operated by third parties, which will usually be those of our commercial partners. This Privacy Policy only applies to the personal information that we collect from you and we cannot be responsible for personal information collected and stored by third parties. If you click on a link, please understand that the relevant third party websites have their own terms and conditions and privacy policies, and we do not accept any responsibility for the content of those third party websites or third party terms and conditions or policies. Please check these policies before you submit any personal information to these websites.  </p>
                                            </div>

                                            <div id="nineDiv">
                                                    <h3 className="heading"> 9.	Age Restrictions </h3>
                                                    <p>You must be 18 years of age or older in order to be a GiniPig. You must be 16 years of age or older in order to be a Treatment Provider. </p>
                                            </div>

                                            <div id="tenDiv">
                                                    <h3 className="heading"> 10. Your rights and choices  </h3>
                                                    <p>This section explains that you have a number of rights in relation to your personal information. </p>

                                                    <ul>
                                                        <p>Under GDPR, as a user of our digital products, you are entitled to certain rights. There are circumstances in which your rights may not apply. You have the right to request that we:</p>

                                                        <li>provide you with a copy of the information we hold about you;</li>
                                                        <li>update any of your personal information if it is inaccurate or out of date; </li>
                                                        <li> delete the personal information we hold about you - if we are providing services to you and you ask us to delete personal information we hold about you then we may be unable to continue providing those services to you;  </li>
                                                        <li>restrict the way in which we process your personal information; </li>
                                                        <li>stop processing your data if you have valid objections to such processing; and</li>
                                                        <li> transfer your personal information to a third party.  </li>

                                                    </ul>

                                                        <p>For more information on your rights and how to use them, or if you would like to make any of the requests set out above, please contact us. </p>
                                                        <p>We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal information (or to exercise any of your other rights). This is a security measure to ensure that personal information is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response. </p>
                                                        <p>As explained in the section on Communications above, even if you consented to the processing of your personal information for marketing purposes (by ticking the relevant box or by requesting information about services), you have the right to ask us to stop processing your personal information for such purposes. You can exercise this right at any time by contacting us at hello@goginipig.com or adjusting your privacy and notification settings within the digital products. Please note that we reserve the right to charge a fee for responding to requests where we reasonably determine that they are manifestly unfounded or onerous or being made in bad faith.</p>
                                                        
                                            </div>

                                            <div id="elevenDiv">
                                                <h3 className="heading"> 11.	Contacting us  </h3>
                                                <p>If you have any questions or concerns about how we handle your personal information, please contact our Data Protection Officer (DPO), by email at hello@goginipig.com </p>
                                                <p> If you are unsatisfied with our response to any data protection issues you raise with us or our DPO, you have the right to make a complaint to the <a href="https://ico.org.uk/concerns/"  target="_blank"> Information Commissioner’s Office (ICO) </a> . The ICO is the authority in the UK which is tasked with the protection of personal information and privacy.   </p>
                                            </div>

                                            <div id="tewelDiv">
                                                <h3 className="heading"> 12.	Browser storage </h3>
                                                <p> Our digital products use browser storage files – the browser storage files are for if you are using the progressive web app. Browser storage files are files containing small amounts of information which are downloaded to your personal computer, mobile or other device when you visit an app or website. In particular, our browser storage files generate a unique identification number for each user which we use to collect certain information on that user.  </p>
                                                <p> We use browser storage files to store data locally on your device to enhance the user experience on our digital products. For example, browser storage files allows us to save the state of your current page so that when you return at a later time or refresh the browser or app, the page will remain the same. We may also use browser storage files to: (i) save a list of items a user last accessed on the digital products (such as events and properties the user selected) so we can display “recently viewed items”; or (ii) marking once a user has seen a tutorial, video, tooltip or onboarding flow so that the user only sees the content one time.   </p>
                                            </div>
                                            <div id="ThirteenDiv">
                                                <h3 className="heading"> 13.	Cookies </h3>
                                                <p> In common with other commercial websites and apps, we use standard technologies including cookies and similar tools to enhance your user experience, improve our site and provide tailored offers on GGP and other sites. </p>
                                               <p>Details of the cookies that we use on our App are listed below:</p>
                                               <p>Preferences: App will use local-storage for saving search filters & profile preference data.</p>
                                               <p>Security: App will use local-storage for storing. </p>
                                               <p>Processes: App will use local-storage for storing information at the time of treatment creation.</p>
                                               <p>Session State: App will use session state for storing tokens which will be used in API calls.</p>
                                               <p>Analytics: App will use google analytics.</p>

                                                 <p>Using cookies, we may collect the following data from you using the GGP Website:</p>       
                                                   <p>Your name and address. </p> 
                                                   <p>Your username and password. </p> 
                                                   <p>Your email address.</p> 
                                                   <p>Your Internet protocol (IP) address.  </p> 
                                                   <p>Your device and browser information. </p> 
                                                   <p>Your phone number.</p> 


                                            </div>


                                        </div>
                                    </div>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default PrivacyPolicy;