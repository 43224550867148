import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import Banner from './heroBanner/Banner'
import Benefitsection from './benefit/Benefitsection'
import WhoareGiniPig from './who/WhoArePig'
import CustomerSection from './CustomerReview/CustomerSection';
import CommunitySection from './Community/CommunitySection';
import { resetpractitionerSignupSaveAPI_Action } from '../../stores/actions'
const LandingPage = () => {
  const dispatch = useDispatch();
  // Use effects
  useEffect(() => {
    dispatch(resetpractitionerSignupSaveAPI_Action());
  }, []);

  let history = useHistory();
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0,0)
    }, 500)
  }, [history]);

  return (
    <>
      <Banner />
      <WhoareGiniPig />
      <Benefitsection />
      <CustomerSection />
      <CommunitySection />
    </>
  )

}

export default LandingPage;