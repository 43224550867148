import React, { useCallback, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { LeftArrow } from '../../config/images';
import TreatmentRadioButton from '../../components/TreatmentRadioButton';
import LargeButton from '../../components/LargeButton';
import { createtreatmentAPI_Action, treatmentaddreviewAPI_Action, categorylistAPI_Action, resettreatmentaddreviewAPI_Action } from '../../stores/actions'
import { getLocalStorageData, clearLocalStorage } from '../../utils/storage';

const initialErrorData = Object.freeze({
    treatment_title_error: "",
    treatment_description_error: "",
    treatment_description_error_active: "Description should be minimum 25 characters long.",
    treatment_sub_cat_error: "",


    is_form_valid_title: false,
    is_form_valid_sub_lenght: false,
    is_form_valid_desc: false,
    is_form_valid_sub_cat: false,
});


const TreatmentAddAnother = () => {
    let history = useHistory();
    const dispatch = useDispatch();


    // States Management
    const [formData, updateFormData] = useState(Object.freeze({

        title: "",
        description: "",
        page: 1,
        token: "",
        category_id: 1,
        sub_category_id: [],


    }));
    const [errorData, updateErrorData] = useState(initialErrorData);
    const [lenghtCatArray, setlenghtCatArray] = useState(1);
    const [character_count, setCharactercount] = useState(500);
    const [SubCatlenghtError, setSubCatlenghtError] = useState(false);
    const [character_count_title, setCharactercountTitle] = useState(65);

    const categoryData = useSelector(state => state.categorylist);
    const loginUserData = useSelector(state => state.login);
    const practitionersignupaddinfoData = useSelector(state => state.practitionersignupaddinfo);
    const LocalDatatoken = getLocalStorageData("loginData")
    useEffect(() => {
        let catId = 1

        let catIdnew = getLocalStorageData("treatmentcatId")
        if (catIdnew !== undefined) {
            catId = catIdnew
        }

        if (loginUserData.logindata !== null) {

            updateFormData({
                ...formData,
                token: loginUserData.logindata.data.token,
                page: catId,
                category_id: catId


            }, []);


        } else if (practitionersignupaddinfoData.practitionersignupaddinfodata && practitionersignupaddinfoData.practitionersignupaddinfodata.data && practitionersignupaddinfoData.practitionersignupaddinfodata.data.user != "") {

            let token = practitionersignupaddinfoData.practitionersignupaddinfodata.data.token
            updateFormData({
                ...formData,
                token: token,
                page: catId,
                category_id: catId

            }, []);

        } else if (LocalDatatoken !== "") {
            let token = LocalDatatoken.token
            updateFormData({
                ...formData,
                token: token,
                page: catId,
                category_id: catId

            }, []);
        }
        else {
            window.scrollTo(0, 0);
            history.push('/login');
        }
        if (categoryData.categorylistdata === null) {
            try {
                dispatch(categorylistAPI_Action());

            } catch (error) {
                console.error(" profile ::  profile() :: Exception ==", error);
            }
        }


    }, [])



    const __onTextChange = (e) => {
        const { id, value } = e.target;
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value,

        });

        if (e.target.name === "title" && e.target.value !== "") {
            setCharactercountTitle(65 - e.target.value.length)
        } else {
            setCharactercountTitle(65)
        }

        if (e.target.name === "description" && e.target.value !== "") {
            setCharactercount(500 - e.target.value.length)
        } else {
            setCharactercount(500)
        }
        if (e.target.name === "description" && e.target.value.length > 25) {
            updateErrorData({
                ...errorData,

                is_form_valid_desc: false,
                treatment_description_error: ""

            });
        }
        if (formData.page == lenghtCatArray) {

            updateFormData({
                ...formData,
                [e.target.name]: e.target.value,

            });


        }

    };


    const checkTreatmentTitleValidation = (e) => {
        const { id, value } = e.target;
        const title = value.trim();
        if (!title) {


            updateErrorData({
                ...errorData,
                is_form_valid_title: true,

                treatment_title_error: "Treatment title is required."
            });


        } else {
            updateErrorData({
                ...errorData,
                is_form_valid_title: false,
                // is_form_valid_desc: true,
                treatment_title_error: ""
            });
        }
    }

    const CategoryClick = (e, id) => {
        updateFormData({
            ...formData,
            category_id: id,
            page: id,
            // title: "",
            // description: "",
            sub_category_id: [],
            treatment_title_error: "",
            treatment_sub_cat_error: "",
            treatment_description_error: ""
        });


        updateErrorData({
            ...errorData,
            treatment_title_error: "",
            treatment_description_error: "",
            treatment_sub_cat_error: "",


            is_form_valid_title: false,
            is_form_valid_sub_lenght: false,
            is_form_valid_desc: false,
            is_form_valid_sub_cat: false,
        });
    }
    const __selectSubCategory = (e, id) => {
        // const {id,value} = e.target;
        let selectedBoxes = formData.sub_category_id;

        let index = selectedBoxes.indexOf(parseInt(id)) // check to see if the name is already stored in the array
        if (index === -1) {
            selectedBoxes.push(parseInt(id))
        } else {
            selectedBoxes.splice(index, 1)
        }

        if (selectedBoxes !== null) {
            if (selectedBoxes.length <= 3) {
                updateErrorData({
                    ...errorData,
                    is_form_valid_sub_cat: false,
                    treatment_sub_cat_error: ""
                });

            }
            else {
                updateErrorData({
                    ...errorData,
                    treatment_sub_cat_error: "(select up to 3)"

                });

            }

        }
        selectedBoxes = selectedBoxes.slice(0, 3);

        updateFormData({
            ...formData,
            sub_category_id: selectedBoxes,

        });


    }

    const checkDescValidation = (e) => {
        const { id, value } = e.target;
        const description = value.trim();
        if (!description) {

            updateErrorData({
                ...errorData,
                is_form_valid_desc: true,
                treatment_description_error: "Treatment description is required.",
            });
            setCharactercount(500)


        } else if (description.length < 25) {
            updateErrorData({
                ...errorData,
                is_form_valid_desc: true,
                treatment_description_error: "Description should be minimum 25 characters long.",
            });
        } else if (description.length > 500) {
            updateErrorData({
                ...errorData,
                is_form_valid_desc: true,
                treatment_description_error: "Only 500 Characters are Allowed.",
            });
        } else {

            updateErrorData({
                ...errorData,
                is_form_valid_desc: false,
                treatment_description_error: ""
            });
        }


    };

    const saveAndFinishClick = (e) => {
        e.preventDefault();
        if (formData.sub_category_id.length !== 0 && formData.description !== "" && formData.description.length >= 25) {

            __apiCall();
            updateFormData({
                ...formData,
                sub_category_id: [],
                category_id: formData.category_id,
                title: "",
                description: "",

            });
            updateErrorData({
                ...errorData,
                is_form_valid_desc: false,
                is_form_valid_title: false,
                is_form_valid_sub_cat: false,
                treatment_sub_cat_error: "",
                treatment_title_error: "",
                treatment_description_error: ""
            });
            let checkToken = "";
            const loginToken = loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token : "" : "";
            const RegisterToken = practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.token : "" : "";
            if (loginToken != "") {
                checkToken = loginToken;
            } else if (RegisterToken != "") {
                checkToken = RegisterToken;
            } else if (LocalDatatoken !== "") {
                checkToken = LocalDatatoken.token;
            }
            if (checkToken !== '') {
                dispatch(resettreatmentaddreviewAPI_Action());
                dispatch(treatmentaddreviewAPI_Action(checkToken));
                // clearLocalStorage("requestDataLocal")
                window.scrollTo(0, 0);
                history.push('/treatment-Menu');
            } else {
                history.push('/login');
            }



        }

        else if (formData.sub_category_id.length === 0) {
            window.scrollTo(0, 0)
            updateErrorData({
                ...errorData,

                is_form_valid_sub_cat: true,
                treatment_sub_cat_error: "Please Select Atleast 1 sub-category.",
            });
        }
        else if (formData.description == "") {
            updateErrorData({
                ...errorData,

                is_form_valid_desc: true,
                treatment_description_error: "Please add Treatment Description.",
            });
        }
        else if (formData.description.length < 25) {
            updateErrorData({
                ...errorData,

                is_form_valid_desc: true,
                treatment_description_error: "Description should be minimum 25 characters long.",
            });
        }




    };


    const __apiCall = useCallback(async () => {
        try {
            // const loginToken = loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token : "" : "";
            let checkToken = "";
            const loginToken = loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token : "" : "";
            const RegisterToken = practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.token : "" : "";
            if (loginToken != "") {
                checkToken = loginToken;
            } else if (RegisterToken != "") {
                checkToken = RegisterToken;
            } else if (LocalDatatoken !== "") {
                checkToken = LocalDatatoken.token;
            }
            if (checkToken !== '') {
                await dispatch(createtreatmentAPI_Action(formData, checkToken));
            } else {
                //  history.push('/login');
            }


            // await dispatch(createtreatmentAPI_Action(formData, loginToken));



        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    });

    //when ac suspend
    useEffect(() => {
        if (categoryData && categoryData.categorylistdata && categoryData.categorylistdata.status_code === 401) {
            clearLocalStorage("loginData")
            clearLocalStorage("practitionersignupData")
            // clearLocalStorage("requestDataLocal")

            dispatch(resettreatmentaddreviewAPI_Action());
            window.scrollTo(0, 0);

            history.push("/login");
        }
    });

    //end



    return (
        <>
            <section className="bgcolor-gray common-section icon_unselectbox treatment_add_another pb80">
                <Container>
                    <Row>
                        <Col className="col-lg-11 m-auto">
                            <div className="navigationTop">
                                <div className="heading-history">
                                    <div className="back-history">
                                        <button type="button" onClick={() => history.goBack()} className="back-btn" >
                                            {" "}
                                            <img src={LeftArrow} alt="arrow" />{" "}
                                        </button>
                                    </div>
                                    <div className="history-title">
                                        <h2> Treatment/Service Menu  </h2>
                                    </div>
                                </div>
                            </div>
                            <div className="from-wrp mt-40">
                                <div className="treatment_info_step">
                                    <p className="steps-label mb-16"> Categories offered </p>
                                    <div className="treatment-catogory">
                                        <div className="category-list">
                                            {

                                                categoryData.categorylistdata && categoryData.categorylistdata.data.categories.map((item, index) => {
                                                    return (

                                                        <TreatmentRadioButton
                                                            CategoryTitle={item.name}
                                                            CategoryClick={(e) => CategoryClick(e, item.id)}
                                                            id={item.id}
                                                            activeid={formData.category_id}
                                                            activeclassformdata={formData.page}

                                                        />


                                                    )

                                                })
                                            }
                                        </div>

                                    </div>
                                </div>
                                <form>
                                    <div className="treatmentform form-wrp fromProfile mb-24 mt-24">

                                        <div className="profilefield">
                                            <div className="showwithlabel">
                                                <label className="profile-label">Treatment Title</label>
                                                {errorData.treatment_title_error &&
                                                    errorData.treatment_title_error != "" ? (
                                                    <p className="error">
                                                        <p style={{ color: "red" }}>
                                                            {errorData.treatment_title_error}{" "}
                                                        </p>
                                                    </p>
                                                ) : null}

                                                {/* {createtreatmentData.createtreatmentdata &&
                                            createtreatmentData.createtreatmentdata.hasOwnProperty(
                                            "errors"
                                            ) ? (
                                            <p className="error">
                                                {createtreatmentData.createtreatmentdata.errors.hasOwnProperty(
                                                "title"
                                                ) ? (
                                                <p style={{ color: "red" }}>
                                                    {
                                                    createtreatmentData.createtreatmentdata.errors
                                                        .title[0]
                                                    }{" "}
                                                </p>
                                                ) : null}
                                            </p>
                                            ) : null} */}
                                            </div>
                                            <input
                                                type="text"
                                                value={formData.title}
                                                id="title"
                                                name="title"
                                                onChange={__onTextChange}
                                                maxlength="65"

                                                onBlur={checkTreatmentTitleValidation}
                                            />

                                            {
                                                character_count_title == 65 ?
                                                    <span className="char-span-title">{character_count_title} Character limit</span> :
                                                    <span className="char-span-title">{character_count_title} Remaining</span>
                                            }
                                        </div>
                                        <div className="category-box mb-24">
                                            <div className="insidebox">
                                                <div className="optional-errormsg">
                                                    <label className="profile-label"> Select Category </label>
                                                    {/* <label className="profile-label">Choose the categories that apply to this treatment</label> */}
                                                    {errorData.treatment_sub_cat_error &&
                                                        errorData.treatment_sub_cat_error != "" ? (
                                                        <p className="error">
                                                            <p className="errortext">
                                                                {"(select up to 3)"}
                                                            </p>
                                                        </p>
                                                    ) : <p className="optiontext"> (select up to 3) </p>}

                                                    {/* {createtreatmentData.createtreatmentdata &&
                                            createtreatmentData.createtreatmentdata.hasOwnProperty(
                                            "errors"
                                            ) ? (
                                            <p className="error">
                                                {createtreatmentData.createtreatmentdata.errors.hasOwnProperty(
                                                "sub_category_id"
                                                ) ? (
                                                <p style={{ color: "red" }}>
                                                    {
                                                    createtreatmentData.createtreatmentdata.errors
                                                        .sub_category_id[0]
                                                    }{" "}
                                                </p>
                                                ) : null}
                                            </p>
                                            ) : null} */}

                                                    {/* {SubCatlenghtError ? (
                                                        <p className="error">
                                                            <p className="errortext">
                                                                {"(select up to 3)"}
                                                            </p>
                                                        </p>
                                                    ) : <p className="optiontext">(select up to 3)</p>} */}

                                                </div>
                                            </div>
                                            <div className="category-checkbox-grp">

                                                {
                                                    categoryData.categorylistdata && categoryData.categorylistdata.data.categories[formData.category_id - 1].sub_categories.data.map((sub_item, sub_index) => {
                                                        return (

                                                            <div className="checkbox-item">
                                                                <div className={formData.sub_category_id.indexOf(sub_item.id) !== -1 ? "checkbox-button sub-background" : "checkbox-button"} >
                                                                    <label className="checkox-label yes-checked">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="checkbox-btn"
                                                                            id={sub_item.id}
                                                                            name={sub_item.id}
                                                                            onClick={(e) => __selectSubCategory(e, sub_item.id)}
                                                                            value={sub_item.id}
                                                                            checked={formData.sub_category_id.indexOf(sub_item.id) !== -1 ? true : false} />
                                                                        <span className="button-text">{sub_item.name}</span>
                                                                    </label>

                                                                </div>
                                                            </div>



                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>


                                        <div className="profilefield mb-0">
                                            <div className="showwithlabel">
                                                <label className="profile-label"> Treatment Description </label>
                                                {errorData.treatment_description_error &&
                                                    errorData.treatment_description_error != "" ? (
                                                    <p className="error">
                                                        <p className="errortext">
                                                            {errorData.treatment_description_error}{" "}
                                                        </p>
                                                    </p>
                                                ) :
                                                    <p className="error">
                                                        <p className="optiontext">
                                                            {errorData.treatment_description_error_active}{" "}
                                                        </p>
                                                    </p>}

                                                {/* {createtreatmentData.createtreatmentdata &&
                                            createtreatmentData.createtreatmentdata.hasOwnProperty(
                                            "errors"
                                            ) ? (
                                            <p className="error">
                                                {createtreatmentData.createtreatmentdata.errors.hasOwnProperty(
                                                "description"
                                                ) ? (
                                                <p style={{ color: "red" }}>
                                                    {
                                                    createtreatmentData.createtreatmentdata.errors
                                                        .description[0]
                                                    }{" "}
                                                </p>
                                                ) : null}
                                            </p>
                                            ) : null} */}
                                            </div>
                                            <div className="input-addicon addicontextarea" >
                                                <textarea
                                                    // placeholder="Character limit"
                                                    value={formData.description}
                                                    id="description"
                                                    name="description"
                                                    onChange={__onTextChange}
                                                    onBlur={checkDescValidation}
                                                    placeholder="Keep your wording relevant to this treatment, you might like to add more details about your level of expertise to make this appealing. If access is difficult for people with disabilities please remember to include this. Parking is a bonus if you have it, so don't forget to mention it!"
                                                    onFocus={(e) => e.target.placeholder = ""}
                                                    maxlength="500"
                                                />
                                                {
                                                    character_count === 500 ?
                                                        <span className="char-span">{character_count} Character limit</span> :
                                                        <span className="char-span">{character_count} Remaining</span>
                                                }

                                            </div>

                                        </div>
                                    </div>
                                    <div className="treatment_info_step">
                                        <p className="steps-label" style={{ fontWeight: '600' }}>By clicking Finish and add to menu, you are abiding by our T's & C's & confirming that this treatment or service does not require a general anaesthetic, is not dental related and that you have the required insurance.</p>
                                    </div>
                                    <div className="btn-align pd32 pt-32">
                                        <LargeButton
                                            ButtonType="button"
                                            ButtonText="Finish & add to menu"
                                            nextButtonClick={saveAndFinishClick}
                                            disabled={errorData.is_form_valid_title === true || formData.title === "" || errorData.is_form_valid_desc === true || errorData.is_form_valid_sub_cat === true
                                                || formData.description === "" || formData.description.length < 25}
                                        />

                                    </div>
                                </form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default TreatmentAddAnother;