import React, { useCallback, useState, useEffect } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import { LeftArrow, icon_whats_availablePNG, reviewAvatar, iconClose, iconPhoto, star, ginipic_avtar } from '../../config/images';
import UpcomingCardBooking from '../../components/UIComponent/UpcomingCardBooking'
import moment from 'moment'
import LoadingSpinner from "../../components/UIComponent/LoadingSpinner"
import { S3_BUCKET_LINK } from '../../config/ApiConstants'

import { practitionerrequestDetailsAPI_Action, resetpendingbookingDetailsAPI_Action, cancelreasonslistAPI_Action, resetdeclineenquiryAPI_Action, resetpractitionerrequestdetailsAPI_Action, resetopenrequestenquiryDetailsAPI_Action, resetacceptenquiryAPI_Action, resetacceptbookingAPI_Action, resetpractionerdeclinebookingAPI_Action, upcomingbookingAPI_Action, resetremoverequestforpractitionerprofileAPI_Action, resetupcomingbookingAPI_Action, openrequestbookingDetailsAPI_Action, declinereasonslistAPI_Action, pendingbookingDetailsAPI_Action } from '../../stores/actions'
//import { practitionerrequestDetailsAPI_Action, cancelreasonslistAPI_Action,resetpractitionerrequestdetailsAPI_Action, upcomingbookingAPI_Action, resetremoverequestforpractitionerprofileAPI_Action,resetupcomingbookingAPI_Action, openrequestbookingDetailsAPI_Action, declinereasonslistAPI_Action, pendingbookingDetailsAPI_Action, resetopenrequestenquiryDetailsAPI_Action } from '../../stores/actions'
import { clearLocalStorage, getLocalStorageData, setLocalStorageData } from '../../utils/storage'

import Modal from 'react-modal';

const customStyles = {
    overlay: {
        background: "#dee2e663", zIndex: 999999
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        border: "none",
        background: "none"
    }
};


const UpcomingPastBooking = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const [key, setKey] = useState('upcoming');
    const [upcomingBookingsprev, setupcomingBookingsprev] = useState([]);
    const [upcomingBookingsnext, setupcomingBookingsnext] = useState([]);
    const [pastBookings, setpastBookings] = useState([]);
    const [isApiCall, setIsApiCall] = useState(false);
    const [iswindowreload, setiswindowReload] = useState(location.iswindowreload);
    const [modalIsOpen, setIsOpen] = useState(false);

    const pendingbookingDetails = useSelector(state => state.pendingbookingdetails);

    const upcomingbookingData = useSelector(state => state.upcomingbookingdata);
    const loginUserData = useSelector(state => state.login);
    const practitionersignupaddinfoData = useSelector(state => state.practitionersignupaddinfo);


    // Use effects
    useEffect(() => {

        try {
            let checkToken = "";
            const loginToken = loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token : "" : "";
            const RegisterToken = practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.token : "" : "";
            let LocalDatatoken = getLocalStorageData("loginData")

            if (loginToken != "") {
                checkToken = loginToken;
            } else if (RegisterToken != "") {
                checkToken = RegisterToken;
            }
            else if (LocalDatatoken !== "") {
                checkToken = LocalDatatoken.token;
            }
            dispatch(resetacceptbookingAPI_Action());
            dispatch(resetpractionerdeclinebookingAPI_Action());
            dispatch(resetdeclineenquiryAPI_Action());
            dispatch(resetacceptenquiryAPI_Action());
            var tabkey = getLocalStorageData('tabkey');
            if (tabkey != undefined) {
                setKey(tabkey)
                clearLocalStorage('tabkey')
            }
            if (iswindowreload) {
                setiswindowReload(false)
                clearLocalStorage('iswindowReload')
                window.location.reload(false);
            }
            setLocalStorageData("confirmData", "")
            clearLocalStorage("confirmData")
            clearLocalStorage("openrequestdata")
            clearLocalStorage("closerequestdata")
            clearLocalStorage("noresponseData")
            if (checkToken !== '') {
                if (!isApiCall) {
                    dispatch(resetupcomingbookingAPI_Action());
                    dispatch(cancelreasonslistAPI_Action(checkToken));
                    dispatch(declinereasonslistAPI_Action(checkToken))
                    dispatch(upcomingbookingAPI_Action(checkToken));

                    setIsApiCall(true)
                }

                //  dispatch(upcomingbookingAPI_Action(checkToken));
            } else {
                window.scrollTo(0, 0);
                history.push('/login');
            }
            let bookingArraypast = []
            let nextArray = []
            let previousArray = []

            // console.log(upcomingbookingData.upcomingbookingdata && upcomingbookingData.upcomingbookingdata.data.upcomming_bookings)
            upcomingbookingData.upcomingbookingdata && upcomingbookingData.upcomingbookingdata.data.upcomming_bookings.map((item, index) => {
                let subCatArray = []
                item.treatment.treatment_sub_category_new.forEach(element => {
                    subCatArray.push(element.name)

                })
                var str;
                if (subCatArray.length === 1) {
                    str = subCatArray.join()
                } else if (subCatArray.length === 2) {

                    str = subCatArray.join(" & ")
                } else if (subCatArray.length === 3) {
                    str = subCatArray[0] + "," + subCatArray[1] + " & " + subCatArray[2]
                }
                let date = new Date(item.request_timeslots[0].date_time)
                var d = new Date(); // for now
                var h = d.getHours(); // => 9
                var m = d.getMinutes(); // =>  30
                var s = d.getSeconds(); // => 51
                var timeHMS = h + ":" + m + ":" + s;
                //console.log("time HMS: ", timeHMS);
                //console.log("request time: ", item.request_timeslots[0].start_time);

                //   console.log(date)
                //let isdateDifference = new Date(date.toDateString()) <= new Date(new Date().toDateString());

                //code change resolve safari issue
                let startDate = moment(item.request_timeslots[0].date_time, 'YYYY-MM-DD HH:mm:ss').toDate();
                let isdateDifference = startDate <= new Date(new Date().toDateString());
                //code change resolve safari issue End.

                let istimeDifference = false
                if (isdateDifference === true && item.type === 2) {
                    //console.log("into difference if");
                    istimeDifference = item.request_timeslots[0].start_time < timeHMS ? true : false;
                }

                //console.log("is time Difference:", istimeDifference);
                let newDate = ""
                let displayDate = item.request_timeslots[0].date_time

                if (displayDate !== null && displayDate !== undefined) {
                    let day = displayDate.substring(8, 10);
                    let displayDay = ""
                    if (day < 9) {
                        displayDay = day.substring(1, 2)
                    } else {
                        displayDay = day
                    }

                    // console.log(displayDate)
                    let n = displayDate.substring(5, 7)
                    // console.log(n)
                    let newn = ""
                    if (n < 10) {
                        newn = n.substring(1, 2)
                    } else {
                        newn = n
                    }

                    var month = new Array();
                    month[1] = "January";
                    month[2] = "February";
                    month[3] = "March";
                    month[4] = "April";
                    month[5] = "May";
                    month[6] = "June";
                    month[7] = "July";
                    month[8] = "August";
                    month[9] = "September";
                    month[10] = "October";
                    month[11] = "November";
                    month[12] = "December";

                    var displaymonth = month[newn];
                    // console.log(displayDay)

                    if (displayDay == 1) {
                        newDate = displayDay + "st" + " " + displaymonth + " "
                    } else if (displayDay == 2) {
                        newDate = displayDay + "nd" + " " + displaymonth + " "
                    } else if (displayDay == 3) {
                        newDate = displayDay + "rd" + " " + displaymonth + " "
                    } else {
                        newDate = displayDay + "th" + " " + displaymonth + " "
                    }
                }
                let period = ""
                let hours = ''
                let [sHours, minutes] = ""

                if (item.request_timeslots[0].start_time !== null) {

                    [sHours, minutes] = item.request_timeslots[0].start_time && item.request_timeslots[0].start_time.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
                    period = +sHours < 12 ? 'AM' : 'PM';
                    hours = +sHours % 12 || 12;
                }
                let timein24hrs = ""
                if (item.request_timeslots[0].start_time !== null && item.request_timeslots[0].start_time !== undefined) {
                    timein24hrs = item.request_timeslots[0].start_time.slice(0, 5)
                }
                let displaydate = newDate + new Date().getFullYear() + " @ " + timein24hrs
                let statusArray = []
                item.bookings.length !== 0 && item.bookings.map((sub_item, index) => {
                    statusArray.push(sub_item.status)
                })
                // console.log(statusArray)
                //  console.log(statusArray)
                // console.log(item.bookings && item.bookings.length)
                let itemobj = {
                    request_id: item.uuid,
                    status: statusArray,
                    sub_cat_array: item.treatment.title,
                    date: displaydate,
                    price: item.your_price,
                    user_name: item.bookings.length !== 0 && item.bookings[0].user.name,
                    profile_pic: item.bookings.length !== 0 && item.bookings[0].user.profile_pic,
                    ginipig_count: item.ginipig_count,
                    spaces_filled: item.bookings_count,
                    postcode: item.postcodes !== null ? item.postcodes.postcode : "Online",
                    city: item.postcodes !== null ? item.postcodes.city : "Online",
                    request_type: item.type,
                    bookinglenght: item.bookings && item.bookings.length

                }

                if (istimeDifference === true && item.type === 2 && item.bookings_count > 0) {
                    previousArray.push(itemobj)
                }
                if (istimeDifference === false) {
                    nextArray.push(itemobj)
                }

                // previousArray.sort(function(a,b){
                //     // Turn your strings into dates, and then subtract them
                //     // to get a value that is either negative, positive, or zero.
                //     return new Date(b.date.toDateString()) - new Date(a.date.toDateString());
                //   });

                setupcomingBookingsnext(nextArray)
                setupcomingBookingsprev(previousArray)

            })


            // let previousArray=[]
            // console.log(upcomingbookingData.upcomingbookingdata && upcomingbookingData.upcomingbookingdata.data.upcomming_bookings)
            upcomingbookingData.upcomingbookingdata && upcomingbookingData.upcomingbookingdata.data.confirm_booking.map((item, index) => {
                // console.log(item)
                let subCatArray = []
                item.request.treatment.treatment_sub_category_new.forEach(element => {
                    subCatArray.push(element.name)

                })
                var str;
                if (subCatArray.length === 1) {
                    str = subCatArray.join()
                } else if (subCatArray.length === 2) {

                    str = subCatArray.join(" & ")
                } else if (subCatArray.length === 3) {
                    str = subCatArray[0] + "," + subCatArray[1] + " & " + subCatArray[2]
                }
                let date = new Date(item.date)
                //console.log(date)
                let isdateDifference = new Date(date.toDateString()) < new Date(new Date().toDateString());
                //  console.log(isdateDifference)
                let newDate = ""
                let displayDate = item.date

                if (displayDate !== null && displayDate !== undefined) {
                    let day = displayDate.substring(8, 10);
                    let displayDay = ""
                    if (day < 9) {
                        displayDay = day.substring(1, 2)
                    } else {
                        displayDay = day
                    }

                    // console.log(displayDate)
                    let n = displayDate.substring(5, 7)
                    // console.log(n)
                    let newn = ""
                    if (n < 10) {
                        newn = n.substring(1, 2)
                    } else {
                        newn = n
                    }

                    var month = new Array();
                    month[1] = "January";
                    month[2] = "February";
                    month[3] = "March";
                    month[4] = "April";
                    month[5] = "May";
                    month[6] = "June";
                    month[7] = "July";
                    month[8] = "August";
                    month[9] = "September";
                    month[10] = "October";
                    month[11] = "November";
                    month[12] = "December";

                    var displaymonth = month[newn];
                    // console.log(displayDay)

                    if (displayDay == 1) {
                        newDate = displayDay + "st" + " " + displaymonth + " "
                    } else if (displayDay == 2) {
                        newDate = displayDay + "nd" + " " + displaymonth + " "
                    } else if (displayDay == 3) {
                        newDate = displayDay + "rd" + " " + displaymonth + " "
                    } else {
                        newDate = displayDay + "th" + " " + displaymonth + " "
                    }
                }

                const [sHours, minutes] = item.time.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
                const period = +sHours < 12 ? 'AM' : 'PM';
                const hours = +sHours % 12 || 12;

                let timein24hrs = ""
                if (item.time !== null && item.time !== undefined) {
                    timein24hrs = item.time.slice(0, 5)
                }


                let displaydate = newDate + new Date().getFullYear() + " @ " + timein24hrs
                //console.log(item.request.bookings && item.request.bookings.length)
                let itemobj = {
                    request_id: item.uuid,
                    status: item.status,
                    sub_cat_array: item.request.treatment.title,
                    date: displaydate,
                    price: item.request.your_price,
                    user_name: item.user.name,
                    profile_pic: item.user.profile_pic,
                    ginipig_count: item.request.ginipig_count,
                    spaces_filled: 1,
                    postcode: item.request.postcodes!==null && item.request.postcodes!==undefined? item.request.postcodes.postcode: "Online",
                    city:item.request.postcodes!==null && item.request.postcodes!==undefined? item.request.postcodes.city:"Online",
                    request_type: 1,
                    bookinglenght: 1

                }

                previousArray.push(itemobj)

                // console.log(previousArray)
                setupcomingBookingsprev(previousArray)
                //setupcomingBookingsnext(nextArray)
            })


            upcomingbookingData.upcomingbookingdata && upcomingbookingData.upcomingbookingdata.data.pastData.pastDataCloseRequest.map((item, index) => {

                // console.log(item)
                let subCatArray = []
                item.treatment.treatment_sub_category_new.forEach(element => {
                    subCatArray.push(element.name)

                })
                var str;
                if (subCatArray.length === 1) {
                    str = subCatArray.join()
                } else if (subCatArray.length === 2) {

                    str = subCatArray.join(" & ")
                } else if (subCatArray.length === 3) {
                    str = subCatArray[0] + "," + subCatArray[1] + " & " + subCatArray[2]
                }
                let date = new Date(item.request_timeslots[0].date_time)
                //console.log(date)
                let isdateDifference = new Date(date.toDateString()) < new Date(new Date().toDateString());
                //  console.log(isdateDifference)
                let newDate = ""
                let displayDate = item.request_timeslots[0].date_time

                if (displayDate !== null && displayDate !== undefined) {
                    let day = displayDate.substring(8, 10);
                    let displayDay = ""
                    if (day < 9) {
                        displayDay = day.substring(1, 2)
                    } else {
                        displayDay = day
                    }

                    // console.log(displayDate)
                    let n = displayDate.substring(5, 7)
                    // console.log(n)
                    let newn = ""
                    if (n < 10) {
                        newn = n.substring(1, 2)
                    } else {
                        newn = n
                    }

                    var month = new Array();
                    month[1] = "January";
                    month[2] = "February";
                    month[3] = "March";
                    month[4] = "April";
                    month[5] = "May";
                    month[6] = "June";
                    month[7] = "July";
                    month[8] = "August";
                    month[9] = "September";
                    month[10] = "October";
                    month[11] = "November";
                    month[12] = "December";

                    var displaymonth = month[newn];


                    if (displayDay == 1) {
                        newDate = displayDay + "st" + " " + displaymonth + " "
                    } else if (displayDay == 2) {
                        newDate = displayDay + "nd" + " " + displaymonth + " "
                    } else if (displayDay == 3) {
                        newDate = displayDay + "rd" + " " + displaymonth + " "
                    } else {
                        newDate = displayDay + "th" + " " + displaymonth + " "
                    }
                }

                let period = ""
                let hours = ''
                let [sHours, minutes] = ""
                //    console.log(item.request_timeslots[0].start_time)
                if (item.request_timeslots[0].start_time !== null) {
                    [sHours, minutes] = item.request_timeslots[0].start_time && item.request_timeslots[0].start_time.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
                    period = +sHours < 12 ? 'AM' : 'PM';
                    hours = +sHours % 12 || 12;
                }

                let timein24hrs = ""
                if (item.request_timeslots[0].start_time !== null && item.request_timeslots[0].start_time !== undefined) {
                    timein24hrs = item.request_timeslots[0].start_time.slice(0, 5)
                }
                let displaydate = newDate + new Date().getFullYear() + " @ " + timein24hrs
                // let isActiveStatus = 1;
                // item.bookings.map((userdbookings, ib)=>{
                //     if(userdbookings.user.status == 0)
                //         {
                //             isActiveStatus = 1;
                //         }
                // })
                let itemobj = {
                    request_id: item.uuid,
                    isReviewed: item.isReviewed,
                    status: item.status,
                    sub_cat_array: item.treatment.title,
                    date: displaydate,
                    price: item.your_price,
                    user_name: item.bookings.length !== 0 && item.bookings[0].user.name,
                    profile_pic: item.bookings.length !== 0 && item.bookings[0].user.profile_pic,
                    ginipig_count: item.ginipig_count,
                    spaces_filled: item.bookings_count,
                    postcode:item.postcodes!==null && item.postcodes!==undefined? item.postcodes.postcode:"Online",
                    city:item.postcodes!==null && item.postcodes!==undefined? item.postcodes.city:"Online",
                    request_type: 2,
                    bookinglenght: item.bookings_count && item.bookings_count,
                    user_status: item.bookings.length !== 0 && item.bookings[0].user.status,
                    // user_status: isActiveStatus,

                }
                //console.log(itemobj.bookinglenght)
                if (itemobj.spaces_filled > 0) {
                    bookingArraypast.push(itemobj)
                }


                setpastBookings(bookingArraypast)
            })

            //    console.log(upcomingbookingData.upcomingbookingdata && upcomingbookingData.upcomingbookingdata.data.pastData.pastDataOpenRequest)

            upcomingbookingData.upcomingbookingdata && upcomingbookingData.upcomingbookingdata.data.pastData.pastDataOpenRequest.map((item, index) => {
                if (item.request !== null) {


                    //  console.log( "item inside",item)
                    let subCatArray = []
                    item.request.treatment.treatment_sub_category_new.forEach(element => {
                        subCatArray.push(element.name)

                    })
                    var str;
                    if (subCatArray.length === 1) {
                        str = subCatArray.join()
                    } else if (subCatArray.length === 2) {

                        str = subCatArray.join(" & ")
                    } else if (subCatArray.length === 3) {
                        str = subCatArray[0] + "," + subCatArray[1] + " & " + subCatArray[2]
                    }
                    let date = new Date(item.date)
                    //console.log(date)
                    let isdateDifference = new Date(date.toDateString()) < new Date(new Date().toDateString());
                    //  console.log(isdateDifference)
                    let newDate = ""
                    let displayDate = item.date

                    if (displayDate !== null && displayDate !== undefined) {
                        let day = displayDate.substring(8, 10);
                        let displayDay = ""
                        if (day < 9) {
                            displayDay = day.substring(1, 2)
                        } else {
                            displayDay = day
                        }

                        // console.log(displayDate)
                        let n = displayDate.substring(5, 7)
                        // console.log(n)
                        let newn = ""
                        if (n < 10) {
                            newn = n.substring(1, 2)
                        } else {
                            newn = n
                        }

                        var month = new Array();
                        month[1] = "January";
                        month[2] = "February";
                        month[3] = "March";
                        month[4] = "April";
                        month[5] = "May";
                        month[6] = "June";
                        month[7] = "July";
                        month[8] = "August";
                        month[9] = "September";
                        month[10] = "October";
                        month[11] = "November";
                        month[12] = "December";

                        var displaymonth = month[newn];
                        //   console.log(displayDay)

                        if (displayDay == 1) {
                            newDate = displayDay + "st" + " " + displaymonth + " "
                        } else if (displayDay == 2) {
                            newDate = displayDay + "nd" + " " + displaymonth + " "
                        } else if (displayDay == 3) {
                            newDate = displayDay + "rd" + " " + displaymonth + " "
                        } else {
                            newDate = displayDay + "th" + " " + displaymonth + " "
                        }
                    }

                    const [sHours, minutes] = item.time.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
                    const period = +sHours < 12 ? 'AM' : 'PM';
                    const hours = +sHours % 12 || 12;

                    let timein24hrs = ""
                    if (item.time !== null && item.time !== undefined) {
                        timein24hrs = item.time.slice(0, 5)
                    }

                    let displaydate = newDate + new Date().getFullYear() + " @ " + timein24hrs
                    //console.log(item.request.bookings && item.request.bookings.length)
                    let itemobj = {
                        request_id: item.uuid,
                        status: item.status,
                        sub_cat_array: item.request.treatment.title,
                        date: displaydate,
                        price: item.request.your_price,
                        user_name: item.user.name,
                        profile_pic: item.user.profile_pic,
                        ginipig_count: item.request.ginipig_count,
                        spaces_filled: 1,
                        isReviewed: item.isReviewed,
                        postcode:item.request.postcodes!==null && item.request.postcodes!==undefined? item.request.postcodes.postcode:"Online",
                        city:item.request.postcodes!==null && item.request.postcodes!==undefined? item.request.postcodes.city:"Online",
                        request_type: 1,
                        bookinglenght: 1,
                        user_status: item.user.status,

                    }


                    bookingArraypast.push(itemobj)
                }
                // console.log(bookingArraypast,"bookingArraypast")
                setpastBookings(bookingArraypast)
            })


        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    }, [upcomingbookingData.upcomingbookingdata && upcomingbookingData.upcomingbookingdata.data])


    const _redirctreviewClick = (e, item) => {
        e.preventDefault();
        setLocalStorageData('tabkey', key)
        //console.log(item)
        _apiCallReview(item.request_id, item.request_type);
    }

    const _apiCallReview = useCallback(async (requestId, request_type) => {

        try {
            let checkToken = "";
            const loginToken = loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token : "" : "";
            const RegisterToken = practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.token : "" : "";
            let LocalDatatoken = getLocalStorageData("loginData")


            if (loginToken != "") {
                checkToken = loginToken;
            } else if (RegisterToken != "") {
                checkToken = RegisterToken;
            }
            else if (LocalDatatoken !== "") {
                checkToken = LocalDatatoken.token;
            }
            // console.log(spaces_fiiled)
            // setLocalStorageData("BookigCount", spaces_fiiled)

            if (request_type === 1) {
                let confirmData = {
                    requestType: 1,
                    requestId: requestId
                }
                setLocalStorageData("confirmData", confirmData)
                await dispatch(resetpendingbookingDetailsAPI_Action());
                window.scrollTo(0, 0);

                window.scrollTo(0, 0);
                history.push({
                    pathname: '/review-ginipig',
                });
            }

            else if (request_type === 2) {
                let confirmData = {
                    requestType: 2,
                    requestId: requestId
                }
                setLocalStorageData("confirmData", confirmData)
                await dispatch(resetpractitionerrequestdetailsAPI_Action());
                window.scrollTo(0, 0);

                window.scrollTo(0, 0);
                history.push({
                    pathname: '/review-ginipig',
                });
            }

        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    });

    if (upcomingbookingData.upcomingbookingdata && upcomingbookingData.upcomingbookingdata.status_code === 401) {
        dispatch(resetupcomingbookingAPI_Action());
        clearLocalStorage("loginData")
        clearLocalStorage("practitionersignupData")
        clearLocalStorage("RedirectPathName")
        clearLocalStorage("requestDataLocal")
        clearLocalStorage("confirmData")
        clearLocalStorage("openrequestdata")
        clearLocalStorage("closerequestdata")
        clearLocalStorage("noresponseData")
        window.scrollTo(0, 0);
        history.push('/login');
    }

    const __apiCall = useCallback(async (requestId, flag, bookinglenght, spaces_fiiled, request_type) => {

        try {
            let checkToken = "";
            const loginToken = loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token : "" : "";
            const RegisterToken = practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.token : "" : "";
            let LocalDatatoken = getLocalStorageData("loginData")


            if (loginToken != "") {
                checkToken = loginToken;
            } else if (RegisterToken != "") {
                checkToken = RegisterToken;
            }
            else if (LocalDatatoken !== "") {
                checkToken = LocalDatatoken.token;
            }
            //  console.log(spaces_fiiled)
            setLocalStorageData("BookigCount", spaces_fiiled)

            if (flag === "confirm" && request_type === 1) {
                let confirmData = {
                    requestType: 1,
                    requestId: requestId
                }
                setLocalStorageData("confirmData", confirmData)

                //await dispatch(pendingbookingDetailsAPI_Action(requestId, checkToken));
                window.scrollTo(0, 0);
                history.push({
                    pathname: '/confirm-appointment',

                });
            }
            else if (flag === "confirm" && request_type === 2) {
                let confirmData = {
                    requestType: 2,
                    requestId: requestId
                }
                setLocalStorageData("confirmData", confirmData)
                //await dispatch(practitionerrequestDetailsAPI_Action(requestId, checkToken));
                window.scrollTo(0, 0);
                history.push({
                    pathname: '/confirm-appointment',

                });
            } else if (bookinglenght === 0) {
                let noresponseData = {
                    requestId: requestId
                }
                setLocalStorageData("noresponseData", noresponseData)
                await dispatch(resetpractitionerrequestdetailsAPI_Action());
                //  window.scrollTo(0, 0);
                history.push({
                    pathname: '/request-no-response',

                });
            } else if (request_type === 1) {

                let openrequestdata = {
                    requestId: requestId,
                    request_type: 1

                }
                setLocalStorageData("openrequestdata", openrequestdata)
                await dispatch(resetopenrequestenquiryDetailsAPI_Action());
                dispatch(resetremoverequestforpractitionerprofileAPI_Action());
                window.scrollTo(0, 0);
                history.push({
                    pathname: '/open-request',
                    bookings_count: spaces_fiiled
                });
            }
            else if (request_type === 2) {
                let closerequestdata = {
                    requestId: requestId,
                    request_type: 2
                }
                setLocalStorageData("closerequestdata", closerequestdata)
                await dispatch(resetpractitionerrequestdetailsAPI_Action());
                window.scrollTo(0, 0);
                history.push({
                    pathname: '/pending-booking-request',
                    bookings_count: spaces_fiiled
                });
            }
            setIsOpen(false)

        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    });
    //   if(pendingbookingDetails.isSuccess===true){

    //   }

    const _PendinCardClick = (requestId, flag, bookinglenght, spaces_fiiled, request_type) => {

        setIsOpen(true)
        __apiCall(requestId, flag, bookinglenght, spaces_fiiled, request_type)
    }

    const goBackToAccount = () => {
        dispatch(resetupcomingbookingAPI_Action());
        window.scrollTo(0, 0);
        history.push('/practitioner-account')
    }
    //console.log(pastBookings)
    //console.log("s", upcomingBookingsprev)
    return (
        <>
            <section className="bgcolor-gray common-section upcoming_past_booking_pg pb80">
                <Container>
                    {
                        upcomingbookingData !== null && upcomingbookingData.isFetching ?
                            <div style={{ width: "100%", marginTop: 150, textAlign: 'center' }}>
                                <LoadingSpinner />
                                <p style={{ fontSize: 20, marginTop: 10 }}>Loading ...</p>
                            </div> :
                            <Row>
                                <Col className="col-lg-11 m-auto">
                                    <div className="navigationTop">
                                        <div className="heading-history">
                                            <div className="back-history">
                                                <button type="button" onClick={goBackToAccount} className="back-btn" >
                                                    {" "}
                                                    <img src={LeftArrow} alt="arrow" />{" "}
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="booking_upcoming_wrp mt-24 mob_tab">

                                        <Tabs
                                            transition={false}
                                            id="controlled-tab-example"
                                            activeKey={key}
                                            onSelect={(k) => setKey(k)}
                                        >
                                            <Tab eventKey="upcoming" title="Upcoming Bookings">
                                                {upcomingBookingsprev.length != 0 || upcomingBookingsnext.length != 0 ?
                                                    <div className="inside_tab_content">

                                                        <UpcomingCardBooking
                                                            bookingArrayprev={upcomingBookingsprev}
                                                            bookingArraynext={upcomingBookingsnext}
                                                            _PendinCardClick={_PendinCardClick}

                                                        />

                                                    </div> :
                                                    <div style={{ width: "100%", marginTop: 200, textAlign: 'center' }}>
                                                        <h3 style={{ fontSize: 20, marginTop: 10 }}>Nothing to see here yet, come back soon</h3>
                                                    </div>
                                                }
                                            </Tab>
                                            <Tab eventKey="past" title="Past Bookings">
                                                {  pastBookings.length !== 0 ?
                                                       <div className="inside_tab_content past_booking">

                                                       {
                                                           pastBookings.length !== 0 && pastBookings.map((item, index) => {
   
                                                               return (
                                                                   <div className="upcoming_cards mt-24">
                                                                       <div className="pad16 upside_content border_bottom">
                                                                           <div className="content_box">
   
                                                                               <p className="slot_booking">{item.date} </p>
   
                                                                               <p className="curreny_text">£{item.price && item.price.toFixed(2)}</p>
                                                                           </div>
                                                                       </div>
                                                                       <div className="pad024 downside_content">
                                                                           <div className="down_content">
                                                                               <div className="first-half">
                                                                                   <p className="sub_category_txt">{item.sub_cat_array}</p>
                                                                               </div>
                                                                               <div className="sec-half">
                                                                                   {
                                                                                       item.bookinglenght === 1 &&
                                                                                       <div className="username_box">
   
                                                                                           <div className="user_avatar">
                                                                                               {
                                                                                                   item.profile_pic !== null ? item.profile_pic !== "" ? (
   
                                                                                                       <img className="img_avtar" src={S3_BUCKET_LINK + item.profile_pic} />
   
                                                                                                   ) : <img src={ginipic_avtar} className="img_avtar" /> : <img src={ginipic_avtar} className="img_avtar" />
                                                                                               }
   
                                                                                           </div>
                                                                                           <div className="user_text">
                                                                                               <span>{item.user_name}</span>
                                                                                           </div>
                                                                                       </div>
   
                                                                                   }
   
                                                                                   {
                                                                                       item.bookinglenght > 1 &&
                                                                                       <div>
                                                                                           <p className="available-apoint"> <span>{item.bookinglenght} </span> GiniPigs </p>
                                                                                       </div>
                                                                                   }
   
                                                                                   {/* {item.isReviewed === 0  ?
                                                                                       <div className="confirm_box-wrp">
                                                                                           <button className="confirm_btns" onClick={(e) => _redirctreviewClick(e, item)}> Leave review </button>
                                                                                       </div> :
                                                                                       <div className="confirm_box-wrp">
                                                                                           <button className="confirm_btns reviewed_btn"> Reviewed </button>
                                                                                       </div>
                                                                                   } */}
   
                                                                                   {item.isReviewed === 0 && item.user_status === 1 && item.status !== 'incompleted' ?
                                                                                       <div className="confirm_box-wrp">
                                                                                           <button className="confirm_btns" onClick={(e) => _redirctreviewClick(e, item)}> Leave review </button>
                                                                                       </div> :
                                                                                       item.isReviewed === 0 && item.user_status === 0 && item.status !== 'incompleted' ?
                                                                                           <div className="confirm_box-wrp">
                                                                                               <button className="confirm_btns reviewed_btn"> Deleted </button>
                                                                                           </div> :
                                                                                           item.isReviewed === 0 && item.user_status === 2 && item.status !== 'incompleted' ?
                                                                                               <div className="confirm_box-wrp">
                                                                                                   <button className="confirm_btns reviewed_btn"> Suspended </button>
                                                                                               </div>
                                                                                               :
                                                                                               item.isReviewed === 0 && item.status === 'incompleted' ?
                                                                                                   <div className="confirm_box-wrp">
                                                                                                       <button className="confirm_btns reviewed_btn"> No Show </button>
                                                                                                   </div>
                                                                                                   :
                                                                                                   <div className="confirm_box-wrp">
                                                                                                       <button className="confirm_btns reviewed_btn"> Reviewed </button>
                                                                                                   </div>
                                                                                   }
                                                                               </div>
                                                                           </div>
                                                                       </div>
                                                                   </div>
                                                               )
                                                           })
                                                       }
   
                                                   </div>:
                                             <div style={{ width: "100%", marginTop: 200, textAlign: 'center' }}>
                                                 <h3 style={{ fontSize: 20, marginTop: 10 }}>Nothing to see here yet, come back soon</h3>
                                             </div>  
                                            }
                                               </Tab>
                                        </Tabs>
                                    </div>


                                </Col>
                            </Row>

                    }
                    <Modal
                        isOpen={modalIsOpen}
                        //onRequestClose={closeModal}
                        to
                        style={customStyles}
                        shouldCloseOnOverlayClick={false}
                        contentLabel="Example Modal"
                    >
                        <div>
                            <LoadingSpinner />
                        </div>

                    </Modal>
                </Container>
            </section>
        </>
    );
}

export default UpcomingPastBooking;