import React from "react";

const TreatmentRadioButton = (props) => {
  return (
    <>
      <div
      onClick={props.CategoryClick}
       className= {props.activeclassformdata === props.id ? "treatment-category-btn active_radiobtn disable_radiobtn" : "treatment-category-btn  disable_radiobtn" } >
        <label htmlFor={"steps"+props.id} className="radio-button">
          <div className={"stepicon step"+props.id} >
            <input className={props.screenName==="menu" ? "radio-tab-menu" :"radio-tab" }  type="radio" id={"steps"+props.id} />
          </div>
          <span className="radio-title">{props.CategoryTitle}</span>
        </label>
      </div>
    </>
  );
};

export default TreatmentRadioButton;
