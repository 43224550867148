import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS,AUTH_HEADERS_NEW } from '../../config/ApiConstants';
export function practitionerSignupAddInfoAPI_Action(data) {
    //console.log("formData ", data)

    const formData = new FormData(); 

     formData.append('practitioner_type', data.practitioner_type);
     formData.append('first_name', data.first_name);
     formData.append('last_name', data.last_name);
     formData.append('email', data.email);
     formData.append('password', data.password);
     formData.append('profile_pic', data.profile_pic);

     formData.append('company_name', data.company_name);
     formData.append('practitioner_category_apply', data.practitioner_category_apply);
     formData.append('company_category', data.company_category);
     formData.append('is_checked_upto_date_insurance', data.is_checked_upto_date_insurance === true ? 1 : 0);
     formData.append('is_checked_terms_condition', data.is_checked_terms_condition === true ? 1 : 0);
     formData.append('is_checked_email_message', data.is_checked_email_message === true ? 1 : 0);
     formData.append('is_checked_marketing', data.is_checked_marketing);

      formData.append('telephone_no', data.telephone_no);
      formData.append('postcode', data.postcode);
      //formData.append('postcodeValue', data.postcodeValue);
      formData.append('postcodeValue', data.street_name);
      formData.append('country', data.country);
      formData.append('city', data.city);
      formData.append('latitude', data.latitude);
      formData.append('longitude', data.longitude);
      for (var i = 0; i < data.sub_category.length; i++) {
        formData.append('sub_category[]', data.sub_category[i]);
    }

    //   formData.append('sub_category[]', data.sub_category);
      formData.append('practicing_saloon', data.practicing_saloon);
      formData.append('establishment_name', data.establishment_name);

      formData.append('flat', data.flat_build_no);

      
    
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/practitioner-signup`,
            method: 'POST',
            headers: {
                ...AUTH_HEADERS_NEW,
            },
            // body:JSON.stringify(data),
            body: formData,
            types: [
                types.PRACTITIONERSIGNUPADDINFO_REQUEST,
                types.PRACTITIONERSIGNUPADDINFO_RECEIVE, {
                    type: types.PRACTITIONERSIGNUPADDINFO_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}


export const resetPractitionerAddInfoAPI_Action = () => {
    return {
        type: types.RESET_PRACTITIONERSIGNUPADDINFO,
        payload: null
    }
};

export const practitionerStripeRegisterAPI_Action = (data,token) =>{
 
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/practitioner/update/stripe/details`,
            method: 'POST',
            headers: {
                ...AUTH_HEADERS,
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),
            types: [
                types.PRACTITIONERSIGNUPADDINFO_REQUEST,
                types.PRACTITIONERSIGNUPADDINFO_RECEIVE, {
                    type: types.PRACTITIONERSIGNUPADDINFO_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}

