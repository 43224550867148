import * as types from "../types/index";
import { setInitialState } from "../InitialState";


const INITIAL_STATE = setInitialState({
    practitionerrequestdetailsdata: null,
});


export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.PRACTITIONERREQUESTDETAILS_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case types.PRACTITIONERREQUESTDETAILS_RECEIVE:
            return {
                ...state,
                isFetching: false,
                isSuccess: true,
                practitionerrequestdetailsdata: action.payload.success
            };
        case types.PRACTITIONERREQUESTDETAILS_FAILURE:
            return {
                ...state,
                isFetching: false,
                isError: true,
                practitionerrequestdetailsdata: action.payload.response.error,
            };
        case types.RESET_PRACTITIONERREQUESTDETAILS:
            return {
                ...state,
                isFetching: false,
                isError: false,
                practitionerrequestdetailsdata: null,
            };
        default:
            return {
                ...state
            }
    }
}