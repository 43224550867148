import React from "react";
import { Link } from "react-router-dom";


const SliderContent = (props) => {
  const __reDirectPage = (e) => {
    window.scrollTo(0, 0);
  };



  return (
    <>
      <div className="sliderItem">
        <div className="sliderCircle">
        <img src={props.image} className="form-img1" ></img>
        </div>
        <div className="sliderContent">
          <h2>{props.title}</h2>
          <div className="sliderdecs">
            <p>

              {" "}
              {props.description}{" "}
            </p>
          </div>
          {/* <div className="alignSliderBtn">
            <Link to="/whoggp" onClick={__reDirectPage} className="sliderbtn">
              {" "}
              See more{" "}
            </Link>


          </div> */}
        </div>
      </div>
    </>
  );
};

export default SliderContent;
