import * as types from "../types/index";
import { setInitialState } from "../InitialState";


const INITIAL_STATE = setInitialState({
    cancelreasonslistdata: null,
});
// postcode,lang,lat,address

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.CANCELREASONSLIST_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case types.CANCELREASONSLIST_RECEIVE:
            return {
                ...state,
                isFetching: false,
                isSuccess: true,
                cancelreasonslistdata: action.payload.success
            };
        case types.CANCELREASONSLIST_FAILURE:
            return {
                ...state,
                isFetching: false,
                isError: true,
                cancelreasonslistdata: action.payload.response.error,
            };
        case types.RESET_CANCELREASONSLIST:
            return {
                ...state,
                isFetching: false,
                isError: false,
                cancelreasonslistdata: null,
            };
        default:
            return {
                ...state
            }
    }
}