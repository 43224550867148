import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";

// redux
import { createbookingAPI_Action } from '../../stores/actions'

const CreateBooking = (props) => {
  // Initialize hooks first
  const dispatch = useDispatch();
  let history = useHistory();

  // States Management
  const [formData, setFormData] = useState(Object.freeze({
    request_id:props.match.params.requestid,
    date:"",
    time:"",
}));

 // Selectors
 const createtBookingData = useSelector(state => state.createbooking);
 const loginUserData = useSelector(state => state.login);
 ////console.log("createtrequest Enquiry Data:",createtBookingData)
 const Data = JSON.stringify(createtBookingData);
 //console.log("login user data: ",loginUserData)
 const request_id=props.match.params.requestid;
  // Use effects 
  useEffect(() => {
    // if (createtBookingData.createbookingdata && createtBookingData.createbookingdata.status_code === 200) {
    //   history.push('/login');
    // }
  })
 

 const __onTextChange = (e) => {
  setFormData({
    ...formData,
    [e.target.name]: e.target.value.trim()
  });
};



  const __apiCall = useCallback(async () => {
    try {
      const loginToken=loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token: "" : "";
      // setFormData({
      //   ...formData,
      //   request_id:request_id,
      // });
      //console.log("before if of submit:")
      if(loginToken!=='')
      {
        //console.log("into login token");
        await dispatch(createbookingAPI_Action(formData,loginToken));
      }
    } catch (error) {
      console.error(" profile ::  profile() :: Exception ==", error);
    }
  });

const __submitBookingForm = (e) => {
  e.preventDefault();
  __apiCall();
};

  return (

    <div className="col-md-12 col-lg-12">
      <div className="col-md-6 col-lg-6">
    <form action="post" onSubmit={__submitBookingForm}>
                <h3>Create Booking</h3>
                
                <div className="form-group">
                    <label>Date</label>
                    <input type="text" className="form-control" id="date" name="date" onChange={__onTextChange} placeholder="Enter Date" />
                </div>

                <div className="form-group">
                    <label>Time</label>
                    <input type="text" className="form-control" id="time" name="time" onChange={__onTextChange} placeholder="Enter Time" />
                </div>
                <button type="submit" className="btn btn-primary btn-block"  >Submit</button>
                
            </form>

            <div>
              {/* <code>
              {
                  JSON.stringify(createtBookingData.isError)===true ? JSON.stringify(createtBookingData.createbookingdata.message):""
              }
            </code> */}
            {/* <label>{JSON.stringify(createtBookingData.isError)===true ? Data.createbookingdata.message : ""}</label> */}
            {/* <code>
              {
                  JSON.stringify(createtBookingData)
              }
            </code> */}
          </div>

            </div>
      </div>
  );
};

export default CreateBooking;