import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Container, Row, Col, Tab, Nav, Accordion } from 'react-bootstrap';
import { LeftArrow, practitioner_avtar, avatar, star, iconDelete, Icon_results, Icon_etiquette, Icon_professionalism, Icon_cleanliness, place_holder, Aesthetic, Hair, Beauty, wellness, AestheticTwo, BeautyTwo, HairTwo, wellnessTwo } from '../../config/images';
import AccordionContent from '../../components/UIComponent/AccordianContenForProfile';
import AccordionContentForPractitioner from '../../components/UIComponent/AccordionContentForPractitioner';
import AccordionContentForAppointment from '../../components/UIComponent/AccordionContentForAppointment';
import LargeButton from '../../components/LargeButton';
import Calendar from 'react-calendar';
import ReactPaginate from 'react-paginate';
import SlickSlider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from 'moment'
import { getLocalStorageData, setLocalStorageData, clearLocalStorage } from '../../utils/storage'
import { S3_BUCKET_LINK } from '../../config/ApiConstants'

import {
    treatmentaddreviewAPI_Action,
    practitionerProfileAPI_Action, PractionerportfoliolistAPI_Action, resetcreatetreatmentAPI_Action,
    ginipigPractitionerProfileAPI_Action, resetcreaterequestAPI_Action, resetimageUploadAPI_Action,
    treatmentDetailsAPI_Action, resetUserAPI_Action, resetgoginipigprofileAPI_Action, resetpractitionerProfileAPI_Action,
    resettreatmentaddreviewAPI_Action, resettreatmentdetailsAPI_Action, resetcreateAppointmentData_Action,
    resetPractitionerAddInfoAPI_Action, resetremoveTreatmentAPI_Action, resetedittreatmentAPI_Action, resetStripeAccountValidityAPI_Action
} from '../../stores/actions'
import ProfileReviewCard from '../../components/UIComponent/ProfileReviewCard';
import { formatPostcode } from '../../utils/utility';
import ReviewBox from '../../components/UIComponent/ReviewBox';

const PractitionerProfile = (props) => {
    //init state
    const { data, Setdata } = useState(
        Object.freeze({
            date: new Date(),
        })
    )

    const [profileImages, setProfileImages] = useState([]);

    let history = useHistory();

    const location = useLocation();
    // Initialize hooks first   
    const dispatch = useDispatch();
    const [isActive, setIsActive] = useState();

    const [tabpankey, setTabpankey] = useState(0);
    const [offset, setOffset] = useState(0);
    const [offsettreat, setOffsettreat] = useState(0);
    const [perPage, setPerpage] = useState(10);
    const [perpageTreatment, setPerpageTreatment] = useState(10);
    const [currentPage, setCurrentpage] = useState(0);
    const [currentPageTreatment, setcurrentPageTreatment] = useState(0);
    const [activetabwhats, setActivetabwhats] = useState(0);
    const [selected_date_time_array, setselected_date_time_array] = useState([]);

    const [iswindowreload, setiswindowReload] = useState(location.iswindowreloadacc);

    const [perPagereview, setPerpagereview] = useState(3);
    const [offsetreview, setOffsetreview] = useState(0);
    const [currentPagereview, setCurrentpagereview] = useState(0);
    // Selectors

    const treatmentaddreviewsData = useSelector(state => state.treatmentaddreviews);
    const userData = useSelector(state => state.userdetails);
    const loginUserData = useSelector(state => state.login);
    const practitionersignupaddinfoData = useSelector(state => state.practitionersignupaddinfo);
    const practitioneProfileData = useSelector(state => state.practitionerprofile);
    const practitionerData = practitioneProfileData.practitionerprofiledata && practitioneProfileData.practitionerprofiledata.data ? practitioneProfileData.practitionerprofiledata.data.profiledata : null;
    const AppointmentCreateData = useSelector(state => state.appointmentcreatedata);
    const [isClicked, setClicked] = useState(false);
    var param_data = props.location.state ? props.location.state.paramData : "";
    // console.log("practitionerData:-", practitionerData);
    //setLocalStorageData("loginDataUser",practitionersignupaddinfoData.practitionersignupaddinfodata.data.user)

    // console.log("logindata:-", loginUserData);

    const LocalDatatoken = getLocalStorageData("loginData")

    const __redirectUrl = (e, id) => {
        e.preventDefault();
        setLocalStorageData("treatmentcatId", id)
        // history.push('practitioner-profile');
        window.scrollTo(0, 0);
        history.push('/treatment-add-another/' + id);
    }

    const [formData, updateFormData] = useState(Object.freeze({

        selected_slots_morning: [],
        selected_slots_afternoon: [],
        selected_slots_evening: [],
        //selected_date_time_array: [],
        request_type: 1,
        whhats_available_pagination: 1,
        treatment_title: "Select a treatment",
        selected_date_slots: [],

        morningSlotsArray: ["7:00", "7:30", "8:00", "8:30", "9:00", "9:30", "10:00", "10:30", "11:00", "11:30"],
        afternoonSlotsArray: ["12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30"],
        eveningSlotsArray: ["17:00", "17:30", "18:00", "18:30", "19:00", "19:30", "20:00", "20:30", "21:00", "21:30"],

    }));



    const SampleNextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div className={className} style={{ ...style }} onClick={onClick}>
                {" "}
                <img src={LeftArrow} className="arrow_icons" alt="icon" />{" "}
            </div>
        );
    };

    const SamplePrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div className={className} style={{ ...style }} onClick={onClick}>
                {" "}
                <img src={LeftArrow} className="arrow_icons" alt="icon" />{" "}
            </div>
        );
    };
    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    arrows: true,
                    infinite: false,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };


    const __redirectToNextPage = (e) => {
        e.preventDefault();
        dispatch(resettreatmentaddreviewAPI_Action());
        window.scrollTo(0, 0);
        history.push('/treatment-select');
    }
    // Use effects
    useEffect(() => {

        try {
            let checkToken = "";

            let LocalDatatoken = getLocalStorageData("loginData")
            let registerData = getLocalStorageData("practitionersignupData")

            if (registerData != "" && registerData !== undefined && registerData !== null) {
                checkToken = registerData.token;
            }
            else if (LocalDatatoken !== "" && LocalDatatoken !== undefined && LocalDatatoken !== null) {
                checkToken = LocalDatatoken.token;
            }
            if (checkToken === '') {
                window.scrollTo(0, 0);
                history.push('/login');
            }
            clearLocalStorage("requestDataLocal")

            clearLocalStorage("RedirectPathName")
            clearLocalStorage("selectedtreatmentId")
            clearLocalStorage("pathnamereviewedit")
            clearLocalStorage("treatmentcatId")
            clearLocalStorage("isfromAccount")
            dispatch(resetcreaterequestAPI_Action());
            dispatch(resetcreatetreatmentAPI_Action());
            dispatch(resettreatmentdetailsAPI_Action());

            // window.location.reload(false);
            if (checkToken !== '') {
                if (param_data) {
                    dispatch(ginipigPractitionerProfileAPI_Action(param_data, checkToken))
                } else {
                    if (iswindowreload) {
                        window.location.reload(false);
                        setiswindowReload(false)

                    }
                    dispatch(practitionerProfileAPI_Action(checkToken));
                }
                dispatch(resetimageUploadAPI_Action())
                dispatch(resetcreateAppointmentData_Action());
                dispatch(resettreatmentdetailsAPI_Action());
                dispatch(resetcreaterequestAPI_Action());
                dispatch(resetcreatetreatmentAPI_Action());
                dispatch(resetedittreatmentAPI_Action());
            } else {
                history.push('/login');
            }

        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }

    }, [])

    if (practitioneProfileData.practitionerprofiledata && practitioneProfileData.practitionerprofiledata.status_code === 401) {
        dispatch(resetpractitionerProfileAPI_Action());
        clearLocalStorage("loginData")
        clearLocalStorage("practitionersignupData")
        clearLocalStorage("RedirectPathName")
        clearLocalStorage("requestDataLocal")
        history.push('/login');
    }

    if (practitionerData !== null && practitioneProfileData.isSuccess) {
        //console.log("api success");
        if (profileImages.length == 0) {
            var tempImg = [];
            // console.log("requestdetails.requestdetailsdata.data.request.request_images.data.length", requestdetails.requestdetailsdata.data.request.request_images.data.length);
            if (practitionerData && practitionerData.practitioner_portfolio_new.length < 2) {
                var islength = true;
                //console.log("inside if port")
                if (practitionerData && practitionerData.practitioner_portfolio_new.length == 1) {
                    islength = false;
                    tempImg.push(practitionerData && practitionerData.practitioner_portfolio_new[0].image_link)
                }
                if (practitionerData.selected_category == 3) {
                    tempImg.push(Aesthetic);
                    if (islength) {
                        tempImg.push(AestheticTwo);
                    }
                } else if (practitionerData.selected_category == 1) {
                    tempImg.push(Hair);
                    if (islength) {
                        tempImg.push(HairTwo);
                    }
                } else if (practitionerData.selected_category == 2) {
                    tempImg.push(Beauty);
                    if (islength) {
                        tempImg.push(BeautyTwo);
                    }
                } else {
                    tempImg.push(wellness);
                    if (islength) {
                        tempImg.push(wellnessTwo);
                    }
                }
            } else {
                //console.log("inside else port")
                practitionerData && practitionerData.practitioner_portfolio_new.map(i => {
                    tempImg.push(i.image_link);
                });
            }
            setProfileImages(tempImg);

        }
    }

    const __redirectSelectTreatment = () => {
        // console.log("__redirectSelectTreatment", practitionerData);

        setClicked(true);
        if (practitionerData.stripe_setup === 0) {
            // console.log("not registered");
            dispatch(resetPractitionerAddInfoAPI_Action());
            dispatch(resetStripeAccountValidityAPI_Action());
            window.scrollTo(0, 0);
            setLocalStorageData("RedirectPathName", "profile");
            history.push({
                pathname: '/register-practitioner-to-stripe',
                state: { 'isRegister': 0, 'token': "", stripe_link: "" },
            });
        } else {
            setLocalStorageData("RedirectPathName", "");
            window.scrollTo(0, 0);
            history.push({
                pathname: '/treatment-select',
                iswindowreload: location.iswindowreload
            });
        }

    }
    const getData = (e) => {
        // console.log("onbeforeunload:-", e);
    }
    // const __redirectDevelopment = () => {
    //     window.scrollTo(0, 0);
    //     await dispatch(PractportfolioaddimageAPI_Action(data, checkToken));
    //     history.push('/practitioner-portfolio')
    // }

    const __redirectDevelopment = useCallback(async () => {

        let checkToken = "";

        let LocalDatatoken = getLocalStorageData("loginData")
        let registerData = getLocalStorageData("practitionersignupData")

        if (registerData != "" && registerData !== undefined && registerData !== null) {
            checkToken = registerData.token;
        }
        else if (LocalDatatoken !== "" && LocalDatatoken !== undefined && LocalDatatoken !== null) {
            checkToken = LocalDatatoken.token;
        }

        try {
            await dispatch(PractionerportfoliolistAPI_Action(checkToken));
        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }

        window.scrollTo(0, 0);
        history.push('/practitioner-portfolio')
    });

    const _onCategoryClick = (e) => {

        setCurrentpage(0)
        setOffset(0)
        //setActivetabwhats(0)
        updateFormData({
            ...formData,
            whhats_available_pagination: 0

        })

    }

    const _whatAvailableClick = (e) => {

        setCurrentpage(0)
        setOffset(0)
        setActivetabwhats(1)
        updateFormData({
            ...formData,
            whhats_available_pagination: 1
        })

    }


    const onTreatmentTitleChange = (event, id, str, title) => {
        //  console.log("selected_date_time_array",formData.selected_date_time_array)
        let appointmentId = ""

        if (str === "accordion") {
            appointmentId = id
        } else {
            appointmentId = event.target.value
        }

        let selected_date_slots = []
        if (selected_date_time_array.length !== 0) {
            setselected_date_time_array([])
        }
        //console.log(selected_date_time_array)

        practitionerData && practitionerData.request.map((item, index) => {

            let dateArray = []
            let timeWithDateArray = []
            let time = {};
            let date = ""
            // console.log("item data for accordian:-", item);
            if (item.id == appointmentId && item.type == 1) {
                //
                item.request_timeslots.map((sub_item, index) => {
                    var dateobj = JSON.parse(sub_item.timeslots);

                    let date = sub_item.date_time;
                    let date1 = moment(date).format("DD-MM-yy")
                    dateArray.push(date1)
                    time = dateobj[Object.keys(dateobj)[0]];

                    let combinedobj = {
                        date: date1,
                        request_tyep: 1,
                        selected_slots_morning: dateobj.morning,
                        selected_slots_afternoon: dateobj.afternoon,
                        selected_slots_evening: dateobj.evening
                    }

                    timeWithDateArray.push(combinedobj)

                })
                setselected_date_time_array(timeWithDateArray)

                updateFormData({
                    ...formData,
                    selected_date_slots: dateArray,
                    // selected_date_time_array: timeWithDateArray,
                    request_type: 1,
                    treatment_title: title,
                    selected_slots_afternoon: [],
                    selected_slots_evening: [],
                    selected_slots_morning: []

                })

            }
            //  else if (item.id == appointmentId && item.type == 2) {
            //     //  console.log("item type 2", item)

            //     let date = item.request_timeslots[0].date_time
            //     let time = item.request_timeslots[0].start_time.substring(0, 5)
            //     //  console.log(time)
            //     let morning = []
            //     let afternoon = []
            //     let evening = []

            //     let newtime = time.substring(0, 2)
            //     if (newtime < 12) {
            //         morning.push(time)
            //     }
            //     else if (newtime > 12 && newtime < 16) {
            //         afternoon.push(time)
            //     } else if (newtime > 16) {
            //         evening.push(time)
            //     }
            //     let date1 = moment(date).format("DD-MM-yy")
            //     dateArray.push(date1)

            //     updateFormData({
            //         ...formData,
            //         selected_date_slots: dateArray,
            //         selected_slots_morning: morning,
            //         selected_slots_afternoon: afternoon,
            //         selected_slots_evening: evening,
            //         request_type: 2,
            //         treatment_title: title

            //     })
            // }


        })

    }


    const onChange = (e) => {
        //  console.log(formData.selected_date_time_array)
        let date1 = moment(e).format("DD-MM-yy")
        selected_date_time_array.map((item, index) => {
            if (date1 === item.date) {
                updateFormData({
                    ...formData,
                    selected_slots_morning: item.selected_slots_morning,
                    selected_slots_afternoon: item.selected_slots_afternoon,
                    selected_slots_evening: item.selected_slots_evening

                })
            }
        })

        // Setdata({...data,date:e});
    }


    const __redirectToEditTreatment = async (e, id) => {
        window.scrollTo(0, 0);
        await dispatch(treatmentDetailsAPI_Action(id));
        history.push('treatment-content-edit/' + id);
    }

    const __deleteTreatment = async (e, id) => {
        let checkToken = "";
        const loginToken = loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token : "" : "";
        const RegisterToken = practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.token : "" : "";
        if (loginToken != "") {
            checkToken = loginToken;
        } else if (RegisterToken != "") {
            checkToken = RegisterToken;
        } else if (LocalDatatoken !== "") {
            checkToken = LocalDatatoken.token;
        }
        if (checkToken !== '') {
            //dispatch(resetremoverequestforpractitionerprofileAPI_Action())
            dispatch(resetremoveTreatmentAPI_Action())
            window.scrollTo(0, 0);
            history.push('delete-treatment/' + id)
        }
    }



    const handlePageClick = (e) => {
        // console.log("hanlepage 1")
        const selectedPage = e.selected;
        const offset = selectedPage * perPage;
        //   console.log(offset)
        setCurrentpage(selectedPage)
        setOffset(offset)

    }


    const handlePageClicktreatment = (e) => {
        console.log("hanlepage 2")
        const selectedPage = e.selected;
        const offset = selectedPage * perpageTreatment;
        //   console.log(offset)
        setcurrentPageTreatment(selectedPage)
        setOffsettreat(offset)

    }

    const tileClassName = (activeStartDate, date, view) => {

        let date1 = moment(activeStartDate.date).format("DD-MM-yy")

        if (formData.selected_date_slots.length !== 0 && formData.selected_date_slots.indexOf(date1) !== -1) {

            return "pratitioner_canlender_active"

        }

    }

    const __redirectAbout = () => {
        window.scrollTo(0, 0);
        history.push('/practitioner-aboutUs')
    }

    const handlePageClickReiew = (e) => {
        //  window.scrollTo(0, 0);
        const selectedPage = e.selected;
        const offset = selectedPage * perPagereview;
        setCurrentpagereview(selectedPage)
        setOffsetreview(offset)
    }

    //console.log(practitioneProfileData.isFetching)

    const __redirectTo = (e, id) => {
        e.preventDefault();
        let checkToken = "";

        let LocalDatatoken = getLocalStorageData("loginData")
        let registerData = getLocalStorageData("practitionersignupData")

        if (registerData != "" && registerData !== undefined && registerData !== null) {
            checkToken = registerData.token;
        }
        else if (LocalDatatoken !== "" && LocalDatatoken !== undefined && LocalDatatoken !== null) {
            checkToken = LocalDatatoken.token;
        }

        if (checkToken === '') {
            window.scrollTo(0, 0);
            history.push('/login');
        }

        if (checkToken !== '') {
            window.scrollTo(0, 0);
            setLocalStorageData("treatmentcatId", id)
            dispatch(treatmentaddreviewAPI_Action(checkToken));
            history.push('/treatment-add-another/' + id);
        } else {
            window.scrollTo(0, 0);
            history.push('/login');
        }
    }



    //console.log('treatment-profile1',practitionerData)
    //console.log('treatment-profile',practitionerData.category_treatments)


    return (
        <>
            <section className="bgcolor-gray common-section practitioner_profile pb80">
                {
                    practitionerData !== null && !practitioneProfileData.isFetching ?
                        <Container>
                            <Row>
                                <Col className="col-lg-11 m-auto">
                                    <div className="navigationTop">
                                        <div className="heading-history">
                                            <div className="back-history">
                                                <button type="button" onClick={() => history.goBack()} className="back-btn" >
                                                    {" "}
                                                    <img src={LeftArrow} alt="arrow" />{" "}
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="practitioner_profile-wrp from-wrp profile_border">

                                        <div className="practitioner-profile-img ">
                                            {param_data ? null : <div className="profile-edtbtn">
                                                <button className="edit_btn" onClick={__redirectAbout} >Edit</button>
                                            </div>}
                                            <div className="two-col1">
                                                <div className="img_wrp_slider">
                                                    <SlickSlider {...settings} >
                                                        {
                                                            profileImages.map((i, index) => {
                                                                return (
                                                                    <div className="img_size profile_img1 " key={index}>

                                                                        <img src={i} />

                                                                    </div>
                                                                )
                                                            })

                                                        }

                                                    </SlickSlider>
                                                </div>

                                            </div>
                                            <div className="profile_portfolio_btn portfolio-btns-box">
                                                <button className="portfolio_btn" onClick={__redirectDevelopment}>  <span className="text-block"> My portfolio </span>  <span className="btn-icon"><img src={LeftArrow} /></span> </button>
                                            </div>
                                        </div>

                                        <div className="practitioner_user_profile mt-8">
                                            <div className="user-row">
                                                <div className="user-avatar">
                                                    <div className="user-icon">
                                                        {
                                                            practitionerData !== null ? practitionerData.profile_pic !== "" ? (

                                                                <img className="avtar" src={S3_BUCKET_LINK + practitionerData.profile_pic} />

                                                            ) : <img src={practitioner_avtar} className="img_avtar" /> : <img src={practitioner_avtar} className="img_avtar" />
                                                        }

                                                    </div>
                                                    <div className="user_content_box">
                                                        <div className="user-title">
                                                            <span className="user-name">{practitionerData !== null ? practitionerData.name : null}</span>
                                                            <span className="user-review">
                                                                <img className="rating" src={star} />
                                                                <span className="ratingpoint">({practitionerData !== null ? practitionerData.review_rating_practitioner.toFixed(1) : 0.0})</span>
                                                            </span>
                                                        </div>
                                                        <div className="user-role">
                                                            <span className="role-name">{practitionerData !== null ? practitionerData.practitioner_type === "Individual" ? practitionerData.practicing_saloon === 1 ? practitionerData.establishment_name : "Individual" : practitionerData.practicing_saloon === 1 ? practitionerData.company_name : practitionerData.practitioner_category_apply : null}, </span>
                                                            <span className="user-address"> {practitionerData !== null ? practitionerData.user_additional_info.length > 0 ? practitionerData.user_additional_info[0].postcode_data !== null ? practitionerData.user_additional_info[0].postcode_data.postcode ? practitionerData.user_additional_info[0].postcode_data.postcode.substr(0, practitionerData.user_additional_info[0].postcode_data.postcode.length - 3) + " " : null : null : null : null}</span>
                                                            <span className="user-address"> {practitionerData !== null ? practitionerData.user_additional_info.length > 0 ? practitionerData.user_additional_info[0].postcode_data !== null ? practitionerData.user_additional_info[0].postcode_data.city : null : null : null}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="user_info_content">
                                                    <p className="info">{practitionerData !== null ? practitionerData.user_additional_info.length > 0 ? practitionerData.user_additional_info[0].bio : null : null}</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>


                                    <div className="practition-skilltreatment pt-70">
                                        <h2 className="skill_heading">Live Appointments</h2>

                                        <div className="profile-tabs">
                                            <Tab.Container id="left-tabs-example" defaultActiveKey={1}>
                                                <div className="practition_profilebox">
                                                    <div className="profile-category">
                                                        <Nav variant="pills" className="flex-column">
                                                            <Nav.Item className=" tab_button">
                                                                <Nav.Link eventKey={1} onClick={_whatAvailableClick} >
                                                                    <span className="list-icon iconbox-1"></span>
                                                                    <span className="categorname">Active Listings</span>

                                                                </Nav.Link>
                                                            </Nav.Item>

                                                            {/* {   practitionerData && practitionerData.category_treatments.length < 4 ? 
                                                            (
                                                                <Nav.Item className=" tab_button icons6_tab">
                                                                <Nav.Link eventKey={6} onClick={(e) => __redirectTo(e,1)} >
                                                                    <span className="list-icon iconbox-6"></span>
                                                                    <span className="categorname"> New category</span>

                                                                </Nav.Link>
                                                            </Nav.Item>
                                                            ) : null
                                                        }  */}

                                                        </Nav>
                                                    </div>
                                                    <div className="profile-accordian">
                                                        <Tab.Content>

                                                            {
                                                                practitionerData !== null && practitionerData.request.length !== 0 && practitionerData.request.slice(offset, offset + perPage).map((item, index) => {

                                                                    return (
                                                                        <>
                                                                            <Tab.Pane className="in" eventKey={1} onClick={(e) => onTreatmentTitleChange(e, item.uuid, "accordion", item.treatment.title)} key={index}>

                                                                                {
                                                                                    <AccordionContentForPractitioner
                                                                                        title={item.treatment && item.treatment.title}
                                                                                        content={item.treatment && item.treatment.description}
                                                                                        expertise_level={item.reason_require_ginipig}
                                                                                        reason_require_ginipig={item.expertise_level}
                                                                                        ginipig_count={item.ginipig_count - item.bookings_places_count}
                                                                                        hours={item.hours}
                                                                                        minutes={item.minutes}
                                                                                        postcode={item.postcodes !== null ? formatPostcode(item.postcodes.postcode) : null}
                                                                                        city={item.postcodes !== null ? item.postcodes.city : null}
                                                                                        isActive={isActive}
                                                                                        indexValue={item.uuid}
                                                                                        onClick={(indexid) => { setIsActive(indexid === isActive ? false : indexid) }}

                                                                                    />

                                                                                }
                                                                            </Tab.Pane>
                                                                        </>
                                                                    )

                                                                })
                                                            }
                                                            {
                                                                practitionerData && practitionerData.request.length > 0 &&
                                                                <div className="pagination-div">

                                                                    <ReactPaginate
                                                                        previousLabel={"prev"}
                                                                        nextLabel={"next"}
                                                                        breakLabel={"..."}
                                                                        breakClassName={"break-me"}
                                                                        pageCount={Math.ceil(practitionerData && practitionerData.request && practitionerData.request !== null && practitionerData.request.length / perPage)}
                                                                        marginPagesDisplayed={2}
                                                                        pageRangeDisplayed={2}
                                                                        onPageChange={handlePageClick}
                                                                        containerClassName={"pagination"}
                                                                        subContainerClassName={"pages pagination"}
                                                                        activeClassName={"active"} />

                                                                    {param_data ? null : <div className="btn-align pd10 ">

                                                                        <LargeButton
                                                                            ButtonType="button"
                                                                            ButtonText="List new appointment"
                                                                            nextButtonClick={(e) => __redirectSelectTreatment()}
                                                                        />

                                                                    </div>}
                                                                </div>
                                                            }
                                                            {formData.whhats_available_pagination === 1 && practitionerData && practitionerData.request && practitionerData.request.length === 0 &&

                                                                <div>

                                                                    <div className="notice-review">
                                                                        <p >You don't have any appointments listed right now, go to 'create new appointment' to advertise your availability.</p>
                                                                    </div>

                                                                    {

                                                                        practitioneProfileData.isFetching
                                                                            ? <div className="create_apoint_btn1 pt-16">
                                                                                <button type="button" className="create_apoint_btn_empty" disabled={true} >Loading..</button>
                                                                            </div> :
                                                                            <div className="create_apoint_btn1 pt-16">
                                                                                <button type="button" className="create_apoint_btn_empty" disabled={isClicked} onClick={__redirectSelectTreatment}>Create new appointment</button>
                                                                            </div>
                                                                    }
                                                                </div>
                                                            }





                                                        </Tab.Content>

                                                    </div>
                                                </div>
                                            </Tab.Container>
                                        </div>
                                    </div>




                                    <div className="practition-skilltreatment pt-70">
                                        <h2 className="skill_heading">My Talent Menu</h2>
                                        <div>
                                            <p className="cattext-label mb-16">Can’t see all categories? Scroll across to see if there’s other options available</p>
                                        </div>

                                        <div className="profile-tabs">
                                            <Tab.Container id="left-tabs-example" defaultActiveKey={2}>
                                                <div className="practition_profilebox">
                                                    <div className="profile-category">
                                                        <Nav variant="pills" className="flex-column">

                                                            {
                                                                practitionerData && practitionerData.category_treatments.map((category, index) => {

                                                                    const caticonid = category.id + 1;
                                                                    return (
                                                                        <Nav.Item key={index} className=" tab_button" onClick={_onCategoryClick}>
                                                                            <Nav.Link eventKey={caticonid}>
                                                                                <span className={"list-icon iconbox-" + caticonid}> </span>
                                                                                <span className="categorname">{category.name}</span>


                                                                            </Nav.Link>
                                                                        </Nav.Item>
                                                                    )


                                                                })
                                                            }


                                                            {practitionerData && practitionerData.category_treatments.length < 4 ?
                                                                (
                                                                    <Nav.Item className=" tab_button icons6_tab">
                                                                        <Nav.Link eventKey={6} onClick={(e) => __redirectTo(e, 1)} >
                                                                            <span className="list-icon iconbox-6"></span>
                                                                            <span className="categorname"> New category</span>

                                                                        </Nav.Link>
                                                                    </Nav.Item>
                                                                ) : null
                                                            }




                                                        </Nav>
                                                    </div>
                                                    <div className="profile-accordian">
                                                        <Tab.Content>
                                                            {

                                                                practitionerData && practitionerData.category_treatments.map((category, index) => {
                                                                    // if (category.treatments.length > 0) {
                                                                    return (
                                                                        <>
                                                                            <Tab.Pane className="in" eventKey={category.id + 1} key={index}>
                                                                                {
                                                                                    category.treatments && category.treatments !== null && category.treatments.slice(offsettreat, offsettreat + perpageTreatment).map((treatmentdata, treat_index) => {
                                                                                        //  console.log("treatment data:-", treatmentdata);
                                                                                        return (
                                                                                            <>
                                                                                                {param_data ? <AccordionContentForPractitioner key={treat_index}
                                                                                                    title={treatmentdata.title}
                                                                                                    content={treatmentdata.description}
                                                                                                    isActive={isActive}
                                                                                                    indexValue={treatmentdata.uuid}
                                                                                                    onClick={(indexid) => { setIsActive(indexid === isActive ? false : indexid) }}

                                                                                                //  expertise_level={expertise_level}
                                                                                                //  ginipig_count={ginipig_count}
                                                                                                //  reason_require_ginipig={reason_require_ginipig}
                                                                                                //  hours={hours}
                                                                                                // minutes={minutes}
                                                                                                // postcode={postcodes !== null ? postcodes.postcode : null}
                                                                                                // city={postcodes !== null ? postcodes.city : null}
                                                                                                /> :
                                                                                                    <AccordionContent
                                                                                                        title={treatmentdata.title}
                                                                                                        editText="Edit"
                                                                                                        deleteText={iconDelete}
                                                                                                        content={treatmentdata.description}
                                                                                                        key={treat_index}
                                                                                                        id={treatmentdata.id}
                                                                                                        uuid={treatmentdata.uuid}
                                                                                                        __nextAction={__redirectToEditTreatment}
                                                                                                        __deleteAction={__deleteTreatment}
                                                                                                        isActive={isActive}
                                                                                                        indexValue={treatmentdata.uuid}
                                                                                                        onClick={(indexid) => { setIsActive(indexid === isActive ? false : indexid) }}

                                                                                                    />}
                                                                                            </>
                                                                                        )
                                                                                    })
                                                                                }

                                                                                {category.treatments && category.treatments !== null && category.treatments.length === 0 &&

                                                                                    <div className="notice-review">
                                                                                        <p >You haven't added any treatments to this category yet, please select 'Add new treatment'</p>
                                                                                    </div>
                                                                                }


                                                                                <div className="pagination-div">


                                                                                    {category.treatments && category.treatments !== null && category.treatments.length > 0 &&

                                                                                        <ReactPaginate
                                                                                            previousLabel={'previous'}
                                                                                            nextLabel={'next'}
                                                                                            breakLabel={'...'}
                                                                                            breakClassName={'break-me'}
                                                                                            pageCount={Math.ceil(category.treatments && category.treatments !== null && category.treatments.length / perpageTreatment)}
                                                                                            marginPagesDisplayed={2}
                                                                                            pageRangeDisplayed={2}
                                                                                            onPageChange={handlePageClicktreatment}
                                                                                            containerClassName={'pagination'}
                                                                                            subContainerClassName={'pages pagination'}
                                                                                            activeClassName={'active'}
                                                                                        />
                                                                                    }


                                                                                    {param_data ? null : <div className="btn-align pd10 ">
                                                                                        {category.treatments && category.treatments !== null && category.treatments.length === 0 &&
                                                                                            <LargeButton
                                                                                                ButtonType="button"
                                                                                                ButtonText="Add to menu"
                                                                                                nextButtonClick={(e) => __redirectUrl(e, category.id)}
                                                                                            />
                                                                                        }

                                                                                        {category.treatments && category.treatments !== null && category.treatments.length > 0 &&
                                                                                            <LargeButton
                                                                                                ButtonType="button"
                                                                                                ButtonText="Add to menu"
                                                                                                nextButtonClick={(e) => __redirectUrl(e, category.id)}
                                                                                            />
                                                                                        }

                                                                                    </div>}

                                                                                </div>


                                                                            </Tab.Pane>
                                                                        </>
                                                                    )

                                                                })
                                                            }


                                                        </Tab.Content>

                                                    </div>
                                                </div>
                                            </Tab.Container>
                                        </div>
                                    </div>
                                    {/* end tab treatmentlist */}

                                    {/* <div className="preview-calendar calender-box pt-70">

                                        <div className="calender_selection_box calander_box1 mb-24">
                                            <div className="title-box">
                                                <h2 className="skill_heading mb-8">Flexible treatment availability</h2>
                                                {
                                                    practitionerData && practitionerData.request.length > 0 ? null :
                                                        <h3 className="calneder_subheading mb-16">You do not have any availability, to show your availability create a new appointment</h3>

                                                }


                                            </div>
                                            <div className="section-box">
                                                <div className="select_feild">

                                                    <select onChange={onTreatmentTitleChange}
                                                        value={formData.treatment_title}>
                                                        <option>{formData.treatment_title}</option>
                                                        {
                                                            practitionerData && practitionerData.request.map((item, index) => {
                                                                return (
                                                                    item.type === 1 ?
                                                                        <option value={item.id}>{item.treatment && item.treatment.title}</option> : null
                                                                )

                                                            })
                                                        }

                                                    </select>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="calander_box">
                                            <div className="pratitioner_canlender">

                                                <Calendar
                                                    onChange={(e) => onChange(e)}
                                                    // value={data}
                                                    tileClassName={tileClassName}
                                                    formatShortWeekday ={ (locale, date) => ['S', 'M', 'T', 'W', 'T', 'F', 'S'][date.getDay()]}
                                                        
                                                />
                                            </div>


                                            <div className="time_slot-box">

                                                {formData.request_type === 1 &&
                                                    <div className="calneder_slot_times">
                                                        <div className="day_slot_box">
                                                            <div className="radio-box">
                                                                <label className="radio_label " htmlFor="day_slot1">

                                                                    <div className="appoint_slots ml-15 appoint_slot_day">
                                                                        <span className="morning_icon icon_slots slot_heading">Morning</span>
                                                                    </div>
                                                                </label>
                                                                <div className="slots_box">
                                                                    <div className="inside_slot_grid">
                                                                        {
                                                                            formData.morningSlotsArray.map((item, index) => {
                                                                                return (
                                                                                    <div className="slot_div">
                                                                                        <button className={formData.selected_slots_morning.indexOf(item) === -1 ? "schedule_slot" : "schedule_slot active_slot"}>
                                                                                            <span className="fix-times">{item}</span>
                                                                                        </button>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="radio-box">

                                                                <label className="radio_label " htmlFor="day_slot2">
                                                                    <div className="appoint_slots ml-15 appoint_slot_day">
                                                                        <span className="afternoon_icon icon_slots slot_heading">Afternoon</span>
                                                                    </div>
                                                                </label>
                                                                <div className="slots_box">
                                                                    <div className="inside_slot_grid">{
                                                                        formData.afternoonSlotsArray.map((item, index) => {
                                                                            return (
                                                                                <div className="slot_div">
                                                                                    <button className={formData.selected_slots_afternoon.indexOf(item) === -1 ? "schedule_slot" : "schedule_slot active_slot"}>
                                                                                        <span className="fix-times">{item}</span>
                                                                                    </button>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="radio-box">
                                                                <label className="radio_label " htmlFor="day_slot3">

                                                                    <div className="appoint_slots ml-15 appoint_slot_day">
                                                                        <span className="evening_icon icon_slots slot_heading">Evening</span>
                                                                    </div>
                                                                </label>
                                                                <div className="slots_box">
                                                                    <div className="inside_slot_grid">
                                                                        {
                                                                            formData.eveningSlotsArray.map((item, index) => {
                                                                                return (
                                                                                    <div className="slot_div">
                                                                                        <button className={formData.selected_slots_evening.indexOf(item) === -1 ? "schedule_slot" : "schedule_slot active_slot"} >
                                                                                            <span className="fix-times">{item}</span>
                                                                                        </button>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }


                                                {formData.request_type === 2 &&
                                                    <div className="calneder_slot_times">
                                                        <div className="day_slot_box">
                                                            <div className="radio-box">
                                                                <label className="radio_label " htmlFor="day_slot1">

                                                                    <div className="appoint_slots ml-15 appoint_slot_day">
                                                                        <span className="morning_icon icon_slots slot_heading">Morning</span>
                                                                    </div>
                                                                </label>
                                                                <div className="slots_box bg-white2">
                                                                    <div className="inside_slot_grid">
                                                                        {
                                                                            formData.selected_slots_morning.length !== 0 && formData.selected_slots_morning.map((item, index) => {
                                                                                return (
                                                                                    <div className="slot_div">
                                                                                        <button className="schedule_slot active_slot">
                                                                                            <span className="fix-times">{item}</span>
                                                                                        </button>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="radio-box">

                                                                <label className="radio_label " htmlFor="day_slot2">
                                                                    <div className="appoint_slots ml-15 appoint_slot_day">
                                                                        <span className="afternoon_icon icon_slots slot_heading">Afternoon</span>
                                                                    </div>
                                                                </label>
                                                                <div className="slots_box bg-white2">
                                                                    <div className="inside_slot_grid">{
                                                                        formData.selected_slots_afternoon !== 0 && formData.selected_slots_afternoon.map((item, index) => {
                                                                            return (
                                                                                <div className="slot_div">
                                                                                    <button className="schedule_slot active_slot">
                                                                                        <span className="fix-times">{item}</span>
                                                                                    </button>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="radio-box">
                                                                <label className="radio_label " htmlFor="day_slot3">

                                                                    <div className="appoint_slots ml-15 appoint_slot_day">
                                                                        <span className="evening_icon icon_slots slot_heading">Evening</span>
                                                                    </div>
                                                                </label>
                                                                <div className="slots_box bg-white2">
                                                                    <div className="inside_slot_grid">
                                                                        {
                                                                            formData.selected_slots_evening !== 0 && formData.selected_slots_evening.map((item, index) => {
                                                                                return (
                                                                                    <div className="slot_div">
                                                                                        <button className="schedule_slot active_slot">
                                                                                            <span className="fix-times">{item}</span>
                                                                                        </button>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                {
                                                    practitioneProfileData.isFetching
                                                        ? <div className="create_apoint_btn1 pt-16">
                                                            <button type="button" className="create_appont_btn" disabled={true} >Loading..</button>
                                                        </div> :
                                                        <div className="create_apoint_btn1 pt-16">
                                                            <button type="button" className="create_appont_btn" disabled={isClicked} onClick={__redirectSelectTreatment}>Create new appointment</button>
                                                        </div>
                                                }



                                            </div>
                                        </div>

                                    </div> */}


                                    <div className="profile_review mt-24">
                                        {/* <h3 className="heading_review">Reviews</h3> */}
                                        <h3 className="heading_review">Everybody loves a great review so get perfecting!</h3>
                                        <div className={practitionerData && practitionerData.practitioner_reviews && practitionerData.practitioner_reviews.length !== 0 ? "review_rating_box  mt-40" : "review_rating_box  mt-40 disable_opacity"}>
                                            <div className="first-div">

                                                <ProfileReviewCard
                                                    ImgIcon={Icon_professionalism}
                                                    ratingName="Professionalism"
                                                    initialRating={Math.ceil(practitionerData && practitionerData.professionalismAverage)}
                                                />
                                                <ProfileReviewCard
                                                    ImgIcon={Icon_etiquette}
                                                    ratingName="Friendliness"
                                                    initialRating={Math.ceil(practitionerData && practitionerData.etiquetteAverage)}
                                                />
                                                <ProfileReviewCard
                                                    ImgIcon={Icon_cleanliness}
                                                    ratingName="Reliability"
                                                    initialRating={Math.ceil(practitionerData && practitionerData.cleanlinessAverage)}

                                                />
                                                <ProfileReviewCard
                                                    ImgIcon={Icon_results}
                                                    ratingName="Results"
                                                    initialRating={Math.ceil(practitionerData && practitionerData.resultAverage)}
                                                />
                                            </div>
                                            <div className="second-div">
                                                <div className="rating_points">
                                                    <img src={star} className="stars" />
                                                    <p className="points">{practitionerData && practitionerData.review_rating_practitioner === 0 ? "0.0" : practitionerData && practitionerData.review_rating_practitioner.toFixed(1)}</p>
                                                </div>
                                            </div>
                                        </div>


                                        {
                                            practitionerData && practitionerData.practitioner_reviews && practitionerData.practitioner_reviews.length !== 0 ?
                                                <div>

                                                    <div className="review_boxs_wrp mt-16">

                                                        {
                                                            practitionerData && practitionerData.practitioner_reviews && practitionerData.practitioner_reviews.slice(offsetreview, offsetreview + perPagereview).map((reviewdata, index) => {

                                                                let reviewDate = new Date(reviewdata.created_at)

                                                                let date1 = moment(reviewDate).format("DD/MM/yy")

                                                                let subCatArray = []
                                                                reviewdata.booking.request.treatment.treatment_sub_category_new.forEach(element => {
                                                                    subCatArray.push(element.name)

                                                                })

                                                                var str;
                                                                if (subCatArray.length === 1) {
                                                                    str = subCatArray.join()
                                                                } else if (subCatArray.length === 2) {

                                                                    str = subCatArray.join(" & ")
                                                                } else if (subCatArray.length === 3) {
                                                                    str = subCatArray[0] + "," + subCatArray[1] + " & " + subCatArray[2]
                                                                }


                                                                return (
                                                                    <div className="review_item mb-16" key={index}>
                                                                        <ReviewBox
                                                                            title={reviewdata.booking.request.treatment.title}
                                                                            reviewDate={date1}
                                                                            decs={reviewdata.comments}
                                                                            reviewAuthor={reviewdata.ginipig.name}
                                                                            showgpname={false}
                                                                            reviewName2="Professionalism"
                                                                            reviewCount2={reviewdata.professionalism}
                                                                            reviewName3="Friendliness "
                                                                            reviewCount3={reviewdata.etiquette}
                                                                            reviewName1="Reliability"
                                                                            reviewCount1={reviewdata.cleanliness}
                                                                            reviewName4="Results"
                                                                            reviewCount4={reviewdata.result}
                                                                            overAllCount={reviewdata.overall}
                                                                        />
                                                                    </div>
                                                                )
                                                            })
                                                        }


                                                    </div>

                                                    <div className="pagination-div">
                                                        <ReactPaginate
                                                            previousLabel={"prev"}
                                                            nextLabel={"next"}
                                                            breakLabel={"..."}
                                                            breakClassName={"break-me"}
                                                            pageCount={Math.ceil(practitionerData && practitionerData.practitioner_reviews && practitionerData.practitioner_reviews.length / perPagereview)}
                                                            //marginPagesDisplayed={2}
                                                            pageRangeDisplayed={2}
                                                            onPageChange={handlePageClickReiew}
                                                            containerClassName={"pagination"}
                                                            subContainerClassName={"pages pagination"}
                                                            activeClassName={"active"} />
                                                    </div>
                                                </div> :
                                                <div className="form-wrp notice-review mt-16">
                                                    <p>You do not have any reviews but hopefully you will soon! GiniPigs will leave reviews once they have been treated by you in an appointment</p>
                                                </div>


                                        }


                                    </div>




                                </Col>
                            </Row>
                        </Container> :
                        <div style={{ width: "100%", fontSize: 20, marginTop: 100, textAlign: 'center' }}>
                            <p>Loading ...</p>
                        </div>


                }
            </section>
        </>
    );
}

export default PractitionerProfile;