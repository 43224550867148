import React from 'react';
import Rating from 'react-rating';
import { LeftArrow, star, icon_unselected_star } from '../../config/images';
import ReactStars from "react-rating-stars-component";

const ProfileReviewCard = (props) => {
    return (
        <>
            <div className="rating_point_profile">
                <div className="rating-icon">
                    <img src={props.ImgIcon} className="rating_icons" />
                    {props.overallrating && <div className="overall_text">{props.overallrating}</div>}
                </div>
                <div className="rading-item">
                    <p>{props.ratingName}</p>
                    <Rating
                        emptySymbol={<img src={icon_unselected_star} className="icon" />}
                        fullSymbol={<img src={star} className="icon" />}
                        initialRating={props.initialRating}
                        readonly={true}
                    />

                    {/* <ReactStars
                        count={5}
                        value={3.5}
                        size={24}
                        isHalf={true}
                        emptyIcon={<img src={icon_unselected_star} className="icon" />}
                        halfIcon={<i className="fa fa-star-half-alt"></i>}
                        fullIcon={<img src={star} className="icon" />}
                        activeColor="#ffd700"

                    /> */}
                </div>
            </div>
        </>
    );
}

export default ProfileReviewCard;