import React from 'react';
import {Container,Row,Col} from 'react-bootstrap';
import {LeftArrow} from '../../config/images'
import {useHistory,Link} from "react-router-dom";
import { setLocalStorageData } from '../../utils/storage';
const UpdateProfileConfirmation = (props) => {
    const history = useHistory();    
    var param_data;
    if(props.location.state !== undefined){
        param_data = props.location.state.data
    }
    
    const __ProfileEdit = () => {
        window.scrollTo(0, 0);
        var data = {'update_profile' : true}
        setLocalStorageData('update_ginipig_profile',JSON.stringify(data))
        history.push('/ginipig-edit' , { data:param_data, });
    }



    return (
        <>
         <section className="bgcolor-gray common-section">
            <Container>
                <Row>
                    <Col className="col-md-10 m-auto">
                        <div className="heading-history">
                            <div className="back-history">
                            <button
                                type="button"
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                    history.goBack()}}
                                className="back-btn"
                            >
                                {" "}
                                <img src={LeftArrow} alt="arrow" />{" "}
                            </button>
                            </div>
                            <div className="history-title">
                            <h2>Update Your Account </h2>
                            </div>
                        </div>
                        <div className="desciption">
                            <p className="subdesc">
                            To book an appointment you have to update your profile picture 
                            </p>

                            <div className="two-col mt-24 mb-24">
                            <button className="btns-gray " onClick={__ProfileEdit}>
                                {" "}
                                Ok
                            </button>

                            {/* <button
                                className="btns-gray "
                                onClick={() => history.goBack()}
                            >
                                {" "}
                                No, do not logout
                            </button> */}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        </>
    );
}

export default UpdateProfileConfirmation;