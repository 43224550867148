import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// redux
import { whatsAvailableAPI_Action } from '../../stores/actions'


const WhatsAvailable = (props) => {
  
  // Initialize hooks first
  const dispatch = useDispatch();

  // Selectors
  const whatsAvailbleData = useSelector(state => state.whatsavailable);
  const Data = whatsAvailbleData;
  
  // Use effects
  useEffect(() => { 
    if(whatsAvailbleData.whatsavailabledata===null)
    {
      try {
        dispatch(whatsAvailableAPI_Action());
      } catch (error) {
        console.error(" whats-available ::  whats-available() :: Exception ==", error);
      }
    }
    
  })
  const __apiCall = useCallback(async () => {
    try {
      await dispatch(whatsAvailableAPI_Action());
    } catch (error) {
      console.error(" whats-available ::  whats-available() :: Exception ==", error);
    }
  });



  return (
    <div>
      <h4>Whats Available</h4>
      {
        whatsAvailbleData.isFetching ? <button >Loading</button> : <button onClick={__apiCall}>API Call</button>
      }
      
      
        <div className="row">
          {
            
            Data.whatsavailabledata && Data.whatsavailabledata.data.treatments.map((treatments,index) => {

              return (
                <>
                <div className="col">{treatments.title}</div>
              <div className="col-6">{treatments.description}</div>
                <div className="col">{treatments.price}</div>
                </>
              )
              
              }) 
          }
            
        </div>
      
  
      

      {/* <div>
        <code>
          {
            JSON.stringify(whatsAvailbleData.whatsavailabledata ? whatsAvailbleData.whatsavailabledata.data: whatsAvailbleData.whatsavailabledata)
          }
        </code>
      </div> */}
    </div>


    
  );
};

export default WhatsAvailable;