
import React, { useCallback, useState, useEffect } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import { LeftArrow, star, iconCase_Study, iconNearly_there, iconPhoto, reviewAvatar, iconPatch_test } from '../../config/images';
import RequestBookingCard from "../../components/UIComponent/RequestBookingCard";
import TransparentButton from "../../components/UIComponent/TransparentButton";
import CloseRequestAcceptCancelCard from "../../components/UIComponent/CloseRequestAcceptCancelCard";
import DeclineBookingCard from "../../components/UIComponent/DeclineBookingCard";
import { getLocalStorageData, setLocalStorageData ,clearLocalStorage} from '../../utils/storage'
import { acceptbookingAPI_Action, declineenquiryAPI_Action,practitionerrequestDetailsAPI_Action,resetLoginAPI_Action,resetupcomingbookingAPI_Action,resetopenrequestenquiryDetailsAPI_Action,resetpractitionerrequestdetailsAPI_Action,resetacceptenquiryAPI_Action,openrequestenquiryDetailsAPI_Action, resetdeclineenquiryAPI_Action, practionercancelbookingAPI_Action, practionerdeclinebookingAPI_Action, pendingbookingDetailsAPI_Action, upcomingbookingAPI_Action, resetgoginipigprofileAPI_Action, resettreatmentaddreviewAPI_Action } from '../../stores/actions'
import Modal from 'react-modal';
import LoadingSpinner from "../../components/UIComponent/LoadingSpinner"
import ModelRequirementCloseRequest from '../../components/ModelRequirementCloseRequest'
import moment from 'moment';

const customStyles = {
    overlay: {
        background: "#dee2e663", zIndex: 999999
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        border: "none",
        background: "none"
    }
};

const AcceptOpenRequest = (props) => {

    let history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const [requestArray, setrequestArray] = useState([]);
    const [subcategory, setsubcategory] = useState("");
    const [bookingDate, setbookingDate] = useState();
    const [bookingId, setBookingId] = useState();
    const [bookingDetails, setBookingDetails] = useState();
    const [bookingCount, setBookingCount] = useState();

    const pendingbookingDetails = useSelector(state => state.pendingbookingdetails);
    const practitionerrequestDetailsdata = useSelector(state => state.practitionerrequestdetailsdata);
    const loginUserData = useSelector(state => state.login);
    const practitionersignupaddinfoData = useSelector(state => state.practitionersignupaddinfo);
    const cancelreasonslistData = useSelector(state => state.cancelreasonslistdata);
    const declinereasonslistData = useSelector(state => state.declinereasonslistdata);
    const openrequestenquirydetailsdata = useSelector(state => state.openrequestenquirydetailsdata);
    const declineenquirydata = useSelector(state => state.declineenquirydata);

    const [formData, updateFormData] = useState(Object.freeze({
        decline_reason_id: [],
        is_togglechangereason1: false,
        is_togglechangereason2: false,
        is_togglechangereason3: false,
        clickCancel: false,
        selectReasonId : ''
    }));

    const [modalIsOpen, setIsOpen] = React.useState(false);

    // Use effects
    useEffect(() => {
       
        try {
            let checkToken = "";
            let LocalDatatoken = getLocalStorageData("loginData")
            let registerData = getLocalStorageData("practitionersignupData")

            if (registerData != "" && registerData !== undefined && registerData !== null) {
                checkToken = registerData.token;
            }
            else if (LocalDatatoken !== "" && LocalDatatoken !== undefined && LocalDatatoken !== null) {
                checkToken = LocalDatatoken.token;
            }
            if (checkToken === '') {
                window.scrollTo(0, 0);
                history.push('/login');
            }
           
            api_callNew( checkToken);


            let bookingDetails = getLocalStorageData("bookingDetailsopen")
            if(bookingDetails===undefined){
                window.scrollTo(0, 0);
                dispatch(resetupcomingbookingAPI_Action());
                history.push({
                    pathname: '/practitioner-account',
                    iswindowreload:true
                });
            }
           // console.log(bookingDetails)
            setBookingDetails(bookingDetails)

            let bookingCount = getLocalStorageData("BookigCount")
            setBookingCount(bookingCount)

        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    }, [])

    const api_callNew = async (checkToken) => {
        let bookingDetailsopendata = getLocalStorageData("bookingDetailsopendata")

       await dispatch(practitionerrequestDetailsAPI_Action(bookingDetailsopendata.requestId, checkToken));
       await dispatch(openrequestenquiryDetailsAPI_Action(bookingDetailsopendata.uuid, checkToken));
    }

    if (practitionerrequestDetailsdata.practitionerrequestdetailsdata && practitionerrequestDetailsdata.practitionerrequestdetailsdata.status_code === 401) {
       dispatch(resetpractitionerrequestdetailsAPI_Action())
        dispatch(resetopenrequestenquiryDetailsAPI_Action())
        dispatch(resetLoginAPI_Action())
        clearLocalStorage("loginData")
        clearLocalStorage("practitionersignupData")
        clearLocalStorage("RedirectPathName")
        clearLocalStorage("requestDataLocal")
        clearLocalStorage("confirmData")
        clearLocalStorage("openrequestdata")
        clearLocalStorage("closerequestdata")
        clearLocalStorage("noresponseData")
        window.scrollTo(0, 0);
        history.push('/login');
    }

    useEffect(() => {
      
        try {
         
            let requestArray = practitionerrequestDetailsdata.practitionerrequestdetailsdata && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.request
            if (requestArray !== null) {
                let subCatArray = []
                requestArray.treatment.treatment_sub_category_new.forEach(element => {
                    subCatArray.push(element.name)

                })

                var str;
                if (subCatArray.length === 1) {
                    str = subCatArray.join()
                } else if (subCatArray.length === 2) {

                    str = subCatArray.join(" & ")
                } else if (subCatArray.length === 3) {
                    str = subCatArray[0] + "," + subCatArray[1] + " & " + subCatArray[2]
                }
                let newDate = ""
                let displayDate = bookingDetails.date


                if (displayDate !== null && displayDate !== undefined) {
                    let day = displayDate.substring(8, 10);
                    
                    let displayDay = ""
                    if (day < 9) {
                        displayDay = day.substring(1, 2)
                    } else {
                        displayDay = day
                    }

                    let n = displayDate.substring(5, 7)
                    // console.log(n)
                    let newn = ""
                    if (n < 10) {
                        newn = n.substring(1, 2)
                    } else {
                        newn = n
                    }

                    var month = new Array();
                    month[1] = "January";
                    month[2] = "February";
                    month[3] = "March";
                    month[4] = "April";
                    month[5] = "May";
                    month[6] = "June";
                    month[7] = "July";
                    month[8] = "August";
                    month[9] = "September";
                    month[10] = "October";
                    month[11] = "November";
                    month[12] = "December";

                    var displaymonth = month[newn];

                    if (displayDay == 1) {
                        newDate = displayDay + "st" + " " + displaymonth + " "
                    } else if (displayDay == 2) {
                        newDate = displayDay + "nd" + " " + displaymonth + ""
                    } else if (displayDay == 3) {
                        newDate = displayDay + "rd" + " " + displaymonth + " "
                    } else {
                        newDate = displayDay + "th" + " " + displaymonth + " "
                    }
                }

                let period = ""
                let hours = ''
                let [sHours, minutes] = ""
                if (requestArray.request_timeslots[0].start_time !== null) {
                    [sHours, minutes] = requestArray.request_timeslots[0].start_time && requestArray.request_timeslots[0].start_time.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
                    period = +sHours < 12 ? 'AM' : 'PM';
                    hours = +sHours % 12 || 12;
                }

                let timein24hrs=""
           
                let display_date = moment(bookingDetails.booking.date).format("Do MMMM YYYY");
                if (bookingDetails.booking.time !== null && bookingDetails.booking.time!==undefined) {
                    timein24hrs=bookingDetails.booking.time.slice(0,5)
               }
                //let set_time1 = moment(bookingDetails.booking.time, "HH:mm:ss").format("hh:mm");
                // let set_dateTime = `${display_date} @ ${timein24hrs}`;
                let set_dateTime = `${display_date}`;
                //let displaydate = newDate + new Date().getFullYear() 
                //let displaydate = newDate + new Date(displayDate).getFullYear() + " @ " + " various times"

                setbookingDate(set_dateTime)
                setsubcategory(requestArray.treatment.title)
            }

            setrequestArray(requestArray)

        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    }, [practitionerrequestDetailsdata])

     
    useEffect(() => {
        try {
            if (declineenquirydata.declineenquirydata && declineenquirydata.declineenquirydata.message !== "") {
                closeModal()
            }
        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
            closeModal()
        }
    }, [declineenquirydata.declineenquirydata])


    const acceptButtonClick = (item) => {
        let dataArray = []
        var dateobj = JSON.parse(openrequestenquirydetailsdata.openrequestenquirydetailsdata && openrequestenquirydetailsdata.openrequestenquirydetailsdata.data.request_enquiry.timeslots);
        let date = openrequestenquirydetailsdata.openrequestenquirydetailsdata && openrequestenquirydetailsdata.openrequestenquirydetailsdata.data.request_enquiry.date + " " + "00:00:00"
        // console.log(dateobj."morning")

        let timeslots = JSON.parse(dateobj);
       // console.log(timeslots.morning)

        // let allTimeSlots=[]
        let hrsArray = []
        let minArray = []
        let allTimeSlots = (timeslots.morning).concat(timeslots.afternoon).concat(timeslots.evening)
       // console.log(allTimeSlots)
        allTimeSlots.map((slot, index) => {
            // console.log(slot.substring())
            var hrs = slot.substr(0, slot.indexOf(':'));
            var minute = slot.substr(slot.length - 2);
            // var lastThree = slot.substr(slot.length - 2);
            hrsArray.push(hrs)
            minArray.push(minute)


        })


        let newhrsArray = hrsArray.sort(function (a, b) { return a - b });
        let newminArray = minArray.sort(function (a, b) { return a - b });
        let filterhrsArray = newhrsArray.filter((item, index) => newhrsArray.indexOf(item) === index)
        let filterminutesArray = newminArray.filter((item, index) => newminArray.indexOf(item) === index)
        let filetrDayArray = allTimeSlots.filter((item, index) => allTimeSlots.indexOf(item) === index)
        //console.log(filetrDayArray)


        let obj = {
            date_time: date,
            timeslots: dateobj
        }
        dataArray.push(obj)
        let priceoriginal = requestArray && requestArray.rrp_price
        let pricedis = requestArray && requestArray.your_price

        let calenderobj = {
            date: openrequestenquirydetailsdata.openrequestenquirydetailsdata && openrequestenquirydetailsdata.openrequestenquirydetailsdata.data.request_enquiry.date,
            requestId: practitionerrequestDetailsdata.practitionerrequestdetailsdata && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.request.id,
            enquiryData: dataArray,
            minuteArray: filterminutesArray,
            hrsArray: filterhrsArray,
            subCatArray: subcategory,
            originalPriceText: priceoriginal,
            discountPrice: pricedis,
            bookingDetails: item,
            allTimeSlots: filetrDayArray
        }

        setLocalStorageData("calenderobj", calenderobj)
        setLocalStorageData("requestIdopen", item.id)
        dispatch(resetacceptenquiryAPI_Action());
        window.scrollTo(0, 0);
        // __apiCall(item)
        history.push({
            pathname: '/select-time-accept-booking',
            bookingDetails: item,
            date: openrequestenquirydetailsdata.openrequestenquirydetailsdata && openrequestenquirydetailsdata.openrequestenquirydetailsdata.data.request_enquiry.date,
            requestId: practitionerrequestDetailsdata.practitionerrequestdetailsdata && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.request.id,
            enquiryData: dataArray,
            minuteArray: filterminutesArray,
            hrsArray: filterhrsArray,
            subCatArray: subcategory,
            originalPriceText: priceoriginal,
            discountPrice: pricedis,


        });

    }

    const _redirecttoGinipicPhoto = (item) => {
       // console.log(item)
        let dataArray = []

        var dateobj = JSON.parse(openrequestenquirydetailsdata.openrequestenquirydetailsdata && openrequestenquirydetailsdata.openrequestenquirydetailsdata.data.request_enquiry.timeslots);
        let date = openrequestenquirydetailsdata.openrequestenquirydetailsdata && openrequestenquirydetailsdata.openrequestenquirydetailsdata.data.request_enquiry.date + " " + "00:00:00"
        // console.log(dateobj."morning")

        let timeslots = JSON.parse(dateobj);
      //  console.log(timeslots.morning)

        // let allTimeSlots=[]
        let hrsArray = []
        let minArray = []
        let allTimeSlots = (timeslots.morning).concat(timeslots.afternoon).concat(timeslots.evening)
      //  console.log(allTimeSlots)
        allTimeSlots.map((slot, index) => {
            // console.log(slot.substring())
            var hrs = slot.substr(0, slot.indexOf(':'));
            var minute = slot.substr(slot.length - 2);
            // var lastThree = slot.substr(slot.length - 2);
            hrsArray.push(hrs)
            minArray.push(minute)


        })


        let newhrsArray = hrsArray.sort(function (a, b) { return a - b });
        let newminArray = minArray.sort(function (a, b) { return a - b });
        let filterhrsArray = newhrsArray.filter((item, index) => newhrsArray.indexOf(item) === index)
        let filterminutesArray = newminArray.filter((item, index) => newminArray.indexOf(item) === index)
        let filetrDayArray = allTimeSlots.filter((item, index) => allTimeSlots.indexOf(item) === index)
       // console.log(filetrDayArray)


        let obj = {
            date_time: date,
            timeslots: dateobj
        }
        dataArray.push(obj)
        let priceoriginal = requestArray && requestArray.rrp_price
        let pricedis = requestArray && requestArray.your_price




        let calenderobj = {
            date: openrequestenquirydetailsdata.openrequestenquirydetailsdata && openrequestenquirydetailsdata.openrequestenquirydetailsdata.data.request_enquiry.date,
            requestId: practitionerrequestDetailsdata.practitionerrequestdetailsdata && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.request.id,
            enquiryData: dataArray,
            minuteArray: filterminutesArray,
            hrsArray: filterhrsArray,
            subCatArray: subcategory,
            originalPriceText: priceoriginal,
            discountPrice: pricedis,
            bookingDetails: item,
            allTimeSlots: filetrDayArray,
        }

        setLocalStorageData("calenderobj", calenderobj)
        setLocalStorageData("requestIdopen", item.uuid)
        // setLocalStorageData("bookingId", item.booking_id)
        setLocalStorageData("bookingId", item.uuid)
      
     

        if (item.booking.ginipig_images.length !== 0) {
            let itemDetails = {
                bookingId:openrequestenquirydetailsdata.openrequestenquirydetailsdata && openrequestenquirydetailsdata.openrequestenquirydetailsdata.data.request_enquiry.uuid,
                user: item.user,
                ginipig_images: item.booking.ginipig_images,
                ginipigreview:false,
                requestType: 2
            }

            let photocloseobj={
                details: itemDetails,
                ginipigreview:false,
                requestId: requestArray.uuid
            }
             setLocalStorageData("photocloseobj",photocloseobj)
            setLocalStorageData("photoObj", itemDetails)

            window.scrollTo(0, 0);
            history.push({
                pathname: '/photo-ginipig',
                details: itemDetails,
                ginipigreview:false,
                requestId: requestArray.uuid
            });

        }


    }



    const __apiCallDecline = useCallback(async (data) => {

        try {

            let checkToken = "";
            let LocalDatatoken = getLocalStorageData("loginData")
            let registerData = getLocalStorageData("practitionersignupData")

            if (registerData != "" && registerData !== undefined && registerData !== null) {
                checkToken = registerData.token;
            }
            else if (LocalDatatoken !== "" && LocalDatatoken !== undefined && LocalDatatoken !== null) {
                checkToken = LocalDatatoken.token;
            }

            dispatch(resetdeclineenquiryAPI_Action());
            await dispatch(declineenquiryAPI_Action(data, checkToken));
            window.scrollTo(0, 0);

        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    });



    const declineButtonClick = (id) => {

        setBookingId(id)

    }
    const sendDeclineButtonClick = (e, item, status) => {
        setLocalStorageData("delineBooking", item)
        setLocalStorageData("formDatadecline", formData.decline_reason_id)

        if (status === "cancel") {

            cancelButtonClick(item);
        }
        else {
         //   console.log(formData.decline_reason_id)
            let formDatanew = {
                id: item.uuid,
                booking_reason_id: formData.decline_reason_id,
            }

            openModal(e);
            __apiCallDecline(formDatanew)
            // window.scrollTo(0, 0);
            // history.push({
            //     pathname: '/request-decline',
            //     bookingDetails: item,
            //     formData: formData.decline_reason_id
            // });
        }
    }

    const openModal = (e) => {
        e.preventDefault();
        setIsOpen(true);
    }

    const closeModal = (item) => {
        setIsOpen(false);

    }

    // if (declineenquirydata.isError === true) {
    //     dispatch(resetdeclineenquiryAPI_Action());
    //     closeModal()
    // }

    if (declineenquirydata.isSuccess === true) {
        dispatch(resetdeclineenquiryAPI_Action());
        closeModal()

        window.scrollTo(0, 0);
        history.push({
            pathname: '/request-decline',
            bookingDetails: true,

        });
    }

    if ( declineenquirydata.declineenquirydata && declineenquirydata.declineenquirydata.status_code === 401) {
        dispatch(resetdeclineenquiryAPI_Action());
        dispatch(resetLoginAPI_Action())
        closeModal()

        window.scrollTo(0, 0);
        history.push({
            pathname: '/login',

        });
    }

   
    const _onCancelClick = () => {

        updateFormData({
            ...formData,
            clickCancel: true,
        });
    }


    const cancelButtonClick = (item) => {
        // let bookingId = practitionerrequestDetailsdata.practitionerrequestdetailsdata && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.booking.id
        let formDatanew = {
            id: item.id,
            booking_reason_id: formData.decline_reason_id,
        }
        window.scrollTo(0, 0);
        history.push({
            pathname: '/confirm-cancel-booking',
            formDatanew: formDatanew
        });

    }

    const cancelAllButtonClick = () => {
        let requestId = requestArray && requestArray.uuid
        let formDatanew = {

            cancel_reason_id: formData.decline_reason_id,
            id: requestId
        }
        window.scrollTo(0, 0);
        history.push({
            pathname: '/confirm-cancel-booking',
            formDatanewall: formDatanew,
            cancel: "all"
        });

    }

    const toggleChangeReason = (id) => {
        let selectedBoxes = formData.decline_reason_id;
        let index = selectedBoxes.indexOf(parseInt(id)) // check to see if the name is already stored in the array
        if (index === -1) {
            selectedBoxes.push(parseInt(id))
        } else {
            selectedBoxes.splice(index, 1)
        }
        updateFormData({
            ...formData,
            decline_reason_id: [id],
            selectReasonId : id
        });

    }


    const editquatityButtonClick = (requestId) => {
        let ginipicArray = []
        let ginpiccount = practitionerrequestDetailsdata.practitionerrequestdetailsdata && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.request.ginipig_count
        for (let i = ginpiccount; i < 20; i++) {
            ginipicArray.push(i)
        }
        let editginiobj={
            requestId: practitionerrequestDetailsdata.practitionerrequestdetailsdata && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.request.id,
            ginipic_count: ginpiccount,
            bookings_count: location.bookings_count,
            ginipicArray: ginipicArray
        }
        setLocalStorageData("editginiobj",editginiobj)
        //  let bookingId = practitionerrequestDetailsdata.practitionerrequestdetailsdata && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.booking.id
        window.scrollTo(0, 0);
        history.push({
            pathname: '/edit-ginipig-quantity',
            requestId: practitionerrequestDetailsdata.practitionerrequestdetailsdata && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.request.id,
            ginipic_count: ginpiccount,
            bookings_count: location.bookings_count,
            ginipicArray: ginipicArray,
            requesttype:1

        })
    }


    
    let durationTimeZero = '';
    if (requestArray) {
        if (requestArray.hours === 0) {
            durationTimeZero = requestArray.minutes + 'm';
        } else {
            durationTimeZero = requestArray.hours + 'h' + '  ' + requestArray.minutes + 'm';
        }
    }


    return (
        <>
            <section className="bgcolor-gray common-section upcoming_past_booking_pg pb80">
                { practitionerrequestDetailsdata.practitionerrequestdetailsdata === null? 
                     <div style={{ width: "100%", marginTop: 250, textAlign: 'center' }}>
                             <LoadingSpinner />
                             <p style={{ fontSize: 20, marginTop: 10 }}>Loading ...</p>
                     </div> 
                     : 
                     <Container>
                     <Row>
                         <Col className="col-lg-11 m-auto">
                             <div className="navigationTop padright-0">
                                 <div className="heading-history">
                                     <div className="back-history">
                                         <button type="button" onClick={() => history.goBack()} className="back-btn" >
                                             {" "}
                                             <img src={LeftArrow} alt="arrow" />{" "}
                                         </button>
                                     </div>
                                 </div>
                                 <div className="spce_btns_box">
                                     <button className="spc_fill_btns">{bookingCount}/{requestArray && requestArray.ginipig_count} spaces filled</button>
                                 </div>
                             </div>
                             
                             {declineenquirydata.isError && declineenquirydata.declineenquirydata &&
                                                 declineenquirydata.declineenquirydata.hasOwnProperty(
                                                     "message"
                                                 ) ? (
                                                     <p style={{ color: "red" }}>
                                                         {
                                                             declineenquirydata.declineenquirydata.message
                                                         }{" "}
                                                     </p>
                                                 ) : null}
 
                             <div className="requirement_wrp padright-0">
                                 <div className="request_card mb-16">
                                     <RequestBookingCard
                                         treatmentTitle={subcategory}
                                         tagsName={requestArray && requestArray.treatment && requestArray.treatment.categories && requestArray.treatment.categories.name && requestArray.treatment.categories.name}
                                         hrs={requestArray && requestArray.hours }
                                         minutes={requestArray && requestArray.minutes}
                                       // duration={requestArray && requestArray.hours + "h" + " " + requestArray.minutes + "m"}
                                        //duration={durationTimeZero}
                                        originalPriceText={requestArray && requestArray.rrp_price && requestArray.rrp_price.toFixed(2)}
                                         discountPrice={requestArray && requestArray.your_price && requestArray.your_price.toFixed(2)}
                                         addressText={requestArray && requestArray.postcodes && requestArray.postcodes.city && requestArray.postcodes.city}
                                         slotTime={bookingDate}
                                         categoryIcon1={iconCase_Study}
                                         categorName1={requestArray && requestArray.expertise_level}
                                         categoryIcon2={iconNearly_there}
                                         categorName2={requestArray && requestArray.reason_require_ginipig}
                                     />
                                 </div>
 
                                 {requestArray && requestArray.photograph_required === 1 ||requestArray && requestArray.attributes_required === 1 ||requestArray && requestArray.is_patchtest_required === 1 ?
 
                                     <div className="model_req_box mb-8">
                                         <ModelRequirementCloseRequest
                                             content={requestArray}
                                             modelTitle="GiniPig Requirements"
                                             disciption={requestArray.model_requirements}
                                             requiredImg={iconPhoto}
                                             requiredPatchImg={iconPatch_test}
                                             patchText="A patch test is required for this treatment"
                                             requiredText="The Treatment/Service Provider requires you to add a photo to ensure perfect compatibility for this appointment"
                                         />
                                     </div>
                                     : null}
 
 
 
                                 <div className="all_request_box mt-40">
 
                                     <div className="request_boxs pending mb-40">
 
                                         {
                                             bookingDetails !== "" && bookingDetails !== null && bookingDetails !== undefined &&
                                             <div className="mt-16">
                                                 <CloseRequestAcceptCancelCard
 
                                                     userImg={bookingDetails && bookingDetails.user.profile_pic}
                                                     userName={bookingDetails && bookingDetails.user.name}
                                                     arrowButtonClick={() => _redirecttoGinipicPhoto(bookingDetails && bookingDetails)}
                                                     showImagearrow={bookingDetails && bookingDetails.booking.ginipig_images.length !== 0 ? true :false}
                                                     //showArrow={bookingDetails.booking.ginipig_images}
                                                     imgArrow={LeftArrow}
                                                     declineButtonClick={() => declineButtonClick(bookingDetails && bookingDetails.id)}
                                                     acceptButtonClick={() => acceptButtonClick(bookingDetails && bookingDetails)}
                                                     ratingImg={star}
                                                     reliabilityCount={bookingDetails.user.reliabilityAverage === 0 ? 0 :Math.ceil(bookingDetails.user.reliabilityAverage)}
                                                     etiquetteCount={bookingDetails.user.etiquetteAverage === 0 ? 0 :Math.ceil(bookingDetails.user.etiquetteAverage)}
                                                     //suitabilityCount={bookingDetails.user.suitabilityAverage === 0 ? 0 : Math.ceil(bookingDetails.user.suitabilityAverage)}
                                                      ratingPoint={bookingDetails.user.review_rating_ginipig}
                                                     AcceptButtonText="Select & Accept"
                                                     DeclineButtonText="Decline"
                                                 />
 
 
                                                 {
                                                     bookingDetails.id === bookingId &&
                                                     <div className="decline_boxs">
 
                                                         <DeclineBookingCard
                                                             toggleChangeReason={toggleChangeReason}
                                                             flag="cancelOpenRq"
                                                             reasonArrayId={formData.selectReasonId}
                                                             sendDeclineButtonClick={(e) => sendDeclineButtonClick(e, bookingDetails, "decline")}
                                                             description={declinereasonslistData.declinereasonslistdata && declinereasonslistData.declinereasonslistdata.data.reasons}
                                                             headingtitle="Select a reason to explain why you are declining"
 
                                                         />
 
 
                                                     </div>
                                                 }
                                             </div>
 
 
 
                                         }
 
 
                                     </div>
 
                                 </div>
 
                             </div>
 
                             <Modal
                                 isOpen={modalIsOpen}
                                 onRequestClose={closeModal}
                                 to
                                 style={customStyles}
                                 shouldCloseOnOverlayClick={false}
                                 contentLabel="Example Modal"
                             >
 
 
                                 <div>
                                     <LoadingSpinner />
                                 </div>
 
                             </Modal>
 
                         </Col>
                     </Row>
                 </Container>
            
                }
                </section>
        </>
    );
}

export default AcceptOpenRequest;