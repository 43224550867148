import * as types from "../types/index";
import { setInitialState } from "../InitialState";


const INITIAL_STATE = setInitialState({
    companycategorylistdata: null,
});
// postcode,lang,lat,address

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.COMPANYCATEGORYLIST_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case types.COMPANYCATEGORYLIST_RECEIVE:
            return {
                ...state,
                isFetching: false,
                isSuccess: true,
                companycategorylistdata: action.payload.success
            };
        case types.COMPANYCATEGORYLIST_FAILURE:
            return {
                ...state,
                isFetching: false,
                isError: true,
                companycategorylistdata: action.payload.response.error,
            };
        case types.RESET_COMPANYCATEGORYLIST:
            return {
                ...state,
                isFetching: false,
                isError: false,
                companycategorylistdata: null,
            };
        default:
            return {
                ...state
            }
    }
}