
import React, { useCallback, useState, useEffect } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import { LeftArrow, star, iconCase_Study, iconNearly_there, icon_unselected_star, reviewAvatar } from '../../config/images';
import RequestBookingCard from "../../components/UIComponent/RequestBookingCard";
import TransparentButton from "../../components/UIComponent/TransparentButton";
import CloseRequestAcceptCancelCard from "../../components/UIComponent/CloseRequestAcceptCancelCard";
import DeclineBookingCard from "../../components/UIComponent/DeclineBookingCard";
import RatingPointCard from "../../components/UIComponent/RatingPointCard";
import LargeButton from '../../components/LargeButton';
import { clearLocalStorage, getLocalStorageData, setLocalStorageData } from '../../utils/storage'
import { acceptbookingAPI_Action, resetpractitionerReviewtoGiniAPI_Action,resetimageUploadAPI_Action,
    resetcreateAppointmentData_Action,resettreatmentdetailsAPI_Action, resetedittreatmentAPI_Action,
     resetcreaterequestAPI_Action,  resetupcomingbookingAPI_Action, upcomingbookingAPI_Action, resetgoginipigprofileAPI_Action, resettreatmentaddreviewAPI_Action, completebookingAPI_Action, practitionerReviewtoGiniAPI_Action } from '../../stores/actions'

const ThanksForReviewingGiniPig = (props) => {
    const dispatch = useDispatch();
    const location = useLocation()
    let history = useHistory();
    const [bookingData, setbookingData] = useState();
    const [etiquette, setEtiquette] = useState(0);
    const [reliability, setReliability] = useState(0);
    const [suitability, setSuitability] = useState(0);
    const [totalrating, setTotalrating] = useState(0);
    const practitionerreviewgini = useSelector(state => state.practitionerreviewgini);


    useEffect(() => {
        window.addEventListener('popstate', function (event) {
            __redirectProfilenew()
            window.location.reload(false);
        }, false);
        if (!location.isfromreview) {
            __redirectProfilenew()
        } else {
            let bookingData = getLocalStorageData("bookingreviewData")
            setbookingData(bookingData)
        }
        clearLocalStorage('bookingreviewData')


    }, [])

    const __redirectProfilenew = async () => {
        window.scrollTo(0, 0);
        dispatch(resetupcomingbookingAPI_Action());
        dispatch(resetpractitionerReviewtoGiniAPI_Action());
        history.push({
            pathname: '/practitioner-account',
            iswindowreload: true

        });
    }

    const __redirectProfile = () => {
        window.scrollTo(0, 0);
        dispatch(resetupcomingbookingAPI_Action());
        dispatch(resetpractitionerReviewtoGiniAPI_Action());
        history.push('/upcoming-pasting-book');
    }

    const createAppointmentClick = () => {
        setLocalStorageData("isfromAccount", true)
        clearLocalStorage("requestDataLocal")

        clearLocalStorage("RedirectPathName")
        clearLocalStorage("selectedtreatmentId")
        clearLocalStorage("pathnamereviewedit")
        clearLocalStorage("treatmentcatId")
        clearLocalStorage("isfromAccount")

        dispatch(resetcreaterequestAPI_Action());
        dispatch(resetimageUploadAPI_Action())
        dispatch(resetcreateAppointmentData_Action());
        dispatch(resettreatmentdetailsAPI_Action());
        dispatch(resettreatmentdetailsAPI_Action());
        dispatch(resetedittreatmentAPI_Action());
        window.scrollTo(0, 0);
        history.push('/treatment-select')
    }



    return (
        <>
            <section className="bgcolor-gray common-section thankyou_review_ginipig_pg pb80">
                <Container>
                    <Row>
                        <Col className="col-lg-11 m-auto">

                            <div className="appoint_conf_box pb-32 pt-70">
                                <div className="heading_content">
                                    <h2 className="title "> Thank You For Leaving Your Review </h2>
                                    <p className="decs mt-8">Everyone Loves Hearing How Well They've Done</p>
                                </div>
                            </div>

                            <div className="requirement_wrp padright-0">

                                <h3 className="rating-heading mb-8 mt-70">Your rating</h3>
                                <div className="d-flexbox">
                                    <div className="first-div">

                                        <RatingPointCard
                                            ratingName="Reliability"
                                            selectedimage={star}
                                            initialRating={practitionerreviewgini.practitionerreviewginidata && practitionerreviewgini.practitionerreviewginidata.data && practitionerreviewgini.practitionerreviewginidata.data.review_data && practitionerreviewgini.practitionerreviewginidata.data.review_data.reliability}
                                            unselectedimage={icon_unselected_star}
                                            disabled={true}
                                        />
                                        <RatingPointCard
                                            ratingName="Friendliness"
                                            selectedimage={star}
                                            initialRating={practitionerreviewgini.practitionerreviewginidata && practitionerreviewgini.practitionerreviewginidata.data && practitionerreviewgini.practitionerreviewginidata.data.review_data && practitionerreviewgini.practitionerreviewginidata.data.review_data.etiquette}
                                            unselectedimage={icon_unselected_star}
                                            disabled={true}
                                        />
                                        {/* <RatingPointCard
                                            ratingName="Suitability"
                                            selectedimage={star}
                                            initialRating={practitionerreviewgini.practitionerreviewginidata && practitionerreviewgini.practitionerreviewginidata.data && practitionerreviewgini.practitionerreviewginidata.data.review_data && practitionerreviewgini.practitionerreviewginidata.data.review_data.suitability}
                                            unselectedimage={icon_unselected_star}
                                            disabled={true}
                                        /> */}
                                    </div>
                                    <div className="second-div">
                                        <div className="rating_points">
                                            <img src={star} className="stars" />

                                            <p className="points">{(Math.round(practitionerreviewgini.practitionerreviewginidata && practitionerreviewgini.practitionerreviewginidata.data && practitionerreviewgini.practitionerreviewginidata.data.review_data && practitionerreviewgini.practitionerreviewginidata.data.review_data.overall * 10) / 10).toFixed(1)}</p>
                                        </div>
                                    </div>
                                </div>


                                <div className="all_request_box confirm_appointment_box mt-40">
                                    <div className="request_boxs pending mb-40">
                                        <div className="confirm-list mb-16">
                                            <CloseRequestAcceptCancelCard
                                                userImg={bookingData && bookingData.user && bookingData.user.profile_pic}
                                                userName={bookingData && bookingData.user && bookingData.user.name}
                                                imgArrow={LeftArrow}
                                                ratingImg={star}
                                                showImagearrow={false}
                                                ratingPoint={bookingData && bookingData.user && bookingData.user.review_rating_ginipig}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="review-div">
                                <div className="pd32 review-btn-1">
                                    <LargeButton
                                        ButtonType="button"
                                        ButtonText=" Back To My Account "
                                        nextButtonClick={__redirectProfile}
                                    />
                                </div>

                                <div className="pd32 review-btn-2">
                                    <LargeButton
                                        ButtonType="button"
                                        ButtonText=" Create More Appointments "
                                        nextButtonClick={createAppointmentClick}
                                    />
                                </div>
                                </div>


                            </div>

                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default ThanksForReviewingGiniPig;