import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {Container,Row,Col} from 'react-bootstrap';
import { LeftArrow } from '../../config/images';

const UnderDevelopment = () => {
    
    let history = useHistory();

    useEffect(() => { 
        window.scrollTo(0,0);
      },[]);
  
       

    return (
        <>
          <section className="lp-hero" style={{backgroundColor:'#fff'}}>
              <Container>
                  <Row>
                      <Col className="col-lg-12">
                        <div className="navigationTop">
                            <div className="heading-history">
                                <div className="back-history">
                                    <button type="button" onClick={() => history.goBack()} className="back-btn" >
                                        {" "}
                                        <img src={LeftArrow} alt="arrow" />{" "}
                                    </button>
                                </div>
                                {/* <div className="history-title">
                                    <h2> Treatment Information  </h2>
                                </div> */}
                            </div>
                        </div>

                        <div className="pb-32 mb-70">
                          <h1 className="mt-5 text-center"> Under development </h1>
                        </div>
                      </Col>
                  </Row>
              </Container>
              </section>     
        </>
    );
}

export default UnderDevelopment;