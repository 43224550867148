import * as types from "../types/index";
import { setInitialState } from "../InitialState";


const INITIAL_STATE = setInitialState({
    practitionerportfoliolistdata: null,
});


export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.PRACTITIONERPORTFOLIOLIST_REQUEST:
            return {
                ...state,
                isFetching: true,
                isError: false,
                isSuccess: false,
            };
        case types.PRACTITIONERPORTFOLIOLIST_RECEIVE:
            return {
                ...state,
                isFetching: false,
                isSuccess: true,
                isError: false,
                practitionerportfoliolistdata: action.payload.success
            };
        case types.PRACTITIONERPORTFOLIOLIST_FAILURE:
            return {
                ...state,
                isFetching: false,
                isError: true,
                isSuccess: false,
                practitionerportfoliolistdata: action.payload.response.error,
            };
        case types.RESET_PRACTITIONERPORTFOLIOLIST:
            return {
                ...state,
                isFetching: false,
                isError: false,
                isSuccess: false,
                practitionerportfoliolistdata: null,
            };
        default:
            return {
                ...state
            }
    }
}