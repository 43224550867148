import React from 'react';
import {Link,useHistory} from 'react-router-dom';
import ButtonComponent from '../who/ButtonComponent';
import {og_lpimg3} from '../../../config/images';
import bannernew2 from '../../../assets/images/bannernew2.jpg';

import { useDispatch, useSelector } from 'react-redux';
import {  resetpractitionerSignupSaveAPI_Action } from '../../../stores/actions'
import { Button } from 'react-bootstrap';

const CommunitySection = (props) =>{
  const dispatch = useDispatch();
    let history = useHistory();

  const loginUserData = useSelector(state => state.login);
    const practitionersignupaddinfoData = useSelector(state => state.practitionersignupaddinfo);
    const registerData = useSelector(state => state.register);
    const Data = JSON.stringify(registerData);
    const __reDirectPage = (e) =>{
       //console.log("redirect page");
        let type="";
        const loginType=loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.user.type: "" : "";
        const PractitionerType= practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.user.type:"" : "";
        const RegisterToken= registerData.registerdata ? registerData.registerdata.data ? registerData.registerdata.data.user.type:"" : "";
          
        if(loginType!=="")
        {
          type=loginType;
        } else if(PractitionerType!== "")
        {
          type=PractitionerType;
        } else if(RegisterToken!=="")
        {
          type=RegisterToken;
        }
        if(type === 'ginipig')
        {   
         // console.log('ginipig type'); 
            window.scrollTo(0, 0);
            history.push('/logout-confirmation');
        } else if(type === 'practitioner'){
         // console.log('practitioner type')
            dispatch(resetpractitionerSignupSaveAPI_Action());
            window.scrollTo(0, 0);
          history.push('/logout-confirmation');
        } else {
            dispatch(resetpractitionerSignupSaveAPI_Action());
            window.scrollTo(0, 0);
            history.push('/signup-practitioner');
        }

    }
 
    return (
        <>
        <div className="img-box leftSideImg">
            <div className="imgwidth whoarepig" style={{ backgroundImage:`url(" ${bannernew2} ")`}}></div>
          <div className="img-info">
            <div className="info-inside">
              <h3> Partner With Us </h3>
              <div className="info-content">
                <div className="contents">
                  <p> We’re your people. We know how hard it can be to find <b>reliable</b> models to perfect your Treatments or Services on, so we created this <b>super-friendly</b> space. By being part of our <b>Tribe</b>, you can <b>relax</b>, safe in the knowledge that we will do everything possible to pair you with <b>perfect GiniPigs.</b> We also look after you if you suffer a last-minute cancellation or a no-show, because we’re your <b>Beauty & Lifestyle BFF. </b>
                  </p>
                </div>
                <div className="common-box">
                
                  <Link 
                  // to={props.RedirectLink}  
                  //  onClick={__reDirectPage} 
                    onClick={(e) => {
                      __reDirectPage('/signup-practitioner')
                  }}
                   className="common-btns commonActiveBtn">
                   Find Me GiniPigs
                  </Link> 
                  <ButtonComponent 
                    btnName="Tell Me More"
                    RedirectLink="/practitioner"
                  />
                  
                  </div>
              </div>
            </div>
          </div>
        </div> 
        </>
    );
}

export default CommunitySection;