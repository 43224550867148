import React, { useCallback, useState, useEffect } from "react";
import DoubleConfirmationPopup from '../../components/UIComponent/DoubleConfirmationPopup';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { getLocalStorageData ,clearLocalStorage} from '../../utils/storage'
import { acceptbookingAPI_Action, cancelallbookingAPI_Action, 
    practionercancelbookingAPI_Action,resetcancelallbookingAPI_Action, 
    resetpractionercancelbookingAPI_Action, resetLoginAPI_Action, 
    upcomingbookingAPI_Action, resetgoginipigprofileAPI_Action,
     resettreatmentaddreviewAPI_Action } from '../../stores/actions'

import { Container, Row,Col } from "react-bootstrap";
import { LeftArrow } from '../../config/images';
import LoadingSpinner from "../../components/UIComponent/LoadingSpinner"
import Modal from 'react-modal';



const customStyles = {
    overlay: {
        background: "#dee2e663", zIndex: 999999
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        border: "none",
        background: "none"
    }
};




const cancelFormData = Object.freeze({
    id:"",
    cancel_reason_message:"",
    cancel_reason_message_text:""
});


const initialErrorData = Object.freeze({
    cancel_reason_message_error: "",
    is_form_valid: false,
});

const CancelBooking = (props) => {
    let history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const [modalIsOpen,setIsOpen] = useState(false);
    const [character_count, setCharactercount] = useState(500);
    const [errorData, updateErrorData] = useState(initialErrorData);
    const [formData, updateFormData] = useState(cancelFormData);
    const practitionercancelbookingdata = useSelector(state => state.practitionercancelbookingdata);
    const cancelallbookingdata = useSelector(state => state.cancelallbookingdata);
    


    const openModal = (e) => {
        //e.preventDefault();
        setIsOpen(true);
    }

    const closeModal = (item) => {
        setIsOpen(false);

    }
    useEffect(() => {
        if(location.formDatanew===undefined){
            history.push({
                pathname: '/practitioner-account',
                iswindowreload:false
    
            });
        }
    })

    //let textareaMassage = cancel_reason_message_text;
    const __handleClickYes = (e) =>{
      
        let cancelDataNew = {
            id:location.formDatanew.id,
            cancel_reason_message:formData.cancel_reason_message_text
        }
            openModal(e);
           __apiCallCancel(cancelDataNew)
    }
    
  const __apiCallCancel = useCallback(async (data) => {
    // console.log("innside cancel")
 
     try {
 
       let checkToken = "";
             let LocalDatatoken = getLocalStorageData("loginData")
             let registerData = getLocalStorageData("practitionersignupData")
     
             if (registerData != "" && registerData!== undefined && registerData !== null) {
                 checkToken = registerData.token;
             }
             else if (LocalDatatoken !== "" && LocalDatatoken !== undefined && LocalDatatoken!==null) {
                 checkToken = LocalDatatoken.token;
             }
             //console.log("loc-cancel",location.cancel)
             if(location.cancel === 'all'){
            
                dispatch(resetcancelallbookingAPI_Action());
                await dispatch(cancelallbookingAPI_Action(data, checkToken));              
             }else{
                
                dispatch(resetpractionercancelbookingAPI_Action());
                await dispatch(practionercancelbookingAPI_Action(data, checkToken));  
             }
      
     } catch (error) {
       console.error(" profile ::  profile() :: Exception ==", error);
     }
   });

 
   
if( practitionercancelbookingdata.isSuccess===true){
    dispatch(resetpractionercancelbookingAPI_Action());  
    closeModal()

    window.scrollTo(0, 0);
  
    history.push({
      pathname: '/cancel-booking-confirmation',
      fromcancelbook:true
    });
  
}

   
if(practitionercancelbookingdata.practitionercancelbookingdata &&  practitionercancelbookingdata.practitionercancelbookingdata.status_code===401){
    dispatch(resetpractionercancelbookingAPI_Action());  
    dispatch(resetLoginAPI_Action())
    closeModal()

    window.scrollTo(0, 0);
    clearLocalStorage("loginData")
    clearLocalStorage("practitionersignupData")
    history.push({
      pathname: '/login',
    });
  
}
   
if( cancelallbookingdata.isSuccess===true){
    dispatch(resetcancelallbookingAPI_Action());  
    closeModal()

    window.scrollTo(0, 0);
  
    history.push({
      pathname: '/cancel-booking-confirmation',
      fromcancelbook:true
    });
}



const __onTextChange = (e) => {
    //console.log(e.target.name, e.target.value)
    updateFormData({
        ...formData,
        [e.target.name]: e.target.value
    });

    if (e.target.name === "cancel_reason_message_text" && e.target.value !== "") {
        setCharactercount(500 - e.target.value.length)
    } else {
        setCharactercount(500)
    }
    if (e.target.name === "cancel_reason_message_text" && e.target.value.length > 25) {
        //console.log("inside",e.target.value.length)
        updateErrorData({
            ...errorData,
            is_form_valid: true,
            cancel_reason_message_error: ""
        });
    }
    
};


const checkBioValidation = (e) => {
    const { id, value } = e.target;
    if (!value) {
        updateErrorData({
            ...errorData,
            is_form_valid: false,
            cancel_reason_message_error: "Should be minimum 25 characters long.",
        });

    } else if (value.length < 25) {
        updateErrorData({
            ...errorData,
            is_form_valid: false,
            cancel_reason_message_error: "Should be minimum 25 characters long.",
        });
    } else if (value.length > 500) {
        updateErrorData({
            ...errorData,
            is_form_valid: false,
            cancel_reason_message_error: "Only 500 Characters are Allowed.",
        });
    } else {
        updateErrorData({
            ...errorData,
            is_form_valid: true,
            cancel_reason_message_error: "",
        });
    }


};





    return (
        <>

            <div className="cancelBooking_pg pb80">
                <Container>
                    <Row>
                        <Col className="col-xl-11 m-auto">
                                <div className="navigationTop">
                                  <div className="heading-history">
                                      <div className="back-history">
                                          <button type="button" onClick={() => history.goBack()} className="back-btn" >
                                              {" "}
                                              <img src={LeftArrow} alt="arrow" />{" "}
                                          </button>
                                      </div>
                                      <div className="history-title">
                                          <h2>Cancel Booking </h2>
                                      </div>
                                  </div>
                                </div>


                            <div className="desciption">
                                <div className="display-flex">
                                    <p className="subdesc"> Are you sure you would like to  cancel your booking? </p>
                                </div>
                                
                                {/* <p className="subdesc">if you cancel within 36 hours of your booking, you will not [insert relevant rule]</p> */}
                                <p className="subdesc"> If you cancel your booking, 15% of the treatment amount will be deducted from your account </p>

                                <div className="textarea-box review-comments">
                                    <p className="textarea-label">Leave a reason for cancelling</p>
                                    {errorData.cancel_reason_message_error &&
                                            errorData.cancel_reason_message_error !== "" ? (
                                                <p className="error">
                                                    <p style={{ color: "red" }}>
                                                        {errorData.cancel_reason_message_error}
                                                    </p>
                                                </p>
                                            ) : null}
                                    
                                    {practitionercancelbookingdata.isError && practitionercancelbookingdata.practitionercancelbookingdata &&
                                        practitionercancelbookingdata.practitionercancelbookingdata.hasOwnProperty(
                                            "message"
                                        ) ? (
                                            <p style={{ color: "red" }}>
                                            {
                                                practitionercancelbookingdata.practitionercancelbookingdata.message
                                            }{" "}
                                        </p>
                                        ) : null}
                                            
                                    <div className="addicontextarea" >
                                        <textarea
                                            id="cancel_reason_message_text"
                                            name="cancel_reason_message_text"
                                            value={formData.cancel_reason_message_text}
                                            onChange={__onTextChange}
                                            onBlur={checkBioValidation}
                                            maxlength="500"
                                        />
                                        {
                                            character_count === 500 ?
                                                <span className="char-span">{character_count} Character limit</span> :
                                                <span className="char-span">{character_count} Remaining</span>
                                        }
                                    </div>
                                </div>

                                <div className="two-cols mt-24 mb-24">
                                <button 
                                    className="btns-gray" 
                                    onClick={(e)=>{ __handleClickYes()}} 
                                    disabled={errorData.is_form_valid === false}
                                    >Yes, cancel this booking </button>
                                <button className="btns-gray" onClick={(e)=>{history.goBack()}} >No, do not cancel this booking </button>
                                </div>
                            </div>


                        
                            <Modal
                                isOpen={modalIsOpen}
                                onRequestClose={closeModal}
                                to
                                style={customStyles}
                                shouldCloseOnOverlayClick={false}
                                contentLabel="Example Modal"
                            >
                                <div>
                                    <LoadingSpinner />
                                </div>

                            </Modal>


                        </Col>
                    </Row>
                </Container>
            </div>
          
        </>
    );
}

export default CancelBooking;