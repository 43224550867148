import React from 'react';
import CommonFlipImg from './CommonFlipImg';
import ButtonComponent from './ButtonComponent';
import bannernew1 from '../../../assets/images/bannernew1.jpg';

// const img = 'https://placeimg.com/640/480/any';

const WhoArePig = (props) => {
    return (
        <>
            <section className="whoarepig-section" >
        <div className="img-box leftSideImg">
            <div className="imgwidth whoarepig" style={{ backgroundImage:`url(" ${bannernew1} ")`}}></div>
          <div className="img-info">
            <div className="info-inside">
            <h3> Why We Are Your Beauty & Lifestyle BFF. </h3>
              <div className="info-content">
                <div className="contents">
                  <p> <b>GiniPig definition ~ Traditionally called ‘models’, a GiniPig is a fabulous person who enables Providers to perfect their skills, whether they’re new to their Industry or Pro’s. We affectionately call them GiniPigs as they are worthy of their own new title. They can spot a fab opportunity when they see it. </b>
                  </p>
                  <p>We bridge the gap between GiniPigs and Treatment & Service Providers in the <b> Hair, Aesthetics, Beauty </b> and <b> Wellness </b> industries. Providers may be<b> Training </b> or building their <b> Portfolios</b>, while the GiniPig enjoys some <b> luxury </b> for a fraction of the price. Treatments & Services with at least 30% off & starting <b> from only £7.50</b>. It’s win-win!</p>
                </div>
                <div className="common-box">
                  <ButtonComponent 
                    btnName="GiniPig’s You Want To Know More?"
                    RedirectLink="/whoggp"
                  />
                  </div>
              </div>
            </div>
          </div>
        </div> 
        










                
                
             </section>    
        </>
    );
}

export default WhoArePig;