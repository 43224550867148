
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, } from 'react-bootstrap';
import { LeftArrow, star, iconCase_Study, iconPatch_test,iconNearly_there, iconPhoto, reviewAvatar } from '../../config/images';
import RequestBookingCard from "../../components/UIComponent/RequestBookingCard";
import CloseRequestAcceptCancelCard from "../../components/UIComponent/CloseRequestAcceptCancelCard";
import { clearLocalStorage, getLocalStorageData, setLocalStorageData } from '../../utils/storage'
import { upcomingbookingAPI_Action, 
    practitionerrequestDetailsAPI_Action, pendingbookingDetailsAPI_Action} from '../../stores/actions'
import LoadingSpinner from "../../components/UIComponent/LoadingSpinner"
import ModelRequirementCloseRequest from '../../components/ModelRequirementCloseRequest'
import {get24HrsTimeFormat}from '../../utils/utility'



const PractitionerReviewGiniPig = (props) => {


    let history = useHistory();
    const dispatch = useDispatch();
    const [requestArray, setrequestArray] = useState([]);
    const [subcategory, setsubcategory] = useState("");
    const [bookingDate, setbookingDate] = useState();
    
    const [bookingId, setBookingId] = useState();

    const [confirmArray, setconfirmArray] = useState([]);
    const [requestType, setRequestType] = useState([])



    const pendingbookingDetails = useSelector(state => state.pendingbookingdetails);
    const practitionerrequestDetailsdata = useSelector(state => state.practitionerrequestdetailsdata);



    // Use effects
    useEffect(() => {

        try {
            let checkToken = "";
            let LocalDatatoken = getLocalStorageData("loginData")
            let registerData = getLocalStorageData("practitionersignupData")

            if (registerData != "" && registerData !== undefined && registerData !== null) {
                checkToken = registerData.token;
            }
            else if (LocalDatatoken !== "" && LocalDatatoken !== undefined && LocalDatatoken !== null) {
                checkToken = LocalDatatoken.token;
            }
            if (checkToken === '') {
                window.scrollTo(0, 0);
                history.push('/login');
            }

            if (checkToken !== '') {
                dispatch(upcomingbookingAPI_Action(checkToken));


            } else {
                history.push('/login');
            }
         
            clearLocalStorage("requestreviewData")
            clearLocalStorage("bookingreviewData")

            let requestData = getLocalStorageData("confirmData")
           // console.log(requestData)
            setRequestType(requestData.requestType)

            _apicallNew(requestData.requestId,checkToken)

      
        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    }, [])


    const _apicallNew = async(requestId,checkToken)=>{
        
        let requestData = getLocalStorageData("confirmData")
        if (requestData.requestType === 1) {
            await dispatch(pendingbookingDetailsAPI_Action(requestId, checkToken));
        }else if (requestData.requestType === 2) {
            await dispatch(practitionerrequestDetailsAPI_Action(requestId, checkToken));
        }
        
    }
    useEffect(() => {

            let requestArray = []
            let requestData = getLocalStorageData("confirmData")
            if (requestData.requestType === 1) {

                requestArray = pendingbookingDetails.pendingbookingdetailsdata && pendingbookingDetails.pendingbookingdetailsdata.data.booking.request

            } else {

                requestArray = practitionerrequestDetailsdata.practitionerrequestdetailsdata && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.request
            }

            if (requestArray !== null) {
                let subCatArray = []
                requestArray.treatment.treatment_sub_category_new.forEach(element => {
                    subCatArray.push(element.name)

                })

                var str;
                if (subCatArray.length === 1) {
                    str = subCatArray.join()
                } else if (subCatArray.length === 2) {

                    str = subCatArray.join(" & ")
                } else if (subCatArray.length === 3) {
                    str = subCatArray[0] + "," + subCatArray[1] + " & " + subCatArray[2]
                }
                let newDate = ""
                let displayDate = requestArray.request_timeslots[0].date_time


                if (displayDate !== null && displayDate !== undefined) {
                    let day = displayDate.substring(8, 10);
                    let displayDay = ""
                    if (day < 9) {
                        displayDay = day.substring(1, 2)
                    } else {
                        displayDay = day
                    }

                    let n = displayDate.substring(5, 7)
                    let newn = ""
                    if (n < 10) {
                        newn = n.substring(1, 2)
                    } else {
                        newn = n
                    }

                    var month = new Array();
                    month[1] = "January";
                    month[2] = "February";
                    month[3] = "March";
                    month[4] = "April";
                    month[5] = "May";
                    month[6] = "June";
                    month[7] = "July";
                    month[8] = "August";
                    month[9] = "September";
                    month[10] = "October";
                    month[11] = "November";
                    month[12] = "December";

                    var displaymonth = month[newn];

                    if (displayDay == 1) {
                        newDate = displayDay + "st" + " " + displaymonth + " "
                    } else if (displayDay == 2) {
                        newDate = displayDay + "nd" + " " + displaymonth + " "
                    } else if (displayDay == 3) {
                        newDate = displayDay + "rd" + " " + displaymonth + " "
                    } else {
                        newDate = displayDay + "th" + " " + displaymonth + " "
                    }
                }

                let period = ""
                let hours = ''
                let [sHours, minutes] = ""
                let time = ""
                if (requestData.requestType === 1) {
                    time = pendingbookingDetails.pendingbookingdetailsdata && pendingbookingDetails.pendingbookingdetailsdata.data && pendingbookingDetails.pendingbookingdetailsdata.data.booking.time

                } else {
                    time = requestArray.request_timeslots[0].start_time
                }



                if (time !== null) {
                    [sHours, minutes] = time && time.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
                    period = +sHours < 12 ? 'AM' : 'PM';
                    hours = +sHours % 12 || 12;
                }
                let timein24hrs= get24HrsTimeFormat(time)

                let displaydate = newDate + new Date().getFullYear() + " @ " +timein24hrs

                setbookingDate(displaydate)
                setsubcategory(requestArray.treatment.title)
                if (requestData.requestType === 1) {
                    let confirm = []
                    confirm.push(pendingbookingDetails.pendingbookingdetailsdata && pendingbookingDetails.pendingbookingdetailsdata.data && pendingbookingDetails.pendingbookingdetailsdata.data.booking)
                    setconfirmArray(confirm)
                } else {

                    setconfirmArray(practitionerrequestDetailsdata.practitionerrequestdetailsdata && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.completedBookings)

                }


            }

            setrequestArray(requestArray)

    }, [pendingbookingDetails,practitionerrequestDetailsdata])

    const acceptButtonClick = (e, item) => {
        if(item.isReviewed===0 && item.user.status === 1)
        {
            setLocalStorageData("requestreviewData", requestArray)
            setLocalStorageData("bookingreviewData", item)
        setBookingId(bookingId)
        
            window.scrollTo(0, 0);
            history.push({
                pathname: '/leave-review-ginipig',

            });
        }
        
    }

    
    const _redirecttoGinipicPhoto = (item) => {
       
        if (item.ginipig_images.length !== 0) {
            let itemDetails = {
                user: item.user,
                ginipig_images: item.ginipig_images,
                requestType: 2,
                ginipigreview:true,
            }
            setLocalStorageData("photoObj", itemDetails)

            window.scrollTo(0, 0);
            history.push({
                pathname: '/photo-ginipig',
                details: itemDetails,
                ginipigreview:true,

                requestId: requestArray.uuid
            });

        }


    }



    let durationTimeZero = '';
    if (requestArray) {
        if (requestArray.hours === 0) {
            durationTimeZero = requestArray.minutes + 'm';
        } else {
            durationTimeZero = requestArray.hours + 'h' + '  ' + requestArray.minutes + 'm';
        }
    }


    return (
        <>
            <section className="bgcolor-gray common-section practition_review_ginipig_pg pb80">

            {
                requestArray === null? 
                <div style={{ width: "100%", marginTop: 150, textAlign: 'center' }}>
                        <LoadingSpinner />
                        <p style={{ fontSize: 20, marginTop: 10 }}>Loading ...</p>
                </div> 
                : 

                <Container>
                    <Row>
                        <Col className="col-lg-11 m-auto">
                            <div className="navigationTop padright-0">
                                <div className="heading-history">
                                    <div className="back-history">
                                        <button type="button" onClick={() => history.goBack()} className="back-btn" >
                                            {" "}
                                            <img src={LeftArrow} alt="arrow" />{" "}
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="requirement_wrp padright-0">
                                <div className="request_card mb-16">
                                    <RequestBookingCard
                                        treatmentTitle={subcategory}
                                        tagsName={requestArray && requestArray.treatment && requestArray.treatment.categories && requestArray.treatment.categories.name && requestArray.treatment.categories.name}
                                        hrs={requestArray && requestArray.hours}
                                        minutes={requestArray && requestArray.minutes}
                                        // duration={requestArray && requestArray.hours + "h" + " " + requestArray.minutes + "m"}
                                        //duration={durationTimeZero}
                                        //duration={requestArray.length>0 && requestArray.hoursrequestArray.hours > 0 ? requestArray.hoursrequestArray.hours + "h" + " " + requestArray.minutes + "m" : requestArray.minutes + "m" }
                                        originalPriceText={requestArray && requestArray.rrp_price && requestArray.rrp_price.toFixed(2)}
                                        discountPrice={requestArray && requestArray.your_price && requestArray.your_price.toFixed(2)}
                                        addressText={requestArray && requestArray.postcodes && requestArray.postcodes.city && requestArray.postcodes.city}
                                        slotTime={bookingDate}
                                        categoryIcon1={iconCase_Study}
                                        categorName1={requestArray && requestArray.expertise_level}
                                        categoryIcon2={iconNearly_there}
                                        categorName2={requestArray && requestArray.reason_require_ginipig}
                                    />
                                </div>
                                {requestArray && requestArray.photograph_required === 1 || requestArray && requestArray.attributes_required === 1 || requestArray && requestArray.is_patchtest_required === 1 ?

                                    <div className="model_req_box mb-8">
                                        <ModelRequirementCloseRequest
                                            content={requestArray}
                                            modelTitle="GiniPig Requirements"
                                            disciption={requestArray && requestArray.model_requirements}
                                            requiredImg={iconPhoto}
                                            requiredPatchImg={iconPatch_test}
                                            patchText="You are required to complete a patch test prior to this treatment"
                                            requiredText="The Treatment/Service Provider requires you to add a photo to ensure perfect compatibility for this appointment"
                                        />
                                    </div>
                                    : null}
                             
                                <div className="all_request_box confirm_appointment_box align-review mt-40">

                                    <div className="request_boxs pending mb-40">
                                        <div className="d_flex">
                                        <h3 className="request_heading">GiniPigs <span>({confirmArray.length !== 0 && confirmArray.length})</span> </h3>                                        </div>

                                        <div className="confirm-list mb-16">


                                        {
                                                confirmArray.length !== 0 && confirmArray.map((item, index) => {

                                                    return (
                                                        <div>
                                                            <CloseRequestAcceptCancelCard
                                                                userImg={item.user.profile_pic}
                                                                userName={item.user.name}
                                                                imgArrow={LeftArrow}
                                                                ratingImg={star}
                                                                reliabilityCount={item.user.reliabilityAverage===0? 0: Math.ceil(item.user.reliabilityAverage)}
                                                                etiquetteCount={item.user.etiquetteAverage===0? 0: Math.ceil(item.user.etiquetteAverage)}
                                                                showImagearrow={false}
                                                              
                                                                arrowButtonClick={() => _redirecttoGinipicPhoto(item)}
                                                                ratingPoint={item.user.review_rating_ginipig}
                                                                  DeclineButtonText={item.isReviewed===1?"Reviewed":item.isReviewed === 0 && item.user.status === 0 ? "Deleted" : item.isReviewed === 0 && item.user.status === 2 ? "Suspended" :null}
                                                                // clickonDontHappen={() => clickonDontHappen(item)}
                                                                 acceptButtonClick={(e) => acceptButtonClick(e, item)}
                                                                //  AcceptButtonText={item.isReviewed===0?"Leave Review":null}
                                                                AcceptButtonText={item.isReviewed===0 && item.user.status === 1 ?"Leave Review": null}
                                                                //ContectGoginipigLink={(textconfirmed===true && bookingId=== item.uuid)?true:false}
                                                                // happenGoginipigLink=""
                                                            />
                                                        </div>


                                                    )
                                                })
                                            }
                                            
                                        </div>


                                    </div>

                                </div>
                            </div>

                        </Col>
                    </Row>
                </Container>
            }
            </section>
        </>
    );
}

export default PractitionerReviewGiniPig;