import React, { useCallback, useState, useEffect } from "react";
import ConfirmationPopup from '../../components/UIComponent/ConfirmationPopup';
import  NavigationBreadcrumbs from '../../components/NavigationBreadcrumbs';

import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { getLocalStorageData } from '../../utils/storage'
import { resetupcomingbookingAPI_Action} from "../../stores/actions";


const ConfirmCancelBooking = (props) =>{
  let history = useHistory();
  const dispatch = useDispatch();


    const handleClickYes = () =>{
        window.scrollTo(0,0);
        dispatch(resetupcomingbookingAPI_Action());
        
        history.push('/practitioner-account');
    }

    return (
        <>
{/*         
                            <NavigationBreadcrumbs 
                                leftalignText="Confirm Treatment Happen"
                            /> */}
            

          <div className="confirm_heppen_pg pb80">
              <ConfirmationPopup 
                SubHeadingText="Thank you for confirming treatment happened . Your fees will get credited to you account Soon "
                ButtonText="Go To Profile"
                __redirectNextPage={handleClickYes}
              />
          </div>
        </>
    );
}

export default ConfirmCancelBooking;