import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS,AUTH_HEADERS_NEW } from '../../config/ApiConstants';
export function practitionerSignupAPI_Action(data) {
    const formData = new FormData(); 
      formData.append('practitioner_type', data.practitioner_type);
      formData.append('first_name', data.first_name);
      formData.append('last_name', data.last_name);
      formData.append('email', data.email);
      formData.append('password', data.password);
      formData.append('profile_pic', data.profile_pic);

      formData.append('company_name', data.company_name);
      formData.append('company_category', data.company_category);
      formData.append('is_checked_upto_date_insurance', 1);
      formData.append('is_checked_terms_condition', 1);
      formData.append('is_checked_email_message', 1);

    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/practitioner-signup`,
            method: 'POST',
            headers: {
                ...AUTH_HEADERS_NEW
            },
            body: formData,
            types: [
                types.PRACTITIONERSIGNUP_REQUEST,
                types.PRACTITIONERSIGNUP_RECEIVE, {
                    type: types.PRACTITIONERSIGNUP_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}


export const resetPractitionerSignupAPI_Action = () => {
    return {
        type: types.RESET_PRACTITIONERSIGNUP,
        payload: null
    }
};
