import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { LeftArrow, iconDelete, icon_addNewPlus } from '../../config/images';
import TreatmentRadioButton from '../../components/TreatmentRadioButton';
import LargeButton from '../../components/LargeButton';
import AccordionContent from '../../components/UIComponent/AccordionContent';
import { clearLocalStorage, getLocalStorageData, setLocalStorageData } from '../../utils/storage';
import { treatmentaddreviewAPI_Action, removeTreatmentAPI_Action, resetupcomingbookingAPI_Action, resetremoveTreatmentAPI_Action, resetedittreatmentAPI_Action, resettreatmentaddreviewAPI_Action, treatmentDetailsAPI_Action, resetLoginAPI_Action } from '../../stores/actions'


const TreatmentMenu = (props) => {
    //state init

    // Initialize hooks first   
    const dispatch = useDispatch();
    let history = useHistory();
    const [pathname, setpathname] = useState("");
    const [isActive, setIsActive] = useState();
    

    // Selectors
    const treatmentaddreviewsData = useSelector(state => state.treatmentaddreviews);
    const loginUserData = useSelector(state => state.login);
    const practitionersignupaddinfoData = useSelector(state => state.practitionersignupaddinfo);
    const removeTreatmentData = useSelector(state => state.removetreatment);
    const LocalDatatoken = getLocalStorageData("loginData")
    if (removeTreatmentData.isSuccess === true) {
        let checkToken = "";

        let LocalDatatoken = getLocalStorageData("loginData")
        let registerData = getLocalStorageData("practitionersignupData")

        if (registerData != "" && registerData !== undefined && registerData !== null) {
            checkToken = registerData.token;
        }
        else if (LocalDatatoken !== "" && LocalDatatoken !== undefined && LocalDatatoken !== null) {
            checkToken = LocalDatatoken.token;
        }
        if (checkToken === '') {
            window.scrollTo(0, 0);
            history.push('/login');
        }
        if (checkToken !== '') {
            dispatch(treatmentaddreviewAPI_Action(checkToken));
        }

        dispatch(resetremoveTreatmentAPI_Action());

        // dispatch(resettreatmentdetailsAPI_Action());

        // window.scrollTo(0,0);
        //    history.push('/treatment-Menu');
    }

    const __redirectTo = (e, id) => {
        e.preventDefault();
        let checkToken = "";

        let LocalDatatoken = getLocalStorageData("loginData")
        let registerData = getLocalStorageData("practitionersignupData")

        if (registerData != "" && registerData !== undefined && registerData !== null) {
            checkToken = registerData.token;
        }
        else if (LocalDatatoken !== "" && LocalDatatoken !== undefined && LocalDatatoken !== null) {
            checkToken = LocalDatatoken.token;
        }
        if (checkToken === '') {
            window.scrollTo(0, 0);
            history.push('/login');
        }
        if (checkToken !== '') {
            window.scrollTo(0, 0);
            setLocalStorageData("treatmentcatId", id)
            dispatch(treatmentaddreviewAPI_Action(checkToken));
            history.push('/treatment-add-another/' + id);
        } else {
            window.scrollTo(0, 0);
            history.push('/login');
        }
    }
    // Use effects
    useEffect(() => {
        setpathname(props.location.RedirectPath)
        dispatch(resetedittreatmentAPI_Action());
        clearLocalStorage("treatmentcatId")

        try {
            let checkToken = "";

            let LocalDatatoken = getLocalStorageData("loginData")
            let registerData = getLocalStorageData("practitionersignupData")

            if (registerData != "" && registerData !== undefined && registerData !== null) {
                checkToken = registerData.token;
            }
            else if (LocalDatatoken !== "" && LocalDatatoken !== undefined && LocalDatatoken !== null) {
                checkToken = LocalDatatoken.token;
            }

            if (checkToken !== '') {
                dispatch(treatmentaddreviewAPI_Action(checkToken));
            } else {
                window.scrollTo(0, 0);
                history.push('/login');
            }


        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }


    }, [])

   

    // console.log("treatment add review:",treatmentaddreviewsData) 
    const __redirectToEditTreatment = async (e, id) => {

        await dispatch(treatmentDetailsAPI_Action(id));
        window.scrollTo(0, 0);
        history.push('treatment-content-edit/' + id);
    }
    const __deleteTreatment = async (e, id) => {
        let checkToken = "";
        const loginToken = loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token : "" : "";
        const RegisterToken = practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.token : "" : "";
        if (loginToken != "") {
            checkToken = loginToken;
        } else if (RegisterToken != "") {
            checkToken = RegisterToken;
        } else if (LocalDatatoken !== "") {
            checkToken = LocalDatatoken.token;
        }
        if (checkToken !== '') {
            dispatch(resetremoveTreatmentAPI_Action())
            window.scrollTo(0, 0);
            history.push('delete-treatment/' + id)
        }
    }

    const __nextButtonClick = () => {
        window.scrollTo(0, 0);

        if (pathname === "PractitionerAccoutnt") {
            dispatch(resetupcomingbookingAPI_Action());
            window.scrollTo(0, 0);
            history.push('/practitioner-account');
        } else {
            window.scrollTo(0, 0);
            history.push('/practitioner-profile');
        }

    }

    const goBackClick = () => {
        window.scrollTo(0, 0);
        var isfromaccount = getLocalStorageData("isfromAccount")
        if (isfromaccount === true) {
            window.scrollTo(0, 0);
            history.push('/practitioner-account');
        } else {
            history.goBack()
        }

    }



 //when ac suspend
    //useEffect(() => {
        if(treatmentaddreviewsData && treatmentaddreviewsData.treatmentaddreviewdata &&  treatmentaddreviewsData.treatmentaddreviewdata.status_code===401){
           clearLocalStorage("loginData")
           clearLocalStorage("treatmentcatId")
           clearLocalStorage("isfromAccount")
           clearLocalStorage("practitionersignupData")
           
           dispatch(resetLoginAPI_Action());
           dispatch(resetremoveTreatmentAPI_Action());
           dispatch(resettreatmentaddreviewAPI_Action());
           dispatch(resetupcomingbookingAPI_Action());
           dispatch(resetedittreatmentAPI_Action());
           window.scrollTo(0, 0);
           //dispatch(resetGinipigUpdateAPI_Action());
           //dispatch(resetgoginipigprofileAPI_Action());     
          history.push("/login");
        }
    //});

    //end



    return (
        <>
            <section className="bgcolor-gray common-section treatmentmenupg pb80">
                <Container>
                    {
                        treatmentaddreviewsData.treatmentaddreviewdata !== null && treatmentaddreviewsData.treatmentaddreviewdata.status_code !== 401 ?
                    
                    <Row>
                        <Col className="col-lg-11 m-auto">
                            <div className="navigationTop">
                                <div className="heading-history">
                                    <div className="back-history">
                                        <button type="button" onClick={goBackClick} className="back-btn" >
                                            {" "}
                                            <img src={LeftArrow} alt="arrow" />{" "}
                                        </button>
                                    </div>
                                    <div className="history-title">
                                        <h2> Treatment/Service Menu   </h2>
                                    </div>
                                </div>
                            </div>
                            <div className="from-wrp mt-40">
                                <div className="treatment_info_step">
                                    <p className="steps-label mb-16">Categories offered</p>
                                    <div className="treatment-catogory">

                                        <div className="category-list">

                                            {
                                                treatmentaddreviewsData &&
                                                treatmentaddreviewsData.treatmentaddreviewdata && treatmentaddreviewsData.treatmentaddreviewdata.data.treatment_data.map((category, index) => {


                                                    return (
                                                        <>
                                                            <TreatmentRadioButton
                                                                CategoryTitle={category.name}
                                                                id={category.id}
                                                                screenName="menu"

                                                                key={index}
                                                                activeclassformdata={category.id}
                                                            />
                                                        </>
                                                    )
                                                })


                                            }

                                            {
                                                treatmentaddreviewsData.treatmentaddreviewdata && treatmentaddreviewsData.treatmentaddreviewdata.data.treatment_data.length < 4 ? (<div className="circle treatment-category-btn">
                                                    <button className="add-category-circle" onClick={(e) => __redirectTo(e, 1)}>
                                                        <span className="add_plus"> <img src={icon_addNewPlus} /> </span>
                                                        <span className="radio-title">Add New </span>
                                                    </button>
                                                </div>) : null
                                            }


                                        </div>
                                    </div>
                                </div>

                                <div className="menu-component">
                                    {/* hair */}
                                    {
                                        treatmentaddreviewsData.treatmentaddreviewdata && treatmentaddreviewsData.treatmentaddreviewdata.data.treatment_data.map((category, index) => {

                                            return (

                                                <>

                                                    <div className="category-treatment mt-40">
                                                        <div className="category-heading mb-16">
                                                            <div className="category-name">
                                                                <div className="menu_category_icon">

                                                                    <TreatmentRadioButton
                                                                        CategoryTitle={category.name}
                                                                        id={category.id}
                                                                        key={index}
                                                                        activeclassformdata={category.id}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="category-modify">
                                                                <div className="modify-list">
                                                                    <button className="modify_btn treatment-newadd" onClick={(e) => __redirectTo(e, category.id)}>Add New</button>
                                                                    {/* <button className="modify_btn treatment-edit">Edit</button> */}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {category.treatments && category.treatments !== null && category.treatments.length === 0 &&

                                                            <div className="notice-review1 treatment_info_step">
                                                                <p className="steps-label mb-8">You haven't added any treatments to this category yet, to do so please go to 'Add new'</p>
                                                            </div>
                                                        }

                                                        <div className="treatment-accordion">
                                                            {
                                                                category.treatments && category.treatments !== null && category.treatments.map((treatmentdata, treat_index) => {
                                                                    return (
                                                                        <AccordionContent
                                                                            title={treatmentdata.title}
                                                                            deleteText={iconDelete}
                                                                            editText="Edit"
                                                                            content={treatmentdata.description}
                                                                            key={treat_index}
                                                                            id={treatmentdata.id}
                                                                            uuid={treatmentdata.uuid}
                                                                            __nextAction={__redirectToEditTreatment}
                                                                            __deleteAction={__deleteTreatment}
                                                                            isActive={isActive}
                                                                            indexValue={treatmentdata.id}
                                                                            onClick={(indexid)=>{ setIsActive(indexid===isActive ? false : indexid) }}
                                                                                    

                                                                        />
                                                                    )
                                                                })
                                                            }
                                                        </div> {/* end accordion*/}

                                                    </div>  {/* category item1 */}

                                                </>
                                            )

                                        })

                                    }


                                    <div className="btn-align pd32">
                                        <LargeButton
                                            ButtonType="button"
                                            ButtonText="Save"
                                            nextButtonClick={__nextButtonClick}
                                        />
                                    </div>



                                </div>

                            </div>
                        </Col>
                    </Row> :
                           <div style={{ width: "100%", fontSize: 20, marginTop: 100, textAlign: 'center' }}>
                           <p>Loading ...</p>
                       </div>
               }
                    
                </Container>
            </section>
        </>
    );
}

export default TreatmentMenu;