import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS } from '../../config/ApiConstants';
export function upcomingbookingAPI_Action(token) {
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/practitioner/bookings`,
            method: 'GET',
            headers: {
                ...AUTH_HEADERS,
                'Authorization':'Bearer '+token
            },
            body:'',
            types: [
                types.UPCOMINGBOOKING_REQUEST,
                types.UPCOMINGBOOKING_RECEIVE, {
                    type: types.UPCOMINGBOOKING_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}


export const resetupcomingbookingAPI_Action = () => {
    return {
        type: types.RESET_UPCOMINGBOOKING,
        payload: null
    }
};
