import React, { useCallback, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { LeftArrow } from '../../config/images';
import TreatmentRadioButton from '../../components/TreatmentRadioButton';
import LargeButton from '../../components/LargeButton';
import { getLocalStorageData, clearLocalStorage } from '../../utils/storage'

// redux
import { edittreatmentAPI_Action, resetedittreatmentAPI_Action, categorylistAPI_Action, resettreatmentdetailsAPI_Action,resettreatmentaddreviewAPI_Action, resetLoginAPI_Action } from '../../stores/actions'

const initialErrorData = Object.freeze({
    treatment_title_error: "",
    treatment_description_error: "",
    treatment_description_error_active: "Description should be minimum 25 characters long.",
    treatment_sub_cat_error: "",

    is_form_valid_title: true,
    is_form_valid_desc: true,
    is_form_valid_sub_cat: true,
});

const TreatmentContentEdit = (props) => {
    // Initialize hooks first
    const dispatch = useDispatch();
    let history = useHistory();
    // States Management
    const [formData, updateFormData] = useState(Object.freeze({

        title: "",
        description: "",
        token: "",
        category_id: 1,
        sub_category_id: [],
        categories: "",


    }));
    const [errorData, updateErrorData] = useState(initialErrorData);
    const [SubCatlenghtError, setSubCatlenghtError] = useState(false);
    const [character_count, setCharactercount] = useState(500);
    const [character_count_title, setCharactercountTitle] = useState(65);

    // Selectors
    const treatmentdetailsData = useSelector(state => state.treatmentdetails);
    const edittreatmentData = useSelector(state => state.edittreatment);
    const Data = JSON.stringify(edittreatmentData);
    const loginUserData = useSelector(state => state.login);
    const categoryData = useSelector(state => state.categorylist);
    const practitionersignupaddinfoData = useSelector(state => state.practitionersignupaddinfo);

    const treatment_id = props.match.params.treatment_id;
    useEffect(() => {

        dispatch(resettreatmentaddreviewAPI_Action());
        if (treatmentdetailsData.treatmentdetailsdata != null) {
            let subArray = [];

            treatmentdetailsData.treatmentdetailsdata.data.treatment.treatment_sub_category.map((sub_cat, index) => {
                subArray.push(sub_cat.sub_category_id);
            })
            updateFormData({
                ...formData,
                id: treatment_id,
                category_id: treatmentdetailsData.treatmentdetailsdata.data.treatment.category_id,
                sub_category_id: subArray,
                title: treatmentdetailsData.treatmentdetailsdata.data.treatment.title,
                description: treatmentdetailsData.treatmentdetailsdata.data.treatment.description,
                categories: treatmentdetailsData.treatmentdetailsdata.data.treatment.categories,

            });

        }

        if (categoryData.categorylistdata === null) {
            try {
                dispatch(categorylistAPI_Action());

            } catch (error) {
                console.error(" profile ::  profile() :: Exception ==", error);
            }
        }


    }, [])


    const __onTextChange = (e) => {

        updateFormData({
            ...formData,
            [e.target.name]: e.target.value
        });

        if (e.target.name === "title" && e.target.value !== "") {
            setCharactercountTitle(65 - e.target.value.length)
        } else {
            setCharactercountTitle(65)
        }

        if (e.target.name === "description" && e.target.value !== "") {
            setCharactercount(500 - e.target.value.length)
        } else {
            setCharactercount(500)
        }
        if (e.target.name === "description" && e.target.value.length > 25) {
            updateErrorData({
                ...errorData,

                is_form_valid_desc: false,
                treatment_description_error: ""

            });
        }


    };



    const checkTreatmentTitleValidation = (e) => {
        if (edittreatmentData.edittreatmentdata !== null && edittreatmentData.isError === true) {
            dispatch(resetedittreatmentAPI_Action());
        }
        const { id, value } = e.target;
        const title = value.trim();
        if (!title) {

            updateErrorData({
                ...errorData,
                is_form_valid_title: false,

                treatment_title_error: "Treatment title is required."
            });



        } else {
            updateErrorData({
                ...errorData,
                is_form_valid_title: true,
                is_form_valid_desc: true,
                treatment_title_error: ""
            });
        }
    }



    const __selectSubCategory = (e, category_id, id) => {
        // const {id,value} = e.target;
        if (edittreatmentData.edittreatmentdata !== null && edittreatmentData.isError === true) {
            dispatch(resetedittreatmentAPI_Action());
        }
        let selectedBoxes = formData.sub_category_id;

        let index = selectedBoxes.indexOf(parseInt(id)) // check to see if the name is already stored in the array
        if (index === -1) {
            selectedBoxes.push(parseInt(id))
        } else {
            selectedBoxes.splice(index, 1)
        }

        if (selectedBoxes !== null) {
            if (selectedBoxes.length <= 3) {
                //setSubCatlenghtError(true)
                updateErrorData({
                    ...errorData,
                    is_form_valid_sub_cat: true,
                    treatment_sub_cat_error: ""
                });
            } else {
                //setSubCatlenghtError(false)
                updateErrorData({
                    ...errorData,
                    treatment_sub_cat_error: "(select up to 3)"
                });
            }

        }


        selectedBoxes = selectedBoxes.slice(0, 3);

        updateFormData({
            ...formData,
            sub_category_id: selectedBoxes,
            category_id: category_id,
        });
        // updateErrorData({
        //     ...errorData,
        //     is_form_valid_sub_cat: true,
        //     treatment_sub_cat_error: ""

        // });
    }


    const checkDescValidation = (e) => {
        if (edittreatmentData.edittreatmentdata !== null && edittreatmentData.isError === true) {
            dispatch(resetedittreatmentAPI_Action());
        }
        const { id, value } = e.target;
        const description = value.trim();
        if (!description) {

            updateErrorData({
                ...errorData,
                is_form_valid_desc: false,
                treatment_description_error: "Treatment description is required.",
            });


        } else if (description.length < 25) {
            updateErrorData({
                ...errorData,
                is_form_valid_desc: false,
                treatment_description_error: "Description should be minimum 25 characters long.",
            });
        } else if (description.length > 500) {
            updateErrorData({
                ...errorData,
                is_form_valid_desc: false,
                treatment_description_error: "Only 500 Characters are Allowed.",
            });
        } else {
            updateErrorData({
                ...errorData,
                is_form_valid_desc: true,
                treatment_description_error: ""
            });
        }


    };
    const __submitTreatmentapiCall = useCallback(async () => {
        try {

            let checkToken = "";
            const loginToken = loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token : "" : "";
            const RegisterToken = practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.token : "" : "";
            let LocalDatatoken = getLocalStorageData("loginData")

            if (loginToken != "") {
                checkToken = loginToken;
            } else if (RegisterToken != "") {
                checkToken = RegisterToken;
            }
            else if (LocalDatatoken !== "") {
                checkToken = LocalDatatoken.token;
            }
            if (checkToken !== '') {
                await dispatch(edittreatmentAPI_Action(formData, checkToken));
            } else {
                window.scrollTo(0, 0);
                history.push('/login');
            }


        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    });

    const __submitTreatmentForm = (e) => {
        e.preventDefault();
        dispatch(resettreatmentdetailsAPI_Action());
        __submitTreatmentapiCall();
    };
    if (edittreatmentData.isSuccess === true) {
        // dispatch(resettreatmentaddreviewAPI_Action());
        dispatch(resetedittreatmentAPI_Action());

        window.scrollTo(0, 0);
        // history.push('/treatment-Menu');
        history.goBack();
    }


    // console.log("ssd",errorData)

    //when ac suspend
    //useEffect(() => {
    if (edittreatmentData && edittreatmentData.edittreatmentdata && edittreatmentData.edittreatmentdata.status_code === 401) {
        clearLocalStorage("loginData")
        clearLocalStorage("treatmentcatId")
        clearLocalStorage("practitionersignupData")

        dispatch(resetLoginAPI_Action());
        dispatch(resetedittreatmentAPI_Action());
        dispatch(resettreatmentaddreviewAPI_Action());
        dispatch(resetedittreatmentAPI_Action());
        window.scrollTo(0, 0);
        //dispatch(resetGinipigUpdateAPI_Action());
        //dispatch(resetgoginipigprofileAPI_Action());     
        history.push("/login");
    }
    //});

    //end








    return (
        <>
            <section className="bgcolor-gray common-section  treatment_content_edit pb80">
                <Container>
                    <Row>
                        <Col className="col-lg-11 m-auto">
                            <div className="navigationTop">
                                <div className="heading-history">
                                    <div className="back-history">
                                        <button type="button" onClick={() => history.goBack()} className="back-btn" >
                                            {" "}
                                            <img src={LeftArrow} alt="arrow" />{" "}
                                        </button>
                                    </div>
                                    <div className="history-title">
                                        <h2> Edit treatment  </h2>
                                    </div>
                                </div>
                            </div>
                            <div className="from-wrp mt-40">
                                <div className="treatment_info_step">
                                    <p className="steps-label mb-16">Select the treatment category </p>
                                    <div className="treatment-catogory">

                                        <div className="category-list">
                                            {/* <TreatmentRadioButton 
                                                CategoryTitle={formData.categories !="" ? formData.categories.name : ""}
                                                id={formData.categories !="" ? formData.categories.id : ""}
                                                activeClass="active_radiobtn"  
                                                activeclassformdata={formData.categories !="" ? formData.categories.id : ""}
                                            /> */}

                                            {
                                                // practitionercatData.practitionercategorydata && practitionercatData.practitionercategorydata.data.user_category_data.map((item, index) => {
                                                categoryData.categorylistdata && categoryData.categorylistdata.data.categories.map((item, index) => {
                                                    if (formData.category_id === item.id) {
                                                        return (
                                                            <div className="category-list">
                                                                <TreatmentRadioButton
                                                                    CategoryTitle={item.name}
                                                                    id={item.id}
                                                                    activeclassformdata={formData.category_id}

                                                                />

                                                            </div>
                                                        )
                                                    }


                                                })
                                            }
                                        </div>

                                    </div>
                                </div>
                                <form action="post" onSubmit={__submitTreatmentForm}>
                                    <div className="treatmentform form-wrp fromProfile mb-24 mt-24">

                                        <div className="profilefield">
                                            <div className="showwithlabel">
                                                <label className="profile-label">Treatment Title</label>
                                                {errorData.treatment_title_error &&
                                                    errorData.treatment_title_error != "" ? (
                                                    <p className="error">
                                                        <p style={{ color: "red" }}>
                                                            {errorData.treatment_title_error}{" "}
                                                        </p>
                                                    </p>
                                                ) : null}

                                                {edittreatmentData.edittreatmentdata &&
                                                    edittreatmentData.edittreatmentdata.hasOwnProperty(
                                                        "errors"
                                                    ) ? (
                                                    <p className="error">
                                                        {edittreatmentData.edittreatmentdata.errors.hasOwnProperty(
                                                            "title"
                                                        ) ? (
                                                            <p style={{ color: "red" }}>
                                                                {
                                                                    edittreatmentData.edittreatmentdata.errors
                                                                        .title[0]
                                                                }{" "}
                                                            </p>
                                                        ) : null}
                                                    </p>
                                                ) : null}
                                            </div>
                                            <input
                                                type="text"
                                                id="title"
                                                name="title"
                                                value={formData.title}
                                                onChange={__onTextChange}
                                                onBlur={checkTreatmentTitleValidation}
                                                maxlength="65"
                                            />

                                            {
                                                character_count_title == 65 ?
                                                    <span className="char-span-title">{character_count_title} Character limit</span> :
                                                    <span className="char-span-title">{character_count_title} Remaining</span>
                                            }
                                        </div>
                                        <div className="category-box mb-24">
                                            <div className="insidebox">
                                                <div className="optional-errormsg">
                                                    <label className="profile-label">Choose the categories that apply to this treatment</label>
                                                    {errorData.treatment_sub_cat_error &&
                                                        errorData.treatment_sub_cat_error != "" ? (
                                                        <p className="error">
                                                            <p style={{ color: "red" }}>
                                                                {"(select up to 3)"}
                                                            </p>
                                                        </p>
                                                    ) : <p className="optiontext"> (select up to 3) </p>}
                                                </div>
                                            </div>
                                            <div className="category-checkbox-grp">

                                                {
                                                    // categoryData.categorylistdata && categoryData.categorylistdata.data.categories[formData.category_id - 1].sub_categories.data.map((sub_item, sub_index) => {
                                                    formData.categories != "" && formData.categories.sub_categories.map((sub_item, sub_index) => {

                                                        return (
                                                            //     <div >
                                                            //         {/* <TreatmentCheckboxButton
                                                            //    SubCategoryName="sub-category"
                                                            //     id={1}
                                                            //     /> */}
                                                            <div className="checkbox-item">
                                                                <div className={formData.sub_category_id.indexOf(sub_item.id) !== -1 ? "checkbox-button sub-background" : "checkbox-button"} >
                                                                    <label className="checkox-label yes-checked">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="checkbox-btn"
                                                                            id={sub_item.id}
                                                                            name={sub_item.id}
                                                                            onClick={(e) => __selectSubCategory(e, formData.category_id, sub_item.id)}
                                                                            value={sub_item.id}
                                                                            checked={formData.sub_category_id.indexOf(sub_item.id) !== -1 ? true : false} />
                                                                        <span className="button-text">{sub_item.name}</span>
                                                                    </label>

                                                                </div>
                                                            </div>



                                                        )
                                                    })
                                                }


                                            </div>
                                        </div>


                                        <div className="profilefield mb-0">
                                            <div className="showwithlabel">
                                                <label className="profile-label"> Treatment Description </label>
                                                {errorData.treatment_description_error &&
                                                    errorData.treatment_description_error != "" ? (
                                                    <p className="error">
                                                        <p className="errortext">
                                                            {errorData.treatment_description_error}{" "}
                                                        </p>
                                                    </p>
                                                ) : <p className="error">
                                                    <p className="optiontext">
                                                        {errorData.treatment_description_error_active}{" "}
                                                    </p>
                                                </p>}

                                                {edittreatmentData.edittreatmentdata &&
                                                    edittreatmentData.edittreatmentdata.hasOwnProperty(
                                                        "errors"
                                                    ) ? (
                                                    <p className="error">
                                                        {edittreatmentData.edittreatmentdata.errors.hasOwnProperty(
                                                            "description"
                                                        ) ? (
                                                            <p style={{ color: "red" }}>
                                                                {
                                                                    edittreatmentData.edittreatmentdata.errors
                                                                        .description[0]
                                                                }{" "}
                                                            </p>
                                                        ) : null}
                                                    </p>
                                                ) : null}
                                            </div>
                                            <div className="input-addicon addicontextarea" >
                                                <textarea
                                                    maxlength="500"
                                                    id="description"
                                                    name="description"
                                                    value={formData.description}
                                                    onChange={__onTextChange}
                                                    onBlur={checkDescValidation}
                                                    placeholder="Keep your wording relevant to this treatment, you might like to add more details about your level of expertise to make this appealing. If access is difficult for people with disabilities please remember to include this. Parking is a bonus if you have it, so don't forget to mention it!"
                                                    onFocus={(e) => e.target.placeholder = ""}
                                                />
                                            </div>
                                            {
                                                character_count === 500 ?
                                                    <span className="char-span">{character_count} Character limit</span> :
                                                    <span className="char-span">{character_count} Remaining</span>
                                            }
                                        </div>
                                    </div>

                                    <div className="btn-align pd32 pt-32">
                                        <LargeButton
                                            ButtonType="submit"
                                            ButtonText="Save changes"
                                            disabled={errorData.is_form_valid_title === false || errorData.is_form_valid_desc === false || errorData.is_form_valid_sub_cat === false ? true : false}

                                        />

                                    </div>
                                </form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default TreatmentContentEdit;