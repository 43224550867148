import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS, AUTH_HEADERS_NEW } from '../../config/ApiConstants';


export function favUnfav_Action(id,token,value) {
    
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/ginipig/favourite/practitioner/`+id ,
            method: 'GET',
            headers: {
                ...AUTH_HEADERS,
                'Authorization':'Bearer '+token
            },
            body: '',
            types: [
                types.FAVOURITE_REQUEST,
                types.FAVOURITE_RECEIVE, {
                    type: types.FAVOURITE_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}


export const resetUserAPI_Action = () => {
    return {
        type: types.RESET_FAVOURITE,
        payload: null
    }
};

export function favUnfav_Actiontreatment(id,token,value) {
   
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/ginipig/favourite/treatment/`+id,
            method: 'GET',
            headers: {
                ...AUTH_HEADERS,
                'Authorization':'Bearer '+token
            },
            body: '',
            types: [
                types.FAVOURITE_REQUEST,
                types.FAVOURITE_RECEIVE, {
                    type: types.FAVOURITE_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}

export const resetUserAPI_ActionTreatment = () => {
    return {
        type: types.RESET_FAVOURITE,
        payload: null
    }
};