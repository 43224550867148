
import React, { useCallback, useState, useEffect } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import { LeftArrow, star, iconCase_Study, iconPatch_test, iconNearly_there, iconPhoto, reviewAvatar } from '../../config/images';
import RequestBookingCard from "../../components/UIComponent/RequestBookingCard";
import TransparentButton from "../../components/UIComponent/TransparentButton";
import CloseRequestAcceptCancelCard from "../../components/UIComponent/CloseRequestAcceptCancelCard";
import { getLocalStorageData, setLocalStorageData, clearLocalStorage } from '../../utils/storage'
import {  completebookingallAPI_Action, resetcompletebookingAPI_Action, resetcompletebookingallAPI_Action, upcomingbookingAPI_Action, resetpractitionerrequestdetailsAPI_Action, resetpendingbookingDetailsAPI_Action, completebookingAPI_Action, pendingbookingDetailsAPI_Action, practitionerrequestDetailsAPI_Action, resetLoginAPI_Action } from '../../stores/actions'
import Modal from 'react-modal';
import LoadingSpinner from "../../components/UIComponent/LoadingSpinner"
import ModelRequirementCloseRequest from '../../components/ModelRequirementCloseRequest'


const customStyles = {
    overlay: {
        background: "#dee2e663", zIndex: 999999
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        border: "none",
        background: "none"
    }
};

const ConfirmAppointment = (props) => {


    let history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const [requestArray, setrequestArray] = useState({});
    const [subcategory, setsubcategory] = useState("");
    const [bookingDate, setbookingDate] = useState();
    const [bookingCount, setBookingCount] = useState();
    const [bookingId, setBookingId] = useState();
    const [confirmArray, setconfirmArray] = useState([]);
    const [requestType, setRequestType] = useState([])
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [confirmedBookings, setconfirmedBookings] = useState([])
    const [newArray, setNewArray] = useState([])
    const [textconfirmed, setTextconfirmed] = React.useState(false);
    const pendingbookingDetails = useSelector(state => state.pendingbookingdetails);
    const practitionerrequestDetailsdata = useSelector(state => state.practitionerrequestdetailsdata);
   const completebookingdata = useSelector(state => state.completebookingdata);
    const completebookingalldata = useSelector(state => state.completebookingalldata);



    // Use effects
    useEffect(() => {
        try {
            let checkToken = "";
            let LocalDatatoken = getLocalStorageData("loginData")
            let registerData = getLocalStorageData("practitionersignupData")

            if (registerData != "" && registerData !== undefined && registerData !== null) {
                checkToken = registerData.token;
            }
            else if (LocalDatatoken !== "" && LocalDatatoken !== undefined && LocalDatatoken !== null) {
                checkToken = LocalDatatoken.token;
            }
            if (checkToken === '') {
                window.scrollTo(0, 0);
                history.push('/login');
            }


            if (checkToken !== '') {
                dispatch(upcomingbookingAPI_Action(checkToken));


            } else {
                window.scrollTo(0, 0);
                history.push('/login');
            }
            setLocalStorageData("bookingIdDontHappen", "")
            let bookingCount = getLocalStorageData("BookigCount")
            setBookingCount(bookingCount)
            let requestData = getLocalStorageData("confirmData")
            setRequestType(requestData.requestType)
            _apicallNew(requestData.requestId, checkToken)


        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    }, [])

    const _apicallNew = async (requestId, checkToken) => {

        let requestData = getLocalStorageData("confirmData")
        if (requestData.requestType === 1) {
            await dispatch(pendingbookingDetailsAPI_Action(requestId, checkToken));
        } else if (requestData.requestType === 2) {
            await dispatch(practitionerrequestDetailsAPI_Action(requestId, checkToken));
        }

    }

    useEffect(() => {

        let requestArray = [];
        let requestData = getLocalStorageData("confirmData")
        if (requestData.requestType === 1) {
            requestArray = pendingbookingDetails.pendingbookingdetailsdata && pendingbookingDetails.pendingbookingdetailsdata.data.booking.request

        } else {

            requestArray = practitionerrequestDetailsdata.practitionerrequestdetailsdata && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.request
        }

        if (requestArray !== null) {
            let subCatArray = []
            requestArray.treatment.treatment_sub_category_new.forEach(element => {
                subCatArray.push(element.name)

            })

            var str;
            if (subCatArray.length === 1) {
                str = subCatArray.join()
            } else if (subCatArray.length === 2) {

                str = subCatArray.join(" & ")
            } else if (subCatArray.length === 3) {
                str = subCatArray[0] + "," + subCatArray[1] + " & " + subCatArray[2]
            }
            let newDate = ""
            let displayDate = "";
            if (requestData.requestType === 2) {
                displayDate = requestArray.request_timeslots[0].date_time
            } else {
                displayDate = pendingbookingDetails.pendingbookingdetailsdata && pendingbookingDetails.pendingbookingdetailsdata.data.booking.date
            }

            if (displayDate !== null && displayDate !== undefined) {
                let day = displayDate.substring(8, 10);
                let displayDay = ""
                if (day < 9) {
                    displayDay = day.substring(1, 2)
                } else {
                    displayDay = day
                }

                let n = displayDate.substring(5, 7)

                let newn = ""
                if (n < 10) {
                    newn = n.substring(1, 2)
                } else {
                    newn = n
                }

                var month = new Array();
                month[1] = "January";
                month[2] = "February";
                month[3] = "March";
                month[4] = "April";
                month[5] = "May";
                month[6] = "June";
                month[7] = "July";
                month[8] = "August";
                month[9] = "September";
                month[10] = "October";
                month[11] = "November";
                month[12] = "December";

                var displaymonth = month[newn];

                if (displayDay == 1) {
                    newDate = displayDay + "st" + " " + displaymonth + " "
                } else if (displayDay == 2) {
                    newDate = displayDay + "nd" + " " + displaymonth + " "
                } else if (displayDay == 3) {
                    newDate = displayDay + "rd" + " " + displaymonth + " "
                } else {
                    newDate = displayDay + "th" + " " + displaymonth + " "
                }
            }

            let period = ""
            let hours = ''
            let [sHours, minutes] = ""
            // console.log(requestArray)
            let time = ""
            if (requestData.requestType === 1) {
                time = pendingbookingDetails.pendingbookingdetailsdata && pendingbookingDetails.pendingbookingdetailsdata.data && pendingbookingDetails.pendingbookingdetailsdata.data.booking.time

            } else {
                time = requestArray.request_timeslots[0].start_time
            }



            if (time !== null) {
                [sHours, minutes] = time && time.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
                period = +sHours < 12 ? 'AM' : 'PM';
                hours = +sHours % 12 || 12;
            }

            let timein24hrs = ""
            if (time !== null && time !== undefined) {
                timein24hrs = time.slice(0, 5)
            }


            let displaydate = newDate + new Date().getFullYear() + " @ " + timein24hrs

            setbookingDate(displaydate)
            setsubcategory(requestArray.treatment.title)
            if (requestData.requestType === 1) {
                let confirm = []
                confirm.push(pendingbookingDetails.pendingbookingdetailsdata && pendingbookingDetails.pendingbookingdetailsdata.data && pendingbookingDetails.pendingbookingdetailsdata.data.booking)
                setconfirmArray(confirm)

            } else {
                setconfirmArray(practitionerrequestDetailsdata.practitionerrequestdetailsdata && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.confirm_booking)
                if (practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.confirm_booking.length) {
                    let abc = [];
                    for (var i = 0; i < practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.confirm_booking.length; i++) {
                        abc.push({ id: practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.confirm_booking[i].id, status: false })
                    }
                    setNewArray(abc)
                }

            }


        }

        setrequestArray(requestArray)


    }, [pendingbookingDetails, practitionerrequestDetailsdata])

    if (pendingbookingDetails && pendingbookingDetails.pendingbookingdetailsdata !== null && pendingbookingDetails.pendingbookingdetailsdata.status_code === 401) {
        clearLocalStorage("loginData")
        clearLocalStorage("practitionersignupData")
        dispatch(resetLoginAPI_Action());
        dispatch(resetpendingbookingDetailsAPI_Action());
        window.scrollTo(0, 0);
        history.push('/login');
    }

    if (practitionerrequestDetailsdata && practitionerrequestDetailsdata.practitionerrequestdetailsdata !== null && practitionerrequestDetailsdata.practitionerrequestdetailsdata.status_code === 401) {
        clearLocalStorage("loginData")
        clearLocalStorage("practitionersignupData")
        dispatch(resetLoginAPI_Action());
        dispatch(resetpractitionerrequestdetailsAPI_Action());
        window.scrollTo(0, 0);
        history.push('/login');
    }


    const acceptButtonClick = (e, bookingId, item) => {
        for (var i = 0; i < newArray.length; i++) {
            if (newArray[i].id === item.id) {
                newArray[i].status = true

            }
        }
        setBookingId(bookingId)
        openModal(e);
        __apiCall(bookingId)

    }


    const openModal = (e) => {
        e.preventDefault();
        setIsOpen(true);
    }

    const closeModal = (item) => {
        setIsOpen(false);
    }

    if (completebookingdata.isError === true) {
         setconfirmedBookings([])
        dispatch(resetcompletebookingAPI_Action());
        closeModal()
    }

    if (completebookingdata.isSuccess === true) {
        confirmedBookings.push(bookingId)
        dispatch(resetcompletebookingAPI_Action());
        closeModal()
        setTextconfirmed(true)
        window.scrollTo(0, 0);
    }

    if (completebookingalldata.isError === true) {
        setconfirmedBookings([])
        dispatch(resetcompletebookingallAPI_Action());
        closeModal()
    }

    if (completebookingalldata.isSuccess === true) {

        let bookingIdArray = []
        if (confirmArray.length > 1) {
            confirmArray.map((item, index) => {
                bookingIdArray.push(item.uuid);


            })
        }
        setconfirmedBookings(bookingIdArray);
        dispatch(resetcompletebookingallAPI_Action());
        closeModal()

        window.scrollTo(0, 0);
        history.push({
            pathname: '/ginipig-confirm-appointment',

        });

    }


    const clickonDontHappen = (item) => {
        setLocalStorageData("bookingIdDontHappen", item)


        window.scrollTo(0, 0);
        history.push({
            pathname: '/appointment-occurr',
            requestType: requestType,
            requestId: location.requestId

        });
    }

    const _confirmAllappointmentClick = (e, bookingId) => {
        let bookingIdArray = []
        if (confirmArray.length > 1) {
            confirmArray.map((item, index) => {
                bookingIdArray.push(item.uuid);


            })
            openModal(e);
            __apiCallConfirmAll(bookingIdArray)
        }
       // setconfirmedBookings(bookingIdArray);
        // setconfirmArray([])
    }



    const __apiCall = useCallback(async (bookingId) => {

        try {

            let checkToken = "";
            let LocalDatatoken = getLocalStorageData("loginData")
            let registerData = getLocalStorageData("practitionersignupData")

            if (registerData != "" && registerData !== undefined && registerData !== null) {
                checkToken = registerData.token;
            }
            else if (LocalDatatoken !== "" && LocalDatatoken !== undefined && LocalDatatoken !== null) {
                checkToken = LocalDatatoken.token;
            }


            dispatch(resetcompletebookingAPI_Action());
            await dispatch(completebookingAPI_Action(bookingId, checkToken));

            dispatch(upcomingbookingAPI_Action(checkToken));


        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    });

    const ClickOnBack = () => {
        window.scrollTo(0, 0);
        history.push({
            pathname: '/upcoming-pasting-book',

        });
    }

    const __apiCallConfirmAll = useCallback(async (bookingId) => {
        let formDatanew = {
            booking_id: bookingId
        }

        try {

            let checkToken = "";
            let LocalDatatoken = getLocalStorageData("loginData")
            let registerData = getLocalStorageData("practitionersignupData")

            if (registerData != "" && registerData !== undefined && registerData !== null) {
                checkToken = registerData.token;
            }
            else if (LocalDatatoken !== "" && LocalDatatoken !== undefined && LocalDatatoken !== null) {
                checkToken = LocalDatatoken.token;
            }

            dispatch(resetcompletebookingallAPI_Action());
            await dispatch(completebookingallAPI_Action(formDatanew, checkToken));
            //await dispatch(pendingbookingDetailsAPI_Action(bookingId, checkToken));
            // window.scrollTo(0, 0);
            // history.push({
            //     pathname: '/ginipig-confirm-appointment',

            // });

        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    });

    let durationTimeZero = '';
    if (requestArray) {
        if (requestArray.hours === 0) {
            durationTimeZero = requestArray.minutes + 'm';
        } else {
            durationTimeZero = requestArray.hours + 'h' + '  ' + requestArray.minutes + 'm';
        }
    }


    return (
        <>
            <section className="bgcolor-gray common-section confirm_appointment_pg pb80">
                {
                    requestArray === null ?
                        <div style={{ width: "100%", marginTop: 150, textAlign: 'center' }}>
                            <LoadingSpinner />
                            <p style={{ fontSize: 20, marginTop: 10 }}>Loading ...</p>
                        </div>
                        :


                        <Container>
                            <Row>
                                <Col className="col-lg-11 m-auto confirm-app">
                                    <div className="navigationTop padright-0">
                                        <div className="heading-history">
                                            <div className="back-history">
                                                <button type="button" onClick={() => history.goBack()} className="back-btn" >
                                                    {" "}
                                                    <img src={LeftArrow} alt="arrow" />{" "}
                                                </button>
                                            </div>
                                        </div>
                                        <div className="spce_btns_box">
                                            <button className="spc_fill_btns">{bookingCount}/{requestArray && requestArray.ginipig_count} spaces filled</button>
                                        </div>
                                    </div>

                                    <div className="requirement_wrp padright-0">
                                        <div className="request_card mb-16">
                                            <RequestBookingCard
                                                treatmentTitle={subcategory}
                                                tagsName={requestArray && requestArray.treatment && requestArray.treatment.categories && requestArray.treatment.categories.name && requestArray.treatment.categories.name}
                                                hrs={requestArray && requestArray.hours}
                                                minutes={requestArray && requestArray.minutes}

                                                //duration={requestArray && requestArray.hours + "h" + " " + requestArray.minutes + "m"}
                                                // duration={durationTimeZero}
                                                originalPriceText={requestArray && requestArray.rrp_price && requestArray.rrp_price.toFixed(2)}
                                                discountPrice={requestArray && requestArray.your_price && requestArray.your_price.toFixed(2)}
                                                addressText={requestArray && requestArray.postcodes && requestArray.postcodes.city && requestArray.postcodes.city}
                                                slotTime={bookingDate}
                                                categoryIcon1={iconNearly_there}
                                                categorName1={requestArray && requestArray.expertise_level}
                                                categoryIcon2={iconCase_Study}
                                                categorName2={requestArray && requestArray.reason_require_ginipig}
                                            />
                                        </div>

                                        {requestArray && requestArray.photograph_required === 1 || requestArray && requestArray.attributes_required === 1 || requestArray && requestArray.is_patchtest_required === 1 ?

                                            <div className="model_req_box mb-8">
                                                <ModelRequirementCloseRequest
                                                    content={requestArray}
                                                    modelTitle="GiniPig Requirements"
                                                    disciption={requestArray && requestArray.model_requirements}
                                                    requiredImg={iconPhoto}
                                                    requiredPatchImg={iconPatch_test}
                                                    patchText="You are required to complete a patch test prior to this treatment"
                                                    requiredText="The Treatment/Service Provider requires you to add a photo to ensure perfect compatibility for this appointment"
                                                />
                                            </div>
                                            : null}
                                        {
                                            requestArray && requestArray.type === 2 &&
                                            <div className="requst-booking mt-24">

                                                <div className="first-button">
                                                    <TransparentButton
                                                        ButtonType="button"
                                                        ButtonClick={(e) => _confirmAllappointmentClick(e)}
                                                        ButtonText="Confirm all appointments"
                                                        disabled={(confirmArray.length > 1) ? "" : true}
                                                    />
                                                </div>

                                            </div>
                                        }



                                        <div className="all_request_box confirm_appointment_box mt-40">

                                            <div className="request_boxs pending mb-40">
                                                <div className="d_flex">
                                                    <h3 className="request_heading">GiniPigs <span>({confirmArray.length !== 0 && confirmArray.length})</span> </h3>
                                                    <p className="confirm-notice">Confirm that you performed this treatment for the GiniPigs booked onto this appointment</p>
                                                    {completebookingdata.isError && completebookingdata.completebookingdata &&
                                                        completebookingdata.completebookingdata.hasOwnProperty(
                                                            "message"
                                                        ) ? (
                                                        <p style={{ color: "red" }}>
                                                            {
                                                                completebookingdata.completebookingdata.message
                                                            }{" "}
                                                        </p>
                                                    ) : null}
                                                </div>


                                                <div className="confirm-list mb-16">


                                                    {
                                                        confirmArray.length !== 0 && confirmArray.map((item, index) => {
                                                            //console.log("index ", index)
                                                            return (
                                                                <div>
                                                                    <CloseRequestAcceptCancelCard
                                                                        userImg={item.user.profile_pic}
                                                                        userName={item.user.name}
                                                                        imgArrow={LeftArrow}
                                                                        ratingImg={star}
                                                                        reliabilityCount={item.user.reliabilityAverage === 0 ? 0 : Math.ceil(item.user.reliabilityAverage)}
                                                                        etiquetteCount={item.user.etiquetteAverage === 0 ? 0 : Math.ceil(item.user.etiquetteAverage)}
                                                                        //  suitabilityCount={item.user.suitabilityAverage===0? 0: Math.ceil(item.user.suitabilityAverage)}
                                                                        showImagearrow={false}
                                                                        ratingPoint={item.user.review_rating_ginipig}
                                                                        clickonDontHappen={() => clickonDontHappen(item)}
                                                                        acceptButtonClick={(e) => acceptButtonClick(e, item.uuid, item)
                                                                            //acceptButtonClick(e, item.uuid)
                                                                        }
                                                                        // AcceptButtonText={(textconfirmed===true && bookingId=== item.uuid)?"Confirmed":"Confirm Appointment"}
                                                                        AcceptButtonText={(confirmedBookings.indexOf(item.uuid) !== -1) ? "Confirmed" : "Confirm Appointment"}
                                                                        ContectGoginipigLink={(confirmedBookings.indexOf(item.uuid) !== -1) ? true : false}
                                                                        happenGoginipigLink=""
                                                                        disabled={(confirmedBookings.indexOf(item.uuid) !== -1) ? true : ""}
                                                                        //hideflag="hide"
                                                                        addClassConfirmHide={(textconfirmed === true && bookingId === item.uuid) ? "hide" : null}
                                                                        newArray={newArray.length ? item.id === newArray[index].id ? newArray[index].status : false : 'no'}
                                                                    />
                                                                </div>


                                                            )
                                                        })
                                                    }
                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                    <Modal
                                        isOpen={modalIsOpen}
                                        onRequestClose={closeModal}
                                        to
                                        style={customStyles}
                                        shouldCloseOnOverlayClick={false}
                                        contentLabel="Example Modal"
                                    >


                                        <div>
                                            <LoadingSpinner />
                                        </div>

                                    </Modal>


                                </Col>
                            </Row>
                        </Container>
                }
            </section>
        </>
    );
}

export default ConfirmAppointment;