import React from "react";
import {star,reviewAvatar} from '../../../config/images'


const ReviewSlider = (props) => {
  return (
    <>
      <div className="clientreview">
        <div className="profile-media">
          <div className="client-avtar">
            <img src={reviewAvatar} alt="rating" />
          </div>
          <div className="clientNameBox">
            <p className="client-name">Imogen Sandford</p>
            <span className="client-rating" style={{ backgroundImage:`url(${star})` }}></span>
          </div>
        </div>
        <div className="client-decs">
            <p>I love GoGiniPig, I use it all the time! 
              I have enjoyed every treatment and 
              it is so great getting to work with trainees.
               Discounted treatments & a relaxing time!</p>
        </div>
      </div>
    </>
  );
};

export default ReviewSlider;
