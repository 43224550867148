import React, { useCallback, useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import { LeftArrow, badges_icon, iconFav, star, icon_suitability, icon_reliability, icon_etiquette, ginipic_avtar, practitioner_avtar } from '../../config/images';
import { getLocalStorageData, clearLocalStorage, setLocalStorageData } from '../../utils/storage'
import {
    logoutAPI_Action,
    resetUserAPI_Action,
    resetLoginAPI_Action,
    resetRegisterAPI_Action,
    resetPractitionerAddInfoAPI_Action,
    resettreatmentaddreviewAPI_Action,
    resetPractionerCategory_API_Action,
    resetpractitionerProfileAPI_Action,
    resetgoginipigprofileAPI_Action,
    userdetailsAPI_Action,
    resetGinipigUpdateAPI_Action,
    favtratmentDetailsAPI_Action,
     resetfavtratmentDetailsAPI_Action,
     favUnfav_Action,
      favUnfav_Actiontreatment, resetUserAPI_ActionTreatment,resetStripeAccountValidityAPI_Action
  } from "../../stores/actions";
import { formatPostcode } from '../../utils/utility';
import ProfileReviewCard from '../../components/UIComponent/ProfileReviewCard';
import moment from 'moment';
import {get24HrsTimeFormat}from '../../utils/utility'
import { S3_BUCKET_LINK } from "../../config/ApiConstants";

const GinipigProfile = (props) => {

    const dispatch = useDispatch();
    const [key, setKey] = useState('upcoming');
    let history = useHistory();
    let loginTokenData = getLocalStorageData("loginData");
    const profileDetails = useSelector(state => state.userdetails);
    const favtreatmentdeatils = useSelector(state => state.favtreatmentdeatils);
    const favDetails = useSelector(state => state.favDetails);
    const [isDataFavourite, setIsDataFavourite] = useState(false);
    const [isDataFavouriteindex, setIsDataFavouriteIndex] = useState(0);
    const [isDataFavouriteTreatment, setIsDataFavouriteTreatment] = useState(false);
    const [isApiCall, setIsApiCall] = useState(false);
    const [treatmentuserId, setTreatmentuserId] = useState();
    const [catId, setcatId] = useState();

    const [favicon, setFavIcon] = useState(0);
    var fav_icon_array = []

   

    useEffect(() => {
        clearLocalStorage("update_ginipig_profile");
        clearLocalStorage("requestReviewDetail")
    //  console.log("tabkey ", getLocalStorageData('tabkey'))
        var tabkey = getLocalStorageData('tabkey');
        if (tabkey != undefined) {
            setKey(tabkey)
            clearLocalStorage('tabkey')
        }
        if (!isApiCall) {
            __apiCall();
            setIsApiCall(true)
        }
        //console.log("profileDetails", profileDetails)
        if(profileDetails && profileDetails.userdetailsdata &&  profileDetails.userdetailsdata.status_code===401){
            clearLocalStorage("loginData")
          clearLocalStorage("practitionersignupData")
           dispatch(resetLoginAPI_Action());
           dispatch(resetUserAPI_Action());
           dispatch(resetRegisterAPI_Action());
           dispatch(resetPractitionerAddInfoAPI_Action());
           dispatch(resettreatmentaddreviewAPI_Action());
           dispatch(resetPractionerCategory_API_Action());
           dispatch(resetpractitionerProfileAPI_Action());
           dispatch(resetGinipigUpdateAPI_Action());
           dispatch(resetgoginipigprofileAPI_Action());
           dispatch(resetStripeAccountValidityAPI_Action());
           window.scrollTo(0, 0);
       
    
          history.push("/login");
        }

    });

    

    const __apiCall = useCallback(async () => {
        //    console.log("param_data ", loginTokenData);
        if (loginTokenData) {
            if (loginTokenData != null || loginTokenData != undefined) {
                try {
                    await dispatch(userdetailsAPI_Action(loginTokenData.token));
                } catch (error) {
                    console.error(" profile ::  profile() :: Exception ==", error);
                }
            }
        }

    });

    const favUnfav = async (id, index) => {
        setFavIcon(id)
        try {
            setIsDataFavouriteIndex(index)
            await dispatch(favUnfav_Action(id, loginTokenData.token));
            setIsDataFavourite(true)

            // profileDetails.userdetailsdata.data.users_favourite_practitioner[index].favicon = ginipic_avtar
            //setFavIcon(ginipic_avtar);
            fav_icon_array[index] = practitioner_avtar;
            //  console.log('index of ', index, ' arr ', fav_icon_array)
        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    }

    if (isDataFavourite) {
        setTimeout(() => {
            //   console.log('favDetails ', favDetails)
            if (favDetails && favDetails.favdetailsdata != null && favDetails.isSuccess === true) {
                if (favDetails.favdetailsdata.message === "Remove as Favourite Practitioner.") {
                    setIsDataFavourite(false)
                    window.scrollTo(0, 0)
                    //setFavIcon(0)    
                    __apiCall()
                }
            }
        }, 2)
    }

    const favUnfavTreatment = async (id, index) => {
        //setFavIcon(id)
        setLocalStorageData('tabpankey', catId + 1);

        try {
            await dispatch(favUnfav_Actiontreatment(id, loginTokenData.token, 2));
            setIsDataFavouriteTreatment(true)

        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    }

    if (isDataFavouriteTreatment) {
        setTimeout(() => {
            if (favDetails && favDetails.favdetailsdata != null && favDetails.isSuccess === true) {
                if (favDetails.favdetailsdata.message === "Remove as Favourite Treatment.") {
                    setIsDataFavouriteTreatment(false)
                    window.scrollTo(0, 0)
                    __apiCall()
                    //setFavIcon(0)    
                }
            }
        }, 2)
    }

    if (favDetails && favDetails.favdetailsdata !== null && favDetails.isSuccess === false) {
        if (favDetails.favdetailsdata.status_code === 401) {
            clearLocalStorage("loginData")
            clearLocalStorage("practitionersignupData")
            dispatch(resetLoginAPI_Action());
            dispatch(resetpractitionerProfileAPI_Action());
            dispatch(resetGinipigUpdateAPI_Action());
            dispatch(resetgoginipigprofileAPI_Action());
            dispatch(resetfavtratmentDetailsAPI_Action());
            dispatch(resetUserAPI_ActionTreatment());
            window.scrollTo(0, 0);
            history.push('/login');
        }
    }


    const __ProfileEdit = () => {
        clearLocalStorage('tabkey');
        //dispatch(resetgoginipigprofileAPI_Action());
        dispatch(resetgoginipigprofileAPI_Action());
        dispatch(resetGinipigUpdateAPI_Action());

        window.scrollTo(0, 0);
        history.push('/ginipig-edit');
    }

    const __logout = () => {
        clearLocalStorage('tabkey');
        window.scrollTo(0, 0);
        history.push('/logout-confirmation');
    }

    const __seeUpcomingDetails = (upcomingData) => {
        window.scrollTo(0, 0);
        setLocalStorageData('tabkey', key)

        history.push('/upcoming-pending-appointment/' + upcomingData.uuid);
    }

    const showPractitionerProfile = (treatmentdata) => {
        window.scrollTo(0, 0);

        clearLocalStorage('book_close_request');
        clearLocalStorage("book_open_request_time");
        setLocalStorageData('tabkey', key)
        setLocalStorageData('tabpankey', 1);
        history.push({
            pathname: '/practitioner-profile-ginipig',
            state: { 'paramData': treatmentdata.practitioners.uuid, 'profile': true }
        });
    }



    const showPractitionerTreatmentProfile = (treatmentdata) => {
        //    console.log("treatmentdata ", treatmentdata)
       
        setTreatmentuserId(treatmentdata.treatment.user.uuid)
        setcatId(treatmentdata.treatment.category_id)
        //window.scrollTo(0, 0);
        clearLocalStorage('book_close_request');
        clearLocalStorage("book_open_request_time");
      //  console.log("tabkey",key)
      

        setTimeout(() => {
           
            setLocalStorageData('tabkey', key)
         }, 2)
        __apiCalltreatment(treatmentdata.treatment.uuid)
       // dispatch(favtratmentDetailsAPI_Action(treatmentdata.treatment_id, loginTokenData.token));

        // history.push({
        //     pathname: '/practitioner-profile-ginipig',
        //     state: { 'paramData': treatmentdata.treatment.user_id, 'profile': true }
        // });


    }

    
    const __apiCalltreatment = useCallback(async (treatment_id) => {
        //    console.log("param_data ", loginTokenData);
        if (loginTokenData) {
            if (loginTokenData != null || loginTokenData != undefined) {
                try {
                    await  dispatch(favtratmentDetailsAPI_Action(treatment_id, loginTokenData.token));
                } catch (error) {
                    console.error(" profile ::  profile() :: Exception ==", error);
                }
            }
        }

    });
    
    if (favtreatmentdeatils.isSuccess === true) {
        if (favtreatmentdeatils.favtreatmentdeatilsdata.data.requestData.length === 0) {
            dispatch(resetfavtratmentDetailsAPI_Action());
            dispatch(resetpractitionerProfileAPI_Action());
            setLocalStorageData('tabkey', key)
          
            setLocalStorageData('tabpankey', catId + 1);
            window.scrollTo(0,0);
           // setLocalStorageData('tabpankey', 1);
            history.push({
                pathname: '/practitioner-profile-ginipig',
                state: { 'paramData': treatmentuserId, 'profile': true, 'categoryId': catId }
            });
        } else {
            let requestid = favtreatmentdeatils.favtreatmentdeatilsdata.data.requestData.uuid
            if (favtreatmentdeatils.favtreatmentdeatilsdata.data.requestData.type === 2) {
                clearLocalStorage('book_close_request')
                window.scrollTo(0, 0);
                history.push('/treatment-detail-for-close-request/' + requestid);
            } else {
                clearLocalStorage("book_open_request_time");
                window.scrollTo(0, 0);
                history.push('/treatment-detail-for-open-request/' + requestid);
            }
        }

    }else if (favtreatmentdeatils.isSuccess === false) {
        if(favtreatmentdeatils.favtreatmentdeatilsdata !== null){
            if(favtreatmentdeatils.favtreatmentdeatilsdata.status_code === 401){
                clearLocalStorage("loginData")
                clearLocalStorage("practitionersignupData")
                dispatch(resetLoginAPI_Action());
                dispatch(resetpractitionerProfileAPI_Action());
                dispatch(resetGinipigUpdateAPI_Action());
                dispatch(resetgoginipigprofileAPI_Action());
                dispatch(resetfavtratmentDetailsAPI_Action());
                window.scrollTo(0, 0);
                history.push('/login');
            }
        }
    }

    const _redirctreviewClick = (e, item) => {
        //console.log("item", item)
        e.preventDefault();
        window.scrollTo(0, 0);
        setLocalStorageData('tabkey', key)
        setLocalStorageData("requestReviewDetail", item)
        history.push({
            pathname: '/leave-review-pastbooking',
        });

    }

    return (
        <>
            <section className="ggp-profile bgcolor-gray common-section ginipig-profile_pg gp_profile pb80">
                <Container>
                    <Row>
                        <Col className="col-lg-11 m-auto">
                            <div className="navigationTop" style={{paddingRight:0}}>
                                <div className="heading-history">
                                    <div className="back-history">
                                        <button type="button" onClick={() => { clearLocalStorage('tabkey'); history.goBack(); }} className="back-btn" >
                                            {" "}
                                            <img src={LeftArrow} alt="arrow" />{" "}
                                        </button>
                                    </div>
                                    <div className="history-title">
                                        <h2> GiniPig Profile  </h2>
                                    </div>
                                </div>

                                <div className="headingbox alignright">
                                    <div className="nextnav">
                                        <button type="button" className="plainbtn mb-8" onClick={__logout}> Logout</button>
                                    </div>
                                </div>
                            </div>

                            {
                                profileDetails.userdetailsdata != null && profileDetails.userdetailsdata.data && !profileDetails.isFetching ?

                                    <div className="ginipig_wrp mt-24 profile-ginipic-box">
                                        <div className="btn-align">
                                            <button type="button" className="ginipig_edit mb-8" onClick={__ProfileEdit}> Edit</button>    
                                        </div>
                                        <div className="profile-boxs ginipigbox mb-24">
                                            <div className="inside_profile_box inline_row">
                                                <div className="leftbox">
                                                    <div className="user_boxs">
                                                        <div className="user-img">
                                                            {
                                                                profileDetails.userdetailsdata !== null && profileDetails.userdetailsdata !== undefined ?profileDetails.userdetailsdata &&  profileDetails.userdetailsdata.data &&  profileDetails.userdetailsdata.data.user_details.profile_pic !== "" ? (

                                                                    <img src={profileDetails.userdetailsdata && profileDetails.userdetailsdata.data.user_details.profile_pic} className="usericon" alts="user-img" />

                                                                ) : <img src={ginipic_avtar} className="img_avtar" /> : <img src={ginipic_avtar} className="img_avtar" />
                                                            }

                                                        </div>
                                                        <div className="user_name">
                                                            <div className="name">
                                                                <p>{profileDetails.userdetailsdata.data.user_details.first_name + ' ' + profileDetails.userdetailsdata.data.user_details.last_name}
                                                                  
                                                                </p>
                                                                <span className="ratingbox">
                                                                        <img src={star} alt="raing icon" />
                                                                        <span className="rating-point">({profileDetails.userdetailsdata.data.user_details.review_rating_ginipig})</span>
                                                                    </span>
                                                            </div>
                                                            <div className="user_address mt-8">
                                                                <p>{profileDetails.userdetailsdata.data.user_details.email}</p>
                                                                <p>{profileDetails.userdetailsdata.data.user_details.telephone_no}</p>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className="rightbox">
                                                    <div className="rating_view gini-review">
                                                        <div className="rating-item">
                                                            <ProfileReviewCard
                                                                ImgIcon={icon_reliability}
                                                                ratingName="Reliability"
                                                                initialRating={Math.ceil(profileDetails.userdetailsdata.data.user_details.reliabilityAverage)}
                                                            //initialRating={3.5}
                                                            />
                                                        </div>
                                                        <div className="rating-item">
                                                            <ProfileReviewCard
                                                                ImgIcon={icon_etiquette}
                                                                ratingName="Friendliness "
                                                                initialRating={Math.ceil(profileDetails.userdetailsdata.data.user_details.etiquetteAverage)}

                                                            />

                                                        </div>
                                                        {/* <div className="rating-item">
                                                            <ProfileReviewCard
                                                                ImgIcon={icon_suitability}
                                                                ratingName="Suitability"
                                                                initialRating={Math.ceil(profileDetails.userdetailsdata.data.user_details.suitabilityAverage)}
                                                            />
                                                        </div> */}
                                                        <div className="rating-item show-mob">
                                                            <ProfileReviewCard
                                                                overallrating={Math.ceil(profileDetails.userdetailsdata.data.user_details.review_rating_ginipig)}
                                                                ratingName="Overall"
                                                                initialRating={Math.ceil(profileDetails.userdetailsdata.data.user_details.review_rating_ginipig)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mb-24 booking_upcoming_wrp ginipig_profile_wrp">
                                            <Tabs
                                                transition={false}
                                                id="controlled-tab-example"
                                                activeKey={key}
                                                onSelect={(k) => setKey(k)}>
                                                <Tab eventKey="upcoming" title="Upcoming Bookings" label="Tab 1">

                                                    {
                                                        profileDetails.userdetailsdata != null && profileDetails.userdetailsdata.data.users_coming_bookings.length ?
                                                            <div className="inside_tab_content upcoming-insides upcoming_mobile">

                                                                {profileDetails.userdetailsdata.data.users_coming_bookings.map((upcomingData, upcoming_index) => {

                                                                    var time = '';
                                                                    let period = ""
                                                                    let hours = ''
                                                                    let [sHours, minutes] = ""

                                                                    if (upcomingData.time !== null) {
                                                                        [sHours, minutes] = upcomingData.time && upcomingData.time.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
                                                                        period = +sHours < 12 ? 'am' : 'pm';
                                                                        hours = +sHours % 12 || 12;
                                                                    }
                                                                    let timein24hrs= get24HrsTimeFormat(upcomingData.time && upcomingData.time)


                                                                    //var set_date = new Date(upcomingData.date).getDate() + ' ' + new Date([new Date(upcomingData.date).getFullYear(), ("0" + (new Date(upcomingData.date).getMonth() + 1)).slice(-2), ("0" + new Date(upcomingData.date).getDate()).slice(-2)].join("-")).toLocaleString('default', { month: 'long',year: 'numeric' }) + " @ " + hours + ":" + minutes + period

                                                                    let dateFormat = moment(upcomingData.date).format("Do MMMM YYYY")
                                                                    var set_date =  dateFormat + " @ " +timein24hrs
                                                                    //console.log("new format", set_date);
                                                                    let upcomingOpenData = upcomingData.type === 'open' && upcomingData.status === 'pending' ? 'Flexible availability' : set_date
                                                                    return (
                                                                        <div key={upcoming_index}>
                                                                            {
                                                                                upcomingData.request !== null ?
                                                                                    <div className="upcoming_cards gini_profile mt-24" onClick={() => { __seeUpcomingDetails(upcomingData) }}>
                                                                                        <div className="pad16 upside_content border_bottom">
                                                                                            <div className="content_box">
                                                                                                <p className="titles">{upcomingData.request.treatment.title}</p>
                                                                                                {upcomingData.status === 'pending' ?
                                                                                                    <div className="badge badgeshow">
                                                                                                        <div className="badgebox">
                                                                                                            <span className="badge_img">
                                                                                                                <img src={badges_icon} className="ginipigs_logo" />
                                                                                                            </span>
                                                                                                            <span className="badge_text">pending enquiry</span>
                                                                                                        </div>
                                                                                                    </div> : null}

                                                                                                <div className="category_price">
                                                                                                    <span className="price_strik og_price"> <span>£</span>{upcomingData.request.rrp_price && upcomingData.request.rrp_price.toFixed(2)}</span>
                                                                                                    <span className="price_discounted"><span>£</span>{upcomingData.request.your_price && upcomingData.request.your_price.toFixed(2)}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="pad024 downside_content">
                                                                                            <div className="down_content">
                                                                                                <div className="first-half">
                                                                                                    <p className="sub_category_txt">{upcomingData.request.postcodes !==null&& upcomingData.request.postcodes!==undefined? formatPostcode(upcomingData.request.postcodes.postcode):"Online"}</p>
                                                                                                    <p className="slot_booking">
                                                                                                    {console.log('sdsd',profileDetails)}
                                                                                                    {upcomingOpenData}
                                                                                                    </p>
                                                                                                </div>
                                                                                                <div className="sec-half">
                                                                                                    <div className="username_box">
                                                                                                        <div className="user_avatar">
                                                                                                            <img src={upcomingData.request.user.profile_pic !== null && upcomingData.request.user.profile_pic !== '' ? S3_BUCKET_LINK + upcomingData.request.user.profile_pic : practitioner_avtar} className="img_avtar" />
                                                                                                        </div>
                                                                                                        <div className="user_text">
                                                                                                            <span>{upcomingData.request.user.name}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="space_fill_box1 topmove">
                                                                                                        <button className="spc_fill_btns"  onClick={() => { __seeUpcomingDetails(upcomingData) }} > More Detail </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    : null
                                                                            }
                                                                        </div>
                                                                    )
                                                                })
                                                                }

                                                            </div> : 
                                                             <div style={{ width: "100%", marginTop: 150, textAlign: 'center' }}>
                                                             <h3 style={{ fontSize: 20, marginTop: 10 }}>Nothing to see here yet, come back soon</h3>
                                                         </div>}
                                                </Tab>
                                                <Tab eventKey="past" title="Past Bookings">

                                                    <div className="inside_tab_content past-insides past_mobile">

                                                        {
                                                            profileDetails.userdetailsdata != null && profileDetails.userdetailsdata.data.users_past_bookings.length > 0 ?
                                                                profileDetails.userdetailsdata.data.users_past_bookings.map((userPastBooking, past_booking_index) => {
                                                                    var time = '';
                                                                    let period = ""
                                                                    let hours = ''
                                                                    let [sHours, minutes] = ""

                                                                    if (userPastBooking.time !== null) {
                                                                        [sHours, minutes] = userPastBooking.time && userPastBooking.time.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
                                                                        period = +sHours < 12 ? 'am' : 'pm';
                                                                        hours = +sHours % 12 || 12;
                                                                    }
                                                                    let timein24hrsuser= get24HrsTimeFormat(userPastBooking.time && userPastBooking.time)

                                                                    //var set_date = new Date(userPastBooking.date).getDate() + ' ' + new Date([new Date(userPastBooking.date).getFullYear(), ("0" + (new Date(userPastBooking.date).getMonth() + 1)).slice(-2), ("0" + new Date(userPastBooking.date).getDate()).slice(-2)].join("-")).toLocaleString('default', { month: 'long',year: 'numeric' }) + ", " + hours + ":" + minutes + period
                                                                    let dateFormat = moment(userPastBooking.date).format("Do MMMM YYYY")
                                                                    var set_date =  dateFormat + " @ " + timein24hrsuser
                                                                    //console.log("new format", set_date);
                                                                    
                                                                    return (
                                                                        <div>
                                                                            {
                                                                                userPastBooking.request !== null ?
                                                                                    <div className="upcoming_cards gini_profile mt-24">
                                                                                        <div className="pad16 upside_content border_bottom">
                                                                                            <div className="content_box">
                                                                                                <p className="titles">{userPastBooking.request.treatment.title}</p>

                                                                                                <div className="category_price">
                                                                                                    <span className="price_strik og_price"> <span>£</span>{userPastBooking.request.rrp_price.toFixed(2)}</span>
                                                                                                    <span className="price_discounted"><span>£</span>{userPastBooking.request.your_price.toFixed(2)}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="pad024 downside_content">
                                                                                            <div className="down_content">
                                                                                                <div className="first-half">
                                                                                                    <p className="sub_category_txt">{userPastBooking.request.postcodes!==null && userPastBooking.request.postcodes!==undefined? userPastBooking.request.postcodes.postcode:"Online"}</p>
                                                                                                    <p className="slot_booking">
                                                                                                        {set_date}
                                                                                                    </p>
                                                                                                </div>
                                                                                                <div className="sec-half">
                                                                                                    <div className="username_box">
                                                                                                        <div className="user_avatar">
                                                                                                            <img src={userPastBooking.request.user.profile_pic !== null && userPastBooking.request.user.profile_pic !== '' ? S3_BUCKET_LINK + userPastBooking.request.user.profile_pic : practitioner_avtar} className="img_avtar" />
                                                                                                        </div>
                                                                                                        <div className="user_text">
                                                                                                            <span>{userPastBooking.request.user.name}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="space_fill_box1 topmove">
                                                                                                        {/* {
                                                                                                            userPastBooking.isReviewed === 0 ?
                                                                                                                <button className="spc_fill_btns leave_btn" onClick={(e) => _redirctreviewClick(e, userPastBooking)}> Leave review </button> :
                                                                                                                <button className="spc_fill_btns reviewed_btn"> Reviewed </button>
                                                                                                        } */}

                                                                                                        {
                                                                                                            userPastBooking.isReviewed === 0 && userPastBooking.request.user.status ===1 && userPastBooking.status !== 'accepted' && userPastBooking.status !== 'incompleted' && userPastBooking.status !== 'cancelled_by_ginipig' && userPastBooking.status !== 'cancelled_by_practitioner' ?
                                                                                                                <button className="spc_fill_btns leave_btn" onClick={(e) => _redirctreviewClick(e, userPastBooking)}> Leave review </button> :
                                                                                                                userPastBooking.isReviewed === 0 && userPastBooking.request.user.status === 0 && userPastBooking.status !== 'accepted' && userPastBooking.status !== 'incompleted' && userPastBooking.status !== 'cancelled_by_ginipig' && userPastBooking.status !== 'cancelled_by_practitioner' ?
                                                                                                                <button className="spc_fill_btns reviewed_btn"> Deleted </button> :
                                                                                                                userPastBooking.isReviewed === 0 && userPastBooking.request.user.status === 2 && userPastBooking.status !== 'accepted' && userPastBooking.status !== 'incompleted' && userPastBooking.status !== 'cancelled_by_ginipig' && userPastBooking.status !== 'cancelled_by_practitioner' ?
                                                                                                                <button className="spc_fill_btns reviewed_btn"> Suspended </button> :
                                                                                                                userPastBooking.isReviewed === 0 && userPastBooking.status === 'accepted' ?
                                                                                                                <button className="spc_fill_btns reviewed_btn">Not confirmed by Practitioner </button> :
                                                                                                                userPastBooking.isReviewed === 0 && userPastBooking.status === 'incompleted' ?
                                                                                                                <button className="spc_fill_btns reviewed_btn">No Show</button> :
                                                                                                                userPastBooking.isReviewed === 0 && userPastBooking.status === 'accepted' ?
                                                                                                                <button className="spc_fill_btns reviewed_btn">Not confirmed by Practitioner </button> :
                                                                                                                userPastBooking.isReviewed === 0 && userPastBooking.status === 'cancelled_by_practitioner' ?
                                                                                                                <button className="spc_fill_btns reviewed_btn">Cancelled Appointment </button> :
                                                                                                                userPastBooking.isReviewed === 0 && userPastBooking.status === 'cancelled_by_ginipig' ?
                                                                                                                <button className="spc_fill_btns reviewed_btn">Cancelled Appointment </button> :
                                                                                                                <button className="spc_fill_btns reviewed_btn"> Reviewed </button>
                                                                                                        }

                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    : null}
                                                                        </div>

                                                                    )
                                                                }) :
                                                                <div style={{ width: "100%", marginTop: 150, textAlign: 'center' }}>
                                                                <h3 style={{ fontSize: 20, marginTop: 10 }}>Nothing to see here yet, come back soon</h3>
                                                            </div>
                                                        }

                                                    </div>


                                                </Tab>
                                                <Tab eventKey="favourites" title="Favourites ">


                                                    <div className="inside_tab_content fav-insides fab_mobile">

                                                        {
                                                            profileDetails.userdetailsdata != null && profileDetails.userdetailsdata.data.users_favourite_bookings.length > 0 ?
                                                                profileDetails.userdetailsdata.data.users_favourite_bookings.map((userFavourite, fav_index) => {

                                                                    return (
                                                                        <div className="upcoming_cards gini_profile mt-24" key={fav_index}>
                                                                            <div className="pad024 inside_fev">
                                                                                <div className="first-div w30">
                                                                                    <div className="content_box">
                                                                                        <p className="titles">{userFavourite.treatment.title}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="second-div w30">
                                                                                    <div className="username_box">
                                                                                        <div className="user_avatar">
                                                                                            <img src={userFavourite.treatment.user.profile_pic !== null && userFavourite.treatment.user.profile_pic !== '' ? S3_BUCKET_LINK + userFavourite.treatment.user.profile_pic : practitioner_avtar} className="img_avtar" />
                                                                                        </div>
                                                                                        <div className="user_text">
                                                                                            <span>{userFavourite.treatment.user.first_name}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="third-div w30">
                                                                                    <div className="space_fill_box1 ">
                                                                                        <button className="spc_fill_btns " onClick={() => { showPractitionerTreatmentProfile(userFavourite) }}>  More Detail </button>

                                                                                        {/* {
                                                                                                    userFavourite.treatment_id === favicon &&
                                                                                                    <img src={practitioner_avtar} className="fav_icon" onClick={() => { favUnfavTreatment(userFavourite.treatment_id, fav_index) }} />
                                                                                                    
                                                                                                }
                                                                                                {
                                                                                                    userFavourite.treatment_id !== favicon &&
                                                                                                    <img src={iconFav} className="fav_icon" onClick={() => { favUnfavTreatment(userFavourite.treatment_id, fav_index) }} />
                                                                                                } */}

                                                                                        <img src={iconFav} className="fav_icon" onClick={() => { favUnfavTreatment(userFavourite.treatment.uuid, fav_index) }} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    )
                                                                }) : (null)
                                                        }

                                                        {
                                                            profileDetails.userdetailsdata != null && profileDetails.userdetailsdata.data.users_favourite_practitioner.length > 0 ?
                                                                profileDetails.userdetailsdata.data.users_favourite_practitioner.map((userFavouritePractitioner, fav_prac_index) => {

                                                                    return (

                                                                        <div className="upcoming_cards gini_profile mt-24">
                                                                            {
                                                                                userFavouritePractitioner.practitioners !== null ?
                                                                                    <div className="pad024 inside_fev">
                                                                                        <div className="first-div w30">
                                                                                            <div className="username_box name-with_rating">
                                                                                                <div className="user_avatar">
                                                                                                    <img src={userFavouritePractitioner.practitioners.profile_pic !== null && userFavouritePractitioner.practitioners.profile_pic !== '' ?  S3_BUCKET_LINK + userFavouritePractitioner.practitioners.profile_pic : practitioner_avtar} className="img_avtar" />
                                                                                                </div>
                                                                                                <div className="user_text">
                                                                                                    <span>{userFavouritePractitioner.practitioners.first_name}</span>
                                                                                                    <div className="ratingbox">
                                                                                                        <img src={star} className="raing_icon" />
                                                                                                        <span className="rating-point">{userFavouritePractitioner.practitioners.review_rating_practitioner.toFixed(1)}</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="second-div w30">
                                                                                            <div className="post_text">

                                                                                                <span className="role-name">{userFavouritePractitioner.practitioners !== null ? userFavouritePractitioner.practitioners.practicing_saloon === 1 ? userFavouritePractitioner.practitioners.company_name + ', ' : userFavouritePractitioner.practitioners.practitioner_category_apply !== null ? userFavouritePractitioner.practitioners.practitioner_category_apply + ',' : null : null} </span>
                                                                                                <span className="user-address"> {  userFavouritePractitioner.practitioners !== null ? userFavouritePractitioner.practitioners.user_additional_info.length > 0 ? userFavouritePractitioner.practitioners.user_additional_info[0].postcode_data !== null ? formatPostcode( userFavouritePractitioner.practitioners.user_additional_info[0].postcode_data.postcode ) ? formatPostcode(  userFavouritePractitioner.practitioners.user_additional_info[0].postcode_data.postcode ) + " " : null : null : null : null}</span>
                                                                                                {/* <span className="user-address"> {userFavouritePractitioner.practitioners !== null ? userFavouritePractitioner.practitioners.user_additional_info.length > 0 ? userFavouritePractitioner.practitioners.user_additional_info[0].postcode_data !== null ? userFavouritePractitioner.practitioners.user_additional_info[0].postcode_data.postcode ? userFavouritePractitioner.practitioners.user_additional_info[0].postcode_data.postcode.substring(0, 3) + " " : null : null : null : null}</span> */}
                                                                                                {/* <span className="role-name">{practitionerData !== null ? practitionerData.practitioner_type === "Individual" ? practitionerData.practicing_saloon === 1 ? practitionerData.establishment_name : "Individual" : practitionerData.practicing_saloon === 1 ? practitionerData.company_name : "Bussiness" : null},</span> */}
                                                                                                <span className="user-address"> {userFavouritePractitioner.practitioners !== null ? userFavouritePractitioner.practitioners.user_additional_info.length > 0 ? userFavouritePractitioner.practitioners.user_additional_info[0].postcode_data !== null ? userFavouritePractitioner.practitioners.user_additional_info[0].postcode_data.city : null : null : null} </span>
                                                                                                {/* {
                                                                                                    userFavouritePractitioner.practitioners.company_name !== null ?
                                                                                                        <p>{userFavouritePractitioner.practitioners.practicing_saloon === 1 ? userFavouritePractitioner.practitioners.company_name + ',' : userFavouritePractitioner.practitioners.practitioner_category_apply !== null ? userFavouritePractitioner.practitioners.practitioner_category_apply + ',' : null} {userFavouritePractitioner.practitioners.user_additional_info.length > 0 ? userFavouritePractitioner.practitioners.user_additional_info[0].postcode_data.postcode : ''}</p>
                                                                                                        :
                                                                                                        <p>Indivitual{userFavouritePractitioner.practitioners.user_additional_info.length > 0 ? ', ' + userFavouritePractitioner.practitioners.user_additional_info[0].postcode_data.postcode : ''}</p>

                                                                                                } */}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="third-div w30">
                                                                                            <div className="space_fill_box1 ">
                                                                                                <button className="spc_fill_btns " onClick={() => { showPractitionerProfile(userFavouritePractitioner) }} > More Detail </button>
                                                                                                {/* {console.log(fav_icon_array[fav_prac_index] , fav_icon_array[fav_prac_index])} */}
                                                                                                <div>

                                                                                                    {/* {
                                                                                                    userFavouritePractitioner.practitioner_id === favicon &&
                                                                                                    <img src={practitioner_avtar} className="fav_icon unselect_icon_fav" onClick={() => {   favUnfav(userFavouritePractitioner.practitioner_id, fav_prac_index) }} />
                                                                                                }
                                                                                                {
                                                                                                    userFavouritePractitioner.practitioner_id !== favicon &&
                                                                                                    <img src={iconFav} className="fav_icon" onClick={() => {   favUnfav(userFavouritePractitioner.practitioner_id, fav_prac_index) }} />
                                                                                                } */}
                                                                                                    <img src={iconFav} className="fav_icon" onClick={() => { favUnfav(userFavouritePractitioner.practitioner_id, fav_prac_index) }} />


                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div> : null
                                                                            }
                                                                        </div>
                                                                    )
                                                                }) : (null)
                                                        }
                                                        { profileDetails.userdetailsdata != null && profileDetails.userdetailsdata.data.users_favourite_bookings.length == 0 &&   profileDetails.userdetailsdata != null && profileDetails.userdetailsdata.data.users_favourite_practitioner.length == 0 ?
                                                              <div style={{ width: "100%", marginTop: 150, textAlign: 'center' }}>
                                                              <h3 style={{ fontSize: 20, marginTop: 10 }}>Nothing to see here yet, come back soon</h3>
                                                          </div>:null
                                                        }
                                                    </div>
                                                </Tab>

                                            </Tabs>

                                        </div>


                                    </div> :
                                    <div style={{ width: "100%", fontSize: 20, marginTop: 100, textAlign: 'center' }}>
                                        <p>Loading ...</p>
                                    </div>
                            }

                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default GinipigProfile;