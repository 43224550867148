import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Container, Row, Col, Tab, Nav, Accordion } from 'react-bootstrap';
import { LeftArrow, lpimg1, searchIcon, locationIcon, dateIcon, star, icon_filter_close, icon_filter } from '../../config/images';
import { useHistory } from 'react-router-dom';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import { whatsAvailableAPI_Action, resetPhotoDetails, resetbookingDetailsSave, saveFilterData, saveSearchData, resetpractitionerProfileAPI_Action, resetAPI_ActionWhatAvailable } from '../../stores/actions'
import DatePicker from "react-datepicker";
import TreatmentsListing from './TreatmentsListing';
import LargeButton from '../../components/LargeButton';
import ReactSlider from 'react-slider'
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import usePlacesAutocomplete, { getGeocode, getLatLng, getZipCode } from 'use-places-autocomplete';
import { setLocalStorageData, getLocalStorageData, clearLocalStorage } from '../../utils/storage';
import { useRef } from 'react';
import { isMobile } from 'react-device-detect';
import * as _ from "lodash";


const AvailableTreatments = (props) => {

  const dispatch = useDispatch();
  let history = useHistory();
  //const [ value, setValue ] = useState(0); 
  const [offset, setOffset] = useState(0);
  const [perPage, setPerpage] = useState(10);
  const [filterpricedefault, setfilterpricedefault] = useState((7.50).toFixed(2));
  const [currentPage, setCurrentpage] = useState(0);

  const [filterActive, setFiterActive] = useState(false);
  const [cross, setCross] = useState(false);
  const [startDate, setStartDate] = useState();
  const [sliderMaxPrice, setSliderMaxPrice] = useState(10);
  
  const [defaultminprice, setdefaultMinprice] = useState((7.50).toFixed(2));
  const [defaultmaxprice, setdefaultmaxprice] = useState(0);

  const [startMaxDate, setStartMaxDate] = useState(new Date());
  const [earliest_availability, setearliest_availability] = useState(false);
  const [price_high_low, setprice_high_low] = useState(false);
  const [price_low_high, setprice_low_high] = useState(false);
  const [highest_rating, sethighest_rating] = useState(false);
  const [closet_me, setcloset_me] = useState(false);
  const [training, settraining] = useState(false);
  const [case_study, setcase_study] = useState(false);
  const [portfolio, setportfolio] = useState(false);
  const [new_bie, setnew_bie] = useState(false);
  const [nearly_there, setnearly_there] = useState(false);
  const [ready_to_go, setready_to_go] = useState(false);
  const [post_data, setPostData] = useState('');
  const [sort_by, setSortBy] = useState('&sort_by=');
  const [treatpurpose, setTreatmentPurpose] = useState('&level=');
  const [is_online, setIs_online] = useState('&is_online=');
  const [complevel, setCompLevel] = useState('&purpose=');
  const [price, setPrice] = useState('&price=');

  const [user_max_price, setMaxUserPrice] = useState(0);
  const [user_min_price, setMinUserPrice] = useState(0);

  const [keywords, setKeywords] = useState('keywords=');
  const [date_post, setDatePost] = useState('&date=');
  const [price_min, setPriceMin] = useState([0, 0]);
  const [distance_Cal, setDistanceCal] = useState([0, 0]);
  const [location, setLocation] = useState('&location=');
  const [latitude, setLatitude] = useState('&latitude=');
  const [longitude, setLongitude] = useState('&longitude=');
  const [city, setCity] = useState('&city=');

  const [postcode, setPostcode] = useState('&postcode=');
  const [distance_send, setDistanceSend] = useState('&distance=');
  const loginUserData = getLocalStorageData('loginDataUser');
  const [disableDistanceSlider, setDisableDistanceSlider] = useState(false);
  const [pricefiltercheck, setpricefiltercheck] = useState('0');
  const [onlinefiltercheck, setOnlinefiltercheck] = useState(0);

  const [checkvar, setCheckvar] = useState('1');
  const [priceHide, setpriceHide] = useState(true);

  const __fliterOnclick = (e) => {
    setFiterActive(!filterActive);
  }

  var param_data = '';

  if (props.location.state !== undefined) {
    if (props.location.state.hasOwnProperty('data')) {
      param_data = JSON.parse(props.location.state.data);
      if (props.location.state.date === 'Any date') {
        param_data.date = props.location.state.date
      }
    } else {
      var dataparse = JSON.parse(props.location.state)
      param_data = dataparse;
    }
  } else {
    // console.log("inside else")
    //history.push('/')
    param_data = ""
  }



  // date picker keyboard patch 
  const pickerRef = useRef(null);

  useEffect(() => {
    if (isMobile && pickerRef.current !== null) {
      pickerRef.current.input.readOnly = true;
    }
  }, [isMobile, pickerRef]);



  const whatsavailabledata = useSelector(state => state.whatsavailable);
  var filterData;
  var price1 = 0;
  var distance_check = 0;

  if (param_data.location === '') {
    param_data.location = 'Any location'
  }



  // useEffect(() => {
  //   console.log('out init' , whatsavailabledata)
  //   //whatsavailabledata.isFetching ?
  //     if(whatsavailabledata.whatsavailabledata !== null && whatsavailabledata.whatsavailabledata.data !== null && whatsavailabledata.whatsavailabledata.data.requests.data.length > 0){
  //       //console.log("in price")
  //       setpriceHide(true) 
  //       console.log("in price true" , priceHide)
  //       //const [price_hi, setPostcode] = useState('&postcode=');
  //     }
  //    //: null
  // },[whatsavailabledata]);


  const [search_keywords, setSearchKeyword] = useState(param_data.keywords);
  const [search_location, setSearchLocation] = useState(param_data.location);
  const [search_latitude, setSearchLatitude] = useState(param_data.latitude);
  const [search_city, setSearchCity] = useState(param_data.city);
  const [search_longitude, setSearchLongitude] = useState(param_data.longitude);
  const [search_postcode, setSearchPostcode] = useState(param_data.postcode);
  const [stepcount, setstepcount] = useState();
  const [maxprice, setmaxPrice] = useState();
  const [minprice, setminprice] = useState();
  const [marksArray, setMarksArray] = useState();

  const [maxDistance, setmaxDistance] = useState();
  const [minDistance, setminDistance] = useState();

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
      componentRestrictions: {
        country: 'uk',
        city: false
      }
    },
    debounce: 300,
    // componentRestrictions: {country: "us"}
    callbackName: 'initMap'
  });




  const __nextClose = () => {
    window.scrollTo(0, 0);
    history.push('/treatment-detail-for-close-request')
  }
  const __nextOpen = () => {
    window.scrollTo(0, 0);
    clearLocalStorage("book_open_request_time");
    history.push('/treatment-detail-for-open-request')
  }

  const getdata = useSelector(state => state.savefilter);
  //console.log('whatsavailabledata ', whatsavailabledata.whatsavailabledata)

  // Use effects
  useEffect(() => {
    
    if (checkvar !== param_data.keywords) {
      setCurrentpage(0)
      setOffset(0)
      setCheckvar(param_data.keywords);
      callDataForAPI()
      saveSearchData(param_data)
    }
    clearLocalStorage('photo_requirement_available')
    clearLocalStorage('bookingcondition_requirement')
    clearLocalStorage('photo_added');
  });


  if (pricefiltercheck === '0') {
    if (whatsavailabledata.whatsavailabledata && whatsavailabledata.whatsavailabledata !== null && whatsavailabledata.isSuccess && whatsavailabledata.whatsavailabledata.data!==undefined) {
      //  console.log("inside slider render ", ' ', maxprice, ' ', pricefiltercheck)
      setmaxPrice(whatsavailabledata.whatsavailabledata.data.maximum_price&&  whatsavailabledata.whatsavailabledata.data.maximum_price)
      setdefaultmaxprice(Math.round(whatsavailabledata.whatsavailabledata.data.maximum_price))

      // prepare marks array for the price slider 
      let tmpArr = [];
      let mxVal = whatsavailabledata.whatsavailabledata.data.maximum_price;
      let i = 7.5;

      while (i <= mxVal) {
        tmpArr.push(i);
        if (i < 10) {
          i = i + 0.5;
        } else if (i >= 10 && i <= 49) {
          i = i + 1;
        }
        else if (i >= 50 && i <= 99) {
          i = i + 5;
        } else {
          i = i + 10;
        }

      }

      setMarksArray(tmpArr);


      // if (whatsavailabledata.whatsavailabledata.data.maximum_price % 1 === 0) {
      //   setmaxPrice(Math.round(whatsavailabledata.whatsavailabledata.data.maximum_price))
      // } else {
      //   setmaxPrice(Math.round(whatsavailabledata.whatsavailabledata.data.maximum_price + 1))
      // }

      // if (whatsavailabledata.whatsavailabledata.data.maximum_price <= 10) {
      //   setmaxPrice(whatsavailabledata.whatsavailabledata.data.maximum_price + 0.5)
      // }
      //  else if (whatsavailabledata.whatsavailabledata.data.maximum_price > 10 && whatsavailabledata.whatsavailabledata.data.maximum_price <= 50) {
      //   setmaxPrice(whatsavailabledata.whatsavailabledata.data.maximum_price + 1)
      // } 
      // else if (whatsavailabledata.whatsavailabledata.data.maximum_price > 50 && whatsavailabledata.whatsavailabledata.data.maximum_price <= 100) {
      //   setmaxPrice(whatsavailabledata.whatsavailabledata.data.maximum_price)

      // } else {
      //   setmaxPrice(whatsavailabledata.whatsavailabledata.data.maximum_price + 10)

      // }


      if (whatsavailabledata.whatsavailabledata.data.user_maximum_price !== null) {
        setMaxUserPrice(whatsavailabledata.whatsavailabledata.data.user_maximum_price)
      }
      if (whatsavailabledata.whatsavailabledata.data.user_minimum_price !== null) {
        setMinUserPrice(whatsavailabledata.whatsavailabledata.data.user_minimum_price)
      }

      if (whatsavailabledata.whatsavailabledata.data.minimum_price % 1 === 0) {
        setminprice(Math.round(whatsavailabledata.whatsavailabledata.data.minimum_price))
      } else {
        if (Math.round(whatsavailabledata.whatsavailabledata.data.minimum_price - 1) === 0) {
          setminprice(1)
        } else {
          setminprice(Math.round(whatsavailabledata.whatsavailabledata.data.minimum_price - 1))
        }
      }


      setminDistance(whatsavailabledata.whatsavailabledata.data.minimum_distance)
      setmaxDistance(whatsavailabledata.whatsavailabledata.data.maximum_distance)






      //  console.log("inside slider render ", ' ', maxprice, ' ', minprice, ' distance ', minDistance, maxDistance)
      setpricefiltercheck('1');
    }
  } else {
    //  console.log("minDistance ", minDistance, ' ', pricefiltercheck)
    //setminDistance(whatsavailabledata.whatsavailabledata.data.minimum_distance)
    //setmaxDistance(whatsavailabledata.whatsavailabledata.data.maximum_distance)
  }
  const callDataForAPI = () => {

    var datemax = new Date();
    datemax.setMonth(datemax.getMonth() + 3);
    setStartMaxDate(datemax)
    //param_data = JSON.parse(param_data);
    // console.log("param_data ", param_data);
    if (param_data.location !== '') {
      if (param_data.location === 'Any location') {
        setDisableDistanceSlider(true)
      } else
        setDisableDistanceSlider(false)
    } else {
      setDisableDistanceSlider(true)
    }

    setSearchLocation(param_data.location)
    setSearchLatitude(param_data.latitude)
    setSearchCity(param_data.city)
    setSearchLongitude(param_data.longitude)
    setSearchPostcode(param_data.postcode)
    setValue(param_data.location, false)
    clearSuggestions();

    var data = '';

    var post_date = ''; var sendkeyword = ''; var sendlocation = '';
    var sendlatitude = '';
    var sendlongitude = '';
    var sendpostcode = '';
    var sendcity = '';

    if (param_data.from_header === 'from_header') {
      setCurrentpage(0)

      clearLocalStorage('filter_treatment_data')
      setpricefiltercheck('0');
      setnearly_there(false)
      setnew_bie(false)
      setready_to_go(false)
      settraining(false)
      setcase_study(false)
      setportfolio(false)
      setearliest_availability(false)
      setprice_high_low(false)
      setprice_low_high(false)
      sethighest_rating(false)
      setCompLevel('&purpose=')
      setTreatmentPurpose('&level=')
      setSortBy('&sort_by=')
      setIs_online('is_online=')
      
      
      var pass_date = '';
      if (startDate !== '' && startDate !== undefined && startDate !== null) {
        pass_date = '&date=' + param_data.date
      } else {
        //console.log('else in ')
        pass_date = '&date=' + param_data.date
      }
      if (param_data.date !== 'Any date') {
        setStartDate(new Date(param_data.date));
      } else {
        setStartDate(param_data.date);
      }


      sendkeyword = 'keywords=' + param_data.keywords
      if (param_data.location === 'Any location') {
        sendlocation = '&location=' + ''
      } else {
        sendlocation = '&location=' + param_data.location
      }
      if (param_data.location === undefined) {
        sendlocation = '&location=' + ''
      }

      sendlatitude = '&latitude=' + param_data.latitude
      sendlongitude = '&longitude=' + param_data.longitude
      sendpostcode = '&postcode=' + param_data.postcode
      sendcity = '&city=' + param_data.city
      post_date = param_data.date

      setDatePost(pass_date);
      // console.log("from not header ", date_post, ' ', post_date, ' ', pass_date)
      setSearchKeyword(param_data.keywords)

    } else if (param_data.from_header === "not_from_header") {
      setpricefiltercheck('0');

      //   console.log("inside else ", param_data.date, ' l ', props.location.state.date)
      if (param_data.date !== 'Any date') {
        setStartDate(new Date(param_data.date))
      } else {
        setStartDate(param_data.date);
      }
      sendkeyword = 'keywords=' + param_data.keywords
      if (param_data.location === 'Any location') {
        sendlocation = '&location=' + ''
      } else {
        sendlocation = '&location=' + param_data.location
      }
      sendlatitude = '&latitude=' + param_data.latitude
      sendlongitude = '&longitude=' + param_data.longitude
      sendpostcode = '&postcode=' + param_data.postcode
      sendcity = '&city=' + param_data.city
      post_date = props.location.state.date
      if (param_data.date !== undefined) {
        setDatePost(date_post + param_data.date);
      }
    } else {
      // console.log("not from d and hp")
      sendlatitude = '';
      sendlongitude = '';
      sendpostcode = '';
      sendcity = '';

    }

    setKeywords(keywords + param_data.keywords);
    setLocation(location + param_data.location);
    setLatitude(latitude + param_data.latitude);
    setLongitude(longitude + param_data.longitude);
    setPostcode(postcode + param_data.postcode);
    setCity(city + param_data.city)
    setPostData(data);

    // console.log("price_min ", data)

    if (getLocalStorageData("filter_treatment_data") !== undefined) {
      filterData = JSON.parse(getLocalStorageData("filter_treatment_data"))
    }
    // console.log("local data ", filterData)
    if (filterData) {

      if (filterData.hasOwnProperty('filterDetails')) {
        if (filterData.filterDetails !== null) {
          price1 = filterData.filterDetails.price_min[0] + ',' + filterData.filterDetails.price_min[1];
          distance_check = filterData.filterDetails.distance_Cal[0] + ',' + filterData.filterDetails.distance_Cal[1];

          setearliest_availability(filterData.filterDetails.earliest_availability)
          sethighest_rating(filterData.filterDetails.highest_rating)
          setprice_low_high(filterData.filterDetails.price_low_high)
          setprice_high_low(filterData.filterDetails.price_high_low)
          setcloset_me(filterData.filterDetails.closet_me)
          settraining(filterData.filterDetails.training)
          setcase_study(filterData.filterDetails.case_study)
          setportfolio(filterData.filterDetails.portfolio)
          setnew_bie(filterData.filterDetails.new_bie)
          setnearly_there(filterData.filterDetails.nearly_there)
          setready_to_go(filterData.filterDetails.ready_to_go)


          if (filterData.filterDetails.price_low_high === true || filterData.filterDetails.price_high_low === true || filterData.filterDetails.closet_me === true || filterData.filterDetails.earliest_availability === true) {
            data = data + sort_by;
            var sort_data = sort_by.includes('=') && sort_by.substr(sort_by.lastIndexOf('=') + 1).split(' ')[0]
            //   console.log("sort by ", sort_by)
            if (filterData.filterDetails.earliest_availability === true) {
              if (sort_data === '') {
                data = data + 'earliest_availability';
              } else {
                data = data + ',' + 'earliest_availability';
              }
            }

            if (filterData.filterDetails.price_low_high === true) {
              if (filterData.filterDetails.earliest_availability === true) {
                data = data + ',price_low_to_high';
              } else {
                data = data + 'price_low_to_high';
              }
              // if (sort_data === '') {
              //   data = data + 'price_low_to_high';
              // } else {
              //   data = data + ',' + 'price_low_to_high';
              // }
            }
            if (filterData.filterDetails.price_high_low === true) {
              if (filterData.filterDetails.price_low_high === true || filterData.filterDetails.earliest_availability === true) {
                data = data + ',price_high_to_low';
              } else {
                data = data + 'price_high_to_low';
              }
              // if (sort_data === '') {
              //   data = data + 'price_high_to_low';
              // } else {
              //   data = data + ',' + 'price_high_to_low';
              // }
            }

            if (filterData.filterDetails.closet_me === true) {
              if (filterData.filterDetails.price_low_high === true || filterData.filterDetails.earliest_availability === true || filterData.filterDetails.price_high_low === true) {
                data = data + ',closest_to_me';
              } else {
                data = data + 'closest_to_me';
              }
              // if (sort_data === '') {
              //   data = data + 'closest_to_me';
              // } else {
              //   data = data + ',' + 'closest_to_me';
              // }
            }
            setSortBy(data)
          }

          if (filterData.filterDetails.training === true || filterData.filterDetails.case_study === true || filterData.filterDetails.portfolio === true) {
            data = data + treatpurpose;
            var set_treat_purpose = '&level=';
            var treatpurpose_sorting = treatpurpose.includes('=') && treatpurpose.substr(treatpurpose.lastIndexOf('=') + 1).split(' ')[0];
            if (filterData.filterDetails.training === true) {
              if (treatpurpose_sorting === '') {
                data = data + 'Training';
                set_treat_purpose = set_treat_purpose + 'Training';
              } else {
                data = data + ',' + 'Training';
                set_treat_purpose = set_treat_purpose + ',Training';
              }
            }
            if (filterData.filterDetails.case_study === true) {
              if (filterData.filterDetails.training === true) {
                data = data + ',Case study';
                set_treat_purpose = set_treat_purpose + ',Case study';
              } else {
                data = data + 'Case study';
                set_treat_purpose = set_treat_purpose + 'Case study';
              }
              // if (treatpurpose_sorting === '') {
              //   data = data + 'Case study';
              // } else {
              //   data = data + ',' + 'Case study';
              // }
            }
            if (filterData.filterDetails.portfolio === true) {
              if (filterData.filterDetails.training === true || filterData.filterDetails.case_study === true) {
                data = data + ',Portfolio';
                set_treat_purpose = set_treat_purpose + ',Portfolio';
              } else {
                data = data + 'Portfolio';
                set_treat_purpose = set_treat_purpose + 'Portfolio';
              }
              // if (treatpurpose_sorting === '') {
              //   data = data + 'Portfolio';
              // } else {
              //   data = data + ',' + 'Portfolio';
              // }
            }
            setTreatmentPurpose(set_treat_purpose)

            
          }

          if (filterData.filterDetails.new_bie === true || filterData.filterDetails.nearly_there === true || filterData.filterDetails.ready_to_go === true) {
            data = data + complevel;
            var set_comp_level = '&purpose=';
            var complevel_sorting = complevel.includes('=') && complevel.substr(complevel.lastIndexOf('=') + 1).split(' ')[0];
            if (filterData.filterDetails.new_bie === true) {
              if (complevel_sorting === '') {
                data = data + 'Newbie';
                set_comp_level = set_comp_level + 'Newbie'
              } else {
                data = data + ',' + 'Newbie';
                set_comp_level = set_comp_level + ',Newbie'
              }
            }
            if (filterData.filterDetails.nearly_there === true) {
              // if (complevel_sorting === '') {
              //   data = data + 'Nearly there';
              // } else {
              //   data = data + ',' + 'Nearly there';
              // }
              if (filterData.filterDetails.new_bie === true) {
                data = data + ',Nearly there';
                set_comp_level = set_comp_level + ',Nearly there'
              } else {
                data = data + 'Nearly there';
                set_comp_level = set_comp_level + 'Nearly there'
              }
            }
            if (filterData.filterDetails.ready_to_go === true) {
              // if (complevel_sorting === '') {
              //   data = data + 'Ready to go';
              // } else {
              //   data = data + ',' + 'Ready to go';
              // }
              if (filterData.filterDetails.new_bie === true || filterData.filterDetails.nearly_there === true) {
                data = data + ',Ready to go';
                set_comp_level = set_comp_level + ',Ready to go'
              } else {
                data = data + 'Ready to go';
                set_comp_level = set_comp_level + 'Ready to go'
              }
            }
            setCompLevel(set_comp_level)
          }

          if (price1 !== '0,0') {
            var price_sorting = price.includes('=') && price.substr(price.lastIndexOf('=') + 1).split(' ')[0];
            if (price_sorting === '') {
              data = data + price + price1;
            }
          }

          if (distance_check !== '0,0') {
            var distance_soring = distance_send.includes('=') && distance_send.substr(distance_send.lastIndexOf('=') + 1).split(' ')[0];
            if (distance_soring === '') {
              data = data + distance_send + filterData.filterDetails.distance_Cal[0] + ',' + filterData.filterDetails.distance_Cal[1];
            }
          }

          // console.log('complevel_sorting ', data)
        }
      }

      if (filterData.hasOwnProperty('searchDetails')) {
        if (filterData.searchDetails !== null) {
          setSearchKeyword(filterData.searchDetails.search_keywords)
          setSearchLocation(filterData.searchDetails.search_location)
          setValue(filterData.searchDetails.search_location, false)
          clearSuggestions();

          setSearchLatitude(filterData.searchDetails.search_latitude)
          setSearchLongitude(filterData.searchDetails.search_longitude)
          setSearchPostcode(filterData.searchDetails.search_postcode)
          setSearchCity(filterData.searchDetails.search_city)
          //setSearchCity(filterData.searchDetails.city)
          if (param_data.from_header === 'from_header') {
            if (param_data.date !== 'Any date') {
              setStartDate(new Date(param_data.date))
            } else {
              setStartDate(param_data.date);
            }
            setSearchKeyword(param_data.keywords)
            sendkeyword = 'keywords=' + param_data.keywords
            if (param_data.location === 'Any location') {
              sendlocation = '&location=' + ''
            } else {
              sendlocation = '&location=' + param_data.location
            }
            sendlatitude = '&latitude=' + param_data.latitude
            sendlongitude = '&longitude=' + param_data.longitude
            sendpostcode = '&postcode=' + param_data.postcode
            sendcity = '&city=' + param_data.city

          } else {
            if (filterData.searchDetails.startDate !== 'Any date') {
              setStartDate(new Date(filterData.searchDetails.startDate))
              post_date = moment(filterData.searchDetails.startDate).format('YYYY-MM-DD')
            } else {
              setStartDate(param_data.date);
            }
            sendkeyword = keywords + filterData.searchDetails.search_keywords
            if (filterData.searchDetails.search_location === 'Any location') {
              sendlocation = location + ''
            } else {
              sendlocation = location + filterData.searchDetails.search_location
            }
            sendlatitude = latitude + filterData.searchDetails.search_latitude
            sendlongitude = longitude + filterData.searchDetails.search_longitude
            sendpostcode = postcode + filterData.searchDetails.search_postcode
            sendcity = city + filterData.searchDetails.search_city
          }

          // console.log("send keyword ", data, ' post_date ', post_date)
        }
      }

    }
    //  console.log("date post last ", date_post, ' po ', post_date)
    //data =  sendkeyword  + sendlocation + sendlatitude + sendlongitude + sendpostcode + date_post + post_date + data;

    if (param_data.from_header === "not_from_header") {
      var setdate = '&date=' + param_data.date;
      if (param_data.date === 'Any date') {
        setdate = '&date=' + 'any_date';
      }
      data = sendkeyword + sendlocation + sendlatitude + sendlongitude + sendpostcode + sendcity + setdate + data;
    } else if (param_data.from_header === "from_header") {
      var setdate = '&date=' + param_data.date;
      if (param_data.date === 'Any date') {
        setdate = '&date=' + 'any_date';
      }
      data = sendkeyword + sendlocation + sendlatitude + sendlongitude + sendpostcode + sendcity + setdate + data;
    } else {
      var setdate = '&date=' + param_data.date;
      setdate = '&date=' + 'any_date';
      data = '' + '' + '' + '' + '' + setdate + data;
    }

    setdataforapi(data)
  }

  const setdataforapi = (data) => {
    // console.log("api ", data)

    if (loginUserData) {
      // console.log("loginUserData:-", loginUserData);
      if (loginUserData.type === "ginipig") {
        data = data + '&ginipig_id=' + loginUserData.uuid;
      }
    }
    //  console.log("api passing data:", data);
    // if(setkeyword !== JSON.parse(props.match.params.formdata).keywords){
    //   setkeyword = JSON.parse(props.match.params.formdata).keywords;
    //   __apiCall(data)
    // }
    restData();
    __apiCall(data)
  }

  const __apiCall = useCallback(async (data) => {
    //setpricefiltercheck('0'); 
    try {
      await dispatch(whatsAvailableAPI_Action(data));
      // var save_date = new Date();
      // if(startDate === undefined){
      //   save_date = new Date(param_data.date)
      // }else{
      //   save_date = startDate
      // }
      // var save_data_search = {
      //   search_keywords: search_keywords,
      //   search_location: search_location,
      //   search_latitude: search_latitude,
      //   search_longitude: search_longitude,
      //   search_postcode: search_postcode,
      //   startDate: save_date,
      // }

      // var save_data = {
      //   earliest_availability: earliest_availability,
      //   price_high_low: price_high_low,
      //   price_low_high: price_low_high,
      //   highest_rating: highest_rating,
      //   closet_me: closet_me,
      //   training: training,
      //   case_study: case_study,
      //   portfolio: portfolio,
      //   new_bie: new_bie,
      //   nearly_there: nearly_there,
      //   ready_to_go: ready_to_go,
      //   price_min: price_min,
      //   distance_Cal: distance_Cal,
      // }
      // var post_data = {filterDetails:save_data,searchDetails:save_data_search}
      // setLocalStorageData("filter_treatment_data",JSON.stringify(post_data))
      // console.log("startDate first ", startDate, ' ', new Date(param_data.date))

    } catch (error) {
      console.error(" treatment ::  treatment() :: Exception ==", error);
    }
  });

  const __onTextChange = (e) => {
    setSearchLocation(e.target.value)
    setValue(e.target.value);
    //  console.log("text change called", e.target.value);
    if (e.target.value === '') {
      setDisableDistanceSlider(true)
    }

  };
  const __onTextChangeKeyword = (e) => {
    //console.log("e.target.value.trim() " , e.target.value.trim())
    //e.preventDefault();
    //setSearchKeyword(e.target.value.trim())
    setSearchKeyword(e.target.value)
  }
  const _applyfilter = (status, filter_name) => {
    setpricefiltercheck('0');

    var api_call = '';
    var distance_sorting = distance_send.includes('=') && distance_send.substr(distance_send.lastIndexOf('=') + 1).split(' ')[0];
    var price_sorting = price.includes('=') && price.substr(price.lastIndexOf('=') + 1).split(' ')[0];
    var treatpurpose_sorting = treatpurpose.includes('=') && treatpurpose.substr(treatpurpose.lastIndexOf('=') + 1).split(' ')[0];
    var isonline_sorting = is_online.includes('=') && is_online.substr(is_online.lastIndexOf('=') + 1).split(' ')[0];
    var complevel_sorting = complevel.includes('=') && complevel.substr(complevel.lastIndexOf('=') + 1).split(' ')[0];
    var search_date = date_post.includes('=') && date_post.substr(date_post.lastIndexOf('=') + 1).split(' ')[0];
    var keywords_sorting = keywords.includes('=') && keywords.substr(keywords.lastIndexOf('=') + 1).split(' ')[0];

    var location_sorting = location.includes('=') && location.substr(location.lastIndexOf('=') + 1).split(' ')[0];
    var latitude_sorting = latitude.includes('=') && latitude.substr(latitude.lastIndexOf('=') + 1).split(' ')[0];
    var longitude_sorting = longitude.includes('=') && longitude.substr(longitude.lastIndexOf('=') + 1).split(' ')[0];
    var postcode_sorting = postcode.includes('=') && postcode.substr(postcode.lastIndexOf('=') + 1).split(' ')[0];
    var city_sorting = city.includes('=') && city.substr(city.lastIndexOf('=') + 1).split(' ')[0];



    if (keywords_sorting !== '') {
      api_call = api_call + "keywords=" + search_keywords
    } else if (search_keywords !== '') {
      api_call = api_call + "keywords=" + search_keywords
    } else if (search_keywords === '') {
      api_call = api_call + "keywords=" + ''
    }

    //  console.log('location_sorting ', location_sorting)
    if (location_sorting !== '') {
      if (search_location === 'Any location') {
        api_call = api_call + "&location=" + ''
      } else {
        api_call = api_call + "&location=" + search_location
      }
    } else {
      if (search_location != '') {
        if (search_location === 'Any location') {
          api_call = api_call + "&location=" + ''
        } else {
          api_call = api_call + "&location=" + search_location
        }
      } else
        api_call = api_call + "&location=" + ''
    }

    if (latitude_sorting !== '') {
      api_call = api_call + "&latitude=" + search_latitude
    } else {
      if (search_latitude != '') {
        api_call = api_call + "&latitude=" + search_latitude
      } else
        api_call = api_call + "&latitude=" + ''
    }

    if (longitude_sorting !== '') {
      api_call = api_call + "&longitude=" + search_longitude
    } else {
      if (search_longitude != '') {
        api_call = api_call + "&longitude=" + search_longitude
      } else
        api_call = api_call + "&longitude=" + ''
    }

    if (postcode_sorting !== '') {
      api_call = api_call + "&postcode=" + search_postcode
    } else {
      if (search_postcode != '') {
        api_call = api_call + "&postcode=" + search_postcode
      } else
        api_call = api_call + "&postcode=" + ''
    }

    if (city_sorting !== '') {
      api_call = api_call + "&city=" + search_city
    } else {
      if (search_city != '') {
        api_call = api_call + "&city=" + search_city
      } else
        api_call = api_call + "&city=" + ''
    }

    if (search_date !== '') {
      var date;
      if (startDate !== 'Any date') {
        date = [startDate.getFullYear(), ("0" + (startDate.getMonth() + 1)).slice(-2), ("0" + startDate.getDate()).slice(-2)].join("-")
      } else {
        date = 'any_date'
      }
      api_call = api_call + '&date=' + date
    }
    if (price_sorting !== '') {
      api_call = api_call + price
    }
    if (treatpurpose_sorting !== '') {
      api_call = api_call + treatpurpose
    }
    if (isonline_sorting !== '') {
      api_call = api_call + is_online
    }
    if (complevel_sorting !== '') {
      api_call = api_call + complevel
    }
    if (distance_sorting !== '') {
      api_call = api_call + distance_send
    }
    //  console.log("status ", status)
    if (!status) {
      var sort_data = sort_by.includes('=') && sort_by.substr(sort_by.lastIndexOf('=') + 1).split(' ')[0]
      var data; var set_data;

      if (sort_data === '') {
        data = sort_by + filter_name;
        //  console.log("data apply filter if ", data)
      } else {
        var new_data = sort_by;
        var old_low_high = 'price_low_to_high';
        var old_high_low = 'price_high_to_low'
        var old_earliest = 'earliest_availability'
        var old_rating = 'highest_rating'
        var old_closest = 'closest_to_me';
        if (new_data.includes(old_low_high) || new_data.includes(old_high_low) || new_data.includes(old_earliest)
          || new_data.includes(old_rating) || new_data.includes(old_closest)) {
          new_data = new_data.replace(old_low_high, '');
          new_data = new_data.replace(old_high_low, '');
          new_data = new_data.replace(old_earliest, '');
          new_data = new_data.replace(old_rating, '');
          new_data = new_data.replace(old_closest, '');
        }

        data = new_data + filter_name;
        // data = sort_by + ',' + filter_name;
        //console.log("data apply filter else ", data, ' new_data ', new_data)
      }



      setSortBy(data);
      //console.log("api_call+data ", api_call + data)
      restData();
      __apiCall(api_call + data)

    } else {

      var ret;
      ret = sort_by.replace(',' + filter_name, '');
      if (sort_by === ret) {
        ret = sort_by.replace(filter_name + ',', '');
      }
      if (sort_by === ret) {
        ret = sort_by.replace(filter_name, '');
      }
      setSortBy(ret)
      if (ret === '&sort_by=') {
        ret = '';
      }
      restData();
      __apiCall(api_call + ret)
    }
  }

  const _applyfilterPurpose = (status, filter_name) => {
    setpricefiltercheck('0');
    var api_call = '';
    var distance_sorting = distance_send.includes('=') && distance_send.substr(distance_send.lastIndexOf('=') + 1).split(' ')[0];
    var sorting = sort_by.includes('=') && sort_by.substr(sort_by.lastIndexOf('=') + 1).split(' ')[0];
    var price_sorting = price.includes('=') && price.substr(price.lastIndexOf('=') + 1).split(' ')[0];
    var complevel_sorting = complevel.includes('=') && complevel.substr(complevel.lastIndexOf('=') + 1).split(' ')[0];
    var online_sorting = is_online.includes('=') && is_online.substr(is_online.lastIndexOf('=') + 1).split(' ')[0];
    var search_date = date_post.includes('=') && date_post.substr(date_post.lastIndexOf('=') + 1).split(' ')[0];
    var keywords_sorting = keywords.includes('=') && keywords.substr(keywords.lastIndexOf('=') + 1).split(' ')[0];

    var location_sorting = location.includes('=') && location.substr(location.lastIndexOf('=') + 1).split(' ')[0];
    var latitude_sorting = latitude.includes('=') && latitude.substr(latitude.lastIndexOf('=') + 1).split(' ')[0];
    var longitude_sorting = longitude.includes('=') && longitude.substr(longitude.lastIndexOf('=') + 1).split(' ')[0];
    var postcode_sorting = postcode.includes('=') && postcode.substr(postcode.lastIndexOf('=') + 1).split(' ')[0];
    var city_sorting = city.includes('=') && city.substr(city.lastIndexOf('=') + 1).split(' ')[0];


    if (keywords_sorting !== '') {
      api_call = api_call + "keywords=" + search_keywords
    } else if (search_keywords !== '') {
      api_call = api_call + "keywords=" + search_keywords
    } else if (search_keywords === '') {
      api_call = api_call + "keywords=" + ''
    }

    if (location_sorting !== '') {
      if (search_location === 'Any location') {
        api_call = api_call + "&location=" + ''
      } else {
        api_call = api_call + "&location=" + search_location
      }
    } else {
      if (search_location != '') {
        if (search_location === 'Any location') {
          api_call = api_call + "&location=" + ''
        } else {
          api_call = api_call + "&location=" + search_location
        }
      } else
        api_call = api_call + "&location=" + ''
    }
    if (latitude_sorting !== '') {
      api_call = api_call + "&latitude=" + search_latitude
    } else {
      if (search_latitude != '') {
        api_call = api_call + "&latitude=" + search_latitude
      } else
        api_call = api_call + "&latitude=" + ''
    }
    if (longitude_sorting !== '') {
      api_call = api_call + "&longitude=" + search_longitude
    } else {
      if (search_longitude != '') {
        api_call = api_call + "&longitude=" + search_longitude
      } else
        api_call = api_call + "&longitude=" + ''
    }
    if (postcode_sorting !== '') {
      api_call = api_call + "&postcode=" + search_postcode
    } else {
      if (search_postcode != '') {
        api_call = api_call + "&postcode=" + search_postcode
      } else
        api_call = api_call + "&postcode=" + ''
    }
    if (city_sorting !== '') {
      api_call = api_call + "&city=" + search_city
    } else {
      if (search_city != '') {
        api_call = api_call + "&city=" + search_city
      } else
        api_call = api_call + "&city=" + ''
    }

    if (search_date !== '') {
      var date;
      if (startDate !== 'Any date') {
        date = [startDate.getFullYear(), ("0" + (startDate.getMonth() + 1)).slice(-2), ("0" + startDate.getDate()).slice(-2)].join("-")
      } else {
        date = 'any_date'
      }
      api_call = api_call + '&date=' + date
    }
    if (sorting !== '') {
      api_call = api_call + sort_by
    }
    if (complevel_sorting !== '') {
      api_call = api_call + complevel
    }
    if (online_sorting !== '') {
      api_call = api_call + is_online
    }
    if (price_sorting !== '') {
      api_call = api_call + price
    }
    if (distance_sorting !== '') {
      api_call = api_call + distance_send
    }
    if (!status) {
      var sort_data = treatpurpose.includes('=') && treatpurpose.substr(treatpurpose.lastIndexOf('=') + 1).split(' ')[0]
      var data;
      if (sort_data === '') {
        data = treatpurpose + filter_name;
      } else {
        data = treatpurpose + ',' + filter_name;
      }
      setTreatmentPurpose(data)
      restData();
      __apiCall(api_call + data)
    } else {
      var ret;
      ret = treatpurpose.replace(',' + filter_name, '');
      if (treatpurpose === ret) {
        ret = treatpurpose.replace(filter_name + ',', '');
      }
      if (treatpurpose === ret) {
        ret = treatpurpose.replace(filter_name, '');
      }
   
      //  console.log("sort_data " + ret)
      setTreatmentPurpose(ret);
      if (ret === '&level=') {
        ret = '';
      }
      restData();
      __apiCall(api_call + ret)
    }
  }


  const _applyfilterPurposeOnline = (status, filter_name) => {
    setpricefiltercheck('0');
    var api_call = '';
    var distance_sorting = distance_send.includes('=') && distance_send.substr(distance_send.lastIndexOf('=') + 1).split(' ')[0];
    var sorting = sort_by.includes('=') && sort_by.substr(sort_by.lastIndexOf('=') + 1).split(' ')[0];
    var price_sorting = price.includes('=') && price.substr(price.lastIndexOf('=') + 1).split(' ')[0];
    var complevel_sorting = complevel.includes('=') && complevel.substr(complevel.lastIndexOf('=') + 1).split(' ')[0];
    var search_date = date_post.includes('=') && date_post.substr(date_post.lastIndexOf('=') + 1).split(' ')[0];
    var keywords_sorting = keywords.includes('=') && keywords.substr(keywords.lastIndexOf('=') + 1).split(' ')[0];

    var location_sorting = location.includes('=') && location.substr(location.lastIndexOf('=') + 1).split(' ')[0];
    var latitude_sorting = latitude.includes('=') && latitude.substr(latitude.lastIndexOf('=') + 1).split(' ')[0];
    var longitude_sorting = longitude.includes('=') && longitude.substr(longitude.lastIndexOf('=') + 1).split(' ')[0];
    var postcode_sorting = postcode.includes('=') && postcode.substr(postcode.lastIndexOf('=') + 1).split(' ')[0];
    var city_sorting = city.includes('=') && city.substr(city.lastIndexOf('=') + 1).split(' ')[0];


    if (keywords_sorting !== '') {
      api_call = api_call + "keywords=" + search_keywords
    } else if (search_keywords !== '') {
      api_call = api_call + "keywords=" + search_keywords
    } else if (search_keywords === '') {
      api_call = api_call + "keywords=" + ''
    }

    if (location_sorting !== '') {
      if (search_location === 'Any location') {
        api_call = api_call + "&location=" + ''
      } else {
        api_call = api_call + "&location=" + search_location
      }
    } else {
      if (search_location != '') {
        if (search_location === 'Any location') {
          api_call = api_call + "&location=" + ''
        } else {
          api_call = api_call + "&location=" + search_location
        }
      } else
        api_call = api_call + "&location=" + ''
    }
    if (latitude_sorting !== '') {
      api_call = api_call + "&latitude=" + search_latitude
    } else {
      if (search_latitude != '') {
        api_call = api_call + "&latitude=" + search_latitude
      } else
        api_call = api_call + "&latitude=" + ''
    }
    if (longitude_sorting !== '') {
      api_call = api_call + "&longitude=" + search_longitude
    } else {
      if (search_longitude != '') {
        api_call = api_call + "&longitude=" + search_longitude
      } else
        api_call = api_call + "&longitude=" + ''
    }
    if (postcode_sorting !== '') {
      api_call = api_call + "&postcode=" + search_postcode
    } else {
      if (search_postcode != '') {
        api_call = api_call + "&postcode=" + search_postcode
      } else
        api_call = api_call + "&postcode=" + ''
    }
    if (city_sorting !== '') {
      api_call = api_call + "&city=" + search_city
    } else {
      if (search_city != '') {
        api_call = api_call + "&city=" + search_city
      } else
        api_call = api_call + "&city=" + ''
    }

    if (search_date !== '') {
      var date;
      if (startDate !== 'Any date') {
        date = [startDate.getFullYear(), ("0" + (startDate.getMonth() + 1)).slice(-2), ("0" + startDate.getDate()).slice(-2)].join("-")
      } else {
        date = 'any_date'
      }
      api_call = api_call + '&date=' + date
    }
    if (sorting !== '') {
      api_call = api_call + sort_by
    }
    if (complevel_sorting !== '') {
      api_call = api_call + complevel
    }
    if (price_sorting !== '') {
      api_call = api_call + price
    }
    if (distance_sorting !== '') {
      api_call = api_call + distance_send
    }
    if (!status) {
      var sort_data = is_online.includes('=') && is_online.substr(is_online.lastIndexOf('=') + 1).split(' ')[0]
      var data="&is_online=1"
      setIs_online(data)
      restData();
      __apiCall(api_call + data)
    } else {
      var ret="";
      setIs_online(ret)
      restData();
      __apiCall(api_call + ret)
    }
  }

  const _applyfilterlevel = (status, filter_name) => {
    setpricefiltercheck('0');
    //console.log("_applyfilter " + sort_by + ' ' + filter_name)

    var api_call = '';
    var distance_sorting = distance_send.includes('=') && distance_send.substr(distance_send.lastIndexOf('=') + 1).split(' ')[0];
    var sorting = sort_by.includes('=') && sort_by.substr(sort_by.lastIndexOf('=') + 1).split(' ')[0];
    var treatpurpose_sorting = treatpurpose.includes('=') && treatpurpose.substr(treatpurpose.lastIndexOf('=') + 1).split(' ')[0];
    var is_online_sorting = is_online.includes('=') && is_online.substr(treatpurpose.lastIndexOf('=') + 1).split(' ')[0];
    var price_sorting = price.includes('=') && price.substr(price.lastIndexOf('=') + 1).split(' ')[0];
    var search_date = date_post.includes('=') && date_post.substr(date_post.lastIndexOf('=') + 1).split(' ')[0];
    var keywords_sorting = keywords.includes('=') && keywords.substr(keywords.lastIndexOf('=') + 1).split(' ')[0];

    var location_sorting = location.includes('=') && location.substr(location.lastIndexOf('=') + 1).split(' ')[0];
    var latitude_sorting = latitude.includes('=') && latitude.substr(latitude.lastIndexOf('=') + 1).split(' ')[0];
    var longitude_sorting = longitude.includes('=') && longitude.substr(longitude.lastIndexOf('=') + 1).split(' ')[0];
    var postcode_sorting = postcode.includes('=') && postcode.substr(postcode.lastIndexOf('=') + 1).split(' ')[0];
    var city_sorting = city.includes('=') && city.substr(city.lastIndexOf('=') + 1).split(' ')[0];


    if (keywords_sorting !== '') {
      api_call = api_call + "keywords=" + search_keywords
    } else if (search_keywords !== '') {
      api_call = api_call + "keywords=" + search_keywords
    } else if (search_keywords === '') {
      api_call = api_call + "keywords=" + ''
    }

    if (location_sorting !== '') {
      if (search_location === 'Any location') {
        api_call = api_call + "&location=" + ''
      } else {
        api_call = api_call + "&location=" + search_location
      }
    } else {
      if (search_location != '') {
        if (search_location === 'Any location') {
          api_call = api_call + "&location=" + ''
        } else {
          api_call = api_call + "&location=" + search_location
        }
      } else
        api_call = api_call + "&location=" + ''
    }
    if (latitude_sorting !== '') {
      api_call = api_call + "&latitude=" + search_latitude
    } else {
      if (search_latitude != '') {
        api_call = api_call + "&latitude=" + search_latitude
      } else
        api_call = api_call + "&latitude=" + ''
    }
    if (longitude_sorting !== '') {
      api_call = api_call + "&longitude=" + search_longitude
    } else {
      if (search_longitude != '') {
        api_call = api_call + "&longitude=" + search_longitude
      } else
        api_call = api_call + "&longitude=" + ''
    }
    if (postcode_sorting !== '') {
      api_call = api_call + "&postcode=" + search_postcode
    } else {
      if (search_postcode != '') {
        api_call = api_call + "&postcode=" + search_postcode
      } else
        api_call = api_call + "&postcode=" + ''
    }

    if (city_sorting !== '') {
      api_call = api_call + "&city=" + search_city
    } else {
      if (search_city != '') {
        api_call = api_call + "&city=" + search_city
      } else
        api_call = api_call + "&city=" + ''
    }

    if (search_date !== '') {
      var date;
      if (startDate !== 'Any date') {
        date = [startDate.getFullYear(), ("0" + (startDate.getMonth() + 1)).slice(-2), ("0" + startDate.getDate()).slice(-2)].join("-")
      } else {
        date = 'any_date'
      }
      api_call = api_call + '&date=' + date
    }
    if (sorting !== '') {
      api_call = api_call + sort_by
    }
    if (price_sorting !== '') {
      api_call = api_call + price
    }
    if (treatpurpose_sorting !== '') {
      api_call = api_call + treatpurpose
    }
    if (is_online_sorting !== '') {
      api_call = api_call + is_online
    }

    
    if (distance_sorting !== '') {
      api_call = api_call + distance_send
    }
    if (!status) {
      var sort_data = complevel.includes('=') && complevel.substr(complevel.lastIndexOf('=') + 1).split(' ')[0]
      var data;
      if (sort_data === '') {
        data = complevel + filter_name;
      } else {
        data = complevel + ',' + filter_name;
      }
      setCompLevel(data)
      // if(sorting === ''){
      //   __apiCall(post_data+data)
      // }else{
      //   __apiCall(post_data+sort_by+data)
      // }
      restData();
      __apiCall(api_call + data)
      //console.log("post_data " + post_data+data)

    } else {
      var ret;
      ret = complevel.replace(',' + filter_name, '');
      if (complevel === ret) {
        ret = complevel.replace(filter_name + ',', '');
      }
      if (complevel === ret) {
        ret = complevel.replace(filter_name, '');
      }
      setCompLevel(ret);
      if (ret === '&purpose=') {
        ret = '';
      }
      restData();
      __apiCall(api_call + ret)
    }
  }


  const _applyfilterPrice = (value, val_array) => {
    var api_call = '';
    setdefaultmaxprice(Math.round(val_array[1]))
    setdefaultMinprice(val_array[0])

    var sorting = sort_by.includes('=') && sort_by.substr(sort_by.lastIndexOf('=') + 1).split(' ')[0];
    var treatpurpose_sorting = treatpurpose.includes('=') && treatpurpose.substr(treatpurpose.lastIndexOf('=') + 1).split(' ')[0];
    var is_online_sorting = is_online.includes('=') && is_online.substr(is_online.lastIndexOf('=') + 1).split(' ')[0];
    var complevel_sorting = complevel.includes('=') && complevel.substr(complevel.lastIndexOf('=') + 1).split(' ')[0];
    var search_date = date_post.includes('=') && date_post.substr(date_post.lastIndexOf('=') + 1).split(' ')[0];
    var keywords_sorting = keywords.includes('=') && keywords.substr(keywords.lastIndexOf('=') + 1).split(' ')[0];

    var location_sorting = location.includes('=') && location.substr(location.lastIndexOf('=') + 1).split(' ')[0];
    var latitude_sorting = latitude.includes('=') && latitude.substr(latitude.lastIndexOf('=') + 1).split(' ')[0];
    var longitude_sorting = longitude.includes('=') && longitude.substr(longitude.lastIndexOf('=') + 1).split(' ')[0];
    var postcode_sorting = postcode.includes('=') && postcode.substr(postcode.lastIndexOf('=') + 1).split(' ')[0];
    var city_sorting = city.includes('=') && city.substr(city.lastIndexOf('=') + 1).split(' ')[0];
    var distance_sorting = distance_send.includes('=') && distance_send.substr(distance_send.lastIndexOf('=') + 1).split(' ')[0];

    if (keywords_sorting !== '') {
      api_call = api_call + "keywords=" + search_keywords
    } else if (search_keywords !== '') {
      api_call = api_call + "keywords=" + search_keywords
    } else if (search_keywords === '') {
      api_call = api_call + "keywords=" + ''
    }

    if (location_sorting !== '') {
      if (search_location === 'Any location') {
        api_call = api_call + "&location=" + ''
      } else {
        api_call = api_call + "&location=" + search_location
      }
    } else {
      if (search_location != '') {
        if (search_location === 'Any location') {
          api_call = api_call + "&location=" + ''
        } else {
          api_call = api_call + "&location=" + search_location
        }
      } else
        api_call = api_call + "&location=" + ''
    }
    if (latitude_sorting !== '') {
      api_call = api_call + "&latitude=" + search_latitude
    } else {
      if (search_latitude != '') {
        api_call = api_call + "&latitude=" + search_latitude
      } else
        api_call = api_call + "&latitude=" + ''
    }
    if (longitude_sorting !== '') {
      api_call = api_call + "&longitude=" + search_longitude
    } else {
      if (search_longitude != '') {
        api_call = api_call + "&longitude=" + search_longitude
      } else
        api_call = api_call + "&longitude=" + ''
    }
    if (postcode_sorting !== '') {
      api_call = api_call + "&postcode=" + search_postcode
    } else {
      if (search_postcode != '') {
        api_call = api_call + "&postcode=" + search_postcode
      } else
        api_call = api_call + "&postcode=" + ''
    }

    if (city_sorting !== '') {
      api_call = api_call + "&city=" + search_city
    } else {
      if (search_city != '') {
        api_call = api_call + "&city=" + search_city
      } else
        api_call = api_call + "&city=" + ''
    }

    if (search_date !== '') {
      var date;
      if (startDate !== 'Any date') {
        date = [startDate.getFullYear(), ("0" + (startDate.getMonth() + 1)).slice(-2), ("0" + startDate.getDate()).slice(-2)].join("-")
      } else {
        date = 'any_date'
      }
      api_call = api_call + '&date=' + date
    }
    if (sorting !== '') {
      api_call = api_call + sort_by
    }
    if (treatpurpose_sorting !== '') {
      api_call = api_call + treatpurpose
    }
    if (is_online_sorting !== '') {
      api_call = api_call + is_online
    }

    
    if (complevel_sorting !== '') {
      api_call = api_call + complevel
    }
    if (distance_sorting !== '') {
      api_call = api_call + distance_send
    }

    var set_local;
    if (value !== '0,0') {
      setPriceMin([val_array[0], val_array[1]])
      set_local = '&price=';
      setPrice(set_local + value)
      api_call = api_call + set_local + value
    }
    restData();
    __apiCall(api_call)
  }

  const _applyfilterDistance = (value, val_array) => {
    //setpricefiltercheck('0'); 
    var api_call = '';
    var price_sorting = price.includes('=') && price.substr(price.lastIndexOf('=') + 1).split(' ')[0];
    var is_online_sorting = is_online.includes('=') && is_online.substr(is_online.lastIndexOf('=') + 1).split(' ')[0];

    var sorting = sort_by.includes('=') && sort_by.substr(sort_by.lastIndexOf('=') + 1).split(' ')[0];
    var treatpurpose_sorting = treatpurpose.includes('=') && treatpurpose.substr(treatpurpose.lastIndexOf('=') + 1).split(' ')[0];
    var complevel_sorting = complevel.includes('=') && complevel.substr(complevel.lastIndexOf('=') + 1).split(' ')[0];
    var search_date = date_post.includes('=') && date_post.substr(date_post.lastIndexOf('=') + 1).split(' ')[0];
    var keywords_sorting = keywords.includes('=') && keywords.substr(keywords.lastIndexOf('=') + 1).split(' ')[0];

    var location_sorting = location.includes('=') && location.substr(location.lastIndexOf('=') + 1).split(' ')[0];
    var latitude_sorting = latitude.includes('=') && latitude.substr(latitude.lastIndexOf('=') + 1).split(' ')[0];
    var longitude_sorting = longitude.includes('=') && longitude.substr(longitude.lastIndexOf('=') + 1).split(' ')[0];
    var postcode_sorting = postcode.includes('=') && postcode.substr(postcode.lastIndexOf('=') + 1).split(' ')[0];
    var city_sorting = city.includes('=') && city.substr(city.lastIndexOf('=') + 1).split(' ')[0];


    if (keywords_sorting !== '') {
      api_call = api_call + "keywords=" + search_keywords
    } else if (search_keywords !== '') {
      api_call = api_call + "keywords=" + search_keywords
    } else if (search_keywords === '') {
      api_call = api_call + "keywords=" + ''
    }
    // console.log("search_location ", search_location, 'location_sorting ', location_sorting)
    if (location_sorting !== '') {
      var abc = search_location
      if (abc === 'Any location') {
        api_call = api_call + "&location=" + ''
      } else {
        api_call = api_call + "&location=" + abc
      }

    } else {
      if (search_location != '') {
        if (search_location === 'Any location') {
          api_call = api_call + "&location=" + ''
        } else {
          api_call = api_call + "&location=" + search_location
        }
      } else
        api_call = api_call + "&location=" + ''
    }
    if (latitude_sorting !== '') {
      api_call = api_call + "&latitude=" + search_latitude
    } else {
      if (search_latitude != '') {
        api_call = api_call + "&latitude=" + search_latitude
      } else
        api_call = api_call + "&latitude=" + ''
    }
    if (longitude_sorting !== '') {
      api_call = api_call + "&longitude=" + search_longitude
    } else {
      if (search_longitude != '') {
        api_call = api_call + "&longitude=" + search_longitude
      } else
        api_call = api_call + "&longitude=" + ''
    }
    if (postcode_sorting !== '') {
      api_call = api_call + "&postcode=" + search_postcode
    } else {
      if (search_postcode != '') {
        api_call = api_call + "&postcode=" + search_postcode
      } else
        api_call = api_call + "&postcode=" + ''
    }
    if (city_sorting !== '') {
      api_call = api_call + "&city=" + search_city
    } else {
      if (search_city != '') {
        api_call = api_call + "&city=" + search_city
      } else
        api_call = api_call + "&city=" + ''
    }
    if (search_date !== '') {
      var date;
      if (startDate !== 'Any date') {
        date = [startDate.getFullYear(), ("0" + (startDate.getMonth() + 1)).slice(-2), ("0" + startDate.getDate()).slice(-2)].join("-")
      } else {
        date = 'any_date'
      }
      api_call = api_call + '&date=' + date
    }
    if (sorting !== '') {
      api_call = api_call + sort_by
    }
    if (treatpurpose_sorting !== '') {
      api_call = api_call + treatpurpose
    }
    if (is_online_sorting !== '') {
      api_call = api_call + is_online
    }

    if (complevel_sorting !== '') {
      api_call = api_call + complevel
    }
    if (price_sorting !== '') {
      api_call = api_call + price
    }
    // console.log('value ', value)
    var set_local;
    if (value !== '0,0') {
      setDistanceCal([val_array[0], val_array[1]])
      set_local = '&distance=';
      setDistanceSend(set_local + value)
      api_call = api_call + set_local + value
    }

    // console.log("search_location ", search_location, ' api ', api_call)
    restData();
    __apiCall(api_call)
  }

  const searchApi = () => {
    //console.log("value ", search_keywords, search_location, startDate)
    setSearchKeyword(search_keywords);
    setpricefiltercheck('0');
    setnearly_there(false)
    setnew_bie(false)
    setready_to_go(false)
    settraining(false)
    setcase_study(false)
    setportfolio(false)
    setearliest_availability(false)
    setprice_high_low(false)
    setprice_low_high(false)
    sethighest_rating(false)
    setCompLevel('&purpose=')
    setTreatmentPurpose('&level=')
    setIs_online('&is_online=')
    setSortBy('&sort_by=')
    var api_call = '';
    var keywords_sorting = keywords.includes('=') && keywords.substr(keywords.lastIndexOf('=') + 1).split(' ')[0];
    var price_sorting = price.includes('=') && price.substr(price.lastIndexOf('=') + 1).split(' ')[0];
    var treatpurpose_sorting = treatpurpose.includes('=') && treatpurpose.substr(treatpurpose.lastIndexOf('=') + 1).split(' ')[0];
    var is_online_sorting = is_online.includes('=') && is_online.substr(is_online.lastIndexOf('=') + 1).split(' ')[0];
    var complevel_sorting = complevel.includes('=') && complevel.substr(complevel.lastIndexOf('=') + 1).split(' ')[0];
    var sorting = sort_by.includes('=') && sort_by.substr(sort_by.lastIndexOf('=') + 1).split(' ')[0];
    var search_date = date_post.includes('=') && date_post.substr(date_post.lastIndexOf('=') + 1).split(' ')[0];

    var location_sorting = location.includes('=') && location.substr(location.lastIndexOf('=') + 1).split(' ')[0];
    var latitude_sorting = latitude.includes('=') && latitude.substr(latitude.lastIndexOf('=') + 1).split(' ')[0];
    var longitude_sorting = longitude.includes('=') && longitude.substr(longitude.lastIndexOf('=') + 1).split(' ')[0];
    var postcode_sorting = postcode.includes('=') && postcode.substr(postcode.lastIndexOf('=') + 1).split(' ')[0];
    var city_sorting = city.includes('=') && city.substr(city.lastIndexOf('=') + 1).split(' ')[0];


    if (keywords_sorting !== '') {
      api_call = api_call + "keywords=" + search_keywords
    } else if (search_keywords !== '') {
      api_call = api_call + "keywords=" + search_keywords
    } else if (search_keywords === '') {
      api_call = api_call + "keywords=" + ''
    }
    var pass_loc = '&location=';
    if (location_sorting !== '') {
      if (search_location === 'Any location') {
        api_call = api_call + pass_loc + ''
      } else {
        api_call = api_call + pass_loc + search_location
      }
    } else {
      if (search_location === 'Any location') {
        api_call = api_call + pass_loc + ''
      } else {
        api_call = api_call + pass_loc + search_location
      }

    }
    if (latitude_sorting !== '') {
      api_call = api_call + "&latitude=" + search_latitude
    } else {
      api_call = api_call + "&latitude=" + search_latitude
    }
    if (longitude_sorting !== '') {
      api_call = api_call + "&longitude=" + search_longitude
    } else {
      api_call = api_call + "&longitude=" + search_longitude
    }
    if (postcode_sorting !== '') {
      api_call = api_call + "&postcode=" + search_postcode
    } else {
      api_call = api_call + "&postcode=" + search_postcode
    }
    if (city_sorting !== '') {
      api_call = api_call + "&city=" + search_city
    } else {
      api_call = api_call + "&city=" + search_city
    }
    if (search_date !== '') {
      var date;
      if (startDate !== 'Any date') {
        date = [ new Date(startDate).getFullYear(), ("0" + (new Date(startDate).getMonth() + 1)).slice(-2), ("0" + new Date(startDate).getDate()).slice(-2)].join("-")
      } else {
        date = 'any_date'
      }
      api_call = api_call + '&date=' + date
    }
    if (sorting !== '') {
      api_call = api_call + sort_by
    }
    // if (price_sorting !== '') {
    //   api_call = api_call + price
    // }
    if (treatpurpose_sorting !== '') {
      api_call = api_call + treatpurpose
    }
    if (is_online_sorting !== '') {
      api_call = api_call + is_online
    }
    if (complevel_sorting !== '') {
      api_call = api_call + complevel
    }
    if (loginUserData) {
      if (loginUserData.type === "ginipig") {
        //console.log("login user id: ",loginUserData.uuid)
        api_call = api_call + '&ginipig_id=' + loginUserData.uuid;
      }
    }

    //  console.log("api call " + api_call)
    restData();
    __apiCall(api_call);


    //new added from api call
    var save_date = new Date();
    if (startDate === undefined) {
      save_date = new Date(param_data.date)
    } else {
      save_date = startDate
    }
    var save_data_search = {
      search_keywords: search_keywords,
      search_location: search_location,
      search_latitude: search_latitude,
      search_longitude: search_longitude,
      search_postcode: search_postcode,
      search_city: search_city,
      startDate: save_date,
    }

    var save_data = {
      earliest_availability: earliest_availability,
      price_high_low: price_high_low,
      price_low_high: price_low_high,
      highest_rating: highest_rating,
      closet_me: closet_me,
      training: training,
      case_study: case_study,
      portfolio: portfolio,
      new_bie: new_bie,
      nearly_there: nearly_there,
      ready_to_go: ready_to_go,
      price_min: price_min,
      distance_Cal: distance_Cal,
    }
    var post_data = { filterDetails: save_data, searchDetails: save_data_search }
    setLocalStorageData("filter_treatment_data", JSON.stringify(post_data))
    //   console.log("startDate first ", startDate, ' ', new Date(param_data.date))
  }

  const restData = async () => {
    try {
      await dispatch(resetAPI_ActionWhatAvailable());
    } catch (error) {
      console.error(" treatment ::  treatment() :: Exception ==", error);
    }
  }

  const nextScreen = async (treatmentdata) => {
    // console.log("treatmentdata:-", treatmentdata);
    if (treatmentdata.bookings_count != undefined && treatmentdata.bookings_count > 0) {
      return false;
    }
    try {
      var save_data = {
        earliest_availability: earliest_availability,
        price_high_low: price_high_low,
        price_low_high: price_low_high,
        highest_rating: highest_rating,
        closet_me: closet_me,
        training: training,
        case_study: case_study,
        portfolio: portfolio,
        new_bie: new_bie,
        nearly_there: nearly_there,
        ready_to_go: ready_to_go,
        price_min: price_min,
        distance_Cal: distance_Cal,
      }
      var save_data_search = {
        search_keywords: search_keywords,
        search_location: search_location,
        search_latitude: search_latitude,
        search_longitude: search_longitude,
        search_postcode: search_postcode,
        search_city: search_city,
        startDate: startDate,
      }
      // await dispatch(saveFilterData(save_data));
      // await dispatch(saveSearchData(save_data_search));

      var post_data = { filterDetails: save_data, searchDetails: save_data_search }

      setLocalStorageData("filter_treatment_data", JSON.stringify(post_data))
      setLocalStorageData("search_treatment_data", JSON.stringify(save_data_search))


      dispatch(resetbookingDetailsSave());
      dispatch(resetPhotoDetails());
      if (treatmentdata.type === 2) {
        clearLocalStorage('book_close_request')
        window.scrollTo(0, 0);
        history.push('/treatment-detail-for-close-request/' + treatmentdata.uuid);
      } else {
        clearLocalStorage("book_open_request_time");
        clearLocalStorage('book_open_request')
        window.scrollTo(0, 0);
        history.push('/treatment-detail-for-open-request/' + treatmentdata.uuid);
      }
    } catch (error) {
      console.error(" profile ::  profile() :: Exception ==", error);
    }
  }


  const showPractitionerProfile = (treatmentdata) => {
    window.scrollTo(0, 0);

    var save_data = {
      earliest_availability: earliest_availability,
      price_high_low: price_high_low,
      price_low_high: price_low_high,
      highest_rating: highest_rating,
      closet_me: closet_me,
      training: training,
      case_study: case_study,
      portfolio: portfolio,
      new_bie: new_bie,
      nearly_there: nearly_there,
      ready_to_go: ready_to_go,
      price_min: price_min,
      distance_Cal: distance_Cal,
    }
    var save_data_search = {
      search_keywords: search_keywords,
      search_location: search_location,
      search_latitude: search_latitude,
      search_city: search_city,
      search_longitude: search_longitude,
      search_postcode: search_postcode,
      search_city: search_city,
      startDate: startDate,
    }

    var post_data = { filterDetails: save_data, searchDetails: save_data_search }
    setLocalStorageData("filter_treatment_data", JSON.stringify(post_data))
    setLocalStorageData("search_treatment_data", JSON.stringify(save_data_search))

    clearLocalStorage('book_close_request');
    clearLocalStorage("book_open_request_time");
    dispatch(resetpractitionerProfileAPI_Action());


    setLocalStorageData('tabpankey', 1);
    window.scrollTo(0, 0);
    // history.push('/practitioner-profile');
    history.push({
      pathname: '/practitioner-profile-ginipig',
      state: { 'paramData': treatmentdata.user.uuid }
    });
  }
  const renderSuggestions = () =>
    data.map(suggestion => {
      //  options = {
      //   types: ['(cities)'],
      //   componentRestrictions: {country: "GB"}
      //  };
      const {
        id,
        structured_formatting: { main_text, secondary_text },

      } = suggestion;

      return (
        <li
          key={id}
          onClick={handleSelect(suggestion)}
        >
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  const handleSelect = ({ description }) => () => {


    if (description !== '') {
      setDisableDistanceSlider(false)
    } else {
      setDisableDistanceSlider(true)
    }

    setValue(description, false);
    setSearchLocation(description)
    clearSuggestions();
    //  console.log("description: ", description);
    let latitude = "";
    let longitude = "";
    let city = "";
    let country = "";
    getGeocode({ address: description })

      .then((results) => {
        //  console.log("results:", results[0].address_components);

        let city_name = results[0].address_components.map((item, key) => {

          if (item.types.indexOf("postal_town") != -1) {
            city = item.long_name;
          } else {
            return null;
          }

        });

        let country_name = results[0].address_components.map((item, key) => {

          if (item.types.indexOf("country") != -1) {
            country = item.long_name;
          } else {
            return null;
          }

        });
        latitude = results[0].geometry.location.lat();
        longitude = results[0].geometry.location.lng();
        //   console.log("city:", city, "latitude: ", latitude, "longitude: ", longitude);
        setSearchLatitude(latitude)
        setSearchLongitude(longitude)
        setSearchCity(city)
        //for post code
        // if (results[0].hasOwnProperty('address_components'[5])) {
        //   console.log("inside")
        //   if (results[0].address_components[5].hasOwnProperty('short_name')) {
        //     setSearchPostcode(results[0].address_components[5].short_name)
        //   }
        // } else {
        //   setSearchPostcode('')
        // }
        return Promise.resolve(results);
      })
      .then((results) => getZipCode(results[0]))
      .then((zipCode) => {
        //  console.log("zipCode ", zipCode)
        setSearchPostcode(zipCode)
        // updateFormData({
        //   ...formData,
        //   latitude:latitude,
        //   longitude:longitude,
        //   postcode:zipCode,
        //   location:description,
        //   country:country,
        //   city:city,
        // });

      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  };
  //console.log("whatsavailabledata whatsavailabledata: ", whatsavailabledata.whatsavailabledata);

  const handlePageClick = (e) => {
    window.scrollTo(0, 0);
    const selectedPage = e.selected;
    const offset = selectedPage * perPage;
    //console.log(offset)
    setCurrentpage(selectedPage)
    setOffset(offset)
  }

  const handleChangeRaw = (value) => {
    //console.log("handleChangeRaw value ", value)
    if (value === undefined)
      setStartDate('Any date')
  }

  const saveSearchData = (param_data) => {

    var save_date = new Date();
    if (startDate === undefined) {
      if (param_data.date === 'Any date') {
        save_date = param_data.date
      } else {
        save_date = new Date(param_data.date)
      }
    } else {
      save_date = startDate
    }
    var save_data_search = {
      search_keywords: param_data.keywords,
      search_location: param_data.location,
      search_latitude: param_data.latitude,
      search_longitude: param_data.longitude,
      search_postcode: param_data.postcode,
      startDate: save_date,
    }
    var save_data = {
      earliest_availability: earliest_availability,
      price_high_low: price_high_low,
      price_low_high: price_low_high,
      highest_rating: highest_rating,
      closet_me: closet_me,
      training: training,
      case_study: case_study,
      portfolio: portfolio,
      new_bie: new_bie,
      nearly_there: nearly_there,
      ready_to_go: ready_to_go,
      price_min: price_min,
      distance_Cal: distance_Cal,
    }
    var post_data = { filterDetails: save_data, searchDetails: save_data_search }
    setLocalStorageData("filter_treatment_data", JSON.stringify(post_data))
    //  console.log("post_data ", post_data, ' ', startDate, ' p ', param_data)
  }


  // if(marksArray.length !== 0){
  //   console.log("in")
  //   setpriceHide(false)
  //   console.log("better",priceHide)
  // }else{
  //   setpriceHide(true)
  // }
  //  console.log("make------------",marksArray)



  return (
    <>
      <section className="bgcolor-gray common-section search-section pb80">
        <Container fluid>
          <Row>
            <Col className="col-lg-12">
              <div className="main-div-availtreat">
                <div className="navigationTop left-arrow">
                  <div className="heading-history">
                    <div className="back-history">
                      <button type="button" onClick={() => history.push('/')} className="back-btn" >
                        {" "}
                        <img src={LeftArrow} alt="arrow" />{" "}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="search-div-main">
                  <div className="search-div">
                    <div className="form-field mb-0 mr-10">
                      <span className="field-icon mt-14"><img src={searchIcon} alt="search Icon" /></span>
                      <input className="field-input" type="text"
                        value={search_keywords}
                        id="search_keywords"
                        autoComplete="off"
                        name="search_keywords"
                        onChange={__onTextChangeKeyword}
                      />
                    </div>
                    <div className="form-field mb-0 mr-10">
                      <div className="loc_point">
                        <span className="field-icon mt-14"><img src={locationIcon} style={{ marginTop: -3 }} alt="location Icon" /></span>
                        <input className="field-input" type="text"
                          value={search_location}
                          value={value}
                          id="search_location"
                          name="search_location"
                          onChange={__onTextChange}

                          placeholder="Please enter location"
                          onClick={() => {
                            // console.log("onClick", search_location)
                            if (search_location === 'Any location') {
                              setSearchLocation('')
                              setValue("")
                            }
                          }}

                        />
                      </div>
                      {status === 'OK' && <ul className="location-search-list autoSearch">{renderSuggestions()}</ul>}
                    </div>
                    <div className="form-field mb-0 picker_date button-search input-search">
                    <label htmlFor="iconClick" className="field-search">
                      <span className="field-icon mt-14" ><img src={dateIcon} style={{ marginTop: -2 }} alt="date Icon" /></span>
                     </label>
                      <DatePicker
                        id={'iconClick'}
                        ref={pickerRef}
                        selected={startDate !== undefined ? startDate === 'Any date' ? '' : startDate : ''}
                        value={startDate}
                        onChange={date => {
                          //setStartDate(date)  
                          setCross(true)
                          var setDate = [new Date(date).getFullYear(), ("0" + (new Date(date).getMonth() + 1)).slice(-2), ("0" + new Date(date).getDate()).slice(-2)].join("-");
                          var second_set;

                          if (startDate !== undefined && startDate !== 'Any date') {
                            second_set = [new Date(startDate).getFullYear(), ("0" + (new Date(startDate).getMonth() + 1)).slice(-2), ("0" + new Date(startDate).getDate()).slice(-2)].join("-");
                          } else {
                            //second_set = 'Any Date';
                            second_set = '';
                          }
                          // console.log(date, ' ', startDate, ' ', second_set, ' ', date);
                          if (setDate === second_set ||  date ===null) {
                            setDate = 'Any date'
                            setStartDate(setDate)
                            setCross(false)
                            // console.log("equal ", startDate);
                          } else {
                            
                            setDate = [new Date(date).getFullYear(), ("0" + (new Date(date).getMonth() + 1)).slice(-2), ("0" + new Date(date).getDate()).slice(-2)].join("-");
                            setStartDate(date)
                          }
                        }
                        }
                        isClearable={cross}
                        minDate={new Date()}
                        maxDate={startMaxDate}
                        dateFormat="dd/MM/yyyy"
                        onChangeRaw={(event) =>
                          handleChangeRaw(event.target.value)}
                      //onBlur={event => focousOut(event.target.value)}
                      />
                      
                    </div>
                    <div className="form-field mb-0 button-search btn-srch">
                      <button className="search" onClick={(e) => searchApi()}>Search</button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="heading-div">
                <div className="heading-div-main">
                  <div className="head-div">
                    {
                      whatsavailabledata.whatsavailabledata && whatsavailabledata.whatsavailabledata !== null ?
                        <h3 className="avail-treat-name">
                          Available Treatments <span className="count">({whatsavailabledata.whatsavailabledata.data != null && whatsavailabledata.whatsavailabledata.data.is_data_not_found_send_five_data === 0 ? whatsavailabledata.whatsavailabledata.data.requests_count : 0})</span>
                        </h3> : null
                    }
                    
                    

                  </div>
                    
                </div>
                <div className="close_btn">
                  <button type="button" onClick={__fliterOnclick} className={filterActive ? " fitler_btn filter_close" : " fitler_btn filter_open "} >
                    <img src={icon_filter} className="filter_open_icon filter_icon" alt="" />
                    <img src={icon_filter_close} className="filter_close_icon filter_icon" alt="" />
                  </button>
                </div>
              </div>

              <div className="filter-listing-div">
                  
                {/* { 
                   whatsavailabledata !== null && !whatsavailabledata.isFetching ?  */}

                <form className={filterActive ? " inner-div availtreatment " : "hideOnMobile inner-div availtreatment"} >
                  <div className="display-state">
                    <div className="mr-30 mrb">
                      <label className="profile-label filter-lable">Sort by</label>
                      <div className="checkbox-group">
                        <label htmlFor="check1">
                          <div className="checkinput">
                            <input
                              className="tnc check-size"
                              type="checkbox"
                              id="check1"
                              onChange={(e) => {
                                _applyfilter(earliest_availability, 'earliest_availability')
                                setearliest_availability(!earliest_availability);

                                if (price_low_high === true) {
                                  setprice_low_high(!price_low_high)
                                }
                                if (price_high_low === true) {
                                  setprice_high_low(!price_high_low)
                                }
                                if (highest_rating === true) {
                                  sethighest_rating(!highest_rating)
                                }
                                if (closet_me === true) {
                                  setcloset_me(!closet_me)
                                }
                              }}
                              checked={earliest_availability === true ? true : false}
                            />
                          </div>
                          <span className={earliest_availability === true ? "checkbox-decs check-tr" : "checkbox-decs"}>Earliest Availability</span>
                        </label>
                      </div>

                      <div className="checkbox-group">
                        <label htmlFor="check2">
                          <div className="checkinput">
                            <input
                              className="tnc check-size"
                              type="checkbox"
                              id="check2"
                              onChange={(e) => {
                                _applyfilter(price_high_low, 'price_high_to_low')
                                setprice_high_low(!price_high_low)
                                if (price_low_high === true) {
                                  setprice_low_high(!price_low_high)
                                }
                                if (earliest_availability === true) {
                                  setearliest_availability(!earliest_availability)
                                }
                                if (highest_rating === true) {
                                  sethighest_rating(!highest_rating)
                                }
                                if (closet_me === true) {
                                  setcloset_me(!closet_me)
                                }

                              }}
                              checked={price_high_low}
                            />
                          </div>
                          <span className={price_high_low === true ? "checkbox-decs check-tr" : "checkbox-decs"}>Price: High-Low</span>
                        </label>
                      </div>

                      <div className="checkbox-group">
                        <label htmlFor="check3">
                          <div className="checkinput">
                            <input
                              className="tnc check-size"
                              type="checkbox"
                              id="check3"
                              onChange={(e) => {
                                _applyfilter(price_low_high, 'price_low_to_high')
                                setprice_low_high(!price_low_high)

                                if (price_high_low === true) {
                                  setprice_high_low(!price_high_low)
                                }
                                if (earliest_availability === true) {
                                  setearliest_availability(!earliest_availability)
                                }
                                if (closet_me === true) {
                                  setcloset_me(!closet_me)
                                }
                                if (highest_rating === true) {
                                  sethighest_rating(!highest_rating)
                                }

                              }}
                              checked={price_low_high}
                            />
                          </div>
                          <span className={price_low_high === true ? "checkbox-decs check-tr" : "checkbox-decs"}>Price: Low-High</span>
                        </label>
                      </div>

                      <div className="checkbox-group">
                        <label htmlFor="check4">
                          <div className="checkinput">
                            <input
                              className="tnc check-size"
                              type="checkbox"
                              id="check4"
                              onChange={(e) => {
                                _applyfilter(highest_rating, 'highest_rating')
                                sethighest_rating(!highest_rating)

                                if (price_high_low === true) {
                                  setprice_high_low(!price_high_low)
                                }
                                if (earliest_availability === true) {
                                  setearliest_availability(!earliest_availability)
                                }
                                if (closet_me === true) {
                                  setcloset_me(!closet_me)
                                }
                                if (price_low_high === true) {
                                  setprice_low_high(!price_low_high)
                                }

                              }}
                              checked={highest_rating}
                            />
                          </div>
                          <span className={highest_rating === true ? "checkbox-decs check-tr" : "checkbox-decs"}>Highest rating</span>
                        </label>
                      </div>

                      <div className="checkbox-group">
                        <label htmlFor="check15">
                          <div className="checkinput">
                            <input
                              className="tnc check-size"
                              type="checkbox"
                              id="check15"
                              onChange={(e) => { _applyfilterPurposeOnline(onlinefiltercheck, 'is_online'); setOnlinefiltercheck(!onlinefiltercheck) }}
                              checked={onlinefiltercheck}
                            />
                          </div>
                          <span className={onlinefiltercheck === true ? "checkbox-decs check-tr" : "checkbox-decs"}>Online Only</span>
                        </label>
                      </div>

                      {
                        (search_location !== '' && search_location !== 'Any location') ?
                          <div className="checkbox-group" >
                            <label htmlFor="check5">
                              <div className="checkinput">
                                <input
                                  className="tnc check-size"
                                  type="checkbox"
                                  id="check5"
                                  onChange={(e) => {
                                    _applyfilter(closet_me, 'closest_to_me')
                                    setcloset_me(!closet_me)

                                    if (price_high_low === true) {
                                      setprice_high_low(!price_high_low)
                                    }
                                    if (earliest_availability === true) {
                                      setearliest_availability(!earliest_availability)
                                    }
                                    if (highest_rating === true) {
                                      sethighest_rating(!highest_rating)
                                    }
                                    if (price_low_high === true) {
                                      setprice_low_high(!price_low_high)
                                    }

                                  }}
                                  checked={closet_me}
                                />
                              </div>
                              <span className={closet_me === true ? "checkbox-decs check-tr" : "checkbox-decs"}> Closest to me </span>
                            </label>
                          </div> : null

                      }

                    </div>

                    <div className="hide-distance">
                      {
                        
                      //whatsavailabledata.whatsavailabledata !== null  && whatsavailabledata.whatsavailabledata.data && whatsavailabledata.whatsavailabledata.data.requests.data.length > 0 ?
                        (search_location !== '' && search_location !== 'Any location') ?  (
                          
                            pricefiltercheck === '1' ?
                              <div className="mr-30 mrb">
                                {console.log("maxDistance", maxDistance, ' minDistance ', minDistance)}
                                <label className="profile-label filter-lable">Distance  <span className="small_txt"> (miles) </span> </label>
                                <div className="distance_txt">
                                  <span className="min_distance">0</span>
                                  <span className="max_distance">99</span>
                                </div>
                                <ReactSlider
                                  className="horizontal-slider"
                                  thumbClassName="example-thumb"
                                  trackClassName="example-track"
                                  min={0}
                                  max={99}
                                  defaultValue={disableDistanceSlider ? [0] : [5]}
                                  //defaultValue={5}
                                  //defaultValue={disableDistanceSlider ? [0, 0] : [0, maxDistance]}
                                  //ariaLabel={['Lower thumb', 'Upper thumb']}
                                  ariaValuetext={state => `Thumb value ${state.valueNow}`}
                                  renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                                  pearling
                                  //minDistance={0}
                                  // min={disableDistanceSlider ? 0 : whatsavailabledata && whatsavailabledata.whatsavailabledata != null ? minDistance : 0}
                                  // max={disableDistanceSlider ? 0 : whatsavailabledata && whatsavailabledata.whatsavailabledata != null ? maxDistance : 0}
                                  //min={disableDistanceSlider ? 0 : minDistance}
                                  //max={disableDistanceSlider ? 0 : maxDistance}
                                  //min={disableDistanceSlider ? 0 : 1}
                                  //max={disableDistanceSlider ? 0 : 50}
                                  
                                  
                                  onAfterChange={val => {
                                    //console.log('onAfterChange value:', val)
                                    _applyfilterDistance(val.toString(), val)
                                  }}
                                  disabled={disableDistanceSlider}
                                />
                              </div> : <div className="mr-30 mrb">
                                <label className="profile-label filter-lable">Distance</label>
                                <div className="static-reactslider">
                                  <div className="first-div"></div>
                                  <div className="absolute-first-div"></div><div className="absolute-second-div"></div>
                                  <div className="absolute-first-div ab-text-fi" >{0}</div><div className="absolute-second-div ab-text-sec" >{0}</div>
                                </div>
                              </div>
                          
                        ) : null
                        //:null
                      }

                    </div>

                    

                    <div className="mr-30 mrb">
                      <label className="profile-label filter-lable">Treatment Purpose</label>
                      <div className="checkbox-group">
                        <label htmlFor="check6">
                          <div className="checkinput">
                            <input
                              className="tnc check-size"
                              type="checkbox"
                              id="check6"
                              onChange={(e) => {
                                _applyfilterPurpose(training, 'Training');
                                settraining(!training)
                              }}
                              checked={training}
                            />
                          </div>
                          <span className={training === true ? "checkbox-decs check-tr" : "checkbox-decs"}>Training</span>
                        </label>
                      </div>
                      <div className="checkbox-group">
                        <label htmlFor="check7">
                          <div className="checkinput">
                            <input
                              className="tnc check-size"
                              type="checkbox"
                              id="check7"
                              onChange={(e) => { _applyfilterPurpose(case_study, 'Case study'); setcase_study(!case_study) }}
                              checked={case_study}
                            />
                          </div>
                          <span className={case_study === true ? "checkbox-decs check-tr" : "checkbox-decs"}>Case Study</span>
                        </label>
                      </div>
                      <div className="checkbox-group">

                        <label htmlFor="check8">
                          <div className="checkinput">
                            <input
                              className="tnc check-size"
                              type="checkbox"
                              id="check8"
                              onChange={(e) => { _applyfilterPurpose(portfolio, 'Portfolio'); setportfolio(!portfolio) }}
                              checked={portfolio}
                            />
                          </div>
                          <span className={portfolio === true ? "checkbox-decs check-tr" : "checkbox-decs"}>Portfolio</span>
                        </label>
                      </div>

                    </div>
                    {
                      //console.log("stepcount", stepcount, "maxprize", maxprice, "minprice", minprice)

                    }
                    {console.log("price",priceHide)}
                    {
                      //whatsavailabledata.whatsavailabledata !== null  && whatsavailabledata.whatsavailabledata.data && whatsavailabledata.whatsavailabledata.data.requests.data.length > 0 ?
                      
                      marksArray && marksArray.length !== 0  ? 
                      pricefiltercheck === '1' ?
                        <div className="mr-30 mrb">
                          <label className="profile-label filter-lable">Price</label>
                          <ReactSlider
                            className="horizontal-slider"
                            thumbClassName="example-thumb"
                            trackClassName="example-track"
                            // defaultValue={[0, maxprice]}

                            defaultValue={[0, marksArray.length - 1]}
                            ariaLabel={['Lower thumb', 'Upper thumb']}
                            ariaValuetext={state => `Thumb value ${state.valueNow}`}
                            renderThumb={(props, state) => {
                              let price = 0;
                              if(marksArray.length > 0){
                                price = marksArray[state.valueNow].toFixed(2)  
                              }
                              
                              return (<div {...props}>£{price}</div>)

                            }}
                            pearling
                            // step={stepcount}
                            // minDistance={0}

                            // min={whatsavailabledata && whatsavailabledata.whatsavailabledata != null ? whatsavailabledata.whatsavailabledata.data.minimum_price % 1 === 0 ? whatsavailabledata.whatsavailabledata.data.minimum_price : Math.round(whatsavailabledata.whatsavailabledata.data.minimum_price - 1) === 0 ? 1 : Math.round(whatsavailabledata.whatsavailabledata.data.minimum_price - 1) : minprice}
                            min={0}
                            max={marksArray.length - 1}
                            onChange={(val) => {
                              // console.log(val, "Currentl selection")

                            }}
                            onAfterChange={val => {

                              _applyfilterPrice([marksArray[val[0]], marksArray[val[1]]].toString(), [marksArray[val[0]], marksArray[val[1]]]);
                            }}
                          />
                        </div> :
                        <div className="mr-30 mrb">
                          <label className="profile-label filter-lable">Price</label>
                          <div className="static-reactslider">
                            <div className="first-div"></div>
                            <div className="absolute-first-div"></div><div className="absolute-second-div"></div>
                            <div className="absolute-first-div ab-text-fi">£{minprice != undefined ? minprice : 0}</div><div className="absolute-second-div ab-text-sec">£{maxprice != undefined ? maxprice : 100}</div>
                          </div>
                          {/* <ReactSlider
                            className="horizontal-slider"
                            thumbClassName="example-thumb"
                            trackClassName="example-track"
                            defaultValue={[0, 10000]}
                            ariaLabel={['Lower thumb', 'Upper thumb']}
                            ariaValuetext={state => `Thumb value ${state.valueNow}`}
                            renderThumb={(props, state) => <div {...props}>Â£{state.valueNow}</div>}
                            pearling
                            minDistance={20}
                            min={0}
                            max={10000}
                            onAfterChange={val => {
                              console.log('onAfterChange value:', val, ' ', val.toString());
                             _applyfilterPrice(val.toString(), val)
                            }}
                            disabled={true}
                          />                       */}
                        </div>
                      //null
                      :null
                    }

                    

                    <div className="mr-30 mrb">
                      <label className="profile-label filter-lable">Competency level</label>
                      <div className="checkbox-group">
                        <label htmlFor="check9">
                          <div className="checkinput">
                            <input
                              className="tnc check-size"
                              type="checkbox"
                              id="check9"
                              onChange={(e) => { _applyfilterlevel(new_bie, 'Newbie'); setnew_bie(!new_bie) }}
                              checked={new_bie}
                            />
                          </div>
                          <span className={new_bie === true ? "checkbox-decs check-tr" : "checkbox-decs"}>Newbie</span>
                        </label>
                      </div>
                      <div className="checkbox-group">
                        <label htmlFor="check10">
                          <div className="checkinput">
                            <input
                              className="tnc check-size"
                              type="checkbox"
                              id="check10"
                              onChange={(e) => { _applyfilterlevel(nearly_there, 'Nearly there'); setnearly_there(!nearly_there) }}
                              checked={nearly_there}
                            />
                          </div>
                          <span className={nearly_there === true ? "checkbox-decs check-tr" : "checkbox-decs"}>Nearly there</span>
                        </label>
                      </div>
                      <div className="checkbox-group">
                        <label htmlFor="check11">
                          <div className="checkinput">
                            <input
                              className="tnc check-size"
                              type="checkbox"
                              id="check11"
                              //onChange={(e) => { _applyfilterlevel(ready_to_go, 'Ready to go'); setready_to_go(!ready_to_go) }}
                              onChange={(e) => { _applyfilterlevel(ready_to_go, 'Star'); setready_to_go(!ready_to_go) }}
                              checked={ready_to_go}
                            />
                          </div>
                          <span className={ready_to_go === true ? "checkbox-decs check-tr" : "checkbox-decs"}>Star</span>
                        </label>
                      </div>


                    </div>
                  </div>
                </form>
            {/* //   :
            //   <div style={{ width: "100%", fontSize: 20, marginTop: 100, textAlign: 'center' }}>
            //       <p>Loading ...</p>
            //   </div>  
            // } */}
                <div className="inner-div availtreatmentlist" >
                {
                      whatsavailabledata.whatsavailabledata && whatsavailabledata.whatsavailabledata !== null && whatsavailabledata.whatsavailabledata.data.is_data_not_found_send_five_data === 1 ?
                      (<div className="no-result-oops">Oops, it looks like there is nothing available based on your search criteria, but don't worry there are plenty more exciting treatments to choose from!</div>) : null
                    }
                  {
                    whatsavailabledata.whatsavailabledata && whatsavailabledata.whatsavailabledata !== null && whatsavailabledata.whatsavailabledata.data !== null && whatsavailabledata.whatsavailabledata.data.requests.data.length > 0
                      ? whatsavailabledata.whatsavailabledata.data.requests.data.slice(offset, offset + perPage).map((treatmentdata, treat_index) => {
                        return (
                          <TreatmentsListing content={treatmentdata} latitide={search_latitude} longitude={search_longitude}
                            search_location={search_location}
                            onPractitionerPress={() => showPractitionerProfile(treatmentdata)}
                            onNextPress={() => { nextScreen(treatmentdata) }}  >
                          </TreatmentsListing>
                        )
                      }) : (
                        whatsavailabledata.isFetching ?
                          <div style={{ width: "100%", fontSize: 20, marginTop: 100, textAlign: 'center' }}>
                            <p>Loading ...</p>
                          </div> :
                          <div style={{ width: "100%", fontSize: 20, marginTop: 100, textAlign: 'center' }}>
                            <p>No treatments available for this search</p>
                          </div>

                      )
                  }
                  {
                   whatsavailabledata.whatsavailabledata !== null && whatsavailabledata.whatsavailabledata.data !== null && whatsavailabledata.whatsavailabledata.data.requests.data.length > 0 ?
                    <div className="pagination-div">

                      <ReactPaginate
                        previousLabel={"prev"}
                        nextLabel={"next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        // pageCount={Math.ceil(whatsavailabledata.whatsavailabledata.data.pagination.total / perPage)}
                        pageCount={Math.ceil(whatsavailabledata.whatsavailabledata.data.requests.total / perPage)}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"} />


                    </div> : null}

                </div>

              </div>




            </Col>
          </Row>
        </Container>
      </section>
    </>
  )

}

export default AvailableTreatments;
