import React from 'react';
import ReviewStructure from './ReviewStructure';

const CustomerSection = (props) =>{
    return (
        <>
            <section className="customer">
                <ReviewStructure />   
            </section>
        </>
    );
}

export default CustomerSection;