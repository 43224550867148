import React from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { LeftArrow } from "../../config/images";
import { useDispatch, useSelector } from 'react-redux';
import LoadingSpinner from "../../components/UIComponent/LoadingSpinner"

const DoubleConfirmationPopup = (props) => {
  const removeTreatmentData = useSelector(state => state.removetreatment);
  const disabletreatmentpractitionerprofileData = useSelector(state => state.disabletreatmentpractitionerprofile);
  let history = useHistory();
  const redirectUrl = (e) => {
    history.push(e);
  };


  return (
    <>
      <section className="bgcolor-gray common-section">
        <Container>
          <Row>
            <Col className="col-md-10 m-auto">
              <div className="heading-history">
                <div className="back-history">
                  <button
                    type="button"
                    onClick={() => history.goBack()}
                    className="back-btn"
                  >
                    {" "}
                    <img src={LeftArrow} alt="arrow" />{" "}
                  </button>
                </div>
                {
                  props.breadcrumbsText &&
                  <div className="history-title">
                    <h2>{props.breadcrumbsText} </h2>
                  </div>
                }
              </div>
              {props.redirectpath === "treatmentdelete" && disabletreatmentpractitionerprofileData.disabletreatmentpractitionerprofiledata &&
                disabletreatmentpractitionerprofileData.disabletreatmentpractitionerprofiledata.hasOwnProperty(
                  "message"
                ) ? (
                  <p className="error">
                    {disabletreatmentpractitionerprofileData.disabletreatmentpractitionerprofiledata.hasOwnProperty(
                      "message"
                    ) ? (
                        <p style={{ color: "red", paddingBottom: 10 }}>
                          {
                            disabletreatmentpractitionerprofileData.disabletreatmentpractitionerprofiledata.message
                          }{" "}
                        </p>
                      ) : null}
                  </p>
                ) : null}
              {/* {props.redirectpath === "treatmentdelete" && removeTreatmentData.removetreatmentdata &&
                removeTreatmentData.removetreatmentdata.hasOwnProperty(
                  "message"
                ) ? (
                  <p className="error">
                    {removeTreatmentData.removetreatmentdata.hasOwnProperty(
                      "message"
                    ) ? (
                        <p style={{ color: "red", paddingBottom: 10 }}>
                          {
                            removeTreatmentData.removetreatmentdata.message
                          }{" "}
                        </p>
                      ) : null}
                  </p>
                ) : null} */}
              <div className="desciption">
                <div className="display-flex">

                  {props.subHeadingtext &&
                    <p className="subdesc">
                      Are you sure you would like to {props.subHeadingtext}
                    </p>
                  }
                  {props.DeleteSubHeadingtext &&
                    <p className="subdesc">
                      {props.DeleteSubHeadingtext}
                    </p>
                  }
                  {props.subHeadingtextTreatment &&
                    <p className="subdesc">
                      {props.subHeadingtextTreatment}
                    </p>
                  }

                  {
                    props.showLoadingdelete &&
                    <div className="ml-30">
                      <LoadingSpinner />
                    </div>
                  }

                </div>
                {
                  props.noticeText &&
                  <p className="subdesc">{props.noticeText}</p>
                }

                <div className="two-cols mt-24 mb-24">
                  <button className="btns-gray " onClick={props.handleClick} >
                    {" "}
                    {/* Yes, {props.buttonYesText} */}
                     {props.buttonYesText}
                  </button>

                  <button
                    className="btns-gray "
                    onClick={props.__redirectBack ? props.__redirectBack : () => history.goBack()}
                  >
                    {" "}
                    {/* No, do not {props.buttonNoText} */}
                     {props.buttonNoText}
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default DoubleConfirmationPopup;
