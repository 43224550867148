import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS,AUTH_HEADERS_NEW } from '../../config/ApiConstants';

export function saveFilterData(data) {
    return {
        type: types.FILTER_DETAILS,
        filterDetails: data
    }
}

export const resetFilterData = () => {
    return {
        type: types.RESET_FILTER_DETAILS,
        payload: null
    }
};

export function saveSearchData(data) {
    return {
        type: types.SEARCH_DETAILS,
        searchDetails: data
    }
}

export const resetSearchData = () => {
    return {
        type: types.RESET_SEARCH_DETAILS,
        payload: null
    }
};