import React,{useEffect} from 'react';
import {Container,Row,Col} from 'react-bootstrap';
import {LeftArrow} from '../../config/images'
import {useHistory,Link} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { getLocalStorageData } from '../../utils/storage';
// redux
import { userdetailsAPI_Action,resetUserAPI_Action,resetgoginipigprofileAPI_Action } from '../../stores/actions'



const GinipigAccount = () => {
    // Initialize hooks first   
    const dispatch = useDispatch();
    const history = useHistory();    
    const __logout = () => {
        window.scrollTo(0, 0);
        history.push('/logout-confirmation');
    }
    const __ProfileEdit = () => {
        window.scrollTo(0, 0);
        //dispatch(resetUserAPI_Action());
        dispatch(resetgoginipigprofileAPI_Action());
        history.push('/ginipig-edit');
    }

  // Selectors
  const userData = useSelector(state => state.userdetails);        
  const loginUserData = useSelector(state => state.login);
  const registerData = useSelector(state => state.register);
  const Data = JSON.stringify(registerData);
  let LocalDatatoken=getLocalStorageData("loginData")
  // Use effects
  useEffect(() => {   
    
    try {
        let checkToken="";
      const loginToken=loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token: "" : "";
      // const PractitionerToken= practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.token:"" : "";
      const RegisterToken= registerData.registerdata ? registerData.registerdata.data ? registerData.registerdata.data.token:"" : "";
      if(loginToken != "")
      {
        checkToken=loginToken;
      }  else if(RegisterToken != "")
      {
        checkToken=RegisterToken;
      } else if (LocalDatatoken !== "") {
        checkToken = LocalDatatoken.token;
    }
        // const loginToken=loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token: "" : "";
        if(checkToken!=='')
        {
           dispatch(userdetailsAPI_Action(checkToken));
        } else {
            window.scrollTo(0, 0);
            history.push('/login');
        }
        
      } catch (error) {
        console.error(" profile ::  profile() :: Exception ==", error);
      }
    
  },[])


    return (
        <>
            <section className="bgcolor-gray common-section">
                <Container>
                    <Row>
                        <Col className="col-lg-11 m-auto">
                            <div className="navigationTop">
                                <div className="heading-history">
                                    <div className="back-history">
                                    <button type="button" className="back-btn" >
                                        {" "}
                                        <img src={LeftArrow} alt="arrow" />{" "}
                                    </button>
                                    </div>
                                    <div className="history-title">
                                    <h2> GiniPig Profile </h2>
                                    </div>
                                </div>
                                <div className="headingbox alignright ">
                                   <div className="nextnav">
                                        <button onClick={__ProfileEdit} className="plainbtn">Edit</button>
                                        <button onClick={__logout} className="plainbtn">Logout</button>
                                        
                                    </div> 
                                </div>
                        </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default GinipigAccount;