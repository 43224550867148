import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS_NEW } from '../../config/ApiConstants';
export function pendingbookingDetailsAPI_Action(id,token) {
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/practitioner/getBookingDetails/`+id,
            method: 'GET',
            headers: {
               
                ...AUTH_HEADERS_NEW,
                'Authorization':'Bearer '+token
  },
            body:'',
            types: [
                types.PENDINGBOOKINGDETAILS_REQUEST,
                types.PENDINGBOOKINGDETAILS_RECEIVE, {
                    type: types.PENDINGBOOKINGDETAILS_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}


export const resetpendingbookingDetailsAPI_Action = () => {
    return {
        type: types.RESET_PENDINGBOOKINGDETAILS,
        payload: null
    }
};
