import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS } from '../../config/ApiConstants';
export function practionerStripeLinkAPI_Action(token) {
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/practitioner/stripe/login/link`,
            method: 'GET',
            headers: {
                ...AUTH_HEADERS,
                'Authorization': 'Bearer ' + token
            },
            body:"",
            types: [
                types.PRACTITIONERSTRIPELINK_REQUEST,
                types.PRACTITIONERSTRIPELINK_RECEIVE, {
                    type: types.PRACTITIONERSTRIPELINK_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}


export const resetpractionerStripeLinkAPI_Action = () => {
    return {
        type: types.RESET_PRACTITIONERSTRIPELINK,
        payload: null
    }
};
