import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Accordion, Card, useAccordionToggle } from 'react-bootstrap';
import { LeftArrow , iconDelete,icon_addNewPlus } from '../../config/images';
import TreatmentRadioButton from '../../components/TreatmentRadioButton';
import LargeButton from '../../components/LargeButton';
import AccordionContent from '../../components/UIComponent/AccordionContent';
// redux
import { treatmentaddreviewAPI_Action, resettreatmentaddreviewAPI_Action,resetLoginAPI_Action, treatmentDetailsAPI_Action, resetremoveTreatmentAPI_Action } from '../../stores/actions'
import { getLocalStorageData,clearLocalStorage,setLocalStorageData } from '../../utils/storage'

const AddTreatmentReview = () => {
    //state init
    const [activeKey, setActiveKey] = useState(0);
    const [isActive, setIsActive] = useState();
    // Initialize hooks first   
    const dispatch = useDispatch();
    let history = useHistory();

    // Selectors
    const treatmentaddreviewsData = useSelector(state => state.treatmentaddreviews);
    const loginUserData = useSelector(state => state.login);
    const practitionersignupaddinfoData = useSelector(state => state.practitionersignupaddinfo);
    
    const LocalDatatoken = getLocalStorageData("loginData")

    
    const __redirectTo = (e) => {
        dispatch(resettreatmentaddreviewAPI_Action());
        window.scrollTo(0, 0);
        history.push('/practitioner-profile');
    }


    const __redirectToAdd = (e, id) => {
        e.preventDefault();
        let checkToken = "";

        let LocalDatatoken = getLocalStorageData("loginData")
        let registerData = getLocalStorageData("practitionersignupData")

        if (registerData != "" && registerData !== undefined && registerData !== null) {
            checkToken = registerData.token;
        }
        else if (LocalDatatoken !== "" && LocalDatatoken !== undefined && LocalDatatoken !== null) {
            checkToken = LocalDatatoken.token;
        }
        if (checkToken === '') {
            window.scrollTo(0, 0);
            history.push('/login');
        }
        if (checkToken !== '') {
            window.scrollTo(0,0);
            setLocalStorageData("treatmentcatId",id)
            dispatch(treatmentaddreviewAPI_Action(checkToken));
            history.push('/treatment-add-another/' + id);
        } else {
            window.scrollTo(0, 0);
            history.push('/login');
        }
    }

    // Use effects
    useEffect(() => {
        if (treatmentaddreviewsData.treatmentaddreviewdata === null) {
            try {
                let checkToken = "";
                const loginToken = loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token : "" : "";
                const RegisterToken = practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.token : "" : "";
                let LocalDatatoken = getLocalStorageData("loginData")

                if (loginToken != "") {
                    checkToken = loginToken;
                } else if (RegisterToken != "") {
                    checkToken = RegisterToken;
                }
                else if (LocalDatatoken !== "") {
                    checkToken = LocalDatatoken.token;
                }
                if (checkToken !== '') {
                    dispatch(treatmentaddreviewAPI_Action(checkToken));
                } else {
                    window.scrollTo(0, 0);
                    history.push('/login');
                }


            } catch (error) {
                console.error(" profile ::  profile() :: Exception ==", error);
            }
        }

    }, [])

    const __redirectBack = (e) => {
        dispatch(resettreatmentaddreviewAPI_Action());
        // history.push('practitioner-profile');
        history.goBack()
    }

    const __redirectToEditTreatment = async (e, id) => {
        window.scrollTo(0, 0);
        await dispatch(treatmentDetailsAPI_Action(id));
        history.push('treatment-content-edit/' + id);
    }


    const __deleteTreatment = async (e, id) => {
        let checkToken = "";
        const loginToken = loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token : "" : "";
        const RegisterToken = practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.token : "" : "";
        if (loginToken != "") {
            checkToken = loginToken;
        } else if (RegisterToken != "") {
            checkToken = RegisterToken;
        } else if (LocalDatatoken !== "") {
            checkToken = LocalDatatoken.token;
        }
        if (checkToken !== '') {
            dispatch(resetremoveTreatmentAPI_Action())
            window.scrollTo(0, 0);
            history.push('delete-treatment/' + id)
        }
    }



 //when ac suspend
    //useEffect(() => {
        if(treatmentaddreviewsData && treatmentaddreviewsData.treatmentaddreviewdata &&  treatmentaddreviewsData.treatmentaddreviewdata.status_code===401){
           clearLocalStorage("loginData")
           clearLocalStorage("treatmentcatId")
           clearLocalStorage("isfromAccount")
           clearLocalStorage("practitionersignupData")
           
           dispatch(resetLoginAPI_Action());
           dispatch(resetremoveTreatmentAPI_Action());
           dispatch(resettreatmentaddreviewAPI_Action());
           window.scrollTo(0, 0);
           //dispatch(resetGinipigUpdateAPI_Action());
           //dispatch(resetgoginipigprofileAPI_Action());     
          history.push("/login");
        }
    //});

    //end


    return (
        <>
            <section className="bgcolor-gray common-section treatmentmenupg pb80">
                <Container>
                {
                        treatmentaddreviewsData.treatmentaddreviewdata !== null && treatmentaddreviewsData.treatmentaddreviewdata.status_code !== 401 ?
                    
                    <Row>
                        <Col className="col-lg-11 m-auto">
                            <div className="navigationTop">
                                <div className="heading-history">
                                    <div className="back-history">
                                        <button type="button" onClick={__redirectBack} className="back-btn" >
                                            {" "}
                                            <img src={LeftArrow} alt="arrow" />{" "}
                                        </button>
                                    </div>
                                    <div className="history-title">
                                        <h2> Review Treatment  </h2>
                                    </div>
                                </div>
                            </div>
                            <div className="from-wrp mt-40">
                                <div className="treatment_info_step">
                                    <p className="steps-label mb-16">The category of services you offer</p>
                                    <div className="treatment-catogory">

                                        <div className="category-list">

                                            {
                                                treatmentaddreviewsData.treatmentaddreviewdata && treatmentaddreviewsData.treatmentaddreviewdata.data.treatment_data.map((category, index) => {


                                                    return (
                                                        <>
                                                            <TreatmentRadioButton
                                                                CategoryTitle={category.name}
                                                                id={category.id}
                                                                key={index}
                                                                activeclassformdata={category.id}
                                                            />
                                                        </>
                                                    )



                                                })
                                            }

                                        {
                                             treatmentaddreviewsData.treatmentaddreviewdata && treatmentaddreviewsData.treatmentaddreviewdata.data.treatment_data.length < 4 ? (<div className="circle treatment-category-btn">
                                             <button className="add-category-circle" onClick={(e) => __redirectToAdd(e,1)}>
                                                 <span className="add_plus"> <img src={icon_addNewPlus} /> </span>
                                                 <span className="radio-title">Add new </span>
                                             </button>
                                         </div>) : null 
                                        }

                                        </div>
                                    </div>
                                </div>

                                <div className="menu-component">
                                    {/* hair */}

                                    {
                                        treatmentaddreviewsData.treatmentaddreviewdata && treatmentaddreviewsData.treatmentaddreviewdata.data.treatment_data.map((category, index) => {

                                            return (

                                                <>
                                                    <div className="category-treatment mt-40">
                                                        <div className="category-heading mb-16">
                                                            <div className="category-name">
                                                                <div className="menu_category_icon">
                                                                    <TreatmentRadioButton
                                                                        CategoryTitle={category.name}
                                                                        id={category.id}
                                                                        key={index}
                                                                        activeclassformdata={category.id}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="category-modify">
                                                                <div className="modify-list">
                                                                <button className="modify_btn treatment-newadd" onClick={(e) => __redirectToAdd(e, category.id)}>Add new</button>
                                                                    {/* <button className="modify_btn treatment-edit">Edit</button> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {category.treatments && category.treatments !== null && category.treatments.length === 0 &&

                                                            <div className="notice-review treatment_info_step">
                                                                <p className="steps-label mb-8">You haven't added any treatments to this category yet, please select 'Add new treatment' from your profile screen </p>
                                                            </div>
                                                        }
                                                        <div className="treatment-accordion">

                                                            {
                                                                category.treatments && category.treatments !== null && category.treatments.map((treatmentdata, treat_index) => {
                                                                    return (
                                                                        <AccordionContent
                                                                            title={treatmentdata.title}
                                                                            editText="Edit"
                                                                            deleteText={iconDelete}
                                                                            content={treatmentdata.description}
                                                                            key={treat_index}
                                                                            id={treatmentdata.id}
                                                                            uuid={treatmentdata.uuid}
                                                                            __nextAction={__redirectToEditTreatment}
                                                                            __deleteAction={__deleteTreatment}
                                                                            isActive={isActive}
                                                                            indexValue={treat_index}
                                                                            onClick={(indexid)=>{ setIsActive(indexid===isActive ? false : indexid) }}


                                                                        />
                                                                    )
                                                                })
                                                            }

                                                        </div> {/* end accordion*/}

                                                    </div>  {/* category item1 */}

                                                </>
                                            )

                                        })

                                    }



                                    <div className="btn-align pd32">
                                        <LargeButton
                                            ButtonType="button"
                                            ButtonText="Finish profile"
                                            disabled={false}
                                            nextButtonClick={__redirectTo}
                                        />
                                    </div>
                                </div>

                            </div>
                        </Col>
                    </Row> : 
                       <div style={{ width: "100%", fontSize: 20, marginTop: 100, textAlign: 'center' }}>
                       <p>Loading ...</p>
                   </div>
           }
                </Container>
            </section>
        </>
    );
}

export default AddTreatmentReview;