import React, { useCallback, useState, useEffect } from "react";
import { useHistory,useLocation} from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Container, Row, Col } from 'react-bootstrap';
import { STRIPE_TOKEN } from "../../config/ApiConstants";
import { LeftArrow,stripelogo} from '../../config/images';
import { createbookingAPI_Action, treatment_details,createOpenbookingAPI_Action,resetcreatebookingAPI_Action,resetLoginAPI_Action,resetUserAPI_Action,resetRegisterAPI_Action,resetPractitionerAddInfoAPI_Action,resettreatmentaddreviewAPI_Action,resetPractionerCategory_API_Action,resetpractitionerProfileAPI_Action,resetGinipigUpdateAPI_Action,resetgoginipigprofileAPI_Action,resetStripeAccountValidityAPI_Action
} from '../../stores/actions';
import PartialPayment from '../../components/PartialPayment';
import { getLocalStorageData, clearLocalStorage } from '../../utils/storage';
import LoadingSpinner from "../../components/UIComponent/LoadingSpinner"
import Modal from 'react-modal'
import LargeButton from '../../components/LargeButton';



const PaymentPage =(props) =>{
    const stripePromise = loadStripe(STRIPE_TOKEN);
    let history = useHistory();
    const dispatch = useDispatch();
    const [isDataPayment, setIsDataPayment] = useState(false);
    const [disable, setDisable] = useState(false);
    const [modalIsOpen,setIsOpen] = React.useState(false);
    const createbooking = useSelector(state => state.createbooking);
    var param_data;
    var post_data;
    var type;
    var status;
    if(props.location.state)
    {
        param_data = props.location.state.paramData;
        post_data= props.location.state.postData;
        type = props.location.state.type;
        status=props.location.state.postData.intent_to_pay_status
    }
    const [isPhotoAdded,setIsPhotoAdded] = useState(false);
    var photo_added;
    const requestdetails = useSelector(state => state.requestdetails); 

    useEffect(()=>{
        if(requestdetails.requestdetailsdata==null || requestdetails.requestdetailsdata==undefined){
            nextButtonClick()
        }
        if(isDataPayment){
            clearLocalStorage('photo_added');
          

            if(createbooking && createbooking != null && createbooking.isSuccess === true){
                //doPayment()
                setDisable(false)
                window.scrollTo(0,0);
                setIsOpen(false)
                history.push({ 
                    pathname: '/thankyou/'+param_data,
                    paramData : param_data
                   }); 
              // history.push('/thankyou/'+param_data)
            }else{
              
                setDisable(false)
                setIsOpen(false)
                window.scrollTo(0,0);
                // history.push({ 
                //     pathname: '/cancel-booking-confirmation',
                //     state: {'paramData' : param_data,errorResponse : createbooking},
                //    });
                
            }   
            // if(createbooking.createbookingdata && createbooking.createbookingdata.status_code===401){
            //    dispatch(resetcreatebookingAPI_Action());
            //     window.scrollTo(0,0);
            //     history.push('/login')
            // }
        }

            if(getLocalStorageData("photo_added") !== undefined){
                photo_added = JSON.parse(getLocalStorageData("photo_added"));
                if(photo_added.photo_added === true){
                if(requestdetails && !requestdetails.isFetching){
                    if(requestdetails.hasOwnProperty('photoDetails')){
                        if(requestdetails.photoDetails === null){
                            //history.push('/photo-requirement/'+param_data);
                            setIsPhotoAdded(true);
                        }else{
                            setIsPhotoAdded(false)
                        }
                    }
                }
                }
            }

    });

    const nextButtonClick = () => {
        window.scrollTo(0, 0);
        clearLocalStorage("book_open_request_time");
        clearLocalStorage("update_ginipig_profile");
        window.removeEventListener('popstate', function(event) {        
          // history.go(1);
       }, false);
        var backpressed = ''; var data = ''; var date = '';
    
        if (getLocalStorageData("filter_treatment_data") !== undefined) {
          backpressed = JSON.parse(getLocalStorageData("filter_treatment_data"))
        }
        // console.log("localstoragedata ", backpressed, ' ', getLocalStorageData("filter_treatment_data"))
        if (backpressed.hasOwnProperty('searchDetails')) {
          if (backpressed.searchDetails !== null) {
            if (backpressed.searchDetails.startDate !== 'Any date') {
              date = [new Date(backpressed.searchDetails.startDate).getFullYear(), ("0" + (new Date(backpressed.searchDetails.startDate).getMonth() + 1)).slice(-2), ("0" + new Date(backpressed.searchDetails.startDate).getDate()).slice(-2)].join("-");
            } else {
              date = 'Any date'
            }
            data = {
              keywords: backpressed.searchDetails.search_keywords,
              location: backpressed.searchDetails.search_location,
              latitude: backpressed.searchDetails.search_latitude,
              longitude: backpressed.searchDetails.search_longitude,
              postcode: backpressed.searchDetails.search_postcode,
              from_header: 'not_from_header',
              date: date
            }
          }
        }
    
        history.push('/search-result', { data: JSON.stringify(data), date: date })
      }

    if(createbooking.createbookingdata && createbooking.createbookingdata.status_code===401){
        dispatch(resetcreatebookingAPI_Action());
        clearLocalStorage("loginData")
        clearLocalStorage("practitionersignupData")
        dispatch(resetLoginAPI_Action());
        dispatch(resetUserAPI_Action());
        dispatch(resetRegisterAPI_Action());
        dispatch(resetPractitionerAddInfoAPI_Action());
        dispatch(resettreatmentaddreviewAPI_Action());
        dispatch(resetPractionerCategory_API_Action());
        dispatch(resetpractitionerProfileAPI_Action());
        dispatch(resetGinipigUpdateAPI_Action());
        dispatch(resetgoginipigprofileAPI_Action());
        dispatch(resetStripeAccountValidityAPI_Action());

        window.scrollTo(0,0);
        history.push('/login')
     }

    

    const doPayment = async(stripeToken) =>{
        openModal();
        let loginToken=getLocalStorageData("loginData");
        console.log("STRIPE CALLBACK", stripeToken)
        try {  
            
            post_data.stripeToken = stripeToken.token.id;
            post_data.stripeEmail = loginToken.user.email;
          
            if(type === 1)
            {
                await dispatch(createOpenbookingAPI_Action(post_data,loginToken.token))
            }else{   
                await dispatch(createbookingAPI_Action(post_data,loginToken.token));
            }
            setIsDataPayment(true)    
        } catch (error) {
          console.error(" profile ::  profile() :: Exception ==", error);
        }  
    }

    

    const customStyles = {
        overlay: {
            background: "#dee2e663" ,zIndex: 999999  },
        content : {
          top                   : '50%',
          left                  : '50%',
          right                 : 'auto',
          bottom                : 'auto',
          marginRight           : '-50%',
          transform             : 'translate(-50%, -50%)',
          border                 :"none",
          background              :"none"
        }
      };

      const openModal = () => {
        //e.preventDefault();
        setIsOpen(true);
     }

    const closeModal = () => {
        setIsOpen(false);
    }

    return (
            <section className="bgcolor-gray common-section payment_pg pb80">
                <Container>
                    <Row>
                        <Col className="col-lg-11 m-auto">
                            <div className="navigationTop">
                                <div className="heading-history">
                                    <div className="back-history">
                                        <button type="button" onClick={() => history.goBack()} className="back-btn" >
                                            {" "}
                                            <img src={LeftArrow} alt="arrow" />{" "}
                                        </button>
                                    </div>
                                    <div className="history-title">
                                        <h2> Payment  </h2>
                                    </div>
                                   
                                </div>
                            
                                    
                            </div>

                        <div className="requirement_wrp"> 
                                {createbooking.isError &&  createbooking.createbookingdata && 
                                    createbooking.createbookingdata.hasOwnProperty(
                                        "message"
                                    ) ? (
                                        <p style={{ color: "red" ,marginTop:10}}>
                                            {
                                                    createbooking.createbookingdata.message
                                            }{" "}
                                        </p>
                                ) : null}                      
                            <div className="payment-populate stripe_label large mt-16">
                            {/* <p className="subheading">Amount to Pay : £<span>{requestdetails.requestdetailsdata && requestdetails.requestdetailsdata.data.request.your_price.toFixed(2)}</span></p> */}
                              {
                                  status==="partial"?
                                  <div>
                                       <p className="subheading">Amount to Pay Now: £<span>{(requestdetails.requestdetailsdata && requestdetails.requestdetailsdata.data.request.your_price!==null&&(requestdetails.requestdetailsdata.data.request.your_price * 0.15).toFixed(2))}</span></p>
                                       <p className="subheading mt-24">Remaining Amount to Pay: £<span>{(requestdetails.requestdetailsdata && requestdetails.requestdetailsdata.data.request.your_price!==null&&(requestdetails.requestdetailsdata.data.request.your_price * 0.85).toFixed(2))}</span></p>
                                  </div>
                                 :
                                  <p className="subheading">Amount to Pay : £<span>{requestdetails.requestdetailsdata && requestdetails.requestdetailsdata.data.request.your_price.toFixed(2)}</span></p>
                              }        
                            </div>
                            <div className="stripe-box-content small stripe_label mb-20">
                                <p className="subheading">Pay now via stripe</p>
                                <div className="stripe-logo">
                                    <img src={stripelogo} className="st-logo" />
                                </div>
                            </div>    
                        </div>                 


                            {
                                isPhotoAdded ? 
                                <div className="btn-align mt-40 pd32" style={{justifyContent:"center"}}>
                                    <LargeButton
                                        ButtonType="button"
                                        ButtonText={"Upload Image(s)"}
                                        nextButtonClick={() => history.push('/photo-requirement/'+param_data)}                                        
                                    />
                                </div>
                                :                            
                                <Elements stripe={stripePromise}> 
                                    <PartialPayment 
                                    doPayment = {doPayment}/>
                                </Elements> 
                            }
                            
                        </Col>
                    </Row>
                
                    <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                        to
                        style={customStyles}
                        shouldCloseOnOverlayClick={false}
                        contentLabel="Example Modal"
                    >                              
                        <div>
                            <LoadingSpinner/>
                        </div>
                        
                    </Modal>

                </Container>
            </section>
    );

};

export default PaymentPage;