import React from 'react';

import { useHistory } from "react-router-dom";
import ConfirmationPopup from '../../components/UIComponent/ConfirmationPopup'

const SignupConfirmationPopup = (props) =>{
    let history = useHistory();

    const __redirectLogin = () =>{
      window.scrollTo(0, 0);
        history.push('/login')
    }

  return (
    <>
      <section className=" pb80 signup_confirmation_pg">
        <ConfirmationPopup
            SubHeadingText="Your signup is succussfully "
            ButtonText="Ok"
            // ButtonText={}
            __redirectNextPage={__redirectLogin}
        />
      </section>


    </>
  );
};

export default SignupConfirmationPopup;