
import React, { useCallback, useState, useEffect } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from 'react-bootstrap';
import { LeftArrow, star } from '../../config/images';
import TransparentButton from "../../components/UIComponent/TransparentButton";
import CloseRequestAcceptCancelCard from "../../components/UIComponent/CloseRequestAcceptCancelCard";
import DeclineBookingCard from "../../components/UIComponent/DeclineBookingCard";
import { acceptbookingAPI_Action, practitionerrequestDetailsAPI_Action, declineenquiryAPI_Action,resetLoginAPI_Action,resetacceptenquiryAPI_Action, resetpractionerdeclinebookingAPI_Action, resetacceptbookingAPI_Action, practionercancelbookingAPI_Action, practionerdeclinebookingAPI_Action, pendingbookingDetailsAPI_Action, upcomingbookingAPI_Action, resetgoginipigprofileAPI_Action, resettreatmentaddreviewAPI_Action } from '../../stores/actions'
import { getLocalStorageData, setLocalStorageData ,clearLocalStorage} from '../../utils/storage'
import LoadingSpinner from "../../components/UIComponent/LoadingSpinner"
import Modal from 'react-modal';

const customStyles = {
    overlay: {
        background: "#dee2e663", zIndex: 999999
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        border: "none",
        background: "none"
    }
};

const PhotoGinipig = (props) => {

    let history = useHistory();

    const dispatch = useDispatch();
    const location = useLocation();

    const [opendecline, setopendecline] = useState(false);
    const [calenderobj, setCalenderobj] = useState();
    const [photoobj, setPhotoobj] = useState();
    const [bookingData, setBookingData] = useState();
    const [bookingId, setBookingId] = useState()
    const acceptbooking = useSelector(state => state.acceptbooking);
    const [modalIsOpen, setIsOpen] = React.useState(false);

    const practitionerdeclinebookingdata = useSelector(state => state.practitionerdeclinebookingdata)

    const declinereasonslistData = useSelector(state => state.declinereasonslistdata);

    const practitionersignupaddinfoData = useSelector(state => state.practitionersignupaddinfo);
    const [formData, updateFormData] = useState(Object.freeze({
        decline_reason_id: [],

        clickCancel: false,
        selectReasonId: ''
    }));


    useEffect(() => {

        let calenderobj = getLocalStorageData("calenderobj")
        setCalenderobj(calenderobj)
        //console.log(calenderobj)
        let photoObj = getLocalStorageData("photoObj")
        setBookingData(photoObj)
        if(photoObj===null || photoObj==undefined){
            history.push({
                pathname: '/practitioner-account',
                iswindowreload:false
    
            });
        }
        let photocloseobj = getLocalStorageData("photocloseobj")
        setPhotoobj(photocloseobj)

      
        let bookingId = getLocalStorageData("bookingId")
        setBookingId(bookingId)

        if (acceptbooking.acceptbookingdata && acceptbooking.acceptbookingdata.message !== "") {
            closeModal()
        }

    }, [acceptbooking.acceptbookingdata])

    
    useEffect(() => {
        try {
            if (practitionerdeclinebookingdata.practitionerdeclinebookingdata && practitionerdeclinebookingdata.practitionerdeclinebookingdata.message !== "") {
                closeModal()
            }
        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
            closeModal()
        }
    }, [practitionerdeclinebookingdata.practitionerdeclinebookingdata])


    const acceptButtonClick = (e, item) => {
        if (bookingData.requestType === 2) {
            window.scrollTo(0, 0);
            dispatch(resetacceptenquiryAPI_Action());
            history.push({
                pathname: '/select-time-accept-booking',
                bookingdata: item

            });

        } else {
            openModal(e)
            __apiCall(item)
        }

    }

    const openModal = (e) => {
        e.preventDefault();
        setIsOpen(true);
    }

    const closeModal = (item) => {
        setIsOpen(false);

    }

    if (acceptbooking.isSuccess === true) {
        dispatch(resetacceptbookingAPI_Action());
        setLocalStorageData("acceptBooking", bookingData)
       // setLocalStorageData("accetptbookingdata", acceptbooking)
        closeModal()

        window.scrollTo(0, 0);
        history.push({
            pathname: '/booking-accepted',
            fromacceptbooking:true
        });
    }

    if (acceptbooking.acceptbookingdata && acceptbooking.acceptbookingdata.status_code === 401) {
        dispatch(resetacceptbookingAPI_Action());
      
        dispatch(resetLoginAPI_Action())
        clearLocalStorage("loginData")
        clearLocalStorage("practitionersignupData")
        clearLocalStorage("RedirectPathName")
        clearLocalStorage("requestDataLocal")
        clearLocalStorage("confirmData")
        clearLocalStorage("openrequestdata")
        clearLocalStorage("closerequestdata")
        clearLocalStorage("noresponseData")
        closeModal()

        window.scrollTo(0, 0);
        history.push({
            pathname: '/login',
        });
    }


    if (practitionerdeclinebookingdata.isSuccess === true) {
        dispatch(resetpractionerdeclinebookingAPI_Action());
        closeModal()

        window.scrollTo(0, 0);
        history.push({
            pathname: '/request-decline',
            bookingDetails: bookingData,

        });
    }

    if (practitionerdeclinebookingdata.practitionerdeclinebookingdata &&  practitionerdeclinebookingdata.practitionerdeclinebookingdata.isSuccess === true) {
        dispatch(resetpractionerdeclinebookingAPI_Action());
        closeModal()
        dispatch(resetLoginAPI_Action())
        window.scrollTo(0, 0);
        history.push({
            pathname: '/login',

        });
    }


    const __apiCall = useCallback(async (item) => {
        // console.log(bookingData)

        try {

            let checkToken = "";

            const RegisterToken = practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.token : "" : "";
            let LocalDatatoken = getLocalStorageData("loginData")

            if (RegisterToken != "") {
                checkToken = RegisterToken;
            }
            else if (LocalDatatoken !== "") {
                checkToken = LocalDatatoken.token;
            }

            await dispatch(acceptbookingAPI_Action(item.uuid, checkToken));
            await dispatch(practitionerrequestDetailsAPI_Action(location.requestId, checkToken));
            // window.scrollTo(0, 0);
            // history.push({
            //     pathname: '/booking-accepted',
            //     bookingdata: item

            // });
        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    });


    const __apiCallDecline = useCallback(async (data) => {
        setLocalStorageData("formDatadecline", data.booking_reason_id)

        try {

            let checkToken = "";
            const RegisterToken = practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.token : "" : "";
            let LocalDatatoken = getLocalStorageData("loginData")
            if (RegisterToken != "") {
                checkToken = RegisterToken;
            }
            else if (LocalDatatoken !== "") {
                checkToken = LocalDatatoken.token;
            }
            if (data.requestType == 2) {
                await dispatch(declineenquiryAPI_Action(data, checkToken));
            } else {
                await dispatch(practionerdeclinebookingAPI_Action(data, checkToken));
            }
            window.scrollTo(0, 0);

            history.push({
                pathname: '/request-decline',
                bookingDetails: true,
                formData: [formData.selectReasonId]
            });

        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    });


    const declineButtonClick = (id) => {
        setopendecline(true)
        setBookingId(id)

    }

    const toggleChangeReason = (id) => {

        let selectedBoxes = formData.decline_reason_id;
        let index = selectedBoxes.indexOf(parseInt(id)) // check to see if the name is already stored in the array
        if (index === -1) {
            selectedBoxes.push(parseInt(id))
        } else {
            selectedBoxes.splice(index, 1)
        }
        updateFormData({
            ...formData,
            decline_reason_id: [id],
            selectReasonId: id
        });

    }


    const sendDeclineButtonClick = (e, item) => {
        // id: item.uuid
        let formDatanew = ""
        if (item.requestType == 2) {
            formDatanew = {
                id: item.bookingId,
                requestType: 2,
                booking_reason_id: [formData.selectReasonId],
            }
        } else {
            formDatanew = {
                id: bookingId,
                requestType: 1,
                booking_reason_id: [formData.selectReasonId],
            }
        }
        openModal(e)
        __apiCallDecline(formDatanew)


    }

    return (
        <>
            <section className="bgcolor-gray common-section photoginipig_pg pb80">
                <Container>
                    <Row>
                        <Col className="col-lg-11 m-auto">
                            <div className="navigationTop padright-0">
                                <div className="heading-history">
                                    <div className="back-history">
                                        <button type="button" onClick={() => history.goBack()} className="back-btn" >
                                            {" "}
                                            <img src={LeftArrow} alt="arrow" />{" "}
                                        </button>
                                    </div>
                                </div>
                                {acceptbooking.isError && acceptbooking.acceptbookingdata &&
                                    acceptbooking.acceptbookingdata.hasOwnProperty(
                                        "message"
                                    ) ? (
                                        <p style={{ color: "red" }}>
                                            {
                                                acceptbooking.acceptbookingdata.message
                                            }{" "}
                                        </p>
                                    ) : null}

                                {practitionerdeclinebookingdata.isError && practitionerdeclinebookingdata.practitionerdeclinebookingdata &&
                                    practitionerdeclinebookingdata.practitionerdeclinebookingdata
                                        .hasOwnProperty(
                                            "message"
                                        ) ? (
                                        <p style={{ color: "red" }}>
                                            {
                                                practitionerdeclinebookingdata.practitionerdeclinebookingdata.message
                                            }{" "}
                                        </p>
                                    ) : null}

                            </div>


                            <div className="requirement_wrp padright-0">

                                <div className="all_request_box mt-40">
                                    <div className="request_boxs pending mb-40">
                                        <CloseRequestAcceptCancelCard
                                            userImg={bookingData && bookingData.user.profile_pic && bookingData.user.profile_pic}
                                            userName={bookingData && bookingData.user.name}
                                            imgArrow={LeftArrow}
                                            showImagearrow={false}
                                            ratingImg={star}
                                            reliabilityCount={bookingData && bookingData.user && bookingData.user.reliabilityAverage === 0 ? 0 : Math.ceil(bookingData && bookingData.user.reliabilityAverage)}
                                            etiquetteCount={bookingData && bookingData.user && bookingData.user.reliabilityAverage === 0 ? 0 : Math.ceil(bookingData && bookingData.user.etiquetteAverage)}
                                           // suitabilityCount={bookingData && bookingData.user && bookingData.user.reliabilityAverage === 0 ? 0 : Math.ceil(bookingData && bookingData.user.suitabilityAverage)}
                                            ratingPoint={bookingData && bookingData.user.review_rating_ginipig}
                                        />
                                    </div>

                                    <div className="photo-box mb-24">
                                        <div className="photo-gridbox">
                                            {
                                                bookingData && bookingData.ginipig_images && bookingData.ginipig_images.map((treatment_images, treat_image_index) => {
                                                    return (
                                                        <div className="img img1">
                                                            <img src={treatment_images.image_link} className="photos" />
                                                        </div>
                                                    )
                                                })
                                            }

                                            {/* <div className="img img1">
                                                <img src={reviewAvatar} className="photos" />
                                            </div>
                                            <div className="img img2">
                                                <img src={reviewAvatar} className="photos" />
                                            </div>
                                            <div className="img img3">
                                                <img src={reviewAvatar} className="photos" />
                                            </div>
                                            <div className="img img4">
                                                <img src={reviewAvatar} className="photos" />
                                            </div> */}

                                        </div>
                                    </div>

                                    {
                                       props.location.details!==undefined && props.location.details.status === "accepted" ||photoobj!==undefined && photoobj.photoGinipigHideButton === true ? null :
                                       photoobj!==undefined &&  photoobj.ginipigreview === false &&
                                            <div className="requst-booking photos mt-24">
                                                <div className="two-col">
                                                    <div className="first-div">
                                                        <TransparentButton
                                                            ButtonType="button"
                                                            ButtonClick={() => declineButtonClick(bookingData.uuid)}
                                                            ButtonText="Decline"
                                                        />
                                                    </div>
                                                    <div className="sec-div">
                                                        <TransparentButton
                                                            ButtonType="button"
                                                            ButtonText={bookingData && bookingData.requestType && bookingData.requestType === 2 ? "Select & Accept" : "Accept"}
                                                            ButtonClick={(e) => acceptButtonClick(e, bookingData)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                    }

                                    {
                                        opendecline === true &&
                                        <div className="decline_boxs">

                                            <DeclineBookingCard
                                                toggleChangeReason={toggleChangeReason}
                                                reasonArrayId={formData.selectReasonId}
                                                flag="cancelPhoto"
                                                sendDeclineButtonClick={(e) => sendDeclineButtonClick(e, bookingData)}
                                                description={declinereasonslistData.declinereasonslistdata && declinereasonslistData.declinereasonslistdata.data.reasons}
                                                headingtitle="Select a reason to explain why you are declining"
                                            />


                                        </div>
                                    }




                                </div>

                                <Modal
                                    isOpen={modalIsOpen}
                                    onRequestClose={closeModal}
                                    to
                                    style={customStyles}
                                    shouldCloseOnOverlayClick={false}
                                    contentLabel="Example Modal"
                                >


                                    <div>
                                        <LoadingSpinner />
                                    </div>

                                </Modal>


                            </div>

                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default PhotoGinipig;