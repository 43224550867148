import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS } from '../../config/ApiConstants';
export function requestDetailsAPI_Action(id,token,ginpig_id) {
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/request/`+id+`?ginipig_id=`+ginpig_id,
            method: 'GET',
            headers: {
                ...AUTH_HEADERS,
            },
            body:'',
            types: [
                types.REQUESTDETAILS_REQUEST,
                types.REQUESTDETAILS_RECEIVE, {
                    type: types.REQUESTDETAILS_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}


export const resetrequestdetailsAPI_Action = () => {
    return {
        type: types.RESET_REQUESTDETAILS,
        payload: null
    }
};

export function bookingDetailsSave(data) {
    return {
        type: types.BOOKINGCONDITION_SAVE_DATA,
        bookingCondition: data
    }
}

export const resetbookingDetailsSave = () => {
    return {
        type: types.RESET_BOOKINGCONDITION_SAVE_DATA,
        payload: null
    }
};

export function photoDetailsSave(data) {
    return {
        type: types.PHOTO_DETAILS,
        photoDetails: data
    }
}

export const resetPhotoDetails = () => {
    return {
        type: types.RESET_PHOTO_DETAILS,
        payload: null
    }
};

export function treatmentDetailsSave(data) {
    return {
        type: types.TREATMENT_DETAILS,
        treatmentDetails: data
    }
}

export const resettreatmentDetailsSave = () => {
    return {
        type: types.RESET_TREATMENT_DETAILS,
        payload: null
    }
};

