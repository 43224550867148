import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS ,AUTH_HEADERS_NEW} from '../../config/ApiConstants';


export function practitinerCategoryAPI_Action(token) {
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/practitioner/categories`,
            method: 'GET',
            headers: {
               
                ...AUTH_HEADERS_NEW,
                'Authorization':'Bearer '+token


            },
            body: '',
            types: [
                types.PRACTIONERCATEGORY_REQUEST,
                types.PRACTIONERCATEGORY_RECEIVE, {
                    type: types.PRACTIONERCATEGORY_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}


export const resetPractionerCategory_API_Action = () => {
    return {
        type: types.RESET_PRACTIONERCATEGORY,
        payload: null
    }
};
