import React , {useCallback,useEffect,useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ConfirmationPopup from '../../components/UIComponent/ConfirmationPopup';
import { resetupcomingbookingAPI_Action} from "../../stores/actions";

import { setLocalStorageData } from '../../utils/storage'; 

const AppointmnetConfirmation = (props) =>{
    let history = useHistory();
    const dispatch = useDispatch();
    const loginUserData = useSelector(state => state.login);
    const requestdetails = useSelector(state => state.requestdetails); 
    const filterData = useSelector(state => state.savefilter);
    const [isCancelBooking,setIsCancelBooking] = useState(true);
    useEffect(() => {
        window.addEventListener('popstate', function(event) {        
            __redirectProfilenew()
            //window.location.reload(false);
        }, false);

    }, [])

    const __redirectProfile = () =>{
        window.scrollTo(0, 0);
        dispatch(resetupcomingbookingAPI_Action());
        window.removeEventListener('popstate', function(event) {        
            // history.go(1);
         }, false);
         history.push({
            pathname: '/practitioner-account',
            iswindowreload:false
        });
      
    }

    const __redirectProfilenew = () =>{
        window.scrollTo(0, 0);
        dispatch(resetupcomingbookingAPI_Action());
        window.removeEventListener('popstate', function(event) {        
            // history.go(1);
         }, false);
         history.push({
            pathname: '/practitioner-account',
            iswindowreload:true
        });
      
    }
    
   

    return (
        <>
            <div className="cancel_booking_confirmation">
                <ConfirmationPopup 
                    SubHeadingText="Thank you for providing these details!"
                    ButtonText= "Back to my account"
                    __redirectNextPage={__redirectProfile}
                />
            </div>  
        </>
    );
}

export default AppointmnetConfirmation ;