import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS } from '../../config/ApiConstants';
export function makefeatureImageAPI_Action(data, token) {
    

    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/practitioner/portfolio/image/featured/status/change`,
            method: 'POST',
            headers: {

                ...AUTH_HEADERS,
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),
            types: [
                types.MAKEIMAGEFEATURE_REQUEST,
                types.MAKEIMAGEFEATURE_RECEIVE, {
                    type: types.MAKEIMAGEFEATURE_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}


export const resetmakefeatureImageAPI_Action = () => {
    return {
        type: types.RESET_MAKEIMAGEFEATURE,
        payload: null
    }
};
