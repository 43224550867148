import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import ConfirmationPopup from '../../components/UIComponent/ConfirmationPopup';
import { resetupcomingbookingAPI_Action } from "../../stores/actions";

import { setLocalStorageData, getLocalStorageData } from '../../utils/storage';

const CancelBookingConfirmation = (props) => {
    let history = useHistory();
    const location=useLocation()

    const dispatch = useDispatch();
    const [isCancelBooking, setIsCancelBooking] = useState(true);

    const __redirectProfile =async () => {
        window.scrollTo(0, 0);
        dispatch(resetupcomingbookingAPI_Action());
        window.removeEventListener('popstate', function(event) {        
            // history.go(1);
         }, false);
         history.push({
            pathname: '/practitioner-account',
            iswindowreload:false

        });
    }

    const __redirectProfilenew =async () => {
        window.scrollTo(0, 0);
        dispatch(resetupcomingbookingAPI_Action());
        window.removeEventListener('popstate', function(event) {        
            // history.go(1);
         }, false);
         history.push({
            pathname: '/practitioner-account',
            iswindowreload:true

        });
    }
    let param_data = "";
    let responseError = "";
    // console.log(props.location.state)

    if (props.location.state !== undefined) {
        param_data = props.location.state.paramData;
        responseError = props.location.state.errorResponse;
    }

    useEffect(() => {
        
        window.addEventListener('popstate', function(event) {        
            __redirectProfilenew()
           window.location.reload(false);
        }, false);

        if(!location.fromcancelbook){
            __redirectProfilenew()
        }
      
        if (param_data) {
            setIsCancelBooking(false);
        }
       
    })
    const disableBack = () => {
        history.go(1);
    }


    const nextButtonClick = () => {
        window.scrollTo(0, 0);
        var backpressed = ''; var data = ''; var date = '';

        if (getLocalStorageData("filter_treatment_data") !== undefined) {
            backpressed = JSON.parse(getLocalStorageData("filter_treatment_data"))
        }
        if (backpressed.hasOwnProperty('searchDetails')) {
            if (backpressed.searchDetails !== null) {
                if (backpressed.searchDetails.startDate !== 'Any date') {
                    date = [new Date(backpressed.searchDetails.startDate).getFullYear(), ("0" + (new Date(backpressed.searchDetails.startDate).getMonth() + 1)).slice(-2), ("0" + new Date(backpressed.searchDetails.startDate).getDate()).slice(-2)].join("-");
                } else {
                    date = 'Any date'
                }
                data = {
                    keywords: backpressed.searchDetails.search_keywords,
                    location: backpressed.searchDetails.search_location,
                    latitude: backpressed.searchDetails.search_latitude,
                    longitude: backpressed.searchDetails.search_longitude,
                    postcode: backpressed.searchDetails.search_postcode,
                    from_header: 'not_from_header',
                    date: date
                }
            }
        }
        history.push('/search-result', { data: JSON.stringify(data), date: date })
    }


    return (
        <>
            <div className="cancel_booking_confirmation">
                <ConfirmationPopup
                    SubHeadingText={!isCancelBooking ? responseError.createbookingdata.message : "Your booking has been cancelled, the GiniPig has been notified"}
                    ButtonText={isCancelBooking ? "Back to my account" : "Back to search"}
                    __redirectNextPage={isCancelBooking ? __redirectProfile : nextButtonClick}
                />
            </div>
        </>
    );
}

export default CancelBookingConfirmation;