import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { LeftArrow, practitioner_avtar, place_holder, star } from '../../config/images'
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import PractitionerPropfileLink from '../../components/UIComponent/PractitionerPropfileLink'
// redux
import { practitionerProfileAPI_Action,resetcreatetreatmentAPI_Action,resetPractitionerAddInfoAPI_Action,resetStripeAccountValidityAPI_Action, resetpractitionerProfileAPI_Action,resetimageUploadAPI_Action,
    resetcreateAppointmentData_Action,resettreatmentdetailsAPI_Action, resetedittreatmentAPI_Action,
     resetcreaterequestAPI_Action,resetLoginAPI_Action, practionerStripeLinkAPI_Action, resetupcomingbookingAPI_Action, 
     resetPractitionerAboutusAPI_Action, upcomingbookingAPI_Action, resetgoginipigprofileAPI_Action, resettreatmentaddreviewAPI_Action } from '../../stores/actions'
import { getLocalStorageData, clearLocalStorage, setLocalStorageData } from '../../utils/storage'
import { TEST_REQUEST } from '../../stores/types';
import LoadingSpinner from "../../components/UIComponent/LoadingSpinner"
import { S3_BUCKET_LINK } from '../../config/ApiConstants'

const PractitionerAccount = (props) => {
    // Initialize hooks first   
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const __logout = () => {
        window.scrollTo(0, 0);
        history.push('/logout-confirmation');
    }
    // console.log("paramdata", location.iswindowreload)

    const [showLoading, setshowLoading] = useState(false);
    const [iswindowreload, setiswindowReload] = useState(location.iswindowreload);

    const __ProfileEdit = () => {
        //dispatch(resetUserAPI_Action());
        // dispatch(resetgoginipigprofileAPI_Action());
        window.scrollTo(0, 0);
        history.push('/Profile-ChangePassword');
    }
    const __PractitionerInfo = () => {
        window.scrollTo(0, 0);
        history.push('/practitioner-info');
    }

    const __PractitionerAddReview = () => {
        dispatch(resettreatmentaddreviewAPI_Action());
        window.scrollTo(0, 0);
        history.push('/review-treatment');
    }

    const __PractitionerTreatmentMenu = () => {
        dispatch(resettreatmentaddreviewAPI_Action());
        window.scrollTo(0, 0);
        history.push('/treatment-Menu');
    }

    const __PractitionerProfile = () => {
        dispatch(resettreatmentaddreviewAPI_Action());
        dispatch(resetpractitionerProfileAPI_Action());
        window.scrollTo(0, 0);
        history.push({
            pathname: '/practitioner-profile',
            iswindowreload: iswindowreload

        });

    }

    // Selectors
    const userData = useSelector(state => state.userdetails);
    const loginUserData = useSelector(state => state.login);
    const practitionerRegisterData = useSelector(state => state.practitionerregister);
    const practitionersignupaddinfoData = useSelector(state => state.practitionersignupaddinfo);
    const practitioneProfileData = useSelector(state => state.practitionerprofile);
    const practitionerData = practitioneProfileData.practitionerprofiledata && practitioneProfileData.practitionerprofiledata.data && practitioneProfileData.practitionerprofiledata.data !== undefined ? practitioneProfileData.practitionerprofiledata.data.profiledata : null;
    const upcomingbookingdata = useSelector(state => state.upcomingbookingdata);
    const practitionerstripelink = useSelector(state => state.practitionerstripelink);

    // Use effects
    useEffect(() => {

        try {
            let checkToken = "";
            let LocalDatatoken = getLocalStorageData("loginData")
            let registerData = getLocalStorageData("practitionersignupData")
            //console.log(registerData)

            if (registerData != "" && registerData !== undefined && registerData !== null) {
                checkToken = registerData.token;
            }
            else if (LocalDatatoken !== "" && LocalDatatoken !== undefined && LocalDatatoken !== null) {
                checkToken = LocalDatatoken.token;
            }
            clearLocalStorage('tabkey')
           // clearLocalStorage("requestDataLocal")
            clearLocalStorage("RedirectPathName")
            clearLocalStorage("selectedtreatmentId")
            clearLocalStorage("pathnamereviewedit")
            clearLocalStorage("treatmentcatId")
            clearLocalStorage("isfromAccount")


            if (checkToken !== '') {
                dispatch(practionerStripeLinkAPI_Action(checkToken))
                dispatch(resetupcomingbookingAPI_Action(checkToken));
                dispatch(practitionerProfileAPI_Action(checkToken));
                dispatch(resetPractitionerAboutusAPI_Action());
                dispatch(resetcreatetreatmentAPI_Action());
                // window.location.reload(false);

            } else {
                //history.push('/login');
            }
            // if(iswindowreload){
            //     setiswindowReload(false)
            //     window.location.reload(false);
            // }

        } catch (error) {
            window.scrollTo(0, 0);
            history.push('/login');
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    }, [])
    if (practitioneProfileData.practitionerprofiledata && practitioneProfileData.practitionerprofiledata.status_code === 401) {
        dispatch(resetpractitionerProfileAPI_Action());
        dispatch(resetLoginAPI_Action());
        clearLocalStorage("loginData")
        clearLocalStorage("practitionersignupData")
        clearLocalStorage("RedirectPathName")
        clearLocalStorage("requestDataLocal")
        dispatch(resetcreatetreatmentAPI_Action());
        window.scrollTo(0, 0);
        history.push('/login');
    }

    // useEffect(() => {
    //     window.addEventListener('popstate', function (event) {
    //         if (iswindowreload) {
    //             setiswindowReload(false)
    //             history.go(1);
    //             window.location.reload(false);
    //         }
    //     }, false);

    // }, [])

    const __redirectScroll = () => {
        window.scrollTo(0, 0);
    }
    const __redirectLink = () => {
        history.push('/join-practitioner')
        // window.scrollTo(0,0);
        // history.goBack()
    }

    const upcomingBookingClick = () => {
        let checkToken = "";
        let LocalDatatoken = getLocalStorageData("loginData")
        let registerData = getLocalStorageData("practitionersignupData")
      
        if (registerData != "" && registerData !== undefined && registerData !== null) {
            checkToken = registerData.token;
        }
        else if (LocalDatatoken !== "" && LocalDatatoken !== undefined && LocalDatatoken !== null) {
            checkToken = LocalDatatoken.token;
        }
        // setshowLoading(true)
        dispatch(resetupcomingbookingAPI_Action());
        //   dispatch(upcomingbookingAPI_Action(checkToken));
        window.scrollTo(0, 0);
        setLocalStorageData("iswindowReload", location.iswindowreload)
        history.push({
            pathname: '/upcoming-pasting-book',
            iswindowreload: iswindowreload

        });
        //  history.push('/upcoming-pasting-book')

    }

    const treatmenuClick = () => {
        setLocalStorageData("isfromAccount", true)
        window.scrollTo(0, 0);
        history.push('/treatment-Menu')

    }

    const createAppointmentClick = () => {
        setLocalStorageData("isfromAccount", true)
        clearLocalStorage("requestDataLocal")

        clearLocalStorage("RedirectPathName")
        clearLocalStorage("selectedtreatmentId")
        clearLocalStorage("pathnamereviewedit")
        clearLocalStorage("treatmentcatId")
        clearLocalStorage("isfromAccount")

        dispatch(resetcreaterequestAPI_Action());
        dispatch(resetimageUploadAPI_Action())
        dispatch(resetcreateAppointmentData_Action());
        dispatch(resettreatmentdetailsAPI_Action());
        dispatch(resettreatmentdetailsAPI_Action());
        dispatch(resetedittreatmentAPI_Action());
        if (practitionerData.stripe_setup === 0) {
            // console.log("not registered");
            dispatch(resetPractitionerAddInfoAPI_Action());
            dispatch(resetStripeAccountValidityAPI_Action());
            window.scrollTo(0, 0);
            setLocalStorageData("RedirectPathName", "profile");
            history.push({
                pathname: '/register-practitioner-to-stripe',
                state: { 'isRegister': 0, 'token': "", stripe_link: "" },
            });
        } else {
            setLocalStorageData("RedirectPathName", "");
            window.scrollTo(0, 0);
            history.push({
                pathname: '/treatment-select',
                iswindowreload:iswindowreload
            });
        }

    }




    // if (upcomingbookingdata.isSuccess === true) {
    //     //setshowLoading(false)
    //     window.scrollTo(0, 0);
    //     history.push('/upcoming-pasting-book')
    // }

    //console.log("stripe", practitionerstripelink)
    //console.log("stripe1", practitioneProfileData)
    return (
        <>
            <section className="bgcolor-gray common-section pb80">
                {
                    practitionerData === null ?

                        <div style={{ width: "100%", marginTop: 250, textAlign: 'center' }}>
                            <LoadingSpinner />
                            <p style={{ fontSize: 20, marginTop: 10 }}>Loading ...</p>
                        </div> :


                        <Container>
                            <Row>
                                <Col className="col-lg-10 m-auto">
                                    <div className="navigationTop">
                                        <div className="heading-history">
                                            {/* <div className="back-history">
                                        <button type="button" onClick={__redirectLink} className="back-btn" >
                                            {" "}
                                            <img src={LeftArrow} alt="arrow" />{" "}
                                        </button>
                                    </div> */}
                                            <div className="history-title">
                                                <h2> My Account </h2>
                                            </div>
                                        </div>
                                        <div className="headingbox alignright ">
                                            <div className="nextnav">
                                                {/* <button onClick={__ProfileEdit} className="plainbtn">Change Password</button> */}
                                                <button onClick={__logout} className="plainbtn">Logout</button>
                                                {/* <button onClick={__PractitionerInfo} className="plainbtn">Practitioner Info</button> */}
                                                {/* <button onClick={__PractitionerAddReview} className="plainbtn">Review treatment</button>
                                        <button onClick={__PractitionerTreatmentMenu} className="plainbtn">Treatment Menu</button> */}
                                                {/* <button onClick={__PractitionerProfile} className="plainbtn">Practitioner Profile</button> */}

                                            </div>
                                        </div>
                                    </div>


                                    <div className="ac_box">
                                        <div className="btn_align_right mb-16 mt-16">
                                            <button className="profiles_btn" onClick={__PractitionerProfile} > <span>My Profile</span> <img src={LeftArrow} className="right_arrow_icons" /> </button>
                                        </div>
                                        <div className="practitioner_profile_box profile-boxs mb-32">
                                            <div className="inside_profile_box inline_row">
                                                <div className="leftbox">
                                                    <div className="user_boxs pract_ac">
                                                        <div className="user-img">
                                                            {
                                                                practitionerData !== null ? practitionerData.profile_pic !== "" ? (

                                                                    <img className="avtar" src={S3_BUCKET_LINK + practitionerData.profile_pic} />

                                                                ) : <img src={practitioner_avtar} className="img_avtar" /> : <img src={practitioner_avtar} className="img_avtar" />
                                                            }
                                                        </div>
                                                        <div className="user_name">
                                                            <div className="name">
                                                                <p> <span className="user-name">{practitionerData !== null ? practitionerData.name : null}</span>


                                                                    <span className="ratingbox">
                                                                        <img src={star} alt="raing icon" />
                                                                        <span className="rating-point">({practitionerData !== null ? practitionerData.review_rating_practitioner.toFixed(1) : 0.0})</span>
                                                                    </span>
                                                                </p>
                                                            </div>
                                                            <div className="user_address mt-8">
                                                                <p>
                                                                    <span className="role-name"> {practitionerData !== null ? practitionerData.practitioner_type === "Individual" ? practitionerData.practicing_saloon === 1 ? practitionerData.establishment_name : "Individual" : practitionerData.practicing_saloon === 1 ? practitionerData.company_name : practitionerData.practitioner_category_apply : null}, </span>
                                                                    <span className="user-address"> {practitionerData !== null ? practitionerData.user_additional_info.length > 0 ? practitionerData.user_additional_info[0].postcode_data !== null ? practitionerData.user_additional_info[0].postcode_data.postcode ? practitionerData.user_additional_info[0].postcode_data.postcode.substr(0, practitionerData.user_additional_info[0].postcode_data.postcode.length - 3) + " " : null : null : null : null}</span>
                                                                    <span className="user-address"> {practitionerData !== null ? practitionerData.user_additional_info.length > 0 ? practitionerData.user_additional_info[0].postcode_data !== null ? practitionerData.user_additional_info[0].postcode_data.city : null : null : null} </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="rightbox">
                                                    <div className="treatment_box">

                                                        {
                                                            practitionerData && practitionerData.category_treatments && practitionerData.category_treatments.map((category, index) => {

                                                                const caticonid = category.id;
                                                                return (
                                                                    <div className="cartegory_item" key={index}>
                                                                        <div className="treatment-category-btn active_radiobtn ">
                                                                            <label htmlFor={"steps" + caticonid} className="radio-button">
                                                                                <div className={"stepicon step" + caticonid}>
                                                                                    <input className="radio-tab" type="radio" id="steps1" />
                                                                                </div>
                                                                                <span className="radio-title">{category.name}</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                )


                                                            })
                                                        }

                                                        {/* <div className="cartegory_item">
                                                <div className="treatment-category-btn active_radiobtn ">
                                                    <label for="steps1" className="radio-button">
                                                        <div className="stepicon step1">
                                                            <input className="radio-tab" type="radio" id="steps1" />
                                                        </div>
                                                        <span className="radio-title">Hair</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="cartegory_item">
                                                <div className="treatment-category-btn active_radiobtn ">
                                                    <label for="steps2" className="radio-button">
                                                        <div className="stepicon step2">
                                                            <input className="radio-tab" type="radio" id="steps2" />
                                                        </div>
                                                        <span className="radio-title">Beauty</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="cartegory_item">
                                                <div className="treatment-category-btn active_radiobtn ">
                                                    <label for="steps3" className="radio-button">
                                                        <div className="stepicon step3">
                                                            <input className="radio-tab" type="radio" id="steps3" />
                                                        </div>
                                                        <span className="radio-title">Aesthetics</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="cartegory_item">
                                                <div className="treatment-category-btn active_radiobtn ">
                                                    <label for="steps4" className="radio-button">
                                                        <div className="stepicon step4">
                                                            <input className="radio-tab" type="radio" id="steps4" />
                                                        </div>
                                                        <span className="radio-title">Wellness</span>
                                                    </label>
                                                </div>
                                            </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tab_content_wrps">
                                        <PractitionerPropfileLink
                                                linkText="Create New Appointment"
                                                RedirectScrollTo={__redirectScroll}
                                                redirectLink="/upcoming-pasting-book"
                                                upcomingBooking={true}
                                                paymentdetails={false}
                                                upcomingBookingClick={createAppointmentClick}
                                                showLoading={showLoading}
                                            />

                                            <PractitionerPropfileLink
                                                linkText="Enquiries & Bookings"
                                                RedirectScrollTo={__redirectScroll}
                                                redirectLink="/upcoming-pasting-book"
                                                upcomingBooking={true}
                                                paymentdetails={false}
                                                upcomingBookingClick={upcomingBookingClick}
                                                showLoading={showLoading}
                                            />
                                            <PractitionerPropfileLink
                                                linkText="My Treatment/Service Menu"
                                                RedirectScrollTo={__redirectScroll}
                                                upcomingBooking={true}
                                                upcomingBookingClick={treatmenuClick}
                                                redirectLink="/treatment-Menu"
                                                paymentdetails={false}


                                            />
                                            <PractitionerPropfileLink
                                                linkText="My Details"
                                                RedirectScrollTo={__redirectScroll}
                                                redirectLink="/practitioner-aboutUs"
                                                upcomingBooking={false}
                                                paymentdetails={false}
                                            />

                                            {

                                                //practitionerstripelink !== null  ?
                                                practitioneProfileData && practitioneProfileData.practitionerprofiledata && practitioneProfileData.practitionerprofiledata.data && practitioneProfileData.practitionerprofiledata.data.profiledata.stripe_setup === 1 ?
                                                    <PractitionerPropfileLink
                                                        linkText="Payment Details"
                                                        //  upcomingBookingClick={(e) => redirectToPayment(e)}
                                                        RedirectScrollTo={__redirectScroll}
                                                        redirectLink=""
                                                        linktopayment={practitionerstripelink.practitionerstripelinkdata && practitionerstripelink.practitionerstripelinkdata.data && practitionerstripelink.practitionerstripelinkdata.data.logindata.url}
                                                        paymentdetails={true}
                                                    />
                                                    : null
                                            }


                                        </div>

                                    </div>

                                </Col>
                            </Row>
                        </Container>
                }
            </section>
        </>
    );
}

export default PractitionerAccount;