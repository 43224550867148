import React ,{Component} from "react";
import ReviewSlider from "./ReviewSlider";
import SlickSlider from 'react-slick';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import {og_lpimg2} from '../../../config/images';

const ReviewStructure = () =>{
    const settings = {
      dots: true,
      arrows:false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <>
        <div className="img-box rightSideImg">
        <div className="imgwidth whoarepig hide-desktop "   style={{ backgroundImage:`url(" ${og_lpimg2} ")`}}></div>  
          <div className="img-info">
            <div className="info-inside">
              <h3>Customer reviews</h3>
              <div className="info-content">
                <SlickSlider {...settings} >
                  <ReviewSlider />
                  <ReviewSlider />
                  <ReviewSlider />
                  <ReviewSlider />
                  <ReviewSlider />
                </SlickSlider>

                
  
              </div>
            </div>
          </div>
          
          <div className="imgwidth whoarepig hide-mob"  style={{ backgroundImage:`url(" ${og_lpimg2} ")`}}></div>
        </div>
      </>
    );
  }


export default ReviewStructure;
