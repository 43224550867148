import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { setLocalStorageData, getLocalStorageData, clearLocalStorage } from '../../utils/storage'

import {
  LeftArrow,
  EyeView,
  iconCorrect,
  iconClose,
} from "../../config/images";
import { useHistory } from "react-router-dom";
// redux
import { loginAPI_Action, resetLoginAPI_Action, checkemailExistsAPI_Action, resetupcomingbookingAPI_Action, resetpractitionerSignupSaveAPI_Action, resetcheckemailExistsAPI_Action } from "../../stores/actions";

const initialErrorData = Object.freeze({
  email_error: "",
  password_error: "",
  is_form_valid: false,
});

const Login = (props) => {
  //state define
  const [passwordType, setPasswordType] = useState("password");
  const [eyeIcon, setEyeIcon] = useState(true);

  // Initialize hooks first
  const dispatch = useDispatch();
  let history = useHistory();

  // States Management
  const [formData, setFormData] = useState(
    Object.freeze({
      email: "",
      password: "",
      remember_me: false,
    })
  );

  const [errorData, updateErrorData] = useState(initialErrorData);

  // Selectors
  const loginData = useSelector((state) => state.login);
  const checkemailaexistsData = useSelector((state) => state.checkemailaexists);

  var get_param = false; var treatment_id = 0;
  if (props.location.state !== undefined) {
    get_param = props.location.state.data.fav_prac_profile;
    treatment_id = props.location.state.data.treatment_id;
  }

  // Use effects
  useEffect(() => {
    // if (loginData.logindata && loginData.logindata.status_code === 200) {
    //   history.push('/user-details');
    // }
    clearLocalStorage("stripeCode");
    clearLocalStorage("loginData");
    clearLocalStorage("loginDataUser");
    clearLocalStorage("redirectPathNameEdit");
    clearLocalStorage("pathname");
    dispatch(resetupcomingbookingAPI_Action());
    let loginTokenData = getLocalStorageData("loginData");
    if (loginTokenData) {
      if (loginTokenData != null || loginTokenData != undefined) {
        window.scrollTo(0, 0);
        history.push('/ginipig-profile')

        if (loginTokenData.user.type === "ginipig") {
          window.scrollTo(0, 0);
          history.push("/ginipig-profile");
        } else {
          dispatch(resetupcomingbookingAPI_Action());
          window.scrollTo(0, 0);
          history.push('/practitioner-account');
        }
        window.scrollTo(0, 0);
      }
    } else {
      dispatch(resetLoginAPI_Action());
      dispatch(resetpractitionerSignupSaveAPI_Action, resetcheckemailExistsAPI_Action());
      dispatch(resetcheckemailExistsAPI_Action());
    }


  }, []);

  if (loginData.logindata && loginData.logindata.status_code === 200) {
    setLocalStorageData("loginData", loginData.logindata.data)
    setLocalStorageData("loginDataUser", loginData.logindata.data.user)
    clearLocalStorage("stripeCode");



    let bookCloseRequest = getLocalStorageData("book_close_request");
    // console.log("bookCloseRequest ", bookCloseRequest)
    let bookOpenRequest = getLocalStorageData("book_open_request");
    if (bookCloseRequest) {
      // console.log("bookCloseRequest1 ", bookCloseRequest)
      if (bookCloseRequest != null || bookCloseRequest != undefined) {

        if (loginData.logindata.data.user.type !== 'practitioner') {
          var request = JSON.parse(bookCloseRequest)
          // console.log('request ', request)
          if (request.book_close_request === true) {
            window.scrollTo(0, 0);
            if (loginData.logindata.data.user.profile_pic === '' || loginData.logindata.data.user.profile_pic === undefined || loginData.logindata.data.user.profile_pic === null) {
              window.scrollTo(0, 0);
              history.push('/ginipig-edit');
            } else
              if (request.photo_required === 1) {
                window.scrollTo(0, 0);
                history.push('photo-requirement/' + request.param_data)
              } else {
                window.scrollTo(0, 0);
                var today = new Date();
                var difference = today.getTime() - request.savedtime;
                var minutesDifference = Math.floor(difference / 1000 / 60);
                difference = minutesDifference
                if (difference < 10) {
                  history.push('view-booking/' + request.param_data)
                }
                else {

                    clearLocalStorage('book_close_request')
                    history.push("/ginipig-profile");
                 
                }
              }
          }
          clearLocalStorage('book_close_request')
        } else {
          if (loginData.logindata.data.user.type === "ginipig") {

            clearLocalStorage('book_close_request')
            history.push("/ginipig-profile");

          } else {
            window.scrollTo(0, 0);
            history.push('/practitioner-account');
          }
        }
      }
    } else if (bookOpenRequest) {
      if (bookOpenRequest != null || bookOpenRequest != undefined) {

        if (loginData.logindata.data.user.type !== 'practitioner') {
          var request = JSON.parse(bookOpenRequest)

          if (request.book_open_request === true) {
            window.scrollTo(0, 0);
            var today = new Date();
            var difference = today.getTime() - request.savedtime;
            var minutesDifference = Math.floor(difference / 1000 / 60);
            difference = minutesDifference
            if (difference < 10) {
              history.push({
                pathname: '/treatment-availability-open-request',
                state: { 'paramData': request.param_data, 'selectedData': request.selectedData, 'treatmentData': request.treatmentData, fromlogin: true },
              });
            } else {

              clearLocalStorage('book_close_request')
              history.push("/ginipig-profile");

            }


          }
          clearLocalStorage('book_open_request')
        } else {
          window.scrollTo(0, 0);
          if (loginData.logindata.data.user.type === "ginipig") {
            let navigateFailure = getLocalStorageData("navigateFailure");
            if (navigateFailure != null && navigateFailure != undefined) {
              var faluredata = JSON.parse(navigateFailure)
              if (faluredata.navigateFailure == true) {
                window.scrollTo(0, 0);
                // clearLocalStorage('navigateFailure');
                history.push({
                  pathname: '/payment-failure',
                  state: { 'failuredata': faluredata, },
                });

              }
            } else {

              history.push("/ginipig-profile");
            }

          } else {
            history.push('/practitioner-account');
          }
        }
      }
    } else if (get_param) {
      if (loginData.logindata.data.user.type === "ginipig") {
        setLocalStorageData('tabpankey', 1);
        history.push({
          pathname: '/practitioner-profile-ginipig',
          state: { 'paramData': treatment_id }
        });
      } else {
        history.push('/practitioner-account');
      }

    }
    else {
      window.scrollTo(0, 0);
      if (loginData.logindata.data.user.type === "ginipig") {
        let navigateFailure = getLocalStorageData("navigateFailure");
        if (navigateFailure != null && navigateFailure != undefined) {
          var faluredata = JSON.parse(navigateFailure)
          if (faluredata.navigateFailure == true) {
            window.scrollTo(0, 0);
            // clearLocalStorage('navigateFailure');
            history.push({
              pathname: '/payment-failure',
              state: { 'failuredata': faluredata, },
            });

          }
        } else {

          history.push("/ginipig-profile");
        }

      } else {

        history.push('/practitioner-account');
      }
    }

    //history.push('/ginipig-edit');

    window.scrollTo(0, 0);
  }

  // Private functions starting with __AppropriateEventName
  const __onTextChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
  };
  const __onChangePasswordToText = (e) => {
    setEyeIcon(!eyeIcon);
    setPasswordType(passwordType == "password" ? "text" : "password");
  };

  const __apiCall = useCallback(async () => {
    try {
      dispatch(resetupcomingbookingAPI_Action());
      await dispatch(loginAPI_Action(formData));
    } catch (error) {
      // console.error(" profile ::  profile() :: Exception ==", error);
    }
  });

  const __submitLoginForm = (e) => {
    e.preventDefault();
    __apiCall();
  };

  const __navigateToForgetPassword = (e) => {
    props.history.push("/forgot-password");
  };

  const __navigateToRegister = (e) => {
    props.history.push("/signup");
    window.scrollTo(0, 0);
  };
  const __navigateToRegisterPractitioner = (e) => {

    props.history.push("/signup-practitioner");
    window.scrollTo(0, 0);
  };

  const checkEmailValidation = async (e) => {
    if (loginData.isError === true) {
      dispatch(resetLoginAPI_Action());
    }

    const { id, value } = e.target;

    if (!value) {
      updateErrorData({
        ...errorData,
        is_form_valid: false,
        email_error: "Email is Required.",
      });
    } else {
      updateErrorData({
        ...errorData,
        is_form_valid: true,
        email_error: "",
      });
    }

    if (typeof value !== "undefined") {
      let lastAtPos = value.lastIndexOf("@");
      let lastDotPos = value.lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          value.indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          value.length - lastDotPos > 2
        )
      ) {
        if (!value) {
          updateErrorData({
            ...errorData,
            is_form_valid: false,
            email_error: "Email is Required.",
          });
        } else {
          updateErrorData({
            ...errorData,
            is_form_valid: false,
            email_error: "Email is not valid.",
          });
        }
      } else {
        let data = {
          email: formData.email,
        };
        // let res = await checkemailExistsAPI_Action(formData);
        // __checkIsEmailExist();
        updateErrorData({
          ...errorData,
          is_form_valid: true,
          email_error: "",
        });
      }
    } else if (value.length > 50) {
      updateErrorData({
        ...errorData,
        is_form_valid: false,
        email_error: "Only 50 Characters are Allowed.",
      });
    } else if (value.length < 6) {
      updateErrorData({
        ...errorData,
        is_form_valid: false,
        email_error: "Minimum 6 Characters are Allowed.",
      });
    } else {
      updateErrorData({
        ...errorData,
        is_form_valid: true,
        email_error: "",
      });
    }

    // if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(value)) {
    //     console.log("true");
    //     updateErrorData({
    //         ...errorData,
    //         email_error: ""
    //       });
    // }
    // else{

    //   updateErrorData({
    //     ...errorData,
    //     email_error: "Please Enter Correct Email:"
    //   });
    // }
    // console.log("Email Error: ",this.state.email_error);
  };

  const checkPasswordValidation = (e) => {
    const { id, value } = e.target;
    var decimal = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/;

    if (!value) {
      updateErrorData({
        ...errorData,
        is_form_valid: false,
        password_error: "Password Is Required.",
      });
    } else if (!value.match(decimal)) {
      updateErrorData({
        ...errorData,
        is_form_valid: false,
        password_error:
          "Password must contain [8 to 20 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character]",
      });
    } else {
      updateErrorData({
        ...errorData,
        is_form_valid: true,
        password_error: "",
      });
    }
  };

  const __checkIsEmailExist = useCallback(async () => {
    try {
      await dispatch(checkemailExistsAPI_Action(formData));
    } catch (error) {
      console.error(" profile ::  profile() :: Exception ==", error);
    }
  });

  const __rememberMe = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: !formData.remember_me,
    });
  };

  const __redirectUrl = () => {
    window.scrollTo(0, 0);
    history.push('/');
  }
  return (
    <>
      <section className="loginform login loginpg">
        <Container>
          <Row>
            <Col className="col-lg-10 m-auto">
              <div className="heading-history">
                <div className="back-history">
                  <button
                    type="button"
                    className="back-btn"
                    onClick={() => history.goBack()}
                  // onClick={() => {
                  //   __redirectUrl()
                  // }}
                  >
                    {" "}
                    <img src={LeftArrow} alt="arrow" />{" "}
                  </button>
                </div>
                <div className="history-title">
                  <h2>Login to existing account</h2>
                </div>
              </div>

              <div className="login-box">
                {loginData.isError === true ? (
                  <p className="error topalign">
                    {!loginData.logindata.errors ? (
                      <span style={{ color: "red" }}>
                        {loginData.logindata.message}
                      </span>
                    ) : null}
                  </p>
                ) : null}

                <form action="post" onSubmit={__submitLoginForm}>
                  <div className="fields">
                    <label className="filed-label"> Email address </label>

                    <div className="inputs">
                      {loginData.logindata &&
                        loginData.logindata.hasOwnProperty("errors") ? (
                        <p className="error">
                          {loginData.logindata.errors.hasOwnProperty(
                            "email"
                          ) ? (
                            <span style={{ color: "red" }}>
                              {loginData.logindata.errors.email[0]}{" "}
                            </span>
                          ) : null}
                        </p>
                      ) : null}

                      {errorData.email_error && errorData.email_error != "" ? (
                        <p className="error">
                          <span style={{ color: "red" }}>
                            {errorData.email_error}{" "}
                          </span>
                        </p>
                      ) : null}

                      {checkemailaexistsData.isError == true &&
                        checkemailaexistsData.checkemailexistsdata.status_code !=
                        200 &&
                        checkemailaexistsData.checkemailexistsdata.message !=
                        "" ? (
                        <p className="error">
                          <span style={{ color: "red" }}>
                            {checkemailaexistsData.checkemailexistsdata.message}{" "}
                          </span>
                        </p>
                      ) : null}

                      <input
                        type="email"
                        id="email"
                        name="email"
                        onChange={__onTextChange}
                        // onKeyUp={checkEmailValidation}
                        autoComplete="off"
                        required
                        minLength="6"
                        maxLength="50"
                      />
                      <span className="checkerroricon">
                        <img
                          src={iconClose}
                          alt="icon"
                          className="erroricon warning"
                        />
                        <img
                          src={iconCorrect}
                          alt="icon"
                          className="erroricon succusess"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="fields">
                    <label className="filed-label"> Password </label>

                    <div className="inputs">
                      {loginData.logindata &&
                        loginData.logindata.hasOwnProperty("errors") ? (
                        <p className="error">
                          {loginData.logindata.errors.hasOwnProperty(
                            "password"
                          ) ? (
                            <span style={{ color: "red" }}>
                              {loginData.logindata.errors.password[0]}{" "}
                            </span>
                          ) : null}
                        </p>
                      ) : null}

                      {errorData.password_error &&
                        errorData.password_error != "" ? (
                        <p className="error">
                          <span style={{ color: "red" }}>
                            {errorData.password_error}{" "}
                          </span>
                        </p>
                      ) : null}

                      <input
                        type={passwordType}
                        id="password"
                        name="password"
                        onChange={__onTextChange}
                        // onKeyUp={checkPasswordValidation}
                        required
                        minLength="8"
                        maxLength="20"
                        autoComplete="off"
                      />

                      <span
                        className="Iconview"
                        onClick={__onChangePasswordToText}
                      >
                        <img src={eyeIcon ? EyeView : EyeView} alt="icon" />
                      </span>

                      {/* <button type="button" className="passwd-btnlogin"> forgot password? </button> */}
                      <button
                        type="button"
                        onClick={__navigateToForgetPassword}
                        className="passwd-btnlogin"
                      >
                        {" "}
                        forgot password?{" "}
                      </button>
                    </div>
                  </div>

                  <div className="alignButton">
                    <button type="submit" className="submit">
                      Login
                    </button>
                  </div>
                </form>
              </div>

              {/* <div>
          <code>
            {
              JSON.stringify(loginData.isError) === true ? JSON.stringify(loginData.logindata.message) : ""
            }
          </code>
          <label>{JSON.stringify(loginData.isError) === true ? JSON.stringify(loginData) : ""}</label>
          <code>
            {
              JSON.stringify(loginData)
            }
          </code>
        </div> */}

              <div className="createBox topSapce">
                <div className="login-list">
                  <div className="list-heading">
                    <h3>Register as a GiniPig </h3>
                  </div>
                  <div className="listbtn">
                    <button
                      onClick={__navigateToRegister}
                      className="commonBtn"
                    >
                      {" "}
                      Register now
                    </button>
                  </div>
                </div>
                <div className="login-list">
                  <div className="list-heading">
                    <h3>Register as a Treatment/Service Provider</h3>
                  </div>
                  <div className="listbtn">
                    <button
                      onClick={__navigateToRegisterPractitioner}
                      className="commonBtn"
                    >
                      {" "}
                      Register now
                    </button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Login;
