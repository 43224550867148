import React, { useCallback, useState, useEffect } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getLocalStorageData,setLocalStorageData } from '../../utils/storage'

import { Container, Row, Col } from 'react-bootstrap';
import { LeftArrow, iconClose, iconPhoto } from '../../config/images';
import LargeButton from '../../components/LargeButton';
import { editginipicAPI_Action,pendingbookingDetailsAPI_Action,openrequestbookingDetailsAPI_Action,practitionerrequestDetailsAPI_Action } from '../../stores/actions'

const EditGinipigQuantity = (props) => {

    const initialErrorData = Object.freeze({
        isform_valid_ginpic: false,

    });

    const [errorData, updateErrorData] = useState(initialErrorData);
    const dispatch = useDispatch();
    let history = useHistory();
    const location = useLocation();
    const [formData, updateFormData] = useState(Object.freeze({
        ginipig_count:"",
        ginipicArray:[]
    }));



    const practitionersignupaddinfoData = useSelector(state => state.practitionersignupaddinfo);

    useEffect(() => {
        let editginiobj = getLocalStorageData("editginiobj")
        updateFormData({
            ginipig_count:editginiobj.ginipic_count,
            ginipicArray:editginiobj.ginipicArray


        })
      
    }, [])

    const saveGinipicquantity = (requestId) => {
        let editginiobjedit = getLocalStorageData("editginiobj")
        let ginipicArrayedit = []
      //  let ginpiccount = practitionerrequestDetailsdata.practitionerrequestdetailsdata && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.request.ginipig_count
        for (let i = formData.ginipig_count; i < 20; i++) {
            ginipicArrayedit.push(i)
        }
      
        let editginiobj={
            requestId:editginiobjedit.requestId,
            ginipic_count: formData.ginipig_count,
            bookings_count: editginiobjedit.bookings_count,
            ginipicArray:ginipicArrayedit,
            requesttype:editginiobjedit.requesttype,
        }
        setLocalStorageData("editginiobj",editginiobj)
        __apiCall(location.requestId)

    }
    const __apiCall = useCallback(async (requestId) => {
        let editginiobj = getLocalStorageData("editginiobj")
     
        try {

            let checkToken = "";
            let LocalDatatoken = getLocalStorageData("loginData")
            let registerData = getLocalStorageData("practitionersignupData")
    
            if (registerData != "" && registerData!== undefined && registerData !== null) {
                checkToken = registerData.token;
            }
            else if (LocalDatatoken !== "" && LocalDatatoken !== undefined && LocalDatatoken!==null) {
                checkToken = LocalDatatoken.token;
            }
            await dispatch(editginipicAPI_Action(formData, checkToken));
            if(editginiobj.requesttype===1){
                await dispatch(openrequestbookingDetailsAPI_Action(requestId, checkToken));
                window.scrollTo(0, 0);
                //history.goBack();
                history.push({
                    pathname: '/open-request',
                    bookings_count:location.bookings_count
                });
            }else{
                await dispatch(practitionerrequestDetailsAPI_Action(requestId, checkToken));
                window.scrollTo(0, 0);
              // history.goBack();
                history.push({
                    pathname: '/pending-booking-request',
                    bookings_count:location.bookings_count
                });
            }
            
           
         


        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    });

    const onChangeGinpic = (e) => {
       // console.log(location.ginipic_count)
        if (e.target.value !== '') {
           // console.log(e.target.value)
            updateFormData({
                ...formData,
                ginipig_count:parseInt(e.target.value),
                id: location.requestId
            })

            updateErrorData({
                ...errorData,
                isform_valid_ginpic: true,
            });
        } else {
            updateErrorData({
                ...errorData,
                isform_valid_ginpic: false,
            });
        }


    }

    return (
        <>
            <section className="bgcolor-gray common-section editgini_quantity pb80">
                <Container>
                    <Row>
                        <Col className="col-lg-11 m-auto">
                            <div className="navigationTop">
                                <div className="heading-history">
                                    <div className="back-history">
                                        <button type="button" onClick={() => history.goBack()} className="back-btn" >
                                            {" "}
                                            <img src={LeftArrow} alt="arrow" />{" "}
                                        </button>
                                    </div>
                                    <div className="history-title">
                                        <h2> Edit quantity  </h2>
                                    </div>
                                </div>
                            </div>


                            <div className="requirement_wrp">

                                <div className="edit_gini_box mt-24">
                                    <p className="select_box_title"> Please select the updated quantity of GiniPigs </p>

                                    <div className="location_find_item">
                                        <div className="center_box borderbox select_feild">
                                            {
                                                formData.ginipicArray !== undefined && formData.ginipicArray.length ?
                                           
                                            <select
                                                value={formData.ginipig_count}

                                                onChange={onChangeGinpic}>
                                                <option></option>
                                                {
                                                    formData.ginipicArray.map((item,index)=>{
                                                        return (
                                                        <option>{item}</option>  
                                                        )
                                                    })
                                                }
                                                {/* <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                                <option>4</option>
                                                <option>5</option>
                                                <option>6</option>
                                                <option>7</option>
                                                <option>8</option>
                                                <option>9</option>
                                                <option>10</option>
                                                <option>11</option>
                                                <option>12</option>
                                                <option>13</option>
                                                <option>14</option>
                                                <option>15</option>
                                                <option>16</option>
                                                <option>17</option>
                                                <option>18</option>
                                                <option>19</option>
                                                <option>20</option> */}
                                            </select>
                                            : null
                                        }
                                        </div>
                                    </div>

                                    <div className="btn-align mt-40 pd32">
                                        <LargeButton
                                            ButtonType="button"
                                            nextButtonClick={saveGinipicquantity}
                                            ButtonText="Save changes"
                                            disabled={errorData.isform_valid_ginpic===false}
                                        />
                                    </div>
                                </div>

                            </div>

                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default EditGinipigQuantity;