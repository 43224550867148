import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { LeftArrow } from '../../config/images';
import ImageUpload from '../../components/Imageuploadtwo';
import LargeButton from '../../components/LargeButton';
import { createAppointmentData_Action, resetcreatetreatmentAPI_Action, imageUploadAPI_Action, resetimageUploadAPI_Action,resettreatmentdetailsAPI_Action, resetcreaterequestAPI_Action, treatmentDetailsAPI_Action,checkStripeAccountValidityAPI_Action } from '../../stores/actions'
import { getLocalStorageData, setLocalStorageData,clearLocalStorage } from '../../utils/storage';


const initialErrorData = Object.freeze({
    rrp_error: "",
    your_price_error: "",
    image_type_error: "",
    is_form_valid: true,
    hrs_error: "",
    is_form_valid_hrs: false,

    is_form_valid_rrp: true,
    is_form_valid_your_price: true,
    is_form_valid_rate: true,
    is_form_valid_reason: true,
    is_form_valid_expertise: true
});




const AppointmentInfo = () => {

    const dispatch = useDispatch();
    let history = useHistory();
    const location = useLocation();

    const [formData, updateFormData] = useState(Object.freeze({
        rrpprice: '',
        yourprice: '',
        file1: "",
        file2: "",
        file3: "",
        file4: "",
        hrs: 0,
        minutes: "00",
        selectedTreatment: [],
        selectedTreatmentId: "",
        activeclassformdata: "",
        category_id: "",
        reason_require_ginipig: "",
        expertise_level: "",
        imagearray1: [],
        imagearray2: [],
        imagearray3: [],
        imagearray4: [],
        is_keep_treatment_on_profile: 0,
        request_image: [],
        selected_time_slots: [],

    }));
    const [errorData, updateErrorData] = useState(initialErrorData);
    const [currentimageId, setcurrentimageId] = useState(1);
    const [hrs_error, setHrsError] = useState("")
    const [reason_error, setReasonError] = useState("")
    const [expertise_error, setExpertiseError] = useState("")
    const [rrp_error, setRRPError] = useState("")
    const [your_price_error, setYourpriceError] = useState("")

    // Selectors
    const AppointmentCreateData = useSelector(state => state.appointmentcreatedata);
    const loginUserData = useSelector(state => state.login);
    const practitionersignupaddinfoData = useSelector(state => state.practitionersignupaddinfo);
    const createtreatmentData = useSelector(state => state.createtreatment);
    const treatmentdetailsData = useSelector(state => state.treatmentdetails);
    const imageUploaddata = useSelector(state => state.imageUploaddata);
    const checkstripeaccountvalidityData = useSelector(state => state.checkstripeaccountvalidity);
    const [errorDataModel,setErrorDataModel]= useState('')
    const [loader,setLoader]= useState(false)
    const [errorModel,setErrorModel]= useState(false)

    // console.log("imageUploaddata",imageUploaddata)
    // console.log("errorDataModel",errorDataModel)

useEffect(() => {
    if(imageUploaddata.isFetching){
        setLoader(true)
    }else if(imageUploaddata.isSuccess && imageUploaddata.data !== null) {
        setLoader(false)
    }else if (imageUploaddata.isError) {
        setErrorModel(true);
        setErrorDataModel(imageUploaddata.imageUploaddata);
    }
}, [imageUploaddata])
    


    // Use effects
    useEffect(() => {
        try {
            let checkToken = "";
            const loginToken = loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token : "" : "";
            const RegisterToken = practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.token : "" : "";
            let LocalDatatoken = getLocalStorageData("loginData")

            if (loginToken != "") {
                checkToken = loginToken;
            } else if (RegisterToken != "") {
                checkToken = RegisterToken;
            }
            else if (LocalDatatoken !== "") {
                checkToken = LocalDatatoken.token;
            }
            window.scrollTo(0, 0);
            dispatch(checkStripeAccountValidityAPI_Action(checkToken));
            dispatch(resetcreatetreatmentAPI_Action());
            let requestDataLocal = getLocalStorageData("requestDataLocal")
            let selectedtreatmentId = getLocalStorageData("selectedtreatmentId")


            if (requestDataLocal.RequestData !== null) {
                let id = selectedtreatmentId

                try {
                    dispatch(treatmentDetailsAPI_Action(id));

                } catch (error) {
                    console.error(" profile ::  profile() :: Exception ==", error);
                }
            }
            


            let useprofilelocation = 1

            if (requestDataLocal.RequestData !== null && requestDataLocal.RequestData !== "" && requestDataLocal.RequestData !== undefined) {
                if (requestDataLocal.RequestData.use_profile_location !== null && requestDataLocal.RequestData.use_profile_location !== undefined && requestDataLocal.RequestData.use_profile_location !== "") {
                    if (requestDataLocal.RequestData.use_profile_location === 1) {
                        useprofilelocation = 1
                    } else {
                        useprofilelocation = 0
                    }
                }


                updateFormData({
                    ...formData,
                    requestId: requestDataLocal.RequestData.requestId ? requestDataLocal.RequestData.requestId : 0,
                    request_image_old: requestDataLocal.RequestData.request_image_old ? requestDataLocal.RequestData.request_image_old : [],

                    is_keep_treatment_on_profile: requestDataLocal.RequestData.is_keep_treatment_on_profile ? requestDataLocal.RequestData.is_keep_treatment_on_profile : 0,
                    redirectPathName: requestDataLocal.RequestData.redirectPathName ? requestDataLocal.RequestData.redirectPathName : "complete-profile",
                    selectedTreatmentId: selectedtreatmentId,
                    selectedTreatment: requestDataLocal.RequestData ? requestDataLocal.RequestData.selectedTreatment : [],
                    rrpprice: requestDataLocal.RequestData.rrpprice ? requestDataLocal.RequestData.rrpprice : "",
                    yourprice: requestDataLocal.RequestData.yourprice ? parseFloat(requestDataLocal.RequestData.yourprice).toFixed(2) : '',

                    hrs: requestDataLocal.RequestData.hrs ? requestDataLocal.RequestData.hrs : 0,
                    minutes: requestDataLocal.RequestData.minutes ? requestDataLocal.RequestData.minutes === 5 ? "05" :requestDataLocal.RequestData.minutes : "00",

                    activeclassformdata: requestDataLocal.RequestData.activeclassformdata ? requestDataLocal.RequestData.activeclassformdata : "",

                    reason_require_ginipig: requestDataLocal.RequestData.reason_require_ginipig ? requestDataLocal.RequestData.reason_require_ginipig : "",
                    expertise_level: requestDataLocal.RequestData.expertise_level ? requestDataLocal.RequestData.expertise_level : "",

                    file1: requestDataLocal.RequestData.file1 ? requestDataLocal.RequestData.file1 : "",
                    file2: requestDataLocal.RequestData.file2 ? requestDataLocal.RequestData.file2 : "",
                    file3: requestDataLocal.RequestData.file3 ? requestDataLocal.RequestData.file3 : "",
                    file4: requestDataLocal.RequestData.file4 ? requestDataLocal.RequestData.file4 : "",

                    imagearray1: requestDataLocal.RequestData.imagearray1 ? requestDataLocal.RequestData.imagearray1 : [],
                    imagearray2: requestDataLocal.RequestData.imagearray2 ? requestDataLocal.RequestData.imagearray2 : [],
                    imagearray3: requestDataLocal.RequestData.imagearray3 ? requestDataLocal.RequestData.imagearray3 : [],
                    imagearray4: requestDataLocal.RequestData.imagearray4 ? requestDataLocal.RequestData.imagearray4 : [],
                    consent_form: requestDataLocal.RequestData.consent_form ? requestDataLocal.RequestData.consent_form : "",


                    attributes_required: requestDataLocal.RequestData.attributes_required ? requestDataLocal.RequestData.attributes_required : "",
                    photograph_required: requestDataLocal.RequestData.photograph_required ? requestDataLocal.RequestData.photograph_required : "",
                    is_consentform_required: requestDataLocal.RequestData.is_consentform_required ? requestDataLocal.RequestData.is_consentform_required : "",
                    patchtest_required: requestDataLocal.RequestData.patchtest_required ? requestDataLocal.RequestData.patchtest_required : "",


                    model_requirements: requestDataLocal.RequestData.model_requirements ? requestDataLocal.RequestData.model_requirements : "",
                    photograph_requirements: requestDataLocal.RequestData.photograph_requirements ? requestDataLocal.RequestData.photograph_requirements : "",
                    consent_form_link: requestDataLocal.RequestData.consent_form_link ? requestDataLocal.RequestData.consent_form_link : "",
                    consentformName: requestDataLocal.RequestData.consentformName ? requestDataLocal.RequestData.consentformName : "",

                    date: requestDataLocal.RequestData.date ? requestDataLocal.RequestData.date : "",
                    start_time_hrs: requestDataLocal.RequestData.start_time_hrs ? requestDataLocal.RequestData.start_time_hrs : "",
                    start_time_minutes: requestDataLocal.RequestData.start_time_minutes ? requestDataLocal.RequestData.start_time_minutes : "",
                    postcode: requestDataLocal.RequestData.postcode ? requestDataLocal.RequestData.postcode : "",

                    postcodeValue: requestDataLocal.RequestData.postcodeValue ? requestDataLocal.RequestData.postcodeValue : "",
                    country: requestDataLocal.RequestData.country ? requestDataLocal.RequestData.country : "",
                    city: requestDataLocal.RequestData.city ? requestDataLocal.RequestData.city : "",
                    latitude: requestDataLocal.RequestData.latitude ? requestDataLocal.RequestData.latitude : "",
                    longitude: requestDataLocal.RequestData.longitude ? requestDataLocal.RequestData.longitude : "",
                    locationprofile: requestDataLocal.RequestData.locationprofile ? requestDataLocal.RequestData.locationprofile : "",
                    use_profile_location: useprofilelocation,
                    ginipig_count: requestDataLocal.RequestData.ginipig_count ? requestDataLocal.RequestData.ginipig_count : "",
                    request_type: requestDataLocal.RequestData.request_type ? requestDataLocal.RequestData.request_type : "",
                    show_postcode: requestDataLocal.RequestData.show_postcode ? requestDataLocal.RequestData.show_postcode : false,
                    ischecked_online: requestDataLocal.RequestData.ischecked_online ? requestDataLocal.RequestData.ischecked_online : false,
                    ischecked_inperson: requestDataLocal.RequestData.ischecked_inperson ? requestDataLocal.RequestData.ischecked_inperson : false,

                    flat_build_no: requestDataLocal.RequestData.flat_build_no ? requestDataLocal.RequestData.flat_build_no : "",
                    selected_time_slots: requestDataLocal.selected_time_slots ? requestDataLocal.selected_time_slots : [],
                    town_city: requestDataLocal.RequestData.town_city ? requestDataLocal.RequestData.town_city : "",
                    street_name: requestDataLocal.RequestData.street_name ? requestDataLocal.RequestData.street_name : "",

                    category_id: requestDataLocal.RequestData.category_id ? requestDataLocal.RequestData.category_id : "",
                    category_name: requestDataLocal.RequestData.category_name ? requestDataLocal.RequestData.name : "",
                    sub_category_id: requestDataLocal.RequestData.sub_category_id ? requestDataLocal.RequestData.sub_category_id : [],
                    title: requestDataLocal.RequestData ? requestDataLocal.RequestData.title : "",
                    description: requestDataLocal.RequestData.title ? requestDataLocal.RequestData.description : "",
                    categories: requestDataLocal.RequestData.categories ? requestDataLocal.RequestData.categories : "",
                    treatment_sub_category: requestDataLocal.RequestData.treatment_sub_category_new ? requestDataLocal.RequestData.treatment_sub_category_new : []

                });
            }

        }
        catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }

    }, [])

    if(checkstripeaccountvalidityData.isError === true && checkstripeaccountvalidityData.checkstripeaccountvaliditydata !== null)
    {
        
        clearLocalStorage("stripeCode");
       
        let checkToken = "";
        const loginToken = loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token : "" : "";
        const RegisterToken = practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.token : "" : "";
        let LocalDatatoken = getLocalStorageData("loginData")

        if (loginToken != "") {
            checkToken = loginToken;
        } else if (RegisterToken != "") {
            checkToken = RegisterToken;
        }
        else if (LocalDatatoken !== "") {
            checkToken = LocalDatatoken.token;
        }

        let stripe_link = '';
        let login_stripe_link =loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.stripe_connect_link:"" : "";
        let register_stripe_link =practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.stripe_connect_link:"" : "";
        if (login_stripe_link != "") {
            stripe_link = login_stripe_link;
        } else if (register_stripe_link != "") {
            stripe_link = register_stripe_link;
        }
        else if (LocalDatatoken !== "") {
            stripe_link = LocalDatatoken.stripe_connect_link;
        }
        window.scrollTo(0, 0);
        setLocalStorageData("RedirectPathName", "join-practitioner")
        history.push({ 
            pathname: '/register-practitioner-to-stripe',
            state: {'isRegister' : 1,'token':checkToken,'stripe_link':stripe_link}
        });

    } 

    const __apiCall = useCallback(async () => {
        try {

            let subArray = [];

            let feature_image = []
            if (formData.file1 !== '') {
                feature_image.push(formData.imagearray1[0])
            }
            if (formData.file2 !== '') {
                feature_image.push(formData.imagearray2[0])
            }
            if (formData.file3 !== '') {
                feature_image.push(formData.imagearray3[0])
            }
            if (formData.file4 !== '') {
                feature_image.push(formData.imagearray4[0])
            }

  
            let treatment_sub_category=[]
            let title = ""
            let description = ""
            let categories = ""
            if (treatmentdetailsData.treatmentdetailsdata !== null) {
                title = treatmentdetailsData.treatmentdetailsdata.data.treatment.title
                description = treatmentdetailsData.treatmentdetailsdata.data.treatment.description
                categories = treatmentdetailsData.treatmentdetailsdata.data.treatment.categories
                treatment_sub_category = treatmentdetailsData.treatmentdetailsdata.data.treatment.treatment_sub_category_new

                treatmentdetailsData.treatmentdetailsdata.data.treatment.treatment_sub_category.map((sub_cat, index) => {
                    subArray.push(sub_cat.sub_category_id);

                })
            }

            let formDatanew = {
                requestId: formData.requestId,
                is_keep_treatment_on_profile: formData.is_keep_treatment_on_profile,
                redirectPathName: formData.redirectPathName,
                selectedTreatmentId: formData.selectedTreatmentId,
                selectedTreatment: formData.selectedTreatment,
                rrpprice: formData.rrpprice,
                yourprice: formData.yourprice,
                file1: formData.file1,
                file2: formData.file2,
                file3: formData.file3,
                file4: formData.file4,
                request_image: [],
                request_image_old: formData.request_image_old,
                hrs: formData.hrs,
                minutes: formData.minutes,
                activeclassformdata: formData.activeclassformdata,
                reason_require_ginipig: formData.reason_require_ginipig,
                expertise_level: formData.expertise_level,
                imagearray1: formData.imagearray1,
                imagearray2: formData.imagearray2,
                imagearray3: formData.imagearray3,
                imagearray4: formData.imagearray4,
                attributes_required: formData.attributes_required,
                photograph_required: formData.photograph_required,
                is_consentform_required: formData.is_consentform_required,
                patchtest_required: formData.patchtest_required,
                consent_form: formData.consent_form,
                model_requirements: formData.model_requirements,
                photograph_requirements: formData.photograph_requirements,
                consent_form_link: formData.consent_form_link,
                consentformName: formData.consentformName,

                date: formData.date,
                start_time_hrs: formData.start_time_hrs,
                start_time_minutes: formData.start_time_minutes,
                postcode: formData.postcode,

                postcodeValue: formData.postcodeValue,
                country: formData.country,
                city: formData.city,
                latitude: formData.latitude,
                longitude: formData.longitude,
                flat_build_no: formData.flat_build_no,
                town_city: formData.town_city,
                street_name: formData.street_name,
                locationprofile: formData.locationprofile,
                use_profile_location: formData.use_profile_location,
                ginipig_count: formData.ginipig_count,
                request_type: formData.request_type,
                show_postcode: formData.show_postcode,

                selected_time_slots: formData.selected_time_slots,

                category_id: treatmentdetailsData.treatmentdetailsdata ? treatmentdetailsData.treatmentdetailsdata.data.treatment.category_id : formData.category_id,
                category_name: treatmentdetailsData.treatmentdetailsdata ? treatmentdetailsData.treatmentdetailsdata.data.treatment.categories.name : formData.category_name,
                sub_category_id: subArray,
                title: title,
                description: description,
                categories: categories,
                treatment_sub_category: treatmentdetailsData.treatmentdetailsdata ? treatmentdetailsData.treatmentdetailsdata.data.treatment.treatment_sub_category_new : formData.treatment_sub_category,
                request_image_old: formData.request_image_old,
                ischecked_online:  formData.ischecked_online,
                ischecked_inperson:formData.ischecked_inperson

            }

            let formDatarequest = {
                RequestData: formDatanew,
                selected_time_slots: formDatanew.selected_time_slots
            }


            setLocalStorageData("requestDataLocal", formDatarequest)
            window.scrollTo(0, 0);
            if (location.redirectPath === "appointment-review") {
                window.scrollTo(0, 0);
                history.push('/appointment-review')

            } else {
                window.scrollTo(0, 0);
                history.push('/ginipig-requirement');
            }


        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    });


    const nextButtonClick = () => {
        dispatch(resetcreaterequestAPI_Action());
        let isdatevalid = formData.hrs != 0 || formData.minutes != "00"
        if (formData.rrpprice !== '' && formData.rrpprice != 0 && formData.yourprice !== '' && formData.yourprice >= 7.50 && 
          formData.reason_require_ginipig !=='' && formData.reason_require_ginipig !== 'Please select a reason' &&
           formData.expertise_level !== 'Please select a level' && formData.expertise_level !=='' && formData.yourprice <= (formData.rrpprice * 70 / 100) && isdatevalid) {
            __apiCall();
        } else {
            if (formData.hrs == 0 && formData.minutes === "00") {
                setHrsError("Please enter the duration of the appointment")
            }
            if (formData.reason_require_ginipig === 'Please select a reason' ||formData.reason_require_ginipig === '' ) {
                setReasonError("Reason GiniPigs are Required",)
            }
            if (formData.expertise_level === 'Please select a level' || formData.expertise_level === '') {
                setExpertiseError("Level of expertise for this treatment is Required")
            }
            if (formData.rrpprice === '') {
                setRRPError("RRP is Required.")
            }
            if (formData.yourprice === '') {
                setYourpriceError("Your Price is Required",)
            }
            if (formData.yourprice !== '' && formData.rrpprice == 0) {
                setRRPError("RRP should not 0.")
            }
            if (formData.rrpprice !== '' && formData.yourprice < 7.50) {
                setYourpriceError("Your price must be greater than £7.50")
            }

            if (formData.rrpprice !== '' && formData.yourprice > (formData.rrpprice * 70 / 100)) {
                setYourpriceError("Your price must be at least 30% off RRP")
            }
        
            window.scrollTo(0, 0);
        }

     

    }
    const deleteImg1 = () => {
        updateFormData({
            ...formData,
            file1: "",
            imagearray1: []
        });
    }
    const deleteImg2 = () => {
        updateFormData({
            ...formData,
            file2: "",
            imagearray2: []
        });
    }
    const deleteImg3 = () => {
        updateFormData({
            ...formData,
            file3: "",
            imagearray3: []
        });
    }
    const deleteImg4 = () => {
        updateFormData({
            ...formData,
            file4: "",
            imagearray4: []
        });
    }

    const onHrsChange = (event) => {
        updateFormData({
            ...formData,
            hrs: event.target.value,
        });
        if (event.target.value !== '') {
            setHrsError("")

        }
    }

    const onMinutesChange = (event) => {
        updateFormData({
            ...formData,
            minutes: event.target.value,
        });
        if (event.target.value !== '') {
            setHrsError("")
        }
    }

    const selectReasonChange = (event) => {
        if (event.target.value) {
            updateFormData({
                ...formData,
                reason_require_ginipig: event.target.value,
            });
            setReasonError("")
        }
        else {
            setReasonError("Reason GiniPigs are Required")
        }
    }
    const selectExpertiseChange = (event) => {
        if (event.target.value) {
            updateFormData({
                ...formData,
                expertise_level: event.target.value,
            });
            setExpertiseError("")
          
        } else {
            setExpertiseError("Level of expertise for this treatment is Required")
          
        }

    }
    const clickonFile = e => {
        e.target.value = "";
    }

    const handleFileChange1 = (e) => {
        e.preventDefault();
        
        dispatch(resetimageUploadAPI_Action());
        setErrorDataModel(null);
        setcurrentimageId(1)

        let filetype = e.target.files[0].type.substring(e.target.files[0].type.lastIndexOf('/'))
        let newfiletype = filetype.replace("/", "")
        if (newfiletype == "jpeg" || newfiletype == "jpg" || newfiletype == "png") {

            let imageArray = []
            if (e.target.files[0] != undefined) {
                let image = e.target.files[0]
                dispatch(imageUploadAPI_Action(image));
            }
            updateErrorData({
                ...errorData,
                is_form_valid: true,
                image_type_error: "",
            });
        }

        else {
            updateErrorData({
                ...errorData,
                is_form_valid: false,
                image_type_error: "Only JPEG,JPG,PNG file extentions are allowed",
            });
        }
       

    };
    const handleFileChange2 = (e) => {
        e.preventDefault();
        dispatch(resetimageUploadAPI_Action());
        setErrorDataModel(null);
        setcurrentimageId(2)

        let filetype = e.target.files[0].type.substring(e.target.files[0].type.lastIndexOf('/'))
        let newfiletype = filetype.replace("/", "")
        if (newfiletype == "jpeg" || newfiletype == "jpg" || newfiletype == "png") {
            let imageArray = []
            if (e.target.files[0] != undefined) {
                let image = e.target.files[0]
                dispatch(imageUploadAPI_Action(image));

            }
            updateErrorData({
                ...errorData,
                is_form_valid: true,
                image_type_error: "",
            });
        }
        else {
            updateErrorData({
                ...errorData,
                is_form_valid: false,
                image_type_error: "Please upload only JPEG,JPG and PNG file",
            });

        }
        
    };

    const handleFileChange3 = (e) => {
        e.preventDefault()
        dispatch(resetimageUploadAPI_Action());
        setcurrentimageId(3)
        let filetype = e.target.files[0].type.substring(e.target.files[0].type.lastIndexOf('/'))
        let newfiletype = filetype.replace("/", "")
        if (newfiletype == "jpeg" || newfiletype == "jpg" || newfiletype == "png") {
            let imageArray = []
            if (e.target.files[0] != undefined) {
                let image = e.target.files[0]
                dispatch(imageUploadAPI_Action(image));
               
            }
            updateErrorData({
                ...errorData,
                is_form_valid: true,
                image_type_error: "",
            });
        }
        else {
            updateErrorData({
                ...errorData,
                is_form_valid: false,
                image_type_error: "Please upload only JPEG,JPG and PNG file",
            });

        }

    };

    const handleFileChange4 = (e) => {
        e.preventDefault()
        dispatch(resetimageUploadAPI_Action());
        setcurrentimageId(4)
        let filetype = e.target.files[0].type.substring(e.target.files[0].type.lastIndexOf('/'))
        let newfiletype = filetype.replace("/", "")
        if (newfiletype == "jpeg" || newfiletype == "jpg" || newfiletype == "png") {
            let imageArray = []
            if (e.target.files[0] != undefined) {
                let image = e.target.files[0]
                dispatch(imageUploadAPI_Action(image));
              
            }
            updateErrorData({
                ...errorData,
                is_form_valid: true,
                image_type_error: "",
            });
        }
        else {
            updateErrorData({
                ...errorData,
                is_form_valid: false,
                image_type_error: "Please upload only JPEG,JPG and PNG file",
            });

        }

    };


    if (imageUploaddata !== null && imageUploaddata.isSuccess) {
        if (currentimageId == 1) {
            updateFormData({
                ...formData,
                file1: imageUploaddata.imageUploaddata.data.image_link,
                imagearray1: [imageUploaddata.imageUploaddata.data.image_link]
            });
            dispatch(resetimageUploadAPI_Action())
            
        } else if (currentimageId == 2) {
            updateFormData({
                ...formData,
                file2: imageUploaddata.imageUploaddata.data.image_link,
                imagearray2: [imageUploaddata.imageUploaddata.data.image_link]
            });
            dispatch(resetimageUploadAPI_Action())
            
        } else if (currentimageId == 3) {
            updateFormData({
                ...formData,
                file3: imageUploaddata.imageUploaddata.data.image_link,
                imagearray3: [imageUploaddata.imageUploaddata.data.image_link]
            });
        } else if (currentimageId == 4) {
            updateFormData({
                ...formData,
                file4: imageUploaddata.imageUploaddata.data.image_link,
                imagearray4: [imageUploaddata.imageUploaddata.data.image_link]
            });
        }

        

    }

    const checkpriceValidation = (e) => {
        const { id, value } = e.target;
        let price = (value * 70 / 100).toFixed(2);
        if (price < 7.50) {
            updateFormData({
                ...formData,
                yourprice: price,

            });
            setYourpriceError("Your price must be greater than £7.50")
        }

        else if (price > (value * 70 / 100)) {
            updateFormData({
                ...formData,
                yourprice: price,

            });
            setYourpriceError("Your price must be at least 30% off RRP")
        } else {
            updateFormData({
                ...formData,
                yourprice: price,

            });
            setYourpriceError("")
        }
    }



    const __onTextChange = (e) => {
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value,

        });
        if (e.target.name === "rrpprice") {
            const { id, value } = e.target;
            if (!value) {
                setRRPError("RRP is Required.")
            } else if (value < (formData.rrpprice * 70 / 100)) {
                setYourpriceError("Your price must be at least 30% off RRP")
            } else {
                setRRPError("")
                setYourpriceError("")
            }

        }

        if (e.target.name === "yourprice") {
            const { id, value } = e.target;
            if (!value) {
                setYourpriceError("Your price is Required")
            }
            if (value < 7.50) {
                setYourpriceError("Your price must be greater than £7.50")
            } else if (value > (formData.rrpprice * 70 / 100)) {
                setYourpriceError("Your price must be at least 30% off RRP")
            }
            else {
                setYourpriceError("")
            }

        }

    }
    const backBtnPressed=()=>{
        // console.log("back button presses");
        dispatch(resettreatmentdetailsAPI_Action());
         history.goBack();
     }

    return (
        <>
            <section className="bgcolor-gray common-section appointment_info_pg pb80">
                <Container>
                    <Row>
                        <Col className="col-lg-11 m-auto">
                            <div className="navigationTop">
                                <div className="heading-history">
                                    <div className="back-history">
                                        <button type="button" onClick={backBtnPressed} className="back-btn" >
                                            {" "}
                                            <img src={LeftArrow} alt="arrow" />{" "}
                                        </button>
                                    </div>
                                    <div className="history-title">
                                        <h2> Appointment Information     </h2>
                                    </div>
                                </div>
                            </div>
                            <div className="from-wrp mt-40">
                                <form>
                                    <div className="appointfrom fromProfile form-wrp mb-24">

                                        <div className="heading-label">Duration of appointment</div>
                                        <div >  {hrs_error &&
                                            hrs_error != "" ? (
                                            <p className="error">
                                                <p style={{ color: "red" }}>
                                                    {hrs_error}{" "}
                                                </p>
                                            </p>
                                        ) : null}  </div>
                                        <div className="two-cols ">
                                            <div className="profilefield setfeild">
                                                <div className="showWithLabal">
                                                    <label className="heading-label"> Hours </label>

                                                </div>
                                                <div className="select_feild">
                                                    <select
                                                        value={formData.hrs}
                                                        onChange={onHrsChange}>
                                                        <option>0</option>
                                                        <option>1</option>
                                                        <option>2</option>
                                                        <option>3</option>
                                                        <option>4</option>
                                                        <option>5</option>
                                                        <option>6</option>
                                                        <option>6</option>
                                                        <option>7</option>
                                                        <option>8</option>
                                                        <option>9</option>
                                                        <option>10</option>
                                                        <option>11</option>
                                                        <option>12</option>
                                                        <option>13</option>
                                                        <option>14</option>
                                                        <option>15</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="profilefield setfeild">
                                                <div className="showWithLabal">
                                                    <label className="heading-label"> Minutes </label>

                                                </div>
                                                <div className="select_feild">
                                                    <select
                                                        value={formData.minutes}
                                                        onChange={onMinutesChange}>
                                                        <option>00</option>
                                                        <option>05</option>
                                                        <option>10</option>
                                                        <option>15</option>
                                                        <option>20</option>
                                                        <option>25</option>
                                                        <option>30</option>
                                                        <option>35</option>
                                                        <option>40</option>
                                                        <option>45</option>
                                                        <option>50</option>
                                                        <option>55</option>

                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="profilefield ">
                                            <div className="showWithLabal1">
                                                <label className="heading-label"> Reason why I require GiniPigs </label>
                                                {reason_error &&
                                                    reason_error != "" ? (
                                                    <p className="error">
                                                        <p style={{ color: "red" }}>
                                                            {reason_error}{" "}
                                                        </p>
                                                    </p>
                                                ) : null}
                                            </div>
                                            <div className="select_feild">
                                                <select
                                                    value={formData.reason_require_ginipig}
                                                    onChange={selectReasonChange}>
                                                    <option>Please select a reason</option>
                                                    <option>Training </option>
                                                    <option>Case study </option>
                                                    <option>Portfolio </option>

                                                </select>
                                            </div>
                                        </div>

                                        <div className="profilefield ">
                                            <div className="showWithLabal1">
                                                <label className="heading-label"> My level of expertise for this treatment/service </label>
                                                {expertise_error &&
                                                    expertise_error != "" ? (
                                                    <p className="error">
                                                        <p style={{ color: "red" }}>
                                                            {expertise_error}{" "}
                                                        </p>
                                                    </p>
                                                ) : null}
                                            </div>
                                            <div className="select_feild">
                                                <select
                                                    value={formData.expertise_level}
                                                    onChange={selectExpertiseChange}>
                                                    <option>Please select a level</option>
                                                    <option>Newbie </option>
                                                    <option>Nearly there </option>
                                                    <option>Star </option>
                                                    {/* <option>Ready to go </option> */}

                                                </select>
                                            </div>
                                        </div>
                                        <div className="heading-label">Treatment Price  (Must be at least 30% off RRP & a minimum list price of £7.50)</div>
                                        <div className="two-cols ">
                                            <div className="profilefield setfeild">
                                                <div className="showWithLabal">
                                                    <label className="heading-label"> RRP </label>
                                                    {rrp_error &&
                                                        rrp_error != "" ? (
                                                        <p className="error">
                                                            <p style={{ color: "red" }}>
                                                                {rrp_error}{" "}
                                                            </p>
                                                        </p>
                                                    ) : null}
                                                </div>

                                                <div className="input_box">
                                                    <span className="euro_sign"> £ </span>
                                                    <input
                                                        type='number'
                                                        value={formData.rrpprice}
                                                        id="rrpprice"
                                                        name="rrpprice"
                                                        pattern='[0-9]{0,5}'
                                                        onChange={__onTextChange}
                                                        onBlur={checkpriceValidation}
                                                    />
                                                </div>

                                            </div>
                                            <div className="profilefield setfeild">
                                                <div className="showWithLabal">
                                                    <label className="heading-label"> Your price  </label>
                                                    {your_price_error &&
                                                        your_price_error != "" ? (
                                                        <p className="error">
                                                            <p style={{ color: "red" }}>
                                                                {your_price_error}{" "}
                                                            </p>
                                                        </p>
                                                    ) : null}
                                                </div>
                                                <div className="input_box">
                                                    <span className="euro_sign"> £ </span>
                                                    <input
                                                        type='number'
                                                        pattern='[0-9]{0,5}'
                                                        value={formData.yourprice}
                                                        id="yourprice"
                                                        name="yourprice"
                                                        onChange={__onTextChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                    </div>


                                    <div className="label-imgupload fromProfile">
                                        <div className="profilefield-label">
                                            <div className="optional-errormsg">
                                               <div>
                                               {/* <label className="profile-label"> Stand out by using your own images (minimum 2) rather than having our bank images automatically selected for you </label>
                                                <label className="profile-label mt-15"> Don't forget to wow them with your first, this is like your shop window and will be the first glimpse of you that they get to see </label> */}
                                               <label className="profile-label"> Add images for this treatment, yours will dazzle more than our bank images. This is your shop window and your opportunity to impress GiniPigs, two images will appear at the top of your listing</label>
                                               
                                               </div>
                                                
                                                <p className="optiontext">Optional</p>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    {errorData.image_type_error &&
                                                    errorData.image_type_error != "" ? (
                                                    <div className="error">
                                                        <p style={{ color: "red" }}>
                                                            {errorData.image_type_error}{" "}
                                                        </p>
                                                    </div>
                                                ) : null}
                                        { errorModel &&
                                        <p className="errortext">{errorDataModel?.errors?.image}</p>
                                        }  
                                    <div className=" form-wrp">
                                        <div className="inside-photoupload">
                                            <div className="two-cols-image">
                                                <div className="uploadimg1">
                                                    <ImageUpload
                                                        id="profile_pic1"
                                                        File={formData.file1 ? formData.file1 : null}
                                                        handleFileChange={handleFileChange1}
                                                        RemoveImg={deleteImg1}
                                                        onClick={clickonFile}
                                                        setEdit={formData.file1 == ""}
                                                        showloading={imageUploaddata.isFetching && currentimageId == 1 ? true : false}
                                                    />
                                                </div>
                                                <div className="uploadimg2">
                                                    <ImageUpload
                                                        id="profile_pic2"
                                                        File={formData.file2 ? formData.file2 : null}
                                                        handleFileChange={handleFileChange2}
                                                        RemoveImg={deleteImg2}
                                                        onClick={clickonFile}
                                                        setEdit={formData.file2 == ""}
                                                        showloading={imageUploaddata.isFetching && currentimageId == 2 ? true : false}
                                                    />
                                                </div>
                                                {/* <div className="uploadimg2">
                                                    <ImageUpload
                                                        id="profile_pic1"
                                                        File={formData.file3 ? formData.file3 : null}
                                                        handleFileChange={handleFileChange3}
                                                        RemoveImg={deleteImg3}
                                                        onClick={clickonFile}
                                                        setEdit={formData.file3 == ""}
                                                        showloading={imageUploaddata.isFetching && currentimageId == 3 ? true : false}
                                                    />
                                                </div> */}
                                                {/* <div className="uploadimg4">
                                                    <ImageUpload
                                                        id="profile_pic4"
                                                        File={formData.file4 ? formData.file4 : null}
                                                        handleFileChange={handleFileChange4}
                                                        RemoveImg={deleteImg4}
                                                        onClick={clickonFile}
                                                        setEdit={formData.file4 == ""}
                                                        showloading={imageUploaddata.isFetching && currentimageId == 4 ? true : false}
                                                    />
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="btn-align pd32">
                                        <LargeButton
                                            ButtonType="button"
                                            ButtonText={location.redirectPath === "appointment-review" ? "Save Changes" : "Next"}
                                            nextButtonClick={nextButtonClick}
                                            disabled={imageUploaddata.isFetching === true}

                                        />
                                    </div>
                                </form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default AppointmentInfo;
