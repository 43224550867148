import React, { useCallback, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { LeftArrow } from '../../config/images';
import TreatmentRadioButton from '../../components/TreatmentRadioButton';
import LargeButton from '../../components/LargeButton';
import { createtreatmentAPI_Action, categorylistAPI_Action, resetLoginAPI_Action, resetcreatetreatmentAPI_Action, resetCategoryListAPI_Action } from '../../stores/actions'
import { getLocalStorageData, clearLocalStorage, setLocalStorageData } from '../../utils/storage'

const initialErrorData = Object.freeze({
    treatment_title_error: "",
    treatment_description_error: "",
    treatment_sub_cat_error: "",
    is_keep_treatment_on_profile_error: "",
    treatment_description_error_active: "Description should be minimum 25 characters long.",
    is_form_valid_title: false,
    is_form_valid_sub_lenght: false,
    is_form_valid_desc: false,
    is_form_valid_sub_cat: false,
    is_form_valid_on_profile: false,

});

const TreatmentAddAnotherGigiRequest = () => {
    let history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    // States Management
    const [formData, updateFormData] = useState(Object.freeze({
        title: "",
        description: "",
        page: 1,
        token: "",
        treatment_id: "",

        category_id: 1,
        sub_category_id: [],
        is_keep_treatment_on_profile: "",
        redirectPathName: "",
    }));

    const [errorData, updateErrorData] = useState(initialErrorData);
    const [nextbtn, setnNextbtn] = useState(true);
    const [lenghtCatArray, setlenghtCatArray] = useState(1);
    const [SubCatlenghtError, setSubCatlenghtError] = useState(false);
    const [character_count, setCharactercount] = useState(500);
    const [character_count_title, setCharactercountTitle] = useState(65);
    const [hideBack, sethideBack] = useState(false);

    const createtreatmentData = useSelector(state => state.createtreatment);
    const categoryData = useSelector(state => state.categorylist);

    const AppointmentCreateData = useSelector(state => state.appointmentcreatedata);

    useEffect(() => {

        let checkToken = "";

        let LocalDatatoken = getLocalStorageData("loginData")
        let registerData = getLocalStorageData("practitionersignupData")

        if (registerData != "" && registerData !== undefined && registerData !== null) {
            checkToken = registerData.token;
        }
        else if (LocalDatatoken !== "" && LocalDatatoken !== undefined && LocalDatatoken !== null) {
            checkToken = LocalDatatoken.token;
        }
        if (checkToken === '') {
            // history.push('/login');
        }
        let RedirectPathName = getLocalStorageData("RedirectPathName")
        if (RedirectPathName == "join-practitioner") {
            sethideBack(true)
        }
        let selectedtreatmentId = getLocalStorageData("selectedtreatmentId")

        if (location.RedirectPathName === "editRequest") {
            updateErrorData({
                ...errorData,
                is_form_valid_title: true

            });
        }
        let requestDataLocal = getLocalStorageData("requestDataLocal")
        let is_keep_treatment_on_profile = "";
        if (requestDataLocal !== null && requestDataLocal !== "" && requestDataLocal !== undefined) {
            if (requestDataLocal.RequestData.is_keep_treatment_on_profile !== null && requestDataLocal.RequestData.is_keep_treatment_on_profile !== undefined && requestDataLocal.RequestData.is_keep_treatment_on_profile !== "") {
                if (requestDataLocal.RequestData.is_keep_treatment_on_profile === 1) {
                    is_keep_treatment_on_profile = 1
                } else {
                    is_keep_treatment_on_profile = 0
                }
            }

            updateFormData({
                ...formData,
                token: checkToken,
                treatment_id: selectedtreatmentId ? selectedtreatmentId : "",
                requestId: requestDataLocal.RequestData.requestId ? requestDataLocal.RequestData.requestId : 0,
                page: requestDataLocal.RequestData ? requestDataLocal.RequestData.category_id : "",
                is_keep_treatment_on_profile: requestDataLocal.RequestData.is_keep_treatment_on_profile ? requestDataLocal.RequestData.is_keep_treatment_on_profile : is_keep_treatment_on_profile,
                redirectPathName: requestDataLocal.RequestData.redirectPathName ? requestDataLocal.RequestData.redirectPathName : "complete-profile",
                request_image_old: requestDataLocal.RequestData.request_image_old ? requestDataLocal.RequestData.request_image_old : [],
                //selectedTreatmentId: requestDataLocal.RequestData ? requestDataLocal.RequestData.selectedTreatmentId : "",
                //selectedTreatment:requestDataLocal.RequestData ? requestDataLocal.RequestData.selectedTreatment :[],
                rrpprice: requestDataLocal.RequestData.rrpprice ? requestDataLocal.RequestData.rrpprice : "",
                yourprice: requestDataLocal.RequestData.yourprice ? requestDataLocal.RequestData.yourprice : "",
                file1: requestDataLocal.RequestData.file1 !== undefined ? requestDataLocal.RequestData.file1 : "",
                file2: requestDataLocal.RequestData.file2 !== undefined ? requestDataLocal.RequestData.file2 : "",
                file3: requestDataLocal.RequestData.file3 !== undefined ? requestDataLocal.RequestData.file3 : "",
                file4: requestDataLocal.RequestData.file4 !== undefined ? requestDataLocal.RequestData.file4 : "",

                hrs: requestDataLocal.RequestData.hrs ? requestDataLocal.RequestData.hrs : "",

                minutes: requestDataLocal.RequestData.minutes ? requestDataLocal.RequestData.minutes : "",

                activeclassformdata: requestDataLocal.RequestData ? requestDataLocal.RequestData.activeclassformdata : "",

                reason_require_ginipig: requestDataLocal.RequestData.reason_require_ginipig ? requestDataLocal.RequestData.reason_require_ginipig : "",
                expertise_level: requestDataLocal.RequestData.expertise_level ? requestDataLocal.RequestData.expertise_level : "",
                imagearray1: requestDataLocal.RequestData.imagearray1 ? requestDataLocal.RequestData.imagearray1 : "",
                imagearray2: requestDataLocal.RequestData.imagearray2 ? requestDataLocal.RequestData.imagearray2 : "",
                imagearray3: requestDataLocal.RequestData.imagearray3 ? requestDataLocal.RequestData.imagearray3 : "",
                imagearray4: requestDataLocal.RequestData.imagearray4 ? requestDataLocal.RequestData.imagearray4 : "",


                attributes_required: requestDataLocal.RequestData ? requestDataLocal.RequestData.attributes_required : "",
                photograph_required: requestDataLocal.RequestData ? requestDataLocal.RequestData.photograph_required : "",
                is_consentform_required: requestDataLocal.RequestData ? requestDataLocal.RequestData.is_consentform_required : "",
                patchtest_required: requestDataLocal.RequestData ? requestDataLocal.RequestData.patchtest_required : "",
                consent_form: requestDataLocal.RequestData ? requestDataLocal.RequestData.consent_form : "",


                model_requirements: requestDataLocal.RequestData ? requestDataLocal.RequestData.model_requirements : "",
                photograph_requirements: requestDataLocal.RequestData ? requestDataLocal.RequestData.photograph_requirements : "",
                consent_form_link: requestDataLocal.RequestData ? requestDataLocal.RequestData.consent_form_link : "",
                consentformName: requestDataLocal.RequestData ? requestDataLocal.RequestData.consentformName : "",

                date: requestDataLocal.RequestData ? requestDataLocal.RequestData.date : "",
                start_time_hrs: requestDataLocal.RequestData ? requestDataLocal.RequestData.start_time_hrs : "",
                start_time_minutes: requestDataLocal.RequestData ? requestDataLocal.RequestData.start_time_minutes : "",
                postcode: requestDataLocal.RequestData ? requestDataLocal.RequestData.postcode : "",

                postcodeValue: requestDataLocal.RequestData ? requestDataLocal.RequestData.postcodeValue : "",
                country: requestDataLocal.RequestData ? requestDataLocal.RequestData.country : "",
                city: requestDataLocal.RequestData ? requestDataLocal.RequestData.city : "",
                town_city: requestDataLocal.RequestData.town_city ? requestDataLocal.RequestData.town_city : "",
                latitude: requestDataLocal.RequestData ? requestDataLocal.RequestData.latitude : "",
                longitude: requestDataLocal.RequestData ? requestDataLocal.RequestData.longitude : "",
                locationprofile: requestDataLocal.RequestData.locationprofile ? requestDataLocal.RequestData.locationprofile : "",
                use_profile_location: requestDataLocal.RequestData ? requestDataLocal.RequestData.use_profile_location : 1,
                ginipig_count: requestDataLocal.RequestData ? requestDataLocal.RequestData.ginipig_count : "",
                request_type: requestDataLocal.RequestData ? requestDataLocal.RequestData.request_type : "",
                show_postcode: requestDataLocal.RequestData ? requestDataLocal.RequestData.show_postcode : "",
                ischecked_online: requestDataLocal.RequestData.ischecked_online ? requestDataLocal.RequestData.ischecked_online : false,
                ischecked_inperson: requestDataLocal.RequestData.ischecked_inperson ? requestDataLocal.RequestData.ischecked_inperson : false,
                street_name: requestDataLocal.RequestData.street_name ? requestDataLocal.RequestData.street_name : "",
                selected_time_slots: requestDataLocal.selected_time_slots ? requestDataLocal.selected_time_slots : "",

                category_id: requestDataLocal.RequestData ? requestDataLocal.RequestData.category_id : "",
                category_name: requestDataLocal.RequestData ? requestDataLocal.RequestData.name : "",
                sub_category_id: requestDataLocal.RequestData.sub_category_id ? requestDataLocal.RequestData.sub_category_id : [],
                title: requestDataLocal.RequestData ? requestDataLocal.RequestData.title : "",
                description: requestDataLocal.RequestData ? requestDataLocal.RequestData.description : "",
                categories: requestDataLocal.RequestData ? requestDataLocal.RequestData.categories : "",
                //treatment_sub_category: requestDataLocal.RequestData.treatment_sub_category_new ? requestDataLocal.RequestData.treatment_sub_category_new : []

            });
            if (requestDataLocal.RequestData && requestDataLocal.RequestData.description) {
                setCharactercount(500 - requestDataLocal.RequestData.description.length)
            } else {
                setCharactercount(500)
            }

            if (requestDataLocal.RequestData && requestDataLocal.RequestData.title) {
                setCharactercountTitle(65 - requestDataLocal.RequestData.title.length)
            } else {
                setCharactercountTitle(65)
            }

        }

        try {
            dispatch(categorylistAPI_Action());

        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }



    }, [])

    const __onTextChange = (e) => {

        updateFormData({
            ...formData,
            [e.target.name]: e.target.value,

        });

          
        if (e.target.name === "title" && e.target.value !== "") {
            setCharactercountTitle(65 - e.target.value.length)
        } else {
            setCharactercountTitle(65)
        }


        if (e.target.name === "description" && e.target.value !== "") {
            setCharactercount(500 - e.target.value.length)
        } else {
            setCharactercount(500)
        }



        if (e.target.name === "description" && e.target.value.length >= 25) {
            updateErrorData({
                ...errorData,

                is_form_valid_desc: false,
                treatment_description_error: ""

            });
        }



        if (formData.page == lenghtCatArray) {


            updateFormData({
                ...formData,
                [e.target.name]: e.target.value,

            });

        }

    };

    const checkTreatmentTitleValidation = (e) => {
        const { id, value } = e.target;

        if (!value) {

            updateErrorData({
                ...errorData,
                is_form_valid_title: false,
                treatment_title_error: "Treatment title is required."
            });

        } else {
            updateErrorData({
                ...errorData,
                is_form_valid_title: true,
                is_form_valid_desc: true,
                treatment_title_error: ""
            });
        }
    }

    const CategoryClick = (e, id) => {
        updateFormData({
            ...formData,
            category_id: id,
            page: id,
            // title: "",
            // description: "",
            sub_category_id: [],
            treatment_title_error: "",
            treatment_sub_cat_error: "",
            treatment_description_error: ""


        });
    }

    const __selectSubCategory = (e, id) => {
        // const {id,value} = e.target;
        let selectedBoxes = formData.sub_category_id;

        let index = selectedBoxes.indexOf(parseInt(id)) // check to see if the name is already stored in the array
        if (index === -1) {
            selectedBoxes.push(parseInt(id))
        } else {
            selectedBoxes.splice(index, 1)
        }

        if (selectedBoxes !== null) {
            if (selectedBoxes.length <= 3) {
                updateErrorData({
                    ...errorData,
                    is_form_valid_sub_cat: false,
                    treatment_sub_cat_error: ""

                });

            }
            else {
                updateErrorData({
                    ...errorData,
                    treatment_sub_cat_error: "(select up to 3)"
                });

            }

        }
        selectedBoxes = selectedBoxes.slice(0, 3);

        updateFormData({
            ...formData,
            sub_category_id: selectedBoxes,
        });


    }


    const checkDescValidation = (e) => {
        const { id, value } = e.target;
        if (!value) {

            updateErrorData({
                ...errorData,
                is_form_valid_desc: true,
                treatment_description_error: "Treatment description is required.",
            });


        } else if (value.length < 25) {
            updateErrorData({
                ...errorData,
                is_form_valid_desc: true,
                treatment_description_error: "Description should be minimum 25 characters long.",
            });
        } else if (value.length > 500) {
            updateErrorData({
                ...errorData,
                is_form_valid_desc: true,
                treatment_description_error: "Only 500 Characters are Allowed.",
            });
        } else {
            updateErrorData({
                ...errorData,
                is_form_valid_desc: false,
                treatment_description_error: ""
            });
        }

    };


    const saveAndFinishClick = (e) => {
        e.preventDefault();

        //dispatch(resetcreatetreatmentAPI_Action()) ;
        if (formData.sub_category_id.length !== 0 && formData.description !== "" && formData.description.length >= 25 && formData.is_keep_treatment_on_profile !== "") {
            __apiCall();
        }

        else if (formData.sub_category_id.length === 0) {
            window.scrollTo(0, 0)
            updateErrorData({
                ...errorData,

                is_form_valid_desc: false,
                treatment_sub_cat_error: "Please Select Atleast 1 sub-category.",
            });
        }
        else if (formData.description == "") {
            updateErrorData({
                ...errorData,

                is_form_valid_desc: false,
                treatment_description_error: "Please add Treatment Description.",
            });
        }
        else if (formData.description.length <25) {
            updateErrorData({
                ...errorData,

                is_form_valid_desc: false,
                treatment_description_error: "Description should be minimum 25 characters long.",
            });
        }
        else if (formData.is_keep_treatment_on_profile !== "") {
            updateErrorData({
                ...errorData,

                is_form_valid_on_profile: false,
                is_keep_treatment_on_profile_error: "Please select Yes or No for Keep treatment on profile.",
            });
        }
    };



    const __apiCall = useCallback(async () => {
        try {
            let checkToken = "";
            let LocalDatatoken = getLocalStorageData("loginData")
            let registerData = getLocalStorageData("practitionersignupData")

            if (registerData != "" && registerData !== undefined && registerData !== null) {
                checkToken = registerData.token;
            }
            else if (LocalDatatoken !== "" && LocalDatatoken !== undefined && LocalDatatoken !== null) {
                checkToken = LocalDatatoken.token;
            }
            if (checkToken === '') {
                window.scrollTo(0, 0);
                history.push('/login');
            }

            if (checkToken !== '') {
                let formDatanew = {
                    RequestData: formData,
                    selected_time_slots: formData.selected_time_slots

                }
                setLocalStorageData("requestDataLocal", formDatanew)
                //await dispatch(createAppointmentData_Action(formData, formData.selected_time_slots));
                await dispatch(createtreatmentAPI_Action(formData, checkToken));
            } else {
                //  history.push('/login');
            }
            // await dispatch(createtreatmentAPI_Action(formData, loginToken));
        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    });

    if (createtreatmentData && createtreatmentData.createtreatmentdata !== null && createtreatmentData.isSuccess === true) {
        //dispatch(resetcreatetreatmentAPI_Action())

        window.scrollTo(0, 0);
        let formDatanew = {
            RequestData: formData,
            selected_time_slots: formData.selected_time_slots

        }
        setLocalStorageData("requestDataLocal", formDatanew)
        setLocalStorageData("redirectPathNameEdit", "addAnother")
        if (createtreatmentData.createtreatmentdata && createtreatmentData.createtreatmentdata.data.treatment.uuid !== 0) {
            setLocalStorageData("selectedtreatmentId", createtreatmentData.createtreatmentdata && createtreatmentData.createtreatmentdata.data.treatment.uuid)
        } else {
            setLocalStorageData("selectedtreatmentId", "")

        }
        window.scrollTo(0, 0);
        history.push('/appointment-info');
    } else if (createtreatmentData && createtreatmentData.createtreatmentdata !== null && createtreatmentData.createtreatmentdata.status_code === 401) {
        //when ac suspend
        clearLocalStorage("loginData")
        clearLocalStorage("selectedtreatmentId")
        clearLocalStorage("redirectPathNameEdit")
       clearLocalStorage("requestDataLocal")
        clearLocalStorage("practitionersignupData")
        dispatch(resetLoginAPI_Action());
        dispatch(resetCategoryListAPI_Action());
        dispatch(resetcreatetreatmentAPI_Action());
        window.scrollTo(0, 0);
        history.push("/login");

    }

    const __isSetTreatmentOnProfile = (e, status) => {
        e.preventDefault();
        updateFormData({
            ...formData,
            is_keep_treatment_on_profile: status,
        });
    }








    return (
        <>
            <section className="bgcolor-gray common-section icon_unselectbox treatment_add_another_gigReq pb80">
                <Container>
                    <Row>
                        <Col className="col-lg-11 m-auto">
                            <div className="navigationTop">
                                <div className="heading-history">
                                    {hideBack === false ?
                                        <div className="back-history">
                                            <button type="button" onClick={() => history.goBack()} className="back-btn" >
                                                {" "}
                                                <img src={LeftArrow} alt="arrow" />{" "}
                                            </button>
                                        </div> : null
                                    }

                                    <div className="history-title">
                                        <h2> Treatment/Service Menu  </h2>
                                    </div>
                                </div>
                            </div>
                            <div className="from-wrp mt-40">
                                <div className="treatment_info_step">
                                    <p className="steps-label mb-16">Categories offered </p>
                                    <div className="treatment-catogory">

                                        <div className="category-list">
                                            {

                                                categoryData.categorylistdata && categoryData.categorylistdata.data.categories.map((item, index) => {
                                                    return (
                                                        <TreatmentRadioButton
                                                            CategoryTitle={item.name}

                                                            CategoryClick={(e) => CategoryClick(e, item.id)}
                                                            id={item.id}
                                                            activeid={formData}
                                                            activeclassformdata={formData.page}

                                                        />
                                                    )
                                                })
                                            }


                                        </div>

                                    </div>
                                </div>
                                <form>
                                    <div className="treatmentform form-wrp fromProfile mb-24 mt-24">

                                        <div className="profilefield">
                                            <div className="showwithlabel">
                                                <label className="profile-label">Treatment Title</label>
                                                {errorData.treatment_title_error &&
                                                    errorData.treatment_title_error != "" ? (
                                                    <p className="error">
                                                        <p style={{ color: "red" }}>
                                                            {errorData.treatment_title_error}{" "}
                                                        </p>
                                                    </p>
                                                ) : null}
                                            </div>
                                            <input
                                                type="text"
                                                value={formData.title}
                                                id="title"
                                                name="title"
                                                onChange={__onTextChange}
                                                maxlength="65"
                                                onBlur={checkTreatmentTitleValidation}
                                            />
                                            {
                                                character_count_title ==65 ?
                                                    <span className="char-span-title">{character_count_title} Character limit</span> :
                                                    <span className="char-span-title">{character_count_title} Remaining</span>
                                            }
                                        </div>

                                        <div className="category-box mb-24">
                                            <div className="insidebox">
                                                <div className="optional-errormsg">
                                                    {/* <label className="profile-label">Choose the categories that apply to this treatment</label> */}
                                                    <label className="profile-label"> Select Category</label>
                                                    {errorData.treatment_sub_cat_error &&
                                                        errorData.treatment_sub_cat_error != "" ? (
                                                        <p className="error">
                                                            <p className="errortext">
                                                                {"(select up to 3)"}
                                                            </p>
                                                        </p>
                                                    ) : <p className="optiontext">(select up to 3)</p>}



                                                </div>
                                            </div>
                                            <div className="category-checkbox-grp">

                                                {
                                                    categoryData.categorylistdata && categoryData.categorylistdata.data && categoryData.categorylistdata.data.categories && categoryData.categorylistdata.data.categories[formData.category_id - 1] && categoryData.categorylistdata.data.categories[formData.category_id - 1].sub_categories.data.map((sub_item, sub_index) => {
                                                        return (

                                                            <div className="checkbox-item">
                                                                <div className={formData.sub_category_id.indexOf(sub_item.id) !== -1 ? "checkbox-button sub-background" : "checkbox-button"} >
                                                                    <label className="checkox-label yes-checked">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="checkbox-btn"
                                                                            id={sub_item.id}
                                                                            name={sub_item.id}
                                                                            onClick={(e) => __selectSubCategory(e, sub_item.id)}
                                                                            value={sub_item.id}
                                                                            checked={formData.sub_category_id.indexOf(sub_item.id) !== -1 ? true : false} />
                                                                        <span className="button-text">{sub_item.name}</span>
                                                                    </label>

                                                                </div>
                                                            </div>



                                                        )
                                                    })
                                                }


                                            </div>
                                        </div>


                                        <div className="profilefield mb-0">
                                            <div className="showwithlabel">
                                                <label className="profile-label"> Treatment Description </label>
                                                {errorData.treatment_description_error &&
                                                    errorData.treatment_description_error != "" ? (
                                                    <p className="error">
                                                        <p className="errortext">
                                                            {errorData.treatment_description_error}{" "}
                                                        </p>
                                                    </p>
                                                ) : <p className="error">
                                                    <p className="optiontext">
                                                        {errorData.treatment_description_error_active}{" "}
                                                    </p>
                                                </p>}
                                            </div>
                                            <div className="input-addicon addicontextarea" >
                                                <textarea
                                                    //placeholder="Character limit"
                                                    maxlength="500"
                                                    value={formData.description}
                                                    id="description"
                                                    name="description"
                                                    onChange={__onTextChange}
                                                    onBlur={checkDescValidation}
                                                    placeholder="Keep your wording relevant to this treatment, you might like to add more details about your level of expertise to make this appealing. If access is difficult for people with disabilities please remember to include this. Parking is a bonus if you have it, so don't forget to mention it!"
                                                    onFocus={(e) => e.target.placeholder = ""}
                                                />
                                                {
                                                    character_count === 500 ?
                                                        <span className="char-span">{character_count} Character limit</span> :
                                                        <span className="char-span">{character_count} Remaining</span>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="ginipig_requist_box">
                                        <h3 className="label_req mb-16">Are you likely to request a GiniPig for this treatment again?</h3>
                                        {errorData.is_keep_treatment_on_profile_error &&
                                            errorData.is_keep_treatment_on_profile_error != "" ? (
                                            <p className="error">
                                                <p style={{ color: "red" }}>
                                                    {errorData.is_keep_treatment_on_profile_error}{" "}
                                                </p>
                                            </p>
                                        ) : null}
                                        <div className="req_grp_btn ">
                                            <button className={formData.is_keep_treatment_on_profile === 1 ? "req_btns active_req" : "req_btns"} onClick={(e) => __isSetTreatmentOnProfile(e, 1)}>Yes, keep this treatment on my profile treatment menu after appointment</button>
                                            <button className={formData.is_keep_treatment_on_profile === 0 ? "req_btns mt-8 active_req" : "req_btns mt-8"} onClick={(e) => __isSetTreatmentOnProfile(e, 0)}>No, remove this treatment from my profile after appointment </button>
                                        </div>
                                    </div>

                                    <div className="btn-align pd32 pt-32">
                                        {
                                            createtreatmentData.isFetching
                                                ? <LargeButton

                                                    disabled={true}
                                                    ButtonText="Loading.."
                                                /> :
                                                <LargeButton
                                                    ButtonType="button"
                                                    ButtonText="Next"
                                                    nextButtonClick={saveAndFinishClick}
                                                    disabled={errorData.title == ""
                                                        || formData.description == "" || formData.description && formData.description.length < 25 || formData.is_keep_treatment_on_profile === ""}
                                                />
                                        }



                                    </div>
                                </form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default TreatmentAddAnotherGigiRequest;