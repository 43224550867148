import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS,AUTH_HEADERS_NEW } from '../../config/ApiConstants';
export function ginipigReviewtoPractAPI_Action(data,token) {
   
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/ginipig/review/booking/practitioner`,
            method: 'POST',
            headers: {
                // ...AUTH_HEADERS,
                ...AUTH_HEADERS,
                'Authorization':'Bearer '+token
            },
             body:JSON.stringify(data),
          //  body: data,
            types: [
                types.GINIPIGREVIEWTOPRACT_REQUEST,
                types.GINIPIGREVIEWTOPRACT_RECEIVE, {
                    type: types.GINIPIGREVIEWTOPRACT_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}


export const resetginipigReviewtoPractAPI_Action = () => {
    return {
        type: types.RESET_GINIPIGREVIEWTOPRACT,
        payload: null
    }
};
