import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS,AUTH_HEADERS_NEW } from '../../config/ApiConstants';
export function checkemailExistsAPI_Action(data) {
    const formData = new FormData(); 
      formData.append('email', data.email);
    //   formData.append('password', data.password);

    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/me/check-email-exist`,
            method: 'POST',
            headers: {
                // ...AUTH_HEADERS,
                ...AUTH_HEADERS_NEW
            },
            // body:JSON.stringify(data),
            body: formData,
            types: [
                types.CHECKEMAILEXISTS_REQUEST,
                types.CHECKEMAILEXISTS_RECEIVE, {
                    type: types.CHECKEMAILEXISTS_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}

export function checkemailExistsEditAPI_Action(data,token) {
    const formData = new FormData(); 
      formData.append('email', data.email);
    //   formData.append('password', data.password);

    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/me/check-email-exist-update`,
            method: 'POST',
            headers: {
                // ...AUTH_HEADERS,
                ...AUTH_HEADERS_NEW,
                'Authorization':'Bearer '+token
            },
            // body:JSON.stringify(data),
            body: formData,
            types: [
                types.CHECKEMAILEXISTS_REQUEST,
                types.CHECKEMAILEXISTS_RECEIVE, {
                    type: types.CHECKEMAILEXISTS_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}

export const resetcheckemailExistsAPI_Action = () => {
    return {
        type: types.RESET_CHECKEMAILEXISTS,
        payload: null
    }
};
