import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS,AUTH_HEADERS_NEW } from '../../config/ApiConstants';
export function PractportfolioaddimageAPI_Action(data,token) {
    //console.log("practionerinfoapi",feature_image)
    const formData = new FormData(); 
      formData.append('feature_image', data.image);
      formData.append('image_title', data.name);
   
    
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/practitioner/portfolio/image/add`,
            method: 'POST',
            headers: {
                ...AUTH_HEADERS_NEW,
                'Authorization':'Bearer '+token
            },
            body: formData,
            types: [
                types.PRACTPORTFOLIOADDIMAGE_REQUEST,
                types.PRACTPORTFOLIOADDIMAGE_RECEIVE, {
                    type: types.PRACTPORTFOLIOADDIMAGE_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}


export const ResetPractportfolioaddimageAPI_Action = () => {
    return {
        type: types.RESET_PRACTPORTFOLIOADDIMAGE,
        payload: null
    }
};
