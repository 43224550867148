import React, { useCallback, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Link } from "react-router-dom"
import { Container, Row, Col } from 'react-bootstrap';
import { LeftArrow } from '../../config/images';
import FromSidebarSiderPact from '../SideBarSlider/FromSidebarSiderPact';
import { resetpractitionerSignupSaveAPI_Action, resetPractitionerAddInfoAPI_Action,practitinerCategoryAPI_Action, resetupcomingbookingAPI_Action, resetStripeAccountValidityAPI_Action } from '../../stores/actions'
import { getLocalStorageData, setLocalStorageData } from '../../utils/storage'


const JoinPractititonerCommunity = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const loginUserData = useSelector(state => state.login);
  const practitionersignupaddinfoData = useSelector(state => state.practitionersignupaddinfo);
  const registerData = useSelector(state => state.register);
  const practitionercatData = useSelector(state => state.practitionercategory);

  // Use effects
  useEffect(() => {
    dispatch(resetpractitionerSignupSaveAPI_Action());
    dispatch(resetPractitionerAddInfoAPI_Action());
    let token = "";
    const loginToken = loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token : "" : "";
    const PractitionerToken = practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.token : "" : "";
    const RegisterToken = registerData.registerdata ? registerData.registerdata.data ? registerData.registerdata.data.token : "" : "";

    if (loginToken != "") {
      token = loginToken;
    } else if (PractitionerToken != "") {
      token = PractitionerToken;
    } else if (RegisterToken != "") {
      token = RegisterToken;
    }
    if (token === "") {
      window.scrollTo(0, 0);
      // history.push('/login');
    }
    if (practitionercatData.practitionercategorydata === null) {
      try {
        dispatch(practitinerCategoryAPI_Action(token));

      } catch (error) {
        console.error(" profile ::  profile() :: Exception ==", error);
      }
    
    }

  }, []);

  const __redirectDev = () => {
    window.scrollTo(0, 0);
    history.push('/join-practitioner')
  }
  const __redirectPractitionInfo = () => {
    window.scrollTo(0, 0);
    history.push('/treatment-info')
  }

  const __redirectPractitionAccount = () => {
    dispatch(resetupcomingbookingAPI_Action());
    window.scrollTo(0, 0);
    history.push('/practitioner-account')
  }

  const __redirectAppointmentList = () => {
    let token = practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.token : "" : "";
    let stripe_link = practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.stripe_connect_link : "" : "";
    dispatch(resetPractitionerAddInfoAPI_Action());
    dispatch(resetStripeAccountValidityAPI_Action());
    window.scrollTo(0, 0);
    setLocalStorageData("RedirectPathName", "join-practitioner")
    history.push({
      pathname: '/register-practitioner-to-stripe',
      state: { 'isRegister': 1, 'token': token, 'stripe_link': stripe_link }
    });

  }

  const __redirectLogout = () => {
    window.scrollTo(0, 0);
    dispatch(resetupcomingbookingAPI_Action());
    history.push('/practitioner-account')
  }
  const __reDirectPage = (e) => {

    let type = "";
    const loginType = loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.user.type : "" : "";
    const PractitionerType = practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.user.type : "" : "";
    const RegisterToken = registerData.registerdata ? registerData.registerdata.data ? registerData.registerdata.data.user.type : "" : "";

    if (loginType != "") {
      type = loginType;
    } else if (PractitionerType != "") {
      type = PractitionerType;
    } else if (RegisterToken != "") {
      type = RegisterToken;
    }
    if (type === 'ginipig') {
      window.scrollTo(0, 0);
      history.push('/logout-confirmation');
    } else if (type === 'practitioner') {
      window.scrollTo(0, 0);
      history.push('/logout-confirmation');
    } else {
      window.scrollTo(0, 0);
      history.push('/signup');
    }

  }
  return (
    <>
      <section className="bgcolor-gray common-section practitionersignup pb80 ">
        <Container>
          <Row>
            <Col className="col-lg-11 m-auto">
              <div className="navigationTop">
                <div className="heading-history">
                  <div className="back-history">
                    <button
                          type="button"
                          className="back-btn"
                          onClick={() => history.goBack()}
                        >
                          {" "}
                          <img src={LeftArrow} alt="arrow" />{" "}
                        </button>
                  </div>
                  <div className="history-title">
                    <h2>Join Us </h2>
                  </div>
                </div>
                <div className="headingbox alignright mobile-hidden">
                  <div className="next-title">
                    <Link
                      // to="/signup" 
                      className="navTopRegister"
                      onClick={() => {
                        __reDirectPage('/signup')
                      }}
                    >
                      Register as a GiniPig
                    </Link>
                    <div className="navTopRightArrow">
                      <img src={LeftArrow} alt="icon" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="practitioner-wrp pt20">
                <div className="practitioner-addfrom practtitionfrom-wrp">
                  <div className="one box">
                    <div className="from-wrp-box">
                      <p className="practitionor-titles mb-16"> Choose your next step </p>

                      <div className="form-wrp fromProfile fromwrp-box mb-70">
                        <p className="practitionor-titles"> Need A GiniPig Now? </p>
                        <button onClick={__redirectAppointmentList} className="practitioner-btns">List an appointment</button>
                      </div>

                      <div className="form-wrp fromProfile fromwrp-box mb-70">
                        <p className="practitionor-titles"> Show Off Your Talent Menu</p>
                        <button onClick={__redirectPractitionInfo} className="practitioner-btns">Add talent to profile</button>
                      </div>

                      <div className="form-wrp fromProfile fromwrp-box mb-70">
                        <p className="practitionor-titles"> Want To Browse? </p>
                        <button onClick={__redirectPractitionAccount} className="practitioner-btns">Explore</button>
                      </div>

                    </div>
                  </div>
                  <div className="box right-slider">
                    <FromSidebarSiderPact />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default JoinPractititonerCommunity;