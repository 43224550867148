import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS,AUTH_HEADERS_NEW } from '../../config/ApiConstants';

export function ginipigSignupSaveData_Action(data) {
    return {
        type: types.GINIPIGSIGNUP_SAVE_DATA,
        ginipigRegisterData: data
    }
}

export const resetginipigSignupSaveAPI_Action = () => {
    return {
        type: types.RESET_GINIPIGRSIGNUP_SAVE_DATA,
        payload: null
    }
};