import React from 'react';
import {Link} from 'react-router-dom';

const ButtonComponent = (props) =>{
  const __reDirectPage = (e) =>{
    window.scrollTo(0, 0);
}
    return (
        <>
          <Link to={props.RedirectLink}   onClick={__reDirectPage} className="common-btns">
                    {props.btnName}
                  </Link>  
        </>
    );
}

export default ButtonComponent;