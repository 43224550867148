import React,{useEffect} from 'react';
import {Link,useHistory} from 'react-router-dom';
import { Container,Row,Col } from 'react-bootstrap';
import {LeftArrow,og_lpimg5} from '../../config/images';
import bannernew3 from '../../assets/images/secondImg.jpg';

import { useDispatch, useSelector } from 'react-redux';
import {   resetpractitionerSignupSaveAPI_Action } from '../../stores/actions'
const BeingPractitioner = () => {
    const dispatch = useDispatch();
    let history = useHistory();

    const loginUserData = useSelector(state => state.login);
    const practitionersignupaddinfoData = useSelector(state => state.practitionersignupaddinfo);
    const registerData = useSelector(state => state.register);
    const Data = JSON.stringify(registerData);

    const __reDirectPage = (e) =>{
       
        let type="";
        const loginType=loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.user.type: "" : "";
        
          const PractitionerType= practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.user.type:"" : "";
          
          const RegisterToken= registerData.registerdata ? registerData.registerdata.data ? registerData.registerdata.data.user.type:"" : "";
          
        if(loginType!=="")
        {
          type=loginType;
        } else if(PractitionerType!== "")
        {
          type=PractitionerType;
        } else if(RegisterToken!=="")
        {
          type=RegisterToken;
        }
        if(type === 'ginipig')
        {    window.scrollTo(0, 0);
            history.push('/logout-confirmation');
        } else if(type === 'practitioner'){
            dispatch(resetpractitionerSignupSaveAPI_Action());
            window.scrollTo(0, 0);
          history.push('/logout-confirmation');
        } else {
            dispatch(resetpractitionerSignupSaveAPI_Action());
            window.scrollTo(0, 0);
            history.push('/signup-practitioner');
        }

    }

    const scrollPgReDirectPage = (e) =>{
        window.scrollTo(0, 0);
    }

    useEffect(() => {
      scrollPgReDirectPage()
    }, [])

    return (
        <>
            <section className="pracitioner" >
                <Container>
                    <Row>
                        <Col className="col-lg-10 m-auto vertical-center">
                        <div className="heading-history">
                <div className="back-history">
                  <button type="button" className="back-btn" onClick={() => history.goBack()}>
                    {" "}
                    <img src={LeftArrow} alt="arrow" />{" "}
                  </button>
                </div>
                
              </div>
              </Col>
                    </Row>
                </Container>

            
              <div className="img-box leftSideImg">
                  <div className="pracitioner-bg imgwidth"  style={{ backgroundImage:`url(" ${bannernew3} ")`}}></div>
                
              <div className="pract_content img-info ">
                <div className="prect_inner">
                    <h3>It’s Simple – You’re Going To Love Us!</h3>
                    <p>
                    Registering as one of our amazing Treatment or Service Providers couldn’t be <b>easier</b>. From listing your initial appointments, to first bookings and collecting amazing reviews in the blink of an eye (framed by <b>perfect lashes</b> of course)! You are your own best canvas, so register with a <b>gorgeous</b> picture of yourself before listing your Treatments or Services and <b>entice</b> your GiniPigs. 
                    </p> 
                    <p>Whether you need GiniPigs for Portfolios, Case Studies, a Training Course or those <b>Insta</b> Pictures we will connect you. We aren’t just for <b>Newbies</b> to the industry, we are for <b>Pros</b> and <b>Stars</b> too, we will connect GiniPigs to everyone from - Academies, Salons, Mobile, Freelance, Home or Online, we have thought of you all. 
                    </p>
                    <p>Did we mention that we can fill last minute appointments for you? As long as your list price to the GiniPig is from £7.50 (and a minimum of 30% off your RRP), go for it! Let’s get filling those appointment slots. </p>
                    <p> 
                    As soon as you’ve received notification by email that your perfect GiniPig would love to have an appointment with you, be quick to confirm their booking, we don’t want them getting away. If you’re <b>lucky</b> enough to have multiple GiniPigs to choose from -  you’re in demand, pick the best GiniPigs for you. We’ll let any others know they weren’t chosen this time and to keep an eye out for more exciting appointments from you.
                    </p>
                    <p>Secure payments! We’ll take care of those, sending you the contact details of your booked GiniPig & forwarding payment to you once we’ve received notification that the appointment is complete.</p>
                    <p>At the end of your appointment we’ll ask you not only to feel proud of yourself, but also to leave your GiniPig a <b>review</b>. They’ll be leaving you one too, so you’ll both reap the rewards. You’ll love how easy it is to get the practice you need with  GiniPigs, so don’t forget to list as many appointments as you can. Plus, you can save your <b>portfolio</b> with us  - making it super simple to show off your <b>skills!</b></p>
                    <h3>You’ll wonder how you ever managed without us!</h3>
                    <div className="btn-align"> 
                        <Link
                        //  to="/signup-practitioner"
                            // onClick={() => {window.scrollTo(0,0)}} 
                            onClick={() => {
                                // __toggleMobileMenu()
                                __reDirectPage('/ginipig-account')
                            }}
                            className="practi-btn-being prac-btn-bg">Find Me GiniPigs</Link>
                    </div>
                </div>
              </div>
            
            </div>

    
            </section>  
        </>
    );
}

export default BeingPractitioner;