import React, { useCallback, useState, useEffect } from "react";
import DoubleConfirmationPopup from '../../components/UIComponent/DoubleConfirmationPopup';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { getLocalStorageData } from '../../utils/storage'
import { acceptbookingAPI_Action, cancelallbookingAPI_Action, practionercancelbookingAPI_Action,resetcancelallbookingAPI_Action, resetpractionercancelbookingAPI_Action, pendingbookingDetailsAPI_Action, upcomingbookingAPI_Action, resetgoginipigprofileAPI_Action, resettreatmentaddreviewAPI_Action } from '../../stores/actions'
import Modal from 'react-modal';
import LoadingSpinner from "../../components/UIComponent/LoadingSpinner"

const customStyles = {
  overlay: {
      background: "#dee2e663" ,zIndex: 999999  },
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    border                 :"none",
    background              :"none"
  }
};

const ConfirmCancelBooking = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const practitionersignupaddinfoData = useSelector(state => state.practitionersignupaddinfo);
  const practitionercancelbookingdata = useSelector(state => state.practitionercancelbookingdata);
  const cancelallbookingdata = useSelector(state => state.cancelallbookingdata);
  
  
  const [modalIsOpen,setIsOpen] = React.useState(false);

  const handleClickYes = (e) => {
    e.preventDefault();
    if (location.cancel === "all") {
      //console.log("locas",location.formDatanew)
      openModal(e);
      __apiCallCancelall(location.formDatanewall)
    } else {

      openModal(e);
      // __apiCallCancel(location.formDatanew)
    }

  }

  const openModal = (e) => {
    e.preventDefault();
    setIsOpen(true);
 }

const closeModal = (item) => {
    setIsOpen(false);
   
}

if( practitionercancelbookingdata.isSuccess===true){
  dispatch(resetpractionercancelbookingAPI_Action());
  closeModal()

  window.scrollTo(0, 0);

  history.push({
    pathname: '/cancel-booking-confirmation',
  });

}

// if (practitionercancelbookingdata.isError === true) {
//   dispatch(resetpractionercancelbookingAPI_Action());
//   closeModal()
// }

// if( cancelallbookingdata.isError===true){
//   dispatch(cancelallbookingAPI_Action());

//   closeModal()

//   window.scrollTo(0, 0);

//   history.push({
//     pathname: '/cancel-booking-confirmation',
//   });

// }




  const __apiCallCancelall = useCallback(async (data) => {
   
    try {

      let checkToken = "";
      let LocalDatatoken = getLocalStorageData("loginData")
      let registerData = getLocalStorageData("practitionersignupData")

      if (registerData != "" && registerData!== undefined && registerData !== null) {
          checkToken = registerData.token;
      }
      else if (LocalDatatoken !== "" && LocalDatatoken !== undefined && LocalDatatoken!==null) {
          checkToken = LocalDatatoken.token;
      }
      dispatch(resetcancelallbookingAPI_Action());
      await dispatch(cancelallbookingAPI_Action(data, checkToken));
      window.scrollTo(0, 0);
      closeModal();
      history.push({
        pathname: '/cancel-booking-confirmation',
      });
     

    } catch (error) {
      console.error(" profile ::  profile() :: Exception ==", error);
    }
  });



  const __apiCallCancel = useCallback(async (data) => {
   // console.log("innside cancel")

    try {

      let checkToken = "";
            let LocalDatatoken = getLocalStorageData("loginData")
            let registerData = getLocalStorageData("practitionersignupData")
    
            if (registerData != "" && registerData!== undefined && registerData !== null) {
                checkToken = registerData.token;
            }
            else if (LocalDatatoken !== "" && LocalDatatoken !== undefined && LocalDatatoken!==null) {
                checkToken = LocalDatatoken.token;
            }
      dispatch(resetpractionercancelbookingAPI_Action());
     await dispatch(practionercancelbookingAPI_Action(data, checkToken));
     
    } catch (error) {
      console.error(" profile ::  profile() :: Exception ==", error);
    }
  });


  return (
    <>
      <div className="cancelbooking_pg pb80">
        <DoubleConfirmationPopup
          breadcrumbsText="Cancel booking"
          subHeadingtext="cancel your booking?"
          noticeText="If you cancel within 36 hours of your booking, 15% amount of treatment will be deducted from your account"
          buttonYesText="Yes, cancel this booking"
          buttonNoText="No, do not cancel this booking"
          handleClick={(e) => handleClickYes(e)}
        />

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          to
          style={customStyles}
          shouldCloseOnOverlayClick={false}
          contentLabel="Example Modal"
        >


          <div>
            <LoadingSpinner />
          </div>

        </Modal>
      </div>
    </>
  );
}

export default ConfirmCancelBooking;