import React,{useState} from 'react';
import {Container,Row,Col,Accordion, Card, useAccordionToggle} from 'react-bootstrap';
import { LeftArrow } from '../../config/images'

const SelectAccordionContent = (props) =>{
    //state init
    const [showText, setShowText] = useState(false);
    // ,(e) =>props.__setSelectedId(e,props.id)
    const _clickTodisabled = (e) =>{
        e.stopPropagation();
        // if(props.disabled === "")
        // {
            props.__setSelectedId(e,props.uuid,props.cat_id)
        // } 
        
    }
    const __itsDisabled = (e) =>{
     
            props.onClick(props.indexValue,props.uuid,props.cat_id)
            
       
        
    }
    return (
        <>
          <Card className={"card-box cards "+props.disabled} >
                <div className={props.indexValue === props.isActive ? "treatment-card-header border_none " :"treatment-card-header "}onClick={__itsDisabled}  >
                    <div className={props.indexValue === props.isActive ? "heading-box heading_bg":"heading-box"}>
                    {/* <div className={"heading-box"}> */}
                        <div className="titles">
                            <span className="title_name"> {props.title} </span>
                            {/* <span className="badge" onClick={(e) =>_clickTodisabled(e)}>{props.selectText}</span> */}
                        </div>
                        <div className="title-icon">  
                            {
                                props.indexValue === props.isActive ? <div className="downarrow"><img src={LeftArrow} /></div> : 
                                <div className="uparrow"><img src={LeftArrow} /></div>  
                            }
                            
                        </div>
                    </div>
                </div>
               
               { props.indexValue === props.isActive && <div className={props.disabled === "" ? "acc-body active-tab" : "acc-body" } >
                    <div className="treatment-body">
                        <div className="treat-content">
                            {props.content}
                        </div>
                        <div className="content-modify ">
                            <div className="modify-list mt-16">
                        {/* <button className="modify_btn treatment-newadd" onClick={(e)=>props.__deleteAction(e,props.id)}> <img src={props.deleteText} className="delete-icon" /> </button> */}
                                <button className="modify_btn treatment-edit" onClick={(e)=>props.__nextAction(e,props.uuid)}>{props.editText}</button>
                            </div>
                        </div>
                    </div>
                </div>
                }
            </Card>  
        </>
    );
}

export default SelectAccordionContent;