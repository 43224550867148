import React from 'react';
import { formatPostcode } from '../utils/utility';

const CloseRequestDetail = (props) => {

    //console.log("props ", props)
    var disable_cancle = false;

    if (props.response_date !== null || props.response_date !== undefined) {
        var today_date = [new Date().getFullYear(), ("0" + (new Date().getMonth() + 1)).slice(-2), ("0" + new Date().getDate()).slice(-2)].join("-")
        if (props.response_date === today_date) {
            if (props.time_hour < new Date().getHours().toString()) {
                disable_cancle = true;
            } else if (props.time_hour === new Date().getHours().toString()) {
                if (props.time_min < new Date().getMinutes().toString()) {
                    disable_cancle = true;
                }
            }
        }
    }
    //console.log("in props ", props.response_date, ' today ', today_date, props.time_hour, ' t ', new Date().getHours(), ' m ', props.time_min , new Date().getMinutes() )

    return (
        <>
            <div className="detail_box white_box">
                <div className="categorylist-box">
                    <div className="list_name">{props.treatmentTitle}</div>
                    {/* {
                        props.practitioner_category_apply !== undefined && props.practitioner_category_apply === "Mobile" ?
                            <div className="category_badge mt-category-badge">
                                <span className="category_badge_name">{props.practitioner_category_apply}</span>
                            </div> : null
                    } */}
                    <div className="category_price">
                        <span className="price_strik og_price"> <span>{props.currencySign}</span>{props.originalPriceText}</span>
                        <span className="price_discounted"><span>{props.currencySign}</span>{props.discountPriceText}</span>
                    </div>
                </div>
                <div className="category_badge">
                    <span className="category_badge_name">{props.tagsName}</span>
                    <span className="category_badge_hrs">{props.hrs > 0 ? props.hrs + "h" : ""} {props.minutes > 0 ? props.minutes + "m" : ""}</span>
                    {/* <span className="category_badge_hrs">{props.duration}</span>    */}
                </div>
                <div className="detail_content mt-8">
                    <p>{props.description}</p>
                </div>
                <div className="datail_option_category mt-16 mb-24">
                    <div className="exp_list_item">
                        <div className="iconwrp_box">
                            <span className="list--icons"><img src={props.categoryIcon1} className="categoryIcon" /></span>
                            <span className=" icon_names">  {props.categorName1} </span>
                        </div>
                        <div className="iconwrp_box">
                            <span className="list--icons"><img src={props.categoryIcon2} className="categoryIcon" /></span>
                            <span className=" icon_names">  {props.categorName2} </span>
                        </div>
                        <div className="iconwrp_box">
                            <span className="list--icons"><img src={props.categoryIcon3} className="categoryIcon" /></span>
                            <span className=" icon_names">  {props.categorName3} </span>
                        </div>
                    </div>
                </div>
                <div className="detail-button-grp">
                    <div className="list-buttons">
                        <div className="detail-btns-grp">
                            <div className="left-align">
                                {
                                    props.request_type === 2 &&
                                    <button className="varity-listbtn italic_btn mr-24"> {props.buttonRequiestTime} </button>
                                }
                                {
                                    props.request_type === 1 &&
                                    <button className="varity-listbtn italic_btn mr-24"> Flexible availability </button>
                                }
                                {
                                    props.buttonReqAddress !== null && props.buttonReqAddress !== undefined ?
                                        <button className="varity-listbtn "> {props.buttonReqAddress !== null && props.buttonReqAddress !== undefined && formatPostcode(props.buttonReqAddress)} </button> :
                                        <button className="varity-listbtn ">Online</button>


                                }



                                {/* <button className="varity-listbtn "> {props.buttonReqAddress!==null && props.buttonReqAddress!==undefined &&  formatPostcode(props.buttonReqAddress)} </button> */}
                            </div>
                            {
                                props.onNextPress && !(props.notshowenquirenowbtn !== undefined && props.notshowenquirenowbtn == true) &&
                                <div className="right-align">
                                    {
                                        props.cancelStatus === '1' ?
                                            props.bookingStatus === 'accepted' || props.bookingStatus === 'pending' ?
                                                <button className="varity-listbtn booking_btn mr0" onClick={props.onNextPress} disabled={disable_cancle}> {props.buttonText} </button> :
                                                null :
                                            <button className="varity-listbtn booking_btn mr0" onClick={props.onNextPress}> {props.buttonText} </button>
                                    }

                                </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CloseRequestDetail;