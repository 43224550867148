import * as types from "../types/index";
import { setInitialState } from "../InitialState";


const INITIAL_STATE = setInitialState({
    ginipigdata: null,
});


export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.GINIPIG_DATA:
            return Object.assign({}, state, {
                ginipigdata: action.ginpigUpdateData
            });
        case types.RESET_GINIPIG_DATA:
            return { ...INITIAL_STATE }
        default:
            return state;

        
    }
}