import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// redux
import { testAPI_Action, resetAPI_Action } from '../../stores/actions'

const Hub = (props) => {
  const dispatch = useDispatch();

  // states
  const testData = useSelector(state => state.test);

  useEffect(() => {
    __apiCall();
  })

  const __apiCall = useCallback(async () => {
    try {
      await dispatch(testAPI_Action());
    } catch (error) {
      console.error(" profile ::  profile() :: Exception ==", error);
    }
  });

  return (
    <div>
      <h4>Hub landing page</h4>
      {
        testData.isFetching ? <button >Loading</button> : <button onClick={__apiCall}>API Call</button>
      }

      <div>
        <code>
          {
            JSON.stringify(testData)
          }
        </code>
      </div>
    </div>
  );
};

export default Hub;