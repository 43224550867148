import * as types from "../types/index";
import { setInitialState } from "../InitialState";


const INITIAL_STATE = setInitialState({
    filterDetails: null,
    searchDetails:null
});


export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.FILTER_DETAILS:
            return Object.assign({}, state, {
                filterDetails: action.filterDetails
            });
        case types.RESET_FILTER_DETAILS:
            return { ...INITIAL_STATE.filterDetails }
        case types.SEARCH_DETAILS:
            return Object.assign({}, state, {
                searchDetails: action.searchDetails
            });
        case types.RESET_SEARCH_DETAILS:
            return { ...INITIAL_STATE.searchDetails }
        default:
            return state;        
    }
}