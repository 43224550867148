import React,{useCallback,useState,useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Container,Row,Col,Tab,Nav,Accordion} from 'react-bootstrap';
import {LeftArrow,reviewAvatar,star,iconPhoto, iconCase_Study,iconNearly_there,iconGiniPigs_Required} from '../../config/images';

import TransparentButton from '../../components/UIComponent/TransparentButton';
import CloseRequestDetail from '../../components/CloseRequestDetail'

import SlickSlider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import UserProfile from '../../components/UserProfile';



const DeclinedBooking = () =>{
    
        const SampleNextArrow = (props) => {
            const { className, style, onClick } = props;
            return (
            <div className={className} style={{ ...style }} onClick={onClick}>
                {" "}
                <img src={LeftArrow} className="arrow_icons" alt="icon" />{" "}
            </div>
            );
        };
        
        const SamplePrevArrow = (props) => {
            const { className, style, onClick } = props;
            return (
            <div className={className} style={{ ...style }} onClick={onClick}>
                {" "}
                <img src={LeftArrow} className="arrow_icons" alt="icon" />{" "}
            </div>
            );
        };
    const settings = {
        dots: true,
        arrows:true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
      };


    let history = useHistory();



    return (
        <>
            <section className="bgcolor-gray common-section practitioner_profile_forginipig declinedbooking_pg pb80">
                <Container>
                    <Row>
                        <Col className="col-lg-11 m-auto">
                            <div className="navigationTop">
                                <div className="heading-history">
                                    <div className="back-history">
                                        <button type="button" onClick={() => history.goBack()} className="back-btn" >
                                            {" "}
                                            <img src={LeftArrow} alt="arrow" />{" "}
                                        </button>
                                    </div>
                                </div> 
                            </div>

                            <div className="practitioner_profile-wrp from-wrp profile_border">
                                <div className="practitioner-profile-img ">
                                    <div className="img_wrp_slider">
                                    <SlickSlider {...settings} >
                                        <div className="img_size profile_img1 ">
                                            <img src={reviewAvatar} />
                                        </div>
                                        <div className="img_size profile_img1 ">
                                            <img src={reviewAvatar} />
                                        </div>
                                        <div className="img_size profile_img1 ">
                                            <img src={reviewAvatar} />
                                        </div>
                                    </SlickSlider>
                                    </div>
                                </div>
                            </div>

                        <div className="details-wrps upcoming_pendding_ap">
                            <div className="close_request_box mt-40 mb-70">
                                <div className="decline-msg_box mb-24">
                                    <div className="white_box msg">
                                        <p>This booking was declined, your payment has been refunded</p>
                                    </div>
                                </div>


                                <CloseRequestDetail 
                                    treatmentTitle="Wash, cut & blow dry"
                                    currencySign="£"
                                    //originalPriceText="25"
                                    //discountPriceText="20"
                                    tagsName="Hair"
                                    duration="2.5 hours"
                                    description="Sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, 
                                    quis nostrud et al exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, 
                                    consectetur et al adipiscing elit, sed do eiusmod. Et al exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. "
                                    categoryIcon1={iconCase_Study}
                                    categorName1="Case Study"
                                    categoryIcon2={iconNearly_there}
                                    categorName2=" Nearly there"
                                />
                            </div>               
                        </div>
                    
                        <div className="details-wrps">
                            <UserProfile />
                        </div>    


                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default DeclinedBooking;