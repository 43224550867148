import * as types from "../types/index";
import { setInitialState } from "../InitialState";


const INITIAL_STATE = setInitialState({
    ginipigregisterdata: null,
});


export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.GINIPIGSIGNUP_SAVE_DATA:
            return Object.assign({}, state, {
                ginipigregisterdata: action.ginipigRegisterData
            });
        case types.RESET_GINIPIGRSIGNUP_SAVE_DATA:
            return { ...INITIAL_STATE }
        default:
            return state;

        
    }
}