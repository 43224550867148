import React from 'react';
import { Route, Switch } from 'react-router-dom';
import App from '../App';

import {
  Hub, Login, WhatsAvailable, Register, ForgotPassword,
  UserProfileUpdate, GinipigProfileUpdate, PractitionerSignup, LandingPage, PractitionerSignupNewProcess, PractitionerPortfolio,
  ProfileChangePassword, SignupConfirmationPopup, GinipigPortfolio, FavouriteLogoutConfirmation, PractitionerThankYouReview,
  Practitioner, AppointmentNotOccurr, ThanksForReviewingGiniPig,
  WhoGgp, Blog, BookingCancellenationConfirmation,
  GiniPigLogout, GiniPigLogoutConfirmation, GinipigAccount,
  ForgotPasswordConfirmation, AcceptOpenRequest, CancelopenRequest,
  PhotoGinipig, PractitionerAboutUs, GinipigProfile, UpcomingPendingAppointment, DeclinedBooking,
  PractitionerReviewGiniPig, LeaveReviewForGiniPig, RequestWithNoResponse, LeaveReviewPastBooking,
  UpcomingPastBooking, CancelBookingConfirmation, CancelBooking, AppointmnetConfirmation, ConfirmCancelBooking, EditGinipigQuantity, RequestDecline, BookingAccepted, ConfirmAppointment,
  BookingDetails, EnquiryDetails, PendingBookRequest, OpenRequest
  , PractitionerSignupAddInfo, JoinPractititonerCommunity, ResetPassword, PractitionerAccount
  , PractitionerInfo, TreatmentInformation, TreatmentAddAnother, TreatmentMenu, TreatmentSelect, PractitionerProfile,
  AppointmentInfo, GiniPigRequirement, AppointmentList, AppointmentReview, AddTreatmentReview, TreatmentContentEdit,
  TreatmentAddAnotherGigiRequest, AppointmentAvailability, DeleteConfirmation, PractitionerProfileForGiniPig, ChangePasswordConfirmation, TreatmentReviewEdit, PractitionerAppointmentlist
  , RegisterPractionertoStripe, PratitionerStripeRegistrationSuccess, TreatmentDetailForCloseRequest, TreatmentDetailForOpenRequest, PhotoRequirement, PaymentOption, Thankyou, ThanksRepayment, EnquiryThankYou, TreatmentAvailability, SelectTimeAcceptBooking,
  PractitionerLogout, ConfirmDeleteRequest, DeleteRequestConfirmation, BlogDetails, Faq, Education, EducationDetail, TermCondition, PrivacyPolicy, ConfirmTreatmnetHappen, PaymentPage, PaymentFailurePage, TimeExpired,
  UpdateProfileConfirmation
} from "../screens";

import { Page404, UnderDevelopment } from '../components';
import AvailableTreatments from '../screens/AvailableTreatments/availableTreatments';

const AppNavigation = (props) => {
  return (
    <Switch>
      <App>
        <Route
          path="/"
          exact
          component={LandingPage} />

        <Route
          path="/Profile-ChangePassword"
          exact
          component={ProfileChangePassword} />

        <Route
          path="/practitioner"
          exact
          component={Practitioner} />

        <Route
          path="/whoggp"
          exact
          component={WhoGgp} />

        <Route
          path="/reset-password"
          exact
          component={ResetPassword} />


        <Route
          path="/ForgotPasswordConfirmation"
          exact
          component={ForgotPasswordConfirmation} />

        <Route
          path="/logout"
          exact
          component={GiniPigLogout} />

        <Route
          path="/ginipig-account"
          exact
          component={GinipigAccount} />

        <Route
          path="/practitioner-account"
          exact
          component={PractitionerAccount} />

        <Route
          path="/logout-confirmation"
          exact
          component={GiniPigLogoutConfirmation} />
        <Route
          path="/favourite-logout-confirmation"
          exact
          component={FavouriteLogoutConfirmation} />

        <Route
          path="/hub"
          exact
          component={Hub} />

        <Route
          path="/blog"
          exact
          component={Blog} />

        <Route
          path="/blog-detail/:id"
          exact
          component={BlogDetails} />

        <Route
          path="/privacy-policy"
          exact
          component={PrivacyPolicy} />


        <Route
          path="/faq"
          exact
          component={Faq} />


        <Route
          path="/login"
          exact
          component={Login} />
        <Route
          path="/whats-available"
          exact
          component={WhatsAvailable} />
        <Route
          path="/signup"
          exact
          component={Register} />

        <Route
          path="/forgot-password"
          exact
          component={ForgotPassword} />

        <Route
          path="/user-details"
          exact
          // component={UserDetails}
          component={UnderDevelopment}
        />

        <Route
          path="/user-edit"
          exact
          component={UserProfileUpdate} />

        <Route
          path="/ginipig-edit"
          exact
          component={GinipigProfileUpdate} />

        <Route
          path="/signup-practitioner1"
          exact
          component={PractitionerSignup} />

        <Route
          path="/signup-practitioner"
          exact
          component={PractitionerSignupNewProcess} />

        <Route
          path="/practitioner-add-info"
          exact
          component={PractitionerSignupAddInfo} />

        <Route
          path="/join-practitioner-community"
          exact
          component={JoinPractititonerCommunity} />


        {/* <Route
          path="/Hair"
          exact
          component={UnderDevelopment} />

        <Route
          path="/Beauty"
          exact
          component={UnderDevelopment} />

        <Route
          path="/Aesthetic"
          exact
          component={UnderDevelopment} />

        <Route
          path="/Wellness"
          exact
          component={UnderDevelopment} /> */}

        <Route
          path="/Education"
          exact
          component={Education} />

        <Route
          path="/education-detail/"
          exact
          component={EducationDetail} />

        <Route
          path="/awesome"
          exact
          component={UnderDevelopment} />

        <Route
          path="/tc"
          exact
          component={TermCondition} />
        <Route
          path="/policy"
          exact
          component={PrivacyPolicy} />

        <Route
          path="/join-practitioner"
          exact
          component={UnderDevelopment} />

        <Route
          path="/signup-confirmation"
          exact
          component={SignupConfirmationPopup} />

        <Route
          // path="/search-result/:formdata/:date/"
          path="/search-result"
          exact
          component={AvailableTreatments} />

        <Route
          path="/view-booking/:bookingid"
          exact
          component={BookingDetails} />

        <Route
          path="/enquiry-detail"
          exact
          component={EnquiryDetails} />

        <Route
          path="/upcoming-pasting-book"
          exact
          component={UpcomingPastBooking} />

        <Route
          path="/payment-option/:id"
          exact
          component={PaymentOption} />

        <Route
          path="/thankyou/:id"
          exact
          component={Thankyou} />

        <Route
          path="/thanks-for-payment/:id"
          exact
          component={ThanksRepayment} />

        <Route
          path="/enquiry-thankyou"
          exact
          component={EnquiryThankYou} />

        <Route
          path="/practitioner-info"
          exact
          component={PractitionerInfo} />

        <Route
          path="/treatment-info"
          exact
          component={TreatmentInformation} />

        <Route
          path="/treatment-availability-open-request"
          exact
          component={TreatmentAvailability} />

        <Route
          path="/treatment-add-another/:id"
          exact
          component={TreatmentAddAnother} />

        <Route
          path="/treatment-Menu"
          exact
          component={TreatmentMenu} />

        <Route
          path="/treatment-detail-for-close-request/:id"
          exact
          component={TreatmentDetailForCloseRequest} />

        <Route
          path="/treatment-detail-for-open-request/:id"
          exact
          component={TreatmentDetailForOpenRequest} />

        <Route
          path="/photo-requirement/:id"
          exact
          component={PhotoRequirement} />

        <Route
          path="/treatment-select"
          exact
          component={TreatmentSelect} />

        <Route
          path="/treatment-content-edit/:treatment_id"
          exact
          component={TreatmentContentEdit} />

        <Route
          path="/treatment-review-edit/:treatment_id"
          exact
          component={TreatmentReviewEdit} />
        <Route
          path="/treatment-ginipig-request"
          exact
          component={TreatmentAddAnotherGigiRequest} />
        <Route
          path="/practitioner-profile"
          exact
          component={PractitionerProfile} />
        <Route
          path="/review-treatment"
          exact
          component={AddTreatmentReview} />
        <Route
          path="/appointment-info"
          exact
          component={AppointmentInfo} />
        <Route
          path="/ginipig-requirement"
          exact
          component={GiniPigRequirement} />
        <Route
          path="/appointment-confirmation"
          exact
          component={AppointmentList} />
        <Route
          path="/appointment-practitoner-confirmation"
          exact
          component={PractitionerAppointmentlist} />
        <Route
          path="/appointment-review"
          exact
          component={AppointmentReview} />
        <Route
          path="/appointment-availability"
          exact
          component={AppointmentAvailability} />
        <Route
          path="/delete-treatment/:id"
          exact
          component={DeleteConfirmation} />

        <Route
          path="/practitioner-profile-ginipig"
          exact
          component={PractitionerProfileForGiniPig} />

        <Route
          path="/change-password-confirmation"
          exact
          component={ChangePasswordConfirmation} />

        <Route
          path="/booking-condition"
          exact
          component={UnderDevelopment} />
        <Route
          path="/open-request"
          exact
          component={OpenRequest} />

        <Route
          path="/practitioner-logout"
          exact
          component={PractitionerLogout} />

        <Route
          path="/pending-booking-request"
          exact
          component={PendingBookRequest} />

        <Route
          path="/accept-open-request"
          exact
          component={AcceptOpenRequest} />

        <Route
          path="/reject-open-request"
          exact
          component={CancelopenRequest} />

        <Route
          path="/confirm-cancel-booking"
          exact
          component={ConfirmCancelBooking} />

        <Route
          path="/cancel-booking"
          exact
          component={CancelBooking} />

        <Route
          path="/confirm-delete-request"
          exact
          component={ConfirmDeleteRequest} />


        <Route
          path="/cancel-booking-confirmation"
          exact
          component={CancelBookingConfirmation} />

        <Route
          path="/appointment-confirmation-donthappen"
          exact
          component={AppointmnetConfirmation} />

        <Route
          path="/delete-request-confirmation"
          exact
          component={DeleteRequestConfirmation} />


        <Route
          path="/edit-ginipig-quantity"
          exact
          component={EditGinipigQuantity} />

        <Route
          path="/request-decline"
          exact
          component={RequestDecline} />

        <Route
          path="/booking-accepted"
          exact
          component={BookingAccepted} />

        <Route
          path="/confirm-appointment"
          exact
          component={ConfirmAppointment} />

        <Route
          path="/ginipig-confirm-appointment"
          exact
          component={ConfirmTreatmnetHappen} />

        <Route
          path="/review-ginipig"
          exact
          component={PractitionerReviewGiniPig} />
        <Route
          path="/leave-review-ginipig"
          exact
          component={LeaveReviewForGiniPig} />
        <Route
          path="/request-no-response"
          exact
          component={RequestWithNoResponse} />
        <Route
          path="/photo-ginipig"
          exact
          component={PhotoGinipig} />
        <Route
          path="/practitioner-aboutUs"
          exact
          component={PractitionerAboutUs} />
        <Route
          path="/ginipig-profile"
          exact
          component={GinipigProfile} />
        <Route
          path="/upcoming-pending-appointment/:id"
          exact
          component={UpcomingPendingAppointment} />
        <Route
          path="/declined-booking"
          exact
          component={DeclinedBooking} />
        <Route
          path="/select-time-accept-booking"
          exact
          component={SelectTimeAcceptBooking} />
        <Route
          path="/leave-review-pastbooking"
          exact
          component={LeaveReviewPastBooking} />
        <Route
          path="/register-practitioner-to-stripe"
          exact
          component={RegisterPractionertoStripe} />
        <Route
          path="/practitioner-registration-success"
          exact
          component={PratitionerStripeRegistrationSuccess} />

        <Route
          path="/payment"
          exact
          component={PaymentPage} />

        <Route
          path="/payment-failure"
          exact
          component={PaymentFailurePage} />

        <Route
          path="/time-expired"
          exact
          component={TimeExpired} />

        <Route
          path="/booking-cancellenation-confirmation/:id"
          exact
          component={BookingCancellenationConfirmation} />

        <Route
          path="/appointment-occurr"
          exact
          component={AppointmentNotOccurr} />

        <Route
          path="/practitioner-portfolio"
          exact
          component={PractitionerPortfolio} />

        <Route
          path="/ginipig-portfolio/:id"
          exact
          component={GinipigPortfolio} />

        <Route
          path="/update-profile-confirmation"
          exact
          component={UpdateProfileConfirmation} />

        <Route
          path="/practitioner-thankyou-review"
          exact
          component={PractitionerThankYouReview} />

        <Route
          path="/thankyou-reviewing-ginipig"
          exact
          component={ThanksForReviewingGiniPig} />
          

      </App>
    </Switch>
  );
};

export default AppNavigation;