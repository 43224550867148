import React,{useState} from 'react';
import {Card} from 'react-bootstrap';
import { LeftArrow } from '../../config/images'

const AccordianContenForProfile = (props) =>{
    //state init
    // const [showText, setShowText] = useState(false);
    // ,(e) =>props.__setSelectedId(e,props.id)
    return (
        <>
          <Card className={"card-box cards "+props.disabled} disabled={props.disabled !== "" ? true :false}>
                <div className= "treatment-card-header" onClick={(e) =>{props.onClick(props.indexValue)}} >
                <div className="heading-box ">
                        <div className="titles">
                            <span className="title_name"> {props.title} </span>
                            <span className="badge">{props.selectText}</span>
                        </div>
                        <div className="title-icon">  
                            {
                                props.indexValue === props.isActive ? <div className="downarrow"><img src={LeftArrow} /></div> : 
                                <div className="uparrow"><img src={LeftArrow} /></div>  
                            }
                            
                        </div>
                    </div>
                </div>
               
               { props.indexValue === props.isActive && <div className="acc-body" >
                    <div className="treatment-body">
                        <div className="treat-content">
                            {props.content}
                        </div>
                        <div className="content-modify ">
                            <div className="modify-list mt-16">
                        <button className="modify_btn treatment-newadd" onClick={(e)=>props.__deleteAction(e,props.uuid)}> <img src={props.deleteText} className="delete-icon" /> </button>
                                <button className="modify_btn treatment-edit" onClick={(e)=>props.__nextAction(e,props.uuid)}>{props.editText}</button>
                            </div>
                        </div>
                    </div>
                </div>
                }
            </Card>  
        </>
    );
}

export default AccordianContenForProfile;