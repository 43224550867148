import React from 'react';
import { practitioner_avtar } from '../config/images';
import { useHistory, Link } from 'react-router-dom';
import { setLocalStorageData,getLocalStorageData } from '../../src/utils/storage';
import { ginipigPractitionerProfileAPI_Action ,resetpractitionerProfileAPI_Action} from '../../src/stores/actions';
import { useDispatch, useSelector } from 'react-redux';

const UserProfile = (props) => {

    let history = useHistory();
    let loginTokenData = getLocalStorageData("loginData")
    const dispatch = useDispatch();

    const gotoginpicprofile = async (e, name) => {
        let catId = 0;
        if (name === "Hair") {
            catId = 1
        } else if (name === "Beauty") {
            catId = 2
        } else if (name === "Aesthetics") {
            catId = 3
        } else if (name === "Wellness") {
            catId = 4
        }
        window.scrollTo(0, 0);
        dispatch(resetpractitionerProfileAPI_Action());
        setLocalStorageData('tabpankey', catId + 1);
        var ginipig_id = 0;
        if (loginTokenData !== undefined) {
            if (loginTokenData.user.type === "ginipig") {
                ginipig_id = loginTokenData.user.id;
            }
        }

       // dispatch(ginipigPractitionerProfileAPI_Action(props.userId, ginipig_id))
        history.push({
            pathname: '/practitioner-profile-ginipig',
            state: { 'paramData': props.userId, 'profile': true, 'categoryId': catId }
        });
    }

    return (
        <>
            <div className="profile-boxs">
                <div className="inside_profile_box inline_row">
                    <div className="leftbox" onClick={(e) => gotoginpicprofile(e)}>
                        <div className="user_boxs">
                            <div className="user-img">
                                <img src={props.userImg === "" ? practitioner_avtar : props.userImg} className="usericon" alts="user-img" />
                            </div>
                            <div className="user_name">
                                <div className="name">
                                    <p>{props.userName} 
                                        
                                    </p>
                                    <span className="ratingbox">
                                            <img src={props.ratingImg} alt="raing icon" />
                                            <span className="rating-point">({props.ratingPoint})</span>
                                        </span>
                                </div>
                                <div className="user_address mt-8">
                                    <p> {props.address} 
                                    { props.companyName && <span className="user-address"> {props.companyName}, </span> }
                                    { props.postCode && <span className="user-address" > {props.postCode} </span> }
                                    { props.city && <span className="user-address"> {props.city} </span> }
                                    </p>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div className="rightbox">
                        <div className="tags_box">
                            <p className="tags_titles"> {props.tagsTitle} </p>
                            <div className="tags-box">
                                {
                                    props.categoryData && props.categoryData.map((key, index) => {
                                        return (
                                            <button className="tagsboxs" onClick={(e) => gotoginpicprofile(e, key.name)}>{key.name}
                                                <span className="tags_count"> ({key.live_treatment})</span>
                                            </button>
                                        )
                                    })
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UserProfile;