import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS } from '../../config/ApiConstants';

export function disabletreatmentpractitionerprofileAPI_Action(id,token) {
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/practitioner/disabled/treatment/profile/${id}`,
            method: 'GET',
            headers: {
                ...AUTH_HEADERS,
                'Authorization': 'Bearer ' + token
            },
         
            //body: JSON.stringify(data),
            body: '',
            types: [
                types.DISABLETREATMENTFORPRACTITIONERPROFILE_REQUEST,
                types.DISABLETREATMENTFORPRACTITIONERPROFILE_RECEIVE, {
                    type: types.DISABLETREATMENTFORPRACTITIONERPROFILE_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}


export const resetdisabletreatmentpractitionerprofileAPI_Action = () => {
    return {
        type: types.RESET_DISABLETREATMENTFORPRACTITIONERPROFILE,
        payload: null
    }
};
