import React, { useCallback,useState, useEffect } from "react";
import { Container, Row, Col,Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LeftArrow, EyeView } from "../../config/images";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import ConfirmationPopup from '../../components/UIComponent/ConfirmationPopup'
// redux
// import { changepasswordAPI_Action,resetchangepasswordAPI_Action,resetupcomingbookingAPI_Action} from '../../stores/actions'

// redux
import {
  resetchangepasswordAPI_Action,
  logoutAPI_Action,
  resetUserAPI_Action,
  resetLoginAPI_Action,
  resetRegisterAPI_Action,
  resetPractitionerAddInfoAPI_Action,
  resettreatmentaddreviewAPI_Action,
  resetPractionerCategory_API_Action,
  resetpractitionerProfileAPI_Action,
  resetgoginipigprofileAPI_Action,
  resetGinipigUpdateAPI_Action,
  resetStripeAccountValidityAPI_Action
} from "../../stores/actions";
import { getLocalStorageData,clearLocalStorage } from '../../utils/storage'


const initialErrorData = Object.freeze({
  password_error:"",
  new_password_error:"",
});
const ChangePasswordConfirmation = () => {

    // Initialize hooks first
  const dispatch = useDispatch();
  let history = useHistory();

  // States Management
  const [formData, updateFormData] = useState(Object.freeze({
    old_password:"",
    new_password:"",
  }));

  let LocalDatatoken=getLocalStorageData("loginData")
    //state define
    const [passwordType, setPasswordType] = useState("password");
    const [eyeIcon, setEyeIcon] = useState(true);
    

    const [errorData, updateErrorData] = useState(initialErrorData);
    const registerData = useSelector(state => state.register);
    const Data = JSON.stringify(registerData);
    const changepasswordData = useSelector(state => state.changepassword);
    const loginUserData = useSelector(state => state.login);
    const practitionersignupaddinfoData = useSelector(state => state.practitionersignupaddinfo);
    // Use effects Similar to componentDidMount and componentDidUpdate:

   useEffect(() => {
    let checkToken="";
    const loginToken=loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token: "" : "";
    const PractitionerToken= practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.token:"" : "";
    const RegisterToken= registerData.registerdata ? registerData.registerdata.data ? registerData.registerdata.data.token:"" : "";
    let LocalDatatoken=getLocalStorageData("loginData")
    if(loginToken != "")
    {
      checkToken=loginToken;
    } else if(PractitionerToken != "")
    {
      checkToken=PractitionerToken;
    }  else if(RegisterToken != "")
    {
      checkToken=RegisterToken;
    } else if (LocalDatatoken !== "") {
      checkToken = LocalDatatoken.token;
    }
    // if(loginUserData.logindata===null)
    if(checkToken == "")
    {
      window.scrollTo(0, 0);
      history.push('/login');
    } 
     dispatch(resetchangepasswordAPI_Action());
    
  }, []);


  const __redirectProfileEdit = () => {
    
    let type="";
    const loginType=loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.user.type: "" : "";
    
      const PractitionerType= practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.user.type:"" : "";
     
      const RegisterToken= registerData.registerdata ? registerData.registerdata.data ? registerData.registerdata.data.user.type:"" : "";
      let LocalDatatoken=getLocalStorageData("loginData")
    if(loginType!="")
    {
      type=loginType;
    } else if(PractitionerType!= "")
    {
      type=PractitionerType;
    } else if(RegisterToken!="")
    {
      type=RegisterToken;
    } else if(LocalDatatoken !="")
    {
      type=LocalDatatoken.user.type
    }
    if(type === 'ginipig')
    {
      window.scrollTo(0,0);
      history.push('/login');
    } else if(type === 'practitioner'){
      window.scrollTo(0,0);
      history.push('/login');
    } else {
      window.scrollTo(0,0);
      history.push('/login');
    }
    // if(type === 'ginipig')
    // {
    //   window.scrollTo(0,0);
    //   history.push('/ginipig-edit');
    // } else if(type === 'practitioner'){
    //   window.scrollTo(0,0);
    //   history.push('/practitioner-account');
    // } else {
    //   window.scrollTo(0,0);
    //   history.push('/login');
    // }
    
  }



  const __logoutUser = async (e) => {
    try {
      let checkToken="";
      const loginToken=loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token: "" : "";
      
      //const PractitionerToken= practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.token:"" : "";
      
      const RegisterToken= registerData.registerdata ? registerData.registerdata.data ? registerData.registerdata.data.token:"" : "";
      

      let  PractitionersignupData = getLocalStorageData("practitionersignupData")
      let PractitionerToken = "";
    
      if (PractitionersignupData != "" && PractitionersignupData!== undefined && PractitionersignupData !== null) {
        PractitionerToken = PractitionersignupData.token;
       }

      if(loginToken != "")
      {
        checkToken=loginToken;
      } else if(PractitionerToken != "")
      {
        checkToken=PractitionerToken;
      }  else if(RegisterToken != "")
      {
        checkToken=RegisterToken;
      } else if (LocalDatatoken !== "") {
        checkToken = LocalDatatoken.token;
    }
      clearLocalStorage("loginData")
      clearLocalStorage("practitionersignupData")
      await dispatch(logoutAPI_Action(checkToken));
      await dispatch(resetLoginAPI_Action());
      await dispatch(resetUserAPI_Action());
      await dispatch(resetRegisterAPI_Action());
      await dispatch(resetPractitionerAddInfoAPI_Action());
      await dispatch(resettreatmentaddreviewAPI_Action());
      await dispatch(resetPractionerCategory_API_Action());
      await dispatch(resetpractitionerProfileAPI_Action());
      await dispatch(resetGinipigUpdateAPI_Action());
      await dispatch(resetgoginipigprofileAPI_Action());
      await dispatch(resetStripeAccountValidityAPI_Action());
      window.scrollTo(0, 0);

      history.push("/login");
    } catch (error) {
      console.error(" profile ::  profile() :: Exception ==", error);
    }
  };




  return (
    <>
      <section className="loginform chngePassword">
        <ConfirmationPopup
            SubHeadingText="Your password has been updated"
            ButtonText="Ok"
            // ButtonText={}
            __redirectNextPage={__logoutUser}
            // __redirectNextPage={__redirectProfileEdit}
        />
      </section>


    </>
  );
};

export default ChangePasswordConfirmation;
