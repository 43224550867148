import React, { useCallback, useState, useEffect } from "react";
import { useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import { LeftArrow, reviewAvatar, iconFav, iconPhoto, star, icon_suitability, icon_reliability, icon_etiquette } from '../../config/images';
import BlogCard from '../../components/BlogCard';
import NavigationBreadcrumbs from '../../components/NavigationBreadcrumbs';
import moment from 'moment'
import ReactPaginate from 'react-paginate';
import { getLocalStorageData, setLocalStorageData, clearLocalStorage } from '../../utils/storage';


//redux 
import { educationlistAPI_Action, resetEducationListAPI_Action } from '../../stores/actions'

const Education = (props) => {
    let history = useHistory();
    let dispatch = useDispatch();

    const [offset, setOffset] = useState(0);
    const [perPage, setPerpage] = useState(3);

    const educationList = useSelector(state => state.educationlist);
    // Use effects
    useEffect(() => {
        let iseducationPage = getLocalStorageData("iseducationPage")
        if(iseducationPage){
            dispatch(educationlistAPI_Action(1));
        }else{
            history.push('/');
        }
       
    }, [])

    // console.log(educationList);

    const __redirectToDetailsPg = (blogId) => {
        window.scrollTo(0, 0);
        history.push('/education-detail/' + blogId);
    }

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        window.scrollTo(0, 0);
        dispatch(educationlistAPI_Action(selectedPage + 1));

        const offset = selectedPage * perPage;

        // setCurrentpage(selectedPage)
        setOffset(offset)

    }

    const backBtnPressed = () => {

        history.goBack();
    }



    return (
        <>

            <section className="bgcolor-gray common-section education_pg pb80">
                <Container>
                    <Row>
                        <Col className="col-lg-11 m-auto">

                            <NavigationBreadcrumbs
                                leftalignText="Education & Courses"
                                onBackClick={() => { backBtnPressed() }}
                            />

                            <div className="boxwrp requirement_wrp mt-24">

                                {
                                    educationList.educationlistdata && educationList.educationlistdata.data && educationList.educationlistdata.data.blogs.map((item, key) => {
                                        let date1 = moment(item.blogPublishDate).format("DD-MMMM-YYYY")

                                        return (
                                            <div className="education-card-item mb-32" key={key}>
                                                <BlogCard
                                                    Img={item.blogImage}
                                                    Heading={item.blogTitle}
                                                    PublishDate={date1}
                                                    Decs={item.blogSummary}
                                                    AuthorName={item.blogAuthor}
                                                    ButtonName="Read more"
                                                    ButtonLink={__redirectToDetailsPg}
                                                    blogId={item.blogId}

                                                />


                                            </div>

                                        )
                                    })
                                }
                                {/* {
                                    educationList.isFetching &&  
                                    <div style={{ width: "100%", fontSize: 20, marginTop: 100, textAlign: 'center' }}>
                                    <p>Loading ...</p>
                                  </div>
                                } */}

                                {
                                educationList.educationlistdata && educationList.educationlistdata.data && educationList.educationlistdata.data.blogs.lenght > 0 ?
                                    
                                <div className="pagination-div common-pagination1">
                                    <ReactPaginate
                                        previousLabel={"prev"}
                                        nextLabel={"next"}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={Math.ceil(educationList.educationlistdata && educationList.educationlistdata.data.blogs_pagination.total / perPage)}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        pageRangeDisplayed={2}
                                        activeClassName={"active"} />
                                </div>
                                : null
                                }


                            </div>

                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default Education;