import React, { useState } from 'react';
import { LeftArrow, icon_whats_availablePNG, reviewAvatar, ginipic_avtar, place_holder } from '../../config/images'
import moment from 'moment';
import { S3_BUCKET_LINK } from '../../config/ApiConstants'

const UpcomingCardBooking = (props) => {
    //console.log(props.bookingArrayprev)
    return (
        <>
            {
                props.bookingArrayprev.length !== 0 && props.bookingArrayprev.map((item, index) => {
                    //console.log(item)
                    return (
                        <div>
                            <div className="upcoming_cards mt-30">
                                <div className="pad16 upside_content border_bottom">
                                    <div className="content_box">
                                        {/* {
                                            item.request_type === 1 &&
                                            <p className="titles"> Flexible availability </p>
                                        }
                                        {
                                            item.request_type === 2 && */}
                                        <p className="titles"> {item.date.toString()} </p>
                                        {/* <p className="titles"> { moment(item.date).format('Do MMMM YYYY')} </p> */}


                                        <p className="curreny_text">£{item.price && item.price.toFixed(2)}</p>
                                    </div>
                                </div>
                                <div className="pad024 downside_content">
                                    <div className="down_content">
                                        <div className="first-half">
                                            <p className="sub_category_txt">{item.sub_cat_array}</p>
                                        </div>
                                        <div className="sec-half">
                                            <div className="username_box">
                                                {
                                                    item.spaces_filled === 1 &&
                                                    <div style={{ display: 'flex' }}>
                                                        <div className="user_avatar">
                                                            {
                                                                item.profile_pic !== null ? item.profile_pic !== "" ? (

                                                                    <img className="img_avtar" src={S3_BUCKET_LINK + item.profile_pic} />

                                                                ) : null : <img src={ginipic_avtar} className="img_avtar" />
                                                            }
                                                        </div>
                                                        <div className="user_text mt-16">
                                                            <span>{item.user_name}</span>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    item.spaces_filled > 1 &&
                                                    <div>
                                                        <p className="available-apoint"> <span>{item.spaces_filled} </span> GiniPigs </p>
                                                    </div>
                                                }

                                            </div>
                                            <div className="confirm_box-wrp">
                                                <button className="confirm_btns" onClick={() => {
                                                    props._PendinCardClick(item.request_id, "confirm", item.bookinglenght, item.spaces_filled, item.request_type)
                                                }}>Awaiting Approval</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    )
                })
            }

            {/* {console.log('next array ', JSON.stringify(props.bookingArraynext))} */}
            {
                props.bookingArraynext.length !== 0 && props.bookingArraynext.map((item, index) => {
                    //
                    return (
                        <div>


                            <div className="upcoming_cards mt-30" onClick={() => {
                                props._PendinCardClick(item.request_id, "pending", item.bookinglenght, item.spaces_filled, item.request_type)
                            }
                            }>
                                <div className="pad16 upside_content border_bottom">
                                    <div className="content_box">
                                        <p className="titles"> {item.sub_cat_array} </p>

                                        {/* <div className="category_badge mt-category-badge">
                                            <span className="category_badge_name">{item.postcode}</span>
                                        </div> */}

                                        {item.status.indexOf("pending") > -1 &&
                                            <div className="badge badgeshow">
                                                <div className="badgebox">
                                                    <span className="badge_img">
                                                        <img src={icon_whats_availablePNG} className="ginipigs_logo" />
                                                    </span>
                                                    <span className="badge_text">pending enquiry</span>
                                                </div>
                                            </div>
                                        }

                                        {item.status.indexOf("cancel") > -1 &&
                                            <div className="badge badgeshow">
                                                <div className="badgebox">
                                                    <span className="badge_img">
                                                        <img src={icon_whats_availablePNG} className="ginipigs_logo" />
                                                    </span>
                                                    <span className="badge_text"> cancellation </span>
                                                </div>
                                            </div>
                                        }
                                        {item.status.indexOf("accepted") > -1 &&
                                            <div className="display-none">

                                            </div>
                                        }

                                        <div className="right_arrow">
                                            <img src={LeftArrow} className="arrow_icon" />
                                        </div>
                                    </div>
                                </div>
                                <div className="pad024 downside_content">
                                    <div className="down_content">
                                        <div className="first-half">
                                            <p className="sub_category_txt"> {item.city} </p>
                                            {
                                                item.request_type === 1 &&
                                                <p className="slot_booking"> Flexible availability </p>
                                            }
                                            {
                                                item.request_type === 2 &&
                                                <p className="slot_booking">{item.date.toString()} </p>
                                            }
                                        </div>
                                        <div className="sec-half">
                                            <div className="space_fill_box">
                                                <button className="spc_fill_btns">{item.spaces_filled}/{item.ginipig_count} spaces filled</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </>
    );
}

export default UpcomingCardBooking;