import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS } from '../../config/ApiConstants';


export function educationlistAPI_Action(page) {
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/education/all?page=`+page,
            method: 'GET',
            headers: {
                ...AUTH_HEADERS,
            },
            body: '',
            types: [
                types.EDUCATIONLIST_REQUEST,
                types.EDUCATIONLIST_RECEIVE, {
                    type: types.EDUCATIONLIST_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}


export const resetEducationListAPI_Action = () => {
    return {
        type: types.RESET_EDUCATIONLIST,
        payload: null
    }
};
