import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { clearLocalStorage, getLocalStorageData } from '../../utils/storage'

import { resetcreatetreatmentAPI_Action, resetcreaterequestAPI_Action, resettreatmentdetailsAPI_Action, resettreatmentaddreviewAPI_Action, resetedittreatmentAPI_Action, createAppointmentData_Action } from '../../stores/actions'

const AppointmentList = () => {
    //state init
    const dispatch = useDispatch();
    let history = useHistory();
    const location = useLocation()


    const [formData, updateFormData] = useState(Object.freeze({
        request_type: "",
        show_postcode: false,
        selected_slots: [],
        selected_slots_morning: [],
        selected_slots_afternoon: [],
        selected_slots_evening: [],


        date: '',
        start_time_hrs: "",
        start_time_minutes: "",
        postcode: "",
        postcodeValue: "",
        country: "",
        city: "",
        latitude: "",
        longitude: "",

        ginipig_count: "",
        sub_category_id: [],
        treatment_sub_category: [],


        selected_time_slots: [],


        attributes_required: false,
        photograph_required: false,
        is_consentform_required: false,
        patchtest_required: false,

        model_requirements: "",
        photograph_requirements: "",
        consent_form_link: "",
        consentformName: "",
        title: "",
        description: "",
        categories: "",
        rrpprice: '',
        yourprice: '',
        file1: "",
        file2: "",
        file3: "",
        file4: "",
        hrs: "",
        minutes: "",
        selectedTreatment: [],
        selectedTreatmentId: "",
        activeclassformdata: "",
        category_id: "",
        reason_require_ginipig: "",
        expertise_level: "",
        imagearray1: [],
        imagearray2: [],
        imagearray3: [],
        imagearray4: [],

        request_image: [],

        date: '',
        start_time_hrs: " ",
        start_time_minutes: " ",
        postcode: "",
        ginipig_count: "",
        request_type: "",
        show_postcode: false,
        ischecked_inperson: false,
        ischecked_online: false,
        subCatStr: "",
        displayDate: "",

        selected_time_slots: [],
    }));



    // Selectors
    const userData = useSelector(state => state.userdetails);
    const loginUserData = useSelector(state => state.login);
    const registerData = useSelector(state => state.register);
    const edittreatmentData = useSelector(state => state.edittreatment);
    const AppointmentCreateData = useSelector(state => state.appointmentcreatedata);
    const practitionersignupaddinfoData = useSelector(state => state.practitionersignupaddinfo);


    // Use effects
    useEffect(() => {
        window.addEventListener('popstate', function (event) {
            __redirectProfilePg()
            window.location.reload(false);
        }, false);
        if (!location.fromreviewreq) {
            __redirectProfilePg()
        }

        try {
            let checkToken = "";
            const loginToken = loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token : "" : "";
            // const PractitionerToken= practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.token:"" : "";
            const RegisterToken = practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.token : "" : "";
            if (loginToken != "") {
                checkToken = loginToken;
            } else if (RegisterToken != "") {
                checkToken = RegisterToken;
            }
            // const loginToken=loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token: "" : "";
            if (checkToken === '') {
                // history.push('/login');
            }
            let requestDataLocal = getLocalStorageData("requestDataLocal")
            //console.log(requestDataLocal.RequestData)
            window.scrollTo(0, 0);
            if (requestDataLocal.RequestData !== null && requestDataLocal.RequestData !== "" && requestDataLocal.RequestData !== undefined) {
                //console.log(AppointmentCreateData.appointmentcreatedata)
                let selectedTimeArray = [];
                requestDataLocal.RequestData && requestDataLocal.RequestData.selected_time_slots.forEach(element => {
                    if (element.selected_slots_morning.length > 0 || element.selected_slots_afternoon.length > 0 || element.selected_slots_evening.length > 0) {
                        selectedTimeArray.push(element)
                    }
                })
                let subCatArray = []
                requestDataLocal.RequestData && requestDataLocal.RequestData.treatment_sub_category.forEach(element => {


                    subCatArray.push(element.name)

                })
                var str;
                if (subCatArray.length === 1) {
                    str = subCatArray.join()
                } else if (subCatArray.length === 2) {

                    str = subCatArray.join(" & ")
                } else if (subCatArray.length === 3) {
                    str = subCatArray[0] + "," + subCatArray[1] + " & " + subCatArray[2]
                }

                let newDate = ""
                let displayDate = requestDataLocal.RequestData.date

                //console.log("date", displayDate)

                if (displayDate !== null && displayDate !== undefined) {
                    let displayDaynew = displayDate.substring(0, 2)
                    let displayYearNew = displayDate.substring(6, 10)

                    let displayDay = ""

                    if (displayDaynew < 10) {
                        displayDay = displayDaynew.substring(1, 2)
                    } else {
                        displayDay = displayDaynew
                    }
                    let n = displayDate.substring(3, 5)
                    let newn = ""
                    if (n < 10) {
                        newn = n.substring(1, 2)
                    } else {
                        newn = n
                    }

                    var month = new Array();
                    month[1] = "January";
                    month[2] = "February";
                    month[3] = "March";
                    month[4] = "April";
                    month[5] = "May";
                    month[6] = "June";
                    month[7] = "July";
                    month[8] = "August";
                    month[9] = "September";
                    month[10] = "October";
                    month[11] = "November";
                    month[12] = "December";
                    var displaymonth = month[newn];

                    if (displayDay == 1) {
                        newDate = displayDay + "st" + " " + displaymonth + " " + displayYearNew
                    } else if (displayDay == 2) {
                        newDate = displayDay + "nd" + " " + displaymonth + " " + displayYearNew
                    } else if (displayDay == 3) {
                        newDate = displayDay + "rd" + " " + displaymonth + " " + displayYearNew
                    } else {
                        newDate = displayDay + "th" + " " + displaymonth + " " + displayYearNew
                    }
                }



                let displaydate = newDate + " @ " + requestDataLocal.RequestData.start_time_hrs + ":" + requestDataLocal.RequestData.start_time_minutes


                let postcode = requestDataLocal.RequestData.postcode
                let postcodeValue = requestDataLocal.RequestData.postcodeValue
                var postcodenew = ""
                if (postcode !== null) {
                    var lastThree = postcode.substr(postcode.length - 3);
                    var first = postcode.substr(0, postcode.length - 3);
                    postcodenew = first + " " + lastThree
                } else if (postcodeValue !== null) {
                    var lastThree = postcodeValue.substr(postcodeValue.length - 3);
                    var first = postcodeValue.substr(0, postcodeValue.length - 3);
                    postcodenew = first + " " + lastThree
                } else {
                    postcodenew = ""
                }


                let rrp = requestDataLocal.RequestData.rrpprice && parseInt(requestDataLocal.RequestData.rrpprice)
                //console.log(rrp)
                let newrrp = (rrp).toFixed(2)


                updateFormData({
                    ...formData,
                    selectedTreatmentId: requestDataLocal.RequestData ? requestDataLocal.RequestData.selectedTreatmentId : "",
                    subCatStr: str,
                    displayDate: displaydate,
                    rrpprice: newrrp,
                    yourprice: requestDataLocal.RequestData ? requestDataLocal.RequestData.yourprice : "",

                    hrs: requestDataLocal.RequestData ? requestDataLocal.RequestData.hrs : 0,

                    minutes: requestDataLocal.RequestData ? requestDataLocal.RequestData.minutes : "",

                    activeclassformdata: requestDataLocal.RequestData ? requestDataLocal.RequestData.activeclassformdata : "",

                    reason_require_ginipig: requestDataLocal.RequestData ? requestDataLocal.RequestData.reason_require_ginipig : "",
                    expertise_level: requestDataLocal.RequestData ? requestDataLocal.RequestData.expertise_level : "",

                    request_image: requestDataLocal.RequestData ? requestDataLocal.RequestData.request_image : "",


                    date: requestDataLocal.RequestData ? requestDataLocal.RequestData.date : "",
                    start_time_hrs: requestDataLocal.RequestData ? requestDataLocal.RequestData.start_time_hrs : 0,
                    start_time_minutes: requestDataLocal.RequestData ? requestDataLocal.RequestData.start_time_minutes : 0,
                    postcode: postcodenew,

                    postcodeValue: requestDataLocal.RequestData ? requestDataLocal.RequestData.postcodeValue : "",
                    country: requestDataLocal.RequestData ? requestDataLocal.RequestData.country : "",
                    city: requestDataLocal.RequestData ? requestDataLocal.RequestData.city : "",
                    latitude: requestDataLocal.RequestData ? requestDataLocal.RequestData.latitude : "",
                    longitude: requestDataLocal.RequestData ? requestDataLocal.RequestData.longitude : "",
                    locationprofile: requestDataLocal.RequestData.locationprofile ? requestDataLocal.RequestData.locationprofile : "",
                    use_profile_location: requestDataLocal.RequestData.use_profile_location ? requestDataLocal.RequestData.use_profile_location : 1,
                    ginipig_count: requestDataLocal.RequestData ? requestDataLocal.RequestData.ginipig_count : "",
                    request_type: requestDataLocal.RequestData ? requestDataLocal.RequestData.request_type : "",
                    show_postcode: requestDataLocal.RequestData ? requestDataLocal.RequestData.show_postcode : "",
                    ischecked_online: requestDataLocal.RequestData.ischecked_online ? requestDataLocal.RequestData.ischecked_online : false,
                    ischecked_inperson: requestDataLocal.RequestData.ischecked_inperson ? requestDataLocal.RequestData.ischecked_inperson : false,

                    selected_time_slots: selectedTimeArray,

                    category_id: requestDataLocal.RequestData ? requestDataLocal.RequestData.category_id : "",
                    category_name: requestDataLocal.RequestData ? requestDataLocal.RequestData.name : "",
                    sub_category_id: requestDataLocal.RequestData ? requestDataLocal.RequestData.sub_category_id : "",
                    title: requestDataLocal.RequestData ? requestDataLocal.RequestData.title : "",
                    description: requestDataLocal.RequestData ? requestDataLocal.RequestData.description : "",
                    categories: requestDataLocal.RequestData ? requestDataLocal.RequestData.categories : "",
                    treatment_sub_category: requestDataLocal.RequestData ? requestDataLocal.RequestData.treatment_sub_category : []

                });

            }



        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }

    }, [])

    const __redirectProfilePg = () => {
        dispatch(resetedittreatmentAPI_Action());
        dispatch(createAppointmentData_Action());
        //  clearLocalStorage("requestDataLocal")
        dispatch(resetcreatetreatmentAPI_Action());

        dispatch(resettreatmentdetailsAPI_Action());
        dispatch(resetcreaterequestAPI_Action());
        window.scrollTo(0, 0);
        window.removeEventListener('popstate', function (event) {
            // history.go(1);
        }, false);
        history.push({
            pathname: '/practitioner-profile',
            iswindowreload: true

        });

    }

    return (
        <>
            <section className="bgcolor-gray common-section appointment_confimation_pg  pb80">
                <Container>
                    <Row>
                        <Col className="col-lg-11 m-auto">
                            <div className="from-wrp mt-32">
                                <div className="appoint_conf_box pb-32 pt-70">
                                    <div className="heading_content">
                                        {/* <h2 className="title ">Your appointment has been listed</h2>
                                        <p className="decs mb-32 mt-24">
                                            You will notified when a GiniPig books this appointment, you will then be
required to accept or decline their booking within 16 hours but we would encourage you to do it ASAP</p> */}
                                        <h2 className="title "> Your Appointment Has Been Listed </h2>
                                        <p className="decs mt-24">When a GiniPig books this appointment an e-mail will be sent, you will then have a maximum of 16 hours to accept them.</p>
                                        <p className="decs mb-32">We don't want you to miss this opportunity, time is money, so we encourage you to hurry before they change their mind!</p>
                                    </div>
                                </div>

                                <div className="form-wrp mb-24">
                                    <div className="inside_content">
                                        <div className="categorylist-box">
                                            <div className="list_name">
                                                {/* {
                                                formData.treatment_sub_category.map((item,index)=>{
                                                    console.log(item)
                                                    return( */}

                                                <span>{formData.title}</span>

                                                {/* )
                                                })
                                            } */}
                                            </div>

                                            <div className="category_price">
                                                <span className="price_strik og_price"> <span>£</span>{formData.rrpprice}</span>
                                                <span className="price_discounted"><span>£</span>{formData.yourprice}</span>
                                            </div>
                                        </div>
                                        <div className="category_badge">
                                            <span className="category_badge_name">{formData.categories.name}</span>

                                            <span className="category_badge_hrs">{formData.hrs > 0 ? formData.hrs + "h" : ""} {formData.minutes > 0 ? formData.minutes + "m" : ""}</span>
                                        </div>

                                        <div className="list-experiment mb-24">
                                            <div className="exp_list_item">
                                                <span className="icons_case icon_names">{formData.reason_require_ginipig} </span>
                                                <span className="icons_near icon_names"> {formData.expertise_level}  </span>
                                                {
                                                    formData.ginipig_count === "1" ?
                                                        <span className="icons_gini icon_names">1 place left </span> :
                                                        <span className="icons_gini icon_names">{formData.ginipig_count} places left </span>
                                                }
                                            </div>
                                        </div>

                                        <div className="list-buttons">
                                            {
                                                formData.request_type === 1 &&
                                                <button className="varity-listbtn italic_btn"> Flexible availability </button>
                                            }
                                            {
                                                formData.request_type === 2 && formData.displayDate !== "" &&
                                                <button className="varity-listbtn italic_btn">{formData.displayDate} </button>
                                            }

                                            {formData.ischecked_online ?
                                                <button className="varity-listbtn mt-16"> Online </button> :
                                                <button className="varity-listbtn mt-16"> {formData.postcode}</button>

                                            }


                                            {/* <button className="varity-listbtn mt-16"> {formData.postcode}, {formData.city} {formData.locationprofile}</button> */}

                                        </div>

                                    </div>
                                </div>

                                <div className="treatment btn-another text-center pt-110 pb-32 ">
                                    <button onClick={__redirectProfilePg} className="addtreatmentbtns"> Back to Profile </button>
                                </div>

                            </div>
                        </Col>
                    </Row>

                </Container>
            </section>
        </>
    );
}

export default AppointmentList;