import React, { useCallback, useState, useEffect } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import { LeftArrow, star, iconCase_Study, iconNearly_there, practitioner_avtar, iconPhoto, reviewAvatar } from '../../config/images';
import CloseRequestAcceptCancelCard from "../../components/UIComponent/CloseRequestAcceptCancelCard";
import RequestBookingCard from "../../components/UIComponent/RequestBookingCard";
import { getLocalStorageData, clearLocalStorage } from '../../utils/storage'
import { resetacceptbookingAPI_Action, resetpendingbookingDetailsAPI_Action, resetLoginAPI_Action, resetpractitionerrequestdetailsAPI_Action, practitionerrequestDetailsAPI_Action, resetupcomingbookingAPI_Action, } from '../../stores/actions'
import LoadingSpinner from "../../components/UIComponent/LoadingSpinner"
import moment from 'moment';
import {get24HrsTimeFormat}from '../../utils/utility'

const BookingAccepted = (props) => {


    let history = useHistory();
    const [locationKeys, setLocationKeys] = useState([])
    const dispatch = useDispatch();
    const location = useLocation();
    const [requestArray, setrequestArray] = useState([]);
    const [subcategory, setsubcategory] = useState("");
    const [bookingDate, setbookingDate] = useState();
    const [profilepic, setprofilepic] = useState();
    const [bookingdata, SetbookingData] = useState()
    const [accetptbookingdata, Setaccetptbookingdata] = useState()

    const pendingbookingDetails = useSelector(state => state.pendingbookingdetails);
    const loginUserData = useSelector(state => state.login);
    const practitionersignupaddinfoData = useSelector(state => state.practitionersignupaddinfo);
    const acceptBooking = useSelector(state => state.acceptBooking);
    const practitionerrequestDetailsdata = useSelector(state => state.practitionerrequestdetailsdata);

    useEffect(() => {
        window.addEventListener('popstate', function(event) {        
            __redirectProfilenew()
             window.location.reload(false);
        }, false);


        let bookingdata = getLocalStorageData("acceptBooking")

        let accetptbookingdata = getLocalStorageData("accetptbookingdata")
        if(!location.fromacceptbooking){
           __redirectProfile()
        }else{
        Setaccetptbookingdata(accetptbookingdata)
        SetbookingData(bookingdata)
        dispatch(resetacceptbookingAPI_Action());
        clearLocalStorage("acceptBooking")
        clearLocalStorage('calenderobj')
        clearLocalStorage('photoObj')

        let checkToken = "";
        let LocalDatatoken = getLocalStorageData("loginData")
        let registerData = getLocalStorageData("practitionersignupData")
        //  console.log(LocalDatatoken)

        if (registerData != "" && registerData !== undefined && registerData !== null) {
            checkToken = registerData.token;
        }
        else if (LocalDatatoken !== "" && LocalDatatoken !== undefined && LocalDatatoken !== null) {
            checkToken = LocalDatatoken.token;
        }

        let closerequestdata = getLocalStorageData("closerequestdata")
        if (closerequestdata !== undefined && closerequestdata.request_type === 2) {
            api_callNew(closerequestdata.requestId, checkToken);
        } else {
            let openrequestdata = getLocalStorageData("openrequestdata")
            api_callNew(openrequestdata.requestId, checkToken);
        }
    }

    }, [])


   
    const api_callNew = async (requestId, checkToken) => {

        dispatch(resetacceptbookingAPI_Action())
        await dispatch(practitionerrequestDetailsAPI_Action(requestId, checkToken));

    }

    useEffect(() => {


        try {
            //   console.log( practitionerrequestDetailsdata.practitionerrequestdetailsdata && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data)
            let requestArray = practitionerrequestDetailsdata.practitionerrequestdetailsdata!==null &&practitionerrequestDetailsdata.practitionerrequestdetailsdata.data!==undefined && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.request
            if (requestArray !== null) {
                let subCatArray = []
                requestArray.treatment.treatment_sub_category_new.forEach(element => {
                    subCatArray.push(element.name)

                })

                var str;
                if (subCatArray.length === 1) {
                    str = subCatArray.join()
                } else if (subCatArray.length === 2) {

                    str = subCatArray.join(" & ")
                } else if (subCatArray.length === 3) {
                    str = subCatArray[0] + "," + subCatArray[1] + " & " + subCatArray[2]
                }
                let newDate = ""
                let displayDate = requestArray.request_timeslots[0].date_time
            
                if (displayDate !== null && displayDate !== undefined) {
                    let day = displayDate.substring(8, 10);
                    let displayDay = ""
                    if (day < 9) {
                        displayDay = day.substring(1, 2)
                    } else {
                        displayDay = day
                    }

                    let n = displayDate.substring(5, 7)
                    // console.log(n)
                    let newn = ""
                    if (n < 10) {
                        newn = n.substring(1, 2)
                    } else {
                        newn = n
                    }

                    var month = new Array();
                    month[1] = "January";
                    month[2] = "February";
                    month[3] = "March";
                    month[4] = "April";
                    month[5] = "May";
                    month[6] = "June";
                    month[7] = "July";
                    month[8] = "August";
                    month[9] = "September";
                    month[10] = "October";
                    month[11] = "November";
                    month[12] = "December";

                    var displaymonth = month[newn];

                    if (displayDay == 1) {
                        newDate = displayDay + "st" + " " + displaymonth + " "
                    } else if (displayDay == 2) {
                        newDate = displayDay + "nd" + " " + displaymonth + " "
                    } else if (displayDay == 3) {
                        newDate = displayDay + "rd" + " " + displaymonth + " "
                    } else {
                        newDate = displayDay + "th" + " " + displaymonth + " "
                    }
                }

                let period = ""
                let hours = ''
                let [sHours, minutes] = ""
                // if (requestArray.request_timeslots[0].start_time !== null) {
                //     [sHours, minutes] = requestArray.request_timeslots[0].start_time && requestArray.request_timeslots[0].start_time.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
                //     period = +sHours < 12 ? 'AM' : 'PM';
                //     hours = +sHours % 12 || 12;
                // }

          
                let time = ""
                let set_date1=""

                if (requestArray.type === 2) {
                    time = requestArray.request_timeslots[0].start_time
                    set_date1 = moment(requestArray.request_timeslots[0].date_time).format("Do MMMM YYYY");


                } else {
                    //  time = bookingdata && bookingdata.booking.time
                    time = accetptbookingdata.acceptenquirydata && accetptbookingdata.acceptenquirydata.data.booking.time
                     set_date1 = moment(accetptbookingdata.acceptenquirydata && accetptbookingdata.acceptenquirydata.data.booking.date).format("Do MMMM YYYY");
                    //console.log('time---',time)
                }

              

                //let time = requestArray.request_timeslots[0].start_time
                // if (requestData.requestType  === 1) {
                //     time = pendingbookingDetails.pendingbookingdetailsdata && pendingbookingDetails.pendingbookingdetailsdata.data && pendingbookingDetails.pendingbookingdetailsdata.data.booking.time
                // } else {
                //     time = requestArray.request_timeslots[0].start_time
                // }

                if (time !== null) {
                    [sHours, minutes] = time && time.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
                    period = +sHours < 12 ? 'AM' : 'PM';
                    hours = +sHours % 12 || 12;
                }

                let timein24hrs= get24HrsTimeFormat(time)


                let displaydate = set_date1 + " @ " + timein24hrs
                //let displaydate = newDate + new Date(displayDate).getFullYear()

                setbookingDate(displaydate)
                setsubcategory(requestArray.treatment.title)
            }

            setrequestArray(requestArray)

        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    }, [practitionerrequestDetailsdata])


    const __redirectProfile =async () => {
        dispatch(resetupcomingbookingAPI_Action());
        dispatch(resetpendingbookingDetailsAPI_Action());
        dispatch(resetacceptbookingAPI_Action());
        window.scrollTo(0, 0);
        window.removeEventListener('popstate', function(event) {        
           // history.go(1);
        }, false);
        history.push({
            pathname: '/practitioner-account',
            iswindowreload:false

        });
    }
    const __redirectProfilenew =async () => {
        dispatch(resetupcomingbookingAPI_Action());
        dispatch(resetpendingbookingDetailsAPI_Action());
        dispatch(resetacceptbookingAPI_Action());
        window.scrollTo(0, 0);
        window.removeEventListener('popstate', function(event) {        
           // history.go(1);
        }, false);
        history.push({
            pathname: '/practitioner-account',
            iswindowreload:true

        });
    }

    if (practitionerrequestDetailsdata.practitionerrequestdetailsdata && practitionerrequestDetailsdata.practitionerrequestdetailsdata.status_code === 401) {
        dispatch(resetacceptbookingAPI_Action())
        dispatch(resetpractitionerrequestdetailsAPI_Action())

        dispatch(resetLoginAPI_Action())
        clearLocalStorage("loginData")
        clearLocalStorage("practitionersignupData")
        clearLocalStorage("RedirectPathName")
        clearLocalStorage("requestDataLocal")
        clearLocalStorage("confirmData")
        clearLocalStorage("openrequestdata")
        clearLocalStorage("closerequestdata")
        clearLocalStorage("noresponseData")
        window.scrollTo(0, 0);
        history.push('/login');
    }


    return (
        <>
            <section className="bgcolor-gray common-section request_accept_pg pb80">
                {practitionerrequestDetailsdata.practitionerrequestdetailsdata === null ?
                    <div style={{ width: "100%", marginTop: 250, textAlign: 'center' }}>
                        <LoadingSpinner />
                        <p style={{ fontSize: 20, marginTop: 10 }}>Loading ...</p>
                    </div>
                    :
                    <Container>
                        <Row>
                            <Col className="col-lg-11 m-auto">

                                <div className="booking-accepted mt-70">
                                    <h3 className="decline-heading mb-8" > Appointment Confirmed </h3>
                                    <div className="request_card mb-16">
                                        <RequestBookingCard
                                            treatmentTitle={subcategory}
                                            tagsName={requestArray && requestArray.treatment && requestArray.treatment.categories && requestArray.treatment.categories.name && requestArray.treatment.categories.name}
                                            hrs={requestArray && requestArray.hours}
                                            minutes={requestArray && requestArray.minutes}

                                            // duration={requestArray && requestArray.hours + "h" + " "+ requestArray.minutes + "m"}
                                            originalPriceText={requestArray && requestArray.rrp_price && requestArray.rrp_price.toFixed(2)}
                                            discountPrice={requestArray && requestArray.your_price && requestArray.your_price.toFixed(2)}
                                            addressText={requestArray && requestArray.postcodes && requestArray.postcodes.city && requestArray.postcodes.city}
                                            slotTime={bookingDate}
                                            categoryIcon1={iconNearly_there}
                                            categorName1={requestArray && requestArray.expertise_level}
                                            categoryIcon2={iconCase_Study}
                                            categorName2={requestArray && requestArray.reason_require_ginipig}
                                        />
                                    </div>


                                </div>

                                <div className="decline_boxs reason_box mt-40">
                                    {/* <h3 className="reason_title mb-16"> Your GiniPig has been advised that your booking has been accepted - Good luck! </h3> */}
                                    <h3 className="reason_title mb-16"> Please check your e-mail for the GiniPig contact details, yours have been sent on to them too. Good Luck! </h3>
                                    <div className="decline_review mt-16 mb-16">
                                        <CloseRequestAcceptCancelCard
                                            userImg={bookingdata && bookingdata.user.profile_pic !== '' ? bookingdata.user.profile_pic : practitioner_avtar}
                                            userName={bookingdata && bookingdata.user.name}
                                            ratingImg={star}
                                            showImagearrow={false}
                                            reliabilityCount={bookingdata && bookingdata.user && bookingdata.user.reliabilityAverage === 0 ? 0 : Math.ceil(bookingdata && bookingdata.user && bookingdata.user.reliabilityAverage)}
                                            etiquetteCount={bookingdata && bookingdata.user && bookingdata.user.etiquetteAverage === 0 ? 0 : Math.ceil(bookingdata && bookingdata.user && bookingdata.user.etiquetteAverage)}
                                            //suitabilityCount={bookingdata && bookingdata.user && bookingdata.user.suitabilityAverage === 0 ? 0 : Math.ceil(bookingdata && bookingdata.user && bookingdata.user.suitabilityAverage)}

                                            ratingPoint={bookingdata && bookingdata.user.review_rating_ginipig}
                                        />
                                    </div>

                                </div>
                                <div className="treatment btn-another text-center pt-110 pb-32 ">
                                    <button className="addtreatmentbtns" onClick={__redirectProfile}> My Account </button>
                                </div>

                            </Col>
                        </Row>
                    </Container>

                }
            </section>
        </>
    );
}

export default BookingAccepted;