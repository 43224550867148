import React from 'react';
import { useHistory } from 'react-router-dom';
import { LeftArrow, star } from '../config/images';

const NavigationBreadcrumbs = (props) => {
    let history = useHistory();
   
    return (
        <>
            <div className="navigationTop padright-0">
                <div className="heading-history">
                    <div className="back-history">
                        <button type="button" onClick={props.onBackClick} className="back-btn" >
                            {" "}
                            <img src={LeftArrow} alt="arrow" />{" "}
                        </button>
                    </div>
                    {
                        props.leftalignText &&
                        <div className="history-title">
                            <h2> {props.leftalignText}  </h2>
                        </div>
                    }

                </div>
            </div>
        </>
    );
}

export default NavigationBreadcrumbs;