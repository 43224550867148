import * as types from "../types/index";
import { setInitialState } from "../InitialState";


const INITIAL_STATE = setInitialState({
    isFetching : false,
    isSuccess : false,
    isError: false,
    requestdetailsdata: null,
    bookingCondition:null,
    photoDetails:null,
    filterDetails:null,
    treatmentDetails:null
});


export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.REQUESTDETAILS_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case types.REQUESTDETAILS_RECEIVE:
            return {
                ...state,
                isFetching: false,
                isSuccess: true,
                requestdetailsdata: action.payload.success
            };
        case types.REQUESTDETAILS_FAILURE:
            return {
                ...state,
                isFetching: false,
                isError: true,
                requestdetailsdata: action.payload.response.error,
            };
        case types.RESET_REQUESTDETAILS:
            return {
                ...state,
                isFetching: false,
                isError: false,
                requestdetailsdata: null,
            };
        case types.BOOKINGCONDITION_SAVE_DATA:
            return Object.assign({}, state, {
                bookingCondition: action.bookingCondition
            });
        case types.RESET_BOOKINGCONDITION_SAVE_DATA:
            return { ...INITIAL_STATE.bookingCondition }

        case types.PHOTO_DETAILS:
            return Object.assign({}, state, {
                photoDetails: action.photoDetails
            });
        case types.RESET_PHOTO_DETAILS:
            return { ...INITIAL_STATE.photoDetails }
        case types.TREATMENT_DETAILS:
                return Object.assign({},state,{
                    treatmentDetails : action.treatmentDetails
                });
        case types.RESET_TREATMENT_DETAILS:
            return {...INITIAL_STATE.treatmentDetails}
        default:
            return {
                ...state
            }
    }
}