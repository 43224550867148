import * as types from "../types/index";
import { setInitialState } from "../InitialState";


const INITIAL_STATE = setInitialState({
    upcomingbookingdata: null,
});


export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.UPCOMINGBOOKING_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case types.UPCOMINGBOOKING_RECEIVE:
            return {
                ...state,
                isFetching: false,
                isSuccess: true,
                upcomingbookingdata: action.payload.success
            };
        case types.UPCOMINGBOOKING_FAILURE:
            return {
                ...state,
                isFetching: false,
                isError: true,
                upcomingbookingdata: action.payload.response.error,
            };
        case types.RESET_UPCOMINGBOOKING:
            return {
                ...state,
                isFetching: false,
                isError: false,
                isSuccess: false,
                upcomingbookingdata: null,
            };
        default:
            return {
                ...state
            }
    }
}