import React,{useEffect} from 'react';

import {Container,Row,Col} from 'react-bootstrap';
import { LeftArrow} from "../../config/images";
import { useHistory,Link } from "react-router-dom";

import {resetForgotPasswordAPI_Action } from "../../stores/actions";
import { useDispatch } from 'react-redux';



const ForgotPasswordConfirmation = (props) => {

    let dispatch = useDispatch();
    let history = useHistory();
    const __navigateToLogin = (e) => {
      window.scrollTo(0, 0);
        history.push("/login")
      }


      // Use effects
  useEffect(() => {
     dispatch(resetForgotPasswordAPI_Action());
  },[]);

    return (
        <>
            <section className="loginform">
    <Container>
      <Row>
        <Col className="col-md-10 m-auto">
          <div className="heading-history">
            <div className="back-history">
              <button onClick={() => history.goBack()} type="button" className="back-btn">
                {" "}
                <img src={LeftArrow} alt="arrow" />{" "}
              </button>
            </div>
            <div className="history-title">
              <h2>We have reset your password</h2>
            </div>
          </div>
            <div className="desciption">
                <p className="subdesc"> Please follow the instructions in the email provided to gain access to your account and reset your password. </p>
                <button onClick={__navigateToLogin} className="btns-common mt-24 mb-24">Log in now</button>  
            </div>
          <div className="login-box">
                <div className="inside-text">
                    <p>Not got your email? Please check your spam or junk folders, if not please check your email and try again.</p>
                    <Link to="/forgot-password" className="trybtns">Try again</Link>
                </div>
          </div>
            </Col>
          </Row>
        </Container>
      </section>

        </>
    );
}

export default ForgotPasswordConfirmation;