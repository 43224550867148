import React from 'react';

const TransparentButton = (props) => {
    return (
        <>
        <button 
         type={props.ButtonType} 
         disabled={props.disabled}
         onClick={props.ButtonClick}
         className="transparent-btns" >
            {props.ButtonText} 
        </button>  
        </>
    );
}

export default TransparentButton;