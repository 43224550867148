import React from "react";
import  SliderContent  from './SliderContent';

import SlickSlider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { LeftArrow,gp1,gp2,gp3,gp4, } from "../../config/images";

const FromSideBarSlider = () => {
  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div className={className} style={{ ...style }} onClick={onClick}>
        {" "}
        <img src={LeftArrow} alt="icon" />{" "}
      </div>
    );
  };

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div className={className} style={{ ...style }} onClick={onClick}>
        {" "}
        <img src={LeftArrow} alt="icon" />{" "}
      </div>
    );
  };

  const settings = {
    dots: true,
    arrow: true,
    speed: 500,
    autoplay:true,
    autoplaySpeed:5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <>
      <div className="workSlider-wrp">
        <SlickSlider {...settings}>
          <SliderContent 
           image={gp1}
          title="Spoil Yourself"
           description="Balyage, Bikram, Botox, Brows & everything in between, what will your treat be?"/>
          <SliderContent 
           image={gp2}
          title="Find Luxury Treatments"
           description="Let us inspire you with the latest treatments"/>
          <SliderContent  
          image={gp3}
          title="From Only £7.50!"
           description="No more envying those celebrities or friends"/>
          <SliderContent  
          image={gp4}
          title="Support Future Stars"
           description="Who knows, you could be a GiniPig for the next Big Star"/> 
         
        </SlickSlider>
      </div>
    </>
  );
};

export default FromSideBarSlider;
