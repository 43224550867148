import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS } from '../../config/ApiConstants';
export function editpractitionerporfolioAPI_Action(data, token) {
    const formData = new FormData(); 
    formData.append('id', data.imageId);
    formData.append('image_title', data.name);

    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/practitioner/portfolio/image/title/update`,
            method: 'POST',
            headers: {

                ...AUTH_HEADERS,
                'Authorization': 'Bearer ' + token
            },
         
            body: JSON.stringify(data),
            types: [
                types.EDITPRACTITIONERPORTFOLIO_REQUEST,
                types.EDITPRACTITIONERPORTFOLIO_RECEIVE, {
                    type: types.EDITPRACTITIONERPORTFOLIO_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}


export const reseteditpractitionerporfolioAPI_Action = () => {
    return {
        type: types.RESET_EDITPRACTITIONERPORTFOLIO,
        payload: null
    }
};
