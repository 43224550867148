
import React, { useCallback, useState, useEffect } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from 'react-bootstrap';
import { LeftArrow, star, iconCase_Study, iconNearly_there, icon_unselected_star } from '../../config/images';
import RequestBookingCard from "../../components/UIComponent/RequestBookingCard";
import CloseRequestAcceptCancelCard from "../../components/UIComponent/CloseRequestAcceptCancelCard";
import RatingPointCard from "../../components/UIComponent/RatingPointCard";
import LargeButton from '../../components/LargeButton';
import { getLocalStorageData, clearLocalStorage } from '../../utils/storage'
import { resetpractitionerReviewtoGiniAPI_Action,practitionerReviewtoGiniAPI_Action,resetupcomingbookingAPI_Action, upcomingbookingAPI_Action,resetLoginAPI_Action } from '../../stores/actions'

const LeaveReviewForGiniPig = (props) => {

    let history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const [requestArray, setrequestArray] = useState([]);
    const [subcategory, setsubcategory] = useState("");
    const [bookingDate, setbookingDate] = useState();
    const [bookingData, setbookingData] = useState();
    const [reliability, setReliability] = useState(0);
    const [etiquette, setEtiquette] = useState(0);
    const [suitability, setSuitability] = useState(0);
    const [totalrating, setTotalrating] = useState(0);
    const [errormsg, setErrorMsg] = useState();
    
    let loginTokenData = getLocalStorageData("loginData");

    const practitionerreviewgini = useSelector(state => state.practitionerreviewgini);
    useEffect(() => {

        try {
            let checkToken = "";
            let LocalDatatoken = getLocalStorageData("loginData")
            let registerData = getLocalStorageData("practitionersignupData")

            if (registerData !== "" && registerData !== undefined && registerData !== null) {
                checkToken = registerData.token;
            }
            else if (LocalDatatoken !== "" && LocalDatatoken !== undefined && LocalDatatoken !== null) {
                checkToken = LocalDatatoken.token;
            }
            if (checkToken === '') {
                window.scrollTo(0, 0);
                history.push('/login');
            }


            if (checkToken !== '') {
                dispatch(upcomingbookingAPI_Action(checkToken));


            } else {
                window.scrollTo(0, 0);
                history.push('/login');
            }

            let requestData = getLocalStorageData("requestreviewData")
            let bookingData = getLocalStorageData("bookingreviewData") 
            setbookingData(bookingData)

            if(bookingData===undefined){
                __redirectProfilenew()
            }

            let requestArray = []
            requestArray = requestData;

            if (requestArray !== null) {
                let subCatArray = []
                requestArray.treatment.treatment_sub_category_new.forEach(element => {
                    subCatArray.push(element.name)

                })

                var str;
                if (subCatArray.length === 1) {
                    str = subCatArray.join()
                } else if (subCatArray.length === 2) {

                    str = subCatArray.join(" & ")
                } else if (subCatArray.length === 3) {
                    str = subCatArray[0] + "," + subCatArray[1] + " & " + subCatArray[2]
                }
                let newDate = ""
                let displayDate = requestArray.request_timeslots[0].date_time


                if (displayDate !== null && displayDate !== undefined) {
                    let day = displayDate.substring(8, 10);
                    let displayDay = ""
                    if (day < 9) {
                        displayDay = day.substring(1, 2)
                    } else {
                        displayDay = day
                    }

                    let n = displayDate.substring(5, 7)
                    // console.log(n)
                    let newn = ""
                    if (n < 10) {
                        newn = n.substring(1, 2)
                    } else {
                        newn = n
                    }

                    var month = new Array();
                    month[1] = "January";
                    month[2] = "February";
                    month[3] = "March";
                    month[4] = "April";
                    month[5] = "May";
                    month[6] = "June";
                    month[7] = "July";
                    month[8] = "August";
                    month[9] = "September";
                    month[10] = "October";
                    month[11] = "November";
                    month[12] = "December";

                    var displaymonth = month[newn];

                    if (displayDay == 1) {
                        newDate = displayDay + "st" + " " + displaymonth + " "
                    } else if (displayDay == 2) {
                        newDate = displayDay + "nd" + " " + displaymonth + " "
                    } else if (displayDay == 3) {
                        newDate = displayDay + "rd" + " " + displaymonth + " "
                    } else {
                        newDate = displayDay + "th" + " " + displaymonth + " "
                    }
                }


                
                let period = ""
                let hours = ''
                let [sHours, minutes] = ""
                //let time = requestArray.request_timeslots[0].start_time

                let time = ""
                
                if (requestArray.type === 1) {
                    time = requestArray.bookings[0].time

                } else {
                    time = requestArray.request_timeslots[0].start_time
                }

                if (time !== null) {
                    [sHours, minutes] = time && time.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
                    period = +sHours < 12 ? 'AM' : 'PM';
                    hours = +sHours % 12 || 12;
                }

                let timein24hrs=""
                if (time !== null && time!==undefined) {
                     timein24hrs=time.slice(0,5)
                }
                
                
                let displaydate = newDate +  new Date().getFullYear()  + " @ " + timein24hrs

                setbookingDate(displaydate)
                setsubcategory(requestArray.treatment.title)

            }

            setrequestArray(requestArray)

        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    }, [])

    const __redirectProfilenew =async () => {
        window.scrollTo(0, 0);
        dispatch(resetupcomingbookingAPI_Action());
        dispatch(resetpractitionerReviewtoGiniAPI_Action());
         history.push({
            pathname: '/practitioner-account',
            iswindowreload:true

        });
    }


    const handleChangeReliability = (rating) => {
        setReliability(rating)
        let totalrating = (rating + etiquette) /2

        var roundedrating = (Math.round(totalrating * 10) / 10).toFixed(1)
        setTotalrating(roundedrating)

    }

    const handleChangeSuitability = (rating, name) => {

        setSuitability(rating)
        let totalrating = (rating + reliability + etiquette) / 2
        var roundedrating = (Math.round(totalrating * 10) / 10).toFixed(1)
        setTotalrating(roundedrating)
    }


    const handleChangeRatingEthiq = (rating, name) => {
        setEtiquette(rating)

        let totalrating = (rating  + reliability) / 2
        var roundedrating = (Math.round(totalrating * 10) / 10).toFixed(1)
        setTotalrating(roundedrating)
    }


    const ClickSubmitButton = () => {
        let msg='';
        let formDatanew = {
            ginipig_id: bookingData && bookingData.user && bookingData.user.uuid,
            booking_id: bookingData && bookingData.uuid,
            reliability: reliability,
            etiquette: etiquette,
            //suitability: suitability,
            overall: totalrating,
        }
        if(totalrating > 0){
            // alert("rating point")
            __apiCall(formDatanew)
        }else{
            // alert("not workng")
            setErrorMsg("Please select a star to rate each category")
        }
        
        
        
    }


    const __apiCall = useCallback(async (formDatanew) => {
        dispatch(resetpractitionerReviewtoGiniAPI_Action());

        if (loginTokenData) {
            if (loginTokenData != null || loginTokenData != undefined) {
                try {
                    await dispatch(practitionerReviewtoGiniAPI_Action(formDatanew, loginTokenData.token));
                } catch (error) {
                    console.error(" profile ::  profile() :: Exception ==", error);
                }
            }
        }
    });


    if (practitionerreviewgini && practitionerreviewgini.practitionerreviewginidata !== null && practitionerreviewgini.isSuccess === true && practitionerreviewgini.practitionerreviewginidata.message === "Review for ginipig is added successfully.") {
        window.scrollTo(0, 0);
        history.push({
            pathname: '/thankyou-reviewing-ginipig',
            isfromreview:true

        });
    }else if(practitionerreviewgini && practitionerreviewgini.practitionerreviewginidata !== null && practitionerreviewgini.practitionerreviewginidata.status_code === 401){

        clearLocalStorage("loginData")
        clearLocalStorage("practitionersignupData")
        dispatch(resetLoginAPI_Action());
        dispatch(resetpractitionerReviewtoGiniAPI_Action());
        window.scrollTo(0, 0);
        history.push('/login');
    }

    //console.log(bookingData)

    return (
        <>
            <section className="bgcolor-gray common-section practition_leave_review_ginipig_pg pb80">
                <Container>
                    <Row>
                        <Col className="col-lg-11 m-auto">
                            <div className="navigationTop padright-0">
                                <div className="heading-history">
                                    <div className="back-history">
                                        <button type="button" onClick={() => history.goBack()} className="back-btn" >
                                            {" "}
                                            <img src={LeftArrow} alt="arrow" />{" "}
                                        </button>
                                    </div>
                                    <div className="history-title">
                                        <h2> Leave A Review  </h2>
                                    </div>
                                </div>
                            </div>

                            <div className="requirement_wrp padright-0">
                                <div className="request_card mb-16">
                                    <RequestBookingCard
                                        treatmentTitle={subcategory}
                                        tagsName={requestArray && requestArray.treatment && requestArray.treatment.categories && requestArray.treatment.categories.name && requestArray.treatment.categories.name}
                                        hrs={requestArray && requestArray.hours}
                                        minutes={requestArray && requestArray.minutes}
                                    
                                        // duration={requestArray && requestArray.hours + "h" + " " + requestArray.minutes + "m"}
                                        originalPriceText={requestArray && requestArray.rrp_price && requestArray.rrp_price.toFixed(2)}
                                        discountPrice={requestArray &&  requestArray.your_price && requestArray.your_price.toFixed(2)}
                                        addressText={requestArray && requestArray.postcodes && requestArray.postcodes.city && requestArray.postcodes.city}
                                        slotTime={bookingDate}
                                        categoryIcon1={iconCase_Study}
                                        categorName1={requestArray && requestArray.expertise_level}
                                        categoryIcon2={iconNearly_there}
                                        categorName2={requestArray && requestArray.reason_require_ginipig}
                                    />
                                </div>


                                <div className="all_request_box confirm_appointment_box mt-40">
                                    <div className="request_boxs pending mb-40">
                                        <div className="confirm-list mb-16">
                                            <CloseRequestAcceptCancelCard
                                                userImg={bookingData && bookingData.user && bookingData.user.profile_pic}
                                                userName={bookingData && bookingData.user && bookingData.user.name}
                                                imgArrow={LeftArrow}
                                                ratingImg={star}
                                                showImagearrow={false}
                                                ratingPoint={bookingData && bookingData.user && bookingData.user.review_rating_ginipig}
                                                // AcceptButtonText="Leave Review"
                                                //acceptButtonClick={(e) => acceptButtonClick(e, item)}
                                                reliabilityCount={bookingData && bookingData.user && bookingData.user.reliabilityAverage === 0 ? 0 : Math.ceil(bookingData && bookingData.user && bookingData.user.reliabilityAverage)}
                                                etiquetteCount={bookingData && bookingData.user && bookingData.user.etiquetteAverage === 0 ? 0 : Math.ceil(bookingData && bookingData.user && bookingData.user.etiquetteAverage)}
                                                //suitabilityCount={bookingData && bookingData.user && bookingData.user.suitabilityAverage === 0 ? 0 : Math.ceil(bookingData && bookingData.user && bookingData.user.suitabilityAverage)}

                                            />
                                        </div>
                                    </div>

                                </div>

                                <h3 className="rating-heading mb-8">Leaving a rating for each category</h3>
                                
                                <div className="d-flexbox">
                                    <div className="first-div">
                                        <RatingPointCard
                                            ratingName="Reliability"
                                            handleChangeRating={handleChangeReliability}
                                            selectedimage={star}
                                            initialRating={reliability}
                                            unselectedimage={icon_unselected_star}
                                        />
                                        <RatingPointCard
                                            ratingName="Friendliness"
                                            handleChangeRating={handleChangeRatingEthiq}
                                            selectedimage={star}
                                            initialRating={etiquette}
                                            unselectedimage={icon_unselected_star}
                                        />
                                        {/* <RatingPointCard
                                            ratingName="Suitability"
                                            handleChangeRating={handleChangeSuitability}
                                            selectedimage={star}
                                            initialRating={suitability}
                                            unselectedimage={icon_unselected_star}
                                        /> */}
                                    </div>
                                    <div className="second-div">
                                        <div className="rating_points">
                                            <img src={star} className="stars" />
                                            <p className="points">{totalrating}</p>
                                        </div>
                                    </div>
                                </div>
                                <p className="error" style={{color:'red'}}>{errormsg}</p>


                                <div className="btn-align mt-24 pd32">
                                   
                                    <LargeButton
                                        ButtonType="button"
                                        ButtonText="Submit review"
                                        nextButtonClick={ClickSubmitButton}
                                    />
                                </div>


                            </div>

                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default LeaveReviewForGiniPig;