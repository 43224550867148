
import React,{useState} from 'react';
import { Card} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { LeftArrow,icon_whats_availablePNG } from '../../config/images'

const AccordionContentForPractitioner = (props) =>{
    //state init
    // const [showText, setShowText] = useState(false);
    
     
    return (
        <>
          <Card className={"card-box cards " + props.disabled} >
                <div className="treatment-card-header" onClick={(e) =>{props.onClick(props.indexValue)}} >
                    <div className="heading-box">
                        <div className="titles">
                            <span className="title_name"> {props.title} </span>
                                <div>
                                    <div >
                                
                                    <span className="badge_text">{props.selectText}</span>
                                    </div>
                                </div>
                        </div>
                        <div className="title-icon">
                            <div className="category_price mr-30">
                                <span className="price_strik og_price"> <span>{props.currencySign}</span>{props.originalPriceText}</span>
                                <span className="price_discounted"><span>{props.currencySign}</span>{props.discountPriceText}</span>
                            </div>  
                            {
                                props.indexValue === props.isActive ? <div className="downarrow"><img src={LeftArrow} /></div> : 
                                <div className="uparrow"><img src={LeftArrow} /></div>  
                            }
                            
                        </div>
                    </div>
                </div>
               
               { props.indexValue === props.isActive && <div className={props.disabled === "" ? "acc-body active-tab" : "acc-body" } >
                    <div className="treatment-body ginipig-profile-body">
                        <div className="treat-content">
                           <p> {props.content} </p>
                           {/* <div className="readmore-div mt-8">
                                <Link to="/" className="read_moretext">more info</Link>
                                <img src={LeftArrow} className="readmore_arrow" />
                            </div> */}
                            <div className="list-contentbox mb-16">
                                <div className="exp_list_item">
                                    <span className="icons_case icon_names"> {props.expertise_level} </span>     
                                    <span className="icons_near icon_names"> {props.reason_require_ginipig} </span>  
                                    {
                                        props.ginipig_count===1 ?
                                        <span className="icons_gini icon_names"> {props.ginipig_count} place left </span>:
                                        <span className="icons_gini icon_names"> {props.ginipig_count} places left </span>

                                    }   
                                        
                                </div>
                            </div>
                            <div className="list_button_box list-buttons">
                            <button className="varity-listbtn italic_btn">{props.hours>0? props.hours+"h":""} {props.minutes>0?props.minutes+"m":""}</button>
                            {props.postcode!==null && props.city!==null?
                                    <button className="varity-listbtn "> {props.postcode}, {props.city} </button>:
                                    <button className="varity-listbtn "> Online </button>
                            }
                               
                            </div>
                        </div>
                        
                    </div>
                </div>
                }
            </Card>  
        </>
    );
}

export default AccordionContentForPractitioner;