import * as types from "../types/index";
import { setInitialState } from "../InitialState";


const INITIAL_STATE = setInitialState({
    deletepractportfoliodata: null,
});


export default function (state = INITIAL_STATE, action) {
   // console.log(action.payload)
    switch (action.type) {
        case types.DELETEPRACTPORTFOLIO_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case types.DELETEPRACTPORTFOLIO_RECEIVE:
            return {
                ...state,
                isFetching: false,
                isSuccess: true,
                deletepractportfoliodata: action.payload
            };
        case types.DELETEPRACTPORTFOLIO_FAILURE:
            return {
                ...state,
                isFetching: false,
                isError: true,
                
                deletepractportfoliodata: action.payload,
            };
        case types.RESET_DELETEPRACTPORTFOLIO:
            return {
                ...state,
                isFetching: false,
                isError: false,
                isSuccess: false,
                deletepractportfoliodata: null,
            };
        default:
            return {
                ...state
            }
    }
}