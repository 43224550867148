import React from 'react';
import { iconDelete_peach } from '../config/images';
import LoadingSpinner from "../components/UIComponent/LoadingSpinner"

const ImageUpload = (props) => {

    return (
        <>
            <div className="img_upload_area">
                <div className="upload-area">
                    {
                        props.File !== "" ?
                            <>
                                <img src={props.File} className={!props.setEdit ? "form-img1" : ""} ></img>
                                {
                                    !props.setEdit ?
                                        <img src={iconDelete_peach} className="remove-img" onClick={props.RemoveImg} />
                                        : null
                                }
                            </> : null
                    }
                    {
                        props.showloading ?
                            <div className="loader-position" style={{textAlign:'center'}}>
                                <LoadingSpinner />
                            </div>
                            : null
                    }
                    <div className="media-btns uploadbtn-postion">
                        <input
                            type="file"
                            id="profile_pic"
                            name="profile_pic"
                            onChange={props.handleFileChange}
                            onClick={props.onClick}
                        />
                        {
                            !props.setEdit ?
                                <span className="upload-profile"> Edit</span> :
                                <span className="upload-profile"> Upload</span>

                        }

                    </div>
                </div>
            </div>
        </>
    );
}

export default ImageUpload;