
import React, { useCallback, useState, useEffect } from "react";
import { useHistory, useLocation, Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import { LeftArrow, star, iconCase_Study, iconNearly_there, iconPhoto, reviewAvatar } from '../../config/images';

import NavigationBreadcrumbs from '../../components/NavigationBreadcrumbs';

const CancellationPolicy = (props) => {

    let history = useHistory();
    const backBtnPressed = () => {
        history.goBack();
    }
    const __reDirectPage = (e) => {
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        __reDirectPage()
    }, [])

    return (
        <>
            <section className="bgcolor-gray common-section open_req_booking_pg pb80">
                <Container>
                    <Row>
                        <Col className="col-lg-11 m-auto">
                            <NavigationBreadcrumbs
                                leftalignText="HELP"
                                onBackClick={() => { backBtnPressed() }}

                            />

                            <div className="requirement_wrp padright-0 mt-24">
                                <div className="white_wrp">
                                    <div className="content-box faq_content">
                                        <h3 className="heading ">Everything that you would love to know but can’t be bothered to read the T’s & C’s – (we hear you, they can be dull)!</h3>
                                        <div className="list-wrp-faq mt-16">
                                            <h3 className="heading">What is a GiniPig</h3>
                                            <p>Noun ~ Singular – A Person traditionally known as a model and allows Treatment or Service Providers to Practice their skills on.  We affectionately call these GiniPigs. </p>
                                            <h3 className="heading"> What does GoGiniPig do? </h3>
                                            <p>We are a one stop platform that introduces Treatment & Service Providers in the Hair, Aesthetics, Beauty & Wellness Industries to willing GiniPigs. The professional is able to hone their skills, add to their portfolio and get new clients through their door, while the GiniPig enjoys some luxury for a fraction of the RRP (always at least 30% off).</p>
                                            <h3 className="heading"> Is GoGiniPig an app and will I receive notifications/reminders? </h3>
                                            <p>We are not an app, but we are an interactive digital platform, the only big difference is that you don’t need to download us (we don’t want to take up your space).  You will receive notifications, mostly   email, or sometimes text, we encourage you to add to your calendar. We will send you a reminder the night before your appointment.  Please check your e-mails as this is where the majority of reminders will go and don’t forget to check your spam, we don’t want us getting lost in there with all our important information.
                                            </p>
                                            <h3 className="heading"> Which industries do you cover? </h3>
                                            <p>We are covering Hair, Aesthetics, Beauty & Wellness.  So, whether you are looking for Balyage, Botox, Brows, Yoga, Tattoos, Personal Training, Nutrition & Mindfulness or a Relaxing Massage, we have something for everyone. We are hoping to add more industries soon.
                                            </p>
                                            <h3 className="heading"> Are you Nationwide? </h3>
                                            <p>We have GiniPigs and Treatment & Service Providers throughout the UK including Northern Ireland – we currently accept payment in ££’s and we hope to have appointments available to you in your location ASAP.  As we are a new company, please bear with us if there isn’t a provider in your area yet, there soon will be! Keep your eyes peeled. Our handy little filter allows you to search the closest Treatment & Service Providers to you.
                                            </p>
                                            <h3 className="heading"> I would like to register as a Treatment or Service Provider & GiniPig too, is this possible? </h3>
                                            <p>
                                                It’s definitely possible, although currently, you will need to register with 2 different emails as we are unable to have Providers and GiniPigs on the same account, hopefully soon!  Don’t worry though, the mobiles can be the same and are unaffected by this.
                                            </p>
                                        </div>

                                        <h3 className="heading mb-16">GINIPIGS</h3>
                                        <h3 className="heading mb-24">BEFORE APPOINTMENT</h3>

                                        <div className="list-wrp-faq">
                                            <h3 className="heading">How old do I need to be to become a GiniPig?</h3>
                                            <p>All GiniPigs, Treatment or Service Providers, must be at least 18 years old and, we don’t put a limit on ages, all ages deserve to be pampered and, look amazing.  However, some Treatment Providers may specify a certain age range for a particular look that they want to recreate or skin type etc but this will be stated within their listing.</p>

                                            <h3 className="heading">Do I need to pay GoGiniPig to join?</h3>
                                            <p>We are a free service for you lovely GiniPigs! You only pay the Treatment Providers the price stated on their listing, we don’t charge you to register with us.
                                            </p>
                                            <h3 className="heading">How do I start finding Treatments & Services?</h3>
                                            <p>Register with us and upload a recent and dazzling picture of yourself, make sure it still looks like you.  Providers will then know who to expect and can see that you are the perfect match for their criteria.  Search for Appointments, using our clever little filters that do a big job in finding you the best treatments based on location, price, reviews and availability.</p>
                                            <p>After pressing the Book or Enquire now buttons on the appointment that you are interested in and your chosen date & time(s),  a payment is secured (but not actually taken, like a hotel booking reservation).  Our fabulous Providers then have 16 hours to contact you to confirm that you are a perfect match (if it’s a last-minute appointment – they will get back to you much quicker). Once confirmed your payment preference is taken and the appointment details sent onto you.</p>
                                            <h3 className="heading">I am trying to book – why do some listings say Book & others say Enquire?</h3>
                                            <p>If the Treatment/Service Provider has multiple appointments for the same service – we have an enquire functionality so that you can both be on the same page and commit to the same date/time.  You specify your interest by clicking the Enquire now button and selecting  the date  – you can only choose one date but multiple time options if the Provider has them.  You add your payment details and as soon as the appointment has been confirmed by them – either your deposit or full payment is then taken (whichever you have chosen to be best for you – or full payment if the appointment is less then 36 hours away).</p>
                                            <h3 className="heading">What can I expect in an Online appointment?</h3>
                                            <p>This could be something like an online nutrition course, fitness class or a block of personal training sessions – there could be many other exciting things too.  We encourage our Providers to be transparent and to include as much information as possible, so that you are clear whether it’s only you attending online as a one off or attending with others on a regular basis.  The desired online method that the Provider uses to hold the class will be emailed to you by the Provider once they have accepted your request and your deposit has been processed.
                                            </p>

                                            <h3 className="heading"> Can I select a Treatment or Service Provider to come to my home? </h3>
                                            <p>This is not something that GoGiniPig currently offer, if the demand is sufficient then this is something that we will consider so watch this space.

                                            </p>

                                            <h3 className="heading"> Can I choose my Provider? </h3>
                                            <p>We encourage our Treatment & Service Providers to write as much detail in their profile so that you know if they are suitable for you. You can choose them and hopefully you will be compatible. They will let you know once you’ve submitted your enquiry.  Please allow up to 16 hours for this.

                                            </p>
                                            <h3 className="heading"> A Provider has declined me, why? </h3>
                                            <p>We understand it can feel disappointing but don’t take it personally.  Our Treatment & Service Providers may have specific criteria to meet for training or images, or it may be that someone else just beat you to it.  There will be others that you will be perfect for, we have no doubt!

                                            </p>
                                            <h3 className="heading"> I struggle with stairs/access, how will I know if this is suitable for me? </h3>
                                            <p>We encourage our Providers to put as much detail as possible and this includes access. Hopefully this will help you to choose the most suitable for you.

                                            </p>
                                            <h3 className="heading"> Can I book Treatments or Services in advance? </h3>
                                            <p>Absolutely, up to 3 months in advance to enable you to be super organised and have something lovely to look forward to.
                                            </p>
                                            <h3 className="heading"> Do you have last minute appointments? </h3>
                                            <p>It’s always worth checking back, as you are able to book up to 2.5 hours before any appointment time.  Keep looking and you may bag that last-minute deal. You will need to be quick though, you GiniPigs are savvy and love these fabulously priced luxuries!</p>
                                            <h3 className="heading"> I would like to book more than one Appointment, is this possible? </h3>
                                            <p>You can book as many as you would like, as long as you’re committed to turning up and pay your deposit, then this is fabulous with us.

                                            </p>
                                            <h3 className="heading">Are all the Appointments for Training?</h3>
                                            <p>No not at all, it will be made clear within Appointment listing and, will state whether you are needed for Training, Case Studies or Portfolios. The Provider could be adding a new treatment to their menu or filling a last-minute cancellation with a fully qualified professional. You will always be aware what level of expertise to expect and will be getting a hugely discounted treatment with a possible future star in the industry, who knows (how amazing would that be)!

                                            </p>
                                            <h3 className="heading">What stage of training will my Treatment or Service Provider be at & what do the Competency levels mean?</h3>
                                            <p>We encourage our providers to tell you within their listing what stage they are at.</p>
                                            <p> <b>Newbie –</b> Your Provider maybe new to the industry or just taken on a new treatment to expand their menu. </p>
                                            <p> <b>Nearly there –</b>they have been practicing a while and may need to tweak a few things, perhaps their speed or gaining confidence.  They will be able to tell you more on their listing. </p>
                                            <p> <b>Star –</b> Feeling completely confident and ready to dazzle you. You can find out more about their competency on individual listings.</p>
                                            <p> We encourage our Providers to be completely transparent within their listings.</p>
                                            <h3 className="heading">Are the Treatment & Service Providers Vetted?</h3>
                                            <p>We are a Friendly, Honest and Transparent platform, encouraging our users to follow our lovely morals.  We recommend that you are diligent and feel completely comfortable before booking or attending your appointment.  The Providers will all be at various levels of expertise, we are unable to vet them personally for you. However, when our Providers sign up with us – our rules state they must have the adequate insurance, qualifications and licenses for the services they are offering to you.</p>

                                        </div>

                                        <h3 className="heading mb-16">Booking & Payment</h3>
                                        <div className="list-wrp-faq">
                                            <h3 className="heading">Is payment secure?</h3>
                                            <p>Payment is secure and we use Stripe for all our payment services.</p>
                                            <h3 className="heading">At what point will money leave my account?</h3>
                                            <p>No money is taken, until the Treatment or Service Provider has confirmed that you are the one! It’s like a hotel booking when you book with your card but, they don’t take your money until a specified time.  Once they’ve confirmed, the money will then be taken, whether a deposit or full payment depending on your preferences and how soon the appointment is.
                                            </p>
                                            <h3 className="heading">I have seen a Treatment or Service I would love to book with ~ do I have to pay in full?
                                            </h3>
                                            <p>We enable you to book up to 3 months in advance so you don’t have to pay in full and, can jump on that Appointment that you love the look of. You pay a small 15% deposit to secure your Appointment when booking - this is non-refundable once our fabulous Provider has confirmed.  For Appointments less than 36 hours away, we then would require full payment which since it’s short notice – this would become non-refundable.  If you would prefer to pay in full at the time of booking, you are welcome to do that too! This is why it’s great to be organised, have something to look forward to and bag that bargain, although have no doubt, there is last minute amazing offers to be had too!
                                            </p>
                                            <h3 className="heading">Why do I need to pay a deposit?</h3>
                                            <p>This shows the Provider that you’re committed to turning up, no one likes being let down and this is their income. We expect them to value you and it’s only fair that we do the same for them.  We all love a supportive and reliable platform.</p>
                                            <h3 className="heading">I have been charged an incorrect amount, what shall I do?</h3>
                                            <p>If there is a query outside out Terms & Conditions and you believe you have been charged incorrectly, please contact us at hello@goginipig.com  With a screenshot of your booking, we may also ask for proof – such as a card statement and we will look into this for you.</p>
                                            <h3 className="heading">I need to cancel my appointment, what should I do?</h3>
                                            <h3 className="heading">If it’s already been confirmed…</h3>
                                            <p>If your appointment is further than 36 hours away then no problem, you’ve only lost your deposit.  If it is less than that then you will be charged the full amount.  If you do need to cancel, then please visit your account settings and follow the cancellation link.  If it is last minute, its courteous to let your Provider know to enable them to get on with their day! You will have had their contact details sent to you previously. To view full details of our cancellation policy, please see our ‘<Link to="/tc">Legals</Link>’.</p>
                                            <h3 className="heading">If the appointment hasn’t yet been confirmed by the Provider…</h3>
                                            <p>Be quick, before it’s too late! We do allow the Providers up to 16 hours to accept or decline your appointment.  If you haven’t yet had the confirmation that you are a perfect match, you can change your mind without any money being taken. </p>
                                        </div>

                                        {/* <h3 className="heading">GiniPig Must Knows</h3> */}
                                        <h3 className="heading mb-16">My Appointment</h3>

                                        <div className="list-wrp-faq">
                                            <h3 className="heading">How long should I expect my Appointment to last?</h3>
                                            <p>The duration will be stated at the top of each listing – in minutes/hours. However, please be aware that as they are perfecting their skills, they may take a little longer than you would be used to. A small price to pay for a great discount, just remember to allow plenty of time for parking.
                                            </p>
                                            <h3 className="heading">Will parking be available at my Appointment?</h3>
                                            <p>Some will have parking facilities, if this is important to you, you could contact the Provider to discuss parking options once the booking is confirmed, and full details have been forwarded on to you.</p>
                                            <h3 className="heading">I am running late, what do I do?</h3>
                                            <p>We encourage you to be on time to avoid any unnecessary stress, as your Provider has allowed a specific amount of time for you and your appointment. We don’t want your appointment being cut short because you were late, this may mean they don’t have time to complete the whole Treatment or Service because of your timekeeping.  At the same time, we do understand that things can crop up – you will have their contact details and we suggest you contact them directly ASAP so that they can decide what is best.</p>
                                            <h3 className="heading">My Treatment or Service Provider hasn’t turned up?</h3>
                                            <p>We are so sorry, this is extremely frustrating, and we understand that, in the first instance we recommend that you contact your Provider, and if you don’t get a suitable answer, please contact us at <a href="mailto:hello@goginipig.com">hello@goginipig.com</a>.</p>
                                            <h3 className="heading">Will the Treatment or Service Provider require photos after the Appointment?</h3>
                                            <p>This will be specified on the listing if photos are required. If you would rather not have a photo taken, and the Provider has specifically said that they need one, please don’t then show an interest in this listing, this maybe the reason that they’re looking for GiniPigs and want to add to their portfolio.  </p>
                                            <h3 className="heading">My Treatment or Service Provider asked for photos at the end of the appointment, but it wasn’t stated in the listing, what do I do?</h3>
                                            <p>We ask our Providers to specify if they need photos for Appointments, if they didn’t make this clear, but you were happy to give permission for photos to be taken and used, perfect – we obviously did a fab job at matching you! Remembering that our platform is an Encouraging, Friendly and Supportive space. If you are both happy, that makes us smile too! </p>
                                            <h3 className="heading">Will my Provider use my images on Social Media?</h3>
                                            <p>For any images used outside our Platform, we encourage our Providers to obtain consent from you.</p>
                                            <h3 className="heading">I am not sure about the results, who do I speak to?</h3>
                                            <p>We recommend that this is taken up with the Provider at the appointment in a courteous and respectful way to enable them to discuss this with you.  We are only a connecting party and take no responsibility once we have introduced you.</p>
                                            <h3 className="heading">Do I have to tip?</h3>
                                            <p>It’s definitely not compulsory but it’s a lovely way of showing someone that they did a great job, it gives a boost to their confidence too. We will leave that decision up to you but if you would like to, it will need to be in cash at the end of the appointment. We are sure that will make their day.</p>
                                            <h3 className="heading">Why does the Treatment/Service Provider need to review me?</h3>
                                            <p>This helps future Providers decide if you will be perfect for their Appointment, based on your Reliability & Friendliness, this helps them decide which GiniPigs to use next time.  We are a friendly, supportive and encouraging brand and we do our best to support future careers.  No-one wants to be let down last-minute, have a GiniPig who doesn’t fill their criteria, or keeps cancelling, this helps to avoid this.</p>
                                            <h3 className="heading">What do I need to review the Provider for?</h3>
                                            <p>You will be asked to review the Treatment or Service Provider on their Professionalism, Friendliness, Reliability and Results.  Remember some will be training so kindness goes along way!  It helps to make it easier for our other GiniPigs when choosing Providers. </p>
                                            <h3 className="heading">I would love to buy a gift card for a friend, can I do this?</h3>
                                            <p>This is something we would love to offer soon but for the time being perhaps you could give them the cash to treat themselves on here, let them choose what they would like to book. What a great present and they will get so much more for their cash!</p>

                                        </div>

                                        <h3 className="heading mt-16">Troubleshooting</h3>
                                        <div className="list-wrp-faq">
                                            <h3 className="heading">My account has been taken down, why?</h3>
                                            <p>We are a safe and friendly platform, perhaps you broke our Terms & Conditions, or somebody has reported inappropriate content? Sometimes of course we do experience technical issues and so if you think it’s something other than the above, please get in touch and we will do our best to resolve this for you.</p>
                                            <h3 className="heading">I am experiencing problems with the site</h3>
                                            <p>We are aware that occasionally we may have some glitches, if you experience any issues with our site, please contact us at <a href="mailto:hello@goginipig.com">hello@goginipig.com</a>. If we don’t know then we can’t put it right. Thank you.</p>
                                            <p>We hope we have thought of most things to enable you to have an enjoyable visit to our platform,  if you think that there is something we should absolutely be including here, then please get in touch. We are human after all and we can miss something under our noses. Reach out to us by emailing <a href="mailto:hello@goginipig.com">hello@goginipig.com</a>. thank you!</p>
                                            <h3 className="heading">I need help, who do I speak to?</h3>
                                            <p>You can chat to us at <a href="mailto:hello@goginipig.com">hello@goginipig.com</a>.</p>

                                        </div>

                                        <h3 className="heading">Treatment & Service Providers Valuable Info</h3>
                                        <div className="list-wrp-faq">
                                            <p>We have GiniPigs, Treatment & Service Providers throughout the UK and we hope to have GiniPigs available to you in your location ASAP.  As we are a new company, please bear with us if there aren’t yet any on board in your area, there soon will be! Place your listing, you will soon know if we have available GiniPigs for you.</p>
                                            <h3 className="heading">Can I list Online Services that I offer?</h3>
                                            <p>We know that many of you offer online services – whether it’s for Education in the Hair, Beauty, Aesthetics or Wellness Industries, Fitness Classes, Nutrition or Mindfulness – the world is your oyster and we have thought of you all. Simply select the ‘Online’ option.  Remember to include all the important information such as… is a course of treatments required, is it 1-1, or a group session, if a course you will need to list for a specific start date (not through our flexible appointments option), be clear in your listing, the days, number of sessions, commitment that they will need to make and times you are offering.  You will get more GiniPigs that way, it’s all about transparency and reliability. Let’s fill those classes for you! </p>
                                            <h3 className="heading">What level of expertise do I need to have, to enable me to list through GoGiniPig?</h3>
                                            <p>We encourage all levels of expertise, from someone new to the industry to a just launched treatment that they are practicing, experts that want more clients through their doors or last-minute appointments filled and everything in between. You must have up to date insurance cover, qualifications and any required licenses and only offer Treatments or Services that do not require a General Anaesthetic and is not a dental service, in line with our Terms & Conditions. </p>
                                            <h3 className="heading">Are the Treatment & Service Providers Vetted?</h3>
                                            <p>We are a Friendly, Honest and Transparent platform, encouraging our users to follow our lovely morals.  We recommend that our GiniPigs do their own due diligence and feel completely comfortable before booking or attending your appointment.  We are aware that our Providers will all be at various levels of expertise, we are unable to vet them personally. However, when our Providers sign up with us, they must confirm that they must have the adequate insurance, qualifications and licenses for the services they are offering to GiniPigs.</p>
                                            <h3 className="heading">How do I start listing Treatments?</h3>
                                            <p>Register with us and upload a recent and dazzling picture of yourself (make sure it still looks like you, so that the GiniPig knows who to expect), no business logos, websites, emails or telephone numbers are allowed.  GiniPigs can use our clever little filters that do a big job in finding you, based on location, price, reviews and availability. </p>
                                            <p>When listing a treatment, we love seeing your own images – it helps you stand out – if you don’t upload your own – our bank images will automatically be selected for you! We know yours will be much better so shine with those – it creates a much more personal experience. </p>
                                            <p>Whether you need GiniPigs for Training, Case Studies, Portfolios or you are fully qualified and want to fill those slots in your diary – you will be glad you found us! We’re your new ‘go to’ platform that is here to make your life more flawless.  No more freebies practicing on family and friends. We value your time and talent.  </p>
                                            <p>You can list multiple appointments whether online or in person, these can be for a fixed date and time or if you have flexible availability – list more and raise your income.  You can list for as many GiniPigs as you would like, we’ve allowed for patch tests and consultation forms, you can see your GiniPigs profile picture & ask for additional images if you are looking for that specific look! Did we mention, we take payment on your behalf and forward onto you once we have received confirmation that the appointment happened. In fact, we think we have thought of everything, (if you think that we have missed something lets chat, we are only human after all). </p>
                                            <p>We encourage you to be as quick as you can when you have received interest from a GiniPig as our clever system automatically declines them on your behalf, if you have exceeded the 16 hour time allowed without responding to them.  We want to get money and experience flowing for you and, our GiniPigs to receive your fabulous services. In case you’re super busy making everyone look wonderful, our platform will allow this to happen on 3 consecutive occasions before your account is automatically paused.  It’s the way that we keep our fabulous site fresh and all parties happy! </p>

                                            <h3 className="heading">Is there a minimum or maximum amount that I need to charge?</h3>
                                            <p>There is a minimum charge of £7.50 but no maximum.  As long as you are offering Treatments or Services with a minimum of 30% off – (don’t worry our clever little tool calculates this for you).  We know lots of you were offering treatments free as you were struggling to find GiniPigs so this way it helps raise your income too. Don’t forget that the payment that you will receive will be your list price minus our 15% GoGiniPig fee.  For your ease – the minimum RRP to ensure that it calculates a list price of £7.50 – will be £10.73. </p>
                                            <h3 className="heading">When viewing as a GiniPig why do some listings say Book & others say Enquire?</h3>
                                            <p>If you have multiple days and times available, we have an enquire functionality so that you can both be on the same page and commit to the same date/time.  Your GiniPig specifies an interest by clicking the Enquire now button and clicks on the date that is available – they can choose one date from your options but multiple times if you have them. </p>

                                            <h3 className="heading">I need to get extra clients for my business, can I use GoGiniPig?</h3>
                                            <p>Absolutely, we know ourselves how hard it can be to gain new clients.  You are welcome to use our Platform for this. We only ask that you offer a minimum of 30% discount and charge at least £7.50.  We would love to get new clients through your door and raise your income.</p>
                                            <h3 className="heading">I require a GiniPig for a certain look that I have in mind, how will I be able to check that they are suitable?</h3>
                                            <p>Within the Platform, you can ask for a photo for the purpose of the Appointment, enabling you to see if they have the desired look to achieve your goals.  Hopefully they will also have a dazzling profile picture that will help make your decision easier.
                                            </p>

                                            <h3 className="heading">Am I able to offer a Patch Test before the Appointment?</h3>
                                            <p>We have included functionality that enables you to state on your listing that a patch test is required for your Treatment.  You will arrange this directly with your GiniPig once you have confirmed their booking and have received all their contact details.  When listing an appointment that needs a patch test – our platform won’t allow you to list unless there is a minimum of 72 hours before the appointment time.  It is your responsibility to ensure that there is enough time to complete a patch test before you list the appointment time. Don’t forget to leave plenty of time to accommodate for this.
                                            </p>

                                            <h3 className="heading">There are Contra-indications associated with this Appointment, can I specify these to my GiniPig?</h3>
                                            <p>Don’t worry about that, we have included a function where you can upload your consultation form for the GiniPig to read. That way you can be sure that they will be suitable for your Services. They will still complete the consultation form with you, if necessary, but this is a quick way to avoid disappointment from both parties.
                                            </p>
                                           

                                            <h3 className="heading">Can I list appointments for surgical procedures?</h3>
                                            <p>One day we hope to include this but for the time being its non- surgical.  You can still list for Botox, Fillers & Facial Peels though. You can list any Treatments that do not require a general anaesthetic. If you are unsure whether or not you can list your treatment, we suggest you contact us, and we will assist you <a href="mailto:hello@goginipig.com">hello@goginipig.com</a> </p>
                                          
                                            <h3 className="heading">Can I list an appointment for a dental procedure?</h3>
                                            <p>No – we currently don’t allow any dental procedures to be listed through our platform, maybe one day! </p>
                                          
                                            <h3 className="heading">How do I know if my GiniPig will be suitable?</h3>
                                            <p>We have thought of this and the disappointment for both parties when contra-indications mean that the client isn’t suitable for your Appointment.  We have made this easy for you to upload your consultation form for the GiniPig to read.  If you need do a patch test too, you are able to arrange this within the platform.</p>

                                            <h3 className="heading">I am a mobile therapist, can I use GoGiniPigs services?</h3>
                                            <p>This is not something that GoGiniPig currently offers, if the demand is sufficient then this is something that we will consider so watch this space.</p>
                                           
                                            <h3 className="heading">I had some GiniPigs awaiting for me to approve and now they have disappeared, why?</h3>
                                            <p>To enable us to keep our platform working effectively and flowing beautifully. If you don’t confirm or decline GiniPigs within 16 hours – our system auto declines them on your behalf.  We understand that you could be busy – however, at the same time, it’s not fair for our GiniPig to hang around waiting for approval when they may have their eyes on other fabulous opportunities.  We appreciate that sometimes things are out of your hands and this may have been an oversight, if that’s the case, we are a friendly bunch so reach out and chat and lets see what we can do to help.  If this has happened on 3 consecutive occasions your account maybe temporarily suspended.  Let’s chat at  hello@goginipig.com and resolve this for you.</p>

                                            <h3 className="heading">My GiniPig didn’t turn up, who do I speak to?</h3>
                                            <p>If you’ve tried making contact with the GiniPig but haven’t been able to resolve this, simply press the ‘No Show’ button in your account.  We get that it’s frustrating, we will forward you 50% of the Appointment price to soften that blow for you. We don’t want you losing out.</p>

                                            <h3 className="heading">I work for a business but would love to practice my skills outside of this at another location, am I able to?</h3>
                                            <p>If the business is happy with you doing this then, we are too! You will need to make it clear on your listing that although you work for them, the Appointment will be taking place elsewhere independently.  We also recommend that you update your profile so that your GiniPigs will know exactly where to go and will avoid any confusion.</p>
                                          
                                            <h3 className="heading">I need more than one GiniPig, is this possible?</h3>
                                            <p>We have made this possible and you can list as many GiniPigs as you would like, at one time and for different treatments too.</p>
                                           
                                            <h3 className="heading">I don’t have a set date or time to list an appointment as I can be flexible, can I still use the services?</h3>
                                            <p>Of course, as well as having a set appointment time and date, we also have a flexible option for you to list multiple days and times.</p>
                                        
                                            <h3 className="heading">Payments</h3>
                                            <h3 className="heading">Is payment secure?</h3>
                                            <p>Payment is secure and we use trusted Stripe for all our payment services.</p>

                                            <h3 className="heading">How much does GoGiniPig charge for their services?</h3>
                                            <p>We charge a small admin fee of 15% in line with our Terms & Conditions.  A fee is also taken by Stripe if you have an active account with GoGiniPig that month, (this means we have magically found you GiniPigs during that month), they charge a total of £2 for that month for their services – we make no profit on this.  It’s a small price to pay for the new clients that will be through your door whilst raising your income and experience! </p>
                                          
                                            <h3 className="heading">How do I receive the payment after the Appointment?</h3>
                                            <p>Once we’ve received confirmation that the Appointment has taken place, we will forward the money onto you.  Please allow a few days for this to be credited to your account.</p>
                                            {/* <p >WE NEED TO INCLUDE AND CLARIFY THE PAYMENT TIME IT TAKES TO REACH THEIR ACCOUNT FROM STRIPE AND INCLUDE A Q AND A FOR THIS.</p> */}
                                            <h3 className="heading">I have noticed a sum of £2 debited from my account.</h3>
                                            <p>If your account is active in any calendar month and we have connected GiniPigs to you for your Appointments, our Payment Partner Stripe will charge you £2 to cover admin fees for that month. We do not make any profit on these charges.</p>
                                            <p> <a href="https://stripe.com/gb/legal"> https://stripe.com/gb/legal</a></p>

                                            <h3 className="heading">I have been charged an incorrect amount, what shall I do?</h3>
                                            <p>If there is a query outside out <Link to="/tc"> Terms & Conditions </Link> and you believe you have been charged incorrectly, please contact us at < a href="mailto:hello@goginipig.com">hello@goginipig.com</a>. With a screenshot of your booking, we may also ask for proof – such as a card statement and we will look into this for you.</p>
                                            <h3 className="heading">Why do I get charged for cancelling last minute.</h3>
                                            <p>You will be charged the 15% non-refundable deposit that the GiniPig has paid, in line with our <Link to="/tc"> Terms and Conditions </Link>. We expect the GiniPig to value your time and commit to you, it’s only fair that we do the same for them.</p>
                                          
                                            <h3 className="heading">Reviews</h3>
                                            <h3 className="heading">How will the GiniPig be reviewing me?</h3>
                                            <p>The GiniPigs will be able to rate you on Professionalism, Friendliness, Timekeeping & Overall Results.  These are all very important for any industry and will help you to see where you are excelling or where tweaks may need to be made to help you to soar.</p>
                                          
                                            <h3 className="heading">Extra Bits and Bobs</h3>
                                            <h3 className="heading">What is my Portfolio?</h3>
                                            <p>The Portfolio is where you can save your images of all your work, see your progress, how far you’ve come and what you’ve achieved over time. You can show off your amazing skills to future employers and clients.  You can also use this to share your images on your listing by simply pressing feature and if you change your mind, there’s always unfeature too!</p>
                                           
                                           
                                            <h3 className="heading">Can the GiniPigs tip?</h3>
                                            <p>Yes, if you provide a service that you are both happy with, they are welcome to tip, this is down to their discretion and will need to be done in cash at the end of the appointment. We are unable to accept tips through the platform.</p>
                                          
                                            <h3 className="heading">Can Ginipigs purchase Gift Vouchers through the platform?</h3>
                                            <p>We don’t have a Gift Voucher option at the moment , we are hoping to add something soon. We know it would be a great way for those lovely GiniPigs to get more luxe for their money by buying GoGiniPig vouchers.</p>
                                        </div>

                                        <h3 className="mt-16  heading">Troubleshooting</h3>
                                        <div className="list-wrp-faq">
                                            <h3 className="heading">My account has been taken down, why?</h3>
                                            <p>Our system automatically declines GiniPigs if you have not responded within 16 hours, so that nobody is left waiting and avoids disappointment. If this has happened on 3 consecutive occasions your account and listings will automatically be paused.  If you have been unable to respond due to things outside your control – please get in touch and let’s see what we can do.<Link>hello@goginipig.com</Link> </p>
                                            <p>As we aim to be a safe and friendly platform, perhaps you broke our Terms & Conditions, or somebody has reported inappropriate content.   If you believe this maybe incorrect, please get in touch so that we can discuss this further. </p>
                                             <p>Sometimes, of course we do experience technical issues and so if you think it’s something other than the above, please get in touch, we will do our best to resolve this for you.  </p>

                                            <h3 className="heading">I am experiencing problems with the site</h3>
                                            <p>We are aware that occasionally we may have some glitches, if you experience any issues with our site, please contact us at <a href="mailto:hello@goginipig.com">hello@goginipig.com</a>.  If we don’t know then we can’t put it right. We love that you are helping to keep our performance in tip top condition.
                                            </p>

                                            <h3 className="heading">Have we missed something? </h3>
                                            <p>We hope we have thought of most things to enable you to have an enjoyable visit to our platform, but if you think that there is something we should absolutely be including here then please get in touch. We are human after all and we all can miss something under our noses. Reach out to us by emailing <a href="mailto:hello@goginipig.com">hello@goginipig.com</a> thank you!
                                            </p>
                                            <h3 className="heading">I need help, who do I speak to? </h3>
                                            <p>You can chat to us. We would love to hear from you at  <a href="mailto:hello@goginipig.com">hello@goginipig.com</a>
                                            </p>
                                            <h3 className="heading">You want us to find GiniPigs for your industry too? </h3>
                                            <p>Perfect, lets chat! We would LOVE to discuss helping you too! Drop us a message at <a href="mailto:hello@goginipig.com">hello@goginipig.com</a>
                                            </p>

                                        </div>


                                    </div>
                                </div>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default CancellationPolicy;