import React, { useCallback, useState, useEffect } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Container, Row, Col } from 'react-bootstrap';
import { LeftArrow, stripelogo } from '../../config/images';
import {
    acceptFailPaymentAPI_Action, resetacceptFailPaymentAPI_Action, createbookingAPI_Action, treatment_details, createOpenbookingAPI_Action, resetcreatebookingAPI_Action, resetLoginAPI_Action, resetUserAPI_Action, resetRegisterAPI_Action, resetPractitionerAddInfoAPI_Action, resettreatmentaddreviewAPI_Action, resetPractionerCategory_API_Action, resetpractitionerProfileAPI_Action, resetGinipigUpdateAPI_Action, resetgoginipigprofileAPI_Action, resetStripeAccountValidityAPI_Action
} from '../../stores/actions';
import { STRIPE_TOKEN } from "../../config/ApiConstants";

import PartialPayment from '../../components/PartialPayment';
import { getLocalStorageData, setLocalStorageData, clearLocalStorage } from '../../utils/storage';
import LoadingSpinner from "../../components/UIComponent/LoadingSpinner"
import Modal from 'react-modal'
import LargeButton from '../../components/LargeButton';
import queryString from 'query-string';
import { bookingDetailsAPI_Action, resetbookingdetailsAPI_Action } from '../../stores/actions'
import moment from 'moment';



const PaymentFailurePage = (props) => {
    const stripePromise = loadStripe(STRIPE_TOKEN)
    let history = useHistory();
    const dispatch = useDispatch();
    const [isDataPayment, setIsDataPayment] = useState(false);
    const [displayunautherror, setDisplayunautherror] = useState(false);
    const [disable, setDisable] = useState(false);
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const createbooking = useSelector(state => state.createbooking);
    const acceptfailpayment = useSelector(state => state.acceptfailpayment);
    var param_data;
    var post_data;
    var type;
    var failuredata;
    var status;
    const bookingData = useSelector(state => state.bookingdetails);
    let params = queryString.parse(props.location.search)
    let paramsstate = queryString.parse(props.location)
    if (props.location.state) {

        param_data = props.location.state.paramData;
        post_data = props.location.state.postData;
        type = props.location.state.type;
        failuredata=props.location.state.failuredata;
      status = props.location.state.failuredata.intent_to_pay_status
    }
    const [isPhotoAdded, setIsPhotoAdded] = useState(false);
    var photo_added;
    const requestdetails = useSelector(state => state.requestdetails);


    useEffect(() => {

        let checkToken = "";
        let checkType = "";
        let LocalDatatoken = getLocalStorageData("loginData")
        let registerData = getLocalStorageData("practitionersignupData")

        if (registerData != "" && registerData !== undefined && registerData !== null) {
            checkToken = registerData.token;
            checkType = registerData.user.type;
            // if(registerData.user.type === 'practitioner')
            // {
            //     window.scrollTo(0, 0);
            //     let failuredata={
            //         navigateFailure:true,
            //         bookingId:params.booking_id,
            //         payment_failure_id:params.payment_failure_id,
            //         intent_to_pay_status:'partial'

            //     }
            //     setLocalStorageData("navigateFailure",JSON.stringify(failuredata))
            //     history.push('/login');
            // }
            
        }
        else if (LocalDatatoken !== "" && LocalDatatoken !== undefined && LocalDatatoken !== null) {
            checkToken = LocalDatatoken.token;
            checkType = LocalDatatoken.user.type;
            // if(LocalDatatoken.user.type === 'practitioner')
            // {
            //     window.scrollTo(0, 0);
            //     let failuredata={
            //         navigateFailure:true,
            //         bookingId:params.booking_id,
            //         payment_failure_id:params.payment_failure_id,
            //         intent_to_pay_status:'partial'

            //     }
            //     setLocalStorageData("navigateFailure",JSON.stringify(failuredata))
            //     history.push('/login');
            // }
            
        }
        
        if (checkToken === "" || checkType === 'practitioner') {
            window.scrollTo(0, 0);
            let failuredata={
                navigateFailure:true,
                bookingId:params.booking_id,
                payment_failure_id:params.payment_failure_id,
                intent_to_pay_status:'partial'

            }
            setLocalStorageData("navigateFailure",JSON.stringify(failuredata))
            history.push('/login');
        }else{
            if (params.booking_id != "" && params.booking_id !== undefined) {
                dispatch(resetacceptFailPaymentAPI_Action());
                dispatch(resetbookingdetailsAPI_Action());
                dispatch(bookingDetailsAPI_Action(params.booking_id, checkToken));
            }else if(failuredata!==null && failuredata!==undefined &&failuredata!==''){
                dispatch(resetacceptFailPaymentAPI_Action());
                dispatch(resetbookingdetailsAPI_Action());
                dispatch(bookingDetailsAPI_Action(failuredata.bookingId, checkToken));
            }
        }

    }, []);


    useEffect(() => {
        if (requestdetails.requestdetailsdata == null || requestdetails.requestdetailsdata == undefined) {
            nextButtonClick()
        }

        if (isDataPayment) {
            setDisable(false)
            window.scrollTo(0, 0);

            if (acceptfailpayment && acceptfailpayment != null && acceptfailpayment.isSuccess === true) {
                if (bookingData.bookingdetailsdata.data.booking.request.data.type === 1) {
                    var new_date = moment(bookingData.bookingdetailsdata.data.booking.date).format("DD-MM-yy");
                    var dateSelected = {
                        date: new_date,
                        timeslots: {
                            morning: [bookingData.bookingdetailsdata.data.booking.time],
                            afternoon: [],
                            evening: [],
                        }
                    }

                    setLocalStorageData("book_open_request_time", JSON.stringify(dateSelected))
                } else {

                }


                //doPayment()
                setDisable(false)
                window.scrollTo(0, 0);
                history.push({
                    pathname: '/thanks-for-payment/' + bookingData.bookingdetailsdata.data.booking.request.data.uuid,
                    paramData: params
                });
                
                dispatch(resetbookingdetailsAPI_Action());
            }
            else if (acceptfailpayment && acceptfailpayment != null && acceptfailpayment.isError === true) {
                setDisable(false)
                setIsOpen(false);
                // history.push('/time-expired');
            }
            else {
                setDisable(false)
                window.scrollTo(0, 0);
            }

        }
    }, [acceptfailpayment]);



    useEffect(() => {

        if (bookingData && bookingData.isSuccess == true && bookingData.bookingdetailsdata.data.booking.run_at <= moment().format('Y-m-d H:m:s')) {

            var run_at_date = moment(bookingData.bookingdetailsdata.data.booking.run_at).format('Y-m-d');
            var current_date_date = moment().format('Y-m-d');
            if (run_at_date <= current_date_date) {
                var run_at_time = moment(bookingData.bookingdetailsdata.data.booking.run_at).format('H:m:s');
                var current_date_time = moment().format('H:m:s');
                if (run_at_time <= current_date_time) {
                    history.push('/time-expired');
                }

            }
        }

        if (bookingData && bookingData.isSuccess == true && bookingData.bookingdetailsdata.data.booking.payment_failure_status == 'completed') {

            history.push('/time-expired');
        }

    }, [bookingData.bookingdetailsdata]);


    if (bookingData.bookingdetailsdata && bookingData.bookingdetailsdata.status_code === 401) {
        dispatch(resetbookingdetailsAPI_Action());
        setDisplayunautherror(true)
        window.scrollTo(0, 0);
    }

    const nextButtonClick = () => {
        window.scrollTo(0, 0);
        clearLocalStorage("book_open_request_time");
        clearLocalStorage("update_ginipig_profile");
        window.removeEventListener('popstate', function (event) {
            // history.go(1);
        }, false);
        var backpressed = ''; var data = ''; var date = '';

        if (getLocalStorageData("filter_treatment_data") !== undefined) {
            backpressed = JSON.parse(getLocalStorageData("filter_treatment_data"))
        }
    }


    if (acceptfailpayment.acceptfailpaymentdata && acceptfailpayment.acceptfailpaymentdata.status_code === 401) {
        dispatch(resetacceptFailPaymentAPI_Action());
        clearLocalStorage("loginData")
        clearLocalStorage("practitionersignupData")
        dispatch(resetLoginAPI_Action());
        dispatch(resetUserAPI_Action());
        dispatch(resetRegisterAPI_Action());
        dispatch(resetPractitionerAddInfoAPI_Action());
        dispatch(resettreatmentaddreviewAPI_Action());
        dispatch(resetPractionerCategory_API_Action());
        dispatch(resetpractitionerProfileAPI_Action());
        dispatch(resetGinipigUpdateAPI_Action());
        dispatch(resetgoginipigprofileAPI_Action());
        dispatch(resetStripeAccountValidityAPI_Action());

        window.scrollTo(0, 0);
        history.push('/login')
    }



    const doPayment = async (stripeToken) => {
        await dispatch(resetacceptFailPaymentAPI_Action());
        openModal();
        
        let loginToken = "";
        let LocalDatatoken = getLocalStorageData("loginData")
        let registerData = getLocalStorageData("practitionersignupData")
        let navigateFailureDt = getLocalStorageData("navigateFailure");
        let navigateFailureData = "";
        if(navigateFailureDt != undefined)
        {
             navigateFailureData = JSON.parse(navigateFailureDt)
        } 

        if (registerData != "" && registerData !== undefined && registerData !== null) {
            loginToken = registerData.token;
        }
        else if (LocalDatatoken !== "" && LocalDatatoken !== undefined && LocalDatatoken !== null) {
            loginToken = LocalDatatoken.token;
        }

        try {
            
            var post_data_new = {};
            post_data_new.stripeToken = stripeToken.token.id;
            post_data_new.stripeEmail = bookingData.bookingdetailsdata.data.booking.user.data.email;
            post_data_new.booking_id = bookingData.bookingdetailsdata.data.booking.id;
            post_data_new.intent_to_pay_status = "partial";
            post_data_new.payment_failure_id = params.payment_failure_id != undefined  ? params.payment_failure_id : navigateFailureData.payment_failure_id;
            setIsDataPayment(true);
            setIsOpen(true);
            clearLocalStorage('navigateFailure');
            await dispatch(acceptFailPaymentAPI_Action(post_data_new, loginToken))

        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    }



    const customStyles = {
        overlay: {
            background: "#dee2e663", zIndex: 999999
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            border: "none",
            background: "none"
        }
    };

    const openModal = () => {
        //e.preventDefault();
        setIsOpen(true);
    }

    const closeModal = () => {
        setIsOpen(false);
    }

    return (
        <section className="bgcolor-gray common-section payment_pg pb80">
            <Container>
                <Row>
                    <Col className="col-lg-11 m-auto">
                        <div className="navigationTop">
                            <div className="heading-history">
                                <div className="back-history">
                                    <button type="button" onClick={() => history.goBack()} className="back-btn" >
                                        {" "}
                                        <img src={LeftArrow} alt="arrow" />{" "}
                                    </button>
                                </div>
                                <div className="history-title">
                                    <h2> Payment  </h2>
                                </div>

                            </div>

                            {/* {acceptfailpayment.isError && acceptfailpayment.acceptfailpaymentdata &&
                                acceptfailpayment.acceptfailpaymentdata.hasOwnProperty(
                                    "message"
                                ) ? (
                                <p style={{ color: "red", marginTop: 10 }}>
                                    {
                                        acceptfailpayment.acceptfailpaymentdata.message
                                    }{" "}
                                </p>
                            ) : null} */}

                            {/* {displayunautherror ? (
                                <p style={{ color: "red", marginTop: 10 }}>
                                    {"Unauthorized User "}
                                </p>
                            ) : null} */}


                        </div>



                        {bookingData && bookingData.isSuccess == true && bookingData.bookingdetailsdata !== null ?
                            <div className="requirement_wrp">
                                <div className="payment-populate stripe_label large mt-16">
                                    {/* <p className="subheading">Amount to Pay : £<span>{requestdetails.requestdetailsdata && requestdetails.requestdetailsdata.data.request.your_price.toFixed(2)}</span></p> */}
                                    {
                                        status === "partial" ?
                                            <div>
                                                <p className="subheading">Amount to Pay Now: £<span>{(bookingData.bookingdetailsdata !== null && bookingData.bookingdetailsdata.data.booking.request.data.your_price !== null && (bookingData.bookingdetailsdata.data.booking.request.data.your_price * 0.85).toFixed(2))}</span></p>
                                                {/* <p className="subheading mt-24">Remaining Amount to Pay: £<span>{(requestdetails.requestdetailsdata && requestdetails.requestdetailsdata.data.request.your_price!==null&&(requestdetails.requestdetailsdata.data.request.your_price * 0.85).toFixed(2))}</span></p> */}
                                            </div>
                                            :
                                            <p className="subheading">Amount to Pay : £<span>{bookingData.bookingdetailsdata !== null && bookingData.bookingdetailsdata.data.booking.request.data.your_price.toFixed(2)}</span></p>
                                    }
                                </div>
                                <div className="stripe-box-content small stripe_label mb-20">
                                    <p className="subheading">Pay now via stripe</p>
                                    <div className="stripe-logo">
                                        <img src={stripelogo} className="st-logo" />
                                    </div>
                                </div>


                            </div>
                            :
                            displayunautherror?
                            <div className="history-title mt-40">
                                <h2>Error 401 - Unauthorized Access </h2>
                                <p className="subheading">Sorry your request could not be processed</p>
                            </div>:
                             <div style={{ width: "100%", fontSize: 20, marginTop: 100, textAlign: 'center' }}>
                             <p>Loading ...</p>
                         </div>
                        }
                        
                        {acceptfailpayment.isError && acceptfailpayment.acceptfailpaymentdata &&
                                acceptfailpayment.acceptfailpaymentdata.hasOwnProperty(
                                    "message"
                                ) ? (
                                <p style={{ color: "red" }}>
                                    {
                                        acceptfailpayment.acceptfailpaymentdata.message
                                    }{" "}
                                </p>
                            ) : null}

                        {
                            bookingData && bookingData.isSuccess == true && bookingData.bookingdetailsdata !== null ?
                                <div>
                                    {
                                        isPhotoAdded ?
                                            <div className="btn-align mt-40 pd32" style={{ justifyContent: "center" }}>
                                                <LargeButton
                                                    ButtonType="button"
                                                    ButtonText={"Upload Image(s)"}
                                                    nextButtonClick={() => history.push('/photo-requirement/' + param_data)}
                                                />
                                            </div>
                                            :
                                            <Elements stripe={stripePromise}>
                                                <PartialPayment
                                                    doPayment={doPayment} />
                                            </Elements>
                                    }
                                </div> : null
                        }


                    </Col>
                </Row>

                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    to
                    style={customStyles}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal"
                >
                    <div>
                        <LoadingSpinner />
                    </div>

                </Modal>

            </Container>
        </section>
    );

};

export default PaymentFailurePage;