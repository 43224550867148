import React from 'react';
import ChangePassword from './ChangePassword';

const ProfileChangePassword = (props)  => {
    return (
        <>
            <ChangePassword />
        </>
    );
}

export default ProfileChangePassword;