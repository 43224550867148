import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Container, Row, Col } from "react-bootstrap";
import { LeftArrow } from "../../config/images";

// redux
import {
  logoutAPI_Action,
  resetUserAPI_Action,
  resetLoginAPI_Action,
  resetRegisterAPI_Action,
  resetPractitionerAddInfoAPI_Action,
  resettreatmentaddreviewAPI_Action,
  resetPractionerCategory_API_Action,
  resetpractitionerProfileAPI_Action,
  resetgoginipigprofileAPI_Action,
  resetGinipigUpdateAPI_Action,
  resettreatmentdetailsAPI_Action,
  resetStripeAccountValidityAPI_Action
} from "../../stores/actions";
import {clearLocalStorage} from '../../utils/storage'
import { getLocalStorageData } from '../../utils/storage';
const GiniPigLogoutConfirmation = () => {
  const dispatch = useDispatch();
  const loginUserData = useSelector((state) => state.login);
  const registerData = useSelector(state => state.register);
  const practitionersignupaddinfoData = useSelector(state => state.practitionersignupaddinfo);
  const Data = JSON.stringify(registerData);
  let LocalDatatoken=getLocalStorageData("loginData")
  const loginToken = loginUserData.logindata
    ? loginUserData.logindata.data
      ? loginUserData.logindata.data.token
      : ""
    : "";
  let history = useHistory();

  // useEffect(
  //   ()=>{
  //     let checkToken="";
  //     const loginToken=loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token: "" : "";
     
  //     const PractitionerToken= practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.token:"" : "";
      
  //     const RegisterToken= registerData.registerdata ? registerData.registerdata.data ? registerData.registerdata.data.token:"" : "";
      
  //     if(loginToken != "")
  //     {
  //       checkToken=loginToken;
  //     } else if(PractitionerToken != "")
  //     {
  //       checkToken=PractitionerToken;
  //     }  else if(RegisterToken != "")
  //     {
  //       checkToken=RegisterToken;
  //     }

  //   // if(loginUserData.logindata===null){
  //     if(checkToken  === ""){
  //     history.push('/login');
  //   }
  // },[])

  const redirectUrl = (e) => { 
    window.scrollTo(0, 0);
    history.push(e);
  };

  const logoutUser = async (e) => {
    try {
      let checkToken="";
      const loginToken=loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token: "" : "";
      
      //const PractitionerToken= practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.token:"" : "";
      
      const RegisterToken= registerData.registerdata ? registerData.registerdata.data ? registerData.registerdata.data.token:"" : "";
      

      let  PractitionersignupData = getLocalStorageData("practitionersignupData")
      let PractitionerToken = "";
    
      if (PractitionersignupData != "" && PractitionersignupData!== undefined && PractitionersignupData !== null) {
        PractitionerToken = PractitionersignupData.token;
       }

      if(loginToken != "")
      {
        checkToken=loginToken;
      } else if(PractitionerToken != "")
      {
        checkToken=PractitionerToken;
      }  else if(RegisterToken != "")
      {
        checkToken=RegisterToken;
      } else if (LocalDatatoken !== "") {
        checkToken = LocalDatatoken.token;
    }
      clearLocalStorage("loginData")
      clearLocalStorage("practitionersignupData")
      clearLocalStorage("requestDataLocal")
      clearLocalStorage("book_close_request")
      clearLocalStorage("book_open_request")
    
      await dispatch(logoutAPI_Action(checkToken));
      await dispatch(resetLoginAPI_Action());
      await dispatch(resetUserAPI_Action());
      await dispatch(resetRegisterAPI_Action());
      await dispatch(resetPractitionerAddInfoAPI_Action());
      await dispatch(resettreatmentaddreviewAPI_Action());
      await dispatch(resetPractionerCategory_API_Action());
      await dispatch(resetpractitionerProfileAPI_Action());
      await dispatch(resetGinipigUpdateAPI_Action());
      await dispatch(resetgoginipigprofileAPI_Action());
      await dispatch(resetStripeAccountValidityAPI_Action());
      await dispatch(resettreatmentdetailsAPI_Action());

      window.scrollTo(0, 0);

      history.push("/login");
    } catch (error) {
      console.error(" profile ::  profile() :: Exception ==", error);
    }
  };

  return (
    <>
      <section className="bgcolor-gray common-section">
        <Container>
          <Row>
            <Col className="col-md-10 m-auto">
              <div className="heading-history">
                <div className="back-history">
                  <button
                    type="button"
                    onClick={() => history.goBack()}
                    className="back-btn"
                  >
                    {" "}
                    <img src={LeftArrow} alt="arrow" />{" "}
                  </button>
                </div>
                <div className="history-title">
                  <h2>Logout of your account </h2>
                </div>
              </div>
              <div className="desciption">
                <p className="subdesc">
                  Are you sure you would like to logout of your account?
                </p>

                <div className="two-cols mt-24 mb-24">
                  <button className="btns-gray " onClick={logoutUser}>
                    {" "}
                    Yes, logout of my account
                  </button>

                  <button
                    className="btns-gray "
                    onClick={() => history.goBack()}
                  >
                    {" "}
                    No, do not logout
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default GiniPigLogoutConfirmation;
