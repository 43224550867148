import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS,AUTH_HEADERS_NEW } from '../../config/ApiConstants';
export function practitionerAboutusAPI_Action(data,token) {
    const formData = new FormData(); 
      formData.append('practitioner_type', data.practitioner_type);
      formData.append('first_name', data.first_name);
      formData.append('name', data.name);
      formData.append('last_name', data.last_name);
      formData.append('email', data.email);
      formData.append('password', data.password);
      formData.append('profile_pic', data.profile_pic);
      formData.append('telephone_no',data.telephone_no)
      
      formData.append('bio',data.bio)

      formData.append('company_name', data.company_name);

      formData.append('flat', data.flat);
      formData.append('postcodeValue', data.street_name);
      formData.append('country', data.country);
      formData.append('city', data.city);
      formData.append('latitude', data.latitude);
      formData.append('longitude', data.longitude);
      formData.append('postcode',data.postcode)
      formData.append('practitioner_category_apply',data.practitioner_category_apply)
     

    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/practitioner/update/data`,
            method: 'POST',
            headers: {
                // ...AUTH_HEADERS,
                // 'X-Authorization': '7fa88a29503ff00b31359e83dc4540bb11e3360f',
                ...AUTH_HEADERS_NEW,
                'Authorization': 'Bearer ' + token
            },
            // body:JSON.stringify(data),
            body: formData,
            types: [
                types.PRACTITIONERABOUTUS_REQUEST,
                types.PRACTITIONERABOUTUS_RECEIVE, {
                    type: types.PRACTITIONERABOUTUS_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}


export const resetPractitionerAboutusAPI_Action = () => {
    return {
        type: types.RESET_PRACTITIONERABOUTUS,
        payload: null
    }
};
