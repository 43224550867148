import React from "react";
import LargeButton from '../LargeButton';




const DeclineBookingCard = (props) => {
   // console.log(props.description)
    return (
        <>
            <div className="decline_box mt-16">
                <h3 className="reason_txt mt-8">{props.headingtitle}</h3>

                <div className="form-wrp decline_reason_box">
                    {props.description!==null && props.description!==undefined && props.description.map((reason, index) => {
                        // console.log(reason)
                        //console.log(" arrayid ",props.reasonArrayId)
                        //console.log(" arrayidAll ",props.selectReasonIdAll)
                        //console.log('reason' , reason);
                        //console.log('checked' , reason.isChecked);
                        return (
                            <div className="checkbox-group" key={index}>
                                <label htmlFor={ props.flag + index}>
                                    <div className="checkinput">
                                        <input className="tnc" type="checkbox" id={props.flag + index}  
                                            checked={props.reasonArrayId === reason.id ? true : false  || props.selectReasonIdAll === reason.id ? true : false } 
                                            //checked={reason.isChecked}
                                            //onClick={() => props.toggleChangeReason(reason.id,props.itemData)}
                                            onClick={() => props.toggleChangeReason(reason.id)}
                                           required />
                                    </div>

                                    <span className="checkbox-decs">
                                        {" "}
                                        {reason.name}{" "}
                                    </span>

                                </label>
                            </div>
                        )
                    })

                    }

                    <div className="send_btn_box">
                        <div className="btn-align pd32">
                            <LargeButton
                                ButtonType="button"
                                ButtonText={props.status==="cancel" ?"Send Cancel":"Send decline"}
                                nextButtonClick={props.sendDeclineButtonClick}
                            />
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};

export default DeclineBookingCard;
