import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { LeftArrow } from '../../config/images';
import TreatmentRadioButton from '../../components/TreatmentRadioButtonSelect';
import LargeButton from '../../components/LargeButton';
import { clearLocalStorage, getLocalStorageData, setLocalStorageData } from '../../utils/storage'

import SelectAccordionContent from '../../components/UIComponent/SelectAccordionContent';
// redux
import { treatmentaddreviewAPI_Action, resetcreatetreatmentAPI_Action, resettreatmentdetailsAPI_Action, resetLoginAPI_Action, resetcreateAppointmentData_Action, treatmentDetailsAPI_Action } from '../../stores/actions'


const TreatmentSelect = (props) => {
    const location = useLocation();
    // Initialize hooks first   
    const dispatch = useDispatch();
    let history = useHistory();
    const [isActive, setIsActive] = useState();
    const [isEditCliked, setisEditCliked] = useState(false)

    const [formData, updateFormData] = useState(Object.freeze({
        selectedTreatment: [],
        selectedTreatmentId: "",
        activeclassformdata: "",
        category_id: "",
    }));

    // Selectors
    const treatmentdetailsData = useSelector(state => state.treatmentdetails);

    const treatmentaddreviewsData = useSelector(state => state.treatmentaddreviews);
    const loginUserData = useSelector(state => state.login);
    const practitionersignupaddinfoData = useSelector(state => state.practitionersignupaddinfo);
    const AppointmentCreateData = useSelector(state => state.appointmentcreatedata);
    const [treatmentId, settreatmentId] = useState()
    const [iswindowreload, setiswindowReload] = useState(location.iswindowreload);


  
    useEffect(() => {

        try {
            let checkToken = "";
            const loginToken = loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token : "" : "";
            const RegisterToken = practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.token : "" : "";
            let LocalDatatoken = getLocalStorageData("loginData")

            if (loginToken != "") {
                checkToken = loginToken;
            } else if (RegisterToken != "") {
                checkToken = RegisterToken;
            }
            else if (LocalDatatoken !== "") {
                checkToken = LocalDatatoken.token;
            }

            if (checkToken !== '') {
                dispatch(treatmentaddreviewAPI_Action(checkToken));
            }
            if (iswindowreload) {
                setiswindowReload(false)
                window.location.reload(false);
            }

        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }


        dispatch(resettreatmentdetailsAPI_Action());
        let requestDataLocal = getLocalStorageData("requestDataLocal")
        if (requestDataLocal !== null && requestDataLocal !== "" && requestDataLocal !== undefined) {

            setIsActive(requestDataLocal.RequestData.selectedTreatmentId)
            updateFormData({
                ...formData,
                selectedTreatmentId: requestDataLocal.RequestData.selectedTreatmentId ? requestDataLocal.RequestData.selectedTreatmentId : "",
                selectedTreatment: requestDataLocal.RequestData.selectedTreatment ? requestDataLocal.RequestData.selectedTreatment : [],
                rrpprice: requestDataLocal.RequestData.rrpprice ? requestDataLocal.RequestData.rrpprice : "",
                yourprice: requestDataLocal.RequestData.yourprice ? requestDataLocal.RequestData.yourprice : "",
                file1: requestDataLocal.RequestData.file1 ? requestDataLocal.RequestData.file1 : "",
                file2: requestDataLocal.RequestData.file1 ? requestDataLocal.RequestData.file2 : "",
                file3: requestDataLocal.RequestData.file1 ? requestDataLocal.RequestData.file3 : "",
                file4: requestDataLocal.RequestData.file1 ? requestDataLocal.RequestData.file4 : "",
                consent_form: requestDataLocal.RequestData ? requestDataLocal.RequestData.consent_form : "",

                hrs: requestDataLocal.RequestData.hrs ? requestDataLocal.RequestData.hrs : "",

                minutes: requestDataLocal.RequestData.minutes ? requestDataLocal.RequestData.minutes : "",

                activeclassformdata: requestDataLocal.RequestData ? requestDataLocal.RequestData.activeclassformdata : "",

                reason_require_ginipig: requestDataLocal.RequestData.reason_require_ginipig ? requestDataLocal.RequestData.reason_require_ginipig : "",
                expertise_level: requestDataLocal.RequestData.expertise_level ? requestDataLocal.RequestData.expertise_level : "",
                imagearray1: requestDataLocal.RequestData.imagearray1 ? requestDataLocal.RequestData.imagearray1 : "",
                imagearray2: requestDataLocal.RequestData.imagearray2 ? requestDataLocal.RequestData.imagearray2 : "",
                imagearray3: requestDataLocal.RequestData.imagearray3 ? requestDataLocal.RequestData.imagearray3 : "",
                imagearray4: requestDataLocal.RequestData.imagearray4 ? requestDataLocal.RequestData.imagearray4 : "",


                attributes_required: requestDataLocal.RequestData ? requestDataLocal.RequestData.attributes_required : "",
                photograph_required: requestDataLocal.RequestData ? requestDataLocal.RequestData.photograph_required : "",
                is_consentform_required: requestDataLocal.RequestData ? requestDataLocal.RequestData.is_consentform_required : "",
                patchtest_required: requestDataLocal.RequestData ? requestDataLocal.RequestData.patchtest_required : "",

                model_requirements: requestDataLocal.RequestData ? requestDataLocal.RequestData.model_requirements : "",
                photograph_requirements: requestDataLocal.RequestData ? requestDataLocal.RequestData.photograph_requirements : "",
                consent_form_link: requestDataLocal.RequestData ? requestDataLocal.RequestData.consent_form_link : "",
                consentformName: requestDataLocal.RequestData ? requestDataLocal.RequestData.consentformName : "",

                date: requestDataLocal.RequestData ? requestDataLocal.RequestData.date : "",
                start_time_hrs: requestDataLocal.RequestData ? requestDataLocal.RequestData.start_time_hrs : "",
                start_time_minutes: requestDataLocal.RequestData ? requestDataLocal.RequestData.start_time_minutes : "",
                postcode: requestDataLocal.RequestData ? requestDataLocal.RequestData.postcode : "",
                ginipig_count: requestDataLocal.RequestData ? requestDataLocal.RequestData.ginipig_count : "",
                request_type: requestDataLocal.RequestData ? requestDataLocal.RequestData.request_type : "",
                show_postcode: requestDataLocal.RequestData ? requestDataLocal.RequestData.show_postcode : "",

                selected_time_slots: requestDataLocal.selected_time_slots ? requestDataLocal.selected_time_slots : "",

                category_id: requestDataLocal.RequestData ? requestDataLocal.RequestData.category_id : "",
                category_name: requestDataLocal.RequestData ? requestDataLocal.RequestData.name : "",
                sub_category_id: requestDataLocal.RequestData ? requestDataLocal.RequestData.sub_category_id : [],
                title: requestDataLocal.RequestData ? requestDataLocal.RequestData.title : "",
                description: requestDataLocal.RequestData ? requestDataLocal.RequestData.description : "",
                categories: requestDataLocal.RequestData ? requestDataLocal.RequestData.categories : "",
                treatment_sub_category: requestDataLocal.RequestData.treatment_sub_category_new ? requestDataLocal.RequestData.treatment_sub_category_new : []

            });
        }


    }, [])

    // Use effects
    useEffect(() => {
        clearLocalStorage('selectedTreatmentId');
    }, [])
    
    const __redirectAddNewTreatment = () => {
        clearLocalStorage("requestDataLocal")
        window.scrollTo(0, 0);
        history.push('/treatment-ginipig-request');
    }


    const __redirectToEditTreatment = async (e, id) => {
        e.preventDefault();
        e.stopPropagation();
        setisEditCliked(true)
        await dispatch(treatmentDetailsAPI_Action(id));
        window.scrollTo(0, 0);
        history.push('treatment-content-edit/' + id);
    }

    const onTreatmentClick = async (indexid, id, cat_id) => {
        setIsActive(indexid === isActive ? false : indexid)
        __setSelectedId(id, cat_id)
    }


    const __setSelectedId = async (id, cat_id) => {
        settreatmentId(id)

        if (id === formData.selectedTreatmentId) {
            updateFormData({
                ...formData,
                selectedTreatment: [],
                selectedTreatmentId: "",
                activeclassformdata: "",
                category_id: "",
            })
        }
        else {
            let treatmentArray = []
            treatmentArray.push(id);
            updateFormData({
                ...formData,
                selectedTreatment: treatmentArray,
                activeclassformdata: cat_id,
                selectedTreatmentId: id,
                category_id: cat_id
            })
        }
    }
    // console.log("selectedTreatment: ",formData.selectedTreatment);
    const nextButtonClick = async (e) => {
        e.preventDefault();
        resetcreatetreatmentAPI_Action();
        resetcreateAppointmentData_Action();
        let formDatanew = {
            RequestData: formData,
            selected_time_slots: formData.selected_time_slots
        }
        setLocalStorageData("requestDataLocal", formDatanew)
        // await dispatch(createAppointmentData_Action(formData));

        setLocalStorageData("selectedtreatmentId", formData.selectedTreatmentId)
        setLocalStorageData("redirectPathNameEdit", "treatmentselect")
        if (formData.selectedTreatment !== "") {
            await dispatch(treatmentDetailsAPI_Action(formData.selectedTreatment));
        }
    }


    useEffect(() => {
        // console.log("inside useeffect",treatmentdetailsData.treatmentdetailsdata)

        if (treatmentdetailsData && treatmentdetailsData.treatmentdetailsdata && treatmentdetailsData.treatmentdetailsdata.status_code === 200 && !isEditCliked) {
            // console.log("still if")

            let requestObj = treatmentdetailsData.treatmentdetailsdata.data.request_data[0]
            let imageArray = [];

            if (requestObj !== undefined) {
                requestObj.request_images !== undefined && requestObj.request_images.map((image, index) => {
                    imageArray.push(image.image_link)
                })

                let filetype = requestObj.consent_form_link.substring(requestObj.consent_form_link.lastIndexOf('/'))
                //filetype)
                let newfiletype = filetype.replace("/", "")
                let fileName = newfiletype.substring(0, 5) + newfiletype.substring(newfiletype.lastIndexOf('.'))

                let tratmentdata = {
                    selectedTreatment: formData.selectedTreatment,
                    selectedTreatmentId: formData.selectedTreatmentId,
                    activeclassformdata: "",
                    category_id: "",
                    title: "",
                    description: "",
                    page: 1,
                    token: "",
                    treatment_id: "",
                    category_id: 1,
                    sub_category_id: [],
                    is_keep_treatment_on_profile: "",
                    redirectPathName: "",
                    rrpprice: requestObj.rrp_price ? requestObj.rrp_price : "",
                    yourprice: requestObj.your_price ? parseFloat(requestObj.your_price).toFixed(2) : '',
                    hrs: requestObj.hours ? requestObj.hours : 0,
                    minutes: requestObj.minutes ? requestObj.minutes : "00",
                    reason_require_ginipig: requestObj.reason_require_ginipig ? requestObj.reason_require_ginipig : "",
                    expertise_level: requestObj.expertise_level ? requestObj.expertise_level : "",
                    file1: imageArray[0] !== "null" && imageArray[0] !== null && imageArray[0] !== undefined ? imageArray[0] : "",
                    file2: imageArray[1] !== "null" && imageArray[1] !== null && imageArray[1] !== undefined ? imageArray[1] : "",
                    // file3: imageArray[2],
                    // file4: imageArray[3],
                    // imagearray1: [imageArray[0]],
                    // imagearray2: [imageArray[1]],

                    imagearray1: imageArray[0] !== "null" && imageArray[0] !== null && imageArray[0] !== undefined ? [imageArray[0]] : [],
                    imagearray2: imageArray[1] !== "null" && imageArray[1] !== null && imageArray[1] !== undefined ? [imageArray[1]] : [],

                    consent_form: requestObj.consent_form_link ? requestObj.consent_form_link : "",
                    consent_form_link: requestObj.consent_form_link,

                    attributes_required: requestObj.attributes_required === 1 ? true : false,
                    photograph_required: requestObj.photograph_required === 1 ? true : false,
                    is_consentform_required: requestObj.is_consentform_required === 1 && requestObj.consent_form_link !== "" ? true : false,
                    patchtest_required: requestObj.is_patchtest_required === 1 ? true : false,


                    model_requirements: requestObj ? requestObj.model_requirements : "",
                    photograph_requirements: requestObj ? requestObj.photograph_requirements : "",
                    consent_form_link: requestObj ? requestObj.consent_form_link : "",
                    consentformName: fileName
                }
                let formDatanew = {
                    RequestData: tratmentdata,
                }
                setLocalStorageData("requestDataLocal", formDatanew)
            } else {
                let tratmentdata = {
                    selectedTreatment: formData.selectedTreatment,
                    selectedTreatmentId: formData.selectedTreatmentId,
                    activeclassformdata: "",
                    category_id: "",
                    title: "",
                    description: "",
                    page: 1,
                    token: "",
                    treatment_id: "",
                    category_id: 1,
                    sub_category_id: [],
                    is_keep_treatment_on_profile: "",
                    redirectPathName: "",

                }


                let formDatanew = {
                    RequestData: tratmentdata,
                }
                setLocalStorageData("requestDataLocal", formDatanew)
            }

            window.scrollTo(0, 0);
            history.push('/appointment-info');

        }
    }, [treatmentdetailsData.treatmentdetailsdata]);


    //when ac suspend
    useEffect(() => {
        if (treatmentaddreviewsData && treatmentaddreviewsData.treatmentaddreviewdata && treatmentaddreviewsData.treatmentaddreviewdata.status_code === 401) {
            clearLocalStorage("loginData")
            clearLocalStorage("selectedtreatmentId")
            clearLocalStorage("redirectPathNameEdit")
            //clearLocalStorage("requestDataLocal")
            clearLocalStorage("practitionersignupData")

            dispatch(resetLoginAPI_Action());
            dispatch(resetcreateAppointmentData_Action());
            dispatch(resetcreatetreatmentAPI_Action());
            window.scrollTo(0, 0);


            history.push("/login");
        }
    });



    //end


    return (
        <>
            <section className="bgcolor-gray common-section treatment-disabled-accordion treatment_selectpg pb80">
                <Container>
                    {
                        treatmentaddreviewsData.treatmentaddreviewdata !== null && treatmentaddreviewsData.treatmentaddreviewdata.status_code !== 401 ?

                            <Row>
                                <Col className="col-lg-11 m-auto">
                                    <div className="navigationTop">
                                        <div className="heading-history">
                                            <div className="back-history">
                                                <button type="button" onClick={() => {
                                                    clearLocalStorage("requestDataLocal")
                                                    history.goBack()
                                                }
                                                } className="back-btn" >
                                                    {" "}
                                                    <img src={LeftArrow} alt="arrow" />{" "}
                                                </button>
                                            </div>
                                            <div className="history-title">
                                                <h2>  Create An Appointment  </h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="from-wrp mt-40">
                                        <div className="treatment_new_box treatment_info_step mb-32">
                                            <p className="steps-label "> Add to my Treatment/Service menu </p>
                                            <div className="treatment btn-another mt-8">
                                                <button onClick={__redirectAddNewTreatment} className="addtreatmentbtns addPeachColor">Create new treatment</button>
                                            </div>
                                        </div>

                                        <form>
                                            <div className="menu-component">
                                                <div className="treatment_info_step">
                                                    {/* <p className="steps-label mb-16">Appointment an existing treatment to create a new listing</p> */}
                                                    <p className="steps-label mb-16"> Select an existing treatment to create a new appointment </p>
                                                </div>
                                                {/* hair */}



                                                {
                                                    treatmentaddreviewsData.treatmentaddreviewdata && treatmentaddreviewsData.treatmentaddreviewdata.data.treatment_data.map((category, index) => {

                                                        return (

                                                            <>

                                                                <div className="category-treatment mt-8">
                                                                    <div className="category-heading mb-16">
                                                                        <div className="category-name">
                                                                            <div className="menu_category_icon">
                                                                                <TreatmentRadioButton
                                                                                    CategoryTitle={category.name}
                                                                                    id={category.id}
                                                                                    key={index}
                                                                                    activeclassformdata={formData.activeclassformdata !== "" ? formData.activeclassformdata : category.id}
                                                                                    disabled={""}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {category.treatments && category.treatments !== null && category.treatments.length === 0 &&

                                                                        <div className="notice-review treatment_info_step">
                                                                            <p className="steps-label mb-8">You haven't added any treatments to this category yet, please select 'Create new treatment'</p>
                                                                        </div>


                                                                    }

                                                                    <div className="treatment-accordion enable_opacity">

                                                                        {
                                                                            category.treatments && category.treatments !== null && category.treatments.map((treatmentdata, treat_index) => {

                                                                                return (
                                                                                    <SelectAccordionContent
                                                                                        title={treatmentdata.title}
                                                                                        editText="Edit"
                                                                                        selectText={formData.selectedTreatment !== undefined && formData.selectedTreatment.length === 0 ? "Select treatment" : formData.selectedTreatment !== undefined && formData.selectedTreatment.indexOf(treatmentdata.uuid) === -1 ? "Select treatment" : "Selected treatment"}
                                                                                        content={treatmentdata.description}
                                                                                        __setSelectedId={__setSelectedId}
                                                                                        disabled={""}
                                                                                        key={treat_index}
                                                                                        id={treatmentdata.id}
                                                                                        uuid={treatmentdata.uuid}
                                                                                        cat_id={treatmentdata.category_id}
                                                                                        __nextAction={__redirectToEditTreatment}
                                                                                        isActive={isActive}
                                                                                        indexValue={treatmentdata.uuid}
                                                                                        onClick={onTreatmentClick}
                                                                                    // onClick={(indexid)=>{ setIsActive(indexid===isActive ? false : indexid) }}

                                                                                    />
                                                                                )
                                                                            })
                                                                        }

                                                                    </div> {/* end accordion*/}

                                                                </div>  {/* category item1 */}


                                                            </>
                                                        )

                                                    })

                                                }


                                                <div className={formData.selectedTreatment !== undefined && formData.selectedTreatment.length === 0 ? "btn-align pd32 disable_opacity" : "btn-align pd32"}>
                                                    <LargeButton
                                                        ButtonType="submit"
                                                        ButtonText={treatmentdetailsData.isFetching ? "Loading" : "Next"}
                                                        disabled={formData.selectedTreatment !== undefined && formData.selectedTreatment.length === 0 ? true : false}
                                                        nextButtonClick={nextButtonClick}
                                                    />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </Col>
                            </Row> :
                            <div style={{ width: "100%", fontSize: 20, marginTop: 100, textAlign: 'center' }}>
                                <p>Loading ...</p>
                            </div>
                    }

                </Container>
            </section>
        </>
    );
}

export default TreatmentSelect;