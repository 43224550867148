import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS_NEW } from '../../config/ApiConstants';
export function openrequestenquiryDetailsAPI_Action(id, token) {
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/practitioner/open-request/enquiry/details/` + id,
            method: 'GET',
            headers: {

                ...AUTH_HEADERS_NEW,
                'Authorization': 'Bearer ' + token
            },
            body: '',
            types: [
                types.OPENREQUESTENQUIRYDETAILS_REQUEST,
                types.OPENREQUESTENQUIRYDETAILS_RECEIVE, {
                    type: types.OPENREQUESTENQUIRYDETAILS_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}


export const resetopenrequestenquiryDetailsAPI_Action = () => {
    return {
        type: types.RESET_OPENREQUESTENQUIRYDETAILS,
        payload: null
    }
};
