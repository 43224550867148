import React, { useCallback,useState, useEffect } from "react";
import { Container, Row, Col,Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LeftArrow, EyeView } from "../../config/images";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { getLocalStorageData, clearLocalStorage } from '../../utils/storage'
// redux
import { changepasswordAPI_Action,resetupcomingbookingAPI_Action,resetchangepasswordAPI_Action,resetLoginAPI_Action} from '../../stores/actions'


const initialErrorData = Object.freeze({
  password_error:"",
  new_password_error:"",
});

const ChangePassword = () => {

    // Initialize hooks first
  const dispatch = useDispatch();
  let history = useHistory();

  // States Management
  const [formData, updateFormData] = useState(Object.freeze({
    old_password:"",
    new_password:"",
  }));

    //state define
    const [passwordType, setPasswordType] = useState("password");
    const [eyeIcon, setEyeIcon] = useState(true);
    const [errorData, updateErrorData] = useState(initialErrorData);

    const registerData = useSelector(state => state.register);
    const Data = JSON.stringify(registerData);
    const changepasswordData = useSelector(state => state.changepassword);
    const loginUserData = useSelector(state => state.login);
    const practitionersignupaddinfoData = useSelector(state => state.practitionersignupaddinfo);

    // Use effects Similar to componentDidMount and componentDidUpdate:
    //console.log("changepassword Data: ",changepasswordData);
    

    if(changepasswordData && changepasswordData.changepassworddata!==null && changepasswordData.changepassworddata.status_code === 401){
      dispatch(resetchangepasswordAPI_Action());
      clearLocalStorage("loginData")
      clearLocalStorage("practitionersignupData")
      dispatch(resetLoginAPI_Action());
      window.scrollTo(0, 0);
      history.push('/login'); 
    }

   useEffect(() => {
    let checkToken="";
    const loginToken=loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token: "" : "";
    const PractitionerToken= practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.token:"" : "";
    const RegisterToken= registerData.registerdata ? registerData.registerdata.data ? registerData.registerdata.data.token:"" : "";
    let LocalDatatoken=getLocalStorageData("loginData")
    dispatch(resetupcomingbookingAPI_Action());
    dispatch(resetchangepasswordAPI_Action());
    if(loginToken != "")
    {
      checkToken=loginToken;
    } else if(PractitionerToken != "")
    {
      checkToken=PractitionerToken;
    }  else if(RegisterToken != "")
    {
      checkToken=RegisterToken;
    } else if (LocalDatatoken !== "") {
      checkToken = LocalDatatoken.token;
    }
    // if(loginUserData.logindata===null)
    if(checkToken == "")
    {
      window.scrollTo(0, 0);
      history.push('/login');
    } 
    
  }, []);

  if(changepasswordData.changepassworddata !==null && changepasswordData.isSuccess === true){
    dispatch(resetchangepasswordAPI_Action());
    window.scrollTo(0,0);
    history.push('/change-password-confirmation');
  }

    const checkPasswordValidation = (e) => {
      const {id,value} = e.target;
      // var decimal=  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/;
      var decimal=  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;
      
      if(!value){
          updateErrorData({
              ...errorData,
              is_form_valid:false,
              password_error: "Password Is Required."
            });
      }else if(!value.match(decimal)) 
      { 
          updateErrorData({
              ...errorData,
              is_form_valid:false,
              password_error: "Password must contain [8 to 20 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit.]"
            });
      } else{
          updateErrorData({
              ...errorData,
              is_form_valid:false,
              password_error: ""
            });
      }

      if(formData.new_password != "" && errorData.new_password_error == "")
      {
          updateErrorData({
            ...errorData,
            is_form_valid:true,
            password_error: ""
          });
      }


  }

  const checkNewPasswordValidation = (e) => {
    const {id,value} = e.target;
    // var decimal=  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/;
    var decimal=  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;
    
    if(!value){
        updateErrorData({
            ...errorData,
            is_form_valid:false,
            new_password_error: "New Password Is Required."
          });
    }else if(!value.match(decimal)) 
    { 
        updateErrorData({
            ...errorData,
            is_form_valid:false,
            new_password_error: "New Password must contain [8 to 20 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit.]"
          });
    } else{
        updateErrorData({
            ...errorData,
            is_form_valid:true,
            new_password_error: ""
          });
    }
}


  const __onChangePasswordToText = (e) => {
    setEyeIcon(!eyeIcon);
    setPasswordType( passwordType == "password" ? 'text' :'password' );
  };

  const __onTextChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.trim()
    });

    if (e.target.name === "old_password" && e.target.value !== "") {
      var decimal = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;

      if (e.target.value.match(decimal)) {
        updateErrorData({
          ...errorData,
          is_form_valid: false,
          password_error: ""
        });
      }
     }

     if (e.target.name === "new_password" && e.target.value !== "") {
      var decimal = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;

      if (e.target.value.match(decimal)) {
        updateErrorData({
          ...errorData,
          is_form_valid: false,
          new_password_error: ""
        });
      }
     }

     
  };
  const __apiCall = useCallback(async () => {
    try {
      // const loginToken=loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token: "" : "";
      let checkToken="";
      const loginToken=loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token: "" : "";
      const PractitionerToken= practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.token:"" : "";
      const RegisterToken= registerData.registerdata ? registerData.registerdata.data ? registerData.registerdata.data.token:"" : "";
      let LocalDatatoken=getLocalStorageData("loginData")
      if(loginToken != "")
      {
        checkToken=loginToken;
      } else if(PractitionerToken != "")
      {
        checkToken=PractitionerToken;
      }  else if(RegisterToken != "")
      {
        checkToken=RegisterToken;
      } else if (LocalDatatoken !== "") {
        checkToken = LocalDatatoken.token;
      }
      // if(loginUserData.logindata===null)
      if(checkToken == "")
      { window.scrollTo(0, 0);
        history.push('/login');
      } 
      await dispatch(changepasswordAPI_Action(formData,checkToken));
      // if(changepasswordData.isSuccess === true){
        //  handleShowPopup();
      // }
      // await dispatch(resetgoginipigprofileAPI_Action());
      // history.push('/user-details');
    } catch (error) {
      console.error(" profile ::  profile() :: Exception ==", error);
    }
  });

  // if(changepasswordData.isSuccess === true){
    
  //   dispatch(resetchangepasswordAPI_Action());
  //   handleShowPopup();
  // }

  const __submitChangePasswordForm = (e) => {
    e.preventDefault();
    __apiCall();
  };

  const __redirectProfileEdit = () => {
    
    let type="";
    let LocalDatatoken=getLocalStorageData("loginData")
    const loginType=loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.user.type: "" : "";
    
      const PractitionerType= practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.user.type:"" : "";
     
      const RegisterToken= registerData.registerdata ? registerData.registerdata.data ? registerData.registerdata.data.user.type:"" : "";
      
    if(loginType!="")
    {
      type=loginType;
    } else if(PractitionerType!= "")
    {
      type=PractitionerType;
    } else if(RegisterToken!="")
    {
      type=RegisterToken;
    } else if(LocalDatatoken !="")
    {
      type=LocalDatatoken.user.type
    }
    if(type === 'ginipig')
    {
      window.scrollTo(0,0);
      history.push('/ginipig-edit');
    } else if(type === 'practitioner'){
      window.scrollTo(0,0);
      
      history.push('/practitioner-account');
    } else {
      window.scrollTo(0,0);
      history.push('/login');
    }
    
  }


// console.log('error data show', changepasswordData.changepassworddata )


  return (
    <>
      <section className="loginform chngePassword">
        <Container>
          <Row>
            <Col className="col-md-10 m-auto">
                <div className="heading-history">
                  <div className="back-history">
                  <button type="button" className="back-btn"  onClick={() => history.goBack()} >
                    {" "}
                    <img src={LeftArrow} alt="arrow" />{" "}
                  </button>
                   </div>
                    <div className="history-title">
                        <h2>Change password </h2>
                    </div>
               </div>

               <div className="login-box">
               {changepasswordData.isError === true ? (
                  <p className="error topalign">
                    {!changepasswordData.changepassworddata.errors ? (
                      <p style={{ color: "red" }}>
                        {changepasswordData.changepassworddata.message}
                      </p>
                    ) : null}
                  </p>
                ) : null}                         
                        
                    

                <form onSubmit={__submitChangePasswordForm}>
                  
                  <div className="fields">
                    <label className="filed-label"> Current password </label>
                       
                    <div className="inputs">
                    
                      <input
                        type="password"
                        // value={passwordType}
                        id="old_password" 
                        name="old_password" 
                        value={formData.old_password} 
                        // onChange={(e) => passwordType(e.target.value)}
                        onChange={__onTextChange}
                        // onKeyUp={checkPasswordValidation}
                        onBlur={checkPasswordValidation}
                        autocomplete="off"
                        required 
                        minLength="8"
                        maxLength="20"
                      />

                      <div className="error-box">
                      { 
                      errorData.password_error && errorData.password_error!=="" ? (
                          <p className="error" >
                          
                          <p style={{color:"red"}}>{errorData.password_error} </p>
                          </p>
                          
                      ) : null 
                      }
                  
                  { 
                    changepasswordData.changepassworddata && changepasswordData.changepassworddata.hasOwnProperty('errors') ? (
                        <p className="error">
                        
                        {changepasswordData.changepassworddata.errors.hasOwnProperty('old_password') ? (
                            <p style={{color:"red"}}>{changepasswordData.changepassworddata.errors.old_password[0]} </p>
                        ) : null }
                        </p>
                        
                    ) : null 
                  }


                      </div>
                    </div>
                    
                  </div>
                  
                  
                  <div className="fields">
                   <label className="filed-label"> New password  </label>
                    <div className="inputs">
                      <input
                        type={passwordType}
                        // value={passwordType}
                        id="new_password" 
                        name="new_password" 
                        value={formData.new_password} 
                        // onChange={(e) => setPasswordType(e.target.value)}
                        onChange={__onTextChange}
                        // onKeyUp={checkNewPasswordValidation}
                        autocomplete="off"
                        onBlur={checkNewPasswordValidation}
                        required 
                        minLength="8"
                        maxLength="20"
                      />
                      
                      <span className="Iconview" onClick={__onChangePasswordToText}>
                        <img src={eyeIcon ? EyeView : EyeView} alt="icon" />
                      </span>

                      <div className="error-box">
                    { 
                      errorData.new_password_error && errorData.new_password_error!=="" ? (
                          <p className="error" >
                          
                          <p style={{color:"red"}}>{errorData.new_password_error} </p>
                          </p>
                          
                      ) : null 
                      }

                  { 
                    changepasswordData.changepassworddata && changepasswordData.changepassworddata.hasOwnProperty('errors') ? (
                        <p className="error">
                        {changepasswordData.changepassworddata.errors.hasOwnProperty('new_password') ? (
                            <p style={{color:"red"}}>{changepasswordData.changepassworddata.errors.new_password[0]} </p>
                        ) : null }
                        </p>
                        
                    ) : null 
                  }
                    </div>
                    </div>

                    
                  </div>
                  
                  <div className="alignButton">
                    <button type="submit"  disabled={errorData.is_form_valid === true ? false : true} className="submit">
                      Save new password
                    </button>
                  </div>
                </form>
              </div>


               
            </Col>
          </Row>
        </Container>
      </section>


    </>
  );
};

export default ChangePassword;
