import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory,Link } from "react-router-dom";
import { Container,Row,Col} from 'react-bootstrap';
import {LeftArrow,EyeView,iconClose,iconCorrect, iconDelete_peach} from '../../config/images';
import FromSidebarSiderPact from '../SideBarSlider/FromSidebarSiderPact';
import {setLocalStorageData,getLocalStorageData,clearLocalStorage} from '../../utils/storage'


// redux
import { practitionerSignupAPI_Action,categorylistAPI_Action,resetPractitionerSignupAPI_Action,companyCategorylistAPI_Action,checkemailExistsAPI_Action,resetcheckemailExistsAPI_Action,practitionerSignupSaveData_Action,resetpractitionerSignupSaveAPI_Action } from '../../stores/actions'
const initialErrorData = Object.freeze({
  email_error:"",
  email_correct:false,
  firstname_error:"",
  lastname_error:"",
  password_error:"",
  phonenumber_error:"",
  profile_pic_error:"",
  company_category_error:"",
  is_form_valid: false,
});
const PractitionerSignup = (props) => {
  // Initialize hooks first
  const dispatch = useDispatch();
  let history = useHistory();
  //state define
  const [passwordType, setPasswordType] = useState("password");
  const [eyeIcon, setEyeIcon] = useState(true);

  const __onChangePasswordToText = (e) => {
    setEyeIcon(!eyeIcon);
    setPasswordType(passwordType == "password" ? "text" : "password");
  };

  // States Management
  const [formData, updateFormData] = useState(Object.freeze({
    // practitioner_type:"Comapny",
    practitioner_type:"Academy, colleges & salons",

    first_name:"",
    last_name:"",
    email: "",
    password: "",
    password_confirmation:"",
    profile_pic:"",
    file:"",
    // telephone_no:"",
    company_name:"",
    // postcode:"",
    // sub_category:[],
    company_category:"",
    // practicing_saloon:"",
    // establishment_name:"",
    // profile_picture:"",
    displayCompany:"block",
    displayIndividual:"none",
    activeCompany:"active-tab",
    activeIndividual:"",
    is_checked_email_message:false,
    is_checked_upto_date_insurance:false,
    is_checked_terms_condition:false,
}));
const [errorData, updateErrorData] = useState(initialErrorData);
 // Selectors
 const practititionersignupData = useSelector(state => state.practititionersignup);
 const Data = JSON.stringify(practititionersignupData);
 const categoryData = useSelector(state => state.categorylist);
 const comapnycategoryData = useSelector(state => state.comapnycategorylist);
 const checkemailaexistsData = useSelector(state => state.checkemailaexists);
 const practitionerRegisterData = useSelector(state => state.practitionerregister);
  // Use effects 
  useEffect(() => {
  if(comapnycategoryData.companycategorylistdata===null)
  {
    try {
       dispatch(companyCategorylistAPI_Action());
      
    } catch (error) {
      console.error(" profile ::  profile() :: Exception ==", error);
    }
  }

  if(practitionerRegisterData.practitionerregisterdata !== null)
  {
    
    if(practitionerRegisterData.practitionerregisterdata.practitioner_type== "Individual")
  {
     updateFormData({
      ...formData,
      displayCompany:"none",
      displayIndividual:"block",
      practitioner_type: practitionerRegisterData.practitionerregisterdata.practitioner_type,
      company_category:"",
      activeCompany:"",
      activeIndividual:"active-tab",
      first_name:practitionerRegisterData.practitionerregisterdata.first_name,
      last_name:practitionerRegisterData.practitionerregisterdata.last_name,
      email: practitionerRegisterData.practitionerregisterdata.email,
      password: practitionerRegisterData.practitionerregisterdata.password,
      password_confirmation:practitionerRegisterData.practitionerregisterdata.password_confirmation,
      profile_pic:practitionerRegisterData.practitionerregisterdata.profile_pic,
      file:practitionerRegisterData.practitionerregisterdata.file,

      company_name:practitionerRegisterData.practitionerregisterdata.company_name,
      
      company_category:practitionerRegisterData.practitionerregisterdata.company_category,
      
      is_checked_email_message:practitionerRegisterData.practitionerregisterdata.is_checked_email_message,
      is_checked_upto_date_insurance:practitionerRegisterData.practitionerregisterdata.is_checked_upto_date_insurance,
      is_checked_terms_condition:practitionerRegisterData.practitionerregisterdata.is_checked_terms_condition,
    });

  } else {
     updateFormData({
      ...formData,
      displayCompany:"block",
      displayIndividual:"none",
      practitioner_type: practitionerRegisterData.practitionerregisterdata.practitioner_type,
      company_category:"",
      activeCompany:"active-tab",
      activeIndividual:"",
      first_name:practitionerRegisterData.practitionerregisterdata.first_name,
      last_name:practitionerRegisterData.practitionerregisterdata.last_name,
      email: practitionerRegisterData.practitionerregisterdata.email,
      password: practitionerRegisterData.practitionerregisterdata.password,
      password_confirmation:practitionerRegisterData.practitionerregisterdata.password_confirmation,
      profile_pic:practitionerRegisterData.practitionerregisterdata.profile_pic,
      file:practitionerRegisterData.practitionerregisterdata.file,
      company_name:practitionerRegisterData.practitionerregisterdata.company_name,
      
      company_category:practitionerRegisterData.practitionerregisterdata.company_category,
      
      is_checked_email_message:practitionerRegisterData.practitionerregisterdata.is_checked_email_message,
      is_checked_upto_date_insurance:practitionerRegisterData.practitionerregisterdata.is_checked_upto_date_insurance,
      is_checked_terms_condition:practitionerRegisterData.practitionerregisterdata.is_checked_terms_condition,
    });

  }

    updateErrorData({
      ...errorData,
      is_form_valid: true,
    });
  }
},[])

  //  if(practititionersignupData.isSuccess===true)
  //   {
  //     window.scrollTo(0,0);
  //       history.push('/practitioner-add-info');
  //   }

 const __onTextChange = (e) => {
  updateFormData({
    ...formData,
    [e.target.name]: e.target.value
  });
};

// const __onImageClick = e => {
//   updateFormData({
//     ...formData,
//     profile_pic:null,
//     file:null
//   });
// }
const __onImageChange = e => {
  
  if(e.target.files[0] != undefined)
  {
  const imageFile = e.target.files[0];
 
 
  if (!imageFile.name.match(/\.(jpg|jpeg|png|pdf)$/)) {
    updateErrorData({
      ...errorData,
      profile_pic_error:"Please select correct file type:jpg|jpeg|png|pdf",
      is_form_valid: false,
    });
    // this.setState({ invalidImage: 'Please select valid image.' });
    // return false;
  } else{
    if(e.target.files[0] != undefined)
    {
     updateFormData({
        ...formData,
        [e.target.name]: e.target.files[0],
        file: URL.createObjectURL(e.target.files[0]),
      });

      updateErrorData({
        ...errorData,
        profile_pic_error:"",
        is_form_valid: true,
      });

    } else {
      updateErrorData({
        ...errorData,
        profile_pic_error:"Please select correct file type:jpg|jpeg|png|pdf",
        is_form_valid: false,
      });
    }
  }
}

  }

  const __selectPracticingSalon = e => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.trim()
    });
  }

  const __apiCall = useCallback(async () => {
    try {
      let formDataNew= {
        //  ...formData,
          practitioner_type:formData.practitioner_type,

          first_name:formData.first_name,
          last_name:formData.last_name,
          email: formData.email,
          password: formData.password,
          password_confirmation:formData.password_confirmation,
          profile_pic:formData.profile_pic,
          file:formData.file,

          // telephone_no:formData.telephone_no,
          company_name:formData.company_name,
          // postcode:"",
          // sub_category:[],
          company_category:formData.company_category,
          // practicing_saloon:"",
          // establishment_name:"",
          // profile_picture:"",
          displayCompany:formData.displayCompany,
          displayIndividual:formData.displayIndividual,
          activeCompany:formData.activeCompany,
          activeIndividual:formData.activeIndividual,
          is_checked_email_message:formData.is_checked_email_message,
          is_checked_upto_date_insurance:formData.is_checked_upto_date_insurance,
          is_checked_terms_condition:formData.is_checked_terms_condition,


            telephone_no:practitionerRegisterData.practitionerregisterdata ? practitionerRegisterData.practitionerregisterdata.telephone_no : "",
            postcode:practitionerRegisterData.practitionerregisterdata ? practitionerRegisterData.practitionerregisterdata.postcode : "",
            postcodeValue:practitionerRegisterData.practitionerregisterdata ? practitionerRegisterData.practitionerregisterdata.postcodeValue : "",
            country:practitionerRegisterData.practitionerregisterdata ? practitionerRegisterData.practitionerregisterdata.country : "",
            city:practitionerRegisterData.practitionerregisterdata ? practitionerRegisterData.practitionerregisterdata.city : "",
            latitude:practitionerRegisterData.practitionerregisterdata ? practitionerRegisterData.practitionerregisterdata.latitude : "",
            longitude:practitionerRegisterData.practitionerregisterdata ? practitionerRegisterData.practitionerregisterdata.longitude : "",
            // company_name:"",
            sub_category:practitionerRegisterData.practitionerregisterdata ? practitionerRegisterData.practitionerregisterdata.sub_category :[],
            cat_array:practitionerRegisterData.practitionerregisterdata ? practitionerRegisterData.practitionerregisterdata.cat_array :[],

            hair_cat_array:practitionerRegisterData.practitionerregisterdata ? practitionerRegisterData.practitionerregisterdata.hair_cat_array :[],
            beauty_cat_array:practitionerRegisterData.practitionerregisterdata ? practitionerRegisterData.practitionerregisterdata.beauty_cat_array :[],
            aesthetics_cat_array:practitionerRegisterData.practitionerregisterdata ? practitionerRegisterData.practitionerregisterdata.aesthetics_cat_array :[],
            wellness_cat_array:practitionerRegisterData.practitionerregisterdata ? practitionerRegisterData.practitionerregisterdata.wellness_cat_array :[],

            practicing_saloon:practitionerRegisterData.practitionerregisterdata ? practitionerRegisterData.practitionerregisterdata.practitioner_type === formData.practitioner_type ? practitionerRegisterData.practitionerregisterdata.practicing_saloon : "" : "",
            establishment_name:practitionerRegisterData.practitionerregisterdata ? practitionerRegisterData.practitionerregisterdata.establishment_name : "",
            is_accept_notify_ginipig:practitionerRegisterData.practitionerregisterdata ? practitionerRegisterData.practitionerregisterdata.is_accept_notify_ginipig : false,
            // displayCompany:practitionerRegisterData.practitionerregisterdata ? practitionerRegisterData.practitionerregisterdata.displayCompany : formData.displayCompany,
            // displayIndividual:practitionerRegisterData.practitionerregisterdata ? practitionerRegisterData.practitionerregisterdata.displayIndividual :formData.displayIndividual,
            displayEstablishment:practitionerRegisterData.practitionerregisterdata ? practitionerRegisterData.practitionerregisterdata.displayEstablishment :formData.displayEstablishment,
            displayCompanyName:practitionerRegisterData.practitionerregisterdata ? practitionerRegisterData.practitionerregisterdata.displayCompanyName :formData.displayCompanyName,
      }
      await dispatch(practitionerSignupSaveData_Action(formDataNew));
      clearLocalStorage("practitionersignupData");
      window.scrollTo(0,0);
      history.push('/practitioner-add-info');
      
    } catch (error) {
      console.error(" profile ::  profile() :: Exception ==", error);
    }
  });


const checkMobileValidation = (e) => {
  const {id,value} = e.target;
  var decimal=  /^[0][1-9]\d{9}$|^[1-9]\d{9}$/;
  
  if(!value){
      updateErrorData({
          ...errorData,
          is_form_valid: false,
          phonenumber_error: "Phone Number Is Required."
        });
  }else if(!value.match(decimal)) 
  { 
      updateErrorData({
          ...errorData,
          is_form_valid: false,
          phonenumber_error: "Phone number must start with 0 and must be 11 digits."
        });
  } else{
      updateErrorData({
          ...errorData,
          is_form_valid: true,
          phonenumber_error: ""
        });
  }
}

const checkPasswordValidation = (e) => {
  const {id,value} = e.target;
  // var decimal=  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/;
  var decimal=  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;
  
  if(!value){
      updateErrorData({
          ...errorData,
          is_form_valid: false,
          password_error: "Password Is Required."
        });
  }else if(!value.match(decimal)) 
  { 
      updateErrorData({
          ...errorData,
          is_form_valid: false,
          password_error: "Password must contain [8 to 20 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit.]"
        });
  } else{
      updateErrorData({
          ...errorData,
          is_form_valid: true,
          password_error: ""
        });
  }
}


const __checkIsEmailExist = useCallback(async () => {
  try {
    await dispatch(checkemailExistsAPI_Action(formData));
  } catch (error) {
    console.error(" profile ::  profile() :: Exception ==", error);
  }
});
const checkEmailValidation = (e) => {

  if(practititionersignupData.isError===true)
    {
      
        dispatch(resetPractitionerSignupAPI_Action());
    }

    if(checkemailaexistsData.isError==true )
    {
      dispatch(resetcheckemailExistsAPI_Action());
    }
  const {id,value} = e.target;

  if(!value){
      updateErrorData({
          ...errorData,
          is_form_valid: false,
          email_correct:false,
          email_error: "Email is Required."
        });
   } else {
      updateErrorData({
          ...errorData,
          is_form_valid: true,
          email_correct:false,
          email_error: ""
        });
   }

   if(typeof value !== "undefined"){
      let lastAtPos = value.lastIndexOf('@');
      let lastDotPos = value.lastIndexOf('.');

      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && value.indexOf('@@') == -1 && lastDotPos > 2 && (value.length - lastDotPos) > 2)) {
      
        if(!value){
            updateErrorData({
                ...errorData,
                is_form_valid: false,
                email_correct:false,
                email_error: "Email is Required."
              });
        }
        else {
            updateErrorData({
                ...errorData,
                is_form_valid: false,
                email_correct:false,
                email_error: "Email is not valid."
              });
        }
      
       } else {
          updateErrorData({
              ...errorData,
          is_form_valid: true,
          email_correct:true,
              email_error: ""
            });
          
          __checkIsEmailExist();
       }
  } else if(value.length>50)
  {
     updateErrorData({
         ...errorData,
         is_form_valid: false,
         email_correct:false,
         email_error: "Only 50 Characters are Allowed."
       });
  } else if(value.length<6)
  {
     updateErrorData({
         ...errorData,
         is_form_valid: false,
         email_correct:false,
         email_error: "Minimum 6 Characters are Allowed."
       });
  }  else {
      updateErrorData({
          ...errorData,
          is_form_valid: true,
          email_correct:true,
          email_error: ""
        });
  }

};

const checkFirstNameValidation = (e) => {
  const {id,value} = e.target;
  if(!value){
      updateErrorData({
          ...errorData,
          is_form_valid: false,
          firstname_error: "First Name Is Required."
        });
   } else if(value.length>50)
   {
      updateErrorData({
          ...errorData,
          is_form_valid: false,
          firstname_error: "Only 50 Characters are Allowed."
        });
   } else if(!value.match(/^[a-zA-Z\-\s]+$/)){
      updateErrorData({
          ...errorData,
          is_form_valid: false,
          firstname_error: "Oops, you've added an incorrect character! Letters only "
        });
   }  else {
      updateErrorData({
          ...errorData,
          is_form_valid: true,
          firstname_error: ""
        });
   }

  
}


const checkLastNameValidation = (e) => {
  const {id,value} = e.target;
  if(!value){
      updateErrorData({
          ...errorData,
          is_form_valid: false,
          lastname_error: "Last Name Is Required."
        });
   } else if(value.length>50)
   {
      updateErrorData({
          ...errorData,
          is_form_valid: false,
          lastname_error: "Only 50 Characters are Allowed."
        });
   //} else if(!value.match(/^[a-zA-Z\-]+$/)){
   } else if(!value.match(/^[a-zA-Z\-\s]+$/)){
      updateErrorData({
          ...errorData,
          is_form_valid: false,
          lastname_error: "Oops, you've added an incorrect character! Letters only "
        });
   }else {
      updateErrorData({
          ...errorData,
          is_form_valid: true,
          lastname_error: ""
        });
   }

  
}
const deleteImg=()=>{
  updateFormData({
      ...formData,
      profile_pic:"",
      file: "",
  });
}
  const __categoryapiCall = useCallback(async () => {
    try {
      await dispatch(categorylistAPI_Action());
      
    } catch (error) {
      console.error(" profile ::  profile() :: Exception ==", error);
    }
  });

  const __companycategoryapiCall = useCallback(async () => {
    try {
      await dispatch(companyCategorylistAPI_Action());
      
    } catch (error) {
      console.error(" profile ::  profile() :: Exception ==", error);
    }
  });

const __submitPractitionerSignupForm = (e) => {
  e.preventDefault();
  if(formData.company_category!== "")
  {
    __apiCall();
  } else {
    updateErrorData({
      ...errorData,
      is_form_valid: false,
      company_category_error: "Please select Category."
    });
  }
  
};

const __setPractitionerType = async (type) => {
  if(type== "Individual")
  {
    await updateFormData({
      ...formData,
      displayCompany:"none",
      displayIndividual:"block",
      practitioner_type: type,
      company_category:"",
      activeCompany:"",
      activeIndividual:"active-tab",
    });

  } else {
    await updateFormData({
      ...formData,
      displayCompany:"block",
      displayIndividual:"none",
      practitioner_type: type,
      company_category:"",
      activeCompany:"active-tab",
      activeIndividual:"",
    });

  }

  await updateErrorData({
    ...errorData,
    is_form_valid: true,
    company_category_error: ""
  });
 
}

const __selectCompanyCategory = (event) => {
  if(practititionersignupData.isError===true)
    {
        dispatch(resetPractitionerSignupAPI_Action());
    }
  const {id,value} = event.target;
  // let companyCategory=formData.company_category;
  //   let index = companyCategory.indexOf(parseInt(value)) // check to see if the name is already stored in the array
  //   if (index === -1) {
  //     companyCategory.push(parseInt(value)) // if it isn't stored add it to the array
  //   } else {
  //     companyCategory.splice(index, 1) // if it is stored then remove it from the array
  //   }
    updateFormData({
      ...formData,
      company_category: parseInt(value)
    });
    updateErrorData({
      ...errorData,
      is_form_valid: true,
      company_category_error: ""
    });
}


const __selectSubCategory = (event) => {
  let subCategory=formData.sub_category;
  subCategory.push(parseInt(event.target.value));
  updateFormData({
    ...formData,
    sub_category: subCategory,
  });

  // formData.company_category && 
  // let fruites = this.state.fruites
  // fruites.forEach(fruite => {
  //    if (fruite.value === event.target.value)
  //       fruite.isChecked =  event.target.checked
  // })
  // this.setState({fruites: fruites})
}
    
const toggleChangeTerms_condition = (e) => {
  updateFormData({
    ...formData,
    is_checked_terms_condition: !formData.is_checked_terms_condition,
  });
};

const toggleChangeEmailMessage = (e) => {
  updateFormData({
    ...formData,
    is_checked_email_message: !formData.is_checked_email_message,
  });
};

const toggleCheckInsurance = (e) => {
  updateFormData({
    ...formData,
    is_checked_upto_date_insurance: !formData.is_checked_upto_date_insurance,
  });
};

const __redirectGinipigRegister = (e) =>{
  dispatch(resetpractitionerSignupSaveAPI_Action());
  window.scrollTo(0, 0);
  history.push('/signup');
}
const __redirectUrl = (e) => {
  dispatch(resetcheckemailExistsAPI_Action());
  history.goBack()
}

  return (
    
    <>
      <section className="bgcolor-gray common-section practitionersignup pb80 ">
        <Container>
          <Row>
            <Col className="col-lg-11 m-auto">
              <div className="navigationTop">
                <div className="heading-history">
                  <div className="back-history">
                    <button
                      type="button"
                      className="back-btn"
                      onClick={() => {
                        __redirectUrl()
                    }}
                    >
                      {" "}
                      <img src={LeftArrow} alt="arrow" />{" "}
                    </button>
                  </div>
                  <div className="history-title">
                    <h2>Partner With Us </h2>
                  </div>
                </div>
                <div className="headingbox alignright mobile-hidden">
                  <div className="next-title">
                    <Link  onClick={__redirectGinipigRegister} className="navTopRegister">
                    Register as a GiniPig
                    </Link>
                    <div className="navTopRightArrow">
                      <img src={LeftArrow} alt="icon" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="practitioner-wrp pt20">
                <form onSubmit={__submitPractitionerSignupForm}>
                  <div className="practitioner-from register-wrp">
                      <div className="one box">
                        <div className="form-wrp fromProfile">
                            <div className="inside-form">
                                <div className="formtab-box">
                                  <p className="label-title">Select the category applicable to you</p>
                                  <div className="two-cols">
                                      {/* <button className="tab-btns camp-btn active-tab" onClick={(e)=>__setPractitionerType('Comapny')}>Business </button>
                                      <button className="tab-btns indi-btn" onClick={(e)=>__setPractitionerType('Individual')}>Individual </button> */}
                                      {/* {
                                        comapnycategoryData.companycategorylistdata && comapnycategoryData.companycategorylistdata.data.practitioner_types.map((item,index)=>{
                                          return (
                                                  <button className="tab-btns camp-btn active-tab" key={item.id} onClick={(e)=>__setPractitionerType(item.name)}>{item.name}</button>
                                            )
                                        })
                                      } */}

                                      <button type="button" className={"tab-btns camp-btn "+formData.activeCompany} onClick={(e)=>__setPractitionerType(comapnycategoryData.companycategorylistdata && comapnycategoryData.companycategorylistdata.data.practitioner_types[0].name)}>Business</button>
                                      <button type="button" className={"tab-btns indi-btn "+formData.activeIndividual} onClick={(e)=>__setPractitionerType(comapnycategoryData.companycategorylistdata && comapnycategoryData.companycategorylistdata.data.practitioner_types[1].name)}>{comapnycategoryData.companycategorylistdata && comapnycategoryData.companycategorylistdata.data.practitioner_types[1].name}  </button>
                                  </div>
                                </div>
                                <div className="profilefield">
                                  <div className="showWithLabal">
                                    <label className="profile-label">First name</label>
                                    { 
                                        errorData.firstname_error && errorData.firstname_error!="" ? (
                                            <p className="error" >
                                            
                                            <p style={{color:"red"}}>{errorData.firstname_error} </p>
                                            </p>
                                            
                                        ) : null 
                                    }

                                      { 
                                          practititionersignupData.practitionersignupdata && practititionersignupData.practitionersignupdata.hasOwnProperty('errors') ? (
                                              <p className="error">
                                              
                                              {practititionersignupData.practitionersignupdata.errors.hasOwnProperty('first_name') ? (
                                                  <p style={{color:"red"}}>{practititionersignupData.practitionersignupdata.errors.first_name[0]} </p>
                                              ) : null }
                                              </p>
                                              
                                          ) : null 
                                      }

                                  </div>
                                  <input 
                                    type="text"  
                                    id="first_name" 
                                    name="first_name" 
                                    value={formData.first_name}
                                    onChange={__onTextChange} 
                                    // onKeyUp={checkFirstNameValidation}
                                    onBlur={checkFirstNameValidation}
                                    autoComplete="off"
                                    maxLength="50"
                                    required
                                  />
                                </div>
                                <div className="profilefield">
                                  <div className="showWithLabal">
                                    <label className="profile-label">Last name</label>
                                    { 
                      errorData.lastname_error && errorData.lastname_error!="" ? (
                              <p className="error" >
                              
                              <p style={{color:"red"}}>{errorData.lastname_error} </p>
                              </p>
                              
                          ) : null 
                      }

                      { 
                      practititionersignupData.practitionersignupdata && practititionersignupData.practitionersignupdata.hasOwnProperty('errors') ? (
                          <p className="error">
                          
                          {practititionersignupData.practitionersignupdata.errors.hasOwnProperty('last_name') ? (
                              <p style={{color:"red"}}>{practititionersignupData.practitionersignupdata.errors.last_name[0]} </p>
                          ) : null }
                          </p>
                          
                      ) : null 
                      }

                                  </div>
                                  <input 
                                    type="text"  
                                    id="last_name" 
                                    name="last_name"
                                    value={formData.last_name}
                                    onChange={__onTextChange} 
                                    // onKeyUp={checkLastNameValidation}
                                    onBlur={checkLastNameValidation}
                                    autoComplete="off"
                                    maxLength="50"
                                    required
                                  />
                                </div>
                                <div className="formtab-box">
                                  
                                  <div className="inside-data">

              <div className="show-data-company" style={{display:formData.displayCompany}}>
                <p className="label-title">Select the category that applies </p>
                <div className="boxgrid">
                {
                  comapnycategoryData.companycategorylistdata && comapnycategoryData.companycategorylistdata.data.practitioner_types[0].companytypecategory.data.map((sub_item,sub_index)=>{
                    return (
                            
                              <div className="checkbox-button" key={sub_index}>
                                <input type="checkbox"  className={formData.company_category === sub_item.id ? "company-checked yes-checked" : "company-checked"} id={sub_item.id} name={sub_item.id} checked={formData.company_category === sub_item.id ? true : false } onClick={(e)=>__selectCompanyCategory(e)} value={sub_item.id} />
                                <label htmlFor={sub_item.id} className="company-name">{sub_item.id === 4 ? "Beauty salon":sub_item.name}</label>
                              </div>
                      )
                  })
                }
                </div>
                

                </div>
                

                <div className="show-data-individual" style={{display:formData.displayIndividual}}>
                  <p className="label-title">Select the category that applies </p> 
                <div className="boxgrid">
                {
                  comapnycategoryData.companycategorylistdata && comapnycategoryData.companycategorylistdata.data.practitioner_types[1].companytypecategory.data.map((sub_item,sub_index)=>{
                    return (
                            <div className="checkbox-button" key={sub_index} > 
                              <input type="checkbox" className={formData.company_category=== sub_item.id ? "individual-checked yes-checked" : "individual-checked"} id={sub_item.id} name={sub_item.id} checked={formData.company_category=== sub_item.id ? true : false } onClick={(e)=>__selectCompanyCategory(e)} value={sub_item.id} />
                              <label htmlFor={sub_item.id} className="individual-name">{sub_item.name}</label>
                            </div>
                      )
                  })
                }
                </div>
                </div>

                { 
                      errorData.company_category_error && errorData.company_category_error!="" ? (
                              <p className="error" >
                              
                              <p style={{color:"red"}}>{errorData.company_category_error} </p>
                              </p>
                              
                          ) : null 
                      }

                { 
                    practititionersignupData.practitionersignupdata && practititionersignupData.practitionersignupdata.hasOwnProperty('errors') ? (
                        <p className="error">
                        
                        {practititionersignupData.practitionersignupdata.errors.hasOwnProperty('company_category') ? (
                            <p style={{color:"red"}}>{practititionersignupData.practitionersignupdata.errors.company_category[0]} </p>
                        ) : null }
                        </p>
                        
                    ) : null 
                }
                                  
                                  </div>
                                </div>

                                <div className="profilefield" style={{display:formData.displayCompany}}>
                                  <div className="showWithLabal-option">
                                    <label className="profile-label">Company name </label>
                                    <small className="option-validation">optional</small>
                                  </div>
                                  <input 
                                    type="text" 
                                    id="company_name" 
                                    name="company_name" 
                                    value={formData.company_name}
                                    onChange={__onTextChange}
                                  />
                                </div>

                                <div className="profilefield">
                                  <div className="showWithLabal">
                                    <label className="profile-label">Email address </label>

                                    { 
                    errorData.email_error && errorData.email_error!="" ? (
                        <p className="error" >
                        
                        <p style={{color:"red"}}>{errorData.email_error} </p>
                        </p>
                        
                    ) : null 
                    }

                    { 
                    practititionersignupData.practitionersignupdata && practititionersignupData.practitionersignupdata.hasOwnProperty('errors') ? (
                        <p className="error" >
                        
                        {practititionersignupData.practitionersignupdata.errors.hasOwnProperty('email') ? (
                            <p style={{color:"red"}}>{practititionersignupData.practitionersignupdata.errors.email[0]} </p>
                        ) : null }
                        </p>
                        
                    ) : null 
                    }

                    {
                        checkemailaexistsData.isError==true && checkemailaexistsData.checkemailexistsdata.status_code!=200 && checkemailaexistsData.checkemailexistsdata.message !="" ? (
                        <p className="error">
                        
                            <p style={{color:"red"}}>{checkemailaexistsData.checkemailexistsdata.message} </p>
                        
                        </p>

                        ) : null
                    }

                                  </div>
                                  <input 
                                    type="email" 
                                    id="email" 
                                    name="email" 
                                    value={formData.email}
                                    onChange={__onTextChange} 
                                    
                                    // onKeyUp={checkEmailValidation}
                                    onBlur={checkEmailValidation}
                                    autoComplete="off"
                                    required 
                                    minLength="6"
                                    maxLength="50"
                                  />

                          {/* <span className="checkerroricon">
                            {errorData.email_error &&
                            errorData.email_error != "" ? (
                              <img src={iconClose} alt="icon" className="erroricon warning" style={{"display":"none"}}/>
                            ) : formData.email && formData.email!="" ? checkemailaexistsData.isError==false && errorData.email_correct === true ? (
                              <img src={iconCorrect} alt="icon" className="erroricon succusess" style={{"display":"block"}} />
                            ) : (
                              <img src={iconClose} alt="icon" className="erroricon warning" style={{"display":"none"}} />
                            ): (
                              <img src={iconClose} alt="icon" className="erroricon warning" style={{"display":"none"}} />
                            )}
                            
                            
                          </span> */}
                                </div>

                                <div className="profilefield">
                                  <div className="showWithLabal">
                                    <label className="profile-label">Password  </label>

                                    {errorData.password_error &&
                            errorData.password_error != "" ? (
                              <p className="error">
                                <p style={{ color: "red" }}>
                                  {errorData.password_error}{" "}
                                </p>
                              </p>
                            ) : null}

                            {practititionersignupData.practitionersignupdata &&
                            practititionersignupData.practitionersignupdata.hasOwnProperty(
                              "errors"
                            ) ? (
                              <p className="error">
                                {practititionersignupData.practitionersignupdata.errors.hasOwnProperty(
                                  "password"
                                ) ? (
                                  <p style={{ color: "red" }}>
                                    {
                                      practititionersignupData.practitionersignupdata.errors
                                        .password[0]
                                    }{" "}
                                  </p>
                                ) : null}
                              </p>
                            ) : null}
                                  </div>
                                  <div className="addicon">
                                  <input 
                                    type={passwordType}  
                                    sec
                                    id="password" 
                                    name="password" 
                                    value={formData.password}
                                    onChange={__onTextChange}
                                    // onKeyUp={checkPasswordValidation}
                                    onBlur={checkPasswordValidation}
                                    required 
                                    minLength="8"
                                    maxLength="20"
                                  />
                                  <span
                                    className="eyeicon"
                                    onClick={__onChangePasswordToText}
                                  >
                                    <img
                                      src={eyeIcon ? EyeView : EyeView}
                                      alt="icon"
                                    />
                                  </span>
                                  </div>
                                </div>

                            </div>
                        </div>
                      </div>
                      <div className="two box">
                        <div className="form-wrp media-profile">
                        {errorData.profile_pic_error &&
                            errorData.profile_pic_error != "" ? (
                              <p className="error">
                                <p style={{ color: "red" }}>
                                  {errorData.profile_pic_error}{" "}
                                </p>
                              </p>
                            ) : null}

                        {practititionersignupData.practitionersignupdata &&
                        practititionersignupData.practitionersignupdata.hasOwnProperty("errors") ? (
                          <p className="error">
                            {practititionersignupData.practitionersignupdata.errors.hasOwnProperty(
                              "profile_pic"
                            ) ? (
                              <p style={{ color: "red" }}>
                                {
                                  practititionersignupData.practitionersignupdata.errors
                                    .profile_pic[0]
                                }{" "}
                              </p>
                            ) : null}
                          </p>
                        ) : null}
                        <div className="inside-media">
                          <div className="media-img">
                          {
                                formData.file != "" ? (
                                  <>
                                  <img src={formData.file} className="form-control1" />
                                  <img src={iconDelete_peach} className="remove-icon"  onClick={deleteImg} />
                                  </>
                                ) : null
                          }
                              
                            <div className="upload-area">
                              <div className="media-btns uploadbtn-postion">
                              <input
                                type="file"  
                                id="profile_pic" 
                                name="profile_pic" 
                                // onClick={__onImageClick}
                                onChange={__onImageChange}
                              />
                                <span className="upload-profile">{formData.file !== "" ? "Edit" : "Upload"}</span>
                              </div>
                            </div>
                          </div>
                          <div className="media-content">
                            <div className="media-decs">
                              <h3> Profile picture </h3>
                              <p>
                                To help with treatments, please make this photo
                                as clear and as up to date as possible.{" "}
                              </p>
                              
                            </div>
                          </div>

                                    
                        </div>
                        </div>
                      </div>
                      <div className="three box">
                        <div className="form-wrp checkmark-wrp">
                          <div className="check-wrp">
                          <div className="inside-checkmark">
                            <div className="checkbox-group">
                              
                            <label htmlFor="check1">
                            <div className="checkinput">
                              <input 
                              className="tnc"
                                type="checkbox"
                                id="check1"
                                //onClick={toggleChangeTerms_condition}
                                onChange={(e) => {
                                  toggleChangeTerms_condition(e)
                                }}

                                checked={formData.is_checked_terms_condition === true ? true : false}
                                required
                              />
                              </div>
                              <span className="checkbox-decs"> I agree to the Terms & Conditions and Privacy
                              Policy of GoGiniPig. </span>
                              </label>
                            </div>
                            <div className="checkbox-group">
                              
                            <label htmlFor="check2">
                            <div className="checkinput">
                              <input
                              className="tnc"
                                type="checkbox"
                                id="check2"
                                //onClick={toggleChangeEmailMessage}
                                onChange={(e) => {
                                  toggleChangeEmailMessage(e)
                                }}
                                checked={formData.is_checked_email_message === true ? true : false}
                                required
                              />
                              </div>
                              <span className="checkbox-decs">I agree to being contacted and updated via email.  </span>
                              </label>
                            </div>
                            <div className="checkbox-group">
                            
                            <label htmlFor="check3">
                            <div className="checkinput">
                              <input
                              className="tnc"
                                type="checkbox"
                                id="check3"
                                //onClick={toggleCheckInsurance}
                                onChange={(e) => {
                                  toggleCheckInsurance(e)
                                }}
                                checked={formData.is_checked_upto_date_insurance === true ? true : false}
                                required
                              />
                              </div>
                              <span className="checkbox-decs">I can confirm I have the relevant and up to date insurance/license to cover the services I am offering through GoGiniPig 
                              </span>
                              </label>
                            </div>
                          </div>
                          <div className="alignbtn-signup">
                            

                            {
                                practititionersignupData.isFetching 
                                ? <button  className="signUpbtn" disabled={true}> Loading... </button>  
                                : <button type="submit" className="signUpbtn" 
                                disabled={formData.is_checked_terms_condition===true && formData.is_checked_email_message ===true && formData.is_checked_upto_date_insurance ===true && errorData.is_form_valid === true && practititionersignupData.isError === false && checkemailaexistsData.isError==false && checkemailaexistsData.checkemailexistsdata != null ? checkemailaexistsData.checkemailexistsdata.status_code==200 && checkemailaexistsData.checkemailexistsdata.message !=="" ? false :true : true}> Next </button> 
                            }

                          </div>

                          </div>
                        </div>
                      </div>
                      <div className="four box">
                          <div className="signupslider">
                            <FromSidebarSiderPact />
                          </div>
                      </div>
                  </div>
                </form>  
              </div>
            </Col>
          </Row>
        </Container>
      </section>

    </>
  );
};

export default PractitionerSignup;