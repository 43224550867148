import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS } from '../../config/ApiConstants';
export function completebookingallAPI_Action(data,token) {
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/practitioner/confirm/all`,
            method: 'POST',
            headers: {
                ...AUTH_HEADERS,
                'Authorization':'Bearer '+token
            },
            body:JSON.stringify(data),
            types: [
                types.COMPLETEBOOKINGALL_REQUEST,
                types.COMPLETEBOOKINGALL_RECEIVE, {
                    type: types.COMPLETEBOOKINGALL_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}


export const resetcompletebookingallAPI_Action = () => {
    return {
        type: types.RESET_COMPLETEBOOKINGALL,
        payload: null
    }
};
