import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS } from '../../config/ApiConstants';
export function deleterequestAPI_Action(id,token) {
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/request/remove/`+id,
            method: 'GET',
            headers: {

                ...AUTH_HEADERS,
                'Authorization': 'Bearer ' + token
            },
            body:'',
            types: [
                types.DELETEREQUEST_REQUEST,
                types.DELETEREQUEST_RECEIVE, {
                    type: types.DELETEREQUEST_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}


export const resetdeleterequestAPI_Action = () => {
    return {
        type: types.RESET_DELETEREQUEST,
        payload: null
    }
};
