import React from 'react';
import { Link } from 'react-router-dom';
import ProfileReviewCard from '../../components/UIComponent/ProfileReviewCard';
import { S3_BUCKET_LINK } from '../../config/ApiConstants'
import { star, reviewAvatar, ginipic_avtar, place_holder, icon_suitability, icon_reliability, icon_etiquette } from '../../config/images';
import TransparentButton from "../../components/UIComponent/TransparentButton";
const CloseRequestAcceptCancelCard = (props) => {
    return (
        <>

            <div className="grids_2box accept_cancelcard mt-16">
                <div className="one-div">
                    <div className="upcoming_cards  ">
                        <div className="pad8 upside_content ">
                            <div className="content_box">
                                {props.set_date ?
                                    <div className="username_box_cancel">
                                        <div className="user_avatar">
                                            {
                                                props.userImg !== null ? props.userImg !== "" ? (<img className="img_avtar" src={S3_BUCKET_LINK + props.userImg} />) : null : <img src={ginipic_avtar} className="img_avtar" />
                                            }
                                        </div>
                                        <div className="user_text">
                                            <span>{props.userName}</span>

                                            <div className="ratingbox">
                                                <img src={props.ratingImg} alt="raing icon" />
                                                {
                                                    props.ratingPoint == 0 ?
                                                        <span className="rating-point">(0)</span> :
                                                        <span className="rating-point">({props.ratingPoint})</span>
                                                }

                                            </div>
                                        </div>

                                        <div className='date-cancel'>
                                            <p className="slot_booking">
                                                {props.set_date}
                                            </p>
                                        </div>
                                    </div> :

                                    <div className="username_box">
                                        <div className="user_avatar">
                                            {
                                                props.userImg !== null ? props.userImg !== "" ? (<img className="img_avtar" src={S3_BUCKET_LINK + props.userImg} />) : null : <img src={ginipic_avtar} className="img_avtar" />
                                            }


                                        </div>
                                        <div className="user_text">
                                            <span>{props.userName}</span>

                                            <div className="ratingbox">
                                                <img src={props.ratingImg} alt="raing icon" />
                                                {
                                                    props.ratingPoint == 0 ?
                                                        <span className="rating-point">(0)</span> :
                                                        <span className="rating-point">({props.ratingPoint})</span>
                                                }

                                            </div>
                                        </div>

                                    </div>

                                }
                                {
                                    props.showImagearrow ?
                                        <div className="right_arrow" onClick={props.arrowButtonClick}>
                                            <img src={props.imgArrow} className="arrow_icon" />
                                        </div> : null

                                }



                            </div>
                        </div>
                        <div className={props.status === "cancel" ? "display-none" : "pad8 downside_content border_top"}>
                            <div className="rating_view gini-review">
                                <div className="rating-item">
                                    <ProfileReviewCard
                                        ImgIcon={icon_reliability}
                                        ratingName="Reliability"
                                        initialRating={props.reliabilityCount}

                                    />
                                </div>
                                <div className="rating-item">
                                    <ProfileReviewCard
                                        ImgIcon={icon_etiquette}
                                        ratingName="Friendliness"
                                        initialRating={props.etiquetteCount}

                                    />

                                </div>
                                {/* <div className="rating-item">
                                    <ProfileReviewCard
                                        ImgIcon={icon_suitability}
                                        ratingName="Suitability"
                                        initialRating={props.suitabilityCount}

                                    />
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="two-div">
                    <div className="accept disable_opacity_0">
                        <div className="two-col">
                            <div className={props.addClassConfirmHide === "hide" ? "first-div hide-confirm-btn1" : "first-div "}    >

                                {/* {props.AcceptButtonText &&
                                    <TransparentButton
                                        ButtonType="button"
                                        ButtonClick={props.acceptButtonClick}
                                        ButtonText={props.AcceptButtonText} />

                                } */}

                                {props.newArray === false ?
                                    <TransparentButton
                                        ButtonType="button"
                                        ButtonClick={props.acceptButtonClick}
                                        ButtonText={props.AcceptButtonText} /> :

                                    props.AcceptButtonText &&
                                    <TransparentButton
                                        ButtonType="button"
                                        ButtonClick={props.acceptButtonClick}
                                        ButtonText={props.AcceptButtonText} />
                                }

                            </div>
                            <div className="sec-div">
                                {props.DeclineButtonText &&
                                    <TransparentButton
                                        ButtonType="button"
                                        ButtonClick={props.declineButtonClick}
                                        ButtonText={props.DeclineButtonText} />
                                }
                                {
                                    props.ContectGoginipigLink === false &&
                                    <div className="contact-person" onClick={props.clickonDontHappen}> <p> No Show </p></div>
                                    //  <div className="contact-person" onClick={props.clickonDontHappen}> <p> Appointment didn't occur </p></div>
                                }
                                {
                                    props.ContectGoginipigLink === true &&
                                    <div className="contact-person"> <p> This has been moved to past bookings </p></div>
                                    // <div className="contact-person"> <p> No Show </p></div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default CloseRequestAcceptCancelCard;