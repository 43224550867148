import React, { useCallback, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Link, Redirect } from "react-router-dom"
import { Container, Row, Col } from 'react-bootstrap';
import { LeftArrow, iconClose, iconCorrect } from '../../config/images';
import usePlacesAutocomplete, { getGeocode, getLatLng, getZipCode } from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';
import FromSidebarSiderPact from '../SideBarSlider/FromSidebarSiderPact';
// redux
import { practitionerSignupAddInfoAPI_Action, categorylistAPI_Action, companyCategorylistAPI_Action, resetPractitionerAddInfoAPI_Action, validatePostcodeAPI_Action, resetvalidatePostcodeAPI_Action, resetpractitionerSignupSaveAPI_Action, practitionerSignupSaveData_Action, resettreatmentdetailsAPI_Action, resetcreateAppointmentData_Action,resetStripeAccountValidityAPI_Action } from '../../stores/actions'
import {setLocalStorageData,getLocalStorageData,clearLocalStorage} from '../../utils/storage'

const initialErrorData = Object.freeze({
  postcode_error: "",
  establishment_error: "",
  phonenumber_error: "",
  company_name_error: "",
  practitioner_salon_error: "",
  subcategory_error: "",
  accept_ginipig_error: "",
  is_form_valid: false,
  is_form_valid_postcode:false  
});
const PractitionerSignupAddInfo = (props) => {
  // Initialize hooks first
  const dispatch = useDispatch();
  let history = useHistory();

  // States Management
  const [formData, updateFormData] = useState(Object.freeze({
    telephone_no: "",
    postcode: "",
    postcodeValue: "",
    country: "",
    city: "",
    latitude: "",
    longitude: "",
    company_name: "",
    sub_category: [],
    cat_array: [],
    hair_cat_array: [],
    beauty_cat_array: [],
    aesthetics_cat_array: [],
    wellness_cat_array: [],
    show_postcode:false,
    practicing_saloon: 0,
    establishment_name: "",
    is_accept_notify_ginipig: false,
    displayCompany: "block",
    displayIndividual: "none",
    displayEstablishment: "none",
    displayCompanyName: "none",
    // profile_picture:"",
    is_set_data: true,
    flat_build_no:'',
    street_name:'',
    town_city:''

  }));

  const [isaddress, setShowIsAddress] = useState(false);
  const [istotaldesc, setShowIsTotalDescription] = useState('');
  
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
      componentRestrictions: {
        country: 'uk',
        city: false
      }
    },
    debounce: 300,
    // componentRestrictions: {country: "us"}
    callbackName: 'initMap'
  });
  const registerRef = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = e => {
    // Update the keyword of the input element
   // console.log("value: ", e.target.value);
    if(isaddress){
      setShowIsAddress(false)
    }
    setValue(e.target.value);

    
    updateFormData({
      ...formData,
      postcode: "",
      postcodeValue: e.target.value
    });
    
  };

  const handleSelect = ({ description },secondary_text,main_text) => () => {
 
    if(description){
      setShowIsAddress(true)
      setShowIsTotalDescription(description)
    }
    setValue(description, false);
    clearSuggestions();
 
    let latitude = "";
    let longitude = "";
    let city = "";
    let country = "";
    let street_address = '';
    getGeocode({ address: description })

      .then((results) => {
        //  let latlong= getLatLng(results[0]);
        //  let zipcode = getZipCode(results[0]);
        // console.log("results:", results[0].address_components);

        let city_name = results[0].address_components.map((item, key) => {

          if (item.types.indexOf("postal_town") != -1) {
            city = item.long_name;
          } else {
            return null;
          }

        });


        let country_name = results[0].address_components.map((item, key) => {

          if (item.types.indexOf("country") != -1) {
            country = item.long_name;
          } else {
            return null;
          }

        });
        latitude = results[0].geometry.location.lat();
        longitude = results[0].geometry.location.lng();
        // console.log("city:", city, country);
        street_address = description.replace(", UK","");
        street_address = street_address.replace(", "+city,"");

        if (city === "") {
          updateErrorData({
              ...errorData,
              is_form_valid_postcode: true,
              postcode_error: "Please select a valid postcode"
          });
      } else {
          updateErrorData({
              ...errorData,
              is_form_valid_postcode: false,
              postcode_error: ""
          });
      }
       
      //  console.log("suggestion ", description,' s ',secondary_text,' m ',main_text, ' street_address ', street_address)
    

        // updateFormData({
        //     ...formData,
        //     latitude:latitude,
        //     longitude:longitude
        //   });
        //  console.log("1st latlong: ",latlong);
        //  console.log("1st zipcode: ",zipcode);
        // let country=results[0].fetchPlace('country');
        //   console.log("country:",results[0]);
        return Promise.resolve(results);
      })
      .then((results) => getZipCode(results[0]))
      .then((zipCode) => {


        let zipcode = zipCode;

        if(zipcode != null && zipcode.length < 6){
          var abc =  street_address.substring(street_address.indexOf(zipcode));
          zipcode = abc;
          
        }
        if(zipcode!==null){
          street_address = street_address.replace(zipcode, "");
          if(zipcode.length<6){
            updateErrorData({
              ...errorData,
              is_form_valid: false,
              is_form_valid_postcode:true,
              postcode_error: "Post Code should be minimum 5 characters."
            });
          }else {
            updateErrorData({
              ...errorData,
              is_form_valid: true,
              is_form_valid_postcode:false,
              postcode_error: ""
            });
          }
        
          updateFormData({
              ...formData,
              latitude: latitude,
              longitude: longitude,
              postcode: zipcode,
              // postcodeValue: description,
              postcodeValue: zipcode,
              country: country,
              city: city,
              locationprofile: "",
              street_name: street_address,
              town_city: city
          });
         }else{
          updateErrorData({
            ...errorData,
            is_form_valid_postcode: true,
            postcode_error: "Postcode cannot be found, please enter address manually below."
        });
        setShowIsAddress(true)
          let postcodeValue1=formData.postcodeValue
          var postcodenew=""
          if(postcodeValue1!==null){
              var fourth = postcodeValue1.substr(postcodeValue1.length - 4);
              var lastThree = postcodeValue1.substr(postcodeValue1.length - 3);
              var first = postcodeValue1.substr(0,postcodeValue1.length - 3);
              var  postcodenew1=first+" "+lastThree
              if(fourth.substr(0,1)===" "){
                 
                  postcodenew=formData.postcodeValue.toUpperCase()
              }else{
                  postcodenew=postcodenew1.toUpperCase()
              }
             
          }
          street_address = street_address.replace(postcodenew, "");
          if(postcodenew.length<6 ){
            updateErrorData({
              ...errorData,
              is_form_valid: false,
              postcode_error: "Post Code should be minimum 5 characters."
            });
          }else {
            updateErrorData({
              ...errorData,
              is_form_valid: true,
              postcode_error: ""
            });
          }
        
          updateFormData({
              ...formData,
              latitude: latitude,
              longitude: longitude,
              postcode: postcodenew,
              // postcodeValue: description,
              postcodeValue:postcodenew,
              country: country,
              city: city,
              locationprofile: "",
              street_name: street_address,
              town_city: city
          });
         }
        setValue(zipcode,false)
        
      })
      // .then((results) => getLatLng(results[0]))
      // .then(({ lat, lng }) => {
      //   console.log("📍 Coordinates: ", { lat, lng });
      // })
      .catch((error) => {
        console.log("Error: ", error);
      });
  };


  const renderSuggestions = () =>
    data.map(suggestion => {
      
      const {id,structured_formatting: { main_text, secondary_text },} = suggestion;

      return (
        <li
          key={id}
          onClick={handleSelect(suggestion,secondary_text,main_text)}
        >
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  const [errorData, updateErrorData] = useState(initialErrorData);
  // Selectors
  const practititionersignupData = useSelector(state => state.practititionersignup);
  const Data = JSON.stringify(practititionersignupData);
  const categoryData = useSelector(state => state.categorylist);
  const comapnycategoryData = useSelector(state => state.comapnycategorylist);
  const loginUserData = useSelector(state => state.login);
  const practitionersignupaddinfoData = useSelector(state => state.practitionersignupaddinfo);
  const validatepostcodeData = useSelector(state => state.validatepostcode);
  const practitionerRegisterData = useSelector(state => state.practitionerregister);
  const [isOpen, setIsOpen] = useState(false);
  const [stripeUrl, setStripeUrl] = useState("https://connect.stripe.com/express/oauth/authorize?redirect_uri=https://goginipig-middleware.local/stripe/practitioner-register&client_id=ca_HZDn3IUerFo6eDOziXTd5kwErqFnYd54&state=eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiZjU4NTkzMjg5ZmJjNDgxMGE5MDA5NGQ1ODFmNDJiY2Y0N2U1ODQwMmI2NjlmYTlhNWQ4NTE3ZDg3NDE3OTkxNWFkNjI2MTE1NzY4YmQ3NjUiLCJpYXQiOjE2MDE0Njc2NjIsIm5iZiI6MTYwMTQ2NzY2MiwiZXhwIjoxNjA5MzMzNjYxLCJzdWIiOiI2NCIsInNjb3BlcyI6W119.Q2B9-zUSsX1kEnG12GBpwDArZM4nyKqlTe9p3CB3gAxWwXVic_ju9Efw3PDddTM7GiIpubbPcCr_fKSmflo8SBmhZKh--76jXDUxeq-VJ_xbWgPypz6xRvmL1MUtqBWUVJ5SjupO2Fh_ujKfRnMmKCLlTZv1JSMaxmxymxjp8CKFWfy6xHywAFiCid_uaga8fx7phdTwhC_xFDQug_qrsdkYApzKxdsMHZS5aEnpQkL20gGw6lMzMBNsSU6-0rgv-08EyJ7gT5L0vZe_1NzGX3W_3xCdcE5qqOW9BdqzScAE-FrRFmeDtYYyEUXCQU77gUUfupPk0Ie1gRIzxUpyjXhcnCBJGe5DjGGrwkbMidAgvGpm5GPchvB3Gv28KrhMBXAMNxbSHE3Up8qR8BIH-Et7FFymu-MPGy4rtx392C3e2iFB41xpbguZR98_dOnHH63HvfP4MucR2eJR8Vpp32HqYjnOkN-pieTW0hfyv4EcLecwQpDaIVG1MgLtfr1KF1gadbh84fNXesbdids71TbLA83sp0i3QxjOg4ukEAG94OaMNzkiEibVZ0CQn6pTlfirlQmBvBnzjorXZXnl4h5DQErnW1uVe-DuMC8xteY8gr3S-FenbmYcFTBsqPfw-6AJi-9DWSBjLzeu6aEIv0dKslXwDyPwUDS-43PfJ2Y");
  // Use effects 
  useEffect(() => {
    if (practitionerRegisterData.practitionerregisterdata === null) {
      window.scrollTo(0, 0);
      history.push('/login');
    } else {
      if (practitionerRegisterData.practitionerregisterdata && practitionerRegisterData.practitionerregisterdata.practitioner_type !== 'Individual') {
        if(practitionerRegisterData.practitionerregisterdata && practitionerRegisterData.practitionerregisterdata.show_postcode===true){
          setShowIsAddress(true)
        }
        updateFormData({
          ...formData,
          company_name: practitionerRegisterData.practitionerregisterdata.company_name,

          telephone_no: practitionerRegisterData.practitionerregisterdata.telephone_no ? practitionerRegisterData.practitionerregisterdata.telephone_no : "",
          postcode: practitionerRegisterData.practitionerregisterdata.postcode ? practitionerRegisterData.practitionerregisterdata.postcode : "",
          postcodeValue: practitionerRegisterData.practitionerregisterdata.postcodeValue ? practitionerRegisterData.practitionerregisterdata.postcodeValue : "",
          latitude: practitionerRegisterData.practitionerregisterdata.latitude ? practitionerRegisterData.practitionerregisterdata.latitude : "",
          longitude: practitionerRegisterData.practitionerregisterdata.longitude ? practitionerRegisterData.practitionerregisterdata.longitude : "",
          country: practitionerRegisterData.practitionerregisterdata.country ? practitionerRegisterData.practitionerregisterdata.country : "",
          city: practitionerRegisterData.practitionerregisterdata.city ? practitionerRegisterData.practitionerregisterdata.city : "",
          // company_name:"",
          sub_category: practitionerRegisterData.practitionerregisterdata.sub_category ? practitionerRegisterData.practitionerregisterdata.sub_category : [],
          cat_array: practitionerRegisterData.practitionerregisterdata.cat_array ? practitionerRegisterData.practitionerregisterdata.cat_array : [],
          hair_cat_array: practitionerRegisterData.practitionerregisterdata.hair_cat_array ? practitionerRegisterData.practitionerregisterdata.hair_cat_array : [],
          beauty_cat_array: practitionerRegisterData.practitionerregisterdata.beauty_cat_array ? practitionerRegisterData.practitionerregisterdata.beauty_cat_array : [],
          aesthetics_cat_array: practitionerRegisterData.practitionerregisterdata.aesthetics_cat_array ? practitionerRegisterData.practitionerregisterdata.aesthetics_cat_array : [],
          wellness_cat_array: practitionerRegisterData.practitionerregisterdata.wellness_cat_array ? practitionerRegisterData.practitionerregisterdata.wellness_cat_array : [],
          practicing_saloon: practitionerRegisterData.practitionerregisterdata.practicing_saloon ? practitionerRegisterData.practitionerregisterdata.practicing_saloon : 0,
          establishment_name: practitionerRegisterData.practitionerregisterdata.establishment_name ? practitionerRegisterData.practitionerregisterdata.establishment_name : "",
          is_accept_notify_ginipig: practitionerRegisterData.practitionerregisterdata.is_accept_notify_ginipig ? practitionerRegisterData.practitionerregisterdata.is_accept_notify_ginipig : false,
          displayCompany: practitionerRegisterData.practitionerregisterdata.displayCompany ? practitionerRegisterData.practitionerregisterdata.displayCompany === "block" ? "block" : "block" : "block",
          displayIndividual: practitionerRegisterData.practitionerregisterdata.displayIndividual ? practitionerRegisterData.practitionerregisterdata.displayIndividual === "block" ? "none" : "none" : "none",
          displayEstablishment: practitionerRegisterData.practitionerregisterdata.displayEstablishment ? practitionerRegisterData.practitionerregisterdata.displayEstablishment === " block" ? "none" : "none" : "none",
          displayCompanyName: practitionerRegisterData.practitionerregisterdata.displayCompanyName ? practitionerRegisterData.practitionerregisterdata.displayCompanyName === "block" ? "block" : practitionerRegisterData.practitionerregisterdata.practicing_saloon == 1 ? "block" : "none" : "none",


          activeCompany: practitionerRegisterData.practitionerregisterdata.activeCompany ? practitionerRegisterData.practitionerregisterdata.activeCompany : "active-tab",
          activeIndividual: practitionerRegisterData.practitionerregisterdata.activeIndividual ? practitionerRegisterData.practitionerregisterdata.activeIndividual : "",
          practitioner_type: practitionerRegisterData.practitionerregisterdata.practitioner_type,
          first_name: practitionerRegisterData.practitionerregisterdata.first_name,
          last_name: practitionerRegisterData.practitionerregisterdata.last_name,
          email: practitionerRegisterData.practitionerregisterdata.email,
          password: practitionerRegisterData.practitionerregisterdata.password,
          profile_pic: practitionerRegisterData.practitionerregisterdata.profile_pic,
          file: practitionerRegisterData.practitionerregisterdata.file,
          practitioner_category_apply:practitionerRegisterData.practitionerregisterdata.practitioner_category_apply,

          is_checked_email_message: practitionerRegisterData.practitionerregisterdata.is_checked_email_message,
          is_checked_upto_date_insurance: practitionerRegisterData.practitionerregisterdata.is_checked_upto_date_insurance,
          is_checked_marketing: practitionerRegisterData.practitionerregisterdata.is_checked_marketing,

          is_checked_terms_condition: practitionerRegisterData.practitionerregisterdata.is_checked_terms_condition,

          postcodeValue:practitionerRegisterData.practitionerregisterdata.postcodeValue ?practitionerRegisterData.practitionerregisterdata.postcodeValue : "",
          country:practitionerRegisterData.practitionerregisterdata.country ?practitionerRegisterData.practitionerregisterdata.country : "",
          city:practitionerRegisterData.practitionerregisterdata.city ?practitionerRegisterData.practitionerregisterdata.city : "",
          town_city:practitionerRegisterData.practitionerregisterdata.town_city ?practitionerRegisterData.practitionerregisterdata.town_city : "",

          latitude:practitionerRegisterData.practitionerregisterdata.latitude ?practitionerRegisterData.practitionerregisterdata.latitude : "",
          longitude:practitionerRegisterData.practitionerregisterdata.longitude ?practitionerRegisterData.practitionerregisterdata.longitude : "",
          show_postcode:practitionerRegisterData.practitionerregisterdata.show_postcode ?practitionerRegisterData.practitionerregisterdata.show_postcode : false,
          flat_build_no:practitionerRegisterData.practitionerregisterdata.flat_build_no ?practitionerRegisterData.practitionerregisterdata.flat_build_no : "",
          street_name:practitionerRegisterData.practitionerregisterdata ?practitionerRegisterData.practitionerregisterdata.street_name : "",

        });

      } else {
        // console.log("else",practitionerRegisterData.practitionerregisterdata && practitionerRegisterData.practitionerregisterdata)
        updateFormData({
          ...formData,
          company_name: practitionerRegisterData.practitionerregisterdata.company_name,
          // displayCompany:"none",
          // displayIndividual:"block",

          telephone_no: practitionerRegisterData.practitionerregisterdata.telephone_no ? practitionerRegisterData.practitionerregisterdata.telephone_no : "",
          postcode: practitionerRegisterData.practitionerregisterdata.postcode ? practitionerRegisterData.practitionerregisterdata.postcode : "",
          postcodeValue: practitionerRegisterData.practitionerregisterdata.postcodeValue ? practitionerRegisterData.practitionerregisterdata.postcodeValue : "",
          latitude: practitionerRegisterData.practitionerregisterdata.latitude ? practitionerRegisterData.practitionerregisterdata.latitude : "",
          longitude: practitionerRegisterData.practitionerregisterdata.longitude ? practitionerRegisterData.practitionerregisterdata.longitude : "",
          country: practitionerRegisterData.practitionerregisterdata.country ? practitionerRegisterData.practitionerregisterdata.country : "",
          city: practitionerRegisterData.practitionerregisterdata.city ? practitionerRegisterData.practitionerregisterdata.city : "",
          // company_name:"",
          sub_category: practitionerRegisterData.practitionerregisterdata.sub_category ? practitionerRegisterData.practitionerregisterdata.sub_category : [],
          cat_array: practitionerRegisterData.practitionerregisterdata.cat_array ? practitionerRegisterData.practitionerregisterdata.cat_array : [],

          hair_cat_array: practitionerRegisterData.practitionerregisterdata.hair_cat_array ? practitionerRegisterData.practitionerregisterdata.hair_cat_array : [],
          beauty_cat_array: practitionerRegisterData.practitionerregisterdata.beauty_cat_array ? practitionerRegisterData.practitionerregisterdata.beauty_cat_array : [],
          aesthetics_cat_array: practitionerRegisterData.practitionerregisterdata.aesthetics_cat_array ? practitionerRegisterData.practitionerregisterdata.aesthetics_cat_array : [],
          wellness_cat_array: practitionerRegisterData.practitionerregisterdata.wellness_cat_array ? practitionerRegisterData.practitionerregisterdata.wellness_cat_array : [],

          practicing_saloon: practitionerRegisterData.practitionerregisterdata.practicing_saloon ? practitionerRegisterData.practitionerregisterdata.practicing_saloon : 0,
          establishment_name: practitionerRegisterData.practitionerregisterdata.establishment_name ? practitionerRegisterData.practitionerregisterdata.establishment_name : "",
          is_accept_notify_ginipig: practitionerRegisterData.practitionerregisterdata.is_accept_notify_ginipig ? practitionerRegisterData.practitionerregisterdata.is_accept_notify_ginipig : false,
          displayCompany: practitionerRegisterData.practitionerregisterdata.displayCompany ? practitionerRegisterData.practitionerregisterdata.displayCompany === "block" ? "none" : "none" : "none",
          displayIndividual: practitionerRegisterData.practitionerregisterdata.displayIndividual ? practitionerRegisterData.practitionerregisterdata.displayIndividual === "block" ? "block" : "block" : "block",
          displayEstablishment: practitionerRegisterData.practitionerregisterdata.displayEstablishment ? practitionerRegisterData.practitionerregisterdata.displayEstablishment === "block" ? "block" : practitionerRegisterData.practitionerregisterdata.practicing_saloon == 1 ? "block" : "none" : "none",
          displayCompanyName: practitionerRegisterData.practitionerregisterdata.displayCompanyName ? practitionerRegisterData.practitionerregisterdata.displayCompanyName === "block" ? "none" : "none" : "none",

          activeCompany: practitionerRegisterData.practitionerregisterdata.activeCompany ? practitionerRegisterData.practitionerregisterdata.activeCompany : "",
          activeIndividual: practitionerRegisterData.practitionerregisterdata.activeIndividual ? practitionerRegisterData.practitionerregisterdata.activeIndividual : "active-tab",
          practitioner_type: practitionerRegisterData.practitionerregisterdata.practitioner_type,
          first_name: practitionerRegisterData.practitionerregisterdata.first_name,
          last_name: practitionerRegisterData.practitionerregisterdata.last_name,
          email: practitionerRegisterData.practitionerregisterdata.email,
          password: practitionerRegisterData.practitionerregisterdata.password,
          profile_pic: practitionerRegisterData.practitionerregisterdata.profile_pic,
          file: practitionerRegisterData.practitionerregisterdata.file,
          practitioner_category_apply:practitionerRegisterData.practitionerregisterdata.practitioner_category_apply,
          is_checked_email_message: practitionerRegisterData.practitionerregisterdata.is_checked_email_message,
          is_checked_upto_date_insurance: practitionerRegisterData.practitionerregisterdata.is_checked_upto_date_insurance,
          is_checked_terms_condition: practitionerRegisterData.practitionerregisterdata.is_checked_terms_condition,
          is_checked_marketing: practitionerRegisterData.practitionerregisterdata.is_checked_marketing,

        });
      }
      updateErrorData({
        ...errorData,
        is_form_valid: true,
      });

    }
    if (categoryData.categorylistdata === null) {
      try {
        dispatch(categorylistAPI_Action());

      } catch (error) {
        console.error(" profile ::  profile() :: Exception ==", error);
      }
    }
  }, [])
  // })

  if (practitionersignupaddinfoData.isSuccess === true && practitionersignupaddinfoData.practitionersignupaddinfodata !== null) {
    // dispatch(resetpractitionerSignupSaveAPI_Action());
    setLocalStorageData("loginData",practitionersignupaddinfoData.practitionersignupaddinfodata.data)
    setLocalStorageData("loginDataUser",practitionersignupaddinfoData.practitionersignupaddinfodata.data.user)
    clearLocalStorage("stripeCode");
   
    setLocalStorageData("practitionersignupData",practitionersignupaddinfoData.practitionersignupaddinfodata.data)
   
    window.scrollTo(0, 0);
    history.push('/practitioner-info');
  }

  const __selectSubCategory = (e, cat_id) => {
    if (practitionersignupaddinfoData.isError === true) {
      dispatch(resetPractitionerAddInfoAPI_Action());
      dispatch(resetStripeAccountValidityAPI_Action());
    }
    if (errorData.subcategory_error != "") {
      updateErrorData({
        ...errorData,
        subcategory_error: "",
        is_form_valid: true,
      });
    }
    const { id, value } = e.target;
    let selectedBoxes = formData.sub_category;
    let selectedCategory = formData.cat_array;
    let selectedHairsCat = formData.hair_cat_array;
    let selectedBeautyCat = formData.beauty_cat_array;
    let selectedAestheticsCat = formData.aesthetics_cat_array;
    let selectedWellnessCat = formData.wellness_cat_array;

    let index = selectedBoxes.indexOf(parseInt(value)) // check to see if the name is already stored in the array
    if (index === -1) {
      selectedBoxes.push(parseInt(value)) // if it isn't stored add it to the array
      if (cat_id == 1) {
        selectedHairsCat.push(parseInt(value))

      } else if (cat_id == 2) {
        selectedBeautyCat.push(parseInt(value))

      } else if (cat_id == 3) {
        selectedAestheticsCat.push(parseInt(value))

      } else if (cat_id == 4) {
        selectedWellnessCat.push(parseInt(value))

      }
    } else {
      selectedBoxes.splice(index, 1) // if it is stored then remove it from the array

      if (cat_id == 1) {
        let indexCat = selectedHairsCat.indexOf(parseInt(value))
        if (indexCat === -1) {
          selectedHairsCat.push(parseInt(value))
        } else {
          selectedHairsCat.splice(indexCat, 1)
        }


      } else if (cat_id == 2) {
        let indexCat = selectedBeautyCat.indexOf(parseInt(value))
        if (indexCat === -1) {
          selectedBeautyCat.push(parseInt(value))
        } else {
          selectedBeautyCat.splice(indexCat, 1)
        }
      } else if (cat_id == 3) {
        let indexCat = selectedAestheticsCat.indexOf(parseInt(value))
        if (indexCat === -1) {
          selectedAestheticsCat.push(parseInt(value))
        } else {
          selectedAestheticsCat.splice(indexCat, 1)
        }
      } else if (cat_id == 4) {
        let indexCat = selectedWellnessCat.indexOf(parseInt(value))
        if (indexCat === -1) {
          selectedWellnessCat.push(parseInt(value))
        } else {
          selectedWellnessCat.splice(indexCat, 1)
        }
      }
    }

    let categoryindex = selectedCategory.indexOf(parseInt(cat_id)) // check to see if the name is already stored in the array
    if (categoryindex === -1) {
      selectedCategory.push(parseInt(cat_id)) // if it isn't stored add it to the array
    } else {
      selectedCategory.splice(categoryindex, 1) // if it is stored then remove it from the array
    }
    updateFormData({
      ...formData,
      sub_category: selectedBoxes,
      cat_array: selectedCategory,
      hair_cat_array: selectedHairsCat,
      beauty_cat_array: selectedBeautyCat,
      aesthetics_cat_array: selectedAestheticsCat,
      wellness_cat_array: selectedWellnessCat,
    });

  }

  const __onTextChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
    if (e.target.name === "company_name") {
      updateErrorData({
        ...errorData,
        company_name_error: "",
        is_form_valid: true,
      });
    }

    if (e.target.name === "establishment_name") {
      updateErrorData({
        ...errorData,
        establishment_name_error: "",
        is_form_valid: true,
      });
    }

    if (e.target.name === "telephone_no" && e.target.value !== "") {
      var decimal = /^[0][1-9]\d{9}$|^[1-9]\d{9}$/;
      if (e.target.value.match(decimal) && e.target.value.length === 11)
      updateErrorData({
        ...errorData,
        is_form_valid: true,
        phonenumber_error: "",
      });
    }

   
  };


  const __selectPracticingSalon = (e, type) => {
    if (practitionersignupaddinfoData.isError === true) {
      dispatch(resetPractitionerAddInfoAPI_Action());
      dispatch(resetStripeAccountValidityAPI_Action());
    }
    let displayEstablishmentField = "none";
    let displayCompanyNameField = "none";
    if (type == "individual") {

      if (parseInt(e.target.value.trim()) === 1) {
        displayEstablishmentField = "block";
        displayCompanyNameField = "none";
        if (formData.establishment_name === "") {
          updateErrorData({
            ...errorData,
            is_form_valid: false,
            establishment_error: ""
          });
        }

      } else if (parseInt(e.target.value.trim()) === 0) {
        displayEstablishmentField = "none";
        displayCompanyNameField = "none";

        updateErrorData({
          ...errorData,
          is_form_valid: true,
          establishment_error: ""
        });
      }
    } else {
      if (parseInt(e.target.value.trim()) === 1) {
        displayEstablishmentField = "none";
        displayCompanyNameField = "block";
        if (formData.company_name === "") {
          updateErrorData({
            ...errorData,
            is_form_valid: false,
            company_name_error: ""
          });
        }


      } else if (parseInt(e.target.value.trim()) === 0) {
        displayEstablishmentField = "none";
        displayCompanyNameField = "none";

        updateErrorData({
          ...errorData,
          is_form_valid: true,
          company_name_error: ""
        });
      }
    }
    updateFormData({
      ...formData,
      [e.target.name]: parseInt(e.target.value.trim()),
      displayCompany: formData.displayCompany,
      displayIndividual: formData.displayIndividual,
      displayEstablishment: displayEstablishmentField,
      displayCompanyName: displayCompanyNameField,
    });
  }

  const __apiCall = useCallback(async () => {
    try {
     
      await dispatch(practitionerSignupAddInfoAPI_Action(formData));
       if(formData.practicing_saloon===""){
        window.scrollTo(0, 0);
      }
      //setIsOpen(true);
    } catch (error) {
      console.error(" profile ::  profile() :: Exception ==", error);
    }
  });

  const __categoryapiCall = useCallback(async () => {
    try {
      await dispatch(categorylistAPI_Action());

    } catch (error) {
      console.error(" profile ::  profile() :: Exception ==", error);
    }
  });


  const __submitPractitionerSignupForm = (e) => {
    e.preventDefault();
    let check_valid = false;
    if (formData.practicing_saloon !== '') {
      if (formData.practitioner_type === 'Individual' && formData.practicing_saloon === 1) {
        if (formData.establishment_name !== "") {
          check_valid = true;
        } else {
          updateErrorData({
            ...errorData,
            establishment_error: "Establishment Name Is Required.",
            is_form_valid: false,
          });
        }
      } else {
        check_valid = true;
      }

      if (formData.practitioner_type !== 'Individual' && formData.practicing_saloon === 1) {
        if (formData.company_name !== "") {
          check_valid = true;
        } else {
          updateErrorData({
            ...errorData,
            company_name_error: "Company Name Is Required.",
            is_form_valid: false,
          });
        }
      } else {
        check_valid = true;
      }
    } else {
      // updateErrorData({
      //   ...errorData,
      //   practitioner_salon_error:"Practitioner is Required.",
      //   is_form_valid: false,
      // });
      check_valid = true;
    }

    if (formData.sub_category.length > 0) {
      updateErrorData({
        ...errorData,
        subcategory_error: "",
        is_form_valid: true,
      });
      check_valid = true;
    } else {
      updateErrorData({
        ...errorData,
        subcategory_error: "Please Select Atleast 1 sub-category.",
        is_form_valid: false,
      });
      check_valid = false;
    }

    // if (formData.is_accept_notify_ginipig===false) {
    //   updateErrorData({
    //     ...errorData,
    //     accept_ginipig_error: "",
    //     is_form_valid: true,
    //   });
    //   check_valid = true;
    // } else {
    //   updateErrorData({
    //     ...errorData,
    //     accept_ginipig_error: "Please Select Atleast 1 sub-category.",
    //     is_form_valid: false,
    //   });
    //   check_valid = false;
    // }

    if(formData.is_accept_notify_ginipig===true){
      updateErrorData({
        ...errorData,
        is_form_valid: true,
        accept_ginipig_error: " "
      });
    
    }else{
      window.scrollTo(0, 200);
      updateErrorData({
        ...errorData,
        is_form_valid: false,
        accept_ginipig_error: "Please accept to being notified by GoGiniPig via text message for appointment purposes.   "
      });
    }
   
    if (check_valid === true && formData.is_accept_notify_ginipig===true) {
      __apiCall();
    }
    // else {
    //   updateErrorData({
    //     ...errorData,
    //     establishment_error:errorData.establishment_error,
    //     company_name_error:errorData.company_name_error,
    //     subcategory_error:errorData.subcategory_error,
    //     is_form_valid: false,
    //   });
    // }

  };

  const toggleChangeEmailMessage = (e) => {
    // console.log("toggle",e.target.value)
    updateFormData({
      ...formData,
      is_accept_notify_ginipig: !formData.is_accept_notify_ginipig,
    });

      updateErrorData({
        ...errorData,
        is_form_valid: true,
        accept_ginipig_error: " "
      });
   
  };



  const checkMobileValidation = (e) => {
    const { id, value } = e.target;
    var decimal = /^[0][1-9]\d{9}$|^[1-9]\d{9}$/;

    if (!value) {
      updateErrorData({
        ...errorData,
        is_form_valid: false,
        phonenumber_error: "Phone Number Is Required.",
      });
    } else if (!value.match(decimal)) {
      updateErrorData({
        ...errorData,
        is_form_valid: false,
        phonenumber_error:
          "Phone number must start with 0 and must be 11 digits.",
      });
    } else if (value.length !== 11) {
      updateErrorData({
        ...errorData,
        is_form_valid: false,
        phonenumber_error:
          "Phone number must start with 0 and must be 11 digits.",
      });
    } else {
      updateErrorData({
        ...errorData,
        is_form_valid: true,
        phonenumber_error: "",
      });
    }
  };



  const checkPostcodeValidation = (e) => {
    
      if(formData.postcodeValue.length>5 && formData.city!=='' && (formData.postcodeValue).match(/^([a-z0-9]\s*){5,8}$/i)){
        
        updateErrorData({
          ...errorData,
          is_form_valid_postcode: false,
          postcode_error: " "
      })
      }
      else if(formData.postcodeValue == "" || formData.postcodeValue.length>5 ){
       
          updateErrorData({
          ...errorData,
          is_form_valid_postcode: true,
          postcode_error: "Please ensure you have entered a valid UK postcode"
          })
      
      }
    
  }


  const checkEstablishmentNameValidation = (e) => {
    const { id, value } = e.target;
    if (!value) {
      updateErrorData({
        ...errorData,
        is_form_valid: false,
        establishment_error: "Establishment Name Is Required."
      });
    } else {
      updateErrorData({
        ...errorData,
        is_form_valid: true,
        establishment_error: ""
      });
    }
  }

  const checkCompanyNameValidation = (e) => {
    const { id, value } = e.target;
    if (!value) {
      if (formData.practicing_saloon === 1) {
        updateErrorData({
          ...errorData,
          is_form_valid: false,
          company_name_error: "Company Name Is Required."
        });
      } else {
        updateErrorData({
          ...errorData,
          is_form_valid: true,
          company_name_error: ""
        });
      }

    } else {
      updateErrorData({
        ...errorData,
        is_form_valid: true,
        company_name_error: ""
      });
    }
  }
  const __checkPostcode = useCallback(async () => {
    try {
      await dispatch(validatePostcodeAPI_Action(formData.postcode));

    } catch (error) {
      console.error(" profile ::  profile() :: Exception ==", error);
    }
  });

  const Redirect_url = () => {
    dispatch(practitionerSignupSaveData_Action(formData));
    history.goBack()
  }
  const __redirectGinipigRegister = (e) => {
  
      dispatch(resettreatmentdetailsAPI_Action());
      dispatch(resetcreateAppointmentData_Action());
      dispatch(resetpractitionerSignupSaveAPI_Action());
      window.scrollTo(0, 0);
      history.push('/signup');
   
   
  }

  const checkFirstNameValidation = (e) => {
    const { id, value } = e.target;
    if (!value) {
      updateErrorData({
        ...errorData,
        is_form_valid: false,
        firstname_error: "First Name Is Required."
      });
    } else if (value.length > 50) {
      updateErrorData({
        ...errorData,
        is_form_valid: false,
        firstname_error: "Only 50 Characters are Allowed."
      });
      //} else if (!value.match(/^[a-zA-Z]+$/)) {
    } else if (!value.match(/^[a-zA-Z\-\s]+$/)) {
      updateErrorData({
        ...errorData,
        is_form_valid: false,
        firstname_error: "Oops, you've added an incorrect character! Letters only "
      });
    } else {
      updateErrorData({
        ...errorData,
        is_form_valid: true,
        firstname_error: ""
      });
    }


  }

  return (
    <>
      <section className="bgcolor-gray common-section practitionersignup pb80 ">
        <Container>
          <Row>
            <Col className="col-lg-11 m-auto">
              <div className="navigationTop">
                <div className="heading-history">
                  <div className="back-history">
                    <button
                      type="button"
                      className="back-btn"
                      onClick={() => Redirect_url()}
                    >
                      {/* <Link to="/signup-practitioner" 
                        state={{ prevPath: props.location.pathname }}
                        className="back-btn"
                        onClick={Redirect_url}> */}
                      {" "}
                      <img src={LeftArrow} alt="arrow" />{" "}
                      {/* </Link> */}
                    </button>
                  </div>
                  <div className="history-title">
                    <h2>Join Us </h2>
                  </div>
                </div>
                <div className="headingbox alignright mobile-hidden">
                  <div className="next-title">
                    <Link onClick={__redirectGinipigRegister} className="navTopRegister">
                    Register as a GiniPig
                    </Link>
                    <div className="navTopRightArrow">
                      <img src={LeftArrow} alt="icon" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="practitioner-wrp pt20">
                <form action="post" onSubmit={__submitPractitionerSignupForm}>
                  <div className="practitioner-addfrom practtitionfrom-wrp">
                    <div className="one box">
                      <div className="form-wrp fromProfile mb-24">
                        <div className="form-inside">
                          <div className="profilefield">
                            <label className="profile-label">Treatment location address</label>
                            <span className="notes ">This won't be shown on your listing if you choose to list an online appointment</span>
                              <div className="error-show">
                              {
                                errorData.postcode_error && errorData.postcode_error != "" ? (
                                  <div className="error">
                                    <p  style={{ color: "red" }}>{errorData.postcode_error} </p>
                                    </div>
                                ) : null
                              }

                              {
                                practitionersignupaddinfoData.practitionersignupaddinfodata && practitionersignupaddinfoData.practitionersignupaddinfodata.hasOwnProperty('errors') ? (
                                  <div className="error">

                                    {practitionersignupaddinfoData.practitionersignupaddinfodata.errors.hasOwnProperty('postcode') ? (
                                      <p style={{ color: "red" }}>{practitionersignupaddinfoData.practitionersignupaddinfodata.errors.postcode[0]} </p>
                                    ) : null}
                                  </div>

                                ) : null
                              }

                              {
                                validatepostcodeData.validatepostcodedata && validatepostcodeData.validatepostcodedata.status_code === 400 ? (
                                  validatepostcodeData.validatepostcodedata.message !== "" ? (
                                      <div className="error"> <p  style={{ color: "red" }}>{validatepostcodeData.validatepostcodedata.message} </p></div>
                                    ) : null
                                ) : null
                              }
                              
                            </div>


                            <div className="search-field mt-3">
                              <div className="loc_point">
                                <input
                                  className="searchText"
                                  type="text"
                                  id="postcode"
                                  name="postcode"
                                  onBlur={checkPostcodeValidation}
                                  value={formData.postcodeValue}
                                  // onChange={__onTextChange}  
                                  
                                  autoComplete="off"
                                  onChange={handleInput}
                                  disabled={!ready}
                                  placeholder="Please enter postcode"
                                  // onKeyUp={checkPostcodeValidation}
                                  //onBlur={checkPostcodeValidation}
                                  required
                                />
                                {console.log("postcodeValue input",formData.postcodeValue)}
                                {status === 'OK' && <ul className="autoSearch">{renderSuggestions()}</ul>}
                              </div>
                              {/* <span className="checkerroricon">
                                {errorData.postcode_error &&
                                  errorData.postcode_error != "" ? (
                                    <img src={iconClose} alt="icon" className="erroricon warning" style={{ "display": "none" }} />
                                  ) : formData.postcode && formData.postcode != "" ? validatepostcodeData.validatepostcodedata && validatepostcodeData.validatepostcodedata.status_code === 200 ? (
                                    <img src={iconCorrect} alt="icon" className="erroricon succusess" style={{ "display": "block" }} />
                                  ) : (
                                      <img src={iconClose} alt="icon" className="erroricon warning" style={{ "display": "none" }} />
                                    ) : (
                                      <img src={iconClose} alt="icon" className="erroricon warning" style={{ "display": "none" }} />
                                    )}
                              </span> */}
                              {/* {
                                            validatepostcodeData.isFetching 
                                            ? <button  className="btn-search"  disabled={true}> Loading... </button>  
                                            : <button type="button" className="btn-search" disabled={ formData.postcode && formData.postcode!="" ? false : true}  onClick={__checkPostcode}>Search</button>
                                        } */}


                            </div>

                          
                          </div>

                          {
                            isaddress ? 
                            <div>
                                <div className="profilefield">
                            <label className="profile-label">Flat/Building No</label>

                            <div className="search-field">
                              <div className="loc_point">
                                <input
                                  className="searchText"
                                  type="text"
                                  id="flat_build_no"
                                  name="flat_build_no"
                                  value={formData.flat_build_no}
                                  onChange={__onTextChange} 
                                  autoComplete="off"
                                  placeholder="Please enter Flat/Building no"
                                  required
                                />
                              </div>
                              
                            </div>

                           </div>

                          <div className="profilefield">
                            <label className="profile-label">Street Name</label>

                            <div className="search-field">
                              <div className="loc_point">
                                <input
                                  className="searchText"
                                  type="text"
                                  id="street_name"
                                  name="street_name"
                                  value={formData.street_name}
                                  onChange={__onTextChange} 
                                  autoComplete="off"
                                  placeholder="Please enter street name"
                                  required
                                />
                              </div>
                              
                            </div>

                           </div>

                          <div className="profilefield">
                            <label className="profile-label">City/Town Name</label>

                            <div className="search-field">
                              <div className="loc_point">
                                <input
                                  className="searchText"
                                  type="text"
                                  id="town_city"
                                  name="town_city"
                                  value={formData.town_city}
                                  onChange={__onTextChange} 
                                  autoComplete="off"
                                  placeholder="Please enter city/town name"
                                  required
                                />
                              </div>
                              
                            </div>

                           </div>

                            </div> : null
                          }

                          <div className="profilefield-checkbox" style={{ display: formData.displayCompany }}>
                            <label className="profile-label">Select if you would like to show your company name on your profile </label>
                            <div className="check-grp">
                              <div className="check-tick">
                                <input
                                  className="tnc"
                                  type="checkbox"
                                  id="practicing_saloon"
                                  name="practicing_saloon"
                                  onChange={(e) => __selectPracticingSalon(e, "Bussiness")}
                                  value={1}
                                  defaultChecked={formData.practicing_saloon === 1 ? true : false}
                                // required
                                />
                                <label htmlFor="practicing_saloon" className="checkbox-text"> Yes</label>
                              </div>
                              <div className="check-tick">
                                <input
                                  className="tnc"
                                  type="checkbox"
                                  id="practicing_saloon1"
                                  name="practicing_saloon"
                                  onChange={(e) => __selectPracticingSalon(e, "Bussiness")}
                                  value={0}
                                  defaultChecked={formData.practicing_saloon === 0 ? true : false}
                                // required
                                />
                                <label htmlFor="practicing_saloon1" className="checkbox-text"> No</label>
                              </div>
                            </div>
                            <div className="error-show">
                              {
                                practitionersignupaddinfoData.practitionersignupaddinfodata && practitionersignupaddinfoData.practitionersignupaddinfodata.hasOwnProperty('errors') ? (
                                  <div className="error">

                                    {practitionersignupaddinfoData.practitionersignupaddinfodata.errors.hasOwnProperty('practicing_saloon') ? (
                                      <p style={{ color: "red" }}>{practitionersignupaddinfoData.practitionersignupaddinfodata.errors.practicing_saloon[0]} </p>
                                    ) : null}
                                  </div>

                                ) : null
                              }
                            </div>
                          </div>

                          <div className="profilefield-checkbox" style={{ display: formData.displayIndividual }}>
                            <label className="profile-label">Are you practicing with a salon or company?  </label>
                            <div className="check-grp">
                              <div className="check-tick">
                                <input
                                  className="tnc"
                                  type="checkbox"
                                  id="practicing_saloon"
                                  name="practicing_saloon"
                                  onChange={(e) => __selectPracticingSalon(e, "individual")}
                                  value={1}
                                  defaultChecked={formData.practicing_saloon === 1 ? true : false}

                                />
                                <label htmlFor="practicing_saloon" className="checkbox-text"> Yes</label>
                              </div>
                              <div className="check-tick">
                                <input
                                  className="tnc"
                                  type="checkbox"
                                  id="practicing_saloon1"
                                  name="practicing_saloon"
                                  onChange={(e) => __selectPracticingSalon(e, "individual")}
                                  value={0}
                                  defaultChecked={formData.practicing_saloon === 0 ? true : false}

                                />
                                <label htmlFor="practicing_saloon1" className="checkbox-text"> No</label>
                              </div>
                            </div>
                            <div className="error-show">
                              {
                                practitionersignupaddinfoData.practitionersignupaddinfodata && practitionersignupaddinfoData.practitionersignupaddinfodata.hasOwnProperty('errors') ? (
                                  <div className="error">

                                    {practitionersignupaddinfoData.practitionersignupaddinfodata.errors.hasOwnProperty('practicing_saloon') ? (
                                      <p style={{ color: "red" }}>{practitionersignupaddinfoData.practitionersignupaddinfodata.errors.practicing_saloon[0]} </p>
                                    ) : null}
                                  </div>

                                ) : null
                              }
                            </div>
                          </div>

                          <div className="profilefield" style={{ display: formData.displayEstablishment }}>
                            <label className="profile-label-small">see your name below, this will now be displayed in your profile </label>

                            <input
                              type="text"
                              className="form-control"
                              id="establishment_name"
                              name="establishment_name"
                              value={formData.establishment_name}
                              onChange={__onTextChange}
                              // onKeyUp={checkEstablishmentNameValidation}
                              onBlur={checkEstablishmentNameValidation}
                            // required
                            />
                            <div className="error-show">
                              {
                                errorData.establishment_error && errorData.establishment_error != "" ? (
                                  <p className="error" >
                                    <span style={{ color: "red" }}>{errorData.establishment_error} </span>
                                  </p>

                                ) : null
                              }

                              {
                                practitionersignupaddinfoData.practitionersignupaddinfodata && practitionersignupaddinfoData.practitionersignupaddinfodata.hasOwnProperty('errors') ? (
                                  <div className="error">

                                    {practitionersignupaddinfoData.practitionersignupaddinfodata.errors.hasOwnProperty('establishment_name') ? (
                                      <p style={{ color: "red" }}>{practitionersignupaddinfoData.practitionersignupaddinfodata.errors.establishment_name[0]} </p>
                                    ) : null}
                                  </div>

                                ) : null
                              }
                            </div>
                          </div>

                          <div className="profilefield" style={{ display: formData.displayCompanyName }}>
                            {/* <div className="showWithLabal">  */}
                            <label className="profile-label-small">see your name below, this will now be displayed in your profile </label>


                            {/* </div> */}
                            <input
                              type="text"
                              className="form-control"
                              value={formData.company_name}
                              id="company_name"
                              name="company_name"
                              onChange={__onTextChange}
                              // onKeyUp={checkCompanyNameValidation}
                              onBlur={checkCompanyNameValidation}

                            />

                            <div className="error-show">
                              {
                                errorData.company_name_error && errorData.company_name_error != "" ? (
                                  <div className="error" >

                                    <p style={{ color: "red" }}>{errorData.company_name_error} </p>
                                  </div>

                                ) : null
                              }

                              {
                                practitionersignupaddinfoData.practitionersignupaddinfodata && practitionersignupaddinfoData.practitionersignupaddinfodata.hasOwnProperty('errors') ? (
                                  <div className="error">

                                    {practitionersignupaddinfoData.practitionersignupaddinfodata.errors.hasOwnProperty('establishment_name') ? (
                                      <p style={{ color: "red" }}>{practitionersignupaddinfoData.practitionersignupaddinfodata.errors.establishment_name[0]} </p>
                                    ) : null}
                                  </div>

                                ) : null
                              }


                            </div>
                          </div>

                          {/* <div className="profilefield">
                                <div>
                                  <label className="profile-label">see your name below, this will now be displayed in your profile</label>
                                  {
                                    errorData.firstname_error && errorData.firstname_error != "" ? (
                                      <p className="error" >

                                        <p style={{ color: "red" }}>{errorData.firstname_error} </p>
                                      </p>

                                    ) : null
                                  }

                                  {
                                    practititionersignupData.practitionersignupdata && practititionersignupData.practitionersignupdata.hasOwnProperty('errors') ? (
                                      <p className="error">

                                        {practititionersignupData.practitionersignupdata.errors.hasOwnProperty('first_name') ? (
                                          <p style={{ color: "red" }}>{practititionersignupData.practitionersignupdata.errors.first_name[0]} </p>
                                        ) : null}
                                      </p>

                                    ) : null
                                  }
                                </div>
                                <input
                                  type="text"
                                  id="first_name"
                                  disabled={true}
                                  name="first_name"
                                  value={formData.first_name}
                                  onChange={__onTextChange}
                                  // onKeyUp={checkFirstNameValidation}
                                  onBlur={checkFirstNameValidation}
                                  autoComplete="off"
                                  maxLength="50"
                                  required
                                />
                              </div> */}

                          <div className="profilefield">
                            <div className="showWithLabal">
                              <label className="profile-label">Mobile Contact number</label>
                              <span className="notes">Only shared with GiniPigs once booking confirmed</span>
                              {/* <span className="notes">Only visible to GiniPigs with appointments</span> */}
                            </div>
                            {
                              errorData.phonenumber_error && errorData.phonenumber_error != "" ? (
                                <div className="error" >

                                  <p style={{ color: "red" }}>{errorData.phonenumber_error} </p>
                                </div>

                              ) : null
                            }

                            {
                              practitionersignupaddinfoData.practitionersignupaddinfodata && practitionersignupaddinfoData.practitionersignupaddinfodata.hasOwnProperty('errors') ? (
                                <div className="error">

                                  {practitionersignupaddinfoData.practitionersignupaddinfodata.errors.hasOwnProperty('telephone_no') ? (
                                    <p style={{ color: "red" }}>{practitionersignupaddinfoData.practitionersignupaddinfodata.errors.telephone_no[0]} </p>
                                  ) : null}
                                </div>

                              ) : null
                            }

                            <input
                              type="number"
                              id="telephone_no"
                              name="telephone_no"
                              value={formData.telephone_no}
                              onChange={__onTextChange}
                              // onKeyUp={checkMobileValidation}
                              onBlur={checkMobileValidation}
                              required
                              minLength="10"
                              maxLength="11"
                            />

                          </div>
                        </div>
                       

                        <div className="checkwrpbox fromProfile">
                        {
                                errorData.accept_ginipig_error && errorData.accept_ginipig_error != "" ? (
                                  <div className="error" >
                                    <p style={{ color: "red" }}>{errorData.accept_ginipig_error} </p>
                                  </div>

                                ) : null
                              }
                          <div className="checkbox-group">
                        

                            <label htmlFor="check1">
                              <div className="checkinput">
                                <input
                                  className="tnc"
                                  type="checkbox"
                                  id="check1"
                                  onChange={toggleChangeEmailMessage}
                                  defaultChecked={formData.is_accept_notify_ginipig === true ? true : false}
                                />
                              </div>
                              <span className="checkbox-decs">I accept to being notified by GoGiniPig via text message for appointment purposes.  </span>
                            </label>
                          </div>
                        </div>

                      </div>
                      <div className="form-wrp1 fromProfile mb-24">
                        <div className="inside-form">
                          <p className="label-title"> Please select the services you will be offering </p>
                          <div className="profilefield-checkbox boxstwo">
                            {
                              categoryData.categorylistdata &&categoryData.categorylistdata.data.categories && categoryData.categorylistdata.data.categories.map((item, index) => {

                                return (
                                  <div className="checkbox-wrp" key={index}>

                                    <label className={item.id === 1 && formData.hair_cat_array.length >= 1
                                      ? "labelcheckbox activeOnchecked" :
                                      item.id === 2 && formData.beauty_cat_array.length >= 1 ?
                                        "labelcheckbox activeOnchecked" :
                                        item.id === 3 && formData.aesthetics_cat_array.length >= 1 ?
                                          "labelcheckbox activeOnchecked" :
                                          item.id === 4 && formData.wellness_cat_array.length >= 1 ?
                                            "labelcheckbox activeOnchecked" :
                                            "labelcheckbox"

                                    }>
                                      <span className={item.name + "_icon iconc_category"}></span>
                                      <span className="categorytext">{item.name}</span>
                                    </label>


                                    <div className="check-box" key={index}>
                                      {
                                        item.sub_categories && item.sub_categories.data.map((sub_item, sub_index) => {
                                          return (
                                            <div className="check-tick" key={sub_index}>
                                              <div className="checkinput">
                                                <input
                                                  type="checkbox"
                                                  className="tnc"
                                                  id={sub_item.id}
                                                  name={sub_item.id}
                                                  onChange={(e) => __selectSubCategory(e, item.id)}
                                                  value={sub_item.id}
                                                  defaultChecked={formData.sub_category.indexOf(sub_item.id) !== -1 ? true : false}
                                                />
                                              </div>
                                              <label htmlFor={sub_item.id} className="checkbox-text">{sub_item.name}</label>
                                            </div>
                                          )
                                        })
                                      }
                                    </div>
                                  </div>
                                )
                              })
                            }
                          </div>
                          {
                            errorData.subcategory_error && errorData.subcategory_error != "" ? (
                              <div className="error" >

                                <p style={{ color: "red" }}>{errorData.subcategory_error} </p>
                              </div>

                            ) : null
                          }

                          {
                            practitionersignupaddinfoData.practitionersignupaddinfodata && practitionersignupaddinfoData.practitionersignupaddinfodata.hasOwnProperty('errors') ? (
                              <div className="error">

                                {practitionersignupaddinfoData.practitionersignupaddinfodata.errors.hasOwnProperty('sub_category') ? (
                                  <p style={{ color: "red" }}>{practitionersignupaddinfoData.practitionersignupaddinfodata.errors.sub_category[0]} </p>
                                ) : null}
                              </div>

                            ) : null
                          }




                          {/* <button type="submit" className="practitionerapp-btns"  >Submit</button> */}
                          {
                            practitionersignupaddinfoData.isFetching
                              ? <button className="practitionerapp-btns mt-16" disabled={true}> Loading... </button>
                              : <button type="submit" className="practitionerapp-btns mt-16"
                                disabled={
                                  errorData.is_form_valid === true && practitionersignupaddinfoData.isError === false
                                    && errorData.is_form_valid_postcode === false 
                                    && validatepostcodeData.isError === false && formData.telephone_no != "" && formData.postcode != "" 
                                    && formData.flat_build_no != "" && formData.street_name != "" && formData.town_city != ""
                                    && formData.sub_category !=""
                                    ? false : true}> Sign up </button>
                          }
                        </div>
                      </div>


                    </div>
                    <div className="box right-slider">
                      <FromSidebarSiderPact
                      />
                    </div>
                  </div>
                </form>
              </div>
            </Col>
          </Row>
          
        </Container>
      </section>
    </>

  );
};

export default PractitionerSignupAddInfo;