import * as types from "../types/index";
import { setInitialState } from "../InitialState";


const INITIAL_STATE = setInitialState({
    blogdetailsdata: null,
});


export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.BLOGDETAIL_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case types.BLOGDETAIL_RECEIVE:
            return {
                ...state,
                isFetching: false,
                isSuccess: true,
                blogdetailsdata: action.payload.success
            };
        case types.BLOGDETAIL_FAILURE:
            return {
                ...state,
                isFetching: false,
                isError: true,
                blogdetailsdata: action.payload.response.error,
            };
        case types.RESET_BLOGDETAIL:
            return {
                ...state,
                isFetching: false,
                isError: false,
                blogdetailsdata: null,
            };
        default:
            return {
                ...state
            }
    }
}