import React from 'react';

const BlogDetailCard = (props) => {
    return (
        <>
            <div className="blog-detail">
                <div className="blog_img">
                    <img src={props.Img} className="img_details" alt="blogImage" />
                </div>
                <div className="details-content">
                    {props.children}
                </div>
            </div>

        </>
    );
}

export default BlogDetailCard;