
import React, { useCallback, useState, useEffect } from "react";
import { useHistory,Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from 'react-bootstrap';

import {LeftArrow,reviewAvatar,star,iconPhoto, iconCase_Study,iconNearly_there,iconGiniPigs_Required} from '../../config/images';
import ImageUpload from '../../components/ImageUpload';
import LargeButton from '../../components/LargeButton';
import ThumbnailViewDetail from '../../components/ThumbnailViewDetail';




// redux
import { bookingDetailsAPI_Action } from '../../stores/actions'


const EnquiryDetails = (props) => {
  let history = useHistory();

  
  const __nextEnquiry = () => {
    window.scrollTo(0,0);
    history.push('/enquiry-thankyou')
}

  // Initialize hooks first   
  const dispatch = useDispatch();

  // Selectors
  const bookingData = useSelector(state => state.bookingdetails);
  const userData = useSelector(state => state.userdetails);        
  const loginUserData = useSelector(state => state.login);
  const booking_id=props.match.params.bookingid;


  // Use effects
  useEffect(() => {   
    // if(treatmentdetailsData.treatmentdetailsdata===null)
    // {                   
    //   try {
        
    //        dispatch(bookingDetailsAPI_Action(booking_id));
        
    //   } catch (error) {
    //     console.error(" profile ::  profile() :: Exception ==", error);
    //   }
    // }
    
  })


  const __apiCall = useCallback(async () => {
    try {
     
        await dispatch(bookingDetailsAPI_Action(booking_id));
      
      
    } catch (error) {
      console.error(" profile ::  profile() :: Exception ==", error);
    }
  });

  

  return (
    <>

                  <section className="bgcolor-gray common-section photo_requirement_pg pb80">
                    <Container>
                      <Row>
                          <Col className="col-lg-11 m-auto">
                                <div className="navigationTop">
                                  <div className="heading-history">
                                      <div className="back-history">
                                          <button type="button" onClick={() => history.goBack()} className="back-btn" >
                                              {" "}
                                              <img src={LeftArrow} alt="arrow" />{" "}
                                          </button>
                                      </div>
                                      <div className="history-title">
                                          <h2> Enquiry details  </h2>
                                      </div>
                                  </div>
                                </div>

                              <div className="requirement_wrp">
                                <form>
                                <div className="booking_box mt-24 mb-70">
                                  <ThumbnailViewDetail 
                                      treatmentTitle="Wash, cut & blow dry"
                                      currencySign="£"
                                      originalPriceText="25"
                                      discountPriceText="20"
                                      tagsName="Hair"
                                      duration="2.5 hours"
                                      categoryIcon1={iconCase_Study}
                                      categorName1="Case Study"
                                      categoryIcon2={iconNearly_there}
                                      categorName2=" Nearly there"
                                      categoryIcon3={iconGiniPigs_Required}
                                      categorName3="8 GiniPigs required"
                                      buttonRequiestTime="1st June at 18:30"
                                      buttonReqAddress="NW6, London"
                                  />
                                </div>

                                <div className="model_box_req  mt-24 ">
                                  <div className="req_img_box inst_box">
                                      <h3 className="title">GiniPig Requirements</h3>
                                      <div className="inst_content mt-16">
                                          <p>The Treatment/Service Provider requires you to add a photo to ensure perfect compatibility for this appointment</p>
                                      </div>
                                  </div>
                                  <div className="check_box borderTop">
                                      
                                    <div className="checkbox-group mb0">
                                      <label htmlFor="check1">
                                      <div className="checkinput mr-8">
                                        <input
                                        className="tnc"
                                          type="checkbox"
                                          id="check1"
                                          required
                                        />
                                        </div>
                                        <span className="checkbox-titles"> I have read and agree that I fit the model requirements to be suitable for this treatment. </span>
                                        </label>
                                      </div>
                                    </div> 
                                </div>

                                <div className="open_form_box mt-40 mb-40">
                                    <div className="inside_divi">
                                        <div className="open_btn">
                                          <button className="open_from_btn">Open form</button>
                                        </div>
                                        <div className="open_from_content">
                                          <p>Please read the attached treatment form, you may be expected to read & sign this at the appointment</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="req_img_box pt-40 mb-16">
                                  <div className="checkbox-group mb0">
                                      <label htmlFor="check4">
                                      <div className="checkinput mr-8">
                                        <input
                                        className="tnc"
                                          type="checkbox"
                                          id="check4"
                                          required
                                        />
                                        </div>
                                        <span className="checkbox-titles">I have read and accept the terms of the treatment consent form.     </span>  
                                        </label>
                                      </div>
                                </div>

                                <div className="req_img_box mb-16">
                                  <div className="checkbox-group mb0">
                                      <label htmlFor="check5">
                                      <div className="checkinput mr-8">
                                        <input
                                        className="tnc"
                                          type="checkbox"
                                          id="check5"
                                          required
                                        />
                                        </div>
                                        <span className="checkbox-titles">I accept the  <Link to="/booking-condition" className="plainbtn fontweight"> booking conditions </Link>.</span>  
                                        </label>
                                      </div>
                                </div>

                                <div className="btn-align mt-40 pd32">
                                    <LargeButton
                                        ButtonType="button"
                                        ButtonText="Submit enquiry"
                                        nextButtonClick={__nextEnquiry}
                                    />
                                </div>

                                
                                </form>

                              </div>

                            </Col>
                          </Row>
                        </Container>
                      </section>


    {/* // <div>
    //   <h4>Booking Details page</h4>
    //   {
    //     bookingData.isFetching ? <button >Loading</button> : <button onClick={__apiCall}>API Call</button>
    //   }

    //   <div>
    //     <code>
          
    //       {
    //         bookingData.bookingdetailsdata &&
    //        <>
    //         <div className="form-group">
    //             <label>Booking Date: </label>
    //             <strong>{bookingData.bookingdetailsdata.data.booking.date}</strong>
                
    //         </div>
    //         <div className="form-group">
    //             <label>Booking Time: </label>
    //             <strong>{bookingData.bookingdetailsdata.data.booking.time}</strong>
    //         </div>

           
            

            
    //         </>
       
    //       }
    //     </code>
    //   </div>
    // </div> */}

    </>
  );
};

export default EnquiryDetails;