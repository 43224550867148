
import React, { useCallback, useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from 'react-bootstrap';
import { LeftArrow, star, icon_unselected_star, practitioner_avtar } from '../../config/images';
import RatingPointCard from "../../components/UIComponent/RatingPointCard";
import { ginipigReviewtoPractAPI_Action,resetginipigReviewtoPractAPI_Action, resetLoginAPI_Action } from '../../stores/actions';
import LargeButton from '../../components/LargeButton';
import { getLocalStorageData, clearLocalStorage } from '../../utils/storage'
import {get24HrsTimeFormat}from '../../utils/utility'
import { S3_BUCKET_LINK } from '../../config/ApiConstants'


const initialErrorData = Object.freeze({

    description_error: "",
    is_form_valid_desc: false,

});

const LeaveReviewPastBooking = (props) => {


    let history = useHistory();
    const dispatch = useDispatch();

    const [subcategory, setsubcategory] = useState("");
    const [bookingDate, setbookingDate] = useState();
    const [requestArray, setrequestArray] = useState([]);
    const [detailsuser, setuserDetail] = useState();
    const [postcode, setPostcode] = useState("");
    const [cleanliness, setCleanliness] = useState(0);
    const [professionalism, setProfessionalism] = useState(0);
    const [etiquette, setEtiquette] = useState(0);
    const [result, setResult] = useState(0);
    const [totalrating, setTotalrating] = useState(0);
    const [character_count, setCharactercount] = useState(500);
    const [booking_id, setBookingId] = useState(500);
    const [errormsg, setErrorMsg] = useState();
   
    const [errorData, updateErrorData] = useState(initialErrorData);

    const [formData, updateFormData] = useState(Object.freeze({
        description: "",
        type_treatment_before: false,
    }));
    let subject = "Report Content GoGiniPig"
    let emailUrL = "mailto:hello@goginipig.com?subject=" + subject
    let loginTokenData = getLocalStorageData("loginData");
    const ginipigreviewtopract = useSelector(state => state.ginipigreviewtopract);

    useEffect(() => {
        let requestReviewDetail = getLocalStorageData("requestReviewDetail");
        if(requestReviewDetail===undefined){
            window.scrollTo(0, 0);
            history.push({
                pathname: '/',
            });
        }
       
        let requestArray = requestReviewDetail && requestReviewDetail.request
        let detailsuser = requestReviewDetail && requestReviewDetail.request && requestReviewDetail.request.user
        // console.log(requestArray)
        setuserDetail(detailsuser)
        setBookingId(requestReviewDetail&&requestReviewDetail.uuid)
        if (requestArray !== null && requestArray !== undefined) {
            let subCatArray = []
            requestArray.treatment.treatment_sub_category_new.forEach(element => {
                subCatArray.push(element.name)

            })

            var str;
            if (subCatArray.length === 1) {
                str = subCatArray.join()
            } else if (subCatArray.length === 2) {

                str = subCatArray.join(" & ")
            } else if (subCatArray.length === 3) {
                str = subCatArray[0] + "," + subCatArray[1] + " & " + subCatArray[2]
            }
            let newDate = ""
            let displayDate = requestReviewDetail && requestReviewDetail.date


            if (displayDate !== null && displayDate !== undefined) {
                let day = displayDate.substring(8, 10);
                let displayDay = ""
                if (day < 9) {
                    displayDay = day.substring(1, 2)
                } else {
                    displayDay = day
                }

                let n = displayDate.substring(5, 7)
                // console.log(n)
                let newn = ""
                if (n < 10) {
                    newn = n.substring(1, 2)
                } else {
                    newn = n
                }

                var month = new Array();
                month[1] = "January";
                month[2] = "February";
                month[3] = "March";
                month[4] = "April";
                month[5] = "May";
                month[6] = "June";
                month[7] = "July";
                month[8] = "August";
                month[9] = "September";
                month[10] = "October";
                month[11] = "November";
                month[12] = "December";

                var displaymonth = month[newn];

                if (displayDay == 1) {
                    newDate = displayDay + "st " + displaymonth + " "
                } else if (displayDay == 2) {
                    newDate = displayDay + "nd "  + displaymonth + " "
                } else if (displayDay == 3) {
                    newDate = displayDay + "rd "  + displaymonth + " "
                } else { 
                    newDate = displayDay + "th "  + displaymonth + " "
                }
            }
            let period = ""
            let hours = ''
            let [sHours, minutes] = ""
           // console.log(requestReviewDetail && requestReviewDetail.time)

            if (requestReviewDetail && requestReviewDetail.time !== null) {
                [sHours, minutes] = requestReviewDetail && requestReviewDetail.time  && requestReviewDetail.time.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
                period = +sHours < 12 ? 'AM' : 'PM';
                hours = +sHours % 12 || 12;
            }
            let timein24hrs= get24HrsTimeFormat(requestReviewDetail && requestReviewDetail.time  && requestReviewDetail.time)

            let displaydate = newDate + new Date().getFullYear() + " @ " + timein24hrs
            setbookingDate(displaydate)
            setsubcategory(requestArray?.treatment?.title)
            let postcode = requestArray?.postcodes?.postcode
            var lastThree = postcode.substr(postcode.length - 3);
        //    console.log(postcode)
        //    console.log(lastThree)

            var first = postcode.substr(0, postcode.length - 3);
           // console.log(first)
            var postcodenew = first + " " + lastThree
            setPostcode(postcodenew)
        }

        setrequestArray(requestArray)

    }, []);
   
    const handleChangeRatingClean = (rating) => {
        setCleanliness(rating)
        let totalrating =(rating + professionalism + etiquette + result) / 4
        var roundedrating = (Math.round(totalrating * 10) / 10).toFixed(1)
        setTotalrating(roundedrating)

    }

    const handleChangeRatingProp = (rating, name) => {
       // console.log(rating)
        setProfessionalism(rating)
        let totalrating =  (rating + cleanliness + etiquette + result) / 4
        var roundedrating = (Math.round(totalrating * 10) / 10).toFixed(1)
        setTotalrating(roundedrating)
    }


    const handleChangeRatingEthiq = (rating, name) => {
        setEtiquette(rating)
        let totalrating =  (rating + cleanliness + professionalism + result) / 4
        var roundedrating = (Math.round(totalrating * 10) / 10).toFixed(1)
        setTotalrating(roundedrating)
    }

    const handleChangeRatingresult = (rating, name) => {
        setResult(rating)
        let totalrating =  (rating + cleanliness + etiquette + professionalism) / 4
        var roundedrating = (Math.round(totalrating * 10) / 10).toFixed(1)
        setTotalrating(roundedrating)

    }


    // const checkDescValidation = (e) => {
    //     const { id, value } = e.target;
    //     const description = value.trim();
    //     if (!description) {

    //         updateErrorData({
    //             ...errorData,
    //             is_form_valid_desc: false,
    //             description_error: "comments is required.",
    //         });


    //     } else if (description.length < 25) {
    //         updateErrorData({
    //             ...errorData,
    //             is_form_valid_desc: false,
    //             description_error: "comments should be minimum 25 characters long.",
    //         });
    //     } else if (description.length > 500) {
    //         updateErrorData({
    //             ...errorData,
    //             is_form_valid_desc: false,
    //             description_error: "Only 500 Characters are Allowed.",
    //         });
    //     } else {


    //         updateErrorData({
    //             ...errorData,
    //             is_form_valid_desc: true,
    //             description_error: ""
    //         });
    //     }


    // };
    const __onTextChange = (e) => {
      //  const { id, value } = e.target;
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value,

        });
        if (e.target.name === "description" && e.target.value !== "") {
            setCharactercount(500 - e.target.value.length)
        } else {
            setCharactercount(500)
        }
        if (e.target.name === "description" && e.target.value.length > 25) {
           // console.log("inside", e.target.value.length)
            updateErrorData({
                ...errorData,

                is_form_valid_desc: false,
                description_error: ""

            });
        }


    };


    const ClickSubmitButton = () => {
       
        let formDatanew = {
            practitioner_id:detailsuser.uuid,
            booking_id:booking_id,
            cleanliness:cleanliness,
            professionalism:professionalism,
            etiquette:etiquette,
            result:result,
            overall:totalrating,
            comments:formData.description,
        
        }
       // console.log(formDatanew)
       if(totalrating > 0){
        // alert("rating point")
        __apiCall(formDatanew)
        }else{
            // alert("not workng")
            setErrorMsg("Please select a star to rate each category")
        }


    }

    
    const __apiCall = useCallback(async (formDatanew) => {

        if (loginTokenData) {
            if (loginTokenData !== null || loginTokenData !== undefined) {
                try {
                    await dispatch(ginipigReviewtoPractAPI_Action(formDatanew,loginTokenData.token));
                } catch (error) {
                    console.error(" profile ::  profile() :: Exception ==", error);
                }
            }
        }
    });

    if (ginipigreviewtopract.isSuccess === true) {
        dispatch(resetginipigReviewtoPractAPI_Action());
      
        window.scrollTo(0, 0);
        history.push({
          pathname: '/practitioner-thankyou-review',
          isleavereview:true

        });
    }else if(ginipigreviewtopract.ginipigreviewtopractdata && ginipigreviewtopract.ginipigreviewtopractdata.status_code===401){
        window.scrollTo(0, 0);
        dispatch(resetginipigReviewtoPractAPI_Action());
        dispatch(resetLoginAPI_Action());
        clearLocalStorage("loginData")
        clearLocalStorage("practitionersignupData")
        clearLocalStorage("RedirectPathName")
        clearLocalStorage("requestDataLocal")
        window.scrollTo(0, 0);
        history.push({
          pathname: '/login',

        });
    }
    return (
        <>
            <section className="bgcolor-gray common-section leave_review_ginipig_pg pb80">
                <Container>
                    <Row>
                        <Col className="col-lg-11 m-auto">
                            <div className="navigationTop padright-0">
                                <div className="heading-history">
                                    <div className="back-history">
                                        <button type="button" onClick={() => history.goBack()} className="back-btn" >
                                            {" "}
                                            <img src={LeftArrow} alt="arrow" />{" "}
                                        </button>
                                    </div>
                                    <div className="history-title">
                                        <h2> Leave A Review  </h2>
                                    </div>
                                </div>
                            </div>

                            <div className="requirement_wrp padright-0 mt-24">
                                <div className="request_card_review mb-16">
                                    <h3 className="rating-heading mb-8"> Treatment/Service Information </h3>
                                    <div className="detail_box white_box mb-70">
                                        <div className="categorylist-box">
                                            <div className="list_name">{subcategory && subcategory}</div>
                                            <div className="category_price">
                                                <span className="price_strik og_price"> <span>£</span>{requestArray && requestArray.rrp_price && requestArray.rrp_price.toFixed(2)}</span>
                                                <span className="price_discounted"><span>£</span>{requestArray && requestArray.your_price && requestArray.your_price.toFixed(2)}</span>
                                            </div>
                                        </div>
                                        <div className="category_badge">
                                            <span className="category_badge_name">{requestArray && requestArray.treatment && requestArray.treatment.categories && requestArray.treatment.categories.name && requestArray.treatment.categories.name}</span>
                                            <span className="category_badge_hrs">{requestArray && requestArray.hours + "h " + requestArray.minutes + "m"}</span>
                                        </div>
                                        <div className="detail_content mt-8">
                                            <p> {requestArray && requestArray.treatment && requestArray.treatment.description}
                                            </p>
                                            <div className="user_profile btn-align">
                                                <div className="username_box">
                                                    <div className="user_avatar">
                                                        {
                                                            detailsuser && detailsuser.profile_pic !== null ? detailsuser && detailsuser.profile_pic !== "" ? (

                                                                <img className="img_avtar" alt="arrow" src={S3_BUCKET_LINK + detailsuser.profile_pic} />

                                                            ) : null : <img src={practitioner_avtar} className="img_avtar" alt="arrow" />
                                                        }


                                                    </div>
                                                    <div className="user_text">
                                                    <span>{detailsuser && detailsuser.first_name} {detailsuser && detailsuser.last_name}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="detail-button-grp">
                                            <div className="list-buttons">
                                                <div className="detail-btns-grp">
                                                    <div className="left-align">
                                                        <button className="varity-listbtn italic_btn mr-24">{bookingDate && bookingDate} </button>
                                                        <button className="varity-listbtn ">{postcode} </button>
                                                    </div>
                                                    <div className="right-align">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>


                                <h3 className="rating-heading mb-24">Leaving a rating for each category</h3>
                                <div className="d-flexbox mb-40">
                                    <div className="first-div">

                                        
                                        <RatingPointCard
                                            ratingName="Professionalism"
                                            handleChangeRating={handleChangeRatingProp}
                                            selectedimage={star}
                                            initialRating={professionalism}
                                            unselectedimage={icon_unselected_star}
                                        />
                                        <RatingPointCard
                                            ratingName="Friendliness"
                                            handleChangeRating={handleChangeRatingEthiq}
                                            selectedimage={star}
                                            initialRating={etiquette}
                                            unselectedimage={icon_unselected_star}
                                        />
                                        <RatingPointCard
                                            //ratingName="Cleanliness"
                                            ratingName="Reliability"
                                            handleChangeRating={handleChangeRatingClean}
                                            selectedimage={star}
                                            initialRating={cleanliness}
                                            unselectedimage={icon_unselected_star}
                                        />
                                        <RatingPointCard
                                            ratingName="Results"
                                            handleChangeRating={handleChangeRatingresult}
                                            selectedimage={star}
                                            initialRating={result}
                                            unselectedimage={icon_unselected_star}
                                        />
                                    </div>
                                    <div className="second-div">
                                        <div className="rating_points">
                                            <img src={star} className="stars" alt="arrow" />
                                            <p className="points">{totalrating}</p>
                                        </div>
                                    </div>
                                </div>
                                <p className="error" style={{color:'red'}}>{errormsg}</p>


                                <div className="review-comments mb-16">
                                    <div className="optional-errormsg">
                                        <label className="profile-label">Leaving your comments</label>
                                        {errorData.description_error &&
                                            errorData.description_error !== "" ? (
                                                <p className="error">
                                                    <p style={{ color: "red" }}>
                                                        {errorData.description_error}{" "}
                                                    </p>
                                                </p>
                                            ) : null}

                                            { ginipigreviewtopract.isError &&  ginipigreviewtopract.ginipigreviewtopractdata &&
                                                ginipigreviewtopract.ginipigreviewtopractdata.hasOwnProperty(
                                                    "message"
                                                ) ? (
                                                    <p className="error">
                                                        <p style={{ color: "red" }}>
                                                            {
                                                                ginipigreviewtopract.ginipigreviewtopractdata.message
                                                            }{" "}
                                                        </p>
                                                    </p>
                                                ) : null}
                                        <p className="optiontext">Optional</p>
                                    </div>

                                    <div className="input-addicon addicontextarea" >
                                        <textarea
                                            // placeholder="Character limit"
                                            id="description"
                                            name="description"
                                            onChange={__onTextChange}
                                            length={500}
                                            maxLength="500"
                                            //onBlur={checkDescValidation}

                                        //onBlur={checkBioValidation}
                                        />
                                        {
                                            character_count === 500 ?
                                                <span className="char-span">{character_count} Character limit</span> :
                                                <span className="char-span">{character_count} Remaining</span>
                                        }
                                    </div>
                                </div>

                                <p className="inappropriate_text">Problem with treatment, Treatment/Service provider  or overall experience?
                                          <span>   </span>
                                    <a rel="noopener noreferrer" href={emailUrL}>
                                        <button className="plainbtn"> make a complaint </button>
                                    </a>
                                </p>

                                <div className="btn-align mt-24 pd32">
                                    <LargeButton
                                        ButtonType="button"
                                        ButtonText="Submit review"
                                        nextButtonClick={ClickSubmitButton}
                                    />
                                </div>


                            </div>

                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default LeaveReviewPastBooking;