import React from 'react';
import { formatPostcode } from '../utils/utility';

const ThumbnailViewDetail = (props) => {

    var rrp = props.originalPriceText;
    var your_price = props.discountPriceText;

    return (
        <>
            <div className="detail_box boking-view white_box">
                <div className="categorylist-box">
                    <div className="list_name">{props.treatmentTitle}</div>
                    <div className="category_price">
                        <span className="price_strik og_price"> <span>{props.currencySign}</span>{rrp}</span>
                        <span className="price_discounted"><span>{props.currencySign}</span>{your_price}</span>
                    </div>
                </div>
                <div className="category_badge">
                    <span className="category_badge_name">{props.tagsName}</span>
                    
                    <span className="category_badge_hrs">{props.hrs > 0 ? props.hrs + "h" : ""} {props.minutes > 0 ? props.minutes + "m" : ""}</span>
                </div>
                <div className="datail_option_category mt-16 mb-24">
                    <div className="exp_list_item">
                        <div className="iconwrp_box">
                            <span className="list--icons"><img src={props.categoryIcon1} className="categoryIcon" /></span>
                            <span className=" icon_names">  {props.categorName1} </span>
                        </div>
                        <div className="iconwrp_box">
                            <span className="list--icons"><img src={props.categoryIcon2} className="categoryIcon" /></span>
                            <span className=" icon_names">  {props.categorName2} </span>
                        </div>
                        <div className="iconwrp_box">
                            <span className="list--icons"><img src={props.categoryIcon3} className="categoryIcon" /></span>
                            <span className=" icon_names">  {props.categorName3} </span>
                        </div>
                    </div>
                </div>
                <div className="detail-button-grp">
                    <div className="list-buttons">
                        <div className="detail-btns-grp">
                            <div className="left-align">
                                <button className="varity-listbtn italic_btn mr-24"> {props.buttonRequiestTime} </button>
                               {props.buttonReqAddress!==null ?
                                    <button className="varity-listbtn "> {formatPostcode(props.buttonReqAddress)} </button>:
                                    <button className="varity-listbtn ">Online </button>
                               }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ThumbnailViewDetail;