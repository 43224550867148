
import React, { useCallback, useState, useEffect } from "react";
import { useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from 'react-bootstrap';
import moment from 'moment';
import { LeftArrow, iconPhoto, iconCase_Study, iconNearly_there, iconGiniPigs_Required } from '../../config/images';
import LargeButton from '../../components/LargeButton';
import ThumbnailViewDetail from '../../components/ThumbnailViewDetail';
import { requestDetailsAPI_Action, treatmentDetailsSave, bookingDetailsSave, resetrequestdetailsAPI_Action } from '../../stores/actions'
import { getLocalStorageData, clearLocalStorage, setLocalStorageData } from '../../utils/storage'
import { formatPostcode } from '../../utils/utility';
import { getHrsLeft } from '../../utils/utility';

const BookingDetails = (props) => {
  let history = useHistory();
  // Initialize hooks first   
  const dispatch = useDispatch();
  // Selectors
  //const bookingData = useSelector(state => state.bookingdetails);
  //const userData = useSelector(state => state.userdetails);        
  const loginUserData = useSelector(state => state.login);
  const requestdetails = useSelector(state => state.requestdetails);
  const [paymentDisable, setpaymentDisable] = useState(true);
  const [modal_require, setModalRequire] = useState(false);
  const [consent_form, setConsentForm] = useState(false);
  const [photo_condition, setPhotoCondition] = useState(false);
  const [patch_required, setPatchRequired] = useState(false);

  const [isData, setIsData] = useState(false);
  const [accept_booking_check, setAcceptBookingCheck] = useState(1);
  const [validation_check, setValidationCheck] = useState([]);
  const [pattextError, setPattextError] = useState(false);
  const [validation_check_length, setValidationCheckLength] = useState([]);
  const [isPhotoAdded, setIsPhotoAdded] = useState(false);
  var param_data = props.match.params.bookingid;

  //const deratils_state = useSelector(state => state);
  //console.log("deratils_state ", deratils_state)

  const [formData, updateFormData] = useState(Object.freeze({
    modal_require: false,
    consent_form: false,
    photo_condition: false,
    patch_required: false,
    accept_booking: false
  }));
  var photo_added;
  var request_type = 1;
  var appointment_date;
  var appointment_time;
  let post_data = {
    request_id: param_data,
    date: "",
    time: "",
    intent_to_pay_status: 'full',
    stripeToken: '',
    stripeTokenType: 'card',
    stripeEmail: '',
    is_suitable_to_treatment: 0,
    is_supplied_matching_photos: 0,
    is_read_accept_consent_form: 0,
    is_accept_booking_condition: 0,
    is_allow_patch_test: 1,
    use_ginipig_existing_card: 0,
    request_image: []
  }

  // Use effects

  useEffect(() => {
    clearLocalStorage('book_close_request')
    __apiCall()
    // if(requestdetails)
    // {
    //     console.log("requestdetails:-",requestdetails);
    //     if(requestdetails.hasOwnProperty('bookingCondition')){
    //       if(requestdetails.bookingCondition !== null){
    //         updateFormData({
    //           ...formData,
    //           modal_require : requestdetails.bookingCondition.modal_require,
    //           consent_form:requestdetails.bookingCondition.consent_form,
    //           photo_condition:requestdetails.bookingCondition.photo_condition,
    //           patch_required:requestdetails.bookingCondition.patch_required,
    //           accept_booking:requestdetails.bookingCondition.accept_booking,
    //         });
    //       }              
    //     }
    // }            

    if (getLocalStorageData("photo_added") !== undefined) {
      photo_added = JSON.parse(getLocalStorageData("photo_added"));
      if (photo_added.photo_added === true) {
        if (requestdetails && !requestdetails.isFetching) {
          if (requestdetails.hasOwnProperty('photoDetails')) {
            if (requestdetails.photoDetails === null) {
              //history.push('/photo-requirement/'+param_data);
              setIsPhotoAdded(true);
            } else {
              setIsPhotoAdded(false)
            }
          }
        }
      }
    }
    var booking_cond = '';
    if (getLocalStorageData("bookingcondition_requirement") !== undefined) {
      booking_cond = JSON.parse(getLocalStorageData("bookingcondition_requirement"));
      if (booking_cond !== null) {
        updateFormData({
          ...formData,
          modal_require: booking_cond.modal_require,
          consent_form: booking_cond.consent_form,
          photo_condition: booking_cond.photo_condition,
          patch_required: booking_cond.patch_required,
          accept_booking: booking_cond.accept_booking,
        });
      }
    }

  }, []);

  //   if(profileDetails && profileDetails.userdetailsdata &&  profileDetails.userdetailsdata.status_code===401){
  //     clearLocalStorage("loginData")
  //   clearLocalStorage("practitionersignupData")
  //    dispatch(resetLoginAPI_Action());
  //    dispatch(resetUserAPI_Action());
  //    dispatch(resetRegisterAPI_Action());
  //    dispatch(resetPractitionerAddInfoAPI_Action());
  //    dispatch(resettreatmentaddreviewAPI_Action());
  //    dispatch(resetPractionerCategory_API_Action());
  //    dispatch(resetpractitionerProfileAPI_Action());
  //    dispatch(resetGinipigUpdateAPI_Action());
  //    dispatch(resetgoginipigprofileAPI_Action());


  //   history.push("/login");
  // }


  if (requestdetails.requestdetailsdata && requestdetails.requestdetailsdata !== null) {
    var time = '';
    var set_date;
    let period = ""
    let hours = ''
    let [sHours, minutes] = ""
    if (time == "") {
      if (requestdetails.requestdetailsdata.data.request.type !== 1) {
        if (requestdetails.requestdetailsdata.data.request.request_timeslots.data[0].start_time !== null) {
          [sHours, minutes] = requestdetails.requestdetailsdata.data.request.request_timeslots.data[0].start_time && requestdetails.requestdetailsdata.data.request.request_timeslots.data[0].start_time.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
          period = +sHours < 12 ? 'am' : 'pm';
          hours = +sHours % 12 || 12;
        }

        let timein24hrs = ""
        if (requestdetails.requestdetailsdata.data.request.request_timeslots.data[0].start_time !== null && requestdetails.requestdetailsdata.data.request.request_timeslots.data[0].start_time !== undefined) {
          timein24hrs = requestdetails.requestdetailsdata.data.request.request_timeslots.data[0].start_time.slice(0, 5)
        }


        let dateFormat = moment(requestdetails.requestdetailsdata.data.request.request_timeslots.data[0].date_time).format("Do MMMM YYYY")
        set_date = dateFormat + " @ " + timein24hrs
        //set_date =moment(requestdetails.requestdetailsdata.data.request.request_timeslots.data[0].date_time,"yy-MM-DD, h:mm:ss").format("Do MMMM YYYY") + " @ " + hours + ":" + minutes + period

      }
      else {
        let localdata = getLocalStorageData("book_open_request_time");
        if (localdata) {
          let temp = JSON.parse(getLocalStorageData("book_open_request_time"));
          let datesCount = 0;
          let timeCount = 0;
          let selectedDate = "";
          // console.log("selected time data:-", temp);
          //let selectedDates = temp.map(obj=>{
          if (temp.timeslots.morning.length > 0 || temp.timeslots.afternoon.length > 0 || temp.timeslots.evening.length > 0) {
            datesCount++;
            selectedDate = selectedDate !== "" ? selectedDate : temp.date;
            if (time === "") {
              if (temp.timeslots.morning.length > 0 && temp.timeslots.morning.length < 2) {
                time = temp.timeslots.morning[0]
              } else if (temp.timeslots.afternoon.length > 0 && temp.timeslots.afternoon.length < 2) {
                time = temp.timeslots.afternoon[0]
              } else if (temp.timeslots.evening.length > 0 && temp.timeslots.evening.length < 2) {
                time = temp.timeslots.evening[0]
              }
            }
          }

          if (temp.timeslots.morning.length > 0) {
            timeCount++;
            if (temp.timeslots.morning.length > 1)
              timeCount++;
          }
          if (temp.timeslots.afternoon.length > 0) {
            timeCount++;
            if (temp.timeslots.afternoon.length > 1)
              timeCount++;
          }
          if (temp.timeslots.evening.length > 0) {
            timeCount++;
            if (temp.timeslots.evening.length > 1)
              timeCount++;
          }
          // })
          // console.log("datesCount:-",datesCount," timeCount:-",timeCount, "selectedDate:- ",selectedDate, "time:-",time);

          //time =  timeCount>1 ? " @ various times": `, ${time}`;
          time = timeCount > 1 ? " " : `, ${time}`;
          set_date = moment(selectedDate, "DD-MM-yy").format("Do MMMM YYYY") + time;
          //set_date =moment(selectedDate,"DD-MM-yy").format("Do MMMM YYYY") + time;
        
        }


      }
    }
  }

  const __apiCall = useCallback(async () => {
    const loginToken = loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token : "" : "";
    const ginpig_id = loginUserData.logindata ? loginUserData.logindata.data && loginUserData.logindata.data.user.type === "ginipig" ? loginUserData.logindata.data.user.uuid : 0 : 0;

    try {
      // console.log("request details api call:-",param_data,loginToken);
      await dispatch(requestDetailsAPI_Action(param_data, loginToken, ginpig_id));
      setIsData(true)
    } catch (error) {
      console.error(" profile ::  profile() :: Exception ==", error);
    }
  });

  if (isData) {
    setTimeout(() => {
      var isValid = []
      if (requestdetails.requestdetailsdata && requestdetails.requestdetailsdata != null) {
        if (requestdetails.requestdetailsdata.data.request.attributes_required === 1) {
          setModalRequire(true)
          isValid.push('modal_require')
        }
        if (requestdetails.requestdetailsdata.data.request.is_patchtest_required === 1) {
          setPatchRequired(true)
          isValid.push('patch_required')
        }
        if (requestdetails.requestdetailsdata.data.request.is_consentform_required === 1) {
          setConsentForm(true)
          isValid.push('consent_form')
        }
        if (requestdetails.requestdetailsdata.data.request.photograph_required === 1) {
          setPhotoCondition(true)
          isValid.push('photo_condition')
        }
        isValid.push('accept_booking')
        setValidationCheck(isValid);
        // console.log("form data after sometime ", formData)
        setIsData(false)
        isAlreadyCheck()
      }
    }, 2)
  }

  const isAlreadyCheck = () => {
    var setPayment_change = true;
    if (validation_check.length > 0) {
      for (var i = 0; i < validation_check.length; i++) {
        if (validation_check[i] === 'photo_condition') {
          if (formData.photo_condition === true) {
            validation_check_length.push('photo_condition')
          }
        }
        if (validation_check[i] === 'patch_required') {
          if (formData.patch_required === true) {
            validation_check_length.push('patch_required')
          }
        }
        if (validation_check[i] === 'consent_form') {
          if (formData.consent_form === true) {
            validation_check_length.push('consent_form')
          }
        }
        if (validation_check[i] === 'accept_booking') {
          if (formData.accept_booking === true) {
            validation_check_length.push('accept_booking')
          }
        }
        if (validation_check[i] === 'modal_require') {
          if (formData.modal_require === true) {
            validation_check_length.push('modal_require')
          }
        }
      }
    }
   
    if (validation_check.length === validation_check_length.length) {
      setPayment_change = false;
      // console.log('length  ', validation_check.length , ' ',  validation_check_length.length)
    } else {
      //  console.log('length else  ',validation_check.length , ' ',  validation_check_length.length)
    }
    setpaymentDisable(setPayment_change)
  }

  const proceedToPayment = async () => {
    if (requestdetails && requestdetails.requestdetailsdata !== null && !requestdetails.isFetching) {
      appointment_date = requestdetails.requestdetailsdata !== null ? moment(requestdetails.requestdetailsdata.data.request.request_timeslots.data[0].date_time, "YYYY-MM-DD").format("YYYY/MM/DD") : '';
      appointment_time = requestdetails.requestdetailsdata !== null ? moment(requestdetails.requestdetailsdata.data.request.request_timeslots.data[0].start_time, "H:mm").format("H:mm") : '';
      if (requestdetails.requestdetailsdata !== null && requestdetails.requestdetailsdata.data.request.type === 1) {
        let selectedDates = getLocalStorageData("book_open_request_time") ? JSON.parse(getLocalStorageData("book_open_request_time")) : {}; post_data.date = moment(selectedDates.date, "DD-MM-YYYY").format("YYYY/MM/DD");
        post_data.time = selectedDates.timeslots;

        let temp = "";
        var hrsLeft = 0;
        if (selectedDates.timeslots.morning.length > 0) {
          temp = post_data.date + " " + selectedDates.timeslots.morning[0];
          var datetimeFrmt = moment(temp, "YYYY-MM-DD H:mm").format("YYYY/MM/DD H:mm");
          hrsLeft = getHrsLeft(datetimeFrmt);
          if (hrsLeft <= 72 && requestdetails.requestdetailsdata !== null && requestdetails.requestdetailsdata.data.request.is_patchtest_required==1) {
            setPattextError(true)
          } else {
            setPattextError(false)
            _NextApicall()
            setPattextError(false)

          }

        } else if (selectedDates.timeslots.afternoon.length > 0) {
          temp = post_data.date + " " + selectedDates.timeslots.afternoon[0];
          var datetimeFrmt = moment(temp, "YYYY-MM-DD H:mm").format("YYYY/MM/DD H:mm");
          hrsLeft = getHrsLeft(datetimeFrmt);
          if (hrsLeft <= 72 && requestdetails.requestdetailsdata.data.request.is_patchtest_required==1) {
            setPattextError(true)
          } else {
            setPattextError(false)
            _NextApicall()

          }

        } else if (selectedDates.timeslots.evening.length > 0) {
          temp = post_data.date + " " + selectedDates.timeslots.evening[0];
          var datetimeFrmt = moment(temp, "YYYY-MM-DD H:mm").format("YYYY/MM/DD H:mm");
          hrsLeft = getHrsLeft(datetimeFrmt);
          if (hrsLeft <= 72 && requestdetails.requestdetailsdata.data.request.is_patchtest_required==1) {
            setPattextError(true)
          } else {
            setPattextError(false)
            _NextApicall()
          }
        }
        // setDisable(true);
      } else {
        let tempdate = requestdetails.requestdetailsdata.data.request.request_timeslots.data[0].date_time.split(" ");
        let temp = tempdate[0] + " " + requestdetails.requestdetailsdata.data.request.request_timeslots.data[0].start_time;

        var datetimeFrmt = moment(temp, "YYYY-MM-DD H:mm").format("YYYY/MM/DD H:mm");
        var hrsLeft = getHrsLeft(datetimeFrmt);
        if (hrsLeft <= 72 &&  requestdetails.requestdetailsdata.data.request.is_patchtest_required==1) {
          setPattextError(true)
        } else {
          setPattextError(false)
          _NextApicall()
        }
      }
    }

  }

  const _NextApicall = async () => {
    try {
      let data = {
        modal_require: formData.modal_require,
        consent_form: formData.consent_form,
        photo_condition: formData.photo_condition,
        patch_required: formData.patch_required,
        accept_booking: formData.accept_booking
      }
      setLocalStorageData("bookingcondition_requirement", JSON.stringify(data))
      await dispatch(bookingDetailsSave(data));
      await dispatch(treatmentDetailsSave(requestdetails.requestdetailsdata.data));
      window.scrollTo(0, 0);
      history.push('/payment-option/' + param_data)
    } catch (error) {
      console.error(" profile ::  profile() :: Exception ==", error);
    }

  }

  const toggleChangerequiremnetsModel = (e) => {
    updateFormData({
      ...formData,
      modal_require: !formData.modal_require,

    });
    // console.log("formData.modal_require1 ", formData.modal_require, ' ',formData.photo_condition, ' ', formData.patch_required, ' ', formData.consent_form)
    var setPayment_change = true;
    setValidationCheckLength([])
    if (validation_check.length > 0) {
      for (var i = 0; i < validation_check.length; i++) {
        if (validation_check[i] === 'photo_condition') {
          if (formData.photo_condition === true) {
            validation_check_length.push('photo_condition')
          }
        }
        if (validation_check[i] === 'patch_required') {
          if (formData.patch_required === true) {
            validation_check_length.push('patch_required')
          }
        }
        if (validation_check[i] === 'consent_form') {
          if (formData.consent_form === true) {
            validation_check_length.push('consent_form')
          }
        }
        if (validation_check[i] === 'accept_booking') {
          if (formData.accept_booking === true) {
            validation_check_length.push('accept_booking')
          }
        }
        if (validation_check[i] === 'modal_require') {
          if (formData.modal_require === false) {
            validation_check_length.push('modal_require')
          }
        }
      }
    }

    if (validation_check.length === validation_check_length.length) {
      setPayment_change = false;
      // console.log('length  ', validation_check.length , ' ',  validation_check_length.length)
    } else {
      //  console.log('length else  ',validation_check.length , ' ',  validation_check_length.length)
    }

    setpaymentDisable(setPayment_change)
  }

  const toggleChangerequiremnetsConsent = (e) => {
    updateFormData({
      ...formData,
      consent_form: !formData.consent_form,
    });
    var setPayment_change = true;
    setValidationCheckLength([])
    if (validation_check.length > 0) {
      for (var i = 0; i < validation_check.length; i++) {
        if (validation_check[i] === 'photo_condition') {
          if (formData.photo_condition === true) {
            validation_check_length.push('photo_condition')
          }
        }
        if (validation_check[i] === 'patch_required') {
          if (formData.patch_required === true) {
            validation_check_length.push('patch_required')
          }
        }
        if (validation_check[i] === 'consent_form') {
          if (formData.consent_form === false) {
            validation_check_length.push('consent_form')
          }
        }
        if (validation_check[i] === 'accept_booking') {
          if (formData.accept_booking === true) {
            validation_check_length.push('accept_booking')
          }
        }
        if (validation_check[i] === 'modal_require') {
          if (formData.modal_require === true) {
            validation_check_length.push('modal_require')
          }
        }
      }
    }
    if (validation_check.length === validation_check_length.length) {
      setPayment_change = false;
      //console.log('length  ', validation_check.length , ' ',  validation_check_length.length)
    } else {
      // console.log('length else  ',validation_check.length , ' ',  validation_check_length.length)
    }
    setpaymentDisable(setPayment_change)
  }

  const toggleChangerequiremnetsPhoto = (e) => {
    updateFormData({
      ...formData,
      photo_condition: !formData.photo_condition,
    });
    var setPayment_change = true;
    setValidationCheckLength([])
    if (validation_check.length > 0) {
      for (var i = 0; i < validation_check.length; i++) {
        if (validation_check[i] === 'photo_condition') {
          if (formData.photo_condition === false) {
            validation_check_length.push('photo_condition')
          }
        }
        if (validation_check[i] === 'patch_required') {
          if (formData.patch_required === true) {
            validation_check_length.push('patch_required')
          }
        }
        if (validation_check[i] === 'consent_form') {
          if (formData.consent_form === true) {
            validation_check_length.push('consent_form')
          }
        }
        if (validation_check[i] === 'accept_booking') {
          if (formData.accept_booking === true) {
            validation_check_length.push('accept_booking')
          }
        }
        if (validation_check[i] === 'modal_require') {
          if (formData.modal_require === true) {
            validation_check_length.push('modal_require')
          }
        }
      }
    }
    if (validation_check.length === validation_check_length.length) {
      setPayment_change = false;
      // console.log('length  ', validation_check.length , ' ',  validation_check_length.length)
    } else {
      //console.log('length else  ',validation_check.length , ' ',  validation_check_length.length)
    }
    setpaymentDisable(setPayment_change)
  }

  const toggleChangerequiremnetsPatch = (e) => {
    updateFormData({
      ...formData,
      patch_required: !formData.patch_required,
    });

    
    var setPayment_change = true;
    setValidationCheckLength([])
    if (validation_check.length > 0) {
      for (var i = 0; i < validation_check.length; i++) {

        if (validation_check[i] === 'photo_condition') {
          if (formData.photo_condition === true) {
            validation_check_length.push('photo_condition')
          }
        }
        if (validation_check[i] === 'patch_required') {
          if (formData.patch_required === false) {
            if (validation_check_length.indexOf("patch_required") === -1) {
              validation_check_length.push('patch_required')
            }
            //  validation_check_length.push('patch_required')
          }
        }
        if (validation_check[i] === 'consent_form') {
          if (formData.consent_form === true) {
            validation_check_length.push('consent_form')
          }
        }
        if (validation_check[i] === 'accept_booking') {
          if (formData.accept_booking === true) {
            validation_check_length.push('accept_booking')
          }
        }
        if (validation_check[i] === 'modal_require') {
          if (formData.modal_require === true) {
            validation_check_length.push('modal_require')
          }
        }
      }
    }
    if (validation_check.length === validation_check_length.length) {
      setPayment_change = false;
      // console.log('length  ', validation_check.length , ' ',  validation_check_length.length)
    } else {
      // console.log('length else  ',validation_check.length , ' ',  validation_check_length.length)
    }

    setpaymentDisable(setPayment_change)
  }

  const toggleChangerequiremnetsBooking = (e) => {
    updateFormData({
      ...formData,
      accept_booking: !formData.accept_booking,

    });
    var setPayment_change = true;
    setValidationCheckLength([])
    if (validation_check.length > 0) {
      for (var i = 0; i < validation_check.length; i++) {
        if (validation_check[i] === 'photo_condition') {
          if (formData.photo_condition === true) {
            if (validation_check_length.indexOf("photo_condition") === -1) {
              validation_check_length.push('photo_condition')
            }
          }
        }
        if (validation_check[i] === 'patch_required') {
          if (formData.patch_required === true) {
            if (validation_check_length.indexOf("patch_required") === -1) {
              validation_check_length.push('patch_required')
            }
          }
        }
        if (validation_check[i] === 'consent_form') {
          if (formData.consent_form === true) {
            if (validation_check_length.indexOf("consent_form") === -1) {
              validation_check_length.push('consent_form')
            }
          }
        }
        if (validation_check[i] === 'accept_booking') {
          if (formData.accept_booking === false) {
            validation_check_length.push('accept_booking')
          }
        }
        if (validation_check[i] === 'modal_require') {
          if (formData.modal_require === true) {
            if (validation_check_length.indexOf("modal_require") === -1) {
              validation_check_length.push('modal_require')
            }
          }
        }
      }
    }

    if (validation_check.length === validation_check_length.length) {
      setPayment_change = false;
      // console.log('length  ', validation_check.length , ' ',  validation_check_length.length)
    } else {
      // console.log('length else  ',validation_check.length , ' ',  validation_check_length.length)
    }

    setpaymentDisable(setPayment_change)
  }


  const bookingCondition = async () => {
    try {
      let data = {
        modal_require: formData.modal_require,
        consent_form: formData.consent_form,
        photo_condition: formData.photo_condition,
        patch_required: formData.patch_required,
        accept_booking: formData.accept_booking
      }
      await dispatch(bookingDetailsSave(data));
      window.scrollTo(0, 0);
      history.push('/booking-condition')
    } catch (error) {
      console.error(" profile ::  profile() :: Exception ==", error);
    }

  }

  const onBackPressed = async () => {
    //  console.log("requestdetails:-",requestdetails.requestdetailsdata, photo_added);
    if (photo_added !== undefined) {
      if (photo_added.photo_added === true) {
        window.scrollTo(0, 0);
        history.push('/photo-requirement/' + param_data);
      }
    }
    else {
      if (requestdetails.requestdetailsdata.data.request.photograph_required === 1) {
        window.scrollTo(0, 0);
        history.push('/photo-requirement/' + param_data);
      } else {
        let view_booking_from_profile=getLocalStorageData("view_booking_from_profile")

        //console.log("requestdetails.requestdetailsdata.data",requestdetails.requestdetailsdata.data.request.user.data)
        if(view_booking_from_profile!==undefined && view_booking_from_profile==1){
          history.push({
            pathname: '/practitioner-profile-ginipig',
            state: { 'paramData': requestdetails.requestdetailsdata.data.request.user.data.uuid }
          });
        }else if (requestdetails.requestdetailsdata.data.request.type == 1) {
          window.scrollTo(0, 0);
          await dispatch(resetrequestdetailsAPI_Action())
          history.push('/treatment-detail-for-open-request/' + param_data);
        } else {
          window.scrollTo(0, 0);
          history.push('/treatment-detail-for-close-request/' + param_data);
        }

      }
    }
  }

  const __reDirectPage = () => {
    let data = {
      modal_require: formData.modal_require,
      consent_form: formData.consent_form,
      photo_condition: formData.photo_condition,
      patch_required: formData.patch_required,
      accept_booking: formData.accept_booking

    }
    setLocalStorageData("bookingcondition_requirement", JSON.stringify(data))
    window.scrollTo(0, 0);
  }




  //console.log("req", requestdetails)
  //console.log("date", set_date)

  return (
    <>

      <section className="bgcolor-gray common-section photo_requirement_pg pb80">
        <Container>
          <Row>
            {
              isPhotoAdded ?

                <Col className="col-lg-11 m-auto">
                  <div className="navigationTop">
                    <div className="heading-history">
                      <div className="back-history">
                        <button type="button" onClick={() => onBackPressed()} className="back-btn" >
                          {" "}
                          <img src={LeftArrow} alt="arrow" />{" "}
                        </button>
                      </div>
                      <div className="history-title">
                        <h2> Booking Details  </h2>
                      </div>
                    </div>
                  </div>

                  <div className="btn-align mt-40 pd32" style={{ justifyContent: "center" }}>
                    <LargeButton
                      ButtonType="button"
                      ButtonText={"Upload Image(s)"}
                      nextButtonClick={() => history.push('/photo-requirement/' + param_data)}
                    />
                  </div>
                </Col>
                :
                requestdetails.requestdetailsdata && requestdetails.requestdetailsdata !== null ?
                  <Col className="col-lg-11 m-auto">
                    <div className="navigationTop">
                      <div className="heading-history">
                        <div className="back-history">
                          <button type="button" onClick={() => onBackPressed()} className="back-btn" >
                            {" "}
                            <img src={LeftArrow} alt="arrow" />{" "}
                          </button>
                        </div>
                        <div className="history-title">
                          <h2> Booking Details  </h2>
                        </div>
                      </div>
                    </div>

                    <div className="requirement_wrp">
                      <form>
                        <div className="booking_box mt-24 mb-70">

                          <ThumbnailViewDetail
                            treatmentTitle={requestdetails.requestdetailsdata.data.request.treatment.data.title}
                            currencySign="£"
                            originalPriceText={requestdetails.requestdetailsdata.data.request.rrp_price.toFixed(2)}
                            discountPriceText={requestdetails.requestdetailsdata.data.request.your_price.toFixed(2)}
                            tagsName={requestdetails.requestdetailsdata.data.request.treatment.data.categories.data.name}
                            hrs={requestdetails.requestdetailsdata.data.request.hours}
                            minutes={requestdetails.requestdetailsdata.data.request.minutes}
                            // duration={requestdetails.requestdetailsdata.data.request.hours +'h'+' '+ requestdetails.requestdetailsdata.data.request.minutes+'m'}
                            categoryIcon1={iconCase_Study}
                            categorName1={requestdetails.requestdetailsdata.data.request.reason_require_ginipig}
                            categoryIcon2={iconNearly_there}
                            categorName2={requestdetails.requestdetailsdata.data.request.expertise_level}
                            categoryIcon3={iconGiniPigs_Required}
                            categorName3={requestdetails.requestdetailsdata.data.request.places_available === 1 ? 1 + " place left" : requestdetails.requestdetailsdata.data.request.places_available + " places left"}
                            buttonRequiestTime={set_date}
                            buttonReqAddress={requestdetails.requestdetailsdata.data.request.postcodes!==null && requestdetails.requestdetailsdata.data.request.postcodes!==undefined? formatPostcode(requestdetails.requestdetailsdata.data.request.postcodes.data.postcode):null}
                          />
                        </div>


                        {requestdetails.requestdetailsdata && requestdetails.requestdetailsdata !== null ?
                          requestdetails.requestdetailsdata.data.request.attributes_required === 1 ?
                            <div className="model_box_req  mt-24 ">
                              <div className="req_img_box inst_box">
                                <h3 className="title">GiniPig Requirements</h3>
                                <div className="inst_content mt-16">
                                  <p>{requestdetails.requestdetailsdata.data.request.model_requirements}</p>
                                </div>
                              </div>
                              <div className="check_box borderTop0">

                                <div className="checkbox-group mb0">
                                  <label htmlFor="check1">
                                    <div className="checkinput mr-8">
                                      <input
                                        className="tnc"
                                        type="checkbox"
                                        id="check1"
                                        checked={formData.modal_require === true ? true : false}
                                        onClick={toggleChangerequiremnetsModel}

                                      />
                                    </div>
                                    <span className="checkbox-titles">I have read and agree that I am suitable as per the Treatment/Service Providers requirements </span>
                                  </label>
                                </div>
                              </div>
                            </div> : null : null
                        }
                        <div className="photo_box_req  mt-24 mb-40">
                          {requestdetails.requestdetailsdata && requestdetails.requestdetailsdata !== null ?
                            requestdetails.requestdetailsdata.data.request.photograph_required === 1 ?
                              <div className="req_img_box inst_box">
                                <div className="inline_row_text flex_row">
                                  <img src={iconPhoto} className="icons_required mr-8" />
                                  <p> The Treatment/Service Provider requires you to add a photo to ensure perfect compatibility for this appointment </p>
                                  {/* <p>You are required to supply a photo of the Treatment/Service area in order to book this Treatment/Service.</p> */}
                                </div>
                                <div className="inst_content mt-16">
                                  <p>{requestdetails.requestdetailsdata.data.request.photograph_requirements}</p>
                                </div>
                              </div> : null : null
                          }


                          {requestdetails.requestdetailsdata && requestdetails.requestdetailsdata !== null ?
                            requestdetails.requestdetailsdata.data.request.photograph_required === 1 ?
                              <div className="check_box borderTop">
                                <div className="checkbox-group">
                                  <label htmlFor="check2">
                                    <div className="checkinput mr-8">
                                      <input
                                        className="tnc"
                                        type="checkbox"
                                        id="check2"
                                        checked={formData.photo_condition === true ? true : false}
                                        onClick={toggleChangerequiremnetsPhoto}
                                      />
                                    </div>
                                    <span className="checkbox-titles"> I have supplied the required photo(s) matching the image instructions   </span>
                                  </label>
                                </div>
                              </div>
                              : null : null}
                          {requestdetails.requestdetailsdata && requestdetails.requestdetailsdata !== null ?
                            requestdetails.requestdetailsdata.data.request.is_patchtest_required === 1 ?
                              <div className="check_box borderTop">
                                <div className="checkbox-group mb0">
                                  <label htmlFor="check3">
                                    <div className="checkinput mr-8">
                                      <input
                                        className="tnc"
                                        type="checkbox"
                                        id="check3"
                                        // checked={patch_required === true ? true : false}
                                        // onClick={() => {  
                                        //   setPatchRequired(!patch_required) 
                                        //   enableproceedToPayment()                                
                                        // }} 
                                        checked={formData.patch_required === true ? true : false}
                                        onClick={toggleChangerequiremnetsPatch}
                                      />
                                    </div>
                                    <span className="checkbox-titles"> I agree to complete a patch test prior to this treatment   </span>
                                  </label>
                                </div></div> : null : null
                          }
                        </div>

                        {requestdetails.requestdetailsdata && requestdetails.requestdetailsdata !== null ?
                          requestdetails.requestdetailsdata.data.request.is_consentform_required === 1 ?

                            <div>
                              <div className="open_form_box mt-40 mb-40">
                                <div className="inside_divi">
                                  <div className="open_btn">
                                    <div className="open_from_btn"><a style={{ color: 'black' }} href={requestdetails.requestdetailsdata.data.request.consent_form_link != '' ? requestdetails.requestdetailsdata.data.request.consent_form_link : ''} target="_blank">Open form</a></div>
                                  </div>
                                  <div className="open_from_content">
                                    <p>Please read the attached form, you may be expected to read & sign this at the appointment</p>
                                  </div>
                                </div>
                              </div>

                              <div className="req_img_box pt-40 mb-16">
                                <div className="checkbox-group mb0">
                                  <label htmlFor="check4">
                                    <div className="checkinput mr-8">
                                      <input
                                        className="tnc"
                                        type="checkbox"
                                        id="check4"
                                        // checked={consent_form === true ? true : false}
                                        // onClick={() => {  
                                        //   setConsentForm(!consent_form) 
                                        //   enableproceedToPayment()                                
                                        // }} 
                                        checked={formData.consent_form === true ? true : false}
                                        onClick={toggleChangerequiremnetsConsent}
                                      />
                                    </div>
                                    <span className="checkbox-titles">I have read and accept the terms of the  consent form   </span>
                                  </label>
                                </div>
                              </div>
                            </div> : null : null}

                        <div className="req_img_box mb-16">
                          <div className="checkbox-group mb0">
                            <label htmlFor="check5">
                              <div className="checkinput mr-8">
                                <input
                                  className="tnc"
                                  type="checkbox"
                                  id="check5"
                                  // checked={accept_booking === true ? true : false}
                                  // onClick={() => {  
                                  //   setAcceptBooking(!accept_booking) 
                                  //   //enableproceedToPayment()                                
                                  // }}
                                  checked={formData.accept_booking === true ? true : false}
                                  onClick={toggleChangerequiremnetsBooking}
                                />
                              </div>
                              <span className="checkbox-titles">I accept the  <Link to="/tc" className="plainbtn fontweight" onClick={__reDirectPage}> booking conditions </Link>.</span>
                            </label>
                          </div>
                        </div>

                        {pattextError &&
                          pattextError != "" ? (
                          <p className="error">
                            <p style={{ color: "red" }}>
                              {"It's too late to now book this treatment as there is insufficient time to allow for possible reactions from Patch Testing. Is there something else that takes your fancy?"}{" "}
                            </p>
                          </p>
                        ) : null}

                        <div className="btn-align mt-40 pd32">
                          <LargeButton
                            ButtonType="button"
                            ButtonText={requestdetails.requestdetailsdata.data.request.type !== 1 ? "Proceed to payment" : "Submit enquiry"}
                            nextButtonClick={proceedToPayment}
                            disabled={paymentDisable}
                          />
                        </div>


                      </form>

                    </div>

                  </Col> :
                  <div style={{ width: "100%", fontSize: 20, marginTop: 100, textAlign: 'center' }}>
                    <p>Loading ...</p>
                  </div>
            }
          </Row>
        </Container>
      </section>



    </>
  );
};

export default BookingDetails;