import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS,AUTH_HEADERS_NEW } from '../../config/ApiConstants';

export function GinipigUpdateData_Action(data) {
    return {
        type: types.GINIPIG_DATA,
        ginpigUpdateData: data
    }
}

export const resetGinipigUpdateAPI_Action = () => {
    return {
        type: types.RESET_GINIPIG_DATA,
        payload: null
    }
};