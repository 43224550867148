import React,{useEffect} from 'react';
import WhoAreGgp from './WhoAreGgp';
const WhoGgp = () => {
    const __reDirectPage = (e) =>{
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        __reDirectPage()
    }, [])
    return (
        <>
         <WhoAreGgp />   
        </>
    );
}

export default WhoGgp;