import * as types from "../types/index";
import { setInitialState } from "../InitialState";


const INITIAL_STATE = setInitialState({
    deleterequestdata: null,
});


export default function (state = INITIAL_STATE, action) {
   // console.log(action.payload)
    switch (action.type) {
        case types.DELETEREQUEST_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case types.DELETEREQUEST_RECEIVE:
            return {
                ...state,
                isFetching: false,
                isSuccess: true,
                deleterequestdata: action.payload
            };
        case types.DELETEREQUEST_FAILURE:
            return {
                ...state,
                isFetching: false,
                isError: true,
                
                deleterequestdata: action.payload,
            };
        case types.RESET_DELETEREQUEST:
            return {
                ...state,
                isFetching: false,
                isError: false,
                isSuccess: false,
                deleterequestdata: null,
            };
        default:
            return {
                ...state
            }
    }
}