import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS,AUTH_HEADERS_NEW } from '../../config/ApiConstants';

export function createAppointmentData_Action(data,selected_time_slots) {
    return {
        type: types.APPOINTMENT_SAVE_DATA,
        appointmentCreateData: data,
        selected_time_slots:selected_time_slots
    }
}

export const resetcreateAppointmentData_Action = () => {
    return {
        type: types.RESET_APPOINTMENT_SAVE_DATA,
        payload: null
    }
};