import React, { useCallback, useState, useEffect } from "react";
import { useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import { LeftArrow, reviewAvatar, iconFav, iconPhoto, star, icon_suitability, icon_reliability, icon_etiquette } from '../../config/images';
import BlogCard from '../../components/BlogCard';
import NavigationBreadcrumbs from '../../components/NavigationBreadcrumbs';
import ReactPaginate from 'react-paginate';
//import Pagination from '../../components/Pagination'
import moment from 'moment';
//redux 

import { bloglistAPI_Action, resetBlogListAPI_Action } from '../../stores/actions'
import Loader from "../../components/Loader";

const Blog = (props) => {
    let history = useHistory();
    let dispatch = useDispatch();

    const [offset, setOffset] = useState(0);
    const [perPage, setPerpage] = useState(3);
    const [perPageURL, setPerPageURL] = useState(1);
    const [currentPage, setCurrentpage] = useState(0);
    const [loader, setLoader] = useState(false);
    const [blogData, setBlogData] = useState([]);
    const [errorModel, setErrorModel] = useState(false);
    const [errorDataModel, setErrorDataModel] = useState(false);

    const bloglistInfo = useSelector(state => state.bloglist);

    // Use effects
    useEffect(() => {
        try{
            dispatch(bloglistAPI_Action(perPageURL));
        }catch(e){
            console.log('Error',e)
        }
    }, [])

    useEffect(() => {
        if (bloglistInfo.isFetching) {
          setLoader(true);
        } else if (bloglistInfo.isSuccess && bloglistInfo.data !== null) {
          setLoader(false);
          let dashboardData = bloglistInfo?.bloglistdata?.data?.blogs;
          setBlogData(dashboardData);
        } else if (bloglistInfo.isError) {
          setErrorModel(true);
          setErrorDataModel(bloglistInfo.data);
        }
      }, [bloglistInfo]);


    const __redirectToDetailsPg = (blogId) => {
        window.scrollTo(0, 0);
        history.push('/blog-detail/' + blogId);
    }

    const backBtnPressed=()=>{
        history.goBack();
    }

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * perPage;
       // console.log(e.selected)
        window.scrollTo(0, 0);
       dispatch(bloglistAPI_Action(selectedPage+1));
    }

    return (
        <>
            {/* {
                loader === true ? <Loader />  : */}

            <section className="bgcolor-gray common-section blog_pg pb80">
                <Container>
                    <Row>
                        <Col className="col-lg-11 m-auto">
                        <div className="navigationTop padright-0">
                <div className="heading-history">
                        <button type="button" onClick={()=>history.goBack()} className="back-btn" >
                            <img src={LeftArrow} alt="arrow" />
                        </button>
                        <div className="history-title"><h2> Blog  </h2></div>
                    
                </div>
            </div>

                            <div className="boxwrp requirement_wrp mt-24">

                                {
                                    // bloglistInfo.bloglistdata && bloglistInfo.bloglistdata.data && bloglistInfo.bloglistdata.data.blogs && bloglistInfo.bloglistdata.data.blogs.map((item, key) => {
                                        blogData.map((item, key) => {
                                        let dateFormat = moment(item.blogPublishDate).format("DD-MMMM-YYYY")
                                        return (
                                            <div className="card-itme mb-32" key={key}>
                                                <BlogCard
                                                    Img={item.blogImage}
                                                    Heading={item.blogTitle}
                                                    PublishDate={dateFormat}
                                                    Decs={item.blogSummary}
                                                    AuthorName={item.blogAuthor}
                                                    ButtonName="Read more"
                                                    ButtonLink={__redirectToDetailsPg}
                                                    blogId={item.blogId}
                                                />
                                            </div>
                                        )
                                    })
                                }

                                {
                                    // bloglistInfo.bloglistdata && bloglistInfo.bloglistdata.data && bloglistInfo.bloglistdata.data.blogs &&  bloglistInfo.bloglistdata.data.blogs.lenght > 0 ?
                                    blogData.lenght > 0 ?
                                    <div className="pagination-div common-pagination1">
                                    <ReactPaginate
                                        previousLabel={"prev"}
                                        nextLabel={"next"}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={Math.ceil(bloglistInfo.bloglistdata && bloglistInfo.bloglistdata && bloglistInfo.bloglistdata !== null && bloglistInfo.bloglistdata.data.blogs_pagination.total / perPage)}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"} />
                                    </div>

                                : null
                                }

                              

                                {/* {
                                            <div className="pagination"  >
                                                
                                                <li>{ bloglistInfo.bloglistdata !== null ? bloglistInfo.bloglistdata.data.blogs_pagination.prev_page_url : '' }</li>
                                                <li>{bloglistInfo.bloglistdata !== null ? bloglistInfo.bloglistdata.data.blogs_pagination.current_page : ''}</li>
                                                <li>{bloglistInfo.bloglistdata !== null ? bloglistInfo.bloglistdata.data.blogs_pagination.per_page : ''}</li> 
                                                <li>{bloglistInfo.bloglistdata !== null ? bloglistInfo.bloglistdata.data.blogs_pagination.total : ''}</li> 
                                                {/* <li> <Link to={bloglistInfo.bloglistdata !== null ? bloglistInfo.bloglistdata.data.blogs_pagination.next_page_url : ''}> > </Link> </li>  */}
                                {/* </div>
                                }  */}

                                {/* <Pagination data={bloglistInfo.bloglistdata.data.blogs_pagination} /> */}

                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
{/* } */}
        </>
    );
}

export default Blog;