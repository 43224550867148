import * as types from "../types/index";
import { setInitialState } from "../InitialState";


const INITIAL_STATE = setInitialState({
    favdetailsdata: null,
    favdetailsdatatreatment: null,
});
// postcode,lang,lat,address

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.FAVOURITE_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case types.FAVOURITE_RECEIVE:
            return {
                ...state,
                isFetching: false,
                isSuccess: true,
                favdetailsdata: action.payload.success
            };
        case types.FAVOURITE_FAILURE:
            return {
                ...state,
                isFetching: false,
                isError: true,
                favdetailsdata: action.payload.response.error,
            };
        case types.RESET_FAVOURITE:
            return {
                ...state,
                isFetching: false,
                isError: false,
                favdetailsdata: null,
            };

            case types.FAVOURITE_REQUEST_TREATMENT:
                return {
                    ...state,
                    isFetching: true,
                };
            case types.FAVOURITE_RECEIVE_TREATMENT:
                return {
                    ...state,
                    isFetching: false,
                    isSuccess: true,
                    favdetailsdatatreatment: action.payload.success
                };
            case types.FAVOURITE_FAILURE_TREATMENT:
                return {
                    ...state,
                    isFetching: false,
                    isError: true,
                    favdetailsdatatreatment: action.payload.response.error,
                };
            case types.RESET_FAVOURITE_TREATMENT:
                return {
                    ...state,
                    isFetching: false,
                    isError: false,
                    favdetailsdatatreatment: null,
                };

        default:
            return {
                ...state
            }
    }
}