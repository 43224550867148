import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS,AUTH_HEADERS_NEW } from '../../config/ApiConstants';
export function createbookingAPI_Action(data,token) {
    const formData = new FormData(); 

    formData.append('request_id', data.request_id);
    formData.append('date', data.date);
    formData.append('time', data.time);
    formData.append('is_suitable_to_treatment', data.is_suitable_to_treatment);
    formData.append('is_supplied_matching_photos', data.is_supplied_matching_photos);
    formData.append('is_accept_booking_condition', data.is_accept_booking_condition);
    formData.append('is_read_accept_consent_form', data.is_read_accept_consent_form);


    formData.append('is_allow_patch_test', data.is_allow_patch_test);
    formData.append('payment_type', data.intent_to_pay_status);

    formData.append('stripeToken', data.stripeToken);
    formData.append('stripeTokenType', data.stripeTokenType);
    formData.append('stripeEmail', data.stripeEmail);

     for (var i = 0; i < data.request_image.length; i++) {
       formData.append('request_image[]', data.request_image[i]);
   }

    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/booking/add`,
            method: 'POST',
            headers: {
                ...AUTH_HEADERS_NEW,
                'Authorization':'Bearer '+token
            },
            // body:JSON.stringify(data),
            body:formData,
            types: [
                types.CREATEBOOKING_REQUEST,
                types.CREATEBOOKING_RECEIVE, {
                    type: types.CREATEBOOKING_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}

export const createOpenbookingAPI_Action =(data,token)=> {
    const formData = new FormData(); 

    formData.append('request_id', data.request_id);
    formData.append('date', data.date);
    formData.append('timeslots', JSON.stringify(data.time));
    formData.append('is_suitable_to_treatment', data.is_suitable_to_treatment);
    formData.append('is_supplied_matching_photos', data.is_supplied_matching_photos);
    formData.append('is_accept_booking_condition', data.is_accept_booking_condition);
    formData.append('is_read_accept_consent_form', data.is_read_accept_consent_form);
    formData.append('use_ginipig_existing_card',data.use_ginipig_existing_card);

    formData.append('is_allow_patch_test', data.is_allow_patch_test);
    formData.append('payment_type', data.intent_to_pay_status);

    formData.append('stripeToken', data.stripeToken);
    formData.append('stripeTokenType', data.stripeTokenType);
    formData.append('stripeEmail', data.stripeEmail);

     for (var i = 0; i < data.request_image.length; i++) {
       formData.append('request_image[]', data.request_image[i]);
   }

    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/ginipig/request-enquiry/add`,
            method: 'POST',
            headers: {
                ...AUTH_HEADERS_NEW,
                'Authorization':'Bearer '+token
            },
            // body:JSON.stringify(data),
            body:formData,
            types: [
                types.CREATEBOOKING_REQUEST,
                types.CREATEBOOKING_RECEIVE, {
                    type: types.CREATEBOOKING_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}


export const resetcreatebookingAPI_Action = () => {
    return {
        type: types.RESET_CREATEBOOKING,
        payload: null
    }
};
