
import React, { useCallback, useState, useEffect } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from 'react-bootstrap';
import { LeftArrow, star, iconCase_Study, iconNearly_there, iconPhoto, reviewAvatar } from '../../config/images';
import RequestBookingCard from "../../components/UIComponent/RequestBookingCard";
import CloseRequestAcceptCancelCard from "../../components/UIComponent/CloseRequestAcceptCancelCard";
import DeclineBookingCard from "../../components/UIComponent/DeclineBookingCard";
import { getLocalStorageData, setLocalStorageData } from '../../utils/storage'
import { practionerdeclinebookingAPI_Action, practitionerrequestDetailsAPI_Action, openrequestenquiryDetailsAPI_Action } from '../../stores/actions'
import LoadingSpinner from "../../components/UIComponent/LoadingSpinner"

const CancelopenRequest = (props) => {

    let history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const [requestArray, setrequestArray] = useState([]);
    const [subcategory, setsubcategory] = useState("");
    const [bookingDate, setbookingDate] = useState();
    const [bookingId, setBookingId] = useState();
    const [bookingData, setBookingData] = useState();
    const [bookingCount, setBookingCount] = useState();

    const practitionerrequestDetailsdata = useSelector(state => state.practitionerrequestdetailsdata);
    const declinereasonslistData = useSelector(state => state.declinereasonslistdata);

    const openrequestenquirydetailsdata = useSelector(state => state.openrequestenquirydetailsdata);

    const [formData, updateFormData] = useState(Object.freeze({
        decline_reason_id: [],
        is_togglechangereason1: false,
        is_togglechangereason2: false,
        is_togglechangereason3: false,
        clickCancel: false,
        selectReasonId : ' '
    }));


    // Use effects
    useEffect(() => {

        try {
            let checkToken = "";
            let LocalDatatoken = getLocalStorageData("loginData")
            let registerData = getLocalStorageData("practitionersignupData")

            if (registerData !== "" && registerData !== undefined && registerData !== null) {
                checkToken = registerData.token;
            }
            else if (LocalDatatoken !== "" && LocalDatatoken !== undefined && LocalDatatoken !== null) {
                checkToken = LocalDatatoken.token;
            }
            if (checkToken === '') {
                window.scrollTo(0, 0);
                history.push('/login');
            }

            api_callNew( checkToken);

            let bookingDetailsopen = getLocalStorageData("bookingDetailsopen")
            setBookingData(bookingDetailsopen)

            let bookingCount = getLocalStorageData("BookigCount")
            setBookingCount(bookingCount)


        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    }, [])

    const api_callNew = async (checkToken) => {
        let bookingDetailsopendata = getLocalStorageData("bookingDetailsopendata")

       await dispatch(practitionerrequestDetailsAPI_Action(bookingDetailsopendata.requestId, checkToken));
       await dispatch(openrequestenquiryDetailsAPI_Action(bookingDetailsopendata.uuid, checkToken));
    }

    useEffect(() => {

        try {
            let bookingDetailsopen = getLocalStorageData("bookingDetailsopen")
            setBookingData(bookingDetailsopen)

            let bookingCount = getLocalStorageData("BookigCount")
            setBookingCount(bookingCount)
           
            let requestArray = practitionerrequestDetailsdata.practitionerrequestdetailsdata && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.request
            if (requestArray !== null) {
                let subCatArray = []
                requestArray.treatment.treatment_sub_category_new.forEach(element => {
                    subCatArray.push(element.name)

                })

                var str;
                if (subCatArray.length === 1) {
                    str = subCatArray.join()
                } else if (subCatArray.length === 2) {

                    str = subCatArray.join(" & ")
                } else if (subCatArray.length === 3) {
                    str = subCatArray[0] + "," + subCatArray[1] + " & " + subCatArray[2]
                }
                let newDate = ""
                let displayDate = bookingDetailsopen.date


                if (displayDate !== null && displayDate !== undefined) {
                    let day = displayDate.substring(8, 10);
                    let displayDay = ""
                    if (day < 9) {
                        displayDay = day.substring(1, 2)
                    } else {
                        displayDay = day
                    }

                    let n = displayDate.substring(5, 7)
                    // console.log(n)
                    let newn = ""
                    if (n < 10) {
                        newn = n.substring(1, 2)
                    } else {
                        newn = n
                    }

                    var month = new Array();
                    month[1] = "January";
                    month[2] = "February";
                    month[3] = "March";
                    month[4] = "April";
                    month[5] = "May";
                    month[6] = "June";
                    month[7] = "July";
                    month[8] = "August";
                    month[9] = "September";
                    month[10] = "October";
                    month[11] = "November";
                    month[12] = "December";

                    var displaymonth = month[newn];

                    if (displayDay == 1) {
                        newDate = displayDay + "st" + " " + displaymonth + " "
                    } else if (displayDay == 2) {
                        newDate = displayDay + "nd" + " " + displaymonth + " "
                    } else if (displayDay == 3) {
                        newDate = displayDay + "rd" + " " + displaymonth + " "
                    } else {
                        newDate = displayDay + "th" + " " + displaymonth + " "
                    }
                }

                let period = ""
                let hours = ''
                let [sHours, minutes] = ""
                if (bookingDetailsopen.time !== null) {
                    [sHours, minutes] = bookingDetailsopen.time && bookingDetailsopen.time.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
                    period = +sHours < 12 ? 'AM' : 'PM';
                    hours = +sHours % 12 || 12;
                }
                let timein24hrs=""
                if (bookingDetailsopen.time !== null && bookingDetailsopen.time!==undefined) {
                     timein24hrs=bookingDetailsopen.time.slice(0,5)
                }
                let displaydate = newDate + new Date().getFullYear() + " @ " + timein24hrs

                setbookingDate(displaydate)
                setsubcategory(requestArray.treatment.title)
            }

            setrequestArray(requestArray)

        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    }, [practitionerrequestDetailsdata])


    const __apiCallDecline = useCallback(async (data) => {

        try {

            let checkToken = "";
            let LocalDatatoken = getLocalStorageData("loginData")
            let registerData = getLocalStorageData("practitionersignupData")

            if (registerData != "" && registerData !== undefined && registerData !== null) {
                checkToken = registerData.token;
            }
            else if (LocalDatatoken !== "" && LocalDatatoken !== undefined && LocalDatatoken !== null) {
                checkToken = LocalDatatoken.token;
            }


            await dispatch(practionerdeclinebookingAPI_Action(data, checkToken));
            window.scrollTo(0, 0);

        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    });


    const declineButtonClick = (item, status,id) => {
        //setBookingId(id)
        cancelButtonDirectClick(item)
    }

    const sendDeclineButtonClick = (item, status) => {
        cancelButtonClick(item);
    }

    const _onCancelClick = () => {

        updateFormData({
            ...formData,
            clickCancel: true,
        });
    }


    const cancelButtonDirectClick = (item) => {
        let formDatanew = {
            id: item.uuid,
            //booking_reason_id: formData.decline_reason_id,
        }
        window.scrollTo(0, 0);
        history.push({
            pathname: '/cancel-booking',
            formDatanew: formDatanew
        });
    }

    const cancelButtonClick = (item) => {
        // let bookingId = practitionerrequestDetailsdata.practitionerrequestdetailsdata && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.booking.id
        let formDatanew = {
            id: item.uuid,
            booking_reason_id: formData.decline_reason_id,
        }
        window.scrollTo(0, 0);
        history.push({
            pathname: '/confirm-cancel-booking',
            formDatanew: formDatanew
        });

    }

    const cancelAllButtonClick = () => {
        let requestId = requestArray && requestArray.uuid
        let formDatanew = {

            cancel_reason_id: formData.decline_reason_id,
            id: requestId
        }
        window.scrollTo(0, 0);
        history.push({
            pathname: '/confirm-cancel-booking',
            formDatanewall: formDatanew,
            cancel: "all"
        });

    }

    const toggleChangeReason = (id) => {
        let selectedBoxes = formData.decline_reason_id;
        let index = selectedBoxes.indexOf(parseInt(id)) // check to see if the name is already stored in the array
        if (index === -1) {
            selectedBoxes.push(parseInt(id))
        } else {
            selectedBoxes.splice(index, 1)
        }
        updateFormData({
            ...formData,
            decline_reason_id: [id],
            selectReasonId : id
        });

    }


    const editquatityButtonClick = (requestId) => {
        let ginipicArray = []
        let ginpiccount = practitionerrequestDetailsdata.practitionerrequestdetailsdata && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.request.ginipig_count
        for (let i = ginpiccount; i < 20; i++) {
            ginipicArray.push(i)
        }
        let editginiobj={
            requestId: practitionerrequestDetailsdata.practitionerrequestdetailsdata && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.request.uuid,
            ginipic_count: ginpiccount,
            bookings_count: location.bookings_count,
            ginipicArray: ginipicArray,
            requesttype:1
        }
        setLocalStorageData("editginiobj",editginiobj)
        //  let bookingId = practitionerrequestDetailsdata.practitionerrequestdetailsdata && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.booking.id
        window.scrollTo(0, 0);
        history.push({
            pathname: '/edit-ginipig-quantity',
            requestId: practitionerrequestDetailsdata.practitionerrequestdetailsdata && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.request.uuid,
            ginipic_count: ginpiccount,
            bookings_count: location.bookings_count,
            ginipicArray: ginipicArray

        })
    }




    const _redirecttoGinipicPhoto = (item) => {
        // console.log(item)
         let dataArray = []
 
         //var dateobj = JSON.parse(openrequestenquirydetailsdata.openrequestenquirydetailsdata && openrequestenquirydetailsdata.openrequestenquirydetailsdata.data.request_enquiry.timeslots);
         //let date = openrequestenquirydetailsdata.openrequestenquirydetailsdata && openrequestenquirydetailsdata.openrequestenquirydetailsdata.data.request_enquiry.date + " " + "00:00:00"
         // console.log(dateobj."morning")
 
         //let timeslots = JSON.parse(dateobj);
       //  console.log(timeslots.morning)
 
         // let allTimeSlots=[]
        //  let hrsArray = []
        //  let minArray = []
        //  let allTimeSlots = (timeslots.morning).concat(timeslots.afternoon).concat(timeslots.evening)
       //  console.log(allTimeSlots)
        //  allTimeSlots.map((slot, index) => {
             // console.log(slot.substring())
             //var hrs = slot.substr(0, slot.indexOf(':'));
             //var minute = slot.substr(slot.length - 2);
             // var lastThree = slot.substr(slot.length - 2);
             //hrsArray.push(hrs)
             //minArray.push(minute)
 
 
         //})
 
 
        //  let newhrsArray = hrsArray.sort(function (a, b) { return a - b });
        //  let newminArray = minArray.sort(function (a, b) { return a - b });
        //  let filterhrsArray = newhrsArray.filter((item, index) => newhrsArray.indexOf(item) === index)
        //  let filterminutesArray = newminArray.filter((item, index) => newminArray.indexOf(item) === index)
        //  let filetrDayArray = allTimeSlots.filter((item, index) => allTimeSlots.indexOf(item) === index)
        // console.log(filetrDayArray)
 
 
        //  let obj = {
        //      date_time: date,
        //      timeslots: dateobj
        //  }
        //  dataArray.push(obj)
        //  let priceoriginal = requestArray && requestArray.rrp_price
        //  let pricedis = requestArray && requestArray.your_price
 
        //  let calenderobj = {
        //      date: openrequestenquirydetailsdata.openrequestenquirydetailsdata && openrequestenquirydetailsdata.openrequestenquirydetailsdata.data.request_enquiry.date,
        //      requestId: practitionerrequestDetailsdata.practitionerrequestdetailsdata && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.request.id,
        //      enquiryData: dataArray,
        //      minuteArray: filterminutesArray,
        //      hrsArray: filterhrsArray,
        //      subCatArray: subcategory,
        //      originalPriceText: priceoriginal,
        //      discountPrice: pricedis,
        //      bookingDetails: item,
        //      allTimeSlots: filetrDayArray,
        //  }
 
         //setLocalStorageData("calenderobj", calenderobj)
         //setLocalStorageData("requestIdopen", item.uuid)
         // setLocalStorageData("bookingId", item.booking_id)
         setLocalStorageData("bookingId", item.uuid)
 
         if (item.ginipig_images.length !== 0) {
             let itemDetails = {
                 bookingId : item.uuid,
                 //bookingId:openrequestenquirydetailsdata.openrequestenquirydetailsdata && openrequestenquirydetailsdata.openrequestenquirydetailsdata.data.request_enquiry.uuid,
                 user: item.user,
                 ginipig_images: item.ginipig_images,
                 ginipigreview:false,
                 requestType: 2,
                 photoGinipigHideButton: true
             }

             setLocalStorageData("photocloseobj","")
             setLocalStorageData("photoObj", itemDetails)
 
             window.scrollTo(0, 0);
             history.push({
                 pathname: '/photo-ginipig',
                 details: itemDetails,
                 ginipigreview:false,
 
                // requestId: requestArray.uuid
             });
 
         }
 
 
     }



    return (
        <>
            <section className="bgcolor-gray common-section upcoming_past_booking_pg reject-rq pb80">
                {practitionerrequestDetailsdata.practitionerrequestdetailsdata === null? 
                     <div style={{ width: "100%", marginTop: 250, textAlign: 'center' }}>
                             <LoadingSpinner />
                             <p style={{ fontSize: 20, marginTop: 10 }}>Loading ...</p>
                     </div> 
                     : 
                    <Container>
                    <Row>
                        <Col className="col-lg-11 m-auto">
                            <div className="navigationTop padright-0">
                                <div className="heading-history">
                                    <div className="back-history">
                                        <button type="button" onClick={() => history.goBack()} className="back-btn" >
                                            {" "}
                                            <img src={LeftArrow} alt="arrow" />{" "}
                                        </button>
                                    </div>
                                </div>
                                <div className="spce_btns_box">
                                    <button className="spc_fill_btns">{bookingCount}/{requestArray && requestArray.ginipig_count} spaces filled</button>
                                </div>
                            </div>

                            <div className="requirement_wrp padright-0">
                                <div className="request_card mb-16">
                                    <RequestBookingCard
                                        treatmentTitle={subcategory}
                                        tagsName={requestArray && requestArray.treatment && requestArray.treatment.categories && requestArray.treatment.categories.name && requestArray.treatment.categories.name}
                                        hrs={requestArray && requestArray.hours}
                                        minutes={requestArray && requestArray.minutes}
                                    
                                        // duration={requestArray && requestArray.hours + "h" + " " + requestArray.minutes + "m"}
                                        originalPriceText={requestArray && requestArray.rrp_price && requestArray.rrp_price.toFixed(2)}
                                        discountPrice={requestArray && requestArray.your_price && requestArray.your_price.toFixed(2)}
                                        addressText={requestArray && requestArray.postcodes && requestArray.postcodes.city && requestArray.postcodes.city}
                                        slotTime={bookingDate}
                                        categoryIcon1={iconNearly_there}
                                        categorName1={requestArray && requestArray.expertise_level}
                                        categoryIcon2={iconCase_Study}
                                        categorName2={requestArray && requestArray.reason_require_ginipig}
                                    />
                                </div>

                                <div className="req_model">
                                    <div className="req_img_box inst_box">
                                        <h3 className="title">GiniPig Requirements</h3>
                                        <div className="inst_content mt-16 mb-8">
                                            <p>{requestArray && requestArray.model_requirements}</p>
                                        </div>

                                        <div className="inline_row_text flex_row mt-8">
                                            <img src={iconPhoto} className="icons_required mr-8" />
                                            <p>The Treatment/Service Provider requires you to add a photo to ensure perfect compatibility for this appointment</p>
                                        </div>
                                    </div>
                                </div>


                                <div className="all_request_box mt-40">

                                    <div className="request_boxs pending mb-40">


                                        <div className="mt-16">

                                            <CloseRequestAcceptCancelCard
                                                userImg={bookingData && bookingData.user.profile_pic}
                                                userName={bookingData && bookingData.user.name}
                                                imgArrow={LeftArrow}
                                                //showImagearrow={true}
                                                arrowButtonClick={() => _redirecttoGinipicPhoto(bookingData && bookingData)}
                                                showImagearrow={bookingData && bookingData.ginipig_images.length !== 0 ? true :false}
                                                //declineButtonClick={() => declineButtonClick(bookingData)}
                                                declineButtonClick={() => declineButtonClick(bookingData)}
                                                ratingImg={star}
                                                reliabilityCount={bookingData && bookingData.user && bookingData.user.reliabilityAverage === 0 ? 0 : Math.ceil(bookingData && bookingData.user.reliabilityAverage)}
                                                etiquetteCount={bookingData && bookingData.user && bookingData.user.etiquetteAverage === 0 ? 0 : Math.ceil(bookingData && bookingData.user.etiquetteAverage)}
                                               // suitabilityCount={bookingData && bookingData.user && bookingData.user.suitabilityAverage === 0 ? 0 : Math.ceil(bookingData && bookingData.user.suitabilityAverage)}

                                                ratingPoint={bookingData && bookingData.user.review_rating_ginipig}
                                                DeclineButtonText="Cancel"
                                            />

                                            {
                                                bookingData && bookingData.uuid === bookingId &&
                                                <div className="decline_boxs">

                                                    <DeclineBookingCard
                                                        toggleChangeReason={toggleChangeReason}
                                                        flag="cancelRq"
                                                        reasonArrayId={formData.selectReasonId}
                                                        sendDeclineButtonClick={() => sendDeclineButtonClick(bookingData, "decline")}
                                                        description={declinereasonslistData.declinereasonslistdata && declinereasonslistData.declinereasonslistdata.data.reasons}
                                                        headingtitle="Select a reason to explain why you are declining"

                                                    />


                                                </div>
                                            }
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </Col>
                    </Row>
                </Container>
          
                }
                  </section>
        </>
    );
}

export default CancelopenRequest;