import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation,Link } from 'react-router-dom';
import { Container, Row, Col, Tab, Nav, Accordion } from 'react-bootstrap';
import { LeftArrow, iconPatch_test, star, iconPhoto, iconCase_Study, iconNearly_there, iconGiniPigs_Required, Aesthetic, Hair, Beauty, wellness, AestheticTwo, BeautyTwo, HairTwo, wellnessTwo } from '../../config/images';

import TransparentButton from '../../components/UIComponent/TransparentButton';
import CloseRequestDetail from '../../components/CloseRequestDetail'
import ModelRequirementCloseRequest from '../../components/ModelRequirementCloseRequest'
import SlickSlider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import UserProfile from '../../components/UserProfile';
import { getLocalStorageData, setLocalStorageData, clearLocalStorage } from '../../utils/storage'
import { resetdeleterequestAPI_Action, upcomingbookingAPI_Action, createAppointmentData_Action,resetcreatetreatmentAPI_Action,practitionerrequestDetailsAPI_Action, resetpractitionerrequestdetailsAPI_Action, resetLoginAPI_Action} from '../../stores/actions'
import moment from 'moment'
import LoadingSpinner from "../../components/UIComponent/LoadingSpinner"


const RequestWithNoResponse = () => {

    let history = useHistory();

    const dispatch = useDispatch();
    const location = useLocation();
    const [requestArray, setrequestArray] = useState([]);
    const [subcategory, setsubcategory] = useState("");
    const [bookingDate, setbookingDate] = useState();
    const [bookingId, setBookingId] = useState();

    const pendingbookingDetails = useSelector(state => state.pendingbookingdetails);
    const loginUserData = useSelector(state => state.login);
    const practitionersignupaddinfoData = useSelector(state => state.practitionersignupaddinfo);
    const cancelreasonslistData = useSelector(state => state.cancelreasonslistdata);
    const declinereasonslistData = useSelector(state => state.declinereasonslistdata);
    const practitionerrequestDetailsdata = useSelector(state => state.practitionerrequestdetailsdata);
    const [profileImages, setProfileImages] = useState([]);
    const [timeSlotsArray, setTimeSlotsArray] = useState([]);

    const [formData, updateFormData] = useState(Object.freeze({
        decline_reason_id: [],
        is_togglechangereason1: false,
        is_togglechangereason2: false,
        is_togglechangereason3: false,
        clickCancel: false
    }));

    // Use effects
    useEffect(() => {
        try {
            let checkToken = "";
            const loginToken = loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token : "" : "";
            const RegisterToken = practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.token : "" : "";
            let LocalDatatoken = getLocalStorageData("loginData")

            if (loginToken != "") {
                checkToken = loginToken;
            } else if (RegisterToken != "") {
                checkToken = RegisterToken;
            }
            else if (LocalDatatoken !== "") {
                checkToken = LocalDatatoken.token;
            }

            if (checkToken !== '') {
                dispatch(upcomingbookingAPI_Action(checkToken));


            } else {
                history.push('/login');
            }

            let requestData = getLocalStorageData("noresponseData")

            api_callNew(requestData.requestId,checkToken);

          //  console.log(practitionerrequestDetailsdata.practitionerrequestdetailsdata)
            // let requestArray = practitionerrequestDetailsdata.practitionerrequestdetailsdata && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.request
            // if (requestArray !== null) {
            //     let subCatArray = []
            //     requestArray.treatment.treatment_sub_category_new.forEach(element => {
            //         subCatArray.push(element.name)

            //     })

            //     var str;
            //     if (subCatArray.length === 1) {
            //         str = subCatArray.join()
            //     } else if (subCatArray.length === 2) {

            //         str = subCatArray.join(" & ")
            //     } else if (subCatArray.length === 3) {
            //         str = subCatArray[0] + "," + subCatArray[1] + " & " + subCatArray[2]
            //     }
            //     let newDate = ""
            //     let displayDate = requestArray.request_timeslots[0].date_time


            //     if (displayDate !== null && displayDate !== undefined) {
            //         let day = displayDate.substring(8, 10);
            //         let displayDay = ""
            //         if (day < 9) {
            //             displayDay = day.substring(1, 2)
            //         } else {
            //             displayDay = day
            //         }

            //         let n = displayDate.substring(5, 7)
            //         // console.log(n)
            //         let newn = ""
            //         if (n < 10) {
            //             newn = n.substring(1, 2)
            //         } else {
            //             newn = n
            //         }

            //         var month = new Array();
            //         month[1] = "January";
            //         month[2] = "February";
            //         month[3] = "March";
            //         month[4] = "April";
            //         month[5] = "May";
            //         month[6] = "June";
            //         month[7] = "July";
            //         month[8] = "August";
            //         month[9] = "September";
            //         month[10] = "October";
            //         month[11] = "November";
            //         month[12] = "December";

            //         var displaymonth = month[newn];

            //         if (displayDay == 1) {
            //             newDate = displayDay + "st" + " " + displaymonth + " "
            //         } else if (displayDay == 2) {
            //             newDate = displayDay + "nd" + " " + displaymonth + " "
            //         } else if (displayDay == 3) {
            //             newDate = displayDay + "rd" + " " + displaymonth + " "
            //         } else {
            //             newDate = displayDay + "th" + " " + displaymonth + " "
            //         }
            //     }
            //     let period = ""
            //     let hours = ''
            //     let [sHours, minutes] = ""

            //     if (requestArray.request_timeslots[0].start_time !== null) {
            //         [sHours, minutes] = requestArray.request_timeslots[0].start_time && requestArray.request_timeslots[0].start_time.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
            //         period = +sHours < 12 ? 'AM' : 'PM';
            //         hours = +sHours % 12 || 12;
            //     }


            //     let displaydate = newDate + new Date(displayDate).getFullYear() + " @ " + hours + ":" + minutes + period

            //     setbookingDate(displaydate)
            //     setsubcategory(requestArray.treatment.title)

            //     if (requestArray !== null) {

            //      //   console.log(requestArray);
            //         if (profileImages.length == 0) {
            //             var tempImg = [];
            //             // console.log("requestdetails.requestdetailsdata.data.request.request_images.data.length",requestdetails.requestdetailsdata.data.request.request_images.data.length);
            //             if (requestArray && requestArray.request_images && requestArray.request_images.length < 2) {
            //                 var islength = true;
            //                 if (requestArray && requestArray.request_images && requestArray.request_images.length == 1) {
            //                     islength = false;
            //                     tempImg.push(requestArray.request_images[0].image_link)
            //                 }
            //                 if (requestArray && requestArray.treatment && requestArray.treatment && requestArray.treatment.categories && requestArray.treatment.categories.name == "Aesthetics") {
            //                     tempImg.push(Aesthetic);
            //                     if (islength) {
            //                         tempImg.push(AestheticTwo);
            //                     }
            //                 } else if (requestArray && requestArray.treatment && requestArray.treatment && requestArray.treatment.categories && requestArray.treatment.categories.name == "Hair") {
            //                     tempImg.push(Hair);
            //                     if (islength) {
            //                         tempImg.push(HairTwo);
            //                     }
            //                 } else if (requestArray && requestArray.treatment && requestArray.treatment && requestArray.treatment.categories && requestArray.treatment.categories.name == "Beauty") {
            //                     tempImg.push(Beauty);
            //                     if (islength) {
            //                         tempImg.push(BeautyTwo);
            //                     }
            //                 } else {
            //                     tempImg.push(wellness);
            //                     if (islength) {
            //                         tempImg.push(wellnessTwo);
            //                     }
            //                 }
            //             }
            //             else {
            //                 requestArray && requestArray.request_images && requestArray.request_images.map(i => {
            //                     tempImg.push(i.image_link);
            //                 });
            //             }
            //             setProfileImages(tempImg);
            //           //  console.log("profile images:-", tempImg);
            //         }
            //     }

            // }

            // setrequestArray(requestArray)

        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    }, [])

    const api_callNew = async(requestId,checkToken) =>{
        await dispatch(practitionerrequestDetailsAPI_Action(requestId, checkToken));
    }

    useEffect(() => {

        let requestArray = practitionerrequestDetailsdata.practitionerrequestdetailsdata && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.request
            if (requestArray !== null) {
                let subCatArray = []
                requestArray.treatment.treatment_sub_category_new.forEach(element => {
                    subCatArray.push(element.name)

                })

                var str;
                if (subCatArray.length === 1) {
                    str = subCatArray.join()
                } else if (subCatArray.length === 2) {

                    str = subCatArray.join(" & ")
                } else if (subCatArray.length === 3) {
                    str = subCatArray[0] + "," + subCatArray[1] + " & " + subCatArray[2]
                }
                let newDate = ""
                let displayDate = requestArray.request_timeslots[0].date_time


                if (displayDate !== null && displayDate !== undefined) {
                    let day = displayDate.substring(8, 10);
                    let displayDay = ""
                    if (day < 9) {
                        displayDay = day.substring(1, 2)
                    } else {
                        displayDay = day
                    }

                    let n = displayDate.substring(5, 7)
                    // console.log(n)
                    let newn = ""
                    if (n < 10) {
                        newn = n.substring(1, 2)
                    } else {
                        newn = n
                    }

                    var month = new Array();
                    month[1] = "January";
                    month[2] = "February";
                    month[3] = "March";
                    month[4] = "April";
                    month[5] = "May";
                    month[6] = "June";
                    month[7] = "July";
                    month[8] = "August";
                    month[9] = "September";
                    month[10] = "October";
                    month[11] = "November";
                    month[12] = "December";

                    var displaymonth = month[newn];

                    if (displayDay == 1) {
                        newDate = displayDay + "st" + " " + displaymonth + " "
                    } else if (displayDay == 2) {
                        newDate = displayDay + "nd" + " " + displaymonth + " "
                    } else if (displayDay == 3) {
                        newDate = displayDay + "rd" + " " + displaymonth + " "
                    } else {
                        newDate = displayDay + "th" + " " + displaymonth + " "
                    }
                }
                let period = ""
                let hours = ''
                let [sHours, minutes] = ""

                if (requestArray.request_timeslots[0].start_time !== null) {
                    [sHours, minutes] = requestArray.request_timeslots[0].start_time && requestArray.request_timeslots[0].start_time.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
                    period = +sHours < 12 ? 'AM' : 'PM';
                    hours = +sHours % 12 || 12;
                }

                let timein24hrs=""
                if (requestArray.request_timeslots[0].start_time !== null && requestArray.request_timeslots[0].start_time!==undefined) {
                     timein24hrs=requestArray.request_timeslots[0].start_time.slice(0,5)
                }
                let displaydate = newDate + new Date().getFullYear() + " @ " + timein24hrs

                setbookingDate(displaydate)
                setsubcategory(requestArray.treatment.title)

                let timeWithDateArray = [];
                let time = {};
    
                if (requestArray !== undefined) {
                    if(requestArray.type === 1){
                        requestArray.request_timeslots && requestArray.request_timeslots.map((sub_item, index) => {
                            console.log("sub_item--", sub_item);
                            var dateobj = JSON.parse(sub_item.timeslots);
                            let date = sub_item.date_time;
                            let date1 = moment(date).format("DD-MM-yy")
                            time = dateobj[Object.keys(dateobj)[0]];
        
        
                            let combinedobj = {
                                date: date1,
                                formmatedDate: new Date(date),
                                request_tyep: 1,
                                selected_slots_morning: dateobj.morning,
                                selected_slots_afternoon: dateobj.afternoon,
                                selected_slots_evening: dateobj.evening
                            }
        
                            timeWithDateArray.push(combinedobj)
        
                        })
                    }
                }
                setTimeSlotsArray(timeWithDateArray)



                if (requestArray !== null) {

                 //   console.log(requestArray);
                    if (profileImages.length == 0) {
                        var tempImg = [];
                        // console.log("requestdetails.requestdetailsdata.data.request.request_images.data.length",requestdetails.requestdetailsdata.data.request.request_images.data.length);
                        if (requestArray && requestArray.request_images && requestArray.request_images.length < 2) {
                            var islength = true;
                            if (requestArray && requestArray.request_images && requestArray.request_images.length == 1) {
                                islength = false;
                                tempImg.push(requestArray.request_images[0].image_link)
                            }
                            if (requestArray && requestArray.treatment && requestArray.treatment && requestArray.treatment.categories && requestArray.treatment.categories.name == "Aesthetics") {
                                tempImg.push(Aesthetic);
                                if (islength) {
                                    tempImg.push(AestheticTwo);
                                }
                            } else if (requestArray && requestArray.treatment && requestArray.treatment && requestArray.treatment.categories && requestArray.treatment.categories.name == "Hair") {
                                tempImg.push(Hair);
                                if (islength) {
                                    tempImg.push(HairTwo);
                                }
                            } else if (requestArray && requestArray.treatment && requestArray.treatment && requestArray.treatment.categories && requestArray.treatment.categories.name == "Beauty") {
                                tempImg.push(Beauty);
                                if (islength) {
                                    tempImg.push(BeautyTwo);
                                }
                            } else {
                                tempImg.push(wellness);
                                if (islength) {
                                    tempImg.push(wellnessTwo);
                                }
                            }
                        }
                        else {
                            requestArray && requestArray.request_images && requestArray.request_images.map(i => {
                                tempImg.push(i.image_link);
                            });
                        }
                        setProfileImages(tempImg);
                      //  console.log("profile images:-", tempImg);
                    }
                }

            }

            setrequestArray(requestArray)

    }, [practitionerrequestDetailsdata])

    if(practitionerrequestDetailsdata && practitionerrequestDetailsdata.practitionerrequestdetailsdata !== null && practitionerrequestDetailsdata.practitionerrequestdetailsdata.status_code === 401){
        clearLocalStorage("loginData")
        clearLocalStorage("practitionersignupData")
        dispatch(resetLoginAPI_Action());
        dispatch(resetpractitionerrequestdetailsAPI_Action());
        window.scrollTo(0, 0);
        history.push('/login');
}
    const SampleNextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div className={className} style={{ ...style }} onClick={onClick}>
                {" "}
                <img src={LeftArrow} className="arrow_icons" alt="icon" />{" "}
            </div>
        );
    };

    const SamplePrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div className={className} style={{ ...style }} onClick={onClick}>
                {" "}
                <img src={LeftArrow} className="arrow_icons" alt="icon" />{" "}
            </div>
        );
    };
    
    const settings = {
        dots: true,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const __apiCall = useCallback(async (requestid) => {
        try {

            window.scrollTo(0, 0);
            history.push({
                pathname: '/confirm-delete-request',
                RedirectPathName: "editRequest",
                requestid: requestid,


            });
            // await dispatch(deleterequestAPI_Action(requestid,checkToken));


        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    });

    const calldeleteListingApi = (id) => {
        dispatch(resetdeleterequestAPI_Action());
         __apiCall(id)

    }

    const calleditRequestApi = (requestArray) => {
        dispatch(resetcreatetreatmentAPI_Action());
        let subArray = [];
        let imageArray = [];
        let timeWithDateArray = [];
        let time = {};

        requestArray.treatment.treatment_sub_category_new.map((sub_cat, index) => {
            subArray.push(sub_cat.id);
        })

        requestArray.request_images.map((image, index) => {
            imageArray.push(image.image_link)
        })

        let date = requestArray.request_timeslots[0].date_time
        let date1 = moment(date).format("DD-MM-yy")
        let timeminutes = ""
        let timehrs = ""
        if (requestArray.type === 2) {
            timeminutes = parseInt(requestArray.request_timeslots[0].start_time.substring(3, 5))
            timehrs = parseInt(requestArray.request_timeslots[0].start_time.substring(0, 2))
        }
        let filetype = requestArray.consent_form_link.substring(requestArray.consent_form_link.lastIndexOf('/'))
       //filetype)
        let newfiletype = filetype.replace("/", "")
        let fileName = newfiletype.substring(0, 5) + newfiletype.substring(newfiletype.lastIndexOf('.'))
       // console.log(fileName)
        if (requestArray.type === 1) {
            requestArray.request_timeslots && requestArray.request_timeslots.map((sub_item, index) => {
                var dateobj = JSON.parse(sub_item.timeslots);
                // let date = Object.keys(dateobj)[0];
                let date = sub_item.date_time;
                let date1 = moment(date).format("DD-MM-yy")
                //dateArray.push(date1)
                time = dateobj[Object.keys(dateobj)[0]];

                // console.log(time)

                let combinedobj = {
                    date: date1,
                    formmatedDate: new Date(date),
                    request_tyep: 1,
                    selected_slots_morning: dateobj.morning,
                    selected_slots_afternoon: dateobj.afternoon,
                    selected_slots_evening: dateobj.evening
                }

                timeWithDateArray.push(combinedobj)
              //  console.log(timeWithDateArray)


            })
        } else {


            let combinedobj = {
                date: date1,
                request_tyep: 1,
                selected_slots_morning: [],
                selected_slots_afternoon: [],
                selected_slots_evening: []

            }
            timeWithDateArray.push(combinedobj)
        }

        let postcode= requestArray.postcodes && requestArray.postcodes.postcode
        var postcodenew=""
        if(postcode!==null){
            var lastThree = postcode.substr(postcode.length - 3);
            var first = postcode.substr(0,postcode.length - 3);
            postcodenew=first+" "+lastThree
        }
        let formData = {
            requestId: requestArray.id,
            id: requestArray.treatment.id,
            category_id: requestArray.treatment.category_id,
            sub_category_id: subArray,
            title: requestArray.treatment.title,
            description: requestArray.treatment.description,
            categories: requestArray.treatment.categories,
            is_keep_treatment_on_profile: requestArray.treatment.is_keep_on_profile,
            rrpprice: requestArray.rrp_price,
            yourprice: requestArray.your_price,
            file1: imageArray[0],
            file2: imageArray[1],
            file3: imageArray[2],
            file4: imageArray[3],
            hrs: requestArray.hours,
            minutes: requestArray.minutes,
            selectedTreatment: [],
            reason_require_ginipig: requestArray.reason_require_ginipig,
            expertise_level: requestArray.expertise_level,
            ischecked_online: requestArray.is_online==1?true : false,
            ischecked_inperson: requestArray.is_online==0?true : false,
            is_online:requestArray.is_online,
            
            // imagearray1: [imageArray[0]],
            // imagearray2: [imageArray[1]],
            // imagearray3: [imageArray[2]],
            // imagearray4: [imageArray[3]],
            request_image_old: imageArray,
            is_keep_treatment_on_profile: 0,
            // request_image: requestArray.request_images,
            attributes_required: requestArray.attributes_required === 1 ? true : false,
            photograph_required: requestArray.photograph_required === 1 ? true : false,
            is_consentform_required: requestArray.is_consentform_required === 1 && requestArray.consent_form_link !== "" ? true : false,
            patchtest_required: requestArray.is_patchtest_required === 1 ? true : false,

            model_requirements: requestArray.model_requirements,
            photograph_requirements: requestArray.photograph_requirements,
            consent_form_link: requestArray.consent_form_link,
            consentformName: fileName,
            consent_form: requestArray.consent_form_link,
            selected_time_slots: timeWithDateArray,
            request_type: requestArray.type,
            date: date1,

            start_time_minutes: timeminutes,
            start_time_hrs: timehrs,
            postcode: postcodenew,
            street_name:requestArray.postcodes && requestArray.postcodes.location &&  requestArray.postcodes.location,
            postcodeValue: requestArray.postcodes && requestArray.postcodes.postcode_value &&  requestArray.postcodes.postcode_value,
            country:requestArray.postcodes && requestArray.postcodes.country &&  requestArray.postcodes.country,
            city:requestArray.postcodes &&  requestArray.postcodes.city &&  requestArray.postcodes.city,
            town_city:requestArray.postcodes &&requestArray.postcodes.city && requestArray.postcodes.city,
            latitude: requestArray.postcodes &&requestArray.postcodes.latitude &&requestArray.postcodes.latitude,
            longitude:requestArray.postcodes &&requestArray.postcodes.longitude && requestArray.postcodes.longitude,
            locationprofile: requestArray.postcodes && requestArray.postcodes.location &&  requestArray.postcodes.location,
            //use_profile_location: 0,
            use_profile_location: requestArray.use_profile_location,
            ginipig_count: requestArray.ginipig_count,

        }

        __apiCallEdit(formData)


    }

    const __apiCallEdit = useCallback(async (formData) => {
        try {
            // const loginToken = loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token : "" : "";
          //  console.log(formData)

            var save_data = {
                file1: formData.file1,
                file2: formData.file2,
                file3: formData.file3,
                file4: formData.file4,
                consent_form: formData.consent_form


            }

            dispatch(createAppointmentData_Action(save_data));

            setLocalStorageData("RedirectPathName", "editRequest")
            let formDatarequest = {
                RequestData: formData,
                selected_time_slots: formData.selected_time_slots

            }
            setLocalStorageData("requestDataLocal", formDatarequest)
            window.scrollTo(0, 0);
            history.push({
                pathname: '/treatment-ginipig-request',
                RedirectPathName: "editRequest"

            });


        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    });
   // console.log(requestArray)
    
    const __redirectportfolio = (id) => {
        window.scrollTo(0, 0);
        history.push('/ginipig-portfolio/' + id)
    }

    return (
        <>
            <section className="bgcolor-gray common-section practitioner_profile_forginipig request_openpg pb80">
        
            {
                requestArray === null? 
                <div style={{ width: "100%", marginTop: 150, textAlign: 'center' }}>
                        <LoadingSpinner />
                        <p style={{ fontSize: 20, marginTop: 10 }}>Loading ...</p>
                </div> 
                : 
            
                <Container>
                    <Row>
                        <Col className="col-lg-11 m-auto">
                            <div className="navigationTop">
                                <div className="heading-history">
                                    <div className="back-history">
                                        <button type="button" onClick={() => history.goBack()} className="back-btn" >
                                            {" "}
                                            <img src={LeftArrow} alt="arrow" />{" "}
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="practitioner_profile-wrp from-wrp profile_border">
                                <div className="practitioner-profile-img ">
                                    <div className="img_wrp_slider">
                                        <SlickSlider {...settings} >

                                            {
                                                profileImages.map((i) => {
                                                    return (
                                                        <div className="img_size profile_img1 ">
                                                             
                                                             <Link onClick={__redirectportfolio}>   
                                                            <img src={i} />
                                                            </Link>
                                                        </div>
                                                    )
                                                })
                                            }
                                            {/* <div className="img_size profile_img1 ">
                                                <img src={reviewAvatar} />
                                            </div>
                                            <div className="img_size profile_img1 ">
                                                <img src={reviewAvatar} />
                                            </div>
                                            <div className="img_size profile_img1 ">
                                                <img src={reviewAvatar} />
                                            </div> */}
                                        </SlickSlider>
                                    </div>
                                </div>
                            </div>

                            <div className="details-wrps">
                                <div className="close_request_box mt-40 mb-70">
                                    <CloseRequestDetail
                                        treatmentTitle={subcategory}
                                        currencySign="£"
                                        originalPriceText={requestArray && requestArray.rrp_price && requestArray.rrp_price.toFixed(2)}
                                        request_type={requestArray && requestArray.type}
                                        discountPriceText={requestArray && requestArray.your_price && requestArray.your_price.toFixed(2)}
                                        tagsName={requestArray && requestArray.treatment && requestArray.treatment.categories && requestArray.treatment.categories.name && requestArray.treatment.categories.name}
                                        hrs={requestArray && requestArray.hours}
                                        minutes={requestArray && requestArray.minutes}
                                    // duration={requestArray && requestArray.hours + "h" + " "+ requestArray.minutes + "m"}
                                        description={requestArray && requestArray.treatment && requestArray.treatment.description}
                                        categoryIcon1={iconCase_Study}
                                        categorName1={requestArray && requestArray.expertise_level}
                                        categoryIcon2={iconNearly_there}
                                        categorName2={requestArray && requestArray.reason_require_ginipig}
                                        categoryIcon3={iconGiniPigs_Required}
                                        categorName3={requestArray && requestArray.ginipig_count===1 ?  "1 place left " : requestArray.ginipig_count  + " places left"}
                                        buttonRequiestTime={bookingDate}
                                        buttonReqAddress={requestArray && requestArray.postcodes && requestArray.postcodes.postcode && requestArray.postcodes.postcode}

                                    />
                                </div>
                            </div>

                            <div className="details-wrps">
                                        {requestArray && requestArray.photograph_required === 1 || requestArray && requestArray.attributes_required === 1 || requestArray && requestArray.is_patchtest_required === 1 ?

                                            <div className="model_req_box mb-8">
                                                <ModelRequirementCloseRequest
                                                    content={requestArray}
                                                    modelTitle="GiniPig Requirements"
                                                    disciption={requestArray.model_requirements && requestArray.model_requirements}
                                                    requiredImg={iconPhoto}
                                                    requiredPatchImg={iconPatch_test}
                                                    patchText="A patch test is required for this treatment "
                                                    requiredText="The Treatment/Service Provider requires you to add a photo to ensure perfect compatibility for this appointment"
                                                />
                                            </div>
                                            : null}

                                        <div>
                                            
                                            {
                                                timeSlotsArray.length !== 0 && timeSlotsArray.map((item, index) => {
                                                    return (
                                                        <>
                                                        <div className="title_set pb24 titile_box mt-32 border_bottom_1">
                                                                <h3 className="heading">Listed Availability </h3>
                                                            </div>
                                                        <div className="form-wrp mt-32">
                                                            <div className="inside_box_1">
                                                                <div className="time-box">
                                                                    <div className="appointment_date">
                                                                        <p className="dates">{item.date}</p>
                                                                    </div>
                                                                    <div className="appointment_slot">
                                                                        <div className={item.selected_slots_morning && item.selected_slots_morning.length > 0 ? "day_slot mb-10" : "day_slot mb-10 disable_opacity"}>
                                                                            <div className="appoint_slots">
                                                                                <span className="morning_icon icon_slots slot_heading">Morning</span>
                                                                            </div>
                                                                            <div className="appointment_times">
                                                                                <div className="timeslot">
                                                                                    {
                                                                                        (item.selected_slots_morning && item.selected_slots_morning.length > 0) && item.selected_slots_morning.map((sub_item, sub_index) => {
                                                                                            return (
                                                                                                <span>{sub_item}</span>
                                                                                            )

                                                                                        })
                                                                                    }
                                                                                    <div className={item.selected_slots_morning && item.selected_slots_morning.length > 0 ? "noAvailable_box slot-show" : "noAvailable_box"}>
                                                                                        <span className="noavailaibel_text">No availability</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className={item.selected_slots_afternoon && item.selected_slots_afternoon.length > 0 ? "day_slot mb-10" : "day_slot mb-10 disable_opacity"}>
                                                                            <div className="appoint_slots">
                                                                                <span className="afternoon_icon icon_slots slot_heading">Afternoon</span>
                                                                            </div>

                                                                            <div className="appointment_times">
                                                                                <div className="timeslot">
                                                                                    {
                                                                                        (item.selected_slots_afternoon && item.selected_slots_afternoon.length > 0) && item.selected_slots_afternoon.map((sub_item, sub_index) => {
                                                                                            return (
                                                                                                <span>{sub_item}</span>
                                                                                            )

                                                                                        })
                                                                                    }
                                                                                    <div className={item.selected_slots_afternoon && item.selected_slots_afternoon.length > 0 ? "noAvailable_box slot-show" : "noAvailable_box"}>
                                                                                        <span className="noavailaibel_text">No availability</span>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className={item.selected_slots_evening && item.selected_slots_evening.length > 0 ? "day_slot mb-10" : "day_slot mb-10 disable_opacity"}>
                                                                            <div className="appoint_slots">
                                                                                <span className="evening_icon icon_slots slot_heading">Evening</span>
                                                                            </div>
                                                                            <div className="appointment_times">
                                                                                <div className="timeslot">
                                                                                    {
                                                                                        (item.selected_slots_evening && item.selected_slots_evening.length > 0) && item.selected_slots_evening.map((sub_item, sub_index) => {
                                                                                            return (
                                                                                                <span>{sub_item}</span>
                                                                                            )

                                                                                        })
                                                                                    }
                                                                                    <div className={item.selected_slots_evening && item.selected_slots_evening.length > 0 ? "noAvailable_box slot-show" : "noAvailable_box"}>
                                                                                        <span className="noavailaibel_text">No availability</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    )

                                                })
                                            }
                                        </div>

                                        <div className="no-respons_btn mt-24 mb-16">
                                            <div className="two-col">
                                                <div className="btn1">
                                                    <TransparentButton
                                                        ButtonType="button"
                                                        ButtonText="Delete listing"
                                                        ButtonClick={() => calldeleteListingApi(requestArray.uuid)}
                                                    />
                                                </div>
                                                <div className="btn2">
                                                    <TransparentButton
                                                        ButtonType="button"
                                                        ButtonText="Edit"
                                                        ButtonClick={() => calleditRequestApi(requestArray)}
                                                    />
                                                </div>
                                            </div>
                                        </div>


                                    </div>


                                </Col>
                            </Row>
                        </Container>
                }
            </section>
        </>
    );
}

export default RequestWithNoResponse;