import * as types from "../types/index";
import { setInitialState } from "../InitialState";


const INITIAL_STATE = setInitialState({
   openrequestbookingdetailsdata: null,
});


export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.OPENREQUESTBOOKINGDETAILS_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case types.OPENREQUESTBOOKINGDETAILS_RECEIVE:
            return {
                ...state,
                isFetching: false,
                isSuccess: true,
               openrequestbookingdetailsdata: action.payload.success
            };
        case types.OPENREQUESTBOOKINGDETAILS_FAILURE:
            return {
                ...state,
                isFetching: false,
                isError: true,
               openrequestbookingdetailsdata: action.payload.response.error,
            };
        case types.RESET_OPENREQUESTBOOKINGDETAILS:
            return {
                ...state,
                isFetching: false,
                isError: false,
               openrequestbookingdetailsdata: null,
            };
        default:
            return {
                ...state
            }
    }
}