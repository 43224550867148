import React from 'react';
import { LeftArrow, star } from '../../config/images';

const ReviewBox = (props) => {
    return (
        <>
            <div className="review_grid_2boxs ">
                <div className="review_box white_bg pad36_24">
                    <div className="review-heading flexbox_space">
                        <h3 className="heading">{props.title}</h3>
                        <p className="review_date"> Review on {props.reviewDate}</p>
                    </div>
                    {
                        props.decs && 
                        <div className="review-content mb-40">
                            <p>{props.decs}</p>
                        </div>
                    }
                  {!props.showgpname?null:
                        <div className="review-author">
                        <p>{props.reviewAuthor}</p>
                    </div>
                  }
                </div>
                <div className="rating_card white_bg pad32_16">
                    <div className="review-list">
                        
                        {  props.reviewName2 && 
                            <div className="review-item">
                                <p className="review-name">{props.reviewName2}</p>
                                <p className="review-count">{props.reviewCount2}</p>
                            </div>
                        }
                        { props. reviewName3 && 
                            <div className="review-item">
                                <p className="review-name">{props.reviewName3}</p>
                                <p className="review-count">{props.reviewCount3}</p>
                            </div>
                        }
                        { props.reviewName1 && 
                            <div className="review-item">
                                <p className="review-name">{props.reviewName1}</p>
                                <p className="review-count">{props.reviewCount1}</p>
                            </div>
                        }
                        {  props.reviewName4 && 
                            <div className="review-item">
                                <p className="review-name">{props.reviewName4}</p>
                                <p className="review-count">{props.reviewCount4}</p>
                            </div>
                        }
                        {   props.overAllCount ?
                            <div className="review-item">
                                <p className="review-name text-bold">Overall</p>
                                <div className="overall_rating">
                                    <img src={star} className="rating-icon" />
                                    <span className="overall_count">{props.overAllCount}</span>
                                </div>
                            </div>:
                             <div className="review-item">
                             <p className="review-name text-bold">Overall</p>
                             <div className="overall_rating">
                                 <img src={star} className="rating-icon" />
                                 <span className="overall_count">0</span>
                             </div>
                         </div>
                        }
                        
                        
                    </div>
                </div>
            </div>
        </>
    );
}

export default ReviewBox;