import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS } from '../../config/ApiConstants';


export function educationDetailAPI_Action(id) {
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/education/`+ id + `/details`,
            method: 'GET',
            headers: {
                ...AUTH_HEADERS,
            },
            body: '',
            types: [
                types.EDUCATIONDETAIL_REQUEST,
                types.EDUCATIONDETAIL_RECEIVE, {
                    type: types.EDUCATIONDETAIL_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}


export const resetEducationDetailAPI_Action = () => {
    return {
        type: types.RESET_EDUCATIONDETAIL,
        payload: null
    }
};
