import React, { useState } from 'react';
import { Container, Row, Col, Accordion, Card, useAccordionToggle } from 'react-bootstrap';
import { LeftArrow, favourite, unfavourite } from '../../config/images'
import LoadingSpinner from "../../components/UIComponent/LoadingSpinner"

const AccordionContentFav = (props) => {
    //state init
    // const [showText, setShowText] = useState(false);
    // ,(e) =>props.__setSelectedId(e,props.id)
    return (
        <>
            <Card className={"card-box cards " + props.disabled} disabled={props.disabled !== "" ? true : false}>
                <div className="treatment-card-header" onClick={(e) =>{props.onClick(props.indexValue)}} >
                    <div className="heading-box">
                        <div className="titles">
                            <span className="title_name"> {props.title} </span>
                            <span className="badge">{props.selectText}</span>
                        </div>
                        <div className="title-icon">
                            {
                                props.indexValue === props.isActive  ? <div className="downarrow"><img src={LeftArrow} /></div> :
                                    <div className="uparrow"><img src={LeftArrow} /></div>
                            }

                        </div>
                    </div>
                </div>

                {props.indexValue === props.isActive  && <div className="acc-body" >
                    <div className="treatment-body">
                        <div className="treat-content">
                            {props.content}
                        </div>


                        <div className="favcontent-modify ">
                            <div className="favcontent-modify mt-16">
                                <button className="modify_btn" onClick={(e) => props.favApitreatmentCall(e, props.uuid)} ><img src={props.isFavtreatment ? favourite : unfavourite} className="fav_icon_treatment" /></button>
                               
                                 {
                                    props.showLoading &&  props.isActive && 
                                    <div className="ml-15">
                                    <LoadingSpinner />
                                    </div>
                                }
                                
                                <h3 className="ml-15">Save this treatment to add to your favourites </h3>
                            </div>
                        </div>

                    </div>
                </div>
                }

            </Card>
        </>
    );
}

export default AccordionContentFav;