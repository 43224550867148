import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Container, Row, Col } from "react-bootstrap";
import { LeftArrow } from "../../config/images";
import { getLocalStorageData, setLocalStorageData, clearLocalStorage } from '../../utils/storage';
import { practitionerStripeRegisterAPI_Action, resetPractitionerAddInfoAPI_Action, resetBlogDetailAPI_Action, checkStripeAccountValidityAPI_Action, resetStripeAccountValidityAPI_Action } from "../../stores/actions";
import Modal from "react-modal";

const RegisterPractionertoStripe = (props) => {
  const dispatch = useDispatch();
  const loginUserData = getLocalStorageData("loginData");
  const registerData = getLocalStorageData("practitionersignupData");

  const practitionersignupaddinfoData = useSelector(state => state.practitionersignupaddinfo);
  const checkstripeaccountvalidityData = useSelector(state => state.checkstripeaccountvalidity);
  const loginToken = loginUserData ? loginUserData.token : "";
  const PractitionerToken = registerData ? registerData.token : "";//practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.token:"" : "";
  const [isShow, setIsShow] = useState(false);
  const [isUserRegistered, setIsUserRegistered] = useState(false);

  let stripe_connect_link = loginUserData

    ? loginUserData.stripe_connect_link
    : ""


  const isRegister = props.location.state.isRegister;
  let history = useHistory();

  const registerUser = (e) => {
    dispatch(resetStripeAccountValidityAPI_Action());

    if (isRegister === 1 && props.location.state.stripe_link !== "") {
      stripe_connect_link = props.location.state.stripe_link;
    }
    stripe_connect_link = stripe_connect_link //.replace("http://goginipig.development.pocketappserver.net.s3-website-eu-west-1.amazonaws.com/practitioner-registration-success", "http://localhost:3000/practitioner-registration-success");
    var win = window.open(stripe_connect_link, "_blank", "width=820,height=500");

    var timer = setInterval(function () {
      if (win.closed) {
        clearInterval(timer);
        setIsShow(false);
      }
    }, 1000);


    setIsShow(true);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      let temp = getLocalStorageData("stripeCode");
      if (temp) {
        clearInterval(interval)
        if (isUserRegistered === false) {
          _apiCall(temp);
        }
      }
    }, 1000);
  }, []);



  useEffect(() => {
    if (practitionersignupaddinfoData.isSuccess === true && practitionersignupaddinfoData.practitionersignupaddinfodata !== null) {
      _checkStripeValidation();
      setLocalStorageData("practitionersignupData", practitionersignupaddinfoData.practitionersignupaddinfodata.data)
      dispatch(resetPractitionerAddInfoAPI_Action());

    } else if (practitionersignupaddinfoData.isError === true && practitionersignupaddinfoData.practitionersignupaddinfodata !== null) {
      clearLocalStorage("stripeCode");
    }
  })

  useEffect(() => {
    if (checkstripeaccountvalidityData.isSuccess === true && checkstripeaccountvalidityData.checkstripeaccountvaliditydata !== null) {
      setLocalStorageData("stripeAccountValidity", checkstripeaccountvalidityData.checkstripeaccountvaliditydata.data)

      window.scrollTo(0, 0);
      setIsShow(false);

      if (isRegister === 1) {
        setLocalStorageData("RedirectPathName", "join-practitioner")
        window.scrollTo(0, 0);
        history.push({
          pathname: '/treatment-ginipig-request',
        });

      } else {
        setLocalStorageData("RedirectPathName", "");
        window.scrollTo(0, 0);
        history.push('/treatment-select')

      }
    } else if (checkstripeaccountvalidityData.isError === true && checkstripeaccountvalidityData.checkstripeaccountvaliditydata !== null) {
      clearLocalStorage("stripeCode");
    }
  })

  const backButtonClick = () => {

    let RedirectPathName = getLocalStorageData("RedirectPathName")
    if (RedirectPathName == "join-practitioner-community") {
      window.scrollTo(0, 0);
      history.push('/join-practitioner-community')
    } else if (RedirectPathName == "profile") {
      window.scrollTo(0, 0);
      history.push('/practitioner-profile')
    }
  }



  const _apiCall = async (code) => {
    setIsUserRegistered(true);
    let data = {
      code: code,
    }
    if (PractitionerToken !== "") {
      await dispatch(practitionerStripeRegisterAPI_Action(data, PractitionerToken));

    } else {
      await dispatch(practitionerStripeRegisterAPI_Action(data, loginToken));


    }
  }


  const _checkStripeValidation = async () => {
    setIsUserRegistered(true);

    if (PractitionerToken !== "") {
      await dispatch(checkStripeAccountValidityAPI_Action(PractitionerToken));

    } else {
      await dispatch(checkStripeAccountValidityAPI_Action(loginToken));
    }
  }



  return (
    <>
      <section className="bgcolor-gray common-section">
        <Container>
          <Row>
            <Col className="col-md-10 m-auto">
              <div className="heading-history">
                <div className="back-history">
                  <button
                    type="button"
                    onClick={() => history.goBack()}
                    className="back-btn"
                  >
                    {" "}
                    <img src={LeftArrow} alt="arrow" />{" "}
                  </button>
                </div>
                <div className="history-title">
                  <h2>Create Your Earnings Account</h2>
                </div>
              </div>
              <div className="desciption">
                <p className="subdesc">
                To list your first appointment you must create your earnings account. This will be the account that you will be paid into & will allow for withdrawal of your earnings based on the services you've provided.
                </p>

                {checkstripeaccountvalidityData.checkstripeaccountvaliditydata &&
                  checkstripeaccountvalidityData.checkstripeaccountvaliditydata.hasOwnProperty(
                    "error"
                  ) ? (
                  <p className="error">
                    {checkstripeaccountvalidityData.checkstripeaccountvaliditydata.error.hasOwnProperty(
                      "message"
                    ) ? (
                      <p style={{ color: "red" }}>
                        Your Stripe Registration is failed, Please Register on Stripe Again.
                      </p>
                    ) : null}
                  </p>
                ) : null}

                {practitionersignupaddinfoData.isError && practitionersignupaddinfoData.practitionersignupaddinfodata &&
                  practitionersignupaddinfoData.practitionersignupaddinfodata.hasOwnProperty(
                    "message"
                  ) ? (
                  <p style={{ color: "red" }}>
                    {
                      practitionersignupaddinfoData.practitionersignupaddinfodata.message
                    }{" "}
                  </p>
                ) : null}

                {checkstripeaccountvalidityData.isError && checkstripeaccountvalidityData.checkstripeaccountvaliditydata &&
                  checkstripeaccountvalidityData.checkstripeaccountvaliditydata.hasOwnProperty(
                    "message"
                  ) ? (
                  <p style={{ color: "red" }}>
                    Your Stripe Registration is failed, Please Register on Stripe Again.
                  </p>
                ) : null}

                {/* <div className="two-col mt-24 mb-24">
                  <button className="btns-gray " onClick={registerUser}>
                    {" "}
                    Register
                  </button>
                </div> */}
                   <div className="two-cols mt-24 mb-24">
                      
                      <button className="btns-gray " onClick={registerUser}>
                        {" "}
                      Yes, set up account
                    </button>

                      <button
                        className="btns-gray "
                        onClick={() => history.goBack()}
                      >
                        {" "}
                      No, cancel
                    </button>
                    </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Modal isOpen={isShow} styles={{ modal: {}, overlay: { background: "#000", zIndex: 9999 } }}>

        </Modal>
      </section>
    </>
  );
};

export default RegisterPractionertoStripe;