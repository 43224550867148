import React, { useCallback, useState, useEffect } from "react";
import { useHistory,Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import { LeftArrow, reviewAvatar, iconFav, iconPhoto, star, icon_suitability, icon_reliability, icon_etiquette } from '../../config/images';
import BlogDetailCard from '../../components/BlogDetailCard';
import  NavigationBreadcrumbs from '../../components/NavigationBreadcrumbs';
import moment from 'moment';
//redux 

import { blogDetailAPI_Action,resetBlogDetailAPI_Action } from '../../stores/actions'
import Loader from "../../components/Loader";

const BlogDetails = (props) => {
    let history = useHistory();
    let dispatch = useDispatch();
    const id=props.match.params.id;
   // console.log("id" + id);
    const blogDetailInfo = useSelector(state => state.blogdetail);
    const decsPtagRemove = blogDetailInfo.blogdetailsdata !== null ? blogDetailInfo.blogdetailsdata.data.blogDetails.blogDescription: "" ;
    //const content = decsPtagRemove.replace(/(<p>|<\/p>)/g, "");
    const datefromatPass = blogDetailInfo.blogdetailsdata !== null ? blogDetailInfo.blogdetailsdata.data.blogDetails.blogPublishDate : ''

    const [loader, setLoader] = useState(false);
    const [blogDataDetails, setBlogDataDetails] = useState([]);
    const [errorModel, setErrorModel] = useState(false);
    const [errorDataModel, setErrorDataModel] = useState(false);

     // Use effects
     useEffect(() => { 
         try{
            dispatch(blogDetailAPI_Action(id));
         }catch(e){
            console.log('Error',e);
         }        
    },[])
    
    useEffect(() => {
        if (blogDetailInfo.isFetching) {
          setLoader(true);
          console.log(' is loading')
        } else if (blogDetailInfo.isSuccess && blogDetailInfo.data !== null) {
          setLoader(false);
          let dashboardData = blogDetailInfo?.blogdetailsdata?.data?.blogDetails;
          setBlogDataDetails(dashboardData);
        console.log('dd succuss',)
        } else if (blogDetailInfo.isError) {
          setErrorModel(true);
          setErrorDataModel(blogDetailInfo.data);
        }
      }, [blogDetailInfo]);
    
    console.log('props',blogDataDetails)

   console.log("info" , blogDetailInfo);


    let dateFormat = moment(datefromatPass).format("DD-MMMM-YYYY")

    const __detailsContent = () => {
        return(
            <div className="detail-wrp">
                <div className="b_heading flexbox_grid mb-24">
                <h3 className="title">{blogDataDetails.blogTitle }</h3>
                    <span className="post_date">{dateFormat}</span>
                </div>
                <div className="details-decs">
                    {/* <p> {content} </p> */}
                    <div dangerouslySetInnerHTML={{ __html: decsPtagRemove }} />
                    <div className="author-name">
                        <p>
                    { blogDataDetails.blogAuthor}</p>
                    </div>
                </div>
            </div>
            );
    }

    const backBtnPressed=()=>{
        history.goBack();
    }

    return (
        <>
            {
                loader === true ?  <Loader /> : null 
            }
            

            <section className="bgcolor-gray common-section navline-position blog_details_pg pb80">
                <Container>
                    <Row>
                        <Col className="col-lg-11 m-auto">
                            <NavigationBreadcrumbs 
                             onBackClick = {()=>{backBtnPressed()}}
                            />
                            
                            <div className="boxwrp details requirement_wrp mt-24">
                                {
                                    blogDetailInfo &&
                                    
                                    <BlogDetailCard 
                                        Img={blogDataDetails.blogImage }
                                    >
                                            {__detailsContent()}
                                    </BlogDetailCard>
                                }
                            </div>

                        </Col> 
                    </Row> 
                </Container>
            </section>
        </>
    );
}

export default BlogDetails;