import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS, AUTH_HEADERS_NEW } from '../../config/ApiConstants';
export function createrequestAPI_Action(data,token) {

    const formData = new FormData(); 

    formData.append('treatment_id', data.treatment_id);
    formData.append('type', data.type);
    formData.append('hours', data.hours);
    formData.append('minutes', data.minutes);
    formData.append('reason_require_ginipig', data.reason_require_ginipig);
    formData.append('expertise_level', data.expertise_level);

    formData.append('rrp_price', data.rrp_price);
    formData.append('your_price', data.your_price);
    formData.append('attributes_required', data.attributes_required);
    formData.append('model_requirements', data.model_requirements);
    formData.append('photograph_required', data.photograph_required );

     formData.append('is_consentform_required', data.is_consentform_required);
     formData.append('patchtest_required', data.patchtest_required);

     formData.append('photograph_requirements', data.photograph_requirements);
     formData.append('consent_form_link', data.consent_form_link);
     formData.append('consent_form', data.consent_form);
   
     formData.append('date', data.date);

     formData.append('start_time', data.start_time);
     formData.append('use_profile_location', data.use_profile_location);
     formData.append('postcode', data.postcode);
     formData.append('street_name',data.street_name)
     
     formData.append('postcodeValue', data.postcodeValue);
     formData.append('country', data.country);
     formData.append('city', data.city);
     formData.append('latitude', data.latitude);
     formData.append('longitude', data.longitude);
     formData.append('request_old_id', data.request_old_id);
     formData.append('request_old_id', data.request_old_id);
     formData.append('is_online',data.ischecked_online)
    //  formData.append('request_image_old', data.request_image_old);

    if(data.request_image_old.length!==undefined || data.request_image_old!==null){
        for (var i = 0; i < data.request_image_old.length; i++) {
            formData.append('request_image_old[]', data.request_image_old[i]);
        }
    }
  
     

     formData.append('ginipig_count', data.ginipig_count);
     formData.append('request_timeslots', JSON.stringify(data.request_timeslots));
     formData.append('location', JSON.stringify(data.location));
     formData.append('is_keep_treatment_on_profile', JSON.stringify(data.is_keep_treatment_on_profile));

     
  if(data.request_image!==undefined && data.request_image!==null && data.request_image!=="")
     for (var i = 0; i < data.request_image.length; i++) {
       formData.append('request_image[]', data.request_image[i]);
   }
   formData.append('flat', data.flat);

   
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/request/add`,
            method: 'POST',
            headers: {
                ...AUTH_HEADERS_NEW,
                'Authorization':'Bearer '+token
            },
            body:formData,
            types: [
                types.CREATEREQUEST_REQUEST,
                types.CREATEREQUEST_RECEIVE, {
                    type: types.CREATEREQUEST_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}


export const resetcreaterequestAPI_Action = () => {
    
    return {
        type: types.RESET_CREATEREQUEST,
        payload: null
    }
};
