
import React, { useCallback, useState, useEffect } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from 'react-bootstrap';
import { LeftArrow, star} from '../../config/images';
import CloseRequestAcceptCancelCard from "../../components/UIComponent/CloseRequestAcceptCancelCard";
import LargeButton from '../../components/LargeButton';
import {  bookingNotCompltedAPI_Action, resetbookingNotCompltedAPI_Action, resetLoginAPI_Action } from '../../stores/actions'
import { getLocalStorageData,clearLocalStorage } from '../../utils/storage'


const initialErrorData = Object.freeze({

    description_error: "",
    is_form_valid_desc: false,

});
const AppointmentNotOccurr = (props) => {

    const dispatch = useDispatch();
    let history = useHistory();
    const location = useLocation();
    const [bookingdata, setbookingdata] = useState();
    const [character_count, setCharactercount] = useState(500);

    const [errorData, updateErrorData] = useState(initialErrorData);
    const [formData, updateFormData] = useState(Object.freeze({

        description: "",

    }));
    const practitionersignupaddinfoData = useSelector(state => state.practitionersignupaddinfo);

    const bookingnotcompletedData = useSelector(state => state.bookingnotcompletedData);
    // console.log("statec ", bookingnotcompletedData)

    // Use effects
    useEffect(() => {

        try {
            let bookingData = getLocalStorageData("bookingIdDontHappen")
            setbookingdata(bookingData)


        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    }, [])

    const ClicksendButton = () => {
       // console.log("ClicksendButton")
        let formDatanew = {
            id: bookingdata.uuid,
            not_occurs_reason: formData.description
        }

        __apiCall(formDatanew)
    }


    const __apiCall = useCallback(async (formDatanew) => {

        try {

            let checkToken = "";
            const RegisterToken = practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.token : "" : "";
            let LocalDatatoken = getLocalStorageData("loginData")

            if (RegisterToken != "") {
                checkToken = RegisterToken;
            }
            else if (LocalDatatoken !== "") {
                checkToken = LocalDatatoken.token;
            }

            await dispatch(bookingNotCompltedAPI_Action(formDatanew, checkToken));
            //await dispatch(pendingbookingDetailsAPI_Action(bookingId, checkToken));
            window.scrollTo(0, 0);
            history.push({
                pathname: '/appointment-confirmation-donthappen',

            });


        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    });

    if(bookingnotcompletedData && bookingnotcompletedData.bookingnotcompletedData !== null && bookingnotcompletedData.bookingnotcompletedData.status_code === 401){
        clearLocalStorage("loginData")
        clearLocalStorage("practitionersignupData")
        dispatch(resetLoginAPI_Action());
        dispatch(resetbookingNotCompltedAPI_Action());
        window.scrollTo(0, 0);
        history.push('/login');
    }

    const __onTextChange = (e) => {
        const { id, value } = e.target;
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value,

        });
        if (e.target.name === "description" && e.target.value !== "") {
            setCharactercount(500 - e.target.value.length)
        } else {
            setCharactercount(500)
        }
        if (e.target.name === "description" && e.target.value.length > 25) {
           // console.log("inside", e.target.value.length)
            updateErrorData({
                ...errorData,

                is_form_valid_desc: false,
                description_error: ""

            });
        }


    };



    const checkDescValidation = (e) => {
        const { id, value } = e.target;
        const description = value.trim();
        if (!description) {

            updateErrorData({
                ...errorData,
                is_form_valid_desc: false,
                description_error: "Comments are required",
            });


        } else if (description.length < 25) {
            updateErrorData({
                ...errorData,
                is_form_valid_desc: false,
                description_error: "Comments should be minimum 25 characters long",
            });
        } else if (description.length > 500) {
            updateErrorData({
                ...errorData,
                is_form_valid_desc: false,
                description_error: "Only 500 Characters are Allowed.",
            });
        } else {


            // if(createtreatmentData.createtreatmentdata !==null && createtreatmentData.isError===true)
            // {
            //     dispatch(resetcreatetreatmentAPI_Action());
            // }
            updateErrorData({
                ...errorData,
                is_form_valid_desc: true,
                description_error: ""
            });
        }


    };


    return (
        <>
            <section className="bgcolor-gray common-section leave_review_occurr pb80">
                <Container>
                    <Row>
                        <Col className="col-lg-11 m-auto">
                            <div className="navigationTop padright-0">
                                <div className="heading-history">
                                    <div className="back-history">
                                        <button type="button" onClick={() => history.goBack()} className="back-btn" >
                                            {" "}
                                            <img src={LeftArrow} alt="arrow" />{" "}
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="requirement_wrp padright-0">

                                <div className="decs-occurr">
                                    <p className="subdesc1"> This appointment didn't occur? Please confirm this GiniPig's appointment did not go ahead and leave any necessary comments </p>
                                </div>

                                <div className="all_request_box occurr_appointment_box mt-8">
                                    <div className="request_boxs pending mb-40">
                                        <div className="confirm-list mb-16">
                                            <CloseRequestAcceptCancelCard
                                                userImg={bookingdata && bookingdata.user.profile_pic}
                                                userName={bookingdata && bookingdata.user.name}
                                                ratingImg={star}
                                                showImagearrow={false}
                                                reliabilityCount={bookingdata && bookingdata.user && bookingdata.user.reliabilityAverage === 0 ? 0 : Math.ceil(bookingdata && bookingdata.user && bookingdata.user.reliabilityAverage)}
                                                etiquetteCount={bookingdata && bookingdata.user && bookingdata.user.etiquetteAverage === 0 ? 0 : Math.ceil(bookingdata && bookingdata.user && bookingdata.user.etiquetteAverage)}
                                               // suitabilityCount={bookingdata && bookingdata.user && bookingdata.user.suitabilityAverage === 0 ? 0 : Math.ceil(bookingdata && bookingdata.user && bookingdata.user.suitabilityAverage)}
                                                ratingPoint={bookingdata && bookingdata.user.review_rating_ginipig}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-wrp">

                                        <div className="teaxtarebox review-comments">

                                            <div className="subdesc1"> Leave your comments</div>
                                            {errorData.description_error &&
                                                errorData.description_error != "" ? (
                                                    <p className="error">
                                                        <p style={{ color: "red" }}>
                                                            {errorData.description_error}{" "}
                                                        </p>
                                                    </p>
                                                ) : null}

                                            {bookingnotcompletedData.isError &&  bookingnotcompletedData.bookingnotcompletedData &&
                                                bookingnotcompletedData.bookingnotcompletedData.hasOwnProperty(
                                                    "message"
                                                ) ? (
                                                    <p className="error">
                                                        <p style={{ color: "red" }}>
                                                            {
                                                                bookingnotcompletedData.bookingnotcompletedData.message
                                                            }{" "}
                                                        </p>
                                                    </p>
                                                ) : null}
                                            <div className="input-addicon addicontextarea mt-8" >
                                                <textarea
                                                    value={formData.description}
                                                    placeholder="Please leave any details about this appointmnet e.g how long you waited, were you contacted by Ginipig etc.."
                                                    id="description"
                                                    name="description"
                                                    onChange={__onTextChange}
                                                    onBlur={checkDescValidation}
                                                    maxlength="500"

                                                />

                                                {
                                                    character_count === 500 ?
                                                        <span className="char-span">{character_count} Character limit</span> :
                                                        <span className="char-span">{character_count} Remaining</span>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                </div>




                                <div className="btn-align mt-24 pd32">
                                    <LargeButton
                                        ButtonType="button"
                                        ButtonText=" Confirm & submit"
                                        disabled={errorData.is_form_valid_desc === false}
                                        nextButtonClick={ClicksendButton}
                                    />
                                </div>


                            </div>

                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default AppointmentNotOccurr;