import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Container, Row, Col } from "react-bootstrap";
import { LeftArrow } from "../../config/images";

// redux
import {
  logoutAPI_Action,
  resetUserAPI_Action,
  resetLoginAPI_Action,
  resetRegisterAPI_Action,
  resetPractitionerAddInfoAPI_Action,
  resettreatmentaddreviewAPI_Action,
  resetPractionerCategory_API_Action,
  resetpractitionerProfileAPI_Action,
  resetpractionerStripeLinkAPI_Action,
  resettreatmentdetailsAPI_Action,
  resetcreatetreatmentAPI_Action,
  resetStripeAccountValidityAPI_Action
} from "../../stores/actions";
import { clearLocalStorage } from '../../utils/storage'
import { getLocalStorageData } from '../../utils/storage';
const PractitionerLogout = (props) => {
  const dispatch = useDispatch();
  const loginUserData = useSelector((state) => state.login);
  const registerData = useSelector(state => state.register);
  const practitionersignupaddinfoData = useSelector(state => state.practitionersignupaddinfo);
  const Data = JSON.stringify(registerData);
  let LocalDatatoken = getLocalStorageData("loginData")
  const loginToken = loginUserData.logindata
    ? loginUserData.logindata.data
      ? loginUserData.logindata.data.token
      : ""
    : "";
  let history = useHistory();

  var param_data = false; var treatment_id = 0; var fav_text = '';
  if (props.location.state !== undefined) {
    param_data = props.location.state.data.fav;
    treatment_id = props.location.state.data.treatment_id;
    fav_text = props.location.state.data.text;
  }



  const logoutUser = async (e) => {
    if (fav_text === 'In order to favourite a  Treatment/Service provider profile or treatment, please login as a GiniPig.') {
      history.push("/login", { data: { fav_prac_profile: true, treatment_id: treatment_id } });
    }
    else {
      try {
        let checkToken = "";
        const loginToken = loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token : "" : "";
        let registerDatapractitioner = getLocalStorageData("practitionersignupData")
        const PractitionerToken = registerDatapractitioner ? registerData ? registerDatapractitioner.token : "" : "";

        const RegisterToken = registerData.registerdata ? registerData.registerdata.data ? registerData.registerdata.data.token : "" : "";

        if (loginToken != "") {
          checkToken = loginToken;
        } else if (PractitionerToken != "") {
          checkToken = PractitionerToken;
        } else if (RegisterToken != "") {
          checkToken = RegisterToken;
        } else if (LocalDatatoken !== "") {
         // checkToken = LocalDatatoken.token;
        }
        clearLocalStorage("loginData")
        clearLocalStorage("practitionersignupData")
        clearLocalStorage("RedirectPathName")
        clearLocalStorage("requestDataLocal")



        await dispatch(logoutAPI_Action(checkToken));
        await dispatch(resetLoginAPI_Action());
        await dispatch(resetUserAPI_Action());
        await dispatch(resetRegisterAPI_Action());
        await dispatch(resetPractitionerAddInfoAPI_Action());
        await dispatch(resettreatmentaddreviewAPI_Action());
        await dispatch(resetPractionerCategory_API_Action());
        await dispatch(resetpractitionerProfileAPI_Action());
        await dispatch(resetpractionerStripeLinkAPI_Action());
        await dispatch(resetStripeAccountValidityAPI_Action());
        await dispatch(resettreatmentdetailsAPI_Action());
        await dispatch(resetcreatetreatmentAPI_Action());
        

        if (param_data) {
          window.scrollTo(0, 0);
          history.push("/login", { data: { fav_prac_profile: true, treatment_id: treatment_id } });
        } else {
          window.scrollTo(0, 0);
          history.push("/login");
        }

      } catch (error) {
        console.error(" profile ::  profile() :: Exception ==", error);
      }
    }
  };

  return (
    <>
      <section className="bgcolor-gray common-section">
        <Container>
          <Row>
            <Col className="col-md-10 m-auto">
              <div className="heading-history">
                <div className="back-history">
                  <button
                    type="button"
                    onClick={() => {
                      window.scrollTo(0, 0);
                      history.goBack()
                    }}
                    className="back-btn"
                  >
                    {" "}
                    <img src={LeftArrow} alt="arrow" />{" "}
                  </button>
                </div>
                {fav_text === 'In order to favourite a Practitioner profile or treatment, please login as a GiniPig.' ?
                  <div className="history-title">
                    <h2>Login to your GiniPig account </h2>
                  </div> :
                  <div className="history-title">
                    <h2>Logout of your account </h2>
                  </div>

                }
              </div>
              <div className="desciption">
                {
                  param_data ?
                    <p className="subdesc">
                      {fav_text}
                    </p> :
                    <p className="subdesc">
                      To book an appointment you have to log in as a GiniPig. Would you like to log out of your Treatment/Service provider account?
                  </p>
                }
                {
                  fav_text === "" || fav_text === 'In order to favourite a Treatment/Service provider profile or treatment, please login as a GiniPig. Would you like to logout of your Treatment/Service provider account?'
                    ?
                    <div className="two-cols mt-24 mb-24">
                      
                      <button className="btns-gray " onClick={logoutUser}>
                        {" "}
                      Yes, logout of my account
                    </button>

                      <button
                        className="btns-gray "
                        onClick={() => history.goBack()}
                      >
                        {" "}
                      No, do not logout
                    </button>
                    </div> :
                    <div className="two-col mt-24 mb-24">
                      <button className="btns-gray " onClick={logoutUser}>
                        {" "}
                      Ok
                    </button>
                    </div>
                }


              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default PractitionerLogout;
