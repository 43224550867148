import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS } from '../../config/ApiConstants';
export function whatsAvailableAPI_Action(data) {
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/whats-available?${data}&page=1&per_page=500`,
            method: 'GET',
            headers: {
                ...AUTH_HEADERS,
            },
            body:'',
            types: [
                types.WHATSAVAILABLE_REQUEST,
                types.WHATSAVAILABLE_RECEIVE, {
                    type: types.WHATSAVAILABLE_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}


export const resetAPI_ActionWhatAvailable = () => {
    return {
        type: types.RESET_WHATSAVAILABLE,
        payload: null
    }
};
