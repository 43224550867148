import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Container, Row, Col } from "react-bootstrap";
import { LeftArrow } from "../../config/images";
import LargeButton from "../../components/LargeButton";
import LoadingSpinner from "../../components/UIComponent/LoadingSpinner";
import { createAppointmentData_Action, resetcreaterequestAPI_Action, imageUploadAPI_Action, resetimageUploadAPI_Action, } from '../../stores/actions'
import { getLocalStorageData, setLocalStorageData } from '../../utils/storage'
import moment from 'moment'

const initialErrorData = Object.freeze({
  treatment_title_error: "",
  requirements_modal_desc_error: "",
  requirements_photo_desc_error: "",
  requirements_modal_desc_error_active: "Description should be minimum 25 characters long.",
  requirements_photo_desc_error_active: "Description should be minimum 25 characters long.",

  is_form_valid_modal: true,
  is_form_valid_other: true,
  is_form_valid_photo: true,
  is_form_valid_file: true


});


const GiniPigRequirement = () => {
  // Initialize hooks first   
  const dispatch = useDispatch();
  let history = useHistory();
  const location = useLocation();
  const [patchtesterror, setpatchtesterror] = useState("");

  const [formData, updateFormData] = useState(Object.freeze({

    attributes_required: false,
    photograph_required: false,
    is_consentform_required: false,
    patchtest_required: false,

    model_requirements: "",
    photograph_requirements: "",
    consent_form_link: "",
    consentformName: "",
    consent_form: "",
    rrpprice: '',
    yourprice: '',
    file1: "",
    file2: "",
    file3: "",
    file4: "",
    hrs: "",
    minutes: "00",
    selectedTreatment: [],
    selectedTreatmentId: "",
    activeclassformdata: "",
    category_id: "",
    reason_require_ginipig: "",
    expertise_level: "",
    imagearray1: [],
    imagearray2: [],
    imagearray3: [],
    imagearray4: [],
    is_keep_treatment_on_profile: 0,
    request_image: [],
    selected_time_slots: [],

  }));

  const [errorData, updateErrorData] = useState(initialErrorData);
  const [character_count_modal, setCharactercountModal] = useState(500);
  const [character_count_photo, setCharactercountPhoto] = useState(500);

  // Selectors
  const AppointmentCreateData = useSelector(state => state.appointmentcreatedata);
  const imageUploaddata = useSelector(state => state.imageUploaddata);

  // Use effects
  useEffect(() => {

    try {
      let checkToken = "";

      let LocalDatatoken = getLocalStorageData("loginData")
      let registerData = getLocalStorageData("practitionersignupData")

      if (registerData !== "" && registerData !== undefined && registerData !== null) {
        checkToken = registerData.token;
      }
      else if (LocalDatatoken !== "" && LocalDatatoken !== undefined && LocalDatatoken !== null) {
        checkToken = LocalDatatoken.token;
      }
      if (checkToken === '') {
        window.scrollTo(0, 0);
        history.push('/login');
      }

      let requestDataLocal = getLocalStorageData("requestDataLocal")

      let useprofilelocation = 1

      if (requestDataLocal.RequestData !== null && requestDataLocal.RequestData !== "" && requestDataLocal.RequestData !== undefined) {

        if (requestDataLocal.RequestData.use_profile_location !== null && requestDataLocal.RequestData.use_profile_location !== undefined && requestDataLocal.RequestData.use_profile_location !== "") {
          if (requestDataLocal.RequestData.use_profile_location === 1) {
            useprofilelocation = 1
          } else {
            useprofilelocation = 0
          }
        }
        updateFormData({
          ...formData,
          requestId: requestDataLocal.RequestData.requestId ? requestDataLocal.RequestData.requestId : 0,
          request_image_old: requestDataLocal.RequestData.request_image_old ? requestDataLocal.RequestData.request_image_old : [],
          flat_build_no: requestDataLocal.RequestData.flat_build_no ? requestDataLocal.RequestData.flat_build_no : "",
          is_keep_treatment_on_profile: requestDataLocal.RequestData.is_keep_treatment_on_profile ? requestDataLocal.RequestData.is_keep_treatment_on_profile : 0,
          redirectPathName: requestDataLocal.RequestData.redirectPathName ? requestDataLocal.RequestData.redirectPathName : "complete-profile",
          selectedTreatmentId: requestDataLocal.RequestData ? requestDataLocal.RequestData.selectedTreatmentId : "",
          selectedTreatment: requestDataLocal.RequestData ? requestDataLocal.RequestData.selectedTreatment : [],
          rrpprice: requestDataLocal.RequestData.rrpprice ? requestDataLocal.RequestData.rrpprice : "",
          yourprice: requestDataLocal.RequestData.yourprice ? requestDataLocal.RequestData.yourprice : "",

          file1: requestDataLocal.RequestData.file1 ? requestDataLocal.RequestData.file1 : "",
          file2: requestDataLocal.RequestData.file2 ? requestDataLocal.RequestData.file2 : "",
          file3: requestDataLocal.RequestData.file3 ? requestDataLocal.RequestData.file3 : "",
          file4: requestDataLocal.RequestData.file4 ? requestDataLocal.RequestData.file4 : "",

          imagearray1: requestDataLocal.RequestData.imagearray1 ? requestDataLocal.RequestData.imagearray1 : [],
          imagearray2: requestDataLocal.RequestData.imagearray2 ? requestDataLocal.RequestData.imagearray2 : [],
          imagearray3: requestDataLocal.RequestData.imagearray3 ? requestDataLocal.RequestData.imagearray3 : [],
          imagearray4: requestDataLocal.RequestData.imagearray4 ? requestDataLocal.RequestData.imagearray4 : [],
          consent_form: requestDataLocal.RequestData.consent_form ? requestDataLocal.RequestData.consent_form : "",

          town_city: requestDataLocal.RequestData.town_city ? requestDataLocal.RequestData.town_city : "",
          street_name: requestDataLocal.RequestData.street_name ? requestDataLocal.RequestData.street_name : "",
          hrs: requestDataLocal.RequestData.hrs ? requestDataLocal.RequestData.hrs : 0,

          minutes: requestDataLocal.RequestData.minutes ? requestDataLocal.RequestData.minutes : "00",

          activeclassformdata: requestDataLocal.RequestData ? requestDataLocal.RequestData.activeclassformdata : "",

          reason_require_ginipig: requestDataLocal.RequestData.reason_require_ginipig ? requestDataLocal.RequestData.reason_require_ginipig : "",
          expertise_level: requestDataLocal.RequestData.expertise_level ? requestDataLocal.RequestData.expertise_level : "",



          attributes_required: requestDataLocal.RequestData.attributes_required ? requestDataLocal.RequestData.attributes_required : false,
          photograph_required: requestDataLocal.RequestData.photograph_required ? requestDataLocal.RequestData.photograph_required : false,
          is_consentform_required: requestDataLocal.RequestData.is_consentform_required ? requestDataLocal.RequestData.is_consentform_required : false,
          patchtest_required: requestDataLocal.RequestData.patchtest_required ? requestDataLocal.RequestData.patchtest_required : false,


          model_requirements: requestDataLocal.RequestData ? requestDataLocal.RequestData.model_requirements : "",
          photograph_requirements: requestDataLocal.RequestData ? requestDataLocal.RequestData.photograph_requirements : "",
          consent_form_link: requestDataLocal.RequestData ? requestDataLocal.RequestData.consent_form_link : "",
          consentformName: requestDataLocal.RequestData ? requestDataLocal.RequestData.consentformName : "",

          date: requestDataLocal.RequestData ? requestDataLocal.RequestData.date : "",
          start_time_hrs: requestDataLocal.RequestData ? requestDataLocal.RequestData.start_time_hrs : "",
          start_time_minutes: requestDataLocal.RequestData ? requestDataLocal.RequestData.start_time_minutes : "",
          postcode: requestDataLocal.RequestData ? requestDataLocal.RequestData.postcode : "",

          postcodeValue: requestDataLocal.RequestData.postcodeValue ? requestDataLocal.RequestData.postcodeValue : "",
          country: requestDataLocal.RequestData.country ? requestDataLocal.RequestData.country : "",
          city: requestDataLocal.RequestData.city ? requestDataLocal.RequestData.city : "",
          latitude: requestDataLocal.RequestData.latitude ? requestDataLocal.RequestData.latitude : "",
          longitude: requestDataLocal.RequestData.longitude ? requestDataLocal.RequestData.longitude : "",
          locationprofile: requestDataLocal.RequestData.locationprofile ? requestDataLocal.RequestData.locationprofile : "",
          use_profile_location: useprofilelocation,
          ginipig_count: requestDataLocal.RequestData.ginipig_count ? requestDataLocal.RequestData.ginipig_count : "",
          request_type: requestDataLocal.RequestData.request_type ? requestDataLocal.RequestData.request_type : "",
          show_postcode: requestDataLocal.RequestData.show_postcode ? requestDataLocal.RequestData.show_postcode : false,
          ischecked_online: requestDataLocal.RequestData.ischecked_online ? requestDataLocal.RequestData.ischecked_online : false,
          ischecked_inperson: requestDataLocal.RequestData.ischecked_inperson ? requestDataLocal.RequestData.ischecked_inperson : false,
          town_city: requestDataLocal.RequestData.town_city ? requestDataLocal.RequestData.town_city : "",
          street_name: requestDataLocal.RequestData.street_name ? requestDataLocal.RequestData.street_name : "",

          selected_time_slots: requestDataLocal.selected_time_slots ? requestDataLocal.selected_time_slots : [],

          category_id: requestDataLocal.RequestData.category_id ? requestDataLocal.RequestData.category_id : "",
          category_name: requestDataLocal.RequestData.category_name ? requestDataLocal.RequestData.name : "",
          sub_category_id: requestDataLocal.RequestData.sub_category_id ? requestDataLocal.RequestData.sub_category_id : [],
          title: requestDataLocal.RequestData ? requestDataLocal.RequestData.title : "",
          description: requestDataLocal.RequestData.title ? requestDataLocal.RequestData.description : "",
          categories: requestDataLocal.RequestData.categories ? requestDataLocal.RequestData.categories : "",
          treatment_sub_category: requestDataLocal.RequestData.treatment_sub_category ? requestDataLocal.RequestData.treatment_sub_category : []

        });
      }

      // var save_data = {
      //   file1: AppointmentCreateData.appointmentcreatedata.file1 ? AppointmentCreateData.appointmentcreatedata.file1 : "",
      //   file2: AppointmentCreateData.appointmentcreatedata.file2 ? AppointmentCreateData.appointmentcreatedata.file2 : "",
      //   file3: AppointmentCreateData.appointmentcreatedata.file3 ? AppointmentCreateData.appointmentcreatedata.file3 : "",
      //   file4: AppointmentCreateData.appointmentcreatedata.file4 ? AppointmentCreateData.appointmentcreatedata.file4 : "",
      //   // consent_form:AppointmentCreateData.appointmentcreatedata ? AppointmentCreateData.appointmentcreatedata.consent_form : "",

      //   imagearray1: AppointmentCreateData.appointmentcreatedata.imagearray1 ? AppointmentCreateData.appointmentcreatedata.imagearray1 : [],
      //   imagearray2: AppointmentCreateData.appointmentcreatedata.imagearray2 ? AppointmentCreateData.appointmentcreatedata.imagearray2 : [],
      //   imagearray3: AppointmentCreateData.appointmentcreatedata.imagearray3 ? AppointmentCreateData.appointmentcreatedata.imagearray3 : [],
      //   imagearray4: AppointmentCreateData.appointmentcreatedata.imagearray4 ? AppointmentCreateData.appointmentcreatedata.imagearray4 : [],
      //   consent_form: AppointmentCreateData.appointmentcreatedata.consent_form ? AppointmentCreateData.appointmentcreatedata.consent_form : [],

      // }

      // dispatch(createAppointmentData_Action(save_data));


    } catch (error) {
      console.error(" profile ::  profile() :: Exception ==", error);
    }

    dispatch(resetimageUploadAPI_Action())

  }, [])

  const __apiCall = useCallback(async () => {
    try {

      let formDatarequest = {
        RequestData: formData,
        selected_time_slots: formData.selected_time_slots

      }

      setLocalStorageData("requestDataLocal", formDatarequest)

      window.scrollTo(0, 0);
      if (location.redirectPath === "appointment-review") {
        history.push('/appointment-review')

      } else {
        history.push('/appointment-availability');
      }

    } catch (error) {
      console.error(" profile ::  profile() :: Exception ==", error);
    }
  });


  const checkPatchtestError = (currentDate, value) => {

    // console.log("value", value)
    let formattedtime = currentDate + " " + value
    var todaysDate = moment();
    var treatmentDate = moment(formattedtime, "DD-MM-YYYY HH:mm");
    var ms = moment(treatmentDate).diff(todaysDate, "hours");
    if (ms < 72) {
      return true
    } else {
      return false
    }

  }



  const nextButtonClick = async (e) => {
    e.preventDefault()

    if (formData.selected_time_slots.length > 0 && formData.patchtest_required && formData.request_type == 1) {
      let isSlotagreater = false
      let slot = "12:00"

      formData.selected_time_slots.map((item, index) => {
        if (isSlotagreater) {
          return;
        }
        if (item.selected_slots_morning.length > 0) {
          slot = item.selected_slots_morning[0]
        } else if (item.selected_slots_afternoon.length > 0) {
          slot = item.selected_slots_afternoon[0]
        } else if (item.selected_slots_evening.length > 0) {
          slot = item.selected_slots_evening[0]
        }

        if (checkPatchtestError(item.date, slot)) {
          isSlotagreater = true
          return;
        }

      })
      if (isSlotagreater) {
        setpatchtesterror("Oops! It doesn’t look like you have allowed enough time to fit in a Patch Test before the appointment")
      } else {
        setpatchtesterror("")
        dispatch(resetcreaterequestAPI_Action());
        __apiCall()
      }

    } else if (formData.patchtest_required && formData.request_type == 2 && formData.date !== "") {
      let slot = formData.start_time_hrs + ":" + formData.start_time_minutes
      if (checkPatchtestError(formData.date, slot)) {
        setpatchtesterror("Oops! It doesn’t look like you have allowed enough time to fit in a Patch Test before the appointment")
      } else {
        setpatchtesterror("")
        dispatch(resetcreaterequestAPI_Action());
        __apiCall()

      }
    }

    else {
      setpatchtesterror("")
      dispatch(resetcreaterequestAPI_Action());
      __apiCall()
    }

  }

  const toggleChangerequiremnetsModel = (e) => {
    updateFormData({
      ...formData,
      attributes_required: !formData.attributes_required,

    });

    if (formData.attributes_required === false) {
      updateErrorData({
        ...errorData,
        is_form_valid_modal: false,

      });
    }
    else if (formData.attributes_required === true) {
      updateErrorData({
        ...errorData,
        is_form_valid_modal: true,

      });
      updateFormData({
        ...formData,
        attributes_required: false,
        model_requirements: "",

      });

    }
  };

  const toggleChangerequiremnetsphoto = (e) => {

    updateFormData({
      ...formData,
      photograph_required: !formData.photograph_required,
    });

    if (formData.photograph_required === false) {
      updateErrorData({
        ...errorData,
        is_form_valid_photo: false,

      });

    }
    else if (formData.photograph_required === true) {
      updateErrorData({
        ...errorData,
        is_form_valid_photo: true,

      });

      updateFormData({
        ...formData,
        photograph_requirements: "",
        photograph_required: false,
      });

    }

  }


  const handleFileChange = (e) => {
    e.preventDefault();
    dispatch(resetimageUploadAPI_Action());

    let filetype = e.target.files[0].type.substring(e.target.files[0].type.lastIndexOf('/'))
    let newfiletype = filetype.replace("/", "")
    //console.log(e.target.files[0].name)
    if (newfiletype == "jpeg" || newfiletype == "jpg" || newfiletype == "png" || newfiletype == "pdf") {

      if (e.target.files[0] !== undefined) {

        updateFormData({
          ...formData,
          // consent_form: e.target.files[0],
          //consent_form_link: URL.createObjectURL(e.target.files[0]),
          consentformName: e.target.files[0].name.substring(0, 5) + "." + newfiletype,

        });

        let image = e.target.files[0]
        dispatch(imageUploadAPI_Action(image));

        updateErrorData({
          ...errorData,
          is_form_valid_file: true,
          image_type_error: ""


        });

      }
    }
    else {
      updateErrorData({
        ...errorData,
        is_form_valid_file: false,
        image_type_error: "Please upload only JPEG,JPG,PNG and pdf file.",
      });

    }

  };

  if (imageUploaddata !== null && imageUploaddata.isSuccess) {

    updateFormData({
      ...formData,
      consent_form: imageUploaddata.imageUploaddata.data.image_link,
      consent_form_link: imageUploaddata.imageUploaddata.data.image_link
    });
    dispatch(resetimageUploadAPI_Action())


  }

  const toggleChangerequiremnetsFile = (e) => {
    updateFormData({
      ...formData,
      is_consentform_required: !formData.is_consentform_required,
    });
    if (formData.is_consentform_required === false) {
      updateErrorData({
        ...errorData,
        is_form_valid_file: false,

      });

    }
    else if (formData.is_consentform_required === true) {
      updateErrorData({
        ...errorData,
        is_form_valid_file: true,

      });

      updateFormData({
        ...formData,
        consent_form_link: "",
        is_consentform_required: false,

      });

    }


  };
  const toggleChangerequiremnetsPatchtest = (e) => {
    updateFormData({
      ...formData,
      patchtest_required: !formData.patchtest_required,
    });
  }


  const checkModalDescValidation = (e) => {
    const { id, value } = e.target;
    if (!value) {

      updateErrorData({
        ...errorData,
        is_form_valid_modal: false,
        requirements_modal_desc_error: "Model requirements description is required.",
      });


    } else if (value.length < 25) {
      updateErrorData({
        ...errorData,
        is_form_valid_modal: false,
        requirements_modal_desc_error: "Description should be minimum 25 characters long.",
      });
    } else if (value.length > 500) {
      updateErrorData({
        ...errorData,
        is_form_valid_modal: false,
        requirements_modal_desc_error: "Only 500 Characters are Allowed.",
      });
    } else {
      updateErrorData({
        ...errorData,
        is_form_valid_modal: true,
        requirements_modal_desc_error: ""
      });
    }
  }

  const checkPhotoDescValidation = (e) => {
    const { value } = e.target;


    if (!value) {

      updateErrorData({
        ...errorData,
        is_form_valid_photo: false,
        requirements_photo_desc_error: "Photo requirements description is required.",
      });


    } else if (value.length < 25) {
      updateErrorData({
        ...errorData,
        is_form_valid_photo: false,
        requirements_photo_desc_error: "Description should be minimum 25 characters long.",
      });
    } else if (value.length > 500) {
      updateErrorData({
        ...errorData,
        is_form_valid_photo: false,
        requirements_photo_desc_error: "Only 500 Characters are Allowed.",
      });
    } else {
      updateErrorData({
        ...errorData,
        is_form_valid_photo: true,
        requirements_photo_desc_error: ""
      });
    }
  }

  const __onTextChange = (e) => {

    updateFormData({
      ...formData,
      [e.target.name]: e.target.value
    });

    if (e.target.name === "model_requirements" && e.target.value !== "") {
      setCharactercountModal(500 - e.target.value.length)
    } else {
      setCharactercountModal(500)
    }
    if (e.target.name === "model_requirements" && e.target.value.length > 25) {
      //  console.log("inside",e.target.value.length)
      updateErrorData({
        ...errorData,

        is_form_valid_modal: true,
        requirements_modal_desc_error: ""

      });
    }

    if (e.target.name === "photograph_requirements" && e.target.value !== "") {
      setCharactercountPhoto(500 - e.target.value.length)
    } else {
      setCharactercountPhoto(500)
    }
    if (e.target.name === "photograph_requirements" && e.target.value.length > 25) {
      //  console.log("inside",e.target.value.length)
      updateErrorData({
        ...errorData,

        is_form_valid_photo: true,
        requirements_photo_desc_error: ""

      });
    }


  }

  console.log("formData.is_consentform_required", formData.is_consentform_required)


  return (
    <>
      <section className="bgcolor-gray common-section ginipig_requirement pb80">
        <Container>
          <Row>
            <Col className="col-lg-11 m-auto">
              <div className="navigationTop">
                <div className="heading-history">
                  <div className="back-history">
                    <button
                      type="button"
                      onClick={() => history.goBack()}
                      className="back-btn"
                    >
                      {" "}
                      <img src={LeftArrow} alt="arrow" />{" "}
                    </button>
                  </div>
                  <div className="history-title">
                    <h2> GiniPig Must Haves </h2>
                  </div>
                </div>
              </div>
              <div className="from-wrp mt-40">
                <form>
                  <div className="req-box">
                    <div className="fromProfile  form-wrp-require mb-24">
                      <div className="optional-errormsg">
                        <p className="profile-label">Model Requirements</p>
                        <p className="optiontext">Optional</p>
                      </div>
                      <div className="checkbox_req">
                        <div className="checkbox-group">
                          <label htmlFor="check1">
                            <div className="checkinput">
                              <input
                                className="tnc"
                                type="checkbox"
                                id="check1"
                                onChange={toggleChangerequiremnetsModel}
                                checked={formData.attributes_required === true ? true : false}
                              />
                            </div>
                            <span className="checkbox-decs">
                              {" "}
                              I require particular GiniPig ‘must haves’ for this
                              treatment{" "}
                            </span>
                          </label>
                        </div>
                      </div>

                      {formData.attributes_required === true ?

                        <div className="bg-white  form-wrp mb-24">
                          <div className="profilefield mb-0">
                            <div className="showwithlabel">
                              <label className="profile-label">
                                Please describe the details of the GiniPig’s
                                requirements
                              </label>
                              {errorData.requirements_modal_desc_error &&
                                errorData.requirements_modal_desc_error !== "" ? (
                                <p className="error">
                                  <p style={{ color: "red" }}>
                                    {errorData.requirements_modal_desc_error}{" "}
                                  </p>
                                </p>
                              ) : <p className="error">
                                <p className="optiontext">
                                  {errorData.requirements_modal_desc_error_active}{" "}
                                </p>
                              </p>}
                            </div>
                            <div
                              className="input-addicon addicontextarea"

                            >
                              <textarea
                                //placeholder="Character limit"
                                id="model_requirements"
                                name="model_requirements"
                                value={formData.model_requirements}
                                onChange={__onTextChange}
                                onBlur={checkModalDescValidation}
                                maxlength="500"
                              />
                              {
                                character_count_modal === 500 ?
                                  <span className="char-span">{character_count_modal} Character limit</span> :
                                  <span className="char-span">{character_count_modal} Remaining</span>
                              }
                            </div>
                          </div>
                        </div> : null

                      }
                    </div>
                  </div>
                  <div className="req-box">
                    <div className="fromProfile  form-wrp-require mb-24">
                      <div className="optional-errormsg">
                        <p className="profile-label">Photo Requirements</p>
                        <p className="optiontext">Optional</p>
                      </div>
                      <div className="checkbox_req">
                        <div className="checkbox-group">
                          <label htmlFor="check2">
                            <div className="checkinput">
                              <input
                                className="tnc"
                                type="checkbox"
                                id="check2"
                                onChange={toggleChangerequiremnetsphoto}
                                checked={formData.photograph_required === true ? true : false}
                              />
                            </div>
                            <span className="checkbox-decs">
                              I require the Ginipig to supply images in order to
                              check their suitability
                            </span>
                          </label>
                        </div>
                      </div>

                      {formData.photograph_required === true ?
                        <div className="fromProfile  form-wrp bg-white mb-24">
                          <div className="profilefield mb-0">
                            <div className="showwithlabel">
                              <label className="profile-label">
                                Please describe the details of the photographs
                                required
                              </label>
                              {errorData.requirements_photo_desc_error &&
                                errorData.requirements_photo_desc_error != "" ? (
                                <p className="error">
                                  <p className="errortext">
                                    {errorData.requirements_photo_desc_error}{" "}
                                  </p>
                                </p>
                              ) : <p className="error">
                                <p className="optiontext">
                                  {errorData.requirements_photo_desc_error_active}{" "}
                                </p>
                              </p>}
                            </div>
                            <div
                              className="input-addicon addicontextarea"


                            >
                              <textarea
                                // placeholder="Character limit"
                                id="photograph_requirements"
                                name="photograph_requirements"
                                value={formData.photograph_requirements}
                                onChange={__onTextChange}
                                onBlur={checkPhotoDescValidation}
                                maxlength="500"
                              />
                              {
                                character_count_photo === 500 ?
                                  <span className="char-span">{character_count_photo} Character limit</span> :
                                  <span className="char-span">{character_count_photo} Remaining</span>
                              }

                            </div>
                          </div>
                        </div> : null

                      }
                    </div>
                  </div>
                  <div className="req-box">
                    <div className="optional-errormsg">
                      <p className="profile-label">Consultation Form</p>

                      <p className="optiontext">Optional</p>
                    </div>
                    <div className="fromProfile   mb-24">
                      <div className="docs-upload">
                        <div className="optional-errormsg">
                          <div className="checkbox_req">
                            <div className="checkbox-group">
                              <label htmlFor="check3">
                                <div className="checkinput">
                                  <input
                                    className="tnc"
                                    type="checkbox"
                                    id="check3"
                                    onChange={toggleChangerequiremnetsFile}
                                    checked={formData.is_consentform_required === true ? true : false}
                                  />
                                </div>
                                <span className="checkbox-decs">
                                  Are there contraindications for this appointment? By uploading your consultation form the GiniPig will be able to ensure suitability before booking
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                        {errorData.image_type_error &&
                          errorData.image_type_error != "" ? (
                          <p className="error">
                            <p style={{ color: "red" }}>
                              {errorData.image_type_error}{" "}
                            </p>
                          </p>
                        ) : null}
                        




                        {/* <p className="optiontext">Optional</p> */}


                        {formData.is_consentform_required ?
                          <div className="upload_btn_req">
                            {imageUploaddata.imageUploaddata &&
                                imageUploaddata.imageUploaddata.hasOwnProperty("errors") ? (
                                <p className="errortext">
                                    {imageUploaddata.imageUploaddata.errors.hasOwnProperty(
                                    "image"
                                    ) ? (
                                    <p style={{ color: "red" }}>
                                        {
                                        imageUploaddata.imageUploaddata.errors.image[0]
                                        }{" "}
                                    </p>
                                    ) : null}
                                </p>
                                ) : null}
                            
                            <div className="btn_upload" style={{position:'relative'}}>
                              <input
                                type="file"
                                id="profile_text"
                                name="profile_text"
                                onChange={handleFileChange}

                              />
                              
                              {formData.consent_form_link ?
                                <span disabled={true} className="upload-profile"> {formData.consentformName}</span> :
                                <span disabled={true} className="upload-profile"> Upload</span>
                              }
                              {
                                imageUploaddata.isFetching === true ? (
                                  <div className="loader-position" style={{textAlign:'center',marginTop:'8px',marginBottom:'10px',position: 'absolute',
                                  top: 0,
                                  left: 0,
                                  right: 0,}}>
                                      <LoadingSpinner />
                                  </div>
                                ) : null
                              }
                              
                              
                            </div>
                              

                          </div> : null

                        }
                      </div>


                    </div>
                  </div>


                  <div className="req_box">
                    <div className="optional-errormsg">
                      <p className="profile-label">Patch Test</p>
                      <p className="optiontext">Optional</p>
                    </div>

                    {patchtesterror &&
                      patchtesterror != "" ? (
                      <p className="error">
                        <p style={{ color: "red" }}>
                          {patchtesterror}{" "}
                        </p>
                      </p>
                    ) : null}


                    <div className="fromProfile   mb-24">
                      <div className="checkbox_req">
                        <div className="checkbox-group">
                          <label htmlFor="check4">
                            <div className="checkinput">
                              <input
                                className="tnc"
                                type="checkbox"
                                id="check4"
                                onChange={toggleChangerequiremnetsPatchtest}
                                checked={formData.patchtest_required === true ? true : false}
                              />
                            </div>
                            <span className="checkbox-decs">
                              I require the Ginipig to complete a patch test prior to this treatment
                            </span>
                          </label>


                        </div>

                      </div>
                      {
                        formData.patchtest_required ?
                          <p className="profile-label-header">
                            Make sure there is 72 hours before the appointment to align your diaries & make this happen, ensuring your appointment goes smoothly.
                          </p> : null
                      }
                    </div>
                  </div>
                  {(!errorData.is_form_valid_file && formData.is_consentform_required === true) ||(!errorData.is_form_valid_photo && formData.photograph_required === true) 
                  || (!errorData.is_form_valid_modal && formData.attributes_required === true)   ? (
                    <p className="error">
                      <p style={{ color: "red", marginBottom: 20 }}>
                        {"Please ensure you have supplied the necessary files and information before proceeding"}
                      </p>
                    </p>
                  ) : null}

                  <div className="btn-align pd32 mt-40">
                    { imageUploaddata.isFetching ?
                      <LargeButton
                      disabled={true}
                      ButtonText="Loading..."
                  />  :
                    <LargeButton
                      ButtonType="submit"
                      ButtonText={location.redirectPath === "appointment-review" ? "Save Changes" : "Next"}
                      disabled={(
                        errorData.is_form_valid_photo === true 
                        && errorData.is_form_valid_modal === true 
                        && errorData.is_form_valid_file === true ) ? false : true}
                      nextButtonClick={nextButtonClick} />
                    }
                  </div>

                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default GiniPigRequirement;
