import React, { useCallback, useEffect, useState } from 'react';
import { useHistory ,useLocation} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { LeftArrow, reviewAvatar, star, iconPhoto, iconCase_Study, iconNearly_there, iconGiniPigs_Required } from '../../config/images';
import ThumbnailViewDetail from '../../components/ThumbnailViewDetail';
import UserProfile from '../../components/UserProfile';
import LargeButton from '../../components/LargeButton';
import moment from 'moment';
import { requestDetailsAPI_Action, treatment_details, resetupcomingbookingAPI_Action } from '../../stores/actions';
import { getLocalStorageData, clearLocalStorage } from '../../utils/storage';

const ThanksRepayment = (props) => {
  //state init
  let history = useHistory();
  const dispatch = useDispatch();
  const location=useLocation()
  const loginUserData = useSelector(state => state.login);
  const requestdetails = useSelector(state => state.requestdetails);
  const filterData = useSelector(state => state.savefilter);
  var param_data = props.match.params.id;



  useEffect(() => {
    window.addEventListener('popstate', function (event) {
      nextButtonClick()
      window.location.reload(false);
    }, false);

    clearLocalStorage('photo_requirement_available')
    clearLocalStorage('bookingcondition_requirement')
    if(location.paramData){
      __apiCall()
    }else{
      nextButtonClick()
    }
    
  }, []);

  const __apiCall = useCallback(async () => {
    const loginToken = loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token : "" : "";
    try {
      await dispatch(requestDetailsAPI_Action(param_data, loginToken, 0));
    } catch (error) {
      console.error(" profile ::  profile() :: Exception ==", error);
    }
  });

  const __redirectProfilePg = () => {
    window.scrollTo(0, 0);
    dispatch(resetupcomingbookingAPI_Action());
    history.push('/practitioner-account')
  }

  const nextButtonClick = () => {
    window.scrollTo(0, 0);
    clearLocalStorage("book_open_request_time");
    clearLocalStorage("update_ginipig_profile");
    window.removeEventListener('popstate', function(event) {        
      // history.go(1);
   }, false);
    var backpressed = ''; var data = ''; var date = '';

    if (getLocalStorageData("filter_treatment_data") !== undefined) {
      backpressed = JSON.parse(getLocalStorageData("filter_treatment_data"))
    }
    if (backpressed.hasOwnProperty('searchDetails')) {
      if (backpressed.searchDetails !== null) {
        if (backpressed.searchDetails.startDate !== 'Any date') {
          date = [new Date(backpressed.searchDetails.startDate).getFullYear(), ("0" + (new Date(backpressed.searchDetails.startDate).getMonth() + 1)).slice(-2), ("0" + new Date(backpressed.searchDetails.startDate).getDate()).slice(-2)].join("-");
        } else {
          date = 'Any date'
        }
        data = {
          keywords: backpressed.searchDetails.search_keywords,
          location: backpressed.searchDetails.search_location,
          latitude: backpressed.searchDetails.search_latitude,
          longitude: backpressed.searchDetails.search_longitude,
          postcode: backpressed.searchDetails.search_postcode,
          from_header: 'not_from_header',
          date: date
        }
      }
    }

    history.push('/search-result', { data: JSON.stringify(data), date: date })
  }

  if (requestdetails.requestdetailsdata && requestdetails.requestdetailsdata !== null) {
    var time = '';
    var set_date;
    if (time == "") {
      if (requestdetails.requestdetailsdata.data.request.type !== 1) {

        if (requestdetails.requestdetailsdata.data.request.request_timeslots.data[0].start_time !== null) {
          time = requestdetails.requestdetailsdata.data.request.request_timeslots.data[0].start_time.split(':')
          time = time[0] + ':' + time[1]
        }

        //set_date =moment(requestdetails.requestdetailsdata.data.request.request_timeslots.data[0].date_time).format("Do MMMM YYYY") + ', '+ time;

        let dateFormat = moment(requestdetails.requestdetailsdata.data.request.request_timeslots.data[0].date_time).format("Do MMMM YYYY")
        set_date = dateFormat + ' @ ' + time;


        //set_date =moment(requestdetails.requestdetailsdata.data.request.request_timeslots.data[0].date_time,"yy-MM-DD, h:mm:ss").format("Do MMM") + ' at '+ time;

      } else {
        let localdata = getLocalStorageData("book_open_request_time");
        if (localdata) {
          let temp = JSON.parse(localdata);
          let datesCount = 0;
          let timeCount = 0;
          let selectedDate = "";
          // console.log("selected time data:-", temp);
          //let selectedDates = temp.map(obj=>{
          if (temp.timeslots.morning.length > 0 || temp.timeslots.afternoon.length > 0 || temp.timeslots.evening.length > 0) {
            datesCount++;
            selectedDate = selectedDate !== "" ? selectedDate : temp.date;
            if (time === "") {
              if (temp.timeslots.morning.length > 0 && temp.timeslots.morning.length < 2) {
                time = temp.timeslots.morning[0]
              } else if (temp.timeslots.afternoon.length > 0 && temp.timeslots.afternoon.length < 2) {
                time = temp.timeslots.afternoon[0]
              } else if (temp.timeslots.evening.length > 0 && temp.timeslots.evening.length < 2) {
                time = temp.timeslots.evening[0]
              }
            }
          }
          if (temp.timeslots.morning.length > 0) {
            timeCount++;
            if (temp.timeslots.morning.length > 1)
              timeCount++;
          }
          if (temp.timeslots.afternoon.length > 0) {
            timeCount++;
            if (temp.timeslots.afternoon.length > 1)
              timeCount++;
          }
          if (temp.timeslots.evening.length > 0) {
            timeCount++;
            if (temp.timeslots.evening.length > 1)
              timeCount++;
          }
          // })
          // console.log("datesCount:-",datesCount," timeCount:-",timeCount, "selectedDate:- ",selectedDate, "time:-",time);
          time = timeCount > 1 ? " " : `, ${time}`;
          //time =  timeCount>1 ? " @ various times": `, ${time}`;
          //set_date =moment(selectedDate).format("Do MMMM YYYY") + time;
          set_date = moment(selectedDate, "DD-MM-yy").format("Do MMMM YYYY") + time;
          //set_date =moment(selectedDate,"DD-MM-yy").format("Do MMMM YYYY") + time;
        }

      }
    }
  }


  return (
    <>
      <section className="bgcolor-gray common-section appointment_confimation_pg  pb80">
        <Container>
          <Row>
            {
              requestdetails.requestdetailsdata && requestdetails.requestdetailsdata !== null ?
                <Col className="col-lg-11 m-auto">
                  <div className="from-wrp mt-40">
                    <div className="appoint_conf_box pb-32 pt-70">
                      <div className="heading_content">
                        <h2 className="title "> Thank You </h2>
                      </div>
                    </div>

                    <div className="payment_box mt-24 mb-70">
                      <ThumbnailViewDetail
                        treatmentTitle={requestdetails.requestdetailsdata.data.request.treatment.data.title}
                        currencySign="£"
                        originalPriceText={requestdetails.requestdetailsdata.data.request.rrp_price.toFixed(2)}
                        discountPriceText={requestdetails.requestdetailsdata.data.request.your_price.toFixed(2)}
                        tagsName={requestdetails.requestdetailsdata.data.request.treatment.data.categories.data.name}
                        duration={requestdetails.requestdetailsdata.data.request.hours + 'h' + ' ' + requestdetails.requestdetailsdata.data.request.minutes + 'm'}
                        categoryIcon1={iconCase_Study}
                        categorName1={requestdetails.requestdetailsdata.data.request.reason_require_ginipig}
                        categoryIcon2={iconNearly_there}
                        categorName2={requestdetails.requestdetailsdata.data.request.expertise_level}
                        categoryIcon3={iconGiniPigs_Required}
                        categorName3={requestdetails.requestdetailsdata.data.request.places_available === 1 ? "1 place left " : requestdetails.requestdetailsdata.data.request.places_available + " places left"}
                        buttonRequiestTime={set_date}
                        buttonReqAddress={requestdetails.requestdetailsdata.data.request.postcodes.data.postcode}
                      />
                    </div>


                    <div className="thank_user_box mt-40">
                      <UserProfile
                        userImg={requestdetails.requestdetailsdata.data.request.treatment.data.user.data.profile_pic}
                        userName={requestdetails.requestdetailsdata.data.request.treatment.data.user.data.first_name + ' ' + requestdetails.requestdetailsdata.data.request.treatment.data.user.data.last_name}
                        ratingImg={star}
                        userId={requestdetails.requestdetailsdata.data.request.treatment.data.user.data.uuid}
                        ratingPoint={requestdetails.requestdetailsdata.data.request.user.data.review_rating_practitioner.toFixed(1)}
                        //address={requestdetails.requestdetailsdata.data.request.treatment.data.user.data.user_additional_info.data[0].postcode_data.data.location}
                        tagsTitle="Other available appointments"

                        //companyName={requestdetails !== null ? requestdetails.requestdetailsdata.data.request.treatment.data.user.data.type === "Individual" ? practitionerData.practicing_saloon === 1 ? practitionerData.establishment_name : "Individual" : practitionerData.practicing_saloon === 1 ? practitionerData.company_name : practitionerData.practitioner_category_apply : null}
                        companyName={requestdetails.requestdetailsdata.data.request.treatment.data.user.data.practitioner_category_apply == null ? requestdetails.requestdetailsdata.data.request.treatment.data.user.data.company_name : requestdetails.requestdetailsdata.data.request.treatment.data.user.data.practitioner_category_apply}
                        //postCode={  requestdetails.requestdetailsdata.data.request.treatment.data.user.data.user_additional_info.length > 0 ?  requestdetails.requestdetailsdata.data.request.treatment.data.user.data.user_additional_info.data[0].postcode_data !== null ?  requestdetails.requestdetailsdata.data.request.treatment.data.user.data.user_additional_info.data[0].postcode_data.data.postcode ?  requestdetails.requestdetailsdata.data.request.treatment.data.user.data.user_additional_info[0].postcode_data.data.postcode.substr(0, requestdetails.requestdetailsdata.data.request.treatment.data.user.data.user_additional_info[0].postcode_data.data.postcode.length - 3) + " " : null : null : null}
                        postCode={requestdetails.requestdetailsdata.data.request.treatment.data.user.data.user_additional_info.data[0].postcode_data.data.postcode.substr(0, requestdetails.requestdetailsdata.data.request.treatment.data.user.data.user_additional_info.data[0].postcode_data.data.postcode.length - 3)}
                        city={requestdetails.requestdetailsdata.data.request.treatment.data.user.data.user_additional_info.data[0].postcode_data.data.city}

                        // tagName="Hair"
                        // count="2"
                        categoryData={requestdetails.requestdetailsdata.data.request.treatment.data.user.data.category_data}
                      />
                    </div>

                    <div className="btn-align mt-24 pd32">
                      <LargeButton
                        ButtonType="button"
                        ButtonText="Back to search"
                        nextButtonClick={nextButtonClick}
                      />
                    </div>

                  </div>
                </Col> :
                <div style={{ width: "100%", fontSize: 20, marginTop: 100, textAlign: 'center' }}>
                  <p>Loading ...</p>
                </div>
            }
          </Row>

        </Container>
      </section>
    </>
  );
}

export default ThanksRepayment;