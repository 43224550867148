import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { LeftArrow, reviewAvatar, Aesthetic, Hair, Beauty, wellness, AestheticTwo, BeautyTwo, HairTwo, wellnessTwo } from '../../config/images';
import TreatmentRadioButton from '../../components/TreatmentRadioButton'
import TreatmentCheckboxButton from '../../components/TreatmentCheckboxButton'
import LargeButton from '../../components/LargeButton'
import { getLocalStorageData, setLocalStorageData, clearLocalStorage } from '../../utils/storage'
import { formatPostcode } from '../../utils/utility';

import { treatmentDetailsAPI_Action, createAppointmentData_Action, createrequestAPI_Action, practitinerCategoryAPI_Action, categorylistAPI_Action, resettreatmentaddreviewAPI_Action, resetcreaterequestAPI_Action } from '../../stores/actions'

const AppointmentReview = (props) => {
    //state init
    const dispatch = useDispatch();
    let history = useHistory();
    const location = useLocation();

    const [formData, updateFormData] = useState(Object.freeze({
        request_type: "",
        show_postcode: false,
        selected_slots: [],
        selected_slots_morning: [],
        selected_slots_afternoon: [],
        selected_slots_evening: [],
        displayImageArray: [],


        date: '',

        start_time_minutes: "",
        postcode: "",

        postcodeValue: "",
        country: "",
        city: "",
        latitude: "",
        longitude: "",

        ginipig_count: "",
        sub_category_id: [],
        treatment_sub_category: [],
        is_keep_treatment_on_profile: 0,




        attributes_required: false,
        photograph_required: false,
        is_consentform_required: false,
        patchtest_required: false,

        model_requirements: "",
        photograph_requirements: "",
        consent_form_link: "",
        consentformName: "",
        title: "",
        description: "",
        categories: "",
        rrpprice: '',
        yourprice: '',
        file1: "",
        file2: "",
        file3: "",
        file4: "",
        hrs: "",
        minutes: "00",
        selectedTreatment: [],
        selectedTreatmentId: "",
        activeclassformdata: "",
        category_id: "",
        reason_require_ginipig: "",
        expertise_level: "",
        imagearray1: [],
        imagearray2: [],
        imagearray3: [],
        selectedTimeArray: [],
        imagearray4: [],

        request_image: [],

        date: '',
        start_time_hrs: "",
        start_time_minutes: "",
        // postcode: "",
        ginipig_count: "",
        request_type: "",
        show_postcode: false,
        street_name: "",


        selected_time_slots: [],
        flat_build_no: ''
    }));

    // Selectors
    const userData = useSelector(state => state.userdetails);
    const loginUserData = useSelector(state => state.login);
    const registerData = useSelector(state => state.register);
    const Data = JSON.stringify(registerData);
    const treatmentdetailsData = useSelector(state => state.treatmentdetails);
    const AppointmentCreateData = useSelector(state => state.appointmentcreatedata);
    const categoryData = useSelector(state => state.categorylist);
    const practitionersignupaddinfoData = useSelector(state => state.practitionersignupaddinfo);
    const createrequest = useSelector(state => state.createrequest);
    const edittreatmentData = useSelector(state => state.edittreatment);
    const practitionercatData = useSelector(state => state.practitionercategory);

    // Use effects
    useEffect(() => {
        // console.log("categoryData:-", categoryData);
        // console.log("practitionersignupaddinfoData:-", practitionersignupaddinfoData);
        // console.log("AppointmentCreateData:-", AppointmentCreateData);
        // console.log("treatmentdetailsData:-", treatmentdetailsData);
        // console.log("createrequest:-", createrequest);
        // console.log("edittreatmentData:-", edittreatmentData);
        try {
            let checkToken = "";

            let LocalDatatoken = getLocalStorageData("loginData")
            let registerData = getLocalStorageData("practitionersignupData")

            if (registerData != "" && registerData !== undefined && registerData !== null) {
                checkToken = registerData.token;
            }
            else if (LocalDatatoken !== "" && LocalDatatoken !== undefined && LocalDatatoken !== null) {
                checkToken = LocalDatatoken.token;
            }
            if (checkToken === '') {
                window.scrollTo(0, 0);
                history.push('/login');
            }

            if (categoryData.categorylistdata === null) {
                try {
                    dispatch(categorylistAPI_Action());

                } catch (error) {
                    console.error(" profile ::  profile() :: Exception ==", error);
                }
            }
            if (practitionercatData.practitionercategorydata === null) {
                try {
                  dispatch(practitinerCategoryAPI_Action(checkToken));
          
                } catch (error) {
                  console.error(" profile ::  profile() :: Exception ==", error);
                }
              
              }

            let requestDataLocal = getLocalStorageData("requestDataLocal")
            // console.log(requestDataLocal.RequestData)
            //   if(requestDataLocal===null && requestDataLocal===undefined){
            //     history.push({
            //         pathname: '/practitioner-profile',
            //         iswindowreload:true
            //     });
            //   }else{


            if (requestDataLocal !== undefined && requestDataLocal.RequestData !== null && requestDataLocal.RequestData !== "" && requestDataLocal.RequestData !== undefined) {
                let useprofilelocation = 1
                if (requestDataLocal.RequestData.use_profile_location !== null && requestDataLocal.RequestData.use_profile_location !== undefined && requestDataLocal.RequestData.use_profile_location !== "") {
                    if (requestDataLocal.RequestData.use_profile_location === 1) {
                        useprofilelocation = 1
                    } else {
                        useprofilelocation = 0
                    }
                }

                let selectedTimeArray = [];
                requestDataLocal.selected_time_slots && requestDataLocal.selected_time_slots.forEach(element => {
                    if (element.selected_slots_morning.length > 0 || element.selected_slots_afternoon.length > 0 || element.selected_slots_evening.length > 0) {
                        selectedTimeArray.push(element)
                    }
                })
                if (selectedTimeArray.length !== 0) {
                    selectedTimeArray.sort(function (a, b) {
                        return new Date(a.formmatedDate) - new Date(b.formmatedDate);
                    });
                }
                let pathname = getLocalStorageData("pathnamereviewedit")
                let treatment_sub_category = []
                let treatment_title = ""
                if (pathname === "editTreatment") {
                    //  console.log(edittreatmentData.edittreatmentdata)
                    treatment_sub_category = edittreatmentData.edittreatmentdata ? edittreatmentData.edittreatmentdata.data.treatment.treatment_sub_category_new : []
                    treatment_title = edittreatmentData.edittreatmentdata ? edittreatmentData.edittreatmentdata.data.treatment.title : ""

                }
                // console.log("equestDataLocal.RequestData.category_name", requestDataLocal.RequestData.categories.name)

                updateFormData({
                    ...formData,
                    selectedTreatmentId: requestDataLocal.RequestData.selectedTreatmentId ? requestDataLocal.RequestData.selectedTreatmentId : "",
                    selectedTreatment: requestDataLocal.RequestData.selectedTreatment ? requestDataLocal.RequestData.selectedTreatment : [],
                    is_keep_treatment_on_profile: requestDataLocal.RequestData.is_keep_treatment_on_profile ? requestDataLocal.RequestData.is_keep_treatment_on_profile : 0,
                    redirectPathName: requestDataLocal.RequestData.redirectPathName ? requestDataLocal.RequestData.redirectPathName : "complete-profile",
                    requestId: requestDataLocal.RequestData.requestId ? requestDataLocal.RequestData.requestId : 0,
                    rrpprice: requestDataLocal.RequestData.rrpprice ? requestDataLocal.RequestData.rrpprice : "",
                    yourprice: requestDataLocal.RequestData.yourprice ? requestDataLocal.RequestData.yourprice : "",

                    hrs: requestDataLocal.RequestData.hrs ? requestDataLocal.RequestData.hrs : 0,
                    request_image_old: requestDataLocal.RequestData.request_image_old ? requestDataLocal.RequestData.request_image_old : [],

                    minutes: requestDataLocal.RequestData.minutes ? requestDataLocal.RequestData.minutes : "00",

                    activeclassformdata: requestDataLocal.RequestData ? requestDataLocal.RequestData.activeclassformdata : "",

                    reason_require_ginipig: requestDataLocal.RequestData.reason_require_ginipig ? requestDataLocal.RequestData.reason_require_ginipig : "",
                    expertise_level: requestDataLocal.RequestData.expertise_level ? requestDataLocal.RequestData.expertise_level : "",


                    request_image: requestDataLocal.RequestData.request_image ? requestDataLocal.RequestData.request_image : [],
                    attributes_required: requestDataLocal.RequestData.attributes_required ? requestDataLocal.RequestData.attributes_required : false,
                    photograph_required: requestDataLocal.RequestData.photograph_required ? requestDataLocal.RequestData.photograph_required : false,
                    is_consentform_required: requestDataLocal.RequestData.is_consentform_required ? requestDataLocal.RequestData.is_consentform_required : false,
                    patchtest_required: requestDataLocal.RequestData.patchtest_required ? requestDataLocal.RequestData.patchtest_required : false,

                    model_requirements: requestDataLocal.RequestData.model_requirements ? requestDataLocal.RequestData.model_requirements : "",
                    photograph_requirements: requestDataLocal.RequestData.photograph_requirements ? requestDataLocal.RequestData.photograph_requirements : "",
                    consent_form_link: requestDataLocal.RequestData.consent_form_link ? requestDataLocal.RequestData.consent_form_link : "",
                    consentformName: requestDataLocal.RequestData.consentformName ? requestDataLocal.RequestData.consentformName : "",

                    date: requestDataLocal.RequestData.date ? requestDataLocal.RequestData.date : "",
                    start_time_hrs: requestDataLocal.RequestData.start_time_hrs ? requestDataLocal.RequestData.start_time_hrs : 0,
                    start_time_minutes: requestDataLocal.RequestData.start_time_minutes ? requestDataLocal.RequestData.start_time_minutes : 0,
                    postcode: requestDataLocal.RequestData ? requestDataLocal.RequestData.postcode : "",
                    town_city: requestDataLocal.RequestData.town_city ? requestDataLocal.RequestData.town_city : "",
                    street_name: requestDataLocal.RequestData.street_name ? requestDataLocal.RequestData.street_name : "",

                    //postcodeValue: requestDataLocal.RequestData.postcodeValue ? requestDataLocal.RequestData.postcodeValue : "",
                    postcodeValue: requestDataLocal.RequestData.postcode ? requestDataLocal.RequestData.postcode : "",
                    flat_build_no: requestDataLocal.RequestData.flat_build_no ? requestDataLocal.RequestData.flat_build_no : "",
                    country: requestDataLocal.RequestData.country ? requestDataLocal.RequestData.country : "",
                    city: requestDataLocal.RequestData.city ? requestDataLocal.RequestData.city : "",

                    latitude: requestDataLocal.RequestData.latitude ? requestDataLocal.RequestData.latitude : "",
                    longitude: requestDataLocal.RequestData.longitude ? requestDataLocal.RequestData.longitude : "",
                    locationprofile: requestDataLocal.RequestData.locationprofile ? requestDataLocal.RequestData.locationprofile : "",
                    use_profile_location: useprofilelocation,

                    ginipig_count: requestDataLocal.RequestData.ginipig_count ? requestDataLocal.RequestData.ginipig_count : "",
                    request_type: requestDataLocal.RequestData.request_type ? requestDataLocal.RequestData.request_type : "",
                    show_postcode: requestDataLocal.RequestData.show_postcode ? requestDataLocal.RequestData.show_postcode : "",
                    ischecked_online: requestDataLocal.RequestData.ischecked_online ? requestDataLocal.RequestData.ischecked_online : false,
                    ischecked_inperson: requestDataLocal.RequestData.ischecked_inperson ? requestDataLocal.RequestData.ischecked_inperson : false,

                    selected_time_slots: requestDataLocal.selected_time_slots ? requestDataLocal.selected_time_slots : [],
                    selectedTimeArray: selectedTimeArray,
                    category_id: requestDataLocal.RequestData.category_id ? requestDataLocal.RequestData.category_id : "",
                    category_name: requestDataLocal.RequestData.category_name ? requestDataLocal.RequestData.name : "",
                    sub_category_id: requestDataLocal.RequestData.sub_category_id ? requestDataLocal.RequestData.sub_category_id : "",
                    title: requestDataLocal.RequestData.title ? requestDataLocal.RequestData.title : treatment_title,
                    description: requestDataLocal.RequestData.description ? requestDataLocal.RequestData.description : "",
                    categories: requestDataLocal.RequestData.categories ? requestDataLocal.RequestData.categories : "",
                    treatment_sub_category: requestDataLocal.RequestData.treatment_sub_category ? requestDataLocal.RequestData.treatment_sub_category : treatment_sub_category,

                    file1: requestDataLocal.RequestData.file1 ? requestDataLocal.RequestData.file1 : "",
                    file2: requestDataLocal.RequestData.file2 ? requestDataLocal.RequestData.file2 : "",
                    file3: requestDataLocal.RequestData.file3 ? requestDataLocal.RequestData.file3 : "",
                    file4: requestDataLocal.RequestData.file4 ? requestDataLocal.RequestData.file4 : "",

                    imagearray1: requestDataLocal.RequestData.imagearray1 ? requestDataLocal.RequestData.imagearray1 : [],
                    imagearray2: requestDataLocal.RequestData.imagearray2 ? requestDataLocal.RequestData.imagearray2 : [],
                    imagearray3: requestDataLocal.RequestData.imagearray3 ? requestDataLocal.RequestData.imagearray3 : [],
                    imagearray4: requestDataLocal.RequestData.imagearray4 ? requestDataLocal.RequestData.imagearray4 : [],
                    consent_form: requestDataLocal.RequestData.consent_form ? requestDataLocal.RequestData.consent_form : "",

                });

                // var save_data = {
                //     file1: AppointmentCreateData.appointmentcreatedata && AppointmentCreateData.appointmentcreatedata.file1 ? AppointmentCreateData.appointmentcreatedata.file1 : "",
                //     file2: AppointmentCreateData.appointmentcreatedata && AppointmentCreateData.appointmentcreatedata.file2 ? AppointmentCreateData.appointmentcreatedata.file2 : "",
                //     file3: AppointmentCreateData.appointmentcreatedata && AppointmentCreateData.appointmentcreatedata.file3 ? AppointmentCreateData.appointmentcreatedata.file3 : "",
                //     file4: AppointmentCreateData.appointmentcreatedata && AppointmentCreateData.appointmentcreatedata.file4 ? AppointmentCreateData.appointmentcreatedata.file4 : "",
                //     // consent_form:AppointmentCreateData.appointmentcreatedata ? AppointmentCreateData.appointmentcreatedata.consent_form : "",

                //     imagearray1: AppointmentCreateData.appointmentcreatedata && AppointmentCreateData.appointmentcreatedata.imagearray1 ? AppointmentCreateData.appointmentcreatedata.imagearray1 : [],
                //     imagearray2: AppointmentCreateData.appointmentcreatedata && AppointmentCreateData.appointmentcreatedata.imagearray2 ? AppointmentCreateData.appointmentcreatedata.imagearray2 : [],
                //     imagearray3: AppointmentCreateData.appointmentcreatedata && AppointmentCreateData.appointmentcreatedata.imagearray3 ? AppointmentCreateData.appointmentcreatedata.imagearray3 : [],
                //     imagearray4: AppointmentCreateData.appointmentcreatedata && AppointmentCreateData.appointmentcreatedata.imagearray4 ? AppointmentCreateData.appointmentcreatedata.imagearray4 : [],
                //     consent_form: AppointmentCreateData.appointmentcreatedata && AppointmentCreateData.appointmentcreatedata.consent_form ? AppointmentCreateData.appointmentcreatedata.consent_form : "",

                // }

                // dispatch(createAppointmentData_Action(save_data));



            } else {
                window.scrollTo(0, 0);
                history.push({
                    pathname: '/practitioner-profile',
                    iswindowreload: true
                });
            }

        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }

    }, [])

    // console.log(createrequest)

    const nextButtonClick = (e) => {

        //  console.log("formData", formData.selectedTimeArray)
        e.preventDefault();

        const newArray = formData.selectedTimeArray
        var object_slots = {};
        var dateinarray = ""
        newArray.forEach((item, index) => {
            dateinarray = item.date;
            object_slots[dateinarray] = {

                morning: item.selected_slots_morning,
                afternoon: item.selected_slots_afternoon,
                evening: item.selected_slots_evening,


            }

        })
        // console.log(object_slots)

        let startTime = formData.start_time_hrs + ":" + formData.start_time_minutes

        // console.log("formData on re", formData)

        let feature_image = formData.imagearray1.concat(formData.imagearray2).concat(formData.imagearray3).concat(formData.imagearray4)
        // console.log(feature_image)
        let formDataNew = {
            treatment_id: formData.selectedTreatmentId,
            type: formData.request_type,
            request_type: formData.request_type,
            hours: formData.hrs,
            hrs: formData.hrs,
            start_time_hrs: formData.start_time_hrs,
            start_time_minutes: formData.start_time_minutes,
            minutes: formData.minutes,
            reason_require_ginipig: formData.reason_require_ginipig,
            expertise_level: formData.expertise_level,
            rrp_price: formData.rrpprice,
            your_price: formData.yourprice,
            rrpprice: formData.rrpprice,
            yourprice: formData.yourprice,
            attributes_required: formData.attributes_required === true ? 1 : 0,
            model_requirements: formData.model_requirements,
            photograph_required: formData.photograph_required === true ? 1 : 0,
            is_consentform_required: formData.is_consentform_required === true ? 1 : 0,
            patchtest_required: formData.patchtest_required === true ? 1 : 0,
            ischecked_online: formData.ischecked_online === true ? 1 : 0,
            ischecked_inperson: formData.ischecked_inperson === true ? 1 : 0,
            photograph_requirements: formData.photograph_requirements,
            consent_form_link: formData.consent_form_link,
            consent_form: formData.consent_form,
            request_image_old: formData.request_image_old,
            category_id: formData.category_id,
            categories: formData.categories,
            title: formData.title,
            description: formData.description,

            date: formData.date,
            start_time: formData.request_type === 2 ? startTime : "",
            use_profile_location: formData.ischecked_online === true ? 2 : formData.use_profile_location,
            postcode: formData.postcode,

            postcodeValue: formData.postcode,
            country: formData.country,
            city: formData.city,
            latitude: formData.latitude,
            longitude: formData.longitude,

            ginipig_count: formData.ginipig_count,
            request_timeslots: object_slots,
            location: formData.locationprofile,
            street_name: formData.street_name,
            request_image: feature_image,
            is_keep_treatment_on_profile: formData.is_keep_treatment_on_profile,
            treatment_sub_category: formData.treatment_sub_category,
            locationprofile: formData.locationprofile,
            redirectPathName: formData.redirectPathName,
            sub_category_id: formData.sub_category_id,
            selected_time_slots: formData.selected_time_slots,
            // categories: formData.categories,
            request_old_id: formData.requestId,
            flat: formData.flat_build_no
        }
        __apiCallRequest(formDataNew);
        // console.log(formDataNew)
        // let RedirectPathName = getLocalStorageData("RedirectPathName")
        // if (RedirectPathName == "join-practitioner") {
        //     // console.log("inside if")
        //     __apiCallAppointment(formDataNew);

        // } else {
        //     //  console.log("inside esle ", formDataNew)
        //     __apiCallRequest(formDataNew);
        // }


    }



    const __apiCallRequest = useCallback(async (formDataNew) => {
        try {
            let checkToken = "";

            let LocalDatatoken = getLocalStorageData("loginData")
            let registerData = getLocalStorageData("practitionersignupData")

            if (registerData != "" && registerData !== undefined && registerData !== null) {
                checkToken = registerData.token;
            }
            else if (LocalDatatoken !== "" && LocalDatatoken !== undefined && LocalDatatoken !== null) {
                checkToken = LocalDatatoken.token;
            }
            if (checkToken === '') {
                window.scrollTo(0, 0);
                history.push('/login');
            }
            let formDatarequest = {
                RequestData: formData,
                selected_time_slots: formData.selected_time_slots

            }
            setLocalStorageData("requestDataLocal", formDatarequest)


            await dispatch(createrequestAPI_Action(formDataNew, checkToken));

            // window.scrollTo(0, 0);
            // history.push('/appointment-confirmation')

        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    });

    // console.log(createrequest)


    if (createrequest.isSuccess === true) {
        dispatch(resetcreaterequestAPI_Action());
        window.scrollTo(0, 0);

         let RedirectPathName = getLocalStorageData("RedirectPathName")
        if (RedirectPathName == "join-practitioner") {
            // console.log("inside if")
            history.push('/appointment-practitoner-confirmation')

        } else {
            history.push({
                pathname: '/appointment-confirmation',
                fromreviewreq: true
            });
        }
     

    }


    if (createrequest.createrequestdata && createrequest.createrequestdata.status_code === 401) {
        dispatch(resetcreaterequestAPI_Action());
        clearLocalStorage("requestDataLocal")
        clearLocalStorage("RedirectPathName")
        clearLocalStorage("selectedtreatmentId")
        clearLocalStorage("pathnamereviewedit")
        clearLocalStorage("treatmentcatId")
        window.scrollTo(0, 0);
        history.push('/login')
    }

    const __apiCallAppointment = useCallback(async (formDataNew) => {
        try {
            let formDatarequest = {
                RequestData: formDataNew,
                selected_time_slots: formData.selected_time_slots

            }
            setLocalStorageData("requestDataLocal", formDatarequest)
            // await dispatch(createAppointmentData_Action(formDataNew, formData.selected_time_slots));
            //  await dispatch(createAppointmentData_Action(formDataNew, formData.selected_time_slots));

            window.scrollTo(0, 0);
            history.push('/practitioner-info')

        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    });


    const __redirectToTreatmentEdit = useCallback(async () => {
        try {
            let formDatarequest = {
                RequestData: formData,
                selected_time_slots: formData.selected_time_slots

            }
            setLocalStorageData("requestDataLocal", formDatarequest)
            //await dispatch(createAppointmentData_Action(formData, formData.selected_time_slots));
            window.scrollTo(0, 0);
            history.push('/treatment-review-edit/' + formData.selectedTreatmentId)


        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    });

    const backBtnPressed = () => {
        setLocalStorageData("RedirectPathName", "editRequest")
        history.goBack();
    }
    const __redirectToRequestEdit = useCallback(async () => {
        try {
            let formDatarequest = {
                RequestData: formData,
                selected_time_slots: formData.selected_time_slots

            }
            setLocalStorageData("requestDataLocal", formDatarequest)
            // await dispatch(createAppointmentData_Action(formData, formData.selected_time_slots));
            window.scrollTo(0, 0);

            history.push({
                pathname: '/appointment-info',
                redirectPath: 'appointment-review',

            });

        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    });

    const __redirectToRequirmentsEdit = useCallback(async () => {
        try {
            let formDatarequest = {
                RequestData: formData,
                selected_time_slots: formData.selected_time_slots

            }
            setLocalStorageData("requestDataLocal", formDatarequest)

            // await dispatch(createAppointmentData_Action(formData, formData.selected_time_slots));
            window.scrollTo(0, 0);

            history.push({
                pathname: '/ginipig-requirement',
                redirectPath: 'appointment-review',

            });

        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    });

    const __redirectToAppointmentAvailabilityEdit = useCallback(async () => {
        try {

            await dispatch(createAppointmentData_Action(formData));
            window.scrollTo(0, 0);
            setLocalStorageData("RedirectPathName", "editRequest")
            history.push({
                pathname: '/appointment-availability',
                redirectPath: 'appointment-review',

            });



        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    });




    return (
        <>

            <section className="bgcolor-gray common-section appoint_review_pg pb80">
                <Container>
                    <Row>
                        <Col className="col-lg-11 m-auto">
                            <form action="post" >
                                <div className="navigationTop">
                                    <div className="heading-history">
                                        <div className="back-history">
                                            <button type="button" onClick={() => { backBtnPressed() }} className="back-btn" >
                                                {" "}
                                                <img src={LeftArrow} alt="arrow" />{" "}
                                            </button>
                                        </div>
                                        <div className="history-title">
                                            <h2> Review Your Appointment Information </h2>
                                        </div>
                                    </div>
                                </div>

                                <div className="from-wrp pb-32">
                                    <div className="review_appoint mt-40">
                                        <div className="title_set titile_box">
                                            <h3 className="heading">Treatment/Service Information</h3>
                                            <button className="edit_review_btn" onClick={(e) => __redirectToTreatmentEdit(e)}>Edit</button>
                                        </div>
                                        <div className="form-wrp mt-8">
                                            <div className="review_form">
                                                <div className="treatment_info_step">
                                                    <p className="steps-label mb-16"> Treatment Category </p>
                                                    <div className="treatment-catogory">

                                                        <div className="category-list-review">

                                                            <div className="category-list">
                                                                <TreatmentRadioButton
                                                                    CategoryTitle={formData.categories !== "" && formData.categories.name && formData.categories.name}
                                                                    id={formData.category_id && formData.category_id}
                                                                    activeclassformdata={formData.category_id && formData.category_id}

                                                                />

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="treatmentform fromProfile  mt-24">
                                                    <div className="profilefield">
                                                        <div className="showwithlabel">
                                                            <label className="profile-label">Treatment Title</label>

                                                        </div>
                                                        <input
                                                            type="text"
                                                            disabled={true}
                                                            value={formData.title}
                                                        />
                                                    </div>
                                                    <div className="profilefield">
                                                        <div className="showwithlabel">
                                                            <label className="profile-label">Treatment Sub-Categories</label>
                                                        </div>
                                                        <div className="category-checkbox-grp">

                                                            {

                                                                formData.categories != "" && formData.categories.sub_categories.map((sub_item, sub_index) => {



                                                                    return (

                                                                        <div className={formData.sub_category_id.indexOf(sub_item.id) !== -1 ? "checkbox-item" : "display-none"} >
                                                                            <div className="checkbox-button" >
                                                                                <label className="checkox-label yes-checked">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        className="checkbox-btn"
                                                                                        id={sub_item.id}
                                                                                        name={sub_item.id}
                                                                                        disabled={true}
                                                                                        value={sub_item.id}
                                                                                        checked={true} />
                                                                                    <span className="button-text">{sub_item.name}</span>
                                                                                </label>

                                                                            </div>
                                                                        </div>



                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="profilefield mb-0">
                                                        <div className="showwithlabel">
                                                            <label className="profile-label"> Treatment Description </label>
                                                        </div>
                                                        <div className="input-addicon addicontextarea" >
                                                            <textarea
                                                                disabled={true}
                                                                value={formData.description}
                                                            />
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* end 1st */}
                                    <div className="review_appoint mt-40">
                                        <div className="title_set titile_box">
                                            <h3 className="heading"> Appointment Information </h3>
                                            <button className="edit_review_btn" onClick={(e) => __redirectToRequestEdit(e)}>Edit</button>
                                        </div>
                                        <div className="form-wrp mt-8">
                                            <div className="review_form fromProfile">
                                                <div className="heading-label">Duration of Appointment</div>
                                                <div className="inside_feild">
                                                    <div className="two-cols ">
                                                        <div className="profilefield setfeild">
                                                            <div className="showWithLabal">
                                                                <label className="heading-label"> Hours </label>
                                                            </div>
                                                            <input type="text"
                                                                value={formData.hrs}
                                                                disabled={true} />
                                                        </div>
                                                        <div className="profilefield setfeild">
                                                            <div className="showWithLabal">
                                                                <label className="heading-label"> Minutes </label>
                                                            </div>
                                                            <input type="text"
                                                                value={formData.minutes}
                                                                disabled={true} />
                                                        </div>
                                                    </div>

                                                    <div className="profilefield ">
                                                        <div className="showWithLabal1">
                                                            <label className="heading-label"> Reason why I require GiniPigs </label>
                                                        </div>
                                                        <input type="text"
                                                            value={formData.reason_require_ginipig}
                                                            disabled={true} />
                                                    </div>
                                                    <div className="profilefield ">
                                                        <div className="showWithLabal1">
                                                            <label className="heading-label"> My level of expertise for this treatment/service </label>
                                                        </div>
                                                        <input type="text"
                                                            value={formData.expertise_level}
                                                            disabled={true} />
                                                    </div>
                                                    <div className="heading-label">Treatment Price  (Must be at least 30% off RRP & a minimum list price of £7.50)</div>
                                                    <div className="two-cols ">
                                                        <div className="profilefield setfeild">
                                                            <div className="showWithLabal">
                                                                <label className="heading-label"> RRP </label>
                                                            </div>
                                                            <div className="input_box">
                                                                <span className="euro_sign"> £ </span>
                                                                <input type="text"
                                                                    value={formData.rrpprice}
                                                                    disabled={true} />
                                                            </div>
                                                        </div>
                                                        <div className="profilefield setfeild">
                                                            <div className="showWithLabal">
                                                                <label className="heading-label"> Your Price  </label>
                                                            </div>
                                                            <div className="input_box">
                                                                <span className="euro_sign"> £ </span>
                                                                <input type="text"
                                                                    value={formData.yourprice}
                                                                    disabled={true} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* <div className="uploaded-area">
                                                        <div className="four-grid">

                                                            {formData.file1 ?
                                                                <div className="uploaded_img">
                                                                    <img src={formData.file1} />
                                                                </div> :
                                                                formData.file3 || (formData.file2 && formData.file4) ? null :
                                                                    <div className="uploaded_img">
                                                                        <img src={formData.categories !== "" && formData.categories.name === 'Hair' ? Hair : formData.categories !== "" && formData.categories.name === 'Beauty' ? Beauty : formData.categories !== "" && formData.categories.name === 'Aesthetics' ? Aesthetic : wellness} />
                                                                    </div>

                                                            }
                                                            {formData.file2 ?
                                                                <div className="uploaded_img">
                                                                    <img src={formData.file2} />
                                                                </div> :
                                                                formData.file4 || (formData.file1 && formData.file3) ? null :
                                                                    <div className="uploaded_img">
                                                                        <img src={formData.categories !== "" && formData.categories.name === 'Hair' ? HairTwo : formData.categories !== "" && formData.categories.name === 'Beauty' ? BeautyTwo : formData.categories !== "" && formData.categories.name === 'Aesthetics' ? AestheticTwo : wellnessTwo} />
                                                                    </div>

                                                            }
                                                            {formData.file3 ?
                                                                <div className="uploaded_img">
                                                                    <img src={formData.file3} />
                                                                </div> : null

                                                            }
                                                            {formData.file4 ?
                                                                <div className="uploaded_img">
                                                                    <img src={formData.file4} />
                                                                </div> : null

                                                            }

                                                        </div>
                                                    </div> */}

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className=" form-wrp mt-30">
                                        <div className="inside-photoupload">
                                            <div className="two-cols-image">
                                                
                                                {formData.file1 ?
                                                    <div className="img_upload_area-two">
                                                    <div className="upload-area">
                                                        <img  className="form-img1" src={formData.file1} />
                                                    </div>
                                                    </div>

                                                     :
                                                    formData.file3 || (formData.file2 && formData.file4) ? null :
                                                      <div className="img_upload_area-two">
                                                    <div className="upload-area">
                                                            <img src={formData.categories !== "" && formData.categories.name === 'Hair' ? Hair : formData.categories !== "" && formData.categories.name === 'Beauty' ? Beauty : formData.categories !== "" && formData.categories.name === 'Aesthetics' ? Aesthetic : wellness} />
                                                        </div>
                                                        </div>


                                                }
                                                {formData.file2 ?
                                                
                                                <div className="img_upload_area-two">
                                                <div className="upload-area">
                                                        <img className="form-img1" src={formData.file2} />
                                                    </div> </div>:
                                                    formData.file4 || (formData.file1 && formData.file3) ? null :
                                                    <div className="img_upload_area-two">
                                                    <div className="upload-area">
                                                            <img src={formData.categories !== "" && formData.categories.name === 'Hair' ? HairTwo : formData.categories !== "" && formData.categories.name === 'Beauty' ? BeautyTwo : formData.categories !== "" && formData.categories.name === 'Aesthetics' ? AestheticTwo : wellnessTwo} />
                                                        </div>
                                                        </div>

                                                }
                                                {/* {formData.file3 ?
                                                    <div className="uploaded_img">
                                                        <img src={formData.file3} />
                                                    </div> : null

                                                }
                                                {formData.file4 ?
                                                    <div className="uploaded_img">
                                                        <img src={formData.file4} />
                                                    </div> : null

                                                } */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* end 2nd */}
                                    <div className="review_appoint fromProfile mt-40">
                                        <div className="title_set titile_box">
                                            <h3 className="heading"> GiniPig Requirements </h3>
                                            <button className="edit_review_btn" onClick={(e) => __redirectToRequirmentsEdit(e)}>Edit</button>
                                        </div>

                                        <div className="form-wrp mt-8">
                                            {formData.attributes_required === false && formData.photograph_required == false && formData.is_consentform_required === false && formData.patchtest_required === false &&

                                                <div className="checkbox_req mt-16">
                                                    <div className="checkbox-group">
                                                        <label>
                                                            <span className="checkbox-decs">
                                                                There are no GiniPig requirements for this appointment
                                                                {/* No Requirements Available. */}
                                                                {/* Stand out by using your own images (minimum 2) rather than having our bank images automatically selected for you */}
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                            }

                                            {
                                                formData.attributes_required === true &&
                                                <div className="req_box">
                                                    <div className="checkbox_req">
                                                        <div className="checkbox-group">
                                                            <label htmlFor="check1">
                                                                <div className="checkinput">
                                                                    <input
                                                                        className="tnc"
                                                                        type="checkbox"
                                                                        disabled={true}
                                                                        id="check1"
                                                                        checked={formData.attributes_required}
                                                                    />
                                                                </div>
                                                                <span className="checkbox-decs">
                                                                    I  require particular attributes from the Ginipig for this treatment?
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div className="profilefield">
                                                        <div className="input-addicon addicontextarea">
                                                            <textarea
                                                                value={formData.model_requirements !== "" ? formData.model_requirements : ""}
                                                                disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>


                                            }
                                            {formData.photograph_required == true &&
                                                <div className="req_box">
                                                    <div className="checkbox_req">
                                                        <div className="checkbox-group">
                                                            <label htmlFor="check2">
                                                                <div className="checkinput">
                                                                    <input
                                                                        className="tnc"
                                                                        type="checkbox"
                                                                        id="check2"
                                                                        checked={formData.photograph_required}
                                                                    />
                                                                </div>
                                                                <span className="checkbox-decs">I  require the Ginipig to supply images in order to select they suitable for this treatment
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div className="profilefield ">
                                                        <div className="input-addicon addicontextarea">
                                                            <textarea
                                                                value={formData.photograph_requirements !== "" ? formData.photograph_requirements : ""}
                                                                disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>


                                            }
                                            {
                                                formData.is_consentform_required === true &&
                                                <div className="consent_form-box">
                                                    <p className="consent-label">Treatment Form</p>
                                                    <button className="consent_btn">{formData.consentformName} </button>
                                                </div>
                                            }

                                            {
                                                formData.patchtest_required === true &&
                                                <div className="checkbox_req mt-16">
                                                    <div className="checkbox-group">
                                                        <label htmlFor="check3">
                                                            <div className="checkinput">
                                                                <input
                                                                    className="tnc"
                                                                    type="checkbox"
                                                                    id="check3"

                                                                    checked={formData.patchtest_required}
                                                                />
                                                            </div>
                                                            <span className="checkbox-decs">
                                                                I require the Ginipig to complete a patch test prior to this treatment
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                    {/* end 3rd */}



                                    <div className="review_appoint fromProfile mt-40">
                                        <div className="title_set titile_box">
                                            <h3 className="heading"> Appointment Information </h3>
                                            <button className="edit_review_btn" onClick={(e) => __redirectToAppointmentAvailabilityEdit(e)}>Edit</button>
                                        </div>

                                        <div className="set_box mt-16">
                                            <h3 className="sub_heading">Availability</h3>

                                            {
                                                formData.request_type === 1 && formData.selectedTimeArray.length !== 0 && formData.selectedTimeArray.map((item, index) => {
                                                    return (
                                                        <div className="form-wrp mt-8">
                                                            <div className="inside_box_1">
                                                                <div className="time-box">
                                                                    <div className="appointment_date">
                                                                        <p className="dates">{item.date}</p>
                                                                    </div>
                                                                    <div className="appointment_slot">
                                                                        <div className={item.selected_slots_morning && item.selected_slots_morning.length > 0 ? "day_slot mb-10" : "day_slot mb-10 disable_opacity"}>
                                                                            <div className="appoint_slots">
                                                                                <span className="morning_icon icon_slots slot_heading">Morning</span>
                                                                            </div>
                                                                            <div className="appointment_times">
                                                                                <div className="timeslot">
                                                                                    {
                                                                                        (item.selected_slots_morning && item.selected_slots_morning.length > 0) && item.selected_slots_morning.map((sub_item, sub_index) => {
                                                                                            return (
                                                                                                <span>{sub_item}</span>
                                                                                            )

                                                                                        })
                                                                                    }
                                                                                    <div className={item.selected_slots_morning && item.selected_slots_morning.length > 0 ? "noAvailable_box slot-show" : "noAvailable_box"}>
                                                                                        <span className="noavailaibel_text">No availability</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className={item.selected_slots_afternoon && item.selected_slots_afternoon.length > 0 ? "day_slot mb-10" : "day_slot mb-10 disable_opacity"}>
                                                                            <div className="appoint_slots">
                                                                                <span className="afternoon_icon icon_slots slot_heading">Afternoon</span>
                                                                            </div>

                                                                            <div className="appointment_times">
                                                                                <div className="timeslot">
                                                                                    {
                                                                                        (item.selected_slots_afternoon && item.selected_slots_afternoon.length > 0) && item.selected_slots_afternoon.map((sub_item, sub_index) => {
                                                                                            return (
                                                                                                <span>{sub_item}</span>
                                                                                            )

                                                                                        })
                                                                                    }
                                                                                    <div className={item.selected_slots_afternoon && item.selected_slots_afternoon.length > 0 ? "noAvailable_box slot-show" : "noAvailable_box"}>
                                                                                        <span className="noavailaibel_text">No availability</span>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className={item.selected_slots_evening && item.selected_slots_evening.length > 0 ? "day_slot mb-10" : "day_slot mb-10 disable_opacity"}>
                                                                            <div className="appoint_slots">
                                                                                <span className="evening_icon icon_slots slot_heading">Evening</span>
                                                                            </div>
                                                                            <div className="appointment_times">
                                                                                <div className="timeslot">
                                                                                    {
                                                                                        (item.selected_slots_evening && item.selected_slots_evening.length > 0) && item.selected_slots_evening.map((sub_item, sub_index) => {
                                                                                            return (
                                                                                                <span>{sub_item}</span>
                                                                                            )

                                                                                        })
                                                                                    }
                                                                                    <div className={item.selected_slots_evening && item.selected_slots_evening.length > 0 ? "noAvailable_box slot-show" : "noAvailable_box"}>
                                                                                        <span className="noavailaibel_text">No availability</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    )
                                                })
                                            }


                                            {formData.request_type === 2 &&
                                                <div className="form-wrp mt-8">

                                                    <div className="inside_box_1">
                                                        <div className="time-box">
                                                            <div className="appointment_date">
                                                                <p className="dates">{formData.date}</p>
                                                            </div>
                                                            <div className="appointment_slot">
                                                                <div className="day_slot mb-10">
                                                                    <div className="appoint_slots">
                                                                        <span className="morning_icon icon_slots slot_heading">Morning</span>
                                                                    </div>
                                                                    <div className="appointment_times">
                                                                        <div className="timeslot">
                                                                            {formData.start_time_hrs < 12 &&

                                                                                <span>{formData.start_time_hrs}:{formData.start_time_minutes}</span>

                                                                            }


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="day_slot mb-10">
                                                                    <div className="appoint_slots">
                                                                        <span className="afternoon_icon icon_slots slot_heading">Afternoon</span>
                                                                    </div>
                                                                    <div className="appointment_times">
                                                                        <div className="timeslot">
                                                                            {formData.start_time_hrs >= 12 && formData.start_time_hrs < 17 &&

                                                                                <span>{formData.start_time_hrs}:{formData.start_time_minutes}</span>

                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="day_slot mb-10">
                                                                    <div className="appoint_slots">
                                                                        <span className="evening_icon icon_slots slot_heading">Evening</span>
                                                                    </div>
                                                                    <div className="appointment_times">
                                                                        <div className="timeslot">
                                                                            {formData.start_time_hrs >= 17 &&

                                                                                <span>{formData.start_time_hrs}:{formData.start_time_minutes}</span>

                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>


                                                </div>

                                            }
                                        </div>



                                        <div className="set_box mt-16">
                                            <h3 className="sub_heading">Location</h3>
                                            {createrequest.createrequestdata &&
                                                createrequest.createrequestdata.hasOwnProperty(
                                                    "message"
                                                ) ? (
                                                <p className="error">
                                                    {createrequest.createrequestdata.hasOwnProperty(
                                                        "message"
                                                    ) ? (
                                                        <p style={{ color: "red" }}>
                                                            Please Enter Valid Postcode data{" "}
                                                        </p>
                                                    ) : null}
                                                </p>
                                            ) : null}
                                            <div className="two-cols">
                                                <div className="address_show size50">
                                                    <div className="form-wrp">
                                                        {/* <p className="address">
                                                            36 Silversmith Place <br />
                                                        Fallfield Lane <br />
                                                        St Johns Wood <br />
                                                        London <br />
                                                        NW6 7GH
                                                    </p> */}
                                                        {
                                                            formData.ischecked_online ?
                                                                <p className="address">Online only </p> : null
                                                        }

                                                        {
                                                            formData.postcode !== "" && formData.use_profile_location == 0 && !formData.ischecked_online ? (
                                                                <p className="address">
                                                                    {formData.flat_build_no}{', '}
                                                                    {formData.street_name} <br />
                                                                    {formData.town_city} <br />
                                                                    {formatPostcode(formData.postcode)}
                                                                </p>
                                                            ) : null
                                                        }

                                                        {
                                                            formData.postcode !== "" && formData.use_profile_location == 1 && !formData.ischecked_online ? (
                                                                <p className="address">
                                                                    {formData.flat_build_no}{', '}
                                                                    {formData.locationprofile} <br />
                                                                    {formData.town_city} <br />
                                                                    {formatPostcode(formData.postcode)}
                                                                </p>
                                                            ) : null
                                                        }
                                                    </div>
                                                </div>
                                                <div className="blank size50"></div>
                                            </div>
                                        </div>

                                        <div className="set_box mt-16">
                                            <h3 className="sub_heading">Quantity of GiniPigs you require</h3>
                                            <div className="two-cols">
                                                <div className="gini_req size50">
                                                    <div className="box_wrpfield">
                                                        <p className="no_request">{formData.ginipig_count}</p>
                                                    </div>
                                                </div>
                                                <div className="blank size50"></div>
                                            </div>
                                        </div>


                                        <div className="treatment_info_step mt-5">
                                            <p className="steps-label" style={{fontWeight:'600'}}>By clicking List Now, you are abiding by our T's & C's & confirming that this treatment or service does not require a general anaesthetic, is not dental related and that you have the required insurance/license.</p>
                                        </div>
                                        
                                        <div className="btn-align pd32 ">

                                            {
                                                createrequest.isFetching
                                                    ? <LargeButton

                                                        disabled={true}
                                                        ButtonText="Loading.."
                                                    /> :
                                                    <LargeButton
                                                        ButtonType="button"
                                                        ButtonText="List Now"
                                                        nextButtonClick={nextButtonClick}
                                                    />
                                            }
                                        </div>

                                    </div>
                                    {/* end 4th */}

                                </div>
                            </form>
                        </Col>
                    </Row>
                </Container>
            </section>



        </>
    );
}

export default AppointmentReview;