import React, { useCallback, useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from 'react-bootstrap';
import { LeftArrow, iconClose, iconPhoto } from '../../config/images';
import ImageUpload from '../../components/ImageUpload';
import LargeButton from '../../components/LargeButton';
import AppointmentCalender from '../../components/AppointmentCalender';
import { requestDetailsAPI_Action, resetrequestdetailsAPI_Action } from '../../stores/actions';
import { getLocalStorageData, setLocalStorageData, clearLocalStorage } from '../../utils/storage';
import Modal from 'react-modal'
import LoadingSpinner from "../../components/UIComponent/LoadingSpinner"

const customStyles = {
    overlay: {
        background: "#dee2e663", zIndex: 999999
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        border: "none",
        background: "none"
    }
};
const TreatmentAvailability = (props) => {

    let history = useHistory();

    const loginUserData = useSelector(state => state.login);
    const dispatch = useDispatch();
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [error, setshowerror] = React.useState("");
    const [istreatmentalreadyBooked, setisTreatmentalreadyBooked] = React.useState(false);
    const requestdetails = useSelector(state => state.requestdetails);
    const param_data = props.location.state.paramData;
    const selectedate = props.location.state.selectedData;
    const treatmentData = props.location.state.treatmentData;

    useEffect(() => {
        if (props.location.state.fromlogin) {
            openModal()
        }
        __apiCall()
    }, []);

    const __apiCall = useCallback(async () => {
        const loginToken = loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token : "" : "";
        const ginpig_id = loginUserData.logindata ? loginUserData.logindata.data && loginUserData.logindata.data.user.type === "ginipig" ? loginUserData.logindata.data.user.uuid : 0 : 0;
        try {

            await dispatch(requestDetailsAPI_Action(props.location.state.treatmentData.uuid, loginToken, ginpig_id));
        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    });

    if (requestdetails.requestdetailsdata && requestdetails.requestdetailsdata != null && requestdetails.requestdetailsdata.status_code == 200) {
        if (requestdetails.requestdetailsdata && requestdetails.requestdetailsdata !== null && requestdetails.requestdetailsdata.data.request.booking_count !== 0) {
            setisTreatmentalreadyBooked(true)
        }
        if (props.location.state.fromlogin) {
            dispatch(resetrequestdetailsAPI_Action())
            setIsOpen(false)
        }

    }
    const clearError = (isclear) => {
        if (isclear) {
            setshowerror("")
        } else {
            if (istreatmentalreadyBooked) {
                setshowerror("Please Select a Time Slot")
            }

        }
    }
    const __nextBooking = () => {
        var temp = getLocalStorageData("book_open_request_time");
        if (temp !== undefined) {
            setshowerror("")
            setIsOpen(false)
            if (treatmentData.photograph_required === 1) {
                window.scrollTo(0, 0);
                history.push('/photo-requirement/' + param_data)
            } else {
                window.scrollTo(0, 0);
                history.push('/view-booking/' + props.location.state.treatmentData.uuid)
                //history.push('/payment-option')
            }
        } else {
            setIsOpen(false)
            setshowerror("Please Select a Time Slot")
        }

    }
    const openModal = () => {
        //e.preventDefault();
        setIsOpen(true);
    }

    const closeModal = () => {
        setIsOpen(false);
    }

    // console.log("treatmentData.request_timeslots.data",treatmentData.request_timeslots.data)


    return (
        <>
            <section className="bgcolor-gray common-section open_req_treatment_Availability_pg pb80">
                <Container>
                    <Row>
                        <Col className="col-lg-11 m-auto">
                            <div className="navigationTop">
                                <div className="heading-history">
                                    <div className="back-history">
                                        <button type="button" onClick={() => {
                                            __apiCall()
                                            let RedirectPathName = getLocalStorageData("pathnamegini")
                                            if (RedirectPathName === "fromginipigProfile") {
                                                history.goBack()
                                            } else {
                                                history.push('/treatment-detail-for-open-request/' + param_data);
                                            }
                                         
                                            //</div>history.goBack()
                                        }} className="back-btn" >
                                            {" "}
                                            <img src={LeftArrow} alt="arrow" />{" "}
                                        </button>
                                    </div>
                                    <div className="history-title">
                                        <h2> Treatment availability  </h2>
                                    </div>
                                </div>
                            </div>

                            <div className="requirement_wrp">
                                <div className="req_img_box mt-24">
                                    <div className="categorylist-box">
                                        <div className="list_name">{treatmentData.treatment.data.title} </div>
                                        <div className="category_price">
                                            <span className="price_strik og_price"> <span> £</span>{treatmentData.rrp_price.toFixed(2)}</span>
                                            <span className="price_discounted"> <span> £</span>{treatmentData.your_price.toFixed(2)}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="select_availbility_box mt-24 mb-32">
                                   <p>Select ONE date with as many time options as you would like. Check ahead on the calendar, we don't want you missing a fab opportunity</p>
                                </div>

                                <div className={
                                    requestdetails.requestdetailsdata &&
                                        requestdetails.requestdetailsdata !== null &&
                                        requestdetails.requestdetailsdata.data.request.booking_count !== 0 ? "treatment_availability_calender mb-40 Disable Dis_all" : "treatment_availability_calender mb-40"}>


                                    <AppointmentCalender
                                        calendarData={treatmentData.request_timeslots.data}
                                        selectedData={selectedate}
                                        redirectfromopen={false}
                                        clearError={clearError}


                                    />
                                </div>
                                {error &&
                                    error != "" ? (
                                    <p className="error">
                                        <p style={{ color: "red" }}>
                                            {error}{" "}
                                        </p>
                                    </p>
                                ) : null}


                                <div className="btn-align pd32 pt-110">
                                    <LargeButton
                                        ButtonType="button"
                                        ButtonText={istreatmentalreadyBooked ? "Already Booked" : "Next"}
                                        disabled={istreatmentalreadyBooked ? true : false}
                                        nextButtonClick={__nextBooking}
                                    />
                                </div>

                            </div>


                        </Col>
                    </Row>
                    <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                        to
                        style={customStyles}
                        shouldCloseOnOverlayClick={false}
                        contentLabel="Example Modal"
                    >
                        <div>
                            <LoadingSpinner />
                        </div>

                    </Modal>
                </Container>
            </section>
        </>
    );
}

export default TreatmentAvailability;