import React, { useCallback, useState, useEffect } from 'react';
import initReactFastclick from 'react-fastclick';
import { Header, Footer } from './components';
import { educationDisablesAPI_Action} from '../src/stores/actions'
import { isIOS, isAndroid, isChrome, isSafari, isFirefox, isMobile, isTablet, isMobileSafari, isIOS13 } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { getLocalStorageData, setLocalStorageData, clearLocalStorage } from './utils/storage';
import { Helmet } from "react-helmet";
import { GOOGLE_MAPS_API_KEY } from './config/ApiConstants';

import './assets/css/scss/bootstrap.scss';
import './assets/css/scss/ginipig-theme.scss';

const App = props => {
  // initialization of fast click
  const educationdisable = useSelector(state => state.educationdisable);
  const dispatch = useDispatch();
  let iseducationPage = getLocalStorageData("iseducationPage")
  const [educationpageenable, setEducationpageenable] = useState(iseducationPage?iseducationPage:false);

  const MapsURL = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places&callback=initMap`;

  initReactFastclick();

  useEffect(() => {
    // const intervalId = setInterval(() => {  //assign interval to a variable to clear it.
    //  calleducationpageenableapi()
    // }, 60000)
    calleducationpageenableapi();
    // return () => clearInterval(intervalId); //This is important
   
  }, [useState])

  useEffect(() => {
    if(educationdisable.educationDisablesdata!==null && educationdisable.educationDisablesdata.data!==undefined){
      if(educationdisable.educationDisablesdata.data.education_status==1){
        setEducationpageenable(true)
        setLocalStorageData("iseducationPage", true)
      }else{
        setEducationpageenable(false)
        setLocalStorageData("iseducationPage", false)
      }

    }
   
  }, [educationdisable.educationDisablesdata])

  const calleducationpageenableapi=async()=>{
   await dispatch(educationDisablesAPI_Action());
  }


  let baseClass = 'app'

  if (isIOS) {
    baseClass = baseClass + ' isiOS'
  }
  if (isAndroid) {
    baseClass = baseClass + ' isAndroid'
  }
  if (isChrome) {
    baseClass = baseClass + ' isChrome'
  }
  if (isSafari) {
    baseClass = baseClass + ' isSafari'
  }
  if (isFirefox) {
    baseClass = baseClass + ' isFirefox'
  }


  if (isMobile) {
    baseClass = baseClass + ' isMobile'
  }

  if (isTablet) {
    baseClass = baseClass + ' isTablet'
  }

  if (isMobileSafari) {
    baseClass = baseClass + ' isMobileSafari'
  }

  if (isIOS13) {
    baseClass = baseClass + ' isIOS13'
  }
  
  return (
    <div className={baseClass} id="app">
      <Helmet>
        {/* <meta name="description" content="Connecting Models to Practicing Professionals in Hair, Hair, Aesthetics & Wellness Industries. Always a minimum of 30% off, fill your diary & treat" />
        <meta name="keywords" content="Hair, Hair, Aesthetics, Wellness" /> */}
        <script async src={MapsURL}></script>
      </Helmet>
      <Header />
      {props.children}
      <Footer educationpageenable={educationpageenable}/>
    </div>
  );
};

export default App;
