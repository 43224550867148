import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import moment from 'moment';
import { setData } from '../utils/storage';
import { getLocalStorageData, setLocalStorageData, clearLocalStorage } from '../utils/storage';

const AppointmentCalender = (props) => {
    //init state
    const { data, Setdatas } = useState(
        Object.freeze({
            date: new Date(),
        })
    )

    const [selectedDate, setSelectedDate] = useState("");
    const [activeDate, setactiveDate] = useState(new Date());
    const [isDefault, setIsDefault] = useState(false);
    const [defaultDate, setDefaultDate] = useState("");
    const testData = props.calendarData;
    const selectedDates = props.selectedData;
    const [formData, updateFormData] = useState(Object.freeze({

        selected_slots_morning: [],
        selected_slots_afternoon: [],
        selected_slots_evening: [],
        selected_date_time_array: [],
        request_type: 1,
        whhats_available_pagination: 1,
        treatment_title: "Full Head of Highlights",
        selected_date_slots: [],
        available_date_slots: [],
        morningSlotsArray: ["7:00", "7:30", "8:00", "8:30", "9:00", "9:30", "10:00", "10:30", "11:00", "11:30"],
        afternoonSlotsArray: ["12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30"],
        eveningSlotsArray: ["17:00", "17:30", "18:00", "18:30", "19:00", "19:30", "20:00", "20:30", "21:00", "21:30"],

    }));


    useEffect(() => {
        let mngSlots = [];
        let eveSlots = [];
        let aftrSlots = [];
        let slctmngSlots = [];
        let slcteveSlots = [];
        let slctaftrSlots = [];
        let dteTimearry = [];

        // var datemax = new Date();
        // datemax.setMonth(datemax.getMonth() + 1);
        // setactiveDate(datemax)

        let dateArray = []
        let caldates = testData.length !== 0 && testData.map(i => {

            let dates = moment(i.date_time).format("DD-MM-yy");
            let timeslots = JSON.parse(i.timeslots);
            if (selectedDates) {

                let d = dates.substring(0, 2)
                let n = dates.substring(3, 5)
                let y = dates.substring(6, 10)
                let displayDay = y + "-" + n + "-" + d

                var datemax = new Date(displayDay);
                let currentdate = new Date()

                let date1 = moment(datemax).format("DD-MM-yy")
                let date2 = moment(currentdate).format("DD-MM-yy")

                if (selectedDates.date === dates) {
                    if (!isDefault) {
                      
                        let tempDt = selectedDates.date.split("-");
                        let FrmtDt = tempDt[2] + "-" + tempDt[1] + "-" + tempDt[0];

                        setDefaultDate(FrmtDt);
                        setIsDefault(true);
                        mngSlots = timeslots.morning;
                        eveSlots = timeslots.evening;
                        aftrSlots = timeslots.afternoon;
                        slctmngSlots = selectedDates.timeslots.morning;
                        slctaftrSlots = selectedDates.timeslots.afternoon;
                        slcteveSlots = selectedDates.timeslots.evening;
                    }

                    let nwObj = {
                        date: dates,
                        request_type: 1,
                        morningSlotsArray: timeslots.morning,
                        afternoonSlotsArray: timeslots.afternoon,
                        eveningSlotsArray: timeslots.evening,
                        selected_slots_morning: selectedDates.timeslots.morning,
                        selected_slots_evening: selectedDates.timeslots.evening,
                        selected_slots_afternoon: selectedDates.timeslots.afternoon
                    }

                    if (datemax > currentdate) {
                        if (timeslots.morning.length == 0 && timeslots.afternoon == 0 && timeslots.evening == 0) {
                            return
                        } else {
                        dateArray.push(datemax)
                        }
                    } else if (date1 === date2) {
                        if (timeslots.morning.length == 0 && timeslots.afternoon == 0 && timeslots.evening == 0) {
                            return
                        } else {
                        dateArray.push(datemax)
                        }
                    }
                    if (datemax > currentdate) {
                        if (timeslots.morning.length == 0 && timeslots.afternoon == 0 && timeslots.evening == 0) {
                            return
                        } else {
                        dteTimearry.push(nwObj);
                        }
                    } else if (date1 === date2) {
                        if (timeslots.morning.length == 0 && timeslots.afternoon == 0 && timeslots.evening == 0) {
                            return
                        } else {
                        dteTimearry.push(nwObj);
                        }
                    }

                    // dteTimearry.push(nwObj);
                } else {
                 
                    if (datemax > currentdate) {
                        if (timeslots.morning.length == 0 && timeslots.afternoon == 0 && timeslots.evening == 0) {
                            return
                        } else {
                        dateArray.push(datemax)
                        }
                    } else if (date1 === date2) {
                        if (timeslots.morning.length == 0 && timeslots.afternoon == 0 && timeslots.evening == 0) {
                            return
                        } else {
                        dateArray.push(datemax)
                        }
                    }

                    let nwObj = {
                        date: dates,
                        request_type: 1,
                        morningSlotsArray: timeslots.morning,
                        afternoonSlotsArray: timeslots.afternoon,
                        eveningSlotsArray: timeslots.evening,
                        selected_slots_morning: [],
                        selected_slots_evening: [],
                        selected_slots_afternoon: []
                    }
                    if (datemax > currentdate) {
                        if (timeslots.morning.length == 0 && timeslots.afternoon == 0 && timeslots.evening == 0) {
                            return
                        } else {
                        dteTimearry.push(nwObj);
                        }
                    } else if (date1 === date2) {
                        if (timeslots.morning.length == 0 && timeslots.afternoon == 0 && timeslots.evening == 0) {
                            return
                        } else {
                        dteTimearry.push(nwObj);
                        }
                    }

                    // dteTimearry.push(nwObj);
                }
            } else {

                let d = dates.substring(0, 2)
                let n = dates.substring(3, 5)
                let y = dates.substring(6, 10)
                let displayDay = y + "-" + n + "-" + d


                var datemax = new Date(displayDay);
                let currentdate = new Date()

                let date1 = moment(datemax).format("DD-MM-yy")
                let date2 = moment(currentdate).format("DD-MM-yy")


                if (datemax > currentdate) {
                    if (timeslots.morning.length == 0 && timeslots.afternoon == 0 && timeslots.evening == 0) {
                        return
                    } else {
                    dateArray.push(datemax)
                    }
                } else if (date1 === date2) {
                    if (timeslots.morning.length == 0 && timeslots.afternoon == 0 && timeslots.evening == 0) {
                        return
                    } else {
                    dateArray.push(datemax)
                    }
                }

                let nwObj = {
                    date: dates,
                    request_type: 1,
                    morningSlotsArray: timeslots.morning,
                    afternoonSlotsArray: timeslots.afternoon,
                    eveningSlotsArray: timeslots.evening,
                    selected_slots_morning: [],
                    selected_slots_evening: [],
                    selected_slots_afternoon: []
                }
               
                if (datemax > currentdate) {
                    if (timeslots.morning.length == 0 && timeslots.afternoon == 0 && timeslots.evening == 0) {
                        return
                    } else {
                        dteTimearry.push(nwObj);
                    }

                } else if (date1 === date2) {
                    if (timeslots.morning.length == 0 && timeslots.afternoon == 0 && timeslots.evening == 0) {
                        return
                    } else {
                        dteTimearry.push(nwObj);
                    }
                }

            }
            return dates;
        });

        setactiveDate(dateArray[0])


        let timeselected = getLocalStorageData("timeselected")
        //console.log("dteTimearry",dteTimearry)
        if (dteTimearry !== undefined && dteTimearry.length !== 0 && !timeselected === true) {
            //console.log("dteTimearry","if")
            setSelectedDate(dteTimearry[0].date);
            updateFormData({
                ...formData,
                morningSlotsArray: dteTimearry[0].morningSlotsArray,
                afternoonSlotsArray: dteTimearry[0].afternoonSlotsArray,
                eveningSlotsArray: dteTimearry[0].eveningSlotsArray,
                selected_slots_morning: slctmngSlots,
                selected_slots_afternoon: slctaftrSlots,
                selected_slots_evening: slcteveSlots,
                selected_date_time_array: dteTimearry,
                available_date_slots: caldates,
                request_type: 1
            })
        } else {
            //console.log("dteTimearry","else")
            setSelectedDate(caldates[0]);
            updateFormData({
                ...formData,
                morningSlotsArray: mngSlots,
                afternoonSlotsArray: aftrSlots,
                eveningSlotsArray: eveSlots,
                selected_slots_morning: slctmngSlots,
                selected_slots_afternoon: slctaftrSlots,
                selected_slots_evening: slcteveSlots,
                selected_date_time_array: dteTimearry,
                available_date_slots: caldates,
                request_type: 1
            })
        }






    }, [testData]);

    const onChange = (e) => {

        let date1 = moment(e).format("DD-MM-yy")
        let date2 = moment(e).format('YYYY-MM-DD')

        setSelectedDate(date1);
        setIsDefault(false)
        setactiveDate(e)
        // setLocalStorageData("timeselected",true)
        if (selectedDates) {
            if (selectedDates.date !== date1) {
                // clearLocalStorage("book_open_request_time");
            }
        }

        if (formData.available_date_slots !== false && formData.available_date_slots !== null) {
            if (formData.available_date_slots.indexOf(date1) !== -1) {
                formData.selected_date_time_array.map((item, index) => {
                    if (date1 === item.date) {
                        updateFormData({
                            ...formData,
                            morningSlotsArray: item.morningSlotsArray,
                            afternoonSlotsArray: item.afternoonSlotsArray,
                            eveningSlotsArray: item.eveningSlotsArray,
                            selected_slots_morning: item.selected_slots_morning,
                            selected_slots_afternoon: item.selected_slots_afternoon,
                            selected_slots_evening: item.selected_slots_evening,
                        })
                    } else {
                        item.selected_slots_morning = [];
                        item.selected_slots_afternoon = [];
                        item.selected_slots_evening = [];
                    }

                    // if (date1 === item.date) {

                    //     var tem_mor_pi = []; var tem_af_pi = []; var tem_eve_pi = [];

                    //     console.log("selected data :-", date1, ' ', item, ' ', date2);
                    //     var current_date = [new Date().getFullYear(), ("0" + (new Date().getMonth() + 1)).slice(-2), ("0" + new Date().getDate()).slice(-2)].join("-")
                    //     var current_time = new Date().getHours().toString()
                    //     var current_time_dec = new Date().getHours().toString() + '.' + new Date().getMinutes().toString()
                    //     console.log("current_date:- ", current_date, 'current_time:-', current_time, 'current_time_dec:-', parseFloat(current_time_dec))
                    //     if (date2 === current_date) {
                    //         var post_time;
                    //         var hour = parseInt(new Date().getHours()) + 2;
                    //         var minute = parseInt(new Date().getMinutes()) + 30;

                    //         console.log('hour:-', hour, 'minute:', minute)
                    //         if (minute >= 60) {
                    //             if (minute > 60) {
                    //                 hour = hour + 1;
                    //                 var remaining_min = minute - 60;
                    //                 post_time = parseFloat(hour + '.' + 0 + remaining_min).toFixed(2)
                    //             } else if (minute === 60) {
                    //                 hour = hour + 1;
                    //                 var remaining_min = minute - 60;
                    //                 post_time = parseFloat(hour + '.' + remaining_min).toFixed(2)
                    //             }
                    //         } else {
                    //             post_time = (parseFloat(hour + '.' + minute)).toFixed(2)
                    //         }

                    //         console.log('calculation:-', post_time, ' ');

                    //         if (current_time < 12 && current_time === 24) {

                    //             if (item.morningSlotsArray.length) {
                    //                 for (var i = 0; i < item.morningSlotsArray.length; i++) {
                    //                     var split_time = item.morningSlotsArray[i].split(':')
                    //                     split_time = parseFloat(split_time[0] + '.' + split_time[1]).toFixed(2)

                    //                     if (post_time > split_time) {
                    //                     } else {
                    //                         tem_mor_pi.push(item.morningSlotsArray[i])
                    //                     }
                    //                 }
                    //             } else {
                    //                 tem_mor_pi = [];
                    //             }

                    //             if (item.afternoonSlotsArray.length) {
                    //                 for (var i = 0; i < item.afternoonSlotsArray.length; i++) {
                    //                     var split_time = item.afternoonSlotsArray[i].split(':')
                    //                     split_time = parseFloat(split_time[0] + '.' + split_time[1]).toFixed(2)

                    //                     if (post_time > split_time) {
                    //                         console.log("inside true:-", item.afternoonSlotsArray)
                    //                     } else {
                    //                         tem_af_pi.push(item.afternoonSlotsArray[i])
                    //                     }
                    //                 }
                    //             } else {
                    //                 tem_af_pi = [];
                    //             }

                    //         } else if (current_time >= 12 && current_time < 17) {
                    //             item.morningSlotsArray = [];
                    //             if (item.afternoonSlotsArray.length) {
                    //                 for (var i = 0; i < item.afternoonSlotsArray.length; i++) {
                    //                     var split_time = item.afternoonSlotsArray[i].split(':')
                    //                     split_time = parseFloat(split_time[0] + '.' + split_time[1]).toFixed(2)

                    //                     if (post_time > split_time) {
                    //                     } else {
                    //                         tem_af_pi.push(item.afternoonSlotsArray[i])
                    //                     }
                    //                 }
                    //             } else {
                    //                 tem_af_pi = [];
                    //             }

                    //             if (item.eveningSlotsArray.length) {
                    //                 for (var i = 0; i < item.eveningSlotsArray.length; i++) {
                    //                     var split_time = item.eveningSlotsArray[i].split(':')
                    //                     split_time = parseFloat(split_time[0] + '.' + split_time[1]).toFixed(2)

                    //                     if (post_time > split_time) {
                    //                     } else {
                    //                         tem_eve_pi.push(item.eveningSlotsArray[i])
                    //                     }
                    //                 }
                    //             } else {
                    //                 tem_eve_pi = []
                    //             }

                    //         } else if (current_time >= 17 && current_time < 24) {
                    //             item.morningSlotsArray = [];
                    //             item.afternoonSlotsArray = [];
                    //             if (item.eveningSlotsArray.length) {
                    //                 for (var i = 0; i < item.eveningSlotsArray.length; i++) {
                    //                     var split_time = item.eveningSlotsArray[i].split(':')
                    //                     split_time = parseFloat(split_time[0] + '.' + split_time[1]).toFixed(2)

                    //                     if (post_time > split_time) {
                    //                     } else {
                    //                         tem_eve_pi.push(item.eveningSlotsArray[i])
                    //                     }
                    //                 }
                    //             } else {
                    //                 tem_eve_pi = []
                    //             }

                    //         }
                    //         console.log("temp_afternoon ", tem_af_pi)
                    //         updateFormData({
                    //             ...formData,
                    //             morningSlotsArray: tem_mor_pi,
                    //             afternoonSlotsArray: tem_af_pi,
                    //             eveningSlotsArray: tem_eve_pi,
                    //             selected_slots_morning: item.selected_slots_morning,
                    //             selected_slots_afternoon: item.selected_slots_afternoon,
                    //             selected_slots_evening: item.selected_slots_evening,
                    //         })
                    //     } else {
                    //         updateFormData({
                    //             ...formData,
                    //             morningSlotsArray: item.morningSlotsArray,
                    //             afternoonSlotsArray: item.afternoonSlotsArray,
                    //             eveningSlotsArray: item.eveningSlotsArray,
                    //             selected_slots_morning: item.selected_slots_morning,
                    //             selected_slots_afternoon: item.selected_slots_afternoon,
                    //             selected_slots_evening: item.selected_slots_evening,
                    //         })
                    //     }

                    // } else {
                    //     item.selected_slots_morning = [];
                    //     item.selected_slots_afternoon = [];
                    //     item.selected_slots_evening = [];
                    // }
                })
            } else {

                updateFormData({
                    ...formData,
                    morningSlotsArray: [],
                    afternoonSlotsArray: [],
                    eveningSlotsArray: [],

                })
            }

            // Setdata({...data,date:e});


        }


    }

    const onDateSelect = (e, type) => {
        // console.log("selected date:-", type, ' formData:- ', formData.selected_date_time_array);
        setLocalStorageData("timeselected", true)
        let selectedTime = [];
        if (type == "mng") {

            selectedTime = formData.selected_slots_morning;
            if (selectedTime.indexOf(e) !== -1) {
                selectedTime.splice(selectedTime.indexOf(e), 1);
            } else {

                selectedTime.push(e);
            }

            updateFormData({
                ...formData,
                selected_slots_morning: selectedTime
            })
        } else if (type == "eveng") {
            selectedTime = formData.selected_slots_evening;
            if (selectedTime.indexOf(e) !== -1) {
                selectedTime.splice(selectedTime.indexOf(e), 1);
                //console.log("already selected ")
            } else {
                //console.log("is selecting ");
                selectedTime.push(e);
            }
            updateFormData({
                ...formData,
                selected_slots_evening: selectedTime
            })
        } else if (type == "afternoon") {
            selectedTime = formData.selected_slots_afternoon;
            if (selectedTime.indexOf(e) !== -1) {
                selectedTime.splice(selectedTime.indexOf(e), 1);
                //console.log("already selected ")
            } else {
                //console.log("is selecting ");
                selectedTime.push(e);
            }
            updateFormData({
                ...formData,
                selected_slots_afternoon: selectedTime
            })
        }

        formData.selected_date_time_array.map((item, index) => {
            if (selectedDate === item.date) {
                item.selected_slots_morning = formData.selected_slots_morning;
                item.selected_slots_afternoon = formData.selected_slots_afternoon;
                item.selected_slots_evening = formData.selected_slots_evening;
                var dateSelected = {
                    date: selectedDate,
                    timeslots: {
                        morning: formData.selected_slots_morning,
                        afternoon: formData.selected_slots_afternoon,
                        evening: formData.selected_slots_evening,
                    }
                }
                if(formData.selected_slots_morning.length==0 &&formData.selected_slots_afternoon.length==0 && formData.selected_slots_evening.length==0 ){
                    clearLocalStorage("book_open_request_time")
                    if(props.clearError!==undefined){
                        props.clearError(false)
                    }
                   
                }else{
                    setLocalStorageData("book_open_request_time", JSON.stringify(dateSelected))
                    if(props.clearError!==undefined){
                        props.clearError(true)
                    }
                }
              
            }
        });

        // Setdata({...data,date:e});
    }

    const tileClassName = (activeStartDate, date, view) => {

        let date1 = moment(activeStartDate.date).format("DD-MM-yy")

        if (formData.available_date_slots) {
            if (formData.available_date_slots.length !== 0 && formData.available_date_slots.indexOf(date1) !== -1) {

                return " pratitioner_canlender_active"

            }
        }


    }

    return (

        <>
            <div className="calander_box1">
                <div className="pratitioner_canlender availibility_box">

                    <Calendar
                        onChange={(e) => onChange(e)}
                        minDate={new Date()}
                        value={isDefault ? new Date(defaultDate) : activeDate}
                        tileClassName={tileClassName}
                        formatShortWeekday={(locale, date) => ['S', 'M', 'T', 'W', 'T', 'F', 'S'][date.getDay()]}
                    />
                </div>
                <div className="calneder_slot_times for_ginipig">
                    <div className="day_slot_box">
                        <div className="radio-box">

                            <label className={formData.morningSlotsArray.length > 0 ? "radio_label applyflex" : "radio_label applyflex disable_row"} htmlFor="day_slot1">

                                <div className="appoint_slots ml-15 appoint_slot_day">
                                    <span className="morning_icon icon_slots slot_heading">Morning</span>
                                </div>
                                <div className={formData.morningSlotsArray.length > 0 ? "noAvailable_box slot-show" : "noAvailable_box"}>
                                    <span className="noavailaibel_text">No availability</span>
                                </div>

                            </label>
                            {formData.morningSlotsArray.length > 0 ?
                                <div className="slots_avail_box">
                                    <div className="inside_slot_grid">
                                        {
                                            formData.morningSlotsArray.map((item, index) => {
                                                return (
                                                    <div className="slot_div">
                                                        <button
                                                            disabled={props.disableSlots}
                                                            className={formData.selected_slots_morning.indexOf(item) === -1 ? "schedule_slot" : "schedule_slot active_slot"} onClick={() => onDateSelect(item, "mng")}>
                                                            <span className="fix-times">{item}</span>
                                                        </button>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div> : null}
                        </div>
                        <div className="radio-box">
                            <label className={formData.afternoonSlotsArray.length > 0 ? "radio_label applyflex" : "radio_label applyflex disable_row"} htmlFor="day_slot1">

                                <div className="appoint_slots ml-15 appoint_slot_day">
                                    <span className="afternoon_icon icon_slots slot_heading">Afternoon</span>
                                </div>

                                <div className={formData.afternoonSlotsArray.length > 0 ? "noAvailable_box slot-show" : "noAvailable_box"}>
                                    <span className="noavailaibel_text">No availability</span>
                                </div>

                            </label>
                            {formData.afternoonSlotsArray.length > 0 ?
                                <div className="slots_avail_box">
                                    <div className="inside_slot_grid">

                                        {
                                            formData.afternoonSlotsArray.map((item, index) => {
                                                return (
                                                    <div className="slot_div">
                                                        <button
                                                            disabled={props.disableSlots}
                                                            className={formData.selected_slots_afternoon.indexOf(item) === -1 ? "schedule_slot" : "schedule_slot active_slot"} onClick={() => onDateSelect(item, "afternoon")}>
                                                            <span className="fix-times">{item}</span>
                                                        </button>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div> : null}

                        </div>
                        <div className="radio-box">
                            <label className={formData.eveningSlotsArray.length > 0 ? "radio_label applyflex" : "radio_label applyflex disable_row"} htmlFor="day_slot1">

                                <div className="appoint_slots ml-15 appoint_slot_day">
                                    <span className="evening_icon icon_slots slot_heading">Evening</span>
                                </div>
                                <div className={formData.eveningSlotsArray.length > 0 ? "noAvailable_box slot-show" : "noAvailable_box"}>
                                    <span className="noavailaibel_text">No availability</span>
                                </div>

                            </label>
                            {formData.eveningSlotsArray.length > 0 ?
                                <div className="slots_avail_box">
                                    <div className="inside_slot_grid">
                                        {
                                            formData.eveningSlotsArray.map((item, index) => {
                                                return (
                                                    <div className="slot_div">
                                                        <button
                                                            disabled={props.disableSlots}
                                                            className={formData.selected_slots_evening.indexOf(item) === -1 ? "schedule_slot" : "schedule_slot active_slot"} onClick={() => onDateSelect(item, "eveng")}>
                                                            <span className="fix-times">{item}</span>
                                                        </button>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div> : null}
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default AppointmentCalender;