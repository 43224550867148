import React from 'react';
import BeingPractitioner from './BeingPractitioner';

const Practitioner = () => {
    return (
        <>
          <BeingPractitioner />  
        </>
    );
}

export default Practitioner ;