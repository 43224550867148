import React, { useCallback, useEffect, useState } from 'react';
import { useHistory,useLocation } from 'react-router-dom';
import ConfirmationPopup from '../../components/UIComponent/ConfirmationPopup';
import { resetdeleterequestAPI_Action,resetupcomingbookingAPI_Action } from '../../stores/actions'
import { useDispatch, useSelector } from 'react-redux';


const DeleteRequestConfirmation = (props) =>{
    let history = useHistory();
    const dispatch = useDispatch();
    const location=useLocation()
    
    const __redirectProfile = () =>{ 
        window.scrollTo(0, 0);
        dispatch(resetdeleterequestAPI_Action());
        dispatch(resetupcomingbookingAPI_Action());
        history.push('/practitioner-account')
    }

    const __redirectProfilenew = () =>{ 
        window.scrollTo(0, 0);
        dispatch(resetdeleterequestAPI_Action());
        dispatch(resetupcomingbookingAPI_Action());
        window.removeEventListener('popstate', function(event) {        
            // history.go(1);
         }, false);
        history.push({
            pathname: '/practitioner-account',
            iswindowreload:true

        });
    }
    useEffect(() => {
        
        window.addEventListener('popstate', function(event) {        
            __redirectProfilenew()
              window.location.reload(false);
        }, false);

        if(!location.requestid){
            __redirectProfilenew()
        }
    
    },[])

    return (
        <>
            <div className="cancel_booking_confirmation">
                <ConfirmationPopup 
                    SubHeadingText="Your request  has been deleted"
                    ButtonText="Back to my account"
                    __redirectNextPage={__redirectProfile}
                />
        

            </div>  
        </>
    );
}

export default DeleteRequestConfirmation ;