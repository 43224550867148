import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS,AUTH_HEADERS_NEW } from '../../config/ApiConstants';

export function practitionerSignupSaveData_Action(data) {
    return {
        type: types.PRACTITIONERSIGNUP_SAVE_DATA,
        practitionerRegisterData: data
    }
}

export const resetpractitionerSignupSaveAPI_Action = () => {
    return {
        type: types.RESET_PRACTITIONERSIGNUP_SAVE_DATA,
        payload: null
    }
};