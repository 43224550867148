import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const ConfirmationPopup = (props) => {
  return (
    <>
      <section className="bgcolor-gray common-section pt-70">
        <Container>
          <Row>
            <Col className="col-md-10 m-auto">
              
              <div className="desciption">
                <p className="subdesc">
                    {props.SubHeadingText}
                </p>

                <div className="two-col mt-24 mb-24">
                  <button className="btns-gray " onClick={props.__redirectNextPage}>
                     {props.ButtonText}
                  </button>
                  
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ConfirmationPopup;
