import { combineReducers } from 'redux';
import test from './test-reducer';
import login from './login-reducer';
import whatsavailable from './whatsavailable-reducer';
import register from './register-reducer';
import forgotpassword from './forgotpassword-reducer';
import userdetails from './userdetails-reducer';
import usserprofile from './userprofileupdate-reducer';
import ginipigprofile from './ginipigprofileupdate-reducer';
import practititionersignup from './practititionersignup-reducer';
import logout from './logout-reducer';
import createtreatment from './createtreatment-reducer';
import edittreatment from './edittreatment-reducer';
import treatmentdetails from './treatmentdetails-reducer';
import treatmentlist from './treatmentlist-reducer'
import categorylist from './categorylist-reducer'
import comapnycategorylist from './companycategorylist-reducer'
import comapnycategorylistnew from './companycategorylistnew-reducer'
import createrequest from './createrequest-reducer'
import editrequest from './editrequest-reducer'
import requestdetails from './requestdetails-reducer'
import createrequestenquiry from './createrequestenquiry-reducer'
import requestenquirydetails from './requestenquirydetails-reducer'
import createbooking from './createbooking-reducer'
import bookingdetails from './bookingdetails-reducer'
import cancelbooking from './cancelbooking-reducer'
import acceptbooking from './acceptbooking-reducer'
import practitionersignupaddinfo from './practitionersignupaddinfo-reducer'
import checkemailaexists from './checkemailaexists-reducer'
import changepassword from './changepassword-reducer'
import resetpassword from './resetpassword-reducer'
import validatepostcode from './validatepostcode-reducer'
import practitionerregister from './practitionerregister-reducer'
import ginipigdata from './ginipigdata-reducer'
import practictionerfeatureImage from './practitionerfeatureImage-reducer'
import practitionercategory from './practitionercategory-reducer'
import treatmentaddreviews from './treatmentaddreviews-reducer';
import practitionerprofile from './practitionerprofile-reducer';
import disabletreatmentpractitionerprofile from './disabletreatmentforpractitionerprofile-reducer';

import removetreatment from './removetreatment-reducer';
import appointmentcreatedata from './appointmentcreatedata-reducer'
import whatsavailabledetails from './whatsavailabledetails-reducer';
import savefilter from './savefilter-reducer';
import upcomingbookingdata from './upcomingbooking-reducer'
import pendingbookingdetails from './pendingbookingdetails-reducer'
import editginipic from './editginipic-reducer'
import completebookingdata from './completebooking-reducer'
import cancelreasonslistdata from './cancelreasonslist-reducer'
import declinereasonslistdata from './declinereasonslist-reducer'
import practitionercancelbookingdata from './practitionercancelbooking-reducer'
import practitionerdeclinebookingdata from './practitionerdeclinebooking-reducer'
import completebookingalldata from './completebookingall-reducer'
import deleterequestdata from './deleterequest-reducer'
import practitionerrequestdetailsdata from './practitionerrequestdetails-reducer'
import cancelallbookingdata from './cancelallbooking-reducer'
import practitioneraboutusdata from './practitioneraboutus-reducer'
import openrequestbookingdetailsdata from './openrequestbookingdetails-reducer'
import openrequestenquirydetailsdata from './openrequestenquirydetails-reducer'
import acceptenquirydata from './acceptenquiry-reducer'
import declineenquirydata  from './declineenquiry-reducer'
import bloglist from './bloglist-reducer'
import blogdetail from './blogdetail-reducer'
import favDetails from './favUnfavGinipig-reducer'
import cancelginipigbookingDetails from './cancelbookingginipig-reducer'
import bookingnotcompletedData from './bookingnotcompleted-reducer'
import practitionerportfoliolist from './practitionerportfoliolist-reducer'
import practportfolioaddimage from './practportfolioaddimage-reducer'
import editpractitionerportfolio from './editpractportfolio-reducer'
import deletepractportfolio from './deletepractportfolio-reducer'
import makeimagefeaturedata from './makeimagefeature-reducer'


import educationlist from './educationlist-reducer'
import educationdetail from './educationdetail-reducer'
import ginipigPortfolio from './ginipigPortfolio-reducer'
import ginipigreviewtopract from './ginipigreviewtopract-reducer'
import practitionerreviewgini from './practitionerreviewtogini-reducer'
import treatmentfav from './treatmentfav-reducer'
import favtreatmentdeatils from './getfavtreatmentdetails-reducer'
import practitionerstripelink from './practitionerstripelink-reducer'
import postcodesearch from './postcodesearch-reducer'
import imageUploaddata from './imageupload-reducer'
import ginipigregister from './ginipigregister-reducer'
import removerequestforpractitionerprofile from './removerequestforpractitionerprofile-reducer'
import checkstripeaccountvalidity from './checkstripeaccountvalidity-reducer'
import acceptfailpayment from './acceptfailpayment-reducer'
import educationdisable from './educationdisable-reducer';
 

const rootReducer = combineReducers({
    test: test,
    login:login,
    whatsavailable:whatsavailable,
    register:register,
    forgotpassword:forgotpassword,
    userdetails:userdetails,
    usserprofile:usserprofile,
    ginipigprofile:ginipigprofile,
    practititionersignup:practititionersignup,
    logout:logout,
    createtreatment:createtreatment,
    edittreatment:edittreatment,
    treatmentdetails:treatmentdetails,
    treatmentlist:treatmentlist,
    categorylist:categorylist,
    comapnycategorylist:comapnycategorylist,
    comapnycategorylistnew:comapnycategorylistnew,
    createrequest:createrequest,
    requestdetails:requestdetails,
    editrequest:editrequest,
    createrequestenquiry:createrequestenquiry,
    requestenquirydetails:requestenquirydetails,
    createbooking:createbooking,
    bookingdetails:bookingdetails,
    cancelbooking:cancelbooking,
    acceptbooking:acceptbooking,
    practitionersignupaddinfo:practitionersignupaddinfo,
    checkemailaexists:checkemailaexists,
    changepassword:changepassword,
    resetpassword:resetpassword,
    validatepostcode:validatepostcode,
    practitionerregister:practitionerregister,
    ginipigdata:ginipigdata,
    practictionerfeatureImage:practictionerfeatureImage,
    treatmentaddreviews:treatmentaddreviews,
    practitionerprofile:practitionerprofile,
    practitionercategory:practitionercategory,
    removetreatment:removetreatment,
    appointmentcreatedata:appointmentcreatedata,
    whatsavailabledetails:whatsavailabledetails,
    savefilter:savefilter,
    upcomingbookingdata:upcomingbookingdata,
    pendingbookingdetails:pendingbookingdetails,
    editginipic:editginipic,
    completebookingdata:completebookingdata,
    cancelreasonslistdata:cancelreasonslistdata,
    declinereasonslistdata:declinereasonslistdata,
    practitionerdeclinebookingdata:practitionerdeclinebookingdata,
    practitionercancelbookingdata:practitionercancelbookingdata,
    completebookingalldata:completebookingalldata,
    deleterequestdata:deleterequestdata,
    practitionerrequestdetailsdata:practitionerrequestdetailsdata,
    cancelallbookingdata:cancelallbookingdata,
    practitioneraboutusdata:practitioneraboutusdata,
    openrequestbookingdetailsdata:openrequestbookingdetailsdata,
    openrequestenquirydetailsdata:openrequestenquirydetailsdata,
    acceptenquirydata:acceptenquirydata,
    declineenquirydata:declineenquirydata,
    bookingnotcompletedData:bookingnotcompletedData,
    practitionerportfoliolist:practitionerportfoliolist,
    editpractitionerportfolio:editpractitionerportfolio,
    deletepractportfolio:deletepractportfolio,
    practitionerreviewgini:practitionerreviewgini,
    bloglist:bloglist,
    blogdetail:blogdetail,
    favDetails:favDetails,
    cancelginipigbookingDetails:cancelginipigbookingDetails,
    educationlist:educationlist,
    educationdetail:educationdetail,
    ginipigPortfolio:ginipigPortfolio,
    practportfolioaddimage:practportfolioaddimage,
    makeimagefeaturedata:makeimagefeaturedata,
    ginipigreviewtopract:ginipigreviewtopract,
    practitionerreviewgini:practitionerreviewgini,
    treatmentfav:treatmentfav,
    favtreatmentdeatils:favtreatmentdeatils,
    practitionerstripelink:practitionerstripelink,
    postcodesearch:postcodesearch,
    imageUploaddata:imageUploaddata,
    disabletreatmentpractitionerprofile:disabletreatmentpractitionerprofile,
    removerequestforpractitionerprofile:removerequestforpractitionerprofile,
    ginipigregister:ginipigregister,
    checkstripeaccountvalidity:checkstripeaccountvalidity,
    acceptfailpayment:acceptfailpayment,
    educationdisable:educationdisable
});

export default rootReducer;