import React, { useCallback, useState, useEffect,useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { Container, Row, Col, Tab, Nav, Accordion } from 'react-bootstrap';
import { LeftArrow, iconPatch_test, reviewAvatar, star, iconPhoto, iconCase_Study, iconNearly_there, iconGiniPigs_Required, Aesthetic, Hair, Beauty, wellness, AestheticTwo, BeautyTwo, HairTwo, wellnessTwo } from '../../config/images';
import AccordionContentForAppointment from '../../components/UIComponent/AccordionContentForAppointment';
import LargeButton from '../../components/LargeButton';
import Calendar from 'react-calendar'
import ReactPaginate from 'react-paginate';
import CloseRequestDetail from '../../components/CloseRequestDetail'
import ModelRequirementCloseRequest from '../../components/ModelRequirementCloseRequest'
import ReviewBox from '../../components/UIComponent/ReviewBox';
import moment from 'moment';


import SlickSlider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import UserProfile from '../../components/UserProfile';
import {
    requestDetailsAPI_Action,
    logoutAPI_Action,
    resetUserAPI_Action,
    resetLoginAPI_Action,
    resetRegisterAPI_Action,
    resetPractitionerAddInfoAPI_Action,
    resettreatmentaddreviewAPI_Action,
    resetPractionerCategory_API_Action,
    resetpractitionerProfileAPI_Action,
    resetAPI_ActionWhatAvailable
} from '../../stores/actions'
import { getLocalStorageData, setLocalStorageData, clearLocalStorage } from '../../utils/storage'
import { formatPostcode } from '../../utils/utility';
import {get24HrsTimeFormat}from '../../utils/utility'

const TreatmentDetailForCloseRequest = (props) => {
    const filterData = useSelector(state => state.savefilter);
    const loginUserData = useSelector(state => state.login);
    const registerData = useSelector(state => state.register);
    const practitionersignupaddinfoData = useSelector(state => state.practitionersignupaddinfo);
    let LocalDatatoken = getLocalStorageData("loginData")
    const dispatch = useDispatch();
    const requestdetails = useSelector(state => state.requestdetails);
    const [profileImages, setProfileImages] = useState([]);
    var param_data = props.match.params.id;
    let loginTokenData = getLocalStorageData("loginData");
    var set_user_location = ' ';
    let subject = "Report Content GoGiniPig"
    let emailUrL = "mailto:hello@goginipig.com?subject=" + subject
    const [showreviewpract, setshowReviewPract] = useState(false);

    const [perPagereview, setPerpagereview] = useState(3);
    const [offsetreview, setOffsetreview] = useState(0);
    const [currentPagereview, setCurrentpagereview] = useState(0);
    const myRef = useRef()

    useEffect(() => {

        __apiCall()
        dispatch(resetAPI_ActionWhatAvailable());
    }, []);

    // if(requestdetails.requestdetailsdata && requestdetails.requestdetailsdata !== null){
    //     if(requestdetails.requestdetailsdata.data.request.treatment.data.user.data.user_additional_info.hasOwnProperty('data')){
    //         set_user_location = requestdetails.requestdetailsdata.data.request.treatment.data.user.data.user_additional_info.data[0].postcode_data.location
    //     }
    // }
    if (requestdetails.requestdetailsdata && requestdetails.requestdetailsdata !== null) {
        var time = '';
        if (profileImages.length == 0) {
            var tempImg = [];
            if (requestdetails.requestdetailsdata.data.request.request_images.data.length < 2) {
                var islength = true;
                if (requestdetails.requestdetailsdata.data.request.request_images.data.length == 1) {
                    islength = false;
                    tempImg.push(requestdetails.requestdetailsdata.data.request.request_images.data[0].image_link)
                }
                if (requestdetails.requestdetailsdata.data.request.treatment.data.categories.data.name == "Aesthetics") {
                    tempImg.push(Aesthetic);
                    if (islength) {
                        tempImg.push(AestheticTwo);
                    }
                } else if (requestdetails.requestdetailsdata.data.request.treatment.data.categories.data.name == "Hair") {
                    tempImg.push(Hair);
                    if (islength) {
                        tempImg.push(HairTwo);
                    }
                } else if (requestdetails.requestdetailsdata.data.request.treatment.data.categories.data.name == "Beauty") {
                    tempImg.push(Beauty);
                    if (islength) {
                        tempImg.push(BeautyTwo);
                    }
                } else {
                    tempImg.push(wellness);
                    if (islength) {
                        tempImg.push(wellnessTwo);
                    }
                }
            } else {
                requestdetails.requestdetailsdata.data.request.request_images.data.map(i => {
                    tempImg.push(i.image_link);
                });
            }
            setProfileImages(tempImg);
            if (loginTokenData && loginTokenData.user && loginTokenData.user.id === requestdetails.requestdetailsdata.data.request.user_id) {
                setshowReviewPract(true)
            }
            if (requestdetails.requestdetailsdata.data.request.request_timeslots.data[0].start_time !== null) {
                time = requestdetails.requestdetailsdata.data.request.request_timeslots.data[0].start_time.split(':')
                time = time[0] + ':' + time[1]
            }
        }

        let period = ""
        let hours = ''
        let [sHours, minutes] = ""
        //    console.log(item.request_timeslots[0].start_time)
        if (requestdetails.requestdetailsdata.data.request.request_timeslots.data[0].start_time !== null) {
            [sHours, minutes] = requestdetails.requestdetailsdata.data.request.request_timeslots.data[0].start_time && requestdetails.requestdetailsdata.data.request.request_timeslots.data[0].start_time.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
            period = +sHours < 12 ? 'am' : 'pm';
            hours = +sHours % 12 || 12;
        }
        let timein24hrs= get24HrsTimeFormat(requestdetails.requestdetailsdata.data.request.request_timeslots.data[0].start_time)
        


        //var set_date = new Date(requestdetails.requestdetailsdata.data.request.request_timeslots.data[0].date_time).getDate() + ' ' + new Date([new Date(requestdetails.requestdetailsdata.data.request.request_timeslots.data[0].date_time).getFullYear(), ("0" + (new Date(requestdetails.requestdetailsdata.data.request.request_timeslots.data[0].date_time).getMonth() + 1)).slice(-2), ("0" + new Date(requestdetails.requestdetailsdata.data.request.request_timeslots.data[0].date_time).getDate()).slice(-2)].join("-")).toLocaleString('default', { month: 'long',year: 'numeric' }) + ", " + hours + ":" + minutes + period
        //console.log("setdate:-", set_date);
        let dateFormat = moment(requestdetails.requestdetailsdata.data.request.request_timeslots.data[0].date_time).format("Do MMMM YYYY")
        var set_date =  dateFormat + " @ " + timein24hrs


    }

    const SampleNextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div className={className} style={{ ...style }} onClick={onClick}>
                {" "}
                <img src={LeftArrow} className="arrow_icons" alt="icon" />{" "}
            </div>
        );
    };

    const SamplePrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div className={className} style={{ ...style }} onClick={onClick}>
                {" "}
                <img src={LeftArrow} className="arrow_icons" alt="icon" />{" "}
            </div>
        );
    };
    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };



    //init state
    const { data, Setdata } = useState(
        Object.freeze({
            date: new Date(),
        })
    )

   

    let history = useHistory();

    const __apiCall = useCallback(async () => {
        const loginToken = loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token : "" : "";
        const ginpig_id = loginUserData.logindata ? loginUserData.logindata.data && loginUserData.logindata.data.user.type === "ginipig" ? loginUserData.logindata.data.user.uuid : 0 : 0;

        try {

            await dispatch(requestDetailsAPI_Action(param_data, loginToken, ginpig_id));
        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    });

    const nextScreenCall = async () => {
        if (loginTokenData) {
            if (loginTokenData != null || loginTokenData != undefined) {

                if (loginTokenData.user.type === "practitioner") {
                    var data = {
                        book_close_request: true,
                        param_data: param_data,
                        photo_required: requestdetails.requestdetailsdata.data.request.photograph_required
                    }
                    setLocalStorageData("book_close_request", JSON.stringify(data))
                    window.scrollTo(0, 0);
                    history.push("/practitioner-logout");
                } else {
                    if (loginTokenData.user.profile_pic === '' || loginTokenData.user.profile_pic === undefined || loginTokenData.user.profile_pic === null) {
                        var data = {
                            id: param_data, photo_required: requestdetails.requestdetailsdata.data.request.photograph_required, path: 'close'
                        }

                        window.scrollTo(0, 0);
                        history.push('/update-profile-confirmation', { data: data });
                    } else
                        if (requestdetails.requestdetailsdata.data.request.photograph_required === 1) {
                            window.scrollTo(0, 0);
                            history.push('/photo-requirement/' + param_data)
                        } else {
                            window.scrollTo(0, 0);
                            history.push('/view-booking/' + param_data)
                        }
                }
            }
        } else {
            var today = new Date();
            var data = {
                book_close_request: true,
                param_data: param_data,
                savedtime:today.getTime(),
                photo_required: requestdetails.requestdetailsdata.data.request.photograph_required
            }
            setLocalStorageData("book_close_request", JSON.stringify(data))
            window.scrollTo(0, 0);
            var dataforphoto = {
                id: param_data, photo_required: requestdetails.requestdetailsdata.data.request.photograph_required, path: 'close'
            }
            setLocalStorageData("dataforphoto",JSON.stringify(dataforphoto))
            history.push("/login");
        }

        window.scrollTo(0, 0);
    }

    const onBackPressed = () => {
        var backpressed = ''; var data = ''; var date = '';

        if (getLocalStorageData("filter_treatment_data") !== undefined) {
            backpressed = JSON.parse(getLocalStorageData("filter_treatment_data"))
        }
        if (backpressed.hasOwnProperty('searchDetails')) {
            if (backpressed.searchDetails !== null) {
                if (backpressed.searchDetails.startDate !== 'Any date') {
                    date = [new Date(backpressed.searchDetails.startDate).getFullYear(), ("0" + (new Date(backpressed.searchDetails.startDate).getMonth() + 1)).slice(-2), ("0" + new Date(backpressed.searchDetails.startDate).getDate()).slice(-2)].join("-");
                } else {
                    date = 'Any date'
                }


                data = {
                    keywords: backpressed.searchDetails.search_keywords,
                    location: backpressed.searchDetails.search_location,
                    latitude: backpressed.searchDetails.search_latitude,
                    longitude: backpressed.searchDetails.search_longitude,
                    postcode: backpressed.searchDetails.search_postcode,
                    from_header: 'not_from_header',
                    date: date
                }
            }
        }
        let RedirectPathName = getLocalStorageData("pathnamegini")
        if (RedirectPathName === "fromginipigProfile") {
            history.goBack()
        } else {
            window.scrollTo(0, 0);
            history.push('/search-result', { data: JSON.stringify(data), date: date })
        }

    }

    // const __redirectportfolio = (id) => {
    //     window.scrollTo(0, 0);
    //     history.push('/ginipig-portfolio/' + id)
    // }


    const handlePageClickReiew = (e) => {
        if(myRef!==null){
            window.scrollTo({ behavior: 'smooth', top: myRef.current.offsetTop })
        }
       
        const selectedPage = e.selected;
        const offset = selectedPage * perPagereview;
        setCurrentpagereview(selectedPage)
        setOffsetreview(offset)
    }

//when ac suspend
//useEffect(() => {
    // if(requestdetails && requestdetails.requestdetailsdata &&  requestdetails.requestdetailsdata.status_code===401){
    //    clearLocalStorage("loginData")
    //    clearLocalStorage("selectedtreatmentId")
    //    clearLocalStorage("redirectPathNameEdit")
    //    clearLocalStorage("requestDataLocal")
    //    clearLocalStorage("practitionersignupData")
       
    //    dispatch(resetLoginAPI_Action());
    //    dispatch(resetUserAPI_Action());
    //    dispatch(resetRegisterAPI_Action());
    //    dispatch(resetPractitionerAddInfoAPI_Action());
    //    dispatch(resettreatmentaddreviewAPI_Action());
    //    dispatch(resetAPI_ActionWhatAvailable());

    //   history.push("/login");
    // }
//});

//end




    return (
        <>
            <section className="bgcolor-gray common-section practitioner_profile_forginipig request_closepg pb80">
                <Container>
                    <Row>

                        {
                            requestdetails.requestdetailsdata && requestdetails.requestdetailsdata.data !== null ?
                                <Col className="col-lg-11 m-auto">
                                    <div className="navigationTop">
                                        <div className="heading-history">
                                            <div className="back-history">
                                                <button type="button" onClick={() => {
                                                    //history.goBack()
                                                    onBackPressed()
                                                }} className="back-btn" >
                                                    {" "}
                                                    <img src={LeftArrow} alt="arrow" />{" "}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="practitioner_profile-wrp from-wrp profile_border">
                                        <div className="practitioner-profile-img ">
                                            <div className="img_wrp_slider">
                                                <SlickSlider {...settings} >
                                                    {
                                                        profileImages.map((i) => {
                                                            return (
                                                                <div className="img_size profile_img1 ">

                                                                    <img src={i} />

                                                                </div>
                                                            )
                                                        })

                                                    }
                                                </SlickSlider>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="details-wrps">
                                        <div className="close_request_box mt-40 mb-70">

                                            <CloseRequestDetail
                                                treatmentTitle={requestdetails.requestdetailsdata.data.request.treatment.data.title}
                                                currencySign="£"
                                                originalPriceText={requestdetails.requestdetailsdata.data.request.rrp_price.toFixed(2)}
                                                discountPriceText={requestdetails.requestdetailsdata.data.request.your_price.toFixed(2)}
                                                hrs={requestdetails.requestdetailsdata.data.request.hours}
                                                minutes={requestdetails.requestdetailsdata.data.request.minutes}
                                                practitioner_category_apply={requestdetails.requestdetailsdata.data.request.treatment.data.user.data.practitioner_category_apply}
                                                tagsName={requestdetails.requestdetailsdata.data.request.treatment.data.categories.data.name}
                                                // duration={requestdetails.requestdetailsdata.data.request.hours + 'h' + " " + requestdetails.requestdetailsdata.data.request.minutes + 'm'}
                                                description={requestdetails.requestdetailsdata.data.request.treatment.data.description}
                                                categoryIcon1={iconCase_Study}
                                                request_type={requestdetails.requestdetailsdata.data.request.type}
                                                categorName1={requestdetails.requestdetailsdata.data.request.reason_require_ginipig}
                                                categoryIcon2={iconNearly_there}
                                                categorName2={requestdetails.requestdetailsdata.data.request.expertise_level}
                                                categoryIcon3={iconGiniPigs_Required}
                                                categorName3={requestdetails.requestdetailsdata.data.request.places_available ===1 ? "1 place left ": requestdetails.requestdetailsdata.data.request.places_available + " places left "}
                                                buttonRequiestTime={set_date}
                                                buttonReqAddress={requestdetails.requestdetailsdata.data.request.postcodes!==null && requestdetails.requestdetailsdata.data.request.postcodes!==undefined? formatPostcode(requestdetails.requestdetailsdata.data.request.postcodes.data.postcode):null}
                                                buttonText="Book now"
                                                onNextPress={() => { nextScreenCall() }}
                                            />
                                        </div>
                                        {requestdetails.requestdetailsdata.data.request.photograph_required === 1 || requestdetails.requestdetailsdata.data.request.attributes_required === 1 || requestdetails.requestdetailsdata.data.request.is_patchtest_required === 1 ?
                                            <div className="model_req_box mb-8">
                                                <ModelRequirementCloseRequest
                                                    content={requestdetails.requestdetailsdata.data.request}
                                                    modelTitle="GiniPig Requirements"
                                                    disciption={requestdetails.requestdetailsdata.data.request.model_requirements}
                                                    requiredImg={iconPhoto}
                                                    requiredPatchImg={iconPatch_test}
                                                    requiredText=" The Treatment/Service Provider requires you to add a photo to ensure perfect compatibility for this appointment "
                                                    patchText="You are required to complete a patch test prior to this treatment"
                                                />
                                            </div>
                                            : null}
                                        <div className="profile_user_box mt-40">
                                            <UserProfile
                                                userImg={requestdetails.requestdetailsdata.data.request.treatment.data.user.data.profile_pic}
                                                userName={requestdetails.requestdetailsdata.data.request.treatment.data.user.data.first_name + ' ' + requestdetails.requestdetailsdata.data.request.treatment.data.user.data.last_name}
                                                ratingImg={star}
                                                userId={requestdetails.requestdetailsdata.data.request.treatment.data.user.data.uuid}
                                                ratingPoint={requestdetails.requestdetailsdata.data.request.user.data.review_rating_practitioner.toFixed(1)}
                                                address={requestdetails.requestdetailsdata.data.request.treatment.data.user.data.user_additional_info.data[0].postcode_data.data.location}
                                                tagsTitle="Other available appointments"
                                                // tagName="Hair"
                                                // count="2"
                                                categoryData={requestdetails.requestdetailsdata.data.request.treatment.data.user.data.category_data}
                                            />
                                        </div>
                                    

                                    <div className="review_boxs_wrp" ref={myRef}>

                                        {requestdetails.requestdetailsdata.data.request.practitioner_review.length !== 0 ?
                                            <p className="mt-24 mb-24 review_titles">Practitioner’s latest reviews</p> : null
                                        }
                                        {requestdetails.requestdetailsdata.data.request.practitioner_review.length !== 0 ?

                                            <div>

                                                <div className="review_boxs_wrp mt-16">
                                                    {

                                                        requestdetails.requestdetailsdata.data.request.practitioner_review.slice(offsetreview, offsetreview + perPagereview).map((reviewdata, index) => {
                                                            let reviewDate = new Date(reviewdata.created_at)
                                                            let date1 = moment(reviewDate).format("DD/MM/yy")

                                                            let subCatArray = []
                                                            reviewdata.booking.request.treatment.treatment_sub_category_new.forEach(element => {
                                                                subCatArray.push(element.name)

                                                            })

                                                            var str;
                                                            if (subCatArray.length === 1) {
                                                                str = subCatArray.join()
                                                            } else if (subCatArray.length === 2) {

                                                                str = subCatArray.join(" & ")
                                                            } else if (subCatArray.length === 3) {
                                                                str = subCatArray[0] + "," + subCatArray[1] + " & " + subCatArray[2]
                                                            }


                                                            return (
                                                                <div className="review_item mb-16">
                                                                    <ReviewBox
                                                                      showgpname={true}
                                                                        title={reviewdata.booking.request.treatment.title}
                                                                        reviewDate={date1}
                                                                        decs={reviewdata.comments}
                                                                        reviewAuthor={reviewdata.ginipig.name}
                                                                        
                                                                        reviewName2="Professionalism"
                                                                        reviewCount2={reviewdata.professionalism}
                                                                        reviewName3="Friendliness "
                                                                        reviewCount3={reviewdata.etiquette}
                                                                        reviewName1="Reliability"
                                                                        reviewCount1={reviewdata.cleanliness}
                                                                        reviewName4="Results"
                                                                        reviewCount4={reviewdata.result}
                                                                        overAllCount={reviewdata.overall}
                                                                    />
                                                                </div>
                                                            )
                                                        })
                                                    }


                                                </div>

                                                <div className="pagination-div">
                                                    <ReactPaginate
                                                        previousLabel={"prev"}
                                                        nextLabel={"next"}
                                                        breakLabel={"..."}
                                                        breakClassName={"break-me"}
                                                        pageCount={Math.ceil(requestdetails.requestdetailsdata.data.request.practitioner_review.length / perPagereview)}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={5}
                                                        onPageChange={handlePageClickReiew}
                                                        containerClassName={"pagination"}
                                                        subContainerClassName={"pages pagination"}
                                                        activeClassName={"active"} />
                                                </div>

                                            </div> : showreviewpract === true ?
                                                <div className="form-wrp notice-review mt-16">
                                                    <p>You do not have any reviews but hopefully you will soon! GiniPigs will leave reviews once they have been treated by you in an appointment</p>
                                                </div> : null


                                        }

                                        <p className="inappropriate_text mt-8">Inappropriate content on this page?
                                          <span>   </span>
                                            <a  href={emailUrL}>
                                                <button className="plainbtn"> Report content </button>
                                            </a>
                                        </p>
                                    </div>
                                </div>

                                </Col> :
                                 <div style={{ width: "100%", fontSize: 20, marginTop: 100, textAlign: 'center' }}>
                                    <p>Loading ...</p>
                                </div>
                        } 
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default TreatmentDetailForCloseRequest;