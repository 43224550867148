import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS,AUTH_HEADERS_NEW } from '../../config/ApiConstants';
export function registerAPI_Action(data) {
    const formData = new FormData(); 
      formData.append('first_name', data.first_name);
      formData.append('last_name', data.last_name);
      formData.append('email', data.email);
      formData.append('password', data.password);
      formData.append('profile_pic', data.profile_pic);
      formData.append('telephone_no', data.telephone_no);
      formData.append('is_checked_terms_condition', data.is_checked_terms_condition);
      formData.append('is_checked_email_message', data.is_checked_email_message);
      formData.append('is_checked_marketing', data.is_checked_marketing);
      formData.append('is_accept_notify_ginipig', data.is_accept_notify_ginipig === true ? 1 : 0);
      
    
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/ginipig-signup`,
            method: 'POST',
            headers: {
                ...AUTH_HEADERS_NEW

            },
            // body:JSON.stringify(data),
            body: formData,
            types: [
                types.REGISTER_REQUEST,
                types.REGISTER_RECEIVE, {
                    type: types.REGISTER_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}


export const resetRegisterAPI_Action = () => {
    return {
        type: types.RESET_REGISTER,
        payload: null
    }
};
