import * as types from "../types/index";
import { setInitialState } from "../InitialState";


const INITIAL_STATE = setInitialState({
    practitionerprofiledata: null,
    practitionerFavData: null,
});
// postcode,lang,lat,address

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.PRACTITIONERPROFILE_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case types.PRACTITIONERPROFILE_RECEIVE:
            return {
                ...state,
                isFetching: false,
                isSuccess: true,
                practitionerprofiledata: action.payload.success
            };
        case types.PRACTITIONERPROFILE_FAILURE:
            return {
                ...state,
                isFetching: false,
                isError: true,
                practitionerprofiledata: action.payload.response.error,
            };
        case types.RESET_PRACTITIONERPROFILE:
            return {
                ...state,
                isFetching: false,
                isError: false,
                practitionerprofiledata: null,
            };
            case types.PRACTITIONERPROFILEFAV_REQUEST:
                return {
                    ...state,
                    isFetching: true,
                };
            case types.PRACTITIONERPROFILEFAV_RECEIVE:
                return {
                    ...state,
                    isFetching: false,
                    isSuccess: true,
                    practitionerFavData: action.payload.success
                };
            case types.PRACTITIONERPROFILEFAV_FAILURE:
                return {
                    ...state,
                    isFetching: false,
                    isError: true,
                    isSuccess: false,
                    practitionerFavData: action.payload.response.error,
                };
            case types.RESETPRACTITIONERFAV:
            return {
                ...state,
                isFetching: false,
                isError: false,
                isSuccess: false,
                practitionerFavData: null,
            };

                
            default:
                return {
                    ...state
                }
    }
}