import React, { useEffect, useState } from 'react';
import { useHistory,useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { LeftArrow, reviewAvatar, star, iconPhoto, iconCase_Study, iconNearly_there, iconGiniPigs_Required ,practitioner_avtar} from '../../config/images';

import UserProfile from '../../components/UserProfile';
import LargeButton from '../../components/LargeButton';
import { clearLocalStorage, getLocalStorageData } from '../../utils/storage'
import { S3_BUCKET_LINK } from '../../config/ApiConstants'

const PractitionerThankYouReview = () => {
    //state init
    let history = useHistory();
    const location=useLocation()
    const dispatch = useDispatch();
    const [detailsuser, setuserDetail] = useState();

    const [requestArray, setrequestArray] = useState([]);

    useEffect(() => {
        if(!location.isleavereview){
            ClickSubmitButton()
        }
       
        let requestReviewDetail = getLocalStorageData("requestReviewDetail");
        let requestArray = requestReviewDetail && requestReviewDetail.request
        let detailsuser = requestReviewDetail && requestReviewDetail.request && requestReviewDetail.request.user
        setuserDetail(detailsuser)
        setrequestArray(requestArray)
        clearLocalStorage('requestReviewDetail')

    }, []);

    const ClickSubmitButton = () => {
        window.scrollTo(0, 0);
        history.push({
            pathname: '/',

        });
    }
    return (
        <>
            <section className="bgcolor-gray common-section review_thankyou_pg  pb80">
                <Container>
                    <Row>
                        <Col className="col-lg-11 m-auto">
                            <div className="from-wrp mt-40">
                                <div className="appoint_conf_box pb-32 pt-70">
                                    <div className="heading_content">
                                        <h2 className="title "> Thank You </h2>
                                        <p className="decs mb-32 mt-24"> Your review will mean a lot and help them on their journey to success </p>
                                    </div>
                                </div>

                                <div className="thank_user_box mt-40">
                                    {
                                        requestArray !== null && requestArray !== undefined && requestArray !== "" ?
                                            <UserProfile
                                                userImg={   requestArray && requestArray.user && requestArray.user.profile_pic !=='' ? S3_BUCKET_LINK+requestArray.user.profile_pic : practitioner_avtar}
                                                //userImg={requestArray && requestArray.user && requestArray.user.profile_pic}
                                                userName={requestArray && requestArray.user && requestArray.user.first_name }
                                                ratingImg={star}
                                                userId={requestArray && requestArray.user && requestArray.user.uuid}
                                                ratingPoint={requestArray && requestArray.user && requestArray.user.review_rating_practitioner}
                                                address={requestArray &&  requestArray.postcodes &&  requestArray.postcodes.location}
                                                tagsTitle="Other available appointments"
                                               categoryData={requestArray &&  requestArray.user && requestArray.user.category_data && requestArray.user.category_data}
                                            // userImg={requestReviewDetail &&requestArray && requestReviewDetail.request.user && requestReviewDetail.request.user.profile_pic}
                                            // userName={requestReviewDetail && requestReviewDetail.request && requestReviewDetail.request.user && requestReviewDetail.request.user.first_name + ' ' + requestReviewDetail && requestReviewDetail.request && requestReviewDetail.request.user && requestReviewDetail.request.user.last_name}
                                            // ratingImg={star}
                                            // ratingPoint="5.0"
                                            // address={requestdetails.requestdetailsdata.data.request.treatment.data.user.data.user_additional_info.data[0].postcode_data.data.location}
                                            // tagsTitle="Other available appointments"
                                            // // tagName="Hair"
                                            // // count="2"
                                            // categoryData={requestdetails.requestdetailsdata.data.request.treatment.data.user.data.category_data}
                                            /> : null
                                    }
                                </div>

                                <div className="btn-align mt-24 pd32">
                                    <LargeButton
                                        ButtonType="button"
                                        ButtonText="Back to search"
                                        nextButtonClick={ClickSubmitButton}

                                    />
                                </div>

                            </div>
                        </Col>
                    </Row>

                </Container>
            </section>
        </>
    );
}

export default PractitionerThankYouReview;