import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  giniPigLogoFooter,
  icon_facebk,
  icon_instagram,
} from "../../config/images";
import { Link } from "react-router-dom";
import CookieConsent from "react-cookie-consent";

const Footer = (props) => {
  var datasend = {
    data: {
      keywords: "hair",
      location: "Any location",
      postcode: "",
      postcodeValue: "",
      country: "",
      city: "",
      latitude: "",
      longitude: "",
      from_header: "from_header",
      date: "Any date",
    },
  };

  var datasendBeauty = {
    data: {
      keywords: "Beauty",
      location: "Any location",
      postcode: "",
      postcodeValue: "",
      country: "",
      city: "",
      latitude: "",
      longitude: "",
      from_header: "from_header",
      date: "Any date",
    },
  };
  var datasendAesthetic = {
    data: {
      keywords: "Aesthetic",
      location: "Any location",
      postcode: "",
      postcodeValue: "",
      country: "",
      city: "",
      latitude: "",
      longitude: "",
      from_header: "from_header",
      date: "Any date",
    },
  };
  var datasendWellness = {
    data: {
      keywords: "Wellness",
      location: "Any location",
      postcode: "",
      postcodeValue: "",
      country: "",
      city: "",
      latitude: "",
      longitude: "",
      from_header: "from_header",
      date: "Any date",
    },
  };

  const __reDirectPage = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <footer className="footer-section">
        <Container>
          <Row>
            <div className="footer-box">
              <div className="footer-box1">
                <div className="logo">
                  <img src={giniPigLogoFooter} alt="" />
                </div>
                <h3 className="footer-copyright">
                &copy; {new Date().getFullYear()} GoGiniPig Limited
                </h3>

                <ul className="socail_list">
                  <li>
                    <a
                      className="nav-social"
                      href="https://www.facebook.com/goginipig"
                      target="_blank"
                    >
                      <img src={icon_facebk} className="icon-socail fb" />
                    </a>
                  </li>
                  <li>
                    <a
                      className="nav-social"
                      href="https://www.instagram.com/goginipig"
                      target="_blank"
                    >
                      <img src={icon_instagram} className="icon-socail insta" />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="footer-box2">
                <div className="footer-list0">
                  <div className="f1">
                    <ul className="footer-list">
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          onClick={(e) => {
                            window.location = "mailto:hello@goginipig.com";
                            e.preventDefault();
                          }}
                        >
                          Contact Us
                        </Link>
                      </li>
                      <li>
                        <Link to="/Blog">Blog</Link>
                      </li>
                      {props.educationpageenable ? (
                        <li>
                          <Link to="/Education">Education</Link>
                        </li>
                      ) : null}
                    </ul>
                  </div>
                  <div className="f2">
                    <ul className="footer-list">
                      <li>
                        <Link
                          // to="/hair"
                          to={{
                            pathname: "/search-result",
                            state: JSON.stringify(datasend.data),
                          }}
                        >
                          Hair
                        </Link>
                      </li>
                      <li>
                        <Link
                          // to="/Beauty"
                          to={{
                            pathname: "/search-result",
                            state: JSON.stringify(datasendBeauty.data),
                          }}
                        >
                          Beauty
                        </Link>
                      </li>
                      <li>
                        <Link
                          // to="/Aesthetic"
                          to={{
                            pathname: "/search-result",
                            state: JSON.stringify(datasendAesthetic.data),
                          }}
                        >
                          Aesthetics
                        </Link>
                      </li>
                      <li>
                        <Link
                          // to="/Wellness"
                          to={{
                            pathname: "/search-result",
                            state: JSON.stringify(datasendWellness.data),
                          }}
                        >
                          Wellness
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="f3">
                    <ul className="footer-list">
                      <li>
                        <Link to="/whoggp" >
                          GiniPigs
                        </Link>
                      </li>
                      <li>
                        <Link to="/practitioner" >
                          Providers
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="f4">
                    <ul className="footer-list">
                      <li>
                        <Link to="/tc" >
                          Legals
                        </Link>
                      </li>
                      <li>
                        <Link to="/faq" >
                          Help
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <Col className="col-12 col-md-3 col-lg-4"></Col>
            <Col className="col-12  col-md-8 col-lg-8"></Col>
          </Row>
        </Container>
        {/* <div className='container'>Place sticky footer content here.</div> */}
      </footer>

      {/* cookies components */}

      <CookieConsent
        expires={150}
        location="bottom"
        buttonText="I Understand"
        cookieName="Goginipig"
      >
        This site uses cookies to improve your experience. We use cookies to
        personalise content and analyse our traffic. These cookies allow the
        website to remember some of the choices you make and provide enhanced
        personal features. The information these cookies collect may be
        anonymized and they cannot track your browsing activity on other
        websites
        <Link
          className=" btn-white-primary d-flex"
          to="/tc#privacy_policy"
          onClick={__reDirectPage}
          style={{
            color: "#fff",
            fontSize: "13px",
            lineHeight: "25px",
            fontWeight: "bolder",
            padding: "0.1rem 0rem",
            margin: "10px 0px 0px",
            textDecoration: "underline",
          }}
        >
          {" "}
          Read more{" "}
        </Link>
      </CookieConsent>
    </>
  );
};

export default Footer;
