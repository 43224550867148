import React, { useCallback, useState, useEffect } from "react";

const ModelRequirementCloseRequest = (props) => {


    // const [disableview, setDisableView] = useState(true);
    // if(props.content.attributes_required === 1 || props.content.photograph_required === 1  || props.content.is_patchtest_required === 1){
    //     setDisableView(false)
    // }
    // else{
    //     setDisableView(false)
    // }

    return (
        <>
            {/* {
            !props.disableview ?  */}
            
            <div className="model_box white_box" >
                <div className="inside_model_content">
                     { 
                        props.content.attributes_required === 1 ?
                        <div>
                            <h3 className="model-title">{props.modelTitle}</h3>
                            <div className="model-content mt-8">
                                <p>{props.disciption}</p>
                            </div>
                        </div> : null
                      }
                      { 
                        props.content.photograph_required === 1 ?
                        <div className="model-required">
                            <div className="inline_row_text flex_row">
                                <img src={props.requiredImg} className="icons_required mr-8" />
                                <p>{props.requiredText}</p>
                            </div>
                        </div> : null
                      }
                      { 
                        props.content.is_patchtest_required === 1 ?
                        <div className="model-required">
                            <div className="inline_row_text flex_row">
                                 {  
                                    props.requiredPatchImg && 
                                    <img src={props.requiredPatchImg} className="icons_required mr-8" />
                                 }
                                 <p>{props.patchText}</p>
                            </div>
                        </div> : null
                      }                   
                    
                </div>
            </div> 
             {/* : null
        }  */}
       </>
    );
}

export default ModelRequirementCloseRequest;