import React, { useCallback, useState, useEffect } from "react";

import { Container, Row, Col,Modal } from "react-bootstrap";
import { LeftArrow, reviewAvatar,iconClose,profileIcon, iconDelete_peach ,ginipic_avtar} from "../../config/images";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getLocalStorageData, clearLocalStorage,setLocalStorageData } from '../../utils/storage';
// redux
import { userdetailsAPI_Action,goginipigprofileAPI_Action,resetpractitionerProfileAPI_Action,resetgoginipigprofileAPI_Action,checkemailExistsAPI_Action,checkemailExistsEditAPI_Action,GinipigUpdateData_Action, resetGinipigUpdateAPI_Action, resetLoginAPI_Action} from '../../stores/actions'

const initialFormData = Object.freeze({
  first_name: "",
  last_name: "",
  email: "",
  username: "",
  profile_pic: "",
  profile_pic_link:"",
  mobile: "",
  // remember_me:false,
  // deviceOneSignalId:'f06b8c52-d997-41d5-93b0-ef3c432466f0',
});

const initialErrorData = Object.freeze({
  email_error:"",
  firstname_error:"",
  lastname_error:"",
  password_error:"",
  phonenumber_error:"",
  is_form_valid:true,
});

const GinipigProfileUpdate = (props) => {
 // console.log("props ", props)
  var param_data;
  if(props.location.state !== undefined){
       param_data = props.location.state.data    
  }

  // console.log("param_data",param_data)
  
  //hooks
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isData, setIsData] = useState(false);
  // const [errorMSG, setErrorMSG] = useState('');
  // Initialize hooks first
  const dispatch = useDispatch();
  const history = useHistory();

  // States Management
  const [formData, updateFormData] = useState(Object.freeze({
    first_name:"",
    last_name:"",
    email: "",
    username:"",
    profile_pic:"",
    profile_pic_link:"",
    mobile:"",
  }));
  const [errorData, updateErrorData] = useState(initialErrorData);
  // Selectors
  const userData = useSelector(state => state.userdetails);
  const ginipigProfileData = useSelector(state => state.ginipigprofile);
  const loginUserData = useSelector(state => state.login);
  const ginipigUpdateData = useSelector(state => state.ginipigdata);
  let LocalDatatoken=getLocalStorageData("loginData");



  
  if(ginipigProfileData.ginipigprofiledata && ginipigProfileData.ginipigprofiledata.status_code===401){
      clearLocalStorage("loginData")
      clearLocalStorage("practitionersignupData")
      dispatch(resetLoginAPI_Action());
      dispatch(resetpractitionerProfileAPI_Action());
      dispatch(resetGinipigUpdateAPI_Action());
      dispatch(resetgoginipigprofileAPI_Action());
      window.scrollTo(0, 0);
      history.push('/login'); 
    }

  if(isData){
  setTimeout(() => {
    if(ginipigProfileData.isSuccess===true)
    {

    if(ginipigUpdateData.ginipigdata === null)
    {
      const updatedData=ginipigProfileData.ginipigprofiledata && ginipigProfileData.ginipigprofiledata.data !== null ? ginipigProfileData.ginipigprofiledata.data.user : null;
      if(updatedData!==null)
      {
        dispatch(GinipigUpdateData_Action(updatedData));
      }
      
    }else{
      if (LocalDatatoken !== "") {
        //console.log('LocalDatatoken ', LocalDatatoken)
        var storeloginData = {
          token:LocalDatatoken.token,
          user:ginipigUpdateData.ginipigdata
        }
        setLocalStorageData("loginData",storeloginData)
      }
      
    }
    
    window.scrollTo(0, 0);
    //console.log("local data ", getLocalStorageData("update_ginipig_profile"))
    if(getLocalStorageData("update_ginipig_profile") !== undefined){
      var redirect = JSON.parse(getLocalStorageData('update_ginipig_profile'))
      //console.log('redirect ', redirect , ' ', formData)
        if(redirect.update_profile){
          if(formData.profile_pic_link !== ''){
           
            if(param_data.path === 'open'){
              let bookOpenRequest = getLocalStorageData("book_open_request");
              // console.log("update 34",bookOpenRequest)
              if (bookOpenRequest){
                if(bookOpenRequest != null || bookOpenRequest != undefined){          
                    var request = JSON.parse(bookOpenRequest)
                    if(request.book_open_request === true){
                      window.scrollTo(0, 0);
                      history.push({ 
                        pathname: '/treatment-availability-open-request',
                        state: {'paramData' : request.param_data,'selectedData': request.selectedData,'treatmentData':request.treatmentData},
                       });
                    }
                }
              }
            }else
            if(param_data.photo_required === 1){
              window.scrollTo(0, 0);
              history.push('/photo-requirement/'+param_data.id)
            }else{
              window.scrollTo(0, 0);
              history.push('/view-booking/'+param_data.id)
            }
          }else{ console.log("update 3")

          }        
        }else{
          window.scrollTo(0, 0);
          history.push('/ginipig-profile');
        }
    }else{
      window.scrollTo(0, 0);
      // history.push('/ginipig-profile');
    }   
    setIsData(false)
    }
    
  }, 2);
  }

  const registerData = useSelector(state => state.register);
  const Data = JSON.stringify(registerData);
  const checkemailaexistsData = useSelector(state => state.checkemailaexists);
   // Use effects Similar to componentDidMount and componentDidUpdate:
   useEffect(() => {
     if(ginipigUpdateData.ginipigdata !== null)
     {
      updateFormData({
          ...formData,
          first_name:ginipigUpdateData.ginipigdata.first_name,
          last_name:ginipigUpdateData.ginipigdata.last_name,
          username:ginipigUpdateData.ginipigdata.username,
          email:ginipigUpdateData.ginipigdata.email,
          mobile:ginipigUpdateData.ginipigdata.telephone_no,
          profile_pic:ginipigUpdateData.ginipigdata.profile_pic !== "" ? ginipigUpdateData.ginipigdata.profile_pic : "",
           profile_pic_link:ginipigUpdateData.ginipigdata.profile_pic !== "" ? ginipigUpdateData.ginipigdata.profile_pic : "",
            
        },[]);

     }
    else if(loginUserData.logindata!==null)
    {
      updateFormData({
                ...formData,
                first_name:userData.userdetailsdata.data.user_details.first_name,
                last_name:userData.userdetailsdata.data.user_details.last_name,
                username:userData.userdetailsdata.data.user_details.username,
                email:userData.userdetailsdata.data.user_details.email,
                mobile:userData.userdetailsdata.data.user_details.telephone_no,
                profile_pic:userData.userdetailsdata.data.user_details.profile_pic !== "" ? userData.userdetailsdata.data.user_details.profile_pic : "",
                profile_pic_link:userData.userdetailsdata.data.user_details.profile_pic !== "" ? userData.userdetailsdata.data.user_details.profile_pic : "",
            
      },[]);

    } else if (LocalDatatoken !== "") {
      updateFormData({
        ...formData,
        first_name:LocalDatatoken.user.first_name,
        last_name:LocalDatatoken.user.last_name,
        username:LocalDatatoken.user.username,
        email:LocalDatatoken.user.email,
        mobile:LocalDatatoken.user.telephone_no,
        profile_pic:LocalDatatoken.user.profile_pic != "" ? LocalDatatoken.user.profile_pic : "",
        profile_pic_link:LocalDatatoken.user.profile_pic != "" ? LocalDatatoken.user.profile_pic : "",
    
      },[]);
    } 
    else if(registerData.registerdata && registerData.registerdata.data && registerData.registerdata.data.user!="") {
      updateFormData({
        ...formData,
        first_name:registerData.registerdata.data.user.first_name,
        last_name:registerData.registerdata.data.user.last_name,
        username:registerData.registerdata.data.user.username,
        email:registerData.registerdata.data.user.email,
        mobile:registerData.registerdata.data.user.telephone_no,
        profile_pic:registerData.registerdata.data.user.profile_pic != "" ? registerData.registerdata.data.user.profile_pic : "",
        profile_pic_link:registerData.registerdata.data.user.profile_pic != "" ? registerData.registerdata.data.user.profile_pic : "",
    
      },[]);
    } 
    else {
      window.scrollTo(0, 0);
      history.push('/login');
    }
  }, []);

  
 const __onTextChange = (e) => {
  updateFormData({
    ...formData,
    [e.target.name]: e.target.value
  });
};

const deleteImg=()=>{
  
  updateFormData({
      ...formData,
      profile_pic: "",
      profile_pic_link: "",
      
  });
}

const __onImageChange = e => {
    
      if(e.target.files[0] != undefined)
    {
      // updateFormData({
      //   ...formData,
      //   [e.target.name]: e.target.files[0],
      //   file: URL.createObjectURL(e.target.files[0]),
      // });

      updateFormData({
        ...formData,
        [e.target.name]: e.target.files[0],
        profile_pic_link:URL.createObjectURL(e.target.files[0]),
      });
    }
  }

  const checkMobileValidation = (e) => {
    const {id,value} = e.target;
    var decimal=  /^[0][1-9]\d{9}$|^[1-9]\d{9}$/;
    
    if(!value){
        updateErrorData({
            ...errorData,
            is_form_valid:false,
            phonenumber_error: "Phone Number Is Required."
          });
    }else if(!value.match(decimal)) 
    { 
        updateErrorData({
            ...errorData,
            is_form_valid:false,
            phonenumber_error: "Phone number must start with 0 and must be 11 digits."
          });
    }else if(value.length!==11) 
    { 
        updateErrorData({
            ...errorData,
            is_form_valid:false,
            phonenumber_error: "Phone number must start with 0 and must be 11 digits."
          });
    }  else{
        updateErrorData({
            ...errorData,
            is_form_valid:true,
            phonenumber_error: ""
          });
    }
}

const __checkIsEmailExist = useCallback(async () => {
  try {
    let checkToken="";
      const loginToken=loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token: "" : "";
      // const PractitionerToken= practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.token:"" : "";
      const RegisterToken= registerData.registerdata ? registerData.registerdata.data ? registerData.registerdata.data.token:"" : "";
      if(loginToken != "")
      {
        checkToken = loginToken;
      }  else if(RegisterToken != "")
      {
        checkToken = RegisterToken;
      } else if (LocalDatatoken !== "") {
        checkToken = LocalDatatoken.token;
      }

    // const loginToken=loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token: "" : "";
    await dispatch(checkemailExistsEditAPI_Action(formData,checkToken));
  } catch (error) {
    console.error(" profile ::  profile() :: Exception ==", error);
  }
});


const checkEmailValidation = async(e) => {
  if(ginipigProfileData.isError===true)
  {
      dispatch(resetgoginipigprofileAPI_Action());
  }

  const {id,value} = e.target;

  if(!value){
      updateErrorData({
          ...errorData,
          is_form_valid:false,
          email_error: "Email is Required."
        });
   } else {
      updateErrorData({
          ...errorData,
          is_form_valid:true,
          email_error: ""
        });
   }

   if(typeof value !== "undefined"){
      let lastAtPos = value.lastIndexOf('@');
      let lastDotPos = value.lastIndexOf('.');

      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && value.indexOf('@@') == -1 && lastDotPos > 2 && (value.length - lastDotPos) > 2)) {
      
      if(!value){
          updateErrorData({
              ...errorData,
              is_form_valid:false,
              email_error: "Email is Required."
            });
       }
       else {
          updateErrorData({
              ...errorData,
              is_form_valid:false,
              email_error: "Email is not valid."
            });
       }
      
       } else {
           let data = {
              email:formData.email
           };
          // let res = await checkemailExistsAPI_Action(formData);
          __checkIsEmailExist();
          // updateErrorData({
          //     ...errorData,
          //     is_form_valid:true,
          //     email_error: ""
          //   });
       }
  } else if(value.length>50)
  {
     updateErrorData({
         ...errorData,
         is_form_valid:false,
         email_error: "Only 50 Characters are Allowed."
       });
  } else if(value.length<6)
  {
     updateErrorData({
         ...errorData,
         is_form_valid:false,
         email_error: "Minimum 6 Characters are Allowed."
       });
  }  else {
      updateErrorData({
          ...errorData,
          is_form_valid:true,
          email_error: ""
        });
  }
};

const checkFirstNameValidation = (e) => {
  const {id,value} = e.target;
  if(!value){
      updateErrorData({
          ...errorData,
          is_form_valid:false,
          firstname_error: "First Name Is Required."
        });
   } else if(value.length>50)
   {
      updateErrorData({
          ...errorData,
          is_form_valid:false,
          firstname_error: "Only 50 Characters are Allowed."
        });
   //} else if(!value.match(/^[a-zA-Z]+$/)){
   } else if(!value.match(/^[a-zA-Z\-\s]+$/)){
      updateErrorData({
          ...errorData,
          is_form_valid:false,
          firstname_error: "Oops, you've added an incorrect character! Letters only "
        });
   }  else {
      updateErrorData({
          ...errorData,
          is_form_valid:true,
          firstname_error: ""
        });
   }

  
}


const checkLastNameValidation = (e) => {
  const {id,value} = e.target;
  if(!value){
      updateErrorData({
          ...errorData,
          is_form_valid:false,
          lastname_error: "Last Name Is Required."
        });
   } else if(value.length>50)
   {
      updateErrorData({
          ...errorData,
          is_form_valid:false,
          lastname_error: "Only 50 Characters are Allowed."
        });
   //} else if(!value.match(/^[a-zA-Z]+$/)){
   } else if(!value.match(/^[a-zA-Z\-\s]+$/)){
      updateErrorData({
          ...errorData,
          is_form_valid:false,
          lastname_error: "Oops, you've added an incorrect character! Letters only "
        });
   }else {
      updateErrorData({
          ...errorData,
          is_form_valid:true,
          lastname_error: ""
        });
   }

  
}

  const __apiCall = useCallback(async () => {
    try {
      let checkToken="";
      const loginToken=loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token: "" : "";
      // const PractitionerToken= practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.token:"" : "";
      const RegisterToken= registerData.registerdata ? registerData.registerdata.data ? registerData.registerdata.data.token:"" : "";
      
      if(loginToken != "")
      {
        checkToken=loginToken;
      }  else if(RegisterToken != "")
      {
        checkToken=RegisterToken;
      } else if (LocalDatatoken !== "") {
        checkToken = LocalDatatoken.token;
      }
     // console.log(formData)
      // const loginToken=loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token: "" : "";
      await dispatch(goginipigprofileAPI_Action(formData,checkToken));
       //await dispatch(resetgoginipigprofileAPI_Action());
      // history.push('/user-details');
      setIsData(true)
    } catch (error) {
      console.error(" profile ::  profile() :: Exception ==", error);
    }
  });

  const __fetchapiCall = useCallback(async () => {
    try {
      const loginToken = loginUserData.logindata
        ? loginUserData.logindata.data
          ? loginUserData.logindata.data.token
          : ""
        : "";
      if (loginToken !== "") {
        await dispatch(userdetailsAPI_Action(loginToken));
      }
    } catch (error) {
      console.error(" profile ::  profile() :: Exception ==", error);
    }
  });

  const __submitGinipigProfileForm = async (e) => {
    e.preventDefault();
    await dispatch(resetGinipigUpdateAPI_Action());
    __apiCall();
  };


  const __redirectScrollTop = () =>{
    window.scrollTo(0, 0);
  }


//   useEffect(()=>{
//    let error1 = () =>{  errorData.email_error && errorData.email_error != "" ? (
//              setErrorMSG(errorData.email_error) 
//          ) : null
//     }
     
//     let err2 = () =>{
//         checkemailaexistsData.isError==true && checkemailaexistsData.checkemailexistsdata.status_code!=200 && checkemailaexistsData.checkemailexistsdata.message !="" ? (
//           setErrorMSG(checkemailaexistsData.checkemailexistsdata.message)
//       ) : null
//     }  

//     let err3 = () =>{
//         ginipigProfileData.ginipigprofiledata && ginipigProfileData.ginipigprofiledata.hasOwnProperty('errors') ? (
//           ginipigProfileData.ginipigprofiledata.errors.hasOwnProperty('email') ? (
//             setErrorMSG(ginipigProfileData.ginipigprofiledata.errors.email[0]) 
//         ) : null 
        
//         ) : null 
//     }
    
//     error1();
//     err2();
//     err3();

//  },[errorData,checkemailaexistsData,ginipigProfileData])
 
 


  return (
    <>
      <section className="bgcolor-gray profile-box">
        <Container>
          <Row>
            <Col className="col-lg-11 m-auto">
              <div className="heading-history">
                <div className="back-history">
                  <button
                    type="button"
                    className="back-btn"
                    onClick={() => history.goBack()}
                  >
                    {" "}
                    <img src={LeftArrow} alt="arrow" />{" "}
                  </button>
                </div>
                <div className="history-title">
                  <h2> Edit Your Information </h2>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="profilebox profile-edit">
          <form onSubmit={__submitGinipigProfileForm}>
            <div className="inside-profile">
              <div className="align-left">
                <div className="fromProfile profile-wrp boxedit mb-24">
                  <div className="profilefield">
                    <div className="showWithLabal">
                    <label className="profile-label">First name</label>

                    { 
                      errorData.firstname_error && errorData.firstname_error!="" ? (
                          <small className="error" >
                          
                          <p style={{color:"red"}}>{errorData.firstname_error} </p>
                          </small>
                          
                      ) : null 
                  }


                { 
                    ginipigProfileData.ginipigprofiledata && ginipigProfileData.ginipigprofiledata.hasOwnProperty('errors') ? (
                        <small className="error">
                        
                        {ginipigProfileData.ginipigprofiledata.errors.hasOwnProperty('first_name') ? (
                            <p style={{color:"red"}}>{ginipigProfileData.ginipigprofiledata.errors.first_name[0]} </p>
                        ) : null }
                        </small>
                        
                    ) : null 
                    }
                    </div>
                    <input
                      type="text"
                      id="first_name"
                      name="first_name"
                      value={formData.first_name}
                      onChange={__onTextChange}
                      // onKeyUp={checkFirstNameValidation}
                      onBlur={checkFirstNameValidation}
                      autoComplete="off"
                      maxLength="50"
                      required
                    />
                  </div>
                  <div className="profilefield">
                    <div className="showWithLabal">
                    <label className="profile-label">Last name</label>

                    { 
                      errorData.lastname_error && errorData.lastname_error!="" ? (
                              <small className="error" >
                              
                              <p style={{color:"red"}}>{errorData.lastname_error} </p>
                              </small>
                              
                          ) : null 
                      }

                      { 
                      ginipigProfileData.ginipigprofiledata && ginipigProfileData.ginipigprofiledata.hasOwnProperty('errors') ? (
                          <small className="error">
                          
                          {ginipigProfileData.ginipigprofiledata.errors.hasOwnProperty('last_name') ? (
                              <p style={{color:"red"}}>{ginipigProfileData.ginipigprofiledata.errors.last_name[0]} </p>
                          ) : null }
                          </small>
                          
                      ) : null 
                      }
                      </div>
                    <input
                      type="text"
                      id="last_name"
                      name="last_name"
                      value={formData.last_name}
                      onChange={__onTextChange}
                      // onKeyUp={checkLastNameValidation}
                      onBlur={checkLastNameValidation}
                      autoComplete="off"
                      maxLength="50"
                      required
                    />
                  </div>
                  <div className="profilefield">
                  <div className="showWithLabal">
                    <label className="profile-label">Contact number</label>
                    { 
                      errorData.phonenumber_error && errorData.phonenumber_error!="" ? (
                          <small className="error" >
                          
                          <p style={{color:"red"}}>{errorData.phonenumber_error} </p>
                          </small>
                          
                      ) : null 
                      }

                      { 
                      ginipigProfileData.ginipigprofiledata && ginipigProfileData.ginipigprofiledata.hasOwnProperty('errors') ? (
                          <small className="error">
                          
                          {ginipigProfileData.ginipigprofiledata.errors.hasOwnProperty('mobile') ? (
                              <p style={{color:"red"}}>{ginipigProfileData.ginipigprofiledata.errors.mobile[0]} </p>
                          ) : null }
                          </small>
                          
                      ) : null 
                      }
                      </div>
                    <input
                      type="number"
                      id="mobile"
                      name="mobile"
                      value={formData.mobile}
                      onChange={__onTextChange}
                      // onKeyUp={checkMobileValidation}
                      onBlur={checkMobileValidation}
                      required
                      minLength="10"
                      maxLength="11" 
                    />
                  </div>
                  <div className="profilefield">
                  <div className="showWithLabal">
                    <label className="profile-label"> Email address  </label>
                    {/* {errorMSG && <small className="error" >
                        <p style={{ color: "red" }}>{errorMSG}</p>
                    </small>} */}
                    { 
                    errorData.email_error && errorData.email_error!="" ? (
                        <small className="error" >
                        <p style={{color:"red"}}>{errorData.email_error} </p>
                        </small>
                        
                    ) : null 
                    }

                    {/* { 
                    ginipigProfileData.ginipigprofiledata && ginipigProfileData.ginipigprofiledata.hasOwnProperty('errors') ? (
                        <small className="error" >
                        
                        {ginipigProfileData.ginipigprofiledata.errors.hasOwnProperty('email') ? (
                            <p style={{color:"red"}}>{ginipigProfileData.ginipigprofiledata.errors.email[0]} </p>
                        ) : null }
                        </small>
                        
                    ) : null 
                    } */}

                    {
                        checkemailaexistsData.isError==true &&
                        checkemailaexistsData.checkemailexistsdata.status_code!=200 &&
                        checkemailaexistsData.checkemailexistsdata.message !="" ? (
                        <small className="error">
                            <p style={{color:"red"}}>{checkemailaexistsData.checkemailexistsdata.message} </p>
                        </small>
                        ) : null
                    }
                    </div>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={__onTextChange}
                      // onKeyUp={checkEmailValidation}
                      onBlur={checkEmailValidation}
                      autoComplete="off"
                      required 
                      minLength="6"
                      maxLength="50"
                    />
                  </div>
                </div>

                <div className="profile-wrp media-profile boxedit-profile">
                  <div className="inside-media">
                    <div className="media-img">
                    
                    {
                      // ginipigUpdateData.ginipigdata && ginipigUpdateData.ginipigdata.profile_pic != "" ? (
                      //  <> <img src={ginipigUpdateData.ginipigdata.profile_pic} alt="avtar" />
                      //   <img src={iconClose} className="remove-icon"  onClick={deleteImg} />
                      //   </>
                      // ):userData.userdetailsdata && userData.userdetailsdata.data.user_details.profile_pic !="" ? (
                      //   <> <img src={userData.userdetailsdata.data.user_details.profile_pic} alt="avtar" />
                      //   <img src={iconClose} className="remove-icon"  onClick={deleteImg} />
                      //   </>
                      // ) : registerData.registerdata && registerData.registerdata.data.user.profile_pic !="" ? (
                      //   <> <img src={registerData.registerdata.data.user.profile_pic} alt="avtar" />
                      //   <img src={iconClose} className="remove-icon"  onClick={deleteImg} />
                      //   </>
                      // ) : null

                      formData.profile_pic_link !== "" ? (
                        <> <img src={formData.profile_pic_link} alt="avtar" />
                        <img src={iconDelete_peach} className="remove-icon"  onClick={deleteImg} />
                        </>
                      ) : <img src={ginipic_avtar} className="img_avtar" />
                    }
                      
                    </div>
                    <div className="media-content">
                      <div className="media-decs">
                        <h3> Profile Picture </h3>
                        {/* <p>
                          {" "}
                          You can skip this for now but will need a profile
                          photo before making a booking.{" "}
                        </p> */}
                        <p>Some Providers maybe needing a particular look or specific criteria! Therefore we would encourage you to put the most up to date picture of yourself to ensure perfect matching.</p>
                      </div>
                      <div className="media-btns">
                      {
                        formData.profile_pic_link ==""?
                        <button type="button" className="upload-profile">
                        {" "}
                        Upload New
                      </button>:
                        <button type="button" className="upload-profile">
                        {" "}
                        Edit
                      </button>
                      }
                        <div className="uploadt-btn-input">
                          <input
                            type="file"
                            id="profile_pic"
                            name="profile_pic"
                            onChange={__onImageChange}
                          />
                        </div>
                      </div>

                      {ginipigProfileData.ginipigprofiledata &&
                        ginipigProfileData.ginipigprofiledata.hasOwnProperty("errors") ? (
                          <small className="error">
                            {ginipigProfileData.ginipigprofiledata.errors.hasOwnProperty(
                              "profile_pic"
                            ) ? (
                              <p style={{ color: "red" }}>
                                {
                                  ginipigProfileData.ginipigprofiledata.errors
                                    .profile_pic[0]
                                }{" "}
                              </p>
                            ) : null}
                          </small>
                        ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="align-right"></div>
            </div>

            <div className="Profilebtn">
              <div className="Profilebtn-box">
                <Link to="/Profile-ChangePassword" onClick={__redirectScrollTop} className="passwd-btn">
                  Change Password
                </Link>
                {/* <button type="submit" className="save-btn">
                  {" "}
                  Save Changes{" "}
                </button> */}
                {
                    // ginipigProfileData.isFetching ? <button className="save-btn" disabled={true}> Loading </button>  : <button type="submit" className="save-btn" disabled={errorData.is_form_valid === true && loginUserData.isError === false && checkemailaexistsData.isError==false && checkemailaexistsData.checkemailexistsdata.status_code==200 && checkemailaexistsData.checkemailexistsdata.message !=="" ? false : true}> {" "}
                    // Save Changes{" "}</button> 

                    ginipigProfileData.isFetching ? 
                    <button className="save-btn" disabled={true}> Loading </button>  : 
                    <button type="submit" className="save-btn"  disabled={errorData.is_form_valid === true  ? false : true}> {" "}
                    Save Changes{" "}</button> 
                } 
              </div>
            </div>
          </form>
        </div>
      </section>

      {/* <div>
              <code>
              {
                  JSON.stringify(registerData.isError)===true ? JSON.stringify(registerData.registerdata.message):""
              }
            </code>
            <label>{JSON.stringify(registerData.isError)===true ? Data.registerdata.message : ""}</label>
            <code>
              {
                  JSON.stringify(registerData)
              }
            </code>
          </div> */}

            <div className="model">
                <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                  </Modal.Header>
                  <Modal.Body> congratulations Your Ginipig Profile are updated.</Modal.Body>
                  <Modal.Footer>
                    <button variant="primary" onClick={handleClose}>
                      Ok
                    </button>
                  </Modal.Footer>
                </Modal>
            </div>


    </>
  );
};

export default GinipigProfileUpdate;
