import * as types from "../types/index";
import { setInitialState } from "../InitialState";


const INITIAL_STATE = setInitialState({
    practportfolioaddimagedata: null,
});


export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.PRACTPORTFOLIOADDIMAGE_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case types.PRACTPORTFOLIOADDIMAGE_RECEIVE:
            return {
                ...state,
                isFetching: false,
                isSuccess: true,
                practportfolioaddimagedata: action.payload.success
            };
        case types.PRACTPORTFOLIOADDIMAGE_FAILURE:
            return {
                ...state,
                isFetching: false,
                isError: true,
                practportfolioaddimagedata: action.payload.response.error,
            };
        case types.RESET_PRACTPORTFOLIOADDIMAGE:
            return {
                ...state,
                isFetching: false,
                isError: false,
                isSuccess: false,
                practportfolioaddimagedata: null,
            };
        default:
            return {
                ...state
            }
    }
}