import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS } from '../../config/ApiConstants';
export function forgotpasswordAPI_Action(data) {
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/forgot-password`,
            method: 'POST',
            headers: {
                ...AUTH_HEADERS,
            },
            body:JSON.stringify(data),
            types: [
                types.FORGOTPASSWORD_REQUEST,
                types.FORGOTPASSWORD_RECEIVE, {
                    type: types.FORGOTPASSWORD_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}


export const resetForgotPasswordAPI_Action = () => {
    return {
        type: types.RESET_FORGOTPASSWORD,
        payload: null
    }
};
