import React from 'react';
import { Link } from 'react-router-dom';
import { LeftArrow } from '../../config/images';
import LoadingSpinner from "../../components/UIComponent/LoadingSpinner"


const PractitionerPropfileLink = (props) => {
    return (
        <>
            <div className="box_tab mb-16">

                {props.upcomingBooking === false &&
                    <div className="inside_tabbox">
                        <Link to={{ pathname: props.redirectLink, RedirectPath: "PractitionerAccoutnt" }} className="a_text">
                            <span>{props.linkText}</span>

                            <span className="rightarrow">
                                <img src={LeftArrow} className="icon_arrow" alt="icon" />
                            </span>
                        </Link>
                    </div>
                }
                {props.paymentdetails === true &&
                    <div className="inside_tabbox">
                      
                        <a className="a_text" target="_blank" href={props.linktopayment}>
                            <span>{props.linkText}</span>

                            <span className="rightarrow">
                                <img src={LeftArrow} className="icon_arrow" alt="icon" />
                            </span>
                        </a>
                    </div>
                }
                {
                    props.upcomingBooking === true &&
                    <div className="inside_tabbox" onClick={props.upcomingBookingClick}>
                        <Link className="a_text test-deco-none">
                            <span>{props.linkText}</span>
                            {
                                props.showLoading &&
                                <LoadingSpinner />
                            }
                            <span className="rightarrow">
                                <img src={LeftArrow} className="icon_arrow" alt="icon" />
                            </span>
                        </Link>

                    </div>
                }

            </div>
        </>
    );
}

export default PractitionerPropfileLink;