import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS } from '../../config/ApiConstants';


export function practitionerProfileAPI_Action(token) {
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/practitioner/profile`,
            method: 'GET',
            headers: {
                ...AUTH_HEADERS,
                'Authorization':'Bearer '+token
            },
            body: '',
            types: [
                types.PRACTITIONERPROFILE_REQUEST,
                types.PRACTITIONERPROFILE_RECEIVE, {
                    type: types.PRACTITIONERPROFILE_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}

export function ginipigPractitionerProfileAPI_Action(id,ginipig_id) {
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/ginipig/practitioner/details/${id}`+`?ginipig_id=`+ginipig_id,
            method: 'GET',
            headers: {
                ...AUTH_HEADERS,
            },
            body: '',
            types: [
                types.PRACTITIONERPROFILE_REQUEST,
                types.PRACTITIONERPROFILE_RECEIVE, {
                    type: types.PRACTITIONERPROFILE_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}

export function practitionerFav(id,token){
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/ginipig/favourite/practitioner/${id}`,
            method: 'GET',
            headers: {
                ...AUTH_HEADERS,
                'Authorization':'Bearer '+token
            },
            body: '',
            types: [
                types.PRACTITIONERPROFILEFAV_REQUEST,
                types.PRACTITIONERPROFILEFAV_RECEIVE, {
                    type: types.PRACTITIONERPROFILEFAV_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
} 


export const resetpractitionerProfileAPI_Action = () => {
    return {
        type: types.RESET_PRACTITIONERPROFILE,
        payload: null
    }
};

export const resetpractitionerFavData_Action = () => {
    return {
        type: types.RESETPRACTITIONERFAV,
        payload: null
    }
};
