import React, { Component } from "react";
import { Container, Row, Form } from "react-bootstrap";
import Beneftbox from "./Beneftbox";

import { calendarIcon,specialOffer, star, Charity , gp2,gp3,prac1,lp_icon3,lp_icon2,lp_icon1 } from "../../../config/images";

const Benefitsection = ()=> {

    return (
      <>
      <section className="benefit-pigs">
        <Container>
          <Row>
            <div className="benefititle-box">
                <h3>You'll Love Us</h3>  
            </div> 
            <div className="col-lg-10 m-auto">
              <div className="benefit-grid"> 
              <div className="benefit-item">
                <div className="benefit-icon iconset1">
                  <img src={lp_icon1} alt="icon" />
                </div>
                <div className="subheading">
                  <h3 className="subheading-text">Newbies to Pro's</h3>
                </div>
                <div className="benefit-desc">Connecting you to our Gorgeous Ginipigs</div>
              </div>
              <div className="benefit-item">
                <div className="benefit-icon iconset2">
                  <img src={lp_icon3} alt="icon" />
                </div>
                <div className="subheading">
                  <h3 className="subheading-text"> Without The Price Tags </h3>
                </div>
                <div className="benefit-desc"> Must haves from only £7.50 & always a min 30% OFF! </div>
              </div>
              <div className="benefit-item">
                <div className="benefit-icon iconset3">
                  <img src={lp_icon2} alt="icon" />
                </div>
                <div className="subheading">
                  <h3 className="subheading-text"> Your Fairy Godmothers </h3>
                </div>
                <div className="benefit-desc"> Let's fill those last minute appointments </div>
              </div>
              </div>
            </div>
          </Row>
        </Container>
      </section>
      </>
    );
  }

export default Benefitsection;
