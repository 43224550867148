import React from 'react';
import Rating from 'react-rating';
import { LeftArrow,star,icon_unselected_star } from '../../config/images';
const RatingPointCard = (props) => {

    

    return (
        <>
            <div className="rating_point_box">
                <div className="rading-item">
                    <p>{props.ratingName}</p>
                    <Rating 
                        emptySymbol={<img src={props.unselectedimage} className="icon" />}
                        onChange={props.handleChangeRating}
                        initialRating={props.initialRating}
                        fullSymbol={<img src={props.selectedimage} className="icon" />}
                        readonly={props.disabled}
                    />
                </div>
            </div>  
        </>
    );
}

export default RatingPointCard;