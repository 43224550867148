import * as types from "../types/index";
import { setInitialState } from "../InitialState";


const INITIAL_STATE = setInitialState({
    appointmentcreatedata: null,
});


export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.APPOINTMENT_SAVE_DATA:
            return Object.assign({}, state, {
                appointmentcreatedata: action.appointmentCreateData,
                selected_time_slots:action.selected_time_slots
            });
        case types.RESET_APPOINTMENT_SAVE_DATA:
            return { ...INITIAL_STATE }
        default:
            return state;

        
    }
}