import React, { useCallback, useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from 'react-bootstrap';
import { LeftArrow, iconClose,iconPhoto } from '../../config/images';
import ImageUpload from '../../components/ImageUpload';
import LargeButton from '../../components/LargeButton';
import { requestDetailsAPI_Action,treatment_details,photoDetailsSave, imageUploadAPI_Action, resetimageUploadAPI_Action, resetopenrequestbookingdetailsAPI_Action, resetrequestdetailsAPI_Action, } from '../../stores/actions'
import { getLocalStorageData, clearLocalStorage,setLocalStorageData } from '../../utils/storage'

const initialErrorData = Object.freeze({
    rrp_error: "",
    your_price_error: "",
    image_type_error: "",
    is_form_valid: true,
    hrs_error: "",
    is_form_valid_hrs: false,

    is_form_valid_rrp: true,
    is_form_valid_your_price: true,
    is_form_valid_rate: true,
    is_form_valid_reason: true,
    is_form_valid_expertise: true
});

const PhotoRequirement = (props) =>{

    let history = useHistory();
    const dispatch = useDispatch(); 
    const loginUserData = useSelector(state => state.login);
    const upload_result = useSelector(state => state.whatsavailabledetails);
    const imageUploaddata = useSelector(state => state.imageUploaddata);

    const [errorDataModel,setErrorDataModel]= useState(null)
    const [loader,setLoader]= useState(false)
    const [errorModel,setErrorModel]= useState(false)

    var param_data = props.match.params.id;

    const __nextBooking = () => {
        window.scrollTo(0,0);
        history.push('/view-booking/1')
    }

    useEffect(() => {
        if(imageUploaddata.isFetching){
            setLoader(true)
        }else if(imageUploaddata.isSuccess && imageUploaddata.data !== null) {
            setLoader(false)
        }else if (imageUploaddata.isError) {
            setErrorModel(true);
            setErrorDataModel(imageUploaddata.imageUploaddata);
        }
    }, [imageUploaddata])
        


   // console.log("upload_result ", JSON.stringify(upload_result))
    
    const [formData, updateFormData] = useState(Object.freeze({
        file1: "",
        file2: "",
        file3: "",
        file4: "",
        imagearray1: [],
        imagearray2: [],
        imagearray3: [],
        imagearray4: [],
    }));
    const [errorData, updateErrorData] = useState(initialErrorData);
    const [disableContinue, setdisableContinue] = useState(true);
    const requestdetails = useSelector(state => state.requestdetails); 
    const [isData, setIsData] = useState(false);
    const [currentimageId, setcurrentimageId] = useState(1);

 // console.log("requestdetails.requestdetailsdata ", requestdetails)

    useEffect(() => {
        clearLocalStorage('book_close_request');
        //clearLocalStorage('update_ginipig_profile');
        
        __apiCall()
        if(requestdetails){
            if(requestdetails.hasOwnProperty('photoDetails')){
                if(requestdetails.photoDetails !== null){
                updateFormData({
                    ...formData,
                    file1: requestdetails.photoDetails.file1,
                    file2: requestdetails.photoDetails.file2,
                    file3: requestdetails.photoDetails.file3,
                    file4: requestdetails.photoDetails.file4,
                    imagearray1: requestdetails.photoDetails.imagearray1,
                    imagearray2: requestdetails.photoDetails.imagearray2,
                    imagearray3: requestdetails.photoDetails.imagearray3,
                    imagearray4: requestdetails.photoDetails.imagearray4,
                  });
                  setdisableContinue(false)
                }
            }            
        } 

        //localstorage data
        //    var photo_details = '';
        //   // console.log('photo details ', getLocalStorageData("photo_requirement_available") )  
        //    if(getLocalStorageData("photo_requirement_available") !== undefined){
        //     photo_details = JSON.parse(getLocalStorageData("photo_requirement_available"));
        //    // console.log('photo details ', photo_details)  
        //     if(photo_details !== null){
        //         updateFormData({
        //             ...formData,
        //             file1: photo_details.file1,
        //             file2: photo_details.file2,
        //             file3: photo_details.file3,
        //             file4: photo_details.file4,
        //             imagearray1: photo_details.imagearray1,
        //             imagearray2: photo_details.imagearray2,
        //             imagearray3: photo_details.imagearray3,
        //             imagearray4: photo_details.imagearray4,
        //           });
        //           setdisableContinue(false)
        //     }
        //   }
                   
    },[]);

    const __apiCall = useCallback(async () => {
        const loginToken=loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token: "" : "";
        const ginpig_id = loginUserData.logindata ? loginUserData.logindata.data && loginUserData.logindata.data.user.type === "ginipig" ? loginUserData.logindata.data.user.uuid : 0:0;
       // console.log("logindata:-",loginUserData);
        try {    
           
          await dispatch(requestDetailsAPI_Action(param_data,loginToken,ginpig_id));          
        } catch (error) {
          console.error(" profile ::  profile() :: Exception ==", error);
        }
      });

    const deleteImg1 = () => {
        updateFormData({
            ...formData,
            file1: "",
            imagearray1: []
        });
       // console.log("formData.file1 " , formData.imagearray1,' length ', formData.imagearray1.length, ' files2 ', formData.file2)
        if(formData.file2 === '' && formData.file3 === '' && formData.file4 === ''){
            setdisableContinue(true)
        }
    }

    const deleteImg2 = () => {
        updateFormData({
            ...formData,
            file2: "",
            imagearray2: []
        });
        if(formData.file1 === '' && formData.file3 === '' && formData.file4 === ''){
            setdisableContinue(true)
        }
    }
    const deleteImg3 = () => {
        updateFormData({
            ...formData,
            file3: "",
            imagearray3: []
        });
        if(formData.file1 === '' && formData.file2 === '' && formData.file4 === ''){
            setdisableContinue(true)
        }
    }
    const deleteImg4 = () => {
        updateFormData({
            ...formData,
            file4: "",
            imagearray4: []
        });
        if(formData.file1 === '' && formData.file3 === '' && formData.file3 === ''){
            setdisableContinue(true)
        }
    }

    const handleFileChange1 = (e) => {
       // console.log(e.target.files[0])
       e.preventDefault();
       dispatch(resetimageUploadAPI_Action());
       setErrorDataModel(null);
       setcurrentimageId(1)

        let filetype = e.target.files[0].type.substring(e.target.files[0].type.lastIndexOf('/'))
        let newfiletype = filetype.replace("/", "")
        if (newfiletype == "jpeg" || newfiletype == "jpg" || newfiletype == "png") {

            let imageArray = []
            if (e.target.files[0] != undefined) {
                let image = e.target.files[0]
                dispatch(imageUploadAPI_Action(image));
                setdisableContinue(true)
                // imageArray.push(e.target.files[0])
                // updateFormData({
                //     ...formData,
                //     imagearray1: imageArray,
                //     file1: URL.createObjectURL(e.target.files[0]),

                // });

            }
         
            updateErrorData({
                ...errorData,
                is_form_valid: true,
                image_type_error: "",
            });
        }
        else {
            updateErrorData({
                ...errorData,
                is_form_valid: false,
                image_type_error: "Only JPEG,JPG,PNG file extentions are allowed.",
            });

        }

    };

    
    const handleFileChange2 = (e) => {
        e.preventDefault();
        dispatch(resetimageUploadAPI_Action());
        setErrorDataModel(null);
        setcurrentimageId(2)

        let filetype = e.target.files[0].type.substring(e.target.files[0].type.lastIndexOf('/'))
        let newfiletype = filetype.replace("/", "")
        if (newfiletype == "jpeg" || newfiletype == "jpg" || newfiletype == "png") {
            let imageArray = []
            if (e.target.files[0] != undefined) {
                let image = e.target.files[0]
                dispatch(imageUploadAPI_Action(image));
                setdisableContinue(true)
                // imageArray.push(e.target.files[0])
                // updateFormData({
                //     ...formData,
                //     imagearray2: imageArray,
                //     file2: URL.createObjectURL(e.target.files[0]),

                // });

            }
          //  setdisableContinue(false)
            updateErrorData({
                ...errorData,
                is_form_valid: true,
                image_type_error: "",
            });
        }
        else {
            updateErrorData({
                ...errorData,
                is_form_valid: false,
                image_type_error: "Please upload only JPEG,JPG and PNG file.",
            });

        }

    };

    const handleFileChange3 = (e) => {
        e.preventDefault();
        dispatch(resetimageUploadAPI_Action());
        setErrorDataModel(null);
        setcurrentimageId(3)

        let filetype = e.target.files[0].type.substring(e.target.files[0].type.lastIndexOf('/'))
        let newfiletype = filetype.replace("/", "")
        if (newfiletype == "jpeg" || newfiletype == "jpg" || newfiletype == "png") {
            let imageArray = []
            if (e.target.files[0] != undefined) {
                let image = e.target.files[0]
                setdisableContinue(true)
                dispatch(imageUploadAPI_Action(image));
                // imageArray.push(e.target.files[0])
                // updateFormData({
                //     ...formData,
                //     imagearray3: imageArray,
                //     file3: URL.createObjectURL(e.target.files[0]),

                // });

            }
         //   setdisableContinue(false)
            updateErrorData({
                ...errorData,
                is_form_valid: true,
                image_type_error: "",
            });
        }
        else {
            updateErrorData({
                ...errorData,
                is_form_valid: false,
                image_type_error: "Please upload only JPEG,JPG and PNG file.",
            });

        }

    };
    const clickonFile= e => {
        e.target.value=""
    }
    const handleFileChange4 = (e) => {
        e.preventDefault()
        dispatch(resetimageUploadAPI_Action());
        setErrorDataModel(null);
        setcurrentimageId(4)

        let filetype = e.target.files[0].type.substring(e.target.files[0].type.lastIndexOf('/'))
        let newfiletype = filetype.replace("/", "")
        if (newfiletype == "jpeg" || newfiletype == "jpg" || newfiletype == "png") {
            let imageArray = []
           if (e.target.files[0] != undefined) {
                let image = e.target.files[0]
                setdisableContinue(true)
                dispatch(imageUploadAPI_Action(image));
                // imageArray.push(e.target.files[0])
                // updateFormData({
                //     ...formData,
                //     imagearray4: imageArray,
                //     file4: URL.createObjectURL(e.target.files[0]),

                // });

            }
            //setdisableContinue(false)
            updateErrorData({
                ...errorData,
                is_form_valid: true,
                image_type_error: "",
            });
        }
        else {
            updateErrorData({
                ...errorData,
                is_form_valid: false,
                image_type_error: "Please upload only JPEG,JPG and PNG file.",
            });

        }

    };

    
    if (imageUploaddata !== null && imageUploaddata.isSuccess) {
      // console.log(imageUploaddata)

        if (currentimageId == 1) {
            setdisableContinue(false)
            updateFormData({
                ...formData,
                file1: imageUploaddata.imageUploaddata.data.image_link,
                imagearray1:[imageUploaddata.imageUploaddata.data.image_link]
            });
            //setImage1(imageUploaddata.imageUploaddata.data.image_link)
        } else if (currentimageId == 2) {
            setdisableContinue(false)
            updateFormData({
                ...formData,
                file2: imageUploaddata.imageUploaddata.data.image_link,
                imagearray2:[imageUploaddata.imageUploaddata.data.image_link]
            });
        } else if (currentimageId == 3) {
            setdisableContinue(false)
            updateFormData({
                ...formData,
                file3: imageUploaddata.imageUploaddata.data.image_link,
                imagearray3:[imageUploaddata.imageUploaddata.data.image_link]
            });
        } else if (currentimageId == 4) {
            setdisableContinue(false)
            updateFormData({
                ...formData,
                file4: imageUploaddata.imageUploaddata.data.image_link,
                imagearray4:[imageUploaddata.imageUploaddata.data.image_link]
            });
        }

        dispatch(resetimageUploadAPI_Action())
        // setImage

    }


    const uploadImages = async () =>{
        var data = {'request_id':param_data}
       // console.log(formData)
        let feature_image = []       
        if(formData.file1 !== ''){
            feature_image.push(formData.imagearray1[0])
        }
        if(formData.file2 !== ''){
            feature_image.push(formData.imagearray2[0])
        }
        if(formData.file3 !== ''){
            feature_image.push(formData.imagearray3[0])
        }
        if(formData.file4 !== ''){
            feature_image.push(formData.imagearray4[0])
        }
       // console.log("feature_image " , feature_image)
       
        let loginToken=getLocalStorageData("loginData")
       // console.log("loginToken ",loginToken)
        // try {     
        //     await dispatch(treatment_details(feature_image,data,loginToken.token));  
        //     setIsData(true)    
        // } catch (error) {
        //   console.error(" treatment ::  treatment() :: Exception ==", error);
        // }      
        try{
            var save_data = {
                file1: formData.file1,
                file2: formData.file2,
                file3: formData.file3,
                file4: formData.file4,
                imagearray1: formData.imagearray1,
                imagearray2: formData.imagearray2,
                imagearray3: formData.imagearray3,
                imagearray4: formData.imagearray4,
            }
            await dispatch(photoDetailsSave(save_data));
            setLocalStorageData("photo_requirement_available",JSON.stringify(save_data))
            var photo_added = {photo_added : true}
            setLocalStorageData("photo_added",JSON.stringify(photo_added))
            window.scrollTo(0,0);
            history.push('/view-booking/'+param_data)
          } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
          }        
    }

    if(isData){
        setTimeout(() =>{
            if(upload_result && upload_result != null && upload_result.isSuccess === true){
                window.scrollTo(0,0);
                history.push('/view-booking/'+param_data)
            }
        },2)
    }

    const onBackPressed =async ()=>{
        if(requestdetails.requestdetailsdata.data.request.type == 1)
          {
            window.scrollTo(0, 0);
           await  dispatch(resetrequestdetailsAPI_Action())
            history.push('/treatment-detail-for-open-request/'+param_data);
          }else{
            window.scrollTo(0, 0);
            history.push('/treatment-detail-for-close-request/'+param_data);
          }
         
    }



    return (
        <>
            <section className="bgcolor-gray common-section photo_requirement_pg pb80">
                <Container>
                    <Row>
                        <Col className="col-lg-11 m-auto">
                            <div className="navigationTop">
                                <div className="heading-history">
                                    <div className="back-history">
                                        <button type="button" onClick={() => onBackPressed()} className="back-btn" >
                                            {" "}
                                            <img src={LeftArrow} alt="arrow" />{" "}
                                        </button>
                                    </div>
                                    <div className="history-title">
                                        <h2> Photo Requirements  </h2>
                                    </div>
                                </div>
                            </div>

                            <div className="requirement_wrp mt-40">
                                <div className="req_img_box">
                                    <div className="inline_row_text flex_row">
                                        <img src={iconPhoto} className="icons_required mr-8" />
                                        <p> In order to book this treatment/service you are required to supply a photo </p>
                                    </div>
                                </div>

                                <div className="req_img_box inst_box mt-24 mb-32">
                                    <h3 className="title">Photo Instruction</h3>

                                    {
                                        requestdetails.requestdetailsdata && requestdetails.requestdetailsdata !== null ?
                                        <div className="inst_content mt-16">                                        
                                        <p>{requestdetails.requestdetailsdata.data.request.photograph_requirements}</p>
                                        </div> : null
                                    }


                                   
                                </div>
                                <form>
                                <div className="photo_area border_area">
                                { errorModel &&
                                        <p className="errortext">{errorDataModel?.errors?.image}</p>
                                        } 
                                    <div className="four-cols">
                                        <div className="photos-1">
                                            <ImageUpload
                                            id="profile_pic1"
                                            File={formData.file1 ? formData.file1 : null}
                                            handleFileChange={handleFileChange1}
                                            RemoveImg={deleteImg1}
                                            showloading={imageUploaddata.isFetching && currentimageId==1 ? true:false}
                                            //onClick={clickonFile}
                                            setEdit={formData.file1 == ""}
                                            />
                                        </div>
                                        <div className="photos-2">
                                            <ImageUpload 
                                            id="profile_pic2"
                                            File={formData.file2 ? formData.file2 : null}
                                            handleFileChange={handleFileChange2}
                                            RemoveImg={deleteImg2}
                                            showloading={imageUploaddata.isFetching && currentimageId==2 ? true:false}
                                            //onClick={clickonFile}
                                            setEdit={formData.file2 == ""}/>
                                        </div>
                                        <div className="photos-3">
                                            <ImageUpload
                                            id="profile_pic3"
                                            File={formData.file3 ? formData.file3 : null}
                                            handleFileChange={handleFileChange3}
                                            RemoveImg={deleteImg3}
                                            showloading={imageUploaddata.isFetching && currentimageId==3? true:false}
                                          //  onClick={clickonFile}
                                            setEdit={formData.file3 == ""} />
                                        </div>
                                        <div className="photos-4">
                                            <ImageUpload
                                            id="profile_pic4"
                                            File={formData.file4 ? formData.file4 : null}
                                            handleFileChange={handleFileChange4}
                                            RemoveImg={deleteImg4}
                                            showloading={imageUploaddata.isFetching && currentimageId==4 ? true:false}
                                           // onClick={clickonFile}
                                            setEdit={formData.file4 == ""} />
                                        </div>
                                    </div>
                                </div>
                                </form>

                                <div className="btn-align mt-40 pd32">
                                {
                                               upload_result && upload_result != null && upload_result.isFetching
                                                    ? <LargeButton
                                                        disabled={true}
                                                        ButtonText="Loading.."
                                                    /> :
                                                    <LargeButton
                                                        ButtonType="button"
                                                        ButtonText={ requestdetails.requestdetailsdata && requestdetails.requestdetailsdata !== null && requestdetails.requestdetailsdata.data.request.booking_count !==0 ?  "Already Booked" : "Continue" }
                                                        disabled = {requestdetails.requestdetailsdata && requestdetails.requestdetailsdata !== null && requestdetails.requestdetailsdata.data.request.booking_count !==0 ? true : disableContinue}
                                                        nextButtonClick={uploadImages}
                                                    />
                                            }
                                   
                                </div>


                            </div>

                        </Col> 
                    </Row>
                </Container>
            </section> 
        </>
    );
}

export default PhotoRequirement;