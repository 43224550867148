import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS } from '../../config/ApiConstants';


export function companyCategorylistAPI_Action() {
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/company-categories/list`,
            method: 'GET',
            headers: {
                ...AUTH_HEADERS,
            },
            body: '',
            types: [
                types.COMPANYCATEGORYLIST_REQUEST,
                types.COMPANYCATEGORYLIST_RECEIVE, {
                    type: types.COMPANYCATEGORYLIST_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}


export const resetCompanyCategoryListAPI_Action = () => {
    return {
        type: types.RESET_COMPANYCATEGORYLIST,
        payload: null
    }
};
