import React from 'react';
import {Container,Row,Col} from 'react-bootstrap';
import {LeftArrow} from '../../config/images'
import {useHistory,Link} from "react-router-dom";
const GiniPigLogout = () => {

    const history = useHistory();    
    const __logout = () => {
        window.scrollTo(0, 0);
        history.push('/logout-confirmation');
    }

    const __ProfileEdit = () => {
        window.scrollTo(0, 0);
        history.push('/ginipig-edit');
    }



    return (
        <>
            <section className="bgcolor-gray common-section">
                <Container>
                    <Row>
                        <Col className="col-lg-11 m-auto">
                            <div className="navigationTop">
                                <div className="heading-history">
                                    <div className="back-history">
                                    <button type="button" className="back-btn" >
                                        {" "}
                                        <img src={LeftArrow} alt="arrow" />{" "}
                                    </button>
                                    </div>
                                    <div className="history-title">
                                    <h2> Ginipig Profile Logout </h2>
                                    </div>
                                </div>
                                <div className="headingbox alignright ">
                                   <div className="nextnav">
                                        <button onClick={__ProfileEdit} className="plainbtn">Edit</button>
                                        <button onClick={__logout} className="plainbtn">Logout</button>
                                        
                                    </div> 
                                </div>
                        </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default GiniPigLogout;