import React, { useCallback, useEffect, useState } from 'react';

import { Container, Row, Col } from 'react-bootstrap';
import { LeftArrow, iconClose, iconCorrect } from '../../config/images';
import LargeButton from '../../components/LargeButton'
import Calendar from 'react-calendar'
import { useDispatch, useSelector } from 'react-redux';
import { createAppointmentData_Action, resetcreaterequestAPI_Action, resetvalidatePostcodeAPI_Action } from '../../stores/actions'
import { useHistory, useLocation } from 'react-router-dom';
import DatePicker from "react-datepicker";
import moment from 'moment'
import usePlacesAutocomplete, { getGeocode, getZipCode } from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';
import { getLocalStorageData, setLocalStorageData } from '../../utils/storage'
import { useRef } from 'react';
import { isMobile } from 'react-device-detect'
import { formatPostcode } from '../../utils/utility';



const AppointmentAvailability = (props) => {
    //state init
    //init state
    const { dataN, Setdata } = useState(
        Object.freeze({
            date: new Date(),
        })
    )

    const initialErrorData = Object.freeze({
        isform_valid_postcode: false,
        isform_valid_cal: false,
        is_form_valid_postcode: false,
        isform_valid_ginpic: false,
        isform_valid_slots: false,
        timeslot_error: false,
        postcode_error: "",
        slot_date_error: "",

    });

    const [errorData, updateErrorData] = useState(initialErrorData);

    // States Management
    const [selected_time_slots, setselected_time_slots] = useState([

    ]);

    const [selected_date_array, setselected_date_array] = useState([]);
    const [currenthrs, setcurrenthrs] = useState();
    const [patchtesterror, setpatchtesterror] = useState("");
    const [morninggridisable, setmorninggridisable] = useState(false);
    const [afternoongridisable, setafternoongridisable] = useState(false);
    const [eveninggridisable, seteveninggridisable] = useState(false);
    const [cross, setCross] = useState(false);


    const [allTimeSlots, setallTimeSlots] = useState(["00", "7:00", "7:30", "8:00", "8:30", "9:00", "9:30", "10:00", "10:30", "11:00", "11:30", "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30", "17:00", "17:30", "18:00", "18:30", "19:00", "19:30", "20:00", "20:30", "21:00", "21:30"])
    const [morningSlotsArraydisable, setmorningSlotsArraydisable] = useState([])
    const [morningSlotsArray, setMorningSlotsArray] = useState(["7:00", "7:30", "8:00", "8:30", "9:00", "9:30", "10:00", "10:30", "11:00", "11:30"])
    const [afternoonSlotsArray, setAfternoonSlotsArray] = useState(["12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30"])
    const [eveningSlotsArray, seteveningSlotsArray] = useState(["17:00", "17:30", "18:00", "18:30", "19:00", "19:30", "20:00", "20:30", "21:00", "21:30"])
    const [closeRequesthrsArray, setcloseRequestHrsArray] = useState(["07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21"])
    const [closeRequestminuteArray, setcloseRequestminuteArray] = useState(["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"])



    const dispatch = useDispatch();
    let history = useHistory();
    const location = useLocation();
    const [formData, updateFormData] = useState(Object.freeze({
        // practitioner_type:"Comapny",

        selected_slots: [],
        selected_slots_morning: [],
        selected_slots_afternoon: [],
        selected_slots_evening: [],
        //  selected_date_array: [],
        use_profile_location: 1,
        date: '',
        formatedDate: "",
        start_time_hrs: "00",
        start_time_minutes: "00",
        postcode: "",
        postcodeValue: "",
        country: "",
        city: "",
        latitude: "",
        longitude: "",
        ginipig_count: "",
        request_type: "",
        show_postcode: false,
        locationprofile: "",
        maxDate: "",


        selected_time_slots: [],


        attributes_required: false,
        photograph_required: false,
        is_consentform_required: false,
        patchtest_required: false,

        model_requirements: "",
        photograph_requirements: "",
        consent_form_link: "",
        consentformName: "",

        rrpprice: '',
        yourprice: '',
        file1: "",
        file2: "",
        file3: "",
        file4: "",
        consent_form: "",
        hrs: "",
        minutes: "00",
        selectedTreatment: [],
        selectedTreatmentId: "",
        activeclassformdata: "",
        category_id: "",
        reason_require_ginipig: "",
        expertise_level: "",
        imagearray1: [],
        imagearray2: [],
        imagearray3: [],
        imagearray4: [],

        request_image: [],
        flat_build_no: '',
        street_name: '',
        town_city: '',
        ischecked_online: false,
        ischecked_inperson: false
    }));


    const [startDate, setStartDate] = useState(new Date());
    const [startMaxDate, setStartMaxDate] = useState(new Date());
    const [startminDate, setStartminDate] = useState(new Date());
    const [isaddress, setShowIsAddress] = useState(false);
    const [showAddresslayout, setShowAddresslayout] = useState(false);

    const [istotaldesc, setShowIsTotalDescription] = useState('');
    const [istotalAdress, setShowIsTotalAddress] = useState(false);


    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions
    } = usePlacesAutocomplete({
        requestOptions: {
            /* Define search scope here */
            componentRestrictions: {
                country: 'uk',
                city: false
            }
        },
        debounce: 300,
        // componentRestrictions: {country: "us"}
        callbackName: 'initMap'
    });
    const registerRef = useOnclickOutside(() => {
        // When user clicks outside of the component, we can dismiss
        // the searched suggestions by calling this method
        clearSuggestions();
    });

    const handleInput = e => {

        if (isaddress) {
            setShowIsAddress(false)
        }

        setValue(e.target.value);
        updateFormData({
            ...formData,
            postcode: "",
            flat_build_no: "",
            use_profile_location: 0,
            postcodeValue: e.target.value
        });
    };

    const handleSelect = ({ description }, secondary_text, main_text) => () => {
        // When user selects a place, we can replace the keyword without request data from API
        // by setting the second parameter as "false"

        // updateFormData({
        //   ...formData,
        //   postcodeValue:description
        // });
        if (description) {
            setShowIsAddress(true)
            setShowIsTotalDescription(description)
        }
        setValue(description, false);
        clearSuggestions();

        let latitude = "";
        let longitude = "";
        let city = "";
        let country = "";
        let street_address = '';
        getGeocode({ address: description })

            .then((results) => {

                let city_name = results[0].address_components.map((item, key) => {

                    if (item.types.indexOf("postal_town") !== -1) {
                        city = item.long_name;
                    } else {
                        return null;
                    }

                });

                let country_name = results[0].address_components.map((item, key) => {

                    if (item.types.indexOf("country") != -1) {
                        country = item.long_name;
                    } else {
                        return null;
                    }

                });
                latitude = results[0].geometry.location.lat();
                longitude = results[0].geometry.location.lng();
                if (city === "") {
                    updateErrorData({
                        ...errorData,
                        is_form_valid_postcode: true,
                        postcode_error: "Please select a valid postcode"
                    });
                } else {
                    updateErrorData({
                        ...errorData,
                        is_form_valid_postcode: false,
                        postcode_error: ""
                    });
                }
                street_address = description.replace(", UK", "");
                street_address = street_address.replace(", " + city, "");


                return Promise.resolve(results);
            })
            .then((results) => getZipCode(results[0]))
            .then((zipCode) => {
                // setShowIsTotalAddress(false)
                // let latitude = results[0].geometry.location.lat();
                // let longitude = results[0].geometry.location.lng();

                let zipcode = zipCode;

                if (zipcode.length < 6) {
                    var abc = street_address.substring(street_address.indexOf(zipcode));
                    zipcode = abc;

                }

                if (zipcode !== null) {
                    street_address = street_address.replace(zipcode, "");
                    updateFormData({
                        ...formData,
                        latitude: latitude,
                        longitude: longitude,
                        postcode: zipcode,
                        // postcodeValue: description,
                        postcodeValue: zipcode,
                        country: country,
                        city: city,
                        locationprofile: "",
                        street_name: street_address,
                        town_city: city
                    });
                } else {
                    updateErrorData({
                        ...errorData,
                        is_form_valid_postcode: true,
                        postcode_error: "Postcode cannot be found, please enter address manually below."
                    });
                    setShowIsAddress(true)
                    let postcodeValue1 = formData.postcodeValue
                    var postcodenew = ""
                    if (postcodeValue1 !== null) {
                        var fourth = postcodeValue1.substr(postcodeValue1.length - 4);
                        var lastThree = postcodeValue1.substr(postcodeValue1.length - 3);
                        var first = postcodeValue1.substr(0, postcodeValue1.length - 3);
                        var postcodenew1 = first + " " + lastThree
                        if (fourth.substr(0, 1) === " ") {

                            postcodenew = formData.postcodeValue.toUpperCase()
                        } else {
                            postcodenew = postcodenew1.toUpperCase()
                        }

                    }
                    street_address = street_address.replace(postcodenew, "");
                    updateFormData({
                        ...formData,
                        latitude: latitude,
                        longitude: longitude,
                        postcode: postcodenew,
                        // postcodeValue: description,
                        postcodeValue: postcodenew,
                        country: country,
                        city: city,
                        locationprofile: "",
                        street_name: street_address,
                        town_city: city
                    });
                }
                setValue(zipcode, false)

            })

            .catch((error) => {
                console.log("Error: ", error);
            });
    };


    const renderSuggestions = () =>
        data.map(suggestion => {
            //  options = {
            //   types: ['(cities)'],
            //   componentRestrictions: {country: "GB"}
            //  };
            const {
                id,
                structured_formatting: { main_text, secondary_text },

            } = suggestion;

            return (
                <li
                    key={id}
                    onClick={handleSelect(suggestion)}
                >
                    <strong>{main_text}</strong> <small>{secondary_text}</small>
                </li>
            );
        });



    // Selectors

    const registerData = useSelector(state => state.register);

    const [currentDate, setcurrentDate] = useState(true);
    const [currentSelectedDate, setcurrentSelectedDate] = useState("");

    const AppointmentCreateData = useSelector(state => state.appointmentcreatedata);
    const validatepostcodeData = useSelector(state => state.validatepostcode);

    // date picker keyboard patch 
    const pickerRef = useRef(null);

    useEffect(() => {
        if (isMobile && pickerRef.current !== null) {
            pickerRef.current.input.readOnly = true;
        }
    }, [isMobile, pickerRef]);




    // Use effects
    useEffect(() => {

        var datemax = new Date();
        datemax.setMonth(datemax.getMonth() + 3);
        setStartMaxDate(datemax)

        var today = new Date();
        let h = today.getHours();

        let datemin = ""
        if (h >= 18) {
            today.setDate(today.getDate() + 1);
            datemin = today;
        }
        else {
            datemin = today;
        }

        setStartminDate(datemin)
        setStartDate(datemin)


        var datemin1 = today,
            mnth = ("0" + (today.getMonth() + 1)).slice(-2),
            day = ("0" + today.getDate()).slice(-2);
        let selecteddate = [day, mnth, today.getFullYear()].join("-");



        // let newArray = ["00", "07", "08", "09", "10", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21"]
        let newArray = ["00", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21"]
        let newArraymin = ["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"]
        let m = today.getMinutes(); // =>  30

        const startTime = h + ":" + m
        const durationInMinutes = '180';



        const endTime = moment(startTime, 'HH:mm:ss').add(durationInMinutes, 'minutes').format('HH:mm');
        setcurrenthrs(endTime)

        let sliceindex = "";
        var endtimeminute = endTime.substr(endTime.length - 2);
        let endtimehrs = endTime.substr(0, endTime.indexOf(':'));
        let hrsArray = []
        let minArray = []

        let RedirectPathName = getLocalStorageData("RedirectPathName")
        if (RedirectPathName === "editRequest") {
            hrsArray = newArray
            minArray = newArraymin
            setcloseRequestHrsArray(newArray)
            setcloseRequestminuteArray(minArray)
            setcurrenthrs(endTime)
        } else {
            if (endtimehrs > 20) {
                hrsArray = newArray
                minArray = newArraymin

            } else {

                newArray.map((hrs, index) => {
                    if (endtimehrs === hrs) {
                        sliceindex = index;
                    }

                    hrsArray = newArray.slice(sliceindex, 16)


                    if (endtimeminute < 5) {
                        minArray = newArraymin.slice(1, 15)
                    } else if (endtimeminute >= 5 && endtimeminute < 10) {
                        minArray = newArraymin.slice(2, 15)
                    }
                    else if (endtimeminute >= 10 && endtimeminute < 15) {
                        minArray = newArraymin.slice(3, 15)
                    }
                    else if (endtimeminute >= 15 && endtimeminute < 20) {
                        minArray = newArraymin.slice(4, 15)
                    }
                    else if (endtimeminute >= 20 && endtimeminute < 25) {
                        minArray = newArraymin.slice(5, 15)
                    }
                    else if (endtimeminute >= 25 && endtimeminute < 30) {
                        minArray = newArraymin.slice(6, 15)
                    }
                    else if (endtimeminute >= 30 && endtimeminute < 35) {
                        minArray = newArraymin.slice(7, 15)
                    }
                    else if (endtimeminute >= 35 && endtimeminute < 40) {
                        minArray = newArraymin.slice(8, 15)
                    }
                    else if (endtimeminute >= 40 && endtimeminute < 45) {
                        minArray = newArraymin.slice(9, 15)
                    }
                    else if (endtimeminute >= 45 && endtimeminute < 50) {
                        minArray = newArraymin.slice(10, 15)
                    }
                    else if (endtimeminute >= 50 && endtimeminute < 55) {
                        minArray = newArraymin.slice(11, 15)
                    }
                    else if (endtimeminute >= 55) {
                        minArray = newArraymin.slice(0, 15)
                    }
                })



            }
            setcurrentDate(selecteddate)
            setcurrenthrs(endTime)
            setcloseRequestHrsArray(hrsArray)
            setcloseRequestminuteArray(minArray)
        }

        try {
            let checkToken = "";

            //let LocalDatatoken = getLocalStorageData("loginData")
            //let registerData = getLocalStorageData("practitionersignupData")
            let LocalDatatoken = getLocalStorageData("loginDataUser")
            let registerData = getLocalStorageData("loginDataUser")

            if (registerData != "" && registerData !== undefined && registerData !== null) {
                checkToken = registerData.token;
            }
            else if (LocalDatatoken !== "" && LocalDatatoken !== undefined && LocalDatatoken !== null) {
                checkToken = LocalDatatoken.token;
            }
            if (checkToken === '') {
                window.scrollTo(0, 0);
                history.push('/login');
            }

            let RedirectPathName = getLocalStorageData("RedirectPathName")
            if (location.redirectPath === "appointment-review" || RedirectPathName === "editRequest") {
                updateErrorData({
                    ...errorData,
                    isform_valid_postcode: true,
                    isform_valid_cal: true,
                    isform_valid_ginpic: true,
                    isform_valid_slots: true,
                    timeslot_error: true,
                    postcode_error: ""

                });
                setShowIsTotalAddress(true)
            }

            let requestDataLocal = getLocalStorageData("requestDataLocal")

            if (requestDataLocal.RequestData !== null && requestDataLocal.RequestData !== "" && requestDataLocal.RequestData !== undefined) {
                let postcode = ""
                let locationprofile = ""
                let flat_build_no = ""
                let street_name = ""
                let town_city = ""
                let city = ""
                let country = ""
                let postcodeValue = ""
                let latitude = ""
                let longitude = ""

                //let registerDataLocal = getLocalStorageData("practitionersignupData")
                let registerDataLocal = getLocalStorageData("loginDataUser")
                //let registerData = registerDataLocal && registerDataLocal.data && registerDataLocal.data.user.user_additional_info !== undefined && registerDataLocal.data.user.user_additional_info[0].postcode_data;
                let registerData = registerDataLocal && registerDataLocal.user_additional_info !== undefined && registerDataLocal.user_additional_info.data[0].postcode_data.data;


                //let LocalData = getLocalStorageData("loginData")
                let LocalData = getLocalStorageData("loginDataUser")
                //let loginDatapostcode = LocalData && LocalData.user.user_additional_info.data[0].postcode_data.data;
                let loginDatapostcode = LocalData && LocalData.user_additional_info.data[0].postcode_data.data;

                if (registerData !== "" && registerData != null && registerData !== undefined) {
                    postcode = "";
                    locationprofile = registerData.location;
                    flat_build_no = registerData.flat;
                    town_city = registerData.city;
                    city = registerData.city;
                    country = registerData.country;
                    // postcodeValue = registerData.postcode
                    latitude = registerData.latitude;
                    longitude = registerData.longitude
                } else {

                    postcode = "";
                    locationprofile = loginDatapostcode.location;
                    flat_build_no = loginDatapostcode.flat;
                    town_city = loginDatapostcode.city;
                    city = loginDatapostcode.city;
                    country = loginDatapostcode.country;
                    // postcodeValue = loginDatapostcode.postcode
                    latitude = loginDatapostcode.latitude;
                    longitude = loginDatapostcode.longitude
                }

                let useprofilelocation = 1

                if (requestDataLocal.RequestData.use_profile_location !== null && requestDataLocal.RequestData.use_profile_location !== undefined && requestDataLocal.RequestData.use_profile_location !== "") {
                    if (requestDataLocal.RequestData.use_profile_location === 1) {
                        useprofilelocation = 1;
                        setShowAddresslayout(true)
                    } else {
                        useprofilelocation = 0;
                        setShowIsTotalAddress(true)
                    }
                }



                let selectedTimeArray = [];
                let selectedDateSlots = []
                let RedirectPathName = getLocalStorageData("RedirectPathName")
                if (RedirectPathName === "editRequest") {
                    requestDataLocal && requestDataLocal.selected_time_slots.forEach(element => {
                        if (element.selected_slots_morning.length > 0 || element.selected_slots_afternoon.length > 0 || element.selected_slots_evening.length > 0) {
                            selectedTimeArray.push(element)
                            // console.log(element.formmatedDate)
                            selectedDateSlots.push(element.formmatedDate)
                        }
                    })
                    if (selectedTimeArray.length !== 0) {
                        selectedTimeArray.sort(function (a, b) {
                            return new Date(a.formmatedDate) - new Date(b.formmatedDate);
                        });

                    }

                    let calDatedisplay = requestDataLocal && requestDataLocal.RequestData.date
                    let y = calDatedisplay.substring(6, 10)
                    let m = calDatedisplay.substring(3, 5)
                    let d = calDatedisplay.substring(0, 2)
                    let newformd = y + "-" + m + "-" + d
                    let calshowdate = new Date(newformd)

                    setselected_time_slots(selectedTimeArray)
                    setselected_date_array(selectedDateSlots)
                    setStartDate(calshowdate)
                    if ((requestDataLocal.RequestData.start_time_hrs).toString().length === 1) {
                        requestDataLocal.RequestData.start_time_hrs = '0' + requestDataLocal.RequestData.start_time_hrs
                    }
                    //console.log("requestDataLocal.RequestData.postcodeValue",requestDataLocal.RequestData.start_time_hrs)
                }



                updateFormData({
                    ...formData,
                    maxDate: datemax,
                    selectedTreatmentId: requestDataLocal.RequestData.selectedTreatmentId ? requestDataLocal.RequestData.selectedTreatmentId : "",
                    selectedTreatment: requestDataLocal.RequestData.selectedTreatment ? requestDataLocal.RequestData.selectedTreatment : [],
                    is_keep_treatment_on_profile: requestDataLocal.RequestData.is_keep_treatment_on_profile ? requestDataLocal.RequestData.is_keep_treatment_on_profile : 0,
                    redirectPathName: requestDataLocal.RequestData.redirectPathName ? requestDataLocal.RequestData.redirectPathName : "complete-profile",
                    requestId: requestDataLocal.RequestData.requestId ? requestDataLocal.RequestData.requestId : 0,
                    request_image_old: requestDataLocal.RequestData.request_image_old ? requestDataLocal.RequestData.request_image_old : [],


                    rrpprice: requestDataLocal.RequestData.rrpprice ? requestDataLocal.RequestData.rrpprice : "",
                    yourprice: requestDataLocal.RequestData.yourprice ? requestDataLocal.RequestData.yourprice : "",

                    hrs: requestDataLocal.RequestData.hrs ? requestDataLocal.RequestData.hrs : 0,

                    minutes: requestDataLocal.RequestData.minutes ? requestDataLocal.RequestData.minutes : "00",

                    activeclassformdata: requestDataLocal.RequestData ? requestDataLocal.RequestData.activeclassformdata : "",
                    category_id: requestDataLocal.RequestData.category_id ? requestDataLocal.RequestData.category_id : "",
                    reason_require_ginipig: requestDataLocal.RequestData.reason_require_ginipig ? requestDataLocal.RequestData.reason_require_ginipig : "",
                    expertise_level: requestDataLocal.RequestData.expertise_level ? requestDataLocal.RequestData.expertise_level : "",


                    request_image: requestDataLocal.RequestData.request_image ? requestDataLocal.RequestData.request_image : [],
                    attributes_required: requestDataLocal.RequestData.attributes_required ? requestDataLocal.RequestData.attributes_required : false,
                    photograph_required: requestDataLocal.RequestData.photograph_required ? requestDataLocal.RequestData.photograph_required : false,
                    is_consentform_required: requestDataLocal.RequestData.is_consentform_required ? requestDataLocal.RequestData.is_consentform_required : false,
                    patchtest_required: requestDataLocal.RequestData.patchtest_required ? requestDataLocal.RequestData.patchtest_required : false,

                    model_requirements: requestDataLocal.RequestData.model_requirements ? requestDataLocal.RequestData.model_requirements : "",
                    photograph_requirements: requestDataLocal.RequestData.photograph_requirements ? requestDataLocal.RequestData.photograph_requirements : "",
                    consent_form_link: requestDataLocal.RequestData.consent_form_link ? requestDataLocal.RequestData.consent_form_link : "",
                    consentformName: requestDataLocal.RequestData.consentformName ? requestDataLocal.RequestData.consentformName : "",

                    date: requestDataLocal.RequestData.date ? requestDataLocal.RequestData.date : selecteddate,
                    start_time_hrs: requestDataLocal.RequestData.start_time_hrs ? requestDataLocal.RequestData.start_time_hrs : hrsArray[0],
                    start_time_minutes: requestDataLocal.RequestData.start_time_minutes ? requestDataLocal.RequestData.start_time_minutes : minArray[0],
                    postcode: requestDataLocal.RequestData.postcode ? requestDataLocal.RequestData.postcode : postcode,
                    locationprofile: requestDataLocal.RequestData.locationprofile ? requestDataLocal.RequestData.locationprofile : locationprofile,
                    use_profile_location: useprofilelocation,
                    postcodeValue: requestDataLocal.RequestData.postcodeValue ? useprofilelocation === 1 ? '' : requestDataLocal.RequestData.postcodeValue : postcodeValue,
                    country: requestDataLocal.RequestData.country ? requestDataLocal.RequestData.country : country,
                    city: requestDataLocal.RequestData.city ? requestDataLocal.RequestData.city : city,
                    latitude: requestDataLocal.RequestData.latitude ? requestDataLocal.RequestData.latitude : latitude,
                    longitude: requestDataLocal.RequestData.longitude ? requestDataLocal.RequestData.longitude : longitude,
                    flat_build_no: requestDataLocal.RequestData.flat_build_no ? requestDataLocal.RequestData.flat_build_no : flat_build_no,
                    street_name: requestDataLocal.RequestData.street_name ? requestDataLocal.RequestData.street_name : street_name,
                    town_city: requestDataLocal.RequestData.town_city ? requestDataLocal.RequestData.town_city : town_city,
                    ginipig_count: requestDataLocal.RequestData.ginipig_count ? requestDataLocal.RequestData.ginipig_count : "",
                    request_type: requestDataLocal.RequestData.request_type ? requestDataLocal.RequestData.request_type : "",

                    show_postcode: requestDataLocal.RequestData.show_postcode ? requestDataLocal.RequestData.show_postcode : false,
                    ischecked_online: requestDataLocal.RequestData.ischecked_online ? requestDataLocal.RequestData.ischecked_online : false,
                    ischecked_inperson: requestDataLocal.RequestData.ischecked_inperson ? requestDataLocal.RequestData.ischecked_inperson : false,
                    selected_time_slots: requestDataLocal.selected_time_slots ? requestDataLocal.selected_time_slots : "",
                    selected_date_array: selectedDateSlots,

                    category_id: requestDataLocal.RequestData.category_id ? requestDataLocal.RequestData.category_id : "",
                    category_name: requestDataLocal.RequestData.category_name ? requestDataLocal.RequestData.category_name : "",
                    sub_category_id: requestDataLocal.RequestData.sub_category_id ? requestDataLocal.RequestData.sub_category_id : [],
                    title: requestDataLocal.RequestData.title ? requestDataLocal.RequestData.title : "",
                    description: requestDataLocal.RequestData.description ? requestDataLocal.RequestData.description : "",
                    categories: requestDataLocal.RequestData.categories ? requestDataLocal.RequestData.categories : "",
                    treatment_sub_category: requestDataLocal.RequestData.treatment_sub_category ? requestDataLocal.RequestData.treatment_sub_category : [],

                    file1: requestDataLocal.RequestData.file1 ? requestDataLocal.RequestData.file1 : "",
                    file2: requestDataLocal.RequestData.file2 ? requestDataLocal.RequestData.file2 : "",
                    file3: requestDataLocal.RequestData.file3 ? requestDataLocal.RequestData.file3 : "",
                    file4: requestDataLocal.RequestData.file4 ? requestDataLocal.RequestData.file4 : "",

                    imagearray1: requestDataLocal.RequestData.imagearray1 ? requestDataLocal.RequestData.imagearray1 : [],
                    imagearray2: requestDataLocal.RequestData.imagearray2 ? requestDataLocal.RequestData.imagearray2 : [],
                    imagearray3: requestDataLocal.RequestData.imagearray3 ? requestDataLocal.RequestData.imagearray3 : [],
                    imagearray4: requestDataLocal.RequestData.imagearray4 ? requestDataLocal.RequestData.imagearray4 : [],
                    consent_form: requestDataLocal.RequestData.consent_form ? requestDataLocal.RequestData.consent_form : "",
                });

            }

            // var save_data = {
            //     file1: AppointmentCreateData.appointmentcreatedata && AppointmentCreateData.appointmentcreatedata.file1 ? AppointmentCreateData.appointmentcreatedata.file1 : "",
            //     file2: AppointmentCreateData.appointmentcreatedata && AppointmentCreateData.appointmentcreatedata.file2 ? AppointmentCreateData.appointmentcreatedata.file2 : "",
            //     file3: AppointmentCreateData.appointmentcreatedata && AppointmentCreateData.appointmentcreatedata.file3 ? AppointmentCreateData.appointmentcreatedata.file3 : "",
            //     file4: AppointmentCreateData.appointmentcreatedata && AppointmentCreateData.appointmentcreatedata.file4 ? AppointmentCreateData.appointmentcreatedata.file4 : "",
            //     // consent_form:AppointmentCreateData.appointmentcreatedata ? AppointmentCreateData.appointmentcreatedata.consent_form : "",

            //     imagearray1: AppointmentCreateData.appointmentcreatedata && AppointmentCreateData.appointmentcreatedata.imagearray1 ? AppointmentCreateData.appointmentcreatedata.imagearray1 : [],
            //     imagearray2: AppointmentCreateData.appointmentcreatedata && AppointmentCreateData.appointmentcreatedata.imagearray2 ? AppointmentCreateData.appointmentcreatedata.imagearray2 : [],
            //     imagearray3: AppointmentCreateData.appointmentcreatedata && AppointmentCreateData.appointmentcreatedata.imagearray3 ? AppointmentCreateData.appointmentcreatedata.imagearray3 : [],
            //     imagearray4: AppointmentCreateData.appointmentcreatedata && AppointmentCreateData.appointmentcreatedata.imagearray4 ? AppointmentCreateData.appointmentcreatedata.imagearray4 : [],
            //     consent_form: AppointmentCreateData.appointmentcreatedata && AppointmentCreateData.appointmentcreatedata.consent_form ? AppointmentCreateData.appointmentcreatedata.consent_form : "",

            // }

            // dispatch(createAppointmentData_Action(save_data));




        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }

    }, [])


    const tileClassName = (activeStartDate, date, view) => {

        let date1 = moment(activeStartDate.date).format("DD-MM-yy")


        let newDateArray = []
        selected_date_array !== [] && selected_date_array.forEach((item, index) => {
            let formatedDate = moment(item).format("DD-MM-yy")
            newDateArray.push(formatedDate)
        })

        if (newDateArray.indexOf(date1) !== -1) {

            return "pratitioner_canlender_active"

        }

    }


    const nextButtonClick = async () => {
        dispatch(resetcreaterequestAPI_Action());

        if ((formData.postcode !== "" || formData.start_time_hrs !== undefined || formData.start_time_minutes !== "00") && formData.request_type === 2 && formData.start_time_hrs !== "00") {
            let value = formData.start_time_hrs + ":" + formData.start_time_minutes

            if (formData.patchtest_required) {
                let ispatchtest = checkPatchtestError(value)
                if (ispatchtest) {
                    window.scrollTo(0, 0);
                    setpatchtesterror("Oops! It doesn’t look like you have allowed enough time to fit in a Patch Test before the appointment")
                } else {
                    setpatchtesterror("")
                    __apiCall();

                    updateErrorData({
                        ...errorData,
                        isform_valid_slots: false,
                        timeslot_error: "",
                    });
                }
            } else {
                setpatchtesterror("")
                __apiCall();
                updateErrorData({
                    ...errorData,
                    isform_valid_slots: false,
                    timeslot_error: "",

                });
            }

        } else if ((formData.selected_slots_morning.length > 0 || formData.selected_slots_afternoon.length > 0 || formData.selected_slots_evening.length > 0 || formData.selected_date_array.length !== 0) && formData.request_type === 1) {
            __apiCall();
            window.scrollTo(0, 0);
            updateErrorData({
                ...errorData,
                isform_valid_slots: false,
                timeslot_error: "",

            });
        }
        else if (formData.postcode === "") {
            window.scrollTo(0, 0);
            updateErrorData({
                ...errorData,
                isform_valid_slots: true,
                timeslot_error: "Please Select a Time Slot",

            });
        }
        else {
            window.scrollTo(0, 0);
            updateErrorData({
                ...errorData,
                isform_valid_slots: true,
                timeslot_error: "Please Select a Time Slot",

            });
        }

    }

    const __redirectToRequirmentsEdit = useCallback(async () => {
        try {

            await dispatch(createAppointmentData_Action(formData, selected_time_slots));
            window.scrollTo(0, 0);

            history.push({
                pathname: '/ginipig-requirement',


            });

        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    });

    const __apiCall = useCallback(async () => {
        try {
            setLocalStorageData("pathname", "availibility")
            let formDatarequest = {
                RequestData: formData,
                selected_time_slots: selected_time_slots

            }
            setLocalStorageData("requestDataLocal", formDatarequest)

            window.scrollTo(0, 0);
            history.push('/appointment-review')

        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    });
    const onChange = (e) => {

        updateErrorData({
            ...errorData,
            isform_valid_cal: true,
            slot_date_error: "",

        });

        updateFormData({
            ...formData,
            selected_slots_morning: [],
            selected_slots_afternoon: [],
            selected_slots_evening: [],
        })

        let selectedDay = ""

        var date = new Date(e),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        let selecteddate = [day, mnth, date.getFullYear()].join("-");
        selectedDay = day
        setcurrentDate(selecteddate)
        setcurrentSelectedDate(e)
        var todaysDate = moment();
        var treatmentDate = moment(e, "DD-MM-YYYY");
        var ms = moment(treatmentDate).diff(todaysDate, "days");
        if (formData.patchtest_required) {
            if (ms >=2) {
                setpatchtesterror("")
            } else {
                setpatchtesterror("Oops! It doesn’t look like you have allowed enough time to fit in a Patch Test before the appointment")
            }

        }


        let h = date.getHours(); // => 9
        let m = date.getMinutes(); // =>  30
        let s = date.getSeconds(); // => 51

        const startTime = h + ":" + m
        const durationInMinutes = '180';

        const endTime = moment(startTime, 'HH:mm:ss').add(durationInMinutes, 'minutes').format('HH:mm');
        setcurrenthrs(endTime)

        var todayDate = new Date(),
            mnth1 = ("0" + (todayDate.getMonth() + 1)).slice(-2),
            day = ("0" + todayDate.getDate()).slice(-2);
       
        let selecteddate1 = [day, mnth1, todayDate.getFullYear()].join("-");
        let sliceindex = ""
        var endtimeminute = endTime.substr(endTime.length - 2);

        if (selecteddate1 === selecteddate) {
            let endtimehrs = endTime.substr(0, endTime.indexOf(':'))

            // console.log(endTime)
            let disablearray = []

            if (endtimehrs >= 21) {
                setmorningSlotsArraydisable(allTimeSlots)
                setmorninggridisable(true)
                setafternoongridisable(true)
                seteveninggridisable(true)

            } else {
                allTimeSlots.map((slot, index) => {

                    var hrs = slot.substr(0, slot.indexOf(':'));
                    var minute = slot.substr(slot.length - 2);
                    let newhrs = ""
                    if (hrs < 10) {
                        newhrs = 0 + hrs

                    } else {
                        newhrs = hrs
                    }
                    //console.log(hrs)
                    if (endtimehrs === newhrs) {
                        sliceindex = index

                    }

                })

                if (endtimeminute < 30) {
                    disablearray = allTimeSlots.slice(0, sliceindex)
                } else {
                    disablearray = allTimeSlots.slice(0, sliceindex + 1)
                }
                if (sliceindex > 0 && sliceindex < 10) {
                    setmorninggridisable(true)
                } else if (sliceindex > 10 && sliceindex < 20) {
                    setmorninggridisable(true)
                    setafternoongridisable(true)
                } else if (sliceindex > 20 && sliceindex < 30) {
                    setmorninggridisable(true)
                    setafternoongridisable(true)
                    seteveninggridisable(true)
                }

                setmorningSlotsArraydisable(disablearray)
            }


        } else {
            setmorningSlotsArraydisable([])
            setmorninggridisable(false)
            setafternoongridisable(false)
            seteveninggridisable(false)
        }




        let obj = {
            date: selecteddate,
            formmatedDate: e,
            selected_slots_morning: [],
            selected_slots_afternoon: [],
            selected_slots_evening: [],

        }



        const newArray = JSON.parse(JSON.stringify(selected_time_slots))


        let selectedDateSlots = []
        let selectedDateSlotsformted = []
        newArray.forEach(element => {
            selectedDateSlots.push(element.date)
            if (element.selected_slots_morning.length > 0 || element.selected_slots_afternoon.length > 0 || element.selected_slots_evening.length > 0) {
                selectedDateSlotsformted.push(element.formmatedDate)
            }


            if (element.date === selecteddate) {

                updateFormData({
                    ...formData,
                    selected_slots_morning: element.selected_slots_morning,
                    selected_slots_afternoon: element.selected_slots_afternoon,
                    selected_slots_evening: element.selected_slots_evening,
                    // selected_date_array:selectedDateSlotsformted,

                });
            }


        })

        setselected_date_array(selectedDateSlotsformted)

        if (selectedDateSlots.indexOf(selecteddate) === -1) {
            newArray.push(obj); // Push the object   
        }
        if (newArray.length !== 0) {
            newArray.sort(function (a, b) {
                return new Date(a.formmatedDate) - new Date(b.formmatedDate);
            });

        }

        setselected_time_slots(newArray);


    }

    const onHrsChange = (event) => {
        let newArraymin = ["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"]
        let newArray = ["07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21"]
        setcloseRequestminuteArray(newArraymin)
        var todayDate = new Date(),
            mnth = ("0" + (todayDate.getMonth() + 1)).slice(-2),
            day = ("0" + todayDate.getDate()).slice(-2);
        let selecteddate1 = [day, mnth, todayDate.getFullYear()].join("-");
        setcurrentDate(setcurrentDate)
        var endtimeminute = ""
        var endtimehrs = ""

        let currenttime = currenthrs
        if (currenttime !== "" && currenttime !== undefined && currenttime !== null) {
            endtimeminute = currenthrs.substr(currenthrs.length - 2);
            endtimehrs = currenthrs.substr(0, currenthrs.indexOf(':'));
        }


        let minArray = []
        if (formData.date === selecteddate1) {

            if (endtimehrs === event.target.value) {

                if (endtimeminute < 5) {
                    minArray = newArraymin.slice(1, 15)
                } else if (endtimeminute > 5 && endtimeminute < 10) {
                    minArray = newArraymin.slice(2, 15)
                }
                else if (endtimeminute > 10 && endtimeminute < 15) {
                    minArray = newArraymin.slice(3, 15)
                }
                else if (endtimeminute > 15 && endtimeminute < 20) {
                    minArray = newArraymin.slice(4, 15)
                }
                else if (endtimeminute > 20 && endtimeminute < 25) {
                    minArray = newArraymin.slice(5, 15)
                }
                else if (endtimeminute > 25 && endtimeminute < 30) {
                    minArray = newArraymin.slice(6, 15)
                }
                else if (endtimeminute > 30 && endtimeminute < 35) {
                    minArray = newArraymin.slice(7, 15)
                }
                else if (endtimeminute > 35 && endtimeminute < 40) {
                    minArray = newArraymin.slice(8, 15)
                }
                else if (endtimeminute > 40 && endtimeminute < 45) {
                    minArray = newArraymin.slice(9, 15)
                }
                else if (endtimeminute > 45 && endtimeminute < 50) {
                    minArray = newArraymin.slice(10, 15)
                }
                else if (endtimeminute > 50 && endtimeminute < 55) {
                    minArray = newArraymin.slice(11, 15)
                }
                else if (endtimeminute > 55) {
                    minArray = newArraymin.slice(12, 15)
                }

                setcloseRequestminuteArray(minArray)
            } else {

                minArray = newArraymin.slice(0, 15)
            }

        } else {
            setcloseRequestHrsArray(newArray)
            setcloseRequestminuteArray(newArraymin)
        }

        if (formData.patchtest_required) {
            let valuehr = event.target.value + "0"
            let ispatchtest = checkPatchtestError(valuehr)
            if (ispatchtest) {
                window.scrollTo(0, 0);
                setpatchtesterror("Oops! It doesn’t look like you have allowed enough time to fit in a Patch Test before the appointment")
            } else {
                setpatchtesterror("")
                updateFormData({
                    ...formData,
                    start_time_hrs: event.target.value,
                });
            }
        } else {
            setpatchtesterror("")
            updateFormData({
                ...formData,
                start_time_hrs: event.target.value,
            });
        }

        updateFormData({
            ...formData,
            start_time_hrs: event.target.value,
        });


    }

    const onMinutesChange = (event) => {
        updateFormData({
            ...formData,
            start_time_minutes: event.target.value,
        });

    }
    const __onTextChange = (e) => {
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value
        });

    };

    const checkPostcodeValidation = (e) => {
        if (validatepostcodeData.isError === true) {
            dispatch(resetvalidatePostcodeAPI_Action());
        }
        const { id, value } = e.target;
        if (!value) {
            updateErrorData({
                ...errorData,
                is_form_valid_postcode: true,
                postcode_error: "Postcode Is Required."
            });
        } else if (!(formData.postcodeValue.length > 5 && formData.city == '' && (formData.postcodeValue).match(/^([a-z0-9]\s*){5,8}$/i))) {
            updateErrorData({
                ...errorData,
                is_form_valid_postcode: true,
                postcode_error: "Please ensure you have entered a valid UK postcode"
            })
        }
        else {
            updateErrorData({
                ...errorData,
                is_form_valid_postcode: false,
                postcode_error: ""
            });
        }
    }



    const setDatepick = (date) => {

        let newArray = ["00", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21"]
        let newArraymin = ["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"]

        setcloseRequestHrsArray(newArray)
        setcloseRequestminuteArray(newArraymin)

        setStartDate(date)
        var date = new Date(date),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        let selecteddate = [day, mnth, date.getFullYear()].join("-");
        setcurrentDate(selecteddate)

        let h = date.getHours(); // => 9
        let m = date.getMinutes(); // =>  30

        const startTime = h + ":" + m
        const durationInMinutes = '180';

        const endTime = moment(startTime, 'HH:mm:ss').add(durationInMinutes, 'minutes').format('HH:mm');
        setcurrenthrs(endTime)


        var todayDate = new Date(),
            mnth = ("0" + (todayDate.getMonth() + 1)).slice(-2),
            day = ("0" + todayDate.getDate()).slice(-2);


        let selecteddate1 = [day, mnth, todayDate.getFullYear()].join("-");

        let sliceindex = "";
        var endtimeminute = endTime.substr(endTime.length - 2);
        let endtimehrs = endTime.substr(0, endTime.indexOf(':'));
        let hrsArray = []
        let minArray = []


        if (selecteddate === selecteddate1) {

            let h = todayDate.getHours(); // => 9
            let m = todayDate.getMinutes(); // =>  30

            let startTime = h + ":" + m
            let endTime = moment(startTime, 'HH:mm:ss').add(durationInMinutes, 'minutes').format('HH:mm');
            let endtimeminute = endTime.substr(endTime.length - 2);
            let endtimehrs = endTime.substr(0, endTime.indexOf(':'));

            if (endtimehrs > 21) {
                hrsArray = []
                minArray = []

            } else {
                newArray.map((hrs, index) => {
                    if (endtimehrs === hrs) {
                        sliceindex = index;
                    }
                    hrsArray = newArray.slice(sliceindex, 15)
                })

                newArray.map((hrs, index) => {
                    if (endtimehrs === hrs) {
                        sliceindex = index;
                    }
                    hrsArray = newArray.slice(sliceindex, 16)


                    if (endtimeminute < 5) {
                        minArray = newArraymin.slice(1, 15)
                    } else if (endtimeminute >= 5 && endtimeminute < 10) {
                        minArray = newArraymin.slice(2, 15)
                    }
                    else if (endtimeminute >= 10 && endtimeminute < 15) {
                        minArray = newArraymin.slice(3, 15)
                    }
                    else if (endtimeminute >= 15 && endtimeminute < 20) {
                        minArray = newArraymin.slice(4, 15)
                    }
                    else if (endtimeminute >= 20 && endtimeminute < 25) {
                        minArray = newArraymin.slice(5, 15)
                    }
                    else if (endtimeminute >= 25 && endtimeminute < 30) {
                        minArray = newArraymin.slice(6, 15)
                    }
                    else if (endtimeminute >= 30 && endtimeminute < 35) {
                        minArray = newArraymin.slice(7, 15)
                    }
                    else if (endtimeminute >= 35 && endtimeminute < 40) {
                        minArray = newArraymin.slice(8, 15)
                    }
                    else if (endtimeminute >= 40 && endtimeminute < 45) {
                        minArray = newArraymin.slice(9, 15)
                    }
                    else if (endtimeminute >= 45 && endtimeminute < 50) {
                        minArray = newArraymin.slice(10, 15)
                    }
                    else if (endtimeminute >= 50 && endtimeminute < 55) {
                        minArray = newArraymin.slice(11, 15)
                    }
                    else if (endtimeminute >= 55) {
                        minArray = newArraymin.slice(0, 15)
                    }
                })


            }



            updateFormData({
                ...formData,
                date: selecteddate,
                start_time_hrs: hrsArray[0],
                start_time_minutes: minArray[0]
            })

            setcloseRequestHrsArray(hrsArray)
            setcloseRequestminuteArray(minArray)

        } else {

            updateFormData({
                ...formData,
                date: selecteddate,
                start_time_hrs: "00",
                start_time_minutes: "00"
            })
            setcloseRequestHrsArray(newArray)
            setcloseRequestminuteArray(newArraymin)
        }



        updateErrorData({
            ...errorData,
            isform_valid_cal: true,


        });
    }

    const onChangeGinpic = (e) => {


        if (e.target.value !== '') {

            updateFormData({
                ...formData,
                ginipig_count: e.target.value,
                selected_time_slots: selected_time_slots
            })
            updateErrorData({
                ...errorData,
                isform_valid_ginpic: true,


            });
        }
        else {
            updateErrorData({
                ...errorData,
                isform_valid_ginpic: false,

            });
        }


    }


    const selectCalendarRequest = (value) => {
        if (value === 2) {
            updateErrorData({
                ...errorData,
                isform_valid_cal: true,
            });
            var today = new Date();
            var datemin1 = today,
                mnth = ("0" + (today.getMonth() + 1)).slice(-2),
                day = ("0" + today.getDate()).slice(-2);
            let selecteddate = [day, mnth, today.getFullYear()].join("-");
            setcurrentDate(selecteddate)
        }
        else {
            updateErrorData({
                ...errorData,
                isform_valid_cal: false,


            });
        }

        updateFormData({
            ...formData,
            request_type: value,
        })
    }

    const checkPatchtestError = (value) => {
        let formattedtime = currentDate + " " + value
        var todaysDate = moment();
        var treatmentDate = moment(formattedtime, "DD-MM-YYYY HH:mm");
        var ms = moment(treatmentDate).diff(todaysDate, "hours");
        if (ms < 72) {
            return true
        } else {
            return false
        }

    }

    const listseletctedmorningslots = (value) => {
        let selectedslots = formData.selected_slots_morning;
        let index = selectedslots.indexOf(value)

        if (index === -1) {
            selectedslots.push(value)
        } else {
            selectedslots.splice(index, 1)
        }

        let newselectedslots = selectedslots.sort(function (a, b) { return a.replace(":", "") - b.replace(":", "") });
        setselected_time_slots(selected_time_slots.map((x) => {
            if (x.date === currentDate)
                return { ...x, selected_slots_morning: newselectedslots };
            else { return x }
        }));
        updateFormData({
            ...formData,
            selected_slots_morning: selectedslots,
            selected_time_slots: selected_time_slots

        });

        updateErrorData({
            ...errorData,
            slot_date_error: ""
        });

    }

    const _selectedSlotMorning = (e, value, id) => {

        if (selected_time_slots.length === 0) {
            updateErrorData({
                ...errorData,
                slot_date_error: "Please select a date before selecting a time"
            });
        } else {
            if (formData.patchtest_required) {
                let ispatchtest = checkPatchtestError(value)
                if (ispatchtest) {
                    window.scrollTo(0, 0);
                    setpatchtesterror("Oops! It doesn’t look like you have allowed enough time to fit in a Patch Test before the appointment")
                } else {
                    setpatchtesterror("")
                    listseletctedmorningslots(value)
                }
            } else {
                setpatchtesterror("")
                listseletctedmorningslots(value)
            }
        }

    }



    const listseletctedafternoonSlots = (value) => {
        // console.log("")
        let selectedslots = formData.selected_slots_afternoon;
        let index = selectedslots.indexOf(value) // check to see if the name is already stored in the array
        if (index === -1) {
            selectedslots.push(value)
        } else {
            selectedslots.splice(index, 1)
        }
        let newselectedslots = selectedslots.sort(function (a, b) { return a.replace(":", "") - b.replace(":", "") });
        setselected_time_slots(selected_time_slots.map((x) => {
            if (x.date === currentDate)
                return { ...x, selected_slots_afternoon: newselectedslots };
            else { return x }
        }));
        updateFormData({
            ...formData,
            selected_slots_afternoon: selectedslots,
            selected_time_slots: selected_time_slots

        });
        updateErrorData({
            ...errorData,
            slot_date_error: ""
        });
    }



    const _selectedSlotAfternoon = (e, value, id) => {
        if (selected_time_slots.length === 0) {
            updateErrorData({
                ...errorData,
                slot_date_error: "Please select a date before selecting a time"
            });
        } else {
            if (formData.patchtest_required) {
                let ispatchtest = checkPatchtestError(value)
                if (ispatchtest) {
                    window.scrollTo(0, 0);
                    setpatchtesterror("Oops! It doesn’t look like you have allowed enough time to fit in a Patch Test before the appointment")
                } else {
                    setpatchtesterror("")
                    listseletctedafternoonSlots(value)
                }
            } else {
                setpatchtesterror("")
                listseletctedafternoonSlots(value)
            }
        }
    }



    const _selectedSlotEvening = (e, value, id) => {
        if (selected_time_slots.length === 0) {
            updateErrorData({
                ...errorData,
                slot_date_error: "Please select a date before selecting a time"
            });
        } else {
            if (formData.patchtest_required) {
                let ispatchtest = checkPatchtestError(value)
                if (ispatchtest) {
                    window.scrollTo(0, 0);
                    setpatchtesterror("Oops! It doesn’t look like you have allowed enough time to fit in a Patch Test before the appointment")
                } else {
                    setpatchtesterror("")
                    listselectedeveningslots(value)
                }
            } else {
                setpatchtesterror("")
                listselectedeveningslots(value)

            }
        }
    }


    const listselectedeveningslots = (value) => {
        let selectedslots = formData.selected_slots_evening;
        let index = selectedslots.indexOf(value) // check to see if the name is already stored in the array
        if (index === -1) {
            selectedslots.push(value)
        } else {
            selectedslots.splice(index, 1)
        }


        let newselectedslots = selectedslots.sort(function (a, b) { return a.replace(":", "") - b.replace(":", "") });

        setselected_time_slots(selected_time_slots.map((x) => {

            if (x.date === currentDate)
                return { ...x, selected_slots_evening: newselectedslots };
            else { return x }


        }));

        updateFormData({
            ...formData,
            selected_slots_evening: selectedslots,
            selected_time_slots: selected_time_slots

        });
        updateErrorData({
            ...errorData,
            slot_date_error: ""
        });
    }




    const __fetchPostcodeData = (e) => {
        e.preventDefault();
        setShowIsAddress(false)
        let postcode = ""
        let locationprofile = ""
        let flat_build_no = ""
        let street_name = ""
        let town_city = ""
        let city = ""
        let country = ""
        let postcodeValue = ""
        let latitude = ""
        let longitude = ""
        //let registerDataLocal = getLocalStorageData("practitionersignupData")
        let registerDataLocal = getLocalStorageData("loginDataUser")
        //let registerData = registerDataLocal && registerDataLocal.data && registerDataLocal.data.user.user_additional_info !== undefined && registerDataLocal.data.user.user_additional_info[0].postcode_data;
        let registerData = registerDataLocal && registerDataLocal.user_additional_info !== undefined && registerDataLocal.user_additional_info.data[0].postcode_data.data;

        //let LocalData = getLocalStorageData("loginData")
        let LocalData = getLocalStorageData("loginDataUser")

        let loginDatapostcode = LocalData && LocalData.user_additional_info.data[0].postcode_data.data;
        if (registerData !== "" && registerData != null && registerData !== undefined) {
            postcode = registerData.postcode;
            locationprofile = registerData.location;
            flat_build_no = registerData.flat;
            town_city = registerData.city;
            city = registerData.city;
            country = registerData.country;
            postcodeValue = registerData.postcode
            latitude = registerData.latitude;
            longitude = registerData.longitude
        } else {

            postcode = loginDatapostcode.postcode;
            locationprofile = loginDatapostcode.location;
            flat_build_no = loginDatapostcode.flat;
            town_city = loginDatapostcode.city;
            city = loginDatapostcode.city;
            country = loginDatapostcode.country;
            postcodeValue = loginDatapostcode.postcode
            latitude = loginDatapostcode.latitude;
            longitude = loginDatapostcode.longitude
        }
        clearSuggestions();
        setShowAddresslayout(true)
        updateFormData({
            ...formData,
            show_postcode: true,
            postcode: postcode,
            town_city: town_city,
            //   postcodeValue: postcodeValue,
            latitude: latitude,
            longitude: longitude,
            flat_build_no: flat_build_no,
            city: city,
            country: country,
            use_profile_location: 1,
            locationprofile: locationprofile

        })
        updateErrorData({
            ...errorData,
            isform_valid_postcode: true,
            postcode_error: ""
        });

    }

    const setmorningDaySlot = () => {
        let selectedslots = [];
        morningSlotsArray.map((item, id) => {
            selectedslots.push(item)

        })
        setselected_time_slots(selected_time_slots.map((x) => {
            if (x.selected_slots_morning.length === 0) {
                if (x.date === currentDate)
                    return { ...x, selected_slots_morning: selectedslots };
                else { return x }

            } else {
                if (x.date === currentDate)
                    return { ...x, selected_slots_morning: [] };
                else { return x }
            }

        }));


        if (formData.selected_slots_morning.length === 0) {
            updateFormData({
                ...formData,
                selected_slots_morning: selectedslots,
                selected_time_slots: selected_time_slots
            })
        } else {
            updateFormData({
                ...formData,
                selected_slots_morning: [],
                selected_time_slots: selected_time_slots
            })
        }
    }

    const toggleChangeOnline = (e) => {
        updateFormData({
            ...formData,
            ischecked_online: !formData.ischecked_online,
            ischecked_inperson: false
        });
    };


    const toggleChangeInPerson = (e) => {
        updateFormData({
            ...formData,
            ischecked_inperson: !formData.ischecked_inperson,
            ischecked_online: false
        });
    };


    const setafternoondayslots = () => {
        let selectedslots = [];
        afternoonSlotsArray.map((item, id) => {
            selectedslots.push(item)
        })
        setselected_time_slots(selected_time_slots.map((x) => {
            if (x.selected_slots_afternoon.length === 0) {
                if (x.date === currentDate)
                    return { ...x, selected_slots_afternoon: selectedslots };
                else { return x }

            } else {
                if (x.date === currentDate)
                    return { ...x, selected_slots_afternoon: [] };
                else { return x }
            }
        }));
        if (formData.selected_slots_afternoon.length === 0) {
            updateFormData({
                ...formData,
                selected_slots_afternoon: selectedslots,
                selected_time_slots: selected_time_slots
            })
        } else {
            updateFormData({
                ...formData,
                selected_slots_afternoon: [],
                selected_time_slots: selected_time_slots
            })
        }
    }

    const seteveningdaySlots = () => {
        let selectedslots = [];
        eveningSlotsArray.map((item, id) => {
            selectedslots.push(item)
        })

        setselected_time_slots(selected_time_slots.map((x) => {
            if (x.selected_slots_evening.length === 0) {
                if (x.date === currentDate)
                    return { ...x, selected_slots_evening: selectedslots };
                else { return x }
            } else {
                if (x.date === currentDate)
                    return { ...x, selected_slots_evening: [] };
                else { return x }
            }
        }));

        if (formData.selected_slots_evening.length === 0) {
            updateFormData({
                ...formData,
                selected_slots_evening: selectedslots,
                selected_time_slots: selected_time_slots
            })
        } else {
            updateFormData({
                ...formData,
                selected_slots_evening: [],
                selected_time_slots: selected_time_slots
            })
        }
    }

    const __selectDaySlot = (e, slot_id) => {
        if (selected_time_slots.length === 0) {
            updateErrorData({
                ...errorData,
                slot_date_error: "Please select a date before selecting a time"
            });
        } else {

            if (slot_id == 1) {
                if (formData.patchtest_required) {
                    let ispatchtest = checkPatchtestError("7.01")
                    if (ispatchtest) {
                        window.scrollTo(0, 0);
                        setpatchtesterror("Oops! It doesn’t look like you have allowed enough time to fit in a Patch Test before the appointment")
                    } else {
                        setpatchtesterror("")
                        setmorningDaySlot()
                    }
                } else {
                    setpatchtesterror("")
                    setmorningDaySlot()
                }

            }
            if (slot_id == 2) {
                if (formData.patchtest_required) {
                    let ispatchtest = checkPatchtestError("12.01")
                    if (ispatchtest) {
                        window.scrollTo(0, 0);
                        setpatchtesterror("Oops! It doesn’t look like you have allowed enough time to fit in a Patch Test before the appointment")
                    } else {
                        setpatchtesterror("")
                        setafternoondayslots()
                    }
                } else {
                    setpatchtesterror("")
                    setafternoondayslots()
                }
            }
            if (slot_id == 3) {
                if (formData.patchtest_required) {
                    let ispatchtest = checkPatchtestError("17.01")
                    if (ispatchtest) {
                        window.scrollTo(0, 0);
                        setpatchtesterror("Oops! It doesn’t look like you have allowed enough time to fit in a Patch Test before the appointment")
                    } else {
                        setpatchtesterror("")
                        seteveningdaySlots()
                    }
                } else {
                    setpatchtesterror("")
                    seteveningdaySlots()
                }

            }

            updateErrorData({
                ...errorData,
                slot_date_error: ""
            });

        }

    }

    const saveAddress = () => {
        if (formData.postcode !== null) {
            if (formData.postcode.length < 6) {
                updateErrorData({
                    ...errorData,
                    isform_valid_postcode: false,
                    postcode_error: "Post Code should be minimum 5 characters "
                });
            } else {
                updateErrorData({
                    ...errorData,
                    isform_valid_postcode: true,
                    postcode_error: ""
                });
            }
        } else {
            updateErrorData({
                ...errorData,
                isform_valid_postcode: false,
                postcode_error: "Postcode cannot be found, please enter address manually below."
            })
        }

        if (formData.flat_build_no !== '' && formData.street_name !== '' && formData.town_city !== '' && formData.postcode !== '' && formData.postcode !== null && formData.postcode.length >= 6) {
            setShowIsTotalAddress(true)
            setShowIsAddress(false)
            setShowAddresslayout(true)
            updateFormData({
                ...formData,
                postcodeValue: "",

            })
        } else {
            updateFormData({
                ...formData,
                postcodeValue: "",

            })
            setShowIsTotalAddress(false)
            setShowIsAddress(true)
            setShowAddresslayout(true)
        }

    }

    // console.log(errorData.isform_valid_cal, errorData.isform_valid_ginpic, formData.postcode, formData.city, formData.flat_build_no, formData.town_city,)
    return (
        <>

            <section className="bgcolor-gray common-section appoint_available_pg pb80">
                <Container>
                    <Row>
                        <Col className="col-lg-11 m-auto">
                            <div className="navigationTop">
                                <div className="heading-history">
                                    <div className="back-history">
                                        <button type="button" onClick={() => history.goBack()} className="back-btn" >
                                            {" "}
                                            <img src={LeftArrow} alt="arrow" />{" "}
                                        </button>
                                    </div>
                                    <div className="history-title">
                                        <h2> Appointment Availability </h2>
                                    </div>
                                </div>
                            </div>

                            <div className="appoint_wrp mt-40 ">
                                <div className="select_request_box mb-40">
                                    <h3 className="select_box_title mb-24">Ready to create an appointment</h3>
                                    <div className="two-col mt-16 mb-32">
                                        <div className="request_btn_item">
                                            <button className={formData.request_type === 1 ? "white_btn active_button " : "white_btn  "} onClick={() => selectCalendarRequest(1)}>
                                                {/* Flexible availability - select multiple dates and times */}
                                                I have multiple dates & times available
                                            </button>
                                        </div>
                                        <div className="request_btn_item">
                                            <button className={formData.request_type === 2 ? "white_btn active_button " : " white_btn "} onClick={() => selectCalendarRequest(2)}>
                                                {/* Set availability - select one dates and times */}
                                                I have a specific date & time available
                                            </button>
                                        </div>
                                    </div>


                                    <div className={formData.request_type === 1 ? "select_appointment_calender" : "select_appointment_calender flexible-hide"}>
                                        {errorData.timeslot_error &&
                                            errorData.timeslot_error != "" ? (
                                            <p className="error">
                                                <p style={{ color: "red" }}>
                                                    {errorData.timeslot_error}{" "}
                                                </p>
                                            </p>
                                        ) : null}

                                        {errorData.slot_date_error &&
                                            errorData.slot_date_error != "" ? (
                                            <p className="error  mb-16">
                                                <p style={{ color: "red" }}>
                                                    {errorData.slot_date_error}{" "}
                                                </p>
                                            </p>
                                        ) : null}

                                        {patchtesterror &&
                                            patchtesterror != "" ? (
                                            <p className="error mb-16">
                                                <p style={{ color: "red" }}>
                                                    {patchtesterror}{" "}
                                                </p>
                                            </p>
                                        ) : null}
                                        <h3 className="calneder_title mb-16">Select the date(s) & time(s) of availability  </h3>
                                        <div className="appointment_boxs mt-8">
                                            <div className="calneder-item">
                                                <div className="pratitioner_canlender ">
                                                    <Calendar
                                                        onChange={(e) => onChange(e)}
                                                        value={dataN}
                                                        minDate={startminDate}
                                                        maxDate={startMaxDate}

                                                        //tileClassName={({ activeStartDate, date, view }) =>  date ===new Date("Sep 18 2020")? 'pratitioner_canlender_active' : ""}
                                                        tileClassName={tileClassName}

                                                        isDisabled={true}
                                                        formatShortWeekday={(locale, date) => ['S', 'M', 'T', 'W', 'T', 'F', 'S'][date.getDay()]}
                                                    />
                                                </div>
                                            </div>
                                            <div className="calneder_slot_times">
                                                <div className="day_slot_box">
                                                    <div className="radio-box">
                                                        <label className="radio_label " htmlFor="day_slot1">
                                                            <input
                                                                type="radio"
                                                                name="morning"
                                                                id="day_slot1"
                                                                disabled={morninggridisable}
                                                                checked={formData.selected_slots_morning.length === morningSlotsArray.length ? true : false}
                                                                onClick={(e) => __selectDaySlot(e, 1)}
                                                            />
                                                            <div className="appoint_slots ml-15 appoint_slot_day">
                                                                <span className="morning_icon icon_slots slot_heading">Morning</span>
                                                            </div>
                                                        </label>
                                                        <div className="slots_box">
                                                            <div className="inside_slot_grid">

                                                                {

                                                                    morningSlotsArray.map((item, index) => {

                                                                        return (

                                                                            <div className="slot_div">
                                                                                <button
                                                                                    disabled={morningSlotsArraydisable.indexOf(item) === -1 ? false : true}
                                                                                    className={formData.selected_slots_morning.indexOf(item) === -1 ? "schedule_slot" : "schedule_slot active_slot"}
                                                                                    onClick={(e) => _selectedSlotMorning(e, item, index)}>
                                                                                    <span className="fix-times">{item}</span>
                                                                                </button>
                                                                            </div>
                                                                        )

                                                                    })
                                                                }


                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="radio-box">

                                                        <label className="radio_label " htmlFor="day_slot2">
                                                            <input
                                                                type="radio"
                                                                name="afternoon"
                                                                id="day_slot2"
                                                                disabled={afternoongridisable ? true : false}
                                                                checked={afternoonSlotsArray.length === formData.selected_slots_afternoon.length ? true : false}
                                                                onClick={(e) => __selectDaySlot(e, 2)}
                                                            />
                                                            <div className="appoint_slots ml-15 appoint_slot_day">
                                                                <span className="afternoon_icon icon_slots slot_heading">Afternoon</span>
                                                            </div>
                                                        </label>
                                                        <div className="slots_box">
                                                            <div className="inside_slot_grid">

                                                                {

                                                                    afternoonSlotsArray.map((item, index) => {

                                                                        return (

                                                                            <div className="slot_div">
                                                                                <button
                                                                                    disabled={morningSlotsArraydisable.indexOf(item) === -1 ? false : true}
                                                                                    className={formData.selected_slots_afternoon.indexOf(item) === -1 ? "schedule_slot" : "schedule_slot active_slot"}
                                                                                    onClick={(e) => _selectedSlotAfternoon(e, item, index)}>
                                                                                    <span className="fix-times">{item}</span>
                                                                                </button>
                                                                            </div>
                                                                        )

                                                                    })
                                                                }


                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="radio-box">
                                                        <label className="radio_label " htmlFor="day_slot3">
                                                            <input
                                                                type="radio"
                                                                name="evening"
                                                                id="day_slot3"
                                                                disabled={eveninggridisable ? true : false}
                                                                checked={formData.selected_slots_evening.length === eveningSlotsArray.length ? true : false}
                                                                onClick={(e) => __selectDaySlot(e, 3)}
                                                            />
                                                            <div className="appoint_slots ml-15 appoint_slot_day">
                                                                <span className="evening_icon icon_slots slot_heading">Evening</span>
                                                            </div>
                                                        </label>
                                                        <div className="slots_box">
                                                            <div className="inside_slot_grid">
                                                                {

                                                                    eveningSlotsArray.map((item, index) => {

                                                                        return (

                                                                            <div className="slot_div">
                                                                                <button
                                                                                    disabled={morningSlotsArraydisable.indexOf(item) === -1 ? false : true}
                                                                                    className={formData.selected_slots_evening.indexOf(item) === -1 ? "schedule_slot" : "schedule_slot active_slot"}
                                                                                    onClick={(e) => _selectedSlotEvening(e, item, index)}>
                                                                                    <span className="fix-times">{item}</span>
                                                                                </button>
                                                                            </div>
                                                                        )

                                                                    })
                                                                }


                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="set_box mt-24">
                                            <h3 className="sub_heading select_box_title">Selected dates and times</h3>
                                            {

                                                selected_time_slots && selected_time_slots.map((sub_item, index) => {


                                                    return (
                                                        <div>
                                                            {
                                                                (sub_item.selected_slots_morning.length > 0 || sub_item.selected_slots_afternoon.length > 0 || sub_item.selected_slots_evening.length > 0) &&
                                                                <div className="form-wrp mb-10">
                                                                    <div className="inside_box1">
                                                                        <div className="time-box">
                                                                            <div className="appointment_date">

                                                                                <p className="dates">

                                                                                    {sub_item.date}

                                                                                </p>
                                                                            </div>
                                                                            <div className="appointment_slot">
                                                                                <div className={sub_item.selected_slots_morning && sub_item.selected_slots_morning.length > 0 ? "day_slot mb-10 " : "day_slot mb-10 disable_opacity"}>
                                                                                    <div className="appoint_slots">
                                                                                        <span className="morning_icon icon_slots slot_heading">Morning</span>
                                                                                    </div>
                                                                                    <div className="appointment_times">
                                                                                        <div className="timeslot">
                                                                                            <div>
                                                                                                {
                                                                                                    sub_item.selected_slots_morning && sub_item.selected_slots_morning.map((item, index) => {

                                                                                                        return (
                                                                                                            <span className="fix-times">{item}</span>
                                                                                                        )
                                                                                                    })}
                                                                                                <div className={sub_item.selected_slots_morning && sub_item.selected_slots_morning.length > 0 ? "noAvailable_box slot-show" : "noAvailable_box"}>
                                                                                                    <span className="noavailaibel_text">No availability</span>
                                                                                                </div>

                                                                                            </div>

                                                                                        </div>


                                                                                    </div>
                                                                                </div>
                                                                                <div className={sub_item.selected_slots_afternoon && sub_item.selected_slots_afternoon.length > 0 ? "day_slot mb-10 " : "day_slot mb-10 disable_opacity"}>
                                                                                    <div className="appoint_slots">
                                                                                        <span className="afternoon_icon icon_slots slot_heading">Afternoon</span>
                                                                                    </div>
                                                                                    <div className="appointment_times">
                                                                                        <div className="timeslot">

                                                                                            {
                                                                                                sub_item.selected_slots_afternoon && sub_item.selected_slots_afternoon.map((item, index) => {
                                                                                                    return (

                                                                                                        <span className="fix-times">{item}</span>
                                                                                                    )
                                                                                                })
                                                                                            }

                                                                                            <div className={sub_item.selected_slots_afternoon && sub_item.selected_slots_afternoon.length > 0 ? "noAvailable_box slot-show" : "noAvailable_box"}>
                                                                                                <span className="noavailaibel_text">No availability</span>
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className={sub_item.selected_slots_evening && sub_item.selected_slots_evening.length > 0 ? "day_slot mb-10 " : "day_slot mb-10 disable_opacity"}>
                                                                                    <div className="appoint_slots">
                                                                                        <span className="evening_icon icon_slots slot_heading">Evening</span>
                                                                                    </div>
                                                                                    <div className="appointment_times">
                                                                                        <div className="timeslot">

                                                                                            {
                                                                                                sub_item.selected_slots_evening && sub_item.selected_slots_evening.map((item, index) => {
                                                                                                    return (

                                                                                                        <span className="fix-times">{item}</span>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                            <div className={sub_item.selected_slots_evening && sub_item.selected_slots_evening.length > 0 ? "noAvailable_box slot-show" : "noAvailable_box"}>
                                                                                                <span className="noavailaibel_text">No availability</span>
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            }
                                                        </div>



                                                    )
                                                }
                                                )

                                            }
                                        </div>



                                    </div>

                                    {/* open request  flexible hide end */}

                                    {/* close request start */}
                                    <div className={formData.request_type === 2 ? "close_req_box " : "close_req_box close_req_hide"}>
                                        {errorData.timeslot_error &&
                                            errorData.timeslot_error != "" ? (
                                            <p className="error  mb-16">
                                                <p style={{ color: "red" }}>
                                                    {errorData.timeslot_error}{" "}
                                                </p>
                                            </p>
                                        ) : null}

                                        {patchtesterror &&
                                            patchtesterror != "" ? (
                                            <p className="error  mb-16">
                                                <p style={{ color: "red" }}>
                                                    {patchtesterror}{" "}
                                                </p>
                                            </p>
                                        ) : null}


                                        <div className="two-col">
                                            <div className="select_item picker_date">
                                                <p className="field-title"> Select date (up to 3 months in advance) </p>
                                                <div className="select_feild">
                                                    <DatePicker

                                                        ref={pickerRef}
                                                        dateFormat="dd/MM/yyyy"
                                                        selected={startDate}
                                                        value={startminDate}
                                                        minDate={startminDate}
                                                        maxDate={startMaxDate}
                                                        onChange={date => {
                                                            setCross(true)
                                                            setDatepick(date)

                                                            var todaysDate = moment();
                                                            var treatmentDate = moment(date, "DD-MM-YYYY");
                                                            var ms = moment(treatmentDate).diff(todaysDate, "days");
                                                            if (formData.patchtest_required) {
                                                                if (ms >=2) {
                                                                    setpatchtesterror("")
                                                                } else {
                                                                    setpatchtesterror("Oops! It doesn’t look like you have allowed enough time to fit in a Patch Test before the appointment")
                                                                }

                                                            }
                                                        }}
                                                        isClearable={cross}
                                                    />

                                                </div>


                                            </div>
                                            <div className="time_box">

                                                <p className="field-title"> Select time(up to 3 hrs before) </p>

                                                <div className="borderbox">
                                                    <div className="inside_box">
                                                        <div className="hrs_box select_feild">
                                                            <select
                                                                value={formData.start_time_hrs}
                                                                onChange={onHrsChange} >


                                                                {
                                                                    closeRequesthrsArray.map((item, index) => {
                                                                        return (
                                                                            <option>{item}</option>
                                                                        )
                                                                    })
                                                                }

                                                                {/* <option>07</option>
                                                                <option>08</option>
                                                                <option>09</option>
                                                                <option>10</option>
                                                                <option>11</option>
                                                                <option>12</option>
                                                                <option>13</option>
                                                                <option>14</option>
                                                                <option>15</option>
                                                                <option>16</option>
                                                                <option>17</option>
                                                                <option>18</option>
                                                                <option>19</option>
                                                                <option>20</option>
                                                                <option>21</option> */}

                                                            </select>


                                                        </div>
                                                        <div className="semisymbolr"><span>:</span></div>
                                                        <div className="mins_box select_feild">

                                                            <select
                                                                value={formData.start_time_minutes}
                                                                onChange={onMinutesChange}>

                                                                {
                                                                    closeRequestminuteArray.map((item, index) => {
                                                                        return (
                                                                            <option>{item}</option>
                                                                        )
                                                                    })
                                                                }
                                                                {/* <option>00</option>
                                                                <option>05</option>
                                                                <option>10</option>
                                                                <option>15</option>
                                                                <option>20</option>
                                                                <option>25</option>
                                                                <option>30</option>
                                                                <option>35</option>
                                                                <option>40</option>
                                                                <option>45</option>
                                                                <option>50</option>
                                                                <option>55</option>
                                                                <option>60</option> */}
                                                            </select>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* end close request */}


                                    <div className="location_wrp_box mt-30">
                                        <p className="select_box_title">Select the type of Appointment</p>



                                        <div className="check-box-online-div">
                                            <label htmlFor="check3">
                                                <div className="d-flex align-items-center mr-4">
                                                    <input
                                                        className="tnc"
                                                        type="checkbox"
                                                        id="check3"
                                                        onClick={toggleChangeInPerson}
                                                        checked={formData.ischecked_inperson === true ? true : false}
                                                        required
                                                    />
                                                    <span className="mr-2 pl-4 select_box_online"> In person </span>
                                                </div>
                                            </label>
                                            <label htmlFor="check4">
                                                <div className="d-flex align-items-center mr-4">
                                                    <input
                                                        className="tnc"
                                                        type="checkbox"
                                                        id="check4"
                                                        onClick={toggleChangeOnline}
                                                        checked={formData.ischecked_online === true ? true : false}
                                                        required
                                                    />
                                                    <span className="mr-2 pl-4 select_box_online"> Online</span>
                                                </div>
                                            </label>

                                        </div>


                                    </div>

                                </div>
                                {/* request button end */}




                                <div className="location_wrp_box mt-16">
                                    {
                                        formData.ischecked_online ?
                                            <div>
                                                <div className="notice-review-online">
                                                    <p>You will be required to contact the GiniPig directly to set up your online appointment, don't worry we will send you their contact details</p>
                                                </div>
                                            </div> : null
                                    }
                                    {
                                        formData.ischecked_inperson ?
                                            <div>
                                                <p className="select_box_title">Appointment Postcode</p>
                                                {/* <p className="select_box_title">Set the location for this appointment</p> */}
                                                {errorData.postcode_error &&
                                                    errorData.postcode_error !== "" ? (
                                                    <p className="error  mb-16">
                                                        <p style={{ color: "red", paddingBottom: 8 }}>
                                                            {errorData.postcode_error}{" "}
                                                        </p>
                                                    </p>
                                                ) : null}
                                                <div className="location-fieldbox pb-16">
                                                    <div className="location_find_item">
                                                        <button className="detect_location_btn" onClick={(e) => __fetchPostcodeData(e)}>Use profile location</button>
                                                    </div>
                                                    <div className="location_find_item or_box">
                                                        <span className="or_text"> or </span>
                                                    </div>
                                                    <div className="location_find_item">
                                                        <span className="checkerroriconcal">

                                                        </span>

                                                        <div className="user_location">
                                                            <div className="loc_point">
                                                                <input
                                                                    className="searchText"
                                                                    type="text"
                                                                    id="postcode"
                                                                    name="postcode"
                                                                    // value={formData.postcode}
                                                                    // onChange={__onTextChange}
                                                                    // placeholder="Postcode"
                                                                    value={formData.postcodeValue}
                                                                    //  style="text-transform:uppercase"
                                                                    autoComplete="off"


                                                                    onChange={handleInput}
                                                                    disabled={!ready}
                                                                    placeholder="Please enter new postcode"
                                                                    // onKeyUp={checkPostcodeValidation}
                                                                    onBlur={checkPostcodeValidation}
                                                                    required

                                                                />
                                                                {status === 'OK' && <ul className="autoSearch">{renderSuggestions()}</ul>}
                                                            </div>
                                                            {/* {
                                                    validatepostcodeData.isFetching
                                                        ? <button className="btn-search" disabled={true}> Loading... </button>
                                                        : <button type="button" className="btn-search" disabled={formData.postcode && formData.postcode != "" ? false : true} onClick={__fetchPostcodeData}>Search</button>
                                                } */}

                                                        </div>

                                                    </div>
                                                </div>


                                                <div className={formData.show_postcode === false ? "two-cols mt-16 flexTop flexboxs" : "two-cols mt-16 flexTop flexboxs"}>

                                                    <div className="address-boxs1">
                                                        <div className={showAddresslayout ? "address_show location_find_item" : "display-none"}>
                                                            {/* {
                                                    formData.postcode !== "" && formData.city !== "" && formData.use_profile_location === 0 ? (
                                                        <p className="address">
                                                            {formData.postcodeValue} <br />
                                                            {formData.city} <br />
                                                            {formData.postcode}
                                                        </p>
                                                    ) : null
                                                } */}
                                                            {/* {
                                                    console.log("formData.postcode is " + formData.postcode, formData.flat_build_no, formData.town_city, formData.use_profile_location, istotalAdress)
                                                } */}

                                                            {
                                                                formData.postcode !== "" && formData.flat_build_no !== "" && formData.town_city !== "" && istotalAdress && formData.use_profile_location == 0 ? (
                                                                    <div className="form-wrp">
                                                                        <p className="address">
                                                                            {formData.flat_build_no}{', '}
                                                                            {formData.street_name} <br />
                                                                            {formData.town_city} <br />
                                                                            {formatPostcode(formData.postcode)}
                                                                        </p>
                                                                    </div>
                                                                ) : null
                                                            }


                                                            {
                                                                formData.postcode !== "" && formData.locationprofile !== "" && formData.use_profile_location === 1 ? (
                                                                    <div className="form-wrp">
                                                                        <p className="address">
                                                                            {formData.flat_build_no}{', '}
                                                                            {formData.locationprofile} <br />
                                                                            {formData.city} <br />
                                                                            {formatPostcode(formData.postcode)}

                                                                        </p>
                                                                    </div>
                                                                ) : null
                                                            }



                                                        </div>
                                                    </div>
                                                    {isaddress ?
                                                        <div className="address-field-details">
                                                            <div className="user_location ">
                                                                <input
                                                                    className="sText"
                                                                    type="text"
                                                                    id="flat_build_no"
                                                                    name="flat_build_no"
                                                                    autoComplete="off"
                                                                    placeholder="Flat/House number "
                                                                    value={formData.flat_build_no}
                                                                    onChange={__onTextChange}
                                                                    required

                                                                />
                                                            </div>
                                                            <div className="user_location mt-8">
                                                                <input
                                                                    className="sText"
                                                                    type="text"
                                                                    id="street_name"
                                                                    name="street_name"
                                                                    autoComplete="off"
                                                                    placeholder="Street name "
                                                                    value={formData.street_name}
                                                                    onChange={__onTextChange}
                                                                    required

                                                                />
                                                            </div>
                                                            <div className="user_location mt-8">
                                                                <input
                                                                    className="sText"
                                                                    type="text"
                                                                    id="town_city"
                                                                    name="town_city"
                                                                    autoComplete="off"
                                                                    placeholder="Town/City name "
                                                                    value={formData.town_city}
                                                                    onChange={__onTextChange}
                                                                    required

                                                                />
                                                            </div>
                                                            <div className="btn-align pd8 pt-8 mt-8">
                                                                <button type="button" className="finishbtn"
                                                                    disabled={formData.flat_build_no !== '' && formData.street_name !== '' && formData.town_city !== '' && formData.postcode !== '' ? false : true}
                                                                    onClick={() => saveAddress()} >Save</button>
                                                            </div>
                                                        </div> : null
                                                    }

                                                </div>
                                            </div> : null

                                    }

                                    <div className="two-cols mt-40">
                                        <div className="location_find_item">
                                            <p className="select_box_title"> Total Number of GiniPigs Required </p>
                                            <div className="center_box borderbox select_feild">
                                                <select
                                                    value={formData.ginipig_count}
                                                    onChange={onChangeGinpic}>
                                                    <option></option>
                                                    <option>1</option>
                                                    <option>2</option>
                                                    <option>3</option>
                                                    <option>4</option>
                                                    <option>5</option>
                                                    <option>6</option>
                                                    <option>7</option>
                                                    <option>8</option>
                                                    <option>9</option>
                                                    <option>10</option>
                                                    <option>11</option>
                                                    <option>12</option>
                                                    <option>13</option>
                                                    <option>14</option>
                                                    <option>15</option>
                                                    <option>16</option>
                                                    <option>17</option>
                                                    <option>18</option>
                                                    <option>19</option>
                                                    <option>20</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div className="btn-align pd32 pt-32">
                                    {
                                        formData.ischecked_online ?
                                            <LargeButton
                                                ButtonType="button"
                                                nextButtonClick={nextButtonClick}
                                                ButtonText={location.redirectPath === "appointment-review" ? "Save Changes" : "Next"}
                                                disabled={errorData.isform_valid_cal === false || errorData.isform_valid_ginpic === false ? true : false}
                                            /> :
                                            <LargeButton
                                                ButtonType="button"
                                                nextButtonClick={nextButtonClick}
                                                ButtonText={location.redirectPath === "appointment-review" ? "Save Changes" : "Next"}
                                                disabled={errorData.isform_valid_cal === false || errorData.isform_valid_ginpic === false || formData.postcode === null || formData.city === "" || formData.flat_build_no === "" || formData.postcode.length < 5 ? true : false}
                                            />
                                    }
                                </div>

                            </div>


                        </Col>
                    </Row>
                </Container>
            </section>



        </>
    );
}

export default AppointmentAvailability;