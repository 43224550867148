import React, { useCallback, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { LeftArrow } from '../../config/images';
import TreatmentRadioButton from '../../components/TreatmentRadioButton';
import LargeButton from '../../components/LargeButton';
import { getLocalStorageData, setLocalStorageData } from '../../utils/storage'

// redux
import { edittreatmentAPI_Action, resetedittreatmentAPI_Action, createAppointmentData_Action, resetcreaterequestAPI_Action, resettreatmentdetailsAPI_Action, categorylistAPI_Action, resettreatmentaddreviewAPI_Action } from '../../stores/actions'

const initialErrorData = Object.freeze({
    treatment_title_error: "",
    treatment_description_error: "",
    treatment_sub_cat_error: "",
    treatment_description_error_active: "Description should be minimum 25 characters long.",

    is_form_valid_title: true,
    is_form_valid_desc: true,
    is_form_valid_sub_cat: true,
});

const TreatmentReviewEdit = (props) => {
    // Initialize hooks first
    const dispatch = useDispatch();
    let history = useHistory();
    // States Management
    const [formData, updateFormData] = useState(Object.freeze({

        title: "",
        description: "",
        token: "",
        category_id: 1,
        sub_category_id: [],
        categories: "",


    }));
    const [errorData, updateErrorData] = useState(initialErrorData);
    const [SubCatlenghtError, setSubCatlenghtError] = useState(false);
    const [character_count, setCharactercount] = useState(500);
    const [character_count_title, setCharactercountTitle] = useState(65);

    // Selectors
    const edittreatmentData = useSelector(state => state.edittreatment);
    const Data = JSON.stringify(edittreatmentData);
    const loginUserData = useSelector(state => state.login);
    const categoryData = useSelector(state => state.categorylist);
    const practitionersignupaddinfoData = useSelector(state => state.practitionersignupaddinfo);
    const AppointmentCreateData = useSelector(state => state.appointmentcreatedata);


    useEffect(() => {

        let checkToken = "";
        const loginToken = loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token : "" : "";
        const RegisterToken = practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.token : "" : "";
        let LocalDatatoken = getLocalStorageData("loginData")

        if (loginToken !== "") {
            checkToken = loginToken;
        } else if (RegisterToken != "") {
            checkToken = RegisterToken;
        }
        else if (LocalDatatoken !== "") {
            checkToken = LocalDatatoken.token;
        }

        if (checkToken === '') {
            history.push('/login');
        }

        dispatch(resettreatmentaddreviewAPI_Action());
        let requestDataLocal = getLocalStorageData("requestDataLocal")
        let useprofilelocation = 1

        if (requestDataLocal.RequestData !== null && requestDataLocal.RequestData !== "" && requestDataLocal.RequestData !== undefined) {
            // console.log(selectedtreatmentId)
            if (requestDataLocal.RequestData.use_profile_location !== null && requestDataLocal.RequestData.use_profile_location !== undefined && requestDataLocal.RequestData.use_profile_location !== "") {
                if (requestDataLocal.RequestData.use_profile_location === 1) {
                    useprofilelocation = 1
                } else {
                    useprofilelocation = 0
                }
            }
        }


        if (requestDataLocal.selected_time_slots !== null) {

            let selectedTimeArray = [];
            requestDataLocal.selected_time_slots && requestDataLocal.selected_time_slots.forEach(element => {
                if (element.selected_slots_morning.length > 0 || element.selected_slots_afternoon.length > 0 || element.selected_slots_evening.length > 0) {
                    selectedTimeArray.push(element)
                }
            })

            updateFormData({
                ...formData,
                selectedTreatmentId: requestDataLocal.RequestData.selectedTreatmentId ? requestDataLocal.RequestData.selectedTreatmentId : "",
                selectedTreatment: requestDataLocal.RequestData.selectedTreatment ? requestDataLocal.RequestData.selectedTreatment : [],
                is_keep_treatment_on_profile: requestDataLocal.RequestData.is_keep_treatment_on_profile ? requestDataLocal.RequestData.is_keep_treatment_on_profile : 0,
                redirectPathName: requestDataLocal.RequestData.redirectPathName ? requestDataLocal.RequestData.redirectPathName : "complete-profile",
                requestId: requestDataLocal.RequestData.requestId ? requestDataLocal.RequestData.requestId : 0,
                rrpprice: requestDataLocal.RequestData.rrpprice ? requestDataLocal.RequestData.rrpprice : "",
                yourprice: requestDataLocal.RequestData.yourprice ? requestDataLocal.RequestData.yourprice : "",

                hrs: requestDataLocal.RequestData.hrs ? requestDataLocal.RequestData.hrs : "",
                request_image_old: requestDataLocal.RequestData.request_image_old ? requestDataLocal.RequestData.request_image_old : [],

                minutes: requestDataLocal.RequestData.minutes ? requestDataLocal.RequestData.minutes : "",
                activeclassformdata: requestDataLocal.RequestData.activeclassformdata ? requestDataLocal.RequestData.activeclassformdata : "",

                reason_require_ginipig: requestDataLocal.RequestData.reason_require_ginipig ? requestDataLocal.RequestData.reason_require_ginipig : "",
                expertise_level: requestDataLocal.RequestData.expertise_level ? requestDataLocal.RequestData.expertise_level : "",

                request_image: requestDataLocal.RequestData.request_image ? requestDataLocal.RequestData.request_image : [],
                attributes_required: requestDataLocal.RequestData.attributes_required ? requestDataLocal.RequestData.attributes_required : false,
                photograph_required: requestDataLocal.RequestData.photograph_required ? requestDataLocal.RequestData.photograph_required : false,
                is_consentform_required: requestDataLocal.RequestData.is_consentform_required ? requestDataLocal.RequestData.is_consentform_required : false,
                patchtest_required: requestDataLocal.RequestData.patchtest_required ? requestDataLocal.RequestData.patchtest_required : false,
                consent_form_link: requestDataLocal.RequestData.consent_form_link ? requestDataLocal.RequestData.consent_form_link : "",
                model_requirements: requestDataLocal.RequestData.model_requirements ? requestDataLocal.RequestData.model_requirements : "",
                photograph_requirements: requestDataLocal.RequestData.photograph_requirements ? requestDataLocal.RequestData.photograph_requirements : "",
                // consent_form_link: requestDataLocal.RequestData.consent_form_link ? requestDataLocal.RequestData.consent_form_link : "",
                consentformName: requestDataLocal.RequestData.consentformName ? requestDataLocal.RequestData.consentformName : "",

                date: requestDataLocal.RequestData.date ? requestDataLocal.RequestData.date : "",
                start_time_hrs: requestDataLocal.RequestData.start_time_hrs ? requestDataLocal.RequestData.start_time_hrs : 0,
                start_time_minutes: requestDataLocal.RequestData.start_time_minutes ? requestDataLocal.RequestData.start_time_minutes : 0,
                postcode: requestDataLocal.RequestData ? requestDataLocal.RequestData.postcode : "",

                postcodeValue: requestDataLocal.RequestData.postcodeValue ? requestDataLocal.RequestData.postcodeValue : "",
                country: requestDataLocal.RequestData.country ? requestDataLocal.RequestData.country : "",
                city: requestDataLocal.RequestData.city ? requestDataLocal.RequestData.city : "",
                latitude: requestDataLocal.RequestData.latitude ? requestDataLocal.RequestData.latitude : "",
                longitude: requestDataLocal.RequestData.longitude ? requestDataLocal.RequestData.longitude : "",
                locationprofile: requestDataLocal.RequestData.locationprofile ? requestDataLocal.RequestData.locationprofile : "",
                use_profile_location: useprofilelocation,
                flat_build_no: requestDataLocal.RequestData.flat_build_no ? requestDataLocal.RequestData.flat_build_no : "",
                ginipig_count: requestDataLocal.RequestData.ginipig_count ? requestDataLocal.RequestData.ginipig_count : "",
                request_type: requestDataLocal.RequestData.request_type ? requestDataLocal.RequestData.request_type : "",
                show_postcode: requestDataLocal.RequestData.show_postcode ? requestDataLocal.RequestData.show_postcode : "",
                ischecked_online: requestDataLocal.RequestData.ischecked_online ? requestDataLocal.RequestData.ischecked_online : false,
                ischecked_inperson: requestDataLocal.RequestData.ischecked_inperson ? requestDataLocal.RequestData.ischecked_inperson : false,

                town_city: requestDataLocal.RequestData.town_city ? requestDataLocal.RequestData.town_city : "",
                street_name: requestDataLocal.RequestData.street_name ? requestDataLocal.RequestData.street_name : "",

                selected_time_slots: requestDataLocal.selected_time_slots ? requestDataLocal.selected_time_slots : [],
                selectedTimeArray: selectedTimeArray,
                category_id: requestDataLocal.RequestData.category_id ? requestDataLocal.RequestData.category_id : "",
                category_name: requestDataLocal.RequestData.category_name ? requestDataLocal.RequestData.name : "",
                sub_category_id: requestDataLocal.RequestData.sub_category_id ? requestDataLocal.RequestData.sub_category_id : "",
                title: requestDataLocal.RequestData.title ? requestDataLocal.RequestData.title : "",
                description: requestDataLocal.RequestData.description ? requestDataLocal.RequestData.description : "",
                categories: requestDataLocal.RequestData.categories ? requestDataLocal.RequestData.categories : "",

                treatment_sub_category: [],

                file1: requestDataLocal.RequestData.file1 ? requestDataLocal.RequestData.file1 : "",
                file2: requestDataLocal.RequestData.file2 ? requestDataLocal.RequestData.file2 : "",
                file3: requestDataLocal.RequestData.file3 ? requestDataLocal.RequestData.file3 : "",
                file4: requestDataLocal.RequestData.file4 ? requestDataLocal.RequestData.file4 : "",

                imagearray1: requestDataLocal.RequestData.imagearray1 ? requestDataLocal.RequestData.imagearray1 : [],
                imagearray2: requestDataLocal.RequestData.imagearray2 ? requestDataLocal.RequestData.imagearray2 : [],
                imagearray3: requestDataLocal.RequestData.imagearray3 ? requestDataLocal.RequestData.imagearray3 : [],
                imagearray4: requestDataLocal.RequestData.imagearray4 ? requestDataLocal.RequestData.imagearray4 : [],
                consent_form: requestDataLocal.RequestData.consent_form ? requestDataLocal.RequestData.consent_form : "",
            });


            if (requestDataLocal.RequestData && requestDataLocal.RequestData.title) {
                setCharactercountTitle(65 - requestDataLocal.RequestData.title.length)
            } else {
                setCharactercountTitle(65)
            }

        }

        // var save_data = {
        //     file1: AppointmentCreateData.appointmentcreatedata.file1 ? AppointmentCreateData.appointmentcreatedata.file1 : "",
        //     file2: AppointmentCreateData.appointmentcreatedata.file2 ? AppointmentCreateData.appointmentcreatedata.file2 : "",
        //     file3: AppointmentCreateData.appointmentcreatedata.file3 ? AppointmentCreateData.appointmentcreatedata.file3 : "",
        //     file4: AppointmentCreateData.appointmentcreatedata.file4 ? AppointmentCreateData.appointmentcreatedata.file4 : "",
        //     consent_form: AppointmentCreateData.appointmentcreatedata.consent_form ? AppointmentCreateData.appointmentcreatedata.consent_form : "",

        //     imagearray1: AppointmentCreateData.appointmentcreatedata.imagearray1 ? AppointmentCreateData.appointmentcreatedata.imagearray1 : [],
        //     imagearray2: AppointmentCreateData.appointmentcreatedata.imagearray2 ? AppointmentCreateData.appointmentcreatedata.imagearray2 : [],
        //     imagearray3: AppointmentCreateData.appointmentcreatedata.imagearray3 ? AppointmentCreateData.appointmentcreatedata.imagearray3 : [],
        //     imagearray4: AppointmentCreateData.appointmentcreatedata.imagearray4 ? AppointmentCreateData.appointmentcreatedata.imagearray4 : [],
        // }

        // dispatch(createAppointmentData_Action(save_data));


        if (categoryData.categorylistdata === null) {
            try {
                dispatch(categorylistAPI_Action());

            } catch (error) {
                console.error(" profile ::  profile() :: Exception ==", error);
            }
        }


    }, [])


    const __onTextChange = (e) => {

        updateFormData({
            ...formData,
            [e.target.name]: e.target.value
        });

        if (e.target.name === "title" && e.target.value !== "") {
            setCharactercountTitle(65 - e.target.value.length)
        } else {
            setCharactercountTitle(65)
        }
        if (e.target.name === "description" && e.target.value !== "") {
            setCharactercount(500 - e.target.value.length)
        } else {
            setCharactercount(500)
        }
        if (e.target.name === "description" && e.target.value.length > 25) {
            updateErrorData({
                ...errorData,

                is_form_valid_desc: false,
                treatment_description_error: ""

            });
        }
        // if(formData.category_id==4){
        //     updateErrorData({
        //         ...errorData,

        //         is_form_valid_desc: false,
        //         is_form_valid_title: false,
        //         is_form_valid_sub_cat:false,

        //     });

        // }

    };



    const checkTreatmentTitleValidation = (e) => {
        if (edittreatmentData.edittreatmentdata !== null && edittreatmentData.isError === true) {
            dispatch(resetedittreatmentAPI_Action());
        }
        const { value } = e.target;
        const title = value.trim();
        if (!title) {

            updateErrorData({
                ...errorData,
                is_form_valid_title: false,

                treatment_title_error: "Treatment title is required."
            });



        } else {
            updateErrorData({
                ...errorData,
                is_form_valid_title: true,
                is_form_valid_desc: true,
                treatment_title_error: ""
            });
        }
    }



    const __selectSubCategory = (e, category_id, id) => {
        // const {id,value} = e.target;
        if (edittreatmentData.edittreatmentdata !== null && edittreatmentData.isError === true) {
            dispatch(resetedittreatmentAPI_Action());
        }
        let selectedBoxes = formData.sub_category_id;

        let index = selectedBoxes.indexOf(parseInt(id)) // check to see if the name is already stored in the array
        if (index === -1) {
            selectedBoxes.push(parseInt(id))
        } else {
            selectedBoxes.splice(index, 1)
        }

        if (selectedBoxes !== null) {
            if (selectedBoxes.length <= 3) {
                //setSubCatlenghtError(true)
                updateErrorData({
                    ...errorData,
                    is_form_valid_sub_cat: true,
                    treatment_sub_cat_error: ""
                });
            } else {
                //setSubCatlenghtError(false)
                updateErrorData({
                    ...errorData,
                    treatment_sub_cat_error: "(select up to 3)"
                });
            }
        }

        selectedBoxes = selectedBoxes.slice(0, 3);
        updateFormData({
            ...formData,
            sub_category_id: selectedBoxes,
            category_id: category_id,
        });
        // updateErrorData({
        //     ...errorData,
        //     is_form_valid_sub_cat: true,
        //     treatment_sub_cat_error: ""
        // });
    }


    const checkDescValidation = (e) => {
        if (edittreatmentData.edittreatmentdata !== null && edittreatmentData.isError === true) {
            dispatch(resetedittreatmentAPI_Action());
        }
        const { id, value } = e.target;
        const description = value.trim();
        if (!description) {

            updateErrorData({
                ...errorData,
                is_form_valid_desc: false,
                treatment_description_error: "Treatment description is required.",
            });


        } else if (description.length < 25) {
            updateErrorData({
                ...errorData,
                is_form_valid_desc: false,
                treatment_description_error: "Description should be minimum 25 characters long.",
            });
        } else if (description.length > 500) {
            updateErrorData({
                ...errorData,
                is_form_valid_desc: false,
                treatment_description_error: "Only 500 Characters are Allowed.",
            });
        } else {
            updateErrorData({
                ...errorData,
                is_form_valid_desc: true,
                treatment_description_error: ""
            });
        }


    };
    const __submitTreatmentapiCall = useCallback(async () => {
        try {
            // console.log(formData.selected_time_slots)
            dispatch(resetcreaterequestAPI_Action());
            setLocalStorageData("pathnamereviewedit", "editTreatment")
            let formDatarequest = {
                RequestData: formData,
                selected_time_slots: formData.selected_time_slots

            }
            setLocalStorageData("requestDataLocal", formDatarequest)
            // await dispatch(createAppointmentData_Action(formData,formData.selected_time_slots));
            let checkToken = "";
            const loginToken = loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token : "" : "";
            const RegisterToken = practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.token : "" : "";
            let LocalDatatoken = getLocalStorageData("loginData")

            if (loginToken !== "") {
                checkToken = loginToken;
            } else if (RegisterToken != "") {
                checkToken = RegisterToken;
            }
            else if (LocalDatatoken !== "") {
                checkToken = LocalDatatoken.token;
            }


            let newformData = {

                id: formData.selectedTreatmentId,
                category_id: formData.category_id,
                sub_category_id: formData.sub_category_id,
                title: formData.title,
                description: formData.description,
                categories: formData.categories,
                treatment_sub_category: formData.treatment_sub_category


            }
            // console.log(newformData)
            if (checkToken !== '') {

                await dispatch(edittreatmentAPI_Action(newformData, checkToken));
                window.scrollTo(0, 0);
                history.push('/appointment-review')
            } else {
                history.push('/login');
            }


        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    });

    const __submitTreatmentForm = (e) => {
        e.preventDefault();
        __submitTreatmentapiCall();
        dispatch(resettreatmentdetailsAPI_Action())
    };

    return (
        <>
            <section className="bgcolor-gray common-section  treatment_content_edit pb80">
                <Container>
                    <Row>
                        <Col className="col-lg-11 m-auto">
                            <div className="navigationTop">
                                <div className="heading-history">
                                    <div className="back-history">
                                        <button type="button" onClick={() => history.goBack()} className="back-btn" >
                                            {" "}
                                            <img src={LeftArrow} alt="arrow" />{" "}
                                        </button>
                                    </div>
                                    <div className="history-title">
                                        <h2> Edit treatment  </h2>
                                    </div>
                                </div>
                            </div>
                            <div className="from-wrp mt-40">
                                <div className="treatment_info_step">
                                    <p className="steps-label mb-16">Select the treatment category </p>
                                    <div className="treatment-catogory">

                                        <div className="category-list">
                                            {/* <TreatmentRadioButton 
                                                CategoryTitle={formData.categories !="" ? formData.categories.name : ""}
                                                id={formData.categories !="" ? formData.categories.id : ""}
                                                activeClass="active_radiobtn"  
                                                activeclassformdata={formData.categories !="" ? formData.categories.id : ""}
                                            /> */}

                                            {
                                                // practitionercatData.practitionercategorydata && practitionercatData.practitionercategorydata.data.user_category_data.map((item, index) => {
                                                categoryData.categorylistdata && categoryData.categorylistdata.data.categories.map((item, index) => {
                                                    if (formData.category_id === item.id) {
                                                        return (
                                                            <div className="category-list">
                                                                <TreatmentRadioButton
                                                                    CategoryTitle={item.name}
                                                                    id={item.id}
                                                                    activeclassformdata={formData.category_id}

                                                                />

                                                            </div>
                                                        )
                                                    }


                                                })
                                            }
                                        </div>

                                    </div>
                                </div>
                                <form action="post" onSubmit={__submitTreatmentForm}>
                                    <div className="treatmentform form-wrp fromProfile mb-24 mt-24">

                                        <div className="profilefield">
                                            <div className="showwithlabel">
                                                <label className="profile-label">Treatment Title</label>
                                                {errorData.treatment_title_error &&
                                                    errorData.treatment_title_error !== "" ? (
                                                    <p className="error">
                                                        <p style={{ color: "red" }}>
                                                            {errorData.treatment_title_error}{" "}
                                                        </p>
                                                    </p>
                                                ) : null}

                                                {edittreatmentData.edittreatmentdata &&
                                                    edittreatmentData.edittreatmentdata.hasOwnProperty(
                                                        "errors"
                                                    ) ? (
                                                    <p className="error">
                                                        {edittreatmentData.edittreatmentdata.errors.hasOwnProperty(
                                                            "title"
                                                        ) ? (
                                                            <p style={{ color: "red" }}>
                                                                {
                                                                    edittreatmentData.edittreatmentdata.errors
                                                                        .title[0]
                                                                }{" "}
                                                            </p>
                                                        ) : null}
                                                    </p>
                                                ) : null}
                                            </div>
                                            <input
                                                type="text"
                                                id="title"
                                                name="title"
                                                value={formData.title}
                                                onChange={__onTextChange}
                                                onBlur={checkTreatmentTitleValidation}
                                                maxlength="65"
                                            />


                                            {
                                                character_count_title == 65 ?
                                                    <span className="char-span-title">{character_count_title} Character limit</span> :
                                                    <span className="char-span-title">{character_count_title} Remaining</span>
                                            }
                                        </div>
                                        <div className="category-box mb-24">
                                            <div className="insidebox">
                                                <div className="optional-errormsg">
                                                    <label className="profile-label">Choose the categories that apply to this treatment</label>
                                                    {errorData.treatment_sub_cat_error &&
                                                        errorData.treatment_sub_cat_error != "" ? (
                                                        <p className="error">
                                                            <p style={{ color: "red" }}>
                                                                {/* {errorData.treatment_sub_cat_error}{" "} */}
                                                                {"(select up to 3)"}
                                                            </p>
                                                        </p>
                                                    ) : <p className="optiontext"> (select up to 3) </p>}

                                                    {/* {edittreatmentData.edittreatmentdata &&
                                                        edittreatmentData.edittreatmentdata.hasOwnProperty(
                                                            "errors"
                                                        ) ? (
                                                            <p className="error">
                                                                {edittreatmentData.edittreatmentdata.errors.hasOwnProperty(
                                                                    "sub_category_id"
                                                                ) ? (
                                                                        <p style={{ color: "red" }}>
                                                                            {
                                                                                edittreatmentData.edittreatmentdata.errors
                                                                                    .sub_category_id[0]
                                                                            }{" "}
                                                                        </p>
                                                                    ) : null}
                                                            </p>
                                                        ) : null} */}

                                                    {/* {SubCatlenghtError ? (
                                                        <p className="error">
                                                            <p style={{ color: "red" }}>
                                                                {"You can select only three subcategories."}
                                                            </p>
                                                        </p>
                                                    ) : null}

                                                    <p className="optiontext">(select up to 3)</p> */}
                                                </div>
                                            </div>
                                            <div className="category-checkbox-grp">

                                                {
                                                    // categoryData.categorylistdata && categoryData.categorylistdata.data.categories[formData.category_id - 1].sub_categories.data.map((sub_item, sub_index) => {
                                                    formData.categories != "" && formData.categories.sub_categories.map((sub_item, sub_index) => {

                                                        return (

                                                            <div className="checkbox-item">
                                                                <div className={formData.sub_category_id.indexOf(sub_item.id) !== -1 ? "checkbox-button sub-background" : "checkbox-button"} >
                                                                    <label className="checkox-label yes-checked">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="checkbox-btn"
                                                                            id={sub_item.id}
                                                                            name={sub_item.id}
                                                                            onClick={(e) => __selectSubCategory(e, formData.category_id, sub_item.id)}
                                                                            value={sub_item.id}
                                                                            checked={formData.sub_category_id.indexOf(sub_item.id) !== -1 ? true : false} />
                                                                        <span className="button-text">{sub_item.name}</span>
                                                                    </label>

                                                                </div>
                                                            </div>



                                                        )
                                                    })
                                                }


                                            </div>
                                        </div>


                                        <div className="profilefield mb-0">
                                            <div className="showwithlabel">
                                                <label className="profile-label"> Treatment Description </label>
                                                {errorData.treatment_description_error &&
                                                    errorData.treatment_description_error != "" ? (
                                                    <p className="error">
                                                        <p className="errortext">
                                                            {errorData.treatment_description_error}{" "}
                                                        </p>
                                                    </p>
                                                ) : <p className="error">
                                                    <p className="optiontext">
                                                        {errorData.treatment_description_error_active}{" "}
                                                    </p>
                                                </p>}

                                                {edittreatmentData.edittreatmentdata &&
                                                    edittreatmentData.edittreatmentdata.hasOwnProperty(
                                                        "errors"
                                                    ) ? (
                                                    <p className="error">
                                                        {edittreatmentData.edittreatmentdata.errors.hasOwnProperty(
                                                            "description"
                                                        ) ? (
                                                            <p style={{ color: "red" }}>
                                                                {
                                                                    edittreatmentData.edittreatmentdata.errors
                                                                        .description[0]
                                                                }{" "}
                                                            </p>
                                                        ) : null}
                                                    </p>
                                                ) : null}
                                            </div>
                                            <div className="input-addicon addicontextarea" >
                                                <textarea
                                                    id="description"
                                                    name="description"
                                                    value={formData.description}
                                                    onChange={__onTextChange}
                                                    onBlur={checkDescValidation}
                                                    placeholder="Keep your wording relevant to this treatment, you might like to add more details about your level of expertise to make this appealing. If access is difficult for people with disabilities please remember to include this. Parking is a bonus if you have it, so don't forget to mention it!"
                                                    onFocus={(e) => e.target.placeholder = ""}
                                                    maxlength="500"
                                                />
                                                {
                                                    character_count === 500 ?
                                                        <span className="char-span">{character_count} Character limit</span> :
                                                        <span className="char-span">{character_count} Remaining</span>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="btn-align pd32 pt-32">
                                        <LargeButton
                                            ButtonType="submit"
                                            ButtonText="Save changes"
                                            disabled={errorData.is_form_valid_title === false || errorData.is_form_valid_desc === false || errorData.is_form_valid_sub_cat === false ? true : false}

                                        />

                                    </div>
                                </form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default TreatmentReviewEdit;