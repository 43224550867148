import * as types from "../types/index";
import { setInitialState } from "../InitialState";


const INITIAL_STATE = setInitialState({
    educationDisablesdata: null,
});


export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.EDUCATIONDISABLE_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case types.EDUCATIONDISABLE_RECEIVE:
            return {
                ...state,
                isFetching: false,
                isSuccess: true,
                educationDisablesdata: action.payload.success
            };
        case types.EDUCATIONDISABLE_FAILURE:
            return {
                ...state,
                isFetching: false,
                isError: true,
                educationDisablesdata: action.payload.response.error,
            };
        case types.RESET_EDUCATIONDISABLE:
            return {
                ...state,
                isFetching: false,
                isError: false,
                educationDisablesdata: null,
            };
        default:
            return {
                ...state
            }
    }
}