import React, { useCallback, useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from 'react-bootstrap';
import { LeftArrow, iconClose } from '../../config/images';
import ImageUpload from '../../components/ImageUpload';
import LargeButton from '../../components/LargeButton';
import { getLocalStorageData } from '../../utils/storage'
import {imageUploadAPI_Action,resetimageUploadAPI_Action, PractionerfeatureImageAPI_Action, ResetPractionerfeatureImageAPI_Action,createAppointmentData_Action, createrequestAPI_Action, resettreatmentdetailsAPI_Action, categorylistAPI_Action, practitinerCategoryAPI_Action } from "../../stores/actions";

const initialErrorData = Object.freeze({
    name_error: "",
    bio_error: "",
    bio_error_active: "Should be minimum 25 characters long.",

    image_type_error: "",
    is_form_valid: true,
});
const PractitionerInfo = () => {

    let history = useHistory();
    const dispatch = useDispatch();
    const [formData, updateFormData] = useState(Object.freeze({
        name: "",
        bio: "",
        token: "",
        file1: "",
        file2: "",
        imagearray1: [],
        imagearray2: [],

        feature_image: [],

    }));
    const [errorData, updateErrorData] = useState(initialErrorData);
    const [character_count, setCharactercount] = useState(500);
    const [currentimageId, setcurrentimageId] = useState(1);
    const [disablename, setDisabeleName] = useState(true);
    const [hideBack, sethideBack] = useState(false);
    const practitionercatData = useSelector(state => state.practitionercategory);

    const loginUserData = useSelector(state => state.login);
    const practitionersignupaddinfoData = useSelector(state => state.practitionersignupaddinfo);
    const practitionerfeatureImageData = useSelector(state => state.practictionerfeatureImage);
    const userData = useSelector(state => state.userdetails);
    const AppointmentCreateData = useSelector(state => state.appointmentcreatedata);
    const imageUploaddata = useSelector(state => state.imageUploaddata);
 
   

    useEffect(() => {

        let localDataLogin = getLocalStorageData("loginData")
       // console.log(localDataLogin)
        let registerData = getLocalStorageData("practitionersignupData")


        //console.log(practitionersignupaddinfoData.practitionersignupaddinfodata)
        if (registerData && registerData && registerData.user != "") {

            updateFormData({
                ...formData,
                name: registerData.user.name,
                bio: registerData.user.bio ? registerData.user.bio : "",
                token: registerData.token
            }, []);

            let token = registerData.token
                try {
                    dispatch(practitinerCategoryAPI_Action(token));

                } catch (error) {
                    console.error(" profile ::  profile() :: Exception ==", error);
                }
         
        } else
            if (localDataLogin !== null && localDataLogin !== undefined) {

                updateFormData({
                    ...formData,
                    name: userData.userdetailsdata.data.user_details.name,
                    bio: userData.userdetailsdata.data.user_details.bio ? userData.userdetailsdata.data.user_details.bio : "",
                    token: localDataLogin.token
                }, []);

                if (localDataLogin !== null && localDataLogin !== undefined) {
                    let token = localDataLogin.token
                    if (practitionercatData.practitionercategorydata === null) {
                        try {
                            dispatch(practitinerCategoryAPI_Action(token));


                        } catch (error) {
                            console.error(" profile ::  profile() :: Exception ==", error);
                        }
                    }
                }


            }

        let formDataNew = {}
        let redirectPathName = ""
        if (AppointmentCreateData.appointmentcreatedata !== null) {
           // console.log(AppointmentCreateData.appointmentcreatedata)
            formDataNew = AppointmentCreateData.appointmentcreatedata
            // redirectPathName=AppointmentCreateData.appointmentcreatedata.redirectPathName?AppointmentCreateData.appointmentcreatedata.redirectPathName:""
        }
      //  console.log(redirectPathName)
        let RedirectPathName = getLocalStorageData("RedirectPathName")
        if (RedirectPathName == "join-practitioner") {

            dispatch(createAppointmentData_Action(formDataNew));
        }


        else {
            //  history.push('/login');
        }

        dispatch(resetimageUploadAPI_Action())

    }, [])




    const checkNameValidation = (e) => {
        const { id, value } = e.target;
        if (!value) {
            window.scrollTo(0, 0);
            updateErrorData({
                ...errorData,
                is_form_valid: false,
                name_error: " Profile display name is Required.",
            });
        } else if (value.length < 2) {
            window.scrollTo(0, 0);
            updateErrorData({
                ...errorData,
                is_form_valid: false,
                name_error: "Name should be minimum 2 characters long.",
            });
        } else if (value.length > 50) {
            window.scrollTo(0, 0);
            updateErrorData({
                ...errorData,
                is_form_valid: false,
                name_error: "Only 50 Characters are Allowed.",
            });
        } else {
            updateErrorData({
                ...errorData,
                is_form_valid: true,
                name_error: "",
            });
        }
    };
    const checkBioValidation = (e) => {
        const { id, value } = e.target;

        if (!value) {
            window.scrollTo(0, 0);
            updateErrorData({
                ...errorData,
                is_form_valid_desc: false,
                bio_error: "Should be minimum 25 characters long.",
            });


        } else if (value.length < 25) {
            window.scrollTo(0, 0);
            updateErrorData({
                ...errorData,
                is_form_valid: false,
                bio_error: "Should be minimum 25 characters long.",
            });
        } else if (value.length > 500) {
            window.scrollTo(0, 0);
            updateErrorData({
                ...errorData,
                is_form_valid: false,
                bio_error: "Only 500 Characters are Allowed.",
            });
        } else {


            updateErrorData({
                ...errorData,
                is_form_valid: true,
                bio_error: "",
            });
        }


    };

    const deleteImg1 = () => {

        updateFormData({
            ...formData,
            file1: "",
            imagearray1: [],
        });


    }
    const deleteImg2 = () => {


        updateFormData({
            ...formData,
            file2: "",
            imagearray2: [],
        });


    }

    const editProfileInfo = (e) => {
        e.preventDefault();
        setDisabeleName(false)
    }


    if (imageUploaddata !== null && imageUploaddata.isSuccess) {
        if (currentimageId == 1) {
            updateFormData({
                ...formData,
                file1: imageUploaddata.imageUploaddata.data.image_link,
                imagearray1: [imageUploaddata.imageUploaddata.data.image_link]
            });
            dispatch(resetimageUploadAPI_Action())
            
        } else if (currentimageId == 2) {
            updateFormData({
                ...formData,
                file2: imageUploaddata.imageUploaddata.data.image_link,
                imagearray2: [imageUploaddata.imageUploaddata.data.image_link]
            });
            dispatch(resetimageUploadAPI_Action()) 
        }  
      }

    const handleFileChange1 = (e) => {

        dispatch(ResetPractionerfeatureImageAPI_Action())
        dispatch(resetimageUploadAPI_Action())
        setcurrentimageId(1)

        let filetype = e.target.files[0].type.substring(e.target.files[0].type.lastIndexOf('/'))

        let newfiletype = filetype.replace("/", "")
        if (newfiletype == "jpeg" || newfiletype == "jpg" || newfiletype == "png") {

            let imageArray = []
            if (e.target.files[0] != undefined) {
              let image =  e.target.files[0];
              imageArray.push(image)
              dispatch(imageUploadAPI_Action(image));

                // updateFormData({
                //     ...formData,
                //     imagearray1: imageArray,
                //     file1: URL.createObjectURL(e.target.files[0]),
                // });

            }
            updateErrorData({
                ...errorData,
                is_form_valid: true,
                image_type_error: "",
            });
        }
        else {
            updateErrorData({
                ...errorData,
                is_form_valid: false,
                image_type_error: "Only JPEG,JPG,PNG file extentions are allowed.",
            });

        }



    };
    const handleFileChange2 = (e) => {

        dispatch(ResetPractionerfeatureImageAPI_Action())
        dispatch(resetimageUploadAPI_Action())
        setcurrentimageId(2)

        let filetype = e.target.files[0].type.substring(e.target.files[0].type.lastIndexOf('/'))

        let newfiletype = filetype.replace("/", "")
        if (newfiletype == "jpeg" || newfiletype == "jpg" || newfiletype == "png") {

            let imageArray = [];
            if (e.target.files[0] != undefined) {
                 let image = e.target.files[0]
                imageArray.push(e.target.files[0])
                dispatch(imageUploadAPI_Action(image));
                // updateFormData({
                //     ...formData,
                //     imagearray2: imageArray,
                //     file2: URL.createObjectURL(e.target.files[0]),

                // });

            }
            updateErrorData({
                ...errorData,
                is_form_valid: true,
                image_type_error: "",
            });
        }
        else {
            updateErrorData({
                ...errorData,
                is_form_valid: false,
                image_type_error: "Please upload only JPEG,JPG and PNG file.",
            });

        }



    };

    const handleChange = (e) => {


        updateFormData({
            ...formData,
            [e.target.name]: e.target.value
        });

        if (e.target.name === "bio" && e.target.value !== "") {
            setCharactercount(500 - e.target.value.length)
        }
        if (e.target.name === "bio" && e.target.value.length > 25) {
            //console.log("inside",e.target.value.length)
            updateErrorData({
                ...errorData,

                is_form_valid: true,
                bio_error: ""

            });
        }
    };
    const __submitPractitionerInfoForm = (e) => {
        e.preventDefault();
        let formDataNew = {}
        let redirectPathName = ""
        let requestDataLocal = getLocalStorageData("requestDataLocal")
        if (requestDataLocal!==undefined && requestDataLocal.RequestData !== null && requestDataLocal.RequestData !== "" && requestDataLocal.RequestData !== undefined) {
           // console.log(AppointmentCreateData.appointmentcreatedata)
            formDataNew = requestDataLocal.RequestData
            // redirectPathName=AppointmentCreateData.appointmentcreatedata.redirectPathName?AppointmentCreateData.appointmentcreatedata.redirectPathName:""
        }
      //  console.log(redirectPathName)
        let RedirectPathName = getLocalStorageData("RedirectPathName")
        __apiCallProfile();
        // if (RedirectPathName == "join-practitioner") {

        //     __apiCallAppointment(formDataNew);
        // } else {

        //     __apiCallProfile();
        // }


    };


    const __apiCallProfile = useCallback(async () => {

        let feature_image = formData.imagearray1.concat(formData.imagearray2)

        try {
            await dispatch(PractionerfeatureImageAPI_Action(formData, feature_image));
        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
        window.scrollTo(0,0);
        history.push('/join-practitioner-community');
    
    });

    
    

    // console.log("ddsd",practitionerfeatureImageData)

    const __apiCallAppointment = useCallback(async (formDataNew) => {

        __apiCallRequest(formDataNew);

        let feature_image = formData.imagearray1.concat(formData.imagearray2)
        try {
            await dispatch(createAppointmentData_Action(formDataNew));
            await dispatch(PractionerfeatureImageAPI_Action(formData, feature_image));

        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }

    });

    const __apiCallRequest = useCallback(async (formDataNew) => {
        try {

            let checkToken = "";

            let LocalDatatoken = getLocalStorageData("loginData")
            let registerData = getLocalStorageData("practitionersignupData")

            if (registerData != "" && registerData !== undefined && registerData !== null) {
                checkToken = registerData.token;
            }
            else if (LocalDatatoken !== "" && LocalDatatoken !== undefined && LocalDatatoken !== null) {
                checkToken = LocalDatatoken.token;
            }
            if (checkToken === '') {
                window.scrollTo(0, 0);
                history.push('/login');
            }
            await dispatch(createrequestAPI_Action(formDataNew, checkToken));


            window.scrollTo(0, 0);
            history.push('/appointment-practitoner-confirmation')

        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    });


    return (
        <>
            <section className="bgcolor-gray common-section PractitionInfopg pb80">
                <Container>
                    <Row>
                        <Col className="col-lg-11 m-auto">
                            <div className="navigationTop">
                                <div className="heading-history">
                                    <div className="back-history">
                                        {/* <button type="button" onClick={() => history.goBack()} className="back-btn" >
                                            {" "}
                                            <img src={LeftArrow} alt="arrow" />{" "}
                                        </button> */}
                                    </div>

                                    <div className="history-title">
                                        <h2>About Me</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="from-wrp mt-40">
                                <div className="from_inside_wrp ">
                                    <form action="post" onSubmit={__submitPractitionerInfoForm}>
                                        <div className="fromProfile form-wrp mb-24">
                                            <div className="profilefield">
                                                <div className="showwithlabel">
                                                    <label className="profile-label">Profile display name</label>
                                                    {errorData.name_error &&
                                                        errorData.name_error != "" ? (
                                                            <p className="error">
                                                                <span style={{ color: "red" }}>
                                                                    {errorData.name_error}{" "}
                                                                </span>
                                                            </p>
                                                        ) : null}
                                                </div>
                                                <div className="input-addicon addicontext" >
                                                    <input
                                                        value={formData.name}
                                                        disabled={disablename}
                                                        onChange={handleChange}
                                                        onBlur={checkNameValidation}
                                                        id="name"
                                                        name="name"
                                                        type="text"
                                                    />
                                                    <button className="btn-edit-name  " onClick={editProfileInfo}>
                                                        Edit
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="profilefield mb-0">
                                                <div className="showwithlabel">
                                                    <label className="profile-label">Write a short bio for your profile</label>
                                                    {errorData.bio_error &&
                                                        errorData.bio_error != "" ? (
                                                            <p className="error">
                                                                <span className="errortext">
                                                                    {errorData.bio_error}{" "}
                                                                </span>
                                                            </p>
                                                        ) :  <p className="error">
                                                        <span className="optiontext">
                                                            {errorData.bio_error_active}{" "}
                                                        </span>
                                                    </p>}
                                                </div>
                                                <div className="input-addicon" >
                                                    <textarea
                                                        placeholder="This is your shop window to sell yourself to GiniPig's, ensure you include your favourite pictures of your portfolio. Be honest about qualifications, training & experience. Everyone loves warm & friendly. Your GiniPig will review you on Professionalism, Friendliness, Reliability & Results. You can come back & edit your profile at anytime - as you grow. Remember we are here for all your Training, Case Study, Portfolio requirements & even filling those empty slots. Let's get filling your diary!"
                                                        id="bio"
                                                        name="bio"
                                                        value={formData.bio}
                                                        onChange={handleChange}
                                                        onBlur={checkBioValidation}
                                                        onFocus={(e)=>e.target.placeholder = ""}
                                                        maxLength="500"
                                                        required
                                                    />
                                                    {
                                                        character_count === 500 ?
                                                            <span className="char-span">{character_count} Character limit</span> :
                                                            <span className="char-span">{character_count} Remaining</span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="label-imgupload fromProfile">
                                            <div className="profilefield-label">
                                                <div className="optional-errormsg">
                                                    {/* <label className="profile-label">Start your portfolio by adding 2 feature images that will
                                                    appear at the top of your profile of treatments</label> */}
                                                    <label className="profile-label">Add 2 of your own photos to enhance your profile and to show off your skills ( remember no business names or logo's allowed)</label>
                                                    <p className="optiontext">Optional</p>
                                                </div>
                                            </div>
                                            {errorData.image_type_error &&
                                                errorData.image_type_error != "" ? (
                                                    <p className="error">
                                                        <span style={{ color: "red" }}>
                                                            {errorData.image_type_error}{" "}
                                                        </span>
                                                    </p>
                                            ) : null}
                                            {imageUploaddata.imageUploaddata &&
                                                imageUploaddata.imageUploaddata.hasOwnProperty("errors") ? (
                                                <p className="error">
                                                    {imageUploaddata.imageUploaddata.errors.hasOwnProperty(
                                                    "image"
                                                    ) ? (
                                                    <p style={{ color: "red" }}>
                                                        {
                                                        imageUploaddata.imageUploaddata.errors.image[0]
                                                        }{" "}
                                                    </p>
                                                    ) : null}
                                                </p>
                                                ) : null}
                                        </div>
                                        <div className=" form-wrp">
                                            <div className="inside-photoupload">
                                                <div className="two-col">
                                                    <div className="firstuploadimg">

                                                        <ImageUpload
                                                            id="profile_pic1"
                                                            File={formData.file1 ? formData.file1 : null}
                                                            handleFileChange={handleFileChange1}
                                                            RemoveImg={deleteImg1}
                                                            setEdit={formData.file1 == ""}
                                                            showloading={imageUploaddata.isFetching && currentimageId == 1 ? true : false}
                                                        />
                                                    </div>

                                                    <div className="seconduploadimg">
                                                        <ImageUpload
                                                            id="profile_pic"
                                                            File={formData.file2 ? formData.file2 : null}
                                                            handleFileChange={handleFileChange2}
                                                            RemoveImg={deleteImg2}
                                                            setEdit={formData.file2 == ""}
                                                            showloading={imageUploaddata.isFetching && currentimageId == 2 ? true : false}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="btn-align pd32">
                                            {
                                                practitionerfeatureImageData.isFetching || imageUploaddata.isFetching
                                                    ? <LargeButton
                                                        disabled={true}
                                                        ButtonText="Loading..."
                                                    /> :
                                                    <LargeButton
                                                        ButtonType="submit"
                                                        disabled={errorData.is_form_valid === false}
                                                        ButtonText="Finish"
                                                    />
                                            }

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </section>
        </>
    );
}

export default PractitionerInfo;