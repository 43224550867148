import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import { LeftArrow, star, favourite, unfavourite, Icon_results, Icon_etiquette, Icon_professionalism, Icon_cleanliness, practitioner_avtar, Aesthetic, Hair, Beauty, wellness, AestheticTwo, BeautyTwo, HairTwo, wellnessTwo } from '../../config/images';
import AccordionContentForAppointment from '../../components/UIComponent/AccordionContentForAppointment';
import AppointmentCalender from '../../components/AppointmentCalender';
import ProfileReviewCard from '../../components/UIComponent/ProfileReviewCard';
import ReviewBox from '../../components/UIComponent/ReviewBox';
import ReactPaginate from 'react-paginate';
import SlickSlider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from 'moment';
import { getLocalStorageData, setLocalStorageData, clearLocalStorage } from '../../utils/storage';
import { ginipigPractitionerProfileAPI_Action, resettreatmentdetailsAPI_Action, treatmentFavAPI_Action, resetcreateAppointmentData_Action, practitionerFav, resetPhotoDetails, resetbookingDetailsSave, resetpractitionerProfileAPI_Action, resetAPI_ActionWhatAvailable, resetLoginAPI_Action, resetpractitionerFavData_Action } from '../../stores/actions';
import { formatPostcode } from '../../utils/utility';
import AccordionContentFav from '../../components/UIComponent/AccordionContentFav';

const PractitionerProfileForGiniPig = (props) => {
    const { data, Setdata } = useState(
        Object.freeze({
            date: new Date(),
        })
    )
    const myRef = useRef()

    let history = useHistory();
    // Initialize hooks first   
    const dispatch = useDispatch();
    const [profileImages, setProfileImages] = useState([]);
    const [isActive, setIsActive] = useState();


    const [tabpankey, setTabpankey] = useState(1);
    const [offset, setOffset] = useState(0);
    const [perPage, setPerpage] = useState(10);
    const [currentPage, setCurrentpage] = useState(0);

    const [perPagereview, setPerpagereview] = useState(3);
    const [offsetreview, setOffsetreview] = useState(0);
    const [showLoadingFav, setShowLoqadingFav] = useState(false);
    const [favtreatment, setFavTreatment] = useState(false);


    const [currentPagereview, setCurrentpagereview] = useState(0);
    const [isFav, setIsfav] = useState(false);
    const [isFavtreatment, setisFavtreatment] = useState(false);
    const [favId, setFavid] = useState(false);
    const [showreviewpract, setshowReviewPract] = useState(false);


    // Selectors
    const userData = useSelector(state => state.userdetails);
    const loginUserData = useSelector(state => state.login);
    const practitionersignupaddinfoData = useSelector(state => state.practitionersignupaddinfo);
    const practitioneProfileData = useSelector(state => state.practitionerprofile);
    const practitionerData = practitioneProfileData.practitionerprofiledata && practitioneProfileData.practitionerprofiledata.data ? practitioneProfileData.practitionerprofiledata.data.profiledata : null;
    const AppointmentCreateData = useSelector(state => state.appointmentcreatedata);
    var param_data = props.location.state ? props.location.state.paramData : "";
    var categoryId = props.location.state ? props.location.state.categoryId : "";
    var profile_data = props.location.state ? props.location.state.profile : false;
    const [treatmentDateTime, setTreatmentDateTime] = useState([]);
    let loginTokenData = getLocalStorageData("loginData")
    const loginToken = loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token : "" : "";
    const RegisterToken = practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.token : "" : "";

    const [formData, updateFormData] = useState(Object.freeze({

        selected_slots_morning: [],
        selected_slots_afternoon: [],
        selected_slots_evening: [],
        selected_date_time_array: [],
        request_type: 1,
        whhats_available_pagination: 0,
        treatment_title: "Select a treatment",
        selected_date_slots: [],

        morningSlotsArray: ["7:00", "7:30", "8:00", "8:30", "9:00", "9:30", "10:00", "10:30", "11:00", "11:30"],
        afternoonSlotsArray: ["12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30"],
        eveningSlotsArray: ["17:00", "17:30", "18:00", "18:30", "19:00", "19:30", "20:00", "20:30", "21:00", "21:30"],

    }));

    const SampleNextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div className={className} style={{ ...style }} onClick={onClick}>
                {" "}
                <img src={LeftArrow} className="arrow_icons" alt="icon" />{" "}
            </div>
        );
    };


    useEffect(() => {

        // console.log("inside useeffect gini")

        try {
            let checkToken = "";

            if (loginToken != "") {
                checkToken = loginToken;
            } else if (RegisterToken != "") {
                checkToken = RegisterToken;
            }
            else if (loginTokenData !== undefined) {
                checkToken = loginTokenData.token;
            }
            var tabpankey = getLocalStorageData('tabpankey');
            if (tabpankey === 1) {
                window.scrollTo(0, 0);
            } else {
                window.scrollTo(0, 0);

            }
            setTabpankey(1)
            updateFormData({
                ...formData,
                whhats_available_pagination: 1
            })
            // if (tabpankey != undefined) {
            //     setTabpankey(tabpankey)
            //     updateFormData({
            //         ...formData,
            //         whhats_available_pagination: 0
            //     })

            // } else {
            //     setTabpankey(1)
            //     updateFormData({
            //         ...formData,
            //         whhats_available_pagination: 1
            //     })
            // }

            var ginipig_id = 0;
            if (loginTokenData !== undefined) {
                if (loginTokenData.user.type === "ginipig") {
                    ginipig_id = loginTokenData.user.uuid;
                } else {

                    if (loginTokenData.user.uuid === param_data) {
                        setshowReviewPract(true)
                    }
                }
            }

            clearLocalStorage("timeselected")
            clearLocalStorage("pathnamegini")
            dispatch(ginipigPractitionerProfileAPI_Action(param_data, ginipig_id))
            dispatch(resetcreateAppointmentData_Action());
            dispatch(resettreatmentdetailsAPI_Action);


        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
        dispatch(resetAPI_ActionWhatAvailable());

    }, [])


    useEffect(() => {
        if (practitioneProfileData.practitionerprofiledata !== null && practitioneProfileData.practitionerprofiledata.data !== undefined) {
            if (practitioneProfileData.practitionerprofiledata.data.profiledata.is_favourite) {
                setIsfav(true)
            }
            var tabpankey = getLocalStorageData('tabpankey');
            if (tabpankey === 1) {
                if (!favtreatment) {
                    window.scrollTo(0, 0);
                }

            } else {
                window.scrollTo(0, 500);

            }
        }



    }, [practitioneProfileData.practitionerprofiledata])

    if (practitioneProfileData.practitionerprofiledata != null && practitioneProfileData.practitionerprofiledata.status_code === 401) {
        dispatch(resetpractitionerProfileAPI_Action());
        clearLocalStorage("loginData")
        clearLocalStorage("practitionersignupData")
        clearLocalStorage("RedirectPathName")
        clearLocalStorage("requestDataLocal")
        window.scrollTo(0, 0);
        history.push('/login');
    }


    if (practitioneProfileData.practitionerFavData && practitioneProfileData.practitionerFavData.status_code === 401) {
        dispatch(resetpractitionerProfileAPI_Action());
        dispatch(resetpractitionerFavData_Action());
        dispatch(resetLoginAPI_Action());
        clearLocalStorage("loginData")
        clearLocalStorage("practitionersignupData")
        clearLocalStorage("RedirectPathName")
        clearLocalStorage("requestDataLocal")
        window.scrollTo(0, 0);
        history.push('/login');
    }


    if (practitionerData !== null && practitioneProfileData.isSuccess) {
        var tabpankeynew = getLocalStorageData('tabpankey');
        if (tabpankeynew === 1) {
        } else if (!favtreatment) {
            var timer = setInterval(function () {
                clearInterval(timer);
            }, 1000);

        }
        if (profileImages.length == 0) {
            var tempImg = [];
            if (practitionerData && practitionerData.practitioner_portfolio_new.length < 2) {
                var islength = true;
                if (practitionerData && practitionerData.practitioner_portfolio_new.length == 1) {
                    islength = false;
                    tempImg.push(practitionerData && practitionerData.practitioner_portfolio_new[0].image_link)
                }
                if (practitionerData.selected_category == 3) {
                    tempImg.push(Aesthetic);
                    if (islength) {
                        tempImg.push(AestheticTwo);
                    }
                } else if (practitionerData.selected_category == 1) {
                    tempImg.push(Hair);
                    if (islength) {
                        tempImg.push(HairTwo);
                    }
                } else if (practitionerData.selected_category == 2) {
                    tempImg.push(Beauty);
                    if (islength) {
                        tempImg.push(BeautyTwo);
                    }
                } else {
                    tempImg.push(wellness);
                    if (islength) {
                        tempImg.push(wellnessTwo);
                    }
                }
            } else {
                practitionerData && practitionerData.practitioner_portfolio_new.map(i => {
                    tempImg.push(i.image_link);
                });
            }
            setProfileImages(tempImg);

        }

    }

    var isFavourite = false
    if (practitionerData !== null) {
        if (practitionerData.is_favourite) {
            isFavourite = true;
        }
    }

    const SamplePrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div className={className} style={{ ...style }} onClick={onClick}>
                {" "}
                <img src={LeftArrow} className="arrow_icons" alt="icon" />{" "}
            </div>
        );
    };
    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    arrows: true,
                    infinite: false,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const _onCategoryClick = (e) => {
        setCurrentpage(0)
        setOffset(0)
        updateFormData({
            ...formData,
            whhats_available_pagination: 0
        })
    }

    const _whatAvailableClick = (e) => {
        setCurrentpage(0)
        setOffset(0)
        updateFormData({
            ...formData,
            whhats_available_pagination: 1
        })

    }

    const onTreatmentTitleChange = (event, id, str, title) => {

        let appointmentId = ""

        if (str === "accordion") {
            appointmentId = id
        } else {
            appointmentId = event.target.value
        }

        let selected_date_slots = []

        practitionerData && practitionerData.request.map((item, index) => {

            let dateArray = []
            let timeWithDateArray = []
            let time = {};
            let date = ""

            if (item.uuid == appointmentId && item.type == 1) {

                setTreatmentDateTime(item.request_timeslots);

                updateFormData({
                    ...formData,
                    request_type: 1,
                    treatment_title: title
                })

            }
            //  else if (item.id == appointmentId && item.type == 2) {

            //     let date = item.request_timeslots[0].date_time
            //     let time = item.request_timeslots[0].start_time.substring(0, 5)

            //     let morning = []
            //     let afternoon = []
            //     let evening = []

            //     let newtime = time.substring(0, 2)
            //     if (newtime < 12) {
            //         morning.push(time)
            //     }
            //     else if (newtime > 12 && newtime < 16) {
            //         afternoon.push(time)
            //     } else if (newtime > 16) {
            //         evening.push(time)
            //     }
            //     let date1 = moment(date).format("DD-MM-yy")
            //     dateArray.push(date1)

            //     updateFormData({
            //         ...formData,
            //         selected_date_slots: dateArray,
            //         selected_slots_morning: morning,
            //         selected_slots_afternoon: afternoon,
            //         selected_slots_evening: evening,
            //         request_type: 2,
            //         treatment_title: title
            //     })
            // }

        })

    }


    const onChange = (e) => {
        let date1 = moment(e).format("DD-MM-yy")
        formData.selected_date_time_array.map((item, index) => {
            if (date1 === item.date) {
                updateFormData({
                    ...formData,
                    selected_slots_morning: item.selected_slots_morning,
                    selected_slots_afternoon: item.selected_slots_afternoon,
                    selected_slots_evening: item.selected_slots_evening

                })
            }
        })

    }

    const handlePageClick = (e) => {
        window.scrollTo(0, 0);
        const selectedPage = e.selected;
        const offset = selectedPage * perPage;
        setCurrentpage(selectedPage)
        setOffset(offset)
    }

    const handlePageClickReiew = (e) => {
        if (myRef !== null) {
            window.scrollTo({ behavior: 'smooth', top: myRef.current.offsetTop })
        }
        const selectedPage = e.selected;
        const offset = selectedPage * perPagereview;
        setCurrentpagereview(selectedPage)
        setOffsetreview(offset)
    }

    const tileClassName = (activeStartDate, date, view) => {

        let date1 = moment(activeStartDate.date).format("DD-MM-yy")

        if (formData.selected_date_slots.length !== 0 && formData.selected_date_slots.indexOf(date1) !== -1) {

            return "pratitioner_canlender_active"

        }

    }

    const _getDateTime = (timeSlot, type) => {
        if (type === 2) {
            let dates = moment(timeSlot[0].date_time).format("Do MMMM YYYY");
            let timeslot = moment(timeSlot[0].start_time, "HH:mm").format("hh:mm A")
            let timein24hrs = ""
            if (timeSlot[0].start_time !== null && timeSlot[0].start_time !== undefined) {
                timein24hrs = timeSlot[0].start_time.slice(0, 5)
            }

            return dates + ' @ ' + timein24hrs;
        }
        else { return "flexible"; }
    }

    const nextScreen = async (treatmentdata) => {

        try {
            if (loginTokenData) {

                if (loginTokenData != null || loginTokenData != undefined) {

                    if (loginTokenData.user.type === "practitioner") {
                        if (treatmentdata.type === 2) {
                            var data = {
                                book_close_request: true,
                                param_data: param_data,
                                photo_required: treatmentdata.photograph_required,
                            }
                            setLocalStorageData("book_close_request", JSON.stringify(data))
                        } else {
                            let temp = {}
                            temp = getLocalStorageData("book_open_request_time") ? JSON.parse(getLocalStorageData("book_open_request_time")) : {};
                            let treatment = treatmentdata;
                            treatment.treatment = { data: treatmentdata.treatment };
                            treatment.request_timeslots = { data: treatment.request_timeslots };
                            var data = {
                                book_open_request: true,
                                param_data: param_data,
                                selectedData: temp,
                                treatmentData: treatment
                            }
                            setLocalStorageData("book_open_request", JSON.stringify(data))

                        }
                        window.scrollTo(0, 0);
                        history.push("/practitioner-logout");
                    } else {
                        window.scrollTo(0, 0);
                        clearLocalStorage("timeselected")
                        if (loginTokenData.user.profile_pic === '' || loginTokenData.user.profile_pic === undefined || loginTokenData.user.profile_pic === null) {
                            var data = {
                                id: treatmentdata.uuid, photo_required: treatmentdata.photograph_required, path: treatmentdata.type === 2 ? 'close' : 'open'
                            }
                            if (treatmentdata.type === 1) {
                                let temp = {}
                                temp = getLocalStorageData("book_open_request_time") ? JSON.parse(getLocalStorageData("book_open_request_time")) : {};

                                var datan = {
                                    book_open_request: true,
                                    param_data: param_data,
                                    selectedData: temp,
                                    treatmentData: treatmentdata
                                }
                                setLocalStorageData("book_open_request", JSON.stringify(datan))
                            }
                            window.scrollTo(0, 0);
                            history.push('/update-profile-confirmation', { data: data });
                        } else
                            dispatch(resetpractitionerProfileAPI_Action());
                        if (treatmentdata.type === 2) {
                            if (treatmentdata.photograph_required === 1) {
                                window.scrollTo(0, 0);
                                history.push('/photo-requirement/' + treatmentdata.uuid)
                            } else {
                                window.scrollTo(0, 0);
                                setLocalStorageData("view_booking_from_profile", 1)
                                history.push('/view-booking/' + treatmentdata.uuid)
                            }

                            //history.push('/treatment-detail-for-close-request/'+treatmentdata.id);
                        } else {
                            let temp = {}
                            temp = getLocalStorageData("book_open_request_time") ? JSON.parse(getLocalStorageData("book_open_request_time")) : {};
                            let treatment = treatmentdata;
                            treatment.treatment = { data: treatmentdata.treatment };
                            treatment.request_timeslots = { data: treatment.request_timeslots };
                            window.scrollTo(0, 0);
                            setLocalStorageData("pathnamegini", "fromginipigProfile")

                            history.push({
                                pathname: '/treatment-availability-open-request',
                                state: { 'paramData': treatmentdata.uuid, 'selectedData': temp, 'treatmentData': treatmentdata },
                            });
                            //param_data

                            // window.scrollTo(0,0);
                            // history.push('/treatment-detail-for-open-request/'+treatmentdata.id);
                        }
                    }
                }
            } else {
                window.scrollTo(0, 0);
                history.push('/login')
            }


        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    }

    const favApiCall = async () => {

        try {
            let checkToken = "";

            if (loginToken != "") {
                checkToken = loginToken;
            } else if (RegisterToken != "") {
                checkToken = RegisterToken;
            }
            else if (loginTokenData !== undefined) {
                checkToken = loginTokenData.token;
            }

            if (checkToken !== '') {
                if (loginTokenData.user.type === "practitioner") {
                    window.scrollTo(0, 0);
                    history.push("/practitioner-logout", { data: { fav: true, treatment_id: param_data, text: 'In order to favourite a Treatment/Service provider profile or treatment, please login as a GiniPig. Would you like to logout of your Treatment/Service provider account?' } });
                } else {
                    await dispatch(practitionerFav(param_data, checkToken));
                    setIsfav(!isFav);
                    isFavourite = !isFavourite;
                }
            } else {
                window.scrollTo(0, 0);
                history.push("/practitioner-logout", { data: { fav: true, treatment_id: param_data, text: 'In order to favourite a Treatment/Service provider profile or treatment, please login as a GiniPig.' } });
            }
        } catch (error) {
            //console.log("Fav error msg:-", error);
        }
    }



    const favApitreatmentCall = async (e, id) => {
        setShowLoqadingFav(true)
        setFavTreatment(true)

        setFavid(id)

        try {
            let checkToken = "";

            if (loginToken != "") {
                checkToken = loginToken;
            } else if (RegisterToken != "") {
                checkToken = RegisterToken;
            }
            else if (loginTokenData !== undefined) {
                checkToken = loginTokenData.token;
            }

            var ginipig_id = 0;
            if (loginTokenData !== undefined) {
                if (loginTokenData.user.type === "ginipig") {
                    ginipig_id = loginTokenData.user.uuid;
                }
            }

            if (checkToken !== '') {
                if (loginTokenData.user.type === "practitioner") {
                    window.scrollTo(0, 0);
                    history.push("/practitioner-logout", { data: { fav: true, treatment_id: param_data, text: 'In order to favourite a Treatment/Service provider profile or treatment, please login as a GiniPig. Would you like to logout of your Treatment/Service provider account?' } });
                } else {
                    await dispatch(treatmentFavAPI_Action(id, checkToken));
                    await dispatch(ginipigPractitionerProfileAPI_Action(param_data, ginipig_id))
                    setisFavtreatment(!isFavtreatment);
                    setShowLoqadingFav(false)
                    // isFavourite = !isFavourite;
                }
            } else {
                window.scrollTo(0, 0);
                history.push("/practitioner-logout", { data: { fav: true, treatment_id: param_data, text: 'In order to favourite a Treatment/Service provider profile or treatment, please login as a GiniPig.' } });
            }
        } catch (error) {
            //console.log("Fav error msg:-", error);
        }
    }

    const redirectToDetails = (treatmentdata) => {
        dispatch(resetbookingDetailsSave());
        dispatch(resetPhotoDetails());
        setLocalStorageData("pathnamegini", "fromginipigProfile")
        if (treatmentdata.type === 2) {
            clearLocalStorage('book_close_request')
            window.scrollTo(0, 0);
            history.push('/treatment-detail-for-close-request/' + treatmentdata.uuid);
        } else {
            clearLocalStorage("book_open_request_time");
            window.scrollTo(0, 0);
            history.push('/treatment-detail-for-open-request/' + treatmentdata.uuid);
        }
    }

    const onBackPressed = () => {
        if (profile_data) {
            setLocalStorageData('tabkey', 'favourites');
            history.goBack();
        } else {
            var backpressed = ''; var data = ''; var date = '';

            if (getLocalStorageData("filter_treatment_data") !== undefined) {
                backpressed = JSON.parse(getLocalStorageData("filter_treatment_data"))
            }
            if (backpressed.hasOwnProperty('searchDetails')) {
                if (backpressed.searchDetails !== null) {
                    if (backpressed.searchDetails.startDate !== 'Any date') {
                        date = [new Date(backpressed.searchDetails.startDate).getFullYear(), ("0" + (new Date(backpressed.searchDetails.startDate).getMonth() + 1)).slice(-2), ("0" + new Date(backpressed.searchDetails.startDate).getDate()).slice(-2)].join("-");
                    } else {
                        date = 'Any date'
                    }
                    data = {
                        keywords: backpressed.searchDetails.search_keywords,
                        location: backpressed.searchDetails.search_location,
                        latitude: backpressed.searchDetails.search_latitude,
                        longitude: backpressed.searchDetails.search_longitude,
                        postcode: backpressed.searchDetails.search_postcode,
                        from_header: 'not_from_header',
                        date: date
                    }
                }
            }
            window.scrollTo(0, 0);
            history.push('/search-result', { data: JSON.stringify(data), date: date })
        }
    }


    const __redirectportfolio = (id) => {
        window.scrollTo(0, 0);
        history.push('/ginipig-portfolio/' + id)
    }
    // console.log( loginTokenData && loginTokenData.user!==undefined &&  loginTokenData.user&&  loginTokenData.user.type)

    return (
        <>
            <section className="bgcolor-gray common-section practitioner_profile_forginipig pratitioner_profilecheck pb80">
                <Container>
                    {
                        practitionerData !== null && practitioneProfileData.practitionerprofiledata !== null ?
                            <Row>
                                <Col className="col-lg-12 col-xl-11 m-auto">
                                    <div className="navigationTop">
                                        <div className="heading-history">
                                            <div className="back-history">
                                                <button type="button" onClick={() => {
                                                    onBackPressed()

                                                }} className="back-btn" >
                                                    {" "}
                                                    <img src={LeftArrow} alt="arrow" />{" "}
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="practitioner_profile-wrp from-wrp profile_border">

                                        <div className="practitioner-profile-img ">
                                            <div className="profile-edtbtn" style={{ zIndex: 9 }}>
                                                {/* <button className="edit_btn" onClick={() => favApiCall()} ><img src={isFav || isFavourite ? favourite : unfavourite} className="fav_icon" /></button> */}
                                                <button className="edit_btn" onClick={() => favApiCall()} ><img src={isFav ? favourite : unfavourite} className="fav_icon" /></button>
                                            </div>

                                            <div className="img_wrp_slider">
                                                <SlickSlider {...settings} >
                                                    {
                                                        profileImages.map((i) => {
                                                            return (
                                                                <div className="img_size profile_img1 ">

                                                                    <img src={i} />

                                                                </div>
                                                            )
                                                        })

                                                    }

                                                </SlickSlider>
                                                {
                                                    practitionerData && practitionerData.practitioner_portfolio_new.length > 0 ?
                                                        <div className="profile_portfolio_btn portfolio-btns-box" onClick={(e) => __redirectportfolio(practitionerData && practitionerData.uuid)}>
                                                            <button className="portfolio_btn" > <span className="text-block"> Portfolio</span> <span className="btn-icon"><img src={LeftArrow} /></span> </button>
                                                        </div> :
                                                        <div className="profile_portfolio_btn portfolio-btns-box">
                                                            <button className="portfolio_btn" > <span className="text-block"> Portfolio</span> <span className="btn-icon"><img src={LeftArrow} /></span> </button>
                                                        </div>

                                                }

                                            </div>
                                        </div>

                                        <div className="practitioner_user_profile mt-8">
                                            <div className="user-row">
                                                <div className="user-avatar">
                                                    <div className="user-icon">
                                                        <img className="avtar" src={practitionerData && practitionerData.profile_pic !== "" ? practitionerData.profile_pic : practitioner_avtar} />
                                                    </div>
                                                    <div className="user_content_box">
                                                        <div className="user-title">
                                                            <span className="user-name">{practitionerData !== null ? practitionerData.name : null}</span>

                                                            <span className="user-review">
                                                                <img className="rating" src={star} />
                                                                <span className="ratingpoint">({practitionerData !== null ? practitionerData.review_rating_practitioner.toFixed(1) : 0.0})</span>
                                                            </span>
                                                        </div>
                                                        <div className="user-role">
                                                            <span className="role-name">{practitionerData !== null ? practitionerData.practicing_saloon === 1 ? practitionerData.company_name + ', ' : practitionerData.practitioner_category_apply !== null ? practitionerData.practitioner_category_apply + ',' : null : null} </span>
                                                            <span className="user-address"> {practitionerData !== null ? practitionerData.user_additional_info.length > 0 ? practitionerData.user_additional_info[0].postcode_data !== null ? practitionerData.user_additional_info[0].postcode_data.postcode ? practitionerData.user_additional_info[0].postcode_data.postcode.substr(0, practitionerData.user_additional_info[0].postcode_data.postcode.length - 3) + " " : null : null : null : null}</span>
                                                            {/* <span className="role-name">{practitionerData !== null ? practitionerData.practitioner_type === "Individual" ? practitionerData.practicing_saloon === 1 ? practitionerData.establishment_name : "Individual" : practitionerData.practicing_saloon === 1 ? practitionerData.company_name : "Bussiness" : null},</span> */}
                                                            <span className="user-address"> {practitionerData !== null ? practitionerData.user_additional_info.length > 0 ? practitionerData.user_additional_info[0].postcode_data !== null ? practitionerData.user_additional_info[0].postcode_data.city : null : null : null} </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="user_info_content">
                                                    <p className="info">{practitionerData !== null ? practitionerData.user_additional_info.length > 0 ? practitionerData.user_additional_info[0].bio : null : null}</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>


                                    <div className="practition-skilltreatment pt-70 mb-32 pract-ginipig">
                                        <h2 className="skill_heading mb-16">My Talent Menu</h2>
                                        <div>
                                            <p className="cattext-label mb-16">Can’t see all categories? Scroll across to see if there’s other options available</p>
                                        </div>

                                        <div className="profile-tabs">
                                            <Tab.Container id="left-tabs-example" defaultActiveKey={tabpankey}>
                                                <div className="practition_profilebox">
                                                    <div className="profile-category">
                                                        <Nav variant="pills" className="flex-column">
                                                            <Nav.Item className=" tab_button">
                                                                <Nav.Link eventKey={1} onClick={_whatAvailableClick}>
                                                                    <span className="list-icon iconbox-1"></span>
                                                                    <span className="categorname">Whats available</span>

                                                                </Nav.Link>
                                                            </Nav.Item>

                                                            {
                                                                practitionerData && practitionerData.category_treatments.map((category, index) => {
                                                                    if (category.treatments.length > 0) {
                                                                        const caticonid = category.id + 1;
                                                                        return (
                                                                            <Nav.Item className=" tab_button" onClick={_onCategoryClick}>
                                                                                <Nav.Link eventKey={caticonid}>
                                                                                    <span className={"list-icon iconbox-" + caticonid}> </span>
                                                                                    <span className="categorname">{category.name}</span>
                                                                                </Nav.Link>
                                                                            </Nav.Item>
                                                                        )
                                                                    }
                                                                }
                                                                    //}
                                                                )
                                                            }
                                                        </Nav>
                                                    </div>
                                                    <div className="profile-accordian">
                                                        <Tab.Content>
                                                            {
                                                                practitionerData !== null && practitionerData.request.length !== 0 && practitionerData.request.slice(offset, offset + perPage).map((item, index) => {

                                                                    return (
                                                                        <>
                                                                            <Tab.Pane className="in" eventKey={1} onClick={(e) => onTreatmentTitleChange(e, item.uuid, "accordion", item.treatment.title)} defaultActiveKey={tabpankey}>

                                                                                {

                                                                                    <AccordionContentForAppointment
                                                                                        isActive={isActive}
                                                                                        indexValue={item.uuid}
                                                                                        title={item.treatment.title}
                                                                                        content={item.treatment.description}
                                                                                        selectText={_getDateTime(item.request_timeslots, item.type)}
                                                                                        originalPriceText={item && item.rrp_price.toFixed(2)}
                                                                                        discountPriceText={item && item.your_price.toFixed(2)}
                                                                                        currencySign="£"
                                                                                        redirectUrl={() => { redirectToDetails(item) }}
                                                                                        ginipig_count={item.ginipig_count - item.bookings_places_count}
                                                                                        expertise_level={item.expertise_level}
                                                                                        reason_require_ginipig={item.reason_require_ginipig}
                                                                                        hours={item.hours}
                                                                                        minutes={item.minutes}
                                                                                        btnText={item.type === 1 ? item.bookings_count && item.bookings_count === 1 ? "Already Booked" : "Enquire Now" : item.bookings_count && item.bookings_count === 1 ? "Already Booked" : "Book Now"}
                                                                                        requesttype={item.type}
                                                                                        postcode={item.postcodes !== null ? formatPostcode(item.postcodes.postcode) : null}
                                                                                        city={item.postcodes !== null ? item.postcodes.city : null}
                                                                                        btnDisable={item.bookings_count && item.bookings_count === 1 ? true : false}
                                                                                        onNextPress={() => {
                                                                                            nextScreen(item)
                                                                                        }}
                                                                                        onClick={(indexid) => { setIsActive(indexid === isActive ? false : indexid) }}
                                                                                    />


                                                                                }
                                                                            </Tab.Pane>
                                                                        </>
                                                                    )

                                                                })
                                                            }
                                                            {
                                                                formData.whhats_available_pagination === 1 && practitionerData && practitionerData.request.length > 0 &&
                                                                <div className="pagination-div">

                                                                    <ReactPaginate
                                                                        previousLabel={"prev"}
                                                                        nextLabel={"next"}
                                                                        breakLabel={"..."}
                                                                        breakClassName={"break-me"}
                                                                        pageCount={Math.ceil(practitionerData && practitionerData.request && practitionerData.request !== null && practitionerData.request.length / perPage)}
                                                                        marginPagesDisplayed={2}

                                                                        pageRangeDisplayed={5}
                                                                        onPageChange={handlePageClick}
                                                                        containerClassName={"pagination"}
                                                                        subContainerClassName={"pages pagination"}
                                                                        activeClassName={"active"} />


                                                                </div>
                                                            }
                                                            {formData.whhats_available_pagination === 1 && practitionerData && practitionerData.request && practitionerData.request.length === 0 &&

                                                                <div className="notice-review">
                                                                    <p >There aren't any available appointmnets right now,favourite a treatment from categories below to be notified when it becomes available.</p>
                                                                </div>
                                                            }
                                                            {

                                                                practitionerData && practitionerData.category_treatments.map((category, index) => {
                                                                    if (category.treatments.length > 0) {
                                                                        return (
                                                                            <>
                                                                                <Tab.Pane className="in" eventKey={category.id + 1} >
                                                                                    {
                                                                                        category.treatments && category.treatments !== null && category.treatments.slice(offset, offset + perPage).map((treatmentdata, treat_index) => {
                                                                                            if (treatmentdata.request.length === 0) {
                                                                                                return (
                                                                                                    <>

                                                                                                        <AccordionContentFav
                                                                                                            title={treatmentdata.title}
                                                                                                            content={treatmentdata.description}
                                                                                                            key={treat_index}
                                                                                                            id={treatmentdata.uuid}
                                                                                                            uuid={treatmentdata.uuid}
                                                                                                            showLoading={showLoadingFav ? true : false}
                                                                                                            favId={favId}
                                                                                                            isFavtreatment={treatmentdata.is_favourite}
                                                                                                            favApitreatmentCall={favApitreatmentCall}
                                                                                                            isActive={isActive}
                                                                                                            indexValue={treatmentdata.id}
                                                                                                            onClick={(indexid) => { setIsActive(indexid === isActive ? false : indexid) }}

                                                                                                        />
                                                                                                    </>
                                                                                                )
                                                                                            } else {
                                                                                                return (


                                                                                                    treatmentdata.request && treatmentdata.request.map((item, index) => {
                                                                                                        return (
                                                                                                            <>

                                                                                                                <AccordionContentForAppointment
                                                                                                                    title={item.treatment.title}
                                                                                                                    accordianClick={(e) => onTreatmentTitleChange(e, item.uuid, "accordion", item.treatment.title)}
                                                                                                                    content={item.treatment.description}
                                                                                                                    selectText={_getDateTime(item.request_timeslots, item.type)}
                                                                                                                    originalPriceText={item.rrp_price.toFixed(2)}
                                                                                                                    discountPriceText={item.your_price.toFixed(2)}
                                                                                                                    currencySign="£"
                                                                                                                    redirectUrl={() => { redirectToDetails(item) }}
                                                                                                                    ginipig_count={item.ginipig_count - item.bookings_places_count}
                                                                                                                    reason_require_ginipig={item.reason_require_ginipig}
                                                                                                                    hours={item.hours}
                                                                                                                    expertise_level={item.expertise_level}
                                                                                                                    minutes={item.minutes}
                                                                                                                    postcode={item.postcodes !== null ? formatPostcode(item.postcodes.postcode) : null}
                                                                                                                    city={item.postcodes !== null ? item.postcodes.city : null}
                                                                                                                    // btnTextEnquire={"Enquire Now"}
                                                                                                                    btnText={item.type == 1 ? item.bookings_count && item.bookings_count === 1 ? "Already Booked" : "Enquire Now" : item.bookings_count && item.bookings_count === 1 ? "Already Booked" : "Book Now"}
                                                                                                                    btnDisable={item.bookings_count && item.bookings_count === 1 ? true : false}
                                                                                                                    onNextPress={() => {
                                                                                                                        nextScreen(item)
                                                                                                                    }}
                                                                                                                    onClick={(indexid) => { setIsActive(indexid === isActive ? false : indexid) }}
                                                                                                                    isActive={isActive}
                                                                                                                    indexValue={item.id}
                                                                                                                />
                                                                                                            </>
                                                                                                        )
                                                                                                    })
                                                                                                )
                                                                                            }
                                                                                        })
                                                                                    }

                                                                                    <div className="pagination-div">
                                                                                        <ReactPaginate
                                                                                            previousLabel={"prev"}
                                                                                            nextLabel={"next"}
                                                                                            breakLabel={"..."}
                                                                                            breakClassName={"break-me"}
                                                                                            pageCount={Math.ceil(category.treatments && category.treatments !== null && category.treatments.length / perPage)}
                                                                                            marginPagesDisplayed={2}
                                                                                            pageRangeDisplayed={5}
                                                                                            onPageChange={handlePageClick}
                                                                                            containerClassName={"pagination"}
                                                                                            subContainerClassName={"pages pagination"}
                                                                                            activeClassName={"active"} />
                                                                                    </div>


                                                                                </Tab.Pane>
                                                                            </>
                                                                        )
                                                                    }
                                                                })
                                                            }


                                                            {/* {

                                                        practitionerData && practitionerData.category_treatments.map((category, index) => {
                                                            //if (category.treatments.length > 0) {

                                                            let count = 0;
                                                            let totalCount = 0;

                                                            let tetmp = practitionerData.request.filter(obj => obj.treatment.category_id === category.id);
                                                            totalCount = tetmp.length > 10 ? 10 : tetmp.length;
                                                            console.log("filtered data category wise:-", tetmp);
                                                            return (
                                                                <>
                                                                    {

                                                                        practitionerData !== null && practitionerData.request.length !== 0 && practitionerData.request.filter(obj => obj.treatment.category_id === category.id).slice(offset, offset + perPage).map((item, index) => {

                                                                            if (item.treatment.category_id === category.id) {
                                                                                count++;
                                                                                console.log("count value:-", count);
                                                                                return (
                                                                                    <>
                                                                                        <Tab.Pane className="in" eventKey={category.id + 1} onClick={(e) => onTreatmentTitleChange(e, item.id, "accordion", item.treatment.title)}>
                                                                                            {
                                                                                                <AccordionContentForAppointment
                                                                                                    title={item.treatment.title}
                                                                                                    content={item.treatment.description}
                                                                                                    selectText={_getDateTime(item.request_timeslots, item.type)}
                                                                                                    originalPriceText={item.rrp_price}
                                                                                                    discountPriceText={item.your_price}
                                                                                                    currencySign="£"
                                                                                                    redirectUrl={() => { redirectToDetails(item) }}
                                                                                                    ginipig_count={item.ginipig_count - item.bookings_places_count}
                                                                                                    reason_require_ginipig={item.reason_require_ginipig}
                                                                                                    hours={item.hours}
                                                                                                    expertise_level={item.expertise_level}
                                                                                                    minutes={item.minutes}
                                                                                                    postcode={item.postcodes !== null ? formatPostcode(item.postcodes.postcode) : null}
                                                                                                    city={item.postcodes !== null ? item.postcodes.city : null}
                                                                                                    btnText={item.bookings_count && item.bookings_count === 1 ? "Already Booked" : "Book Now"}
                                                                                                    btnDisable={item.bookings_count && item.bookings_count === 1 ? true : false}
                                                                                                    onNextPress={() => { console.log("treatmentdata " + item.id); nextScreen(item) }}
                                                                                                />

                                                                                            }
                                                                                            {console.log("count:-", count, "totalCount:-", totalCount)}{count == totalCount ?
                                                                                                <div className="pagination-div">
                                                                                                    <ReactPaginate
                                                                                                        previousLabel={"prev"}
                                                                                                        nextLabel={"next"}
                                                                                                        breakLabel={"..."}
                                                                                                        breakClassName={"break-me"}
                                                                                                        pageCount={Math.ceil(tetmp.length / perPage)}
                                                                                                        marginPagesDisplayed={2}
                                                                                                        pageRangeDisplayed={5}
                                                                                                        onPageChange={handlePageClick}
                                                                                                        containerClassName={"pagination"}
                                                                                                        subContainerClassName={"pages pagination"}
                                                                                                        activeClassName={"active"} />
                                                                                                </div> : null}
                                                                                        </Tab.Pane>

                                                                                    </>
                                                                                )
                                                                            }
                                                                        })
                                                                    }
                                                                </>
                                                            )
                                                        }
                                                            //}
                                                        )
                                                    } */}
                                                        </Tab.Content>


                                                    </div>
                                                </div>
                                            </Tab.Container>
                                        </div>
                                    </div>
                                    {/* end tab treatmentlist */}

                                    <div className="calender_boxfor_ginipig mt-40">
                                        {/* <div className="calender_selection_box calander_box1 mb-24">
                                            <div className="title-box">
                                                <h2 className="skill_heading mb-8">Flexible treatment availability</h2>
                                            </div>
                                            <div className="section-box">
                                                <div className="select_feild">
                                                    <select onChange={onTreatmentTitleChange}
                                                        value={formData.treatment_title}>
                                                        <option>{formData.treatment_title}</option>
                                                        {
                                                            practitionerData && practitionerData.request.map((item, index) => {
                                                                return (
                                                                    item.type === 1 ?
                                                                        <option value={item.uuid}>{item.treatment.title}</option> :
                                                                        null
                                                                )

                                                            })
                                                        }

                                                    </select>
                                                </div>
                                            </div>
                                        </div> */}
                                        {/* <div className="open_reqiuest_box mt-70 mb-40">
                                            <AppointmentCalender
                                                calendarData={treatmentDateTime}
                                                selectedDate={[]}
                                                redirectfromopen={true}
                                            />
                                        </div> */}

                                    </div>

                                    <div className="reviewforginipigs" ref={myRef}>
                                        <div className="profile_review mt-24">
                                            {
                                                loginTokenData && loginTokenData.user !== undefined && loginTokenData.user && loginTokenData.user.type === "practitioner" ?
                                                    <h3 className="heading_review">Everybody loves a great review so get perfecting!</h3> :
                                                    <h3 className="heading_review">Reviews</h3>

                                            }
                                            {/* <h3 className="heading_review">Reviews</h3> */}

                                            <div className="review_rating_box  mt-40">
                                                <div className="first-div">

                                                    <ProfileReviewCard
                                                        ImgIcon={Icon_professionalism}
                                                        ratingName="Professionalism"
                                                        initialRating={Math.ceil(practitionerData && practitionerData.professionalismAverage)}
                                                    />
                                                    <ProfileReviewCard
                                                        ImgIcon={Icon_etiquette}
                                                        ratingName="Friendliness  "
                                                        initialRating={Math.ceil(practitionerData && practitionerData.etiquetteAverage)}
                                                    />
                                                    <ProfileReviewCard
                                                        ImgIcon={Icon_cleanliness}
                                                        ratingName="Reliability"
                                                        initialRating={Math.ceil(practitionerData && practitionerData.cleanlinessAverage)}
                                                    />
                                                    <ProfileReviewCard
                                                        ImgIcon={Icon_results}
                                                        ratingName="Results"
                                                        initialRating={Math.ceil(practitionerData && practitionerData.resultAverage)}
                                                    />
                                                </div>
                                                <div className="second-div">
                                                    <div className="rating_points">
                                                        <img src={star} className="stars" />
                                                        <p className="points">{practitionerData && practitionerData.review_rating_practitioner === 0 ? "0.0" : practitionerData && practitionerData.review_rating_practitioner.toFixed(1)}</p>

                                                    </div>
                                                </div>
                                            </div>


                                            {/* <div className="form-wrp notice-review mt-16">
                                        <p>You do not have any reviews but hopefully you will soon! GiniPigs will leave reviews once they have been treated by you in an appointment</p>
                                    </div> */}
                                        </div>

                                        {
                                            practitionerData && practitionerData.practitioner_reviews && practitionerData.practitioner_reviews.length !== 0 ?
                                                <div>

                                                    <div className="review_boxs_wrp mt-16" >

                                                        {
                                                            practitionerData && practitionerData.practitioner_reviews && practitionerData.practitioner_reviews.slice(offsetreview, offsetreview + perPagereview).map((reviewdata, index) => {
                                                                let reviewDate = new Date(reviewdata.created_at)
                                                                let date1 = moment(reviewDate).format("DD/MM/yy")

                                                                let subCatArray = []
                                                                reviewdata.booking.request.treatment.treatment_sub_category_new.forEach(element => {
                                                                    subCatArray.push(element.name)

                                                                })

                                                                var str;
                                                                if (subCatArray.length === 1) {
                                                                    str = subCatArray.join()
                                                                } else if (subCatArray.length === 2) {

                                                                    str = subCatArray.join(" & ")
                                                                } else if (subCatArray.length === 3) {
                                                                    str = subCatArray[0] + "," + subCatArray[1] + " & " + subCatArray[2]
                                                                }


                                                                return (
                                                                    <div className="review_item mb-16">
                                                                        <ReviewBox
                                                                            showgpname={false}
                                                                            title={reviewdata.booking.request.treatment.title}
                                                                            reviewDate={date1}
                                                                            decs={reviewdata.comments}
                                                                            reviewAuthor={reviewdata.ginipig.name}
                                                                            reviewName1="Reliability"
                                                                            reviewCount1={reviewdata.cleanliness}
                                                                            reviewName2="Professionalism"
                                                                            reviewCount2={reviewdata.professionalism}
                                                                            reviewName3="Friendliness "
                                                                            reviewCount3={reviewdata.etiquette}
                                                                            reviewName4="Results"
                                                                            reviewCount4={reviewdata.result}
                                                                            overAllCount={reviewdata.overall}
                                                                        />
                                                                    </div>
                                                                )
                                                            })
                                                        }


                                                    </div>

                                                    <div className="pagination-div">
                                                        <ReactPaginate
                                                            previousLabel={"prev"}
                                                            nextLabel={"next"}
                                                            breakLabel={"..."}
                                                            breakClassName={"break-me"}
                                                            pageCount={Math.ceil(practitionerData && practitionerData.practitioner_reviews && practitionerData.practitioner_reviews.length / perPagereview)}
                                                            marginPagesDisplayed={2}
                                                            pageRangeDisplayed={5}
                                                            onPageChange={handlePageClickReiew}
                                                            containerClassName={"pagination"}
                                                            subContainerClassName={"pages pagination"}
                                                            activeClassName={"active"} />
                                                    </div>
                                                </div> :
                                                showreviewpract === true ?
                                                    <div className="form-wrp notice-review mt-16">
                                                        <p>You do not have any reviews but hopefully you will soon! GiniPigs will leave reviews once they have been treated by you in an appointment</p>
                                                    </div> : null

                                        }






                                    </div>

                                </Col>
                            </Row> : null




                    }
                    {practitioneProfileData.isFetching && !showLoadingFav &&

                        <div style={{ width: "100%", fontSize: 20, marginTop: 100, textAlign: 'center' }}>
                            <p>Loading ...</p>
                        </div>
                    }

                </Container>
            </section>
        </>
    );
}

export default PractitionerProfileForGiniPig;