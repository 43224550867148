import React, { useCallback, useState, useEffect } from "react";

import { Container, Row, Col, Modal } from "react-bootstrap";

import { LeftArrow, reviewAvatar, iconClose, profileIcon, practitioner_avtar } from "../../config/images";
import { Link } from "react-router-dom";
import ImageUpload from '../../components/ImageUpload';
import { useDispatch, useSelector } from 'react-redux';
import LargeButton from '../../components/LargeButton';
import { useHistory } from "react-router-dom";
import { getLocalStorageData, setLocalStorageData, clearLocalStorage } from '../../utils/storage'
import { imageUploadAPI_Action, resetimageUploadAPI_Action, companyCategorylistnewAPI_Action, practitionerAboutusAPI_Action, resetupcomingbookingAPI_Action, resetpractitionerProfileAPI_Action, companyCategorylistAPI_Action, checkemailExistsAPI_Action, resetcheckemailExistsAPI_Action, practitionerSignupSaveData_Action, resetPractitionerAboutusAPI_Action, resetLoginAPI_Action } from '../../stores/actions'
import usePlacesAutocomplete, { getGeocode, getLatLng, getZipCode } from 'use-places-autocomplete';

const initialErrorData = Object.freeze({
    email_error: "",
    firstname_error: "",
    bio_error: "",
    bio_error_active: "Should be minimum 25 characters long.",

    name_error: "",
    lastname_error: "",
    password_error: "",
    phonenumber_error: "",
    profile_pic_error: "",
    company_category_error: "",
    postcode_error: "",
    is_form_valid: true,
});

const PractitionerAboutUs = (props) => {
    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions
    } = usePlacesAutocomplete({
        requestOptions: {
            /* Define search scope here */
            componentRestrictions: {
                country: 'uk',
                city: false
            }
        },
        debounce: 300,
        // componentRestrictions: {country: "us"}
        callbackName: 'initMap'
    });
    const comapnycategorynewData = useSelector(state => state.comapnycategorylistnew);

    // 4.Practitioner Update Data
    // -API: practitioner/update/data
    // -Method: POST
    // -Parameters: Auth Token is required
    //  first_name:Atul
    //  last_name:Kadam
    //  email:atul.kadam200@sharklasers.com
    //  postcode:SW3 6JJ
    //  latitude:51.4909031
    //  longitude:-0.1732813
    //  city:London
    //  country:United Kingdom
    //  postcodeValue:Fulham Road, London SW3 6JJ, UK
    //  telephone_no:09796659812
    //  bio:
    //  profile_pic:

    //hooks
    // Initialize hooks first
    const [errorData, updateErrorData] = useState(initialErrorData);
    // const [errorMSG, setErrorMSG] = useState('');
    const [character_count, setCharactercount] = useState(500);
    const [isaddress, setShowIsAddress] = useState(false);
    const [istotaldesc, setShowIsTotalDescription] = useState('');
    const [removeonclick, setRemoveClick] = useState(false);
    const [currentimageId, setcurrentimageId] = useState(1);
    const [tabpankey, setTabpankey] = useState(0);

    const [errorDataModel, setErrorDataModel] = useState('')
    const [loader, setLoader] = useState(false)
    const [errorModel, setErrorModel] = useState(false)

    const dispatch = useDispatch();
    let history = useHistory();

    const [formData, updateFormData] = useState(Object.freeze({
        first_name: "",
        last_name: "",
        name: "",
        email: "",
        postcode: "",
        latitude: "",
        longitude: "",
        city: "",
        country: "",
        postcodeValue: "",
        telephone_no: "",
        bio: "",
        profile_pic: "",
        practitioner_category_apply: "",

        //newly added
        postcode: "",
        postcodeValue: "",
        country: "",
        city: "",
        latitude: "",
        longitude: "",
        street_name: '',
        town_city: '',
        flat_build_no: '',


    }));
    const practitioneProfileData = useSelector(state => state.practitionerprofile);

    const practitionersignupaddinfoData = useSelector(state => state.practitionersignupaddinfo);
    const checkemailaexistsData = useSelector(state => state.checkemailaexists);
    const practitioneraboutusdata = useSelector(state => state.practitioneraboutusdata);
    const imageUploaddata = useSelector(state => state.imageUploaddata);
    const practitionerData = practitioneProfileData.practitionerprofiledata && practitioneProfileData.practitionerprofiledata.data ? practitioneProfileData.practitionerprofiledata.data.profiledata : null;



    // Use effects
    useEffect(() => {
        window.scrollTo(0, 0);

        try {
            let checkToken = "";

            const RegisterToken = practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.token : "" : "";
            let LocalData = getLocalStorageData("loginDataUser")

            dispatch(resetupcomingbookingAPI_Action());
            dispatch(companyCategorylistnewAPI_Action());
            if (LocalData !== null) {
                updateFormData({
                    ...formData,
                    first_name: LocalData.first_name,
                    name: LocalData.name,
                    last_name: LocalData.last_name,
                    email: LocalData.email,
                    company_name: LocalData.company_name,
                    first_name: LocalData.first_name,
                    bio: practitionerData && practitionerData.practitioner_additional_info[0] && practitionerData.practitioner_additional_info[0].bio ? practitionerData && practitionerData.practitioner_additional_info[0] && practitionerData.practitioner_additional_info[0].bio : "",
                    postcode: LocalData.user_additional_info && LocalData.user_additional_info.data[0].postcode_data.data.postcode,
                    latitude: LocalData.user_additional_info && LocalData.user_additional_info.data[0].postcode_data.data.latitude,
                    longitude: LocalData.user_additional_info && LocalData.user_additional_info.data[0].postcode_data.data.longitude,
                    city: LocalData.user_additional_info && LocalData.user_additional_info.data[0].postcode_data.data.city,
                    country: LocalData.user_additional_info && LocalData.user_additional_info.data[0].postcode_data.data.country,
                    postcodeValue: LocalData.user_additional_info && LocalData.user_additional_info.data[0].postcode_data.data.postcode,
                    //location: LocalData.user_additional_info && (LocalData.user_additional_info.data[0].postcode_data.data.flat + ' ' + LocalData.user_additional_info.data[0].postcode_data.data.postcodeValue + ' ' + LocalData.user_additional_info.data[0].postcode_data.data.city + ' ' + LocalData.user_additional_info.data[0].postcode_data.data.postcode),
                    location: LocalData.user_additional_info && (LocalData.user_additional_info.data[0].postcode_data.data.flat + ', ' + LocalData.user_additional_info.data[0].postcode_data.data.location + ', ' + LocalData.user_additional_info.data[0].postcode_data.data.postcode.substr(0, practitionerData.user_additional_info[0].postcode_data.postcode.length - 3) + " " + LocalData.user_additional_info.data[0].postcode_data.data.postcode.substr(practitionerData.user_additional_info[0].postcode_data.postcode.length - 3)),
                    practitioner_category_apply: practitionerData && practitionerData.practitioner_category_apply ? practitionerData.practitioner_category_apply : "",
                    telephone_no: LocalData.telephone_no,
                    profile_pic: LocalData.profile_pic,
                    profile_pic1: LocalData.profile_pic,
                    token: LocalData.token,
                    flat_build_no: LocalData.user_additional_info && LocalData.user_additional_info.data[0].postcode_data.data.flat,
                    street_name: LocalData.user_additional_info && LocalData.user_additional_info.data[0].postcode_data.data.location,
                    town_city: LocalData.user_additional_info && LocalData.user_additional_info.data[0].postcode_data.data.city,
                }, []);
            }

        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
        dispatch(resetimageUploadAPI_Action())

    }, [])


    if (practitioneProfileData.practitionerprofiledata && practitioneProfileData.practitionerprofiledata.status_code === 401) {
        dispatch(resetpractitionerProfileAPI_Action());
        clearLocalStorage("loginData")
        clearLocalStorage("practitionersignupData")
        clearLocalStorage("RedirectPathName")
        clearLocalStorage("requestDataLocal")
        window.scrollTo(0, 0);
        history.push('/login');
    }

    if (practitioneraboutusdata && practitioneraboutusdata.practitioneraboutusdata !== null && practitioneraboutusdata.practitioneraboutusdata.status_code === 401) {
        dispatch(resetPractitionerAboutusAPI_Action());
        dispatch(resetLoginAPI_Action());
        clearLocalStorage("loginData")
        clearLocalStorage("practitionersignupData")
        clearLocalStorage("RedirectPathName")
        clearLocalStorage("requestDataLocal")
        window.scrollTo(0, 0);
        history.push('/login');
    } 

    if (practitioneraboutusdata.isSuccess === true  && practitioneraboutusdata.practitioneraboutusdata !== null ) {
        dispatch(resetPractitionerAboutusAPI_Action());
        window.scrollTo(0, 0);

        history.push('/practitioner-profile')

    }


    const __redirectScrollTop = () => {
        window.scrollTo(0, 0);
    }

    const __onTextChange = (e) => {
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value
        });

        if (e.target.name === "bio" && e.target.value !== "") {
            setCharactercount(500 - e.target.value.length)
        } else {
            setCharactercount(500)
        }
        if (e.target.name === "bio" && e.target.value.length > 25) {
            //console.log("inside",e.target.value.length)
            updateErrorData({
                ...errorData,

                is_form_valid: true,
                bio_error: ""

            });
        }
    };


    const checkMobileValidation = (e) => {
        const { id, value } = e.target;
        var decimal = /^[0][1-9]\d{9}$|^[1-9]\d{9}$/;

        if (!value) {
            updateErrorData({
                ...errorData,
                is_form_valid: false,
                phonenumber_error: "Phone Number Is Required.",
            });
        } else if (!value.match(decimal)) {
            updateErrorData({
                ...errorData,
                is_form_valid: false,
                phonenumber_error:
                    "Phone number must start with 0 and must be 11 digits.",
            });
        } else if (value.length !== 11) {
            updateErrorData({
                ...errorData,
                is_form_valid: false,
                phonenumber_error:
                    "Phone number must start with 0 and must be 11 digits.",
            });
        } else {
            updateErrorData({
                ...errorData,
                is_form_valid: true,
                phonenumber_error: "",
            });
        }
    };
    const checkEmailValidation = (e) => {

        if (checkemailaexistsData.isError == true) {
            dispatch(resetcheckemailExistsAPI_Action());
        }
        const { id, value } = e.target;

        if (!value) {
            updateErrorData({
                ...errorData,
                is_form_valid: false,
                email_error: "Email is Required."
            });
        } else {
            updateErrorData({
                ...errorData,
                is_form_valid: true,
                email_error: ""
            });
        }

        if (typeof value !== "undefined") {
            let lastAtPos = value.lastIndexOf('@');
            let lastDotPos = value.lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && value.indexOf('@@') == -1 && lastDotPos > 2 && (value.length - lastDotPos) > 2)) {

                if (!value) {
                    updateErrorData({
                        ...errorData,
                        is_form_valid: false,
                        email_error: "Email is Required."
                    });
                }
                else {
                    updateErrorData({
                        ...errorData,
                        is_form_valid: false,
                        email_error: "Email is not valid."
                    });
                }

            } else {
                __checkIsEmailExist();
            }
        } else if (value.length > 50) {
            updateErrorData({
                ...errorData,
                is_form_valid: false,
                email_error: "Only 50 Characters are Allowed."
            });
        } else if (value.length < 6) {
            updateErrorData({
                ...errorData,
                is_form_valid: false,
                email_error: "Minimum 6 Characters are Allowed."
            });
        } else {

            updateErrorData({
                ...errorData,
                is_form_valid: true,
                email_error: ""
            });
        }

    };

    const checkFirstNameValidation = (e) => {
        const { id, value } = e.target;
        if (!value) {
            updateErrorData({
                ...errorData,
                is_form_valid: false,
                firstname_error: "First Name Is Required."
            });
        } else if (value.length > 50) {
            updateErrorData({
                ...errorData,
                is_form_valid: false,
                firstname_error: "Only 50 Characters are Allowed."
            });
            //} else if (!value.match(/^[a-zA-Z]+$/)) {
        } else if (!value.match(/^[a-zA-Z\-\s]+$/)) {
            updateErrorData({
                ...errorData,
                is_form_valid: false,
                firstname_error: "Oops, you've added an incorrect character! Letters only "
            });
        } else {
            updateErrorData({
                ...errorData,
                is_form_valid: true,
                firstname_error: ""
            });
        }

    }


    const checkNameValidation = (e) => {
        const { id, value } = e.target;
        if (!value) {
            updateErrorData({
                ...errorData,
                is_form_valid: false,
                name_error: "Profile Display Name Is Required."
            });
        } else if (value.length > 50) {
            updateErrorData({
                ...errorData,
                is_form_valid: false,
                name_error: "Only 50 Characters are Allowed."
            });
        } else if (!value.match(/^[a-zA-Z]+$/)) {
            updateErrorData({
                ...errorData,
                is_form_valid: false,
                name_error: "Oops, you've added an incorrect character! Letters only "
            });
        } else {
            updateErrorData({
                ...errorData,
                is_form_valid: true,
                name_error: ""
            });
        }


    }


    const checkLastNameValidation = (e) => {
        const { id, value } = e.target;
        if (!value) {
            updateErrorData({
                ...errorData,
                is_form_valid: false,
                lastname_error: "Last Name Is Required."
            });
        } else if (value.length > 50) {
            updateErrorData({
                ...errorData,
                is_form_valid: false,
                lastname_error: "Only 50 Characters are Allowed."
            });
        } else if (!value.match(/^[a-zA-Z\-\s]+$/)) {
            //} else if (!value.match(/^[-_ a-zA-Z]+$/)) {
            updateErrorData({
                ...errorData,
                is_form_valid: false,
                lastname_error: "Oops, you've added an incorrect character! Letters only "
            });
        } else {
            updateErrorData({
                ...errorData,
                is_form_valid: true,
                lastname_error: ""
            });
        }


    }

    const __checkIsEmailExist = useCallback(async () => {
        try {
            await dispatch(checkemailExistsAPI_Action(formData));
        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    });

    const deleteImg = () => {
        updateFormData({
            ...formData,
            profile_pic: "",
            profile_pic1: "",
        });

    }


    if (imageUploaddata !== null && imageUploaddata.isSuccess) {
        if (currentimageId == 1) {
            updateFormData({
                ...formData,
                profile_pic1: imageUploaddata.imageUploaddata.data.image_link,
                profile_pic: [imageUploaddata.imageUploaddata.data.image_link]
            });
            dispatch(resetimageUploadAPI_Action())

        }
    }


    const clickonFile = e => {
        e.target.value = ""
    }
    const __onImageChange = e => {
        dispatch(resetPractitionerAboutusAPI_Action())
        dispatch(resetimageUploadAPI_Action())
        setcurrentimageId(1)
        if (e.target.files[0] != undefined) {
            // console.log("in image is not undefined")
            const imageFile = e.target.files[0];
            dispatch(imageUploadAPI_Action(imageFile));

            if (!imageFile.name.match(/\.(jpg|jpeg|png|pdf)$/)) {
                updateErrorData({
                    ...errorData,
                    profile_pic_error: "Please select correct file type:jpg|jpeg|png|pdf",
                    is_form_valid: false,
                });
                // this.setState({ invalidImage: 'Please select valid image.' });
                // return false;
            } else {
                // console.log("file types match");

                if (e.target.files[0] != undefined) {
                    // console.log("file types match into if");
                    // updateFormData({
                    //     ...formData,
                    //     profile_pic1: e.target.files[0],
                    //     profile_pic: URL.createObjectURL(e.target.files[0]),
                    // });

                    updateErrorData({
                        ...errorData,
                        profile_pic_error: "",
                        is_form_valid: true,
                    });

                } else {
                    // console.log("file types match into else");
                    updateErrorData({
                        ...errorData,
                        profile_pic_error: "Please select correct file type:jpg|jpeg|png|pdf",
                        is_form_valid: false,
                    });
                }
            }
        }

    }


    const __submitPractitionerAboutusForm = (e) => {
        e.preventDefault();
        __apiCall();
    }

    const checkBioValidation = (e) => {
        const { id, value } = e.target;
        if (!value) {
            updateErrorData({
                ...errorData,
                is_form_valid: false,
                bio_error: "Should be minimum 25 characters long.",
            });

        } else if (value.length < 25) {
            updateErrorData({
                ...errorData,
                is_form_valid: false,
                bio_error: "Should be minimum 25 characters long.",
            });
        } else if (value.length > 500) {
            updateErrorData({
                ...errorData,
                is_form_valid: false,
                bio_error: "Only 500 Characters are Allowed.",
            });
        } else {
            updateErrorData({
                ...errorData,
                is_form_valid: true,
                bio_error: "",
            });
        }


    };

    const __apiCall = useCallback(async () => {
        try {


            let formDataNew = {

                first_name: formData.first_name,
                last_name: formData.last_name,
                email: formData.email,
                profile_pic: formData.profile_pic1,
                file: formData.file,
                bio: formData.bio,
                name: formData.name,

                company_name: formData.company_name,

                telephone_no: formData.telephone_no,
                postcode: formData.postcode,
                postcodeValue: formData.postcodeValue,
                country: formData.country,
                city: formData.city,
                latitude: formData.latitude,
                longitude: formData.longitude,
                street_name: formData.street_name,
                flat: formData.flat_build_no,
                practitioner_category_apply: formData.practitioner_category_apply

            }

            //  console.log(formDataNew)

            let checkToken = "";

            const RegisterToken = practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.token : "" : "";
            let LocalDatatoken = getLocalStorageData("loginData")

            if (RegisterToken != "") {
                checkToken = RegisterToken;
            }
            else if (LocalDatatoken !== "") {
                checkToken = LocalDatatoken.token;
            }
            await dispatch(practitionerAboutusAPI_Action(formDataNew, checkToken));
            window.scrollTo(0, 0);

        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    }, [formData, practitionersignupaddinfoData]);

    if (practitioneraboutusdata.practitioneraboutusdata && practitioneraboutusdata.practitioneraboutusdata.status_code === 201) {
        setLocalStorageData("loginDataUser", practitioneraboutusdata.practitioneraboutusdata.data.user)
        window.scrollTo(0, 0);
        // history.push('/practitioner-account');
    }

    const deleteImg1 = () => {
        updateFormData({
            ...formData,
            profile_pic: "",
            profile_pic1: "",
        });
    }

    const handleInput = e => {
        if (isaddress) {
            setShowIsAddress(false)
        }


        setValue(e.target.value);
        updateFormData({
            ...formData,
            postcode: "",
            location: e.target.value,
            flat_build_no: '',
            street_name: '',
            town_city: ''
        });
    };


    const checkPostcodeValidation = (e) => {

        if (formData.city === "") {
            updateErrorData({
                ...errorData,
                is_form_valid_postcode: true,
                postcode_error: "Please ensure you have entered a valid UK postcode"
            });
        } else if (!(formData.postcodeValue.length > 5 && (formData.postcodeValue).match(/^([a-z0-9]\s*){5,8}$/i))) {
            updateErrorData({
                ...errorData,
                is_form_valid_postcode: true,
                postcode_error: "Please ensure you have entered a valid UK postcode"
            })
        }
        else {
            updateErrorData({
                ...errorData,
                is_form_valid_postcode: false,
                postcode_error: ""
            });
        }
    }

    const handleSelect = ({ description }) => () => {
        if (description) {
            setShowIsAddress(true)
            setShowIsTotalDescription(description)
        }
        setValue(description, false);
        clearSuggestions();

        let latitude = "";
        let longitude = "";
        let city = "";
        let country = "";
        let street_address = '';
        getGeocode({ address: description })

            .then((results) => {
                let city_name = results[0].address_components.map((item, key) => {

                    if (item.types.indexOf("postal_town") != -1) {
                        city = item.long_name;
                    } else {
                        return null;
                    }

                });


                let country_name = results[0].address_components.map((item, key) => {

                    if (item.types.indexOf("country") != -1) {
                        country = item.long_name;
                    } else {
                        return null;
                    }

                });
                latitude = results[0].geometry.location.lat();
                longitude = results[0].geometry.location.lng();

                street_address = description.replace(", UK", "");

                street_address = street_address.replace(", " + city, "");

                street_address = street_address.replace(formData.location, "");


                if (city === "") {
                    updateErrorData({
                        ...errorData,
                        is_form_valid_postcode: true,
                        postcode_error: "Please select a valid postcode"
                    });
                } else {
                    updateErrorData({
                        ...errorData,
                        is_form_valid_postcode: false,
                        postcode_error: ""
                    });
                }

                return Promise.resolve(results);
            })
            .then((results) => getZipCode(results[0]))
            .then((zipCode) => {
                let zipcode = zipCode;

                if (zipcode.length < 6) {
                    var abc = street_address.substring(street_address.indexOf(zipcode));
                    zipcode = abc;

                }

                if (zipcode !== null) {
                    street_address = street_address.replace(zipcode, "");
                    if (zipcode.length < 6) {
                        updateErrorData({
                            ...errorData,
                            is_form_valid: false,
                            postcode_error: "Post Code should be minimum 5 characters."
                        });
                    } else {
                        updateErrorData({
                            ...errorData,
                            is_form_valid: true,
                            postcode_error: ""
                        });
                    }
                    updateFormData({
                        ...formData,
                        latitude: latitude,
                        longitude: longitude,
                        postcode: zipcode,
                        //postcodeValue: description,
                        location: zipcode,
                        country: country,
                        city: city,
                        street_name: street_address,
                        town_city: city
                    });
                } else {
                    updateErrorData({
                        ...errorData,
                        is_form_valid_postcode: true,
                        postcode_error: "Postcode cannot be found, please enter address manually below."
                    });
                    setShowIsAddress(true)
                    let postcodeValue1 = formData.location
                    var postcodenew = ""
                    if (postcodeValue1 !== null) {
                        var fourth = postcodeValue1.substr(postcodeValue1.length - 4);
                        var lastThree = postcodeValue1.substr(postcodeValue1.length - 3);
                        var first = postcodeValue1.substr(0, postcodeValue1.length - 3);
                        var postcodenew1 = first + " " + lastThree
                        // console.log("esle", fourth.substr(0, 1))
                        if (fourth.substr(0, 1) === " ") {

                            postcodenew = formData.location.toUpperCase()
                        } else {
                            postcodenew = postcodenew1.toUpperCase()
                        }

                    }
                    street_address = street_address.replace(postcodenew, "");
                    if (postcodenew.length < 6) {
                        updateErrorData({
                            ...errorData,
                            is_form_valid: false,
                            postcode_error: "Post Code should be minimum 5 characters."
                        });
                    } else {
                        updateErrorData({
                            ...errorData,
                            is_form_valid: true,
                            postcode_error: ""
                        });
                    }
                    updateFormData({
                        ...formData,
                        latitude: latitude,
                        longitude: longitude,
                        postcode: postcodenew,
                        //postcodeValue: description,
                        location: postcodenew,
                        country: country,
                        city: city,
                        street_name: street_address,
                        town_city: city
                    });
                }
                setValue(zipcode, false)
                // console.log("latLng Code: ", formData);
            })

            .catch((error) => {
                console.log("Error: ", error);
            });
    };
    const __selectCompanyCategory = (event, name) => {

        updateFormData({
            ...formData,
            practitioner_category_apply: name
        });
        updateErrorData({
            ...errorData,
            is_form_valid: true,
            practitioner_category_apply_error: ""
        });
    }

    const renderSuggestions = () =>
        data.map(suggestion => {

            const {
                id,
                structured_formatting: { main_text, secondary_text },

            } = suggestion;

            return (
                <li
                    key={id}
                    onClick={handleSelect(suggestion, secondary_text, main_text)}
                >
                    <strong>{main_text}</strong> <small>{secondary_text}</small>
                </li>
            );
        });



    // useEffect(()=>{
    //         errorData.email_error && errorData.email_error != "" ? (
    //             setErrorMSG(errorData.email_error) 
    //         ) : null

    //         checkemailaexistsData.isError==true && checkemailaexistsData.checkemailexistsdata.status_code!=200 && checkemailaexistsData.checkemailexistsdata.message !="" ? (
    //             setErrorMSG(checkemailaexistsData.checkemailexistsdata.message)
    //         ) : null

    // },[errorData,checkemailaexistsData])



    return (
        <>
            <section className="bgcolor-gray profile-box">
                <Container>
                    <Row>
                        <Col className="col-lg-11 m-auto">
                            <div className="heading-history">
                                <div className="back-history">
                                    <button
                                        type="button"
                                        className="back-btn"
                                        onClick={() => history.goBack()}
                                    >
                                        {" "}
                                        <img src={LeftArrow} alt="arrow" />{" "}
                                    </button>
                                </div>
                                <div className="history-title">
                                    <h2> About me </h2>
                                </div>
                            </div>

                            <form onSubmit={__submitPractitionerAboutusForm}>
                                <div className=" practitioner-aboutus mt-24">
                                    <div className="inside-profile">
                                        <div className="align-left">
                                            <div className="fromProfile form-wrp boxedit mb-24">
                                                <div className="profilefield">
                                                    <div className="showWithLabal">
                                                        <label className="profile-label">First name</label>
                                                        {
                                                            errorData.firstname_error && errorData.firstname_error != "" ? (
                                                                <p className="error" >

                                                                    <p style={{ color: "red" }}>{errorData.firstname_error} </p>
                                                                </p>

                                                            ) : null
                                                        }
                                                    </div>
                                                    <input
                                                        type="text"
                                                        id="first_name"
                                                        name="first_name"
                                                        value={formData.first_name}
                                                        onChange={__onTextChange}
                                                        onBlur={checkFirstNameValidation}
                                                        autoComplete="off"
                                                        maxLength="50"
                                                        required
                                                    />
                                                </div>
                                                <div className="profilefield">
                                                    <div className="showWithLabal">
                                                        <label className="profile-label">Last name</label>
                                                        {
                                                            errorData.lastname_error && errorData.lastname_error != "" ? (
                                                                <p className="error" >

                                                                    <p style={{ color: "red" }}>{errorData.lastname_error} </p>
                                                                </p>

                                                            ) : null
                                                        }
                                                    </div>
                                                    <input
                                                        type="text"
                                                        id="last_name"
                                                        name="last_name"
                                                        value={formData.last_name}
                                                        onChange={__onTextChange}
                                                        onBlur={checkLastNameValidation}
                                                        autoComplete="off"
                                                        maxLength="50"
                                                        required
                                                    />
                                                </div>
                                                <div className="profilefield">
                                                    <div className="showWithLabal">
                                                        <label className="profile-label">Company name</label>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        id="company_name"
                                                        name="company_name"
                                                        value={formData.company_name}
                                                        onChange={__onTextChange}
                                                    />
                                                </div>
                                                <div className="profilefield">
                                                    <div className="showWithLabal">
                                                        <label className="profile-label">Email address </label>

                                                        {/* {errorMSG && <small className="error" >
                                                            <p style={{ color: "red" }}>{errorMSG}</p>
                                                        </small>} */}

                                                        {
                                                            errorData.email_error && errorData.email_error != "" ? (
                                                                <p className="error" >
                                                                    <p style={{ color: "red" }}>{errorData.email_error} </p>
                                                                </p>

                                                            ) : null
                                                        }
                                                        {
                                                            checkemailaexistsData.isError == true && checkemailaexistsData.checkemailexistsdata.status_code != 200 && checkemailaexistsData.checkemailexistsdata.message != "" ? (
                                                                <small className="error">
                                                                    <p style={{ color: "red" }}>{checkemailaexistsData.checkemailexistsdata.message} </p>
                                                                </small>

                                                            ) : null
                                                        }
                                                    </div>
                                                    <input
                                                        type="email"
                                                        id="email"
                                                        name="email"
                                                        value={formData.email}
                                                        onChange={__onTextChange}
                                                        // onKeyUp={checkEmailValidation}
                                                        onBlur={checkEmailValidation}
                                                        autoComplete="off"
                                                        required
                                                        minLength="6"
                                                        maxLength="50"

                                                    />
                                                </div>
                                                <div className="profilefield">
                                                    <div className="showWithLabal">
                                                        <label className="profile-label">Treatment location  </label>

                                                        {
                                                            errorData.postcode_error && errorData.postcode_error != "" ? (
                                                                <p className="error" >

                                                                    <p style={{ color: "red" }}>{errorData.postcode_error} </p>
                                                                </p>

                                                            ) : null
                                                        }
                                                    </div>
                                                    <input
                                                        type="text"
                                                        value={formData.location}
                                                        id="location"
                                                        name="location"
                                                        onChange={handleInput}
                                                        autoComplete="off"
                                                        placeholder="Please enter treatment location"
                                                        onBlur={checkPostcodeValidation}
                                                        required
                                                        onClick={() => {
                                                            if (!removeonclick) {
                                                                updateFormData({
                                                                    ...formData,
                                                                    location: '',
                                                                    city: '',
                                                                });
                                                                setRemoveClick(true)
                                                            }
                                                        }}
                                                    />
                                                    {status === 'OK' && <ul className="autoSearch">{renderSuggestions()}</ul>}
                                                </div>


                                                {/* //new added     */}

                                                {
                                                    isaddress ?
                                                        <div>
                                                            <div className="profilefield">
                                                                <label className="profile-label">Flat/Building No</label>

                                                                <div className="search-field">
                                                                    <div className="loc_point">
                                                                        <input
                                                                            //className="searchText"
                                                                            type="text"
                                                                            id="flat_build_no"
                                                                            name="flat_build_no"
                                                                            value={formData.flat_build_no}

                                                                            onChange={__onTextChange}
                                                                            autoComplete="off"
                                                                            placeholder="Please enter Flat/Building no"
                                                                            required
                                                                        />
                                                                    </div>

                                                                </div>

                                                            </div>

                                                            <div className="profilefield">
                                                                <label className="profile-label">Street Name</label>

                                                                <div className="search-field">
                                                                    <div className="loc_point">
                                                                        <input
                                                                            //className="searchText"
                                                                            type="text"
                                                                            id="street_name"
                                                                            name="street_name"
                                                                            value={formData.street_name}
                                                                            onChange={__onTextChange}
                                                                            autoComplete="off"
                                                                            placeholder="Please enter street name"
                                                                            required
                                                                        />
                                                                    </div>

                                                                </div>

                                                            </div>

                                                            <div className="profilefield">
                                                                <label className="profile-label">City/Town Name</label>

                                                                <div className="search-field">
                                                                    <div className="loc_point">
                                                                        <input
                                                                            //className="searchText"
                                                                            type="text"
                                                                            id="town_city"
                                                                            name="town_city"
                                                                            value={formData.town_city}
                                                                            onChange={__onTextChange}
                                                                            autoComplete="off"
                                                                            placeholder="Please enter city/town name"
                                                                            required
                                                                        />
                                                                    </div>

                                                                </div>

                                                            </div>

                                                        </div> : null
                                                }


                                                <div className="profilefield">
                                                    <div className="showWithLabal">
                                                        <label className="profile-label">Contact number   </label>
                                                        {
                                                            errorData.phonenumber_error && errorData.phonenumber_error != "" ? (
                                                                <p className="error" >

                                                                    <p style={{ color: "red" }}>{errorData.phonenumber_error} </p>
                                                                </p>

                                                            ) : null
                                                        }

                                                    </div>
                                                    <input
                                                        type="number"
                                                        id="telephone_no"
                                                        name="telephone_no"
                                                        value={formData.telephone_no}
                                                        onChange={__onTextChange}
                                                        // onKeyUp={checkMobileValidation}
                                                        onBlur={checkMobileValidation}
                                                        required
                                                        minLength="10"
                                                        maxLength="11"

                                                    />
                                                </div>

                                            </div>

                                            <div className="fromProfile  mb-24">
                                                {imageUploaddata.imageUploaddata &&
                                                    imageUploaddata.imageUploaddata.hasOwnProperty("errors") ? (
                                                    <p className="errortext">
                                                        {imageUploaddata.imageUploaddata.errors.hasOwnProperty(
                                                            "image"
                                                        ) ? (
                                                            <span style={{ color: "red" }}>
                                                                {
                                                                    imageUploaddata.imageUploaddata.errors.image[0]
                                                                }{" "}
                                                            </span>
                                                        ) : null}
                                                    </p>
                                                ) : null}
                                                <div className="two box">
                                                    <div className=" media-profile">
                                                        <div className="inside-media">
                                                            <div className="media-img">
                                                                <div className="upload-area">
                                                                    <ImageUpload
                                                                        id="profile_pic1"
                                                                        File={formData.profile_pic ? formData.profile_pic : practitioner_avtar}
                                                                        handleFileChange={__onImageChange}
                                                                        RemoveImg={deleteImg1}
                                                                        setEdit={formData.profile_pic == ""}
                                                                        showloading={imageUploaddata.isFetching && currentimageId == 1 ? true : false}
                                                                    />


                                                                </div>
                                                            </div>

                                                            <div className="media-content">
                                                                <div className="media-decs">
                                                                    <h3> Profile picture </h3>
                                                                    <p>
                                                                        Please make this photo
                                                                        as clear and as up to date as possible.{" "}
                                                                    </p>
                                                                    {/* <p>
                                                                        {" "}
                                                                        You can skip this for now but will need a
                                                                        profile photo before making a booking.{" "}
                                                                    </p> */}
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>

                                            </div>

                                            <div className="formtab-box">
                                                <div className="inside-data">
                                                    <div className="show-data-company">
                                                        <p className="category-form">Select the category that applies </p>

                                                        {
                                                            errorData.practitioner_category_apply_error && errorData.practitioner_category_apply_error != "" ? (
                                                                <p className="error" >

                                                                    <p style={{ color: "red" }}>{errorData.practitioner_category_apply_error} </p>
                                                                </p>

                                                            ) : null
                                                        }


                                                        <div className="boxgrid">
                                                            {
                                                                comapnycategorynewData.companycategorylistnewdata && comapnycategorynewData.companycategorylistnewdata.data && comapnycategorynewData.companycategorylistnewdata.data.categories.map((sub_item, sub_index) => {
                                                                    if (sub_item.id !== 5) {
                                                                        return (

                                                                            <div className="checkbox-button" key={sub_index}>
                                                                                <input type="checkbox" className={formData.practitioner_category_apply === sub_item.name ? "company-checked yes-checked" : "company-checked"} id={sub_item.name} name={sub_item.name} checked={formData.practitioner_category_apply === sub_item.name ? true : false} onClick={(e) => __selectCompanyCategory(e, sub_item.name)} value={sub_item.name} />
                                                                                {/* <label htmlFor={sub_item.id} className="company-name">{sub_item.id === 4 ? "Beauty salon" : sub_item.name}</label> */}
                                                                                <label htmlFor={sub_item.id} className="company-name">{sub_item.name}</label>
                                                                            </div>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                        </div>


                                                    </div>

                                                </div>
                                            </div>


                                            <div className="fromProfile form-wrp boxedit3 mb-24">
                                                <div className="profilefield">
                                                    <div className="showwithlabel">
                                                        <label className="profile-label">Profile display name</label>
                                                        {errorData.name_error &&
                                                            errorData.name_error != "" ? (
                                                            <p className="error">
                                                                <p style={{ color: "red" }}>
                                                                    {errorData.name_error}{" "}
                                                                </p>
                                                            </p>
                                                        ) : null}
                                                    </div>
                                                    <div className="input-addicon addicontext" >
                                                        <input
                                                            id="name"
                                                            name="name"
                                                            type="text"
                                                            value={formData.name}
                                                            onBlur={checkNameValidation}
                                                            onChange={__onTextChange}
                                                            placeholder="pre-populated"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="profilefield mb-0">
                                                    <div className="showwithlabel">
                                                        <label className="profile-label">Write a short bio for your profile</label>
                                                        {errorData.bio_error &&
                                                            errorData.bio_error != "" ? (
                                                            <p className="errortext error">
                                                                {errorData.bio_error}{" "}
                                                            </p>
                                                        ) :
                                                            <p className="optiontext error">
                                                                {errorData.bio_error_active}{" "}
                                                            </p>
                                                        }
                                                    </div>
                                                    <div className="input-addicon addicontextarea" >
                                                        <textarea
                                                            placeholder="This is your shop window to sell yourself to GiniPig's, ensure you include your favourite pictures of your portfolio. Be honest about qualifications, training & experience. Everyone loves warm & friendly. Your GiniPig will review you on Professionalism, Friendliness, Reliability & Results. You can come back & edit your profile at anytime - as you grow. Remember we are here for all your Training, Case Study, Portfolio requirements & even filling those empty slots. Let's get filling your diary!"
                                                            onFocus={(e) => e.target.placeholder = ""}
                                                            maxLength="500"
                                                            //placeholder="Character limit"
                                                            value={formData.bio}
                                                            id="bio"
                                                            name="bio"
                                                            onChange={__onTextChange}
                                                            onBlur={checkBioValidation}
                                                        />
                                                    </div>
                                                    {
                                                        character_count === 500 ?
                                                            <span className="char-span">{character_count} Character limit</span> :
                                                            <span className="char-span">{character_count} Remaining</span>
                                                    }
                                                </div>
                                            </div>

                                            <div className="btn-align pra_about pd32">
                                                <Link to="/Profile-ChangePassword" onClick={__redirectScrollTop} className="passwd-btn">
                                                    Change Password
                                                </Link>
                                                <LargeButton
                                                    ButtonType="submit"
                                                    ButtonText="Save Changes"
                                                    disabled={errorData.is_form_valid === false || imageUploaddata.isFetching || practitioneraboutusdata.isFetching && currentimageId == 1 ? true : false}
                                                />
                                            </div>

                                        </div>
                                        <div className="align-right"></div>
                                    </div>
                                </div>


                            </form>
                        </Col>
                    </Row>
                </Container>

            </section>

        </>
    );
};

export default PractitionerAboutUs;
