export const REQUESTDETAILS_REQUEST = "REQUESTDETAILS_REQUEST";
export const REQUESTDETAILS_RECEIVE = "REQUESTDETAILS_RECEIVE";
export const REQUESTDETAILS_FAILURE = "REQUESTDETAILS_FAILURE";
export const RESET_REQUESTDETAILS   = "RESET_REQUESTDETAILS";
export const BOOKINGCONDITION_SAVE_DATA = "BOOKINGCONDITION_SAVE_DATA";
export const RESET_BOOKINGCONDITION_SAVE_DATA = "RESET_BOOKINGCONDITION_SAVE_DATA";
export const PHOTO_DETAILS = "PHOTO_DETAILS";
export const RESET_PHOTO_DETAILS = "RESET_PHOTO_DETAILS";
export const TREATMENT_DETAILS = "TREATMENT_DETAILS";
export const RESET_TREATMENT_DETAILS = "RESET_TREATMENT_DETAILS";


