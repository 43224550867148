import React from "react";
import {
  LeftArrow,
  icon_whats_availablePNG,
  reviewAvatar,
} from "../../config/images";

const RequestBookingCard = (props) => {
  return (
    <>
      <div className="upcoming_cards bookingcards mt-24">
        <div className="pad16 upside_content border_bottom">
          <div className="content_box">
            <div className="categorylist-box">
                <div className="list_name">{props.treatmentTitle}</div>
                <div className="category_badge">
                    <span className="category_badge_name">{props.tagsName}</span>   
                    <span className="category_badge_hrs">{props.hrs > 0 ? props.hrs + "h" : ""} {props.minutes > 0 ? props.minutes + "m" : ""}</span>
                    {/* <span className="category_badge_hrs">{props.duration}</span>    */}
                </div>
                <div className="category_price">
                        <span className="price_strik og_price"> <span>£</span>{props.originalPriceText}</span>
                        <span className="price_discounted"><span>£</span>{props.discountPrice}</span>
                </div>
            </div>
          </div>
        </div>
        <div className="pad16 downside_content">
          <div className="down_content">
            <div className="location-text">
                <span>{props.addressText!==null && props.addressText!==undefined? props.addressText:"Online"}</span>
            </div>
            <div className="timing_box">
                <span>{props.slotTime}</span>
            </div>
            <div className="exp_list_item">
                
                <div className="iconwrp_box">
                    <span className="list--icons"><img src={props.categoryIcon2} className="categoryIcon" /></span>
                    <span className=" icon_names">  {props.categorName2} </span>     
                </div>
                <div className="iconwrp_box">
                    <span className="list--icons"><img src={props.categoryIcon1} className="categoryIcon" /></span>
                    <span className=" icon_names">  {props.categorName1} </span>     
                </div>  
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestBookingCard;
