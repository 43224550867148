import React from 'react';
import { useHistory,Link } from 'react-router-dom';

const BlogCard = (props) =>{
    return (
        <>
            <div className="blog-card">
                <div className="b_card">
                    <div className="b_img">
                        <img src={props.Img} className="img_block" />
                    </div>
                    <div className="b_content pad24">
                        <div className="b_heading flexbox_grid mb-16">
                            <h3 className="title">{props.Heading}</h3>
                            <span className="post_date">{props.PublishDate}</span>
                        </div>
                        <div className="b_decs mb-16">
                            <p>{props.Decs}</p>
                        </div>
                        <div className="b_author_box flexbox_grid mt-8">
                            <p className="author_name">{props.AuthorName}</p>
                            <button onClick={(e) => props.ButtonLink(props.blogId)} className="readmore_btn" >{props.ButtonName}</button> 
                        </div>
                    </div>
                </div>
            </div>  
        </>
    );
}

export default BlogCard;