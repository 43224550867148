import React, { useCallback, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import {
  LeftArrow,
  star,
  iconPhoto,
  iconDelete_peach,
  reviewAvatar,
} from "../../config/images";
import NavigationBreadcrumbs from "../../components/NavigationBreadcrumbs";
import ImageUpload from "../../components/ImageUpload";
import {
  PractionerportfoliolistAPI_Action,
  PractportfolioaddimageAPI_Action,
  resetpractitionerProfileAPI_Action,
  makefeatureImageAPI_Action,
  deletepractportfolioAPI_Action,
  editpractitionerporfolioAPI_Action,
  resetLoginAPI_Action,
  ResetPractionerportfoliolistAPI_Action,
  ResetPractportfolioaddimageAPI_Action
} from "../../stores/actions";
import {
  getLocalStorageData,
  setLocalStorageData,
  clearLocalStorage,
} from "../../utils/storage";

const initialErrorData = Object.freeze({
  image_type_error: "",
  is_form_valid: true,
});
const PractitionerPortfolio = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();

  const [portfolioImages, setPortfolioImages] = useState([]);
  const [imageId, setImageId] = useState();
  const [errorData, updateErrorData] = useState(initialErrorData);

  const editpractitionerportfolio = useSelector(
    (state) => state.editpractitionerportfolio
  );
  const practportfolioaddimage = useSelector(
    (state) => state.practportfolioaddimage
  );
  const makeimagefeaturedata = useSelector(
    (state) => state.makeimagefeaturedata
  );
  const practitionerportfoliolist = useSelector(
    (state) => state.practitionerportfoliolist
  );


  const [formData, updateFormData] = useState(
    Object.freeze({
      file1: "",
      filesave: "",
      imageName: "",
      imageNameedited: "",
      imagearray1: [],
    })
  );
  useEffect(() => {
    __apiCall();
    dispatch(resetpractitionerProfileAPI_Action());

    let imageArray = [];
    if (practitionerportfoliolist.practitionerportfoliolistdata !== null) {
      if (
        practitionerportfoliolist &&
        practitionerportfoliolist.practitionerportfoliolistdata !== null &&
        practitionerportfoliolist.practitionerportfoliolistdata.status_code !==
          401
      ) {
        imageArray =
          practitionerportfoliolist.practitionerportfoliolistdata.data
            .practitionerPortfolioDetails;
      }
    }
    setPortfolioImages(imageArray);
  }, []);

  if (
    practitionerportfoliolist &&
    practitionerportfoliolist.practitionerportfoliolistdata !== null &&
    practitionerportfoliolist.practitionerportfoliolistdata.status_code === 401
  ) {
    clearLocalStorage("loginData");
    clearLocalStorage("practitionersignupData");
    dispatch(resetLoginAPI_Action());
    dispatch(ResetPractionerportfoliolistAPI_Action());
    window.scrollTo(0, 0);
    history.push("/login");
  }

  const __apiCall = useCallback(async () => {
    let checkToken = "";

    let LocalDatatoken = getLocalStorageData("loginData");
    let registerData = getLocalStorageData("practitionersignupData");

    if (
      registerData != "" &&
      registerData !== undefined &&
      registerData !== null
    ) {
      checkToken = registerData.token;
    } else if (
      LocalDatatoken !== "" &&
      LocalDatatoken !== undefined &&
      LocalDatatoken !== null
    ) {
      checkToken = LocalDatatoken.token;
    }
    try {
      await dispatch(PractionerportfoliolistAPI_Action(checkToken));
    } catch (error) {
      console.error(" profile ::  profile() :: Exception ==", error);
    }
  });

  const backBtnPressed = () => {
    history.goBack();
  };
  const deleteImg1 = () => {
    updateFormData({
      ...formData,
      file1: "",
      filesave: "",
      imagearray1: [],
    });
  };
  const handleFileChange1 = (e) => {

      dispatch(ResetPractportfolioaddimageAPI_Action())

    if (e.target.files[0] !== undefined) {
      let filetype = e.target.files[0].type.substring(
        e.target.files[0].type.lastIndexOf("/")
      );

      let newfiletype = filetype.replace("/", "");
      if (
        newfiletype == "jpeg" ||
        newfiletype == "jpg" ||
        newfiletype == "png"
      ) {
        let imageArray = [];
        if (e.target.files[0] != undefined) {
          imageArray.push(e.target.files[0]);
          updateFormData({
            ...formData,
            filesave: e.target.files[0],
            file1: URL.createObjectURL(e.target.files[0]),
          });
        }
        updateErrorData({
          ...errorData,
          // is_form_valid: true,
          image_type_error: "",
        });
      } else {
        updateErrorData({
          ...errorData,
          // is_form_valid: false,
          image_type_error: "Please upload only JPEG,JPG and PNG file.",
        });
      }
    }
  };

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const ClickonSaveImage = (e) => {
    e.preventDefault();
    __apiCallAdd();
  };
  const editButtonClick = (e, item) => {
    e.preventDefault();
    setImageId(item.id);
    updateFormData({
      ...formData,
      imageNameedited: item.image_title,
    });
  };

  const editsaveClick = (e, item) => {
    e.preventDefault();
    // setImageId(item.id)
    __apiCallEdit(item);
  };

  const deleteImageClick = (e, item) => {
    e.preventDefault();
    // setImageId(item.id)
    __apiCallDelete(item.id);
  };
  const makefeatureImage = (e, item, status) => {
    e.preventDefault();
    // setImageId(item.id)
    __apiCallFeature(item.id, status);
  };

  const __apiCallAdd = useCallback(async () => {
    let data = {
      image: formData.filesave,
      name: formData.imageName,
    };
    let checkToken = "";

    let LocalDatatoken = getLocalStorageData("loginData");
    let registerData = getLocalStorageData("practitionersignupData");

    if (
      registerData != "" &&
      registerData !== undefined &&
      registerData !== null
    ) {
      checkToken = registerData.token;
    } else if (
      LocalDatatoken !== "" &&
      LocalDatatoken !== undefined &&
      LocalDatatoken !== null
    ) {
      checkToken = LocalDatatoken.token;
    }

    try {
      await dispatch(PractportfolioaddimageAPI_Action(data, checkToken));
      await dispatch(PractionerportfoliolistAPI_Action(checkToken));
    } catch (error) {
      console.error(" profile ::  profile() :: Exception ==", error);
    }
    updateFormData({
      ...formData,
      file1: "",
      filesave: "",
      imageName: "",
    });

    window.scrollTo(0, 0);
  });
  const __apiCallEdit = useCallback(async (item) => {
    let data = {
      id: item.id,
      image_title: formData.imageNameedited,
    };
    let checkToken = "";

    let LocalDatatoken = getLocalStorageData("loginData");
    let registerData = getLocalStorageData("practitionersignupData");

    if (
      registerData != "" &&
      registerData !== undefined &&
      registerData !== null
    ) {
      checkToken = registerData.token;
    } else if (
      LocalDatatoken !== "" &&
      LocalDatatoken !== undefined &&
      LocalDatatoken !== null
    ) {
      checkToken = LocalDatatoken.token;
    }

    try {
      await dispatch(editpractitionerporfolioAPI_Action(data, checkToken));
      await dispatch(PractionerportfoliolistAPI_Action(checkToken));
    } catch (error) {
      console.error(" profile ::  profile() :: Exception ==", error);
    }
    setImageId("");

    window.scrollTo(0, 0);
  });

  const __apiCallDelete = useCallback(async (id) => {
    let checkToken = "";

    let LocalDatatoken = getLocalStorageData("loginData");
    let registerData = getLocalStorageData("practitionersignupData");

    if (
      registerData != "" &&
      registerData !== undefined &&
      registerData !== null
    ) {
      checkToken = registerData.token;
    } else if (
      LocalDatatoken !== "" &&
      LocalDatatoken !== undefined &&
      LocalDatatoken !== null
    ) {
      checkToken = LocalDatatoken.token;
    }

    try {
      await dispatch(deletepractportfolioAPI_Action(id, checkToken));
      await dispatch(PractionerportfoliolistAPI_Action(checkToken));
    } catch (error) {
      console.error(" profile ::  profile() :: Exception ==", error);
    }
    updateFormData({
      ...formData,
      file1: "",
      filesave: "",
    });

    window.scrollTo(0, 0);
  });

  const __apiCallFeature = useCallback(async (id, status) => {
    let data = {
      id: id,
      is_featured: status,
    };
    let checkToken = "";

    let LocalDatatoken = getLocalStorageData("loginData");
    let registerData = getLocalStorageData("practitionersignupData");

    if (
      registerData != "" &&
      registerData !== undefined &&
      registerData !== null
    ) {
      checkToken = registerData.token;
    } else if (
      LocalDatatoken !== "" &&
      LocalDatatoken !== undefined &&
      LocalDatatoken !== null
    ) {
      checkToken = LocalDatatoken.token;
    }

    try {
      await dispatch(makefeatureImageAPI_Action(data, checkToken));
      await dispatch(PractionerportfoliolistAPI_Action(checkToken));
    } catch (error) {
      console.error(" profile ::  profile() :: Exception ==", error);
    }
    updateFormData({
      ...formData,
      file1: "",
      filesave: "",
    });

    window.scrollTo(0, 0);
  });
  // console.log(makeimagefeaturedata.makeimagefeaturedata )
  return (
    <>
      <section className="bgcolor-gray common-section PractitionerPortfolio pb80">
        <Container>
          <Row>
            {practitionerportfoliolist &&
            practitionerportfoliolist.practitionerportfoliolistdata !== null &&
            practitionerportfoliolist.practitionerportfoliolistdata
              .status_code !== 401 ? (
              <Col className="col-lg-11 m-auto">
                <NavigationBreadcrumbs
                leftalignText="Add images here to feature on your profile"
                  onBackClick={() => {
                    backBtnPressed();
                  }}

                  
                />
                
                <div className="portfolio_wrp">
                  <div className="port pading16 white">
                      
                      {practportfolioaddimage.isError &&
                          <p className="errortext">{practportfolioaddimage?.practportfolioaddimagedata?.errors?.feature_image}</p>
                      }
                    {makeimagefeaturedata.makeimagefeaturedata &&
                    makeimagefeaturedata.makeimagefeaturedata.hasOwnProperty(
                      "data"
                    ) ? (
                      <p className="error">
                        {makeimagefeaturedata.makeimagefeaturedata.data.hasOwnProperty(
                          "error_message"
                        ) ? (
                          <p style={{ color: "red", paddingBottom: 10 }}>
                            {
                              makeimagefeaturedata.makeimagefeaturedata.data
                                .error_message
                            }{" "}
                          </p>
                        ) : null}
                      </p>
                    ) : null}
                    <div className="gridbox gridcol-3">
                      {practitionerportfoliolist.practitionerportfoliolistdata &&
                        practitionerportfoliolist.practitionerportfoliolistdata.data.practitionerPortfolioDetails.map(
                          (image, index) => {
                            return (
                              <div className="img-item" key={index}>
                                {image.featured === 1 ? (
                                  <div
                                    className="img-tag tag_disable_clr"
                                    onClick={(e) =>
                                      makefeatureImage(e, image, 0)
                                    }
                                  >
                                    <span>Unfeature</span>
                                  </div>
                                ) : (
                                  <div
                                    className="img-tag tag_active_clr"
                                    onClick={(e) =>
                                      makefeatureImage(e, image, 1)
                                    }
                                  >
                                    <span>Feature</span>
                                  </div>
                                )}

                                <div className="img-box">
                                  <img
                                    src={image.image_link}
                                    className="imgblock"
                                  />
                                  {image.id === imageId ? (
                                    <div className="img-overlay">
                                      <div className="icon-delete">
                                        <img
                                          src={iconDelete_peach}
                                          className="icon-dlt"
                                          onClick={(e) =>
                                            deleteImageClick(e, image)
                                          }
                                        />
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                                <div className="portfolio-content pad8">
                                  <div
                                    className={
                                      image.id !== imageId
                                        ? "flexgrid-box "
                                        : "flexgrid-box b-hide"
                                    }
                                  >
                                    <p className="img-name">
                                      {image.image_title !== null &&
                                        image.image_title}{" "}
                                    </p>
                                    <button
                                      className="edit-button"
                                      onClick={(e) => editButtonClick(e, image)}
                                    >
                                      {" "}
                                      Edit{" "}
                                    </button>
                                  </div>

                                  <div
                                    className={
                                      image.id === imageId
                                        ? "edtitreatment-box"
                                        : "edtitreatment-box  b-hide"
                                    }
                                  >
                                    <form>
                                      <div className="inputbox">
                                        <input
                                          type="text"
                                          required
                                          id="imageNameedited"
                                          name="imageNameedited"
                                          className="inputfield"
                                          value={formData.imageNameedited}
                                          onChange={handleChange}
                                        />

                                        {editpractitionerportfolio.isFetching ? (
                                          <button
                                            type="button"
                                            className="saveBtn"
                                            disabled={true}
                                          >
                                            Loading..
                                          </button>
                                        ) : (
                                          <button
                                            type="submit"
                                            className="saveBtn"
                                            onClick={(e) =>
                                              editsaveClick(e, image)
                                            }
                                          >
                                            save
                                          </button>
                                        )}
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )}

                      <div className="img-item">
                        <div className="feature-upload">
                          <ImageUpload
                            id="profile_pic"
                            File={formData.file1 ? formData.file1 : null}
                            handleFileChange={handleFileChange1}
                            RemoveImg={deleteImg1}
                            setEdit={formData.file1 == ""}
                          />
                        </div>
                        <div
                          className={
                            formData.file1 == ""
                              ? "edtitreatment-box pad8 b-hide"
                              : "edtitreatment-box  pad8"
                          }
                        >
                          <form>
                            <div className="inputbox">
                              <input
                                type="text"
                                required
                                id="imageName"
                                name="imageName"
                                className="inputfield"
                                value={formData.imageName}
                                onChange={handleChange}
                              />
                              {practportfolioaddimage.isFetching ? (
                                <button
                                  type="submit"
                                  disabled={true}
                                  className="saveBtn"
                                >
                                  Loading...
                                </button>
                              ) : (
                                <button
                                  type="submit"
                                  className="saveBtn"
                                  onClick={ClickonSaveImage}
                                >
                                  save
                                </button>
                              )}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            ) : (
              <div
                style={{
                  width: "100%",
                  fontSize: 20,
                  marginTop: 100,
                  textAlign: "center",
                }}
              >
                <p>Loading ...</p>
              </div>
            )}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default PractitionerPortfolio;
