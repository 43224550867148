import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Col } from 'react-bootstrap';
import { LeftArrow } from '../../config/images';
import { getLocalStorageData, clearLocalStorage } from '../../utils/storage';
import { getHrsLeft } from '../../utils/utility';
import moment from 'moment';
import LargeButton from '../../components/LargeButton';
import { resetcreatebookingAPI_Action } from '../../stores/actions';


const PaymentOption = (props) => {
    const dispatch = useDispatch();
    let history = useHistory();
    const requestdetails = useSelector(state => state.requestdetails);
    var param_data = props.match.params.id;
    const [disable, setDisable] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const [isPhotoAdded, setIsPhotoAdded] = useState(false);


    var request_type = 1;
    var appointment_date;
    var appointment_time;

    let post_data = {
        request_id: param_data,
        date: requestdetails.treatmentDetails !== null ? moment(requestdetails.treatmentDetails.request.request_timeslots.data[0].date_time, "YYYY-MM-DD").format("YYYY/MM/DD") : '',
        time: requestdetails.treatmentDetails !== null ? moment(requestdetails.treatmentDetails.request.request_timeslots.data[0].start_time, "H:mm").format("H:mm") : '',
        intent_to_pay_status: 'full',
        stripeToken: '',
        stripeTokenType: 'card',
        stripeEmail: '',
        is_suitable_to_treatment: 0,
        is_supplied_matching_photos: 0,
        is_read_accept_consent_form: 0,
        is_accept_booking_condition: 0,
        is_allow_patch_test: 1,
        use_ginipig_existing_card: 0,
        request_image: []
    }
    let feature_image = []
    var photo_added;

    useEffect(() => {
        if (requestdetails && requestdetails.treatmentDetails !== null) {
            //    if(requestdetails.hasOwnProperty('bookingCondition'))
            //    {

            //         if(requestdetails.bookingCondition !== null){

            //             if(requestdetails.bookingCondition.modal_require === true){
            //                 post_data.is_suitable_to_treatment = 1
            //             }
            //             if(requestdetails.bookingCondition.consent_form === true){
            //                 post_data.is_read_accept_consent_form = 1
            //             }
            //             if(requestdetails.bookingCondition.photo_condition === true){
            //                 post_data.is_supplied_matching_photos = 1
            //             }
            //             if(requestdetails.bookingCondition.patch_required === true){
            //                 post_data.is_allow_patch_test = 1
            //             }
            //             if(requestdetails.bookingCondition.accept_booking === true){
            //                 post_data.is_accept_booking_condition = 1
            //             }
            //         }
            //    }

            //fromlocalstorage
            var booking_cond = '';
            if (getLocalStorageData("bookingcondition_requirement") !== undefined) {
                booking_cond = JSON.parse(getLocalStorageData("bookingcondition_requirement"));

                if (booking_cond !== null) {
                    if (booking_cond.modal_require === true) {
                        post_data.is_suitable_to_treatment = 1
                    }
                    if (booking_cond.consent_form === true) {
                        post_data.is_read_accept_consent_form = 1
                    }
                    if (booking_cond.photo_condition === true) {
                        post_data.is_supplied_matching_photos = 1
                    }
                    if (booking_cond.patch_required === true) {
                        post_data.is_allow_patch_test = 1
                    }
                    if (booking_cond.accept_booking === true) {
                        post_data.is_accept_booking_condition = 1
                    }
                }
            }

            if (requestdetails.hasOwnProperty('photoDetails')) {
                if (requestdetails.photoDetails !== null) {
                    if (requestdetails.photoDetails.imagearray1.length > 0) {
                        feature_image.push(requestdetails.photoDetails.imagearray1[0])
                    }
                    if (requestdetails.photoDetails.imagearray2.length > 0) {
                        feature_image.push(requestdetails.photoDetails.imagearray2[0])
                    }
                    if (requestdetails.photoDetails.imagearray3.length > 0) {
                        feature_image.push(requestdetails.photoDetails.imagearray3[0])
                    }
                    if (requestdetails.photoDetails.imagearray4.length > 0) {
                        feature_image.push(requestdetails.photoDetails.imagearray4[0])
                    }
                }
            }
            if (requestdetails && requestdetails.treatmentDetails !== null && !requestdetails.isFetching) {
                request_type = requestdetails.treatmentDetails.request.type;
                appointment_date = requestdetails.treatmentDetails !== null ? moment(requestdetails.treatmentDetails.request.request_timeslots.data[0].date_time, "YYYY-MM-DD").format("YYYY/MM/DD") : '';
                appointment_time = requestdetails.treatmentDetails !== null ? moment(requestdetails.treatmentDetails.request.request_timeslots.data[0].start_time, "H:mm").format("H:mm") : '';

                if (requestdetails.treatmentDetails.request.type === 1) {
                    let selectedDates = getLocalStorageData("book_open_request_time") ? JSON.parse(getLocalStorageData("book_open_request_time")) : {};

                    post_data.date = moment(selectedDates.date, "DD-MM-YYYY").format("YYYY/MM/DD");
                    post_data.time = selectedDates.timeslots;

                    let temp = "";
                    var hrsLeft = 0;
                    if (selectedDates.timeslots.morning.length > 0) {
                        temp = post_data.date + " " + selectedDates.timeslots.morning[0];
                        var datetimeFrmt = moment(temp, "YYYY-MM-DD H:mm").format("YYYY/MM/DD H:mm");
                        hrsLeft = getHrsLeft(datetimeFrmt);
                        if (hrsLeft <= 36) {
                            setIsVisible(false);
                        }

                    } else if (selectedDates.timeslots.afternoon.length > 0) {
                        temp = post_data.date + " " + selectedDates.timeslots.afternoon[0];
                        var datetimeFrmt = moment(temp, "YYYY-MM-DD H:mm").format("YYYY/MM/DD H:mm");
                        hrsLeft = getHrsLeft(datetimeFrmt);
                        if (hrsLeft <= 36) {
                            setIsVisible(false);
                        }

                    } else if (selectedDates.timeslots.evening.length > 0) {
                        temp = post_data.date + " " + selectedDates.timeslots.evening[0];
                        var datetimeFrmt = moment(temp, "YYYY-MM-DD H:mm").format("YYYY/MM/DD H:mm");
                        hrsLeft = getHrsLeft(datetimeFrmt);
                        if (hrsLeft <= 36) {
                            setIsVisible(false);
                        }
                    }
                    // setDisable(true);
                } else {
                    let tempdate = requestdetails.treatmentDetails.request.request_timeslots.data[0].date_time.split(" ");
                    let temp = tempdate[0] + " " + requestdetails.treatmentDetails.request.request_timeslots.data[0].start_time;

                    var datetimeFrmt = moment(temp, "YYYY-MM-DD H:mm").format("YYYY/MM/DD H:mm");
                    var hrsLeft = getHrsLeft(datetimeFrmt);
                    if (hrsLeft <= 36) {
                        setIsVisible(false);
                    }
                }
            }

        } else {
            nextButtonClick()
        }
        post_data.request_image = feature_image;


        if (getLocalStorageData("photo_added") !== undefined) {
            photo_added = JSON.parse(getLocalStorageData("photo_added"));
            if (photo_added.photo_added === true) {
                if (requestdetails && !requestdetails.isFetching) {
                    if (requestdetails.hasOwnProperty('photoDetails')) {
                        if (requestdetails.photoDetails === null) {
                            //history.push('/photo-requirement/'+param_data);
                            setIsPhotoAdded(true);
                        } else {
                            setIsPhotoAdded(false)
                        }
                    }
                }
            }
        }



    }, [post_data]);

    const nextButtonClick = () => {
        window.scrollTo(0, 0);
        clearLocalStorage("book_open_request_time");
        clearLocalStorage("update_ginipig_profile");
        window.removeEventListener('popstate', function (event) {
            // history.go(1);
        }, false);
        var backpressed = ''; var data = ''; var date = '';

        if (getLocalStorageData("filter_treatment_data") !== undefined) {
            backpressed = JSON.parse(getLocalStorageData("filter_treatment_data"))
        }
        if (backpressed.hasOwnProperty('searchDetails')) {
            if (backpressed.searchDetails !== null) {
                if (backpressed.searchDetails.startDate !== 'Any date') {
                    date = [new Date(backpressed.searchDetails.startDate).getFullYear(), ("0" + (new Date(backpressed.searchDetails.startDate).getMonth() + 1)).slice(-2), ("0" + new Date(backpressed.searchDetails.startDate).getDate()).slice(-2)].join("-");
                } else {
                    date = 'Any date'
                }
                data = {
                    keywords: backpressed.searchDetails.search_keywords,
                    location: backpressed.searchDetails.search_location,
                    latitude: backpressed.searchDetails.search_latitude,
                    longitude: backpressed.searchDetails.search_longitude,
                    postcode: backpressed.searchDetails.search_postcode,
                    from_header: 'not_from_header',
                    date: date
                }
            }
        }

        history.push('/search-result', { data: JSON.stringify(data), date: date })
    }


    const partialPayment = () => {
        setDisable(true);
        post_data.intent_to_pay_status = "partial";

        doPayment();
    }

    const fullPayment = () => {
        setDisable(true);
        post_data.intent_to_pay_status = 'full';

        doPayment();
    }
    const doPayment = async () => {
        let loginToken = getLocalStorageData("loginData");

        try {

            // history.push({ 
            //     pathname: '/payment',
            //     state: {'paramData' : param_data,'postData': post_data,'type':requestdetails.treatmentDetails.request.type},
            //    });  
            dispatch(resetcreatebookingAPI_Action());
            window.scrollTo(0, 0);
            history.push({
                pathname: '/payment',
                state: { 'paramData': param_data, 'postData': post_data, 'type': request_type },
            });


        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    }




    const __nextBooking = () => {
        window.scrollTo(0, 0);
        history.push('/thankyou')
    }


    return (
        <>
            <section className="bgcolor-gray common-section payment_pg pb80">
                <Container>
                    <Row>
                        <Col className="col-lg-11 m-auto">
                            <div className="navigationTop">
                                <div className="heading-history">
                                    <div className="back-history">
                                        <button type="button" onClick={() => history.goBack()} className="back-btn" >
                                            {" "}
                                            <img src={LeftArrow} alt="arrow" />{" "}
                                        </button>
                                    </div>
                                    <div className="history-title">
                                        <h2> Payment Options  </h2>

                                    </div>

                                </div>

                            </div>
                            <div>
                                <p className="paymaent-subheader">
                                This is like a hotel reservation, payment will only be taken when your Provider confirms you're a perfect match and that the appointment has happened. Fingers crossed!
                                </p>
                            </div>

                            {
                                isPhotoAdded ?
                                    <div className="btn-align mt-40 pd32" style={{ justifyContent: "center" }}>
                                        <LargeButton
                                            ButtonType="button"
                                            ButtonText={"Upload Image(s)"}
                                            nextButtonClick={() => history.push('/photo-requirement/' + param_data)}
                                        />
                                    </div> :

                                    <div className="requirement_wrp">
                                        <div className="payment-box mt-40">
                                            <div className="inside_content">
                                                <h3>Pay In Full </h3>
                                                <div className="pay_decs mt-16">
                                                    <p> Great feeling to know that no further payment is needed, unless of course you'd like to tip the Treatment Provider on the day </p>
                                                </div>

                                                <div className="payment_btn_box mt-16">
                                                    <div className="btn-align">
                                                        <button className="button_pay"
                                                            onClick={() => fullPayment()}
                                                            disabled={disable}
                                                        >Pay In Full </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="payment-box mt-70 mb-40" style={{ display: isVisible ? "block" : "none" }}>
                                            <div className="inside_content" >
                                                <h3> Pay Deposit </h3>
                                                {/* <h3>Pay 15% deposit now, pay the rest later</h3> */}
                                                <div className="pay_decs mt-16">
                                                    <p> You're a step closer to a fabulous treatment/service, the remaining payment will be due 36 hours before your appointment, we will remind you 48 hours beforehand </p>
                                                </div>

                                                <div className="payment_btn_box mt-16">
                                                    <div className="btn-align">
                                                        <button className="button_pay" disabled={disable} onClick={() => partialPayment()}>Pay Deposit </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            }

                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default PaymentOption;