import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import moment from 'moment';
import { setData } from '../utils/storage';
import { getLocalStorageData, setLocalStorageData, clearLocalStorage } from '../utils/storage';

const AppointmentCalenderSelected = (props) => {
    //init state
    const { data, Setdatas } = useState(
        Object.freeze({
            date: new Date(),
        })
    )

    const [selectedDate, setSelectedDate] = useState("");

    const testData = props.calendarData;
    const selectedDates = props.selectedData;
    const [formData, updateFormData] = useState(Object.freeze({

        morningSlotsArray: [],
        afternoonSlotsArray: [],
        eveningSlotsArray: [],

    }));
    // const [available_date_slots,setAvailable_date_slots] = useState([]);
    // console.log("selectedDate:-", testData);

    useEffect(() => {

        let timeslots = JSON.parse(testData.length !== 0 && testData[0].timeslots);
        let date1 = moment(props.activeDate && props.activeDate).format("DD-MM-yy")
        let tempDt = date1.split("-");
        let FrmtDt = tempDt[2] + "-" + tempDt[1] + "-" + tempDt[0];
        let datemax = new Date(FrmtDt);
        setSelectedDate(datemax)
        updateFormData({
            ...formData,
            morningSlotsArray: timeslots && timeslots.morning,
            afternoonSlotsArray: timeslots && timeslots.afternoon,
            eveningSlotsArray: timeslots && timeslots.evening,
        })


    }, []);

    const tileClassName = (activeStartDate, date, view) => {

        let date2 = moment(activeStartDate.date).format("DD-MM-yy")
        let date1 = moment(props.activeDate && props.activeDate).format("DD-MM-yy")
      
        if (date1 === date2) {
            return "pratitioner_canlender_active_selected_new"

        }
    }

    return (
        <>
            <div className="calander_box1">
                <div className="pratitioner_canlender availibility_box">
                    <Calendar
                        //onChange={(e) => onChange(e)}
                        minDate={new Date()}
                        value={selectedDate}
                        tileClassName={tileClassName}
                        formatShortWeekday ={ (locale, date) => ['S', 'M', 'T', 'W', 'T', 'F', 'S'][date.getDay()]}
                    />
                </div>
                <div className="calneder_slot_times for_ginipig">
                    <div className="day_slot_box">
                        <div className="radio-box">

                            <label className={formData.morningSlotsArray.length > 0 ? "radio_label applyflex" : "radio_label applyflex disable_row"} htmlFor="day_slot1">

                                <div className="appoint_slots ml-15 appoint_slot_day">
                                    <span className="morning_icon icon_slots slot_heading">Morning</span>
                                </div>
                                <div className={formData.morningSlotsArray.length > 0 ? "noAvailable_box slot-show" : "noAvailable_box"}>
                                    <span className="noavailaibel_text">No availability</span>
                                </div>

                            </label>
                            {formData.morningSlotsArray.length > 0 ?
                                <div className="slots_avail_box">
                                    <div className="inside_slot_grid">
                                        {
                                            formData.morningSlotsArray.map((item, index) => {
                                                return (
                                                    
                                                    <div className="slot_div">
                                                    <button
                                                        disabled={props.disableSlots}
                                                        className="schedule_slot">
                                                        <span className="fix-times">{item}</span>
                                                    </button>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div> : null}
                        </div>
                        <div className="radio-box">
                            <label className={formData.afternoonSlotsArray.length > 0 ? "radio_label applyflex" : "radio_label applyflex disable_row"} htmlFor="day_slot1">

                                <div className="appoint_slots ml-15 appoint_slot_day">
                                    <span className="morning_icon icon_slots slot_heading">Afternoon</span>
                                </div>

                                <div className={formData.afternoonSlotsArray.length > 0 ? "noAvailable_box slot-show" : "noAvailable_box"}>
                                    <span className="noavailaibel_text">No availability</span>
                                </div>

                            </label>
                            {formData.afternoonSlotsArray.length > 0 ?
                                <div className="slots_avail_box">
                                    <div className="inside_slot_grid">
                                        {
                                            formData.afternoonSlotsArray.map((item, index) => {
                                                return (
                                                    
                                                    <div className="slot_div">
                                                    <button
                                                        disabled={props.disableSlots}
                                                        className="schedule_slot"  >
                                                        <span className="fix-times">{item}</span>
                                                    </button>
                                                    </div>

                                                )
                                            })
                                        }
                                    </div>
                                </div> : null}

                        </div>
                        <div className="radio-box">
                            <label className={formData.eveningSlotsArray.length > 0 ? "radio_label applyflex" : "radio_label applyflex disable_row"} htmlFor="day_slot1">

                                <div className="appoint_slots ml-15 appoint_slot_day">
                                    <span className="morning_icon icon_slots slot_heading">Evening</span>
                                </div>
                                <div className={formData.eveningSlotsArray.length > 0 ? "noAvailable_box slot-show" : "noAvailable_box"}>
                                    <span className="noavailaibel_text">No availability</span>
                                </div>

                            </label>
                            {formData.eveningSlotsArray.length > 0 ?
                                <div className="slots_avail_box">
                                    <div className="inside_slot_grid">
                                        {
                                            formData.eveningSlotsArray.map((item, index) => {
                                                return (
                                                    
                                                    <div className="slot_div">
                                                        <button
                                                            disabled={props.disableSlots}
                                                            className="schedule_slot">
                                                            <span className="fix-times">{item}</span>
                                                        </button>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div> : null}
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default AppointmentCalenderSelected;