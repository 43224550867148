import React, { useMemo,useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";


const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
         
          color: "#424770",
          letterSpacing: "0.025em",
          
        },
        invalid: {
          color: "#9e2146"
        }
      },
      hidePostalCode:false,
      billingAddressCollection: 'required',

    }),
  
    
  );

  return options;
};


const CardForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const [isEnable, setIsEnable] = useState(false)
  const handleSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    setIsEnable(true);
    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);
    if (result.error) {
    } else {
      props.doPayment(result);
      // pass the token to your backend API

    }
  };

  const oncardChange=(event)=>{
    if(event.complete===true){
      setIsEnable(false)
    }else{
      setIsEnable(true)
    }
  }
  

  return (
    <div className="requirement_wrp mt-40 padright-0">
    <form onSubmit={handleSubmit}>
      <div className="stripe_label">
         {/* <p className="subheading"> Card details </p> */}
         <p className="subheading"> Enter card details below </p>
        <CardElement
         hidePostalCode={false}
          options={options}
          onChange={(value)=>oncardChange(value)}
        />
      </div>
      <button type="submit" className="stripe_button" disabled={isEnable|| !stripe}>
        Pay
      </button>
    </form>
    </div>
  );
};

export default CardForm;

                                