import React from "react";
import  SliderContent  from './slideContent';

import SlickSlider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { LeftArrow,prac1,prac2,prac3,prac4,prac5 } from "../../config/images";

const FromSideBarSliderPact = () => {
  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div className={className} style={{ ...style }} onClick={onClick}>
        {" "}
        <img src={LeftArrow} alt="icon" />{" "}
      </div>
    );
  };

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div className={className} style={{ ...style }} onClick={onClick}>
        {" "}
        <img src={LeftArrow} alt="icon" />{" "}
      </div>
    );
  };



  const settings = {
    dots: true,
    autoplay:true,
    arrow: true,
    speed: 500,
    autoplaySpeed:5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <>
      <div className="workSlider-wrp">
        <SlickSlider {...settings}>
          <SliderContent 
          image={prac1}
          title="Gap in Your Diary?"
           description="Treatments can be listed up to 3 hours before the appointment time, so let us fill those slots, when you’re earning, you’re not spending!"/>
          <SliderContent 
            image={prac2}
          title="New Clients"
          description="Unleashing your creativity and bring future clients through your door"/>
          <SliderContent 
            image={prac3}
            title="Find Models"
            description="You are your own best canvas, so register with a gorgeous picture of yourself before listing all your services, to entice your GiniPigs & perfect those skills"/>
          <SliderContent 
            image={prac4}
          title="Earn as You Learn"
            description="We take secure payment on your behalf, no more freebies for family & friends. Raise your income with GiniPigs who value your skills & time"/>
          <SliderContent 
            image={prac5}
          title="Grow Your Portfolio"
          description="Get new clients through your door & save your portfolio images. Great for you to see how you have progressed & easily showcase to future employees or clients"/>
        </SlickSlider>
      </div>
    </>
  );
};

export default FromSideBarSliderPact;
