import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS } from '../../config/ApiConstants';


export function treatmentFavAPI_Action(id,token) {
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/ginipig/favourite/treatment/${id}`,
            method: 'GET',
            headers: {
                ...AUTH_HEADERS,
                'Authorization':'Bearer '+token
            },
            body: '',
            types: [
                types.TREATMENTFAV_REQUEST,
                types.TREATMENTFAV_RECEIVE, {
                    type: types.TREATMENTFAV_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}


export const resettreatmentFavAPI_Action = () => {
    return {
        type: types.RESET_TREATMENTFAV,
        payload: null
    }
};
