import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col,Modal } from "react-bootstrap";
import { LeftArrow, EyeView } from "../../config/images";
import { useHistory } from "react-router-dom";
import queryString from 'query-string';

// redux
import { resetpasswordAPI_Action,resetPasswordAPI_Action } from "../../stores/actions";

const initialErrorData = Object.freeze({
  email_error: "",
  password_error:"",
  is_form_valid: false,
});

const ResetPassword = (props) => {
  //hooks state
  const [show, setShow] = useState(false);
  const handleClosePopup = () => setShow(false);
  const handleShowPopup = () => setShow(true);


   const __redirectconfirmation = () =>{
    handleClosePopup();
    window.scrollTo(0, 0);
     history.push('/ForgotPasswordConfirmation');
   }


  // Initialize hooks first
  const dispatch = useDispatch();
  let history = useHistory();
  // States Management
  const [formData, updateFormData] = useState(
    Object.freeze({
      email: "",
      password:"",
      code:"",
    })
  );
  const [passwordType, setPasswordType] = useState("password");
  const [eyeIcon, setEyeIcon] = useState(true);
  const [errorData, updateErrorData] = useState(initialErrorData);
  // Selectors
  const ForgotPasswordData = useSelector((state) => state.forgotpassword);
  const Data = JSON.stringify(ForgotPasswordData);
  const ResetPasswordData = useSelector((state) => state.resetpassword);

  //console.log("Forgot Password Data: ",ForgotPasswordData);
  if(ForgotPasswordData.isSuccess === true){
    //console.log('into its success');
    window.scrollTo(0, 0);
    history.push('/ForgotPasswordConfirmation');
  }
  
  // Use effects
  useEffect(() => {
    let params = queryString.parse(props.location.search);
    // console.log("params new: ",params);
    updateFormData({
      ...formData,
      email: params.email,
      code:params.code,
    });
    // if (
    //   ForgotPasswordData.forgotpassword &&
    //   ForgotPasswordData.forgotpassword.status_code === 200
    // ) {
    //   history.push("/login");
    // }
  },[]);
  
    if(ResetPasswordData.isSuccess === true){
      //console.log('into its success');
      window.scrollTo(0, 0);
      history.push("/login");
    }
  const __onChangePasswordToText = (e) => {
    setEyeIcon(!eyeIcon);
    setPasswordType( passwordType == "password" ? 'text' :'password' );
  };

  const __onTextChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  const checkEmailValidation = async(e) => {

    const { id, value } = e.target;

    if (!value) {
      updateErrorData({
        ...errorData,
        is_form_valid: false,
        email_error: "Email is Required.",
      });
    } else {
      updateErrorData({
        ...errorData,
        is_form_valid: true,
        email_error: "",
      });
    }

    if (typeof value !== "undefined") {
      let lastAtPos = value.lastIndexOf("@");
      let lastDotPos = value.lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          value.indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          value.length - lastDotPos > 2
        )
      ) {
        if (!value) {
          updateErrorData({
            ...errorData,
            is_form_valid: false,
            email_error: "Email is Required.",
          });
        } else {
          updateErrorData({
            ...errorData,
            is_form_valid: false,
            email_error: "Email is not valid.",
          });
        }
      } else {
        updateErrorData({
          ...errorData,
          is_form_valid: true,
          email_error: "",
        });
      }
    } else if (value.length > 50) {
      updateErrorData({
        ...errorData,
        is_form_valid: false,
        email_error: "Only 50 Characters are Allowed.",
      });
    } else if (value.length < 6) {
      updateErrorData({
        ...errorData,
        is_form_valid: false,
        email_error: "Minimum 6 Characters are Allowed.",
      });
    } else {
      updateErrorData({
        ...errorData,
        is_form_valid: true,
        email_error: "",
      });
    }

    // if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(value)) {
    //     console.log("true");
    //     updateErrorData({
    //         ...errorData,
    //         email_error: ""
    //       });
    // }
    // else{

    //   updateErrorData({
    //     ...errorData,
    //     email_error: "Please Enter Correct Email:"
    //   });
    // }
    // console.log("Email Error: ",this.state.email_error);
  };

  const __apiCall = useCallback(async () => {
    try {
      let params = queryString.parse(props.location.search);
      await dispatch(resetpasswordAPI_Action(formData,params.token));
      //console.log('respons return')
        if(ForgotPasswordData.isSuccess === true){
          handleShowPopup();
        }
    } catch (error) {
      console.error(" profile ::  profile() :: Exception ==", error);
    }
  });

  const __submitForgotPasswordForm = (e) => {
    e.preventDefault();
    __apiCall();
  };

  const checkNewPasswordValidation = (e) => {
    const {id,value} = e.target; 
    // var decimal=  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/;
    var decimal=  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;

    
    if(!value){
        updateErrorData({
            ...errorData,
            is_form_valid:false,
            password_error: "New Password Is Required."
          });
    }else if(!value.match(decimal)) 
    { 
        updateErrorData({
            ...errorData,
            is_form_valid:false,
            password_error: "New Password must contain [8 to 20 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit]"
          });
    } else{
        updateErrorData({
            ...errorData,
            is_form_valid:true,
            password_error: ""
          });
    }
}


const  __redirectLink = () =>{
  window.scrollTo(0,0)
  history.push("/login");
}

const removeErrorOnFocus = (e) =>{
  dispatch(resetPasswordAPI_Action())
  const {id,value} = e.target; 
    // var decimal=  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/;
    var decimal=  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;
    
    if(!value){
        updateErrorData({
            ...errorData,
            is_form_valid:false,
            password_error:''
          });
    }else if(!value.match(decimal)) 
    { 
        updateErrorData({
            ...errorData,
            is_form_valid:false,
            password_error:''
          });
    } else{
        updateErrorData({
            ...errorData,
            is_form_valid:true,
            password_error: ""
          });
    }
}



  return (
    <>
      <section className="loginform">
        <Container>
          <Row>
            <Col className="col-md-10 m-auto">
              <div className="heading-history">
                <div className="back-history">
                  <button
                    onClick={() => __redirectLink()}
                    type="button"
                    className="back-btn"
                  >
                    {" "}
                    <img src={LeftArrow} alt="arrow" />{" "}
                  </button>
                </div>
                <div className="history-title">
                  <h2>Reset password </h2>
                </div>
              </div>
              <div className="desciption">
                <p className="subdesc">
                  Please enter new password and click on Reset password.{" "}
                </p>
              </div>
              <div className="login-box resetpaaswd">
                <form action="post" onSubmit={__submitForgotPasswordForm}>
                  <div className="fields">
                    <label className="filed-label"> Email address </label>
                    

                    <div className="inputs">
                   
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={__onTextChange}
                        onKeyUp={checkEmailValidation}
                        autoComplete="off"
                        required 
                        minLength="6"
                        maxLength="50"
                        readOnly={true}
                        autoFocus
                      />
                        
                        <div className="error-box">
                            
                        {errorData.email_error &&
                            errorData.email_error != "" ? (
                              <small className="error">
                                <p style={{ color: "red" }}>
                                  {errorData.email_error}{" "}
                                </p>
                              </small>
                            ) : null}

                      {ResetPasswordData.resetpassworddata &&
                            ResetPasswordData.resetpassworddata.hasOwnProperty(
                              "errors"
                            ) ? (
                              <small className="error">
                                {ResetPasswordData.resetpassworddata.errors.hasOwnProperty(
                                  "email"
                                ) ? (
                                  <p style={{ color: "red" }}>
                                    {ResetPasswordData.resetpassworddata.errors.email[0]}{" "}
                                  </p>
                                ) : null}
                              </small>
                            ) : null}

                            {/* {ResetPasswordData.isError == true ? (
                            <small className="error ">
                                {ResetPasswordData.resetpassworddata.status_code === 422 ? (
                                <p style={{ color: "red" }}>
                                    {ResetPasswordData.resetpassworddata.message}
                                </p>
                                ) : null}
                            </small>
                            ) : null} */}
                    
                        </div>

                    </div>
                  </div>

                  <div className="fields">
                    <label className="filed-label">New Password </label>
                    <div className="inputs">
                   
                        <input
                        type={passwordType}
                        // value={passwordType}
                        id="password" 
                        name="password" 
                        value={formData.password} 
                        // onChange={(e) => setPasswordType(e.target.value)}
                        onChange={__onTextChange}
                        onBlur={checkNewPasswordValidation}
                        //onKeyUp={checkNewPasswordValidation}
                        onKeyDown={removeErrorOnFocus}
                        required 
                        minLength="8"
                        maxLength="20"
                        autocomplete="off"
                        
                      />
                       <span className="Iconview" onClick={__onChangePasswordToText}>
                        <img src={eyeIcon ? EyeView : EyeView} alt="icon" />
                      </span>
                    
                      <div className="error-box">
                      {errorData.password_error &&
                            errorData.password_error != "" ? (
                              <small className="error">
                                <p style={{ color: "red" }}>
                                  {errorData.password_error}{" "}
                                </p>
                              </small>
                            ) : null}

                      {/* {ResetPasswordData.resetpassworddata &&
                            ResetPasswordData.resetpassworddata.hasOwnProperty(
                              "errors"
                            ) ? (
                              <small className="error">
                                {ResetPasswordData.resetpassworddata.errors.hasOwnProperty(
                                  "password"
                                ) ? (
                                  <p style={{ color: "red" }}>
                                    {ResetPasswordData.resetpassworddata.errors.password[0]}{" "}
                                  </p>
                                ) : null}
                              </small>
                            ) : null} */}


                              
                            {ResetPasswordData.isError == true ? (
                            <small className="error ">
                                {ResetPasswordData.resetpassworddata.status_code===422 ? (
                                <p style={{ color: "red" }}>
                                    {ResetPasswordData.resetpassworddata.errors.password[0]}
                                </p>
                                ) : null}
                                
                                {ResetPasswordData.resetpassworddata.status_code===400 ? (
                                <p style={{ color: "red" }}>
                                    {ResetPasswordData.resetpassworddata.message="The link has already been used"}
                                </p>
                                ) : null}

                            </small>
                            ) : null}
                            </div>
                    </div>
                  </div>

                  <div className="alignButton">
                    <button type="submit" className="submit" 
                    disabled={
                      errorData.is_form_valid === true
                        ? false
                        : true
                    }
                    >
                      Reset password
                    </button>
                  </div>
                </form>

               


              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Modal show={show} className="in" onHide={handleClosePopup}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body> please check your mail id. we are send a changed password link. </Modal.Body>
        <Modal.Footer>
          
          <button variant="primary" onClick={__redirectconfirmation}>
            Ok
          </button>
        </Modal.Footer>
      </Modal>


    </>
  );
};

export default ResetPassword;
