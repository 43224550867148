import React, { useCallback, useState, useEffect } from "react";
import { useHistory,Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import { LeftArrow, reviewAvatar, iconFav, iconPhoto, star, icon_suitability, icon_reliability, icon_etiquette } from '../../config/images';
import BlogDetailCard from '../../components/BlogDetailCard';
import  NavigationBreadcrumbs from '../../components/NavigationBreadcrumbs';

//redux 
import { educationDetailAPI_Action,resetEducationDetailAPI_Action } from '../../stores/actions'

const EducationDetail = (props) => {
    let history = useHistory();
    let dispatch = useDispatch();
    const id=props.match.params.id;
    //console.log("id" + id);
    const educationDetailInfo = useSelector(state => state.educationdetail);
    const decsPtagRemove = educationDetailInfo.educationDetailsdata !== null ? educationDetailInfo.educationDetailsdata.data.blogDetails.blogDescription: "" ;
    //const content = decsPtagRemove.replace(/(<p>|<\/p>)/g, "");
    //const content = decsPtagRemove.replace(/<(.|\n)*?>/g, '');
    
   // console.log("decs" , educationDetailInfo.educationDetailsdata !== null && educationDetailInfo.educationDetailsdata.data.blogDetails.blogPartnerLink)

    const educationPartnerLink = educationDetailInfo.educationDetailsdata !== null ? educationDetailInfo.educationDetailsdata.data.blogDetails.blogPartnerLink:" " ;

     // Use effects
     useEffect(() => { 
            dispatch(educationDetailAPI_Action(id));
    },[])

 

    const __redirectToginipigPartner = () =>{
        window.scrollTo(0,0);
        history.push(educationPartnerLink);  
    }
    const backBtnPressed=()=>{
     //   console.log("back button presses");
        history.goBack();
    }

    const __detailsContent = () => {
        return(
            <div className="detail-wrp">
                <div className="b_heading flexbox_grid mb-24">
                    <h3 className="title">{educationDetailInfo.educationDetailsdata !== null ? educationDetailInfo.educationDetailsdata.data.blogDetails.blogTitle : '' }</h3>
                </div>
                <div className="details-decs">
                  <div dangerouslySetInnerHTML={{ __html: decsPtagRemove }} /> 
                    
                    <div className="wrp_content flexbox_grid mt-16">
                        <div className="detail-heading">
                            <h3>Interested in this education or course, head on over to partner’s site to get started</h3>
                        </div>

                        {
                             educationDetailInfo.educationDetailsdata !== null && educationDetailInfo.educationDetailsdata.data.blogDetails.blogPartnerLink &&educationDetailInfo.educationDetailsdata.data.blogDetails.blogPartnerLink!=="" &&

                             <div className="button-boxs">
                             <a className="list-item-div join_nowbtn" target="_blank" href={ educationDetailInfo.educationDetailsdata !== null && educationDetailInfo.educationDetailsdata.data.blogDetails.blogPartnerLink &&educationDetailInfo.educationDetailsdata.data.blogDetails.blogPartnerLink!=="" && educationDetailInfo.educationDetailsdata.data.blogDetails.blogPartnerLink }>
                             Join now 
                             </a>
                                
                         </div>

                        }


                    </div>

                </div>
            </div>



            );
    }


    return (
        <>

            <section className="bgcolor-gray common-section navline-position education_details_pg pb80">
                <Container>
                    <Row>
                        <Col className="col-lg-11 m-auto">
                            <NavigationBreadcrumbs 
                             onBackClick = {()=>{backBtnPressed()}}
                            />

                            <div className="boxwrp details requirement_wrp mt-24">
                                {
                                    educationDetailInfo &&
                                
                                    <BlogDetailCard 
                                    Img={educationDetailInfo.educationDetailsdata !== null ? educationDetailInfo.educationDetailsdata.data.blogDetails.blogImage : '' }
                                    >
                                            {__detailsContent()}
                                    </BlogDetailCard>
                                }
                            </div>

                        </Col> 
                    </Row> 
                </Container>
            </section>
        </>
    );
}

export default EducationDetail;