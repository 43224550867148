import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS,AUTH_HEADERS_NEW } from '../../config/ApiConstants';
export function acceptFailPaymentAPI_Action(data,token) {
    const formData = new FormData(); 

    
    formData.append('stripeToken', data.stripeToken);
    formData.append('stripeTokenType', data.stripeTokenType);
    formData.append('stripeEmail', data.stripeEmail);
    formData.append('booking_id', data.booking_id);
    formData.append('payment_type', data.intent_to_pay_status);
    formData.append('payment_failure_id', data.payment_failure_id);


    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/fail/payment/accept`,
            method: 'POST',
            headers: {
                ...AUTH_HEADERS_NEW,
                'Authorization':'Bearer '+token
            },
            // body:JSON.stringify(data),
            body:formData,
            types: [
                types.ACCEPTFAILPAYMENT_REQUEST,
                types.ACCEPTFAILPAYMENT_RECEIVE, {
                    type: types.ACCEPTFAILPAYMENT_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}



export const resetacceptFailPaymentAPI_Action = () => {
    return {
        type: types.RESET_ACCEPTFAILPAYMENT,
        payload: null
    }
};
