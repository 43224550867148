import React, { useState, useEffect } from 'react';
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { giniPigLogoWhite, heart, profileIcon } from '../../config/images';

// redux
import { logoutAPI_Action, resetUserAPI_Action,resetupcomingbookingAPI_Action, resetLoginAPI_Action,resetPractitionerAddInfoAPI_Action,resetPractitionerSignupAPI_Action,resetpractitionerSignupSaveAPI_Action,resetStripeAccountValidityAPI_Action } from '../../stores/actions'
import { Button, NavDropdown, Navbar, Nav, Form, FormControl } from 'react-bootstrap';
import { getLocalStorageData,setLocalStorageData,clearLocalStorage } from '../../utils/storage'
import moment from 'moment'



const Header = (props) => {
    const dispatch = useDispatch();
    
    const [toggleon, setToggleOn] = useState(false);
    const loginUserData = useSelector(state => state.login);
    const practitionersignupaddinfoData = useSelector(state => state.practitionersignupaddinfo);
    const registerData = useSelector(state => state.register);
    const Data = JSON.stringify(registerData);
    const [tokenlogin, setLoginToken] = useState('');
    // const loginToken = loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token : "" : "";
    let checkToken="";
    const loginToken=loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token: "" : "";
    const PractitionerToken= practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.token:"" : "";
    const RegisterToken= registerData.registerdata ? registerData.registerdata.data ? registerData.registerdata.data.token:"" : "";
    let loginTokenData=getLocalStorageData("loginData");
   
    var datasend = {  data:{
            keywords:'hair',location:"Any location", postcode:"", postcodeValue:"",country:"",city:"",latitude:"", longitude:"",from_header:'from_header',            
            date:'Any date'
        }
    }
    var datasendBeauty= {  data:{
        keywords:'Beauty',location:"Any location", postcode:"", postcodeValue:"",country:"",city:"",latitude:"", longitude:"",from_header:'from_header',
        date:'Any date'
    }
    }
    var datasendAesthetic= {  data:{
        keywords:'Aesthetic',location:"Any location", postcode:"", postcodeValue:"",country:"",city:"",latitude:"", longitude:"",from_header:'from_header',
        date:'Any date'
    }
    }
    var datasendWellness= {  data:{
        keywords:'Wellness',location:"Any location", postcode:"", postcodeValue:"",country:"",city:"",latitude:"", longitude:"",from_header:'from_header',
        date:'Any date'
    }
    }

    if(loginToken != "")
    {
      checkToken=loginToken;
    } else if(PractitionerToken != "")
    {
      checkToken=PractitionerToken;
    }  else if(RegisterToken != "")
    {
      checkToken=RegisterToken;
    }

   // console.log("header login ", loginToken)

    // useEffect(() => {
    //     if(loginTokenData){
    //         if(loginTokenData != null || loginTokenData != undefined){
    //           setLoginToken(loginTokenData.token)
    //         }
    //       }    
    //   }, []);

    let history = useHistory();
    const redirectUrl = (e) => {
        let type="";
        const loginType=loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.user.type: "" : "";
       
          const PractitionerType= practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.user.type:"" : "";
         
          const RegisterToken= registerData.registerdata ? registerData.registerdata.data ? registerData.registerdata.data.user.type:"" : "";
        
       // console.log("loginTokenData inside ",JSON.stringify(loginTokenData))
          if(loginType!="")
        {
          type=loginType;
        } else if(PractitionerType!= "")
        {
          type=PractitionerType;
        } else if(RegisterToken!="")
        {
          type=RegisterToken;
        }
        
        if(loginTokenData.user.type === 'ginipig')
        {
            window.scrollTo(0, 0);
            history.push(e);
        } else if(loginTokenData.user.type === 'practitioner'){
            dispatch(resetpractitionerSignupSaveAPI_Action());
            window.scrollTo(0, 0);
            dispatch(resetupcomingbookingAPI_Action());
            history.push('/practitioner-account');
            
        } else {
            dispatch(resetpractitionerSignupSaveAPI_Action());
            window.scrollTo(0, 0);
            history.push(e);
        }
        
        // if(loginUserData.logindata !== null && loginUserData.logindata.data.user.type === 'ginipig')
        // {
        //     history.push(e);
        // } else {
        // // if(loginUserData.logindata.data.addinfo_status === true)
        // // {
        // //     history.push('/practitioner-account');
        // // } else {
        // //     history.push('/practitioner-add-info');
        // // }
        // }
        
    }


    const logoutUser = async (e) => {

        try {
            await dispatch(logoutAPI_Action(checkToken));
            await dispatch(resetLoginAPI_Action());
            await dispatch(resetUserAPI_Action());
            await dispatch(resetPractitionerAddInfoAPI_Action());
            await dispatch(resetPractitionerSignupAPI_Action());
            await dispatch(resetStripeAccountValidityAPI_Action());
            
            history.push('/login');
        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    }


    const __toggleMobileMenu = (e) => {
        setToggleOn(!toggleon);
        window.scrollTo(0, 0);
    }

    const __redirectStatiscPage = (e) => {
        dispatch(resetpractitionerSignupSaveAPI_Action());
        if(e === 'Hair' || e === 'Beauty' || e === 'Aesthetic' || e === 'Wellness'){
        // var date = [startDate.getFullYear(), ("0" + (startDate.getMonth() + 1)).slice(-2), ("0" + startDate.getDate()).slice(-2)].join("-")    
        // var data = {
        //     keywords:e,
        //     location:"",
        //     postcode:"",
        //     postcodeValue:"",
        //     country:"",
        //     city:"",
        //     latitude:"",
        //     longitude:"",
        //     from_header:'from_header'
        // }

        //console.log("post data ", JSON.stringify(data))
        //history.push('/search-result/'+JSON.stringify(data)+'/'+date);
        }else{
            history.push(e);
        }
        
        
        //history.push('/start-survey/' + baseProposal.proposalSlug, { data: data })
        window.scrollTo(0, 0);
    }

    const __redirectHomePage = () => {
        window.scrollTo(0, 0);
        history.push('/')
    }

    return (

        <header className="header">
            <div className="header-wrp">
                <div className="menubox">
                    <div className="header-brand">
                        <Link 
                        onClick={() => {
                            __redirectHomePage()
                        }}
                        to={{ 
                            pathname: "/",  
                           }}
                        ><img src={giniPigLogoWhite} /></Link>
                    </div>
                    <div className="button-toggle" >
                        <div className={`hamburger-btn ${!toggleon ? 'closes' : 'open'}`} onClick={__toggleMobileMenu}>
                            <div className="hamburger"></div>
                        </div>
                    </div>
                </div>
                <div className={`header-menulist ${!toggleon ? 'menuclose' : 'menuopen'} `}>
                    <ul className="menu">
                        <li className="nav-item">
                            <Link 
                            onClick={() => {
                                __toggleMobileMenu()
                              //  __redirectStatiscPage('Hair')
                            }}                             
                             to={{ 
                                pathname: "/search-result", 
                                state: JSON.stringify(datasend.data) 
                               }}
                            >Hair</Link>
                        </li>
                        <li className="nav-item">
                            <Link onClick={() => {
                                __toggleMobileMenu()
                               // __redirectStatiscPage('Beauty')
                            }} 
                            to={{ 
                                pathname: "/search-result", 
                                state: JSON.stringify(datasendBeauty.data) 
                               }}
                            >Beauty</Link>
                        </li>
                        <li className="nav-item">
                            <Link onClick={() => {
                                __toggleMobileMenu()
                                //__redirectStatiscPage('Aesthetic')
                            }} 
                            to={{ 
                                pathname: "/search-result", 
                                state: JSON.stringify(datasendAesthetic.data) 
                               }}
                            // to="/Aesthetic"
                            >Aesthetics</Link>
                        </li>
                        <li className="nav-item">
                            <Link onClick={() => {
                                __toggleMobileMenu()
                                //__redirectStatiscPage('Wellness')
                            }} 
                            to={{ 
                                pathname: "/search-result", 
                                state: JSON.stringify(datasendWellness.data) 
                               }}
                            >Wellness</Link>
                        </li>
                      {props.educationpageenable?
                            <li className="nav-item">
                            <span onClick={() => {
                                __toggleMobileMenu()
                                __redirectStatiscPage('/Education')
                            }} 
                            // to="/Education"
                             >Education</span>
                        </li>:null
                      }
                        <li className="nav-item">
                            <span onClick={() => {
                                __toggleMobileMenu()
                                __redirectStatiscPage('/Blog')
                            }} 
                            // to="/Blog"
                            >Blog</span>
                        </li>
                    </ul>
                    <ul className="sidebar-menu">

                        
                           
                           {
                               
                            loginTokenData === undefined ? (
                                <li className="item">
                                    <Link onClick={() => {
                                                setLocalStorageData('tabkey','favourites');
                                                __toggleMobileMenu()
                                                __redirectStatiscPage('/login')
                                            }}  
                                    className="awesome-menu">
                                        <img src={heart} />
                                        <span className="login_text fav1">Favourites</span>
                                    </Link>
                                </li>
                            ) : <>
                                {
                                    (loginTokenData.user.type === 'practitioner')?
                                     ( <li className="item display-none">
                                        </li> 
                                     ):(
                                        <li className="item">
                                            <Link onClick={() => {
                                                setLocalStorageData('tabkey','favourites');
                                                
                                                __redirectStatiscPage('/ginipig-profile')
                                                __toggleMobileMenu()
                                            }}  
                                            className="awesome-menu">
                                                <img src={heart} />
                                                <span className="login_text fav1">Favourites</span>
                                            </Link>
                                        </li>
                                    )

                                }
                            </>

                                // <Link onClick={() => {
                                //     __toggleMobileMenu()
                                //     __redirectStatiscPage('/ginipig-profile')
                                // }}  
                                // className="awesome-menu">
                                //     <img src={heart} />
                                //     <span className="login_text fav1">Favourites</span>
                                // </Link>
                            

                           }
                           
                           



                        
                        <li className="item">
                        {/* {console.log("after ",loginTokenData , ' ',loginTokenData)} */}
                        {
                            
                            loginTokenData === undefined ? 
                            (
                                
                                    <Link  onClick={() => {
                                        window.scrollTo(0, 0);
                                        setLocalStorageData('tabkey','upcoming');
                                        __toggleMobileMenu()
                                        __redirectStatiscPage('/login')

                                    }} className="login-menu">
                                        <img src={profileIcon} />
                                        <span className="login_text ac1">Account</span>
                                    </Link>   
                                
                            ) : (
                                
                                    <Link onClick={() => {
                                        window.scrollTo(0, 0);
                                        setLocalStorageData('tabkey','upcoming');
                                        __toggleMobileMenu()
                                        redirectUrl('/ginipig-profile')
                                        
                                   }} className="login-menu">
                                        <img src={profileIcon} />
                                        <span className="login_text ac1">Account</span>
                                    </Link>   
                                
                            )

                        }
                        </li>
                    </ul>
                </div>
            </div>

        </header>


    );
};

export default Header;