import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Container, Row, Col } from "react-bootstrap";
import { LeftArrow } from "../../config/images";
import { getLocalStorageData,setLocalStorageData } from '../../utils/storage';

const PratitionerStripeRegistrationSuccess = (props)=>{
    const loginUserData = useSelector((state) => state.login);
  const registerData = useSelector(state => state.register);
  const practitionersignupaddinfoData = useSelector(state => state.practitionersignupaddinfo);
  const loginToken = loginUserData.logindata
    ? loginUserData.logindata.data
      ? loginUserData.logindata.data.token
      : ""
    : "";
   
  let history = useHistory();

  useEffect(() => {
    let temp = props.location.search;
    temp = temp.split("&");
    setLocalStorageData("stripeCode",temp[0].replace("?code=",""));
    window.opener.location.reload();
    window.close();
  },[]);

  return (
    <>
      <section className="bgcolor-gray common-section">
        <Container>
          <Row>
            <Col className="col-md-10 m-auto">
              <div className="heading-history">
                <div className="back-history">
                  <button
                    type="button"
                    onClick={() => history.goBack()}
                    className="back-btn"
                  >
                    {" "}
                    <img src={LeftArrow} alt="arrow" />{" "}
                  </button>
                </div>
                <div className="history-title">
                  <h2>Register Done</h2>
                </div>
              </div>
              <div className="desciption">
                <p className="subdesc">
                  Your registration done successfully.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default PratitionerStripeRegistrationSuccess;