import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Modal } from "react-bootstrap";
import { LeftArrow } from "../../config/images";
import { useHistory } from "react-router-dom";

// redux
import { forgotpasswordAPI_Action, resetForgotPasswordAPI_Action } from "../../stores/actions";
const initialErrorData = Object.freeze({
  email_error: "",
  is_form_valid: false,
});

const ForgotPassword = (props) => {
  //hooks state
  const [show, setShow] = useState(false);
  const handleClosePopup = () => setShow(false);
  const handleShowPopup = () => setShow(true);
  const [showError, SetShowError] = useState(false);

  const __redirectconfirmation = () => {
    handleClosePopup();
    window.scrollTo(0, 0);
    history.push('/ForgotPasswordConfirmation');
  }


  // Initialize hooks first
  const dispatch = useDispatch();
  let history = useHistory();
  // States Management
  const [formData, setFormData] = useState(
    Object.freeze({
      email: "",
    })
  );
  const [errorData, updateErrorData] = useState(initialErrorData);
  // Selectors
  const ForgotPasswordData = useSelector((state) => state.forgotpassword);
  const Data = JSON.stringify(ForgotPasswordData);


  if (ForgotPasswordData.isSuccess === true) {
    //console.log('into its success');
    window.scrollTo(0, 0);
    history.push('/ForgotPasswordConfirmation');
  }

  // Use effects
  useEffect(() => {
    if (ForgotPasswordData && ForgotPasswordData.forgotpassworddata && ForgotPasswordData.forgotpassworddata.status_code === 401) {
      SetShowError(ForgotPasswordData.forgotpassworddata.message)
    }
  });

  // Use effects
  useEffect(() => {
    if (
      ForgotPasswordData.forgotpassword &&
      ForgotPasswordData.forgotpassword.status_code === 200
    ) {
      window.scrollTo(0, 0);
      history.push("/login");
    }
  });

  const __onTextChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  const checkEmailValidation = async (e) => {
    dispatch(resetForgotPasswordAPI_Action())
    const { id, value } = e.target;

    if (!value) {
      updateErrorData({
        ...errorData,
        is_form_valid: false,
        email_error: "Email is Required.",
      });
    } else {
      updateErrorData({
        ...errorData,
        is_form_valid: true,
        email_error: "",
      });
    }

    if (typeof value !== "undefined") {
      let lastAtPos = value.lastIndexOf("@");
      let lastDotPos = value.lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          value.indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          value.length - lastDotPos > 2
        )
      ) {
        if (!value) {
          updateErrorData({
            ...errorData,
            is_form_valid: false,
            email_error: "Email is Required.",
          });
        } else {
          updateErrorData({
            ...errorData,
            is_form_valid: false,
            email_error: "Email is not valid.",
          });
        }
      } else {
        updateErrorData({
          ...errorData,
          is_form_valid: true,
          email_error: "",
        });
      }
    } else if (value.length > 50) {
      updateErrorData({
        ...errorData,
        is_form_valid: false,
        email_error: "Only 50 Characters are Allowed.",
      });
    } else if (value.length < 6) {
      updateErrorData({
        ...errorData,
        is_form_valid: false,
        email_error: "Minimum 6 Characters are Allowed.",
      });
    } else {
      updateErrorData({
        ...errorData,
        is_form_valid: true,
        email_error: "",
      });
    }

    // if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(value)) {
    //     console.log("true");
    //     updateErrorData({
    //         ...errorData,
    //         email_error: ""
    //       });
    // }
    // else{

    //   updateErrorData({
    //     ...errorData,
    //     email_error: "Please Enter Correct Email:"
    //   });
    // }
    // console.log("Email Error: ",this.state.email_error);
  };

  useEffect(() => {
    updateErrorData({
      ...errorData,
      is_form_valid: true,
      email_error: "",
    });
    dispatch(resetForgotPasswordAPI_Action())
  }, []);

  const __apiCall = useCallback(async () => {
    try {
      await dispatch(forgotpasswordAPI_Action(formData));
      //console.log('respons return')
      if (ForgotPasswordData.isSuccess === true) {
        handleShowPopup();
      }
    } catch (error) {
      console.error(" profile ::  profile() :: Exception ==", error);
    }
  });

  const __submitForgotPasswordForm = (e) => {
    e.preventDefault();
    __apiCall();

  };

  return (
    <>
      <section className="loginform">
        <Container>
          <Row>
            <Col className="col-md-10 m-auto">
              <div className="heading-history">
                <div className="back-history">
                  <button
                    onClick={() => history.goBack()}
                    type="button"
                    className="back-btn"
                  >
                    {" "}
                    <img src={LeftArrow} alt="arrow" />{" "}
                  </button>
                </div>
                <div className="history-title">
                  <h2>Forgotten password </h2>
                </div>
              </div>
              <div className="desciption">
                <p className="subdesc">
                  Please enter the email address below for the account you
                  signed up with. If we have an account under this email address
                  we will reset your password{" "}
                </p>
              </div>
              <div className="login-box forgetpasswd">
                <form action="post" onSubmit={__submitForgotPasswordForm}>
                  <div className="fields">
                    <label className="filed-label"> Email address </label>
                    <div className="inputs">
                      <small className="error">
                        <p style={{ color: "red" }}>{showError}</p></small>
                      {errorData.email_error &&
                        errorData.email_error != "" ? (
                        <small className="error">
                          <p style={{ color: "red" }}>
                            {errorData.email_error}{" "}
                          </p>
                        </small>
                      ) : null}

                      {ForgotPasswordData.forgotpassword && !ForgotPasswordData.isError &&
                        ForgotPasswordData.forgotpassword.hasOwnProperty(
                          "errors"
                        ) ? (
                        <small className="error">
                          {ForgotPasswordData.forgotpassword.errors.hasOwnProperty(
                            "email"
                          ) ? (
                            <p style={{ color: "red" }}>
                              {ForgotPasswordData.forgotpassword.errors.email[0]}{" "}
                            </p>
                          ) : null}
                        </small>
                      ) : null}

                      {/* {ForgotPasswordData.isError === true && !(ForgotPasswordData.forgotpassword && !ForgotPasswordData.isError &&
                        ForgotPasswordData.forgotpassword.hasOwnProperty(
                          "errors"
                        ))? (
                        <small className="error ">
                          <p style={{ color: "red" }}>
                            {ForgotPasswordData.forgotpassworddata.message}
                          </p>
                        </small>
                      ) : null} */}


                      <input
                        type="email"
                        id="email"
                        name="email"
                        onChange={__onTextChange}
                        // onKeyUp={checkEmailValidation}
                        onBlur={checkEmailValidation}

                      />
                    </div>
                  </div>
                  <div className="alignButton">
                    <button type="submit" className="submit"
                      disabled={
                        errorData.is_form_valid === true
                          ? false
                          : true
                      }
                    >
                      Reset password
                    </button>
                  </div>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Modal show={show} className="in" onHide={handleClosePopup}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body> please check your mail id. we are send a changed password link. </Modal.Body>
        <Modal.Footer>

          <button variant="primary" onClick={__redirectconfirmation}>
            Ok
          </button>
        </Modal.Footer>
      </Modal>


    </>
  );
};

export default ForgotPassword;
