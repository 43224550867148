import React from 'react';
import { Link } from 'react-router-dom';
import { star, avatar, reviewAvatar, icon_suitability, icon_reliability, icon_etiquette,ginipic_avtar } from '../../config/images';
import TransparentButton from "../../components/UIComponent/TransparentButton";
import { S3_BUCKET_LINK } from '../../config/ApiConstants'
const OpenRequestCard = (props) => {

    return (
        <>

            <div className="grids_nobox openreq_card mt-16">
                <div className="one-div" onClick={props.handleBookingClick}>
                    <div className="upcoming_cards  ">
                        <div className="pad8 upside_content ">
                            <div className="content_box">
                                <div className="categorylist-box">
                                    <div className="list_name">{props.Title}</div>
                                </div>

                                <div className="right_arrow" onClick={props.arrowButtonClick}>
                                    <img src={props.imgArrow} className="arrow_icon" />
                                </div>

                            </div>
                        </div>
                        <div className={props.status === "cancel" ? "display-none" : "pad8 downside_content border_top"}>
                            <div className="down_content">
                                <div className="location-text">
                                    <span>{props.addressText!==null && props.addressText!==undefined ?props.addressText:"Online"}</span>
                                </div>
                                <div className="timing_box">
                                    <span>{props.slotTime}</span>
                                </div>
                                <div className="username_box middle">
                                    <div className="user_avatar">
                                        {
                                            props.reviewAvatar !== null ? props.reviewAvatar !== undefined ? (

                                                <img className="img_avtar" src={S3_BUCKET_LINK + props.reviewAvatar} />

                                            ) :  <img src={ginipic_avtar} className="img_avtar" /> : <img src={ginipic_avtar} className="img_avtar" />
                                        }


                                    </div>
                                    <div className="user_text">
                                        <span>{props.userName}</span>

                                        <div className="ratingbox">
                                            <img src={props.ratingImg} alt="raing icon" />
                                            <span className="rating-point">({props.ratingPoint})</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default OpenRequestCard;