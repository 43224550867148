import React, { useCallback,useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory,Link } from "react-router-dom";
import { Container,Row,Col } from 'react-bootstrap';
import {LeftArrow,profileIcon} from '../../config/images';


// redux
import { userdetailsAPI_Action,userprofileAPI_Action,resetUserAPI_Action } from '../../stores/actions'

const UserProfileUpdate = (props) => {

  // Initialize hooks first
  const dispatch = useDispatch();
  let history = useHistory();

  // States Management
  const [formData, setFormData] = useState(Object.freeze({
    first_name:"",
    last_name:"",
    email: "",
    username:"",
    profile_pic:"",
    mobile:"",
  }));

   // Selectors
  const userData = useSelector(state => state.userdetails);
  // const userProfileData = useSelector(state => state.usserprofile);
  const loginUserData = useSelector(state => state.login);
  const registerData = useSelector(state => state.register);
  const Data = JSON.stringify(registerData);
  
   // Use effects Similar to componentDidMount and componentDidUpdate:
   useEffect(() => {
    if(userData.userdetailsdata!=null)
      {
        setFormData({
          ...formData,
          first_name:userData.userdetailsdata.data.user_details.first_name,
          last_name:userData.userdetailsdata.data.user_details.last_name,
          username:userData.userdetailsdata.data.user_details.username,
          email:userData.userdetailsdata.data.user_details.email,
          mobile:userData.userdetailsdata.data.user_details.telephone_no,
          profile_pic:userData.userdetailsdata.data.user_details.profile_pic,
      
      
        });

      } else {
        try {
          const loginToken=loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token: "" : "";
          if(loginToken!=='')
          {
             dispatch(userdetailsAPI_Action(loginToken));
          }
          
        } catch (error) {
          console.error(" profile ::  profile() :: Exception ==", error);
        }
      }
  }, [userData]);
 

 const __onTextChange = (e) => {
  setFormData({
    ...formData,
    [e.target.name]: e.target.value.trim()
  });
};

const __onImageChange = e => {
    setFormData({
        ...formData,
        [e.target.name]: e.target.files[0],
      });
  }

  // states
  
  const __apiCall = useCallback(async () => {
    try {
      const loginToken=loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token: "" : "";
      await dispatch(userprofileAPI_Action(formData,loginToken));
      await dispatch(resetUserAPI_Action());
      window.scrollTo(0, 0);
      history.push('/user-details');
    } catch (error) {
      console.error(" profile ::  profile() :: Exception ==", error);
    }
  });

  const __fetchapiCall = useCallback(async () => {
    try {
      const loginToken=loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token: "" : "";
      if(loginToken!=='')
      {
        await dispatch(userdetailsAPI_Action(loginToken));
      }
    } catch (error) {
      console.error(" profile ::  profile() :: Exception ==", error);
    }
  });


const __submitUserProfileForm = (e) => {
  e.preventDefault();
  __apiCall();
};

  return (
    <>
    <section className="bgcolor-gray profile-box">
        <Container>
            <Row>
                <Col className="col-lg-10 m-auto">
                <div className="heading-history">
                    <div className="back-history">
                    <button type="button" className="back-btn">
                        {" "}
                        <img src={LeftArrow} alt="arrow" />{" "}
                    </button>
                    </div>
                    <div className="history-title">
                    <h2> Edit Your Information  </h2>
                    </div>
                </div>
                {/* {
        userData.isFetching ? <button >Loading</button> : <button onClick={__fetchapiCall}>API Call</button>
      } */}
                <div className="profilebox">
                            <div className="inside-profile">
                                <div className="align-left">
                                    <form className="fromProfile profile-wrp" onSubmit={__submitUserProfileForm} >
                                        <div className="profilefield">
                                            <label className="profile-label">First Name</label>
                                            <input 
                                                type="text" 
                                                id="first_name" 
                                                name="first_name" 
                                                value={formData.first_name} 
                                                onChange={__onTextChange} 
                                            />
                                        </div>
                                        <div className="profilefield">
                                            <label className="profile-label">Last Name</label>
                                            <input 
                                                type="text" 
                                                id="last_name" 
                                                name="last_name" 
                                                value={formData.last_name} 
                                                onChange={__onTextChange} 
                                            />
                                        </div>
                                        <div className="profilefield">
                                            <label className="profile-label">Contact number</label>
                                            <input 
                                                type="number"  
                                                id="mobile" 
                                                name="mobile" 
                                                value={formData.mobile} 
                                                onChange={__onTextChange} 
                                            />
                                        </div>
                                        <div className="profilefield">
                                            <label className="profile-label"> Email Address </label>
                                            <input 
                                                type="email"  
                                                id="email" 
                                                name="email" 
                                                value={formData.email} 
                                                onChange={__onTextChange} 
                                            />
                                        </div>
                                    </form>
                                    <div>
              {/* <code>
              {
                  JSON.stringify(registerData.isError)===true ? JSON.stringify(registerData.registerdata.message):""
              }
            </code>
            <label>{JSON.stringify(registerData.isError)===true ? Data.registerdata.message : ""}</label>
            <code>
              {
                  JSON.stringify(registerData)
              }
            </code> */}
          </div>
                                    <div className="profile-wrp media-profile">
                                        <div className="inside-media">
                                            <div className="media-img">
                                                <img src={profileIcon} alt="avtar" />
                                            </div>
                                            <div className="media-content">
                                                <div className="media-decs">
                                                    <h3> Profile Picture </h3>  
                                                    <p> React Hook useCallback does nothing when called with only one argument. 
                                                        Did you forget to pass an array of dependencies </p>
                                                </div>
                                                <div className="media-btns">
                                                    <input 
                                                      type="file"  
                                                      id="profile_pic" 
                                                      name="profile_pic" 
                                                      onChange={__onImageChange}
                                                    />
                                                    <Link to="" className="upload-profile"> Upload New</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="align-right">
                                </div>
                            </div> 

                            <div className="Profilebtn">
                                    <div className="Profilebtn-box">
                                        <Link to="" className="passwd-btn">Change Password</Link>
                                        <Link to="" className="save-btn"> Save Changes </Link>
                                    </div>
                            </div>

                        </div> 
                        
                        </Col>
                    </Row>
                </Container>
            </section>   
        </>



            

  );
};

export default UserProfileUpdate;