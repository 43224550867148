import React , {useEffect,useState} from 'react';
import {useHistory} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {Container,Row,Col} from 'react-bootstrap';
import {LeftArrow,reviewAvatar,star,iconPhoto, iconCase_Study,iconNearly_there,iconGiniPigs_Required} from '../../config/images';
import ThumbnailViewDetail from '../../components/ThumbnailViewDetail';
import UserProfile from '../../components/UserProfile';
import LargeButton from '../../components/LargeButton';
import { resetdeleterequestAPI_Action,resetupcomingbookingAPI_Action } from '../../stores/actions'

const EnquiryThankYou = () =>{
    //state init
    let history = useHistory();
    const dispatch = useDispatch();

     // Selectors
//   const userData = useSelector(state => state.userdetails);        
//   const loginUserData = useSelector(state => state.login);
//   const registerData = useSelector(state => state.register);
//   const Data = JSON.stringify(registerData);
    
  // Use effects
//   useEffect(() => {   
    
//     try {
//         let checkToken="";
//         const loginToken=loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token: "" : "";
//         // const PractitionerToken= practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.token:"" : "";
        // const RegisterToken= registerData.registerdata ? registerData.registerdata.data ? registerData.registerdata.data.token:"" : "";
        // if(loginToken != "")
        // {
        //     checkToken=loginToken;
        // }  else if(RegisterToken != "")
        // {
        //     checkToken=RegisterToken;
        // }
        // // const loginToken=loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token: "" : "";
//         if(checkToken==='')
//         {
//             history.push('/login');
//         }
        
//       } catch (error) {
//         console.error(" profile ::  profile() :: Exception ==", error);
//       }
    
//   },[])
    
    const __redirectProfilePg = () =>{
        window.scrollTo(0,0);
        dispatch(resetupcomingbookingAPI_Action());
        history.push('/practitioner-account')   
    }

    


    return (
        <>
            <section className="bgcolor-gray common-section appointment_confimation_pg  pb80">
                <Container>
                    <Row>
                        <Col className="col-lg-11 m-auto">
                            <div className="from-wrp mt-40">
                                <div className="appoint_conf_box pb-32 pt-70">
                                    <div className="heading_content">
                                        <h2 className="title "> Thank you </h2>
                                        <p className="decs mb-32 mt-24">Your enquiry has been sent. You will an receive a notification from the Treatment/Service provider  in the next 24 hours to confirm your booking. </p>
                                        <p className="decs mb-24 text-left">Your Enquiry</p>
                                    </div>
                                </div>

                                <div className="payment_box mt-24 mb-70">
                                  <ThumbnailViewDetail 
                                      treatmentTitle="Wash, cut & blow dry"
                                      currencySign="£"
                                      originalPriceText="25"
                                      discountPriceText="20"
                                      tagsName="Hair"
                                      duration="2.5 hours"
                                      categoryIcon1={iconCase_Study}
                                      categorName1="Case Study"
                                      categoryIcon2={iconNearly_there}
                                      categorName2=" Nearly there"
                                      categoryIcon3={iconGiniPigs_Required}
                                      categorName3="8 GiniPigs required"
                                      buttonRequiestTime="1st June at 18:30"
                                      buttonReqAddress="NW6, London"
                                  />
                                </div>  

                                
                            <div className="thank_user_box mt-40">
                                <UserProfile 
                                    userImg={reviewAvatar}
                                    userName="Imogen Sandford"
                                    ratingImg={star}
                                    ratingPoint="5.0"
                                    address="NW6 London"
                                    tagsTitle="Other available appointments"
                                    tagName="Hair"
                                    count="2"
                                />
                            </div>

                                <div className="btn-align mt-24 pd32">
                                    <LargeButton
                                        ButtonType="button"
                                        ButtonText="Back to search"
                                    />
                                </div>

                            </div>
                        </Col>
                    </Row>
                    
                </Container>
            </section>
        </>
    );
}

export default EnquiryThankYou;