import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS } from '../../config/ApiConstants';
export function editginipicAPI_Action(data, token) {
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/request/edit-quantity`,
            method: 'POST',
            headers: {

                ...AUTH_HEADERS,
                'Authorization': 'Bearer ' + token
            },
         
            body: JSON.stringify(data),
            types: [
                types.EDITGINIPIC_REQUEST,
                types.EDITGINIPIC_RECEIVE, {
                    type: types.EDITGINIPIC_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}


export const reseteditginipicAPI_Action = () => {
    return {
        type: types.RESET_EDITGINIPIC,
        payload: null
    }
};
