import React, { useState,useEffect } from 'react';
import { Container, Row, Col, Accordion, Card, useAccordionToggle } from 'react-bootstrap';
import { practitioner_avtar, lpimg1, star, Aesthetic, Beauty,Hair,wellness } from '../../config/images';
import { S3_BUCKET_LINK } from '../../config/ApiConstants';
import { formatPostcode } from '../../utils/utility';
import moment from 'moment';


const TreatmentsListing = (props) => {
   // console.log("props: ", JSON.stringify(props));
    const bookings_count = props.content.bookings_count != undefined ? props.content.bookings_count : 0;
  
    var number_suf =['1st', '2nd', '3rd', '4th', '5th', '6th', '7th', '8th', '9th', '10th',
    '11th', '12th', '13th', '14th', '15th', '16th', '17th', '18th', '19th',
    '20th', '21st', '22nd', '23rd', '24th', '25th', '26th', '27th', '28th',
    '29th', '30th', '31st']
    var only_date = new Date(props.content.request_timeslots[0].date_time).getDate()  ;
    if(new Date(props.content.request_timeslots[0].date_time).getDate() != undefined){   
        for(var i=0;i<number_suf.length;i++){
            if(i+1 === only_date){
                only_date = number_suf[i]  
            }
        }
        //console.log('only_date ', only_date, ' ', number_suf.length, ' dd ', new Date(props.content.request_timeslots[0].date_time).getDate())
    }

    var time = '';

    let period = ""
    let hours = ''
    let [sHours, minutes] = ""
    if (props.content.request_timeslots[0].start_time !== null) {
        //[sHours, minutes] = props.content.request_timeslots[0].start_time && props.content.request_timeslots[0].start_time.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
        [sHours, minutes] = props.content.request_timeslots[0].start_time && props.content.request_timeslots[0].start_time.match(/([01][0-9]|2[0-3]):([0-5][0-9])/).slice(1);
        period = +sHours < 12 ? 'am' : 'pm';
        hours = +sHours % 12 || 12;
    }

    let timein24hrs=""
    if (props.content.request_timeslots[0].start_time !== null && props.content.request_timeslots[0].start_time!==undefined) {
         timein24hrs=props.content.request_timeslots[0].start_time.slice(0,5)
    }
   
  
 
    let dateFormat = moment(props.content.request_timeslots[0].date_time).format("Do MMMM YYYY")
    var set_date =  dateFormat + " @ " + timein24hrs
    
    let dates = moment(props.content.request_timeslots[0].date_time).format("Do MMM");
    let timeslot = moment(props.content.request_timeslots[0].start_time, "HH:mm").format("hh:mm A")

    //var set_date = dates + ' at ' + time;

    // let miles = 0;
    // let unit = "K";
    // if ((props.latitide == props.content.postcodes.latitude) && (props.longitude == props.content.postcodes.longitude)) {
    //     miles = 0;
    // }
    // else {
    //     var radlat1 = Math.PI * props.latitide / 180;
    //     var radlat2 = Math.PI * props.content.postcodes.latitude / 180;
    //     var theta = props.longitude - props.content.postcodes.longitude;
    //     var radtheta = Math.PI * theta / 180;
    //     var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    //     if (dist > 1) {
    //         dist = 1;
    //     }
    //     dist = Math.acos(dist);
    //     dist = dist * 180 / Math.PI;
    //     dist = dist * 60 * 1.1515;
    //     // if (unit=="K") { dist = dist * 1.609344 }
    //     // if (unit=="N") { dist = dist * 0.8684 }
    //     // // return dist;
    //     // var miles = kilometers / 1.6;
    //     miles = Math.round(dist);
    // }
    var rrp = props.content.rrp_price.toFixed(2);
    var your_price = props.content.your_price.toFixed(2);


    const scrollPgReDirectPage = (e) =>{
        window.scrollTo(0, 0);
    }

    useEffect(() => {
      scrollPgReDirectPage()
    }, [])

    return (
        <>
            <div>
            {
                props.content.treatment != null ?
            
                <div className="main-wrapper" disabled={bookings_count === 0 ? false : true}>                               
                    {
                        props.search_location != '' && props.search_location != 'Any location' ?
                        // <p className="distance-remain">({miles} miles) </p> : null
                        <p className="distance-remain">({props.content.miles} miles) </p> : null
                    }
                    <div className="div-wrap mb-24">
                            <div  className="image-div" onClick={props.onNextPress}  disabled={bookings_count === 0 ? false : true}>
                                {/* <img className="tratment-image" src={props.content.treatment_images[0].image_link}/> */}
                                <img className="tratment-image" src={props.content.request_images.length > 0 ? props.content.request_images[0].image_link : props.content.treatment.categories.name === 'Hair' ? Hair : props.content.treatment.categories.name === 'Beauty'? Beauty : props.content.treatment.categories.name === 'Aesthetics' ? Aesthetic : props.content.treatment.categories.name === 'Wellness' ? wellness : null}/>
                            </div>
                            <div className="display-div">
                            <div className="display-inside-div">
                            <div className="inside-content"  onClick={props.onNextPress} disabled={bookings_count === 0 ? false : true}>
                                <div className="categorylist-box">
                                    <div className="list_name">{props.content.treatment.title}</div>
                                    {/* {
                                        props.content.user.practitioner_category_apply!==undefined && props.content.user.practitioner_category_apply==="Mobile"?
                                        <div className="category_badge mt-category-badge">
                                        <span className="category_badge_name">{props.content.user.practitioner_category_apply}</span>
                                        </div>:null
                                    } */}
                                   
                                    <div className="category_price">
                                        <span className="price_strik og_price"> <span>£</span>{rrp}</span>
                                        <span className="price_discounted"><span>£</span>{your_price}</span>
                                    </div>
                                </div>
                                <div className="category_badge">
                                        <span className="category_badge_name">{props.content.treatment.categories.name}</span>   
                                        <span className="category_badge_hrs">{props.content.hours>0? props.content.hours+"h":""} {props.content.minutes>0? props.content.minutes+"m":""}</span>
                                       
                                </div>
                                <div>
                                    <p className="desciption">{props.content.treatment.description}</p>
                                </div>
                                <div className="list-experiment mb-24">
                                    <div className="exp_list_item mt-13">
                                        <span className="icons_port icon_names icon-size">{props.content.reason_require_ginipig}</span>     
                                        <span className="icons_nearlythere icon_names icon-size">{props.content.expertise_level}</span>    
                                        {(props.content.ginipig_count - props.content.bookings_places_count)===1 ?
                                          <span className="icons_gignireq icon_names icon-size">{props.content.ginipig_count - props.content.bookings_places_count} place left</span>:
                                          <span className="icons_gignireq icon_names icon-size">{props.content.ginipig_count - props.content.bookings_places_count} places left</span>     


                                        } 
                                      
                                    </div>
                                </div>                                       
                            </div>
                            <div className="vertical-line-div">
                                <div className="vertical-line">
                                </div>
                            </div>
                            <div className="practitioner-user" onClick={props.onPractitionerPress} >
                                <div className="p-image-div">
                                    <img src={props.content.treatment.user.profile_pic !== ''? S3_BUCKET_LINK+props.content.treatment.user.profile_pic:practitioner_avtar} className="p-image" />
                                </div>
                                <div className="practitioner-user-name">
                                    <p className="provider-name">{props.content.treatment.user.first_name}</p>
                                    <p className="indivitual">{props.content.treatment.user && props.content.treatment.user.practicing_saloon === 1 ? props.content.treatment.user.company_name :props.content.treatment.user.practitioner_category_apply }</p>
                                    <div className="practitioner-user-rating">
                                    <div>
                                        <span className="icons_gini icon_names rating"><img src={star} /> <span className="text-count">{props.content.treatment.user.review_rating_practitioner.toFixed(1)} </span> </span> 
                                    </div>
                                    <div>
                                        {/* <p className="other-treatment mb-24">Other treatments({props.content.treatment.user.live_treatment === 0 ? 0 : props.content.treatment.user.live_treatment - 1})</p>*/}
                                        <p className="other-treatment mb-24">Available appointments({props.content.other_treatment === 0 ? 0 : props.content.other_treatment})</p>
                                    </div>
                                    </div>
                                </div>       
                            </div>
                            
                            </div>
                            <div className="list-buttons button-right" onClick={props.onNextPress} disabled={bookings_count === 0 ? false : true}>
                                {props.content.type === 1 ? 
                                    <button className="varity-listbtn mt-5" style={{fontStyle:"italic"}} disabled={bookings_count === 0 ? false : true}>Flexible availability</button> :
                                    <button className="varity-listbtn mt-5" disabled={bookings_count === 0 ? false : true}>{set_date} </button>
                                }
                                  {props.content.postcodes!==null?
                                        <button className="varity-listbtn mt-5 bt-none" disabled={bookings_count === 0 ? false : true}>{ formatPostcode(props.content.postcodes.postcode)}</button>:
                                        <button className="varity-listbtn mt-5 bt-none" disabled={bookings_count === 0 ? false : true}>Online</button>
                                  }
                                    {
                                        props.content.type === 1 ? 
                                        <button className="varity-listbtn book-now-listbtn mt-5" disabled={bookings_count === 0 ? false : true} >{bookings_count === 0 ? 'Enquire Now' : 'Already Booked'}</button>:
                                        <button className="varity-listbtn book-now-listbtn mt-5" disabled={bookings_count === 0 ? false : true} >{bookings_count === 0 ? 'Book Now' : 'Already Booked'}</button>
                                    }
                                   
                            </div> 
                        </div>  
                    </div>
                </div> : null
            }  
            </div>
        </>
    );
}

export default TreatmentsListing;