import React from 'react'
import LoadingSpinner from "../components/UIComponent/LoadingSpinner";
const Loader = () => {
    return (
        <>
            <div style={{ 
            textAlign: 'center',
            position: 'fixed',
            top:'0',
            zIndex: 11111,
            background: '#f8f8f8',
            width: '100%',
            height: '100vh',
             }}>
                 <div style={{marginTop:'20%'}}>
                    <LoadingSpinner />
                    <p style={{ fontSize: 20, marginTop: 10 }}>Loading ...</p>
             </div>
            </div> 
        </>
    )
}

export default Loader
