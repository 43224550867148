
import React, { useCallback, useState, useEffect } from "react";
import { useHistory, useLocation} from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import {Link as Slink} from 'react-scroll';
import {  practitionerSignupSaveData_Action,ginipigSignupSaveData_Action } from '../../stores/actions'
import { LeftArrow, star, iconCase_Study, iconNearly_there, iconPhoto, reviewAvatar } from '../../config/images';
import  NavigationBreadcrumbs from '../../components/NavigationBreadcrumbs';

const TermCondition = (props,{location}) => {
    const practitionerRegisterData = useSelector(state => state.practitionerregister);
    const ginipigRegisterData = useSelector(state => state.ginipigregister);

    const dispatch = useDispatch();
    let history = useHistory();
    const { pathname, hash } = useLocation();
    useEffect(() => {
        // if not a hash link, scroll to top
        if (hash === '') {
          window.scrollTo(0,0);
        }
        // else scroll to id
        else {
          setTimeout(() => {
            const id = hash.replace('#', '');
            const element = document.getElementById(id);
            if (element) {
              element.scrollIntoView();
            // window.scrollTo(0,3000);
            }
          }, 0);
        }
      }, [pathname]); // do this on route change
      
    const __reDirectPage = (e) =>{
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        __reDirectPage()
    }, [])


    const backBtnPressed= async ()=>{
       
        if (practitionerRegisterData.practitionerregisterdata !== null) {

            let formDataNew = {
              first_name: practitionerRegisterData.practitionerregisterdata.first_name,
              last_name: practitionerRegisterData.practitionerregisterdata.last_name,
              practitioner_category_apply: practitionerRegisterData.practitionerregisterdata.practitioner_category_apply,
              email: practitionerRegisterData.practitionerregisterdata.email,
              password: practitionerRegisterData.practitionerregisterdata.password,
              password_confirmation: practitionerRegisterData.practitionerregisterdata.password_confirmation,
              profile_pic: practitionerRegisterData.practitionerregisterdata.profile_pic,
              company_name: practitionerRegisterData.practitionerregisterdata.company_name,
              file: practitionerRegisterData.practitionerregisterdata.file
            }
            await dispatch(practitionerSignupSaveData_Action(formDataNew));
        }
        if (ginipigRegisterData.ginipigregisterdata !== null) {

            let formDataNew = {
                first_name: ginipigRegisterData.ginipigregisterdata.first_name,
                last_name: ginipigRegisterData.ginipigregisterdata.last_name,
                email: ginipigRegisterData.ginipigregisterdata.email,
                password:  ginipigRegisterData.ginipigregisterdata.password,
                password_confirmation:  ginipigRegisterData.ginipigregisterdata.password_confirmation,
                profile_pic:  ginipigRegisterData.ginipigregisterdata.profile_pic,
                file:  ginipigRegisterData.ginipigregisterdata.file,
                telephone_no:  ginipigRegisterData.ginipigregisterdata.telephone_no,
                is_checked_terms_condition:  ginipigRegisterData.ginipigregisterdata.is_checked_terms_condition,
                is_checked_email_message:  ginipigRegisterData.ginipigregisterdata.is_checked_email_message,
                is_accept_notify_ginipig:  ginipigRegisterData.ginipigregisterdata.is_accept_notify_ginipig,

            }
            await dispatch(ginipigSignupSaveData_Action(formDataNew));
        }
        history.goBack();
    }

    
    return (
        <>
            <section className="bgcolor-gray common-section open_req_booking_pg pb80">
                <Container>
                    <Row>
                        <Col className="col-lg-11 m-auto">
                            <NavigationBreadcrumbs 
                                leftalignText=" Boring But Important Bits "
                                onBackClick = {()=>{backBtnPressed()}}

                            />

                            <div className="requirement_wrp padright-0 mt-24">
                                    <div className="white_wrp" id="tc">
                                        <div className="content-box">
                                            <h3  className="heading">TERMS AND CONDITIONS</h3>
                                            <p>
                                            Welcome to GoGiniPig. Before you start enjoying our services, we have to set out a few ground rules. We have done our best to cut the legal chat and make them as easy to understand as possible but if you have any questions at all, feel free to contact us at <a href="mailto:hello@goginipig.com" className="email-border"> hello@goginipig.com </a> 
                                            </p>
                                            <h3 className="heading">WHO WE ARE</h3>
                    
                                            <p>We are Go Ginipig Limited (t/a GoGiniPig), a company incorporated and registered in England and Wales with company number 12201976. Our registered office is at 1a Albany Road, Stratford-Upon-Avon, Warwickshire, CV37 6PG (referred to as <span className="bold"> GGP, we, our </span> and  <span className="bold">us</span>). 
                                            </p>
                                            <p>
                                            We are an online platform which allows users to book a broad range of Hair, Beauty, Aesthetics and Wellness Services provided by Treatment or Service Providers. The services offered by the Treatment or Service Providers are sold by them, not by us. We are only responsible for the arranging and concluding of your bookings and we have been appointed by the Treatment or Service Providers to act as their agent in this respect.
                                            </p>
                                            
                                            <h3 className="heading">THESE TERMS </h3>
                                            <p>In these terms and conditions (together with the documents referred to in them) (the <span className="bold"> Terms </span>), we will refer to our <span className="bold"> Website</span>, and our <span className="bold"> App </span>, and together we’ll refer to them as our <span className="bold"> Digital Products</span>. 
                                            </p>
                                            <p>When we talk about our Website, we refer to our site located at or accessible through <a href="https://www.goginipig.com">www.goginipig.com</a> </p>
                                            <p>When we talk about our <span className="bold"> App</span>, we refer to our mobile and/or web application, which can be accessed directly through your web browser on a computer or mobile device either as a responsive website or a progressive web application.</p>
                                            <p>These Terms govern your relationship with us when you access and licence our <span className="bold"> Digital Products</span>, so please make sure to read them carefully before you start accessing them. Once you start using our Digital Products you are taken to have understood and accepted these Terms. </p>
                                            <p>In these Terms, we refer to anyone who visits or uses our Digital Products as a <span className="bold">‘User’.</span> </p>
                                            
                                            <h3 className="heading">OTHER APPLICABLE TERMS </h3>
                                            <p> So as not to overload you with too much information at once, these Terms also include our  <Slink to="pp" style={{cursor:'pointer'}} spy={true} smooth={true} offset={-65} > Privacy Policy </Slink> (the <span className="bold">Privacy Policy</span>). You should read the Privacy Policy as it sets out the terms on which we process (collect, use, share, etc.) any personal data we collect from you or that you provide to us.
                                            </p>
                                            
                                            <h3 className="heading">CHANGES TO THESE TERMS </h3>
                                                <p>This section informs you of how we may update and amend these Terms.</p>
                                                <p>We may from time to time amend these Terms to ensure that we remain compliant with relevant laws and regulations or to keep up to date with improvements or changes we might make to the services and experiences we can offer to you via our Digital Products.</p>
                                                <p>We don't expect you to check these Terms every time you use our Digital Products but equally we don't want to bombard you with every little update. We just ask that you check this page from time to time to take notice of any changes we have made. This is important because by continuing to use any of the Digital Products after changes are made, you are accepting those changes and will be bound by them.</p>

                                                <h3 className="heading">THE WEBSITE </h3>
                                                <p> This section tells you what the Website does. </p>
                                                <ul> 
                                                    
                                                <p> We offer the Website mainly in order to:  </p>
                                                    <li>welcome you to and tell you all our Digital Products;</li>
                                                    <li>make bookings for the services we offer through the Digital Products;</li>
                                                    <li>direct you towards the latest version of our mobile web app which will allow you to browse for and book the services and manage your account with us. </li>
                                                    <li>provide a platform for customer support;</li>
                                                    <li>to give you access to our legal and data protection policies.</li>
                                                </ul>
                                                <p>Please refer to our <Slink to="pp" style={{cursor:'pointer'}} spy={true} smooth={true} offset={-65} > Privacy Policy </Slink>  for more information on how we process your personal data.</p>
                                                <p>The list above is not necessarily a complete list of the functions of the Website and we may decide to offer additional Website functions, or stop providing any Website functions, at any time. </p>
                                                
                                            <h3 className="heading">THE MOBILE WEB APP </h3> 
                                                <p>This section tells you what the App does.</p>
                                                <ul>
                                                    
                                                <p >We offer various functions via the App (the <span className="bold"> App Functions </span>), including those which allow you to: </p>
                                                    <li>create a personal Account on the App;</li>
                                                    <li>make bookings for the services offered through the Digital Products; and</li>
                                                    <li>receive notifications via, emails, SMS. These messages may include information about your Account, your future bookings and general GGP information. The list above is not necessarily a complete list of the App Functions, and we may decide to offer additional or remove some current App Functions, at any time.</li>
                                                </ul>
                                            <h3 className="heading">CREATING AN ACCOUNT </h3> 
                                            {/* <p>To have full access to the App and the App Functions, you will have to register with us and create an Account. You can do this by providing your full name; email and/or mobile phone number; and date of birth (confirming that you are at least 18 years old). </p> */}
                                            <p>To have full access to GoGiniPig, you will need to register with GoGiniPig and create an Account by providing your full name, email and mobile phone number. Telephone numbers are only used when an appointment has been booked to enable full details and confirmation to be sent. They are not used for marketing purposes</p>
                                            <p>Your email address is used to sign into our Website and/or App, together with a password created by you, but you can change this at any time. By using the Digital Products and/or signing up with us you agree to any applicable terms of the service, including these Terms. </p>
                                            <p> Please provide truthful and accurate information when registering with GGP – this helps us provide you with the best service. </p>
                                            <p>You are responsible for looking after the security of your Account. Please immediately notify us of any unauthorised use of your Account or any other breach of security relating to any of the Digital Products. We are not responsible for any loss or damage caused by the disclosure of your Account details to someone else. </p>
                                            <p>You are responsible for ensuring that the information we hold about you is up to date. Please amend your details as appropriate from time to time to notify us of any changes.</p>
                                            
                                            <h3 className="heading">ACCEPTABLE USE RESTRICTIONS </h3>

                                            <p> This section tells you what you can and cannot do with the Digital Products.</p>
                                            
                                            <ul>
                                            <p>You may use the Digital Products only for personal and lawful purposes. In particular, but without limitation, you agree not to:</p>
                                                <li>use the Digital Products in any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect;</li>
                                                <li>use, share, or otherwise exploit the Digital Products for any commercial, business, or monetised purpose whatsoever, other than is expressly permitted by GGP;</li>
                                                <li>reproduce, duplicate, copy, share, or re-sell any part of the Digital Products in contravention of these Terms; </li>
                                                <li>use the Digital Products in a way that could damage, disable, overburden, impair or compromise our systems or security or interfere with other Users;</li>
                                                <li>use the Digital Products in a way which we deem to be inappropriate or abusive or which could cause offence or distress in any way to any Users, staff-member or other person associated with our service;</li>
                                                <li>transmit any data, send or upload any material that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer code designed to adversely affect the operation of the Digital Products; or</li>
                                                <li>access without authority, interfere with, damage or disrupt (a) any part of the Website or the App; (b) any equipment or network on which the Website is stored; (c) any software used in the provision of the Website or the App; or (d) any equipment, network or software owned or used by any third party, (together the <span className="bold">Acceptable Use Restrictions</span>).</li>
                                            </ul>

                                            
                                            <h3 className="heading">TERMINATION OF YOUR RIGHTS </h3>
                                              <p>This section tells you how we can end your rights under these Terms.</p> 
                                                <ul>
                                                    
                                              <p>We may end your rights under these Terms immediately and without notice if: </p> 
                                                    <li>you have breached any of the Acceptable Use Restrictions;</li>
                                                    <li>we believe that your use of the Website or the App is unsuitable in any way; or </li>
                                                    <li>you are otherwise in breach of these Terms.</li>
                                                </ul>   
                                                <ul>
                                                    
                                                <p> If we end your rights under these Terms: </p> 
                                                    <li>you must immediately stop all activities authorised by these Terms, including your access to and use of any or all of the Digital Products.</li>
                                                </ul>

                                                 <h3 className="heading"> INTELLECTUAL PROPERTY RIGHTS </h3>
                                                 <p> This section sets out who owns the Intellectual Property Rights on the Digital Products. </p>
                                                 <p>You agree that the Intellectual Property Rights on the Digital Products and all material published on, in, or via them is owned and controlled by or licensed to us.</p>
                                                 <p> <span className="bold"> “Intellectual Property Rights” </span> means patents, utility models, rights to inventions, copyright and neighbouring and related rights, moral rights, trade marks and service marks, business names and domain names, rights in get-up and trade dress, goodwill and the right to sue for passing off or unfair competition, rights in designs, rights in computer software, database rights, rights to use, and protect the confidentiality of, confidential information (including know-how and trade secrets) and all other intellectual property rights, in each case whether registered or unregistered and including all applications and rights to apply for and be granted, renewals or extensions of, and rights to claim priority from, such rights and all similar or equivalent rights or forms of protection which subsist or will subsist now or in the future in any part of the world.</p>  

                                                  <h3 className="heading">AVAILABILITY OF THE WEBSITE AND THE APP </h3>   
                                                  <p>This section explains that we try and keep the Digital Products up and running at all times but we cannot promise that this will always be the case. </p>
                                                  <p>The Digital Products are provided on an "as is" basis. We make no representations, warranties or guarantees of any kind regarding the availability or operation of the Digital Products, or that they will be secure, uninterrupted or free of defects. </p>
                                                  <p>Your access to any of the Digital Products may be suspended or restricted occasionally to allow for maintenance, repairs, upgrades, or the introduction of new functions or services. Availability of our Digital Products may also be interrupted in the case of events or occurrences beyond our reasonable control. We will not be liable to you if for any reason any of the Digital Products are unavailable at any time or for any period.</p> 

                                                   <h3 className="heading">UPDATES TO THE APP </h3>
                                                   <p>From time to time updates to the App may be issued directly via our Digital Products. Depending on the update, you may not be able to use the App until you have accepted any new terms. </p> 

                                                   <h3 className="heading">WEBSITES WE LINK TO</h3>
                                                   <p>This section informs you of our relationships with any third party websites that feature on our Digital Products. The Digital Products may link to other third party websites from which third party services can be obtained. Whilst we reasonably believe that these are reputable sources of such services, you acknowledge that these other websites are independent from us and we make no representations or warranties as to the legitimacy, accuracy or quality of such third party services, and we do not accept any responsibility for their content, safety, practices or privacy policies. Essentially, we will do our best to ensure they are safe but you access any third party at your own risk.</p>
                                                    <p></p>
                                                   
                                                   <h3 className="heading">COMPUTER VIRUSES</h3>
                                                   <p>This section provides some helpful advice about how to keep your devices safe from viruses.</p>
                                                   <p>We do everything we can to ensure that no part of the Digital Products will contain or spread any viruses or other malicious code but this section explains how you can best protect your devices.  </p>
                                                   <p>We recommend that you ensure that equipment used to access the Digital Products run up-to-date anti-virus software as a precaution, and you are advised to virus-check any materials downloaded from any of the Digital Products and regularly check for the presence of viruses and other malicious code. </p>
                                                   <p>To the full extent permitted by law we exclude liability for damage or loss of any kind caused by viruses or other harmful components originating or contracted from the Digital Products.</p>

                                                   
                                                   <h3 className="heading">NO RELIANCE ON INFORMATION </h3>
                                                   <p>All information published on or via the Digital Products is provided in good faith and for general information purpose only. We make no warranties about the completeness, reliability, or accuracy of such information. Any action you take based on such information is taken at your own risk. </p>
                                                   
                                                   <h3 className="heading">IF THERE IS A PROBLEM WITH THE WEBSITE AND/OR APP </h3>
                                                   <p> This section explains how you can tell us about any problems with any of the Digital Products and what your legal rights are.  </p>
                                                    <p>If you have any questions or complaints about GoGiniPigs platform or services, please contact us at… <a href="mailto:hello@goginipig.com" className="email-border"> hello@goginipig.com.</a> </p>
                                                    
                                                   <h3 className="heading">OUR RESPONSIBILITY FOR LOSS OR DAMAGE SUFFERED BY YOU </h3>
                                                        <p>This section explains the limits of our liability to you where you suffer any loss or damage from any of the Digital Products.</p>
                                                        <p>We do not exclude or limit our liability to you where it would be unlawful to do so. This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors and for fraud or fraudulent misrepresentation.</p>
                                                        <p>You accept and agree we will not be liable for any harmful effect that accessing the Digital Products may have on you, and you agree that you access the Digital Products at your own risk. In the event that you are directed to any third party platform, we shall not be responsible for the acts or omission of such a platform.</p>
                                                  
                                                   <h3 className="heading"> COMMUNICATIONS BETWEEN US</h3>
                                                   <p>This section explains how you can contact us and how and when we may contact you. </p>
                                                   <p>If you wish to contact us for any reason, chat with us here <a href="mailto:hello@goginipig.com" className="email-border"> hello@goginipig.com.</a> </p>
                                                   <p>We will only contact you if we make any relevant updates or changes to our services, or where you have signed up for marketing communications. You may opt out of marketing communications at any time by using the "unsubscribe" link provided. </p>
                                                  
                                                   <h3 className="heading"> OTHER IMPORTANT TERMS</h3>
                                                    <p>This section provides all of the extra terms that we have to tell you about. </p>
                                                    <p>We may transfer our rights and obligations under these Terms to another organisation, but this will not affect your rights or our obligations under these Terms. </p>
                                                    <p>If we do not enforce our rights against you, or if we delay in doing so, that does not mean that we have waived our rights against you, and it does not mean that you are relieved of your obligations under these Terms. If we do waive a breach by you, we will only do so in writing, and that will not mean that we will automatically waive any later breach by you. </p>
                                                    <p>Each of the terms and conditions of these Terms operates separately. If any court or competent authority decides that any of them are unlawful or unenforceable, the remaining terms and conditions will remain in full force and effect. </p>
                                                    <p>These Terms are governed by English law and the courts of England and Wales have non-exclusive jurisdiction. </p>

                                        </div>
                                    </div>
                            </div>

                        </Col>
                    </Row>
                    {/* treatmet service provider agreement */}
                    <Row>
                        <Col className="col-lg-11 m-auto">
                            <div className="requirement_wrp  padright-0 mt-24">
                                <div className="white_wrp">
                                    <div className="content-box">
                                        <h3  className="heading"> Treatment or Service Provider Agreement </h3>
                                        <div className="tableDiv">
                                             <table>
                                                 <thead>
                                                    <tr>
                                                        <th>Go Ginipig Limited</th>
                                                        <td>Go Ginipig Limited (with Company Number 12201976) of 1a Albany Road, Stratford-Upon-Avon, Warwickshire, CV37 6PG (‘<span className="bold">GGP</span>’)
                                                        </td>
                                                    </tr>
                                                 </thead>
                                                 <tbody>
                                                    <tr>
                                                        <td align="top"> <b> The Treatment or Service Provider </b>
                                                        </td>
                                                        <td>You, the Treatment or Service Provider
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td align="top"><b> Services </b></td>
                                                        <td>
                                                            <p>The Treatment or Service Provider shall be an individual providing health, beauty, aesthetic or wellness services through a salon, academy, clinic or online facility. In the event that the Treatment or Service Provider practices through a company name, you shall ensure that you have all the requisite permissions from the company owner to use the name for trading purposes. No company logo or telephone numbers should be displayed, uploading business identifiable material will be in breach of the terms of this Agreement and will permit GGP to remove the Treatment or Service Provider from the Site. Any fees that have already been incurred will be the responsibility of the Treatment/Service Provider.</p>
<p>
The services to be provided by the Treatment or Service Provider are not prescriptive, but they shall be in the Health, Beauty, Aesthetics or Wellness sector and shall be targeted at both male and female clients (the ‘GiniPig’ or ‘GP’). For the avoidance of doubt, the services to be offered through the Site shall not include any form of plastic surgery or medical treatment which may require a general anaesthetic (by way of example breast implants or surgical nose reconstruction) but may include botox (anti-wrinkle) and face and lip filler (dermal filler) treatments.
</p>

<h3>Account Registration</h3>
<p>All Treatment or Service Providers must register an account ("GGP Account") to access and use certain features of the Site, such as publishing your Services. If you are registering a GGP Account for a business, organisation or other legal entity, you represent and warrant that you have the authority to both (i) legally bind that entity and (ii) grant GGP all permissions and licenses provided in this Agreement.
</p>
<p>You must provide accurate, current and complete information during the registration process and keep your GGP Account and public GGP Account profile page information up-to-date at all times. 
</p>
<p>Treatment/ Service Providers must upload Profile Pictures of themselves and the skills they offer, no company names, telephone numbers or logo's should be visible in the photos. Uploading business identifiable material will be in breach of the terms of this Agreement and will permit GGP to remove the Treatment or Service Provider from the Site. Any fees that have already been incurred will be the responsibility of the Treatment/Service Provider.
</p>
<p>You may not register more than one (1) Treatment or Service Provider GGP Account unless GGP authorises you to do so. You can however also have a GiniPig Account. You may not assign or otherwise transfer your GGP Account to a third party.</p>
<p>You are responsible for maintaining the confidentiality and security of your GGP Account credentials and may not disclose your credentials to any third party. You must immediately notify GGP if you know or have any reason to suspect that your credentials have been lost, stolen, misappropriated, or otherwise compromised or in case of any actual or suspected unauthorised use of your GGP Account. You are liable for any and all activities conducted through your GGP Account, unless such activities are not authorised by you and you are not otherwise negligent (such as failing to report the unauthorised use or loss of your credentials).
</p>
<p>The Treatment or Service Provider shall list the Services they are providing on the Site, together with the location from which they work from or are attending their training course at, all online appointments and services will use the ‘online’ option as their location. Each listing on the Site shall include: (i) the day the appointment is available for the GiniPig to book; (ii) the time that the appointment is available for the GiniPig to book; (iii) the location where the appointment is available; (iv) the number of GiniPigs required for the appointment if more than one GiniPig is required; and (v) the total cost of the Services available through the appointment. 
</p>
<p>The Treatment or Service Provider shall use all reasonable endeavours to accept a GiniPig booking request as soon as is reasonably practicable. There is a 16 hour window for a Treatment or Service Provider to accept or decline a GiniPig booking request. In the event that a Treatment or Service Provider fails to acknowledge a booking request from a GiniPig, within the 16 hour window, the GiniPig request will be automatically declined and the GiniPig will be advised that the booking request has not been completed and that no funds will be taken from their account. Should this occur on more than three (3) consecutive occasions, GGP reserves the right to remove all of the Treatment or Service Provider’s Services from the Site. For the avoidance of doubt, in no event shall the Treatment or Service Provider use the Site for advertising purposes in respect of any products or services that the Treatment or Service Provider provides outwith the scope of this Agreement. The Treatment or Service Provider acknowledges that any such advertisement on the Site will be in breach of the terms of this Agreement and will permit GGP to remove the Treatment or Service Provider from the Site.
</p>
<p>GGP are aware that internet verification is difficult, and we do not assume any responsibility for the confirmation of your identity. Therefore, for transparency and fraud prevention purposes, and as permitted by applicable laws, we may, but have no obligation to: (i) ask you to provide a form of government identification or other information or undertake additional checks designed to help verify your identity or background; and (ii) screen you against third party databases or other sources and request appropriate reports from service providers.
</p>

                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td align="top"><b> Payment/Finance </b></td>
                                                        <td>
                                                            <p>In order to ensure all payments are processed securely and in compliance with
payment regulations, payment processing services for Treatment or Service Providers are
provided by Stripe and are subject to the Stripe Connect account agreement,
which includes the <a href="https://stripe.com/gb/legal" target="_blank">Stripe terms of service.</a>  By agreeing to the terms of this Agreement, you agree to be
bound by the Stripe Services Agreement, as may be modified by Stripe from
time to time. 
</p>
<p>GGP is required to make a payment of £2 per month to Stripe for each
Treatment or Service Provider active on the Site. You therefore agree that in the event that
you have provided Services in any month, this fee
of £2 will be taken by GGP from your Invoice Statement.
</p>
 <p>GGP will send the appropriate payment if applicable to your Stripe account after confirmation that your appointment occurred, or in the event that it was cancelled within 36 hours of the appointment time. Payment will be released to you within a maximum of 7 Business Days as per Stripe Payment Policy. Any monies you may owe us for Bookings you may have had to cancel (as detailed below), together with the monthly £2 fee incurred for using our platform (as detailed above) will be deducted from your account.
 </p>
<p>In the event that we owe you monies, we will pay this into your Stripe Account within 7 Business Days. In the event that you owe us monies; we will initiate payment on your behalf, in accordance with authorisation given by you on accepting this Agreement. In the event that the balance is settled, it will show on your Stripe Account that there is no further action needed.
</p>
<p>Upon a booking being made by a GiniPig and being accepted by you, a 15% non 
refundable deposit is taken from the GiniPig. As a result, in the event that you
cancel a booking following your initial acceptance, you agree to reimburse GGP
for the 15% deposit which is taken from the GiniPig by GGP. You hereby 
authorise GGP to take any such payments from your Invoice Statement as
detailed in this Agreement. For the avoidance of doubt, GGP will have full
control over the payment systems which are necessary to ensure that the payment
provisions contained herein are fully adhered to.
</p>
<p>The Treatment or Service Provider shall advertise their Services on the Site for a list price
of at least 30% less than the RRP for the Services being provided (such listing
price to be determined by the Treatment or Service Provider acting in accordance with
normal industry practice). The minimum price for any individual Service shall
be £7.50.  
</p>
<p>Once confirmation has been received by GGP from the Treatment or Service Provider that
the Services are completed by the Treatment or Service Provider, 85% of the list
price which is advertised by the Treatment or Service Provider for their Services on the Site
shall be transferred directly by GGP to the Treatment or Service Providers Stripe Account
and payment will be released to you within a maximum of 7 Business Days as per Stripe Payment Policy.
</p>
<p>
In the event that Services are not completed as a result of a ‘no-show’ or a cancellation by the GiniPig within 36 hours of the scheduled appointment time. The Treatment or Service Provider shall receive 50% of the listed treatment price which is advertised by the Treatment or Service Provider for their Services on the Site, or in the case of cancellation occurring due to payment failure by the GiniPig the Treatment or Service Provider shall receive 15% of the listed treatment price which is advertised by the Treatment or Service Provider for their Services on the Site.
</p>
 <p>The Treatment or Service Provider shall be entitled to retain any tips that are given to them
directly by a GiniPig.
 </p>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td align="top"><b> Special Provisions </b></td>
                                                        <td>
                                                            <p>The Treatment or Service Provider is fully responsible for the provision of the Services and, as per the GGP Booking Terms, the Treatment or Service Providers Contract provides that the contract for those Services is between the Treatment or Service Provider and the GiniPig directly. GGP shall not be liable in any way, for the provision of the Services. </p>
                                                            <p>The Treatment or Service Provider shall ensure that at all times, he/she complies with the following special provisions:</p>
                                                            <div className="list">
                                                                <p>1.	The Treatment or Service Provider shall ensure that they have public liability insurance cover with a cap of no less than £1,000,000.</p>
                                                                <p>2.	The Treatment or Service Provider must ensure that they have all licenses, consents, qualifications, authorities & insurance as is required for the provision of the Services.</p>
                                                                <p>3.	The Treatment or Service Provider must provide the Services in accordance with the highest industry standards at all times. 
                                                                </p>
                                                                <p>4.	The Treatment or Service Provider will be at least 18 years of age when carrying out the Services. 
                                                                </p>
                                                                <p>5.	The Treatment or Service Provider shall ensure that they comply with all Covid-19, or other health and safety related matters, when providing the Services.</p>
                                                                <p>6.	The Treatment or Service Provider must ensure that the list of Services provided by them for the Site is complete and accurate and will not in any way mislead a GiniPig. Any restrictions or requirements of the Services shall be clearly detailed by the Treatment or Service Provider who shall be solely responsible for keeping all listings fully accurate and up-to-date at all times.   
                                                                </p>
                                                                <p>7.	The Treatment or Service Provider will only offer Services which are scheduled for within three (3) months of the listing date. Services which are scheduled for a date that is later than three (3) months from the listing date, will not be listed on the Site. 
                                                                </p>
                                                                <p>8.	The Treatment or Service Provider shall not solicit a GiniPig to make any bookings with the Treatment or Service Provider in any way other than through the Site.</p>
                                                                <p>9.	The Treatment or Service Provider shall ensure that the applicable GiniPig is suitable for them to carry out the treatment or service  that is required and that there are no contraindications in respect of the GiniPig who may want such treatment or service. In the event that a patch test is required in respect of any of the Services provided by the Treatment  Provider, the Treatment  Provider shall make this clear within the booking process, Once the booking is confirmed the Treatment  Provider will be given the contact details of the GiniPig and it is their responsibility to communicate the process of patch testing with the GiniPig, GGP shall have no role or responsibility in this process. GGP encourage the Treatment or Service Provider to specify in the treatment description if there maybe any barriers making their treatment unsuitable in anyway for specific  needs. In the event that a consultation form is required, the Treatment or Service Provider shall be solely responsible for the completion of such forms and the accuracy of the information contained within the forms. The Treatment or Service Provider shall consider the photograph and/or consultation form provided by the GiniPig when they are making a booking, in order to determine if there is any reason why a specific booking should not proceed.  
                                                                </p>
                                                                <p>10.	Subject to the express approval of each GiniPig, the Treatment or Service Provider shall be entitled to take before and after photographs and/or video recordings for use on the Site together with the GGP social channels, such as Facebook and Instagram (the “Social Channels”). The Treatment or Service Provider shall not use these photographs and/or video recordings in any way other than for use on the Site or the Social Channels. Similarly, the Treatment or Service Provider shall provide a photograph of themselves for the Site and Social Channels to accompany the Treatment or Service Provider’s listings.
                                                                </p>
                                                                <p>11.	The Treatment or Service Provider acknowledges that the placement and ranking of listings on the Site will be done in date order, from the next available date to the date furthest away. 
                                                                </p>
                                                                <p>12.	Within a certain timeframe after the completion of the Services, GiniPigs can leave a public review (“Review”) on the Site and submit a star rating (“Rating”) on the Site. Ratings or Reviews reflect the opinions of individual GiniPigs and do not reflect the opinion of GGP. Ratings and Reviews are not verified by GGP for accuracy and may be incorrect or misleading. 
                                                                </p>
                                                            
                                                            </div>
                                                        </td>
                                                    </tr>
                                                 </tbody>
                                             </table>
                                        </div>
                                        <h3>General Terms and Conditions:</h3>
                                        <p>(a)	The Treatment or Service Provider hereby grants to GGP the right to use imagery in connection with the provision of the Services on  <a href="https://www.goginipig.com">www.goginipig.com</a>  (or such other URL as notified by GGP from time to time), including its different interfaces such as website, desktop, laptop, mobile and mobile applications and any other form of technology vertical or technological delivery mechanism, which may either exist as at the date of this Agreement, or be developed in the future, either by or on behalf of GGP or otherwise (the “<span className="bold">Site</span>”).
                                        </p>
                                        <p>(b)	All payments due to the Treatment or Service Provider under this Agreement shall be inclusive of value added tax (or equivalent) and the Treatment or Service Provider shall therefore be solely responsible for dealing with any VAT payments that may be due. 
                                        </p>
                                        <p>(c)	To the maximum extent allowable by law, neither party shall be liable to the other for any loss of profits, business, goodwill, revenue, anticipated savings or for any indirect or consequential loss or damage arising out of or in connection with this Agreement. Without prejudice to the above exclusion each party’s total liability in connection with this Agreement whether in contract, tort (including negligence) or otherwise shall be three times the fee payable to the Treatment or Service Provider in the year in which the liability occurred. This paragraph shall be read and construed subject to any limitation imposed by law and nothing in this paragraph shall limit or exclude any liability for fraud.  
                                        </p>
                                        <p>(d)	All Intellectual Property Rights in content developed or created by the parties as part of the development or running of the Site shall be owned by GGP. For the purposes of this Agreement, “<span className="bold">Intellectual Property Rights</span>” means patents, utility models, rights to inventions, copyright and neighbouring and related rights, moral rights, trademarks and service marks, business names and domain names, rights in get-up and trade dress, goodwill and the right to sue for passing off or unfair competition, rights in designs, rights in computer software, database rights, rights to use, and protect the confidentiality of, confidential information (including know-how and trade secrets) and all other intellectual property rights, in each case whether registered or unregistered and including all applications and rights to apply for and be granted, renewals or extensions of, and rights to claim priority from, such rights and all similar or equivalent rights or forms of protection which subsist or will subsist now or in the future in any part of the world. 
                                        </p>
                                        <p>(e)	The Treatment or Service Provider shall be solely responsible for any content in relation to the Services which is made available on the Site. Accordingly, the Treatment or Service Provider represents and warrants that they: (i) are either the sole and exclusive owner of all content that is made available on or through the Site or you have all rights, licenses, consents and releases that are necessary to grant to GGP the rights in and to such content; and (ii) neither the content nor your posting, uploading, publication, submission or transmittal of the content or GGP’s use of the content (or any portion thereof) as contemplated under these Terms will infringe, misappropriate or violate the Intellectual Property Rights of a third party or result in the violation of any applicable law or regulation.
                                        </p>
                                        <p>(f)	You will not post, upload, publish, submit or transmit any content that is: (i) fraudulent, false, misleading (directly or by omission or failure to update information) or deceptive; (ii) defamatory, libellous, obscene, pornographic, vulgar or offensive; (iii) promoting discrimination, bigotry, racism, hatred, harassment or harm against any individual or group; (iv) violent or threatening or promotes violence or actions that are threatening to any other person or animal; (v) promoting illegal or harmful activities or substances; or (vi) violates any of GGP’s policies. GGP may remove or disable access to any content that is in violation of applicable law, these Terms or our then-current policies or standards, or otherwise may be harmful or objectionable to GGP or any third party. Where GGP removes or disables content, GGP will notify you and provide the reasons for such a measure, unless such notification would (i) prevent or impede the detection or prevention of fraud or other illegal activities, (ii) harm the legitimate interests of any third party, or (ii) contravene applicable laws. 
                                        </p>
                                        <p>(g)	This Agreement shall not constitute a partnership, joint venture or contract of employment and neither party shall act or hold itself out as acting on behalf of the other.  Each party acknowledges that in entering into this Agreement it has not relied upon any representation, warranty or other assurance save as is expressly set out in this Agreement.  
                                        </p>
                                        <p>(h)	This document constitutes the entire agreement between the parties and may not be changed or modified except by separate written agreement signed by both parties.</p>
                                        <p>(i)	Neither Party shall have the right to assign or otherwise transfer any of its rights or obligations hereunder without the prior written consent of the other party.</p>
                                        <p>(j)	The parties agree to keep the terms of this Agreement strictly confidential at all times except as required by law. </p>
                                        <p>(k)	This Agreement shall be governed by the laws of England and Wales and the parties agree to submit to the exclusive jurisdiction of the Courts of England and Wales.</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    {/* GiniPig Booking Conditions */}

                    <Row>
                        <Col className="col-lg-11 m-auto">
                            <div className="requirement_wrp  padright-0 mt-24">
                                <div className="white_wrp">
                                    <div className="content-box">
                                    <h3  className="heading"> BOOKING TERMS AND CONDITIONS </h3>
                                    <p>Please read these Booking Terms and Conditions carefully before you complete any transactions via the GGP Website or App as these Booking Terms and Conditions will apply to your transaction. If you do not agree with these Booking Terms and Conditions, you should not use our Website or App to make a booking. 
                                    </p>
                                    <h3  className="heading"> Defined Terms </h3>
                                    <p>For ease of reference the following terms shall have the following meanings in these Booking Terms and Conditions:</p>
                                    <p>“App” means the GoGiniPig Customer mobile web application software.</p>
                                    <p>“Booking" means a booking made with a particular Treatment or Service Provider for the provision of Services whether made via the Website or App.</p>
                                    <p>"Partner(s)" means our Treatment or Service Providers who offer their goods and services via our Website or App;</p>
                                    <p>"Services" means any products, goods and/or services of a Treatment or Service Provider offered for booking via the Website or App;</p>
                                    <p>“Website” means  <a href="https://www.goginipig.com"> www.goginipig.com</a> </p>
                                    <p><b>Introduction and relationship between you, us and third party Partners</b></p>
                                    <p>This Website and App are operated by Go Ginipig Limited (t/a GoGiniPig), a company registered in England under Company Number 12201976 and whose Registered Office is at 1a Albany Road, Stratford-Upon-Avon, Warwickshire, CV37 6PG (“GoGiniPig”, "GGP", "us", "we" or "our"). </p>
                                    <p>The Website and App allow you to book and pay for a broad range of Hair, Beauty, Aesthetics and Wellness Services provided by our Treatment or Service Providers (our “Treatment or Service Providers”). The Services that you book are provided by our Treatment or Service Providers and not by us. The provision of the Services booked via our Website or App is the responsibility of the Treatment or Service Providers which provides them. You will pay for Services via the Website or App, your payment will be received by us acting as commercial agent on behalf of the Treatment or Service Provider. When we receive payment as commercial agent on behalf of the relevant Treatment or Service Provider, our receipt of the correct payment will discharge your debt to that Treatment or Service Provider.
                                    </p>
                                    <p>The legal implications of this is that when you purchase Services, it will create two binding contracts for you:</p>
                                    <p>1.	a contract between you and GGP (under which GGP has certain responsibilities to you in relation to the purchase or booking) (the “GGP Contract”). That contract is based on these Booking Terms and Conditions; and
                                    </p>
                                    <p>2.	a contract between you and the relevant Treatment or Service Provider in respect of the provision or supply of the Services which you book through the Website or App (the “Treatment or Service Providers Contract”). That contract is subject to certain provisions of these Booking Terms and Conditions.
                                    </p>
                                    <p>All Services are offered by GGP on behalf of our Treatment or Service Providers. That is, GGP takes and concludes your bookings as a commercial agent for the Treatment or Service Providers. Therefore, we are not responsible or liable to you for the actual Services that are booked through the Website or App and are provided to you by our Treatment or Service Providers.
                                    </p>
                                    <p>You should only make a Booking using GGP if you are 18 years of age, or older or if you are making a Booking for someone else who is 18 years of age, or older.
                                    </p>
                                    <p>Please check these Booking Terms and Conditions each time you wish to make a Booking to ensure you understand the terms which will apply to your specific Booking.
                                    </p>
                                    <p><b>Orders and how the contract is formed between you and us</b></p>
                                    <p>When making a booking through the Website or App, you will place an order for a Booking (an “Order”). The booking process allows the Treatment or Service Provider a maximum of 16 hours to accept your Booking. Upon receiving a confirmation email/text that your Booking has been accepted by the Treatment or Service Provider, either a 15% deposit payment or the full payment for your Order, whichever option you chose when you placed your Order, will be taken from you. For the avoidance of doubt, the 15% deposit will become non-refundable at that time. Until your Booking is accepted by the Treatment or Service Provider, you are entitled to change your mind and cancel your Booking request. If you wish to cancel a Treatment or Service Providers Contract (i.e. if you wish to cancel a Booking after you have received the Booking Confirmation), please refer to the provisions below in relation to the cancellation of a Booking.</p>
                                    <p>The Treatment or Service Providers Contract will be formed when you receive confirmation of your booking (“Booking Confirmation"). Your Booking Confirmation is your receipt from GGP. </p>
                                    <p>We reserve the right to withdraw access to GGP and/or cancel any Booking in the event that you fail any credit or fraud prevention check or where we reasonably suspect fraud or money laundering by you or someone using your account.</p>
                                    <p><b>Services</b></p>
                                    <p>Our Treatment or Service Providers are under a legal duty to provide Services that are in conformity with the relevant Treatment or Service Providers Contract.</p>
                                    <p>All Services shown on the Website or App are subject to availability and any description of the Services on the Website or App, which are written by our Treatment or Service Providers to the best of their knowledge, are for illustrative purposes only and actual Services may vary from those descriptions. We require our Treatment or Service Providers to ensure that all information provided by them for display on the Website or App is accurate, complete and not misleading in any way but we cannot verify the information which they provide to us. It will be each Treatment or Service Provider’s responsibility to ensure that their Services listed on the Website or App are available and accurately described.</p>
                                    <p>You will have legal rights as a consumer in the event that the Services are not performed with reasonable care and skill or are otherwise not as described. Advice about your legal rights is available from your local Citizens' Advice Bureau or Trading Standards office. Nothing in these Booking Terms and Conditions will affect your legal rights.</p>
                                    <p>It is your (or the person receiving the Services) sole responsibility when making an Order, to ensure that you are compliant with any contraindications which may impact upon your ability to receive the Services. In the event that you (or the relevant recipient of the Services) fail to disclose any such information to the Treatment or Service Provider, neither GGP nor the relevant Treatment or Service Provider shall be liable to you (or the recipient of the Services) for any injury, loss or damages resulting from the Services that could reasonably have been avoided if you (or the recipient of the Services) had disclosed that information prior to receiving the Services. In the event that a Treatment or Service Provider requires a photograph from you (or from the individual receiving the Services) in order to confirm that you are suitable to receive the Services, it is your responsibility to ensure that the photograph you provide is a true and accurate representation of you (or of the individual who will be the recipient of the Services). In the event that a patch test is required in respect of the Services, this will be made clear to you when making your Order. The Treatment or Service Provider will communicate with you directly in relation to the patch test process that is required. The Treatment or Service Provider will be solely responsible for the completion of such patch test processes. </p>
                                    <p>In the event that you have any special needs, please check the specific Treatment or Service details, where we encourage Providers to add information which may make their appointment difficult, for some GiniPigs with specific needs. </p>
                                    <p>Our Treatment or Service Providers shall be entitled to take before and after photographs and/or video recordings showing the Services which have been provided to you. The Treatment or Service Provider may seek to feature you in these photographs and/or video recordings. It is your responsibility to advise the Treatment or Service Provider if you do not wish to feature in these photographs and/or video recordings, which will only be available to view on the Website or on the GGP social channels such as Facebook and Instagram.</p>
                                    <p>IT IS A KEY REQUIREMENT THAT ALL THE INFORMATION YOU PROVIDE WHEN MAKING A BOOKING IS TRUE AND ACCURATE. OUR TREATMENT OR SERVICE PROVIDERS WILL USE THE INFORMATION PROVIDED BY YOU TO ENSURE THAT YOU ARE SUITABLE FOR THE SERVICES YOU HAVE BOOKED AND A LEGALLY BINDING CONTRACT WITH THE TREATMENT OR SERVICE PROVIDER WILL BE FORMED ON THAT BASIS. ANY MISLEADING INFORMATION PROVIDED BY YOU, MAY RESULT IN THE SERVICES NOT BEING PROVIDED TO YOU AND NO REFUND BEING DUE TO YOU. PLEASE ENSURE THAT YOU HAVE READ ALL OF THE RELEVANT DOCUMENTATION ON THE WEBSITE WHEN MAKING YOUR BOOKING WITH US.</p>
                                    <p ><b>The Treatment or Service Providers</b> </p>
                                    <p id="cancellation_policy">The Treatment or Service Providers will be in the process of completing their training, portfolios and/or case studies whilst providing our users with discounted treatments.  Treatment or Service Providers will therefore be able to save the portfolio of work which they carry out to the Website and App for our users to view at their convenience. Please speak to the Treatment or Service Provider directly in the event that you would rather that they do not showcase the work which they carry out in providing the Services to you on the Website or App.</p>
                                    <p >Our Treatment or Service Providers will be at least 18 years of age when providing the Services. </p>
                                    <p ><b>Cancellation Prior to Booking Acceptance</b></p>
                                    <p>If you change your mind about a Booking and wish to cancel it <b>prior to it being accepted</b> by the  Treatment or Service Provider, the Booking will not be processed and no money will be taken from you.</p>
                                    <p><b>Cancellation more than 36 Hours Before Booking</b></p>
                                    <p>If you wish to cancel a Booking more than 36 hours before it is due to commence, in the event that you only paid the deposit prior to the cancellation, the Booking will be cancelled, and no further money will be due from you but your 15% deposit will not be repaid to you.</p>
                                    <p>In the event that you had paid the total amount due for the Booking, you will be refunded 85% of the total sum paid with the deposit of 15% not being repaid to you.</p>
                                    <p><b>Cancellation less than 36 Hours Before Booking</b></p>
                                    <p>If you wish to cancel a Booking less than 36 hours before it is due to commence, you will not be entitled to any refund.</p>
                                    <p>In order to cancel your Booking, this should be done through the Website or App directly. </p>
                                    <p><b>Resolving Issues</b></p>
                                    <p>GGP cares about your experience and wants to ensure that we maintain the highest standards possible and so if you would like to make a complaint about one of our Treatment or Service Providers or their Services, please either:</p>
                                    <p>(i)	speak to the Treatment or Service Provider yourself to try and resolve the issue; and/or</p>
                                    <p>(ii)	email us at <a href="mailto:hello@goginipig.com">hello@goginipig.com </a>  or write to us at GoGiniPig, 1a Albany Road, Stratford-Upon-Avon, Warwickshire, CV37 6PG.</p>
                                    <p>Following receipt of a complaint we will contact the Treatment or Service Provider to try and resolve the issue on your behalf. In the event that we are unable to resolve the issue following discussions with the Treatment or Service Provider, if we consider it to be fair in all the circumstances, we may at our sole discretion elect to:</p>
                                    <p>1.	give you a credit note for the disputed/complained about amount of your Booking (to be redeemed on the Website or the App to reduce the purchase price of any Services); or</p>
                                    <p>2.	as a last resort or in extreme circumstances and always at our sole discretion, refund the disputed/complained about amount directly to you via the original method of payment. Please note that it might take up to 30 days to receive a full refund in such cases.</p>
                                    <p>PLEASE NOTE THAT WHILST WE TAKE ALL COMPLAINTS ABOUT OUR TREATMENT OR SERVICE PROVIDERS VERY SERIOUSLY AND WILL ALWAYS DO WHAT WE CAN TO HELP TO RESOLVE SUCH COMPLAINTS, WE ARE NOT RESPONSIBLE TO YOU FOR THE SERVICE(S) WHICH THE TREATMENT OR SERVICE PROVIDERS PROVIDE AND ARE UNDER NO OBLIGATION TO PROVIDE YOU WITH A REFUND OR CREDIT NOTE IN THE EVENT YOU ARE DISSATISFIED WITH THE SERVICES WHICH YOU HAVE RECEIVED FROM OUR TREATMENT OR SERVICE PROVIDERS. </p>

                                    <p><b>Price and Payment</b></p>
                                    <p>Prices (including any processing charges) will be as quoted on the Website or App but may be subject to change by GGP or our Treatment or Service Providers at any time (in which case the Website or App will be updated accordingly). </p>
                                    <p>Payment for all Services must be made at the time of booking in pounds sterling by credit or debit card, through our third-party payment provider, Stripe ("Payment Facility"). Please note that this Payment Facility is subject to the third-party provider’s terms and conditions, privacy policy and other terms of You will be responsible for protecting the confidentiality of your Website or App user ID and any password or other security information used by you to access your account on the Payment Facility. We take reasonable care to ensure that the Payment Facility is available and functioning at all times, but cannot guarantee continuous, uninterrupted or secure access to such Payment Facility, nor can we guarantee that the Payment Facility will be virus or error free. We use third party payment service providers to process payments and because there are many factors beyond our control (such as delays in the banking system or in card networks), we cannot predict or guarantee the amount of time needed to complete the processing of your payment. Access to the Payment Facility may be occasionally restricted to allow for repairs, maintenance or the introduction of new facilities or services. We will attempt to provide reasonable notice of any scheduled interruptions to such Payment Facility and will do what we can to restore the facility as soon as reasonably possible.</p>
                                    <p>GGP will collect the payment, through the Payment Facility, in its capacity as the commercial agent of the Treatment or Service Provider. Once GGP has successfully received your payment for the Services, this will discharge your debt to the Treatment or Service Provider on whose behalf GGP has collected the payment.</p>
                                    <p><b>Liability</b></p>
                                    <p>Where we have been negligent and/or breached a contractual obligation to you, we will be liable for any loss or damage you suffer as a result, provided that loss and/or damage is reasonably foreseeable. Loss or damage is reasonably foreseeable if it is an obvious consequence of our negligence or breach of contract, or would have been considered by you and us to be a likely consequence of it at the time we entered into the GGP Contract.</p>
                                    <p>We do not accept any liability for the following types of loss, whether caused by breach of contract, tort (including negligence) or otherwise, even if the loss is foreseeable: loss of income or revenue; loss of business; loss of profits; loss of anticipated savings; or waste of management or office time.</p>
                                    <p>We do not exclude or limit our liability for death or personal injury arising from our negligence, for fraud or fraudulent misrepresentation, or for any other liability which cannot be excluded or limited under English law.</p>
                                    <p><b>Our right to vary these Booking Terms and Conditions</b></p>
                                    <p>We may revise these Booking Terms and Conditions from time to time in the following circumstances:</p>
                                    <ul>
                                        <li>if we change the process for accepting payment from you;</li>
                                        <li>if there are changes in relevant laws and regulatory requirements; and/or</li>
                                        <li>if there are any other changes to our business that reasonably mean we need to amend these Booking Terms and Conditions.</li>
                                    </ul>
                                    <p>Every time you make a Booking via the Website or App, the Booking Terms and Conditions in force at that time (and available for view on the Website and App and accepted by you at check-out) will apply to the GGP Contract between you and us and the Treatment or Service Providers Contract between you and the Treatment or Service Provider. You can find the date on which these Booking Terms and Conditions were last updated at the top of this page.
                                    </p>
                                    <p><b>General</b></p>
                                    <p>Following the completion of the Services, you will be able to leave a public review (“Review”) of your Booking and the Services received on the Website and will be able to submit a star rating (“Rating”) for such Booking and Services. Ratings or Reviews should reflect your opinion. Such Ratings and Reviews will not be verified by us for accuracy.</p>
                                    <p>All communications and notices from you must be sent to GGP by email at <a href="mailto:hello@goginipig.com">hello@goginipig.com</a> or by post to Go Ginipig Limited, 1a Albany Road, Stratford-Upon-Avon, Warwickshire, CV37 6PG. GGP may communicate and give notice to you via post, email or by posting notices on the Website or App.</p>
                                    <p>GGP reserves the right to deactivate your GGP account in the event of a breach of these Booking Terms and Conditions and/or where you act in a way that is inappropriate, abusive or otherwise unacceptable towards any of our team or to a Treatment or Service Provider, either in communications via phone or email, or in person.</p>
                                    <p>If any of these Booking Terms and Conditions are determined by a competent authority to be invalid, unlawful or unenforceable to any extent, such term, condition or provision will to that extent be severed from the remaining terms, conditions and provisions which will continue to be valid to the fullest extent permitted by law.</p>
                                    <p>These Booking Terms and Conditions will be governed by and construed in accordance with the laws of England. You and we each agree that the English courts will have non-exclusive jurisdiction over any claim or dispute arising from, or related to, the ordering and/or supply of Services via the Website or App.</p>
                                </div>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    {/* pivacy policy */}

                    <Row>
                        <Col className="col-lg-11 m-auto">
                            <div className="requirement_wrp padright-0 mt-24" name="pp">
                                    <div className="white_wrp" id="privacy_policy">
                                        <div className="content-box">
                                            <h3 className="heading">PRIVACY POLICY</h3>
                                            <p> Hello, welcome to the GoGiniPig Privacy Policy. At GoGiniPig (‘GGP’), we are committed to keeping your personal information safe and secure, whether you are a GiniPig or a Treatment Provider, and handling it in accordance with our legal obligations. This Privacy Policy sets out in detail the purposes for which we process your personal information, what rights you have in relation to that information, who we share it with and everything else we think is important for you to be aware of.</p>
                                            <p>Please make sure you check it carefully and if you do not agree with it, then (although we hate to turn you away) you should not use our digital products or services. This is because by accessing our digital products or services, you confirm that you accept the way in which we process your personal information. This Privacy Policy forms part of our Website <Slink to="/tc" spy={true} smooth={true} offset={-65}> Terms of Use </Slink>, <a href="https://stripe.com/gb/legal" target="_blank">Stripe terms of service</a> and capitalised words and phrases in it have the same meaning as those in our Terms.</p>   
                                            <p> If you have any concerns, please feel free to contact us at <a href="mailto:hello@goginipig.com" className="email-border"> hello@goginipig.com </a>.  </p>                                


                                            <h3 className="heading"> About GGP </h3>
                                            <p>Go Ginipig Limited, trading as GoGinipig (referred to as <span className="bold"> Go Ginipig, GGP, we, our </span> or <span className="bold"> us </span> ) is a limited company incorporated in England and Wales, with registered company number 12201976 and registered at 1a Albany Road, Stratford-Upon-Avon, Warwickshire, CV37 6PG. We are the data controller for the purposes of the personal information processed in accordance with this Privacy Policy.  
                                            </p>
                                            <p> Our Data Protection Officer is responsible for our approach to data protection and protecting your privacy. You can contact our Data Protection Officer via email at: <a href="mailto:hello@goginipig.com">hello@goginipig.com</a> . </p>
                                            <p><span className="bold">Contents of this Privacy Policy: </span></p>
                                             <ol className="olderlist">
                                                <li> <Slink to="oneDiv" spy={true} smooth={true} offset={-65}> About this Privacy Policy </Slink> </li>
                                                <li> <Slink to="twoDiv" spy={true} smooth={true}  offset={-65}> The personal information we collect, how we collect it, and why </Slink> </li>
                                                <li> <Slink to="threeDiv" spy={true} smooth={true} offset={-65} > Our legal basis for processing personal information </Slink> </li>
                                                <li> <Slink to="fourDiv" spy={true} smooth={true} offset={-65}> When do we share your personal information? </Slink> </li>
                                                <li> <Slink to="fiveDiv" spy={true} smooth={true} offset={-65}> Communications </Slink> </li>
                                                <li> <Slink to="sixDiv" spy={true} smooth={true} offset={-65}> How long do we store your personal information? </Slink> </li>
                                                <li> <Slink to="sevenDiv" spy={true} smooth={true} offset={-65}> Security of your personal information </Slink> </li>
                                                <li> <Slink to="eightDiv" spy={true} smooth={true} offset={-65}> Links </Slink> </li>
                                                <li> <Slink to="nineDiv" spy={true} smooth={true} offset={-65}> Age Restrictions</Slink> </li>
                                                <li> <Slink to="tenDiv" spy={true} smooth={true} offset={-65}> Your rights and choices  </Slink> </li>
                                                <li> <Slink to="elevenDiv" spy={true} smooth={true} offset={-65}> Contacting us</Slink> </li>
                                                <li> <Slink to="tewelDiv" spy={true} smooth={true} offset={-65}> Browser and device local storage</Slink> </li>
                                                <li> <Slink to="ThirteenDiv" spy={true} smooth={true} offset={-65}> Cookies</Slink> </li>
                                             </ol>
                                        
                                            <div id="oneDiv">
                                             <h3 className="heading"> 1.	About this Privacy Policy </h3>
                                                <p>This Privacy Policy applies to the personal information we collect about you through our digital products, by telephone, by post, through our social media platforms, from third parties and when you otherwise communicate with us. </p>
                                                <p>This Privacy Policy may change from time to time and, if it does, the up-to-date version will always be available on our   website <a to="https://www.goginipig.com">www.goginipig.com</a>. </p>
                                            </div>
                                            <div id="twoDiv"> 
                                             <h3 className="heading"> 2.	The personal information we may collect, how we would collect it, and why </h3>

                                             <p>This section informs you of what information we may collect about you and why. </p>
                                             <p> Personal information means any information about an individual from which that individual can be identified. The following shows information we may process about you, and the purpose for which we would process that information. There may be more than one reason for which we would collect such information and we have only listed the main reasons. If you would like further information, please contact us via the email address above.   </p>
                                             <p>We have also included the legal basis on which we rely on to legitimately process your personal information. </p>
                                                <div className="tableDiv">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th> Information </th>
                                                        <th> The main reasons we would collect it </th>
                                                        <th> Legal basis for processing </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td colSpan="2"> Information you provide to us  </td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td> Name </td>
                                                        <td> Your name enables us to personally communicate with you, both through the platform, and externally in emails, SMS messages and other forms of communication.  </td>
                                                        <td> Performance of a contract with you. </td>
                                                    </tr>
                                                    <tr>
                                                        <td> <p> Email address </p> <p> Phone number</p> </td>
                                                        <td>  These are unique identifiers and allow us to personally communicate with you (e.g. sending marketing communications, service updates and send customer satisfaction surveys). Each one is always stored privately against your profile, so that only you and us can see it. </td>
                                                        <td> Performance of a contract with you. </td>
                                                    </tr>
                                                    <tr>
                                                        <td> City and country of residence Address or postal code  </td>
                                                        <td> To customise your experience and to ensure your browser and App settings are appropriately localised. Your address or postal code is always kept private. </td>
                                                        <td> Performance of a contract with you. </td>
                                                    </tr>
                                                    <tr>
                                                        <td> Date of birth  </td>
                                                        <td> To confirm that you are old enough to access our digital products and consent to the processing of your personal information (you must be 18 years of age to be a GiniPig or a Treatment Provider). We keep this information private. </td>
                                                        <td> Performance of a contract with you. </td>
                                                    </tr>
                                                    <tr>
                                                        <td> Gender </td>
                                                        <td> To populate your personal profile. This is optional and you do not need to tell us if you do not wish to.  </td>
                                                        <td>Necessary for our legitimate interests (to study how users use our services, to develop them and grow our business).</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Your preferences for receiving communications and notifications </td>
                                                        <td>We may store your preferences so we know exactly how to communicate with you (e.g. for marketing or sending service communications), and in some cases, how not to communicate with you.</td>
                                                        <td> Consent; and Necessary for our legitimate interests (to ensure that we are not at risk of breaching data protection laws by communicating with you where you have asked us not to). </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="2"> Information we collect automatically  </td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td> <p> Unique identifier </p> <p> Customer number </p>  </td>
                                                        <td> When a you sign up as either a GiniPig or a Treatment Provider, we generate this as a mechanism to identify you across our technical systems, and to link you with any applicable product preferences, service interaction analytics and customer service history.  </td>
                                                        <td> Necessary for our legitimate interests (to study how users use our services, to develop them and grow our business). </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Your interactions with our service</td>
                                                        <td> When you interact with our website or app, so that we can analyse how you are using our service and use that information to create improvements. </td>
                                                        <td> Necessary for our legitimate interests (to define types of users for our services, to keep the digital products updated and relevant, to develop our business and inform our marketing strategy). </td> 
                                                    </tr>

                                                </tbody>
                                            </table>
                                                </div>
                                            <p> We may also use all of the above information to establish, exercise and defend our legal rights. The lawful bases on which we rely upon to do such are where it is necessary for compliance with a legal obligation and where it is in our legitimate interest to establish, exercise or defend our legal rights.  </p>
                                            <p> In respect of all the above information, our overarching purpose is to enable us to generate a trusted, secure and engaged userbase. We want your information to be secure, but also visible to us so that we can provide a personalised customer service. For any questions or queries, please feel free to get in touch via the email address above.  </p>
                                           </div> 
                                            <div id="threeDiv">
                                            <h3 className="heading"> 3.	Our legal basis for processing personal information </h3>
                                            <p> We only ever use your information in line with applicable data protection laws - in particular, the EU General Data Protection Regulation <span className="bold"> (GDPR) </span>. In short, this means we only use it where we have a legal basis to do so. Under GDPR, the following are the general legal bases for which we process your personal information: </p>

                                            <ul>
                                                <li> <span className="bold">  Consent </span> - you have given us consent to process your personal information for a specific purpose that we have told you about. 
                                                </li>
                                                <li> <span className="bold"> Performance of our contract </span> - processing your personal information is necessary for a contract you have with us, or because we have asked you to take specific steps before entering into that contract. 
                                                </li>
                                                <li> <span className="bold">  Legitimate interests </span> - processing your personal information is necessary for our legitimate interests or those of a third party, provided those interests are not outweighed by your rights and interests (including where processing is required to comply with or enforce a legal obligation). 
                                                </li>
                                            </ul>
                                        </div>
                                            <div id="fourDiv">
                                            <h3 className="heading"> 4.	When do we share your personal information? </h3>
                                                <p>We may disclose your information for certain purposes and to third parties, as described below:</p>

                                                <ul>
                                                    <li> <span className="bold"> The GGP group of companies: </span>  we share your information within the GGP group of companies as required for: providing you with access to our services according to our agreement, data storage and processing, providing customer support, making internal choices around business improvements, content development, and for the other purposes set out in this Privacy Policy.</li>
                                                    <li> <span className="bold">  Third Party Providers: </span> We use certain companies, agents or contractors (Third Party Providers) to perform services on our behalf or to help deliver our services to you. We contract with Third Party Providers, for example to provide marketing, communications, infrastructure and IT services, to personalise and enhance our services, to provide customer service, to collect debts, to analyse and action data (including data about our users’ interactions with our digital products), and to process and administer consumer surveys. In the course of providing such services, these Third Party Providers may have access to your personal information. We do not authorise them to use or disclose your personal information except in connection with providing their services to us.</li>

                                                    <li>  <span className="bold"> Promotions with Our Providers: </span>  We may offer joint promotions, schemes or incentives with our selected partners that, in order for you to participate, will require us to share your information with the relevant partner. In fulfilling these types of promotions, we may share your name and other information in connection with fulfilling the relevant incentive. Please note that Our Providers are responsible for their own privacy and data protection methods and if applicable you should refer to their relevant privacy policy. </li>

                                                    <li>  <span className="bold"> To protect legitimate interests: </span>  There are certain circumstances where GGP and any of our third party providers may disclose and/or make use of your information where a disclosure would be necessary to: (a) satisfy any applicable law, regulation, legal process, or other legal or governmental request or requirement, (b) enforce applicable terms of use <a href="https://stripe.com/gb/legal" target="_blank">Stripe terms of service</a>, including investigation of any actual or alleged breaches, (c) detect, prevent, or otherwise address illegal or suspected illegal activities (including fraud), security or technical issues, or (d) protect against harm to the rights, property or safety of GGP, members or the public, as required or permitted by law. </li>

                                                    <li> <span className="bold"> Transfers of our business: </span>  In connection with any corporate reorganisation, restructuring, merger or sale, or other transfer of assets, we will transfer information, including personal information, provided that the receiving party agrees to comply with our requirements as set out in this Privacy Policy relating to your personal information. </li>

                                                </ul>
                                            </div>

                                            <div id="fiveDiv">
                                                    <h3 className="heading"> 5.	Communications </h3>
                                                    <p>This section is to explain how we will ensure that you only receive communications that you wish to receive. </p>
                                                    <h3 className="heading"> Marketing communications: </h3>
                                                    <p> We want to ensure that you are informed and aware of the best services and promotions that we can offer you. By consenting to receive additional communications (by mail, telephone, text/picture/video message or email) from us and any named third parties that feature at the point of obtaining consent in respect of such information, we will process your personal information in accordance with this Privacy Policy. </p>

                                                    <p> You can change your marketing preferences and unsubscribe at any time by accessing the settings within our digital products. If you choose not to receive this information we will be unable to keep you informed of new services and promotions of ours, or the GGP group of companies, that may interest you.  </p>

                                                    <p>Whatever you choose, you will still receive other important information, for example service updates, as described below. </p>

                                                    <h3 className="heading"> Service communications: </h3>
                                                    <p> As detailed in the table at section 2 above, we may send you communications such as those which relate to any service updates (e.g. service availability) or provide customer satisfaction surveys. We consider that we can lawfully send these communications to you as we have a legitimate interest to do so, namely to effectively provide you with the best service we can and to grow our business. </p>
                                            </div>

                                            <div id="sixDiv">
                                                <h3 className="heading"> 6.	How long do we store your personal information? </h3>
                                                <p> This section explains the length of time that we will retain your personal information.  </p>
                                                <p>We keep your personal information for only as long as is necessary to provide you with our service and for our legitimate and necessary business purposes. Such purposes might include maintaining the high standards of service which we strive to uphold, making decisions on how to progress our offering, complying with applicable legal obligations, and resolving any disputes which arise in the course of our business. </p>
                                                <ul>
                                                    <p>In accordance with this Privacy Policy, you have the right to request that we delete your personal information, except where we are legally permitted or required to maintain certain personal information. For example:</p>
                                                    <li>If there is an unresolved issue relating to your account, for example relating to outstanding an unresolved dispute, then we will retain your personal information until the issue is resolved.</li>
                                                    <li>There may be other situations where we have legitimate business interests to retain personal information, such as to prevent fraud or protect security of our other users. </li>
                                                </ul>
                                                <p>Any third party providers that we engage will keep your personal information stored on their systems for as long as is necessary to provide the relevant services to you or us. If we end our relationship with any third party providers, we will make sure that they securely delete or return your personal information to us. </p>
                                                <p>We may retain personal information about you for statistical purposes. Where information is retained for statistical purposes it will always be anonymised, meaning that you will not be identifiable from that information.</p>
                                            </div>

                                            <div id="sevenDiv">
                                                    <h3 className="heading"> 7.	Security of your personal information </h3>
                                                    <p>This section explains how we keep your personal information safe and where it will be held. </p>
                                                    <p> We are committed to securing and protecting your personal information, and we make sure to implement appropriate technical and organisational measures to help protect the security of your personal information. We have implemented various policies including anonymisation, pseudonymisation, encryption, access, and retention policies to guard against unauthorised access and unnecessary retention of personal information in our systems.  </p>
                                                    <p>The information that we collect from you may be transferred to, and stored at, a destination outside of the European Economic Area (EEA). When we transfer and store your personal information outside of the EEA we will take steps to ensure that the information is transferred in accordance with this Privacy Policy and applicable data protection laws. In particular, we will ensure that appropriate contractual, technical, and organisational measures are in place with any parties outside the EEA such as the Standard Contractual Clauses approved by the EU Commission.</p>

                                                    <p>Unfortunately, the transmission of your personal information via the internet is not completely secure and although we do our best to protect your personal information, we cannot guarantee the security of your information transmitted to us over the internet and you acknowledge that any transmission is at your own risk. </p>
                                            </div>
                                            <div id="eightDiv">
                                                    <h3 className="heading"> 8.	Links</h3>
                                                    <p>This section explains the risks of clicking on any link within our digital products. </p>
                                                   
                                                    <p> Our digital products may, from time to time, contain links to websites operated by third parties, which will usually be those of our commercial partners. This Privacy Policy only applies to the personal information that we collect from you and we cannot be responsible for personal information collected and stored by third parties. If you click on a link, please understand that the relevant third party websites have their own terms and conditions and privacy policies, and we do not accept any responsibility for the content of those third party websites or third party terms and conditions or policies. Please check these policies before you submit any personal information to these websites.  </p>
                                            </div>

                                            <div id="nineDiv">
                                                    <h3 className="heading"> 9.	Age Restrictions </h3>
                                                    <p>You must be a minimum of 18 years of age or older in order to be a GiniPig or Treatment Provider. </p>
                                            </div>

                                            <div id="tenDiv">
                                                    <h3 className="heading"> 10. Your rights and choices  </h3>
                                                    <p>This section explains that you have a number of rights in relation to your personal information. </p>

                                                    <ul>
                                                        <p>Under GDPR, as a user of our digital products, you are entitled to certain rights. There are circumstances in which your rights may not apply. You have the right to request that we:</p>

                                                        <li>provide you with a copy of the information we hold about you;</li>
                                                        <li>update any of your personal information if it is inaccurate or out of date; </li>
                                                        <li> delete the personal information we hold about you - if we are providing services to you and you ask us to delete personal information we hold about you then we may be unable to continue providing those services to you;  </li>
                                                        <li>restrict the way in which we process your personal information; </li>
                                                        <li>stop processing your data if you have valid objections to such processing; and</li>
                                                        <li> transfer your personal information to a third party.  </li>

                                                    </ul>

                                                        <p>For more information on your rights and how to use them, or if you would like to make any of the requests set out above, please contact us. </p>
                                                        <p>We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal information (or to exercise any of your other rights). This is a security measure to ensure that personal information is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response. </p>
                                                        <p>As explained in the section on Communications above, even if you consented to the processing of your personal information for marketing purposes (by ticking the relevant box or by requesting information about services), you have the right to ask us to stop processing your personal information for such purposes. You can exercise this right at any time by contacting us at <a href="mailto:hello@goginipig.com" className="email-border"> hello@goginipig.com </a>  or adjusting your privacy and notification settings within the digital products. Please note that we reserve the right to charge a fee for responding to requests where we reasonably determine that they are manifestly unfounded or onerous or being made in bad faith.</p>
                                                        
                                            </div>

                                            <div id="elevenDiv">
                                                <h3 className="heading"> 11.	Contacting us  </h3>
                                                <p>If you have any questions or concerns about how we handle your personal information, please contact our Data Protection Officer (DPO), by email at <a href="mailto:hello@goginipig.com" className="email-border"> hello@goginipig.com </a>  </p>
                                                <p> If you are unsatisfied with our response to any data protection issues you raise with us or our DPO, you have the right to make a complaint to the <a href="https://ico.org.uk/concerns/"  target="_blank"> Information Commissioner’s Office (ICO) </a> . The ICO is the authority in the UK which is tasked with the protection of personal information and privacy.   </p>
                                            </div>

                                            <div id="tewelDiv">
                                                <h3 className="heading"> 12.	Browser storage </h3>
                                                <p> Our digital products use browser storage files – the browser storage files are for if you are using the progressive web app. Browser storage files are files containing small amounts of information which are downloaded to your personal computer, mobile or other device when you visit an app or website. In particular, our browser storage files generate a unique identification number for each user which we use to collect certain information on that user.  </p>
                                                <p> We use browser storage files to store data locally on your device to enhance the user experience on our digital products. For example, browser storage files allows us to save the state of your current page so that when you return at a later time or refresh the browser or app, the page will remain the same. We may also use browser storage files to: (i) save a list of items a user last accessed on the digital products (such as events and properties the user selected) so we can display “recently viewed items”; or (ii) marking once a user has seen a tutorial, video, tooltip or onboarding flow so that the user only sees the content one time.   </p>
                                            </div>
                                            <div id="ThirteenDiv">
                                                <h3 className="heading"> 13.	Cookies </h3>
                                                <p> In common with other commercial websites and apps, we use standard technologies including cookies and similar tools to enhance your user experience, improve our site and provide tailored offers on GGP and other sites. </p>
                                               <p>Details of the cookies that we use on our App are listed below:</p>
                                               <p>Preferences: App will use local-storage for saving search filters & profile preference data.</p>
                                               <p>Security: App will use local-storage for storing. </p>
                                               <p>Processes: App will use local-storage for storing information at the time of treatment creation.</p>
                                               <p>Session State: App will use session state for storing tokens which will be used in API calls.</p>
                                               <p>Analytics: App will use google analytics.</p>

                                                 <p>Using cookies, we may collect the following data from you using the GGP Website:</p>       
                                                   {/* <p>Your name and address. </p> 
                                                   <p>Your username and password. </p> 
                                                   <p>Your email address.</p>  */}
                                                   <p>Your Internet protocol (IP) address.  </p> 
                                                   <p>Your device and browser information. </p> 
                                                   {/* <p>Your phone number.</p>  */}


                                            </div>


                                        </div>
                                    </div>
                            </div>


                        </Col>
                    </Row>

                </Container>
            </section>
        </>
    );
}

export default TermCondition;