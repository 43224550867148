import React, { useEffect } from 'react';
import {Link,useHistory} from 'react-router-dom';
import { Container,Row,Col } from 'react-bootstrap';
import {LeftArrow,og_lpimg4} from '../../config/images';
import bannernew4 from '../../assets/images/landscapeImg.jpg';



const WhoAreGgp = (props) => {
    let history = useHistory();
    
    const __reDirectPage = (e) =>{
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        __reDirectPage()
    }, [])

    return (
        <>
            <section className="pracitioner Whoggp" >
                <Container>
                    <Row>
                        <Col className="col-lg-10 m-auto vertical-center">
                        <div className="heading-history">
                <div className="back-history">
                  <button type="button" className="back-btn" 
                  onClick={ ()=> {
                      setTimeout(__reDirectPage(), 300)
                      history.goBack()
                  }}  >
                    <img src={LeftArrow} alt="arrow" />
                  </button>
                </div>
                
              </div>
              
                        </Col>
                    </Row>
                </Container>

            <div className="img-box leftSideImg">
                  <div className="pracitioner-bg imgwidth "  style={{ backgroundImage:`url(" ${bannernew4} ")`}}></div>
                  <div className="pract_content img-info pract-content-who-w">
                <div className="prect_inner">
                    <h3>Let’s Tell You More… </h3>
                    <p>
                        <b>GiniPig definition ~ Traditionally called ‘models’, a GiniPig is a fabulous person who enables Providers to perfect their skills, whether they’re new to their Industry or Pro’s. We affectionately call them GiniPigs as they are worthy of their own new title. They can spot a fab opportunity when they see it. </b>    
                    </p>  
                    <p>
                    Registering as one of our fabulous GiniPigs is <b>so easy</b>. By signing up you can search <b>amazing treats</b> and<b> new to market treatments,</b> copying your fave <b>Celebrity looks; luscious lips, volume lashes,</b> or take a break from it all with some <b>Mindfulness, Yoga</b> or perhaps some <b>Personal Training</b>. From <b>Trainees</b> or <b>Newbies</b> adding new skills to their menu, to <b>Pro’s</b> and <b>Stars</b> that need WOW <b>Insta images</b>, new <b>creative looks</b> or filling last minute appointments in their diary. We’ve thought of it all; we are your <b> BFF’s </b> for a reason. 
                    </p>        
                    <p>You’ll go from appointments to collecting amazing reviews in the blink of an eye (framed by perfect lashes of course)!  </p>
                    <p>Register with a <b> gorgeous</b> & <b>smiley</b> profile picture of yourself. It will make you stand out to the Treatment & Service Providers you choose, so you will soon be <b>snapped up!</b></p>
                    <p>Once you have found a fabulous <b>‘must have’</b>, send off an enquiry, we will ask you for your payment at this point (don’t worry no money will be taken here until your appointment is confirmed, it’s like a restaurant reservation to show you are committed) all you have to do is sit back, cross your fingers and wait for the <b>magic</b> acceptance email from your chosen Provider. </p>
                    <p>We’ll take care of those secure payments at this point, so there’s no messing around at your appointment.  We’ll then send you the contact details of your booked Provider so you know exactly where you’re going. The only thing you need to think about, is if you’re going to leave them a tip, but we’ll leave that up to you.</p>
                    <p>If you haven’t been accepted on this occasion don’t worry, this happens to the best of us, don’t take it personally, GiniPigs know a good thing when they see it and someone has probably just beat you to it.  There is plenty more where they came from so keep those <b>eyes peeled</b>, there is no limit to how many you can book.</p>
                    <p>At the end of your appointment, we know your Provider will have appreciated your <b>patience & friendliness</b> and the fact that you were <b>on time</b>, they will be rating you on all of these, so don’t be late!  You’ll be asked to leave them a review too, so you’ll both feel happy. You’ll love how easy it is to feel a <b>million dollars</b> and get those celebrity ‘must have’ treatments at a fraction of the price - by fraction we mean <b>at least 30% off</b> the RRP. We know right -  Wow!  </p>
                    <h3>We’ve put the treat into treatment! </h3>
                    <div className="btn-align"> 
                        <Link to="/signup" onClick={__reDirectPage}  className="practi-btn" >Let’s Go</Link>
                    </div>
                </div>
              </div>
            </div>

            </section>  
        </>
    );
}

export default WhoAreGgp;