import React, { useCallback, useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';

import { resetcreateAppointmentData_Action, resetimageUploadAPI_Action,practitinerCategoryAPI_Action, resetcreatetreatmentAPI_Action, resetupcomingbookingAPI_Action, resettreatmentdetailsAPI_Action, resettreatmentaddreviewAPI_Action, resetedittreatmentAPI_Action, resetcreaterequestAPI_Action } from '../../stores/actions'
import { getLocalStorageData,clearLocalStorage } from '../../utils/storage'

const PractitionerAppointmentlist = () => {
    //state init
    const dispatch = useDispatch();
    let history = useHistory();


    const [formData, updateFormData] = useState(Object.freeze({
        request_type: "",
        show_postcode: false,
        selected_slots: [],
        selected_slots_morning: [],
        selected_slots_afternoon: [],
        selected_slots_evening: [],


        date: '',
        start_time_hrs: "",
        start_time_minutes: "",
        postcode: "",
        postcodeValue: "",
        country: "",
        city: "",
        latitude: "",
        longitude: "",

        ginipig_count: "",
        sub_category_id: [],
        treatment_sub_category: [],


        selected_time_slots: [],


        attributes_required: false,
        photograph_required: false,
        is_consentform_required: false,
        patchtest_required: false,

        model_requirements: "",
        photograph_requirements: "",
        consent_form_link: "",
        consentformName: "",
        title: "",
        description: "",
        categories: "",
        rrpprice: '',
        yourprice: '',
        file1: "",
        file2: "",
        file3: "",
        file4: "",
        hrs: "",
        minutes: "",
        selectedTreatment: [],
        selectedTreatmentId: "",
        activeclassformdata: "",
        category_id: "",
        reason_require_ginipig: "",
        expertise_level: "",
        imagearray1: [],
        imagearray2: [],
        imagearray3: [],
        imagearray4: [],

        request_image: [],

        date: '',
        start_time_hrs: "",
        start_time_minutes: "",
        postcode: "",
        ginipig_count: "",
        request_type: "",
        show_postcode: false,
        subCatStr: "",
        displayDate: "",
        ischecked_inperson: false,
        ischecked_online: false,
        selected_time_slots: [],
    }));



    // Selectors
    const userData = useSelector(state => state.userdetails);
    const loginUserData = useSelector(state => state.login);
    const registerData = useSelector(state => state.register);
    const Data = JSON.stringify(registerData);
    const AppointmentCreateData = useSelector(state => state.appointmentcreatedata);
    const practitionersignupaddinfoData = useSelector(state => state.practitionersignupaddinfo);


    // Use effects
    useEffect(() => {
        window.addEventListener('popstate', function (event) {
            __redirectProfilePg()
            // window.location.reload(false);
        }, false);

        try {
            let checkToken = "";
            const loginToken = loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token : "" : "";
            // const PractitionerToken= practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.token:"" : "";
            const RegisterToken = practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.token : "" : "";
            let LocalDatatoken = getLocalStorageData("loginData")

            if (loginToken != "") {
                checkToken = loginToken;
            } else if (RegisterToken != "") {
                checkToken = RegisterToken;
            }
            else if (LocalDatatoken !== "") {
                checkToken = LocalDatatoken.token;
            }
            // const loginToken=loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token: "" : "";
            if (checkToken === '') {
                // history.push('/login');
            }
            let requestDataLocal = getLocalStorageData("requestDataLocal")
            // console.log(requestDataLocal.RequestData)

            if (requestDataLocal.RequestData !== null && requestDataLocal.RequestData !== "" && requestDataLocal.RequestData !== undefined) {
                let selectedTimeArray = [];
                requestDataLocal.RequestData && requestDataLocal.RequestData.selected_time_slots.forEach(element => {
                    if (element.selected_slots_morning.length > 0 || element.selected_slots_afternoon.length > 0 || element.selected_slots_evening.length > 0) {
                        selectedTimeArray.push(element)
                    }
                })
                let subCatArray = []
                requestDataLocal.RequestData && requestDataLocal.RequestData.treatment_sub_category.forEach(element => {
                    subCatArray.push(element.name)

                })
                var str;
                if (subCatArray.length === 1) {
                    str = subCatArray.join()
                } else if (subCatArray.length === 2) {

                    str = subCatArray.join(" & ")
                } else if (subCatArray.length === 3) {
                    str = subCatArray[0] + "," + subCatArray[1] + " & " + subCatArray[2]
                }

                let newDate = ""
                let displayDate = requestDataLocal.RequestData.date
                if (displayDate !== null && displayDate !== undefined) {
                    let displayDaynew = displayDate.substring(0, 2)
                    let displayDay = ""

                    if (displayDaynew < 10) {
                        displayDay = displayDaynew.substring(1, 2)
                    } else {
                        displayDay = displayDaynew
                    }


                    let n = displayDate.substring(3, 5)
                    let newn = ""
                    if (n < 10) {
                        newn = n.substring(1, 2)
                    } else {
                        newn = n
                    }
                    //  console.log(n)
                    var month = new Array();
                    month[1] = "January";
                    month[2] = "February";
                    month[3] = "March";
                    month[4] = "April";
                    month[5] = "May";
                    month[6] = "June";
                    month[7] = "July";
                    month[8] = "August";
                    month[9] = "September";
                    month[10] = "October";
                    month[11] = "November";
                    month[12] = "December";
                    var displaymonth = month[newn];
                    //console.log("date", displayDate,displaymonth,displayDay)
                    if (displayDay == 1) {
                        newDate = displayDay + "st" + " " + displaymonth
                    } else if (displayDay == 2) {
                        newDate = displayDay + "nd" + " " + displaymonth
                    } else if (displayDay == 3) {
                        newDate = displayDay + "rd" + " " + displaymonth
                    } else {
                        newDate = displayDay + "th" + " " + displaymonth
                    }
                }



                let displaydate = newDate + " " + " @ " + " " + requestDataLocal.RequestData.start_time_hrs + ":" + requestDataLocal.RequestData.start_time_minutes

                let postcode = requestDataLocal.RequestData.postcode
                var lastThree = postcode.substr(postcode.length - 3);
                //console.log(lastThree)

                var first = postcode.substr(0, postcode.length - 3);
                //console.log(first)

                var postcodenew = first + " " + lastThree



                updateFormData({
                    ...formData,
                    selectedTreatmentId: requestDataLocal.RequestData ? requestDataLocal.RequestData.selectedTreatmentId : "",
                    subCatStr: str,
                    displayDate: displaydate,
                    rrpprice: requestDataLocal.RequestData ? requestDataLocal.RequestData.rrpprice : "",
                    yourprice: requestDataLocal.RequestData ? requestDataLocal.RequestData.yourprice : "",
                    file1: requestDataLocal.RequestData ? requestDataLocal.RequestData.file1 : "",
                    file2: requestDataLocal.RequestData ? requestDataLocal.RequestData.file2 : "",
                    file3: requestDataLocal.RequestData ? requestDataLocal.RequestData.file3 : "",
                    file4: requestDataLocal.RequestData ? requestDataLocal.RequestData.file4 : "",
                    hrs: requestDataLocal.RequestData ? requestDataLocal.RequestData.hrs : "",

                    minutes: requestDataLocal.RequestData ? requestDataLocal.RequestData.minutes : "",

                    activeclassformdata: requestDataLocal.RequestData ? requestDataLocal.RequestData.activeclassformdata : "",

                    reason_require_ginipig: requestDataLocal.RequestData ? requestDataLocal.RequestData.reason_require_ginipig : "",
                    expertise_level: requestDataLocal.RequestData ? requestDataLocal.RequestData.expertise_level : "",

                    imagearray1: requestDataLocal.RequestData ? requestDataLocal.RequestData.imagearray1 : "",
                    imagearray2: requestDataLocal.RequestData ? requestDataLocal.RequestData.imagearray2 : "",
                    imagearray3: requestDataLocal.RequestData ? requestDataLocal.RequestData.imagearray3 : "",
                    imagearray4: requestDataLocal.RequestData ? requestDataLocal.RequestData.imagearray4 : "",

                    request_image: requestDataLocal.RequestData ? requestDataLocal.RequestData.request_image : "",
                    attributes_required: requestDataLocal.RequestData ? requestDataLocal.RequestData.attributes_required : "",
                    photograph_required: requestDataLocal.RequestData ? requestDataLocal.RequestData.photograph_required : "",
                    is_consentform_required: requestDataLocal.RequestData ? requestDataLocal.RequestData.is_consentform_required : "",
                    patchtest_required: requestDataLocal.RequestData ? requestDataLocal.RequestData.patchtest_required : "",

                    model_requirements: requestDataLocal.RequestData ? requestDataLocal.RequestData.model_requirements : "",
                    photograph_requirements: requestDataLocal.RequestData ? requestDataLocal.RequestData.photograph_requirements : "",
                    consent_form_link: requestDataLocal.RequestData ? requestDataLocal.RequestData.consent_form_link : "",
                    consentformName: requestDataLocal.RequestData ? requestDataLocal.RequestData.consentformName : "",
                    startTime: requestDataLocal.RequestData.startTime ? requestDataLocal.RequestData.startTime : "",
                    date: requestDataLocal.RequestData ? requestDataLocal.RequestData.date : "",
                    start_time_hrs: requestDataLocal.RequestData ? requestDataLocal.RequestData.start_time_hrs : "",
                    start_time_minutes: requestDataLocal.RequestData ? requestDataLocal.RequestData.start_time_minutes : "",
                    postcode: postcodenew,

                    postcodeValue: requestDataLocal.RequestData ? requestDataLocal.RequestData.postcodeValue : "",
                    country: requestDataLocal.RequestData ? requestDataLocal.RequestData.country : "",
                    city: requestDataLocal.RequestData ? requestDataLocal.RequestData.city : "",
                    latitude: requestDataLocal.RequestData ? requestDataLocal.RequestData.latitude : "",
                    longitude: requestDataLocal.RequestData ? requestDataLocal.RequestData.longitude : "",
                    locationprofile: requestDataLocal.RequestData.locationprofile ? requestDataLocal.RequestData.locationprofile : "",
                    use_profile_location: requestDataLocal.RequestData.use_profile_location ? requestDataLocal.RequestData.use_profile_location : 1,
                    ginipig_count: requestDataLocal.RequestData ? requestDataLocal.RequestData.ginipig_count : "",
                    request_type: requestDataLocal.RequestData ? requestDataLocal.RequestData.request_type : "",
                    show_postcode: requestDataLocal.RequestData ? requestDataLocal.RequestData.show_postcode : "",
                    ischecked_online: requestDataLocal.RequestData.ischecked_online ? requestDataLocal.RequestData.ischecked_online : false,
                    ischecked_inperson: requestDataLocal.RequestData.ischecked_inperson ? requestDataLocal.RequestData.ischecked_inperson : false,

                    selected_time_slots: selectedTimeArray,

                    category_id: requestDataLocal.RequestData ? requestDataLocal.RequestData.category_id : "",
                    category_name: requestDataLocal.RequestData ? requestDataLocal.RequestData.name : "",
                    sub_category_id: requestDataLocal.RequestData ? requestDataLocal.RequestData.sub_category_id : "",
                    title: requestDataLocal.RequestData ? requestDataLocal.RequestData.title : "",
                    description: requestDataLocal.RequestData ? requestDataLocal.RequestData.description : "",
                    categories: requestDataLocal.RequestData ? requestDataLocal.RequestData.categories : "",
                    treatment_sub_category: requestDataLocal.RequestData ? requestDataLocal.RequestData.treatment_sub_category : []

                });

            }

        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }

    }, [])

    const __redirectProfilePg = () => {
        dispatch(resetedittreatmentAPI_Action());
        dispatch(resetcreateAppointmentData_Action())
        dispatch(resetcreatetreatmentAPI_Action());
        dispatch(resetcreatetreatmentAPI_Action());
        dispatch(resettreatmentdetailsAPI_Action());
        dispatch(resetupcomingbookingAPI_Action());
        window.removeEventListener('popstate', function (event) {
            // history.go(1);
        }, false);

        window.scrollTo(0, 0);
        history.push({
            pathname: '/practitioner-account',
            iswindowreload: true
        });

    }

    const __redirectexplore = () => {
        try {
            dispatch(resetedittreatmentAPI_Action());
            dispatch(resetcreatetreatmentAPI_Action());
            dispatch(resettreatmentdetailsAPI_Action());
            dispatch(resetcreaterequestAPI_Action());

            window.scrollTo(0, 0);
            history.push('/');

        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }  
    }
    const __redirectTreatmentPg = useCallback(async () => {
        try {
            let checkToken = "";
            const loginToken = loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token : "" : "";
            // const PractitionerToken= practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.token:"" : "";
            const RegisterToken = practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.token : "" : "";
            let LocalDatatoken = getLocalStorageData("loginData")

            if (loginToken != "") {
                checkToken = loginToken;
            } else if (RegisterToken != "") {
                checkToken = RegisterToken;
            }
            else if (LocalDatatoken !== "") {
                checkToken = LocalDatatoken.token;
            }

            dispatch(practitinerCategoryAPI_Action(checkToken));
            dispatch(resetedittreatmentAPI_Action());
            // await dispatch(createAppointmentData_Action(formDataNew));
            // dispatch(resetcreateAppointmentData_Action())

            dispatch(resetcreatetreatmentAPI_Action());

            dispatch(resettreatmentdetailsAPI_Action());
            dispatch(resetcreaterequestAPI_Action());

            window.scrollTo(0, 0);
            history.push('/treatment-info');

        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    });

    const listappointments = useCallback(async () => {
        try {


          
            clearLocalStorage("requestDataLocal")

            clearLocalStorage("RedirectPathName")
            clearLocalStorage("selectedtreatmentId")
            clearLocalStorage("pathnamereviewedit")
            clearLocalStorage("treatmentcatId")
            clearLocalStorage("isfromAccount")
            dispatch(resetedittreatmentAPI_Action());
            dispatch(resetcreatetreatmentAPI_Action());
            dispatch(resettreatmentdetailsAPI_Action());
            dispatch(resetcreaterequestAPI_Action());

            dispatch(resetcreaterequestAPI_Action());
            dispatch(resetimageUploadAPI_Action())
            dispatch(resetcreateAppointmentData_Action());
            dispatch(resettreatmentdetailsAPI_Action());
            dispatch(resettreatmentdetailsAPI_Action());
            dispatch(resetedittreatmentAPI_Action());

            window.scrollTo(0, 0);
            history.push('/treatment-select');

        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    });

    const redirectSignup = useCallback(async () => {
        try {
            dispatch(resetedittreatmentAPI_Action());
            dispatch(resetcreatetreatmentAPI_Action());
            dispatch(resettreatmentdetailsAPI_Action());
            dispatch(resetcreaterequestAPI_Action());

            window.scrollTo(0, 0);
            history.push('/signup');

        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    });


    return (
        <>
            <section className="bgcolor-gray common-section appointment_confimation_pg  pb80">
                <Container>
                    <Row>
                        <Col className="col-lg-11 m-auto">
                            <div className="from-wrp mt-32">
                                <div className="appoint_conf_box pb-32 pt-70">
                                    <div className="heading_content">
                                        {/* <h2 className="title ">Your appointment has been listed</h2>
                                        <p className="decs mb-32 mt-24">
                                            You will notified when a GiniPig books this appointment, you will then be
required to accept or decline their booking within 16 hours but we would encourage you to do it ASAP</p> */}
                                        <h2 className="title ">Your Appointment Has Been Listed</h2>
                                        <p className="decs mt-24">When a GiniPig books this appointment an e-mail will be sent, you will then have a maximum of 16 hours to accept them.</p>
                                        <p className="decs mb-32">We don't want you to miss this opportunity, time is money, so we encourage you to hurry before they change their mind!</p>
                                    </div>
                                </div>

                                <div className="form-wrp mb-24">
                                    <div className="inside_content">
                                        <div className="categorylist-box">
                                            <div className="list_name">


                                                <span>{formData.title}</span>

                                                {/* )
                                                })
                                            } */}
                                            </div>

                                            <div className="category_price">
                                                <span className="price_strik og_price"> <span>£</span>{formData.rrpprice}</span>
                                                <span className="price_discounted"><span>£</span>{formData.yourprice}</span>
                                            </div>
                                        </div>
                                        <div className="category_badge">
                                            <span className="category_badge_name">{formData.categories.name}</span>
                                            <span className="category_badge_hrs">{formData.hrs > 0 ? formData.hrs + "h" : ""} {formData.minutes > 0 ? formData.minutes + "m" : ""}</span>

                                        </div>

                                        <div className="list-experiment mb-24">
                                            <div className="exp_list_item">
                                                <span className="icons_case icon_names">{formData.expertise_level} </span>
                                                <span className="icons_near icon_names"> {formData.reason_require_ginipig} </span>
                                                {
                                                    formData.ginipig_count === "1" ?
                                                        <span className="icons_gini icon_names">1 place left </span> :
                                                        <span className="icons_gini icon_names">{formData.ginipig_count} places left </span>
                                                }
                                            </div>
                                        </div>

                                        <div className="list-buttons">
                                            {
                                                formData.request_type === 1 &&
                                                <button className="varity-listbtn italic_btn"> Flexible availability </button>
                                            }
                                            {
                                                formData.request_type === 2 && formData.displayDate !== "" &&
                                                <button className="varity-listbtn italic_btn">{formData.displayDate} </button>
                                            }
                                            {formData.ischecked_online ?
                                                <button className="varity-listbtn mt-16"> Online </button> :
                                                <button className="varity-listbtn mt-16"> {formData.postcode}</button>

                                            }
                                        </div>

                                    </div>
                                </div>

                                <h2 className="whats-text mt-70">What's Next?</h2>
                                <div className="form-wrp mb-40 mt-16">
                                    <div className="c_box">
                                        <p className="not_txt">Need more GiniPigs & increase your earnings?</p>

                                        <button onClick={listappointments} className="completemyprofilebtn post_right">List an appointment </button>
                                    </div>

                                </div>

                                <div className="form-wrp mb-16 mt-40">
                                    <div className="c_box">
                                        <p className="not_txt">On a roll, build your talent menu</p>

                                        <button onClick={__redirectTreatmentPg} className="completemyprofilebtn post_right">Add talent to profile </button>
                                    </div>

                                </div>
                                <div className="form-wrp mb-16 mt-40">
                                    <div className="c_box">
                                        <p className="not_txt">All done for now and want to browse?</p>

                                        <button onClick={__redirectexplore} className="completemyprofilebtn post_right">Explore</button>
                                    </div>

                                </div>
                                <div className="form-wrp mb-16 mt-40">
                                    <div className="c_box">
                                        <p className="not_txt">Fancy treating yourself? Register as a GiniPig now</p>

                                        <button onClick={redirectSignup} className="completemyprofilebtn post_right">Sign up </button>
                                    </div>

                                </div>

                                {/* <div className="treatment btn-another  pt-110 pb-32 ">
                                    <div className="f-box">
                                        <p className="not_txt">Not ready to complete your profile?</p>
                                        <button onClick={__redirectProfilePg} className="addtreatmentbtns"> Back to Profile </button>
                                    </div>
                                </div> */}

                            </div>

                        </Col>
                    </Row>

                </Container>
            </section>
        </>
    );
}

export default PractitionerAppointmentlist;
