import React, { useCallback, useState, useEffect } from "react";
import { useHistory,useLocation} from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Container, Row, Col } from 'react-bootstrap';
import { LeftArrow,stripelogo} from '../../config/images';
import { acceptFailPaymentAPI_Action,resetacceptFailPaymentAPI_Action,createbookingAPI_Action, treatment_details,createOpenbookingAPI_Action,resetcreatebookingAPI_Action,resetLoginAPI_Action,resetUserAPI_Action,resetRegisterAPI_Action,resetPractitionerAddInfoAPI_Action,resettreatmentaddreviewAPI_Action,resetPractionerCategory_API_Action,resetpractitionerProfileAPI_Action,resetGinipigUpdateAPI_Action,resetgoginipigprofileAPI_Action,resetStripeAccountValidityAPI_Action
} from '../../stores/actions';
import PartialPayment from '../../components/PartialPayment';
import { getLocalStorageData, clearLocalStorage } from '../../utils/storage';
import LoadingSpinner from "../../components/UIComponent/LoadingSpinner"
import Modal from 'react-modal'
import { STRIPE_TOKEN } from "../../config/ApiConstants";

import LargeButton from '../../components/LargeButton';
import queryString from 'query-string';
import { bookingDetailsAPI_Action } from '../../stores/actions'


const TimeExpired =(props) =>{
    const stripePromise = loadStripe(STRIPE_TOKEN);
    let history = useHistory();
    const dispatch = useDispatch();
    const [isDataPayment, setIsDataPayment] = useState(false);
    const [disable, setDisable] = useState(false);
    const [modalIsOpen,setIsOpen] = React.useState(false);
    const createbooking = useSelector(state => state.createbooking);
    const acceptfailpayment = useSelector(state => state.acceptfailpayment);
    var param_data;
    var post_data;
    var type;
    var status;
    const bookingData = useSelector(state => state.bookingdetails);
    let params = queryString.parse(props.location.search)
    
    
    if(props.location.state)
    {
       
        param_data = props.location.state.paramData;
        post_data= props.location.state.postData;
        type = props.location.state.type;
        status=props.location.state.postData.intent_to_pay_status
    }
    const [isPhotoAdded,setIsPhotoAdded] = useState(false);
    var photo_added;
    const requestdetails = useSelector(state => state.requestdetails); 

   
    useEffect(()=>{
        
    });
   

    

    

    
    

    return (
            <section className="bgcolor-gray common-section payment_pg pb80">
                <Container>
                    <Row>
                        <Col className="col-lg-11 m-auto">
                            <div className="navigationTop">
                                <div className="heading-history">
                                    {/* <div className="back-history">
                                        <button type="button" onClick={() => history.goBack()} className="back-btn" >
                                            {" "}
                                            <img src={LeftArrow} alt="arrow" />{" "}
                                        </button>
                                    </div> */}
                                   
                                   
                                </div>
                            
                                    
                            </div>

                        <div className="requirement_wrp">                       
                            <div className="payment-populate stripe_label large mt-16">
                            <div>
                                       <p className="subheading" >Oops! The link has already expired</p>
                                       {/* <p className="subheading mt-24">Remaining Amount to Pay: £<span>{(requestdetails.requestdetailsdata && requestdetails.requestdetailsdata.data.request.your_price!==null&&(requestdetails.requestdetailsdata.data.request.your_price * 0.85).toFixed(2))}</span></p> */}
                                  </div>       
                            </div>
                               
                        </div>    
                            
                        </Col>
                    </Row>
                
                    

                </Container>
            </section>
    );

};

export default TimeExpired;