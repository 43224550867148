import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS,AUTH_HEADERS_NEW } from '../../config/ApiConstants';
export function PractionerfeatureImageAPI_Action(data,feature_image) {
    const formData = new FormData(); 
      formData.append('display_name', data.name);
      formData.append('bio', data.bio);
      
     // formData.append('profile_pic', data.profile_pic);
      for (var i = 0; i < feature_image.length; i++) {
        formData.append('featured_image[]', feature_image[i]);
      }
    
    return {
        [RSAA]: {
            // endpoint: `${API_ENDPOINT}/signup`,
            endpoint: `${API_ENDPOINT}/practitioner-update`,
            method: 'POST',
            headers: {
                // ...AUTH_HEADERS,
                // // 'content-type': 'multipart/form-data'
                // 'X-Authorization': '7fa88a29503ff00b31359e83dc4540bb11e3360f', 
                ...AUTH_HEADERS_NEW,
                'Authorization':'Bearer '+data.token


            },
            // body:JSON.stringify(data),
            body: formData,
            types: [
                types.PRACTITIONERBIO_REQUEST,
                types.PRACTITIONERBIO_RECEIVE, {
                    type: types.PRACTITIONERBIO_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}


export const ResetPractionerfeatureImageAPI_Action = () => {
    return {
        type: types.RESET_PRACTITIONERBIO,
        payload: null
    }
};
