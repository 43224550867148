import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS,AUTH_HEADERS_NEW } from '../../config/ApiConstants';
export function imageUploadAPI_Action(image) {
   const formData = new FormData(); 
     
      formData.append('image', image);
    
   
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/image/upload`,
            method: 'POST',
            headers: {
                // ...AUTH_HEADERS,
                ...AUTH_HEADERS_NEW,
                'Authorization':'Bearer '
            },
             body:formData,
          //  body: data,
            types: [
                types.IMAGEUPLOAD_REQUEST,
                types.IMAGEUPLOAD_RECEIVE, {
                    type: types.IMAGEUPLOAD_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}


export const resetimageUploadAPI_Action = () => {
    
    return {
        type: types.RESET_IMAGEUPLOAD,
        payload: null,
    }
};
