import React, { useCallback, useState, useEffect } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from 'react-bootstrap';
import { LeftArrow, iconClose, iconPhoto } from '../../config/images';
import LargeButton from '../../components/LargeButton';
import AppointmentCalender from '../../components/AppointmentCalenderSelected'
import DatePicker from "react-datepicker";
import LoadingSpinner from "../../components/UIComponent/LoadingSpinner"
import moment from 'moment';
import { acceptbookingAPI_Action, acceptenquiryAPI_Action,resetpractitionerrequestdetailsAPI_Action,resetopenrequestenquiryDetailsAPI_Action, practitionerrequestDetailsAPI_Action,resetLoginAPI_Action,resetacceptbookingAPI_Action, practionerdeclinebookingAPI_Action, pendingbookingDetailsAPI_Action, upcomingbookingAPI_Action, resetgoginipigprofileAPI_Action, resettreatmentaddreviewAPI_Action, resetacceptenquiryAPI_Action } from '../../stores/actions'
import Modal from 'react-modal';
import { getLocalStorageData, setLocalStorageData,clearLocalStorage } from '../../utils/storage'

const customStyles = {
    overlay: {
        background: "#dee2e663", zIndex: 999999
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        border: "none",
        background: "none"
    }
};
const SelectTimeAcceptBooking = (props) => {
    //init state
    const { dataN, Setdata } = useState(
        Object.freeze({
            date: new Date(),
        })
    )
    let history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    let caldates = []


    const [startDate, setStartDate] = useState(new Date());
    const [startMaxDate, setStartMaxDate] = useState(new Date());
    const [calenderobj, setCalenderobj] = useState();
    const [minuteArray, setMinuteArray] = useState([])
    const [minerror, setminerror] = useState([false])

    const [modalIsOpen, setIsOpen] = React.useState(false);


    const practitionersignupaddinfoData = useSelector(state => state.practitionersignupaddinfo);
    const acceptenquirydata = useSelector(state => state.acceptenquirydata);
    const practitionerrequestDetailsdata = useSelector(state => state.practitionerrequestdetailsdata);


    const [formData, updateFormData] = useState(Object.freeze({

        date: '',
        start_time_hrs: "",
        start_time_minutes: ""
    }));


    useEffect(() => {

        var datemax = new Date();
        datemax.setMonth(datemax.getMonth() + 3);
        setStartMaxDate(datemax)

        let calenderobj = getLocalStorageData("calenderobj")
        setCalenderobj(calenderobj)
        // console.log(calenderobj)
        if(calenderobj==undefined){
       
        history.push({
            pathname: '/practitioner-account',
            iswindowreload:false

        });
   
        }else{
       
        var minute = ""


        var minuteArray = []
        calenderobj.allTimeSlots.map((slot, index) => {
            var hrs = slot.substr(0, slot.indexOf(':'));


            if (calenderobj.hrsArray[0] === hrs) {
                minute = slot.substr(slot.length - 2);
                minuteArray.push(minute)

            }


        })

        let minuteArray1 = ["00", "05", "10", "15", "20", "25", "30"]
        let minuteArray2 = ["30", "35", "40", "45", "50", "55"]
        let minuteArray3 = ["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"]

        //console.log(minuteArray)
        if (minuteArray.includes("00") && minuteArray.includes("30")) {
            setMinuteArray(minuteArray3)
        } else if (minuteArray.includes("30")) {
            setMinuteArray(minuteArray2)
        } else if (minuteArray.includes("00")) {
            setMinuteArray(minuteArray1)
        }

        updateFormData({
            ...formData,
            start_time_hrs: calenderobj && calenderobj.hrsArray[0],
            start_time_minutes: calenderobj && minuteArray[0],
        });
        if (acceptenquirydata.acceptenquirydata && acceptenquirydata.acceptenquirydata.message !== "") {
            // dispatch(resetacceptenquiryAPI_Action());
            closeModal()

        }
             
    }

    }, [acceptenquirydata.acceptenquirydata])
    const acceptButtonClick = (e) => {
        e.preventDefault();
        let item = calenderobj.bookingDetails
        //  console.log(formData.start_time_hrs + ":" + formData.start_time_minutes)

        setLocalStorageData("acceptBooking", item)
        openModal(e);
        __apiCall(item)
        //  let bookingId = practitionerrequestDetailsdata.practitionerrequestdetailsdata && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.booking.id
        // __apiCall(item)
    }
    const __apiCall = useCallback(async (item) => {
        let formDatanew = {
            id: item.uuid,
            time: formData.start_time_hrs + ":" + formData.start_time_minutes,

        }

        try {

            let checkToken = "";

            const RegisterToken = practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.token : "" : "";
            let LocalDatatoken = getLocalStorageData("loginData")

            if (RegisterToken != "") {
                checkToken = RegisterToken;
            }
            else if (LocalDatatoken !== "") {
                checkToken = LocalDatatoken.token;
            }
            await dispatch(practitionerrequestDetailsAPI_Action(item.request.uuid, checkToken));
            await dispatch(acceptenquiryAPI_Action(formDatanew, checkToken));
            // await dispatch(practitionerrequestDetailsAPI_Action(requestArray.id, checkToken));
            window.scrollTo(0, 0);
            // history.push({
            //     pathname: '/booking-accepted',
            //     bookingdata: item

            // });
        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    });

    if (practitionerrequestDetailsdata.practitionerrequestdetailsdata && practitionerrequestDetailsdata.practitionerrequestdetailsdata.status_code === 401) {
      
        dispatch(resetpractitionerrequestdetailsAPI_Action())
        dispatch(resetopenrequestenquiryDetailsAPI_Action())
        dispatch(resetLoginAPI_Action())
        clearLocalStorage("loginData")
        clearLocalStorage("practitionersignupData")
        clearLocalStorage("RedirectPathName")
        clearLocalStorage("requestDataLocal")
        clearLocalStorage("confirmData")
        clearLocalStorage("openrequestdata")
        clearLocalStorage("closerequestdata")
        clearLocalStorage("noresponseData")
        window.scrollTo(0, 0);
        history.push('/login');
    }

    const openModal = (e) => {
        e.preventDefault();
        setIsOpen(true);
    }

    const closeModal = (item) => {
        setIsOpen(false);

    }

    if (acceptenquirydata.isSuccess === true) {
        setLocalStorageData("accetptbookingdata", acceptenquirydata)
        dispatch(resetacceptenquiryAPI_Action());
        closeModal()

        window.scrollTo(0, 0);
        history.push({
            pathname: '/booking-accepted',
            fromacceptbooking:true
        });
    }

    if (acceptenquirydata.acceptenquirydata && acceptenquirydata.acceptenquirydata.status_code === 401) {
        dispatch(resetacceptbookingAPI_Action());
        dispatch(resetpractitionerrequestdetailsAPI_Action())
        dispatch(resetopenrequestenquiryDetailsAPI_Action())
        dispatch(resetLoginAPI_Action())
        clearLocalStorage("loginData")
        clearLocalStorage("practitionersignupData")
        clearLocalStorage("RedirectPathName")
        clearLocalStorage("requestDataLocal")
        clearLocalStorage("confirmData")
        clearLocalStorage("openrequestdata")
        clearLocalStorage("closerequestdata")
        clearLocalStorage("noresponseData")
        closeModal()

        window.scrollTo(0, 0);
        history.push({
            pathname: '/login',
        });
    }

    // if (acceptenquirydata.isError === true) {
    //     dispatch(resetacceptenquiryAPI_Action());
    //     closeModal()

    //     // window.scrollTo(0, 0);
    //     // history.push({
    //     //     pathname: '/booking-accepted',
    //     // });
    // }
    const setDatepick = (date) => {
        //setStartDate(date)
        var date = new Date(date),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        let selecteddate = [day, mnth, date.getFullYear()].join("-");

        updateFormData({
            ...formData,
            date: selecteddate
        })


    }
    const goBackPrevious = () => {
        let item = calenderobj.bookingDetails
        //  let bookingId = practitionerrequestDetailsdata.practitionerrequestdetailsdata && practitionerrequestDetailsdata.practitionerrequestdetailsdata.data.booking.id
        __apiCallrequest(item)

    }

    const __apiCallrequest = useCallback(async (item) => {
        // console.log(item)

        try {
            let checkToken = "";
            const RegisterToken = practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.token : "" : "";
            let LocalDatatoken = getLocalStorageData("loginData")

            if (RegisterToken != "") {
                checkToken = RegisterToken;
            }
            else if (LocalDatatoken !== "") {
                checkToken = LocalDatatoken.token;
            }
            await dispatch(practitionerrequestDetailsAPI_Action(calenderobj.requestId, checkToken));
            //  await dispatch(practitionerrequestDetailsAPI_Action(requestArray.id, checkToken));
            window.scrollTo(0, 0);
            history.push({
                pathname: '/accept-open-request',
                bookingDetails: item

            });
        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    });

    const onHrsChange = (event) => {
        updateFormData({
            ...formData,
            start_time_hrs: event.target.value,
        });

        var minute = ""


        var minuteArray = []
        calenderobj.allTimeSlots.map((slot, index) => {
            var hrs = slot.substr(0, slot.indexOf(':'));


            if (event.target.value === hrs) {
                minute = slot.substr(slot.length - 2);
                minuteArray.push(minute)
                // console.log(minute)
                // if(minute==="00" && minute==="30"){
                //     setMinuteArray(minuteArray3)
                // }else if(minute==="30"){
                //     setMinuteArray(minuteArray2)
                // }else if(minute==="00"){
                //     setMinuteArray(minuteArray1)  
                // }
            }


        })

        let minuteArray1 = ["00", "05", "10", "15", "20", "25", "30"]
        let minuteArray2 = ["30", "35", "40", "45", "50", "55"]
        let minuteArray3 = ["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"]

        if (minuteArray.includes("00") && minuteArray.includes("30")) {
            setMinuteArray(minuteArray3)
        } else if (minuteArray.includes("30")) {
            setMinuteArray(minuteArray2)
        } else if (minuteArray.includes("00")) {
            setMinuteArray(minuteArray1)
        }


        // // console.log(event.target.value)

        // //console.log(minute)
        // let minuteInt = parseInt(minute)
        // let minuteArray1 = ["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55", "60"]
        // console.log(minuteArray1.indexOf(minute))
        // let index = minuteArray1.indexOf(minute)

        // let minuteArrayPass = minuteArray1.slice(index, index + 7)

        // setMinuteArray(minuteArrayPass)

    }

    const clickonmin = (event) => {
        // console.log("change", formData.start_time_hrs)
        if (formData.start_time_hrs === "") {
            setminerror(true)
            setMinuteArray([])
        } else {
            setminerror(false)

        }
    }


    const onMinutesChange = (event) => {

        if (formData.start_time_hrs === "") {

        }
        updateFormData({
            ...formData,
            start_time_minutes: event.target.value,
        });

    }
    // console.log("states data:- ",props.location.state)
    // const param_data = props.location.state.paramData;
    // const selectedate= props.location.state.selectedData;
    // const treatmentData = props.location.state.treatmentData;

    return (
        <>
            <section className="bgcolor-gray common-section open_req_acceptbooing_pg pb80">
                <Container>
                    <Row>
                        <Col className="col-lg-11 m-auto">
                            <div className="navigationTop">
                                <div className="heading-history">
                                    <div className="back-history">
                                        <button type="button" onClick={() => history.goBack()} className="back-btn" >
                                            {" "}
                                            <img src={LeftArrow} alt="arrow" />{" "}
                                        </button>
                                    </div>
                                    <div className="history-title">
                                        <h2> Select time and accept booking  </h2>
                                    </div>
                                </div>
                            </div>

                            <div className="requirement_wrp">
                                <div className="req_img_box mt-24">
                                    <div className="categorylist-box">
                                        <div className="list_name">{calenderobj && calenderobj.subCatArray}</div>
                                        <div className="category_price">
                                            <span className="price_strik og_price"> <span> £</span>{calenderobj && calenderobj.originalPriceText && calenderobj.originalPriceText.toFixed(2)}</span>
                                            <span className="price_discounted"> <span> £</span>{calenderobj && calenderobj.discountPrice && calenderobj.discountPrice.toFixed(2)}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="select_availbility_box mt-24 mb-32">
                                    <p>The GiniPig has selected the following availability</p>
                                </div>

                                <div className="treatment_availability_calender mb-40">
                                    {calenderobj !== "" && calenderobj !== undefined && calenderobj !== null &&
                                        <AppointmentCalender
                                            calendarData={calenderobj && calenderobj.enquiryData}
                                            disableSlots={true}
                                            activeDate={calenderobj && calenderobj.date}
                                            selectedData={[]}

                                        />
                                    }
                                </div>
                                {acceptenquirydata.isError && acceptenquirydata.acceptenquirydata &&
                                   acceptenquirydata.acceptenquirydata.hasOwnProperty(
                                        "message"
                                    ) ? (
                                        <p style={{ color: "red" }}>
                                        {
                                            acceptenquirydata.acceptenquirydata.message
                                        }{" "}
                                    </p>
                                    ) : null}

                                <div className="slect_time_accept-booking">


                                    <div className="select_availbility_box mt-16 mb-16">
                                        <p>Choose your date and time</p>
                                        {/* {minerror===true ? (
                                                            <p className="error">
                                                                <p style={{ color: "red" }}>
                                                                    {"Please select hrs first"}{" "}
                                                                </p>
                                                            </p>
                                                        ) : null} */}
                                    </div>

                                    <div className="two-col">
                                        <div className="select_item picker_date">
                                            <p className="field-title"> Date </p>

                                            <div>
                                                <div className="borderbox">
                                                    <div className="inside_box date-box">
                                                        
                                                        <button className="varity-listbtn italic_btn">
                                                            {
                                                                //calenderobj && calenderobj.date
                                                                //calenderobj && moment(calenderobj.date).format('DD-MM-YYYY')
                                                                calenderobj && moment(calenderobj.date).format('Do MMMM YYYY')
                                                            }

                                                        </button>
                                                    </div>
                                                </div>
                                                {/* <DatePicker
                                                    dateFormat="dd/MM/yyyy"
                                                    selected={startDate}
                                                    value={formData.date}
                                                    minDate={new Date()}
                                                    maxDate={startMaxDate}
                                                    onChange={date => setDatepick(date)}
                                                /> */}

                                            </div>


                                        </div>
                                        <div className="time_box">

                                            <p className="field-title">Time </p>

                                            {/* {minerror===true ? (
                                                            <p className="error">
                                                                <p style={{ color: "red" }}>
                                                                    {"Please select hrs first"}{" "}
                                                                </p>
                                                            </p>
                                                        ) : null}  */}

                                            <div className="borderbox">
                                                <div className="inside_box">
                                                    <div className="hrs_box select_feild">
                                                        <select
                                                            // value={formData.start_time_hrs}
                                                            onChange={onHrsChange}
                                                        >


                                                            {
                                                                calenderobj && calenderobj.hrsArray.map((slot, index) => {

                                                                    return (

                                                                        <option value={slot}>{slot}</option>


                                                                    )

                                                                })
                                                            }
                                                            {/* <option>07</option>
                                                                <option>08</option>
                                                                <option>09</option>
                                                                <option>10</option>
                                                                <option>11</option>
                                                                <option>12</option>
                                                                <option>13</option>
                                                                <option>14</option>
                                                                <option>15</option>
                                                                <option>16</option>
                                                                <option>17</option>
                                                                <option>18</option>
                                                                <option>19</option>
                                                                <option>20</option>
                                                                <option>21</option> */}

                                                        </select>


                                                    </div>
                                                    <div className="semisymbolr"><span>:</span></div>
                                                    <div className="mins_box select_feild" onClick={clickonmin}>

                                                        <select
                                                            // value={formData.start_time_minutes}
                                                            onChange={onMinutesChange}
                                                        >

                                                            {
                                                                minuteArray.length !== 9 && minuteArray.map((value, index) => {
                                                                    return (
                                                                        <option>{value}</option>
                                                                    )

                                                                })
                                                            }
                                                            {/* <option></option>
                                                                <option>00</option>
                                                                <option>05</option>
                                                                <option>10</option>
                                                                <option>15</option>
                                                                <option>20</option>
                                                                <option>25</option>
                                                                <option>30</option>
                                                                <option>35</option>
                                                                <option>40</option>
                                                                <option>45</option>
                                                                <option>50</option>
                                                                <option>55</option>
                                                                <option>60</option> */}
                                                        </select>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="btn-align pd32 pt-110">
                                    <LargeButton
                                        ButtonType="button"
                                        ButtonText="Accept"
                                        nextButtonClick={(e) => acceptButtonClick(e)}
                                    />
                                </div>

                            </div>

                            <Modal
                                isOpen={modalIsOpen}
                                onRequestClose={closeModal}
                                to
                                style={customStyles}
                                shouldCloseOnOverlayClick={false}
                                contentLabel="Example Modal"
                            >


                                <div>
                                    <LoadingSpinner />
                                </div>

                            </Modal>


                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default SelectTimeAcceptBooking;