import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { giniPigLogo, locationIcon, searchIcon, dateIcon } from '../../../config/images';
import DatePicker from "react-datepicker";
import usePlacesAutocomplete, { getGeocode, getLatLng, getZipCode } from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';
import moment from 'moment'
import { resetFilterData, saveSearchData } from '../../../stores/actions'
import { useDispatch, useSelector } from 'react-redux'
import { clearLocalStorage, getLocalStorageData } from "../../../utils/storage";
import { useRef } from 'react';
import { isMobile } from 'react-device-detect'

const Banner = () => {

  //const [startDate, setStartDate] = useState(new Date());
  const [startMaxDate, setStartMaxDate] = useState(new Date());

  const [startDate, setStartDate] = useState('Any date');
  const [cross, setCross] = useState(false);

  let history = useHistory();
  const dispatch = useDispatch();
  //const filterData = useSelector(state => state.savefilter); 
  var filterData; var get_date;
  if (getLocalStorageData("filter_treatment_data") !== undefined) {
    filterData = JSON.parse(getLocalStorageData("filter_treatment_data"));
    if (filterData) {
      if (filterData.hasOwnProperty('searchDetails')) {
        if (filterData.searchDetails !== null) {
          if (filterData.searchDetails.startDate !== null)
            get_date = [new Date(filterData.searchDetails.startDate).getFullYear(), ("0" + (new Date(filterData.searchDetails.startDate).getMonth() + 1)).slice(-2), ("0" + new Date(filterData.searchDetails.startDate).getDate()).slice(-2)].join("-")
        }
      }
    }
  }
  //console.log("filter date ", filterData, ' ', startDate, ' get_date ', get_date)
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
      types: ['(regions)'],
      componentRestrictions: {
        country: 'uk',
      }
    },
    debounce: 300,
    // componentRestrictions: {country: "us"}
    callbackName: 'initMap'
  });


  const registerRef = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = e => {
    // Update the keyword of the input element
    //console.log("value: ", e.target.value);
    setValue(e.target.value);
    if (e.target.value === '') {
      updateFormData({
        ...formData,
        postcode: "",
        location: e.target.value,
        latitude: '', longitude: ''
      });
    } else
      updateFormData({
        ...formData,
        postcode: "",
        location: e.target.value
      });
  };
  const handleSelect = ({ description }) => () => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter as "false"

    // updateFormData({
    //   ...formData,
    //   location:description
    // });
    setValue(description, false);
    clearSuggestions();
    //console.log("description: ", description);
    // Get latitude and longitude via utility functions
    // getGeocode({ address: description })
    //   .then(results =>getZipCode(results[0]))
    //   .then(({ zipCode}) => {
    //     //console.log('📍 Coordinates: ', {zipCode});
    //   }).catch(error => {
    //     console.log('😱 Error: ', error)
    //   });
    let latitude = "";
    let longitude = "";
    let city = "";
    let country = "";
    getGeocode({ address: description })

      .then((results) => {
        //  let latlong= getLatLng(results[0]);
        //  let zipcode = getZipCode(results[0]);
        //console.log("results:", results[0].address_components);

        let city_name = results[0].address_components.map((item, key) => {

          if (item.types.indexOf("postal_town") != -1) {
            city = item.long_name;
          } else {
            return null;
          }

        });

        let country_name = results[0].address_components.map((item, key) => {

          if (item.types.indexOf("country") != -1) {
            country = item.long_name;
          } else {
            return null;
          }

        });
        latitude = results[0].geometry.location.lat();
        longitude = results[0].geometry.location.lng();


        // updateFormData({
        //     ...formData,
        //     latitude:latitude,
        //     longitude:longitude
        //   });
        //  console.log("1st latlong: ",latlong);
        //  console.log("1st zipcode: ",zipcode);
        // let country=results[0].fetchPlace('country');
        //   console.log("country:",results[0]);
        return Promise.resolve(results);
      })
      .then((results) => getZipCode(results[0]))
      .then((zipCode) => {
        // let latitude = results[0].geometry.location.lat();
        // let longitude = results[0].geometry.location.lng();
        updateFormData({
          ...formData,
          latitude: latitude,
          longitude: longitude,
          postcode: zipCode,
          location: description,
          country: country,
          city: city,
        });
        // console.log("latLng Code: ", latLng);
      })
      // .then((results) => getLatLng(results[0]))
      // .then(({ lat, lng }) => {
      //   console.log("📍 Coordinates: ", { lat, lng });
      // })
      .catch((error) => {
        console.log("Error: ", error);
      });
  };

  const renderSuggestions = () =>
    data.map(suggestion => {
      //  options = {
      //   types: ['(cities)'],
      //   componentRestrictions: {country: "GB"}
      //  };
      const {
        id,
        structured_formatting: { main_text, secondary_text },

      } = suggestion;

      return (
        <li
          key={id}
          onClick={handleSelect(suggestion)}
        >
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });


  const __redirectUrl = async () => {
    var date = '';

    if (startDate !== 'Any date') {
      date = [startDate.getFullYear(), ("0" + (startDate.getMonth() + 1)).slice(-2), ("0" + startDate.getDate()).slice(-2)].join("-")
      updateFormData({
        ...formData,
        date: moment(startDate).format('YYYY-MM-DD'),
      });
    } 
    else {
      date = 'Any date'
    }

    clearLocalStorage('filter_treatment_data')
    clearLocalStorage('photo_requirement_available')
    clearLocalStorage('bookingcondition_requirement')
    dispatch(resetFilterData());

    history.push('/search-result', { data: JSON.stringify(formData), date: date })
    window.scrollTo(0, 0);
  }

  const [formData, updateFormData] = useState(Object.freeze({
    keywords: "",
    location: "Any location",
    postcode: "",
    postcodeValue: "",
    country: "",
    city: "",
    latitude: "",
    longitude: "",
    from_header: 'not_from_header',
    //date: filterData != undefined? filterData.hasOwnProperty('searchDetails') ? filterData.searchDetails.startDate !== undefined ? [new Date(filterData.searchDetails.startDate).getFullYear(), ("0" + (new Date(filterData.searchDetails.startDate).getMonth() + 1)).slice(-2), ("0" + new Date(filterData.searchDetails.startDate).getDate()).slice(-2)].join("-"): startDate === 'Any date'? 'Any date': [startDate.getFullYear(), ("0" + (startDate.getMonth() + 1)).slice(-2), ("0" + startDate.getDate()).slice(-2)].join("-"):[startDate.getFullYear(), ("0" + (startDate.getMonth() + 1)).slice(-2), ("0" + startDate.getDate()).slice(-2)].join("-"):[startDate.getFullYear(), ("0" + (startDate.getMonth() + 1)).slice(-2), ("0" + startDate.getDate()).slice(-2)].join("-")
    date: get_date !== undefined ? get_date : startDate
  }));

  const __onTextChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };


  // date picker keyboard patch 
  const pickerRef = useRef(null);

  useEffect(() => {
    if (isMobile && pickerRef.current !== null) {
      pickerRef.current.input.readOnly = true;
    }
  }, [isMobile, pickerRef]);

  useEffect(() => {

    var datemax = new Date();
    datemax.setMonth(datemax.getMonth() + 3);
    setStartMaxDate(datemax)



    updateFormData({
      ...formData,
      location: 'Any location',
    });

  }, []);

  const handleChangeRaw = (value) => {

    if (value === undefined) {
      setStartDate('Any date')
    }

  }

  
  return (
    <>
      <section className="lp-hero">
        <Container>
          <div className="box-block-wrp d-flex align-items-center">
            <div className="m-auto box-block1 w-100">
              <div className="serach-form picker_date">
                <div className="lp-logo">
                  <img src={giniPigLogo} alt="logo Goginipig" />
                </div>

                <div className="serach-heading">
                  <h2 className="title">Ready To Be Spoilt?</h2>
                </div>
                <form className="search-form " 
                //onSubmit={__redirectUrl}
                >
                  <div className="form-field">
                    {/* <label htmlFor="" className="field-label">Search</label> */}
                    <label htmlFor="" className="field-label">Search for Treatments or Services</label>
                    <span className="field-icon"><img src={searchIcon} alt="search Icon" /></span>
                    <input className="field-input" type="text" value={formData.keywords}
                      id="keywords"
                      name="keywords"
                      autoComplete="off"
                      onChange={__onTextChange} />
                  </div>
                  <div className="form-field">
                    <label htmlFor="" className="field-label">Location</label>
                    <div className="loc_point">
                      <span className="field-icon"><img src={locationIcon} alt="location Icon" /></span>
                      <input className="field-input" type="text"
                        //value={locationPlace}
                        value={formData.location}
                        // id="location" 
                        // name="location" 
                        // onChange={__onTextChange}

                        //value={value}
                        onChange={handleInput}
                        disabled={!ready}
                        placeholder="Please enter location"

                        onClick={() => {
                          //console.log("onClick", formData.location)
                          if (formData.location === 'Any location') {
                            updateFormData({
                              ...formData,
                              location: ''
                            });
                            setValue("")
                          }
                        }}

                      />
                    </div>
                    {status === 'OK' && <ul className="autoSearch">{renderSuggestions()}</ul>}

                  </div>
                  <div className="form-field">
                    <label htmlFor="iconClick" className="field-label">Date 
                    <span className="field-icon" style={{cursor:'pointer'}}><img src={dateIcon} alt="search Icon" /></span>
                    {/* <input className="field-input" type="date"   /> */}
                    </label>
                    <DatePicker
                      id='iconClick'
                      className="time_search"
                      style={{backgroundImage:`url(${dateIcon})`}}
                      ref={pickerRef}
                      selected={startDate === 'Any date' ? '' : startDate}
                      value={startDate}
                      onChange={date => {
                        setCross(true)
                        var setDate = [new Date(date).getFullYear(), ("0" + (new Date(date).getMonth() + 1)).slice(-2), ("0" + new Date(date).getDate()).slice(-2)].join("-");
                        var second_set;

                        if (startDate !== undefined && startDate !== 'Any date') {
                          second_set = [new Date(startDate).getFullYear(), ("0" + (new Date(startDate).getMonth() + 1)).slice(-2), ("0" + new Date(startDate).getDate()).slice(-2)].join("-");
                        } else {
                          second_set = '';
                        }
                       
                        //console.log(date, ' ', startDate, ' ', second_set, ' ', date);
                        if (setDate === second_set || date ===null) {
                          setDate = 'Any date'
                          setCross(false)
                          setStartDate(setDate)
                          //console.log("equal ", startDate);
                        } else {
                          setDate = [new Date(date).getFullYear(), ("0" + (new Date(date).getMonth() + 1)).slice(-2), ("0" + new Date(date).getDate()).slice(-2)].join("-");
                          updateFormData({
                            ...formData,
                            date: setDate
                          });
                          setStartDate(date)
                        }
                        // updateFormData({
                        //   ...formData,
                        //   date:setDate
                        // });                        
                        //setStartDate(date)
                      }}
                      isClearable={cross}
                      minDate={new Date()}
                      maxDate={startMaxDate}
                      dateFormat="dd/MM/yyyy"

                      placeholderText="Any Date"
                      onChangeRaw={(event) =>
                        handleChangeRaw(event.target.value)}
                    //onBlur={event => focousOut(event.target.value)}
                    />
                  </div>
                  <div className="search-btn-box">
                    <button className="btn searchbtn" type="button" 
                    onClick={() => { __redirectUrl() }}
                    >Search</button>
                  </div>
                </form>

              </div>
            </div>
            <div className="box-block2 d-none d-md-block w-100">
                      <div className="serach-heading" style={{paddingTop:'9rem',textAlign: 'center'}}>
                      <h2 className="title" style={{fontSize:'2.925rem'}}>Connecting Models to Practicing Professionals</h2>
                        <div className="form-field ">
                          <p className="field-label2 mb-0">Glow. Grow. Perfect.</p>
                          <p className="field-label">No subscription or joining fee</p>
                        </div>
                    </div>
            </div>      
          </div>
        </Container>
      </section>


    </>
  );
}

export default Banner;
