import moment from 'moment';

export const isEmpty = (obj) => {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

export const UIAlert = (type, response) => {
    alert(JSON.stringify(response))
}

export const formatPostcode = (postcode) => {
    if (postcode !== undefined) {
        if (postcode.length > 4) {
            var temp = postcode.substring(0, postcode.length - 3);
            var frmtpostcode = temp + " " + postcode.substring(temp.length, postcode.length);
            return frmtpostcode;
        } else {
            return postcode;
        }
    }

}

export const get24HrsTimeFormat = (time) => {
    let timein24hrs = ""
    if (time !== null && time !== undefined) {
        timein24hrs = time.slice(0, 5)
    }
    return timein24hrs
}


export const get12HrsTimeFormat = (time) => {
    let period = ""
    let hours = ''
    let [sHours, minutes] = ""
    let timein12hrs = ""

    if (time !== null) {
        [sHours, minutes] = time && time.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
        period = +sHours < 12 ? 'AM' : 'PM';
        hours = +sHours % 12 || 12;

        timein12hrs = hours + ":" + minutes + period
    }
    return timein12hrs
}

export const getHrsLeft = (treatmentDateTime) => {

    var currentDate = moment();
    var treatmentDate = moment(treatmentDateTime, "YYYY/MM/DD HH:mm");
    var ms = moment(treatmentDate).diff(currentDate, "hours");
  
    return ms;
}
