import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS } from '../../config/ApiConstants';


export function treatmentaddreviewAPI_Action(token) {
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/get-practitioner-treatments`,
            method: 'GET',
            headers: {
                ...AUTH_HEADERS,
                'Authorization':'Bearer '+token
            },
            body: '',
            types: [
                types.TREATMENTADDREVIEW_REQUEST,
                types.TREATMENTADDREVIEW_RECEIVE, {
                    type: types.TREATMENTADDREVIEW_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}


export const resettreatmentaddreviewAPI_Action = () => {
    return {
        type: types.RESET_TREATMENTADDREVIEW,
        payload: null
    }
};
