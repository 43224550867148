import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import DoubleConfirmationPopup from '../../components/UIComponent/DoubleConfirmationPopup'
import { treatmentaddreviewAPI_Action, removeTreatmentAPI_Action, resetremoveTreatmentAPI_Action, resetedittreatmentAPI_Action, resettreatmentaddreviewAPI_Action, treatmentDetailsAPI_Action,
    resetdisabletreatmentpractitionerprofileAPI_Action,disabletreatmentpractitionerprofileAPI_Action } from '../../stores/actions'
import { getLocalStorageData, setLocalStorageData } from '../../utils/storage'



const DeleteConfirmation = (props) => {

    // Initialize hooks first   
    const dispatch = useDispatch();
    let history = useHistory();

    const loginUserData = useSelector(state => state.login);
    const practitionersignupaddinfoData = useSelector(state => state.practitionersignupaddinfo);
    const disabletreatmentpractitionerprofileData = useSelector(state => state.disabletreatmentpractitionerprofile);
    //const removeTreatmentData = useSelector(state => state.removetreatment);
   
   useEffect( () => {
       dispatch(resetdisabletreatmentpractitionerprofileAPI_Action());
   }, []);

   
   
    const __deleteTreatment = async () => {
        let checkToken = "";

        let LocalDatatoken = getLocalStorageData("loginData")
        let registerData = getLocalStorageData("practitionersignupData")

        if (registerData != "" && registerData !== undefined && registerData !== null) {
            checkToken = registerData.token;
        }
        else if (LocalDatatoken !== "" && LocalDatatoken !== undefined && LocalDatatoken !== null) {
            checkToken = LocalDatatoken.token;
        }
        if (checkToken !== '') {
            let id = props.match.params.id;
            await dispatch(disabletreatmentpractitionerprofileAPI_Action(id,checkToken))
            //await dispatch(removeTreatmentAPI_Action(id, checkToken))
        }

    }


    if (disabletreatmentpractitionerprofileData.isSuccess === true) {
    //if (removeTreatmentData.isSuccess === true) {
        //    dispatch(resettreatmentaddreviewAPI_Action());
        let checkToken = "";
        const loginToken = loginUserData.logindata ? loginUserData.logindata.data ? loginUserData.logindata.data.token : "" : "";
        const RegisterToken = practitionersignupaddinfoData.practitionersignupaddinfodata ? practitionersignupaddinfoData.practitionersignupaddinfodata.data ? practitionersignupaddinfoData.practitionersignupaddinfodata.data.token : "" : "";
        if (loginToken != "") {
            checkToken = loginToken;
        } else if (RegisterToken != "") {
            checkToken = RegisterToken;
        }
        if (checkToken !== '') {
            dispatch(disabletreatmentpractitionerprofileAPI_Action(checkToken));
            //dispatch(treatmentaddreviewAPI_Action(checkToken));
        }
        dispatch(resetdisabletreatmentpractitionerprofileAPI_Action());
        //dispatch(resetremoveTreatmentAPI_Action());
        // dispatch(resettreatmentdetailsAPI_Action());

        window.scrollTo(0, 0);
        history.push('/treatment-Menu');
    }

    const __redirectBack = async () => {
        history.goBack();
    }

    return (
        <>
            <div>
               
                <DoubleConfirmationPopup
                    breadcrumbsText="Treatment menu"
                    subHeadingtextTreatment="By deleting this treatment, it will be permanent, so please be sure!"
                    redirectpath="treatmentdelete"
                    buttonYesText="Happy to delete"
                    buttonNoText="Love to save"
                    handleClick={__deleteTreatment}
                    showLoadingdelete={disabletreatmentpractitionerprofileData.isFetching === true}
                    //showLoadingdelete={removeTreatmentData.isFetching === true}
                    __redirectBack={__redirectBack}
                />
            </div>

        </>
    );
}

export default DeleteConfirmation;