import React from 'react';

function Benefitbox(props) {
    return (
        <>
            {
                props.benefitBox.map( (item,i) => 
                <div className="benefit-item" key={i} >
                <div className="benefit-icon">
                    <img src={item.icon} alt="icon" />     
                </div>
                <div className="subheading">
                    <h3 className="subheading-text">{item.title}</h3>
                </div>
                <div className="benefit-desc">
                   {item.desc}      
                </div>  
            </div>
                )
            }


            
        </>
    );
}

export default Benefitbox;