import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS } from '../../config/ApiConstants';
export function cancelbookingAPIGinipig_Action(id,token) {
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/ginipig/booking/cancel/`+id,
            method: 'GET',
            headers: {
                ...AUTH_HEADERS,
                'Authorization':'Bearer '+token
            },
            body:'',
            types: [
                types.CANCELBOOKING_GINIPIG_REQUEST,
                types.CANCELBOOKING_GINIPIG_RECEIVE, {
                    type: types.CANCELBOOKING_GINIPIG_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}


export const resetcancelbookingAPIGinipig_Action = () => {
    return {
        type: types.RESET_GINIPIG_CANCELBOOKING,
        payload: null
    }
};
