import React, { useCallback, useState, useEffect } from "react";
import DoubleConfirmationPopup from '../../components/UIComponent/DoubleConfirmationPopup';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { getLocalStorageData,clearLocalStorage } from '../../utils/storage'
import { acceptbookingAPI_Action, deleterequestAPI_Action,resetLoginAPI_Action, resetdeleterequestAPI_Action,resetupcomingbookingAPI_Action, practionerdeclinebookingAPI_Action, pendingbookingDetailsAPI_Action, upcomingbookingAPI_Action, resetgoginipigprofileAPI_Action, resettreatmentaddreviewAPI_Action } from '../../stores/actions'



const ConfirmDeleteRequest = (props) => {
    let history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const practitionersignupaddinfoData = useSelector(state => state.practitionersignupaddinfo);
    const deleterequestdata = useSelector(state => state.deleterequestdata);

    useEffect(() => {
     if(!location.requestid){
        window.scrollTo(0, 0);
        dispatch(resetdeleterequestAPI_Action());
        dispatch(resetupcomingbookingAPI_Action());
        history.push('/practitioner-account')
     }
    
    },[])
    
    const handleClickYes = (requestId) => {

        __apiCalldelete(location.requestid)

    }



    const __apiCalldelete = useCallback(async (requestid) => {

        try {

            let checkToken = "";
            let LocalDatatoken = getLocalStorageData("loginData")
            let registerData = getLocalStorageData("practitionersignupData")
    
            if (registerData != "" && registerData!== undefined && registerData !== null) {
                checkToken = registerData.token;
            }
            else if (LocalDatatoken !== "" && LocalDatatoken !== undefined && LocalDatatoken!==null) {
                checkToken = LocalDatatoken.token;
            }
            await dispatch(deleterequestAPI_Action(requestid, checkToken));
            window.scrollTo(0, 0);

            //     history.push({
            //       pathname: '/cancel-booking-confirmation',
            //    });

        } catch (error) {
            console.error(" profile ::  profile() :: Exception ==", error);
        }
    });
    if (deleterequestdata.isSuccess == true) {
        window.scrollTo(0, 0);
        dispatch(resetdeleterequestAPI_Action())
        history.push({
            pathname: '/delete-request-confirmation',
            requestid:location.requestid
        });

    }
    if(deleterequestdata.deleterequestdata && deleterequestdata.deleterequestdata.status===401){
        window.scrollTo(0, 0);
        dispatch(resetdeleterequestAPI_Action())
        clearLocalStorage("loginData")
        clearLocalStorage("practitionersignupData")
        dispatch(resetLoginAPI_Action());
        history.push({
            pathname: '/login',
        }); 
    }



    return (
        <>
            <div className="cancelbooking_pg pb80">
                <DoubleConfirmationPopup
                    breadcrumbsText="Delete Listing"
                    DeleteSubHeadingtext="Are you sure you want to delete this?"
                    noticeText="Since no GiniPigs have booked yet, you can delete without incurring a fee"
                    buttonYesText="Yes, delete this listing"
                    buttonNoText="No, keep it active"
                    handleClick={handleClickYes}
                />
                {deleterequestdata.deleterequestdata &&
                    deleterequestdata.deleterequestdata.hasOwnProperty(
                        "error"
                    ) ? (
                        <p className="error">
                            {deleterequestdata.deleterequestdata.error.hasOwnProperty(
                                "message"
                            ) ? (
                                    <p style={{ color: "red" }}>
                                        {
                                            deleterequestdata.deleterequestdata.error
                                                .message
                                        }{" "}
                                    </p>
                                ) : null}
                        </p>
                    ) : null}
            </div>
        </>
    );
}

export default ConfirmDeleteRequest;